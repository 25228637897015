/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const form = {
  'main-cover': null, // VALUE NOT CAPTURED ON ONLINE FORM THIS IS JUST FOR TESTING
  'cover-options': {
    'ghs-1000000': null,
    'ghs-1500000': null,
    'ghs-2000000': null,
    'ghs-2500000': null,
    'ghs-3000000': null,
    'ghs-5000000': null,
    'ghs-7000000': null,
    'ghs-10000000': null,
  },
  'lives-assured': [],
  'life-assured-identification': {
    'first-names': null,
    surname: null,
    'idpassport-number': null,
    title: null,
    'marital-status': null,
    ghanaian: null,
    'other-nationality': null,
    'date-of-birth': null,
    gender: null,
  },
  'life-assured-identification-type': {
    dvla: null,
    passport: null,
    'voter-id': null,
    nhis: null,
    ssnit: null,
    'nia-ghana-card': null,
  },
  'life-assured-contact-details': {
    'cell-phone': null,
    email: null,
    'digital-address': null,
  },
  'life-assured-postal-address': {
    addressee: null,
    'po-box-or-private-bag': null,
    region: null,
    town: null,
    street: null,
    suburb: null,
  },
  'life-assured-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
    occupation: null,
    'basic-earnings': null,
  },
  'spouse-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    gender: null,
    title: null,
    'cell-phone': null,
  },
  'spouse-identification-type': {
    dvla: null,
    passport: null,
    'voter-id': null,
    nhis: null,
    ssnit: null,
    'nia-ghana-card': null,
  },
  'spouse-is-trustee': null,
  'trustee-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'cell-phone': null,
    relationship: null,
    title: null,
  },
  'debit-order': null,
  'stop-order': null,
  'payment-start-date': null,
  'payment-frequency': {
    monthly: null,
  },
  'payer-person': {
    myself: null,
    'my-spouse': null,
  },
  //add payer person identification
  'payer-person-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
  },
  'payer-signature': null,
  'payment-bank-details': {
    'account-holder-name': null,
    'account-type': null,
    'account-entity-name': null,
    'account-branch-name': null,
    'account-number': null,
  },
  'payment-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
  },
  'mobile-wallet-details': {
    'mobile-ref': null,
    'mobile-wallet-network-provider': null,
    'mobile-wallet-number': null,
  },
  documents: {
    'id-document': [],
  },
  'acceptance-signature': null,
  'application-acceptance-date': null,
  'application-accepted': null,
}
