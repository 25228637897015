/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import {
  hasValue,
  verify,
  validEmail,
  validAmountRange,
} from '../../../../src/core/validations'
import { quotedPremium } from '../bloom'
import {
  validNHIS,
  validVotersID,
  validSSNIT,
  validPassport,
  validDrivers,
  validID,
  validName,
  validCellnumber,
  onlyDigits,
} from '../../validations'
import { updateItemIn } from '../../../../src/core/dataitems'
import dayjs from 'dayjs'
const now = dayjs()

export const validate = (state, requiredOnly, validateOnly) => {
  if (process.env.DISABLE_VALIDATIONS === 'true') {
    return true
  }

  let validates = true
  switch (state.step) {
    case 'quotation-screen':
      validates =
        verify(
          state,
          state.form['owner-radio-group']['policy-for-myself'] ||
            state.form['owner-radio-group']['policy-for-wife'],
          hasValue,
          !validateOnly && { id: 'owner-radio-group' },
          ['*']
        ) && validates
      //date of birth
      validates =
        verify(
          state,
          state.form['life-assured-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 18 && dayjs().diff(v, 'year') <= 55)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      if (state.form['owner-radio-group']['policy-for-myself'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['marital-status'],
            hasValue,
            !validateOnly && { id: 'marital-status' },
            ['*']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['contract-details']['update-option'],
          hasValue,
          !validateOnly && { id: 'update-option' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['contract-details']['term-in-years'],
          hasValue,
          !validateOnly && { id: 'term-in-years' },
          ['*']
        ) && validates

      if (validates && !requiredOnly) {
        validates =
          verify(
            state,
            state.form['contract-details']['term-in-years'],
            (v) =>
              v <=
              60 -
                dayjs().diff(
                  state.form['life-assured-identification']['date-of-birth'],
                  'year'
                ),
            !validateOnly && { id: 'term-in-years' },
            ['The policy cannot be active after your 60-th birth-date.']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['contract-details']['accelerated-cover-sum-assured'],
          hasValue,
          !validateOnly && { id: 'accelerated-cover-sum-assured' },
          ['*']
        ) && validates

      if (state.form['contract-details']['accelerated-cover-sum-assured']) {
        let cbp = parseInt(
          state.form['contract-details']['accelerated-cover-sum-assured']
        )
        let value = parseInt(cbp % 5000)
        if (validates && !requiredOnly) {
          validates =
            verify(
              state,
              state.form['contract-details']['accelerated-cover-sum-assured'],
              (v) => !hasValue(v) || value <= 0,
              !validateOnly && {
                id: 'accelerated-cover-sum-assured',
                type: 'amount',
              },
              [
                'Please make sure that your sum assured is in multiples of 5000.00',
              ]
            ) && validates
        }

        if (validates && !requiredOnly) {
          validates =
            verify(
              state,
              state.form['contract-details']['accelerated-cover-sum-assured'],
              (v) => !hasValue(v) || validAmountRange(v, 5000, 100000),
              !validateOnly && {
                id: 'accelerated-cover-sum-assured',
                type: 'amount',
              },
              [
                'Please make sure that your sum assured is in the range of GHc 5000.00 - Ghc 100000.00',
              ]
            ) && validates
        }
      }
      break

    case 'additional-benefits':
      const prem = quotedPremium(state.form)
      let cashb = parseFloat(state.form['cash-bonus-premium'])

      //EDUCATIONAL SUPPORT BENEFIT PREMIUM VALIDATION
      if (
        state.form['educational-support-benefit']['educational-support-premium']
      ) {
        validates =
          verify(
            state,
            state.form['educational-support-benefit'][
              'educational-support-premium'
            ],
            (v) =>
              requiredOnly ? hasValue(v) : validAmountRange(v, 0, 100000),
            !validateOnly && { id: 'educational-support-premium' },
            requiredOnly ? ['*'] : ['The maximum benefit is GHc 100000.00']
          ) && validates

        if (
          state.form['educational-support-benefit'][
            'educational-support-term'
          ] === null ||
          state.form['educational-support-benefit'][
            'educational-support-term'
          ] === ''
        ) {
          //If the educational support benefit has been selected but no support term, then validate the field
          validates =
            verify(
              state,
              state.form['educational-support-benefit'][
                'educational-support-term'
              ],
              hasValue,
              !validateOnly && { id: 'educational-support-term' },
              ['You have to specify the educational support term.']
            ) && validates
        }
      }

      if (
        state.form['educational-support-benefit']['educational-support-term']
      ) {
        if (
          state.form['educational-support-benefit'][
            'educational-support-premium'
          ] === null ||
          state.form['educational-support-benefit'][
            'educational-support-premium'
          ] === ''
        ) {
          //If the educational support term has been selected but no support benefit, then validate the field
          validates =
            verify(
              state,
              state.form['educational-support-benefit'][
                'educational-support-premium'
              ],
              hasValue,
              !validateOnly && { id: 'educational-support-premium' },
              [
                'You have to specify the premium you want to pay for your Educational benefit.',
              ]
            ) && validates
        }
      }

      let term = parseInt(state.form['contract-details']['term-in-years'])
      let benefitterm = parseInt(
        state.form['educational-support-benefit']['educational-support-term']
      )
      if (validates && !requiredOnly) {
        validates =
          verify(
            state,
            state.form['educational-support-benefit'][
              'educational-support-term'
            ],
            (v) => !hasValue(v) || benefitterm <= term,
            !validateOnly && { id: 'educational-support-term' },
            ['The Edusaver term cannot be longer than the policy term.']
          ) && validates
      }

      //Retirement premium
      if (state.form['retirement-premium']) {
        validates =
          verify(
            state,
            state.form['retirement-premium'],
            (v) =>
              requiredOnly ? hasValue(v) : validAmountRange(v, 200, 5000),
            !validateOnly && { id: 'retirement-premium' },
            requiredOnly
              ? ['*']
              : [
                  'Please make sure that your premium amount is in the range of GHc 200.00 - Ghc 5000.00',
                ]
          ) && validates
      }

      //CASH BONUS VALIDATION
      if (state.form['cash-bonus-premium']) {
        validates =
          verify(
            state,
            state.form['cash-bonus-premium'],
            (v) => !hasValue(v) || validAmountRange(cashb, 0, prem),
            !validateOnly && { id: 'cash-bonus-premium', type: 'amount' },
            [`The maximum amount is GH¢ ${prem}`]
          ) && validates
      }

      //spouse date of birth
      if (state.form['spouse-cover'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['date-of-birth'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                (dayjs().diff(v, 'year') >= 18 &&
                  dayjs().diff(v, 'year') <= 55)),
            !validateOnly && { id: 'date-of-birth' },
            requiredOnly
              ? ['*']
              : ['The age is outside the allowable bounds for this product.']
          ) && validates
      }
      break

    case 'child-cover':
      //babycare cover only allowed for babies and children under the age of 4
      if (state.local['babycare-benefit'] === 'Y') {
        let age = state.local['age-of-child']
        validates =
          verify(
            state,
            state.local && state.local['age-of-child'],
            (v) => hasValue(v) && (requiredOnly || (age >= 0 && age <= 4)),
            !validateOnly && { id: 'babycare-benefit' },
            requiredOnly
              ? ['*']
              : [
                  'BABAYCARE benefit is only allowed for babies and children under 4 years of age.',
                ]
          ) && validates
      }

      validates =
        verify(
          state,
          state.local && state.local['age-of-child'],
          hasValue,
          !validateOnly && { id: 'age-of-child' },
          ['*']
        ) && validates
      break

    case 'life-assured-identification':
      validates = Object.keys(state.form['life-assured-identification'])
        .filter(
          (k) =>
            [
              'idpassport-number',
              'birth-place',
              'tin-number',
              'ghanaian',
              'other-nationality',
            ].indexOf(k) < 0
        )
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      //Life assured ID details should not be compulsory if policyholder is the spouse.
      if (state.form['owner-radio-group']['policy-for-myself'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            hasValue,
            !validateOnly && { id: 'idpassport-number' },
            ['*']
          ) && validates
      }

      //Validate Field Names to only be letters
      validates =
        verify(
          state,
          state.form['life-assured-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      //Validate the gender & title
      if (state.form['life-assured-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (
        state.form['life-assured-identification']['other-nationality'] === null
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification'].ghanaian,
            hasValue,
            !validateOnly && { id: 'ghanaian' },
            ['*']
          ) && validates
      }
      validates =
        verify(
          state,
          state.form['life-assured-identification']['other-nationality'],
          (v) =>
            hasValue(v) ||
            (validates &&
              state.form['life-assured-identification'].ghanaian === 'Y'),
          !validateOnly && { id: 'other-nationality' },
          requiredOnly
            ? ['*']
            : ['If you are not a ghanaian please select your nationality']
        ) && validates

      //Life assured ID details should not be compulsory if policyholder is the spouse.
      if (state.form['owner-radio-group']['policy-for-myself'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification-type'].dvla ||
              state.form['life-assured-identification-type'].passport ||
              state.form['life-assured-identification-type']['voter-id'] ||
              state.form['life-assured-identification-type'].nhis ||
              state.form['life-assured-identification-type'].ssnit ||
              state.form['life-assured-identification-type']['nia-ghana-card'],
            hasValue,
            !validateOnly && { id: 'id-type-radio-group' },
            ['*']
          ) && validates
      }

      //Validation for Voters ID
      if (state.form['life-assured-identification-type']['voter-id'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Voters ID Number']
          ) && validates
      }

      //Validation for NHIS
      if (state.form['life-assured-identification-type']['nhis'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid NHIS Number']
          ) && validates
      }

      //Validation for SSNIT
      if (state.form['life-assured-identification-type']['ssnit'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid SSNIT Number']
          ) && validates
      }

      //Passport
      if (state.form['life-assured-identification-type']['passport'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Passport Number']
          ) && validates
      }

      //Drivers
      if (state.form['life-assured-identification-type']['dvla'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Drivers Number']
          ) && validates
      }

      //ID
      if (
        state.form['life-assured-identification-type']['nia-ghana-card'] === 'Y'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid ID Number']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['life-assured-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      //Life assured ID details and email should not be compulsory if policyholder is the spouse.
      if (state.form['owner-radio-group']['policy-for-myself'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-contact-details'].email,
            (v) => (requiredOnly ? hasValue(v) : validEmail(v)),
            !validateOnly && { id: 'email' },
            requiredOnly ? ['*'] : ['Please enter a valid Email address']
          ) && validates
      }
      break

    case 'spouse-details':
      //Validate Name Fields To Not Accept Numbers
      validates =
        verify(
          state,
          state.form['spouse-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['spouse-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age - spouse has no maximum age
      validates =
        verify(
          state,
          state.form['spouse-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 18 && dayjs().diff(v, 'year') <= 55)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      //Spouse email and ID should not be compulsory if not owner of policy.
      if (state.form['owner-radio-group']['policy-for-wife'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            hasValue,
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Please enter a valid identification number']
          ) && validates
      }

      if (state.form['spouse-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['spouse-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      // Cell phone number
      validates =
        verify(
          state,
          state.form['spouse-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      //Spouse email and ID should not be compulsory if not owner of policy.
      if (state.form['owner-radio-group']['policy-for-wife'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identfication-type'].dvla ||
              state.form['spouse-identfication-type'].passport ||
              state.form['spouse-identfication-type']['voter-id'] ||
              state.form['spouse-identfication-type'].nhis ||
              state.form['spouse-identfication-type'].ssnit ||
              state.form['spouse-identfication-type']['nia-ghana-card'],
            hasValue,
            !validateOnly && { id: 'spouse-id-type-radio-group' },
            requiredOnly ? ['*'] : ['Please select an identification type']
          ) && validates
      }

      //ID
      if (state.form['spouse-identfication-type']['nia-ghana-card'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid National ID Number']
          ) && validates
      }

      //Voters ID
      if (state.form['spouse-identfication-type']['voter-id'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Voters ID Number']
          ) && validates
      }

      //Passport
      if (state.form['spouse-identfication-type']['passport'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Passport Number']
          ) && validates
      }

      //Ssnit
      if (state.form['spouse-identfication-type']['ssnit'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Ssnit Number']
          ) && validates
      }

      //Drivers
      if (state.form['spouse-identfication-type']['dvla'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Drivers Number']
          ) && validates
      }

      //NHIS
      if (state.form['spouse-identfication-type']['nhis'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Nhis Number']
          ) && validates
      }

      // Spouse email should not be compulsory if not owner of policy.
      if (state.form['owner-radio-group']['policy-for-wife'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-contact-details'].email,
            (v) => (requiredOnly ? hasValue(v) : validEmail(v)),
            !validateOnly && { id: 'email' },
            requiredOnly ? ['*'] : ['Please enter a valid Email address']
          ) && validates
      }
      break

    case 'trustee-details':
      //remove idpassport number validation whenever spouse has been added as the trustee.
      validates = Object.keys(state.form['trustee-identification'])
        .filter((k) => ['idpassport-number'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['trustee-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      validates =
        verify(
          state,
          state.form['trustee-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      //Validate Field Names to only be letters
      validates =
        verify(
          state,
          state.form['trustee-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first name']
        ) && validates

      validates =
        verify(
          state,
          state.form['trustee-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age
      validates =
        verify(
          state,
          state.form['trustee-identification']['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().diff(v, 'year') >= 18),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The minimum allowable age for this product is 18.']
        ) && validates

      //Validate the gender & relationship
      if (
        ['Adv', 'Dr', 'Prof', 'Rev'].indexOf(
          state.form['trustee-identification']['title']
        ) >= 0
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Daughter',
                  'Estate',
                  'Grandmother',
                  'Mother',
                  'Mother-In-Law',
                  'Sister',
                  'Spouse',
                  'Testament',
                  'Trust',
                  'Brother',
                  'Father',
                  'Son',
                  'Father-In-Law',
                  'Grandfather',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      if (
        ['Miss', 'Mrs', 'Ms'].indexOf(
          state.form['trustee-identification']['title']
        ) >= 0
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Daughter',
                  'Estate',
                  'Grandmother',
                  'Mother',
                  'Mother-In-Law',
                  'Sister',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      if (state.form['trustee-identification']['title'] === 'Mr') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Estate',
                  'Father',
                  'Son',
                  'Father-In-Law',
                  'Grandfather',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      break

    case 'beneficiary-details':
      if (!requiredOnly) {
        validates =
          verify(
            state,
            state.form['beneficiary-details'],
            (v) => v.length > 0,
            !validateOnly && { id: 'specify-beneficiaries', type: 'list' },
            ['Please add a beneficiary']
          ) && validates
      }
      break

    case 'add-beneficiary':
    case 'edit-beneficiary':
      validates = [
        'first-names',
        'surname',
        'date-of-birth',
        'role',
        'gender',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.local[field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      //Validate Name Fields To Not Accept Numbers
      validates =
        verify(
          state,
          state.local['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.local['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      //Validate the DOB to not be selected in the future
      validates =
        verify(
          state,
          state.local['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().isAfter(v, now)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['Please ensure the date of birth is not selected in the future.']
        ) && validates

      //Validate the gender & role
      if (state.local['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Child',
                  'Cousin',
                  'Daughter',
                  'Family',
                  'Grandchild',
                  'Grandmother',
                  'Main',
                  'Mother',
                  'Mother-in-law',
                  'Niece',
                  'Sister',
                  'Spouse',
                  'Step-Mother',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ['The selected role and gender does not correspond.']
          ) && validates
      }

      if (state.local['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Child',
                  'Cousin',
                  'Family',
                  'Father',
                  'Father-in-law',
                  'Grandchild',
                  'Grandfather',
                  'Main',
                  'Nephew',
                  'Son',
                  'Spouse',
                  'Step-Father',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ['The selected role and gender does not correspond.']
          ) && validates
      }
      break

    case 'update-beneficiary-split-percentage':
      if (!requiredOnly) {
        validates =
          Math.ceil(
            Object.keys(state.local || {})
              .filter((k) => k.startsWith('beneficiary-split'))
              .reduce((t, b) => {
                const v = parseFloat(state.local[b])
                return isNaN(v) ? -1.0 : v + t
              }, 0.0)
          ) === 100 && validates
        if (!validateOnly) {
          updateItemIn(
            state.page.item,
            { id: 'update-benefit-split-percentage' },
            {
              errors: validates ? [] : ['The allocation must add up to 100.00'],
            }
          )
        }
      }
      break

    case 'medical-underwriting':
      validates = [
        'planning-other-insurance',
        'other-assurance-past12-months',
        'risky-occupation-hobbies',
        'refused-application-or-increased-premium',
        'insolvency-issues',
        'occupation-not-clerical',
      ].reduce((valid, field) => {
        valid =
          verify(
            state,
            state.form['life-assured-general-habits'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid
        if (state.form['life-assured-general-habits'][field] === 'Yes') {
          let details = `${field}-details`
          valid =
            verify(
              state,
              state.form['life-assured-general-habits'][details],
              hasValue,
              !validateOnly && { id: details },
              ['*']
            ) && valid
        }
        return valid
      }, validates)

      validates =
        verify(
          state,
          state.form['life-assured-general-habits'][
            'cover-exceeds-one-hundred-thousand'
          ],
          hasValue,
          !validateOnly && { id: 'cover-exceeds-one-hundred-thousand' },
          ['*']
        ) && validates

      if (
        state.form['life-assured-general-habits'][
          'cover-exceeds-one-hundred-thousand'
        ] === 'Yes'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-general-habits'][
              'average-monthly-gross-income'
            ],
            hasValue,
            !validateOnly && { id: 'average-monthly-gross-income' },
            ['*']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['life-assured-general-habits']['smoker'] === 'Y' ||
            state.form['life-assured-general-habits']['non-smoker'] === 'Y' ||
            state.form['life-assured-general-habits']['never-smoked'] === 'Y',
          hasValue,
          !validateOnly && { id: 'smoker-options-radio-group' },
          ['*']
        ) && validates

      let age = dayjs().diff(
        state.form['life-assured-identification']['date-of-birth'],
        'year'
      )

      if (state.form['life-assured-general-habits'].smoker === 'Y') {
        let yrs = parseInt(
          state.form['life-assured-general-habits']['years-smoking']
        )

        validates =
          verify(
            state,
            state.form['life-assured-general-habits']['years-smoking'],
            (v) =>
              requiredOnly
                ? hasValue(v)
                : hasValue(v) && !isNaN(yrs) && yrs > 0 && yrs <= age,
            !validateOnly && { id: 'years-smoking' },
            requiredOnly ? ['*'] : ['Please enter a valid amount of years']
          ) && validates

        validates = [
          'cigarettes-per-day',
          'cigars-per-day',
          'pipe',
          'medical-advice-stop-smoking',
        ].reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-general-habits'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      }

      if (state.form['life-assured-general-habits']['non-smoker'] === 'Y') {
        let yrs = parseInt(
          state.form['life-assured-general-habits']['years-not-smoking']
        )
        validates =
          verify(
            state,
            state.form['life-assured-general-habits']['years-not-smoking'],
            (v) =>
              requiredOnly
                ? hasValue(v)
                : hasValue(v) && !isNaN(yrs) && yrs > 0 && yrs <= age,
            !validateOnly && { id: 'years-not-smoking' },
            requiredOnly ? ['*'] : ['Please enter a valid amount of years']
          ) && validates
      }

      validates = [
        'medical-advice-reduce-drinking',
        'excessive-drinking',
        'excessive-drinking-past',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.form['life-assured-general-habits'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'medical-declaration':
      validates = Object.keys(state.form['life-assured-health-tests']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['life-assured-health-tests'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'health-descriptions':
      validates = state.form['health-concerns'].reduce(
        (itemValid, item, index) =>
          Object.keys(item).reduce(
            (fieldValid, field) =>
              verify(
                state,
                item[field],
                hasValue,
                !validateOnly && { id: `${field}-${index + 1}` },
                ['*']
              ) && fieldValid,
            itemValid
          ),
        validates
      )
      validates = state.form['health-concerns'].reduce(
        (valid, date, counter) => {
          valid = ['medical-condition-date'].reduce(
            (valid, field) => valid && hasValue(date[field]),
            valid
          )
          if (!requiredOnly && valid) {
            valid =
              verify(
                state,
                date['medical-condition-date'],
                (v) => hasValue(v) && (requiredOnly || dayjs().isAfter(v, now)),
                !validateOnly && {
                  id: `medical-condition-date-${counter + 1}`,
                },
                requiredOnly
                  ? ['*']
                  : [
                      `Please ensure the medical condition date is not selected in the future.`,
                    ]
              ) && valid
          }
          return valid
        },
        validates
      )
      break

    case 'children-details':
      validates = state.form['adjusted-children'].reduce(
        (valid, life, counter) => {
          valid = ['first-names', 'surname', 'date-of-birth', 'gender'].reduce(
            (valid, field) => valid && hasValue(life[field]),
            valid
          )
          if (!requiredOnly && valid) {
            valid =
              verify(
                state,
                life['date-of-birth'],
                (v) =>
                  dayjs().diff(v, 'year').toString() === life['age-of-child'],
                !validateOnly && { id: `date-of-birth-${counter}` },
                [
                  `Date of birth does not match indicated age of ${life['age-of-child']} for ${life['relationship']}`,
                ]
              ) && valid
          }
          if (!requiredOnly && valid) {
            valid =
              verify(
                state,
                life['first-names'],
                (v) => (requiredOnly ? hasValue(v) : validName(v)),
                !validateOnly && { id: `first-names-${counter}` },
                requiredOnly ? ['*'] : ['Please enter a valid first-name']
              ) && valid
          }
          if (!requiredOnly && valid) {
            valid =
              verify(
                state,
                life['surname'],
                (v) => (requiredOnly ? hasValue(v) : validName(v)),
                !validateOnly && { id: `surname-${counter}` },
                requiredOnly ? ['*'] : ['Please enter a valid surname']
              ) && valid
          }
          if (!requiredOnly && valid) {
            //Validate the gender & relationship
            if (['Daughter'].indexOf(life.relationship) >= 0) {
              valid =
                verify(
                  state,
                  life.gender,
                  (v) =>
                    hasValue(v) &&
                    (requiredOnly || ['Female'].indexOf(life.gender) >= 0),
                  !validateOnly && { id: `gender-${counter}` },
                  requiredOnly
                    ? ['*']
                    : [
                        'The selected relationship and gender does not correspond.',
                      ]
                ) && valid
            }
          }
          if (!requiredOnly && valid) {
            //Validate the gender & relationship
            if (['Son'].indexOf(life.relationship) >= 0) {
              valid =
                verify(
                  state,
                  life.gender,
                  (v) =>
                    hasValue(v) &&
                    (requiredOnly || ['Male'].indexOf(life.gender) >= 0),
                  !validateOnly && { id: `gender-${counter}` },
                  requiredOnly
                    ? ['*']
                    : [
                        'The selected relationship and gender does not correspond.',
                      ]
                ) && valid
            }
          }
          return valid
        },
        validates
      )
      break

    case 'owner-details':
      validates = Object.keys(state.form['owner-postal-address'])
        .filter((k) => ['addressee'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['owner-postal-address'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      validates =
        verify(
          state,
          state.form['owner-residential-address'].suburb,
          hasValue,
          !validateOnly && { id: 'suburb' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['owner-employment-details'].occupation,
          hasValue,
          !validateOnly && { id: 'occupation' },
          ['*']
        ) && validates
      break

    case 'payment-details':
      validates =
        verify(
          state,
          state.form['payment-method']['debit-order'] ||
            state.form['payment-method']['stop-order'] ||
            state.form['payment-method']['mobile-wallet'],
          hasValue,
          !validateOnly && { id: 'payment-method-radio-group' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-start-date'],
          hasValue,
          !validateOnly && { id: 'payment-start-date' },
          ['*']
        ) && validates
      break

    case 'debit-order-details':
      validates = Object.keys(state.form['payment-bank-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-bank-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates

      //Validate field name to have no numbers
      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-holder-name'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'account-holder-name' },
          requiredOnly ? ['*'] : ['Please remove all numbers in the field']
        ) && validates

      //VALIDATE ACCOUNT NUMBER LENGTH BASED ON THE ACCOUNT ENTITY NAME CHOSEN
      if (!requiredOnly) {
        //10 DIGITS
        if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Absa (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Zenith Bank') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 10 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //9 / 10 / 11 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Bank Of Africa' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) =>
                (v.length === 9 || v.length === 10 || v.length === 11) &&
                onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Access Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Consolidated Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Ecobank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Fidelity Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Atlantic Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Bank Nigeria' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Gcb Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'National Investment Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Omni Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Prudential Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Republic Bank (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Stanbic Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Standard Chartered Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Universal Merchant Bank (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 13 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //14 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'United Bank For Africa (gh) Ltd' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 14 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //12 / 13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Cal Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Societe General Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Guaranty Trust (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => (v.length === 12 || v.length === 13) && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //16 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Agricultural Development Bank' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 16 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
      }
      break

    case 'stop-order-details':
      validates = Object.keys(state.form['payment-employment-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-employment-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break

    case 'mobile-wallet-details':
      validates = Object.keys(state.form['mobile-wallet-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['mobile-wallet-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break

    case 'acceptance-screen':
      validates =
        verify(
          state,
          state.form['acceptance-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break
  }

  if (!requiredOnly && !validates) {
    state.local = state.local || {}
    state.local.failed_validation = true
  }
  return validates
}
