/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

const isFunction = (func) => func && typeof func === 'function'

const randomUid = () => Math.random().toString(16).substring(2)

export const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

/* Recursively update a deeply nested object using a copy on write approach
 *
 * The parameters are
 * i: The root object
 * m: the filter to match the item of interest
 * u: the updated item, or a function that will be called with the matching item that needs to return an updated item
 *
 * This method will recursively walk through the `i` object provided,
 * finding any object that has properties matching those provided in the `m` parameter object.
 * The objects that are found are then merged with the object provided in the `u` parameter.
 */
export const updateItemIn = (item, filter, update) => {
  const matches = Object.keys(filter).reduce(
    (cont, key) => cont && key in item && item[key] === filter[key],
    true
  )
  if (matches) {
    if (isFunction(update)) {
      item = update(item)
    } else {
      for (const key in update) {
        item[key] = update[key]
      }
    }
  }
  switch (item.type) {
    case 'list':
      item.content.forEach((child) => updateItemIn(child, filter, update))
      break
    case 'table':
      item.content.forEach((row) =>
        row.forEach((col) => updateItemIn(col, filter, update))
      )
      break
  }
}

export const findItemsIn = (item, filter) => {
  const matches = Object.keys(filter).reduce(
    (cont, key) => cont && key in item && item[key] === filter[key],
    true
  )
  switch (item.type) {
    case 'list':
      return item.content.reduce(
        (items, child) => items.concat(findItemsIn(child, filter)),
        matches ? [item] : []
      )
    case 'table':
      return item.content.reduce(
        (rowItems, row) =>
          rowItems.concat(
            row.reduce(
              (colItems, col) => colItems.concat(findItemsIn(col, filter)),
              []
            )
          ),
        matches ? [item] : []
      )
    default:
      return matches ? [item] : []
  }
}

export const createItem = (label, obj) =>
  Object.assign(
    {
      uid: randomUid(),
      id: slugify(label),
      type: 'field',
      label: label,
      content: '',
      readonly: true,
    },
    obj || {}
  )

export const createButton = (label, obj) =>
  Object.assign(
    {
      control: false,
      id: slugify(label),
      label: label,
      uid: randomUid(),
    },
    obj || {}
  )
