/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */

import {
  pages,
  beneficiaryItem,
  healthDetailsItem,
  healthDetailsPrefix,
  quotationPanel,
  benefitSplitItem,
  familyMemberLabels,
  familyMemberOptions,
  buttonFromLifeAssured,
  idFromLifeAssured,
  additionalLife,
  createFamilyMember,
  childTablePrefix,
  childItem,
} from './bloom/pages'
import { cancelProcess, completeProcess } from '../../../src/core/actions'
import shortid from 'shortid'
import { form } from './bloom/form'
import {
  updateItemIn,
  createItem,
  findItemsIn,
  createButton,
  slugify,
} from '../../../src/core/dataitems'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import { original } from 'immer'
import { validate } from './bloom/validations'

const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

const dateFormat = 'D MMMM YYYY'

const amountString = (f) =>
  f
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/, '')

const isTrusteeABeneficiary = createSelector(
  (form) => form['trustee-identification']['first-names'],
  (form) => form['trustee-identification'].surname,
  (form) => form['trustee-identification']['date-of-birth'],
  (form) => form['beneficiary-details'],
  (fn, sn, dob, bs) =>
    fn &&
    sn &&
    dob &&
    bs.reduce(
      (result, b) =>
        result ||
        (b['first-names'] === fn &&
          b.surname === sn &&
          b['date-of-birth'] === dob),
      false
    )
)

//Identify if there is an unborn baby added as a child
const hasUnborn = (state) =>
  state.form['children-details'].find((l) => l.relationship === 'Unborn Baby')

//Map all the children details which is not unborn baby
const maptoUnborn = createSelector(
  (form) => form['children-details'],
  (unborn) => {
    const items = unborn.filter(
      (item) => item.relationship.indexOf('Unborn Baby') !== -1
    )
    return items
  }
)

const mapChildren = createSelector(
  (form) => form['children-details'],
  (child) => {
    const items = child.filter(
      (item) => !(item.relationship.indexOf('Unborn Baby') !== -1)
    )
    return items
  }
)

const isChildABeneficiary = (child, bs) =>
  child['first-names'] &&
  child.surname &&
  child['date-of-birth'] &&
  bs.reduce(
    (result, b) =>
      result ||
      (b['first-names'] === child['first-names'] &&
        b.surname === child.surname &&
        b['date-of-birth'] === child['date-of-birth']),
    false
  )

const updateBeneficiarySplit = createSelector(
  (form) => form['beneficiary-details'],
  (beneficiaries) => {
    if (beneficiaries.length > 0) {
      const split = Math.floor(100 / beneficiaries.length)
      let difference = 100 - split * beneficiaries.length
      for (let i = 0; i < beneficiaries.length; i++) {
        if (difference > 0) {
          beneficiaries[i]['benefit-split'] = amountString(split + 1)
          difference -= 1
        } else {
          beneficiaries[i]['benefit-split'] = amountString(split)
        }
      }
    }
    return beneficiaries
  }
)

const healthConcernCount = (form) =>
  [
    'chest-pain',
    'high-blood-pressure',
    'respiratory-condition',
    'digestive-condition',
    'urinary-condition',
    'anxiety-epilepsy',
    'diabetes',
    'cancer-or-tumors',
    'arthritis-osteoporosis',
    'aids',
    'sexually-transmitted-disease',
    'taking-medication',
    'disability',
  ].reduce(
    (t, f) =>
      t +
      (form['life-assured-health-details'][f] === 'Yes' ||
      form['life-assured-health-details'][f] === 'Y'
        ? 1
        : 0),
    0
  )

const createHealthDetailsPage = (state) => {
  const maxConcerns = Math.min(4, healthConcernCount(original(state.form)))
  updateItemIn(
    state.page.item,
    { id: 'health-descriptions', type: 'list' },
    {
      content: healthDetailsPrefix
        .concat(healthDetailsItem(maxConcerns))
        .concat([quotationPanel]),
    }
  )
  if (state.form['health-concerns'].length !== maxConcerns) {
    while (state.form['health-concerns'].length > maxConcerns) {
      state.form['health-concerns'].pop()
    }
    for (let i = state.form['health-concerns'].length; i < maxConcerns; i++) {
      state.form['health-concerns'].push({
        complaints: null,
        'complaint-description': null,
        'medical-condition-date': null,
        'health-results': null,
      })
    }
  }
}

//Change actual mapping from the "displayed online value"
const hospitalUnits = {
  50: '1',
  80: '2',
  100: '3',
  120: '4',
  150: '5',
  200: '6',
}

const optionsForRelationship = (opts, relationship) => {
  if (relationship in opts) {
    return opts[relationship].options || []
  } else {
    const alt = Object.values(opts).find((v) => v.rename === relationship)
    return alt ? alt.options || [] : null
  }
}

//Premium calculations Section
//Calcualte main life age from the date of birth captured
const mainLifeAge = createSelector(
  (form) => form['life-assured-identification']['date-of-birth'],
  (dob) => dayjs().diff(dob, 'year')
)

const spouseAge = createSelector(
  (form) => form['spouse-identification']['date-of-birth'],
  (dob) => dayjs().diff(dob, 'year')
)

//HCB Cover mapping
const hcbcover = createSelector(
  (form) => form['hospitalisation-units'],
  (hcbunits) => {
    const hcb = parseFloat(hcbunits)
    if (hcb === 50) {
      return 1
    } else if (hcb === 80) {
      return 2
    } else if (hcb === 100) {
      return 3
    } else if (hcb === 120) {
      return 4
    } else if (hcb === 150) {
      return 5
    } else if (hcb === 200) {
      return 6
    }
  }
)

var age_index = function (age) {
  if (51 <= age && age <= 55) {
    return 7
  } else if (46 <= age && age <= 50) {
    return 6
  } else if (41 <= age && age <= 45) {
    return 5
  } else if (36 <= age && age <= 40) {
    return 4
  } else if (31 <= age && age <= 35) {
    return 3
  } else if (26 <= age && age <= 30) {
    return 2
  } else if (18 <= age && age <= 25) {
    return 1
  } else {
    return -1
  }
}
var baby_age_index = function (age) {
  if (3 < age && age <= 4) {
    return 4
  } else if (2 < age && age <= 3) {
    return 3
  } else if (1 < age && age <= 2) {
    return 2
  } else if (1 <= age) {
    return 1
  } else {
    return -1
  }
}

//SUM ASSURED
const acceleratedcover = createSelector(
  (form) => form['contract-details']['accelerated-cover-sum-assured'],
  (sumassured) => {
    const cover = parseFloat(sumassured)
    if (cover === 5000) {
      return 1
    } else if (cover === 10000) {
      return 2
    } else if (cover === 15000) {
      return 3
    } else if (cover === 20000) {
      return 4
    } else if (cover === 25000) {
      return 5
    } else if (cover === 30000) {
      return 6
    } else if (cover === 35000) {
      return 7
    } else if (cover === 40000) {
      return 8
    } else if (cover === 45000) {
      return 9
    } else if (cover === 50000) {
      return 10
    } else if (cover === 55000) {
      return 11
    } else if (cover === 60000) {
      return 12
    } else if (cover === 65000) {
      return 13
    } else if (cover === 70000) {
      return 14
    } else if (cover === 75000) {
      return 15
    } else if (cover === 80000) {
      return 16
    } else if (cover === 85000) {
      return 17
    } else if (cover === 90000) {
      return 18
    } else if (cover === 95000) {
      return 19
    } else if (cover === 100000) {
      return 20
    }
    return -1
  }
)

const sumassured = createSelector(
  (form) => form['contract-details']['accelerated-cover-sum-assured'],
  (form) => form['educational-support-benefit']['educational-support-premium'],
  (sum, eduprem) => {
    const cover = parseFloat(sum)
    const educational = !isNaN(parseInt(eduprem)) ? parseInt(eduprem) : 0
    if (!isNaN(cover)) {
      return cover + educational
    } else {
      return '0.00'
    }
  }
)
//TERM IN YEARS - MAIN
const term_index = createSelector(
  (form) => form['contract-details']['term-in-years'],
  (term) => {
    const index = parseFloat(term)
    if (index === 5) {
      return 0
    } else if (index === 6) {
      return 20
    } else if (index === 7) {
      return 40
    } else if (index === 8) {
      return 60
    } else if (index === 9) {
      return 80
    } else if (index === 10) {
      return 100
    } else if (index === 11) {
      return 120
    } else if (index === 12) {
      return 140
    } else if (index === 13) {
      return 160
    } else if (index === 14) {
      return 180
    } else if (index === 15) {
      return 200
    } else if (index === 16) {
      return 220
    } else if (index === 17) {
      return 240
    } else if (index === 18) {
      return 260
    } else if (index === 19) {
      return 280
    } else if (index === 20) {
      return 300
    } else {
      return -1
    }
  }
)

// TERM-IN-YEARS
const hcb_term_index = createSelector(
  (form) => form['contract-details']['term-in-years'],
  (hcbterm) => {
    const hcbindex = parseFloat(hcbterm)
    if (hcbindex === 5) {
      return 0
    } else if (hcbindex === 6) {
      return 6
    } else if (hcbindex === 7) {
      return 12
    } else if (hcbindex === 8) {
      return 18
    } else if (hcbindex === 9) {
      return 24
    } else if (hcbindex === 10) {
      return 30
    } else if (hcbindex === 11) {
      return 36
    } else if (hcbindex === 12) {
      return 42
    } else if (hcbindex === 13) {
      return 48
    } else if (hcbindex === 14) {
      return 54
    } else if (hcbindex === 15) {
      return 60
    } else if (hcbindex === 16) {
      return 66
    } else if (hcbindex === 17) {
      return 72
    } else if (hcbindex === 18) {
      return 78
    } else if (hcbindex === 19) {
      return 84
    } else if (hcbindex === 20) {
      return 90
    } else {
      return -1
    }
  }
)

var main_incap_rates = [
  [87.1971, 88.3337, 90.2517, 93.4502, 98.9391, 107.8602, 121.1811],
  [91.3095, 93.4777, 97.5266, 104.4396, 116.3229, 135.7597, 164.4959],
  [95.4825, 98.8687, 105.0578, 115.7669, 134.1321, 163.7672, 208.0841],
  [99.6649, 104.2919, 112.6116, 127.0725, 151.8996, 192.0115, 251.6421],
  [103.8489, 109.7141, 120.1631, 138.3474, 169.4514, 220.0291, 295.5029],
  [108.034, 115.1361, 127.4773, 149.3813, 187.2497, 247.8921, 338.9007],
  [112.2186, 120.56, 135.0149, 160.6369, 204.9914, 276.2527, 382.2861],
  [116.3991, 125.9795, 142.5883, 171.9289, 222.7308, 304.4799, 426.3175],
  [121.0683, 131.6254, 150.4604, 183.8246, 241.0821, 333.084, 470.233],
  [125.7675, 137.2876, 158.3645, 195.3706, 259.056, 361.6869, 514.1235],
  [130.4713, 142.946, 166.2656, 207.2853, 277.3951, 390.2829, 557.9994],
  [135.1761, 148.607, 174.1678, 219.2409, 295.1648, 418.2645, 601.8659],
  [139.8805, 154.2698, 182.0649, 231.1908, 313.5172, 445.8959, 644.7963],
  [144.5845, 159.9321, 189.9613, 243.1376, 331.9341, 474.4787, 687.2092],
  [149.3344, 165.6138, 197.8931, 255.1451, 350.4072, 503.205, 731.0656],
  [154.0879, 171.2975, 205.8266, 267.1551, 368.8794, 531.9351, 775.0803],
  [158.8441, 176.9836, 213.7591, 279.1594, 387.3427, 560.6544, 819.0939],
  [163.6002, 182.6695, 221.6905, 291.1589, 405.798, 589.3628, 863.0902],
  [168.3552, 188.355, 229.6209, 303.1566, 424.2491, 618.0621, 907.0703],
  [173.1084, 194.04, 237.5505, 315.1534, 442.6971, 646.7535, 951.0365],
  [66.3716, 67.2369, 68.8481, 71.5278, 76.1833, 83.7747, 108.7036],
  [69.5258, 71.4389, 74.7654, 80.4798, 90.3664, 106.3979, 148.8341],
  [72.8182, 75.7309, 80.7793, 89.4263, 104.6167, 129.0929, 189.1929],
  [76.124, 79.9123, 86.682, 98.5031, 118.9814, 152.0072, 229.4958],
  [79.4294, 84.2023, 92.694, 107.5554, 133.1386, 174.705, 270.1094],
  [82.7367, 88.5126, 98.726, 116.6086, 147.5481, 197.635, 310.6431],
  [86.0435, 92.8237, 104.7586, 125.6602, 161.8924, 220.3395, 350.4479],
  [89.3493, 97.1282, 110.6096, 134.5356, 176.2334, 243.2127, 391.2035],
  [93.0135, 101.5988, 117.0571, 144.2269, 191.0179, 266.3783, 431.8373],
  [96.7006, 106.0835, 123.1605, 153.5736, 205.8078, 289.5434, 472.4471],
  [100.3905, 110.5686, 129.4308, 163.0865, 220.595, 312.7026, 513.0429],
  [104.078, 115.0495, 135.711, 172.3313, 235.1027, 335.8579, 553.629],
  [107.7695, 119.5306, 141.9922, 181.8506, 249.8814, 359.0123, 594.2081],
  [111.461, 124.0144, 148.278, 191.4001, 264.2474, 381.7245, 633.3839],
  [115.1879, 128.5159, 154.5868, 200.9921, 279.077, 404.2191, 673.2302],
  [118.9145, 133.0181, 160.8966, 210.5857, 293.9527, 427.3991, 713.8694],
  [122.644, 137.5199, 167.2055, 220.1771, 308.8285, 450.6469, 754.5804],
  [126.3723, 142.0201, 173.5134, 229.7665, 323.7002, 473.8942, 795.2833],
  [130.0994, 146.5189, 179.8206, 239.3539, 338.5681, 497.1348, 835.9717],
  [133.8294, 151.0205, 186.1271, 248.9398, 353.4326, 520.3689, 876.6473],
  [55.4477, 56.2478, 57.6809, 60.0629, 64.2371, 71.0092, 101.758],
  [58.1679, 59.8513, 62.7688, 67.8084, 76.5289, 90.717, 140.4386],
  [60.9839, 63.5342, 67.951, 75.5458, 88.9068, 110.4883, 179.042],
  [63.8049, 67.2182, 73.1409, 83.4259, 101.4121, 130.4698, 217.5635],
  [66.6328, 70.915, 78.3368, 91.2777, 113.8797, 150.4142, 256.3995],
  [69.4592, 74.5184, 83.4425, 99.1289, 126.2635, 170.2547, 295.1552],
  [72.2926, 78.2145, 88.6386, 106.9815, 138.7402, 190.0358, 333.1917],
  [75.1233, 81.9174, 93.8446, 114.8347, 151.2156, 209.9882, 372.1774],
  [78.2434, 85.7543, 99.2453, 123.0591, 164.0498, 230.167, 411.0149],
  [81.3858, 89.5994, 104.6478, 131.2896, 176.8899, 250.3461, 449.8291],
  [84.5253, 93.4445, 109.9086, 139.3763, 189.7294, 270.523, 488.6313],
  [87.669, 97.2934, 115.3045, 147.5981, 202.5692, 290.6963, 527.4242],
  [90.8139, 101.1425, 120.7196, 155.8391, 215.4077, 310.8667, 566.2102],
  [93.9585, 104.9889, 126.1332, 163.8664, 228.0307, 331.035, 603.5932],
  [97.1283, 108.8467, 131.5644, 172.1276, 240.8885, 351.2263, 642.3114],
  [100.3025, 112.7088, 136.9985, 180.4181, 253.4314, 371.0734, 681.1818],
  [103.4789, 116.5722, 142.4296, 188.707, 266.3083, 390.7357, 719.5406],
  [106.6553, 120.4353, 147.8593, 196.9938, 279.2149, 410.9279, 758.3885],
  [109.8314, 124.2981, 153.2895, 205.2801, 292.1211, 431.1671, 797.2721],
  [113.0059, 128.1585, 158.72, 213.5676, 305.0247, 451.4056, 836.1482],
  [48.9183, 49.6824, 51.0052, 53.207, 57.0848, 63.3457, 98.1588],
  [51.4464, 52.989, 55.594, 60.2174, 68.1953, 81.2054, 135.3564],
  [53.9113, 56.2394, 60.2665, 67.2983, 79.5008, 99.2982, 172.98],
  [56.4389, 59.5509, 64.9438, 74.3416, 90.7597, 117.3232, 210.2587],
  [58.9701, 62.8673, 69.6238, 81.444, 102.0691, 135.4439, 247.9834],
  [61.5052, 66.1867, 74.3073, 88.5527, 113.3045, 153.4722, 285.6347],
  [64.0374, 69.4235, 78.9127, 95.6638, 124.6271, 171.4389, 322.5694],
  [66.5736, 72.7448, 83.6017, 102.7763, 135.9486, 189.5783, 360.4552],
  [69.3488, 76.1774, 88.4632, 110.2096, 147.5811, 207.9083, 398.1785],
  [72.1508, 79.6307, 93.3311, 117.6466, 159.2173, 226.2369, 435.8777],
  [74.9533, 83.0831, 98.1997, 125.0846, 170.8524, 244.5606, 473.5635],
  [77.7573, 86.5352, 102.9397, 132.397, 182.4873, 262.8808, 511.2401],
  [80.5577, 89.991, 107.8072, 139.8335, 194.1225, 281.2011, 548.9113],
  [83.3622, 93.4447, 112.6859, 147.281, 205.7571, 299.5204, 585.18],
  [86.1926, 96.9084, 117.5746, 154.5726, 217.2385, 317.8593, 622.783],
  [89.0253, 100.3753, 122.4653, 162.041, 228.8959, 336.1972, 660.5383],
  [91.8589, 103.8429, 127.3597, 169.5282, 240.2705, 354.2351, 698.2986],
  [94.6912, 107.3092, 132.2546, 177.0171, 251.9362, 372.1169, 735.6024],
  [97.5239, 110.776, 137.1482, 184.505, 263.6268, 390.4559, 773.3279],
  [100.3572, 114.2423, 142.0402, 191.9936, 275.3192, 408.8334, 811.0824],
  [44.7583, 45.4422, 46.6938, 48.7794, 52.4061, 58.3413, 95.4745],
  [47.0598, 48.5062, 50.9557, 55.3134, 62.8872, 75.1256, 132.0469],
  [49.389, 51.5724, 55.2983, 61.9164, 73.3688, 91.9558, 168.9523],
  [51.671, 54.5883, 59.6456, 68.4797, 83.8755, 108.7962, 205.5121],
  [54.0072, 57.6575, 63.9953, 75.098, 94.4325, 125.7332, 242.5155],
  [56.3471, 60.7327, 68.3473, 81.7245, 104.9224, 142.5829, 279.4476],
  [58.6884, 63.8086, 72.7015, 88.3533, 115.4973, 159.3703, 315.6658],
  [61.0306, 66.8168, 76.9868, 94.9833, 126.0728, 176.3301, 352.8333],
  [63.5934, 70.0646, 81.5648, 101.9027, 136.9259, 193.4562, 389.8296],
  [66.1733, 73.1841, 86.0085, 108.8279, 147.7827, 210.5812, 426.803],
  [68.7551, 76.3714, 90.5201, 115.754, 158.6406, 227.7033, 463.7632],
  [71.3364, 79.5655, 95.0403, 122.6806, 169.4972, 244.8221, 500.7143],
  [73.918, 82.7599, 99.4493, 129.4972, 180.3528, 261.9384, 537.6588],
  [76.5001, 85.9565, 103.9657, 136.4193, 191.2089, 279.053, 573.2003],
  [79.1006, 89.1576, 108.5058, 143.3802, 202.0931, 296.1862, 610.0762],
  [81.706, 92.3624, 113.0488, 150.1784, 212.8112, 313.3202, 647.1052],
  [84.3118, 95.5705, 117.5941, 157.1389, 223.6915, 330.4532, 684.1392],
  [86.921, 98.7807, 122.138, 164.11, 234.3165, 347.3184, 721.1625],
  [89.5299, 101.9892, 126.6789, 171.0803, 245.2016, 364.0475, 757.78],
  [92.1378, 105.1962, 131.2193, 178.0498, 256.1077, 381.1821, 794.7726],
  [41.8833, 42.5321, 43.7331, 45.7379, 49.2225, 54.9343, 93.6804],
  [44.0425, 45.422, 47.7633, 51.9396, 59.1849, 70.8958, 129.7763],
  [46.2319, 48.3158, 51.8751, 58.2113, 69.151, 86.8967, 166.1731],
  [48.4312, 51.2138, 55.9929, 64.4459, 79.1338, 102.9077, 202.2329],
  [50.5856, 54.0666, 60.1135, 70.7341, 89.1733, 119.0232, 238.7363],
  [52.7859, 56.9671, 64.2366, 77.0258, 99.1424, 135.0524, 275.1678],
  [54.9924, 59.874, 68.3616, 83.3211, 109.1969, 151.0208, 310.8881],
  [57.1987, 62.781, 72.4881, 89.6207, 119.2561, 167.1629, 347.5582],
  [59.6083, 65.7171, 76.6888, 96.1854, 129.5703, 183.4539, 384.0506],
  [62.035, 68.7246, 80.9558, 102.7545, 139.8895, 199.7441, 420.5188],
  [64.4616, 71.7377, 85.228, 109.3248, 150.2083, 216.0308, 456.9749],
  [66.8891, 74.7519, 89.5032, 115.8965, 160.526, 232.3148, 493.4226],
  [69.318, 77.7604, 93.7735, 122.4706, 170.8444, 248.5978, 529.8638],
  [71.7447, 80.774, 97.9483, 128.9432, 181.1628, 264.8791, 564.902],
  [74.1939, 83.8009, 102.2389, 135.5408, 191.5064, 281.1765, 601.2736],
  [76.6451, 86.8282, 106.539, 142.1488, 201.8498, 297.4731, 637.7975],
  [79.0948, 89.8544, 110.8374, 148.6023, 212.0397, 313.7689, 674.3265],
  [81.5448, 92.8821, 115.1376, 155.2062, 222.3804, 330.0647, 710.8452],
  [83.9966, 95.9103, 119.4372, 161.8225, 232.4894, 345.7377, 746.9748],
  [86.4481, 98.938, 123.7374, 168.4406, 242.8363, 362.0165, 783.4636],
  [39.8991, 40.5671, 41.7351, 43.6869, 47.0724, 53.8218, 93.6766],
  [42.0009, 43.34, 45.6075, 49.6574, 56.6773, 69.5675, 129.7763],
  [44.101, 46.1139, 49.558, 55.7026, 66.2932, 85.3513, 166.1731],
  [46.2048, 48.8908, 53.5167, 61.7139, 75.9277, 101.1458, 202.2329],
  [48.2638, 51.626, 57.4812, 67.7787, 85.6105, 117.037, 238.7363],
  [50.3702, 54.4096, 61.4485, 73.8479, 95.23, 132.8484, 275.1678],
  [52.4797, 57.1986, 65.4183, 79.919, 104.934, 148.6001, 310.8881],
  [54.5924, 59.989, 69.3899, 85.9919, 114.6386, 164.5243, 347.5582],
  [56.8982, 62.8688, 73.4924, 92.3179, 124.5891, 180.5922, 384.0506],
  [59.2182, 65.6962, 77.5378, 98.6485, 134.5432, 196.6576, 420.5188],
  [61.5398, 68.582, 81.6426, 104.9809, 144.4977, 212.7204, 456.9749],
  [63.8615, 71.4743, 85.753, 111.313, 154.4514, 228.7812, 493.4226],
  [66.1843, 74.3669, 89.8645, 117.647, 164.4055, 244.8395, 529.8638],
  [68.5058, 77.2587, 93.9756, 123.9807, 174.3588, 260.8962, 564.902],
  [70.8488, 80.1624, 98.0086, 130.2466, 184.3371, 276.9693, 601.2736],
  [73.1919, 83.0601, 102.1279, 136.6047, 194.316, 293.0417, 637.7975],
  [75.5349, 85.9586, 106.256, 142.9712, 204.2945, 309.1132, 674.3265],
  [77.8798, 88.8638, 110.3923, 149.1981, 214.1308, 325.1845, 710.8452],
  [80.224, 91.7687, 114.5289, 155.562, 223.8682, 340.641, 746.9748],
  [82.5673, 94.6727, 118.6634, 161.9375, 233.8449, 356.6915, 783.4636],
  [38.4843, 39.1538, 40.2965, 42.2048, 45.517, 53.0143, 93.67],
  [40.53, 41.8359, 44.0485, 47.9975, 54.8422, 68.5946, 129.7762],
  [42.5603, 44.5202, 47.8776, 53.864, 64.1837, 84.2114, 166.1731],
  [44.593, 47.2074, 51.7135, 59.6976, 73.5468, 99.8394, 202.2329],
  [46.6271, 49.8975, 55.5562, 65.5888, 82.9592, 115.5641, 238.7363],
  [48.6207, 52.5481, 59.4024, 71.4842, 92.3027, 131.2038, 275.1678],
  [50.6538, 55.2408, 63.253, 77.3819, 101.7358, 146.7885, 310.8881],
  [52.6941, 57.9417, 67.1057, 83.2812, 111.1698, 162.5463, 347.5582],
  [54.919, 60.7283, 71.0839, 89.4196, 120.8344, 178.4419, 384.0506],
  [57.1574, 63.4643, 75.0069, 95.5647, 130.506, 194.3363, 420.5188],
  [59.3973, 66.2574, 78.9872, 101.7118, 140.1785, 210.2274, 456.9749],
  [61.6392, 69.0559, 82.9722, 107.8596, 149.8504, 226.1162, 493.4226],
  [63.8814, 71.8556, 86.96, 114.0085, 159.5229, 242.0036, 529.8638],
  [66.1243, 74.6552, 90.9465, 120.1577, 169.195, 257.8892, 564.902],
  [68.3853, 77.4643, 94.9479, 126.3316, 178.8906, 273.7898, 601.2736],
  [70.6482, 80.2734, 98.8565, 132.4142, 188.586, 289.6896, 637.7975],
  [72.9108, 83.0828, 102.857, 138.5883, 198.2821, 305.5895, 674.3265],
  [75.174, 85.8931, 106.8655, 144.632, 207.8409, 321.4892, 710.8452],
  [77.4377, 88.6997, 110.8697, 150.808, 217.5315, 337.0098, 746.9748],
  [79.7011, 91.5056, 114.873, 156.9958, 227.0159, 352.6803, 783.4636],
  [37.4935, 38.127, 39.2464, 41.1186, 44.3722, 52.4249, 93.671],
  [39.4593, 40.7336, 42.901, 46.7708, 53.4769, 67.8679, 129.7762],
  [41.4286, 43.346, 46.6346, 52.4943, 62.5997, 83.3528, 166.1731],
  [43.4054, 45.9631, 50.3757, 58.1892, 71.7479, 98.849, 202.2329],
  [45.384, 48.5832, 54.1215, 63.9404, 80.9471, 114.4427, 238.7363],
  [47.3235, 51.1648, 57.8732, 69.6981, 90.073, 129.9474, 275.1678],
  [49.3034, 53.788, 61.628, 75.4581, 99.2912, 145.3999, 310.8881],
  [51.2878, 56.4173, 65.386, 81.2203, 108.5109, 161.0258, 347.5582],
  [53.4481, 59.1281, 69.2668, 87.2138, 117.9546, 176.787, 384.0506],
  [55.624, 61.7909, 73.0937, 93.2123, 127.4021, 192.5457, 420.5188],
  [57.8028, 64.5121, 76.9768, 99.2116, 136.8503, 208.3022, 456.9749],
  [59.9817, 67.2367, 80.8642, 105.2146, 146.3008, 224.0561, 493.4226],
  [62.1615, 69.9648, 84.7543, 111.2178, 155.7506, 239.8078, 529.8638],
  [64.3402, 72.691, 88.6434, 117.222, 165.2016, 255.5588, 564.902],
  [66.5375, 75.4284, 92.547, 123.2496, 174.6745, 271.3244, 601.2736],
  [68.737, 78.1642, 96.4497, 129.2783, 184.1478, 287.0892, 637.7975],
  [70.938, 80.9017, 100.265, 135.2188, 193.6211, 302.8531, 674.3265],
  [73.139, 83.6393, 104.1671, 141.1111, 202.9597, 318.6172, 710.8452],
  [75.3394, 86.3758, 108.077, 147.1417, 212.4285, 334.0028, 746.9748],
  [77.5413, 89.1141, 111.9875, 153.1838, 221.695, 349.5425, 783.4636],
  [36.7508, 37.3715, 38.4738, 40.3179, 43.524, 51.984, 93.6692],
  [38.6691, 39.9211, 42.0504, 45.8546, 52.4528, 67.3219, 129.7762],
  [40.596, 42.4758, 45.707, 51.4679, 61.4037, 82.7013, 166.1731],
  [42.5268, 45.0358, 49.3723, 57.0506, 70.3796, 98.0931, 202.2329],
  [44.4596, 47.5994, 53.0434, 62.6932, 79.41, 113.5826, 238.7363],
  [46.3546, 50.1272, 56.7188, 68.3382, 88.3653, 128.9837, 275.1678],
  [48.2912, 52.6954, 60.3993, 73.9894, 97.4112, 144.3295, 310.8881],
  [50.2323, 55.269, 64.0826, 79.6429, 106.4615, 159.8513, 347.5582],
  [52.347, 57.9232, 67.8849, 85.5224, 115.73, 175.5054, 384.0506],
  [54.475, 60.5864, 71.6916, 91.4061, 125.0028, 191.158, 420.5188],
  [56.6043, 63.1976, 75.4468, 97.2911, 134.2752, 206.8073, 456.9749],
  [58.732, 65.8582, 79.2534, 103.1761, 143.5475, 222.4551, 493.4226],
  [60.8629, 68.5279, 83.0668, 109.0658, 152.8229, 238.1005, 529.8638],
  [62.9953, 71.1987, 86.8799, 114.9551, 162.0977, 253.7443, 564.902],
  [65.1447, 73.8778, 90.7059, 120.868, 171.3956, 269.4034, 601.2736],
  [67.2962, 76.5575, 94.5315, 126.7811, 180.6934, 285.0616, 637.7975],
  [69.4471, 79.238, 98.2678, 132.6038, 189.9911, 300.719, 674.3265],
  [71.5988, 81.9185, 102.0927, 138.5165, 199.2891, 316.3762, 710.8452],
  [73.7491, 84.5992, 105.9254, 144.3097, 208.4604, 331.6545, 746.9748],
  [75.9005, 87.2803, 109.759, 150.226, 217.5445, 347.0909, 783.4636],
  [36.2093, 36.8167, 37.9044, 39.7278, 42.9001, 51.7083, 93.716],
  [38.0855, 39.3205, 41.4229, 45.1781, 51.6964, 66.9199, 129.7766],
  [39.9783, 41.8325, 45.0206, 50.7064, 60.5176, 82.2191, 166.1731],
  [41.8764, 44.3493, 48.6282, 56.2056, 69.3651, 97.5332, 202.2329],
  [43.7765, 46.8708, 52.243, 61.7668, 78.2691, 112.9452, 238.7363],
  [45.638, 49.3555, 55.8624, 67.3294, 87.0984, 128.2698, 275.1678],
  [47.5408, 51.8826, 59.4863, 72.8977, 96.0151, 143.5354, 310.8881],
  [49.4465, 54.4148, 63.1142, 78.4703, 104.9388, 158.9795, 347.5582],
  [51.5252, 57.0266, 66.8592, 84.2644, 114.0772, 174.5545, 384.0506],
  [53.6172, 59.6454, 70.6071, 90.0635, 123.22, 190.1277, 420.5188],
  [55.7111, 62.2148, 74.3053, 95.863, 132.362, 205.6978, 456.9749],
  [57.8055, 64.8361, 78.0555, 101.6637, 141.5042, 221.2661, 493.4226],
  [59.8998, 67.4621, 81.8108, 107.4644, 150.6462, 236.8325, 529.8638],
  [61.9924, 70.0872, 85.5679, 113.2693, 159.7911, 252.3972, 564.902],
  [64.1041, 72.7215, 89.3358, 119.096, 168.9573, 267.9764, 601.2736],
  [66.2189, 75.3595, 93.1054, 124.924, 178.1247, 283.5554, 637.7975],
  [68.3338, 77.9965, 96.8744, 130.752, 187.2919, 299.1335, 674.3265],
  [70.4493, 80.6349, 100.5567, 136.4927, 196.459, 314.7115, 710.8452],
  [72.5647, 83.2731, 104.325, 142.1931, 205.4996, 329.9104, 746.9748],
  [74.6802, 85.9109, 108.1001, 148.0246, 214.664, 345.4772, 783.4636],
  [35.8113, 36.4071, 37.4851, 39.2929, 42.6788, 51.7094, 93.716],
  [37.6573, 38.8773, 40.9584, 44.6769, 51.4341, 66.9199, 129.7766],
  [39.5233, 41.3562, 44.5118, 50.1422, 60.2183, 82.2191, 166.1731],
  [41.3931, 43.8402, 48.077, 55.5792, 69.0281, 97.5332, 202.2329],
  [43.2681, 46.329, 51.6483, 61.0791, 77.8955, 112.9452, 238.7363],
  [45.1448, 48.8225, 55.2262, 66.5819, 86.6891, 128.2698, 275.1678],
  [46.9847, 51.2804, 58.808, 72.0887, 95.5691, 143.5354, 310.8881],
  [48.8642, 53.7788, 62.3944, 77.6005, 104.4566, 158.9795, 347.5582],
  [50.9145, 56.3584, 66.0967, 83.3316, 113.5573, 174.5545, 384.0506],
  [52.9771, 58.9455, 69.8021, 89.0673, 122.6621, 190.1277, 420.5188],
  [55.0436, 61.4817, 73.4564, 94.804, 131.7667, 205.6978, 456.9749],
  [57.1108, 64.0713, 77.1644, 100.5415, 140.8711, 221.2661, 493.4226],
  [59.1783, 66.6658, 80.8777, 106.2797, 149.9756, 236.8325, 529.8638],
  [61.2461, 69.2612, 84.5916, 112.0186, 159.08, 252.3972, 564.902],
  [63.3309, 71.8648, 88.3181, 117.7824, 168.2084, 267.9764, 601.2736],
  [65.415, 74.4668, 91.9624, 123.4645, 177.3375, 283.5554, 637.7975],
  [67.5009, 77.0712, 95.7712, 129.3109, 186.4669, 299.1335, 674.3265],
  [69.589, 79.6772, 99.4142, 134.9917, 195.596, 314.7115, 710.8452],
  [71.677, 82.2833, 103.1352, 140.625, 204.5993, 329.9104, 746.9748],
  [73.7655, 84.8906, 106.868, 146.3921, 213.7256, 345.4772, 783.4636],
  [35.5202, 36.1046, 37.1746, 38.9723, 42.516, 51.7099, 93.716],
  [37.3387, 38.5492, 40.6137, 44.3052, 51.2405, 66.9199, 129.7766],
  [39.1854, 41.0029, 44.1335, 49.7231, 59.9961, 82.2191, 166.1731],
  [41.0376, 43.4625, 47.6653, 55.1132, 68.779, 97.5332, 202.2329],
  [42.8917, 45.9264, 51.2051, 60.5665, 77.618, 112.9452, 238.7363],
  [44.7087, 48.3565, 54.7505, 66.0249, 86.3854, 128.2698, 275.1678],
  [46.6063, 50.8678, 58.3013, 71.4859, 95.2379, 143.5354, 310.8881],
  [48.4306, 53.3067, 61.8557, 76.9517, 104.0987, 158.9795, 347.5582],
  [50.458, 55.8597, 65.5261, 82.6357, 113.1713, 174.5545, 384.0506],
  [52.5018, 58.4226, 69.1996, 88.3244, 122.2483, 190.1277, 420.5188],
  [54.5468, 60.9875, 72.8745, 94.0142, 131.3249, 205.6978, 456.9749],
  [56.5908, 63.5041, 76.5014, 99.7044, 140.4012, 221.2661, 493.4226],
  [58.6377, 66.071, 80.1788, 105.3957, 149.478, 236.8325, 529.8638],
  [60.6853, 68.6428, 83.8613, 111.0874, 158.5541, 252.3972, 564.902],
  [62.7497, 71.2222, 87.555, 116.8021, 167.6529, 267.9764, 601.2736],
  [64.8156, 73.8033, 91.1689, 122.4378, 176.7537, 283.5554, 637.7975],
  [66.8809, 76.3833, 94.8586, 128.1501, 185.8548, 299.1335, 674.3265],
  [68.9451, 78.9629, 98.5524, 133.8658, 194.9559, 314.7115, 710.8452],
  [71.0115, 81.5443, 102.2466, 139.4574, 203.9319, 329.9104, 746.9748],
  [73.0794, 84.127, 105.9446, 145.1741, 213.0289, 345.4772, 783.4636],
  [35.274, 35.88, 36.9452, 38.7357, 42.3957, 51.7102, 93.716],
  [37.1017, 38.3063, 40.3592, 44.0311, 51.0973, 66.9199, 129.7766],
  [38.9351, 40.7414, 43.8531, 49.4117, 59.8312, 82.2191, 166.1731],
  [40.7721, 43.1818, 47.3589, 54.7668, 68.5941, 97.5332, 202.2329],
  [42.612, 45.6275, 50.8751, 60.1857, 77.4122, 112.9452, 238.7363],
  [44.4535, 48.0769, 54.3966, 65.6107, 86.1599, 128.2698, 275.1678],
  [46.2597, 50.495, 57.9236, 71.038, 94.9922, 143.5354, 310.8881],
  [48.1022, 52.9498, 61.4546, 76.4699, 103.833, 158.9795, 347.5582],
  [50.1159, 55.4867, 65.1009, 82.1187, 112.885, 174.5545, 384.0506],
  [52.1444, 58.0319, 68.7504, 87.7725, 121.9414, 190.1277, 420.5188],
  [54.1744, 60.579, 72.4016, 93.4272, 130.9972, 205.6978, 456.9749],
  [56.2049, 63.0774, 76.0044, 99.0828, 140.053, 221.2661, 493.4226],
  [58.2353, 65.6265, 79.6581, 104.739, 149.1088, 236.8325, 529.8638],
  [60.2651, 68.1799, 83.3161, 110.3958, 158.1644, 252.3972, 564.902],
  [62.3138, 70.7422, 86.9869, 116.0743, 167.2409, 267.9764, 601.2736],
  [64.3642, 73.3049, 90.577, 121.6754, 176.3209, 283.5554, 637.7975],
  [66.4149, 75.8687, 94.2468, 127.3554, 185.4009, 299.1335, 674.3265],
  [68.4655, 78.4319, 97.9176, 133.0374, 194.4812, 314.7115, 710.8452],
  [70.5149, 80.9945, 101.5889, 138.5947, 203.4369, 329.9104, 746.9748],
  [72.5648, 83.557, 105.2601, 144.2723, 212.5125, 345.4772, 783.4636],
  [35.1078, 35.7123, 36.7751, 38.561, 42.3409, 51.7442, 93.716],
  [36.9254, 38.1262, 40.1712, 43.8281, 50.9914, 66.92, 129.7766],
  [38.7496, 40.5474, 43.6448, 49.1807, 59.7091, 82.2191, 166.1731],
  [40.5766, 42.9735, 47.132, 54.5105, 68.4571, 97.5332, 202.2329],
  [42.4038, 45.4051, 50.6297, 59.9031, 77.2596, 112.9452, 238.7363],
  [44.2343, 47.8412, 54.1337, 65.3034, 85.9928, 128.2698, 275.1678],
  [46.0301, 50.2443, 57.6427, 70.7061, 94.8103, 143.5354, 310.8881],
  [47.8629, 52.6881, 61.1567, 76.1134, 103.6366, 158.9795, 347.5582],
  [49.9046, 55.2527, 64.7846, 81.7355, 112.6733, 174.5545, 384.0506],
  [51.8807, 57.744, 68.4166, 87.3634, 121.7142, 190.1277, 420.5188],
  [53.8959, 60.2747, 72.0503, 92.9923, 130.7546, 205.6978, 456.9749],
  [55.9147, 62.7587, 75.6347, 98.6222, 139.7953, 221.2661, 493.4226],
  [57.9341, 65.2944, 79.2707, 104.2524, 148.8356, 236.8325, 529.8638],
  [59.9539, 67.8352, 82.9118, 109.8837, 157.8761, 252.3972, 564.902],
  [61.9893, 70.3842, 86.5649, 115.5363, 166.9374, 267.9764, 601.2736],
  [64.0261, 72.9339, 90.1381, 121.1107, 176.0008, 283.5554, 637.7975],
  [66.065, 75.4843, 93.7894, 126.7639, 185.0653, 299.1335, 674.3265],
  [68.1045, 78.0348, 97.4464, 132.4236, 194.1302, 314.7115, 710.8452],
  [70.1438, 80.5854, 101.1003, 137.9558, 203.0709, 329.9104, 746.9748],
  [72.1821, 83.1351, 104.7544, 143.6077, 212.1312, 345.4772, 783.4636],
  [34.984, 35.5875, 36.6487, 38.4325, 42.2713, 51.7395, 93.716],
  [36.7939, 37.992, 40.0315, 43.6779, 50.9131, 66.92, 129.7766],
  [38.6107, 40.4033, 43.4903, 49.0097, 59.6188, 82.2191, 166.1731],
  [40.4304, 42.8195, 46.9641, 54.3208, 68.3558, 97.5332, 202.2329],
  [42.2509, 45.24, 50.4475, 59.6938, 77.1468, 112.9452, 238.7363],
  [44.0714, 47.6659, 53.9385, 65.0757, 85.8692, 128.2698, 275.1678],
  [45.858, 50.058, 57.4344, 70.4603, 94.6761, 143.5354, 310.8881],
  [47.6835, 52.4917, 60.9355, 75.8495, 103.4915, 158.9795, 347.5582],
  [49.6736, 55.004, 64.5499, 81.452, 112.5169, 174.5545, 384.0506],
  [51.6777, 57.5238, 68.169, 87.0608, 121.5465, 190.1277, 420.5188],
  [53.6872, 60.0475, 71.7896, 92.6706, 130.5756, 205.6978, 456.9749],
  [55.6976, 62.5217, 75.3606, 98.2814, 139.605, 221.2661, 493.4226],
  [57.7083, 65.0477, 78.9836, 103.8926, 148.6341, 236.8325, 529.8638],
  [59.7194, 67.5786, 82.612, 109.5049, 157.6632, 252.3972, 564.902],
  [61.7472, 70.1173, 86.2516, 115.1384, 166.7133, 267.9764, 601.2736],
  [63.7757, 72.6577, 89.8126, 120.6931, 175.7646, 283.5554, 637.7975],
  [65.804, 75.1979, 93.4504, 126.3272, 184.8178, 299.1335, 674.3265],
  [67.8339, 77.7389, 97.0966, 131.9693, 193.8712, 314.7115, 710.8452],
  [69.8649, 80.2799, 100.738, 137.4833, 202.8008, 329.9104, 746.9748],
  [71.8955, 82.8212, 104.3795, 143.1162, 211.8498, 345.4772, 783.4636],
]

var hcb_rates = [
  [1.4521, 1.4467, 1.4412, 1.4314, 1.413, 1.3823, 1.3364],
  [2.3206, 2.315, 2.3063, 2.2907, 2.2619, 2.2136, 2.1416],
  [2.8953, 2.8883, 2.8774, 2.858, 2.822, 2.7618, 2.6721],
  [3.4795, 3.4711, 3.458, 3.4347, 3.3915, 3.3191, 3.2113],
  [4.3429, 4.3324, 4.3161, 4.287, 4.233, 4.1426, 4.008],
  [5.803, 5.789, 5.7672, 5.7284, 5.6562, 5.5354, 5.3557],
  [1.136, 1.1326, 1.129, 1.1225, 1.1105, 1.0907, 1.1975],
  [1.8159, 1.8123, 1.8067, 1.7965, 1.7776, 1.7465, 1.9135],
  [2.2656, 2.2612, 2.2542, 2.2414, 2.2178, 2.179, 2.3874],
  [2.7228, 2.7175, 2.709, 2.6937, 2.6654, 2.6187, 2.8691],
  [3.3984, 3.3917, 3.3812, 3.3621, 3.3267, 3.2685, 3.581],
  [4.541, 4.5321, 4.518, 4.4925, 4.4452, 4.3674, 4.785],
  [0.9632, 0.961, 0.9583, 0.9535, 0.9446, 0.9301, 1.1172],
  [1.5404, 1.5378, 1.5337, 1.526, 1.512, 1.4893, 1.7875],
  [1.9219, 1.9187, 1.9135, 1.904, 1.8865, 1.8581, 2.2301],
  [2.3097, 2.3059, 2.2996, 2.2882, 2.2671, 2.233, 2.6801],
  [2.8828, 2.878, 2.8702, 2.8559, 2.8297, 2.7871, 3.3451],
  [3.8521, 3.8457, 3.8352, 3.8161, 3.7811, 3.7242, 4.4699],
  [0.8556, 0.8541, 0.852, 0.8481, 0.8411, 0.8298, 1.0684],
  [1.3689, 1.3669, 1.3636, 1.3575, 1.3464, 1.3287, 1.7087],
  [1.712, 1.7095, 1.7053, 1.6977, 1.6839, 1.6617, 2.1338],
  [2.0527, 2.0497, 2.0447, 2.0355, 2.019, 1.9924, 2.5621],
  [2.568, 2.5642, 2.558, 2.5466, 2.5258, 2.4926, 3.2007],
  [3.4232, 3.4182, 3.4099, 3.3947, 3.367, 3.3227, 4.2729],
  [0.7847, 0.7832, 0.7815, 0.7782, 0.7725, 0.7633, 1.0359],
  [1.2551, 1.2535, 1.2507, 1.2456, 1.2366, 1.2222, 1.6565],
  [1.5697, 1.5677, 1.5642, 1.5578, 1.5465, 1.5286, 2.0686],
  [1.8821, 1.8796, 1.8755, 1.8678, 1.8542, 1.8327, 2.4838],
  [2.3546, 2.3515, 2.3463, 2.3368, 2.3197, 2.2928, 3.1028],
  [3.1387, 3.1346, 3.1277, 3.115, 3.0923, 3.0564, 4.1424],
  [0.7343, 0.7331, 0.7316, 0.7288, 0.724, 0.7165, 1.0131],
  [1.1746, 1.1733, 1.1709, 1.1666, 1.1591, 1.1473, 1.6198],
  [1.4691, 1.4673, 1.4644, 1.459, 1.4496, 1.4349, 2.0227],
  [1.7614, 1.7593, 1.7558, 1.7493, 1.738, 1.7204, 2.4288],
  [2.2036, 2.201, 2.1966, 2.1885, 2.1744, 2.1523, 3.034],
  [2.9375, 2.934, 2.9282, 2.9174, 2.8985, 2.8691, 4.0507],
  [0.6994, 0.6981, 0.6968, 0.6944, 0.6903, 0.7004, 1.0135],
  [1.1186, 1.1174, 1.1154, 1.1116, 1.1052, 1.1207, 1.6198],
  [1.3989, 1.3975, 1.3949, 1.3902, 1.3822, 1.4016, 2.0227],
  [1.6773, 1.6756, 1.6725, 1.6669, 1.6572, 1.6805, 2.4288],
  [2.0984, 2.0962, 2.0924, 2.0853, 2.0733, 2.1024, 3.034],
  [2.7972, 2.7943, 2.7892, 2.7798, 2.7637, 2.8026, 4.0507],
  [0.6729, 0.6718, 0.6707, 0.6686, 0.6651, 0.6886, 1.0141],
  [1.0763, 1.0753, 1.0735, 1.0703, 1.0648, 1.1009, 1.6198],
  [1.3461, 1.3448, 1.3426, 1.3385, 1.3317, 1.3768, 2.0227],
  [1.614, 1.6125, 1.6098, 1.6049, 1.5967, 1.6507, 2.4288],
  [2.0192, 2.0173, 2.0139, 2.0078, 1.9975, 2.0652, 3.034],
  [2.6916, 2.6891, 2.6846, 2.6765, 2.6628, 2.7529, 4.0507],
  [0.6527, 0.6517, 0.6507, 0.6489, 0.6459, 0.6787, 1.0137],
  [1.044, 1.0432, 1.0416, 1.0388, 1.0341, 1.0858, 1.6198],
  [1.3057, 1.3046, 1.3027, 1.2991, 1.2933, 1.3579, 2.0227],
  [1.5655, 1.5642, 1.5619, 1.5577, 1.5507, 1.6282, 2.4288],
  [1.9586, 1.9569, 1.954, 1.9487, 1.94, 2.0369, 3.034],
  [2.6108, 2.6086, 2.6048, 2.5977, 2.5861, 2.7153, 4.0507],
  [0.6371, 0.6362, 0.6353, 0.6338, 0.6313, 0.6718, 1.0139],
  [1.0192, 1.0184, 1.0171, 1.0147, 1.0108, 1.0743, 1.6198],
  [1.2747, 1.2737, 1.272, 1.269, 1.2641, 1.3436, 2.0227],
  [1.5283, 1.5272, 1.5251, 1.5215, 1.5157, 1.611, 2.4288],
  [1.912, 1.9106, 1.908, 1.9035, 1.8962, 2.0154, 3.034],
  [2.5488, 2.5469, 2.5435, 2.5374, 2.5276, 2.6866, 4.0507],
  [0.6253, 0.6245, 0.6237, 0.6224, 0.6203, 0.6661, 1.0136],
  [1.0004, 0.9998, 0.9986, 0.9965, 0.9932, 1.0658, 1.6198],
  [1.2512, 1.2504, 1.2489, 1.2463, 1.2422, 1.3329, 2.0227],
  [1.5002, 1.4992, 1.4974, 1.4943, 1.4894, 1.5981, 2.4288],
  [1.8768, 1.8755, 1.8733, 1.8694, 1.8633, 1.9994, 3.034],
  [2.5018, 2.5002, 2.4972, 2.492, 2.4839, 2.6652, 4.0507],
  [0.6164, 0.6156, 0.6149, 0.6138, 0.6163, 0.6663, 1.0136],
  [0.9862, 0.9856, 0.9846, 0.9829, 0.9865, 1.0658, 1.6198],
  [1.2334, 1.2327, 1.2314, 1.2292, 1.2338, 1.3329, 2.0227],
  [1.4788, 1.478, 1.4765, 1.4738, 1.4793, 1.5981, 2.4288],
  [1.8501, 1.849, 1.8471, 1.8438, 1.8507, 1.9994, 3.034],
  [2.4663, 2.4648, 2.4623, 2.4579, 2.467, 2.6652, 4.0507],
  [0.6096, 0.6088, 0.6083, 0.6073, 0.6132, 0.6664, 1.0136],
  [0.9754, 0.9749, 0.974, 0.9725, 0.9815, 1.0658, 1.6198],
  [1.2199, 1.2193, 1.2182, 1.2163, 1.2275, 1.3329, 2.0227],
  [1.4627, 1.4619, 1.4606, 1.4584, 1.4717, 1.5981, 2.4288],
  [1.8299, 1.8289, 1.8273, 1.8245, 1.8412, 1.9994, 3.034],
  [2.4393, 2.438, 2.4358, 2.4321, 2.4544, 2.6652, 4.0507],
  [0.6045, 0.6037, 0.6032, 0.6024, 0.6108, 0.6664, 1.0136],
  [0.9672, 0.9668, 0.966, 0.9648, 0.9777, 1.0658, 1.6198],
  [1.2096, 1.2091, 1.2081, 1.2066, 1.2227, 1.3329, 2.0227],
  [1.4503, 1.4497, 1.4486, 1.4467, 1.466, 1.5981, 2.4288],
  [1.8144, 1.8136, 1.8122, 1.8099, 1.834, 1.9994, 3.034],
  [2.4187, 2.4177, 2.4158, 2.4126, 2.4448, 2.6652, 4.0507],
  [0.6006, 0.5998, 0.5994, 0.5988, 0.6091, 0.6664, 1.0136],
  [0.961, 0.9606, 0.9599, 0.9589, 0.9748, 1.0658, 1.6198],
  [1.2018, 1.2014, 1.2005, 1.1992, 1.2191, 1.3329, 2.0227],
  [1.441, 1.4404, 1.4394, 1.4379, 1.4617, 1.5981, 2.4288],
  [1.8027, 1.802, 1.8008, 1.7989, 1.8287, 1.9994, 3.034],
  [2.4031, 2.4022, 2.4005, 2.398, 2.4377, 2.6652, 4.0507],
  [0.5976, 0.5969, 0.5965, 0.596, 0.6077, 0.6665, 1.0136],
  [0.9562, 0.9559, 0.9553, 0.9545, 0.9726, 1.0658, 1.6198],
  [1.1959, 1.1955, 1.1948, 1.1937, 1.2164, 1.3329, 2.0227],
  [1.4338, 1.4333, 1.4325, 1.4313, 1.4585, 1.5981, 2.4288],
  [1.7938, 1.7932, 1.7922, 1.7906, 1.8246, 1.9994, 3.034],
  [2.3912, 2.3904, 2.389, 2.3869, 2.4323, 2.6652, 4.0507],
]

var main_maternity_rates = [
  [0.6085, 0.8313, 1.0673, 1.1793, 0.9899, 0.6215, 0.3451],
  [1.217, 1.6625, 2.1277, 2.3527, 1.9786, 1.2421, 0.6888],
  [1.826, 2.4946, 3.1925, 3.53, 2.9687, 1.8637, 1.0334],
  [2.4344, 3.3256, 4.2561, 4.706, 3.9578, 2.4846, 1.3777],
  [3.0364, 4.1481, 5.3087, 5.8699, 4.9366, 3.0991, 1.7185],
  [3.649, 4.985, 6.3798, 7.0542, 5.9326, 3.7244, 2.0652],
  [4.2599, 5.8195, 7.4477, 8.235, 6.9257, 4.3478, 2.4109],
  [4.8536, 6.6305, 8.4857, 9.3827, 7.8909, 4.9537, 2.7469],
  [5.4692, 7.4716, 9.562, 10.5728, 8.8918, 5.5821, 3.0953],
  [6.0823, 8.3091, 10.6339, 11.758, 9.8885, 6.2078, 3.4423],
  [6.6921, 9.1422, 11.7001, 12.9369, 10.88, 6.8303, 3.7874],
  [7.3017, 9.9749, 12.7658, 14.1152, 11.871, 7.4524, 4.1324],
  [7.911, 10.8073, 13.8311, 15.2932, 12.8617, 8.0743, 4.4773],
  [8.5202, 11.6396, 14.8962, 16.4709, 13.8521, 8.6961, 4.822],
  [9.0962, 12.4264, 15.9032, 17.5843, 14.7885, 9.2839, 5.1552],
  [9.7036, 13.2562, 16.9651, 18.7585, 15.776, 9.9039, 5.4926],
  [10.3147, 14.091, 18.0336, 19.9399, 16.7696, 10.5276, 5.8377],
  [10.926, 14.9261, 19.1023, 21.1216, 17.7634, 11.1515, 6.1836],
  [11.537, 15.7608, 20.1706, 22.3027, 18.7568, 11.7751, 6.5294],
  [12.1477, 16.5952, 21.2384, 23.4835, 19.7497, 12.3985, 6.8751],
  [0.4929, 0.6712, 0.8541, 0.932, 0.7734, 0.4824, 0.2975],
  [0.9852, 1.3404, 1.7059, 1.8616, 1.5449, 0.964, 0.5939],
  [1.4782, 2.0111, 2.5595, 2.7931, 2.318, 1.4464, 0.8911],
  [1.9707, 2.6811, 3.4122, 3.7237, 3.0903, 1.9283, 1.188],
  [2.4581, 3.3442, 4.2561, 4.6446, 3.8546, 2.4052, 1.4819],
  [2.954, 4.019, 5.1149, 5.5817, 4.6323, 2.8905, 1.7808],
  [3.4485, 4.6917, 5.9711, 6.5161, 5.4077, 3.3744, 2.0789],
  [3.9421, 5.3632, 6.8257, 7.4486, 6.1817, 3.8573, 2.373],
  [4.4292, 6.0259, 7.6691, 8.369, 6.9455, 4.334, 2.6697],
  [4.9238, 6.6989, 8.5256, 9.3037, 7.7212, 4.818, 2.9683],
  [5.4175, 7.3706, 9.3804, 10.2365, 8.4953, 5.301, 3.2659],
  [5.9109, 8.0419, 10.2347, 11.1688, 9.2691, 5.7838, 3.5634],
  [6.4042, 8.713, 11.0888, 12.1009, 10.0426, 6.2665, 3.8608],
  [6.8974, 9.384, 11.9428, 13.0328, 10.816, 6.7491, 4.1581],
  [7.3905, 10.0548, 12.7966, 13.9645, 11.5892, 7.2316, 4.4554],
  [7.8835, 10.7256, 13.6503, 14.8961, 12.3624, 7.714, 4.7459],
  [8.3533, 11.3647, 14.4636, 15.7836, 13.0989, 8.1736, 5.035],
  [8.8453, 12.0341, 15.3155, 16.7133, 13.8705, 8.6551, 5.3323],
  [9.3396, 12.7066, 16.1714, 17.6474, 14.6456, 9.1388, 5.6304],
  [9.834, 13.3792, 17.0275, 18.5815, 15.4209, 9.6226, 5.9284],
  [0.4289, 0.5822, 0.736, 0.7933, 0.6516, 0.4056, 0.2715],
  [0.8569, 1.1622, 1.4701, 1.586, 1.303, 0.8108, 0.5425],
  [1.2857, 1.7438, 2.2058, 2.3797, 1.9551, 1.2165, 0.814],
  [1.714, 2.3247, 2.9406, 3.1725, 2.6064, 1.6218, 1.0851],
  [2.1379, 2.8997, 3.6679, 3.9571, 3.251, 2.0229, 1.3535],
  [2.5693, 3.4847, 4.4079, 4.7555, 3.9069, 2.4311, 1.6266],
  [2.9994, 4.068, 5.1458, 5.5516, 4.5609, 2.838, 1.8989],
  [3.4287, 4.6503, 5.8822, 6.3461, 5.2137, 3.2442, 2.1672],
  [3.8523, 5.2249, 6.6091, 7.1303, 5.8579, 3.6451, 2.4384],
  [4.2826, 5.8084, 7.3472, 7.9266, 6.5121, 4.0522, 2.7112],
  [4.7119, 6.3907, 8.0838, 8.7213, 7.165, 4.4584, 2.983],
  [5.1411, 6.9728, 8.8201, 9.5156, 7.8176, 4.8645, 3.2547],
  [5.5701, 7.5547, 9.5562, 10.3097, 8.47, 5.2705, 3.5263],
  [5.9991, 8.1365, 10.2921, 11.1037, 9.1223, 5.6763, 3.7979],
  [6.428, 8.7182, 11.0278, 11.8975, 9.7744, 6.0821, 4.0694],
  [6.8568, 9.2998, 11.7635, 12.6912, 10.4265, 6.4879, 4.3343],
  [7.2856, 9.8814, 12.4992, 13.4849, 11.0786, 6.8936, 4.6054],
  [7.6954, 10.4372, 13.2023, 14.2434, 11.7017, 7.2876, 4.8773],
  [8.1234, 11.0177, 13.9366, 15.0356, 12.3526, 7.687, 5.1433],
  [8.5532, 11.6007, 14.674, 15.8312, 13.0062, 8.0931, 5.4149],
  [0.3886, 0.5261, 0.6608, 0.7054, 0.5751, 0.3578, 0.2557],
  [0.7758, 1.0496, 1.3197, 1.4103, 1.1503, 0.7154, 0.5108],
  [1.1641, 1.5749, 1.9801, 2.1161, 1.726, 1.0733, 0.7664],
  [1.5519, 2.0996, 2.6398, 2.8211, 2.301, 1.4309, 1.0217],
  [1.9403, 2.625, 3.3005, 3.5272, 2.8769, 1.7891, 1.2759],
  [2.3264, 3.1474, 3.9573, 4.2291, 3.4493, 2.1451, 1.5316],
  [2.7156, 3.674, 4.6194, 4.9367, 4.0265, 2.504, 1.7879],
  [3.1043, 4.1999, 5.2806, 5.6432, 4.6028, 2.8624, 2.0403],
  [3.4879, 4.7188, 5.9331, 6.3405, 5.1715, 3.216, 2.2959],
  [3.8774, 5.2458, 6.5957, 7.0487, 5.7491, 3.5752, 2.5528],
  [4.2662, 5.7718, 7.257, 7.7554, 6.3255, 3.9337, 2.8087],
  [4.6547, 6.2974, 7.918, 8.4617, 6.9016, 4.2919, 3.0645],
  [5.0432, 6.823, 8.5787, 9.1679, 7.4776, 4.6501, 3.3203],
  [5.4315, 7.3484, 9.2394, 9.8739, 8.0534, 5.0082, 3.576],
  [5.8198, 7.8738, 9.8999, 10.5798, 8.6291, 5.3662, 3.8316],
  [6.2081, 8.399, 10.5603, 11.2856, 9.2048, 5.7242, 4.0806],
  [6.5963, 8.9243, 11.2207, 11.9913, 9.7804, 6.0822, 4.3359],
  [6.9845, 9.4495, 11.8811, 12.697, 10.356, 6.4402, 4.5919],
  [7.3566, 9.9529, 12.514, 13.3734, 10.9077, 6.7884, 4.8479],
  [7.7442, 10.4773, 13.1734, 14.078, 11.4824, 7.1411, 5.099],
  [0.3615, 0.4878, 0.6097, 0.6464, 0.5239, 0.3261, 0.2453],
  [0.7219, 0.9744, 1.2181, 1.2915, 1.0478, 0.6521, 0.49],
  [1.0831, 1.462, 1.8277, 1.9378, 1.5722, 0.9784, 0.7351],
  [1.444, 1.9491, 2.4365, 2.5833, 2.096, 1.3044, 0.9801],
  [1.8054, 2.4369, 3.0464, 3.2299, 2.6206, 1.6308, 1.2238],
  [2.1647, 2.9219, 3.6526, 3.8726, 3.142, 1.9553, 1.4691],
  [2.5269, 3.4108, 4.2637, 4.5206, 3.6678, 2.2825, 1.715],
  [2.8885, 3.8989, 4.874, 5.1675, 4.1927, 2.6092, 1.957],
  [3.2454, 4.3807, 5.4762, 5.8061, 4.7108, 2.9316, 2.2022],
  [3.6079, 4.8699, 6.0878, 6.4545, 5.2369, 3.259, 2.4487],
  [3.9696, 5.3582, 6.6982, 7.1016, 5.7619, 3.5857, 2.6942],
  [4.3311, 5.8462, 7.3082, 7.7484, 6.2867, 3.9123, 2.9396],
  [4.6926, 6.3341, 7.9181, 8.3951, 6.8114, 4.2388, 3.1849],
  [5.054, 6.8219, 8.5279, 9.0416, 7.3359, 4.5652, 3.4302],
  [5.4153, 7.3096, 9.1375, 9.688, 7.8603, 4.8916, 3.6754],
  [5.7765, 7.7972, 9.7471, 10.3343, 8.3847, 5.2179, 3.9139],
  [6.1378, 8.2848, 10.3567, 10.9805, 8.9091, 5.5442, 4.1588],
  [6.499, 8.7724, 10.9662, 11.6267, 9.4334, 5.8705, 4.4044],
  [6.8602, 9.2599, 11.5756, 12.2729, 9.9576, 6.1968, 4.65],
  [7.2073, 9.7284, 12.1613, 12.8939, 10.4614, 6.5103, 4.8911],
  [0.3421, 0.4606, 0.5727, 0.6039, 0.488, 0.3038, 0.2384],
  [0.6836, 0.9206, 1.1448, 1.206, 0.9749, 0.6076, 0.4754],
  [1.0256, 1.3813, 1.7176, 1.8096, 1.4628, 0.9117, 0.7133],
  [1.3673, 1.8414, 2.2899, 2.4124, 1.9502, 1.2155, 0.951],
  [1.7095, 2.3023, 2.863, 3.0162, 2.4383, 1.5197, 1.1874],
  [2.0497, 2.7605, 3.4327, 3.6164, 2.9235, 1.8221, 1.4256],
  [2.3927, 3.2223, 4.0071, 4.2215, 3.4126, 2.1269, 1.6641],
  [2.7351, 3.6835, 4.5806, 4.8257, 3.9011, 2.4314, 1.8989],
  [3.0731, 4.1387, 5.1465, 5.422, 4.3831, 2.7318, 2.1369],
  [3.4163, 4.6009, 5.7213, 6.0275, 4.8726, 3.0369, 2.3761],
  [3.7588, 5.0622, 6.2949, 6.6319, 5.3611, 3.3414, 2.6143],
  [4.1012, 5.5232, 6.8683, 7.2359, 5.8494, 3.6457, 2.8524],
  [4.4434, 5.9842, 7.4415, 7.8397, 6.3376, 3.9499, 3.0905],
  [4.7856, 6.445, 8.0145, 8.4435, 6.8256, 4.2541, 3.3285],
  [5.1277, 6.9058, 8.5875, 9.0471, 7.3136, 4.5582, 3.5664],
  [5.4698, 7.3665, 9.1604, 9.6507, 7.8015, 4.8623, 3.7977],
  [5.8119, 7.8271, 9.7332, 10.2542, 8.2894, 5.1664, 4.0353],
  [6.1539, 8.2878, 10.306, 10.8576, 8.7772, 5.4704, 4.2736],
  [6.4959, 8.7484, 10.8788, 11.4611, 9.265, 5.7745, 4.5119],
  [6.8379, 9.209, 11.4516, 12.0645, 9.7528, 6.0785, 4.7502],
  [0.3288, 0.4418, 0.5468, 0.5735, 0.4621, 0.2933, 0.2383],
  [0.6569, 0.8827, 1.0927, 1.1457, 0.9239, 0.5868, 0.4754],
  [0.9857, 1.3245, 1.6396, 1.719, 1.3863, 0.8805, 0.7133],
  [1.3141, 1.7658, 2.1858, 2.2917, 1.8481, 1.1738, 0.951],
  [1.643, 2.2077, 2.7328, 2.8653, 2.3107, 1.4676, 1.1874],
  [1.9699, 2.647, 3.2767, 3.4355, 2.7705, 1.7597, 1.4256],
  [2.2995, 3.0899, 3.8249, 4.0103, 3.234, 2.0541, 1.6641],
  [2.6287, 3.5322, 4.3724, 4.5843, 3.6969, 2.3481, 1.8989],
  [2.9535, 3.9686, 4.9126, 5.1508, 4.1537, 2.6382, 2.1369],
  [3.2833, 4.4118, 5.4613, 5.726, 4.6176, 2.9329, 2.3761],
  [3.6125, 4.8542, 6.0088, 6.3001, 5.0805, 3.2269, 2.6143],
  [3.9415, 5.2963, 6.5561, 6.8739, 5.5433, 3.5208, 2.8524],
  [4.2705, 5.7383, 7.1032, 7.4475, 6.0059, 3.8147, 3.0905],
  [4.5993, 6.1802, 7.6502, 8.0211, 6.4684, 4.1084, 3.3285],
  [4.9281, 6.622, 8.1971, 8.5945, 6.9308, 4.4021, 3.5664],
  [5.2569, 7.0638, 8.744, 9.1679, 7.3932, 4.6958, 3.7977],
  [5.5856, 7.5055, 9.2908, 9.7412, 7.8555, 4.9895, 4.0353],
  [5.9144, 7.9472, 9.8376, 10.3145, 8.3178, 5.2831, 4.2736],
  [6.2431, 8.3889, 10.3843, 10.8877, 8.7801, 5.5767, 4.5119],
  [6.5717, 8.8305, 10.9311, 11.4609, 9.2424, 5.8703, 4.7502],
  [0.3184, 0.4269, 0.5268, 0.5507, 0.4432, 0.2859, 0.2385],
  [0.6363, 0.8533, 1.0526, 1.1002, 0.8863, 0.5717, 0.4754],
  [0.9547, 1.2803, 1.5794, 1.6508, 1.3298, 0.8578, 0.7133],
  [1.2727, 1.7068, 2.1055, 2.2008, 1.7728, 1.1435, 0.951],
  [1.5913, 2.134, 2.6325, 2.7516, 2.2165, 1.4298, 1.1874],
  [1.9079, 2.5586, 3.1564, 3.2991, 2.6575, 1.7143, 1.4256],
  [2.2271, 2.9867, 3.6845, 3.8511, 3.1022, 2.0011, 1.6641],
  [2.5459, 3.4142, 4.2118, 4.4023, 3.5462, 2.2875, 1.8989],
  [2.8605, 3.8361, 4.7322, 4.9463, 3.9844, 2.5702, 2.1369],
  [3.1799, 4.2645, 5.2607, 5.4987, 4.4294, 2.8572, 2.3761],
  [3.4987, 4.692, 5.7882, 6.05, 4.8734, 3.1437, 2.6143],
  [3.8174, 5.1194, 6.3154, 6.601, 5.3173, 3.43, 2.8524],
  [4.136, 5.5466, 6.8424, 7.1519, 5.7611, 3.7162, 3.0905],
  [4.4545, 5.9737, 7.3693, 7.7026, 6.2047, 4.0024, 3.3285],
  [4.7729, 6.4008, 7.8962, 8.2533, 6.6483, 4.2885, 3.5664],
  [5.0914, 6.8278, 8.423, 8.8039, 7.0918, 4.5746, 3.7977],
  [5.4098, 7.2548, 8.9497, 9.3545, 7.5353, 4.8607, 4.0353],
  [5.7281, 7.6818, 9.4764, 9.905, 7.9788, 5.1468, 4.2736],
  [6.0465, 8.1087, 10.003, 10.4555, 8.4222, 5.4328, 4.5119],
  [6.3648, 8.5356, 10.5297, 11.0059, 8.8656, 5.7189, 4.7502],
  [0.31, 0.4151, 0.5109, 0.5331, 0.4291, 0.2803, 0.2384],
  [0.6198, 0.8299, 1.0213, 1.0656, 0.8581, 0.5605, 0.4754],
  [0.93, 1.2452, 1.5325, 1.5989, 1.2876, 0.841, 0.7133],
  [1.2399, 1.6601, 2.043, 2.1316, 1.7165, 1.1212, 0.951],
  [1.5502, 2.0756, 2.5543, 2.665, 2.1462, 1.4018, 1.1874],
  [1.8587, 2.4886, 3.0626, 3.1954, 2.5732, 1.6807, 1.4256],
  [2.1697, 2.905, 3.5751, 3.73, 3.0038, 1.9619, 1.6641],
  [2.4802, 3.3208, 4.0868, 4.2639, 3.4337, 2.2427, 1.8989],
  [2.7866, 3.7311, 4.5917, 4.7907, 3.858, 2.5198, 2.1369],
  [3.0979, 4.1478, 5.1045, 5.3258, 4.2888, 2.8013, 2.3761],
  [3.4085, 4.5636, 5.6163, 5.8597, 4.7188, 3.0821, 2.6143],
  [3.7189, 4.9793, 6.1279, 6.3934, 5.1486, 3.3628, 2.8524],
  [4.0293, 5.3948, 6.6392, 6.927, 5.5783, 3.6435, 3.0905],
  [4.3395, 5.8103, 7.1505, 7.4604, 6.0079, 3.924, 3.3285],
  [4.6498, 6.2257, 7.6617, 7.9938, 6.4374, 4.2046, 3.5664],
  [4.96, 6.641, 8.1729, 8.5271, 6.8668, 4.4851, 3.7977],
  [5.2702, 7.0563, 8.6839, 9.0603, 7.2963, 4.7655, 4.0353],
  [5.5803, 7.4716, 9.195, 9.5935, 7.7256, 5.046, 4.2736],
  [5.8904, 7.8868, 9.706, 10.1267, 8.155, 5.3264, 4.5119],
  [6.2006, 8.302, 10.217, 10.6599, 8.5844, 5.6069, 4.7502],
  [0.3036, 0.406, 0.4989, 0.5201, 0.4189, 0.2763, 0.2384],
  [0.6068, 0.8114, 0.997, 1.0393, 0.837, 0.5522, 0.4754],
  [0.9104, 1.2175, 1.496, 1.5593, 1.2559, 0.8285, 0.7133],
  [1.2138, 1.6231, 1.9944, 2.0788, 1.6743, 1.1045, 0.951],
  [1.5175, 2.0294, 2.4936, 2.5991, 2.0934, 1.3809, 1.1874],
  [1.8195, 2.4332, 2.9898, 3.1163, 2.51, 1.6557, 1.4256],
  [2.124, 2.8404, 3.49, 3.6378, 2.9299, 1.9327, 1.6641],
  [2.428, 3.2469, 3.9895, 4.1584, 3.3493, 2.2093, 1.8989],
  [2.728, 3.6481, 4.4825, 4.6722, 3.7631, 2.4823, 2.1369],
  [3.0326, 4.0555, 4.9831, 5.194, 4.1834, 2.7596, 2.3761],
  [3.3367, 4.4621, 5.4827, 5.7148, 4.6028, 3.0362, 2.6143],
  [3.6406, 4.8685, 5.982, 6.2353, 5.0221, 3.3128, 2.8524],
  [3.9444, 5.2748, 6.4813, 6.7557, 5.4412, 3.5892, 3.0905],
  [4.2481, 5.681, 6.9804, 7.2759, 5.8602, 3.8656, 3.3285],
  [4.5519, 6.0871, 7.4794, 7.7961, 6.2791, 4.142, 3.5664],
  [4.8555, 6.4932, 7.9784, 8.3162, 6.698, 4.4183, 3.7977],
  [5.1592, 6.8993, 8.4773, 8.8362, 7.1169, 4.6946, 4.0353],
  [5.4628, 7.3053, 8.9762, 9.3562, 7.5357, 4.9709, 4.2736],
  [5.7664, 7.7113, 9.4751, 9.8762, 7.9545, 5.2472, 4.5119],
  [6.07, 8.1173, 9.9739, 10.3962, 8.3733, 5.5234, 4.7502],
  [0.2989, 0.3991, 0.4898, 0.5104, 0.4114, 0.2735, 0.2384],
  [0.5968, 0.7973, 0.9785, 1.0194, 0.8222, 0.5469, 0.4754],
  [0.8955, 1.1963, 1.4682, 1.5295, 1.2338, 0.8206, 0.7133],
  [1.1938, 1.5949, 1.9573, 2.039, 1.6448, 1.094, 0.951],
  [1.4926, 1.994, 2.4472, 2.5494, 2.0565, 1.3679, 1.1874],
  [1.7897, 2.3908, 2.9342, 3.0567, 2.4655, 1.64, 1.4256],
  [2.0891, 2.7908, 3.4251, 3.5681, 2.8782, 1.9145, 1.6641],
  [2.3881, 3.1903, 3.9153, 4.0788, 3.2902, 2.1885, 1.8989],
  [2.6832, 3.5845, 4.3991, 4.5828, 3.6966, 2.4588, 2.1369],
  [2.9829, 3.9848, 4.8905, 5.0946, 4.1092, 2.7332, 2.3761],
  [3.2819, 4.3843, 5.3808, 5.6054, 4.5215, 3.0075, 2.6143],
  [3.5809, 4.7836, 5.8708, 6.1159, 4.9334, 3.2815, 2.8524],
  [3.8797, 5.1829, 6.3608, 6.6263, 5.3451, 3.5554, 3.0905],
  [4.1785, 5.582, 6.8506, 7.1366, 5.7568, 3.8292, 3.3285],
  [4.4772, 5.981, 7.3404, 7.6468, 6.1683, 4.103, 3.5664],
  [4.7759, 6.3801, 7.8301, 8.1569, 6.5798, 4.3767, 3.7977],
  [5.0745, 6.779, 8.3197, 8.667, 6.9913, 4.6504, 4.0353],
  [5.3732, 7.178, 8.8094, 9.1771, 7.4028, 4.9241, 4.2736],
  [5.6718, 7.5769, 9.299, 9.6871, 7.8142, 5.1978, 4.5119],
  [5.9704, 7.9758, 9.7885, 10.1971, 8.2256, 5.4714, 4.7502],
  [0.2948, 0.3934, 0.4827, 0.503, 0.408, 0.2736, 0.2384],
  [0.5893, 0.7865, 0.9644, 1.0043, 0.8151, 0.5469, 0.4754],
  [0.8841, 1.1801, 1.447, 1.5069, 1.223, 0.8206, 0.7133],
  [1.1787, 1.5732, 1.9291, 2.009, 1.6304, 1.094, 0.951],
  [1.4737, 1.9669, 2.4119, 2.5118, 2.0385, 1.3679, 1.1874],
  [1.7669, 2.3583, 2.8918, 3.0116, 2.444, 1.64, 1.4256],
  [2.0626, 2.7529, 3.3757, 3.5155, 2.853, 1.9145, 1.6641],
  [2.3578, 3.147, 3.8588, 4.0186, 3.2614, 2.1885, 1.8989],
  [2.6491, 3.5358, 4.3357, 4.5152, 3.6643, 2.4588, 2.1369],
  [2.945, 3.9307, 4.8199, 5.0195, 4.0732, 2.7332, 2.3761],
  [3.2402, 4.3248, 5.3031, 5.5227, 4.482, 3.0075, 2.6143],
  [3.5354, 4.7187, 5.7861, 6.0257, 4.8903, 3.2815, 2.8524],
  [3.8304, 5.1125, 6.269, 6.5286, 5.2984, 3.5554, 3.0905],
  [4.1254, 5.5062, 6.7518, 7.0313, 5.7064, 3.8292, 3.3285],
  [4.4203, 5.8998, 7.2344, 7.534, 6.1144, 4.103, 3.5664],
  [4.7152, 6.2934, 7.7171, 8.0366, 6.5223, 4.3767, 3.7977],
  [5.0101, 6.687, 8.1997, 8.5392, 6.9302, 4.6504, 4.0353],
  [5.3049, 7.0805, 8.6822, 9.0418, 7.338, 4.9241, 4.2736],
  [5.5997, 7.474, 9.1648, 9.5443, 7.7459, 5.1978, 4.5119],
  [5.8946, 7.8675, 9.6473, 10.0468, 8.1537, 5.4714, 4.7502],
  [0.292, 0.3894, 0.4773, 0.497, 0.405, 0.2736, 0.2384],
  [0.5834, 0.7782, 0.9536, 0.9931, 0.8097, 0.5469, 0.4754],
  [0.8754, 1.1676, 1.4308, 1.4901, 1.2151, 0.8206, 0.7133],
  [1.1671, 1.5566, 1.9075, 1.9865, 1.6198, 1.094, 0.951],
  [1.4592, 1.9461, 2.3849, 2.4838, 2.0254, 1.3679, 1.1874],
  [1.7495, 2.3334, 2.8595, 2.9777, 2.4279, 1.64, 1.4256],
  [2.0423, 2.7238, 3.338, 3.4761, 2.8345, 1.9145, 1.6641],
  [2.3346, 3.1137, 3.8157, 3.9738, 3.2404, 2.1885, 1.8989],
  [2.623, 3.4984, 4.2872, 4.4679, 3.6437, 2.4588, 2.1369],
  [2.916, 3.8891, 4.766, 4.9632, 4.0467, 2.7332, 2.3761],
  [3.2083, 4.279, 5.2439, 5.4608, 4.4528, 3.0075, 2.6143],
  [3.5006, 4.6688, 5.7215, 5.9582, 4.8585, 3.2815, 2.8524],
  [3.7927, 5.0584, 6.199, 6.4556, 5.2641, 3.5554, 3.0905],
  [4.0847, 5.4479, 6.6763, 6.9528, 5.6695, 3.8292, 3.3285],
  [4.3768, 5.8374, 7.1536, 7.4499, 6.0749, 4.103, 3.5664],
  [4.6688, 6.2268, 7.6309, 7.9469, 6.4803, 4.3767, 3.7977],
  [4.9607, 6.6162, 8.1081, 8.444, 6.8856, 4.6504, 4.0353],
  [5.2527, 7.0056, 8.5852, 8.9409, 7.2909, 4.9241, 4.2736],
  [5.5446, 7.395, 9.0624, 9.4379, 7.6961, 5.1978, 4.5119],
  [5.8365, 7.7843, 9.5395, 9.9348, 8.1013, 5.4714, 4.7502],
  [0.2898, 0.3861, 0.4731, 0.4932, 0.4036, 0.2736, 0.2384],
  [0.579, 0.7718, 0.9454, 0.9856, 0.8068, 0.5469, 0.4754],
  [0.8687, 1.158, 1.4185, 1.4789, 1.2107, 0.8206, 0.7133],
  [1.1582, 1.5438, 1.8911, 1.9716, 1.614, 1.094, 0.951],
  [1.448, 1.9301, 2.3644, 2.4651, 2.018, 1.3679, 1.1874],
  [1.7362, 2.3142, 2.8349, 2.9554, 2.4192, 1.64, 1.4256],
  [2.0267, 2.7014, 3.3092, 3.4501, 2.8243, 1.9145, 1.6641],
  [2.3167, 3.0881, 3.7828, 3.944, 3.2287, 2.1885, 1.8989],
  [2.603, 3.4696, 4.2503, 4.4311, 3.6273, 2.4588, 2.1369],
  [2.8937, 3.8571, 4.7249, 4.9261, 4.0322, 2.7332, 2.3761],
  [3.1838, 4.2438, 5.1987, 5.42, 4.4369, 3.0075, 2.6143],
  [3.4738, 4.6304, 5.6722, 5.9137, 4.8411, 3.2815, 2.8524],
  [3.7637, 5.0168, 6.1455, 6.4073, 5.2452, 3.5554, 3.0905],
  [4.0535, 5.4031, 6.6188, 6.9007, 5.6491, 3.8292, 3.3285],
  [4.3433, 5.7894, 7.0919, 7.3941, 6.053, 4.103, 3.5664],
  [4.6331, 6.1756, 7.5651, 7.8874, 6.4569, 4.3767, 3.7977],
  [4.9228, 6.5618, 8.0382, 8.3806, 6.8607, 4.6504, 4.0353],
  [5.2125, 6.948, 8.5112, 8.8738, 7.2645, 4.9241, 4.2736],
  [5.5022, 7.3341, 8.9842, 9.367, 7.6682, 5.1978, 4.5119],
  [5.7919, 7.7203, 9.4572, 9.8602, 8.072, 5.4714, 4.7502],
  [0.2879, 0.3836, 0.4698, 0.4903, 0.4025, 0.2736, 0.2384],
  [0.5756, 0.7668, 0.9391, 0.9801, 0.8047, 0.5469, 0.4754],
  [0.8636, 1.1506, 1.4091, 1.4707, 1.2076, 0.8206, 0.7133],
  [1.1513, 1.5339, 1.8785, 1.9606, 1.6098, 1.094, 0.951],
  [1.4394, 1.9178, 2.3487, 2.4513, 2.0128, 1.3679, 1.1874],
  [1.7259, 2.2994, 2.8161, 2.939, 2.4131, 1.64, 1.4256],
  [2.0147, 2.6842, 3.2873, 3.4308, 2.8171, 1.9145, 1.6641],
  [2.303, 3.0683, 3.7577, 3.9219, 3.2204, 2.1885, 1.8989],
  [2.5876, 3.4475, 4.2221, 4.4063, 3.6179, 2.4588, 2.1369],
  [2.8766, 3.8325, 4.6936, 4.8985, 4.0218, 2.7332, 2.3761],
  [3.165, 4.2167, 5.1642, 5.3897, 4.4255, 3.0075, 2.6143],
  [3.4532, 4.6008, 5.6345, 5.8806, 4.8286, 3.2815, 2.8524],
  [3.7414, 4.9848, 6.1047, 6.3714, 5.2316, 3.5554, 3.0905],
  [4.0295, 5.3686, 6.5748, 6.8621, 5.6345, 3.8292, 3.3285],
  [4.3176, 5.7524, 7.0449, 7.3527, 6.0374, 4.103, 3.5664],
  [4.6056, 6.1362, 7.5149, 7.8432, 6.4402, 4.3767, 3.7977],
  [4.8937, 6.5199, 7.9848, 8.3337, 6.843, 4.6504, 4.0353],
  [5.1817, 6.9036, 8.4547, 8.8242, 7.2457, 4.9241, 4.2736],
  [5.4696, 7.2873, 8.9246, 9.3146, 7.6484, 5.1978, 4.5119],
  [5.7576, 7.671, 9.3945, 9.805, 8.0511, 5.4714, 4.7502],
  [0.2866, 0.3817, 0.4674, 0.4882, 0.4018, 0.2736, 0.2384],
  [0.5729, 0.763, 0.9343, 0.976, 0.8032, 0.5469, 0.4754],
  [0.8596, 1.1449, 1.4019, 1.4645, 1.2053, 0.8206, 0.7133],
  [1.146, 1.5263, 1.869, 1.9524, 1.6068, 1.094, 0.951],
  [1.4328, 1.9083, 2.3367, 2.4411, 2.0091, 1.3679, 1.1874],
  [1.718, 2.2881, 2.8017, 2.9267, 2.4086, 1.64, 1.4256],
  [2.0054, 2.6709, 3.2705, 3.4165, 2.8118, 1.9145, 1.6641],
  [2.2924, 3.0532, 3.7386, 3.9055, 3.2144, 2.1885, 1.8989],
  [2.5757, 3.4304, 4.2006, 4.3879, 3.6112, 2.4588, 2.1369],
  [2.8633, 3.8136, 4.6697, 4.878, 4.0143, 2.7332, 2.3761],
  [3.1504, 4.1959, 5.1379, 5.3672, 4.4172, 3.0075, 2.6143],
  [3.4374, 4.5781, 5.6059, 5.856, 4.8196, 3.2815, 2.8524],
  [3.7242, 4.9601, 6.0737, 6.3448, 5.2219, 3.5554, 3.0905],
  [4.011, 5.3421, 6.5414, 6.8334, 5.6241, 3.8292, 3.3285],
  [4.2978, 5.724, 7.009, 7.322, 6.0262, 4.103, 3.5664],
  [4.5845, 6.1059, 7.4766, 7.8104, 6.4282, 4.3767, 3.7977],
  [4.8712, 6.4877, 7.9442, 8.2989, 6.8302, 4.6504, 4.0353],
  [5.1579, 6.8695, 8.4117, 8.7873, 7.2322, 4.9241, 4.2736],
  [5.4445, 7.2513, 8.8792, 9.2757, 7.6342, 5.1978, 4.5119],
  [5.7312, 7.6331, 9.3467, 9.7641, 8.0361, 5.4714, 4.7502],
]

var spouse_incap_rates = [
  [3.8276, 4.4642, 5.8697, 8.5856, 13.3532, 19.9918, 28.9677],
  [7.6482, 8.9214, 11.7272, 17.1559, 26.6807, 39.9401, 57.8164],
  [11.429, 13.3317, 17.5662, 25.7412, 40.0326, 59.9275, 86.7491],
  [15.2983, 17.8452, 23.4578, 34.3169, 53.3694, 79.8922, 115.6494],
  [19.0823, 22.2591, 29.2596, 42.8041, 66.5687, 99.6511, 144.2516],
  [22.9325, 26.7502, 35.1631, 51.4404, 79.9997, 119.7569, 173.3562],
  [26.7713, 31.2281, 41.0493, 60.0514, 93.3915, 139.8039, 202.3756],
  [30.5022, 35.5801, 46.77, 68.4203, 106.4067, 159.2873, 230.5791],
  [34.5651, 40.404, 53.5744, 78.7226, 121.7973, 181.2916, 261.3256],
  [38.6613, 45.288, 60.2706, 88.6662, 137.1609, 203.6511, 292.3331],
  [42.7179, 50.1204, 67.0227, 98.8069, 152.5678, 225.7965, 323.0407],
  [46.7713, 54.9468, 73.752, 108.9079, 167.9067, 247.8817, 353.7216],
  [50.8232, 59.7712, 80.4779, 119.0031, 183.2373, 269.9569, 384.3915],
  [54.8741, 64.5944, 87.2018, 129.0951, 198.5631, 292.026, 415.0538],
  [58.7157, 69.3282, 94.0083, 139.3329, 214.0798, 314.2954, 445.8803],
  [62.7755, 74.0352, 100.4391, 148.9975, 228.7396, 335.3628, 476.019],
  [66.8621, 78.8932, 107.2259, 159.205, 244.2113, 357.5704, 505.9366],
  [70.9498, 83.7678, 114.0555, 169.4786, 259.7808, 379.9122, 536.7626],
  [75.0357, 88.6419, 120.8868, 179.7549, 275.3541, 402.2591, 567.6801],
  [79.1197, 93.514, 127.7152, 190.0267, 290.9208, 424.5969, 598.5954],
  [3.0284, 3.5519, 4.695, 6.9169, 10.8231, 16.3374, 26.8418],
  [6.0532, 7.095, 9.3781, 13.8236, 21.6686, 32.6864, 53.6008],
  [9.0824, 10.6455, 14.0712, 20.7414, 32.5127, 49.0441, 80.4242],
  [12.1082, 14.1921, 18.759, 27.6514, 43.3442, 65.3831, 107.2175],
  [15.1028, 17.702, 23.3985, 34.49, 54.0641, 81.5536, 133.7343],
  [18.15, 21.2736, 28.1194, 41.4488, 64.9722, 98.008, 160.7169],
  [21.1882, 24.8348, 32.8265, 48.3873, 75.8483, 114.4143, 187.6205],
  [24.2208, 28.3892, 37.5248, 55.3126, 86.704, 130.7896, 214.0225],
  [27.3553, 32.1244, 42.7934, 63.3213, 98.8048, 148.2918, 242.2018],
  [30.5702, 35.9696, 48.0841, 71.2296, 111.1084, 166.3589, 270.7764],
  [33.7657, 39.786, 53.4176, 79.278, 123.4493, 184.3031, 299.116],
  [36.9591, 43.5994, 58.7382, 87.3028, 135.751, 202.2127, 327.4344],
  [40.1513, 47.4115, 64.0563, 95.3233, 148.0467, 220.1147, 355.7428],
  [43.3428, 51.2226, 69.3728, 103.3415, 160.3388, 238.0119, 384.0444],
  [46.5496, 55.0585, 74.7495, 111.466, 172.7707, 256.057, 412.4859],
  [49.7562, 58.8943, 80.1262, 119.5908, 185.2028, 274.1017, 440.9257],
  [52.806, 62.6458, 85.5024, 127.7147, 197.6335, 292.1444, 468.4918],
  [56.0054, 66.3789, 90.6225, 135.447, 209.4723, 309.3449, 496.406],
  [59.2208, 70.215, 95.9872, 143.5532, 221.8766, 327.3512, 524.8727],
  [62.4367, 74.061, 101.3775, 151.6988, 234.3401, 345.4403, 553.3895],
  [2.5912, 3.0546, 4.0594, 6.0241, 9.4924, 14.3633, 25.6223],
  [5.1837, 6.1034, 8.1107, 12.0319, 18.9696, 28.7112, 51.2633],
  [7.7779, 9.1578, 12.1696, 18.053, 28.4627, 43.0794, 76.9177],
  [10.3691, 12.2087, 16.2239, 24.0674, 37.945, 57.4312, 102.5428],
  [12.9335, 15.2282, 20.2364, 30.0197, 47.3295, 71.635, 127.9035],
  [15.543, 18.3006, 24.3193, 36.0765, 56.8788, 86.0883, 153.7096],
  [18.1449, 21.3641, 28.3903, 42.1156, 66.4002, 100.4992, 179.4403],
  [20.7419, 24.4218, 32.4536, 48.1433, 75.9036, 114.883, 204.6715],
  [23.4191, 27.6231, 36.9765, 55.0484, 86.5657, 130.3269, 231.581],
  [26.1635, 30.9158, 41.5187, 61.8718, 97.1187, 145.9665, 258.8394],
  [28.8919, 34.1849, 46.0941, 68.8056, 107.8074, 161.6121, 285.8763],
  [31.6185, 37.4516, 50.6603, 75.7236, 118.4866, 177.2496, 312.8932],
  [34.3442, 40.7171, 55.2245, 82.6382, 129.1614, 192.8812, 339.9006],
  [37.0692, 43.9818, 59.7873, 89.5507, 139.8331, 208.5086, 366.9015],
  [39.8066, 47.2665, 64.3986, 96.5493, 150.6185, 224.2568, 394.0297],
  [42.5438, 50.5511, 69.0101, 103.5482, 161.4039, 240.0046, 421.1562],
  [45.2808, 53.8354, 73.621, 110.5462, 172.1882, 255.7507, 447.4092],
  [48.0175, 57.1194, 78.2315, 117.5436, 182.9715, 271.4955, 474.494],
  [50.6373, 60.2617, 82.6375, 124.2273, 193.2766, 286.5546, 501.2717],
  [53.3696, 63.5403, 87.2399, 131.212, 204.0409, 302.2728, 528.4329],
  [2.323, 2.747, 3.6691, 5.4766, 8.6747, 13.1373, 24.9212],
  [4.6436, 5.4896, 7.332, 10.9351, 17.3194, 26.2479, 49.8013],
  [6.9675, 8.2368, 11.0011, 16.4074, 25.9865, 39.3832, 74.7236],
  [9.2887, 10.9809, 14.6661, 21.8735, 34.6438, 52.5037, 99.6177],
  [11.6134, 13.7292, 18.3368, 27.348, 43.3145, 65.6443, 124.3472],
  [13.9244, 16.4612, 21.9857, 32.79, 51.9338, 78.707, 149.328],
  [16.2543, 19.2155, 25.6644, 38.2765, 60.6235, 91.8766, 174.3217],
  [18.5806, 21.9656, 29.3375, 43.7547, 69.3, 105.0261, 198.8203],
  [20.9747, 24.8377, 33.4054, 49.9894, 78.9798, 119.2472, 225.0945],
  [23.4279, 27.7901, 37.4907, 56.1535, 88.5625, 133.3357, 251.3836],
  [25.8672, 30.722, 41.6037, 62.4108, 98.2641, 147.5773, 277.6051],
  [28.3049, 33.6518, 45.7096, 68.6566, 107.9588, 161.8161, 303.812],
  [30.7418, 36.5805, 49.8137, 74.8995, 117.6495, 176.0496, 330.0097],
  [33.1781, 39.5085, 53.9166, 81.1405, 127.3375, 190.2794, 356.2012],
  [35.625, 42.4538, 58.0613, 87.4558, 137.1238, 204.6141, 382.5126],
  [38.0717, 45.3988, 62.206, 93.7712, 146.9102, 218.9484, 408.8222],
  [40.5182, 48.3436, 66.3502, 100.0859, 156.6955, 233.2811, 434.2583],
  [42.9645, 51.2881, 70.4941, 106.4, 166.48, 247.6126, 460.5265],
  [45.4107, 54.2325, 74.6377, 112.7137, 176.2638, 261.9433, 486.8827],
  [47.7588, 57.0578, 78.6095, 118.7628, 185.6419, 275.6893, 512.9039],
  [2.1454, 2.5462, 3.4172, 5.1204, 8.1434, 12.3404, 24.4602],
  [4.2882, 5.0886, 6.8289, 10.2316, 16.2677, 24.6565, 48.8461],
  [6.4342, 7.6351, 10.2464, 15.3518, 24.4085, 36.9954, 73.2901],
  [8.5778, 10.1787, 13.6599, 20.4662, 32.5402, 49.3204, 97.7066],
  [10.7246, 12.7262, 17.0787, 25.5885, 40.6844, 61.6644, 121.9578],
  [12.8587, 15.2587, 20.4773, 30.6805, 48.7802, 73.9351, 146.4631],
  [15.0103, 17.8118, 23.9036, 35.814, 56.9423, 86.3062, 170.9775],
  [17.1586, 20.361, 27.3247, 40.9398, 65.092, 98.6585, 194.9974],
  [19.3667, 23.0472, 31.128, 46.7452, 74.1465, 111.9796, 220.7444],
  [21.6287, 25.7499, 34.8909, 52.4871, 83.1118, 125.1768, 246.5112],
  [23.8781, 28.4611, 38.705, 58.3112, 92.1851, 138.5167, 272.2047],
  [26.1261, 31.1712, 42.5142, 64.1266, 101.2533, 151.854, 297.8836],
  [28.3733, 33.8804, 46.3217, 69.9394, 110.3178, 165.1865, 323.5536],
  [30.62, 36.5889, 50.1282, 75.7505, 119.3798, 178.5155, 349.2175],
  [32.8762, 39.3128, 53.9722, 81.6283, 128.5305, 191.9393, 374.9965],
  [35.1322, 42.0366, 57.8161, 87.5061, 137.6811, 205.3626, 400.7737],
  [37.3879, 44.7601, 61.6596, 93.3833, 146.8308, 218.7845, 425.6776],
  [39.6435, 47.4833, 65.5028, 99.26, 155.9796, 232.2054, 451.4134],
  [41.899, 50.2065, 69.3457, 105.1363, 165.1279, 245.6254, 477.2375],
  [44.1127, 52.9294, 73.1885, 111.0122, 174.2757, 259.0447, 503.064],
  [2.0209, 2.4066, 3.2439, 4.8787, 7.7864, 11.7963, 24.15],
  [4.0391, 4.8098, 6.4831, 9.752, 15.5595, 23.5703, 48.1857],
  [6.0604, 7.2169, 9.7275, 14.6322, 23.3459, 35.3657, 72.2989],
  [8.0795, 9.6211, 12.9682, 19.5069, 31.1236, 47.1477, 96.3852],
  [10.1016, 12.0291, 16.2138, 24.3892, 38.9133, 58.9479, 120.3057],
  [12.1117, 14.4228, 19.4403, 29.2424, 46.6567, 70.6781, 144.4822],
  [14.1383, 16.8361, 22.6931, 34.1354, 54.4635, 82.5042, 168.6651],
  [16.1618, 19.2457, 25.9409, 39.0209, 62.2584, 94.3123, 192.3541],
  [18.2594, 21.8007, 29.5411, 44.5341, 70.8923, 107.0204, 217.7373],
  [20.3686, 24.3322, 33.1036, 49.9885, 79.442, 119.6104, 243.1438],
  [22.4846, 26.8904, 36.7133, 55.5179, 88.0927, 132.3361, 268.4729],
  [24.5998, 29.4481, 40.3189, 61.0403, 96.7393, 145.0594, 293.7875],
  [26.7143, 32.005, 43.923, 66.5603, 105.3824, 157.7781, 319.0934],
  [28.8283, 34.5612, 47.5261, 72.0787, 114.0232, 170.4935, 344.3933],
  [30.951, 37.1316, 51.1637, 77.6587, 122.7462, 183.2969, 369.805],
  [33.0736, 39.7018, 54.8013, 83.2388, 131.4691, 196.0998, 395.2151],
  [35.1959, 42.2718, 58.4385, 88.8183, 140.1911, 208.9015, 419.7517],
  [37.3181, 44.8416, 62.0754, 94.3972, 148.9124, 221.702, 445.1204],
  [39.4402, 47.4112, 65.712, 99.9758, 157.633, 234.5018, 470.5773],
  [41.5622, 49.9807, 69.3485, 105.5541, 166.3532, 247.301, 496.0366],
  [1.9345, 2.3122, 3.1285, 4.7255, 7.5628, 11.6582, 24.1474],
  [3.8684, 4.6213, 6.2528, 9.4374, 15.1055, 23.3007, 48.1857],
  [5.8043, 6.934, 9.3819, 14.1601, 22.6648, 34.9611, 72.2989],
  [7.7379, 9.2441, 12.5075, 18.8775, 30.2156, 46.6084, 96.3852],
  [9.6746, 11.5577, 15.6379, 23.6022, 37.7779, 58.2736, 120.3057],
  [11.5998, 13.8576, 18.7497, 28.2989, 45.2955, 69.8696, 144.4822],
  [13.5407, 16.1763, 21.887, 33.0339, 52.8744, 81.5604, 168.6651],
  [15.4786, 18.4915, 25.0195, 37.7618, 60.4419, 93.2335, 192.3541],
  [17.4862, 20.9435, 28.4841, 43.0826, 68.805, 105.7829, 217.7373],
  [19.5046, 23.3731, 31.913, 48.3478, 77.0871, 118.2157, 243.1438],
  [21.5297, 25.8282, 35.3864, 53.683, 85.4657, 130.7825, 268.4729],
  [23.5539, 28.2828, 38.8562, 59.0126, 93.8408, 143.3467, 293.7875],
  [25.5775, 30.7366, 42.3246, 64.3398, 102.2126, 155.9065, 319.0934],
  [27.6007, 33.1899, 45.792, 69.6655, 110.5821, 168.463, 344.3933],
  [29.632, 35.6564, 49.2919, 75.0494, 119.0296, 181.1053, 369.805],
  [31.6632, 38.1228, 52.7919, 80.4334, 127.477, 193.747, 395.2151],
  [33.6942, 40.589, 56.2914, 85.8167, 135.9234, 206.3874, 419.7517],
  [35.725, 43.055, 59.7907, 91.1996, 144.3692, 219.0268, 445.1204],
  [37.7557, 45.5208, 63.2897, 96.5821, 152.8144, 231.6655, 470.5773],
  [39.7863, 47.9866, 66.7886, 101.9643, 161.2592, 244.3035, 496.0366],
  [1.8712, 2.2415, 3.0424, 4.6046, 7.3945, 11.562, 24.1473],
  [3.7401, 4.4801, 6.0808, 9.2041, 14.7784, 23.1096, 48.1857],
  [5.6118, 6.7221, 9.1238, 13.8102, 22.1741, 34.6744, 72.2989],
  [7.4814, 8.9615, 12.1634, 18.411, 29.5613, 46.2262, 96.3852],
  [9.3538, 11.2045, 15.2077, 23.019, 36.96, 57.7958, 120.3057],
  [11.2152, 13.4341, 18.2339, 27.5996, 44.3147, 69.2967, 144.4822],
  [13.0917, 15.6819, 21.2848, 32.2176, 51.7296, 80.8916, 168.6651],
  [14.9655, 17.9263, 24.3311, 36.8287, 59.1332, 92.4689, 192.3541],
  [16.9198, 20.3016, 27.6954, 42.0083, 67.3026, 104.9066, 217.7373],
  [18.8564, 22.655, 31.0252, 47.1345, 75.3934, 117.2289, 243.1438],
  [20.813, 25.0332, 34.3976, 52.3274, 83.5774, 129.6836, 268.4729],
  [22.7691, 27.411, 37.7668, 57.5154, 91.7584, 142.136, 293.7875],
  [24.7247, 29.788, 41.1346, 62.7012, 99.9362, 154.5838, 319.0934],
  [26.6797, 32.1643, 44.5014, 67.8855, 108.1118, 167.0285, 344.3933],
  [28.6426, 34.5534, 47.8995, 73.1256, 116.3624, 179.5574, 369.805],
  [30.6054, 36.9424, 51.2975, 78.3658, 124.613, 192.0857, 395.2151],
  [32.568, 39.3312, 54.6951, 83.6053, 132.8626, 204.6127, 419.7517],
  [34.5304, 41.7198, 58.0924, 88.8444, 141.1116, 217.1387, 445.1204],
  [36.4927, 44.1082, 61.4895, 94.0831, 149.3599, 229.6639, 470.5773],
  [38.4549, 46.4965, 64.8865, 99.3216, 157.6079, 242.1886, 496.0366],
  [1.8231, 2.1875, 2.9765, 4.5209, 7.2799, 11.4967, 24.1501],
  [3.6421, 4.3721, 5.9492, 9.0276, 14.5409, 22.9743, 48.1857],
  [5.4647, 6.5601, 8.9264, 13.5452, 21.8176, 34.4714, 72.2989],
  [7.2853, 8.7456, 11.9003, 18.0578, 29.0861, 45.9555, 96.3852],
  [9.1087, 10.9344, 14.8787, 22.5773, 36.3658, 57.4573, 120.3057],
  [10.9213, 13.1103, 17.8394, 27.0701, 43.6023, 68.8909, 144.4822],
  [12.7486, 15.3039, 20.8244, 31.5995, 50.898, 80.4179, 168.6651],
  [14.5732, 17.4943, 23.8048, 36.1221, 58.1825, 91.9274, 192.3541],
  [16.4758, 19.8111, 27.093, 41.196, 66.2125, 104.2866, 217.7373],
  [18.361, 22.1067, 30.3477, 46.2182, 74.1656, 116.5311, 243.1438],
  [20.2657, 24.4264, 33.6436, 51.3047, 82.2096, 128.9072, 268.4729],
  [22.17, 26.7456, 36.9365, 56.3868, 90.2509, 141.2809, 293.7875],
  [24.0736, 29.0641, 40.2281, 61.4667, 98.2891, 153.6502, 319.0934],
  [25.9768, 31.3819, 43.5188, 66.5452, 106.325, 166.0162, 344.3933],
  [27.8876, 33.7122, 46.8397, 71.6778, 114.4341, 178.4654, 369.805],
  [29.7983, 36.0423, 50.1605, 76.8105, 122.543, 190.9141, 395.2151],
  [31.7087, 38.3721, 53.481, 81.9425, 130.6511, 203.3614, 419.7517],
  [33.619, 40.7018, 56.8012, 87.0741, 138.7584, 215.8078, 445.1204],
  [35.5292, 43.0313, 60.1211, 92.2054, 146.8653, 228.2534, 470.5773],
  [37.4394, 45.3608, 63.4409, 97.3364, 154.9717, 240.6984, 496.0366],
  [1.7845, 2.1462, 2.9262, 4.4536, 7.1949, 11.448, 24.1475],
  [3.5672, 4.2896, 5.8488, 8.8951, 14.3736, 22.8828, 48.1857],
  [5.3523, 6.4363, 8.7758, 13.3464, 21.5666, 34.3342, 72.2989],
  [7.1355, 8.5805, 11.6994, 17.7928, 28.7515, 45.7726, 96.3852],
  [8.9213, 10.7281, 14.6276, 22.246, 35.9475, 57.2286, 120.3057],
  [10.6966, 12.8629, 17.5383, 26.6728, 43.1007, 68.6167, 144.4822],
  [12.4864, 15.0151, 20.4729, 31.1357, 50.3125, 80.0979, 168.6651],
  [14.2735, 17.1641, 23.403, 35.5919, 57.5133, 91.5616, 192.3541],
  [16.1365, 19.4365, 26.6335, 40.5874, 65.4457, 103.868, 217.7373],
  [17.9826, 21.688, 29.8314, 45.5322, 73.3025, 116.0602, 243.1438],
  [19.8476, 23.9631, 33.0694, 50.5396, 81.2486, 128.3835, 268.4729],
  [21.7124, 26.2378, 36.3046, 55.5431, 89.1922, 140.7043, 293.7875],
  [23.5765, 28.5117, 39.5385, 60.5444, 97.1328, 153.0207, 319.0934],
  [25.4401, 30.7851, 42.7715, 65.5443, 105.0711, 165.334, 344.3933],
  [27.3112, 33.0705, 46.034, 70.5972, 113.0812, 177.7297, 369.805],
  [29.1821, 35.3557, 49.2964, 75.65, 121.0912, 190.1248, 395.2151],
  [31.0528, 37.6408, 52.5585, 80.7023, 129.1004, 202.5187, 419.7517],
  [32.9234, 39.9256, 55.8203, 85.7541, 137.1088, 214.9116, 445.1204],
  [34.7939, 42.2103, 59.0818, 90.8056, 145.1167, 227.3037, 470.5773],
  [36.6643, 44.495, 62.3432, 95.8569, 153.1242, 239.6952, 496.0366],
  [1.7567, 2.1163, 2.8906, 4.408, 7.1445, 11.4164, 24.1405],
  [3.5121, 4.2299, 5.7777, 8.8055, 14.2749, 22.8339, 48.1856],
  [5.2698, 6.3467, 8.6691, 13.2121, 21.4185, 34.2609, 72.2989],
  [7.0254, 8.4611, 11.5573, 17.6137, 28.554, 45.6749, 96.3852],
  [8.7837, 10.5788, 14.4498, 22.022, 35.7006, 57.1065, 120.3057],
  [10.5316, 12.6839, 17.3252, 26.4043, 42.8047, 68.4702, 144.4822],
  [12.2938, 14.8062, 20.2241, 30.8223, 49.9669, 79.9269, 168.6651],
  [14.0533, 16.9253, 23.1187, 35.2336, 57.1182, 91.3661, 192.3541],
  [15.8872, 19.1654, 26.3083, 40.1758, 64.9924, 103.6438, 217.7373],
  [17.7046, 21.385, 29.4659, 45.0681, 72.7916, 115.8077, 243.1438],
  [19.5406, 23.6278, 32.6628, 50.0219, 80.6792, 128.1021, 268.4729],
  [21.3762, 25.8703, 35.8571, 54.9719, 88.5644, 140.3942, 293.7875],
  [23.2113, 28.112, 39.0501, 59.9199, 96.4466, 152.6819, 319.0934],
  [25.0459, 30.3531, 42.2423, 64.8665, 104.3266, 164.9665, 344.3933],
  [26.8878, 32.6061, 45.4633, 69.8651, 112.2775, 177.333, 369.805],
  [28.7295, 34.8589, 48.6844, 74.8637, 120.2283, 189.699, 395.2151],
  [30.5711, 37.1115, 51.905, 79.8618, 128.1782, 202.0638, 419.7517],
  [32.4125, 39.3639, 55.1254, 84.8595, 136.1274, 214.4275, 445.1204],
  [34.2538, 41.6162, 58.3456, 89.8568, 144.0762, 226.7905, 470.5773],
  [36.095, 43.8683, 61.5657, 94.8539, 152.0245, 239.1529, 496.0366],
  [1.7362, 2.0951, 2.8663, 4.3791, 7.1287, 11.416, 24.1405],
  [3.4722, 4.1876, 5.7291, 8.7491, 14.245, 22.8339, 48.1856],
  [5.2099, 6.2832, 8.5962, 13.1274, 21.3737, 34.2609, 72.2989],
  [6.9455, 8.3764, 11.46, 17.5007, 28.4943, 45.6749, 96.3852],
  [8.6838, 10.4729, 14.3283, 21.8809, 35.6259, 57.1065, 120.3057],
  [10.4119, 12.5569, 17.1795, 26.235, 42.7152, 68.4702, 144.4822],
  [12.154, 14.658, 20.054, 30.6247, 49.8625, 79.9269, 168.6651],
  [13.8935, 16.7558, 22.9242, 35.0078, 56.9989, 91.3661, 192.3541],
  [15.7064, 18.9731, 26.0858, 39.916, 64.8551, 103.6438, 217.7373],
  [17.5029, 21.17, 29.2156, 44.7747, 72.6366, 115.8077, 243.1438],
  [19.3178, 23.39, 32.3844, 49.6943, 80.506, 128.1021, 268.4729],
  [21.1323, 25.6095, 35.5506, 54.6102, 88.3733, 140.3942, 293.7875],
  [22.9463, 27.8283, 38.7155, 59.5241, 96.2374, 152.6819, 319.0934],
  [24.7599, 30.0465, 41.8796, 64.4367, 104.0995, 164.9665, 344.3933],
  [26.5806, 32.2764, 45.0722, 69.4007, 112.0321, 177.333, 369.805],
  [28.4011, 34.5062, 48.2648, 74.3647, 119.9646, 189.699, 395.2151],
  [30.2215, 36.7357, 51.457, 79.3282, 127.8962, 202.0638, 419.7517],
  [32.0417, 38.9651, 54.649, 84.2913, 135.8271, 214.4275, 445.1204],
  [33.8619, 41.1943, 57.8407, 89.254, 143.7576, 226.7905, 470.5773],
  [35.6819, 43.4235, 61.0323, 94.2165, 151.6876, 239.1529, 496.0366],
  [1.7228, 2.0804, 2.8503, 4.3627, 7.1206, 11.4159, 24.1405],
  [3.4436, 4.1582, 5.6973, 8.7173, 14.2302, 22.8339, 48.1856],
  [5.1669, 6.2391, 8.5484, 13.0797, 21.3514, 34.2609, 72.2989],
  [6.8883, 8.3176, 11.3963, 17.4372, 28.4646, 45.6749, 96.3852],
  [8.6123, 10.3994, 14.2486, 21.8015, 35.5888, 57.1065, 120.3057],
  [10.326, 12.4688, 17.084, 26.1398, 42.6707, 68.4702, 144.4822],
  [12.0538, 14.5551, 19.9425, 30.5136, 49.8105, 79.9269, 168.6651],
  [13.779, 16.6383, 22.7967, 34.8807, 56.9394, 91.3661, 192.3541],
  [15.5768, 18.8396, 25.9399, 39.7693, 64.7863, 103.6438, 217.7373],
  [17.3583, 21.0207, 29.0514, 44.6089, 72.5587, 115.8077, 243.1438],
  [19.158, 23.2248, 32.2015, 49.5086, 80.4188, 128.1021, 268.4729],
  [20.9575, 25.4284, 35.3492, 54.4049, 88.2767, 140.3942, 293.7875],
  [22.7564, 27.6313, 38.4956, 59.2992, 96.1315, 152.6819, 319.0934],
  [24.5548, 29.8336, 41.6411, 64.1921, 103.9843, 164.9665, 344.3933],
  [26.3603, 32.0474, 44.8149, 69.1361, 111.9074, 177.333, 369.805],
  [28.1656, 34.2612, 47.9887, 74.0802, 119.8304, 189.699, 395.2151],
  [29.9708, 36.4747, 51.1621, 79.0237, 127.7525, 202.0638, 419.7517],
  [31.7759, 38.688, 54.3353, 83.9668, 135.674, 214.4275, 445.1204],
  [33.5808, 40.9012, 57.5083, 88.9095, 143.5949, 226.7905, 470.5773],
  [35.3857, 43.1143, 60.6811, 93.852, 151.5155, 239.1529, 496.0366],
  [1.7128, 2.0705, 2.8406, 4.3557, 7.1183, 11.4159, 24.1405],
  [3.4235, 4.1385, 5.678, 8.7041, 14.2266, 22.8339, 48.1856],
  [5.1368, 6.2096, 8.5194, 13.0599, 21.346, 34.2609, 72.2989],
  [6.8481, 8.2783, 11.3576, 17.4108, 28.4574, 45.6749, 96.3852],
  [8.562, 10.3502, 14.2002, 21.7684, 35.5798, 57.1065, 120.3057],
  [10.2658, 12.4098, 17.026, 26.1002, 42.6599, 68.4702, 144.4822],
  [11.9835, 14.4862, 19.8748, 30.4673, 49.7979, 79.9269, 168.6651],
  [13.6986, 16.5595, 22.7193, 34.8279, 56.925, 91.3661, 192.3541],
  [15.4857, 18.7502, 25.8511, 39.7077, 64.7692, 103.6438, 217.7373],
  [17.2567, 20.9207, 28.9514, 44.5387, 72.5389, 115.8077, 243.1438],
  [19.0458, 23.114, 32.0901, 49.4295, 80.3961, 128.1021, 268.4729],
  [20.8346, 25.3069, 35.2263, 54.317, 88.2513, 140.3942, 293.7875],
  [22.6229, 27.4991, 38.3612, 59.2025, 96.1034, 152.6819, 319.0934],
  [24.4107, 29.6907, 41.4954, 64.0865, 103.9534, 164.9665, 344.3933],
  [26.2055, 31.8938, 44.6576, 69.0216, 111.8736, 177.333, 369.805],
  [28.0002, 34.0967, 47.8198, 73.9566, 119.7937, 189.699, 395.2151],
  [29.7947, 36.2994, 50.9817, 78.8911, 127.713, 202.0638, 419.7517],
  [31.589, 38.502, 54.1433, 83.8252, 135.6316, 214.4275, 445.1204],
  [33.3833, 40.7044, 57.3047, 88.7589, 143.5497, 226.7905, 470.5773],
  [35.1775, 42.9067, 60.4659, 93.6923, 151.4673, 239.1529, 496.0366],
  [1.7057, 2.0643, 2.8357, 4.3557, 7.1205, 11.4159, 24.1405],
  [3.4099, 4.1261, 5.668, 8.7049, 14.2315, 22.8339, 48.1856],
  [5.1163, 6.191, 8.5045, 13.0611, 21.3534, 34.2609, 72.2989],
  [6.8208, 8.2535, 11.3378, 17.4124, 28.4673, 45.6749, 96.3852],
  [8.5279, 10.3192, 14.1754, 21.7704, 35.5921, 57.1065, 120.3057],
  [10.2249, 12.3727, 16.9962, 26.1025, 42.6747, 68.4702, 144.4822],
  [11.9357, 14.4429, 19.84, 30.4701, 49.8152, 79.9269, 168.6651],
  [13.644, 16.51, 22.6796, 34.831, 56.9448, 91.3661, 192.3541],
  [15.4239, 18.6939, 25.8052, 39.7102, 64.7911, 103.6438, 217.7373],
  [17.1877, 20.8577, 28.8996, 44.5406, 72.563, 115.8077, 243.1438],
  [18.9696, 23.0443, 32.0322, 49.4307, 80.4224, 128.1021, 268.4729],
  [20.7512, 25.2304, 35.1624, 54.3176, 88.2797, 140.3942, 293.7875],
  [22.5322, 27.4158, 38.2913, 59.2024, 96.134, 152.6819, 319.0934],
  [24.3128, 29.6007, 41.4193, 64.0858, 103.9861, 164.9665, 344.3933],
  [26.1003, 31.7969, 44.5754, 69.0201, 111.9085, 177.333, 369.805],
  [27.8877, 33.993, 47.7315, 73.9544, 119.8307, 189.699, 395.2151],
  [29.675, 36.1889, 50.8872, 78.8882, 127.7522, 202.0638, 419.7517],
  [31.4621, 38.3846, 54.0427, 83.8215, 135.6729, 214.4275, 445.1204],
  [33.2491, 40.5802, 57.1979, 88.7545, 143.5931, 226.7905, 470.5773],
  [35.036, 42.7757, 60.353, 93.6872, 151.5129, 239.1529, 496.0366],
  [1.701, 2.0609, 2.8345, 4.3614, 7.126, 11.416, 24.1405],
  [3.4011, 4.1193, 5.6651, 8.7162, 14.2429, 22.8339, 48.1856],
  [5.1032, 6.1807, 8.5002, 13.0782, 21.3706, 34.2609, 72.2989],
  [6.8033, 8.2398, 11.332, 17.4351, 28.4901, 45.6749, 96.3852],
  [8.506, 10.3021, 14.1682, 21.7988, 35.6207, 57.1065, 120.3057],
  [10.1986, 12.3521, 16.9876, 26.1366, 42.7089, 68.4702, 144.4822],
  [11.9051, 14.4189, 19.83, 30.5099, 49.8551, 79.9269, 168.6651],
  [13.609, 16.4825, 22.6681, 34.8765, 56.9904, 91.3661, 192.3541],
  [15.3843, 18.6626, 25.7918, 39.7613, 64.8427, 103.6438, 217.7373],
  [17.1435, 20.8227, 28.8842, 44.5972, 72.6205, 115.8077, 243.1438],
  [18.9207, 23.0054, 32.0148, 49.4929, 80.4858, 128.1021, 268.4729],
  [20.6976, 25.1877, 35.1429, 54.3853, 88.349, 140.3942, 293.7875],
  [22.474, 27.3693, 38.2698, 59.2757, 96.2092, 152.6819, 319.0934],
  [24.2499, 29.5504, 41.3959, 64.1646, 104.0673, 164.9665, 344.3933],
  [26.0328, 31.7428, 44.5499, 69.1045, 111.9956, 177.333, 369.805],
  [27.8155, 33.935, 47.7039, 74.0443, 119.9238, 189.699, 395.2151],
  [29.5981, 36.1271, 50.8576, 78.9836, 127.8512, 202.0638, 419.7517],
  [31.3805, 38.319, 54.0109, 83.9225, 135.7779, 214.4275, 445.1204],
  [33.1628, 40.5107, 57.1641, 88.861, 143.7041, 226.7905, 470.5773],
  [34.945, 42.7023, 60.3171, 93.7992, 151.6298, 239.1529, 496.0366],
]

var babycare_rates = [
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
  [0.1949, 0.1603, 0.1341, 0.1171],
  [0.3894, 0.3203, 0.2678, 0.2339],
  [0.5843, 0.4806, 0.4019, 0.351],
  [0.779, 0.6407, 0.5358, 0.4679],
  [0.9716, 0.7992, 0.6683, 0.5837],
  [1.1677, 0.9604, 0.8031, 0.7014],
  [1.3631, 1.1212, 0.9376, 0.8189],
  [1.5531, 1.2793, 1.0717, 0.9361],
  [1.9536, 1.612, 1.3553, 1.1902],
  [2.3574, 1.9497, 1.6414, 1.4428],
  [2.7597, 2.2861, 1.9283, 1.6979],
  [3.1551, 2.6218, 2.2146, 1.9526],
  [3.556, 2.9524, 2.4961, 2.2029],
  [3.9596, 3.2894, 2.7837, 2.4589],
  [4.3812, 3.6422, 3.0849, 2.727],
  [4.8032, 3.9953, 3.3863, 2.9953],
  [5.2251, 4.3483, 3.6875, 3.2635],
  [5.6469, 4.7012, 3.9887, 3.5317],
  [6.0685, 5.054, 4.2899, 3.7998],
  [6.478, 5.4068, 4.591, 4.0678],
]

//MAIN BASE RATE
var base_rate = function (cover, age) {
  return main_incap_rates[cover - 1][age_index(age) - 1]
}
//HCB BASE RATE
var hcbbase_rate = function (hcb_cover, age) {
  return hcb_rates[hcb_cover - 1][age_index(age) - 1]
}
//BASE RATE MATERNITY SELCTED
var maternitybase_rate = function (maternity_cover, age) {
  return main_maternity_rates[maternity_cover - 1][age_index(age) - 1]
}
//BASE RATE SPOUSE
var spousebase_rate = function (cover, age) {
  return spouse_incap_rates[cover - 1][age_index(age) - 1]
}
//BASE BABYCARE RATE
var babycarebase_rate = function (cover, age) {
  return babycare_rates[cover - 1][baby_age_index(age) - 1]
}

//Base cover selected and term index selected - being used as a constant in all formulas
const base_cover_term_index = createSelector(
  acceleratedcover,
  term_index,
  (sumassured, terminyears) => {
    if (sumassured >= 0 && terminyears >= 0) {
      var index = sumassured + terminyears
      return index
    } else {
      0
    }
  }
)
//MAIN LIFE PREMIUM
const main_premium = createSelector(
  mainLifeAge,
  base_cover_term_index,
  (age, coverterm) => {
    if (!isNaN(age) && coverterm) {
      return base_rate(coverterm, age)
    } else {
      return 0
    }
  }
)
//HCB PREMIUM
const hcb_premium = createSelector(
  mainLifeAge,
  hcbcover,
  hcb_term_index,
  (age, hospitalcover, hcbterm) => {
    if (!isNaN(age) && hospitalcover >= 0 && hcbterm >= 0) {
      let x = hospitalcover + hcbterm
      return hcbbase_rate(x, age)
    } else {
      return 0
    }
  }
)
//MATERNITY PREMIUM
const maternity_premium = createSelector(
  mainLifeAge,
  base_cover_term_index,
  (age, coverterm) => {
    if (!isNaN(age) && coverterm) {
      return maternitybase_rate(coverterm, age)
    } else {
      return 0
    }
  }
)
//SPOUSE PREMIUM
const spouse_premium = createSelector(
  spouseAge,
  base_cover_term_index,
  (age, coverterm) => {
    if (!isNaN(age) && age > 17 && age < 56 && coverterm) {
      return spousebase_rate(coverterm, age)
    } else {
      return 0
    }
  }
)
const educational_premium = createSelector(
  (form) => form['educational-support-benefit']['educational-support-premium'],
  (form) => form['educational-support-benefit']['educational-support-term'],
  (premium, term) => {
    if (!isNaN(premium) && !isNaN(term)) {
      var index = 12
      let premben = parseInt(premium)
      let tdx = parseInt(term)
      let div = index * tdx
      var end_rate = premben / div
      return end_rate
    } else {
      0
    }
  }
)

const childpremium = createSelector(
  (form) => form['children-details'],
  base_cover_term_index,
  (child, coverterm) => {
    let getpremium = child.map((l) => {
      const age_of_child = l['age-of-child']
      const childcover = l['babycare-benefit']
      if (childcover === 'Y' && age_of_child <= 4 && coverterm) {
        return babycarebase_rate(coverterm, age_of_child)
      } else {
        return 0
      }
    }, 0)
    if (child.length > 0) {
      let sum = 0
      for (let m = 0; m < getpremium.length; m++) {
        sum += getpremium[m]
      }
      return sum
    } else {
      return 0
    }
  }
)

export const quotedPremium = createSelector(
  main_premium,
  hcb_premium,
  spouse_premium,
  (form) => form['spouse-cover'], //Y / N
  (mainrate, hcbrate, spouserate, spousecover) => {
    const rate = []
    if (!isNaN(mainrate) && mainrate > 0 && !isNaN(hcbrate)) {
      const rateA = 1 + (mainrate + hcbrate)
      rate.push(rateA)
      if (spousecover === 'Y' && !isNaN(spouserate) && spouserate > 0) {
        rate.push(spouserate)
      }
      if (rate.length > 0) {
        let sum = 0
        for (let m = 0; m < rate.length; m++) {
          sum += rate[m]
        }
        return amountString(sum)
      }
    } else {
      return '0.00'
    }
  }
)

const paymentFrequencyLookup = {
  'bi-annual': 6,
  annual: 12,
  quarterly: 3,
  monthly: 1,
}

const totalPremium = createSelector(
  main_premium,
  hcb_premium,
  maternity_premium,
  (form) => form['maternity-complications'], // Y / N
  spouse_premium,
  (form) => form['spouse-cover'], //Y / N
  (form) => form['cash-bonus-premium'], //amount
  (form) => form['retirement-premium'], //amount
  childpremium,
  educational_premium,
  (form) => form['payment-frequency'],
  (
    mainrate,
    hcbrate,
    maternityrate,
    maternity,
    spouserate,
    spousecover,
    cbpremium,
    retirementpremium,
    child,
    educationalbenefit,
    paymentFrequency
  ) => {
    const rate = []
    const rprem = parseInt(retirementpremium)
    const cbp = parseInt(cbpremium)
    const pfkey = Object.keys(paymentFrequency).find(
      (key) => paymentFrequency[key] === 'Y'
    )
    const pfmultiplier =
      pfkey in paymentFrequencyLookup ? paymentFrequencyLookup[pfkey] : 1
    if (!isNaN(mainrate) && mainrate > 0 && !isNaN(hcbrate)) {
      const rateA = 1 + (mainrate + hcbrate)
      rate.push(rateA)
      if (maternity === 'Y') {
        rate.push(maternityrate)
      }
      if (!isNaN(cbp) && cbp > 0) {
        rate.push(cbp)
      }
      if (!isNaN(rprem) && rprem > 0) {
        rate.push(rprem)
      }
      if (spousecover === 'Y') {
        rate.push(spouserate)
      }
      if (!isNaN(child) && child > 0) {
        rate.push(child)
      }
      if (!isNaN(educationalbenefit) && educationalbenefit > 0) {
        rate.push(educationalbenefit)
      }
      if (rate.length > 0) {
        let sum = 0
        for (let m = 0; m < rate.length; m++) {
          sum += rate[m]
        }
        return amountString(sum * pfmultiplier)
      }
    } else {
      return '0.00'
    }
  }
)

const generateAcceptancePage = (state) => {
  // Update the reference number
  updateItemIn(
    state.page.item,
    { id: 'contract-id' },
    { content: state.form['reference-number'] }
  )
  // Update all sections
  Object.keys(state.form).forEach((section) => {
    findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
      Object.keys(state.form[section]).forEach((field) => {
        if (state.form[section][field]) {
          updateItemIn(
            sItem,
            { id: field },
            { content: state.form[section][field] }
          )
        }
      })
    })
  })
  // Add beneficiary details
  updateItemIn(
    state.page.item,
    { id: 'beneficiary-details', type: 'table' },
    { content: (state.form['beneficiary-details'] || []).map(beneficiaryItem) }
  )
  updateItemIn(
    state.page.item,
    { id: 'children-details', type: 'table' },
    {
      invisible: state.form['children-details'].length === 0,
      content: (state.form['children-details'] || []).map(additionalLife),
    }
  )
}

// Create the state for a new process
const createState = (state) => {
  state.currentProcess = {} // Create a new process
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  ) // Overall Process state
  state.currentProcess.step = 'quotation-screen' // Current process step
  state.currentProcess.page = pages['quotation-screen'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

const setPageItemFromState = (state) => {
  validate(state, state.local && state.local.failed_validation ? false : true)
  switch (state.step) {
    case 'quotation-screen':
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
          maxYear: dayjs().year() - 18,
          minYear: dayjs().year() - 55,
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'marital-status' },
        {
          content:
            state.form['life-assured-identification']['marital-status'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'term-in-years' },
        { content: state.form['contract-details']['term-in-years'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'accelerated-cover-sum-assured' },
        {
          content:
            state.form['contract-details']['accelerated-cover-sum-assured'] ||
            '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'update-option' },
        {
          content: state.form['contract-details']['update-option'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'policy-for-myself' },
        {
          content: state.form['owner-radio-group']['policy-for-myself'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'policy-for-wife' },
        {
          content: state.form['owner-radio-group']['policy-for-wife'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { uid: 'af7bfb3d63b46b1c', type: 'list' },
        {
          invisible: !(
            state.form['owner-radio-group']['policy-for-wife'] === 'Y' ||
            state.form['owner-radio-group']['policy-for-myself'] === 'N'
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { uid: '21a3110f0d1a90af', type: 'list' },
        {
          invisible: !(
            state.form['owner-radio-group']['policy-for-wife'] === 'N' ||
            state.form['owner-radio-group']['policy-for-myself'] === 'Y'
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { uid: 'e148ae77f2c6adeb', type: 'list' },
        {
          invisible: !(
            state.form['owner-radio-group']['policy-for-wife'] === 'Y' ||
            state.form['owner-radio-group']['policy-for-myself'] === 'Y'
          ),
        }
      )
      break

    case 'additional-benefits':
      updateItemIn(
        state.page.item,
        { id: 'hospitalisation-units' },
        {
          content: state.form['hospitalisation-units'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'educational-support-premium', type: 'amount' },
        {
          content:
            state.form['educational-support-benefit'][
              'educational-support-premium'
            ] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'educational-support-term', type: 'integer' },
        {
          content:
            state.form['educational-support-benefit'][
              'educational-support-term'
            ] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'retirement-premium' },
        {
          content: state.form['retirement-premium'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'cash-bonus-premium' },
        {
          content: state.form['cash-bonus-premium'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'maternity-complications' },
        {
          content: state.form['maternity-complications'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'spouse-cover' },
        {
          content: state.form['spouse-cover'] || '',
        }
      )

      //hide spouse cover if marital status is single
      updateItemIn(
        state.page.item,
        { uid: '2af8934c7df5b4e5', type: 'list' },
        {
          invisible: !(
            state.form['life-assured-identification']['marital-status'] ===
            'Married'
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content: state.form['spouse-identification']['date-of-birth'] || '',
          maxYear: dayjs().year() - 18,
          minYear: dayjs().year() - 55,
        }
      )
      updateItemIn(
        state.page.item,
        { uid: '9c466b21863c0957', type: 'list' },
        { invisible: !(state.form['spouse-cover'] === 'Y') }
      )

      updateItemIn(
        state.page.item,
        { id: 'ladycare-child-added-basic', type: 'table' },
        {
          invisible: state.form['children-details'].length === 0,
          content: (state.form['children-details'] || []).map(additionalLife),
        }
      )

      updateItemIn(
        state.page.item,
        { uid: '5484d75e04e20d51' },
        {
          invisible: state.form['owner-radio-group']['policy-for-wife'] === 'Y',
        }
      )
      updateItemIn(
        state.page.item,
        { uid: 'd22e6ef8d3161fd4' },
        {
          invisible: state.form['owner-radio-group']['policy-for-wife'] === 'Y',
        }
      )

      updateItemIn(
        state.page.item,
        { uid: '5f36ba22ce739d00' },
        {
          invisible:
            state.form['owner-radio-group']['policy-for-myself'] === 'Y',
        }
      )
      updateItemIn(
        state.page.item,
        { uid: '44f8c3d1bf29507d' },
        {
          invisible:
            state.form['owner-radio-group']['policy-for-myself'] === 'Y',
        }
      )
      break

    case 'add-child':
      let familyButtonLabels = familyMemberLabels
      if (
        state.local &&
        state.local.relationship &&
        familyMemberLabels.indexOf(state.local.relationship) > -1
      ) {
        familyButtonLabels = Object.keys(
          familyMemberOptions(0)[state.local.relationship]
        )
      }
      state.page.buttons = familyButtonLabels.map(createButton)
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'child-cover':
      updateItemIn(
        state.page.item,
        { id: 'age-of-child' },
        {
          content:
            state.local.relationship === 'Unborn Baby'
              ? (state.local['age-of-child'] = '0')
              : state.local['age-of-child'] || '',
          options: state.local.options || [],
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'free-child-cover' },
        { content: state.local['free-child-cover'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'babycare-benefit' },
        { content: state.local['babycare-benefit'] || '' }
      )
      break

    case 'remove-children':
      state.page.buttons = state.form['children-details'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'edit-child':
      state.page.buttons = state.form['children-details'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-identification-type',
        'life-assured-contact-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      if (state.local && state.local.clearFieldErrors) {
        updateItemIn(
          state.page.item,
          { id: state.local.clearFieldErrors },
          { errors: [] }
        )
        delete state.local.clearFieldErrors
      }
      break

    case 'spouse-details':
      ;[
        'spouse-identification',
        'spouse-identfication-type',
        'spouse-contact-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      updateItemIn(
        state.page.item,
        { uid: '311d3016b9ae714a' },
        {
          invisible: state.form['owner-radio-group']['policy-for-wife'] === 'Y',
        }
      )
      updateItemIn(
        state.page.item,
        { uid: '6d0d93ab1090eff9' },
        {
          invisible:
            state.form['owner-radio-group']['policy-for-myself'] === 'Y',
        }
      )

      updateItemIn(
        state.page.item,
        { uid: '049aeb66ba8cd4f2' },
        {
          readonly: state.form['spouse-cover'] === 'Y',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'spouse-is-trustee' },
        { content: state.form['spouse-is-trustee'] || '' }
      )
      updateItemIn(
        state.page.item,
        { uid: '46871537a0598450' },
        {
          invisible: state.form['owner-radio-group']['policy-for-wife'] === 'Y',
        }
      )
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(state.page.item, { id: field }, (item) => {
              item.content = state.form[section][field] || ''
              item.readonly = state.form['spouse-is-trustee'] === 'Y'
              if (item.readonly) {
                if (item.elementType === 'select') {
                  item.elementType = 'input'
                  item.inputType = 'text'
                } else if (item.type === 'component') {
                  item.type = 'field'
                }
              }
              return item
            })
          )
        }
      )
      break

    case 'children-details':
      updateItemIn(
        state.page.item,
        { id: 'children-details' },
        {
          content: childTablePrefix.concat(
            state.form['adjusted-children'].map(childItem)
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-outcome', uid: 'dc2796ed00e65f1e' }, //Hide this if there is no unborn babies added
        { invisible: !hasUnborn(state) }
      )

      validate(
        state,
        state.local && state.local.failed_validation ? false : true
      )
      break

    case 'beneficiary-details':
      const numBeneficiaries = (state.form['beneficiary-details'] || []).length
      updateItemIn(
        state.page.item,
        { id: 'beneficiary-details', type: 'table' },
        {
          invisible: numBeneficiaries < 1,
          content: (state.form['beneficiary-details'] || []).map(
            beneficiaryItem
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '6c60b1c422ae8373' }, // Add Trustee as beneficiary
        {
          invisible: isTrusteeABeneficiary(state.form) || numBeneficiaries >= 6,
        }
      )

      let numValidChildren = state.form['children-details'].reduce(
        (b, c) =>
          b +
          (isChildABeneficiary(c, state.form['beneficiary-details']) ? 0 : 1),
        0
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: 'bf6a79c7a7af53dc' }, // Add a Child beneficiary
        { invisible: numValidChildren == 0 || numBeneficiaries >= 6 }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '145b199f80e0c8c3' }, // Add a different beneficiary
        { invisible: numBeneficiaries >= 6 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'add-another-beneficiary':
          case 'add-children':
            button.invisible = numBeneficiaries >= 6
            break
          case 'add-trustee':
            button.invisible =
              isTrusteeABeneficiary(state.form) || numBeneficiaries >= 6
            break
          case 'update-benefit-split-percentage':
            button.invisible = numBeneficiaries < 2
            break
        }
      })
      break

    case 'add-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'edit-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'update-beneficiary-split-percentage':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'owner-details':
      ;[
        'owner-identification',
        'owner-contact-details',
        'owner-postal-address',
        'owner-residential-address',
        'owner-employment-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      break

    case 'existing-coverage':
      Object.keys(state.form['existing-policies']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['existing-policies'][field] || '' }
        )
      )

      Object.keys(state.form['existing-policy-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['existing-policy-details'][field] || '' }
        )
      )

      //Hide Elements
      updateItemIn(
        state.page.item,
        { uid: '427309bfc272eea5', type: 'list' },
        {
          invisible:
            !(
              state.form['existing-policies'][
                'application-replaces-existing'
              ] === 'Y'
            ) ||
            state.form['existing-policies']['application-replaces-existing'] ===
              'Y' ||
            state.form['existing-policies']['application-replaces-existing'] ===
              null,
        }
      )

      updateItemIn(
        state.page.item,
        { uid: '427309bfc272eea5', type: 'list' },
        {
          invisible:
            !(state.form['existing-policies']['existing-coverage'] === 'Y') ||
            state.form['existing-policies']['existing-coverage'] === 'Y' ||
            state.form['existing-policies']['existing-coverage'] === null,
        }
      )
      break

    case 'medical-underwriting':
      Object.keys(state.form['life-assured-general-habits']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-general-habits'][field] || '' }
        )
      )
      ;[
        'planning-other-insurance',
        'other-assurance-past12-months',
        'risky-occupation-hobbies',
        'refused-application-or-increased-premium',
        'insolvency-issues',
        'occupation-not-clerical',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { id: `${field}-details` },
          {
            invisible:
              state.form['life-assured-general-habits'][field] !== 'Yes',
          }
        )
      })
      ;[
        '5f8b25ed4415c897', //  'average-monthly-gross-income-list',
        'beaacad6ef6645ee', // 'total-existing-assurance-cover-in-force-on-your-life-list',
        '91930796f1d9da82', // 'full-reasons-for-effecting-this-additional-cover-list',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { uid: field, type: 'list' },
          {
            invisible:
              state.form['life-assured-general-habits'][
                'cover-exceeds-one-hundred-thousand'
              ] !== 'Yes',
          }
        )
      })

      updateItemIn(
        state.page.item,
        { id: 'years-smoking' },
        {
          invisible: state.form['life-assured-general-habits'].smoker !== 'Y',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'years-smoking' },
        { invisible: state.form['life-assured-general-habits'].smoker !== 'Y' }
      )
      updateItemIn(
        state.page.item,
        { id: 'smoking-details', type: 'list' },
        { invisible: state.form['life-assured-general-habits'].smoker !== 'Y' }
      )

      updateItemIn(
        state.page.item,
        { id: 'years-not-smoking' },
        {
          invisible:
            state.form['life-assured-general-habits']['non-smoker'] !== 'Y',
        }
      )
      break

    case 'medical-declaration':
      Object.keys(state.form['life-assured-health-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-health-details'][field] || '' }
        )
      )
      updateItemIn(
        state.page.item,
        { id: 'family-history-of-illness-details' },
        {
          invisible:
            state.form['life-assured-health-details'][
              'family-history-of-illness'
            ] !== 'Yes',
        }
      )

      Object.keys(state.form['life-assured-health-tests']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-health-tests'][field] || '' }
        )
      )
      break

    case 'health-descriptions':
      state.form['health-concerns'].forEach((c, i) =>
        Object.keys(c).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: `${field}-${i + 1}` },
            { content: c[field] || '' }
          )
        )
      )
      break

    case 'payment-details':
      Object.keys(state.form['payment-method']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-method'][field] || '' }
        )
      )

      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )

      Object.keys(state.form['payment-frequency']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-frequency'][field] || '' }
        )
      )
      break

    case 'stop-order-details':
      Object.keys(state.form['payment-employment-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['payment-employment-details'][field] || '' }
        )
      )
      break

    case 'debit-order-details':
      Object.keys(state.form['payment-bank-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['payment-bank-details'][field] || '',
          }
        )
      )
      break

    case 'mobile-wallet-details':
      Object.keys(state.form['mobile-wallet-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['mobile-wallet-details'][field] || '',
          }
        )
      )
      break

    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break
  }
  updateItemIn(
    state.page.item,
    { id: 'premium' },
    {
      content: totalPremium(state.form),
    }
  )
  updateItemIn(
    state.page.item,
    { id: 'benefit-rate' },
    { content: sumassured(state.form) }
  )
}

// Change the page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}
// processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break
      case 'quotation-screen':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              changePage(state, 'additional-benefits')
              if (state.form['owner-radio-group']['policy-for-wife'] === 'Y') {
                state.form['life-assured-identification']['marital-status'] =
                  'Married'
              }
              state.form['life-assured-identification'].gender = 'Female'
            }
            break
        }
        break
      case 'additional-benefits':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-screen')
            break
          case 'next':
            if (validate(state)) {
              if (totalPremium(state.form) < 100) {
                changePage(state, 'premium-validate')
              } else {
                changePage(state, 'life-assured-identification')
              }
            }
            break
          case 'add-child':
            if (state.form['children-details'].length < 9) {
              changePage(state, 'add-child')
            }
            break
          case 'edit-child':
            const locals = state.local
            changePage(state, 'edit-child')
            state.local = locals
            break
          case 'remove-child':
            changePage(state, 'remove-children')
            break
        }
        break
      case 'premium-validate':
        switch (button.id) {
          case 'done':
            changePage(state, 'additional-benefits')
            break
        }
        break
      case 'remove-children':
        state.form['children-details'] = state.form['children-details'].filter(
          (life, position, lives) =>
            idFromLifeAssured(life, position, lives) !== button.id
        )
        changePage(state, 'additional-benefits')
        break
      case 'add-child':
        if (button.id === 'back') {
          changePage(state, 'additional-benefits')
        } else {
          let optionLevel = familyMemberOptions(0)
          if (
            state.local &&
            state.local.relationship &&
            familyMemberLabels.indexOf(state.local.relationship) > -1
          ) {
            optionLevel = optionLevel[state.local.relationship]
          }
          const elem = Object.keys(optionLevel).find(
            (l) => button.id === slugify(l)
          )
          if (elem) {
            const options = optionLevel[elem]
            if ('options' in options) {
              changePage(state, 'child-cover')
              state.local = state.local || {}
              state.local.options = options.options
              state.local.relationship = options.rename || elem
            } else {
              state.local = state.local || {}
              state.local.relationship = elem
            }
          }
        }
        break

      case 'child-cover':
        switch (button.id) {
          case 'back':
            changePage(state, 'add-child')
            break
          case 'next':
            if (validate(state)) {
              if (
                state.local &&
                ['relationship', 'age-of-child'].every((f) => f in state.local)
              ) {
                const life = createFamilyMember(
                  state.local.relationship,
                  state.local['age-of-child'],
                  state.local['free-child-cover'] || '',
                  state.local['babycare-benefit'] || ''
                )
                if (
                  (state.local.replace || state.local.replace === 0) &&
                  state.local.replace >= 0 &&
                  state.local.replace < state.form['children-details'].length
                ) {
                  state.form['children-details'][state.local.replace] = life
                } else {
                  state.form['children-details'].push(life)
                }
                changePage(state, 'additional-benefits')
              }
            }
            break
        }
        break

      case 'edit-child':
        if (button.id === 'back') {
          changePage(state, 'additional-benefits')
        } else {
          const lifePosition = state.form['children-details'].findIndex(
            (life, position, lives) =>
              idFromLifeAssured(life, position, lives) === button.id
          )
          if (lifePosition >= 0) {
            changePage(state, 'child-cover')
            const life = original(state.form['children-details'][lifePosition])
            state.local = {
              replace: lifePosition,
              relationship: life.relationship,
              'age-of-child': life['age-of-child'],
              'free-child-cover':
                life['free-child-cover'] === 'Y' ? 'Y' : undefined,
              'babycare-benefit':
                life['babycare-benefit'] === 'Y' ? 'Y' : undefined,
            }
            const optionLevel = familyMemberOptions(0) || []
            state.local.options = optionsForRelationship(
              optionLevel,
              life.relationship
            )
            if (state.local.options === null) {
              state.local.options = Object.keys(optionLevel).reduce(
                (options, key) =>
                  optionsForRelationship(optionLevel[key], life.relationship) ||
                  options,
                []
              )
            }
          }
        }
        break

      case 'life-assured-identification':
        switch (button.id) {
          case 'back':
            changePage(state, 'additional-benefits')
            break
          case 'next':
            if (validate(state)) {
              if (
                state.form['owner-radio-group']['policy-for-myself'] === 'Y'
              ) {
                ;['identification', 'contact-details'].forEach((section) =>
                  Object.keys(state.form[`life-assured-${section}`]).forEach(
                    (field) =>
                      (state.form[`owner-${section}`][field] =
                        state.form[`life-assured-${section}`][field])
                  )
                )
                state.form['life-assured-is-owner'] = 'Y'
              }
              state.form['spouse-identification'].gender = 'Male'
              if (
                state.form['life-assured-identification']['marital-status'] ===
                'Married'
              ) {
                changePage(state, 'spouse-details')
              } else {
                changePage(state, 'trustee-details')
              }
              state.form['children-details'].forEach(function (part, index) {
                // part and arr[index] point to the same object
                // so changing the object that part points to changes the object that arr[index] points to
                if (part.relationship === 'Unborn Baby') {
                  ;(part['first-names'] = 'Unborn'), (part['surname'] = 'Baby')
                }
              })
            }
            break
        }
        break
      case 'spouse-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'life-assured-identification')
            break
          case 'next':
            if (validate(state)) {
              if (state.form['spouse-is-trustee'] === 'Y') {
                ;['identification', 'contact-details'].forEach((section) =>
                  Object.keys(state.form[`spouse-${section}`]).forEach(
                    (field) =>
                      (state.form[`trustee-${section}`][field] =
                        state.form[`spouse-${section}`][field])
                  )
                )
                state.form['trustee-identification']['relationship'] = 'Spouse'
              }
              if (state.form['owner-radio-group']['policy-for-wife'] === 'Y') {
                ;['identification', 'contact-details'].forEach((section) =>
                  Object.keys(state.form[`spouse-${section}`]).forEach(
                    (field) =>
                      (state.form[`owner-${section}`][field] =
                        state.form[`spouse-${section}`][field])
                  )
                )
                state.form['spouse-is-owner'] = 'Y'
              }
              changePage(state, 'trustee-details')
            }
            break
        }
        break
      case 'trustee-details':
        switch (button.id) {
          case 'back':
            if (
              state.form['life-assured-identification']['marital-status'] ===
              'Married'
            ) {
              changePage(state, 'spouse-details')
            } else {
              changePage(state, 'life-assured-identification')
            }
            break
          case 'next':
            if (validate(state)) {
              state.form['adjusted-children'] = mapChildren(state.form)
              state.form['adjusted-children'].forEach(function (d, index) {
                if (d.relationship === 'Daughter') {
                  d.gender = 'Female'
                }
                if (d.relationship === 'Son') {
                  d.gender = 'Male'
                }
              })
              state.form['unborn'] = maptoUnborn(state.form)
              if (state.form['adjusted-children'].length > 0) {
                changePage(state, 'children-details')
              } else {
                changePage(state, 'beneficiary-details')
              }
              if (state.form['trustee-identification'].relationship === 'Son') {
                state.form['trustee-identification'].relationship = 'Son-1'
              }
              if (
                state.form['trustee-identification'].relationship === 'Daughter'
              ) {
                state.form['trustee-identification'].relationship = 'Daughter-1'
              }
            }
            break
        }
        break
      case 'children-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'trustee-details')
            break
          case 'next':
            if (validate(state)) {
              state.form['children-details'] = state.form[
                'adjusted-children'
              ].concat(state.form['unborn'])
              changePage(state, 'beneficiary-details')
            }
            break
        }
        break
      case 'beneficiary-details':
        switch (button.id) {
          case 'back':
            if (state.form['adjusted-children'].length > 0) {
              changePage(state, 'children-details')
            } else {
              changePage(state, 'trustee-details')
            }
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'owner-details')
              state.form['beneficiary-details'].forEach(function (ben, index) {
                //make the date of birth "empty" if the child is unborn
                if (ben['first-names'] === 'Unborn') {
                  ben['date-of-birth'] = null
                }
              })
            }
            break
          case 'add-trustee':
            if (
              state.form['beneficiary-details'].length < 7 &&
              !isTrusteeABeneficiary(original(state.form))
            ) {
              state.form['beneficiary-details'].push({
                'first-names':
                  state.form['trustee-identification']['first-names'],
                surname: state.form['trustee-identification'].surname,
                'benefit-split': null,
                role: 'Cessionary',
                gender: state.form['trustee-identification'].gender,
                'date-of-birth':
                  state.form['trustee-identification']['date-of-birth'],
              })
              updateBeneficiarySplit(state.form)
            }
            break
          case 'add-another-beneficiary':
            if (state.form['beneficiary-details'].length < 7) {
              changePage(state, 'add-beneficiary')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
                'role',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break
          case 'add-children':
            let btns = state.form['children-details'].reduce((b, c, i) => {
              if (!isChildABeneficiary(c, state.form['beneficiary-details'])) {
                b.push(createButton(c['first-names'], { index: i }))
              }
              return b
            }, [])
            if (btns.length > 0) {
              changePage(state, 'add-children-as-beneficiary')
              state.page.buttons = btns
            }
            break
          case 'update-benefit-split-percentage':
            changePage(state, 'update-beneficiary-split-percentage')
            state.local = state.form['beneficiary-details'].reduce(
              (l, b, c) => {
                l[`beneficiary-split-${c + 1}`] = b['benefit-split'] || ''
                return l
              },
              {}
            )
            updateItemIn(
              state.page.item,
              { id: 'update-benefit-split-percentage', type: 'list' },
              {
                content:
                  state.form['beneficiary-details'].map(benefitSplitItem),
              }
            )
            break
        }
        break

      case 'add-children-as-beneficiary':
        if (
          button.index >= 0 &&
          button.index < state.form['children-details'].length
        ) {
          state.form['beneficiary-details'].push({
            'first-names':
              state.form['children-details'][button.index]['first-names'],
            surname: state.form['children-details'][button.index].surname,
            'benefit-split': null,
            role: `Child-${button.index + 1}`,
            gender: state.form['children-details'][button.index].gender,
            'date-of-birth':
              state.form['children-details'][button.index]['date-of-birth'],
          })
          updateBeneficiarySplit(state.form)
        }
        changePage(state, 'beneficiary-details')
        break

      case 'add-beneficiary':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'add':
            if (validate(state)) {
              if (state.form['beneficiary-details'].length < 7) {
                state.form['beneficiary-details'].push({
                  'first-names': state.local['first-names'],
                  surname: state.local.surname,
                  'benefit-split': null,
                  role: state.local.role,
                  gender: state.local.gender,
                  'date-of-birth': state.local['date-of-birth'],
                })
                updateBeneficiarySplit(state.form)
              }
              changePage(state, 'beneficiary-details')
            }
            break
        }
        break

      case 'edit-beneficiary':
        switch (button.id) {
          case 'update':
            if (validate(state)) {
              if (
                state.local.index >= 0 &&
                state.local.index <= state.form['beneficiary-details'].length
              ) {
                Object.keys(
                  original(
                    state.form['beneficiary-details'][state.local.index - 1]
                  )
                ).forEach(
                  (k) =>
                    (state.form['beneficiary-details'][state.local.index - 1][
                      k
                    ] = state.local[k])
                )
              }
              changePage(state, 'beneficiary-details')
            }
            break
          case 'delete-beneficiary':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['beneficiary-details'].length
            ) {
              const old = original(state.form['beneficiary-details'])
              state.form['beneficiary-details'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
              updateBeneficiarySplit(state.form)
            }
            changePage(state, 'beneficiary-details')
            break
        }
        break
      case 'update-beneficiary-split-percentage':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'update':
            if (validate(state)) {
              state.form['beneficiary-details'] = original(
                state.form['beneficiary-details']
              ).map((b, c) => {
                b['benefit-split'] = amountString(
                  parseFloat(state.local[`beneficiary-split-${c + 1}`])
                )
                return b
              })
              changePage(state, 'beneficiary-details')
            }
        }
        break
      case 'owner-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'existing-coverage')
            }
            break
        }
        break
      case 'existing-coverage':
        switch (button.id) {
          case 'back':
            changePage(state, 'owner-details')
            break
          case 'next':
            changePage(state, 'medical-underwriting')
            break
        }
        break
      case 'medical-underwriting':
        switch (button.id) {
          case 'back':
            changePage(state, 'existing-coverage')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'medical-declaration')
            }
            break
        }
        break
      case 'medical-declaration':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-underwriting')
            break
          case 'next':
            if (validate(state)) {
              if (healthConcernCount(original(state.form)) > 0) {
                changePage(state, 'health-descriptions')
                createHealthDetailsPage(state)
              } else {
                state.form['health-concerns'].length = 0
                changePage(state, 'payment-details')
              }
            }
            break
        }
        break

      case 'health-descriptions':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-declaration')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details')
            }
            break
        }
        break

      case 'payment-details':
        switch (button.id) {
          case 'back':
            if (healthConcernCount(original(state.form)) > 0) {
              changePage(state, 'health-descriptions')
              createHealthDetailsPage(state)
            } else {
              changePage(state, 'medical-declaration')
            }
            break
          case 'next':
            if (validate(state)) {
              if (state.form['payment-method']['debit-order'] === 'Y') {
                changePage(state, 'debit-order-details')
              } else if (state.form['payment-method']['stop-order'] === 'Y') {
                changePage(state, 'stop-order-details')
              } else if (
                state.form['payment-method']['mobile-wallet'] === 'Y'
              ) {
                changePage(state, 'mobile-wallet-details')
              }
            }
            if (state.form['spouse-is-owner'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['spouse-identification'][field])
              )
              state.form['life-assured-is-payer'] = 'N'
              state.form['payer-person-identification']['relationship'] =
                'Spouse'
            } else if (state.form['life-assured-is-owner'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['life-assured-identification'][field])
              )
              state.form['life-assured-is-payer'] = 'Y'
              state.form['payer-person-identification']['relationship'] = 'Main'
            }
            break
        }
        break
      case 'debit-order-details':
      case 'stop-order-details':
      case 'mobile-wallet-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
            break
        }
        break
      case 'upload-documents':
        switch (button.id) {
          case 'next':
            changePage(state, 'acceptance-screen')
            generateAcceptancePage(state)
            break
          case 'back':
            if (state.form['payment-method']['debit-order'] === 'Y') {
              changePage(state, 'debit-order-details')
            } else if (state.form['payment-method']['stop-order'] === 'Y') {
              changePage(state, 'stop-order-details')
            } else if (state.form['payment-method']['mobile-wallet'] === 'Y') {
              changePage(state, 'mobile-wallet-details')
            }
            state.form['payer-signature'] = null
            break
          case 'upload-id-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
        }
        break

      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            if (state.form.documents[state.local.document].length > 0) {
              changePage(state, 'acceptance-screen')
              generateAcceptancePage(state)
            } else {
              changePage(state, 'upload-documents')
            }
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'back':
            changePage(state, 'upload-documents')
            break
          case 'submit':
            if (validate(state)) {
              state.form['application-accepted'] = 'Y'
              state.form['application-acceptance-date'] =
                dayjs().format('YYYYMMDD')
              state.form.premium = totalPremium(state.form)
              asyncDispatch(completeProcess(state.form['reference-number']))
              if (state.form['hospitalisation-units']) {
                const unit = state.form['hospitalisation-units']
                const mapUnit =
                  unit in hospitalUnits
                    ? hospitalUnits[unit]
                    : hospitalUnits[unit]
                state.form['hospitalisation-units'] = mapUnit
              }
            }
            break
        }
        break
    }
    setPageItemFromState(state)
  }
}

//processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-screen':
      if (data.id in state.form['life-assured-identification']) {
        state.form['life-assured-identification'][data.id] = data.value
        dirty = true
      } else if (data.id in state.form['contract-details']) {
        state.form['contract-details'][data.id] = data.value
        dirty = true
      } else if (data.id in state.form['owner-radio-group']) {
        state.form['owner-radio-group'][data.id] = data.value
        dirty = true
      } else {
        console.warn(`No form element found for ${data.id} [${data.value}]`)
      }
      break

    case 'additional-benefits':
      switch (data.id) {
        case 'hospitalisation-units':
          state.form['hospitalisation-units'] = data.value
          dirty = true
          break
        case 'educational-support-term':
          state.form['educational-support-benefit'][data.id] = data.value
          dirty = true
          break
        case 'educational-support-premium':
          state.form['educational-support-benefit'][data.id] = data.value
          dirty = true
          break
        case 'retirement-premium':
          state.form['retirement-premium'] = data.value
          dirty = true
          break
        case 'cash-bonus-premium':
          state.form['cash-bonus-premium'] = data.value
          dirty = true
          break
        case 'maternity-complications':
          state.form['maternity-complications'] = data.value
          dirty = true
          break
        case 'spouse-cover':
          state.form['spouse-cover'] = data.value
          dirty = true
          break
        case 'date-of-birth':
          state.form['spouse-identification']['date-of-birth'] = data.value
          dirty = true
          break
      }
      break

    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-identification-type',
        'life-assured-contact-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })

      break

    case 'child-cover':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'spouse-details':
      ;[
        'spouse-identification',
        'spouse-identfication-type',
        'spouse-contact-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      if (data.id === 'spouse-is-trustee') {
        state.form[data.id] = data.value
        dirty = true
      }
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      break

    case 'children-details':
      let id = data.id.split('-')
      const index = parseInt(id.pop())
      if (!isNaN(index) && index < state.form['adjusted-children'].length) {
        id = id.join('-')
        if (id in state.form['adjusted-children'][index]) {
          state.form['adjusted-children'][index][id] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break

    case 'add-beneficiary':
    case 'edit-beneficiary':
    case 'update-beneficiary-split-percentage':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'owner-details':
      ;[
        'owner-identification',
        'owner-contact-details',
        'owner-postal-address',
        'owner-residential-address',
        'owner-employment-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      break

    case 'existing-coverage':
      if (data.id in state.form['existing-policies']) {
        state.form['existing-policies'][data.id] = data.value
        dirty = true
      }
      if (data.id in state.form['existing-policy-details']) {
        state.form['existing-policy-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'medical-underwriting':
      if (data.id in state.form['life-assured-general-habits']) {
        state.form['life-assured-general-habits'][data.id] = data.value
        dirty = true
      }
      break

    case 'medical-declaration':
      if (data.id in state.form['life-assured-health-details']) {
        state.form['life-assured-health-details'][data.id] = data.value
        dirty = true
      }
      if (data.id in state.form['life-assured-health-tests']) {
        state.form['life-assured-health-tests'][data.id] = data.value
        dirty = true
      }
      break

    case 'health-descriptions':
      let id1 = data.id.split('-')
      const index1 = parseInt(id1.pop())
      if (!isNaN(index1) && index1 <= state.form['health-concerns'].length) {
        id1 = id1.join('-')
        if (id1 in state.form['health-concerns'][index1 - 1]) {
          state.form['health-concerns'][index1 - 1][id1] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break

    case 'payment-details':
      switch (data.id) {
        case 'debit-order':
        case 'stop-order':
        case 'mobile-wallet':
          state.form['payment-method'][data.id] = data.value
          dirty = true
          break
        case 'payment-start-date':
          const date = dayjs(data.value)
          state.form['payment-start-date'] = date.isValid()
            ? date.format('YYYYMMDD')
            : null
          dirty = true
          break
        case 'monthly':
        case 'annual':
        case 'bi-annual':
        case 'quarterly':
          state.form['payment-frequency'][data.id] = data.value
          dirty = true
          break
      }
      break

    case 'stop-order-details':
      if (data.id in state.form['payment-employment-details']) {
        state.form['payment-employment-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'debit-order-details':
      if (data.id in state.form['payment-bank-details']) {
        state.form['payment-bank-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'mobile-wallet-details':
      if (data.id in state.form['mobile-wallet-details']) {
        state.form['mobile-wallet-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

const processItemClick = (state, data, asyncDispatch) => {
  let dirty = false
  switch (state.step) {
    case 'beneficiary-details':
      if (
        data.index > 0 &&
        data.index <= state.form['beneficiary-details'].length
      ) {
        changePage(state, 'edit-beneficiary')
        state.local = Object.assign(
          {},
          original(state.form['beneficiary-details'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

export default {
  createState,
  processButton,
  processChange,
  processItemClick,
}
