/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setAgentDetails,
  postponeProcess,
  checkCompleted,
} from '../core/actions'
import { validAgentId } from '../core/validations'
import classNames from 'classnames'
import Header from '../components/Header'
import ErrorPage from '../components/ErrorPage'
import ProcessContent from './ProcessContent'
import ProductSelection from './ProductSelection'
import InputModal from './InputModal'
import OnlineStatus from './OnlineStatus'

// Load global application styles
import '../styles/Application.css'

const isModalOpen = (environment) =>
  environment.currentProcess &&
  environment.currentProcess.page &&
  environment.currentProcess.page.modal

const pageClass = (environment) =>
  environment.currentProcess &&
  environment.currentProcess.page &&
  environment.currentProcess.page.class

const AppShell = ({ environment, dispatch }) => {
  useEffect(() => {
    const agentContractId = new URLSearchParams(window.location.search).get(
      'agent-contract-id'
    )
    if (agentContractId) {
      if (validAgentId(agentContractId)) {
        if (process.env.ANALYTICS && window.ga) {
          window.ga('set', 'userId', agentContractId)
        }
        dispatch(setAgentDetails(agentContractId))
      }
      window.history.replaceState(
        {},
        document.title,
        window.location.href.split('?')[0]
      )
    } else if (environment.agentContractId) {
      if (process.env.ANALYTICS && window.ga) {
        window.ga('set', 'userId', environment.agentContractId)
      }
      if (
        process.env.SENTRY &&
        process.env.SENTRY.length > 0 &&
        window.Sentry
      ) {
        window.Sentry.setUser({ id: environment.agentContractId })
      }
    }
    const uploader = setInterval(
      () => dispatch(checkCompleted()),
      process.env.SYNC_INTERVAL
    )
    dispatch(checkCompleted())
    return () => {
      clearInterval(uploader)
    }
  }, [])
  const currentProcessId =
    environment.currentProcess && environment.currentProcess['process-id']
  const knownAgent = !!environment.agentContractId
  return (
    <div
      className={classNames({
        'modal-open': !knownAgent || isModalOpen(environment),
        shell: knownAgent && !environment.currentProcess,
        [pageClass(environment) || '']: true,
      })}
    >
      <OnlineStatus />
      <Header
        enterprise={process.env.APPLICATION_NAME}
        product={
          environment.currentProcess ? environment.currentProcess.label : null
        }
        agent={environment.agentContractId}
        processId={currentProcessId}
        postponeProcess={(e) => {
          e.preventDefault()
          if (currentProcessId) {
            dispatch(postponeProcess(currentProcessId))
          }
        }}
        agentLogout={() => {
          dispatch(setAgentDetails(null))
          if (currentProcessId) {
            dispatch(postponeProcess(currentProcessId))
          }
        }}
      />
      {environment.error && <ErrorPage error={environment.error} />}
      {knownAgent ? (
        environment.currentProcess ? (
          <ProcessContent state={environment.currentProcess} />
        ) : (
          <ProductSelection />
        )
      ) : (
        <InputModal
          name='agent-contract-id'
          title='Please confirm the following details'
          label='Agent Contract Id'
          value=''
          validator={validAgentId}
          invalidMessage='This is not a valid Agent Contract ID'
          onChange={(name, value) => dispatch(setAgentDetails(value))}
        />
      )}
      <div id='app-version'>v{process.env.APPLICATION_VERSION}</div>
    </div>
  )
}

export default connect((state) => state)(AppShell)
