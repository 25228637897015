/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import {
  hasValue,
  verify,
  validEmail,
  validMinAmount,
  validAmountRange,
} from '../../../../src/core/validations'
import { totalPremium } from '../funeral-smart-plan'

import {
  validNHIS,
  validVotersID,
  validSSNIT,
  validPassport,
  validDrivers,
  validID,
  validName,
  validCellnumber,
  onlyDigits,
  validOldDrivers,
} from '../../validations'

import dayjs from 'dayjs'
import { updateItemIn } from '../../../../src/core/dataitems'

export const validate = (state, requiredOnly, validateOnly) => {
  if (process.env.DISABLE_VALIDATIONS === 'true') {
    return true
  }

  let validates = true
  switch (state.step) {
    case 'quotation-screen':
      validates =
        verify(
          state,
          state.form['life-assured-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 18 && dayjs().diff(v, 'year') <= 59)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      // Gender
      validates =
        verify(
          state,
          state.form['life-assured-identification']['gender'],
          hasValue,
          !validateOnly && { id: 'gender' },
          ['*']
        ) && validates

      //Marital Status
      validates =
        verify(
          state,
          state.form['life-assured-identification']['marital-status'],
          hasValue,
          !validateOnly && { id: 'marital-status' },
          ['*']
        ) && validates

      validates =
        Object.keys(state.form['main-cover-level']).find(
          (field) => state.form['main-cover-level'][field] === 'Y'
        ) && validates
      updateItemIn(
        state.page.item,
        { id: 'main-cover-level-radio-group' },
        { errors: validates ? [] : ['*'] }
      )
      break

    case 'family-members-age-and-gender':
      validates =
        verify(
          state,
          state.local && state.local['age-of-member'],
          hasValue,
          !validateOnly && { id: 'age-of-member' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.local && state.local.gender,
          hasValue,
          !validateOnly && { id: 'gender' },
          ['*']
        ) && validates

      //Validate the gender & relationship
      if (
        [
          'Mother',
          'Mother in law',
          'Grandmother',
          'Step mother',
          'Sister',
        ].indexOf(state.local.relationship) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly || ['Female'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (
        [
          'Father',
          'Father in law',
          'Grandfather',
          'Step father',
          'Brother',
        ].indexOf(state.local.relationship) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly || ['Male'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (['Child', 'Guardian'].indexOf(state.local.relationship) >= 0) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Male', 'Female'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (
        state.form['life-assured-identification']['marital-status'] ===
          'Married' &&
        state.form['life-assured-identification']['gender'] === 'Female'
      ) {
        if (
          ['Spouse', 'Additional-Spouse'].indexOf(state.local.relationship) >= 0
        ) {
          validates =
            verify(
              state,
              state.local.gender,
              (v) =>
                hasValue(v) &&
                (requiredOnly || ['Male'].indexOf(state.local.gender) >= 0),
              !validateOnly && { id: 'gender' },
              requiredOnly
                ? ['*']
                : [
                    'The selected spouse gender and main gender may not be the same',
                  ]
            ) && validates
        }
      } else if (
        state.form['life-assured-identification']['marital-status'] ===
          'Married' &&
        state.form['life-assured-identification']['gender'] === 'Male'
      ) {
        if (
          ['Spouse', 'Additional-Spouse'].indexOf(state.local.relationship) >= 0
        ) {
          validates =
            verify(
              state,
              state.local.gender,
              (v) =>
                hasValue(v) &&
                (requiredOnly || ['Female'].indexOf(state.local.gender) >= 0),
              !validateOnly && { id: 'gender' },
              requiredOnly
                ? ['*']
                : [
                    'The selected spouse gender and main gender may not be the same',
                  ]
            ) && validates
        }
      }
      break

    case 'family-members-quotation-details':
      if (!requiredOnly && state.local['age-of-member'] > 70) {
        validates =
          verify(
            state,
            state.local &&
              (state.local['option-e'] ||
                state.local['option-f'] ||
                state.local['option-g']),
            hasValue,
            !validateOnly && { id: 'cover-level-options-radio-group' },
            ['Please select any other cover except for Option H']
          ) && validates
      }
      break

    case 'additional-cover':
      validates =
        verify(
          state,
          state.form['inflation-protector'],
          hasValue,
          !validateOnly && { id: 'inflation-protector' },
          ['*']
        ) && validates

      const prem = totalPremium(state.form)
      let cashb = parseFloat(state.form['cash-bonus-premium'])
      let quotedPrem = prem - cashb
      if (cashb < 5) {
        validates =
          verify(
            state,
            state.form['cash-bonus-premium'],
            (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 5)),
            !validateOnly && { id: 'cash-bonus-premium', type: 'amount' },
            requiredOnly ? ['*'] : ['The minimum amount is GHc 5.00']
          ) && validates
      } else if (cashb > quotedPrem) {
        validates =
          verify(
            state,
            state.form['cash-bonus-premium'],
            (v) => !hasValue(v) || validAmountRange(cashb, 5, quotedPrem),
            !validateOnly && { id: 'cash-bonus-premium', type: 'amount' },
            [
              `You have to choose an amount between GHc5.00 and risk amount GH¢ ${parseFloat(
                quotedPrem
              )}`,
            ]
          ) && validates
      }

      if (prem < 50) {
        validates =
          verify(
            state,
            prem,
            (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 50)),
            !validateOnly && { id: 'family-cover' },
            requiredOnly
              ? ['*']
              : [
                  'The minimum premium is GHc 50. Please select additional covers in order to meet this requirement.',
                ]
          ) && validates
      } else if (prem > 100) {
        validates =
          verify(
            state,
            prem,
            (v) => !hasValue(v) || validAmountRange(v, 50, 100),
            !validateOnly && { id: 'family-cover' },
            [
              `The maximum amount is GH¢100. Please delete additional covers in order to meet this requirement`,
            ]
          ) && validates
      }
      break

    case 'life-assured-identification':
      validates = Object.keys(state.form['life-assured-identification'])
        .filter(
          (k) =>
            [
              'marital-status',
              'gender',
              'date-of-birth',
              'digital-address',
              'other-nationality',
              'ghanaian',
            ].indexOf(k) < 0
        )
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      //Validate field name without numbers
      validates =
        verify(
          state,
          state.form['life-assured-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification-type'].dvla ||
            state.form['life-assured-identification-type'].passport ||
            state.form['life-assured-identification-type']['voter-id'] ||
            state.form['life-assured-identification-type'].nhis ||
            state.form['life-assured-identification-type'].ssnit ||
            state.form['life-assured-identification-type']['nia-ghana-card'],
          hasValue,
          !validateOnly && { id: 'id-type-radio-group' },
          ['*']
        ) && validates

      //Validation for Voters ID
      if (state.form['life-assured-identification-type']['voter-id'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Voters ID Number']
          ) && validates
      }

      //Validation for NHIS
      if (state.form['life-assured-identification-type']['nhis'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid NHIS Number']
          ) && validates
      }

      //Validation for SSNIT
      if (state.form['life-assured-identification-type']['ssnit'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid SSNIT Number']
          ) && validates
      }

      //Passport
      if (state.form['life-assured-identification-type']['passport'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Passport Number']
          ) && validates
      }

      //Drivers
      if (state.form['life-assured-identification-type']['dvla'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly
                ? hasValue(v)
                : validDrivers(v) || validOldDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Drivers Number']
          ) && validates
      }

      //ID
      if (
        state.form['life-assured-identification-type']['nia-ghana-card'] === 'Y'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Validate the gender & title
      if (state.form['life-assured-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['life-assured-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (
        state.form['life-assured-identification']['other-nationality'] === null
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification'].ghanaian,
            hasValue,
            !validateOnly && { id: 'ghanaian' },
            ['*']
          ) && validates
      }
      validates =
        verify(
          state,
          state.form['life-assured-identification']['other-nationality'],
          (v) =>
            hasValue(v) ||
            (validates &&
              state.form['life-assured-identification'].ghanaian === 'Y'),
          !validateOnly && { id: 'other-nationality' },
          requiredOnly
            ? ['*']
            : ['If you are not a ghanaian please select your nationality']
        ) && validates

      // Cell phone number
      validates =
        verify(
          state,
          state.form['life-assured-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      // Email address
      validates =
        verify(
          state,
          state.form['life-assured-contact-details'].email,
          (v) => (requiredOnly ? hasValue(v) : validEmail(v)),
          !validateOnly && { id: 'email' },
          requiredOnly ? ['*'] : ['Please enter a valid Email address']
        ) && validates

      // Postal Address
      validates = Object.keys(state.form['life-assured-postal-details'])
        .filter((k) => ['addressee'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-postal-details'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      // Residential Address
      validates = Object.keys(
        state.form['life-assured-residential-address']
      ).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['life-assured-residential-address'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'trustee-details':
      // Trustee details
      validates = Object.keys(state.form['trustee-identification']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['trustee-identification'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      //Validate Field Names to contain no numbers
      validates =
        verify(
          state,
          state.form['trustee-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['trustee-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age
      validates =
        verify(
          state,
          state.form['trustee-identification']['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().diff(v, 'year') >= 18),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The minimum allowable age for this product is 18.']
        ) && validates

      //Validate the gender & title
      if (
        state.form['trustee-identification']['relationship'] === 'Daughter' ||
        state.form['trustee-identification']['relationship'] === 'Estate' ||
        state.form['trustee-identification']['relationship'] === 'Mother' ||
        state.form['trustee-identification']['relationship'] ===
          'Mother-In-Law' ||
        state.form['trustee-identification']['relationship'] ===
          'Grandmother' ||
        state.form['trustee-identification']['relationship'] === 'Sister' ||
        state.form['trustee-identification']['relationship'] === 'Spouse' ||
        state.form['trustee-identification']['relationship'] === 'Testament' ||
        state.form['trustee-identification']['relationship'] === 'Trust'
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['trustee-identification'].title
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (
        state.form['trustee-identification']['relationship'] === 'Brother' ||
        state.form['trustee-identification']['relationship'] === 'Estate' ||
        state.form['trustee-identification']['relationship'] === 'Father' ||
        state.form['trustee-identification']['relationship'] ===
          'Father-In-Law' ||
        state.form['trustee-identification']['relationship'] ===
          'Grandfather' ||
        state.form['trustee-identification']['relationship'] === 'Son' ||
        state.form['trustee-identification']['relationship'] === 'Spouse' ||
        state.form['trustee-identification']['relationship'] === 'Testament' ||
        state.form['trustee-identification']['relationship'] === 'Trust'
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['trustee-identification'].title
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and relationship does not correspond.']
          ) && validates
      }

      // Cell phone number
      validates =
        verify(
          state,
          state.form['trustee-identification']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates
      break

    case 'additional-lives':
      validates = state.form['lives-assured'].reduce((valid, life, counter) => {
        valid = ['first-names', 'surname', 'date-of-birth'].reduce(
          (valid, field) => valid && hasValue(life[field]),
          valid
        )
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['date-of-birth'],
              (v) =>
                dayjs().diff(v, 'year').toString() === life['age-of-member'],
              !validateOnly && { id: `date-of-birth-${counter}` },
              [
                `Date of birth does not match indicated age of ${life['age-of-member']} for ${life['relationship']}`,
              ]
            ) && valid
        }
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['first-names'],
              (v) => (requiredOnly ? hasValue(v) : validName(v)),
              !validateOnly && { id: `first-names-${counter}` },
              requiredOnly ? ['*'] : ['Please enter a valid first-name']
            ) && valid
        }
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['surname'],
              (v) => (requiredOnly ? hasValue(v) : validName(v)),
              !validateOnly && { id: `surname-${counter}` },
              requiredOnly ? ['*'] : ['Please enter a valid surname']
            ) && valid
        }
        return valid
      }, validates)
      break

    case 'payment-details':
      validates =
        verify(
          state,
          state.form['payment-method-selection']['debit-order'] ||
            state.form['payment-method-selection']['stop-order'] ||
            state.form['payment-method-selection']['mobile-wallet'],
          hasValue,
          !validateOnly && { id: 'payment-method-radio-group' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-start-date'],
          hasValue,
          !validateOnly && { id: 'payment-start-date' },
          ['*']
        ) && validates

      break

    case 'debit-order-details':
      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-holder-name'],
          hasValue,
          !validateOnly && { id: 'account-holder-name' },
          ['*']
        ) && validates

      //Validate field name to have no numbers
      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-holder-name'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'account-holder-name' },
          requiredOnly ? ['*'] : ['Please remove all numbers in the field']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-type'],
          hasValue,
          !validateOnly && { id: 'account-type' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-entity-name'],
          hasValue,
          !validateOnly && { id: 'account-entity-name' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-branch-name'],
          hasValue,
          !validateOnly && { id: 'account-branch-name' },
          ['*']
        ) && validates

      //VALIDATE ACCOUNT NUMBER LENGTH BASED ON THE ACCOUNT ENTITY NAME CHOSEN
      if (!requiredOnly) {
        //10 DIGITS
        if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Absa (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Zenith Bank') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 10 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //9 / 10 / 11 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Bank Of Africa' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) =>
                (v.length === 9 || v.length === 10 || v.length === 11) &&
                onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Access Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Consolidated Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Ecobank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Fidelity Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Atlantic Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Bank Nigeria' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Gcb Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'National Investment Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Omni Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Prudential Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Republic Bank (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Stanbic Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Standard Chartered Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Universal Merchant Bank (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 13 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //14 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
          'United Bank For Africa (gh) Ltd'
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 14 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //12 / 13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Cal Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Societe General Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Guaranty Trust (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => (v.length === 12 || v.length === 13) && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //16 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Agricultural Development Bank' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 16 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
      }
      break

    case 'stop-order-details':
      validates = Object.keys(state.form['stop-order-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['stop-order-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'mobile-wallet-details':
      validates = Object.keys(state.form['mobile-wallet-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['mobile-wallet-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'payer-details':
      validates = Object.keys(state.form['payer-person-identification'])
        .filter((k) => ['idpassport-number'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['payer-person-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      break

    case 'payment-details-acceptance':
      if (state.form.documents['signature'].length < 0) {
        validates =
          verify(
            state,
            state.form['payer-signature'],
            hasValue,
            !validateOnly && { id: 'interactive-canvas-html' },
            requiredOnly ? ['*'] : ['Your signature is required']
          ) && validates
      }
      break

    case 'acceptance-screen':
      validates =
        verify(
          state,
          state.form['acceptance-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break
  }

  if (!requiredOnly && !validates) {
    state.local = state.local || {}
    state.local.failed_validation = true
  }
  return validates
}
