/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import { updateItemIn } from '../../../../src/core/dataitems'
import {
  hasValue,
  validEmail,
  verify,
  validAmountRange,
  validMinAmount,
} from '../../../../src/core/validations'

import {
  validVotersID,
  validSSNIT,
  validPassport,
  validDrivers,
  validOldDrivers,
  validNHIS,
  validID,
  validName,
  validCellnumber,
} from '../../validations'
import dayjs from 'dayjs'

const now = dayjs()

export const validate = (state, requiredOnly, validateOnly) => {
  if (process.env.DISABLE_VALIDATIONS === 'true') {
    return true
  }

  let validates = true
  switch (state.step) {
    case 'quotation-details':
      validates =
        verify(
          state,
          state.form['life-assured-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 30 && dayjs().diff(v, 'year') <= 59)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      //gender
      validates =
        verify(
          state,
          state.form['life-assured-identification']['gender'],
          hasValue,
          !validateOnly && { id: 'gender' },
          ['*']
        ) && validates

      //benefit-option
      validates =
        verify(
          state,
          state.form['benefit-option'],
          hasValue,
          !validateOnly && { id: 'benefit-option' },
          ['*']
        ) && validates

      //TO DO: ADD VALIDATION FOR TOTAL SUM ASSURED
      //Validate values of Benefit Option Fields
      if (state.form['benefit-option'] === 'Standalone') {
        if (state.form['standalone-options']['death-sum-assured']) {
          validates =
            verify(
              state,
              state.form['standalone-options']['death-sum-assured'],
              (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 100000)),
              !validateOnly && { id: 'death-sum-assured' },
              requiredOnly
                ? ['*']
                : [
                    'Sum Assured for each option must be at least GHc 100,000.00',
                  ]
            ) && validates
        } else if (
          state.form['standalone-options'][
            'total-personal-disability-sum-assured'
          ]
        ) {
          validates =
            verify(
              state,
              state.form['standalone-options'][
                'total-personal-disability-sum-assured'
              ],
              (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 100000)),
              !validateOnly && { id: 'total-personal-disability-sum-assured' },
              requiredOnly
                ? ['*']
                : [
                    'Sum Assured for each option must be at least GHc 100,000.00',
                  ]
            ) && validates
        } else if (state.form['standalone-options']['illness-sum-assured']) {
          validates =
            verify(
              state,
              state.form['standalone-options']['illness-sum-assured'],
              (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 100000)),
              !validateOnly && { id: 'illness-sum-assured' },
              requiredOnly
                ? ['*']
                : [
                    'Sum Assured for each option must be at least GHc 100,000.00',
                  ]
            ) && validates
        }
      }

      if (state.form['benefit-option'] === 'Accelerated') {
        if (
          state.form['accelerated-options'][
            'death-and-total-personal-disability-sum-assured'
          ]
        ) {
          validates =
            verify(
              state,
              state.form['accelerated-options'][
                'death-and-total-personal-disability-sum-assured'
              ],
              (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 200000)),
              !validateOnly && {
                id: 'death-and-total-personal-disability-sum-assured',
              },
              requiredOnly
                ? ['*']
                : ['Total Sum Assured must be at least GHc 200,000.00']
            ) && validates
        } else if (
          state.form['accelerated-options'][
            'death-total-personal-disability-and-illness-sum-assured'
          ]
        ) {
          validates =
            verify(
              state,
              state.form['accelerated-options'][
                'death-total-personal-disability-and-illness-sum-assured'
              ],
              (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 200000)),
              !validateOnly && {
                id: 'death-total-personal-disability-and-illness-sum-assured',
              },
              requiredOnly
                ? ['*']
                : ['Total Sum Assured must be at least GHc 200,000.00']
            ) && validates
        }
      }
      break

    case 'additional-benefits':
      //Educational Support Benefit validation (only if values are entered)
      if (
        state.form['educational-support-benefit']['educational-support-premium']
      ) {
        validates =
          verify(
            state,
            state.form['educational-support-benefit'][
              'educational-support-premium'
            ],
            (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 200)),
            !validateOnly && { id: 'educational-support-premium' },
            requiredOnly ? ['*'] : ['The minimum premium is GHc 200']
          ) && validates

        validates =
          verify(
            state,
            state.form['educational-support-benefit'][
              'educational-support-term'
            ],
            (v) => (requiredOnly ? hasValue(v) : validAmountRange(v, 7, 18)),
            !validateOnly && { id: 'educational-support-term' },
            requiredOnly
              ? ['*']
              : ['You have to specify the educational support term']
          ) && validates
      }

      //Retirement Benefit validation (only if value is entered)
      if (state.form['retirement-benefit']['retirement-premium']) {
        validates =
          verify(
            state,
            state.form['retirement-benefit']['retirement-premium'],
            (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 200)),
            !validateOnly && { id: 'retirement-premium' },
            requiredOnly ? ['*'] : ['The minimum premium is GHc 200']
          ) && validates

        validates =
          verify(
            state,
            state.form['retirement-benefit']['retirement-annuity-term'],
            (v) => (requiredOnly ? hasValue(v) : validAmountRange(v, 0, 5)),
            !validateOnly && { id: 'retirement-annuity-term' },
            requiredOnly
              ? ['*']
              : ['You have to specify the educational support term']
          ) && validates
      }
      break

    case 'personal-details':
      //Life assured details
      validates = Object.keys(state.form['life-assured-identification'])
        .filter(
          (k) =>
            ['marital-status', 'nationality', 'university-degree'].indexOf(k) <
            0
        )
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      //Validate field name without numbers
      validates =
        verify(
          state,
          state.form['life-assured-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      //Validate the gender & title
      if (state.form['life-assured-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['life-assured-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }
      // Postal Address
      validates = Object.keys(state.form['life-assured-postal-address'])
        .filter((k) => ['addressee', 'street'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-postal-address'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      // Cell phone number
      validates =
        verify(
          state,
          state.form['life-assured-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates
      // Other phone numbers
      if (!requiredOnly) {
        ;['work-phone', 'home-phone', 'fax'].forEach((field) => {
          if (state.form['life-assured-contact-details'][field]) {
            validates =
              verify(
                state,
                state.form['life-assured-contact-details'][field],
                validCellnumber,
                !validateOnly && { id: field },
                ['Please enter a valid Phone Number']
              ) && validates
          }
        })
      }
      // Valid Email address
      validates =
        verify(
          state,
          state.form['life-assured-contact-details'].email,
          (v) => (requiredOnly ? hasValue(v) : validEmail(v)),
          !validateOnly && { id: 'email' },
          requiredOnly ? ['*'] : ['Please enter a valid Email address']
        ) && validates

      // Occupation
      validates =
        verify(
          state,
          state.form['life-assured-employment-details']['occupation'],
          hasValue,
          !validateOnly && { id: 'occupation' },
          ['*']
        ) && validates

      //Voter ID Validation
      if (
        state.form['life-assured-identification']['id-type'] === 'Voters ID'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Ssnit
      if (state.form['life-assured-identification']['id-type'] === 'Ssnit') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Passport Validate
      if (state.form['life-assured-identification']['id-type'] === 'Passport') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Drivers
      if (
        state.form['life-assured-identification']['id-type'] ===
        'Drivers New ID'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //ID
      if (
        state.form['life-assured-identification']['id-type'] === 'National ID'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //NHIS
      if (state.form['life-assured-identification']['id-type'] === 'Nhis') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Old Drivers
      if (
        state.form['life-assured-identification']['id-type'] ===
        'Drivers Old ID'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validOldDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }
      break

    case 'spouse-details':
      // Spouse details
      validates = Object.keys(state.form['spouse-identification'])
        .filter((k) => ['id-type', 'idpassport-number'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['spouse-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      //Validate Spouse birth date
      validates =
        verify(
          state,
          state.form['spouse-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 18 && dayjs().diff(v, 'year') <= 59)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      //Validate field names without numbers
      validates =
        verify(
          state,
          state.form['spouse-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['spouse-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates
      //Validate the gender & title
      if (state.form['spouse-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['spouse-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['spouse-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['spouse-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }
      // Cell phone number
      validates =
        verify(
          state,
          state.form['spouse-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      //ID
      if (state.form['spouse-identification']['id-type'] === 'National ID') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Voters ID
      if (state.form['spouse-identification']['id-type'] === 'Voters ID') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //SSNIT
      if (state.form['spouse-identification']['id-type'] === 'Ssnit') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Passport
      if (state.form['spouse-identification']['id-type'] === 'Passport') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Drivers
      if (state.form['spouse-identification']['id-type'] === 'Drivers New ID') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //NHIS
      if (state.form['spouse-identification']['id-type'] === 'Nhis') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Old Drivers
      if (state.form['spouse-identification']['id-type'] === 'Drivers Old ID') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validOldDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }
      break

    case 'add-other-lives':
    case 'edit-other-lives':
      validates = [
        'first-names',
        'surname',
        'role',
        'date-of-birth',
        'gender',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.local[field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      //Validate Name Fields To Not Accept Numbers
      validates =
        verify(
          state,
          state.local['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.local['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      //Validate the DOB to not be selected in the future
      validates =
        verify(
          state,
          state.local['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().isAfter(v, now)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['Please ensure the date of birth is not selected in the future.']
        ) && validates

      //Validate the gender & role
      if (state.local['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Child',
                  'Cousin',
                  'Daughter',
                  'Family',
                  'Grandchild',
                  'Grandmother',
                  'Main',
                  'Mother',
                  'Mother-in-law',
                  'Niece',
                  'Sister',
                  'Spouse',
                  'Step-Mother',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ["Role and gender combination doesn't match. Please correct."]
          ) && validates
      }

      if (state.local['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Child',
                  'Cousin',
                  'Family',
                  'Father',
                  'Father-in-law',
                  'Grandchild',
                  'Grandfather',
                  'Main',
                  'Nephew',
                  'Son',
                  'Spouse',
                  'Step-Father',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ["Role and gender combination doesn't match. Please correct."]
          ) && validates
      }

      if (
        state.local['role'] === 'Child' ||
        state.local['role'] === 'Daughter' ||
        state.local['role'] === 'Son' ||
        state.local['role'] === 'Grandchild'
      ) {
        validates =
          verify(
            state,
            state.local['date-of-birth'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                (dayjs().diff(v, 'year') >= 0 &&
                  dayjs().diff(v, 'year') <= 21 &&
                  dayjs().isAfter(v, now))),
            !validateOnly && { id: 'date-of-birth' },
            requiredOnly
              ? ['*']
              : ['The age is outside the allowable bounds for this product.']
          ) && validates
      }

      //Handle birth dates selected in the future
      if (
        state.local['role'] === 'Brother' ||
        state.local['role'] === 'Sister'
      ) {
        validates =
          verify(
            state,
            state.local['date-of-birth'],
            (v) => hasValue(v) && (requiredOnly || dayjs().isAfter(v, now)),
            !validateOnly && { id: 'date-of-birth' },
            requiredOnly ? ['*'] : ['Invalid Birth Date.']
          ) && validates
      }

      //Validate Parents & Grandparents Age Limits
      if (
        state.local['role'] === 'Mother' ||
        state.local['role'] === 'Father' ||
        state.local['role'] === 'Mother-in-law' ||
        state.local['role'] === 'Father-in-law' ||
        state.local['role'] === 'Step-father' ||
        state.local['role'] === 'Step-mother' ||
        state.local['role'] === 'Grandmother' ||
        state.local['role'] === 'Grandfather'
      ) {
        const mainAge =
          state.form['life-assured-identification']['date-of-birth']
        const age = dayjs().diff(mainAge, 'year')
        validates =
          verify(
            state,
            state.local['date-of-birth'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                (dayjs().diff(v, 'year') >= age + 13 &&
                  dayjs().diff(v, 'year') <= 74)),
            !validateOnly && { id: 'date-of-birth' },
            requiredOnly
              ? ['*']
              : ['The age is outside the allowable bounds for this product.']
          ) && validates
      }

      //Validate Guardian Age Limit
      if (state.local['role'] === 'Guardian') {
        const mainAge =
          state.form['life-assured-identification']['date-of-birth']
        const age = dayjs().diff(mainAge, 'year')
        validates =
          verify(
            state,
            state.local['date-of-birth'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                (dayjs().diff(v, 'year') >= age + 16 &&
                  dayjs().diff(v, 'year') <= 74)),
            !validateOnly && { id: 'date-of-birth' },
            requiredOnly
              ? ['*']
              : ['The age is outside the allowable bounds for this product.']
          ) && validates
      }

      //Validate Spouse Age Limit
      if (state.local['role'] === 'Spouse') {
        validates =
          verify(
            state,
            state.local['date-of-birth'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                (dayjs().diff(v, 'year') >= 18 &&
                  dayjs().diff(v, 'year') <= 59)),
            !validateOnly && { id: 'date-of-birth' },
            requiredOnly
              ? ['*']
              : ['The age is outside the allowable bounds for this product.']
          ) && validates
      }
      break

    case 'trustee-details':
      validates = Object.keys(state.form['trustee-identification'])
        .filter(
          (k) =>
            ['marital-status', 'idpassport-number', 'id-type'].indexOf(k) < 0
        )
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['trustee-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      //Validate field names without numbers
      validates =
        verify(
          state,
          state.form['trustee-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['trustee-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age
      validates =
        verify(
          state,
          state.form['trustee-identification']['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().diff(v, 'year') >= 18),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      //Validate the gender & title
      if (state.form['trustee-identification'].gender === 'Female') {
        validates =
          verify(
            state,
            state.form['trustee-identification'].title,
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['trustee-identification'].title
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ["Title and Gender combination doesn't match. Please correct."]
          ) && validates
      }

      if (state.form['trustee-identification'].gender === 'Male') {
        validates =
          verify(
            state,
            state.form['trustee-identification'].title,
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['trustee-identification'].title
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ["Title and Gender combination doesn't match. Please correct."]
          ) && validates
      }

      // Cell phone number
      validates =
        verify(
          state,
          state.form['trustee-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      //Validate ID types if selected.

      if (state.form['trustee-identification']['id-type'] === 'Ghana Card') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Voter id
      if (state.form['trustee-identification']['id-type'] === 'Voter id') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Ssnit
      if (state.form['trustee-identification']['id-type'] === 'Ssnit') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Passport
      if (state.form['trustee-identification']['id-type'] === 'Passport') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Drivers
      if (
        state.form['trustee-identification']['id-type'] === 'Drivers New id'
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //NHIS
      if (state.form['trustee-identification']['id-type'] === 'Nhis') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }

      //Old Drivers
      if (
        state.form['trustee-identification']['id-type'] === 'Drivers Old id'
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validOldDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly
              ? ['*']
              : ['Invalid ID Number for chosen ID Type. Please correct.']
          ) && validates
      }
      break

    case 'add-beneficiary':
    case 'edit-beneficiary':
      validates = [
        'first-names',
        'surname',
        'role',
        'gender',
        'date-of-birth',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.local[field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      //Validate Name Fields To Not Accept Numbers
      validates =
        verify(
          state,
          state.local['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.local['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      //Validate the gender & role
      if (state.local['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Child',
                  'Cousin',
                  'Daughter',
                  'Family',
                  'Grandchild',
                  'Grandmother',
                  'Main',
                  'Mother',
                  'Mother-in-law',
                  'Niece',
                  'Sister',
                  'Spouse',
                  'Step-Mother',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ["Role and gender combination doesn't match. Please correct."]
          ) && validates
      }

      if (state.local['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Child',
                  'Cousin',
                  'Family',
                  'Father',
                  'Father-in-law',
                  'Grandchild',
                  'Grandfather',
                  'Main',
                  'Nephew',
                  'Son',
                  'Spouse',
                  'Step-Father',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ["Role and gender combination doesn't match. Please correct."]
          ) && validates
      }
      break

    case 'update-beneficiary-split-percentage':
      if (!requiredOnly) {
        validates =
          Math.ceil(
            Object.keys(state.local || {})
              .filter((k) => k.startsWith('beneficiary-split'))
              .reduce((t, b) => {
                const v = parseFloat(state.local[b])
                return isNaN(v) ? -1.0 : v + t
              }, 0.0)
          ) === 100 && validates
        if (!validateOnly) {
          updateItemIn(
            state.page.item,
            { id: 'update-benefit-split-percentage' },
            {
              errors: validates ? [] : ['The allocation must add up to 100.00'],
            }
          )
        }
      }
      break

    case 'medical-underwriting':
      validates = [
        'planning-other-insurance',
        'other-assurance-past12-months',
        'risky-occupation-hobbies',
        'refused-application-or-increased-premium',
        'insolvency-issue',
        'occupation-not-clerical',
      ].reduce((valid, field) => {
        valid =
          verify(
            state,
            state.form['life-assured-general-habits'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid
        if (state.form['life-assured-general-habits'][field] === 'Yes') {
          let details = `${field}-details`
          valid =
            verify(
              state,
              state.form['life-assured-general-habits'][details],
              hasValue,
              !validateOnly && { id: details },
              ['*']
            ) && valid
        }
        return valid
      }, validates)

      validates =
        verify(
          state,
          state.form['life-assured-general-habits'][
            'cover-exceeds-one-hundred-thousand'
          ],
          hasValue,
          !validateOnly && { id: 'cover-exceeds-one-hundred-thousand' },
          ['*']
        ) && validates

      if (
        state.form['life-assured-general-habits'][
          'cover-exceeds-one-hundred-thousand'
        ] === 'Yes'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-general-habits'][
              'average-monthly-gross-income'
            ],
            hasValue,
            !validateOnly && { id: 'average-monthly-gross-income' },
            ['*']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['life-assured-general-habits']['smoker'] === 'Y' ||
            state.form['life-assured-general-habits']['non-smoker'] === 'Y' ||
            state.form['life-assured-general-habits']['never-smoked'] === 'Y',
          hasValue,
          !validateOnly && { id: 'smoker-options-radio-group' },
          ['*']
        ) && validates

      let age = dayjs().diff(
        state.form['life-assured-identification']['date-of-birth'],
        'year'
      )

      if (state.form['life-assured-general-habits'].smoker === 'Y') {
        let yrs = parseInt(
          state.form['life-assured-general-habits']['years-smoking']
        )

        validates =
          verify(
            state,
            state.form['life-assured-general-habits']['years-smoking'],
            (v) =>
              requiredOnly
                ? hasValue(v)
                : hasValue(v) && !isNaN(yrs) && yrs > 0 && yrs <= age,
            !validateOnly && { id: 'years-smoking' },
            requiredOnly ? ['*'] : ['Please enter a valid amount of years']
          ) && validates

        validates = [
          'cigarettes-per-day',
          'cigars-per-day',
          'pipe',
          'medical-advice-stop-smoking',
        ].reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-general-habits'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      }

      if (state.form['life-assured-general-habits']['non-smoker'] === 'Y') {
        let yrs = parseInt(
          state.form['life-assured-general-habits']['years-not-smoking']
        )
        validates =
          verify(
            state,
            state.form['life-assured-general-habits']['years-not-smoking'],
            (v) =>
              requiredOnly
                ? hasValue(v)
                : hasValue(v) && !isNaN(yrs) && yrs > 0 && yrs <= age,
            !validateOnly && { id: 'years-not-smoking' },
            requiredOnly ? ['*'] : ['Please enter a valid amount of years']
          ) && validates
      }

      validates = [
        'medical-advice-reduce-drinking',
        'excessive-drinking',
        'excessive-drinking-past',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.form['life-assured-general-habits'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'medical-declaration':
      validates = Object.keys(state.form['life-assured-health-details'])
        .filter(
          (k) =>
            [
              'family-history-of-illness',
              'family-history-of-illness-details',
            ].indexOf(k) < 0
        )
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-health-details'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      validates = ['family-history-of-illness'].reduce((valid, field) => {
        valid =
          verify(
            state,
            state.form['life-assured-health-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid
        if (state.form['life-assured-health-details'][field] === 'Yes') {
          let details = `${field}-details`
          valid =
            verify(
              state,
              state.form['life-assured-health-details'][details],
              hasValue,
              !validateOnly && { id: details },
              ['*']
            ) && valid
        }
        return valid
      }, validates)

      break
    case 'health-descriptions':
      validates = state.form['health-concerns'].reduce(
        (itemValid, item, index) =>
          Object.keys(item).reduce(
            (fieldValid, field) =>
              verify(
                state,
                item[field],
                hasValue,
                !validateOnly && { id: `${field}-${index + 1}` },
                ['*']
              ) && fieldValid,
            itemValid
          ),
        validates
      )
      break

    case 'payment-details':
      validates =
        verify(
          state,
          state.form['payment-method-selection']['debit-order'] ||
            state.form['payment-method-selection']['stop-order'],
          hasValue,
          !validateOnly && { id: 'payment-method-radio-group' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-start-date'],
          hasValue,
          !validateOnly && { id: 'payment-start-date' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-frequency'],
          hasValue,
          !validateOnly && { id: 'payment-frequency' },
          ['*']
        ) && validates
      break

    case 'spouse-identification-for-payment':
      validates = Object.keys(state.form['payer-person-identification']).reduce(
        (valid, field) =>
          verify(
            state,
            state.local[field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      //Validate Field Names to only be letters
      validates =
        verify(
          state,
          state.local['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.local['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      //Validate the DOB to not be selected in the future
      validates =
        verify(
          state,
          state.local['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().isAfter(v, now)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly ? ['*'] : ['Invalid birth date.']
        ) && validates
      break

    case 'debit-order-details':
      validates = Object.keys(state.form['payment-bank-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-bank-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates

      //Validate field name to have no numbers
      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-holder-name'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'account-holder-name' },
          requiredOnly ? ['*'] : ['Please enter a valid name']
        ) && validates

      //VALIDATE ACCOUNT NUMBER LENGTH BASED ON THE ACCOUNT ENTITY NAME CHOSEN
      if (!requiredOnly) {
        //10 DIGITS
        if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Absa (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Zenith Bank') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 10,
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //9 / 10 / 11 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Bank Of Africa' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 9 || v.length === 10 || v.length === 11,
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Access Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Consolidated Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Ecobank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Fidelity Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Atlantic Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Bank Nigeria' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Gcb Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'National Investment Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Omni Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Prudential Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Republic Bank (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Stanbic Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Standard Chartered Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Universal Merchant Bank (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 13,
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //14 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
          'United Bank For Africa (gh) Ltd'
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 14,
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //12 / 13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Cal Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Societe General Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Guaranty Trust (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 12 || v.length === 13,
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //16 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Agricultural Development Bank' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 16,
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
      }
      break

    case 'stop-order-details':
      validates = Object.keys(state.form['payment-employment-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-employment-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break

    case 'upload-id-document':
      const section = state.step.substring(7)
      validates = Object.keys(state.form[section]).reduce(
        (valid, field) =>
          verify(
            state,
            state.form[section][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'acceptance-screen':
      validates =
        verify(
          state,
          state.form['acceptance-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break
  }

  if (!requiredOnly && !validates) {
    state.local = state.local || {}
    state.local.failed_validation = true
  }
  return validates
}
