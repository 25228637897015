/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const form = {
  'life-assured-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'marital-status': null,
    gender: null,
    title: null,
    'idpassport-number': null,
    'digital-address': null,
    ghanaian: null,
    'other-nationality': null,
  },
  'main-cover-level': {
    'ghc-250000': null,
    'ghc-500000': null,
    'ghc-750000': null,
    'ghc-1000000': null,
  },
  'cash-bonus-premium': null,
  'inflation-protector': null,
  'life-assured-identification-type': {
    dvla: null,
    passport: null,
    'voter-id': null,
    nhis: null,
    ssnit: null,
    'nia-ghana-card': null,
  },
  'life-assured-contact-details': {
    'cell-phone': null,
    email: null,
  },
  'life-assured-postal-details': {
    addressee: null,
    'po-box-or-private-bag': null,
    region: null,
    town: null,
  },
  'life-assured-residential-address': {
    suburb: null,
    street: null,
  },
  'lives-assured': [],
  'trustee-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    title: null,
    relationship: null,
    'cell-phone': null,
  },
  'payment-method-selection': {
    'debit-order': null,
    'stop-order': null,
  },
  'payment-start-date': null,
  'payment-frequency': {
    monthly: null,
    quarterly: null,
    'bi-annually': null,
    annually: null,
  },
  'payer-person': {
    myself: null,
    'my-spouse': null,
  },
  'payment-bank-details': {
    'account-holder-name': null,
    'account-type': null,
    'account-entity-name': null,
    'account-branch-name': null,
    'account-number': null,
  },
  'stop-order-details': {
    'employer-organisation': null,
    'employee-number': null,
  },
  'mobile-wallet-details': {
    'mobile-wallet-network-provider': null,
    'mobile-wallet-number': null,
  },
  'payer-person-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
  },
  documents: {
    'id-document': [],
    signature: [],
  },
  'payer-signature': null,
  'acceptance-signature': null,
  'application-acceptance-date': null,
  'application-accepted': null,
}
