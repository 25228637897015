/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import { validAgentId } from './validations'

export const migrations = {
  1: (state) => {
    if (state.environment.agentContractId) {
      const oldAgentId = state.environment.agentContractId
      // Migrate validated agentIds
      if (!validAgentId(oldAgentId)) {
        delete state.environment.agentContractId
        state.environment.postponed.forEach((f) => {
          if (f.process && f.process.agent === oldAgentId) {
            delete f.process.agent
          }
        })
      }
    }
    return state
  },
  2: (state) => {
    if (process.env.ENTERPRISE.toLowerCase() === 'elac') {
      const pages = {
        'lifetime-needs-plus':
          require('../../enterprises/ELAC/products/lifetime-needs-plus/pages')
            .pages,
        'educare-plan-enhanced':
          require('../../enterprises/ELAC/products/educare-plan-enhanced/pages')
            .pages,
        'family-income-protection':
          require('../../enterprises/ELAC/products/family-income-protection/pages')
            .pages,
      }
      state.environment.postponed.forEach((app) => {
        if (
          [
            'lifetime-needs-plus',
            'educare-plan-enhanced',
            'family-income-protection',
          ].indexOf(app.process.product) > -1
        ) {
          if (!app.process.form['payer-person']) {
            app.process.form['payer-person'] = {
              myself: null,
              'my-spouse': null,
            }
            if (
              [
                'debit-order-details',
                'stop-order-details',
                'spouse-details-for-payment',
                'upload-documents',
                'upload-document-pages',
                'acceptance-screen',
                'completed',
              ].indexOf(app.process.step) > -1
            ) {
              app.process.step = 'payment-details'
              app.process.page = pages[app.process.product]['payment-details']
            }
          }
        }
      })
      if (state.environment.currentProcess) {
        if (
          [
            'lifetime-needs-plus',
            'educare-plan-enhanced',
            'family-income-protection',
          ].indexOf(state.environment.currentProcess.product) > -1
        ) {
          if (!state.environment.currentProcess.form['payer-person']) {
            state.environment.currentProcess.form['payer-person'] = {
              myself: null,
              'my-spouse': null,
            }
            if (
              [
                'debit-order-details',
                'stop-order-details',
                'spouse-details-for-payment',
                'upload-documents',
                'upload-document-pages',
                'acceptance-screen',
                'completed',
              ].indexOf(state.environment.currentProcess.step) > -1
            ) {
              state.environment.currentProcess.step = 'payment-details'
              state.environment.currentProcess.page =
                pages[state.environment.currentProcess.product][
                  'payment-details'
                ]
              state.environment.currentProcess.local = null
            }
          }
        }
      }
    }
    return state
  },
  3: (state) => {
    if (process.env.ENTERPRISE.toLowerCase() === 'elac') {
      const pages = {
        'savings-smart-plan':
          require('../../enterprises/ELAC/products/savings-smart-plan/pages')
            .pages,
        'funeral-smart-plan':
          require('../../enterprises/ELAC/products/funeral-smart-plan/pages')
            .pages,
        bloom: require('../../enterprises/ELAC/products/bloom/pages').pages,
        'family-income-security-plan':
          require('../../enterprises/ELAC/products/family-income-security-plan/pages')
            .pages,
        'transition-plan-enhanced':
          require('../../enterprises/ELAC/products/transition-plan-enhanced/pages')
            .pages,
        'family-funeral-plan-unlimited':
          require('../../enterprises/ELAC/products/family-funeral-plan-unlimited/pages')
            .pages,
      }
      state.environment.postponed.forEach((app) => {
        if (
          ['savings-smart-plan', 'funeral-smart-plan'].indexOf(
            app.process.product
          ) > -1
        ) {
          if (app.process.form['payment-method-selection']) {
            const mobileselected =
              app.process.form['payment-method-selection']['mobile-wallet'] ===
              'Y'
            delete app.process.form['payment-method-selection']['mobile-wallet']
            if (
              mobileselected &&
              [
                'mobile-wallet-details',
                'payment-details-verification',
                'payer-details',
                'payment-details-acceptance',
                'upload-documents',
                'upload-document-pages',
                'acceptance-screen',
              ].indexOf(app.process.step) > -1
            ) {
              app.process.step = 'payment-details'
              app.process.page = pages[app.process.product]['payment-details']
            }
          }
        } else if (
          ['bloom', 'family-income-security-plan'].indexOf(
            app.process.product
          ) > -1
        ) {
          if (app.process.form['payment-method']) {
            const mobileselected =
              app.process.form['payment-method']['mobile-wallet'] === 'Y'
            delete app.process.form['payment-method']['mobile-wallet']
            if (
              mobileselected &&
              [
                'mobile-wallet-details',
                'upload-documents',
                'upload-document-pages',
                'acceptance-screen',
              ].indexOf(app.process.step) > -1
            ) {
              app.process.step = 'payment-details'
              app.process.page = pages[app.process.product]['payment-details']
            }
          }
        } else if (
          ['transition-plan-enhanced', 'family-funeral-plan-unlimited'].indexOf(
            app.process.product
          ) > -1
        ) {
          if (app.process.form['mobile-wallet']) {
            const mobileselected = app.process.form['mobile-wallet'] === 'Y'
            delete app.process.form['mobile-wallet']
            if (
              mobileselected &&
              [
                'mobile-wallet-details',
                'upload-documents',
                'upload-document-pages',
                'acceptance-screen',
                'completed',
              ].indexOf(app.process.step) > -1
            ) {
              app.process.step = 'payment-details'
              app.process.page = pages[app.process.product]['payment-details']
            }
          }
        }
      })
      if (state.environment.currentProcess) {
        if (
          ['savings-smart-plan', 'funeral-smart-plan'].indexOf(
            state.environment.currentProcess.product
          ) > -1
        ) {
          if (
            state.environment.currentProcess.form['payment-method-selection']
          ) {
            const mobile =
              state.environment.currentProcess.form['payment-method-selection'][
                'mobile-wallet'
              ] === 'Y'
            delete state.environment.currentProcess.form[
              'payment-method-selection'
            ]['mobile-wallet']
            if (
              mobile &&
              [
                'mobile-wallet-details',
                'payment-details-verification',
                'payer-details',
                'payment-details-acceptance',
                'upload-documents',
                'upload-document-pages',
                'acceptance-screen',
              ].indexOf(state.environment.currentProcess.step) > -1
            ) {
              state.environment.currentProcess.step = 'payment-details'
              state.environment.currentProcess.page =
                pages[state.environment.currentProcess.product][
                  'payment-details'
                ]
              state.environment.currentProcess.local = null
            }
          }
        } else if (
          ['bloom', 'family-income-security-plan'].indexOf(
            state.environment.currentProcess.product
          ) > -1
        ) {
          if (state.environment.currentProcess.form['payment-method']) {
            const mobile =
              state.environment.currentProcess.form['payment-method'][
                'mobile-wallet'
              ] === 'Y'
            delete state.environment.currentProcess.form['payment-method'][
              'mobile-wallet'
            ]
            if (
              mobile &&
              [
                'mobile-wallet-details',
                'upload-documents',
                'upload-document-pages',
                'acceptance-screen',
              ].indexOf(state.environment.currentProcess.step) > -1
            ) {
              state.environment.currentProcess.step = 'payment-details'
              state.environment.currentProcess.page =
                pages[state.environment.currentProcess.product][
                  'payment-details'
                ]
              state.environment.currentProcess.local = null
            }
          }
        } else if (
          ['transition-plan-enhanced', 'family-funeral-plan-unlimited'].indexOf(
            state.environment.currentProcess.product
          ) > -1
        ) {
          if (state.environment.currentProcess.form['mobile-wallet']) {
            const mobile =
              state.environment.currentProcess.form['mobile-wallet'] === 'Y'
            delete state.environment.currentProcess.form['mobile-wallet']
            if (
              mobile &&
              [
                'mobile-wallet-details',
                'upload-documents',
                'upload-document-pages',
                'acceptance-screen',
                'completed',
              ].indexOf(state.environment.currentProcess.step) > -1
            ) {
              state.environment.currentProcess.step = 'payment-details'
              state.environment.currentProcess.page =
                pages[state.environment.currentProcess.product][
                  'payment-details'
                ]
              state.environment.currentProcess.local = null
            }
          }
        }
      }
    }
    return state
  },
}
