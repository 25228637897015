/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import classNames from 'classnames'
import Page from '../components/Page'
import AttachedText from '../components/AttachedText'
import Form from '../components/Form'
import Button from '../components/Button'
import Input from '../components/Input'
import React, { useState } from 'react'

const InputModal = ({
  name,
  title,
  label,
  onChange,
  validator,
  invalidMessage,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isValid, setValid] = useState(false)
  const onSubmit = (name, value) => {
    const valid =
      typeof validator === 'function' ? validator(value) : value.length > 0
    setValid(valid)
    if (valid) {
      onChange(name, value)
    }
  }
  return (
    <Page pageClass='modal'>
      <AttachedText text={[title]} />
      <Form
        items={[name]}
        incomplete={[]}
        onSubmit={(evt) => {
          evt.preventDefault()
          onSubmit(name, inputValue)
        }}
      >
        <Input
          label={label}
          value={inputValue}
          key={name}
          id={name}
          uid={name}
          readonly={false}
          inputType='text'
          itemType='field'
          setFocus={false}
          required={false}
          errors={
            inputValue.length === 0 || isValid
              ? []
              : [invalidMessage || 'The value is not valid']
          }
          hideLabel={false}
          itemIndex={1}
          tooltip={null}
          onChange={(e) => {
            setValid(validator(e.target.value))
            setInputValue(e.target.value)
          }}
        />
        <div className={classNames({ 'button-group': true, control: true })}>
          <Button
            id='set-input-value'
            label='Continue'
            onClick={() => onSubmit(name, inputValue)}
          />
        </div>
      </Form>
    </Page>
  )
}

export default InputModal
