/*
 * Enterprise Life offline validations
 */
export const hasValue = (value) => !!(value && value !== '')

export const validAgentId = (agentId) => {
  return /^[a-zA-Z0-9]{11}$/.test('' + agentId)
}

export const validCellnumber = (value) =>
  hasValue(value) &&
  /^(?:\+233[1-9]{1}[0-9]{8})$|^(?:\+(?!233)[0-9]{1,3}[1-9]{1}[0-9]{8})$/.test(
    value.toString()
  )

export const validName = (value) =>
  hasValue(value) && /^[^0-9]+$/.test(value.toString())
export const validVotersID = (value) =>
  hasValue(value) && /^[0-9]{10}$/.test(value.toString())
export const validNHIS = (value) =>
  hasValue(value) && /^[0-9]{8}$/.test(value.toString())
export const validSSNIT = (value) =>
  hasValue(value) && /^[a-zA-Z]{1}[a-zA-Z0-9]{12}$/.test(value.toString())
export const validPassport = (value) =>
  hasValue(value) && /^[a-zA-Z]{1}[a-zA-Z0-9]{7}$/.test(value.toString())
export const validDrivers = (value) =>
  hasValue(value) &&
  /^[a-zA-Z0-9]{1,8}[a-zA-Z]{1}[a-zA-Z0-9]{1}$/.test(value.toString())
export const validOldDrivers = (value) =>
  (hasValue(value) && /^[a-zA-Z0-9]{7,8}$/.test(value.toString())) ||
  (hasValue(value) && /^[0-9]{6}$/.test(value.toString()))
export const validID = (value) =>
  hasValue(value) &&
  /^[a-zA-Z]{3}[-]*[0-9]{9}[-]*[0-9]{1}$/.test(value.toString())
export const onlyDigits = (value) =>
  hasValue(value) && /^[0-9]+$/.test(value.toString())
