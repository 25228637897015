/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'

const AttachedText = ({ text }) =>
  text &&
  text.length > 0 && (
    <div className='attached-text'>
      <p>
        {text.map((line, lineno) => (
          <span key={lineno}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  )

export default AttachedText
