/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'

const Image = ({ id, content, label, extraStyle }) =>
  content && (
    <img
      src={`data:${content.type};base64,${content.data}`}
      id={id}
      className={extraStyle}
      alt={label}
    />
  )

export default Image
