/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import numbro from 'numbro'
import dayjs from 'dayjs'
import classNames from 'classnames'
import InputSymbol, { inputSymbolContainerClasses } from './InputSymbol'
import ValidationErrors from './ValidationErrors'

const useTooltips = process.env.APPLICATION_USE_TOOLTIPS === 'true'

const Input = ({
  value,
  id,
  label,
  readonly,
  inputType,
  itemType,
  setFocus,
  required,
  errors,
  hideLabel,
  itemIndex,
  tooltip,
  prefix,
  suffix,
  onChange,
}) => {
  const elementId = hideLabel && itemIndex ? `${id}-${itemIndex}` : id
  let asText = false
  const symbolClasses =
    inputSymbolContainerClasses(id, inputType, itemType, prefix, suffix) || {}
  let renderedValue = value
  if (readonly && itemType === 'amount' && value) {
    renderedValue = numbro(value).format({
      thousandSeparated: true,
      mantissa: 2,
    })
    asText = true
  } else if (readonly && inputType === 'date') {
    asText = !(value && dayjs(value, 'YYYYMMDD').isValid())
    renderedValue = asText
      ? value
      : dayjs(value, 'YYYYMMDD').format('YYYY-MM-DD')
  }
  return (
    <div
      className={classNames({
        editable: !readonly,
        field: true,
        'has-tooltip': useTooltips && tooltip,
        'has-value': value.length > 0,
        input: true,
        ...symbolClasses,
      })}
    >
      {!hideLabel && (
        <label
          htmlFor={elementId}
          className={classNames({ hidden: hideLabel, label: true })}
        >
          {label}
        </label>
      )}
      <div className='input-group'>
        <input
          type={asText ? 'text' : inputType}
          autoFocus={setFocus}
          required={required}
          className={classNames({
            disabled: readonly,
            'has-error': errors && errors.length > 0,
            'is-required': required,
            placeholder: value.length === 0 && !useTooltips && tooltip,
          })}
          name={label}
          id={elementId}
          placeholder={useTooltips ? null : tooltip}
          readOnly={readonly}
          value={renderedValue}
          onChange={onChange}
          onWheel={(e) => inputType === 'number' && e.target.blur()}
          noValidate
          autoComplete='off'
        />
        <InputSymbol
          id={id}
          inputType={inputType}
          itemType={itemType}
          prefix={prefix}
          suffix={suffix}
        />
      </div>
      {errors && errors.length > 0 && <ValidationErrors errors={errors} />}
    </div>
  )
}

export default Input
