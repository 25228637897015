/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React, { useEffect } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setOnlineStatus } from '../core/actions'

const OnlineStatus = ({ online, setStatus }) => {
  const setOnline = () => setStatus(true)
  const setOffline = () => setStatus(false)
  useEffect(() => {
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)
    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])
  return <div className={classNames({ online: online, offline: !online })} />
}

export default connect(
  state => ({ online: state.environment.online }),
  { setStatus: setOnlineStatus }
)(OnlineStatus)
