/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'

const ListItem = ({ itemIndex, readonly, contains, extraClass, children }) => (
  <li
    id={`item-${itemIndex}`}
    className={[
      classNames({ 'editable-content': !readonly }),
      `has-${contains}-item`,
      extraClass || '',
    ]
      .join(' ')
      .trim()}
  >
    {children}
  </li>
)

export default ListItem
