/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const form = {
  'cover-details': null,
  'main-cover': null,
  'policy-term': {
    'policy-term-ten': null,
    'policy-term-fifteen': null,
  },
  'hospital-premium': null,
  'personal-accident': null,
  'optional-benefits': {
    'memorial-cover': null,
    '7-day-celebration-cover': null,
    '40-day-celebration-cover': null,
    'accident-benefit-double': null,
  },
  'cash-bonus': null,
  'inflation-protector': null,
  'lives-assured': [],
  'life-assured-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    title: null,
    'marital-status': null,
    'other-nationality': null,
    gender: null,
    occupation: null,
    ghanaian: null,
  },
  'life-assured-contact-details': {
    'cell-phone': null,
    email: null,
    'digital-address': null,
  },
  'life-assured-postal-address': {
    addressee: null,
    'po-box-or-private-bag': null,
    'area-code': null,
    street: null,
    suburb: null,
    town: null,
    region: null,
  },
  'identification-type': {
    dvla: null,
    passport: null,
    'voter-id': null,
    nhis: null,
    ssnit: null,
    'nia-ghana-card': null,
  },
  'trustee-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    title: null,
    relationship: null,
    'cell-phone': null,
  },
  'debit-order': null,
  'stop-order': null,
  'payment-bank-details': {
    'account-holder-name': null,
    'account-entity-name': null,
    'account-type': null,
    'account-branch-name': null,
    'account-number': null,
  },
  'payer-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
  },
  'mobile-wallet-details': {
    'mobile-wallet-number': null,
    'mobile-wallet-network-provider': null,
  },
  documents: {
    'id-document': [],
    'passport-document': [],
  },
  'payment-start-date': null,
  'payment-frequency': {
    monthly: null,
    quarterly: null,
    annually: null,
    'bi-annually': null,
  },
  'payer-signature': null,
  'acceptance-signature': null,
  'application-acceptance-date': null,
  'application-accepted': null,
}
