/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */

import {
  pages,
  familyMemberLabels,
  familyMemberOptions,
  additionalLife,
  createFamilyMember,
  familyMemberTablePrefix,
  familyMemberItem,
  buttonFromLifeAssured,
  idFromLifeAssured,
  paymentDetailsSummary,
} from './transition-plan-enhanced/pages'
import shortid from 'shortid'
import {
  updateItemIn,
  createButton,
  slugify,
  createItem,
  findItemsIn,
} from '../../../src/core/dataitems'
import { form } from './transition-plan-enhanced/form'

import { cancelProcess, completeProcess } from '../../../src/core/actions'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import { original } from 'immer'
import { validate } from './transition-plan-enhanced/validations'

const dateFormat = 'D MMMM YYYY'

const amountString = (f) =>
  f
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/, '')

const mainLifeAge = createSelector(
  (form) => form['life-assured-identification']['date-of-birth'],
  (dob) => dayjs().diff(dob, 'year')
)

const productOption = createSelector(
  (form) => form['cover-options']['ghs-1000000'],
  (form) => form['cover-options']['ghs-1500000'],
  (form) => form['cover-options']['ghs-2000000'],
  (form) => form['cover-options']['ghs-2500000'],
  (form) => form['cover-options']['ghs-3000000'],
  (form) => form['cover-options']['ghs-5000000'],
  (form) => form['cover-options']['ghs-7000000'],
  (form) => form['cover-options']['ghs-10000000'],
  (one, two, three, four, five, six, seven, eight) => {
    if (one === 'Y') {
      return 1
    } else if (two === 'Y') {
      return 2
    } else if (three === 'Y') {
      return 3
    } else if (four === 'Y') {
      return 4
    } else if (five === 'Y') {
      return 5
    } else if (six === 'Y') {
      return 6
    } else if (seven === 'Y') {
      return 7
    } else if (eight === 'Y') {
      return 8
    }
    return 0
  }
)

const benefitRate = createSelector(productOption, (coveroption) => {
  if (coveroption === 1) {
    return 10000
  } else if (coveroption === 2) {
    return 15000
  } else if (coveroption === 3) {
    return 20000
  } else if (coveroption === 4) {
    return 25000
  } else if (coveroption === 5) {
    return 30000
  } else if (coveroption === 6) {
    return 50000
  } else if (coveroption === 7) {
    return 70000
  } else if (coveroption === 8) {
    return 100000
  }
})

//MAIN & SPOUSE INDEX THE SAME
var main_age_index = function (age) {
  if (18 <= age && age <= 25) {
    return 0
  } else if (26 <= age && age <= 30) {
    return 1
  } else if (31 <= age && age <= 35) {
    return 2
  } else if (36 <= age && age <= 40) {
    return 3
  } else if (41 <= age && age <= 45) {
    return 4
  } else if (46 <= age && age <= 50) {
    return 5
  } else if (51 <= age && age <= 55) {
    return 6
  } else if (56 <= age && age <= 60) {
    return 7
  } else if (61 <= age && age <= 65) {
    return 8
  } else if (66 <= age && age <= 70) {
    return 9
  } else if (71 <= age && age <= 75) {
    return 10
  } else if (76 <= age && age <= 80) {
    return 11
  } else if (81 <= age && age <= 85) {
    return 12
  } else if (86 <= age && age <= 90) {
    return 13
  } else if (91 <= age && age <= 95) {
    return 14
  } else if (96 <= age && age <= 100) {
    return 15
  } else {
    return -1
  }
}
//CHILD AGE
var child_age_index = function (age) {
  if (1 <= age && age <= 5) {
    return 0
  } else if (6 <= age && age <= 10) {
    return 1
  } else if (11 <= age && age <= 15) {
    return 2
  } else if (16 <= age && age <= 21) {
    return 3
  } else {
    return -1
  }
}

//GRANDPARENTS AGE
var grandparents_age_index = function (age) {
  if (44 <= age && age <= 50) {
    return 0
  } else if (51 <= age && age <= 55) {
    return 1
  } else if (56 <= age && age <= 60) {
    return 2
  } else if (61 <= age && age <= 65) {
    return 3
  } else if (66 <= age && age <= 70) {
    return 4
  } else if (71 <= age && age <= 75) {
    return 5
  } else if (76 <= age && age <= 80) {
    return 6
  } else if (81 <= age && age <= 85) {
    return 7
  } else if (86 <= age && age <= 90) {
    return 8
  } else if (91 <= age && age <= 95) {
    return 9
  } else if (96 <= age && age <= 100) {
    return 10
  } else {
    return -1
  }
}

//SIBLINGS AGE
var sibling_age_index = function (age) {
  if (0 <= age && age <= 5) {
    return 0
  } else if (6 <= age && age <= 10) {
    return 1
  } else if (11 <= age && age <= 15) {
    return 2
  } else if (16 <= age && age <= 20) {
    return 3
  } else if (21 <= age && age <= 25) {
    return 4
  } else if (26 <= age && age <= 30) {
    return 5
  } else if (31 <= age && age <= 35) {
    return 6
  } else if (36 <= age && age <= 40) {
    return 7
  } else if (41 <= age && age <= 45) {
    return 8
  } else if (46 <= age && age <= 50) {
    return 9
  } else if (51 <= age && age <= 55) {
    return 10
  } else if (56 <= age && age <= 60) {
    return 11
  } else if (61 <= age && age <= 65) {
    return 12
  } else if (66 <= age && age <= 70) {
    return 13
  } else if (71 <= age && age <= 75) {
    return 14
  } else if (76 <= age && age <= 80) {
    return 15
  } else if (81 <= age && age <= 85) {
    return 16
  } else if (86 <= age && age <= 90) {
    return 17
  } else if (91 <= age && age <= 95) {
    return 18
  } else if (96 <= age && age <= 100) {
    return 19
  } else {
    return -1
  }
}

//ADD THESE IN THE RATES FILE PERHAPS
//TEST MAIN RATES
var main_rates = [
  [20.37, 21.37, 22.4, 23.43, 24.44, 28.62, 32.82, 39.14],
  [20.95, 22.23, 23.52, 24.82, 26.11, 31.42, 36.81, 45.01],
  [21.96, 23.7, 25.46, 27.24, 29.03, 36.35, 43.83, 55.21],
  [23.76, 26.3, 28.91, 31.54, 34.21, 45.08, 56.17, 72.91],
  [26.68, 30.6, 34.61, 38.65, 42.71, 59.3, 76.05, 101.26],
  [31.14, 37.15, 43.28, 49.47, 55.66, 80.75, 105.92, 143.74],
  [37.63, 46.74, 55.91, 65.21, 74.52, 111.84, 149.22, 205.51],
  [47.12, 60.65, 74.26, 87.94, 101.7, 156.65, 211.66, 294.63],
  [60.87, 80.74, 100.83, 120.79, 140.99, 221.39, 301.89, 423.28],
  [80.68, 109.8, 139.21, 168.5, 197.86, 315.15, 432.63, 609.69],
  [109.35, 151.83, 194.76, 237.6, 280.22, 451.01, 622.15, 879.95],
  [150.62, 212.41, 274.86, 337.12, 399.03, 647.16, 895.85, 1270.27],
  [209.73, 299.33, 389.39, 479.47, 569.0, 927.98, 1287.79, 1829.26],
  [293.15, 422.47, 551.7, 681.23, 809.9, 1326.18, 1843.63, 2622.07],
  [416.2, 603.56, 790.86, 978.58, 1164.99, 1913.56, 2663.64, 3791.63],
  [649.91, 945.5, 1241.55, 1538.18, 1832.99, 3016.26, 4201.71, 5977.61],
]

//TEST SPOUSE RATES
var spouse_rates = [
  [2.16, 3.24, 4.32, 5.4, 6.47, 10.79, 15.1, 21.59],
  [2.77, 4.15, 5.53, 6.92, 8.29, 13.84, 19.38, 27.68],
  [3.79, 5.69, 7.58, 9.48, 11.37, 18.97, 26.55, 37.93],
  [5.58, 8.37, 11.15, 13.95, 16.74, 27.9, 39.06, 55.79],
  [8.56, 12.83, 17.1, 21.39, 25.67, 42.78, 59.9, 85.56],
  [12.34, 18.49, 24.64, 30.83, 37.0, 61.66, 86.33, 123.32],
  [17.63, 26.43, 35.22, 44.06, 52.88, 88.12, 123.38, 176.25],
  [25.88, 38.76, 51.66, 64.62, 77.56, 129.26, 180.97, 258.51],
  [37.8, 56.61, 75.44, 94.36, 113.15, 188.75, 264.0, 377.49],
  [55.04, 82.46, 109.91, 137.47, 164.73, 274.96, 384.38, 549.92],
  [79.97, 119.83, 159.72, 199.75, 239.36, 399.54, 558.58, 799.07],
  [115.55, 173.14, 230.79, 288.62, 345.83, 577.29, 807.11, 1154.55],
  [165.25, 247.51, 329.92, 412.58, 494.38, 825.23, 1153.74, 1650.42],
  [232.35, 348.21, 464.17, 580.42, 695.68, 1159.97, 1623.32, 2321.78],
  [322.34, 483.35, 644.35, 805.66, 965.89, 1609.36, 2253.74, 3219.73],
  [496.87, 745.3, 993.59, 1240.38, 1489.38, 2481.77, 3475.38, 4961.29],
]

//TEST CHILD RATES
var child_rates = [
  [1.77, 2.65, 3.53, 4.41, 5.29, 8.81, 12.35, 17.64],
  [1.82, 2.73, 3.64, 4.55, 5.46, 9.1, 12.74, 18.19],
  [1.99, 2.98, 3.98, 4.97, 5.96, 9.93, 13.91, 19.85],
  [14.59, 15.12, 15.65, 16.18, 16.7, 18.81, 20.92, 24.08],
]

//GRANDPARENTS RATES
var grandparents_rates = [
  [15.02, 22.52, 30.01, 37.54, 45.06, 75.1, 105.14, 150.19],
  [22.85, 34.26, 45.65, 57.11, 68.54, 114.23, 159.93, 228.45],
  [33.6, 50.37, 67.14, 83.98, 100.79, 167.97, 235.17, 335.94],
  [49.23, 73.74, 98.28, 122.93, 147.53, 245.88, 344.24, 491.75],
  [71.94, 107.74, 143.6, 179.61, 215.36, 359.26, 502.51, 718.51],
  [105.0, 157.33, 209.7, 262.27, 314.28, 524.59, 733.34, 1049.16],
  [153.08, 229.42, 305.8, 382.44, 458.29, 764.94, 1069.44, 1529.86],
  [222.43, 333.37, 444.37, 555.72, 665.93, 1111.54, 1554.08, 2223.03],
  [321.58, 481.94, 642.42, 803.37, 962.7, 1606.85, 2246.67, 3213.64],
  [468.71, 702.57, 936.55, 1171.11, 1403.61, 2340.22, 3275.3, 4684.62],
  [817.35, 1224.44, 1632.26, 2037.4, 2446.67, 4076.29, 5709.08, 8154.98],
]

//SIBLINGS RATE TABLES
var siblings_rates = [
  [2.08, 3.12, 4.15, 5.2, 6.23, 10.38, 14.53, 20.78],
  [2.1, 3.14, 4.19, 5.24, 6.28, 10.46, 14.65, 20.95],
  [2.16, 3.24, 4.32, 5.4, 6.48, 10.8, 15.11, 21.61],
  [2.33, 3.49, 4.65, 5.81, 6.97, 11.62, 16.25, 23.25],
  [2.68, 4.02, 5.36, 6.71, 8.04, 13.41, 18.77, 26.83],
  [3.38, 5.07, 6.75, 8.45, 10.13, 16.89, 23.65, 33.79],
  [4.64, 6.96, 9.28, 11.61, 13.93, 23.22, 32.51, 46.44],
  [6.85, 10.27, 13.69, 17.12, 20.55, 34.25, 47.95, 68.5],
  [10.54, 15.8, 21.05, 26.34, 31.61, 52.68, 73.75, 105.35],
  [15.21, 22.81, 30.39, 38.02, 45.63, 76.05, 106.47, 152.09],
  [21.77, 32.64, 43.5, 54.41, 65.3, 108.83, 152.36, 217.65],
  [32.0, 47.95, 63.9, 79.93, 95.93, 159.88, 223.84, 319.76],
  [46.83, 70.14, 93.49, 116.93, 140.2, 233.88, 327.15, 467.76],
  [68.35, 102.41, 136.5, 170.72, 204.56, 341.46, 477.36, 682.92],
  [99.56, 149.21, 198.89, 248.73, 298.03, 497.51, 695.55, 994.99],
  [144.37, 216.35, 288.38, 360.64, 432.11, 721.34, 1008.48, 1442.64],
  [207.5, 310.86, 414.37, 518.18, 620.94, 1036.43, 1449.02, 2072.82],
  [294.39, 441.26, 588.23, 735.53, 881.68, 1470.09, 2057.3, 2939.38],
  [415.67, 622.78, 830.24, 1038.07, 1244.57, 2073.73, 2904.01, 4145.59],
  [715.66, 1071.74, 1428.71, 1783.14, 2141.41, 3568.19, 4996.86, 7133.03],
]

//WIDER RATES
var wider_rates = [
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [4.85, 7.27, 9.69, 12.12, 14.54, 24.24, 33.93, 48.47],
  [6.91, 10.35, 13.79, 17.25, 20.71, 34.51, 48.31, 69.01],
  [10.41, 15.59, 20.78, 25.99, 31.2, 51.99, 72.79, 103.99],
  [15.38, 23.04, 30.71, 38.42, 46.11, 76.84, 107.58, 153.68],
  [22.54, 33.78, 45.01, 56.31, 67.58, 112.63, 157.68, 225.25],
  [33.11, 49.63, 66.15, 82.74, 99.31, 165.5, 231.72, 331.01],
  [48.48, 72.63, 96.8, 121.08, 145.31, 242.18, 339.06, 484.35],
  [70.84, 106.1, 141.42, 176.88, 212.08, 353.79, 494.87, 707.57],
  [103.37, 154.89, 206.45, 258.2, 309.4, 516.45, 721.99, 1032.89],
  [150.55, 225.62, 300.74, 376.11, 450.69, 752.29, 1051.76, 1504.55],
  [218.21, 327.03, 435.91, 545.14, 653.23, 1090.36, 1524.48, 2180.68],
  [313.99, 470.47, 627.14, 784.24, 939.79, 1566.87, 2193.02, 3137.12],
  [453.33, 679.34, 905.6, 1132.39, 1357.29, 2261.29, 3166.98, 4524.7],
  [794.27, 1190.29, 1586.75, 1980.43, 2378.43, 3962.8, 5549.95, 7922.35],
]

var payment_rate = function (type, age, cover) {
  switch (type) {
    case 'Main':
      return main_rates[main_age_index(age)][cover - 1]
    case 'Child':
      return child_rates[child_age_index(age)][cover - 1]
    case 'Guardian':
      return wider_rates[main_age_index(age)][cover - 1]
    case 'Mother':
      return wider_rates[main_age_index(age)][cover - 1]
    case 'Mother in law':
      return wider_rates[main_age_index(age)][cover - 1]
    case 'Father':
      return wider_rates[main_age_index(age)][cover - 1]
    case 'Father in law':
      return wider_rates[main_age_index(age)][cover - 1]
    case 'Step mother':
      return wider_rates[main_age_index(age)][cover - 1]
    case 'Step father':
      return wider_rates[main_age_index(age)][cover - 1]
    case 'Grandmother':
      return grandparents_rates[grandparents_age_index(age)][cover - 1]
    case 'Grandfather':
      return grandparents_rates[grandparents_age_index(age)][cover - 1]
    case 'Spouse':
      return spouse_rates[main_age_index(age)][cover - 1]
    case 'Sister':
      return siblings_rates[sibling_age_index(age)][cover - 1]
    case 'Brother':
      return siblings_rates[sibling_age_index(age)][cover - 1]
    default:
      return 0.0
  }
}

const quoteCanBeCalculated = (state) =>
  state.form['life-assured-identification']['date-of-birth'] &&
  productOption(state.form) > 0

//CALCULATE THE MAIN LIFE PREMIUM
const mainLifePremium = createSelector(
  (form) => form['main-cover'],
  mainLifeAge,
  productOption,
  (mainCover, ageMain, coverOption) => {
    if (mainCover && ageMain && coverOption) {
      const rate = 1 + payment_rate(mainCover, ageMain, coverOption)
      return rate
    } else {
      return 0
    }
  }
)

//CALCULATE THE LIFE ASSURED PREMIUM
const lifePremium = createSelector(
  (form) => form['lives-assured'],
  productOption,
  (lifeassured, coveroption) => {
    let getAge = lifeassured.map((l) => {
      const life_of_member = l.relationship
      const age_of_member = l['age-of-member']
      const rate_of_life = payment_rate(
        life_of_member,
        age_of_member,
        coveroption
      )
      return rate_of_life
    }, 0)
    if (lifeassured.length > 0) {
      let sum = 0
      for (let m = 0; m < getAge.length; m++) {
        sum += getAge[m]
      }
      return sum
    } else {
      return 0
    }
  }
)

//Clculation for the total premium (adding the life premium and the main life premium)
const totalQuotedPremium = createSelector(
  mainLifePremium,
  lifePremium,
  (main, life) => {
    if (main || life) {
      let premium = main + life
      return premium
    } else {
      return 0
    }
  }
)

const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

const optionsForRelationship = (opts, relationship) => {
  if (relationship in opts) {
    return opts[relationship].options || []
  } else {
    const alt = Object.values(opts).find((v) => v.rename === relationship)
    return alt ? alt.options || [] : null
  }
}

// Prodct rules say spouse gender is opposite to main life gender :(
const spouseGender = (gender) => {
  switch (gender) {
    case 'Male':
      return 'Female'
    case 'Female':
      return 'Male'
    default:
      return null
  }
}

const hasSpouse = (state) =>
  state.form['lives-assured'].find((l) => l.relationship === 'Spouse')

const setPageItemFromState = (state) => {
  validate(state, state.local && state.local.failed_validation ? false : true)

  switch (state.step) {
    case 'quotation-screen':
      // Date of birth
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
          maxYear: dayjs().year() - 18,
          minYear: dayjs().year() - 100,
        }
      )
      //gender
      updateItemIn(
        state.page.item,
        { id: 'gender' },
        {
          content: state.form['life-assured-identification']['gender'] || '',
        }
      )

      // Coer options
      updateItemIn(
        state.page.item,
        { id: 'ghs-1000000', type: 'field' },
        {
          content: state.form['cover-options']['ghs-1000000'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'ghs-1500000', type: 'field' },
        {
          content: state.form['cover-options']['ghs-1500000'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'ghs-2000000', type: 'field' },
        {
          content: state.form['cover-options']['ghs-2000000'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'ghs-2500000', type: 'field' },
        {
          content: state.form['cover-options']['ghs-2500000'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'ghs-3000000', type: 'field' },
        {
          content: state.form['cover-options']['ghs-3000000'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'ghs-5000000', type: 'field' },
        {
          content: state.form['cover-options']['ghs-5000000'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'ghs-7000000', type: 'field' },
        {
          content: state.form['cover-options']['ghs-7000000'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'ghs-10000000', type: 'field' },
        {
          content: state.form['cover-options']['ghs-10000000'] || '',
        }
      )

      //Hide family member option if no cover has been selected
      updateItemIn(
        state.page.item,
        { id: 'your-family', type: 'list' },
        {
          invisible: !(
            state.form['cover-options']['ghs-1000000'] === 'Y' ||
            state.form['cover-options']['ghs-1500000'] === 'Y' ||
            state.form['cover-options']['ghs-2000000'] === 'Y' ||
            state.form['cover-options']['ghs-2500000'] === 'Y' ||
            state.form['cover-options']['ghs-3000000'] === 'Y' ||
            state.form['cover-options']['ghs-5000000'] === 'Y' ||
            state.form['cover-options']['ghs-7000000'] === 'Y' ||
            state.form['cover-options']['ghs-10000000'] === 'Y'
          ),
        }
      )

      //Added Lives insured
      updateItemIn(
        state.page.item,
        { id: 'lives-added-basic' },
        {
          invisible: state.form['lives-assured'].length === 0,
          content: state.form['lives-assured'].map(additionalLife),
        }
      )

      //Hide button to add additional lives - max 12
      const numLives = (state.form['lives-assured'] || []).length
      updateItemIn(
        state.page.item,
        { id: 'system-constant', uid: 'e9bba8eeff937b49' },
        { invisible: numLives >= 12 }
      )

      break

    case 'add-family-members':
      let familyButtonLabels = familyMemberLabels
      if (
        state.local &&
        state.local.relationship &&
        familyMemberLabels.indexOf(state.local.relationship) > -1
      ) {
        familyButtonLabels = Object.keys(
          familyMemberOptions(0)[state.local.relationship]
        )
      } else {
        // Hide spouse button if there already is a spouse
        if (hasSpouse(state)) {
          familyButtonLabels = familyButtonLabels.filter((b) => b !== 'Spouse')
        }
      }
      state.page.buttons = familyButtonLabels.map(createButton)
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'family-members-age-and-gender':
      state.page.text = [
        `Please provide the age and gender of your ${
          state.local.relationship || 'family member'
        }`,
      ]

      updateItemIn(
        state.page.item,
        { id: 'age-of-member' },
        {
          content: state.local['age-of-member'] || '',
          options: state.local.options || [],
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'gender' },
        { content: state.local.gender || '' }
      )
      break

    case 'edit-family-members':
      state.page.buttons = state.form['lives-assured'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'remove-family-members':
      state.page.buttons = state.form['lives-assured'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'personal-details':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-identification-type',
        'life-assured-employment-details',
      ].forEach((section) =>
        Object.keys(state.form[section]).forEach((name) =>
          updateItemIn(
            state.page.item,
            { id: name },
            { content: state.form[section][name] || '' }
          )
        )
      )
      break

    case 'spouse-details':
      ;['spouse-identification', 'spouse-identification-type'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(
              state.page.item,
              { id: field },
              { content: state.form[section][field] || '' }
            )
          )
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'spouse-is-trustee' },
        { content: state.form['spouse-is-trustee'] || '' }
      )
      break

    case 'trustee-details':
      ;['trustee-identification'].forEach((section) =>
        Object.keys(state.form[section]).forEach((name) =>
          updateItemIn(
            state.page.item,
            { id: name },
            { content: state.form[section][name] || '' }
          )
        )
      )
      break

    case 'additional-lives':
      updateItemIn(
        state.page.item,
        { id: 'additional-lives' },
        {
          content: familyMemberTablePrefix.concat(
            state.form['lives-assured'].map(familyMemberItem)
          ),
        }
      )
      validate(
        state,
        state.local && state.local.failed_validation ? false : true
      )
      break

    case 'payment-details':
      updateItemIn(
        state.page.item,
        { id: 'debit-order' },
        { content: state.form['debit-order'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'stop-order' },
        { content: state.form['stop-order'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'mobile-wallet' },
        { content: state.form['mobile-wallet'] || '' }
      )

      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )

      updateItemIn(
        state.page.item,
        { uid: 'ce35234c9c489acc' },
        {
          invisible:
            state.form['life-assured-identification']['marital-status'] !==
            'Married',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'myself' },
        { content: state.form['payer-person']['myself'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'my-spouse' },
        { content: state.form['payer-person']['my-spouse'] || '' }
      )
      break

    case 'debit-order-details':
      Object.keys(state.form['payment-bank-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['payment-bank-details'][field] || '',
          }
        )
      )
      break
    case 'stop-order-details':
      Object.keys(state.form['payment-employment-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['payment-employment-details'][field] || '',
          }
        )
      )
      break

    case 'mobile-wallet-details':
      Object.keys(state.form['mobile-wallet-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['mobile-wallet-details'][field] || '',
          }
        )
      )
      break

    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break

    case 'acceptance-screen':
      Object.keys(state.form).forEach((section) => {
        findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
          Object.keys(state.form[section]).forEach((field) => {
            if (state.form[section][field]) {
              updateItemIn(
                sItem,
                { id: field },
                { content: state.form[section][field] }
              )
            }
          })
        })
      })
      // Update the reference number
      updateItemIn(
        state.page.item,
        { id: 'contract-id' },
        { content: state.form['reference-number'] }
      )
      break
  }

  updateItemIn(
    state.page.item,
    { id: 'benefit-rate' },
    {
      content: quoteCanBeCalculated(state) ? benefitRate(state.form) : '0.00',
    }
  )

  updateItemIn(
    state.page.item,
    { id: 'premium' },
    {
      content: quoteCanBeCalculated(state)
        ? amountString(totalQuotedPremium(state.form))
        : '0.00',
    }
  )
}

// Change the page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}

// Create the state for a new process
const createState = (state) => {
  state.currentProcess = {} // Create a new process
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  ) // Overall Process state
  state.currentProcess.step = 'quotation-screen' // Current process step
  state.currentProcess.page = pages['quotation-screen'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

// processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break
      case 'quotation-screen':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              if (hasSpouse(state)) {
                state.form['life-assured-identification']['marital-status'] =
                  'Married'
                if (state.form['life-assured-identification'].gender !== null) {
                  state.form['spouse-identification'].gender = spouseGender(
                    state.form['life-assured-identification'].gender
                  )
                }
              }
              if (totalQuotedPremium(state.form) < 50) {
                changePage(state, 'premium-amount')
              } else {
                changePage(state, 'personal-details')
              }
            }
            break
          case 'add-family':
            changePage(state, 'add-family-members')
            break
          case 'edit-family':
            const locals = state.local
            changePage(state, 'edit-family-members')
            state.local = locals
            break
          case 'remove-family':
            changePage(state, 'remove-family-members')
            break
        }
        break
      case 'premium-amount':
        switch (button.id) {
          case 'done':
            changePage(state, 'quotation-screen')
            break
        }
        break
      case 'add-family-members':
        if (button.id === 'back') {
          changePage(state, 'quotation-screen')
        } else {
          let optionLevel = familyMemberOptions(mainLifeAge(state.form) || 0)
          if (
            state.local &&
            state.local.relationship &&
            familyMemberLabels.indexOf(state.local.relationship) > -1
          ) {
            optionLevel = optionLevel[state.local.relationship]
          }
          const elem = Object.keys(optionLevel).find(
            (l) => button.id === slugify(l)
          )
          if (elem) {
            const options = optionLevel[elem]
            if ('options' in options) {
              changePage(state, 'family-members-age-and-gender')
              state.local = state.local || {}
              state.local.options = options.options
              state.local.relationship = options.rename || elem
            } else {
              state.local = state.local || {}
              state.local.relationship = elem
            }
          }
        }
        break
      case 'family-members-age-and-gender':
        switch (button.id) {
          case 'back':
            changePage(state, 'add-family-members')
            break
          case 'next':
            if (validate(state)) {
              if (
                state.local &&
                ['relationship', 'age-of-member', 'gender'].every(
                  (f) => f in state.local
                )
              ) {
                const life = createFamilyMember(
                  state.local.relationship,
                  state.local['age-of-member'],
                  state.local.gender
                )
                if (
                  (state.local.replace || state.local.replace === 0) &&
                  state.local.replace >= 0 &&
                  state.local.replace < state.form['lives-assured'].length
                ) {
                  state.form['lives-assured'][state.local.replace] = life
                } else {
                  state.form['lives-assured'].push(life)
                }
                changePage(state, 'quotation-screen')
              }
            }
            break
        }
        break

      case 'edit-family-members':
        if (button.id === 'back') {
          changePage(state, 'quotation-screen')
        } else {
          const lifePosition = state.form['lives-assured'].findIndex(
            (life, position, lives) =>
              idFromLifeAssured(life, position, lives) === button.id
          )
          if (lifePosition >= 0) {
            changePage(state, 'family-members-age-and-gender')
            const life = original(state.form['lives-assured'][lifePosition])
            state.local = {
              replace: lifePosition,
              relationship: life.relationship,
              'age-of-member': life['age-of-member'],
              gender: life.gender,
            }
            const optionLevel =
              familyMemberOptions(mainLifeAge(state.form) || 0) || []
            state.local.options = optionsForRelationship(
              optionLevel,
              life.relationship
            )
            if (state.local.options === null) {
              state.local.options = Object.keys(optionLevel).reduce(
                (options, key) =>
                  optionsForRelationship(optionLevel[key], life.relationship) ||
                  options,
                []
              )
            }
          }
        }
        break
      case 'remove-family-members':
        state.form['lives-assured'] = state.form['lives-assured'].filter(
          (life, position, lives) =>
            idFromLifeAssured(life, position, lives) !== button.id
        )
        changePage(state, 'quotation-screen')
        break
      case 'personal-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-screen')
            break
          case 'next':
            if (validate(state)) {
              changePage(
                state,
                state.form['life-assured-identification']['marital-status'] ===
                  'Married'
                  ? 'spouse-details'
                  : 'trustee-details'
              )
            }
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              state.form['payer-person']['myself'] = 'Y'
            }
            break
        }
        break

      case 'spouse-details':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              if (state.form['spouse-is-trustee'] === 'Y') {
                ;['identification'].forEach((section) =>
                  Object.keys(state.form[`spouse-${section}`]).forEach(
                    (field) =>
                      (state.form[`trustee-${section}`][field] =
                        state.form[`spouse-${section}`][field])
                  )
                )
                state.form['trustee-identification'].relationship = 'Spouse'
              }
              changePage(state, 'trustee-details')
            }
            break
          case 'back':
            changePage(state, 'personal-details')
            break
        }
        break
      case 'trustee-details':
        switch (button.id) {
          case 'back':
            changePage(
              state,
              state.form['life-assured-identification']['marital-status'] ===
                'Married'
                ? 'spouse-details'
                : 'personal-details'
            )
            break
          case 'next':
            if (validate(state)) {
              changePage(
                state,
                state.form['lives-assured'].length > 0
                  ? 'additional-lives'
                  : 'payment-details',
                (state.form['payment-frequency']['monthly'] = 'Y')
              )
            }
            break
        }
        break

      case 'additional-lives':
        switch (button.id) {
          case 'back':
            changePage(state, 'trustee-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details')
            }
            break
        }
        break

      case 'payment-details':
        switch (button.id) {
          case 'back':
            changePage(
              state,
              state.form['lives-assured'].length > 0
                ? 'additional-lives'
                : 'trustee-details'
            )
            break
          case 'next':
            if (validate(state)) {
              if (state.form['debit-order'] === 'Y') {
                changePage(state, 'debit-order-details')
              } else if (state.form['stop-order'] === 'Y') {
                changePage(state, 'stop-order-details')
              } else if (state.form['mobile-wallet'] === 'Y') {
                changePage(state, 'mobile-wallet-details')
              }
            }
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              ;(state.form['payer-person']['myself'] = 'Y') &&
                (state.form['payer-person']['my-spouse'] = 'N')
            }
            if (state.form['payer-person']['my-spouse'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['spouse-identification'][field])
              )
            } else if (state.form['payer-person']['myself'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['life-assured-identification'][field])
              )
            }
            break
        }
        break

      case 'debit-order-details':
      case 'stop-order-details':
      case 'mobile-wallet-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            state.form['payer-signature'] = null
            //TO DO: Delete the form items captured if user returns to payment details page
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
            break
        }
        break

      case 'upload-documents':
        switch (button.id) {
          case 'upload-id-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
          case 'next':
            changePage(state, 'acceptance-screen')
            updateItemIn(
              state.page.item,
              { uid: 'd2994131363640e0', type: 'list' }, // payment-details
              paymentDetailsSummary(state.form)
            )
            break
          case 'back':
            if (state.form['debit-order'] === 'Y') {
              changePage(state, 'debit-order-details')
            } else if (state.form['stop-order'] === 'Y') {
              changePage(state, 'stop-order-details')
            } else if (state.form['mobile-wallet'] === 'Y') {
              changePage(state, 'mobile-wallet-details')
            }
            state.form['payer-signature'] = null
            break
        }
        break

      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            if (state.form.documents[state.local.document].length > 0) {
              changePage(state, 'acceptance-screen')
              updateItemIn(
                state.page.item,
                { uid: 'd2994131363640e0', type: 'list' }, // payment-details
                paymentDetailsSummary(state.form)
              )
            } else {
              changePage(state, 'upload-documents')
            }
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'submit':
            if (validate(state)) {
              state.form['application-accepted'] = 'Y'
              state.form['application-acceptance-date'] =
                dayjs().format('YYYYMMDD')
              state.form.premium = totalQuotedPremium(state.form)
              changePage(state, 'completed')
            }
            break
          case 'back':
            changePage(state, 'upload-documents')
            break
        }
        break
      case 'completed':
        asyncDispatch(completeProcess(state.form['reference-number']))
        break
    }
    setPageItemFromState(state)
  }
}

// processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-screen':
      if (data.id in state.form['life-assured-identification']) {
        state.form['life-assured-identification'][data.id] = data.value
        dirty = true
      } else if (data.id in state.form['cover-options']) {
        state.form['cover-options'][data.id] = data.value
        dirty = true
      } else {
        console.warn(`No form element found for ${data.id} [${data.value}]`)
      }
      if (data.id === 'date-of-birth') {
        state.form['main-cover'] = 'Main'
        dirty = true
      }
      break
    case 'family-members-age-and-gender':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break
    case 'personal-details':
      const personalFormSection = [
        'life-assured-identification',
        'life-assured-identification-type',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-employment-details',
      ].find((s) => data.id in state.form[s])
      if (personalFormSection) {
        state.form[personalFormSection][data.id] = data.value
        dirty = true
      } else {
        console.warn(`No form element found for ${data.id} [${data.value}]`)
      }

      if (data.id === 'marital-status') {
        if (hasSpouse(state)) {
          state.form['life-assured-identification']['marital-status'] =
            'Married'
          dirty = true
        }
        state.form['spouse-identification'].gender =
          data.value === 'Married'
            ? spouseGender(state.form['life-assured-identification'].gender)
            : null
        state.form['spouse-identification']['marital-status'] =
          data.value === 'Married' ? 'Married' : null
        dirty = true
      } else if (data.id === 'gender') {
        if (
          state.form['life-assured-identification']['marital-status'] ===
          'Married'
        ) {
          state.form['spouse-identification'].gender = spouseGender(data.value)
          state.form['spouse-identification']['marital-status'] = 'Married'
          dirty = true
        }
      }
      break

    case 'spouse-details':
      ;['spouse-identification', 'spouse-identification-type'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      if (data.id === 'spouse-is-trustee') {
        state.form[data.id] = data.value
        dirty = true
      }
      break

    case 'trustee-details':
      const trusteeFormSection = ['trustee-identification'].find(
        (s) => data.id in state.form[s]
      )
      if (trusteeFormSection) {
        state.form[trusteeFormSection][data.id] = data.value
        dirty = true
      } else {
        console.warn(`No form element found for ${data.id} [${data.value}]`)
      }
      break

    case 'additional-lives':
      let id = data.id.split('-')
      const index = parseInt(id.pop())
      if (!isNaN(index) && index < state.form['lives-assured'].length) {
        id = id.join('-')
        if (id in state.form['lives-assured'][index]) {
          state.form['lives-assured'][index][id] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break

    case 'payment-details':
      switch (data.id) {
        case 'debit-order':
          state.form['debit-order'] = data.value
          dirty = true
          break
        case 'stop-order':
          state.form['stop-order'] = data.value
          dirty = true
          break
        case 'mobile-wallet':
          state.form['mobile-wallet'] = data.value
          dirty = true
          break
        case 'payment-start-date':
          const date = dayjs(data.value)
          state.form['payment-start-date'] = date.isValid()
            ? date.format('YYYYMMDD')
            : null
          dirty = true
          break
        case 'myself':
        case 'my-spouse':
          state.form['payer-person'][data.id] = data.value
          dirty = true
          break
      }
      break

    case 'debit-order-details':
      if (data.id in state.form['payment-bank-details']) {
        state.form['payment-bank-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'stop-order-details':
      if (data.id in state.form['payment-employment-details']) {
        state.form['payment-employment-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'mobile-wallet-details':
      if (data.id in state.form['mobile-wallet-details']) {
        state.form['mobile-wallet-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

export default {
  createState,
  processButton,
  processChange,
}
