/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import Button from '../components/Button'
import { clickButton } from '../core/actions'

const ButtonGroup = ({ buttons, control, dispatch }) => (
  buttons.length > 0 && (
    <div className={classNames({ 'button-group': true, control: control })}>
      {buttons.map(btn =>
        <Button
          key={btn.label}
          id={btn.id}
          uid={btn.uid}
          label={btn.label}
          icon={btn.icon && `data:${btn.icon.type};base64,${btn.icon.data}`}
          onClick={() => dispatch(clickButton({ id: btn.id, uid: btn.uid }))}
        />)}
    </div>
  )
)

export default connect()(ButtonGroup)
