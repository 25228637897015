/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'

const List = ({ id, label, transposed, extraClass, errors, children }) => {
  const listClass = [
    classNames({
      list: true,
      'has-error': errors && errors.length > 0,
      polyadic: children.length > 1,
      horizontal: transposed,
    }),
    extraClass || '',
  ]
    .join(' ')
    .trim()

  return (
    <ul id={id} className={listClass}>
      <li className='section-header'>{label}</li>
      {transposed ? (
        <li className='section-children'>
          <ul className='list-children'>{children}</ul>
        </li>
      ) : (
        children
      )}
      {errors && errors.length > 0 && (
        <li className='section-errors'>
          <ul className='error-description'>
            {errors.map((error, idx) => (
              <li key={idx}>{error}</li>
            ))}
          </ul>
        </li>
      )}
    </ul>
  )
}

export default List
