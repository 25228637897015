/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'

const Text = ({ id, label, text }) => (
  <div className='text' id={id}>
    {label !== 'Message' && (
      <div className='text-label'>
        <h1>{label}</h1>
      </div>
    )}
    <div className='text-content'>
      <p>
        {text.map((line, key) => (
          <span key={key}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  </div>
)

export default Text
