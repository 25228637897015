/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */
import {
  pages,
  familyMemberLabels,
  familyMemberOptions,
  createFamilyMember,
  additionalLife,
  familyMemberItem,
  familyMemberTablePrefix,
  buttonFromLifeAssured,
  idFromLifeAssured,
  paymentDetailsSummary,
  paymentDetailsSection,
  formSectionFromPaymentType,
} from './family-funeral-plan-unlimited/pages'
import {
  createItem,
  updateItemIn,
  findItemsIn,
  createButton,
  slugify,
} from '../../../src/core/dataitems'
import { rates } from './family-funeral-plan-unlimited/rates'
import { form } from './family-funeral-plan-unlimited/form'
import { validate } from './family-funeral-plan-unlimited/validations'
import { createSelector } from 'reselect'
import shortid from 'shortid'
import dayjs from 'dayjs'

import { original } from 'immer'
import { cancelProcess, completeProcess } from '../../../src/core/actions'

const dateFormat = 'D MMMM YYYY'

const amountString = (f) =>
  f
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/, '')

const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

const hasSpouse = (state) =>
  state.form['lives-assured'].find((l) => l.relationship === 'Spouse')

// Change the page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}

const policyTermYears = createSelector(
  (form) => form['policy-term']['policy-term-ten'],
  (form) => form['policy-term']['policy-term-fifteen'],
  (ten, fifteen) => (ten === 'Y' ? 10 : fifteen === 'Y' ? 15 : 0)
)

const optionsForRelationship = (opts, relationship) => {
  if (relationship in opts) {
    return opts[relationship].options || []
  } else {
    const alt = Object.values(opts).find((v) => v.rename === relationship)
    return alt ? alt.options || [] : null
  }
}

const mostImportantLife = (lives) =>
  [
    'Spouse',
    'Daughter',
    'Son',
    'Child',
    'Sister',
    'Brother',
    'Mother',
    'Father',
    'Wider',
  ].reduce((f, r) => f || lives.find((l) => l.relationship === r), null)

const benefitRate = createSelector(
  (form) => form['main-cover'],
  (form) => form['lives-assured'],
  (mainCover, lives) =>
    lives.reduce(
      (m, l) => Math.max(m, parseFloat(l['cover-benefit'] || '0.0')),
      parseFloat(mainCover || '0.0')
    )
)

const mainLifeAge = createSelector(
  (form) => form['life-assured-identification']['date-of-birth'],
  (dob) => dayjs().diff(dob, 'year')
)

const paymentFrequencyLookup = {
  'bi-annually': 6,
  annually: 12,
  quarterly: 3,
  monthly: 1,
}

/* Calculate the premium of the main life */
const mainLifePremium = createSelector(
  policyTermYears,
  mainLifeAge,
  (form) => form['main-cover'],
  (form) => form['optional-benefits'],
  (form) => form['personal-accident'],
  (form) => form['hospital-premium'],
  (form) => form['cover-details'],
  (term, age, cover, ben, dis, hcb, isCovered) => {
    if (term && age && cover && isCovered !== 'No') {
      const products = [{ p: 'ffun', a: cover }]
      if (ben['memorial-cover'] === 'Y') {
        products.push({ p: 'mem', a: cover })
      }
      if (ben['7-day-celebration-cover'] === 'Y') {
        products.push({ p: 'mem_7', a: cover })
      }
      if (ben['40-day-celebration-cover'] === 'Y') {
        products.push({ p: 'mem_40', a: cover })
      }
      if (ben['accident-benefit-double'] === 'Y') {
        products.push({ p: 'adb', a: cover })
      }
      if (dis && dis.length > 0) {
        products.push({ p: 'dis', a: dis })
      }
      if (hcb && hcb.length > 0) {
        products.push({ p: 'hcb', a: hcb })
      }
      return products.reduce((s, p) => {
        const k = `${p.p}:${term}:${p.a}:${age}`
        return s + (k in rates ? rates[k] : 0)
      }, 0)
    } else {
      return null
    }
  }
)

/* Calculation total premium for quotation */
export const quotedPremiumAmount = createSelector(
  policyTermYears,
  mainLifePremium,
  (form) => form['lives-assured'],
  (form) => form['cash-bonus'],
  (form) => form['payment-frequency'],
  (term, mainLifePremium, lives, cashBonusPremium, paymentFrequency) => {
    if (term) {
      const fee = 1.0
      const cashBonus = parseFloat(cashBonusPremium)
      const pfkey = Object.keys(paymentFrequency).find(
        (key) => paymentFrequency[key] === 'Y'
      )
      const pfmultiplier =
        pfkey in paymentFrequencyLookup ? paymentFrequencyLookup[pfkey] : 1
      const mil = mainLifePremium
        ? 'Main'
        : (mostImportantLife(lives) || {}).relationship
      const premiums = lives.map((l) => {
        const products = [l.relationship === mil ? 'ffun' : 'ffun_add']
        if (l['memorial-cover'] === 'Y') {
          products.push('mem')
        }
        if (l['7-day-celebration-cover'] === 'Y') {
          products.push('mem_7')
        }
        if (l['40-day-celebration-cover'] === 'Y') {
          products.push('mem_40')
        }
        return products.reduce((s, p) => {
          const k = `${p}:${term}:${l['cover-benefit']}:${l['age-of-member']}`
          return s + (k in rates ? rates[k] : 0)
        }, 0)
      })
      const premium = premiums.reduce(
        (s, p) => s + p,
        fee +
          (isNaN(cashBonus) || cashBonus < 5 || cashBonus > 50
            ? 0
            : cashBonus) +
          (isNaN(mainLifePremium) ? 0 : mainLifePremium)
      )
      return premium * pfmultiplier
    }
    return null
  }
)

const quoteCanBeCalculated = (state) =>
  state.form['life-assured-identification']['date-of-birth'] &&
  state.form['life-assured-identification']['date-of-birth'] !== '' &&
  policyTermYears(state.form) > 0

// Update the current page based on the from
const setPageItemFromState = (state) => {
  validate(state, state.local && state.local.failed_validation ? false : true)
  switch (state.step) {
    case 'quotation-details':
      updateItemIn(
        state.page.item,
        { id: 'will-you-also-be-covered-under-this-policy' },
        { content: state.form['cover-details'] || '' }
      )

      // Hide elements when cover is not extended to main life
      updateItemIn(
        state.page.item,
        { uid: '4bfba8a734e6c514', type: 'list' },
        { invisible: state.form['cover-details'] !== 'Yes' }
      )

      updateItemIn(
        state.page.item,
        { id: 'pre-burial-benefits', type: 'list' },
        { invisible: state.form['cover-details'] !== 'Yes' }
      )

      // Date of birth
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
          maxYear: dayjs().year() - 18,
          minYear: dayjs().year() - 100,
        }
      )

      // Policy Term
      updateItemIn(
        state.page.item,
        { id: '10-years', type: 'field' },
        { content: state.form['policy-term']['policy-term-ten'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: '15-years', type: 'field' },
        { content: state.form['policy-term']['policy-term-fifteen'] || '' }
      )

      // Main Cover
      updateItemIn(
        state.page.item,
        { id: 'main-cover' },
        { content: state.form['main-cover'] || '' }
      )

      // Memorial Benefit
      updateItemIn(
        state.page.item,
        { id: 'memorial-cover', type: 'field' },
        {
          content: state.form['optional-benefits']['memorial-cover'] || '',
        }
      )

      // Memorial 7 Day
      updateItemIn(
        state.page.item,
        { id: '7-day-celebration-cover', type: 'field' },
        {
          content:
            state.form['optional-benefits']['7-day-celebration-cover'] || '',
        }
      )

      // Memorial 40 Day
      updateItemIn(
        state.page.item,
        { id: '40-day-celebration-cover', type: 'field' },
        {
          content:
            state.form['optional-benefits']['40-day-celebration-cover'] || '',
        }
      )

      // Double Accident Benefit
      updateItemIn(
        state.page.item,
        { id: 'accident-benefit-double', type: 'field' },
        {
          content:
            state.form['optional-benefits']['accident-benefit-double'] || '',
        }
      )

      // Hospitalization Cover
      updateItemIn(
        state.page.item,
        { id: 'hospital-premium' },
        { content: state.form['hospital-premium'] || '' }
      )

      // Accidental Cover
      updateItemIn(
        state.page.item,
        { id: 'personal-accident' },
        { content: state.form['personal-accident'] || '' }
      )
      break

    case 'quotation-family':
      updateItemIn(
        state.page.item,
        { id: 'cash-bonus', type: 'amount' },
        { content: state.form['cash-bonus'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'inflation-protector', type: 'field' },
        { content: state.form['inflation-protector'] || '' }
      )

      // Lives insured
      updateItemIn(
        state.page.item,
        { id: 'lives-added-basic' },
        {
          invisible: state.form['lives-assured'].length === 0,
          content: state.form['lives-assured'].map(additionalLife),
        }
      )

      // Only show buttons if there are lives assured
      state.page.buttons.forEach((button) => {
        if (['remove-family', 'edit-family'].indexOf(button.id) >= 0) {
          button.invisible = state.form['lives-assured'].length < 1
        }
      })

      updateItemIn(
        state.page.item,
        { uid: 'b3363c988d621624' },
        { invisible: state.form['lives-assured'].length < 1 }
      )

      updateItemIn(
        state.page.item,
        { uid: '17f04613c1225fd4' },
        { invisible: state.form['lives-assured'].length < 1 }
      )

      //Hide button if more than 16 additional lives
      const numLives = (state.form['lives-assured'] || []).length
      updateItemIn(
        state.page.item,
        { id: 'system-constant', uid: '44612adcb73f4f97' },
        { invisible: numLives >= 16 }
      )
      break

    // Add additional lives
    case 'add-family-members':
      let familyButtonLabels = familyMemberLabels
      if (
        state.local &&
        state.local.relationship &&
        familyMemberLabels.indexOf(state.local.relationship) > -1
      ) {
        familyButtonLabels = Object.keys(
          familyMemberOptions(0)[state.local.relationship]
        )
      } else {
        // Hide spouse button if there already is a spouse
        if (hasSpouse(state)) {
          familyButtonLabels = familyButtonLabels.filter((b) => b !== 'Spouse')
        }
      }
      state.page.buttons = familyButtonLabels.map(createButton)
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'family-members-age-and-gender':
      state.page.text = [
        `Please provide the age and gender of your ${
          state.local.relationship || 'family member'
        }`,
      ]

      updateItemIn(
        state.page.item,
        { id: 'age-of-member' },
        {
          content: state.local['age-of-member'] || '',
          options: state.local.options || [],
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'gender' },
        { content: state.local.gender || '' }
      )
      break

    case 'family-members-quotation-details':
      state.page.text = [
        `Select the cover for your ${
          state.local.relationship || 'family member'
        }`,
      ]

      updateItemIn(
        state.page.item,
        { id: 'cover-benefit' },
        { content: state.local['cover-benefit'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: '7-day-celebration-cover' },
        { content: state.local['7-day-celebration-cover'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: '40-day-celebration-cover' },
        { content: state.local['40-day-celebration-cover'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'memorial-cover' },
        { content: state.local['memorial-cover'] || '' }
      )

      break

    case 'remove-family-members':
      state.page.buttons = state.form['lives-assured'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'edit-family-members':
      state.page.buttons = state.form['lives-assured'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'personal-details':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'identification-type',
      ].forEach((section) =>
        Object.keys(state.form[section]).forEach((name) =>
          updateItemIn(
            state.page.item,
            { id: name },
            { content: state.form[section][name] || '' }
          )
        )
      )

      updateItemIn(
        state.page.item,
        { id: 'other-nationality' },
        {
          invisible:
            state.form['life-assured-identification']['ghanaian'] === 'Y',
        }
      )
      break

    case 'trustee-details':
      ;['trustee-identification'].forEach((section) =>
        Object.keys(state.form[section]).forEach((name) =>
          updateItemIn(
            state.page.item,
            { id: name },
            { content: state.form[section][name] || '' }
          )
        )
      )
      break

    case 'additional-lives':
      updateItemIn(
        state.page.item,
        { id: 'additional-lives' },
        {
          content: familyMemberTablePrefix.concat(
            state.form['lives-assured'].map(familyMemberItem)
          ),
        }
      )
      validate(
        state,
        state.local && state.local.failed_validation ? false : true
      )
      break

    // Payer Details
    case 'payment-details':
      updateItemIn(
        state.page.item,
        { id: 'debit-order' },
        { content: state.form['debit-order'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'stop-order' },
        { content: state.form['stop-order'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'mobile-wallet' },
        { content: state.form['mobile-wallet'] || '' }
      )
      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )

      Object.keys(state.form['payment-frequency']).forEach((name) =>
        updateItemIn(
          state.page.item,
          { id: name },
          { content: state.form['payment-frequency'][name] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { uid: '8dcbe97c1d6c8492' },
        paymentDetailsSection(state.form)
      )
      ;[
        'payment-bank-details',
        'payer-employment-details',
        'mobile-wallet-details',
      ].forEach((section) =>
        Object.keys(state.form[section]).forEach((name) =>
          updateItemIn(
            state.page.item,
            { id: name },
            {
              content: state.form[section][name] || '',
              errors: state.form[section][name] ? [] : ['*'],
            }
          )
        )
      )
      break

    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break

    case 'acceptance-screen':
      Object.keys(state.form).forEach((section) => {
        findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
          Object.keys(state.form[section]).forEach((field) => {
            if (state.form[section][field]) {
              updateItemIn(
                sItem,
                { id: field },
                { content: state.form[section][field] }
              )
            }
          })
        })
      })
      // Update the reference number
      updateItemIn(
        state.page.item,
        { id: 'contract-id' },
        { content: state.form['reference-number'] }
      )
      break
  }

  // Persistent elements
  updateItemIn(
    state.page.item,
    { id: 'benefit-rate' },
    { content: amountString(benefitRate(state.form)) }
  )

  // Update the quotation
  updateItemIn(
    state.page.item,
    { id: 'premium' },
    {
      content: quoteCanBeCalculated(state)
        ? amountString(quotedPremiumAmount(state.form))
        : '0.00',
    }
  )
}

// Create the state for a new process
const createState = (state) => {
  state.currentProcess = {} // Create a new process
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  ) // Overall Process state
  state.currentProcess.step = 'quotation-details' // Current process step
  state.currentProcess.page = pages['quotation-details'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

// processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break

      case 'quotation-details':
        switch (button.id) {
          case 'memorial-cover':
          case 'accident-benefit-double':
          case '7-day-celebration-cover':
          case '40-day-celebration-cover':
            state.form['optional-benefits'] = Object.keys(
              state.form['optional-benefits']
            ).reduce((obj, plan) => {
              obj[plan] = plan === button.id
              return obj
            }, {})
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'quotation-family')
            }
            break
          case 'more-info':
            changePage(state, 'additional-info')
            state.local = state.local || {}
            state.local.back = 'quotation-details'
            break
        }
        break

      case 'additional-info':
        switch (button.id) {
          case 'done':
            changePage(
              state,
              state.local && state.local.back
                ? state.local.back
                : 'quotation-details'
            )
            break
        }
        break

      case 'quotation-family':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'personal-details')
            }
            break
          case 'more-info':
            changePage(state, 'additional-info')
            state.local = state.local || {}
            state.local.back = 'quotation-family'
            break
          case 'add-family':
            changePage(state, 'add-family-members')
            break
          case 'remove-family':
            changePage(state, 'remove-family-members')
            break
          case 'edit-family':
            const locals = state.local
            changePage(state, 'edit-family-members')
            state.local = locals
            break
        }
        break

      case 'add-family-members':
        if (button.id === 'back') {
          changePage(state, 'quotation-family')
        } else {
          let optionLevel = familyMemberOptions(mainLifeAge(state.form) || 0)
          if (
            state.local &&
            state.local.relationship &&
            familyMemberLabels.indexOf(state.local.relationship) > -1
          ) {
            optionLevel = optionLevel[state.local.relationship]
          }
          const elem = Object.keys(optionLevel).find(
            (l) => button.id === slugify(l)
          )
          if (elem) {
            const options = optionLevel[elem]
            if ('options' in options) {
              changePage(state, 'family-members-age-and-gender')
              state.local = state.local || {}
              state.local.options = options.options
              state.local.relationship = options.rename || elem
            } else {
              state.local = state.local || {}
              state.local.relationship = elem
            }
          }
        }
        break
      case 'family-members-age-and-gender':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-family')
            break
          case 'next':
            if (validate(state)) {
              const local = state.local
              changePage(state, 'family-members-quotation-details')
              state.local = local
            }
            break
        }
        break
      case 'family-members-quotation-details':
        switch (button.id) {
          case 'back':
            const local = state.local
            changePage(state, 'family-members-age-and-gender')
            state.local = local
            break
          case 'next':
            if (validate(state)) {
              if (
                state.local &&
                [
                  'relationship',
                  'age-of-member',
                  'gender',
                  'cover-benefit',
                ].every((f) => f in state.local)
              ) {
                const life = createFamilyMember(
                  state.local.relationship,
                  state.local['age-of-member'],
                  state.local.gender,
                  state.local['cover-benefit'],
                  state.local['7-day-celebration-cover'] || '',
                  state.local['40-day-celebration-cover'] || '',
                  state.local['memorial-cover'] || ''
                )
                if (
                  (state.local.replace || state.local.replace === 0) &&
                  state.local.replace >= 0 &&
                  state.local.replace < state.form['lives-assured'].length
                ) {
                  state.form['lives-assured'][state.local.replace] = life
                } else {
                  state.form['lives-assured'].push(life)
                }
                changePage(state, 'quotation-family')
              }
            }
            break
        }
        break
      case 'remove-family-members':
        state.form['lives-assured'] = state.form['lives-assured'].filter(
          (life, position, lives) =>
            idFromLifeAssured(life, position, lives) !== button.id
        )
        changePage(state, 'quotation-family')
        break

      case 'edit-family-members':
        if (button.id === 'back') {
          changePage(state, 'quotation-family')
        } else {
          const lifePosition = state.form['lives-assured'].findIndex(
            (life, position, lives) =>
              idFromLifeAssured(life, position, lives) === button.id
          )
          if (lifePosition >= 0) {
            changePage(state, 'family-members-age-and-gender')
            const life = original(state.form['lives-assured'][lifePosition])
            state.local = {
              replace: lifePosition,
              relationship: life.relationship,
              'age-of-member': life['age-of-member'],
              gender: life.gender,
              'cover-benefit': life['cover-benefit'],
              '7-day-celebration-cover':
                life['7-day-celebration-cover'] === 'Y' ? 'Y' : undefined,
              '40-day-celebration-cover':
                life['40-day-celebration-cover'] === 'Y' ? 'Y' : undefined,
              'memorial-cover':
                life['memorial-cover'] === 'Y' ? 'Y' : undefined,
            }
            const optionLevel =
              familyMemberOptions(mainLifeAge(state.form) || 0) || []
            state.local.options = optionsForRelationship(
              optionLevel,
              life.relationship
            )
            if (state.local.options === null) {
              state.local.options = Object.keys(optionLevel).reduce(
                (options, key) =>
                  optionsForRelationship(optionLevel[key], life.relationship) ||
                  options,
                []
              )
            }
          }
        }
        break
      case 'personal-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-family')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'trustee-details')
            }
            break
        }
        break

      case 'trustee-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'personal-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(
                state,
                state.form['lives-assured'].length > 0
                  ? 'additional-lives'
                  : 'payment-details'
              )
              state.form['payer-signature'] = null
            }
            break
        }
        break
      case 'additional-lives':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details')
              state.form['payer-signature'] = null
            }
            break
          case 'back':
            changePage(state, 'trustee-details')
            break
        }
        break

      case 'payment-details':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              // Delete any form items captured in a section that was not selected
              const section = formSectionFromPaymentType(state.form)
              ;[
                'payment-bank-details',
                'payer-employment-details',
                'mobile-wallet-details',
              ]
                .filter((s) => s !== section)
                .forEach((fields) => {
                  Object.keys(form[fields]).forEach((field) => {
                    form[fields][field] = null
                  })
                })
              changePage(state, 'upload-documents')
            }
            break
          case 'back':
            changePage(
              state,
              state.form['lives-assured'].length > 0
                ? 'additional-lives'
                : 'trustee-details'
            )
            break
        }
        break

      case 'upload-documents':
        switch (button.id) {
          case 'upload-id-document':
          case 'upload-passport-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'acceptance-screen')
              updateItemIn(
                state.page.item,
                { uid: 'd2994131363640e0', type: 'list' }, // payment-details
                paymentDetailsSummary(state.form)
              )
            }
            break
          case 'back':
            changePage(state, 'payment-details')
            state.form['payer-signature'] = null
            break
        }
        break

      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            changePage(
              state,
              state.form.documents[state.local.document].length > 0
                ? 'acceptance-screen'
                : 'upload-documents'
            )
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'submit':
            if (validate(state)) {
              state.form['application-accepted'] = 'Y'
              state.form['application-acceptance-date'] =
                dayjs().format('YYYYMMDD')
              state.form.premium = quotedPremiumAmount(state.form)
              changePage(state, 'completed')
            }
            break
          case 'back':
            changePage(state, 'upload-documents')
        }
        break
      case 'completed':
        asyncDispatch(completeProcess(state.form['reference-number']))
        break
    }
    setPageItemFromState(state)
  }
}

// processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-details':
      switch (data.id) {
        case 'will-you-also-be-covered-under-this-policy':
          state.form['cover-details'] = data.value
          if (data.value === 'No') {
            state.form['main-cover'] = null
            state.form['optional-benefits']['7-day-celebration-cover'] = null
            state.form['optional-benefits']['40-day-celebration-cover'] = null
          }
          dirty = true
          break
        case 'date-of-birth':
          state.form['life-assured-identification']['date-of-birth'] =
            data.value
          dirty = true
          break
        case 'main-cover':
          state.form['main-cover'] = data.value
          dirty = true
          break
        case 'hospital-premium':
          state.form['hospital-premium'] = data.value
          dirty = true
          break
        case 'personal-accident':
          state.form['personal-accident'] = data.value
          dirty = true
          break
        case '10-years':
          state.form['policy-term']['policy-term-ten'] = data.value
          dirty = true
          break
        case '15-years':
          state.form['policy-term']['policy-term-fifteen'] = data.value
          dirty = true
          break
        case 'memorial-cover':
        case 'accident-benefit-double':
        case '7-day-celebration-cover':
        case '40-day-celebration-cover':
          state.form['optional-benefits'][data.id] = data.value
          dirty = true
          break
      }
      break
    case 'quotation-family':
      switch (data.id) {
        case 'cash-bonus':
          state.form['cash-bonus'] = data.value
          dirty = true
          break
        case 'inflation-protector':
          state.form['inflation-protector'] = data.value
          dirty = true
          break
      }
      break
    case 'family-members-age-and-gender':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break
    case 'family-members-quotation-details':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'personal-details':
      const personalFormSection = [
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'identification-type',
      ].find((s) => data.id in state.form[s])
      if (personalFormSection) {
        state.form[personalFormSection][data.id] = data.value
        dirty = true
      } else {
        console.warn(`No form element found for ${data.id} [${data.value}]`)
      }
      break

    case 'main-life-details':
      const mainLifeSection = [
        'life-assured-identification',
        'life-assured-contact-details',
        'identification-type',
      ].find((s) => data.id in state.form[s])
      if (mainLifeSection) {
        state.form[mainLifeSection][data.id] = data.value
        dirty = true
      } else {
        console.warn(`No form element found for ${data.id} [${data.value}]`)
      }
      break

    case 'trustee-details':
      const trusteeFormSection = ['trustee-identification'].find(
        (s) => data.id in state.form[s]
      )
      if (trusteeFormSection) {
        state.form[trusteeFormSection][data.id] = data.value
        dirty = true
      } else {
        console.warn(`No form element found for ${data.id} [${data.value}]`)
      }
      break

    case 'additional-lives':
      let id = data.id.split('-')
      const index = parseInt(id.pop())
      if (!isNaN(index) && index < state.form['lives-assured'].length) {
        id = id.join('-')
        if (id in state.form['lives-assured'][index]) {
          state.form['lives-assured'][index][id] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break

    case 'payment-details':
      const paymentTypes = ['debit-order', 'stop-order', 'mobile-wallet']
      const paymentFrequencies = [
        'monthly',
        'quarterly',
        'annually',
        'bi-annually',
      ]
      if (paymentTypes.indexOf(data.id) > -1) {
        dirty = state.form[data.id] !== data.value
        if (dirty) {
          state.form[data.id] = data.value
          updateItemIn(
            state.page.item,
            { uid: '8dcbe97c1d6c8492' },
            paymentDetailsSection(state.form)
          )
        }
      } else if (paymentFrequencies.indexOf(data.id) > -1) {
        dirty = state.form['payment-frequency'][data.id] !== data.value
        if (dirty) {
          state.form['payment-frequency'][data.id] = data.value
        }
      } else if (data.id === 'payment-start-date') {
        const date = dayjs(data.value)
        state.form['payment-start-date'] = date.isValid()
          ? date.format('YYYYMMDD')
          : null
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      } else {
        const section = formSectionFromPaymentType(state.form)
        if (section && data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        } else {
          console.warn('Unhandled data item change')
          console.warn(data)
        }
      }
      break

    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break
  }

  if (dirty) {
    setPageItemFromState(state)
  }
}

const processItemClick = (state, data, asyncDispatch) => {}

export default {
  createState,
  processButton,
  processChange,
  processItemClick,
}
