/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import { quotedPremiumAmount } from '../family-funeral-plan-unlimited'
import { hasValue, validEmail, verify } from '../../../../src/core/validations'
import {
  validNHIS,
  validVotersID,
  validSSNIT,
  validPassport,
  validDrivers,
  validID,
  validName,
  validCellnumber,
  onlyDigits,
} from '../../validations'
import dayjs from 'dayjs'

import options from '../../options.json'
const loadOptions = (uid) => (uid in options ? options[uid].values : [])

export const validate = (state, requiredOnly, validateOnly) => {
  if (process.env.DISABLE_VALIDATIONS === 'true') {
    return true
  }

  let validates = true
  switch (state.step) {
    case 'quotation-details':
      validates =
        verify(
          state,
          state.form['cover-details'],
          hasValue,
          !validateOnly && { id: 'will-you-also-be-covered-under-this-policy' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['date-of-birth'],
          hasValue,
          !validateOnly && { id: 'date-of-birth' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 18 &&
                dayjs().diff(v, 'year') <= 100)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      validates =
        verify(
          state,
          state.form['policy-term']['policy-term-ten'] ||
            state.form['policy-term']['policy-term-fifteen'],
          hasValue,
          !validateOnly && { id: 'policy-term-radio-group' },
          ['*']
        ) && validates

      if (state.form['cover-details'] === 'Yes') {
        validates =
          validates &&
          verify(
            state,
            state.form['main-cover'],
            hasValue,
            !validateOnly && { id: 'main-cover' },
            ['*']
          )
      }
      break

    case 'quotation-family':
      const cbp = parseFloat(state.form['cash-bonus'])
      const prem = quotedPremiumAmount(state.form)
      let maxCbp = !isNaN(cbp) && prem !== null ? prem - cbp : 0
      if (maxCbp < 5) {
        maxCbp = 25
      }

      validates =
        verify(
          state,
          state.form['inflation-protector'],
          hasValue,
          !validateOnly && { id: 'inflation-protector', type: 'field' },
          ['*']
        ) && validates

      if (!requiredOnly) {
        validates =
          validates &&
          verify(
            state,
            state.form['cash-bonus'],
            (v) =>
              !hasValue(v) ||
              !(
                state.form['cover-details'] === 'No' &&
                state.form['lives-assured'].length < 1
              ),
            !validateOnly && { id: 'cash-bonus' },
            [
              'You can only add this benefit if you have selected cover for yourself or other members',
            ]
          )

        if (validates) {
          validates =
            verify(
              state,
              state.form['cash-bonus'],
              (v) => !hasValue(v) || (maxCbp && cbp >= 5.0 && cbp <= maxCbp),
              !validateOnly && { id: 'cash-bonus', type: 'amount' },
              [`Amount must be between GH¢ 5 and GH¢ ${maxCbp}`]
            ) && validates
        }

        validates =
          verify(
            state,
            prem,
            (p) => !isNaN(p) && p >= 50,
            !validateOnly && { id: 'family-cover', type: 'list' },
            [
              'The minimum premium is GH¢ 50. Please select additional cover in order to meet this requirement.',
            ]
          ) && validates
      }
      break

    case 'family-members-age-and-gender':
      validates =
        verify(
          state,
          state.local && state.local['age-of-member'],
          hasValue,
          !validateOnly && { id: 'age-of-member' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.local && state.local.gender,
          hasValue,
          !validateOnly && { id: 'gender' },
          ['*']
        ) && validates

      //Validate the gender & relationship
      if (
        [
          'Mother',
          'Mother in law',
          'Grandmother',
          'Step mother',
          'Sister',
        ].indexOf(state.local.relationship) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly || ['Female'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (
        [
          'Father',
          'Father in law',
          'Grandfather',
          'Step father',
          'Brother',
        ].indexOf(state.local.relationship) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly || ['Male'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (
        ['Spouse', 'Child', 'Additional Spouse', 'Gaurdian', 'Other'].indexOf(
          state.local.relationship
        ) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Male', 'Female'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }
      break

    case 'family-members-quotation-details':
      validates =
        verify(
          state,
          state.local && state.local['cover-benefit'],
          hasValue,
          !validateOnly && { id: 'cover-benefit' },
          ['*']
        ) && validates
      break

    case 'personal-details':
      validates =
        verify(
          state,
          state.form['life-assured-postal-address']['po-box-or-private-bag'],
          hasValue,
          !validateOnly && { id: 'po-box-or-private-bag' },
          ['*']
        ) && validates
      validates =
        verify(
          state,
          state.form['life-assured-postal-address'].region,
          hasValue,
          !validateOnly && { id: 'region' },
          ['*']
        ) && validates
      validates =
        verify(
          state,
          state.form['life-assured-postal-address'].town,
          hasValue,
          !validateOnly && { id: 'town' },
          ['*']
        ) && validates

      if (state.form['life-assured-postal-address']['town']) {
        let townOption = loadOptions('f65c4a97d4bee5a0')
        validates =
          verify(
            state,
            state.form['life-assured-postal-address'].town,
            (v) => (requiredOnly ? hasValue(v) : townOption.includes(v)),
            !validateOnly && { id: 'town' },
            requiredOnly ? ['*'] : ['Please enter a valid town']
          ) && validates
      }
      validates =
        verify(
          state,
          state.form['life-assured-postal-address'].suburb,
          hasValue,
          !validateOnly && { id: 'suburb' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['first-names'],
          hasValue,
          !validateOnly && { id: 'first-names' },
          ['*']
        ) && validates
      validates =
        verify(
          state,
          state.form['life-assured-identification'].surname,
          hasValue,
          !validateOnly && { id: 'surname' },
          ['*']
        ) && validates
      validates =
        verify(
          state,
          state.form['life-assured-identification']['idpassport-number'],
          hasValue,
          !validateOnly && { id: 'idpassport-number' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification'].ghanaian,
          hasValue,
          !validateOnly && { id: 'ghanaian' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['other-nationality'],
          (v) =>
            hasValue(v) ||
            (validates &&
              state.form['life-assured-identification'].ghanaian === 'Y'),
          !validateOnly && { id: 'other-nationality' },
          requiredOnly
            ? ['*']
            : ['If you are not a ghanaian please select your nationality']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification'].gender,
          hasValue,
          !validateOnly && { id: 'gender' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification'].occupation,
          hasValue,
          !validateOnly && { id: 'occupation' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification'].title,
          hasValue,
          !validateOnly && { id: 'title' },
          ['*']
        ) && validates

      //Validate the gender & title
      if (state.form['life-assured-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['life-assured-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['life-assured-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-contact-details'].email,
          (v) => (requiredOnly ? hasValue(v) : validEmail(v)),
          !validateOnly && { id: 'email' },
          requiredOnly ? ['*'] : ['Please enter a valid Email address']
        ) && validates

      validates =
        verify(
          state,
          state.form['identification-type'].dvla ||
            state.form['identification-type'].passport ||
            state.form['identification-type']['voter-id'] ||
            state.form['identification-type'].nhis ||
            state.form['identification-type'].ssnit ||
            state.form['identification-type']['nia-ghana-card'],
          hasValue,
          !validateOnly && { id: 'id-type-radio-group' },
          ['*']
        ) && validates

      //Validation for Voters ID
      if (state.form['identification-type']['voter-id'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Voters ID Number']
          ) && validates
      }

      //Validation for NHIS
      if (state.form['identification-type']['nhis'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid NHIS Number']
          ) && validates
      }

      //Validation for SSNIT
      if (state.form['identification-type']['ssnit'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid SSNIT Number']
          ) && validates
      }

      //Passport
      if (state.form['identification-type']['passport'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Passport Number']
          ) && validates
      }

      //Drivers
      if (state.form['identification-type']['dvla'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Drivers Number']
          ) && validates
      }

      //ID
      if (state.form['identification-type']['nia-ghana-card'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid ID Number']
          ) && validates
      }

      //Validate Field Names to only be letters
      validates =
        verify(
          state,
          state.form['life-assured-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates
      break

    case 'trustee-details':
      validates =
        verify(
          state,
          state.form['trustee-identification']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      validates = [
        'first-names',
        'surname',
        'date-of-birth',
        'relationship',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.form['trustee-identification'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      //Validate Field Names to only be letters
      validates =
        verify(
          state,
          state.form['trustee-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['trustee-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age
      validates =
        verify(
          state,
          state.form['trustee-identification']['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().diff(v, 'year') >= 18),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The minimum allowable age for this product is 18.']
        ) && validates

      //Validate the gender & relationship
      if (
        ['Adv', 'Dr', 'Prof', 'Rev'].indexOf(
          state.form['trustee-identification']['title']
        ) >= 0
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Daughter',
                  'Estate',
                  'Grandmother',
                  'Mother',
                  'Mother In Law',
                  'Sister',
                  'Spouse',
                  'Testament',
                  'Trust',
                  'Brother',
                  'Father',
                  'Son',
                  'Father In Law',
                  'Grandfather',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      if (
        ['Miss', 'Mrs', 'Ms'].indexOf(
          state.form['trustee-identification']['title']
        ) >= 0
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Daughter',
                  'Estate',
                  'Grandmother',
                  'Mother',
                  'Mother In Law',
                  'Sister',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      if (state.form['trustee-identification']['title'] === 'Mr') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Estate',
                  'Father',
                  'Son',
                  'Father In Law',
                  'Grandfather',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      break

    case 'additional-lives':
      validates = state.form['lives-assured'].reduce((valid, life, counter) => {
        valid = ['first-names', 'surname', 'date-of-birth'].reduce(
          (valid, field) => valid && hasValue(life[field]),
          valid
        )
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['date-of-birth'],
              (v) =>
                dayjs().diff(v, 'year').toString() === life['age-of-member'],
              !validateOnly && { id: `date-of-birth-${counter}` },
              [
                `Date of birth does not match indicated age of ${life['age-of-member']} for ${life['relationship']}`,
              ]
            ) && valid
        }
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['first-names'],
              (v) => (requiredOnly ? hasValue(v) : validName(v)),
              !validateOnly && { id: `first-names-${counter}` },
              requiredOnly ? ['*'] : ['Please enter a valid first-name']
            ) && valid
        }
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['surname'],
              (v) => (requiredOnly ? hasValue(v) : validName(v)),
              !validateOnly && { id: `surname-${counter}` },
              requiredOnly ? ['*'] : ['Please enter a valid surname']
            ) && valid
        }
        return valid
      }, validates)
      break

    case 'payment-details':
      validates =
        verify(
          state,
          state.form['debit-order'] ||
            state.form['stop-order'] ||
            state.form['mobile-wallet'],
          hasValue,
          !validateOnly && { id: 'payment-method-radio-group' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-start-date'],
          hasValue,
          !validateOnly && { id: 'payment-start-date' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-frequency'].monthly ||
            state.form['payment-frequency'].quarterly ||
            state.form['payment-frequency'].annually ||
            state.form['payment-frequency']['bi-annually'],
          hasValue,
          !validateOnly && { id: 'payment-frequency-radio-group' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates

      let paymentDetailsSection = null
      if (state.form['debit-order'] === 'Y') {
        paymentDetailsSection = 'payment-bank-details'
      } else if (state.form['stop-order'] === 'Y') {
        paymentDetailsSection = 'payer-employment-details'
      } else if (state.form['mobile-wallet'] === 'Y') {
        paymentDetailsSection = 'mobile-wallet-details'
      }

      if (paymentDetailsSection) {
        validates = Object.keys(state.form[paymentDetailsSection]).reduce(
          (valid, field) =>
            verify(
              state,
              state.form[paymentDetailsSection][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      }

      if (!requiredOnly) {
        if (paymentDetailsSection === 'payment-bank-details') {
          //10 DIGITS
          if (
            (state.form[paymentDetailsSection]['account-entity-name'] ===
              'Absa (gh) Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Zenith Bank') &&
            state.form[paymentDetailsSection]['account-number'] !== null
          ) {
            validates =
              verify(
                state,
                state.form[paymentDetailsSection]['account-number'],
                (v) => v.length === 10 && onlyDigits(v),
                !validateOnly && { id: 'debit-order-details' },
                requiredOnly ? ['*'] : ['Please enter a valid account number']
              ) && validates
          }
          //9 / 10 / 11 DIGITS
          else if (
            state.form[paymentDetailsSection]['account-entity-name'] ===
              'Bank Of Africa' &&
            state.form[paymentDetailsSection]['account-number'] !== null
          ) {
            validates =
              verify(
                state,
                state.form[paymentDetailsSection]['account-number'],
                (v) =>
                  (v.length === 9 || v.length === 10 || v.length === 11) &&
                  onlyDigits(v),
                !validateOnly && { id: 'debit-order-details' },
                ['Please enter a valid account number']
              ) && validates
          }
          //13 DIGITS
          else if (
            (state.form[paymentDetailsSection]['account-entity-name'] ===
              'Access Bank Ghana Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Consolidated Bank Ghana' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Ecobank Ghana Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Fidelity Bank Ghana' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'First Atlantic Bank Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'First Bank Nigeria' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Gcb Bank Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'National Investment Bank' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Omni Bank Ghana Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Prudential Bank Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Republic Bank (gh) Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Stanbic Bank Ghana Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Standard Chartered Bank' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Universal Merchant Bank (gh) Ltd') &&
            state.form[paymentDetailsSection]['account-number'] !== null
          ) {
            validates =
              verify(
                state,
                state.form[paymentDetailsSection]['account-number'],
                (v) => v.length === 13 && onlyDigits(v),
                !validateOnly && { id: 'debit-order-details' },
                ['Please enter a valid account number']
              ) && validates
          }
          //14 DIGITS
          else if (
            state.form[paymentDetailsSection]['account-entity-name'] ===
              'United Bank For Africa (gh) Ltd' &&
            state.form[paymentDetailsSection]['account-number'] !== null
          ) {
            validates =
              verify(
                state,
                state.form[paymentDetailsSection]['account-number'],
                (v) => v.length === 14 && onlyDigits(v),
                !validateOnly && { id: 'debit-order-details' },
                ['Please enter a valid account number']
              ) && validates
          }
          //12 / 13 DIGITS
          else if (
            (state.form[paymentDetailsSection]['account-entity-name'] ===
              'Cal Bank Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Societe General Ghana Ltd' ||
              state.form[paymentDetailsSection]['account-entity-name'] ===
                'Guaranty Trust (gh) Ltd') &&
            state.form[paymentDetailsSection]['account-number'] !== null
          ) {
            validates =
              verify(
                state,
                state.form[paymentDetailsSection]['account-number'],
                (v) => (v.length === 12 || v.length === 13) && onlyDigits(v),
                !validateOnly && { id: 'debit-order-details' },
                ['Please enter a valid account number']
              ) && validates
          }
          //16 DIGITS
          else if (
            state.form[paymentDetailsSection]['account-entity-name'] ===
              'Agricultural Development Bank' &&
            state.form[paymentDetailsSection]['account-number'] !== null
          ) {
            validates =
              verify(
                state,
                state.form[paymentDetailsSection]['account-number'],
                (v) => v.length === 16 && onlyDigits(v),
                !validateOnly && { id: 'debit-order-details' },
                ['Please enter a valid account number']
              ) && validates
          }
        }
      }
      break

    case 'upload-id-document':
    case 'upload-bank-statement':
    case 'upload-birth-certificate':
    case 'upload-passport':
      const section = state.step.substring(7)
      validates = Object.keys(state.form[section]).reduce(
        (valid, field) =>
          verify(
            state,
            state.form[section][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'acceptance-screen':
      validates =
        verify(
          state,
          state.form['acceptance-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break
  }
  if (!requiredOnly && !validates) {
    state.local = state.local || {}
    state.local.failed_validation = true
  }
  return validates
}
