/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'

const Button = ({ id, label, icon, onClick }) => (
  <a
    className={classNames({ 'has-icon': icon, button: true })}
    href=''
    id={id}
    onClick={e => {
      e.preventDefault()
      onClick()
    }}
  >
    {icon && (
      <div className='button-icon'>
        <img src={icon} alt={label} />
      </div>
    )}
    {label}
  </a>
)

export default Button
