/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import 'url-search-params-polyfill'
import React from 'react'
import localForage from 'localforage'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import createEncryptor from 'redux-persist-transform-encrypt'
import createCompressor from 'redux-persist-transform-compress'
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'
import Application from './containers/Application'
import ErrorBoundary from './containers/ErrorBoundary'
import createRouter from './core/router'
import { migrations } from './core/migrations'
import { environment, initialState } from './core/reducers'
import { asyncDispatchMiddleware } from './core/middleware'
import { installPrompt } from './core/actions'

const isProduction = process.env.NODE_ENV === 'production'
if (!isProduction) {
  console.warn(`Running with ${process.env.NODE_ENV} profile`)
}

// Configure error reporting
if (process.env.SENTRY && process.env.SENTRY.length > 0) {
  Sentry.init({
    dsn: process.env.SENTRY,
    release: process.env.REVISION,
    environment: process.env.APPLICATION_ENV,
    integrations: (i) =>
      i
        .filter((integration) => integration.name !== 'Breadcrumbs')
        .concat([
          new Sentry.Integrations.Breadcrumbs({ history: false, xhr: false }),
        ]),
  })
  Sentry.setTag('enterprise', process.env.APPLICATION_NAME)
  Sentry.setTag('version', process.env.APPLICATION_VERSION)
  window.Sentry = Sentry
}

const enableTransforms = false

// Configure persistent storage driver
const storeLabel =
  process.env.APPLICATION_ENV !== 'Production'
    ? `${process.env.APPLICATION_NAME} - ${process.env.APPLICATION_ENV}`
    : process.env.APPLICATION_NAME
localForage.config({
  name: isProduction ? storeLabel : `${storeLabel} [${process.env.NODE_ENV}]`,
  storeName: 'state',
})

// Create persistent storage encryptor (only in production)
const encryptor =
  isProduction &&
  createEncryptor({
    secretKey: process.env.APPLICATION_KEY, // Use the unique application key as the encryption key
    onError: (err) => {
      console.log(err)
    },
  })

// Create persistent storage compressor (only in production)
const compressor = isProduction && createCompressor()

const prodTransforms = [compressor, encryptor]

// Configure persistent storage
const persistConfig = {
  version: 3, // Current state version (see core/migrations if this is changed)
  key: 'state',
  storage: localForage, // Store persisted data using the localForage instance created above
  stateReconciler: autoMergeLevel2, // Shallow merge two levels of the state object
  migrate: createMigrate(migrations, { debug: !isProduction }), // Perform any migrations of data that may be in old storage format
  transforms: isProduction && enableTransforms ? prodTransforms : [], // Compress and encrypt data
}

// Apply persistance to root reducer
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({ environment })
)

// Configure redux store

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // Connect to devtools in non-production environments

const middleware = [asyncDispatchMiddleware]
if (process.env.SENTRY) {
  middleware.push(
    createSentryMiddleware(Sentry, {
      breadcrumbDataFromAction: (action) => action.payload,
      filterBreadcrumbActions: (action) =>
        ['persist/REHYDRATE', 'CHECK_COMPLETED', 'RECORD_EVENT'].indexOf(
          action.type
        ) < 0,
    })
  )
}

const store = createStore(
  persistedReducer, // Use the peristed state reducer
  { environment: initialState }, // Set the inital state of the store
  composeEnhancers(applyMiddleware(...middleware))
)

// Apply persistance to the store
const persistor = persistStore(store)

// Create router
const router = createRouter(store.dispatch)
store.subscribe(() => router(store.getState().environment.route))

// Find root element
const root = document.getElementById('main')

// Render application into root element
const load = () =>
  render(
    <AppContainer>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <Application />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </AppContainer>,
    root
  )

// Setup Progressive Web App
if (process.env.NODE_ENV === 'production') {
  // Enable installation on chrome
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault()
    window.installPromptEvent = event
    store.dispatch(installPrompt(true))
  })

  window.addEventListener('load', () => {
    // Register service worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js')
    }

    // Setup analytics
    const registerPageview = () => {
      if (typeof window.ga === 'function') {
        window.ga('set', 'dimension1', navigator.onLine)
        window.ga('send', 'pageview')
      } else {
        setTimeout(registerPageview, 500)
      }
    }
    registerPageview()
  })
}

// This is needed for Hot Module Replacement
if (module.hot) {
  module.hot.accept('./containers/Application', load)
}

load()
