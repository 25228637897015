/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const form = {
  'owner-radio-group': {
    'policy-for-myself': null,
    'policy-for-wife': null,
  },
  'life-assured-identification': {
    'first-names': null,
    surname: null,
    'idpassport-number': null,
    title: null,
    gender: null,
    'date-of-birth': null,
    'birth-place': null,
    'tin-number': null,
    ghanaian: null,
    'marital-status': null,
    'other-nationality': null,
  },
  'life-assured-identification-type': {
    dvla: null,
    passport: null,
    'voter-id': null,
    nhis: null,
    ssnit: null,
    'nia-ghana-card': null,
  },
  'life-assured-contact-details': {
    'cell-phone': null,
    email: null,
  },
  'contract-details': {
    'accelerated-cover-sum-assured': null,
    'term-in-years': null,
    'update-option': null,
  },
  'spouse-cover': null,
  'hospitalisation-units': null,
  'educational-support-benefit': {
    'educational-support-premium': null,
    'educational-support-term': null,
  },
  'retirement-premium': null,
  'maternity-complications': null,
  'cash-bonus-premium': null,
  'spouse-identification': {
    'first-names': null,
    surname: null,
    'idpassport-number': null,
    'date-of-birth': null,
    title: null,
    gender: null,
  },
  'spouse-identfication-type': {
    dvla: null,
    passport: null,
    'voter-id': null,
    nhis: null,
    ssnit: null,
    'nia-ghana-card': null,
  },
  'spouse-contact-details': {
    'cell-phone': null,
    email: null,
  },
  'spouse-is-trustee': null,
  'trustee-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    title: null,
    relationship: null,
  },
  'trustee-contact-details': {
    'cell-phone': null,
  },
  'children-details': [],
  'beneficiary-details': [],
  'owner-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'marital-status': null,
    gender: null,
    title: null,
    ghanaian: null,
    'idpassport-number': null,
  },
  'owner-contact-details': {
    'cell-phone': null,
    email: null,
  },
  'owner-postal-address': {
    addressee: null,
    town: null,
    region: null,
    'po-box-or-private-bag': null,
  },
  'owner-residential-address': {
    street: null,
    suburb: null,
  },
  'owner-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
    occupation: null,
    'basic-earnings': null,
    'tin-number': null,
  },
  'owner-identfication-type': {
    dvla: null,
    passport: null,
    'voter-id': null,
    nhis: null,
    ssnit: null,
    'nia-ghana-card': null,
  },
  'existing-policies': {
    'existing-coverage': null,
    'application-replaces-existing': null,
  },
  'existing-policy-details': {
    'company-name-1': null,
    'existing-contract-id-1': null,
    'sum-assured-1': null,
    'company-name-2': null,
    'existing-contract-id-2': null,
    'sum-assured-2': null,
    'company-name-3': null,
    'existing-contract-id-3': null,
    'sum-assured-3': null,
  },
  'existing-policy-details': [],
  'life-assured-general-habits': {
    'planning-other-insurance': null,
    'planning-other-insurance-details': null,
    'other-assurance-past12-months': null,
    'other-assurance-past12-months-details': null,
    'cover-exceeds-one-hundred-thousand': null,
    'average-monthly-gross-income': null,
    'total-existing-assurance-cover-in-force-on-your-life': null,
    'full-reasons-for-effecting-this-additional-cover': null,
    'risky-occupation-hobbies': null,
    'risky-occupation-hobbies-details': null,
    'refused-application-or-increased-premium': null,
    'refused-application-or-increased-premium-details': null,
    'insolvency-issues': null,
    'insolvency-issues-details': null,
    'occupation-not-clerical': null,
    'occupation-not-clerical-details': null,
    smoker: null,
    'non-smoker': null,
    'never-smoked': null,
    'years-smoking': null,
    'years-not-smoking': null,
    'cigarettes-per-day': null,
    'cigars-per-day': null,
    pipe: null,
    'medical-advice-stop-smoking': null,
    'medical-advice-reduce-drinking': null,
    'excessive-drinking': null,
    'excessive-drinking-past': null,
  },
  'life-assured-health-tests': {
    'surgery-last-five-years': null,
    'medical-tests-last-five-years': null,
  },
  'life-assured-health-details': {
    'chest-pain': null,
    'high-blood-pressure': null,
    'respiratory-condition': null,
    'digestive-condition': null,
    'urinary-condition': null,
    'anxiety-epilepsy': null,
    diabetes: null,
    'cancer-or-tumors': null,
    'arthritis-osteoporosis': null,
    aids: null,
    'sexually-transmitted-disease': null,
    'taking-medication': null,
    disability: null,
    'assurance-risk': null,
    pregnant: null,
    'family-history-of-illness': null,
    'family-history-of-illness-details': null,
    'close-family-early-death': null,
    'height-in-cm': null,
    'weight-in-kg': null,
  },
  'health-concerns': [],
  'payment-method': {
    'debit-order': null,
    'stop-order': null,
  },
  'payment-start-date': null,
  'payment-frequency': {
    monthly: null,
    annual: null,
    'bi-annual': null,
    quarterly: null,
  },
  'payer-person-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    relationship: null,
  },
  'payment-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
  },
  'payer-signature': null,
  'payment-bank-details': {
    'account-holder-name': null,
    'account-type': null,
    'account-entity-name': null,
    'account-branch-name': null,
    'account-number': null,
  },
  'mobile-wallet-details': {
    'mobile-wallet-network-provider': null,
    'mobile-wallet-number': null,
  },
  documents: {
    'id-document': [],
  },
  'existing-policies-details': [],
  'life-assured-is-owner': null,
  'spouse-is-owner': null,
  'life-assured-is-payer': null,
  'acceptance-signature': null,
  'application-acceptance-date': null,
  'application-accepted': null,
}
