/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'
import ValidationErrors from './ValidationErrors'

const useTooltips = process.env.APPLICATION_USE_TOOLTIPS === 'true'

const Checkbox = ({
  value,
  id,
  label,
  readonly,
  hideLabel,
  required,
  errors,
  itemIndex,
  tooltip,
  onChange
}) => {
  const elementId = itemIndex ? `${id}-${itemIndex}` : id
  return (
    <div
      className={classNames({
        editable: !readonly,
        field: true,
        'has-tooltip': useTooltips && tooltip,
        'has-value': value.length > 0,
        checkbox: true
      })}
    >
      <div className='input-group'>
        <input
          type='checkbox'
          className={classNames({
            disabled: readonly,
            'has-error': errors && errors.length > 0,
            'is-required': required,
            placeholder: value.length === 0 && !useTooltips && tooltip
          })}
          name={label}
          id={elementId}
          required={required}
          onChange={onChange}
          checked={value === 'Y' || value === 'TRUE'}
        />
        <label
          htmlFor={elementId}
          className={classNames({
            hidden: hideLabel,
            label: true,
            'label-inline': true
          })}
        >
          {label}
        </label>
      </div>
      {errors && errors.length > 0 && <ValidationErrors errors={errors} />}
    </div>
  )
}

export default Checkbox
