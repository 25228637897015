/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const rates = {
  'mem_7:15:50000:43': 29.0,
  'ffun_add:15:7500:74': 100.0,
  'mem:10:20000:102': 577.0,
  'adb:10:30000:98': 424.0,
  'ffun:10:2000:44': 38.0,
  'ffun:15:5000:95': 189.0,
  'mem:15:10000:66': 40.0,
  'mem_7:10:10000:102': 174.0,
  'adb:15:20000:110': 282.0,
  'hcb:10:50:56': 3.0,
  'mem_7:15:15000:53': 20.0,
  'ffun_add:15:40000:67': 366.0,
  'mem:10:20000:83': 220.0,
  'mem:10:25000:25': 8.0,
  'mem_7:10:40000:12': 7.0,
  'ffun:10:2000:11': 33.0,
  'dis:10:20000:54': 13.0,
  'adb:10:15000:69': 10.0,
  'adb:10:25000:25': 20.0,
  'mem:10:25000:46': 47.0,
  'mem_40:15:50000:6': 11.0,
  'ffun_add:10:20000:62': 161.0,
  'ffun:15:10000:82': 201.0,
  'ffun_add:10:20000:26': 27.0,
  'mem_40:15:30000:46': 43.0,
  'ffun_add:10:2000:35': 9.0,
  'mem:10:2000:110': 59.0,
  'ffun_add:15:7500:37': 19.0,
  'ffun_add:15:30000:45': 80.0,
  'adb:15:2000:101': 29.0,
  'ffun_add:15:7500:11': 10.0,
  'mem_7:15:15000:6': 2.0,
  'adb:10:40000:37': 46.0,
  'adb:15:20000:109': 282.0,
  'mem:15:30000:10': 6.0,
  'mem:15:25000:12': 6.0,
  'adb:15:2000:76': 5.0,
  'ffun:10:7500:115': 522.0,
  'adb:15:25000:50': 40.0,
  'adb:15:30000:90': 145.0,
  'ffun:15:25000:94': 826.0,
  'ffun_add:10:50000:45': 164.0,
  'mem_40:10:7500:5': 2.0,
  'mem:15:15000:16': 4.0,
  'mem:10:5000:90': 73.0,
  'ffun:10:40000:58': 268.0,
  'ffun:15:30000:77': 397.0,
  'ffun_add:10:2000:106': 151.0,
  'mem_7:15:10000:62': 17.0,
  'adb:15:10000:16': 6.0,
  'mem:15:20000:59': 49.0,
  'mem:15:20000:35': 8.0,
  'mem:15:7500:25': 2.0,
  'mem_40:10:25000:104': 722.0,
  'adb:15:5000:57': 8.0,
  'adb:10:20000:2': 16.0,
  'mem_7:15:20000:57': 30.0,
  'ffun_add:10:40000:117': 2824.0,
  'hcb:10:100:43': 14.0,
  'ffun:10:25000:83': 648.0,
  'mem_7:10:20000:2': 4.0,
  'ffun_add:10:7500:16': 12.0,
  'adb:10:20000:33': 19.0,
  'mem:10:30000:40': 24.0,
  'mem:15:30000:68': 116.0,
  'mem_7:15:50000:102': 866.0,
  'mem:10:40000:30': 16.0,
  'mem_7:15:5000:23': 1.0,
  'mem_7:15:40000:0': 5.0,
  'ffun:10:7500:47': 57.0,
  'mem_40:10:2000:88': 30.0,
  'mem:15:10000:30': 4.0,
  'adb:15:50000:17': 29.0,
  'mem:15:5000:91': 96.0,
  'mem:15:2000:39': 1.0,
  'adb:10:2000:32': 2.0,
  'ffun_add:15:15000:73': 194.0,
  'mem_40:10:15000:47': 29.0,
  'mem:10:7500:91': 145.0,
  'ffun:15:40000:60': 197.0,
  'mem:10:30000:58': 90.0,
  'ffun:10:7500:46': 57.0,
  'adb:15:20000:71': 23.0,
  'mem_7:10:40000:87': 350.0,
  'adb:15:10000:7': 6.0,
  'ffun_add:15:10000:43': 31.0,
  'mem:10:50000:103': 1444.0,
  'mem_40:15:5000:47': 8.0,
  'dis:10:40000:26': 14.0,
  'ffun:15:15000:106': 752.0,
  'mem_40:10:30000:5': 8.0,
  'mem_40:15:30000:49': 43.0,
  'mem_7:10:10000:11': 2.0,
  'adb:10:30000:84': 103.0,
  'mem_7:15:15000:56': 23.0,
  'mem_7:15:7500:14': 1.0,
  'adb:15:2000:102': 29.0,
  'mem_7:10:40000:84': 264.0,
  'adb:10:2000:12': 2.0,
  'dis:10:30000:55': 15.0,
  'ffun:10:25000:85': 648.0,
  'mem_40:15:50000:64': 133.0,
  'mem_40:15:15000:24': 4.0,
  'hcb:15:150:54': 28.0,
  'mem:15:10000:19': 2.0,
  'mem_7:10:2000:23': 1.0,
  'mem:10:7500:27': 4.0,
  'mem:15:20000:103': 577.0,
  'ffun_add:10:20000:10': 20.0,
  'adb:10:25000:111': 353.0,
  'mem_40:15:15000:55': 34.0,
  'mem_7:15:25000:28': 5.0,
  'ffun_add:15:2000:65': 19.0,
  'ffun_add:15:30000:115': 2025.0,
  'mem_7:15:50000:101': 866.0,
  'hcb:10:80:59': 9.0,
  'ffun:10:30000:90': 1002.0,
  'mem_7:15:30000:45': 17.0,
  'ffun:10:20000:28': 44.0,
  'dis:10:30000:18': 13.0,
  'ffun:15:5000:51': 50.0,
  'mem_40:10:40000:7': 11.0,
  'ffun:15:10000:20': 34.0,
  'ffun_add:10:20000:53': 151.0,
  'adb:10:5000:20': 5.0,
  'mem_40:10:40000:1': 11.0,
  'ffun_add:15:7500:3': 10.0,
  'ffun:15:10000:16': 34.0,
  'ffun_add:15:10000:118': 679.0,
  'ffun_add:10:30000:66': 334.0,
  'ffun:10:30000:84': 767.0,
  'ffun_add:15:50000:97': 3373.0,
  'ffun:10:50000:58': 326.0,
  'mem_40:10:20000:69': 92.0,
  'mem_40:10:5000:81': 55.0,
  'ffun:10:30000:54': 205.0,
  'ffun_add:15:30000:43': 80.0,
  'mem_40:15:10000:91': 192.0,
  'mem:10:15000:95': 289.0,
  'mem_40:15:30000:99': 865.0,
  'mem_7:10:10000:8': 2.0,
  'mem_7:10:20000:31': 7.0,
  'ffun:15:20000:16': 38.0,
  'ffun:15:10000:100': 511.0,
  'adb:10:2000:62': 2.0,
  'mem_40:10:30000:3': 8.0,
  'mem_40:15:20000:48': 30.0,
  'mem_7:10:40000:55': 67.0,
  'mem_7:15:7500:80': 36.0,
  'adb:10:40000:58': 76.0,
  'mem_7:10:40000:108': 692.0,
  'mem_40:10:10000:109': 289.0,
  'mem:15:30000:71': 161.0,
  'mem:15:7500:112': 217.0,
  'mem:10:15000:78': 124.0,
  'adb:15:7500:105': 107.0,
  'adb:15:40000:65': 19.0,
  'mem_7:10:20000:103': 347.0,
  'mem:15:15000:89': 211.0,
  'hcb:10:80:33': 9.0,
  'ffun_add:15:30000:35': 37.0,
  'adb:15:30000:101': 424.0,
  'mem:15:20000:9': 5.0,
  'mem_40:15:2000:53': 5.0,
  'adb:15:2000:116': 29.0,
  'adb:10:7500:110': 107.0,
  'mem_40:10:2000:13': 1.0,
  'ffun:15:10000:46': 57.0,
  'ffun_add:10:20000:93': 950.0,
  'ffun:10:2000:59': 51.0,
  'mem:10:10000:108': 289.0,
  'mem:15:10000:81': 103.0,
  'mem_40:15:50000:22': 12.0,
  'mem_7:15:40000:58': 59.0,
  'ffun_add:15:30000:70': 276.0,
  'mem_40:15:50000:95': 956.0,
  'ffun_add:15:15000:33': 23.0,
  'mem:15:2000:57': 6.0,
  'mem_7:10:7500:27': 2.0,
  'ffun:10:5000:104': 363.0,
  'dis:10:50000:30': 15.0,
  'mem_40:10:50000:61': 162.0,
  'adb:10:30000:21': 24.0,
  'mem_40:10:20000:81': 220.0,
  'ffun:15:50000:41': 117.0,
  'ffun:10:7500:56': 83.0,
  'mem_40:15:50000:39': 30.0,
  'ffun_add:15:40000:21': 31.0,
  'adb:10:2000:24': 2.0,
  'ffun:10:10000:61': 99.0,
  'ffun:15:7500:62': 64.0,
  'adb:15:50000:0': 29.0,
  'ffun:15:7500:82': 158.0,
  'mem:10:30000:42': 37.0,
  'ffun_add:15:20000:115': 1353.0,
  'mem_7:15:7500:111': 131.0,
  'adb:15:40000:91': 298.0,
  'mem_40:15:10000:66': 40.0,
  'mem_7:10:30000:76': 148.0,
  'mem:10:15000:100': 433.0,
  'mem:10:2000:86': 30.0,
  'ffun:15:50000:76': 642.0,
  'adb:15:30000:118': 424.0,
  'mem_7:10:7500:101': 131.0,
  'dis:10:10000:35': 11.0,
  'adb:15:5000:56': 8.0,
  'ffun:15:10000:37': 41.0,
  'ffun_add:10:5000:90': 188.0,
  'adb:10:25000:12': 19.0,
  'ffun_add:10:5000:31': 13.0,
  'mem_40:10:25000:0': 7.0,
  'adb:10:30000:62': 19.0,
  'mem_40:15:2000:94': 38.0,
  'ffun:10:40000:20': 49.0,
  'adb:10:50000:38': 58.0,
  'ffun:10:30000:88': 1002.0,
  'hcb:10:80:28': 9.0,
  'mem_40:15:15000:26': 5.0,
  'mem_7:15:5000:114': 88.0,
  'ffun:10:30000:109': 1940.0,
  'mem_40:10:50000:81': 550.0,
  'mem_40:10:15000:82': 166.0,
  'mem:10:7500:113': 217.0,
  'dis:10:30000:56': 19.0,
  'ffun:15:2000:26': 31.0,
  'mem_7:15:20000:63': 32.0,
  'mem:15:10000:111': 289.0,
  'adb:10:50000:75': 67.0,
  'mem:15:15000:112': 433.0,
  'ffun:10:25000:86': 843.0,
  'ffun_add:10:7500:23': 13.0,
  'ffun:10:7500:67': 102.0,
  'mem:10:15000:61': 49.0,
  'mem_7:10:50000:93': 578.0,
  'mem_7:10:7500:45': 6.0,
  'mem:10:30000:120': 865.0,
  'mem_7:15:40000:12': 6.0,
  'mem:10:30000:114': 865.0,
  'adb:10:50000:99': 706.0,
  'mem_7:10:7500:119': 131.0,
  'mem:10:50000:0': 13.0,
  'ffun_add:10:40000:111': 2824.0,
  'mem_7:15:20000:101': 347.0,
  'dis:15:50000:58': 18.0,
  'mem_7:10:50000:104': 866.0,
  'ffun_add:10:25000:35': 39.0,
  'adb:15:30000:112': 424.0,
  'ffun:10:30000:68': 292.0,
  'adb:10:10000:94': 76.0,
  'mem_7:15:50000:81': 308.0,
  'mem:15:50000:98': 1444.0,
  'mem:15:5000:73': 28.0,
  'adb:15:7500:118': 107.0,
  'mem_7:10:7500:21': 2.0,
  'mem:10:30000:56': 90.0,
  'mem_40:10:50000:105': 1444.0,
  'ffun_add:10:40000:27': 46.0,
  'mem_7:10:40000:90': 350.0,
  'ffun:15:7500:90': 206.0,
  'ffun_add:10:50000:65': 390.0,
  'mem_7:15:25000:6': 4.0,
  'adb:10:50000:78': 122.0,
  'ffun_add:15:40000:78': 692.0,
  'adb:10:40000:44': 59.0,
  'adb:10:30000:28': 25.0,
  'mem:15:20000:115': 577.0,
  'mem_7:10:7500:10': 1.0,
  'ffun_add:15:10000:30': 14.0,
  'ffun_add:10:30000:120': 2118.0,
  'mem:10:20000:16': 6.0,
  'adb:10:5000:77': 13.0,
  'ffun_add:15:50000:34': 58.0,
  'mem_40:10:10000:18': 4.0,
  'mem:15:2000:14': 1.0,
  'ffun_add:15:5000:120': 343.0,
  'mem_7:10:7500:85': 50.0,
  'adb:10:50000:73': 67.0,
  'mem_40:10:7500:43': 10.0,
  'adb:15:50000:79': 108.0,
  'mem:10:15000:87': 218.0,
  'ffun_add:15:5000:96': 343.0,
  'mem_40:10:40000:58': 120.0,
  'mem:15:30000:4': 6.0,
  'ffun_add:15:20000:96': 1353.0,
  'adb:10:15000:56': 29.0,
  'ffun:15:5000:47': 44.0,
  'ffun:10:5000:99': 363.0,
  'ffun:15:5000:39': 36.0,
  'mem_40:10:30000:84': 329.0,
  'mem_40:15:10000:15': 2.0,
  'mem_40:10:7500:104': 217.0,
  'mem_40:10:7500:93': 145.0,
  'ffun_add:15:5000:67': 52.0,
  'mem_7:10:15000:15': 2.0,
  'mem:15:50000:109': 1444.0,
  'ffun_add:10:50000:89': 1785.0,
  'ffun_add:10:15000:72': 228.0,
  'ffun:10:25000:88': 843.0,
  'mem_7:15:30000:21': 5.0,
  'mem_40:15:15000:27': 5.0,
  'mem_40:15:2000:91': 38.0,
  'mem_40:10:25000:26': 10.0,
  'mem_7:15:2000:96': 35.0,
  'ffun:15:25000:74': 252.0,
  'mem_40:15:25000:44': 24.0,
  'ffun:15:40000:70': 287.0,
  'mem_40:15:20000:76': 157.0,
  'ffun:10:2000:65': 52.0,
  'adb:10:40000:3': 31.0,
  'mem:15:5000:27': 2.0,
  'ffun:15:40000:118': 1953.0,
  'ffun:10:20000:37': 58.0,
  'adb:15:5000:50': 8.0,
  'mem_40:15:40000:41': 38.0,
  'mem_40:15:50000:59': 121.0,
  'mem_7:15:30000:85': 185.0,
  'mem_7:10:40000:33': 13.0,
  'mem_7:15:30000:118': 520.0,
  'ffun_add:10:15000:95': 715.0,
  'mem:15:25000:71': 134.0,
  'ffun:10:20000:19': 41.0,
  'mem:10:30000:13': 8.0,
  'ffun:15:7500:13': 33.0,
  'mem:15:30000:8': 6.0,
  'mem:15:15000:88': 211.0,
  'dis:10:30000:39': 13.0,
  'adb:15:30000:48': 47.0,
  'adb:10:20000:100': 282.0,
  'ffun:10:25000:25': 44.0,
  'ffun:15:15000:6': 36.0,
  'mem_7:10:5000:83': 34.0,
  'mem:10:20000:22': 7.0,
  'ffun:10:50000:100': 3205.0,
  'ffun:10:10000:113': 679.0,
  'dis:10:20000:23': 12.0,
  'ffun:10:7500:0': 34.0,
  'mem:15:10000:41': 10.0,
  'ffun_add:10:20000:33': 33.0,
  'mem:10:10000:95': 193.0,
  'adb:15:15000:49': 24.0,
  'mem_7:10:15000:81': 100.0,
  'ffun_add:15:20000:111': 1353.0,
  'ffun_add:10:10000:7': 13.0,
  'mem_7:15:2000:104': 35.0,
  'mem_40:15:7500:117': 217.0,
  'mem:15:50000:48': 73.0,
  'ffun_add:15:15000:118': 1017.0,
  'mem:15:40000:117': 1154.0,
  'ffun_add:10:30000:2': 27.0,
  'adb:10:25000:109': 353.0,
  'ffun:15:50000:20': 50.0,
  'mem_7:10:10000:15': 2.0,
  'mem_7:10:2000:1': 1.0,
  'mem:10:15000:106': 433.0,
  'mem_40:15:5000:103': 145.0,
  'adb:15:15000:2': 10.0,
  'mem_40:15:7500:31': 4.0,
  'mem_7:10:2000:106': 35.0,
  'adb:10:50000:43': 73.0,
  'adb:15:15000:67': 7.0,
  'ffun_add:15:40000:54': 229.0,
  'ffun_add:15:20000:36': 37.0,
  'ffun:15:2000:65': 39.0,
  'mem:15:15000:10': 4.0,
  'ffun:10:10000:77': 189.0,
  'adb:10:5000:33': 5.0,
  'mem_7:10:40000:94': 463.0,
  'mem_40:15:5000:98': 145.0,
  'adb:10:2000:75': 4.0,
  'mem:15:40000:54': 88.0,
  'mem:10:30000:84': 329.0,
  'ffun:10:2000:73': 62.0,
  'mem:15:40000:65': 107.0,
  'mem:15:15000:66': 59.0,
  'ffun_add:10:20000:2': 20.0,
  'ffun:15:25000:85': 457.0,
  'ffun_add:15:30000:21': 26.0,
  'mem:15:5000:67': 20.0,
  'mem_7:15:15000:109': 260.0,
  'mem_40:10:30000:101': 865.0,
  'ffun_add:15:7500:101': 511.0,
  'mem_7:10:2000:73': 8.0,
  'mem_40:10:50000:20': 14.0,
  'adb:10:2000:10': 2.0,
  'dis:10:50000:39': 15.0,
  'mem:15:20000:62': 54.0,
  'mem:10:5000:22': 2.0,
  'mem_40:10:10000:118': 289.0,
  'ffun:15:20000:21': 39.0,
  'mem_7:15:50000:42': 29.0,
  'ffun:15:30000:1': 42.0,
  'ffun:10:40000:92': 1734.0,
  'ffun_add:15:2000:61': 19.0,
  'mem_7:15:30000:114': 520.0,
  'adb:15:20000:8': 12.0,
  'mem_40:10:10000:95': 193.0,
  'mem:10:15000:47': 29.0,
  'ffun_add:10:40000:6': 34.0,
  'mem_40:15:25000:94': 479.0,
  'ffun:10:50000:41': 140.0,
  'mem:10:15000:112': 433.0,
  'ffun:10:15000:39': 51.0,
  'dis:15:10000:19': 9.0,
  'mem_7:10:50000:59': 90.0,
  'ffun:15:30000:3': 42.0,
  'mem_7:10:40000:103': 692.0,
  'mem_40:10:40000:117': 1154.0,
  'ffun:10:30000:81': 767.0,
  'mem_7:15:2000:20': 1.0,
  'mem_40:15:20000:35': 8.0,
  'mem_7:10:5000:24': 1.0,
  'mem:15:50000:50': 73.0,
  'mem_40:10:15000:39': 12.0,
  'mem_40:15:7500:65': 20.0,
  'mem_40:15:25000:58': 61.0,
  'ffun_add:10:10000:104': 713.0,
  'mem:10:7500:118': 217.0,
  'adb:10:50000:0': 38.0,
  'mem_7:10:10000:85': 66.0,
  'ffun:15:5000:102': 271.0,
  'mem:15:30000:62': 80.0,
  'ffun:10:30000:34': 57.0,
  'adb:15:2000:25': 1.0,
  'mem:15:15000:94': 287.0,
  'ffun_add:15:7500:0': 10.0,
  'mem_7:15:5000:70': 12.0,
  'ffun_add:10:2000:0': 8.0,
  'mem:10:2000:76': 17.0,
  'ffun_add:15:40000:101': 2698.0,
  'adb:15:5000:4': 4.0,
  'mem_40:15:25000:72': 145.0,
  'ffun_add:15:5000:82': 126.0,
  'ffun:15:50000:115': 2435.0,
  'mem_40:10:2000:22': 1.0,
  'mem:15:15000:86': 211.0,
  'ffun_add:10:2000:82': 63.0,
  'ffun:10:10000:102': 679.0,
  'ffun_add:10:20000:60': 157.0,
  'mem_40:15:20000:83': 205.0,
  'ffun:10:25000:95': 1102.0,
  'mem_7:10:20000:89': 175.0,
  'mem_40:15:2000:73': 12.0,
  'dis:15:50000:22': 12.0,
  'mem_40:15:30000:57': 72.0,
  'ffun:15:50000:52': 229.0,
  'ffun:15:20000:98': 992.0,
  'adb:10:50000:115': 706.0,
  'ffun_add:10:15000:35': 27.0,
  'ffun_add:15:7500:91': 341.0,
  'mem:15:40000:13': 8.0,
  'ffun_add:10:30000:52': 222.0,
  'ffun_add:10:7500:86': 276.0,
  'mem:10:25000:33': 13.0,
  'adb:10:20000:84': 70.0,
  'adb:10:25000:73': 34.0,
  'mem_7:10:7500:78': 37.0,
  'mem:10:2000:54': 6.0,
  'adb:10:30000:96': 424.0,
  'ffun:15:50000:3': 49.0,
  'adb:15:50000:108': 706.0,
  'mem_7:15:7500:44': 5.0,
  'ffun_add:15:7500:89': 252.0,
  'adb:15:50000:44': 58.0,
  'ffun:15:15000:37': 47.0,
  'mem:15:10000:96': 289.0,
  'mem:15:15000:20': 4.0,
  'dis:15:10000:35': 9.0,
  'hcb:15:100:28': 12.0,
  'adb:10:20000:25': 16.0,
  'hcb:15:50:18': 3.0,
  'ffun:15:10000:71': 119.0,
  'mem:10:10000:99': 289.0,
  'ffun_add:15:25000:46': 100.0,
  'ffun_add:10:10000:108': 713.0,
  'hcb:10:120:48': 21.0,
  'mem_7:15:40000:97': 692.0,
  'ffun_add:15:10000:12': 12.0,
  'ffun:10:30000:48': 128.0,
  'adb:15:30000:50': 47.0,
  'ffun:10:15000:2': 38.0,
  'adb:10:20000:120': 282.0,
  'mem:10:10000:23': 4.0,
  'ffun:15:5000:49': 44.0,
  'mem_7:15:25000:51': 34.0,
  'hcb:15:50:54': 3.0,
  'mem:15:25000:7': 6.0,
  'ffun:10:5000:96': 363.0,
  'ffun_add:10:5000:40': 17.0,
  'adb:15:2000:99': 29.0,
  'adb:10:2000:70': 2.0,
  'mem_7:10:20000:6': 4.0,
  'mem_40:10:7500:25': 2.0,
  'adb:15:10000:46': 16.0,
  'ffun_add:15:2000:37': 10.0,
  'dis:15:10000:48': 9.0,
  'ffun_add:10:40000:51': 293.0,
  'ffun:10:20000:96': 1310.0,
  'mem_7:10:10000:86': 88.0,
  'ffun_add:15:30000:51': 174.0,
  'mem_40:15:15000:21': 4.0,
  'mem:10:30000:89': 437.0,
  'ffun:10:20000:61': 157.0,
  'mem_40:10:50000:58': 150.0,
  'mem:15:50000:25': 12.0,
  'mem:10:20000:2': 6.0,
  'mem:15:7500:97': 217.0,
  'ffun:10:5000:88': 206.0,
  'adb:10:10000:88': 50.0,
  'mem:15:7500:2': 2.0,
  'adb:10:30000:113': 424.0,
  'ffun_add:10:2000:96': 151.0,
  'ffun:15:2000:49': 36.0,
  'ffun_add:10:5000:42': 23.0,
  'mem_40:10:20000:92': 386.0,
  'mem_40:15:2000:31': 1.0,
  'ffun_add:10:15000:28': 21.0,
  'ffun:10:7500:88': 286.0,
  'mem_40:10:2000:28': 1.0,
  'mem:10:20000:8': 6.0,
  'mem_40:15:50000:54': 110.0,
  'mem_7:15:50000:44': 29.0,
  'adb:10:40000:30': 32.0,
  'mem:10:2000:51': 6.0,
  'mem_40:15:30000:4': 6.0,
  'adb:10:50000:119': 706.0,
  'mem:15:2000:55': 5.0,
  'mem_7:10:50000:97': 866.0,
  'mem_7:10:25000:66': 70.0,
  'mem:15:10000:43': 10.0,
  'ffun:15:10000:66': 95.0,
  'ffun_add:10:25000:54': 187.0,
  'ffun:10:7500:109': 522.0,
  'ffun:15:2000:86': 77.0,
  'mem_40:10:5000:66': 24.0,
  'ffun_add:15:5000:81': 126.0,
  'ffun:10:40000:26': 57.0,
  'mem_40:15:20000:70': 78.0,
  'mem:10:20000:106': 577.0,
  'mem:15:50000:62': 133.0,
  'mem_7:10:15000:89': 132.0,
  'adb:15:40000:52': 88.0,
  'mem_7:10:10000:94': 116.0,
  'mem:10:20000:12': 6.0,
  'adb:10:2000:0': 2.0,
  'ffun:15:5000:4': 32.0,
  'ffun:15:2000:8': 31.0,
  'ffun_add:10:50000:33': 72.0,
  'adb:15:40000:90': 193.0,
  'mem_40:15:5000:85': 52.0,
  'adb:15:10000:30': 7.0,
  'adb:10:20000:34': 19.0,
  'adb:10:50000:9': 38.0,
  'mem_7:10:7500:4': 1.0,
  'mem_40:15:2000:24': 1.0,
  'mem_7:10:10000:108': 174.0,
  'ffun_add:10:10000:120': 713.0,
  'mem_40:10:40000:76': 328.0,
  'ffun_add:10:20000:66': 225.0,
  'ffun_add:10:20000:21': 23.0,
  'ffun:10:15000:70': 165.0,
  'ffun_add:15:2000:56': 19.0,
  'dis:15:30000:54': 12.0,
  'mem_7:10:25000:22': 5.0,
  'dis:10:30000:29': 13.0,
  'mem:10:15000:39': 12.0,
  'hcb:10:80:39': 9.0,
  'ffun_add:10:5000:118': 362.0,
  'adb:15:5000:117': 71.0,
  'mem_7:15:25000:87': 211.0,
  'mem_40:15:20000:2': 5.0,
  'ffun:10:20000:91': 891.0,
  'mem_40:10:7500:91': 145.0,
  'mem:10:40000:70': 184.0,
  'mem_40:10:20000:100': 577.0,
  'mem_40:15:50000:115': 1444.0,
  'mem:10:30000:25': 10.0,
  'mem_40:10:50000:88': 728.0,
  'mem_40:15:50000:58': 121.0,
  'ffun:10:25000:56': 183.0,
  'ffun_add:10:25000:50': 126.0,
  'adb:10:10000:70': 7.0,
  'mem:15:20000:65': 54.0,
  'mem:10:5000:92': 97.0,
  'ffun_add:15:30000:84': 723.0,
  'ffun_add:15:2000:11': 7.0,
  'ffun:10:7500:112': 522.0,
  'adb:10:7500:61': 5.0,
  'ffun:15:5000:34': 34.0,
  'ffun_add:15:15000:23': 16.0,
  'mem:15:20000:80': 148.0,
  'mem:15:40000:15': 8.0,
  'mem_40:10:40000:36': 31.0,
  'mem_7:10:25000:70': 70.0,
  'ffun:10:7500:116': 522.0,
  'ffun:10:30000:115': 1940.0,
  'adb:10:10000:54': 28.0,
  'mem_7:10:7500:35': 2.0,
  'ffun:10:5000:89': 206.0,
  'ffun:15:5000:36': 36.0,
  'mem:10:7500:30': 4.0,
  'mem_40:15:50000:118': 1444.0,
  'ffun_add:15:20000:59': 124.0,
  'ffun_add:15:2000:25': 7.0,
  'ffun:15:30000:37': 63.0,
  'mem:10:50000:14': 13.0,
  'mem:10:30000:91': 578.0,
  'dis:10:20000:28': 12.0,
  'mem:10:5000:71': 31.0,
  'mem_7:15:25000:92': 287.0,
  'mem:10:15000:98': 433.0,
  'ffun_add:10:20000:83': 545.0,
  'mem_40:10:25000:44': 31.0,
  'hcb:10:50:58': 3.0,
  'ffun:10:5000:72': 96.0,
  'dis:10:50000:37': 15.0,
  'ffun_add:10:20000:16': 21.0,
  'mem:15:30000:72': 161.0,
  'ffun:10:10000:51': 95.0,
  'mem_7:15:20000:41': 12.0,
  'adb:10:25000:11': 19.0,
  'mem_40:15:2000:36': 1.0,
  'ffun_add:10:15000:62': 123.0,
  'mem_40:15:15000:99': 433.0,
  'ffun_add:10:15000:7': 17.0,
  'ffun_add:15:10000:21': 13.0,
  'ffun_add:15:7500:64': 54.0,
  'mem_7:15:15000:59': 23.0,
  'mem_7:10:40000:80': 197.0,
  'mem_7:15:2000:118': 35.0,
  'mem:10:7500:58': 23.0,
  'mem_7:15:5000:6': 1.0,
  'mem_7:15:50000:1': 6.0,
  'mem:15:10000:98': 289.0,
  'mem:15:40000:63': 107.0,
  'mem_7:10:2000:43': 2.0,
  'mem:10:7500:18': 2.0,
  'mem_7:15:5000:81': 31.0,
  'dis:15:30000:28': 11.0,
  'ffun_add:15:25000:57': 153.0,
  'ffun:10:15000:111': 994.0,
  'ffun:15:20000:101': 992.0,
  'adb:10:25000:41': 37.0,
  'mem_7:15:10000:75': 36.0,
  'mem:10:10000:16': 4.0,
  'mem_7:10:5000:6': 1.0,
  'mem:15:7500:15': 2.0,
  'ffun_add:10:50000:109': 3527.0,
  'ffun_add:10:40000:113': 2824.0,
  'mem:15:25000:39': 16.0,
  'ffun_add:15:40000:45': 104.0,
  'dis:10:30000:40': 13.0,
  'adb:15:10000:23': 6.0,
  'mem_40:15:7500:40': 5.0,
  'mem_40:10:20000:58': 60.0,
  'mem_7:10:25000:115': 433.0,
  'adb:10:15000:92': 113.0,
  'ffun:10:30000:110': 1940.0,
  'ffun:10:50000:74': 605.0,
  'mem_7:10:2000:25': 1.0,
  'adb:15:20000:93': 149.0,
  'adb:15:30000:4': 18.0,
  'ffun:15:7500:28': 34.0,
  'adb:10:10000:96': 142.0,
  'mem_40:10:25000:4': 7.0,
  'ffun_add:10:2000:94': 104.0,
  'adb:15:15000:8': 10.0,
  'adb:15:7500:50': 12.0,
  'adb:10:50000:54': 137.0,
  'mem_7:15:30000:2': 4.0,
  'mem_7:15:5000:47': 5.0,
  'ffun:10:7500:57': 83.0,
  'ffun_add:15:20000:47': 82.0,
  'adb:15:10000:57': 16.0,
  'mem:15:50000:113': 1444.0,
  'mem:10:50000:101': 1444.0,
  'dis:15:30000:23': 11.0,
  'mem_7:15:20000:71': 71.0,
  'mem_40:15:2000:80': 17.0,
  'mem_40:15:25000:16': 6.0,
  'mem_7:15:15000:46': 13.0,
  'ffun_add:15:7500:62': 54.0,
  'mem:10:5000:58': 16.0,
  'mem:15:10000:116': 289.0,
  'ffun:15:2000:43': 34.0,
  'mem:10:50000:1': 13.0,
  'ffun_add:15:5000:63': 38.0,
  'mem:15:7500:36': 5.0,
  'ffun:15:2000:31': 32.0,
  'mem:15:7500:100': 217.0,
  'ffun_add:15:5000:86': 171.0,
  'mem_40:15:10000:109': 289.0,
  'ffun_add:10:15000:46': 79.0,
  'mem_40:10:30000:48': 56.0,
  'adb:15:40000:16': 23.0,
  'mem:10:15000:58': 46.0,
  'mem:10:5000:67': 24.0,
  'ffun:10:10000:24': 37.0,
  'mem_7:10:7500:49': 8.0,
  'adb:10:15000:96': 212.0,
  'dis:10:20000:39': 12.0,
  'ffun_add:10:20000:0': 20.0,
  'ffun:10:10000:38': 45.0,
  'mem_40:15:7500:83': 78.0,
  'ffun_add:15:30000:92': 1343.0,
  'ffun_add:10:25000:101': 1769.0,
  'mem_7:15:5000:13': 1.0,
  'ffun_add:15:20000:120': 1353.0,
  'mem_7:15:25000:108': 433.0,
  'ffun_add:15:5000:69': 52.0,
  'mem:10:2000:17': 1.0,
  'mem_40:10:15000:59': 46.0,
  'ffun:15:50000:83': 884.0,
  'ffun:10:25000:112': 1626.0,
  'ffun:10:30000:12': 45.0,
  'mem:15:25000:88': 353.0,
  'adb:10:25000:22': 20.0,
  'mem_7:15:5000:54': 7.0,
  'ffun_add:10:15000:112': 1065.0,
  'adb:10:30000:90': 150.0,
  'adb:15:30000:49': 47.0,
  'mem_40:15:25000:107': 722.0,
  'mem_40:15:25000:78': 197.0,
  'mem_7:10:2000:113': 35.0,
  'adb:15:10000:78': 22.0,
  'mem_7:15:25000:83': 155.0,
  'ffun:15:15000:114': 752.0,
  'adb:10:50000:116': 706.0,
  'mem:10:10000:29': 5.0,
  'adb:15:50000:56': 74.0,
  'adb:15:25000:43': 29.0,
  'mem_7:15:2000:60': 4.0,
  'ffun:15:5000:97': 271.0,
  'ffun:10:50000:111': 3205.0,
  'ffun_add:10:50000:11': 41.0,
  'ffun_add:15:40000:110': 2698.0,
  'adb:10:5000:4': 5.0,
  'ffun_add:15:25000:55': 146.0,
  'mem_40:10:7500:35': 5.0,
  'adb:15:7500:11': 5.0,
  'mem_7:10:50000:107': 866.0,
  'ffun:10:25000:48': 112.0,
  'adb:10:10000:72': 14.0,
  'mem_7:10:30000:39': 14.0,
  'ffun:10:25000:10': 42.0,
  'ffun:10:30000:0': 44.0,
  'ffun:10:5000:49': 49.0,
  'dis:10:20000:32': 12.0,
  'mem_40:15:10000:103': 289.0,
  'adb:10:5000:7': 5.0,
  'mem:10:15000:118': 433.0,
  'mem:10:2000:11': 1.0,
  'mem_40:10:5000:2': 2.0,
  'mem:15:20000:73': 107.0,
  'mem_40:15:15000:69': 59.0,
  'mem_40:10:20000:29': 8.0,
  'mem:15:15000:111': 433.0,
  'mem_40:15:30000:16': 7.0,
  'ffun_add:15:15000:61': 100.0,
  'ffun_add:10:10000:78': 202.0,
  'mem_7:10:50000:29': 12.0,
  'mem:15:50000:105': 1444.0,
  'mem_7:10:5000:21': 1.0,
  'mem_7:10:10000:30': 2.0,
  'ffun_add:15:30000:10': 23.0,
  'ffun_add:10:5000:117': 362.0,
  'mem_7:10:25000:111': 433.0,
  'adb:10:15000:111': 212.0,
  'mem:15:20000:105': 577.0,
  'mem_40:10:30000:63': 97.0,
  'mem_7:15:2000:43': 1.0,
  'ffun:10:2000:40': 35.0,
  'mem_40:15:40000:78': 314.0,
  'ffun_add:10:25000:67': 280.0,
  'mem_40:10:7500:33': 5.0,
  'mem_40:10:25000:10': 7.0,
  'ffun:15:7500:55': 60.0,
  'adb:10:50000:60': 95.0,
  'mem_40:15:15000:11': 4.0,
  'mem_40:10:2000:44': 4.0,
  'mem_7:10:25000:96': 433.0,
  'mem_7:15:5000:53': 7.0,
  'mem_40:10:50000:44': 61.0,
  'mem:10:20000:114': 577.0,
  'adb:10:20000:112': 282.0,
  'ffun_add:15:7500:38': 19.0,
  'mem_7:15:5000:92': 58.0,
  'ffun:15:10000:95': 349.0,
  'mem:15:20000:60': 49.0,
  'mem:10:20000:21': 7.0,
  'mem_7:15:5000:30': 1.0,
  'mem_40:15:40000:83': 410.0,
  'mem_7:15:50000:65': 80.0,
  'mem_40:15:25000:70': 97.0,
  'mem_40:15:40000:36': 24.0,
  'ffun:10:15000:100': 994.0,
  'adb:15:15000:27': 10.0,
  'ffun_add:10:15000:65': 123.0,
  'mem:10:25000:28': 10.0,
  'mem_7:10:7500:67': 22.0,
  'mem_7:15:2000:48': 2.0,
  'adb:10:10000:19': 8.0,
  'adb:15:50000:115': 706.0,
  'mem_40:15:7500:51': 17.0,
  'mem_7:15:7500:119': 131.0,
  'ffun_add:15:30000:71': 380.0,
  'adb:10:7500:113': 107.0,
  'mem_7:10:40000:61': 78.0,
  'ffun_add:15:5000:92': 230.0,
  'mem_7:15:30000:28': 6.0,
  'adb:10:30000:15': 23.0,
  'adb:10:20000:43': 30.0,
  'dis:15:50000:45': 13.0,
  'ffun_add:15:7500:115': 511.0,
  'ffun_add:15:20000:66': 187.0,
  'hcb:10:100:29': 14.0,
  'mem_7:10:20000:78': 98.0,
  'mem_7:10:7500:80': 37.0,
  'mem_7:10:30000:5': 5.0,
  'adb:15:50000:49': 78.0,
  'dis:10:20000:24': 12.0,
  'mem:15:5000:79': 37.0,
  'ffun_add:15:2000:103': 142.0,
  'ffun_add:10:15000:66': 171.0,
  'adb:10:2000:29': 2.0,
  'ffun_add:10:50000:18': 42.0,
  'mem_7:10:10000:83': 66.0,
  'dis:15:40000:23': 11.0,
  'adb:10:25000:70': 16.0,
  'mem_40:10:2000:120': 59.0,
  'ffun:10:30000:89': 1002.0,
  'mem:10:7500:54': 22.0,
  'ffun_add:15:2000:96': 142.0,
  'mem_40:10:40000:106': 1154.0,
  'adb:15:7500:94': 56.0,
  'hcb:15:50:19': 3.0,
  'dis:10:10000:44': 11.0,
  'mem_40:10:7500:24': 2.0,
  'ffun:15:30000:93': 985.0,
  'mem_40:10:25000:25': 8.0,
  'dis:10:50000:32': 15.0,
  'ffun_add:10:30000:45': 101.0,
  'adb:15:50000:52': 109.0,
  'mem_7:10:40000:46': 44.0,
  'ffun:10:10000:34': 40.0,
  'mem_7:15:15000:19': 2.0,
  'mem_40:15:2000:38': 1.0,
  'ffun:15:5000:35': 34.0,
  'ffun_add:15:5000:106': 343.0,
  'ffun_add:10:30000:68': 334.0,
  'mem:15:15000:104': 433.0,
  'adb:15:10000:65': 5.0,
  'ffun:15:7500:9': 33.0,
  'mem_7:10:20000:62': 40.0,
  'ffun_add:15:20000:89': 663.0,
  'mem_7:15:25000:0': 4.0,
  'ffun_add:15:10000:86': 335.0,
  'mem_40:10:50000:23': 16.0,
  'mem_40:15:40000:51': 88.0,
  'ffun_add:10:30000:102': 2118.0,
  'dis:15:10000:38': 9.0,
  'ffun:15:25000:4': 40.0,
  'mem:15:15000:18': 4.0,
  'ffun:10:30000:20': 45.0,
  'ffun:10:5000:30': 35.0,
  'dis:15:20000:41': 10.0,
  'mem_7:15:50000:107': 866.0,
  'adb:15:25000:109': 353.0,
  'ffun:15:10000:48': 57.0,
  'adb:10:15000:24': 12.0,
  'ffun_add:15:2000:17': 7.0,
  'ffun:15:5000:5': 32.0,
  'adb:10:25000:1': 19.0,
  'hcb:15:80:26': 7.0,
  'ffun_add:15:5000:20': 10.0,
  'ffun_add:15:25000:69': 231.0,
  'mem:15:40000:0': 8.0,
  'mem_40:10:15000:23': 5.0,
  'mem:15:20000:49': 30.0,
  'mem_40:10:10000:63': 32.0,
  'mem_7:10:20000:22': 5.0,
  'ffun_add:10:20000:76': 395.0,
  'adb:15:7500:87': 37.0,
  'ffun_add:10:20000:79': 395.0,
  'adb:10:30000:46': 59.0,
  'mem:15:15000:56': 37.0,
  'ffun_add:15:2000:85': 55.0,
  'adb:10:7500:35': 7.0,
  'mem_40:15:5000:105': 145.0,
  'mem_7:10:25000:23': 5.0,
  'mem:15:40000:120': 1154.0,
  'adb:10:25000:23': 20.0,
  'mem_40:10:2000:4': 1.0,
  'ffun_add:15:40000:103': 2698.0,
  'mem_40:15:20000:51': 44.0,
  'mem_40:15:25000:1': 6.0,
  'ffun:15:40000:95': 1304.0,
  'ffun_add:10:50000:10': 39.0,
  'adb:15:40000:58': 60.0,
  'mem_40:15:50000:31': 20.0,
  'ffun:15:25000:83': 457.0,
  'adb:10:2000:28': 2.0,
  'ffun_add:15:25000:119': 1690.0,
  'mem_40:15:40000:43': 38.0,
  'mem_40:10:10000:53': 29.0,
  'ffun:15:2000:23': 31.0,
  'ffun_add:15:7500:92': 341.0,
  'mem_40:10:5000:37': 5.0,
  'mem_40:15:40000:23': 10.0,
  'ffun:10:10000:57': 97.0,
  'mem_40:15:10000:65': 28.0,
  'ffun_add:10:20000:31': 33.0,
  'mem_7:15:7500:12': 1.0,
  'ffun:15:15000:91': 508.0,
  'adb:10:30000:101': 424.0,
  'mem:15:20000:106': 577.0,
  'adb:15:25000:30': 17.0,
  'mem:10:15000:92': 289.0,
  'dis:15:30000:56': 16.0,
  'ffun_add:10:2000:43': 14.0,
  'mem_7:15:2000:4': 1.0,
  'mem_40:10:25000:70': 115.0,
  'mem_40:15:10000:57': 25.0,
  'adb:10:2000:84': 7.0,
  'mem_7:10:50000:51': 84.0,
  'ffun_add:15:2000:75': 31.0,
  'ffun:10:7500:100': 522.0,
  'mem_7:10:20000:107': 347.0,
  'mem_7:10:7500:62': 16.0,
  'mem:10:10000:21': 4.0,
  'ffun_add:15:25000:84': 604.0,
  'mem_40:15:15000:45': 14.0,
  'mem_7:15:15000:77': 71.0,
  'mem_7:15:5000:106': 88.0,
  'mem_40:10:10000:81': 110.0,
  'mem_7:10:2000:19': 1.0,
  'mem_40:15:2000:74': 12.0,
  'mem_40:15:25000:106': 722.0,
  'ffun_add:15:7500:54': 48.0,
  'adb:15:30000:67': 14.0,
  'mem_7:10:50000:1': 8.0,
  'hcb:10:100:54': 15.0,
  'ffun:10:10000:60': 97.0,
  'ffun:10:40000:70': 375.0,
  'mem_40:10:20000:23': 7.0,
  'hcb:15:120:47': 18.0,
  'mem_40:15:7500:102': 217.0,
  'mem_7:10:25000:15': 5.0,
  'mem_7:15:20000:44': 12.0,
  'mem_7:15:25000:18': 4.0,
  'mem_40:15:5000:29': 2.0,
  'ffun_add:15:10000:20': 13.0,
  'mem_40:15:20000:39': 12.0,
  'mem_40:15:15000:32': 6.0,
  'mem:10:7500:44': 10.0,
  'mem:10:10000:96': 289.0,
  'mem:10:20000:116': 577.0,
  'ffun:15:40000:45': 100.0,
  'ffun:10:2000:20': 33.0,
  'adb:10:2000:94': 16.0,
  'ffun:10:30000:32': 57.0,
  'mem:10:5000:28': 2.0,
  'adb:10:10000:99': 142.0,
  'mem_7:10:5000:42': 5.0,
  'adb:15:40000:68': 19.0,
  'ffun:10:30000:10': 44.0,
  'adb:15:25000:80': 54.0,
  'ffun_add:10:2000:49': 16.0,
  'adb:10:7500:74': 11.0,
  'ffun:10:25000:60': 183.0,
  'adb:15:7500:116': 107.0,
  'ffun_add:10:25000:8': 23.0,
  'ffun:15:20000:89': 499.0,
  'mem_40:10:15000:65': 49.0,
  'ffun:15:20000:87': 499.0,
  'mem:10:10000:83': 110.0,
  'mem:10:10000:106': 289.0,
  'mem_40:15:40000:69': 155.0,
  'ffun_add:10:7500:49': 43.0,
  'mem:10:5000:24': 2.0,
  'adb:10:7500:14': 6.0,
  'dis:10:30000:43': 13.0,
  'ffun:10:7500:32': 38.0,
  'mem_7:15:7500:30': 2.0,
  'adb:15:30000:21': 18.0,
  'mem_40:15:7500:112': 217.0,
  'ffun_add:15:50000:3': 34.0,
  'ffun_add:15:2000:38': 10.0,
  'mem_40:10:2000:53': 6.0,
  'mem_40:10:15000:72': 94.0,
  'ffun:15:15000:55': 90.0,
  'ffun_add:15:15000:62': 100.0,
  'mem_7:10:5000:1': 1.0,
  'ffun:15:7500:22': 34.0,
  'mem:10:7500:75': 47.0,
  'dis:15:20000:34': 10.0,
  'mem_40:10:10000:38': 8.0,
  'mem_7:10:10000:10': 2.0,
  'mem_7:10:5000:52': 8.0,
  'ffun:10:30000:28': 50.0,
  'ffun_add:10:20000:115': 1416.0,
  'ffun:10:40000:79': 639.0,
  'mem_7:15:25000:25': 4.0,
  'ffun:10:10000:23': 37.0,
  'mem_40:15:15000:81': 155.0,
  'mem_40:15:15000:8': 4.0,
  'ffun:10:25000:32': 53.0,
  'mem_7:15:50000:86': 422.0,
  'mem_7:15:30000:17': 5.0,
  'adb:15:7500:63': 4.0,
  'ffun:15:50000:74': 475.0,
  'adb:15:40000:86': 193.0,
  'ffun_add:15:50000:55': 285.0,
  'ffun_add:10:10000:45': 39.0,
  'adb:10:40000:0': 31.0,
  'ffun:15:40000:88': 968.0,
  'mem_7:10:20000:76': 98.0,
  'hcb:15:120:20': 17.0,
  'mem:10:7500:3': 2.0,
  'mem:10:30000:60': 90.0,
  'adb:10:40000:68': 25.0,
  'mem:10:50000:107': 1444.0,
  'mem_7:15:2000:53': 4.0,
  'ffun:10:20000:8': 40.0,
  'ffun:15:30000:116': 1472.0,
  'adb:10:7500:3': 6.0,
  'adb:10:5000:43': 8.0,
  'ffun_add:15:25000:89': 828.0,
  'ffun:15:20000:113': 992.0,
  'mem_7:15:30000:117': 520.0,
  'ffun_add:15:7500:111': 511.0,
  'mem:10:2000:69': 10.0,
  'mem:10:20000:88': 292.0,
  'mem_40:15:2000:65': 6.0,
  'mem_7:10:20000:118': 347.0,
  'mem_7:15:20000:33': 5.0,
  'mem_40:10:15000:36': 12.0,
  'ffun:10:2000:69': 56.0,
  'ffun_add:15:2000:45': 12.0,
  'mem:10:20000:98': 577.0,
  'ffun:10:2000:72': 62.0,
  'ffun:10:10000:3': 36.0,
  'mem_40:10:40000:15': 11.0,
  'ffun:10:50000:43': 140.0,
  'mem_40:15:2000:45': 2.0,
  'adb:15:5000:106': 71.0,
  'hcb:15:150:59': 26.0,
  'mem:10:10000:17': 4.0,
  'mem_40:15:30000:50': 43.0,
  'ffun:10:15000:105': 994.0,
  'mem:15:30000:100': 865.0,
  'mem:10:5000:31': 4.0,
  'mem_7:15:30000:77': 142.0,
  'mem_7:10:25000:21': 5.0,
  'mem_40:10:10000:19': 4.0,
  'ffun:15:7500:84': 158.0,
  'ffun:10:25000:71': 322.0,
  'ffun:10:2000:68': 56.0,
  'mem_7:15:15000:117': 260.0,
  'adb:15:50000:8': 29.0,
  'mem_7:10:15000:68': 42.0,
  'ffun_add:15:5000:88': 171.0,
  'mem_40:10:10000:45': 13.0,
  'adb:15:25000:74': 29.0,
  'mem:15:7500:52': 17.0,
  'adb:10:20000:108': 282.0,
  'mem:15:25000:64': 67.0,
  'ffun:10:20000:80': 339.0,
  'mem:15:15000:69': 59.0,
  'ffun:10:10000:105': 679.0,
  'dis:10:10000:22': 11.0,
  'adb:10:5000:38': 6.0,
  'mem_40:10:40000:89': 583.0,
  'ffun:15:5000:20': 32.0,
  'mem_7:10:30000:120': 520.0,
  'mem_40:15:25000:90': 353.0,
  'mem_40:10:15000:37': 12.0,
  'adb:15:50000:113': 706.0,
  'ffun:15:2000:117': 126.0,
  'adb:10:40000:118': 564.0,
  'mem_40:15:30000:116': 865.0,
  'adb:15:40000:78': 86.0,
  'adb:15:7500:99': 107.0,
  'ffun_add:15:7500:23': 12.0,
  'adb:10:40000:112': 564.0,
  'ffun_add:10:15000:49': 79.0,
  'dis:15:50000:39': 12.0,
  'mem_40:10:5000:72': 31.0,
  'mem:10:10000:75': 62.0,
  'ffun:15:5000:112': 271.0,
  'mem_7:10:5000:15': 1.0,
  'dis:10:40000:30': 14.0,
  'ffun_add:10:20000:117': 1416.0,
  'ffun_add:10:40000:97': 2824.0,
  'mem_40:10:15000:56': 46.0,
  'mem_7:15:20000:49': 18.0,
  'adb:10:30000:107': 424.0,
  'dis:15:10000:44': 9.0,
  'adb:15:2000:63': 1.0,
  'adb:15:15000:11': 10.0,
  'mem:15:10000:79': 74.0,
  'mem_40:10:5000:15': 2.0,
  'mem_7:15:30000:95': 344.0,
  'ffun:10:10000:31': 40.0,
  'adb:15:2000:58': 4.0,
  'mem:15:10000:74': 54.0,
  'mem_7:15:15000:73': 53.0,
  'mem_40:10:25000:30': 10.0,
  'mem:10:10000:13': 4.0,
  'mem_7:10:7500:28': 2.0,
  'mem_40:15:25000:92': 479.0,
  'ffun_add:10:7500:67': 90.0,
  'mem_40:15:5000:99': 145.0,
  'dis:15:30000:31': 11.0,
  'mem_7:10:15000:2': 2.0,
  'mem_7:15:5000:118': 88.0,
  'ffun:15:5000:108': 271.0,
  'ffun:15:30000:52': 149.0,
  'adb:10:15000:0': 12.0,
  'ffun:10:30000:104': 1940.0,
  'mem:10:5000:51': 14.0,
  'adb:15:50000:74': 56.0,
  'mem_40:15:5000:110': 145.0,
  'mem_7:10:25000:109': 433.0,
  'mem_7:15:30000:54': 40.0,
  'dis:10:50000:34': 15.0,
  'ffun:15:2000:10': 31.0,
  'mem:15:25000:33': 11.0,
  'mem:10:2000:27': 1.0,
  'adb:10:5000:82': 18.0,
  'ffun_add:15:5000:19': 10.0,
  'adb:10:15000:45': 23.0,
  'mem_7:15:15000:90': 127.0,
  'mem_7:15:5000:65': 8.0,
  'mem_7:10:2000:50': 2.0,
  'ffun:15:50000:22': 53.0,
  'mem_40:15:40000:84': 410.0,
  'ffun_add:15:25000:70': 231.0,
  'mem:10:10000:43': 13.0,
  'ffun_add:10:2000:50': 16.0,
  'mem_7:15:5000:9': 1.0,
  'adb:15:10000:38': 10.0,
  'mem_7:10:2000:71': 8.0,
  'mem_40:10:15000:49': 29.0,
  'mem:15:5000:90': 71.0,
  'ffun:10:30000:49': 128.0,
  'adb:15:50000:15': 29.0,
  'mem:10:20000:5': 6.0,
  'adb:10:40000:71': 54.0,
  'mem:15:40000:38': 24.0,
  'mem_40:15:2000:32': 1.0,
  'adb:10:2000:55': 6.0,
  'ffun:15:5000:12': 32.0,
  'mem_40:15:50000:81': 512.0,
  'ffun_add:15:40000:86': 1319.0,
  'adb:10:30000:29': 25.0,
  'dis:10:10000:32': 11.0,
  'adb:10:30000:17': 24.0,
  'ffun:10:5000:33': 36.0,
  'ffun:15:2000:21': 31.0,
  'mem:15:2000:1': 1.0,
  'ffun:10:30000:118': 1940.0,
  'adb:15:10000:15': 6.0,
  'adb:15:40000:37': 36.0,
  'ffun_add:15:10000:45': 31.0,
  'adb:10:2000:56': 5.0,
  'mem_7:15:15000:55': 20.0,
  'ffun:10:50000:106': 3205.0,
  'mem_7:10:20000:27': 5.0,
  'ffun:10:40000:72': 492.0,
  'ffun_add:10:5000:59': 46.0,
  'adb:10:5000:15': 5.0,
  'mem_7:10:5000:9': 1.0,
  'ffun:10:10000:53': 95.0,
  'adb:15:15000:25': 10.0,
  'adb:15:15000:104': 212.0,
  'ffun_add:15:50000:30': 45.0,
  'dis:10:20000:55': 13.0,
  'ffun_add:10:50000:77': 976.0,
  'mem_7:10:25000:46': 29.0,
  'mem_7:15:30000:18': 5.0,
  'mem:10:50000:115': 1444.0,
  'ffun_add:10:20000:64': 161.0,
  'mem_40:10:10000:5': 4.0,
  'ffun_add:15:5000:98': 343.0,
  'ffun_add:10:50000:13': 41.0,
  'ffun_add:10:40000:84': 1080.0,
  'mem_40:10:30000:47': 56.0,
  'ffun:15:2000:25': 31.0,
  'mem:10:20000:9': 6.0,
  'ffun:10:2000:49': 40.0,
  'mem:15:5000:22': 1.0,
  'adb:10:20000:0': 16.0,
  'ffun_add:15:40000:59': 241.0,
  'mem_40:15:20000:90': 282.0,
  'mem_40:15:15000:100': 433.0,
  'mem:10:5000:113': 145.0,
  'mem_7:10:7500:7': 1.0,
  'mem:15:30000:105': 865.0,
  'adb:10:10000:8': 8.0,
  'mem_40:10:20000:83': 220.0,
  'ffun_add:15:20000:100': 1353.0,
  'mem_7:15:15000:57': 23.0,
  'ffun:10:15000:76': 264.0,
  'adb:10:30000:20': 24.0,
  'mem:15:40000:25': 10.0,
  'adb:10:5000:73': 7.0,
  'adb:10:25000:35': 24.0,
  'mem_40:10:10000:74': 62.0,
  'ffun:15:30000:76': 397.0,
  'adb:15:7500:117': 107.0,
  'ffun_add:15:30000:42': 80.0,
  'mem_7:15:10000:120': 174.0,
  'mem:15:40000:66': 155.0,
  'ffun:15:40000:101': 1953.0,
  'mem_7:15:5000:18': 1.0,
  'ffun_add:15:40000:42': 104.0,
  'adb:10:30000:12': 23.0,
  'mem:10:50000:78': 409.0,
  'mem_40:10:40000:79': 328.0,
  'adb:10:25000:107': 353.0,
  'adb:15:10000:112': 142.0,
  'mem_40:10:10000:50': 19.0,
  'mem:15:7500:108': 217.0,
  'mem_40:10:2000:21': 1.0,
  'ffun_add:10:25000:53': 187.0,
  'mem_40:10:2000:14': 1.0,
  'ffun:10:15000:89': 525.0,
  'ffun:10:50000:51': 316.0,
  'ffun:10:30000:82': 767.0,
  'hcb:10:50:29': 3.0,
  'ffun_add:15:30000:65': 192.0,
  'adb:10:5000:87': 25.0,
  'ffun_add:15:5000:45': 19.0,
  'mem_7:10:20000:24': 5.0,
  'ffun_add:15:20000:42': 55.0,
  'mem_7:15:5000:100': 88.0,
  'adb:10:30000:52': 83.0,
  'ffun:10:5000:52': 67.0,
  'ffun:15:5000:110': 271.0,
  'mem_40:15:15000:63': 41.0,
  'mem:15:15000:110': 433.0,
  'mem_40:10:10000:64': 32.0,
  'ffun_add:10:50000:83': 1348.0,
  'hcb:10:150:58': 31.0,
  'ffun_add:10:2000:55': 23.0,
  'hcb:15:50:24': 3.0,
  'mem_40:10:30000:112': 865.0,
  'mem_7:15:30000:84': 185.0,
  'mem_40:15:7500:41': 7.0,
  'mem:10:20000:55': 56.0,
  'mem_40:10:2000:25': 1.0,
  'mem_7:10:5000:2': 1.0,
  'adb:15:50000:65': 23.0,
  'mem_7:15:30000:83': 185.0,
  'adb:10:15000:46': 30.0,
  'mem:15:30000:92': 574.0,
  'mem:15:7500:89': 106.0,
  'ffun_add:15:7500:32': 14.0,
  'mem_7:15:15000:31': 4.0,
  'ffun:15:40000:51': 189.0,
  'adb:15:10000:0': 6.0,
  'ffun_add:15:10000:17': 13.0,
  'mem_7:15:50000:14': 7.0,
  'adb:15:30000:61': 14.0,
  'ffun_add:15:5000:87': 171.0,
  'mem:10:50000:20': 14.0,
  'mem_40:15:20000:101': 577.0,
  'ffun:15:15000:61': 97.0,
  'ffun:10:10000:22': 37.0,
  'mem_40:10:40000:59': 120.0,
  'mem:10:20000:50': 37.0,
  'ffun:15:25000:109': 1232.0,
  'adb:10:15000:77': 37.0,
  'mem_7:10:25000:87': 218.0,
  'mem_40:10:15000:6': 5.0,
  'ffun_add:10:50000:110': 3527.0,
  'ffun_add:15:5000:1': 9.0,
  'ffun_add:15:5000:37': 14.0,
  'ffun:10:10000:94': 469.0,
  'ffun:10:10000:100': 679.0,
  'mem:10:30000:108': 865.0,
  'adb:15:25000:76': 54.0,
  'mem:10:15000:51': 42.0,
  'ffun_add:15:20000:21': 19.0,
  'mem_7:10:10000:104': 174.0,
  'mem:15:50000:43': 47.0,
  'mem_7:15:5000:66': 12.0,
  'ffun:15:20000:106': 992.0,
  'adb:10:20000:22': 16.0,
  'mem_7:15:20000:54': 26.0,
  'hcb:15:100:51': 12.0,
  'ffun:10:10000:101': 679.0,
  'ffun:10:5000:38': 39.0,
  'adb:10:50000:28': 41.0,
  'ffun_add:15:30000:46': 118.0,
  'mem:10:10000:80': 83.0,
  'ffun:10:2000:97': 173.0,
  'mem_7:10:30000:83': 198.0,
  'mem_7:10:40000:54': 67.0,
  'adb:15:50000:45': 58.0,
  'ffun_add:10:20000:89': 720.0,
  'mem:15:15000:25': 4.0,
  'mem_40:10:7500:12': 2.0,
  'ffun_add:10:5000:53': 45.0,
  'hcb:10:100:37': 14.0,
  'ffun:15:7500:73': 97.0,
  'ffun_add:10:50000:71': 739.0,
  'mem_40:10:15000:90': 218.0,
  'ffun_add:15:20000:91': 898.0,
  'mem_7:15:25000:69': 59.0,
  'adb:10:7500:116': 107.0,
  'mem:15:25000:75': 134.0,
  'mem:15:5000:0': 1.0,
  'adb:10:30000:119': 424.0,
  'mem_7:10:40000:104': 692.0,
  'mem_7:15:20000:78': 95.0,
  'mem:10:50000:24': 16.0,
  'adb:15:5000:40': 5.0,
  'ffun:15:15000:46': 70.0,
  'ffun_add:10:7500:105': 538.0,
  'mem_7:15:2000:51': 4.0,
  'mem_7:10:2000:99': 35.0,
  'adb:15:2000:81': 7.0,
  'mem_7:15:7500:110': 131.0,
  'dis:15:50000:46': 13.0,
  'ffun_add:10:7500:10': 12.0,
  'adb:15:2000:108': 29.0,
  'mem_40:10:7500:47': 14.0,
  'ffun_add:15:30000:2': 23.0,
  'mem:10:2000:57': 6.0,
  'adb:10:2000:1': 2.0,
  'ffun_add:15:40000:102': 2698.0,
  'mem_7:10:2000:112': 35.0,
  'ffun:15:20000:103': 992.0,
  'ffun_add:15:15000:105': 1017.0,
  'mem_40:10:25000:87': 365.0,
  'mem_7:10:5000:101': 88.0,
  'mem_7:10:10000:68': 28.0,
  'mem_7:10:7500:58': 14.0,
  'mem:15:15000:114': 433.0,
  'adb:15:7500:57': 12.0,
  'ffun_add:15:2000:59': 19.0,
  'mem_7:10:7500:98': 131.0,
  'adb:15:2000:105': 29.0,
  'mem:10:5000:108': 145.0,
  'mem_7:10:50000:78': 246.0,
  'mem_40:10:10000:4': 4.0,
  'mem_40:10:5000:5': 2.0,
  'mem:15:40000:90': 563.0,
  'mem:10:25000:109': 722.0,
  'mem:15:30000:101': 865.0,
  'mem_40:10:10000:85': 110.0,
  'ffun_add:15:30000:58': 182.0,
  'mem_7:10:7500:86': 66.0,
  'ffun:10:5000:82': 167.0,
  'mem_40:15:10000:76': 79.0,
  'ffun:10:5000:22': 34.0,
  'ffun:15:25000:112': 1232.0,
  'mem_7:10:40000:13': 7.0,
  'ffun:15:50000:109': 2435.0,
  'mem:10:20000:72': 124.0,
  'mem:10:20000:96': 577.0,
  'mem_7:10:25000:93': 289.0,
  'ffun:15:2000:88': 77.0,
  'adb:10:10000:71': 14.0,
  'ffun:15:20000:20': 38.0,
  'dis:10:40000:48': 14.0,
  'mem_7:15:40000:40': 14.0,
  'mem_40:15:5000:48': 8.0,
  'adb:10:30000:72': 41.0,
  'mem:10:7500:97': 217.0,
  'ffun_add:10:30000:80': 588.0,
  'adb:15:5000:61': 2.0,
  'dis:15:20000:20': 10.0,
  'mem_7:15:15000:43': 10.0,
  'adb:15:7500:54': 17.0,
  'ffun:10:2000:39': 35.0,
  'mem_7:10:40000:106': 692.0,
  'adb:10:7500:22': 6.0,
  'mem_40:15:30000:27': 10.0,
  'adb:15:7500:35': 6.0,
  'mem_7:15:40000:16': 6.0,
  'hcb:15:120:45': 18.0,
  'ffun_add:10:15000:59': 120.0,
  'mem:15:25000:83': 257.0,
  'ffun:10:7500:49': 57.0,
  'adb:15:15000:48': 24.0,
  'ffun:15:25000:110': 1232.0,
  'ffun:15:40000:113': 1953.0,
  'mem:15:2000:5': 1.0,
  'mem:10:50000:81': 550.0,
  'ffun_add:15:5000:85': 126.0,
  'ffun:15:30000:15': 42.0,
  'mem:15:30000:17': 7.0,
  'mem_7:15:10000:32': 2.0,
  'mem_7:15:20000:90': 169.0,
  'mem:15:2000:25': 1.0,
  'dis:15:20000:42': 10.0,
  'adb:15:15000:115': 212.0,
  'mem_7:10:30000:117': 520.0,
  'hcb:10:150:19': 30.0,
  'mem:15:20000:21': 5.0,
  'adb:10:10000:102': 142.0,
  'mem:10:30000:80': 246.0,
  'mem:10:15000:12': 5.0,
  'mem:10:50000:62': 162.0,
  'mem:10:40000:26': 16.0,
  'ffun_add:10:30000:43': 101.0,
  'mem:10:25000:13': 7.0,
  'mem_7:10:15000:13': 2.0,
  'mem:15:50000:59': 121.0,
  'mem_40:10:5000:92': 97.0,
  'ffun:15:7500:102': 391.0,
  'ffun_add:10:40000:98': 2824.0,
  'mem:10:2000:73': 13.0,
  'adb:10:15000:23': 12.0,
  'adb:10:25000:14': 19.0,
  'adb:10:30000:105': 424.0,
  'ffun:15:30000:20': 42.0,
  'mem_7:10:40000:24': 8.0,
  'ffun:10:15000:119': 994.0,
  'mem_7:15:30000:51': 40.0,
  'mem_40:15:15000:6': 4.0,
  'ffun_add:10:25000:93': 1184.0,
  'mem_7:10:2000:78': 11.0,
  'mem_7:15:5000:110': 88.0,
  'ffun_add:10:50000:8': 39.0,
  'ffun:15:50000:79': 642.0,
  'ffun_add:10:7500:72': 118.0,
  'ffun:15:2000:77': 55.0,
  'ffun:10:7500:97': 522.0,
  'ffun_add:10:10000:48': 55.0,
  'adb:15:5000:73': 6.0,
  'adb:15:15000:43': 18.0,
  'ffun:15:5000:31': 34.0,
  'mem_40:15:5000:119': 145.0,
  'ffun:15:5000:29': 33.0,
  'mem_7:15:50000:12': 7.0,
  'ffun_add:10:30000:104': 2118.0,
  'ffun:15:20000:17': 38.0,
  'mem_7:10:5000:85': 34.0,
  'mem_40:15:40000:108': 1154.0,
  'adb:15:5000:110': 71.0,
  'adb:15:7500:43': 10.0,
  'mem_40:15:15000:62': 41.0,
  'ffun:10:15000:3': 38.0,
  'mem:10:15000:96': 433.0,
  'mem_40:10:7500:78': 62.0,
  'ffun_add:15:50000:115': 3373.0,
  'ffun_add:10:15000:86': 542.0,
  'ffun:10:15000:16': 39.0,
  'mem_7:15:40000:108': 692.0,
  'mem_40:15:50000:103': 1444.0,
  'adb:10:2000:89': 11.0,
  'mem_7:10:5000:44': 5.0,
  'adb:10:50000:111': 706.0,
  'mem_40:10:10000:92': 193.0,
  'ffun:15:7500:35': 36.0,
  'mem_40:10:20000:61': 65.0,
  'adb:10:5000:103': 71.0,
  'mem:15:20000:40': 12.0,
  'adb:10:20000:106': 282.0,
  'adb:15:20000:112': 282.0,
  'mem_7:10:20000:113': 347.0,
  'ffun_add:15:50000:47': 194.0,
  'ffun:15:10000:39': 41.0,
  'mem:10:25000:10': 7.0,
  'mem_40:10:20000:6': 6.0,
  'mem_40:15:30000:100': 865.0,
  'mem:10:20000:107': 577.0,
  'mem_7:10:25000:8': 5.0,
  'ffun:10:5000:8': 33.0,
  'ffun_add:10:25000:120': 1769.0,
  'adb:10:50000:120': 706.0,
  'hcb:10:120:24': 19.0,
  'mem:15:30000:91': 574.0,
  'mem_7:10:15000:28': 4.0,
  'adb:15:25000:113': 353.0,
  'mem:10:50000:54': 139.0,
  'ffun_add:10:25000:36': 56.0,
  'mem_7:15:2000:115': 35.0,
  'mem:10:20000:93': 386.0,
  'mem:15:50000:82': 512.0,
  'ffun_add:10:5000:82': 144.0,
  'mem:10:25000:55': 70.0,
  'mem:10:40000:112': 1154.0,
  'dis:10:20000:51': 13.0,
  'ffun:10:10000:19': 36.0,
  'mem_40:10:5000:53': 14.0,
  'mem:10:10000:53': 29.0,
  'ffun_add:10:25000:3': 23.0,
  'mem_40:15:20000:41': 19.0,
  'mem_7:15:40000:24': 6.0,
  'mem:15:50000:13': 11.0,
  'ffun_add:15:50000:37': 83.0,
  'ffun_add:10:7500:31': 17.0,
  'ffun:15:20000:35': 45.0,
  'ffun:10:30000:91': 1312.0,
  'mem:15:5000:111': 145.0,
  'ffun_add:15:5000:25': 10.0,
  'adb:10:30000:79': 73.0,
  'adb:15:20000:54': 44.0,
  'adb:15:30000:23': 18.0,
  'ffun_add:10:25000:78': 492.0,
  'mem_7:15:7500:95': 86.0,
  'ffun:10:7500:38': 42.0,
  'mem:10:15000:43': 19.0,
  'mem_7:15:20000:24': 4.0,
  'mem:15:10000:83': 103.0,
  'ffun:15:50000:97': 2435.0,
  'mem_40:10:50000:84': 550.0,
  'mem_40:10:5000:70': 24.0,
  'ffun:10:30000:30': 50.0,
  'ffun_add:10:15000:64': 123.0,
  'mem_40:15:5000:102': 145.0,
  'mem:10:15000:52': 42.0,
  'ffun_add:15:20000:110': 1353.0,
  'mem_7:15:20000:74': 71.0,
  'mem_7:15:40000:67': 94.0,
  'mem_40:10:2000:79': 17.0,
  'ffun_add:15:40000:24': 31.0,
  'ffun:15:25000:37': 58.0,
  'mem_40:15:10000:8': 2.0,
  'mem_40:15:30000:85': 307.0,
  'mem:15:5000:101': 145.0,
  'hcb:10:50:21': 3.0,
  'ffun:15:50000:120': 2435.0,
  'mem_40:15:7500:96': 217.0,
  'mem_7:15:15000:42': 10.0,
  'ffun_add:10:25000:100': 1769.0,
  'ffun_add:15:40000:114': 2698.0,
  'ffun:10:20000:86': 684.0,
  'mem_7:15:25000:65': 41.0,
  'mem_7:10:20000:9': 4.0,
  'adb:15:5000:19': 4.0,
  'mem:10:10000:85': 110.0,
  'mem:15:15000:102': 433.0,
  'mem_7:15:7500:74': 26.0,
  'ffun_add:10:40000:115': 2824.0,
  'mem:10:50000:71': 310.0,
  'adb:15:25000:77': 54.0,
  'mem_7:15:5000:38': 2.0,
  'adb:15:2000:75': 2.0,
  'adb:10:15000:54': 42.0,
  'ffun_add:10:20000:111': 1416.0,
  'mem:15:10000:67': 40.0,
  'mem:15:25000:81': 257.0,
  'ffun:10:30000:76': 488.0,
  'ffun_add:10:7500:92': 362.0,
  'ffun:15:25000:48': 97.0,
  'mem_7:15:40000:62': 65.0,
  'adb:15:10000:26': 7.0,
  'ffun:10:10000:37': 45.0,
  'ffun:15:40000:3': 46.0,
  'ffun_add:10:30000:115': 2118.0,
  'ffun:10:20000:33': 49.0,
  'mem_40:15:15000:28': 5.0,
  'dis:15:30000:26': 11.0,
  'mem:10:25000:27': 10.0,
  'adb:10:50000:57': 95.0,
  'mem_7:10:50000:106': 866.0,
  'adb:10:25000:108': 353.0,
  'mem_40:10:30000:33': 16.0,
  'adb:15:25000:100': 353.0,
  'ffun:10:40000:11': 48.0,
  'mem_40:15:10000:23': 2.0,
  'ffun_add:15:50000:49': 194.0,
  'adb:10:10000:16': 8.0,
  'mem_40:15:15000:102': 433.0,
  'ffun:15:50000:117': 2435.0,
  'ffun:10:50000:48': 191.0,
  'mem_7:15:5000:112': 88.0,
  'adb:15:15000:76': 32.0,
  'hcb:15:80:49': 8.0,
  'mem_7:15:50000:34': 12.0,
  'mem_40:15:10000:10': 2.0,
  'mem_7:15:10000:77': 48.0,
  'mem_7:10:40000:79': 197.0,
  'mem:15:40000:69': 155.0,
  'adb:15:25000:31': 19.0,
  'mem_40:15:30000:108': 865.0,
  'mem_7:10:10000:35': 4.0,
  'mem_40:10:10000:60': 30.0,
  'mem_40:10:7500:20': 2.0,
  'mem:10:2000:75': 13.0,
  'ffun:10:2000:15': 33.0,
  'mem_40:15:5000:31': 2.0,
  'mem_7:10:40000:85': 264.0,
  'mem_7:15:50000:120': 866.0,
  'ffun_add:10:15000:27': 21.0,
  'ffun_add:10:7500:37': 22.0,
  'mem_40:10:30000:11': 8.0,
  'ffun:15:10000:62': 75.0,
  'dis:10:40000:59': 21.0,
  'mem:10:5000:82': 55.0,
  'mem_40:10:15000:83': 166.0,
  'ffun_add:15:2000:54': 17.0,
  'ffun_add:10:50000:108': 3527.0,
  'ffun:10:15000:24': 40.0,
  'ffun_add:10:40000:12': 34.0,
  'ffun_add:15:40000:84': 962.0,
  'mem_7:15:10000:68': 24.0,
  'ffun:10:50000:87': 1641.0,
  'mem:15:25000:27': 7.0,
  'adb:15:5000:66': 2.0,
  'mem:10:40000:11': 11.0,
  'mem_7:10:10000:70': 28.0,
  'mem:15:20000:25': 5.0,
  'adb:10:2000:13': 2.0,
  'dis:15:30000:49': 11.0,
  'mem_7:15:40000:15': 6.0,
  'dis:15:10000:57': 14.0,
  'mem:15:2000:85': 22.0,
  'mem:15:50000:104': 1444.0,
  'dis:10:10000:55': 12.0,
  'mem_40:15:30000:105': 865.0,
  'mem_40:10:25000:1': 7.0,
  'adb:15:40000:14': 23.0,
  'mem_7:15:20000:27': 4.0,
  'hcb:15:80:40': 8.0,
  'mem_7:10:25000:108': 433.0,
  'adb:15:7500:93': 56.0,
  'ffun_add:10:40000:5': 34.0,
  'mem_7:15:7500:113': 131.0,
  'mem:15:50000:92': 956.0,
  'ffun_add:10:40000:41': 132.0,
  'mem_40:15:7500:64': 20.0,
  'ffun:15:50000:6': 49.0,
  'mem_7:15:5000:45': 4.0,
  'ffun:15:30000:16': 42.0,
  'mem:10:7500:23': 2.0,
  'ffun_add:10:5000:78': 106.0,
  'mem:10:10000:59': 30.0,
  'adb:10:7500:75': 11.0,
  'adb:15:2000:14': 1.0,
  'mem:10:50000:65': 162.0,
  'ffun:15:25000:26': 44.0,
  'adb:15:15000:84': 48.0,
  'mem_7:10:20000:5': 4.0,
  'ffun_add:10:2000:39': 11.0,
  'mem_40:10:10000:120': 289.0,
  'mem:10:20000:48': 37.0,
  'mem_40:10:50000:7': 13.0,
  'ffun:10:5000:47': 49.0,
  'ffun:10:25000:76': 415.0,
  'adb:10:15000:116': 212.0,
  'ffun_add:10:10000:112': 713.0,
  'ffun_add:10:10000:99': 713.0,
  'mem_40:10:2000:94': 40.0,
  'mem:10:40000:33': 20.0,
  'ffun:10:50000:61': 334.0,
  'adb:15:25000:86': 121.0,
  'mem_7:15:50000:77': 236.0,
  'mem_40:10:30000:7': 8.0,
  'adb:10:25000:66': 16.0,
  'mem_40:10:25000:58': 76.0,
  'ffun:15:2000:35': 32.0,
  'mem:15:50000:116': 1444.0,
  'ffun_add:10:25000:23': 27.0,
  'ffun_add:10:30000:67': 334.0,
  'ffun:15:15000:90': 382.0,
  'mem:15:10000:18': 2.0,
  'mem_7:15:15000:47': 13.0,
  'mem_7:15:30000:20': 5.0,
  'mem:10:40000:20': 12.0,
  'mem_7:15:15000:11': 2.0,
  'mem_7:10:25000:43': 19.0,
  'mem_40:15:10000:119': 289.0,
  'ffun:15:15000:92': 508.0,
  'ffun:10:10000:68': 123.0,
  'ffun_add:15:40000:53': 229.0,
  'adb:10:30000:51': 83.0,
  'mem:10:5000:54': 14.0,
  'ffun:10:50000:50': 191.0,
  'mem_40:15:10000:108': 289.0,
  'ffun_add:15:5000:24': 10.0,
  'mem:10:5000:7': 2.0,
  'mem_7:10:25000:48': 29.0,
  'mem_40:10:30000:43': 37.0,
  'ffun_add:15:2000:33': 9.0,
  'mem_40:10:30000:6': 8.0,
  'mem_40:10:30000:2': 8.0,
  'ffun_add:10:25000:13': 24.0,
  'ffun:15:40000:12': 46.0,
  'ffun_add:10:10000:81': 277.0,
  'ffun:15:15000:73': 164.0,
  'mem_7:10:5000:56': 10.0,
  'adb:10:2000:33': 2.0,
  'mem:15:50000:76': 367.0,
  'adb:10:10000:118': 142.0,
  'mem_7:15:5000:40': 2.0,
  'mem:10:15000:59': 46.0,
  'mem_7:10:30000:103': 520.0,
  'ffun_add:15:7500:22': 12.0,
  'adb:10:2000:45': 4.0,
  'mem:10:15000:94': 289.0,
  'ffun_add:15:20000:106': 1353.0,
  'adb:15:20000:42': 23.0,
  'ffun:10:40000:113': 2574.0,
  'ffun:15:40000:92': 1304.0,
  'mem_7:15:20000:46': 18.0,
  'mem_7:15:15000:13': 2.0,
  'ffun_add:10:5000:79': 106.0,
  'mem_40:10:30000:54': 84.0,
  'hcb:15:100:40': 12.0,
  'mem_40:10:5000:67': 24.0,
  'ffun_add:10:40000:85': 1080.0,
  'ffun_add:10:7500:90': 276.0,
  'adb:15:7500:70': 4.0,
  'mem_40:10:40000:5': 11.0,
  'mem_7:10:25000:85': 166.0,
  'hcb:15:120:50': 18.0,
  'mem_7:10:50000:22': 10.0,
  'mem_7:15:30000:12': 5.0,
  'ffun_add:15:2000:97': 142.0,
  'ffun:10:30000:19': 45.0,
  'ffun_add:15:20000:85': 485.0,
  'ffun:15:30000:48': 110.0,
  'mem_40:15:10000:9': 2.0,
  'ffun_add:10:5000:101': 362.0,
  'ffun:15:7500:10': 33.0,
  'adb:15:20000:115': 282.0,
  'ffun_add:15:50000:104': 3373.0,
  'ffun_add:15:50000:100': 3373.0,
  'adb:15:5000:46': 8.0,
  'adb:10:50000:82': 172.0,
  'mem_40:10:10000:83': 110.0,
  'mem:15:15000:40': 10.0,
  'mem:10:15000:13': 5.0,
  'ffun:15:25000:16': 40.0,
  'mem_7:10:7500:81': 50.0,
  'mem_40:10:5000:108': 145.0,
  'adb:10:15000:113': 212.0,
  'mem_7:10:5000:90': 44.0,
  'ffun:15:7500:60': 62.0,
  'ffun:15:40000:53': 189.0,
  'mem:10:15000:83': 166.0,
  'mem:15:50000:52': 110.0,
  'ffun_add:15:5000:89': 171.0,
  'mem_7:15:50000:31': 12.0,
  'adb:10:30000:47': 59.0,
  'mem_7:15:5000:49': 5.0,
  'adb:10:30000:73': 41.0,
  'mem_40:15:10000:81': 103.0,
  'dis:10:50000:45': 15.0,
  'ffun:15:15000:1': 36.0,
  'adb:10:2000:68': 2.0,
  'ffun:10:5000:113': 363.0,
  'mem:15:7500:109': 217.0,
  'mem_7:15:15000:111': 260.0,
  'ffun:10:40000:115': 2574.0,
  'adb:10:50000:24': 40.0,
  'mem:15:50000:83': 512.0,
  'ffun_add:10:30000:87': 1074.0,
  'ffun:15:10000:49': 57.0,
  'ffun:15:40000:65': 208.0,
  'mem_7:15:5000:90': 43.0,
  'adb:15:20000:12': 12.0,
  'ffun_add:15:2000:47': 14.0,
  'mem:15:25000:19': 6.0,
  'mem:15:2000:105': 59.0,
  'ffun_add:15:10000:104': 679.0,
  'ffun:15:5000:27': 33.0,
  'mem:15:25000:82': 257.0,
  'mem_7:15:5000:72': 18.0,
  'ffun:10:50000:102': 3205.0,
  'mem_7:15:20000:20': 4.0,
  'mem_40:10:20000:20': 6.0,
  'mem_40:10:20000:105': 577.0,
  'adb:10:25000:8': 19.0,
  'dis:15:30000:36': 11.0,
  'ffun:10:2000:64': 52.0,
  'mem_7:10:20000:120': 347.0,
  'mem:15:10000:104': 289.0,
  'mem:10:10000:10': 4.0,
  'mem:10:2000:71': 13.0,
  'ffun_add:10:50000:25': 46.0,
  'ffun_add:10:2000:116': 151.0,
  'mem:10:30000:64': 97.0,
  'mem_7:10:2000:16': 1.0,
  'adb:10:5000:45': 8.0,
  'mem_7:15:20000:31': 5.0,
  'adb:15:40000:88': 193.0,
  'mem_40:10:5000:68': 24.0,
  'adb:15:40000:115': 564.0,
  'mem:10:15000:115': 433.0,
  'ffun:10:20000:55': 150.0,
  'ffun_add:10:40000:73': 593.0,
  'ffun_add:15:7500:36': 19.0,
  'mem:10:10000:42': 13.0,
  'mem_7:10:2000:10': 1.0,
  'ffun:15:15000:13': 36.0,
  'mem_7:10:5000:61': 11.0,
  'ffun_add:15:2000:27': 9.0,
  'ffun:10:25000:89': 843.0,
  'ffun_add:10:20000:108': 1416.0,
  'mem_40:15:20000:40': 12.0,
  'mem:15:25000:46': 37.0,
  'mem:10:10000:98': 289.0,
  'mem_7:10:20000:26': 5.0,
  'ffun_add:10:10000:34': 20.0,
  'ffun_add:10:50000:43': 164.0,
  'mem_40:15:15000:2': 4.0,
  'mem_7:10:30000:29': 7.0,
  'mem_7:15:30000:16': 5.0,
  'mem_7:15:10000:55': 13.0,
  'mem:15:50000:77': 367.0,
  'mem:15:50000:118': 1444.0,
  'ffun:10:7500:92': 364.0,
  'ffun_add:10:40000:88': 1430.0,
  'ffun:15:30000:88': 733.0,
  'mem_7:15:5000:75': 18.0,
  'ffun_add:10:40000:57': 304.0,
  'mem_7:10:40000:83': 264.0,
  'ffun:10:5000:9': 33.0,
  'mem:10:40000:85': 439.0,
  'ffun:15:10000:55': 70.0,
  'ffun_add:15:5000:117': 343.0,
  'hcb:15:80:28': 7.0,
  'mem:10:25000:96': 722.0,
  'mem_40:10:30000:108': 865.0,
  'mem_7:10:30000:85': 198.0,
  'mem_40:15:50000:38': 30.0,
  'adb:15:2000:57': 4.0,
  'mem_7:15:25000:53': 34.0,
  'ffun:15:7500:26': 34.0,
  'mem:15:25000:117': 722.0,
  'mem_7:10:7500:77': 37.0,
  'ffun_add:15:30000:24': 26.0,
  'adb:10:7500:0': 6.0,
  'mem:10:25000:68': 115.0,
  'mem_40:10:7500:77': 62.0,
  'mem_40:15:30000:81': 307.0,
  'adb:15:15000:66': 7.0,
  'mem_7:15:7500:73': 26.0,
  'mem:10:25000:70': 115.0,
  'ffun:15:40000:97': 1953.0,
  'ffun_add:10:20000:37': 46.0,
  'ffun:10:15000:99': 994.0,
  'adb:15:40000:23': 24.0,
  'ffun_add:10:20000:98': 1416.0,
  'mem_40:15:40000:87': 563.0,
  'mem_7:15:7500:88': 64.0,
  'mem:15:20000:61': 54.0,
  'mem:15:40000:9': 8.0,
  'ffun:15:15000:80': 214.0,
  'mem_7:10:25000:44': 19.0,
  'adb:10:40000:26': 32.0,
  'ffun:10:20000:72': 266.0,
  'mem_7:15:10000:110': 174.0,
  'mem:10:30000:15': 8.0,
  'ffun:15:40000:102': 1953.0,
  'mem_7:10:40000:86': 350.0,
  'ffun_add:10:50000:6': 39.0,
  'mem:15:7500:104': 217.0,
  'mem_40:15:30000:33': 12.0,
  'mem_40:15:20000:119': 577.0,
  'ffun_add:15:2000:74': 31.0,
  'ffun_add:10:50000:46': 243.0,
  'ffun_add:15:20000:67': 187.0,
  'mem_7:10:30000:48': 34.0,
  'mem_7:15:20000:89': 169.0,
  'adb:10:2000:31': 2.0,
  'dis:15:10000:39': 9.0,
  'mem_7:10:25000:12': 5.0,
  'adb:15:15000:68': 7.0,
  'ffun_add:15:10000:2': 12.0,
  'ffun:10:50000:92': 2157.0,
  'ffun_add:15:7500:7': 10.0,
  'mem_7:15:5000:76': 24.0,
  'mem_40:15:10000:2': 2.0,
  'hcb:15:120:55': 18.0,
  'ffun:15:30000:64': 163.0,
  'mem_40:10:7500:82': 83.0,
  'mem_40:15:5000:108': 145.0,
  'ffun_add:10:50000:95': 2359.0,
  'adb:10:20000:86': 101.0,
  'ffun:15:50000:31': 67.0,
  'adb:15:30000:46': 47.0,
  'ffun_add:15:20000:30': 21.0,
  'adb:10:5000:107': 71.0,
  'mem_7:10:10000:40': 5.0,
  'ffun_add:10:30000:59': 229.0,
  'ffun_add:15:50000:6': 34.0,
  'mem_40:10:15000:51': 42.0,
  'adb:15:25000:22': 16.0,
  'ffun:10:2000:100': 173.0,
  'ffun:15:10000:6': 34.0,
  'mem_7:15:7500:114': 131.0,
  'mem_7:15:50000:16': 7.0,
  'ffun:10:10000:40': 45.0,
  'mem_7:15:15000:10': 2.0,
  'adb:10:50000:85': 172.0,
  'ffun_add:15:15000:53': 90.0,
  'ffun_add:15:2000:22': 7.0,
  'mem_40:10:15000:84': 166.0,
  'mem:10:40000:96': 1154.0,
  'ffun:15:50000:42': 117.0,
  'mem_40:10:50000:16': 14.0,
  'mem_40:10:2000:74': 13.0,
  'mem_7:10:40000:109': 692.0,
  'mem_40:15:10000:69': 40.0,
  'ffun_add:15:10000:112': 679.0,
  'mem_7:15:15000:104': 260.0,
  'ffun_add:15:25000:106': 1690.0,
  'ffun_add:10:5000:46': 31.0,
  'mem_7:10:25000:77': 124.0,
  'mem_7:15:40000:69': 94.0,
  'ffun_add:10:5000:106': 362.0,
  'dis:10:50000:46': 16.0,
  'ffun_add:15:2000:7': 7.0,
  'mem_7:10:10000:93': 116.0,
  'ffun_add:10:20000:24': 23.0,
  'mem_40:10:20000:101': 577.0,
  'ffun_add:10:15000:33': 27.0,
  'mem_40:15:2000:120': 59.0,
  'dis:15:30000:30': 11.0,
  'ffun:15:25000:29': 44.0,
  'ffun:15:5000:100': 271.0,
  'mem_40:15:10000:112': 289.0,
  'ffun_add:10:30000:34': 46.0,
  'adb:15:15000:3': 10.0,
  'mem_40:10:40000:18': 12.0,
  'adb:10:25000:29': 20.0,
  'adb:10:30000:0': 23.0,
  'ffun_add:10:15000:16': 17.0,
  'ffun_add:15:50000:89': 1648.0,
  'mem_40:10:20000:116': 577.0,
  'mem_40:15:20000:104': 577.0,
  'ffun_add:15:50000:62': 317.0,
  'mem:15:10000:91': 192.0,
  'ffun_add:10:2000:76': 47.0,
  'ffun_add:10:7500:97': 538.0,
  'mem_40:15:7500:106': 217.0,
  'hcb:10:100:40': 14.0,
  'mem_7:15:10000:103': 174.0,
  'mem_40:15:40000:16': 10.0,
  'mem_7:10:40000:67': 110.0,
  'ffun:15:10000:19': 34.0,
  'dis:10:50000:54': 17.0,
  'mem_7:15:5000:57': 8.0,
  'adb:10:7500:95': 56.0,
  'mem:15:50000:119': 1444.0,
  'ffun:10:5000:90': 206.0,
  'mem_7:15:30000:25': 5.0,
  'mem_40:15:20000:60': 49.0,
  'mem_40:10:30000:36': 24.0,
  'ffun_add:10:2000:68': 30.0,
  'mem_40:15:7500:118': 217.0,
  'ffun_add:15:40000:85': 962.0,
  'mem:10:20000:109': 577.0,
  'adb:15:2000:15': 1.0,
  'ffun_add:10:5000:80': 106.0,
  'mem:15:25000:2': 6.0,
  'hcb:15:80:54': 8.0,
  'ffun_add:15:30000:7': 23.0,
  'mem_7:15:50000:93': 574.0,
  'mem_7:15:10000:45': 6.0,
  'mem_40:10:2000:59': 6.0,
  'mem_7:15:10000:86': 85.0,
  'mem:10:7500:24': 2.0,
  'ffun:15:10000:45': 48.0,
  'ffun_add:10:5000:50': 31.0,
  'mem_40:15:10000:53': 23.0,
  'adb:10:20000:74': 28.0,
  'adb:10:15000:104': 212.0,
  'adb:10:20000:77': 49.0,
  'mem_7:10:50000:57': 90.0,
  'mem_7:15:5000:115': 88.0,
  'ffun:15:20000:23': 39.0,
  'ffun_add:10:40000:86': 1430.0,
  'ffun_add:10:40000:17': 35.0,
  'mem:15:5000:36': 4.0,
  'ffun:15:2000:104': 126.0,
  'ffun_add:15:2000:44': 12.0,
  'mem:15:10000:50': 16.0,
  'ffun:15:10000:117': 511.0,
  'ffun_add:15:15000:115': 1017.0,
  'dis:15:50000:53': 14.0,
  'mem_7:15:30000:11': 5.0,
  'mem:10:10000:117': 289.0,
  'adb:10:5000:29': 5.0,
  'adb:10:7500:21': 6.0,
  'ffun:15:2000:71': 48.0,
  'adb:15:30000:86': 145.0,
  'mem_40:10:40000:50': 74.0,
  'mem:15:15000:51': 34.0,
  'mem:10:2000:5': 1.0,
  'mem_7:15:15000:3': 2.0,
  'mem_40:10:5000:58': 16.0,
  'adb:10:15000:72': 20.0,
  'mem_7:10:2000:34': 1.0,
  'mem_40:10:15000:45': 19.0,
  'ffun_add:15:30000:41': 80.0,
  'mem:15:50000:30': 14.0,
  'ffun:15:2000:41': 34.0,
  'ffun_add:10:2000:84': 63.0,
  'mem_40:10:50000:116': 1444.0,
  'ffun:15:7500:48': 50.0,
  'ffun:15:10000:94': 349.0,
  'mem:10:30000:76': 246.0,
  'ffun:15:50000:47': 164.0,
  'hcb:10:80:19': 9.0,
  'mem:15:10000:21': 2.0,
  'mem:10:25000:1': 7.0,
  'ffun_add:10:40000:82': 1080.0,
  'mem:15:5000:53': 12.0,
  'mem_7:10:25000:24': 5.0,
  'adb:10:7500:98': 107.0,
  'mem_40:10:15000:105': 433.0,
  'mem_40:15:20000:78': 157.0,
  'mem_7:15:2000:19': 1.0,
  'mem_40:15:20000:117': 577.0,
  'mem:10:50000:113': 1444.0,
  'dis:10:40000:58': 21.0,
  'mem_40:15:20000:105': 577.0,
  'mem:15:30000:93': 574.0,
  'mem_40:10:30000:39': 24.0,
  'adb:15:2000:118': 29.0,
  'mem_7:10:40000:14': 7.0,
  'mem_7:15:15000:84': 92.0,
  'mem_40:15:2000:85': 22.0,
  'ffun_add:10:50000:115': 3527.0,
  'ffun_add:10:2000:22': 9.0,
  'adb:15:20000:37': 18.0,
  'mem_7:15:25000:72': 88.0,
  'mem_7:15:5000:14': 1.0,
  'adb:15:15000:86': 73.0,
  'mem:10:30000:9': 8.0,
  'adb:10:20000:104': 282.0,
  'ffun_add:15:20000:90': 663.0,
  'mem:15:7500:69': 30.0,
  'mem_7:15:7500:13': 1.0,
  'mem:15:50000:35': 20.0,
  'mem_40:15:7500:4': 2.0,
  'mem_7:15:15000:18': 2.0,
  'mem:10:25000:95': 482.0,
  'mem_40:10:25000:37': 19.0,
  'mem_7:15:50000:98': 866.0,
  'hcb:15:150:43': 28.0,
  'ffun:10:50000:113': 3205.0,
  'mem_7:15:25000:113': 433.0,
  'mem:15:2000:46': 4.0,
  'mem:15:20000:71': 107.0,
  'mem:10:7500:63': 25.0,
  'adb:10:2000:5': 2.0,
  'mem_7:10:7500:38': 4.0,
  'adb:15:7500:27': 5.0,
  'ffun_add:15:2000:98': 142.0,
  'mem_7:15:25000:110': 433.0,
  'mem_40:15:30000:82': 307.0,
  'mem_7:15:20000:0': 2.0,
  'ffun_add:10:25000:109': 1769.0,
  'adb:15:25000:36': 23.0,
  'mem:10:50000:6': 13.0,
  'ffun_add:15:30000:104': 2025.0,
  'ffun:10:15000:110': 994.0,
  'ffun:15:30000:115': 1472.0,
  'mem:15:20000:33': 8.0,
  'ffun_add:15:10000:59': 65.0,
  'mem_7:10:15000:21': 4.0,
  'ffun:10:30000:102': 1940.0,
  'mem:10:5000:87': 73.0,
  'mem_7:10:10000:50': 12.0,
  'mem:10:50000:68': 230.0,
  'mem_40:10:20000:54': 56.0,
  'mem_40:15:50000:67': 193.0,
  'mem_7:10:10000:75': 37.0,
  'mem:15:10000:95': 192.0,
  'mem_7:10:15000:62': 30.0,
  'ffun_add:15:25000:44': 68.0,
  'mem:10:20000:61': 65.0,
  'ffun:10:50000:72': 605.0,
  'ffun_add:10:5000:41': 23.0,
  'ffun_add:10:2000:41': 14.0,
  'mem:15:25000:45': 24.0,
  'adb:10:7500:8': 6.0,
  'mem:10:20000:43': 25.0,
  'mem_7:15:15000:102': 260.0,
  'hcb:10:100:18': 14.0,
  'ffun_add:15:5000:97': 343.0,
  'mem_40:10:2000:61': 7.0,
  'ffun:15:15000:100': 752.0,
  'ffun:10:15000:69': 165.0,
  'dis:15:20000:33': 10.0,
  'mem_40:15:25000:39': 16.0,
  'ffun_add:10:2000:71': 38.0,
  'mem_7:15:50000:78': 236.0,
  'mem_7:15:5000:69': 12.0,
  'adb:15:25000:69': 12.0,
  'ffun:10:5000:80': 114.0,
  'ffun_add:10:30000:22': 31.0,
  'mem:10:2000:119': 59.0,
  'mem_40:10:15000:7': 5.0,
  'mem_7:10:7500:52': 13.0,
  'ffun_add:15:30000:108': 2025.0,
  'ffun:10:15000:22': 40.0,
  'ffun_add:15:15000:6': 14.0,
  'mem_40:15:40000:8': 8.0,
  'ffun_add:15:30000:16': 24.0,
  'mem:15:15000:59': 37.0,
  'mem_7:15:30000:78': 142.0,
  'mem_7:10:40000:91': 463.0,
  'mem_7:15:40000:44': 23.0,
  'mem:10:25000:114': 722.0,
  'mem_7:10:5000:38': 2.0,
  'mem:15:5000:1': 1.0,
  'adb:10:50000:90': 250.0,
  'mem:15:15000:8': 4.0,
  'ffun:10:40000:102': 2574.0,
  'mem_40:10:10000:106': 289.0,
  'adb:10:20000:101': 282.0,
  'mem_40:15:50000:66': 193.0,
  'hcb:10:50:24': 3.0,
  'ffun:10:5000:100': 363.0,
  'mem_7:10:2000:117': 35.0,
  'mem:15:10000:107': 289.0,
  'mem_40:15:7500:119': 217.0,
  'adb:15:15000:9': 10.0,
  'adb:10:40000:19': 31.0,
  'adb:15:30000:66': 14.0,
  'mem:15:5000:28': 2.0,
  'mem:10:30000:106': 865.0,
  'ffun:15:40000:13': 46.0,
  'mem:15:50000:28': 14.0,
  'adb:10:10000:116': 142.0,
  'mem_40:15:25000:99': 722.0,
  'dis:10:40000:52': 16.0,
  'adb:10:7500:115': 107.0,
  'adb:15:15000:60': 23.0,
  'ffun:10:7500:70': 102.0,
  'mem_40:10:7500:87': 109.0,
  'mem_7:10:40000:29': 10.0,
  'mem:10:15000:103': 433.0,
  'mem:15:7500:117': 217.0,
  'mem_40:10:20000:63': 65.0,
  'hcb:15:150:37': 27.0,
  'mem_7:10:10000:36': 5.0,
  'adb:10:20000:52': 55.0,
  'mem:10:10000:114': 289.0,
  'mem:10:40000:119': 1154.0,
  'ffun_add:10:10000:86': 365.0,
  'mem:10:40000:57': 120.0,
  'ffun:15:5000:10': 32.0,
  'mem_40:15:10000:90': 142.0,
  'ffun:10:50000:27': 63.0,
  'mem_7:15:25000:75': 88.0,
  'adb:15:25000:26': 17.0,
  'hcb:15:150:21': 26.0,
  'mem_40:10:5000:22': 2.0,
  'mem_40:15:2000:118': 59.0,
  'ffun:10:2000:94': 131.0,
  'mem:10:30000:86': 437.0,
  'ffun:15:5000:87': 148.0,
  'adb:15:15000:1': 10.0,
  'hcb:15:80:23': 7.0,
  'ffun_add:15:10000:32': 17.0,
  'mem_7:15:7500:15': 1.0,
  'mem_7:10:50000:41': 37.0,
  'mem_40:10:7500:58': 23.0,
  'mem:10:25000:80': 205.0,
  'adb:15:7500:40': 7.0,
  'ffun_add:10:30000:6': 27.0,
  'mem:15:30000:11': 7.0,
  'mem:15:40000:119': 1154.0,
  'ffun_add:15:10000:78': 178.0,
  'ffun_add:10:20000:92': 950.0,
  'mem_7:15:15000:58': 23.0,
  'mem_40:10:10000:94': 193.0,
  'ffun:10:10000:47': 65.0,
  'mem:15:5000:80': 37.0,
  'mem:15:30000:19': 7.0,
  'mem_40:15:20000:79': 157.0,
  'dis:15:50000:36': 12.0,
  'adb:10:30000:56': 56.0,
  'adb:15:10000:89': 49.0,
  'ffun_add:15:10000:42': 31.0,
  'mem_40:15:30000:65': 80.0,
  'mem_40:15:30000:42': 29.0,
  'ffun_add:15:5000:48': 26.0,
  'dis:15:20000:53': 11.0,
  'mem:10:30000:39': 24.0,
  'mem_40:15:25000:98': 722.0,
  'adb:15:2000:20': 1.0,
  'mem_40:10:40000:11': 11.0,
  'mem_7:10:2000:70': 6.0,
  'mem:10:2000:47': 5.0,
  'mem_40:10:5000:49': 10.0,
  'ffun_add:10:10000:12': 13.0,
  'ffun:15:30000:25': 44.0,
  'ffun:15:25000:115': 1232.0,
  'mem_7:15:40000:110': 692.0,
  'ffun_add:15:50000:101': 3373.0,
  'mem_7:15:20000:120': 347.0,
  'mem:10:7500:22': 2.0,
  'mem:10:2000:18': 1.0,
  'ffun:10:2000:92': 131.0,
  'mem_7:15:25000:86': 211.0,
  'mem:15:5000:14': 1.0,
  'mem_7:15:15000:66': 35.0,
  'mem_40:15:5000:107': 145.0,
  'ffun_add:15:50000:74': 629.0,
  'adb:15:30000:91': 223.0,
  'adb:15:5000:53': 12.0,
  'ffun_add:10:40000:23': 38.0,
  'mem:10:7500:110': 217.0,
  'ffun_add:10:7500:101': 538.0,
  'ffun:10:5000:20': 33.0,
  'hcb:10:120:18': 19.0,
  'ffun:15:2000:116': 126.0,
  'adb:15:15000:107': 212.0,
  'ffun:15:50000:5': 49.0,
  'ffun_add:10:5000:38': 17.0,
  'mem_7:15:25000:20': 4.0,
  'mem_40:15:15000:114': 433.0,
  'mem:10:15000:44': 19.0,
  'ffun:10:5000:94': 257.0,
  'ffun_add:15:15000:76': 264.0,
  'ffun_add:15:2000:112': 142.0,
  'mem_40:10:7500:85': 83.0,
  'mem_7:15:25000:67': 59.0,
  'ffun:15:20000:12': 38.0,
  'ffun:15:40000:81': 713.0,
  'ffun_add:10:15000:74': 228.0,
  'ffun:10:20000:68': 208.0,
  'mem_40:15:2000:8': 1.0,
  'ffun:15:30000:89': 733.0,
  'dis:10:40000:47': 14.0,
  'mem:15:25000:31': 11.0,
  'dis:10:10000:46': 11.0,
  'mem:10:7500:83': 83.0,
  'mem:15:5000:49': 8.0,
  'ffun_add:15:25000:111': 1690.0,
  'mem:15:7500:93': 144.0,
  'mem:10:5000:78': 42.0,
  'mem_40:15:20000:3': 5.0,
  'ffun_add:15:20000:68': 187.0,
  'mem_40:15:50000:62': 133.0,
  'ffun_add:15:5000:80': 93.0,
  'mem_7:15:40000:35': 10.0,
  'mem_40:15:30000:107': 865.0,
  'adb:15:20000:106': 282.0,
  'mem_40:15:15000:92': 287.0,
  'adb:15:25000:61': 12.0,
  'mem_7:15:40000:92': 460.0,
  'ffun_add:10:5000:0': 10.0,
  'mem_40:15:15000:20': 4.0,
  'ffun:10:5000:78': 114.0,
  'mem:15:5000:93': 96.0,
  'mem:15:5000:10': 1.0,
  'mem_40:15:25000:3': 6.0,
  'mem:15:40000:42': 38.0,
  'mem_40:10:10000:66': 47.0,
  'hcb:15:150:47': 28.0,
  'ffun_add:10:30000:108': 2118.0,
  'ffun:10:2000:113': 173.0,
  'mem:15:30000:53': 66.0,
  'ffun_add:10:10000:29': 17.0,
  'mem_40:10:40000:51': 112.0,
  'ffun_add:15:7500:66': 74.0,
  'adb:15:50000:120': 706.0,
  'ffun_add:15:20000:65': 131.0,
  'mem_40:10:7500:84': 83.0,
  'adb:10:2000:64': 2.0,
  'mem_7:10:7500:76': 37.0,
  'ffun_add:15:40000:76': 692.0,
  'mem_7:15:7500:98': 131.0,
  'adb:15:40000:21': 24.0,
  'adb:10:20000:10': 16.0,
  'adb:10:10000:73': 14.0,
  'mem_40:10:15000:10': 5.0,
  'dis:15:40000:51': 13.0,
  'mem_7:10:5000:34': 2.0,
  'adb:10:25000:103': 353.0,
  'ffun:10:5000:32': 36.0,
  'ffun:10:40000:87': 1322.0,
  'ffun_add:10:30000:51': 222.0,
  'ffun_add:10:10000:61': 85.0,
  'hcb:15:150:44': 28.0,
  'ffun_add:10:20000:63': 161.0,
  'hcb:10:100:20': 14.0,
  'mem:15:25000:101': 722.0,
  'adb:15:10000:19': 6.0,
  'mem:10:10000:102': 289.0,
  'adb:15:30000:111': 424.0,
  'ffun:15:20000:9': 38.0,
  'hcb:15:120:51': 18.0,
  'mem_7:15:40000:55': 53.0,
  'mem_40:15:2000:112': 59.0,
  'ffun_add:15:15000:25': 16.0,
  'mem_40:15:5000:41': 5.0,
  'ffun:10:7500:8': 34.0,
  'ffun:15:30000:2': 42.0,
  'mem:10:30000:10': 8.0,
  'ffun:10:7500:20': 35.0,
  'adb:15:2000:119': 29.0,
  'mem_7:10:10000:95': 116.0,
  'mem:10:7500:79': 62.0,
  'mem:15:5000:74': 28.0,
  'ffun_add:15:7500:107': 511.0,
  'ffun_add:10:5000:1': 10.0,
  'mem:15:25000:110': 722.0,
  'hcb:15:120:37': 17.0,
  'mem_40:15:2000:90': 29.0,
  'ffun:10:2000:67': 56.0,
  'mem_40:15:5000:90': 71.0,
  'hcb:10:50:34': 3.0,
  'ffun_add:15:5000:101': 343.0,
  'mem:15:30000:1': 6.0,
  'adb:10:5000:80': 13.0,
  'ffun:10:50000:12': 53.0,
  'adb:15:5000:107': 71.0,
  'ffun:10:15000:114': 994.0,
  'mem_7:15:10000:23': 2.0,
  'mem:15:30000:89': 422.0,
  'mem_7:15:20000:86': 169.0,
  'mem_7:10:40000:96': 692.0,
  'ffun_add:10:20000:65': 161.0,
  'mem:10:20000:115': 577.0,
  'mem:15:5000:75': 28.0,
  'ffun:15:7500:110': 391.0,
  'ffun_add:15:20000:97': 1353.0,
  'mem_40:15:7500:42': 7.0,
  'mem:15:40000:22': 10.0,
  'ffun:10:15000:115': 994.0,
  'mem_7:10:5000:33': 2.0,
  'mem_7:15:15000:24': 2.0,
  'adb:10:25000:69': 16.0,
  'mem:10:15000:20': 5.0,
  'ffun_add:15:25000:48': 100.0,
  'mem_40:10:7500:71': 47.0,
  'mem:10:2000:41': 4.0,
  'ffun_add:15:40000:63': 255.0,
  'ffun_add:15:10000:4': 12.0,
  'ffun_add:10:15000:80': 299.0,
  'adb:15:40000:66': 19.0,
  'adb:10:7500:111': 107.0,
  'ffun:10:40000:110': 2574.0,
  'ffun_add:10:10000:20': 14.0,
  'ffun:15:5000:58': 51.0,
  'mem_40:10:10000:17': 4.0,
  'mem_7:10:25000:17': 5.0,
  'adb:10:7500:29': 7.0,
  'mem_40:15:25000:96': 722.0,
  'mem_7:10:20000:30': 5.0,
  'ffun_add:10:15000:50': 79.0,
  'adb:10:40000:117': 564.0,
  'ffun:10:20000:54': 150.0,
  'mem_7:15:50000:37': 18.0,
  'mem_7:10:50000:85': 330.0,
  'ffun:10:20000:31': 49.0,
  'mem:15:30000:96': 865.0,
  'mem_7:15:50000:111': 866.0,
  'mem_40:15:50000:113': 1444.0,
  'mem:10:25000:77': 205.0,
  'mem_40:15:20000:106': 577.0,
  'ffun:15:7500:37': 39.0,
  'mem_40:10:10000:8': 4.0,
  'mem_40:10:5000:82': 55.0,
  'mem:15:2000:86': 29.0,
  'mem_40:10:2000:15': 1.0,
  'mem:15:2000:34': 1.0,
  'dis:15:50000:40': 12.0,
  'ffun_add:10:15000:87': 542.0,
  'mem_7:15:5000:29': 1.0,
  'adb:10:20000:47': 40.0,
  'mem_7:15:2000:1': 1.0,
  'mem_7:10:40000:31': 13.0,
  'ffun:10:5000:27': 35.0,
  'mem:10:10000:5': 4.0,
  'ffun_add:10:20000:27': 27.0,
  'mem_40:15:2000:39': 1.0,
  'adb:10:25000:117': 353.0,
  'mem:10:20000:85': 220.0,
  'adb:15:10000:71': 12.0,
  'ffun_add:15:2000:78': 41.0,
  'ffun_add:10:15000:2': 17.0,
  'adb:10:15000:102': 212.0,
  'ffun:15:30000:87': 733.0,
  'adb:10:40000:14': 31.0,
  'mem:15:40000:12': 8.0,
  'hcb:10:150:57': 31.0,
  'ffun_add:15:25000:10': 20.0,
  'adb:10:2000:88': 11.0,
  'mem_7:15:2000:21': 1.0,
  'mem_40:10:10000:49': 19.0,
  'mem:15:2000:20': 1.0,
  'adb:15:20000:16': 12.0,
  'mem_40:10:15000:14': 5.0,
  'ffun_add:10:2000:79': 47.0,
  'ffun_add:10:10000:49': 55.0,
  'mem:10:15000:66': 70.0,
  'ffun_add:10:25000:58': 194.0,
  'adb:15:25000:41': 29.0,
  'ffun:15:40000:47': 137.0,
  'mem_7:15:15000:36': 6.0,
  'adb:10:2000:109': 29.0,
  'adb:15:2000:40': 2.0,
  'mem_7:15:30000:63': 48.0,
  'mem_40:10:20000:114': 577.0,
  'mem_7:10:20000:50': 23.0,
  'adb:15:50000:106': 706.0,
  'mem_40:15:25000:17': 6.0,
  'hcb:10:100:19': 14.0,
  'ffun:10:40000:59': 268.0,
  'ffun_add:15:15000:82': 366.0,
  'ffun:10:40000:9': 48.0,
  'mem_40:10:2000:51': 6.0,
  'mem_40:15:2000:103': 59.0,
  'adb:15:50000:54': 109.0,
  'adb:15:50000:77': 108.0,
  'mem_7:10:50000:53': 84.0,
  'mem:15:15000:12': 4.0,
  'mem_40:10:20000:9': 6.0,
  'mem_40:15:10000:88': 142.0,
  'mem_40:10:20000:99': 577.0,
  'mem:10:20000:86': 292.0,
  'ffun_add:15:7500:8': 10.0,
  'mem_40:15:25000:115': 722.0,
  'adb:15:20000:65': 10.0,
  'ffun:15:7500:39': 39.0,
  'mem_7:10:30000:15': 5.0,
  'ffun:15:20000:115': 992.0,
  'ffun:15:15000:81': 286.0,
  'mem:10:2000:26': 1.0,
  'ffun_add:10:30000:55': 222.0,
  'mem_7:15:25000:16': 4.0,
  'mem_40:10:25000:116': 722.0,
  'mem_7:10:30000:64': 59.0,
  'ffun:10:15000:9': 38.0,
  'mem_7:15:25000:63': 41.0,
  'hcb:15:80:30': 7.0,
  'mem_7:10:10000:58': 18.0,
  'ffun:10:15000:112': 994.0,
  'ffun_add:15:5000:73': 69.0,
  'ffun:15:40000:116': 1953.0,
  'ffun:10:30000:53': 205.0,
  'mem_7:10:10000:49': 12.0,
  'mem:10:15000:17': 5.0,
  'mem_40:15:15000:39': 10.0,
  'mem_40:15:30000:29': 10.0,
  'ffun_add:15:30000:39': 52.0,
  'ffun:15:10000:12': 34.0,
  'mem:10:20000:71': 124.0,
  'mem:10:25000:110': 722.0,
  'dis:15:20000:49': 10.0,
  'ffun:15:15000:48': 70.0,
  'mem:15:2000:22': 1.0,
  'mem_7:10:40000:100': 692.0,
  'mem_40:15:10000:22': 2.0,
  'mem_7:15:5000:87': 43.0,
  'mem_40:10:10000:9': 4.0,
  'ffun:10:25000:77': 415.0,
  'mem_7:15:2000:32': 1.0,
  'mem_40:15:15000:35': 6.0,
  'ffun:15:10000:65': 75.0,
  'mem:10:40000:71': 247.0,
  'mem:10:7500:70': 35.0,
  'mem:10:20000:51': 56.0,
  'dis:15:30000:53': 12.0,
  'ffun_add:15:5000:52': 34.0,
  'ffun_add:10:15000:34': 27.0,
  'ffun:15:7500:74': 97.0,
  'mem:15:5000:55': 12.0,
  'mem_40:15:10000:13': 2.0,
  'mem:15:5000:88': 71.0,
  'mem_7:10:50000:113': 866.0,
  'adb:15:50000:9': 29.0,
  'ffun_add:10:5000:37': 17.0,
  'mem_7:15:5000:82': 31.0,
  'mem_40:15:10000:6': 2.0,
  'ffun_add:10:15000:1': 17.0,
  'ffun:15:2000:51': 38.0,
  'ffun:15:15000:16': 36.0,
  'adb:10:30000:92': 226.0,
  'adb:10:20000:63': 13.0,
  'mem_7:15:40000:54': 53.0,
  'dis:10:20000:45': 12.0,
  'mem:10:25000:78': 205.0,
  'ffun:15:50000:56': 239.0,
  'mem_7:15:30000:97': 520.0,
  'adb:15:15000:74': 17.0,
  'ffun:15:15000:77': 214.0,
  'hcb:10:150:35': 31.0,
  'adb:10:5000:52': 14.0,
  'adb:10:15000:19': 12.0,
  'mem_7:10:25000:80': 124.0,
  'ffun:15:5000:76': 91.0,
  'mem_7:15:2000:3': 1.0,
  'hcb:10:80:30': 9.0,
  'ffun:10:5000:62': 69.0,
  'ffun:15:20000:57': 114.0,
  'mem_7:15:5000:63': 8.0,
  'ffun_add:15:20000:50': 82.0,
  'ffun_add:10:30000:113': 2118.0,
  'hcb:10:100:57': 14.0,
  'ffun_add:10:10000:17': 14.0,
  'adb:10:25000:48': 49.0,
  'mem:15:30000:29': 10.0,
  'ffun_add:10:5000:68': 63.0,
  'mem_7:10:40000:70': 110.0,
  'ffun:15:10000:40': 41.0,
  'mem_7:10:25000:28': 6.0,
  'ffun:15:15000:50': 70.0,
  'ffun_add:10:15000:115': 1065.0,
  'mem:10:50000:57': 150.0,
  'ffun:10:25000:1': 42.0,
  'dis:10:10000:42': 11.0,
  'ffun:15:2000:29': 31.0,
  'ffun:15:25000:20': 40.0,
  'mem_40:15:20000:107': 577.0,
  'ffun:10:15000:75': 209.0,
  'mem_40:10:2000:77': 17.0,
  'mem:15:20000:44': 19.0,
  'mem_40:15:5000:45': 5.0,
  'mem_40:15:30000:89': 422.0,
  'mem:15:5000:100': 145.0,
  'mem:15:5000:61': 14.0,
  'ffun:10:25000:74': 322.0,
  'mem_7:15:5000:35': 1.0,
  'mem_7:10:2000:115': 35.0,
  'mem:10:7500:26': 4.0,
  'mem_7:15:30000:13': 5.0,
  'mem_40:15:2000:82': 22.0,
  'ffun:10:7500:105': 522.0,
  'mem_7:10:15000:108': 260.0,
  'mem_40:10:15000:46': 29.0,
  'mem_40:10:20000:17': 6.0,
  'mem_7:15:20000:97': 347.0,
  'hcb:10:80:32': 9.0,
  'adb:15:30000:10': 18.0,
  'ffun_add:10:30000:77': 588.0,
  'ffun:10:15000:38': 51.0,
  'ffun_add:10:2000:37': 11.0,
  'dis:15:40000:24': 11.0,
  'adb:10:10000:53': 28.0,
  'ffun:15:40000:14': 46.0,
  'ffun_add:15:7500:70': 74.0,
  'adb:15:2000:60': 4.0,
  'ffun:15:5000:7': 32.0,
  'mem_7:10:10000:60': 18.0,
  'adb:15:20000:31': 16.0,
  'dis:15:50000:38': 12.0,
  'ffun_add:10:30000:60': 229.0,
  'mem_40:10:25000:6': 7.0,
  'ffun_add:10:7500:50': 43.0,
  'ffun:10:7500:45': 50.0,
  'adb:10:25000:98': 353.0,
  'mem_7:15:25000:120': 433.0,
  'mem:10:20000:64': 65.0,
  'adb:15:30000:93': 223.0,
  'ffun_add:15:7500:120': 511.0,
  'adb:10:10000:62': 7.0,
  'ffun:10:20000:62': 157.0,
  'ffun_add:15:30000:1': 23.0,
  'mem_40:15:7500:72': 44.0,
  'mem_40:10:10000:96': 289.0,
  'mem:15:2000:65': 6.0,
  'ffun_add:15:2000:32': 9.0,
  'ffun_add:10:15000:116': 1065.0,
  'adb:10:10000:11': 8.0,
  'mem:10:50000:85': 550.0,
  'mem:10:30000:74': 186.0,
  'adb:10:10000:33': 10.0,
  'ffun:15:7500:94': 269.0,
  'adb:15:15000:63': 7.0,
  'mem:15:50000:93': 956.0,
  'ffun:10:40000:97': 2574.0,
  'mem_7:10:10000:109': 174.0,
  'ffun_add:15:2000:73': 31.0,
  'mem_7:10:40000:118': 692.0,
  'mem_7:10:30000:110': 520.0,
  'mem_40:15:5000:35': 2.0,
  'ffun_add:15:50000:29': 45.0,
  'mem_40:10:50000:102': 1444.0,
  'mem:10:2000:117': 59.0,
  'ffun:10:5000:105': 363.0,
  'mem_40:10:20000:85': 220.0,
  'mem:15:30000:14': 7.0,
  'adb:15:15000:42': 18.0,
  'ffun_add:15:40000:12': 28.0,
  'hcb:15:120:56': 16.0,
  'ffun:15:7500:21': 34.0,
  'mem_40:10:15000:75': 94.0,
  'mem_40:10:40000:25': 13.0,
  'ffun:15:10000:75': 119.0,
  'mem_40:10:10000:42': 13.0,
  'ffun:10:10000:10': 36.0,
  'ffun:15:2000:46': 36.0,
  'mem:10:50000:111': 1444.0,
  'mem:10:7500:6': 2.0,
  'ffun_add:15:30000:54': 174.0,
  'mem_40:15:30000:64': 80.0,
  'mem_7:10:15000:16': 4.0,
  'mem_7:15:40000:43': 23.0,
  'mem_40:10:30000:80': 246.0,
  'mem:10:15000:57': 46.0,
  'ffun_add:15:50000:105': 3373.0,
  'mem_40:15:10000:111': 289.0,
  'mem_7:10:15000:82': 100.0,
  'mem_7:15:40000:56': 59.0,
  'ffun:10:30000:37': 71.0,
  'mem_7:15:50000:58': 73.0,
  'ffun:10:40000:45': 119.0,
  'mem_7:15:20000:28': 4.0,
  'mem:15:5000:117': 145.0,
  'adb:15:5000:108': 71.0,
  'mem:15:2000:59': 6.0,
  'mem_7:10:5000:65': 11.0,
  'mem_7:15:50000:66': 116.0,
  'mem_7:15:30000:90': 253.0,
  'mem:10:20000:73': 124.0,
  'ffun_add:10:7500:32': 17.0,
  'mem:15:40000:14': 8.0,
  'mem_40:10:20000:74': 124.0,
  'hcb:10:80:40': 9.0,
  'hcb:15:120:40': 17.0,
  'mem:15:50000:117': 1444.0,
  'mem_40:15:25000:89': 353.0,
  'ffun_add:15:15000:84': 366.0,
  'adb:15:10000:29': 7.0,
  'mem_7:10:5000:98': 88.0,
  'ffun_add:10:50000:82': 1348.0,
  'ffun:15:7500:97': 391.0,
  'dis:15:10000:28': 9.0,
  'adb:10:5000:81': 18.0,
  'ffun:10:5000:112': 363.0,
  'ffun:10:20000:101': 1310.0,
  'mem_40:10:20000:75': 124.0,
  'adb:15:2000:66': 1.0,
  'mem_40:10:20000:55': 56.0,
  'mem:10:15000:81': 166.0,
  'ffun_add:10:40000:50': 196.0,
  'mem_40:10:2000:11': 1.0,
  'adb:15:20000:47': 31.0,
  'mem:15:2000:91': 38.0,
  'mem_40:10:50000:80': 409.0,
  'mem:10:25000:36': 19.0,
  'adb:15:10000:22': 6.0,
  'mem_7:10:50000:60': 90.0,
  'mem_7:15:15000:101': 260.0,
  'adb:15:5000:16': 4.0,
  'adb:10:40000:76': 97.0,
  'ffun_add:15:15000:106': 1017.0,
  'adb:15:5000:91': 37.0,
  'mem_7:15:20000:56': 30.0,
  'adb:15:5000:70': 2.0,
  'mem:15:15000:87': 211.0,
  'mem_40:15:2000:10': 1.0,
  'mem:10:40000:38': 31.0,
  'hcb:15:50:32': 3.0,
  'mem:10:20000:79': 164.0,
  'ffun:15:5000:19': 32.0,
  'mem_7:15:40000:4': 5.0,
  'ffun:15:5000:70': 62.0,
  'ffun:15:15000:23': 37.0,
  'mem_40:15:30000:43': 29.0,
  'ffun:10:2000:120': 173.0,
  'adb:15:10000:59': 16.0,
  'adb:15:50000:88': 241.0,
  'mem_7:15:5000:27': 1.0,
  'mem_7:10:2000:38': 1.0,
  'mem_7:10:25000:9': 5.0,
  'mem:15:20000:94': 383.0,
  'ffun:15:20000:11': 38.0,
  'ffun_add:10:15000:79': 299.0,
  'mem_7:15:25000:13': 4.0,
  'mem_7:10:7500:44': 6.0,
  'adb:10:15000:50': 30.0,
  'mem_7:15:25000:29': 5.0,
  'ffun_add:10:15000:24': 19.0,
  'hcb:10:50:20': 3.0,
  'mem:10:30000:49': 56.0,
  'mem_7:15:10000:60': 16.0,
  'adb:15:5000:94': 37.0,
  'mem_7:10:50000:26': 12.0,
  'mem:10:15000:14': 5.0,
  'mem_7:10:15000:74': 56.0,
  'mem:10:20000:26': 8.0,
  'mem:15:7500:118': 217.0,
  'ffun_add:10:5000:32': 13.0,
  'mem_40:10:10000:82': 110.0,
  'ffun_add:10:15000:91': 715.0,
  'adb:15:20000:36': 18.0,
  'mem:10:5000:50': 10.0,
  'mem_40:10:50000:13': 13.0,
  'ffun_add:10:40000:62': 313.0,
  'ffun:10:40000:46': 160.0,
  'mem_7:10:5000:113': 88.0,
  'ffun_add:15:20000:24': 19.0,
  'mem:10:25000:82': 275.0,
  'mem_40:15:7500:29': 2.0,
  'adb:15:50000:35': 37.0,
  'mem_40:10:5000:119': 145.0,
  'adb:10:40000:57': 76.0,
  'adb:15:2000:54': 5.0,
  'mem_40:10:5000:61': 17.0,
  'mem_40:15:10000:49': 16.0,
  'ffun:15:30000:51': 149.0,
  'mem:15:10000:31': 5.0,
  'mem_7:15:15000:95': 173.0,
  'ffun:10:2000:103': 173.0,
  'ffun:10:30000:14': 45.0,
  'mem_7:15:7500:40': 4.0,
  'ffun:10:20000:84': 527.0,
  'mem_40:10:7500:68': 35.0,
  'mem_7:15:25000:10': 4.0,
  'adb:10:7500:72': 11.0,
  'mem:15:15000:106': 433.0,
  'adb:10:7500:73': 11.0,
  'mem_40:10:15000:107': 433.0,
  'mem:15:7500:27': 2.0,
  'ffun:15:5000:23': 33.0,
  'mem:15:5000:42': 5.0,
  'ffun:15:30000:58': 156.0,
  'adb:10:5000:46': 11.0,
  'mem:10:25000:20': 7.0,
  'mem_40:10:40000:4': 11.0,
  'mem_7:10:2000:72': 8.0,
  'mem_7:15:40000:99': 692.0,
  'ffun_add:15:20000:62': 131.0,
  'mem_40:10:25000:73': 155.0,
  'adb:10:10000:0': 8.0,
  'adb:15:10000:55': 23.0,
  'ffun:10:2000:1': 33.0,
  'mem:15:30000:120': 865.0,
  'mem_7:15:2000:39': 1.0,
  'hcb:10:100:25': 14.0,
  'mem_7:10:40000:102': 692.0,
  'adb:10:2000:63': 2.0,
  'adb:10:40000:101': 564.0,
  'ffun_add:15:25000:118': 1690.0,
  'mem_40:15:10000:70': 40.0,
  'adb:15:25000:1': 14.0,
  'ffun_add:10:25000:46': 126.0,
  'mem_40:10:5000:120': 145.0,
  'adb:15:7500:52': 17.0,
  'adb:10:25000:89': 125.0,
  'adb:15:25000:0': 14.0,
  'mem:10:30000:87': 437.0,
  'mem_40:10:15000:1': 5.0,
  'mem:10:25000:15': 7.0,
  'mem:15:10000:112': 289.0,
  'mem:10:15000:15': 5.0,
  'mem:15:2000:72': 11.0,
  'adb:10:2000:51': 6.0,
  'ffun:10:40000:75': 492.0,
  'ffun_add:10:7500:120': 538.0,
  'mem_40:15:20000:33': 8.0,
  'ffun_add:15:20000:10': 17.0,
  'mem:10:7500:87': 109.0,
  'mem:15:40000:104': 1154.0,
  'mem_40:10:2000:82': 23.0,
  'ffun:15:50000:32': 67.0,
  'adb:10:20000:87': 101.0,
  'ffun:10:30000:24': 47.0,
  'ffun:10:20000:0': 40.0,
  'mem:15:40000:47': 59.0,
  'mem_7:15:25000:80': 119.0,
  'mem_7:10:20000:14': 4.0,
  'mem_40:15:2000:33': 1.0,
  'ffun_add:15:7500:27': 13.0,
  'dis:15:50000:32': 12.0,
  'mem_7:10:5000:118': 88.0,
  'ffun_add:15:30000:26': 30.0,
  'adb:10:30000:97': 424.0,
  'mem_7:15:25000:46': 23.0,
  'ffun_add:10:7500:17': 12.0,
  'mem_7:15:50000:27': 10.0,
  'ffun:15:50000:53': 229.0,
  'ffun:10:40000:80': 639.0,
  'mem:15:10000:22': 2.0,
  'mem_40:15:10000:51': 23.0,
  'mem:10:7500:102': 217.0,
  'adb:10:2000:96': 29.0,
  'ffun:10:7500:28': 36.0,
  'mem_40:15:50000:69': 193.0,
  'mem_40:10:40000:12': 11.0,
  'ffun:15:7500:69': 78.0,
  'mem_40:15:5000:101': 145.0,
  'mem:10:10000:33': 6.0,
  'mem:15:30000:67': 116.0,
  'mem_40:10:50000:29': 19.0,
  'mem_40:10:30000:59': 90.0,
  'ffun:10:5000:48': 49.0,
  'dis:10:40000:40': 14.0,
  'mem:15:15000:48': 23.0,
  'mem:15:30000:21': 7.0,
  'ffun_add:10:30000:105': 2118.0,
  'mem:10:5000:12': 2.0,
  'mem:10:25000:21': 8.0,
  'adb:15:40000:3': 23.0,
  'ffun_add:15:25000:35': 33.0,
  'ffun:10:15000:88': 525.0,
  'ffun_add:15:30000:37': 52.0,
  'mem:10:30000:57': 90.0,
  'dis:10:50000:40': 15.0,
  'ffun_add:10:25000:74': 374.0,
  'adb:15:7500:119': 107.0,
  'mem:10:15000:88': 218.0,
  'adb:10:2000:25': 2.0,
  'mem_7:10:20000:71': 74.0,
  'mem_7:15:10000:46': 10.0,
  'mem:10:10000:62': 32.0,
  'ffun_add:10:7500:82': 211.0,
  'mem_40:15:10000:114': 289.0,
  'mem_7:15:20000:60': 30.0,
  'mem:10:5000:83': 55.0,
  'ffun_add:15:15000:102': 1017.0,
  'mem:15:25000:14': 6.0,
  'ffun_add:10:5000:13': 10.0,
  'mem_7:10:7500:79': 37.0,
  'mem_7:15:5000:79': 24.0,
  'mem_40:10:5000:42': 7.0,
  'ffun_add:15:40000:32': 48.0,
  'ffun:15:50000:104': 2435.0,
  'ffun_add:10:50000:12': 41.0,
  'adb:15:10000:9': 6.0,
  'mem_7:10:10000:20': 2.0,
  'ffun_add:10:40000:52': 293.0,
  'mem_7:10:20000:7': 4.0,
  'ffun:10:2000:75': 62.0,
  'mem:10:10000:67': 47.0,
  'mem_40:15:40000:39': 24.0,
  'ffun_add:10:7500:53': 62.0,
  'mem_40:10:40000:39': 31.0,
  'dis:10:40000:54': 16.0,
  'mem_40:10:10000:115': 289.0,
  'mem_7:10:15000:85': 100.0,
  'mem_40:15:7500:69': 30.0,
  'adb:15:15000:111': 212.0,
  'mem_40:15:10000:46': 16.0,
  'hcb:10:80:37': 9.0,
  'mem_7:15:7500:102': 131.0,
  'ffun:10:10000:7': 36.0,
  'adb:10:40000:84': 138.0,
  'hcb:15:120:19': 17.0,
  'ffun:10:40000:103': 2574.0,
  'ffun_add:10:25000:39': 56.0,
  'adb:10:30000:32': 29.0,
  'dis:10:30000:52': 15.0,
  'adb:15:30000:107': 424.0,
  'ffun:10:5000:55': 67.0,
  'adb:10:7500:118': 107.0,
  'mem:15:2000:12': 1.0,
  'mem_40:15:25000:36': 16.0,
  'ffun:10:50000:73': 605.0,
  'mem_7:15:7500:82': 47.0,
  'mem_7:15:30000:46': 26.0,
  'adb:10:15000:36': 18.0,
  'ffun:15:40000:75': 386.0,
  'ffun:10:7500:44': 50.0,
  'ffun:15:15000:115': 752.0,
  'ffun:10:10000:109': 679.0,
  'mem_40:15:25000:35': 11.0,
  'ffun_add:15:25000:81': 604.0,
  'hcb:10:50:47': 4.0,
  'mem_40:15:2000:43': 2.0,
  'mem_40:10:10000:77': 83.0,
  'hcb:15:50:55': 3.0,
  'adb:15:7500:98': 107.0,
  'mem_40:10:2000:62': 7.0,
  'ffun_add:10:50000:73': 739.0,
  'mem_7:10:15000:47': 17.0,
  'ffun_add:10:2000:102': 151.0,
  'mem:15:7500:119': 217.0,
  'ffun_add:15:50000:117': 3373.0,
  'ffun:10:25000:69': 250.0,
  'ffun:15:10000:57': 72.0,
  'mem_7:10:2000:76': 11.0,
  'ffun_add:15:10000:100': 679.0,
  'mem_40:10:5000:112': 145.0,
  'mem_7:10:30000:74': 112.0,
  'adb:15:30000:75': 34.0,
  'adb:15:25000:40': 23.0,
  'mem_40:15:20000:31': 8.0,
  'mem_40:15:50000:68': 193.0,
  'mem:10:7500:66': 35.0,
  'mem_7:10:7500:100': 131.0,
  'mem_7:15:40000:27': 7.0,
  'mem_7:10:2000:2': 1.0,
  'mem_7:10:50000:83': 330.0,
  'ffun_add:15:20000:74': 255.0,
  'mem:15:7500:114': 217.0,
  'adb:15:15000:83': 48.0,
  'ffun:10:20000:92': 891.0,
  'mem_7:15:15000:115': 260.0,
  'mem:10:50000:70': 230.0,
  'mem_40:15:15000:52': 34.0,
  'ffun_add:10:7500:3': 12.0,
  'ffun_add:10:5000:97': 362.0,
  'ffun_add:10:15000:105': 1065.0,
  'mem_40:10:5000:84': 55.0,
  'mem_7:10:25000:56': 46.0,
  'mem_40:10:2000:40': 2.0,
  'ffun_add:10:10000:46': 55.0,
  'ffun:10:10000:15': 36.0,
  'ffun_add:10:7500:85': 211.0,
  'mem_7:15:40000:32': 10.0,
  'mem_40:15:10000:102': 289.0,
  'ffun:15:25000:100': 1232.0,
  'dis:15:50000:41': 13.0,
  'mem_7:15:7500:103': 131.0,
  'ffun_add:10:5000:64': 46.0,
  'ffun_add:10:50000:69': 552.0,
  'ffun:15:2000:82': 64.0,
  'ffun:10:20000:45': 77.0,
  'adb:10:10000:120': 142.0,
  'adb:15:20000:67': 10.0,
  'ffun:15:2000:40': 32.0,
  'ffun:10:2000:4': 33.0,
  'mem:10:15000:104': 433.0,
  'ffun_add:10:5000:5': 10.0,
  'ffun_add:15:10000:67': 97.0,
  'mem:10:25000:86': 365.0,
  'ffun:10:7500:50': 57.0,
  'mem_40:10:15000:33': 8.0,
  'mem:10:20000:6': 6.0,
  'ffun_add:10:40000:59': 304.0,
  'mem:15:7500:28': 2.0,
  'adb:10:25000:26': 20.0,
  'adb:15:2000:24': 1.0,
  'ffun_add:10:25000:82': 679.0,
  'dis:10:10000:31': 11.0,
  'mem_7:10:10000:52': 17.0,
  'mem_7:10:50000:50': 56.0,
  'adb:10:30000:42': 44.0,
  'ffun_add:10:30000:3': 27.0,
  'adb:10:2000:103': 29.0,
  'dis:15:40000:46': 12.0,
  'ffun:15:10000:89': 265.0,
  'mem:10:5000:79': 42.0,
  'mem:15:50000:49': 73.0,
  'mem_40:15:20000:108': 577.0,
  'ffun:15:15000:33': 41.0,
  'ffun_add:10:25000:94': 1184.0,
  'ffun:15:20000:79': 275.0,
  'mem_7:10:5000:79': 25.0,
  'ffun:15:40000:20': 46.0,
  'mem_40:15:20000:15': 5.0,
  'ffun:10:2000:5': 33.0,
  'adb:10:30000:69': 19.0,
  'mem_40:10:5000:26': 2.0,
  'ffun_add:15:40000:23': 31.0,
  'adb:10:40000:53': 110.0,
  'ffun:10:5000:101': 363.0,
  'adb:15:20000:13': 12.0,
  'ffun_add:15:25000:8': 20.0,
  'adb:15:25000:54': 55.0,
  'mem_40:15:20000:85': 205.0,
  'mem_40:15:20000:89': 282.0,
  'mem:10:50000:66': 230.0,
  'ffun_add:15:10000:52': 62.0,
  'mem:15:10000:35': 5.0,
  'mem_40:10:7500:41': 10.0,
  'adb:10:50000:17': 38.0,
  'ffun_add:10:2000:87': 81.0,
  'mem_7:15:20000:113': 347.0,
  'adb:15:5000:85': 17.0,
  'adb:15:10000:77': 22.0,
  'ffun_add:15:15000:55': 90.0,
  'ffun:10:10000:39': 45.0,
  'adb:10:50000:62': 31.0,
  'ffun:15:5000:11': 32.0,
  'ffun:10:40000:17': 49.0,
  'ffun_add:10:15000:85': 411.0,
  'mem:15:7500:57': 19.0,
  'ffun_add:15:30000:38': 52.0,
  'ffun_add:10:25000:63': 199.0,
  'mem:10:20000:81': 220.0,
  'mem_7:10:50000:119': 866.0,
  'adb:10:2000:69': 2.0,
  'ffun_add:10:40000:83': 1080.0,
  'ffun_add:10:20000:103': 1416.0,
  'ffun_add:15:50000:110': 3373.0,
  'mem_40:15:40000:4': 8.0,
  'hcb:15:50:28': 3.0,
  'mem_40:10:7500:76': 62.0,
  'mem_40:10:20000:46': 37.0,
  'ffun_add:15:50000:13': 34.0,
  'adb:10:50000:1': 38.0,
  'adb:15:2000:26': 2.0,
  'ffun_add:10:40000:32': 59.0,
  'mem_40:15:20000:27': 6.0,
  'mem:15:10000:92': 192.0,
  'hcb:10:80:51': 9.0,
  'adb:10:50000:66': 31.0,
  'mem_7:15:7500:41': 5.0,
  'ffun_add:15:10000:117': 679.0,
  'ffun_add:15:50000:45': 128.0,
  'ffun:10:5000:75': 96.0,
  'ffun:10:10000:70': 123.0,
  'mem_7:10:50000:86': 437.0,
  'mem_40:10:7500:64': 25.0,
  'adb:15:50000:69': 23.0,
  'adb:10:30000:6': 23.0,
  'ffun:15:40000:26': 52.0,
  'ffun_add:10:7500:13': 12.0,
  'mem_7:10:25000:27': 6.0,
  'ffun:15:10000:115': 511.0,
  'mem_40:15:20000:21': 5.0,
  'mem:15:7500:115': 217.0,
  'adb:10:10000:86': 50.0,
  'mem_7:10:10000:25': 2.0,
  'ffun:10:10000:50': 65.0,
  'mem:10:30000:54': 84.0,
  'mem_40:15:2000:88': 29.0,
  'mem_7:10:2000:31': 1.0,
  'ffun:15:7500:83': 158.0,
  'mem_7:15:2000:79': 10.0,
  'adb:15:20000:73': 23.0,
  'mem:10:7500:42': 10.0,
  'mem_7:10:50000:72': 186.0,
  'ffun_add:15:20000:108': 1353.0,
  'adb:10:20000:58': 38.0,
  'mem:10:50000:47': 92.0,
  'mem:10:30000:98': 865.0,
  'ffun:15:30000:103': 1472.0,
  'mem:15:10000:23': 2.0,
  'mem_7:10:40000:8': 7.0,
  'mem_40:10:5000:94': 97.0,
  'adb:10:7500:88': 38.0,
  'mem_7:10:2000:101': 35.0,
  'ffun:10:50000:63': 334.0,
  'mem_7:15:7500:84': 47.0,
  'adb:10:7500:30': 7.0,
  'mem_40:10:40000:67': 184.0,
  'mem:15:5000:85': 52.0,
  'ffun:10:25000:33': 53.0,
  'mem_7:15:5000:101': 88.0,
  'mem_40:10:25000:102': 722.0,
  'mem:15:7500:32': 4.0,
  'mem:10:7500:1': 2.0,
  'ffun:10:2000:86': 110.0,
  'ffun:15:30000:97': 1472.0,
  'mem_7:15:7500:55': 11.0,
  'mem_40:10:20000:66': 92.0,
  'ffun_add:10:20000:77': 395.0,
  'adb:10:2000:93': 16.0,
  'ffun:10:30000:9': 44.0,
  'mem:15:20000:41': 19.0,
  'ffun_add:15:40000:74': 504.0,
  'mem_7:15:2000:68': 5.0,
  'ffun:10:50000:90': 1641.0,
  'ffun:15:10000:0': 34.0,
  'ffun:10:10000:52': 95.0,
  'mem_40:15:5000:74': 30.0,
  'ffun:10:5000:13': 33.0,
  'ffun_add:15:20000:12': 17.0,
  'adb:15:7500:12': 5.0,
  'mem_7:15:30000:10': 4.0,
  'mem_40:15:20000:92': 383.0,
  'adb:15:30000:99': 424.0,
  'mem_7:15:7500:47': 7.0,
  'mem_40:15:50000:84': 512.0,
  'mem:10:5000:63': 17.0,
  'mem_7:10:20000:52': 34.0,
  'mem_7:10:2000:68': 6.0,
  'mem_40:10:7500:101': 217.0,
  'dis:10:30000:57': 19.0,
  'adb:15:10000:75': 12.0,
  'adb:15:20000:46': 31.0,
  'mem_7:10:25000:6': 5.0,
  'ffun:10:40000:39': 84.0,
  'mem_40:10:7500:30': 4.0,
  'mem_7:10:15000:17': 4.0,
  'ffun_add:10:2000:20': 9.0,
  'mem:10:15000:119': 433.0,
  'mem:15:20000:58': 49.0,
  'ffun_add:10:20000:36': 46.0,
  'mem_7:15:25000:118': 433.0,
  'mem:15:25000:13': 6.0,
  'dis:15:20000:27': 10.0,
  'mem:15:25000:36': 16.0,
  'ffun_add:10:10000:8': 13.0,
  'mem:10:5000:95': 97.0,
  'ffun_add:15:50000:108': 3373.0,
  'adb:15:10000:117': 142.0,
  'mem_40:15:2000:72': 12.0,
  'ffun:10:30000:38': 71.0,
  'ffun_add:15:15000:52': 90.0,
  'mem_40:15:25000:93': 479.0,
  'mem_7:10:20000:55': 34.0,
  'mem:15:50000:85': 512.0,
  'adb:10:15000:59': 29.0,
  'ffun_add:15:30000:91': 1343.0,
  'ffun:15:7500:111': 391.0,
  'mem_7:15:50000:97': 866.0,
  'mem_7:15:10000:97': 174.0,
  'dis:15:40000:43': 12.0,
  'ffun:10:50000:54': 316.0,
  'ffun:15:40000:117': 1953.0,
  'adb:10:25000:90': 125.0,
  'ffun:15:30000:94': 985.0,
  'ffun:15:20000:92': 667.0,
  'mem_40:15:15000:109': 433.0,
  'ffun_add:10:30000:30': 35.0,
  'hcb:10:150:20': 30.0,
  'ffun_add:15:20000:53': 118.0,
  'mem_7:10:50000:56': 90.0,
  'mem_40:10:25000:106': 722.0,
  'ffun:10:50000:109': 3205.0,
  'adb:10:5000:69': 4.0,
  'adb:10:20000:55': 55.0,
  'mem_40:15:30000:66': 116.0,
  'mem:10:20000:31': 11.0,
  'mem:15:20000:14': 5.0,
  'mem:15:7500:95': 144.0,
  'mem_7:15:7500:57': 12.0,
  'mem:10:40000:9': 11.0,
  'mem_7:10:7500:40': 4.0,
  'mem_7:10:50000:20': 8.0,
  'adb:15:20000:103': 282.0,
  'mem_40:15:20000:42': 19.0,
  'mem:10:40000:105': 1154.0,
  'ffun_add:15:25000:5': 20.0,
  'adb:15:5000:115': 71.0,
  'adb:10:15000:110': 212.0,
  'mem_7:10:25000:36': 12.0,
  'mem:10:20000:66': 92.0,
  'ffun:10:7500:76': 152.0,
  'mem_40:10:10000:12': 4.0,
  'hcb:15:120:30': 17.0,
  'mem_7:15:7500:68': 18.0,
  'mem_40:15:40000:11': 8.0,
  'ffun_add:15:10000:53': 62.0,
  'ffun_add:15:25000:102': 1690.0,
  'mem:15:50000:14': 11.0,
  'mem:10:25000:65': 82.0,
  'hcb:15:100:24': 12.0,
  'mem:10:5000:96': 145.0,
  'ffun_add:15:20000:22': 19.0,
  'adb:15:20000:91': 149.0,
  'adb:15:5000:99': 71.0,
  'ffun:15:2000:6': 31.0,
  'ffun_add:10:25000:26': 31.0,
  'ffun_add:10:15000:32': 27.0,
  'mem_40:15:25000:59': 61.0,
  'adb:15:20000:44': 23.0,
  'mem_7:10:2000:114': 35.0,
  'mem:15:7500:9': 2.0,
  'ffun:15:10000:102': 511.0,
  'mem_7:15:15000:67': 35.0,
  'adb:15:20000:50': 31.0,
  'adb:15:20000:87': 97.0,
  'mem:15:5000:119': 145.0,
  'adb:15:10000:63': 5.0,
  'mem_7:10:25000:86': 218.0,
  'dis:10:40000:55': 16.0,
  'adb:15:7500:68': 4.0,
  'ffun:15:10000:15': 34.0,
  'ffun:10:15000:25': 40.0,
  'mem_7:10:40000:95': 463.0,
  'mem_7:15:40000:72': 140.0,
  'mem_40:10:20000:76': 164.0,
  'mem_40:10:2000:98': 59.0,
  'mem_7:10:20000:37': 10.0,
  'mem_7:10:15000:79': 74.0,
  'dis:15:40000:54': 13.0,
  'mem:10:50000:41': 61.0,
  'adb:10:40000:111': 564.0,
  'mem_40:10:15000:18': 5.0,
  'mem:10:2000:15': 1.0,
  'mem_40:10:40000:23': 13.0,
  'mem_7:10:25000:37': 12.0,
  'mem_40:15:30000:63': 80.0,
  'ffun:10:50000:85': 1248.0,
  'mem:15:10000:60': 25.0,
  'mem_7:15:2000:11': 1.0,
  'mem_7:15:30000:57': 43.0,
  'hcb:15:80:32': 8.0,
  'dis:15:30000:57': 16.0,
  'mem_40:15:2000:101': 59.0,
  'ffun:10:50000:22': 57.0,
  'mem_7:15:2000:95': 24.0,
  'ffun_add:15:10000:39': 21.0,
  'mem_40:10:15000:30': 6.0,
  'ffun_add:15:50000:72': 629.0,
  'adb:10:25000:16': 19.0,
  'mem:15:20000:93': 383.0,
  'mem_7:10:5000:100': 88.0,
  'mem:10:5000:80': 42.0,
  'mem:15:40000:29': 12.0,
  'adb:10:20000:60': 38.0,
  'ffun_add:15:25000:114': 1690.0,
  'ffun_add:15:7500:116': 511.0,
  'ffun_add:10:2000:52': 23.0,
  'adb:15:5000:65': 2.0,
  'hcb:10:80:36': 9.0,
  'mem_40:15:50000:14': 11.0,
  'mem:10:30000:43': 37.0,
  'mem:10:25000:23': 8.0,
  'hcb:15:100:37': 12.0,
  'mem_7:15:30000:53': 40.0,
  'mem:15:40000:94': 764.0,
  'ffun_add:10:7500:89': 276.0,
  'mem_7:10:10000:105': 174.0,
  'adb:10:15000:61': 10.0,
  'mem_7:10:20000:15': 4.0,
  'ffun:10:20000:41': 77.0,
  'ffun_add:10:15000:47': 79.0,
  'mem_7:10:2000:98': 35.0,
  'dis:15:20000:37': 10.0,
  'mem_40:10:5000:21': 2.0,
  'ffun:10:50000:89': 1641.0,
  'mem_40:10:40000:47': 74.0,
  'ffun_add:15:40000:88': 1319.0,
  'mem:15:2000:31': 1.0,
  'mem:15:5000:37': 4.0,
  'ffun_add:15:10000:24': 13.0,
  'ffun_add:10:30000:53': 222.0,
  'ffun:15:2000:36': 32.0,
  'mem_7:15:7500:18': 1.0,
  'mem_7:10:7500:56': 14.0,
  'mem:10:2000:13': 1.0,
  'mem_40:10:20000:94': 386.0,
  'mem_40:15:50000:29': 14.0,
  'mem_7:10:10000:87': 88.0,
  'mem_40:10:7500:1': 2.0,
  'ffun:10:10000:119': 679.0,
  'dis:10:20000:57': 18.0,
  'mem:15:2000:82': 22.0,
  'mem:15:25000:52': 55.0,
  'mem_40:15:10000:71': 59.0,
  'ffun:10:5000:25': 34.0,
  'adb:15:2000:39': 2.0,
  'ffun:15:25000:72': 252.0,
  'ffun_add:10:2000:57': 23.0,
  'ffun:15:40000:87': 968.0,
  'ffun:10:40000:118': 2574.0,
  'mem_40:10:40000:14': 11.0,
  'mem:10:2000:23': 1.0,
  'mem_40:10:5000:57': 16.0,
  'ffun:10:5000:107': 363.0,
  'mem_40:15:10000:34': 5.0,
  'adb:10:50000:36': 58.0,
  'ffun:15:50000:27': 57.0,
  'ffun:10:20000:78': 339.0,
  'mem_40:15:30000:23': 7.0,
  'ffun_add:10:15000:25': 19.0,
  'adb:15:25000:9': 14.0,
  'dis:15:50000:30': 12.0,
  'mem:10:2000:46': 5.0,
  'mem_7:10:15000:71': 56.0,
  'mem:15:30000:13': 7.0,
  'mem_7:15:30000:72': 104.0,
  'ffun:10:7500:14': 35.0,
  'ffun_add:15:7500:82': 186.0,
  'mem_7:15:20000:7': 2.0,
  'ffun_add:10:5000:9': 10.0,
  'adb:10:50000:16': 38.0,
  'adb:15:5000:86': 25.0,
  'mem_40:10:10000:90': 146.0,
  'adb:15:10000:18': 6.0,
  'mem_40:15:25000:30': 7.0,
  'mem:15:50000:39': 30.0,
  'adb:10:25000:88': 125.0,
  'mem_7:10:15000:9': 2.0,
  'mem_7:15:2000:92': 24.0,
  'dis:15:10000:49': 9.0,
  'ffun:10:25000:118': 1626.0,
  'mem_40:10:5000:35': 4.0,
  'adb:15:50000:28': 32.0,
  'mem_40:15:25000:63': 67.0,
  'ffun:10:50000:17': 55.0,
  'ffun_add:15:7500:45': 25.0,
  'adb:10:50000:12': 38.0,
  'adb:15:15000:57': 23.0,
  'adb:15:30000:102': 424.0,
  'mem_40:10:30000:115': 865.0,
  'hcb:10:120:34': 20.0,
  'mem_7:15:30000:9': 4.0,
  'ffun_add:15:7500:25': 12.0,
  'mem_7:10:20000:101': 347.0,
  'mem_7:10:20000:4': 4.0,
  'ffun:10:20000:69': 208.0,
  'ffun_add:15:5000:112': 343.0,
  'ffun:15:7500:108': 391.0,
  'adb:15:30000:79': 65.0,
  'ffun_add:15:15000:64': 100.0,
  'mem_40:15:15000:95': 287.0,
  'mem:15:30000:26': 10.0,
  'ffun_add:10:50000:48': 243.0,
  'ffun_add:10:50000:86': 1785.0,
  'ffun_add:10:2000:72': 38.0,
  'mem_40:15:20000:57': 49.0,
  'mem_7:10:50000:31': 16.0,
  'adb:15:25000:90': 121.0,
  'ffun:10:7500:54': 81.0,
  'mem_7:10:10000:107': 174.0,
  'mem_7:10:15000:37': 7.0,
  'mem_40:10:50000:21': 16.0,
  'ffun:15:10000:78': 153.0,
  'mem:15:25000:96': 722.0,
  'ffun_add:10:7500:63': 66.0,
  'ffun:15:20000:46': 84.0,
  'mem_7:10:50000:17': 8.0,
  'adb:10:25000:46': 49.0,
  'ffun:15:20000:33': 45.0,
  'mem_7:10:50000:70': 138.0,
  'mem:15:10000:72': 54.0,
  'mem_7:10:25000:99': 433.0,
  'mem_40:15:5000:57': 13.0,
  'adb:10:20000:49': 40.0,
  'hcb:15:50:45': 3.0,
  'ffun_add:15:40000:73': 504.0,
  'mem_40:10:20000:93': 386.0,
  'mem_40:10:40000:27': 16.0,
  'adb:15:2000:12': 1.0,
  'mem_7:15:40000:83': 246.0,
  'ffun_add:10:20000:3': 20.0,
  'adb:10:5000:94': 38.0,
  'adb:15:20000:45': 23.0,
  'ffun_add:10:7500:77': 154.0,
  'mem:15:20000:51': 44.0,
  'mem_40:10:10000:52': 29.0,
  'ffun_add:10:5000:98': 362.0,
  'mem:10:2000:40': 2.0,
  'ffun:10:40000:3': 48.0,
  'ffun:10:5000:119': 363.0,
  'adb:15:50000:20': 29.0,
  'mem_40:10:5000:50': 10.0,
  'adb:10:7500:114': 107.0,
  'mem_7:10:50000:43': 37.0,
  'mem_40:15:50000:100': 1444.0,
  'ffun_add:10:15000:111': 1065.0,
  'ffun_add:15:40000:50': 156.0,
  'adb:15:5000:102': 71.0,
  'ffun:10:20000:11': 41.0,
  'mem_40:15:5000:89': 71.0,
  'mem_40:15:15000:94': 287.0,
  'mem_40:10:25000:56': 76.0,
  'ffun:10:5000:116': 363.0,
  'mem:15:5000:102': 145.0,
  'hcb:15:50:43': 3.0,
  'mem_40:15:50000:28': 14.0,
  'mem_40:10:7500:105': 217.0,
  'ffun:10:2000:48': 40.0,
  'mem:15:40000:72': 214.0,
  'ffun:10:40000:5': 48.0,
  'adb:10:2000:106': 29.0,
  'mem_40:10:30000:8': 8.0,
  'mem_40:10:10000:112': 289.0,
  'ffun:10:2000:106': 173.0,
  'mem_7:15:20000:111': 347.0,
  'mem_7:10:7500:51': 13.0,
  'mem:10:25000:24': 8.0,
  'ffun:15:25000:24': 41.0,
  'ffun_add:10:7500:51': 62.0,
  'mem_40:10:30000:30': 12.0,
  'ffun_add:15:25000:22': 23.0,
  'mem:10:25000:49': 47.0,
  'mem:10:10000:78': 83.0,
  'ffun:10:50000:86': 1641.0,
  'ffun_add:10:25000:25': 27.0,
  'mem_7:15:25000:35': 6.0,
  'mem_40:10:5000:13': 2.0,
  'dis:15:10000:47': 9.0,
  'adb:15:20000:107': 282.0,
  'mem_40:15:2000:63': 6.0,
  'mem_40:10:15000:85': 166.0,
  'ffun:10:30000:36': 71.0,
  'ffun:10:5000:84': 167.0,
  'mem_40:15:30000:101': 865.0,
  'adb:15:7500:83': 24.0,
  'mem_7:15:5000:99': 88.0,
  'adb:10:40000:16': 31.0,
  'mem_40:15:30000:41': 29.0,
  'mem_7:15:30000:66': 70.0,
  'mem_40:10:2000:87': 30.0,
  'ffun:10:30000:39': 71.0,
  'mem_40:10:50000:25': 16.0,
  'mem_40:15:25000:67': 97.0,
  'mem_40:15:2000:77': 17.0,
  'mem_7:10:50000:103': 866.0,
  'mem:10:40000:58': 120.0,
  'mem_40:10:25000:9': 7.0,
  'ffun:15:30000:110': 1472.0,
  'adb:15:15000:15': 10.0,
  'mem:10:25000:39': 19.0,
  'dis:15:10000:42': 9.0,
  'mem_7:15:40000:5': 5.0,
  'mem_40:10:5000:17': 2.0,
  'mem_7:10:7500:6': 1.0,
  'adb:10:50000:47': 98.0,
  'mem_40:10:2000:56': 6.0,
  'mem_7:15:7500:4': 1.0,
  'ffun:10:20000:99': 1310.0,
  'mem_7:10:15000:84': 100.0,
  'mem:15:40000:111': 1154.0,
  'mem:15:20000:116': 577.0,
  'hcb:15:100:31': 12.0,
  'ffun_add:15:10000:114': 679.0,
  'ffun_add:15:50000:16': 35.0,
  'mem:15:30000:37': 18.0,
  'ffun:15:2000:87': 77.0,
  'ffun_add:15:15000:120': 1017.0,
  'mem:15:20000:11': 5.0,
  'mem_40:15:5000:18': 1.0,
  'mem_7:15:15000:112': 260.0,
  'adb:10:20000:20': 16.0,
  'ffun_add:10:2000:81': 63.0,
  'adb:10:25000:4': 19.0,
  'adb:10:10000:25': 8.0,
  'mem_40:15:5000:76': 40.0,
  'ffun_add:10:40000:106': 2824.0,
  'ffun_add:15:30000:12': 23.0,
  'ffun_add:10:20000:5': 20.0,
  'dis:15:40000:38': 12.0,
  'mem_40:10:50000:93': 964.0,
  'ffun_add:10:30000:117': 2118.0,
  'mem_7:10:2000:33': 1.0,
  'mem_40:15:25000:86': 353.0,
  'ffun_add:15:10000:41': 31.0,
  'ffun_add:15:50000:15': 34.0,
  'mem:10:40000:99': 1154.0,
  'adb:15:15000:26': 10.0,
  'ffun:15:15000:56': 93.0,
  'mem_40:10:40000:113': 1154.0,
  'ffun_add:10:30000:90': 1074.0,
  'mem_7:15:25000:60': 37.0,
  'mem_40:15:5000:14': 1.0,
  'adb:10:10000:59': 19.0,
  'ffun_add:10:5000:22': 10.0,
  'mem:15:15000:3': 4.0,
  'adb:10:10000:49': 20.0,
  'ffun_add:10:7500:5': 12.0,
  'adb:15:40000:102': 564.0,
  'mem:15:2000:103': 59.0,
  'dis:15:10000:50': 9.0,
  'ffun:10:5000:34': 36.0,
  'adb:10:50000:55': 137.0,
  'mem:15:40000:99': 1154.0,
  'ffun:10:25000:40': 64.0,
  'ffun_add:15:50000:90': 1648.0,
  'mem_40:15:10000:62': 28.0,
  'mem_7:10:7500:116': 131.0,
  'mem_7:15:50000:116': 866.0,
  'ffun:10:7500:59': 83.0,
  'mem:15:50000:27': 14.0,
  'adb:15:15000:70': 7.0,
  'dis:10:20000:52': 13.0,
  'adb:15:30000:65': 14.0,
  'mem_40:10:30000:23': 10.0,
  'adb:15:2000:70': 1.0,
  'ffun_add:15:15000:70': 142.0,
  'mem:15:25000:11': 6.0,
  'mem:15:5000:112': 145.0,
  'mem:15:10000:100': 289.0,
  'mem_7:15:2000:93': 24.0,
  'ffun:15:40000:55': 189.0,
  'adb:10:5000:66': 4.0,
  'mem_40:10:50000:99': 1444.0,
  'ffun_add:15:40000:52': 229.0,
  'adb:15:40000:83': 127.0,
  'mem_40:10:2000:32': 1.0,
  'mem_40:10:20000:38': 16.0,
  'mem_40:10:7500:21': 2.0,
  'ffun:10:50000:75': 605.0,
  'ffun:10:7500:75': 124.0,
  'mem:15:50000:41': 47.0,
  'mem_40:15:5000:100': 145.0,
  'mem:10:40000:32': 20.0,
  'ffun_add:10:15000:73': 228.0,
  'mem_7:10:5000:106': 88.0,
  'mem_7:15:20000:12': 4.0,
  'mem:10:10000:82': 110.0,
  'mem_40:15:20000:91': 383.0,
  'ffun:10:5000:51': 67.0,
  'mem_7:15:5000:95': 58.0,
  'mem:15:15000:68': 59.0,
  'adb:15:40000:11': 23.0,
  'mem_7:10:7500:93': 88.0,
  'ffun:15:20000:63': 119.0,
  'ffun_add:15:40000:83': 962.0,
  'mem:15:25000:120': 722.0,
  'ffun:15:2000:15': 31.0,
  'ffun:15:50000:10': 49.0,
  'adb:15:40000:89': 193.0,
  'adb:10:2000:82': 7.0,
  'mem_7:15:30000:91': 344.0,
  'mem:10:20000:104': 577.0,
  'mem_40:15:25000:74': 145.0,
  'mem_7:10:10000:9': 2.0,
  'mem:10:25000:57': 76.0,
  'ffun:10:50000:88': 1641.0,
  'mem_40:10:25000:67': 115.0,
  'mem_40:15:40000:68': 155.0,
  'mem_7:15:10000:9': 1.0,
  'ffun_add:10:5000:44': 23.0,
  'mem:15:10000:15': 2.0,
  'mem_40:15:7500:76': 60.0,
  'mem_7:15:50000:104': 866.0,
  'ffun_add:15:50000:48': 194.0,
  'adb:15:50000:72': 56.0,
  'ffun:15:2000:22': 31.0,
  'mem_40:10:25000:12': 7.0,
  'mem_7:15:10000:35': 2.0,
  'mem_7:15:2000:107': 35.0,
  'mem_40:15:7500:78': 60.0,
  'mem:10:2000:55': 6.0,
  'adb:10:7500:41': 12.0,
  'ffun_add:10:10000:10': 13.0,
  'mem_40:10:50000:47': 92.0,
  'mem_7:10:10000:112': 174.0,
  'mem:10:7500:43': 10.0,
  'ffun:10:25000:96': 1626.0,
  'mem:10:30000:0': 8.0,
  'mem_40:15:30000:73': 174.0,
  'mem_7:15:40000:57': 59.0,
  'mem_7:10:5000:35': 2.0,
  'adb:10:30000:33': 29.0,
  'ffun_add:15:10000:110': 679.0,
  'mem_7:15:20000:17': 4.0,
  'mem_7:10:10000:78': 49.0,
  'ffun:10:40000:55': 261.0,
  'adb:15:15000:71': 17.0,
  'ffun_add:10:7500:104': 538.0,
  'adb:10:5000:19': 5.0,
  'mem_40:15:7500:45': 7.0,
  'adb:15:5000:116': 71.0,
  'mem:10:40000:111': 1154.0,
  'adb:15:50000:85': 160.0,
  'ffun:15:15000:24': 37.0,
  'dis:10:50000:50': 16.0,
  'adb:15:7500:112': 107.0,
  'ffun_add:10:7500:87': 276.0,
  'ffun_add:10:40000:30': 46.0,
  'mem:15:15000:115': 433.0,
  'ffun_add:15:15000:104': 1017.0,
  'adb:15:20000:22': 12.0,
  'ffun_add:15:7500:33': 14.0,
  'mem_40:15:20000:29': 6.0,
  'ffun_add:15:2000:52': 17.0,
  'ffun_add:15:50000:65': 317.0,
  'mem:10:40000:104': 1154.0,
  'mem_7:15:2000:73': 7.0,
  'ffun:15:10000:4': 34.0,
  'mem:10:30000:8': 8.0,
  'ffun:15:10000:80': 153.0,
  'mem_7:10:30000:105': 520.0,
  'mem:15:30000:9': 6.0,
  'adb:10:30000:8': 23.0,
  'adb:15:2000:95': 16.0,
  'adb:10:2000:39': 2.0,
  'dis:15:40000:47': 12.0,
  'adb:10:25000:54': 68.0,
  'mem:10:40000:28': 16.0,
  'ffun:10:5000:85': 167.0,
  'mem_7:10:5000:96': 88.0,
  'ffun_add:10:50000:32': 72.0,
  'mem_40:15:25000:79': 197.0,
  'ffun_add:15:10000:46': 44.0,
  'mem:10:25000:75': 155.0,
  'dis:15:50000:24': 12.0,
  'mem_7:15:20000:82': 124.0,
  'ffun_add:15:10000:68': 97.0,
  'adb:15:30000:103': 424.0,
  'adb:15:50000:89': 241.0,
  'mem:15:40000:93': 764.0,
  'ffun_add:15:25000:72': 318.0,
  'ffun_add:15:7500:21': 12.0,
  'ffun_add:10:20000:106': 1416.0,
  'mem_40:15:25000:5': 6.0,
  'dis:15:50000:33': 12.0,
  'mem_40:15:50000:98': 1444.0,
  'mem:15:50000:67': 193.0,
  'hcb:10:150:46': 33.0,
  'mem_40:15:25000:14': 6.0,
  'mem_7:10:30000:23': 6.0,
  'ffun_add:10:40000:120': 2824.0,
  'adb:10:2000:90': 11.0,
  'mem:15:15000:5': 4.0,
  'mem:10:5000:64': 17.0,
  'mem_7:15:20000:47': 18.0,
  'mem:15:15000:82': 155.0,
  'ffun:15:2000:54': 38.0,
  'adb:10:2000:2': 2.0,
  'mem_40:10:30000:29': 12.0,
  'mem_7:15:50000:75': 175.0,
  'ffun:10:2000:84': 95.0,
  'mem_40:15:40000:27': 12.0,
  'mem:10:7500:46': 14.0,
  'hcb:15:150:49': 28.0,
  'ffun:10:2000:57': 51.0,
  'ffun_add:15:2000:41': 12.0,
  'mem:10:7500:8': 2.0,
  'mem_7:10:40000:25': 8.0,
  'mem:10:50000:100': 1444.0,
  'mem:15:50000:70': 193.0,
  'ffun:10:40000:120': 2574.0,
  'mem:15:40000:19': 10.0,
  'mem:15:20000:42': 19.0,
  'adb:10:15000:17': 12.0,
  'dis:15:10000:41': 9.0,
  'mem_40:15:25000:111': 722.0,
  'ffun:10:30000:63': 217.0,
  'ffun:15:40000:17': 46.0,
  'ffun:15:5000:93': 189.0,
  'mem:15:10000:24': 2.0,
  'ffun:10:2000:41': 38.0,
  'mem_7:15:40000:20': 6.0,
  'ffun:15:40000:96': 1953.0,
  'adb:15:2000:83': 7.0,
  'ffun_add:15:20000:102': 1353.0,
  'ffun:10:15000:37': 51.0,
  'ffun_add:15:7500:109': 511.0,
  'ffun:10:15000:116': 994.0,
  'adb:15:25000:3': 14.0,
  'dis:15:30000:27': 11.0,
  'ffun:15:15000:2': 36.0,
  'ffun:15:20000:61': 119.0,
  'mem_40:15:40000:2': 8.0,
  'mem_40:10:10000:119': 289.0,
  'ffun:15:10000:85': 201.0,
  'ffun:10:2000:32': 34.0,
  'mem_7:10:25000:98': 433.0,
  'adb:15:7500:25': 5.0,
  'ffun:15:50000:16': 50.0,
  'mem_7:15:2000:29': 1.0,
  'adb:15:5000:2': 4.0,
  'ffun_add:10:7500:81': 211.0,
  'mem_7:10:25000:4': 5.0,
  'dis:15:20000:48': 10.0,
  'mem_7:10:2000:111': 35.0,
  'ffun_add:15:2000:86': 72.0,
  'ffun_add:15:2000:51': 17.0,
  'adb:15:25000:119': 353.0,
  'mem_40:10:25000:3': 7.0,
  'mem:10:25000:31': 13.0,
  'ffun:10:30000:113': 1940.0,
  'mem:15:15000:43': 14.0,
  'mem_40:10:7500:7': 2.0,
  'mem_40:10:7500:106': 217.0,
  'mem:10:10000:37': 8.0,
  'adb:15:2000:67': 1.0,
  'adb:15:30000:25': 18.0,
  'ffun:10:15000:11': 38.0,
  'ffun:10:30000:4': 44.0,
  'ffun_add:10:5000:71': 82.0,
  'dis:10:20000:40': 12.0,
  'ffun:10:7500:12': 35.0,
  'mem_7:10:50000:87': 437.0,
  'ffun_add:10:15000:98': 1065.0,
  'ffun_add:10:50000:24': 46.0,
  'mem_40:15:15000:89': 211.0,
  'mem:10:10000:113': 289.0,
  'adb:10:40000:52': 110.0,
  'mem_7:10:2000:54': 4.0,
  'mem:15:30000:85': 307.0,
  'ffun:15:40000:54': 189.0,
  'adb:10:15000:76': 37.0,
  'mem_7:10:15000:75': 56.0,
  'mem_7:10:20000:29': 5.0,
  'ffun:15:50000:46': 164.0,
  'mem_40:10:25000:81': 275.0,
  'mem:15:10000:110': 289.0,
  'mem_7:15:50000:49': 44.0,
  'mem_40:15:15000:113': 433.0,
  'mem_40:15:7500:10': 2.0,
  'mem_7:15:25000:42': 14.0,
  'ffun_add:10:2000:110': 151.0,
  'mem_7:10:20000:69': 55.0,
  'adb:15:25000:37': 23.0,
  'mem_7:15:7500:17': 1.0,
  'ffun:15:2000:59': 39.0,
  'adb:10:40000:67': 25.0,
  'ffun:10:10000:85': 288.0,
  'mem_40:10:15000:89': 218.0,
  'mem_7:10:30000:71': 112.0,
  'mem:10:25000:67': 115.0,
  'hcb:15:50:29': 3.0,
  'adb:15:2000:7': 1.0,
  'adb:10:40000:12': 31.0,
  'mem:10:30000:28': 12.0,
  'ffun_add:10:40000:80': 782.0,
  'mem_40:10:30000:116': 865.0,
  'mem_7:15:10000:73': 36.0,
  'ffun_add:10:20000:85': 545.0,
  'mem:15:15000:113': 433.0,
  'mem:10:40000:43': 49.0,
  'ffun:15:25000:3': 40.0,
  'ffun_add:15:25000:110': 1690.0,
  'mem:15:25000:53': 55.0,
  'mem_7:10:30000:70': 83.0,
  'ffun_add:10:2000:63': 24.0,
  'mem:10:30000:36': 24.0,
  'adb:15:40000:40': 36.0,
  'mem:10:20000:57': 60.0,
  'mem:10:30000:4': 8.0,
  'adb:10:30000:48': 59.0,
  'ffun:15:7500:113': 391.0,
  'mem_40:10:10000:22': 4.0,
  'adb:15:20000:26': 13.0,
  'ffun:15:20000:117': 992.0,
  'dis:10:30000:22': 13.0,
  'ffun_add:15:7500:55': 48.0,
  'dis:10:50000:18': 14.0,
  'mem:15:2000:6': 1.0,
  'mem_7:15:20000:15': 4.0,
  'mem_40:10:15000:53': 42.0,
  'hcb:10:100:24': 14.0,
  'mem_40:15:50000:33': 20.0,
  'mem_40:15:10000:95': 192.0,
  'ffun:15:25000:119': 1232.0,
  'ffun:15:20000:8': 38.0,
  'adb:10:20000:1': 16.0,
  'ffun:10:30000:26': 50.0,
  'ffun:15:20000:25': 39.0,
  'adb:15:2000:29': 2.0,
  'ffun:15:10000:27': 36.0,
  'hcb:10:150:40': 32.0,
  'ffun:15:40000:69': 287.0,
  'ffun_add:10:5000:36': 17.0,
  'ffun:10:7500:113': 522.0,
  'mem:10:2000:8': 1.0,
  'mem_7:15:2000:78': 10.0,
  'mem_40:10:40000:56': 120.0,
  'ffun_add:10:2000:92': 104.0,
  'mem_7:10:20000:97': 347.0,
  'adb:15:20000:61': 10.0,
  'mem_7:10:50000:46': 56.0,
  'mem_7:15:2000:37': 1.0,
  'dis:15:30000:51': 12.0,
  'mem:10:30000:111': 865.0,
  'mem_7:10:50000:73': 186.0,
  'mem_7:10:30000:26': 7.0,
  'adb:10:2000:97': 29.0,
  'mem:15:50000:71': 268.0,
  'mem_40:15:15000:54': 34.0,
  'adb:10:2000:65': 2.0,
  'mem_7:10:7500:109': 131.0,
  'mem_40:10:10000:89': 146.0,
  'ffun_add:10:50000:84': 1348.0,
  'mem_40:15:30000:68': 116.0,
  'mem:10:20000:78': 164.0,
  'mem_40:10:25000:65': 82.0,
  'ffun:10:50000:39': 96.0,
  'mem_40:15:20000:17': 5.0,
  'mem_40:10:10000:46': 19.0,
  'ffun:15:7500:61': 64.0,
  'ffun_add:10:2000:3': 8.0,
  'mem_40:10:50000:120': 1444.0,
  'adb:10:15000:66': 10.0,
  'dis:10:50000:58': 22.0,
  'mem_40:15:20000:43': 19.0,
  'ffun_add:10:2000:80': 47.0,
  'ffun_add:15:30000:28': 30.0,
  'dis:15:40000:40': 12.0,
  'ffun:15:20000:109': 992.0,
  'mem_7:15:30000:30': 6.0,
  'ffun_add:15:50000:2': 34.0,
  'adb:15:20000:33': 16.0,
  'ffun_add:10:10000:90': 365.0,
  'adb:15:50000:116': 706.0,
  'mem_40:15:20000:80': 157.0,
  'mem:15:10000:88': 142.0,
  'mem_7:15:40000:76': 188.0,
  'mem_7:15:2000:10': 1.0,
  'adb:15:2000:104': 29.0,
  'mem:10:50000:64': 162.0,
  'ffun_add:10:20000:1': 20.0,
  'adb:10:10000:82': 35.0,
  'adb:15:2000:80': 5.0,
  'adb:15:30000:105': 424.0,
  'mem:10:40000:73': 247.0,
  'ffun:15:30000:23': 44.0,
  'mem:15:7500:22': 2.0,
  'mem_7:10:30000:34': 10.0,
  'mem_7:15:15000:60': 23.0,
  'ffun:15:7500:34': 36.0,
  'ffun_add:10:40000:96': 2824.0,
  'ffun:15:20000:60': 114.0,
  'mem_7:15:50000:35': 12.0,
  'mem_7:10:40000:105': 692.0,
  'adb:15:10000:110': 142.0,
  'ffun:10:10000:26': 38.0,
  'mem:10:25000:42': 31.0,
  'mem:15:40000:113': 1154.0,
  'adb:10:7500:112': 107.0,
  'ffun:10:10000:42': 55.0,
  'mem:15:7500:53': 17.0,
  'ffun:10:5000:15': 33.0,
  'ffun:10:20000:105': 1310.0,
  'mem:15:40000:37': 24.0,
  'ffun:10:5000:117': 363.0,
  'mem:15:40000:73': 214.0,
  'mem_40:15:5000:111': 145.0,
  'dis:10:50000:20': 14.0,
  'ffun_add:10:50000:52': 366.0,
  'adb:10:2000:14': 2.0,
  'adb:15:15000:82': 48.0,
  'adb:15:15000:16': 10.0,
  'mem_7:15:20000:1': 2.0,
  'mem_40:15:2000:57': 6.0,
  'mem:10:10000:3': 4.0,
  'mem:10:15000:23': 5.0,
  'ffun:15:30000:100': 1472.0,
  'mem:15:15000:22': 4.0,
  'adb:10:40000:46': 79.0,
  'mem_40:10:10000:14': 4.0,
  'mem:15:50000:78': 367.0,
  'adb:10:5000:5': 5.0,
  'mem:15:7500:65': 20.0,
  'mem:10:15000:55': 42.0,
  'mem:10:15000:56': 46.0,
  'mem:15:15000:14': 4.0,
  'ffun_add:10:5000:92': 245.0,
  'ffun_add:10:25000:45': 86.0,
  'dis:10:50000:24': 14.0,
  'ffun:15:15000:25': 37.0,
  'mem_40:15:30000:5': 6.0,
  'ffun_add:10:25000:4': 23.0,
  'ffun:15:20000:86': 499.0,
  'mem:10:10000:40': 8.0,
  'ffun:15:5000:69': 62.0,
  'adb:10:50000:103': 706.0,
  'ffun:10:25000:19': 43.0,
  'mem_40:15:15000:104': 433.0,
  'mem_7:10:5000:57': 10.0,
  'ffun:15:50000:51': 229.0,
  'mem_40:15:40000:101': 1154.0,
  'ffun_add:15:15000:4': 14.0,
  'ffun_add:15:10000:85': 245.0,
  'mem:10:50000:40': 38.0,
  'hcb:15:80:24': 7.0,
  'mem_40:10:5000:114': 145.0,
  'mem_7:15:10000:102': 174.0,
  'mem_40:10:15000:24': 5.0,
  'ffun:10:5000:4': 33.0,
  'adb:15:25000:70': 12.0,
  'ffun_add:10:25000:0': 23.0,
  'mem_7:15:30000:50': 26.0,
  'mem:10:50000:44': 61.0,
  'ffun:15:20000:114': 992.0,
  'ffun:15:25000:111': 1232.0,
  'mem_40:15:7500:19': 2.0,
  'ffun_add:15:40000:13': 28.0,
  'ffun:10:40000:117': 2574.0,
  'mem_40:15:40000:67': 155.0,
  'ffun:10:10000:82': 288.0,
  'mem_40:10:15000:115': 433.0,
  'ffun:15:7500:32': 36.0,
  'ffun:10:30000:18': 45.0,
  'mem:15:5000:110': 145.0,
  'mem:15:20000:79': 148.0,
  'ffun:15:10000:106': 511.0,
  'mem:15:50000:7': 11.0,
  'ffun_add:10:25000:72': 374.0,
  'ffun:10:7500:103': 522.0,
  'adb:10:2000:18': 2.0,
  'ffun_add:15:30000:6': 23.0,
  'mem_40:10:7500:92': 145.0,
  'adb:15:5000:100': 71.0,
  'hcb:10:150:56': 31.0,
  'mem_7:10:2000:102': 35.0,
  'ffun_add:15:15000:116': 1017.0,
  'ffun:10:15000:7': 38.0,
  'ffun_add:15:15000:94': 675.0,
  'ffun_add:15:40000:34': 48.0,
  'ffun:15:20000:91': 667.0,
  'ffun_add:15:20000:84': 485.0,
  'ffun:15:2000:114': 126.0,
  'mem:15:30000:111': 865.0,
  'ffun:15:7500:47': 50.0,
  'ffun_add:15:40000:43': 104.0,
  'mem_40:10:15000:42': 19.0,
  'mem_7:10:25000:65': 49.0,
  'mem_7:10:25000:100': 433.0,
  'ffun:10:20000:103': 1310.0,
  'mem:10:5000:52': 14.0,
  'adb:15:2000:22': 1.0,
  'ffun:10:30000:21': 47.0,
  'mem_7:10:7500:18': 2.0,
  'mem:15:40000:67': 155.0,
  'ffun_add:15:50000:19': 35.0,
  'mem:10:40000:68': 184.0,
  'adb:10:30000:88': 150.0,
  'dis:15:20000:51': 11.0,
  'adb:10:40000:27': 32.0,
  'ffun_add:15:20000:79': 349.0,
  'ffun:15:7500:3': 33.0,
  'ffun_add:10:15000:18': 17.0,
  'ffun_add:10:25000:64': 199.0,
  'mem_40:10:20000:28': 8.0,
  'mem:15:10000:27': 4.0,
  'ffun_add:10:50000:104': 3527.0,
  'ffun:15:30000:53': 149.0,
  'mem:15:50000:115': 1444.0,
  'mem:15:15000:47': 23.0,
  'mem:10:7500:80': 62.0,
  'ffun:10:25000:52': 177.0,
  'adb:10:20000:111': 282.0,
  'adb:15:5000:103': 71.0,
  'mem_40:10:50000:79': 409.0,
  'ffun_add:15:2000:20': 7.0,
  'mem_7:10:25000:101': 433.0,
  'ffun:10:40000:42': 119.0,
  'mem:15:25000:104': 722.0,
  'mem:15:15000:15': 4.0,
  'adb:10:30000:11': 23.0,
  'mem:10:50000:84': 550.0,
  'ffun:15:7500:105': 391.0,
  'adb:10:20000:29': 17.0,
  'mem_40:15:50000:32': 20.0,
  'mem_40:10:7500:56': 23.0,
  'mem_7:10:5000:55': 8.0,
  'mem_7:15:30000:0': 4.0,
  'ffun:10:20000:77': 339.0,
  'mem:15:25000:116': 722.0,
  'mem_7:15:40000:37': 14.0,
  'mem_40:10:15000:31': 8.0,
  'mem_40:15:7500:27': 2.0,
  'ffun_add:10:7500:111': 538.0,
  'mem_40:10:25000:109': 722.0,
  'mem_7:10:15000:26': 4.0,
  'mem_40:15:50000:19': 11.0,
  'mem:10:5000:6': 2.0,
  'mem:15:30000:44': 29.0,
  'ffun_add:10:40000:110': 2824.0,
  'mem_40:15:7500:114': 217.0,
  'adb:10:30000:89': 150.0,
  'mem_40:10:25000:33': 13.0,
  'ffun:10:40000:104': 2574.0,
  'ffun_add:15:40000:46': 156.0,
  'adb:15:30000:14': 18.0,
  'ffun:10:7500:120': 522.0,
  'mem:15:20000:86': 282.0,
  'ffun:15:5000:94': 189.0,
  'mem_7:10:20000:1': 4.0,
  'mem_40:10:25000:77': 205.0,
  'ffun:10:15000:0': 38.0,
  'mem:15:2000:78': 16.0,
  'ffun:15:20000:93': 667.0,
  'mem_40:15:15000:34': 6.0,
  'mem_40:10:7500:54': 22.0,
  'mem_40:15:20000:20': 5.0,
  'mem:15:50000:99': 1444.0,
  'mem:15:15000:72': 80.0,
  'mem:10:50000:80': 409.0,
  'hcb:15:50:59': 3.0,
  'ffun_add:10:7500:98': 538.0,
  'mem_40:15:7500:8': 2.0,
  'mem_40:15:40000:71': 233.0,
  'mem:15:10000:3': 2.0,
  'ffun:10:50000:66': 460.0,
  'ffun:15:7500:87': 206.0,
  'adb:10:5000:13': 5.0,
  'ffun_add:15:10000:84': 245.0,
  'ffun:15:10000:7': 34.0,
  'adb:15:10000:21': 6.0,
  'adb:10:10000:15': 8.0,
  'adb:15:2000:16': 1.0,
  'ffun_add:15:5000:115': 343.0,
  'hcb:10:150:52': 33.0,
  'ffun_add:15:15000:60': 94.0,
  'ffun_add:10:10000:111': 713.0,
  'mem:15:25000:97': 722.0,
  'mem_7:15:20000:87': 169.0,
  'adb:15:40000:25': 24.0,
  'mem:15:50000:38': 30.0,
  'mem_40:15:2000:116': 59.0,
  'mem_7:10:5000:73': 19.0,
  'mem:10:10000:103': 289.0,
  'ffun_add:15:25000:40': 45.0,
  'ffun:15:5000:99': 271.0,
  'mem_40:15:30000:104': 865.0,
  'mem_7:10:7500:61': 16.0,
  'ffun:15:50000:18': 50.0,
  'mem_7:10:10000:88': 88.0,
  'mem_40:10:7500:61': 25.0,
  'dis:15:40000:58': 17.0,
  'adb:10:25000:115': 353.0,
  'ffun:10:20000:46': 97.0,
  'adb:15:10000:68': 5.0,
  'mem:15:15000:107': 433.0,
  'mem_40:15:50000:5': 11.0,
  'mem_40:15:20000:111': 577.0,
  'mem_40:10:7500:109': 217.0,
  'ffun:10:2000:7': 33.0,
  'mem_40:10:40000:30': 16.0,
  'mem:10:25000:101': 722.0,
  'mem_7:10:50000:48': 56.0,
  'mem_7:15:15000:12': 2.0,
  'ffun_add:15:40000:31': 48.0,
  'ffun:10:25000:116': 1626.0,
  'mem_7:15:15000:41': 10.0,
  'ffun:10:15000:65': 128.0,
  'mem_7:15:5000:32': 1.0,
  'ffun_add:10:30000:1': 27.0,
  'ffun:10:2000:105': 173.0,
  'mem_40:10:50000:15': 13.0,
  'adb:10:30000:94': 226.0,
  'mem_40:15:40000:106': 1154.0,
  'dis:10:40000:29': 14.0,
  'mem:15:5000:71': 28.0,
  'ffun_add:10:7500:103': 538.0,
  'ffun:15:50000:96': 2435.0,
  'ffun_add:15:7500:2': 10.0,
  'adb:10:25000:3': 19.0,
  'adb:10:30000:81': 103.0,
  'ffun:15:50000:9': 49.0,
  'mem_7:10:5000:32': 2.0,
  'mem_40:15:30000:17': 7.0,
  'mem_40:15:2000:58': 6.0,
  'ffun:15:10000:68': 95.0,
  'adb:10:15000:70': 10.0,
  'mem_40:10:2000:75': 13.0,
  'ffun:10:7500:2': 34.0,
  'mem_40:10:15000:106': 433.0,
  'mem:15:5000:29': 2.0,
  'mem_7:15:30000:34': 7.0,
  'ffun_add:15:20000:87': 663.0,
  'ffun_add:10:30000:49': 149.0,
  'mem_40:10:40000:28': 16.0,
  'ffun:15:2000:66': 43.0,
  'mem_7:10:15000:107': 260.0,
  'mem_40:10:20000:15': 6.0,
  'adb:10:2000:17': 2.0,
  'hcb:10:120:54': 21.0,
  'adb:15:40000:72': 44.0,
  'mem_40:15:15000:71': 88.0,
  'mem_40:10:25000:54': 70.0,
  'mem_7:15:2000:30': 1.0,
  'mem_7:15:7500:76': 36.0,
  'mem_40:10:40000:40': 31.0,
  'mem_7:15:50000:100': 866.0,
  'dis:10:30000:26': 13.0,
  'adb:10:40000:28': 32.0,
  'dis:10:30000:19': 13.0,
  'ffun:15:15000:32': 41.0,
  'mem:10:50000:10': 13.0,
  'ffun_add:15:7500:112': 511.0,
  'ffun_add:15:30000:14': 23.0,
  'adb:10:7500:4': 6.0,
  'mem:10:50000:106': 1444.0,
  'ffun_add:15:40000:14': 28.0,
  'ffun_add:15:20000:61': 131.0,
  'mem_7:10:10000:111': 174.0,
  'mem:15:20000:26': 6.0,
  'mem:15:7500:54': 17.0,
  'mem_7:15:15000:16': 2.0,
  'mem:15:25000:77': 184.0,
  'mem_40:10:50000:104': 1444.0,
  'ffun:15:15000:97': 752.0,
  'ffun_add:15:2000:93': 96.0,
  'ffun_add:15:25000:73': 318.0,
  'mem_7:15:15000:52': 20.0,
  'mem_40:10:7500:94': 145.0,
  'mem_40:10:7500:116': 217.0,
  'mem_40:10:7500:28': 4.0,
  'adb:15:10000:54': 23.0,
  'mem_40:15:50000:46': 73.0,
  'mem:15:2000:58': 6.0,
  'mem_7:10:20000:56': 36.0,
  'mem_7:10:10000:27': 2.0,
  'mem_40:15:40000:25': 10.0,
  'dis:10:20000:20': 12.0,
  'mem:15:30000:31': 12.0,
  'adb:10:40000:61': 25.0,
  'mem_40:15:2000:35': 1.0,
  'mem_40:10:50000:51': 139.0,
  'mem:10:5000:91': 97.0,
  'ffun_add:10:30000:36': 66.0,
  'mem_40:15:5000:10': 1.0,
  'mem_40:15:30000:22': 7.0,
  'mem:15:5000:23': 1.0,
  'adb:15:2000:65': 1.0,
  'ffun:10:10000:73': 153.0,
  'ffun:15:7500:75': 97.0,
  'mem:15:20000:119': 577.0,
  'ffun_add:15:10000:119': 679.0,
  'ffun:15:2000:68': 43.0,
  'ffun:15:15000:119': 752.0,
  'mem_40:10:10000:34': 6.0,
  'adb:15:50000:76': 108.0,
  'adb:15:2000:43': 2.0,
  'ffun:10:2000:115': 173.0,
  'mem_40:10:25000:76': 205.0,
  'ffun_add:10:10000:84': 277.0,
  'ffun_add:15:5000:18': 10.0,
  'adb:15:5000:8': 4.0,
  'mem_7:15:2000:114': 35.0,
  'adb:15:7500:55': 17.0,
  'mem_7:10:7500:105': 131.0,
  'adb:10:25000:47': 49.0,
  'mem_40:10:2000:91': 40.0,
  'adb:15:10000:27': 7.0,
  'mem_7:10:7500:112': 131.0,
  'mem:15:50000:95': 956.0,
  'mem_40:10:40000:104': 1154.0,
  'mem_7:10:25000:82': 166.0,
  'mem:15:25000:90': 353.0,
  'adb:10:20000:68': 13.0,
  'adb:15:30000:32': 23.0,
  'mem:15:20000:38': 12.0,
  'adb:15:25000:99': 353.0,
  'adb:15:25000:107': 353.0,
  'dis:10:20000:19': 12.0,
  'mem_40:15:5000:66': 20.0,
  'mem_7:15:15000:78': 71.0,
  'mem:15:25000:32': 11.0,
  'mem_40:15:10000:86': 142.0,
  'ffun_add:15:15000:69': 142.0,
  'ffun_add:15:7500:103': 511.0,
  'mem:10:50000:83': 550.0,
  'ffun_add:10:30000:97': 2118.0,
  'ffun:10:15000:107': 994.0,
  'adb:10:20000:73': 28.0,
  'dis:15:20000:38': 10.0,
  'mem_7:15:25000:99': 433.0,
  'ffun_add:15:30000:67': 276.0,
  'ffun:10:20000:21': 41.0,
  'ffun_add:10:50000:51': 366.0,
  'hcb:10:100:35': 14.0,
  'ffun:10:7500:24': 35.0,
  'ffun:15:25000:80': 336.0,
  'mem:15:10000:42': 10.0,
  'ffun:10:20000:115': 1310.0,
  'adb:15:30000:41': 35.0,
  'ffun_add:10:25000:71': 374.0,
  'ffun:15:15000:49': 70.0,
  'ffun:10:10000:69': 123.0,
  'ffun_add:15:10000:25': 13.0,
  'mem_40:10:40000:26': 16.0,
  'ffun:10:7500:110': 522.0,
  'mem_7:15:40000:91': 460.0,
  'mem:15:50000:17': 11.0,
  'ffun:10:5000:36': 39.0,
  'adb:15:7500:66': 4.0,
  'mem:15:2000:110': 59.0,
  'ffun_add:15:25000:56': 153.0,
  'ffun_add:15:30000:102': 2025.0,
  'mem_40:15:15000:49': 23.0,
  'ffun_add:10:10000:97': 713.0,
  'ffun_add:10:50000:76': 976.0,
  'ffun:10:25000:22': 44.0,
  'mem:15:25000:21': 6.0,
  'mem_40:15:7500:35': 4.0,
  'mem_40:15:5000:43': 5.0,
  'ffun:10:5000:31': 36.0,
  'adb:15:20000:78': 43.0,
  'mem_7:15:5000:51': 7.0,
  'ffun_add:15:7500:86': 252.0,
  'ffun:10:20000:13': 41.0,
  'mem:15:2000:44': 2.0,
  'ffun_add:15:50000:98': 3373.0,
  'adb:10:5000:27': 5.0,
  'adb:15:7500:18': 5.0,
  'mem_7:10:10000:31': 4.0,
  'hcb:15:80:34': 8.0,
  'adb:10:7500:68': 5.0,
  'ffun:15:40000:85': 713.0,
  'adb:15:15000:109': 212.0,
  'hcb:15:100:44': 12.0,
  'mem_7:15:15000:49': 13.0,
  'mem_7:10:50000:27': 12.0,
  'ffun_add:10:25000:47': 126.0,
  'mem:10:7500:19': 2.0,
  'ffun_add:10:10000:119': 713.0,
  'mem_40:15:5000:44': 5.0,
  'mem_7:10:30000:65': 59.0,
  'mem_40:10:50000:118': 1444.0,
  'mem:10:30000:113': 865.0,
  'mem:10:5000:84': 55.0,
  'adb:15:50000:66': 23.0,
  'mem_7:10:10000:71': 37.0,
  'ffun:10:5000:11': 33.0,
  'ffun_add:10:25000:18': 24.0,
  'ffun:15:25000:82': 457.0,
  'ffun:15:7500:46': 50.0,
  'adb:15:10000:32': 8.0,
  'ffun_add:10:30000:57': 229.0,
  'mem_7:10:40000:3': 6.0,
  'ffun:10:10000:72': 153.0,
  'ffun:15:15000:71': 164.0,
  'hcb:10:100:46': 15.0,
  'mem_40:10:2000:67': 10.0,
  'mem:10:7500:47': 14.0,
  'mem_7:15:20000:23': 4.0,
  'mem_7:15:20000:107': 347.0,
  'ffun_add:10:15000:44': 54.0,
  'mem_7:10:7500:50': 8.0,
  'ffun:10:7500:51': 81.0,
  'adb:15:50000:63': 23.0,
  'ffun_add:15:10000:98': 679.0,
  'mem:15:15000:4': 4.0,
  'mem_7:15:7500:50': 7.0,
  'mem_7:10:50000:16': 8.0,
  'ffun_add:15:2000:19': 7.0,
  'ffun_add:10:15000:4': 17.0,
  'mem_7:10:50000:2': 8.0,
  'mem_40:10:5000:4': 2.0,
  'mem:15:5000:104': 145.0,
  'ffun:10:25000:67': 250.0,
  'adb:10:20000:102': 282.0,
  'ffun_add:15:30000:77': 520.0,
  'dis:10:10000:52': 12.0,
  'adb:10:20000:92': 150.0,
  'ffun_add:10:30000:106': 2118.0,
  'adb:10:5000:120': 71.0,
  'mem_7:10:5000:63': 11.0,
  'ffun:15:7500:51': 60.0,
  'mem:10:15000:91': 289.0,
  'ffun:10:15000:49': 81.0,
  'mem_7:15:5000:103': 88.0,
  'ffun:15:7500:98': 391.0,
  'ffun:10:5000:118': 363.0,
  'hcb:10:120:31': 20.0,
  'mem_40:10:7500:75': 47.0,
  'hcb:15:120:58': 16.0,
  'mem_40:15:7500:25': 2.0,
  'ffun_add:10:30000:20': 28.0,
  'mem:10:2000:64': 7.0,
  'mem:10:10000:66': 47.0,
  'mem_7:15:20000:102': 347.0,
  'ffun_add:15:50000:64': 317.0,
  'mem_7:10:7500:84': 50.0,
  'mem_7:15:40000:7': 5.0,
  'adb:15:15000:7': 10.0,
  'dis:10:40000:24': 13.0,
  'ffun:15:2000:94': 94.0,
  'dis:10:30000:38': 13.0,
  'mem_40:10:20000:80': 164.0,
  'mem_40:10:2000:38': 2.0,
  'mem_7:10:40000:26': 10.0,
  'ffun:15:2000:105': 126.0,
  'ffun:10:30000:99': 1940.0,
  'adb:15:10000:14': 6.0,
  'ffun:10:25000:111': 1626.0,
  'dis:10:10000:49': 11.0,
  'mem_40:15:50000:102': 1444.0,
  'mem:10:15000:46': 29.0,
  'mem:15:10000:8': 2.0,
  'adb:10:30000:87': 150.0,
  'ffun_add:10:40000:102': 2824.0,
  'mem_40:15:5000:4': 1.0,
  'ffun_add:10:20000:43': 69.0,
  'mem_7:10:50000:84': 330.0,
  'mem_40:15:2000:25': 1.0,
  'ffun_add:10:5000:10': 10.0,
  'mem:10:5000:69': 24.0,
  'mem_40:15:2000:5': 1.0,
  'mem:10:40000:117': 1154.0,
  'ffun_add:10:40000:100': 2824.0,
  'ffun:10:50000:64': 334.0,
  'ffun:10:50000:6': 53.0,
  'hcb:15:80:35': 8.0,
  'mem_40:10:5000:110': 145.0,
  'mem:10:25000:3': 7.0,
  'ffun_add:10:40000:9': 34.0,
  'mem_7:10:25000:112': 433.0,
  'ffun:10:15000:44': 66.0,
  'adb:15:50000:24': 30.0,
  'ffun:15:2000:84': 64.0,
  'ffun:15:5000:8': 32.0,
  'ffun_add:15:40000:75': 504.0,
  'mem:10:25000:72': 155.0,
  'mem:15:2000:113': 59.0,
  'ffun:15:40000:120': 1953.0,
  'mem_7:15:15000:96': 260.0,
  'mem_7:10:2000:103': 35.0,
  'mem_40:10:2000:112': 59.0,
  'ffun:15:7500:23': 34.0,
  'mem:15:2000:38': 1.0,
  'adb:15:5000:47': 8.0,
  'mem:15:50000:53': 110.0,
  'mem_40:10:40000:41': 49.0,
  'mem_40:15:50000:77': 394.0,
  'mem:10:40000:14': 11.0,
  'adb:10:5000:109': 71.0,
  'mem_40:10:20000:67': 92.0,
  'mem:10:2000:35': 1.0,
  'ffun:10:15000:50': 81.0,
  'mem:15:10000:90': 142.0,
  'ffun:15:25000:44': 74.0,
  'mem_7:15:20000:104': 347.0,
  'mem:15:10000:115': 289.0,
  'mem_7:10:25000:107': 433.0,
  'hcb:15:150:48': 28.0,
  'ffun:10:25000:17': 43.0,
  'mem_40:15:50000:111': 1444.0,
  'mem_40:10:20000:60': 60.0,
  'mem_7:15:25000:3': 4.0,
  'adb:10:25000:82': 86.0,
  'mem_7:10:25000:1': 5.0,
  'ffun_add:10:20000:38': 46.0,
  'mem:15:30000:28': 10.0,
  'mem:10:15000:117': 433.0,
  'mem_40:10:5000:87': 73.0,
  'mem:10:40000:103': 1154.0,
  'mem_7:10:40000:5': 6.0,
  'mem:15:50000:89': 704.0,
  'ffun:15:25000:60': 135.0,
  'mem_40:15:20000:56': 49.0,
  'ffun_add:15:5000:14': 9.0,
  'mem_7:10:15000:48': 17.0,
  'ffun_add:15:10000:8': 12.0,
  'dis:15:50000:27': 12.0,
  'ffun_add:10:2000:101': 151.0,
  'mem_7:10:50000:114': 866.0,
  'mem:15:40000:53': 88.0,
  'mem_7:15:2000:64': 4.0,
  'mem_40:10:15000:40': 12.0,
  'mem_7:10:25000:58': 46.0,
  'mem_40:10:30000:32': 16.0,
  'dis:15:50000:34': 12.0,
  'ffun_add:10:10000:22': 15.0,
  'mem:15:7500:83': 78.0,
  'adb:15:10000:66': 5.0,
  'mem_40:10:15000:64': 49.0,
  'hcb:15:150:30': 26.0,
  'ffun:10:25000:91': 1102.0,
  'adb:15:15000:13': 10.0,
  'ffun:15:25000:42': 74.0,
  'mem_40:10:2000:109': 59.0,
  'mem:15:5000:103': 145.0,
  'hcb:10:120:22': 19.0,
  'mem_40:10:30000:113': 865.0,
  'mem_7:10:20000:45': 16.0,
  'mem_7:15:2000:119': 35.0,
  'mem:15:15000:39': 10.0,
  'mem:10:50000:79': 409.0,
  'mem:10:7500:108': 217.0,
  'mem_40:10:50000:82': 550.0,
  'adb:10:25000:9': 19.0,
  'mem_7:15:5000:15': 1.0,
  'mem_40:10:5000:59': 16.0,
  'ffun_add:10:40000:37': 85.0,
  'mem_40:10:20000:71': 124.0,
  'ffun:15:20000:1': 38.0,
  'ffun:10:2000:14': 33.0,
  'mem_40:15:7500:98': 217.0,
  'mem_7:10:30000:72': 112.0,
  'mem:15:40000:23': 10.0,
  'ffun_add:15:20000:51': 118.0,
  'mem_7:10:30000:84': 198.0,
  'mem:10:25000:59': 76.0,
  'mem:10:7500:107': 217.0,
  'mem_7:15:2000:54': 4.0,
  'ffun:10:15000:14': 38.0,
  'mem:15:7500:39': 5.0,
  'ffun_add:10:7500:84': 211.0,
  'mem_7:10:20000:109': 347.0,
  'mem_7:10:20000:46': 23.0,
  'ffun_add:10:5000:120': 362.0,
  'adb:15:25000:114': 353.0,
  'ffun:10:15000:36': 51.0,
  'ffun:15:20000:112': 992.0,
  'mem_40:15:7500:55': 17.0,
  'adb:15:5000:29': 4.0,
  'adb:10:5000:22': 5.0,
  'mem:10:15000:67': 70.0,
  'mem_40:15:15000:88': 211.0,
  'ffun_add:15:2000:92': 96.0,
  'mem:15:30000:66': 116.0,
  'mem_40:15:40000:29': 12.0,
  'ffun:15:25000:57': 135.0,
  'mem_7:15:2000:88': 18.0,
  'mem_40:15:50000:75': 290.0,
  'ffun:15:25000:99': 1232.0,
  'mem:10:20000:52': 56.0,
  'ffun_add:10:2000:46': 16.0,
  'hcb:10:120:23': 19.0,
  'hcb:15:150:34': 27.0,
  'mem_7:10:25000:73': 94.0,
  'adb:10:25000:118': 353.0,
  'ffun_add:15:7500:96': 511.0,
  'mem_7:15:10000:5': 1.0,
  'mem:10:7500:32': 5.0,
  'ffun_add:15:25000:17': 21.0,
  'dis:10:10000:19': 11.0,
  'mem_40:10:50000:107': 1444.0,
  'mem_7:10:20000:96': 347.0,
  'ffun_add:15:10000:44': 31.0,
  'mem_40:10:5000:14': 2.0,
  'mem_40:15:15000:10': 4.0,
  'ffun_add:10:10000:118': 713.0,
  'mem_40:15:5000:39': 4.0,
  'mem_40:15:50000:117': 1444.0,
  'ffun:15:40000:63': 208.0,
  'mem:15:5000:66': 20.0,
  'mem:10:25000:94': 482.0,
  'ffun:10:5000:50': 49.0,
  'ffun:15:40000:30': 52.0,
  'mem_40:10:25000:19': 7.0,
  'ffun_add:15:30000:96': 2025.0,
  'mem:10:10000:48': 19.0,
  'ffun:10:7500:87': 286.0,
  'mem_7:15:15000:108': 260.0,
  'adb:10:2000:119': 29.0,
  'mem:10:7500:95': 145.0,
  'mem:10:30000:14': 8.0,
  'mem:15:50000:81': 512.0,
  'mem_7:15:25000:91': 287.0,
  'ffun:10:15000:34': 44.0,
  'hcb:10:50:33': 3.0,
  'mem_40:10:2000:70': 10.0,
  'adb:10:50000:34': 47.0,
  'mem_40:15:50000:65': 133.0,
  'adb:15:30000:95': 223.0,
  'mem:15:7500:63': 20.0,
  'ffun:10:5000:45': 45.0,
  'mem_7:15:20000:39': 7.0,
  'mem:10:15000:85': 166.0,
  'mem:10:40000:23': 13.0,
  'mem_7:15:15000:83': 92.0,
  'ffun_add:10:20000:91': 950.0,
  'adb:10:10000:112': 142.0,
  'mem_7:10:5000:108': 88.0,
  'ffun:10:15000:71': 209.0,
  'mem_7:10:40000:27': 10.0,
  'ffun:15:20000:42': 65.0,
  'ffun:10:40000:109': 2574.0,
  'mem_40:10:10000:40': 8.0,
  'adb:15:30000:3': 18.0,
  'mem_40:15:5000:75': 30.0,
  'adb:15:25000:65': 12.0,
  'ffun:15:25000:86': 617.0,
  'adb:15:20000:66': 10.0,
  'mem:15:2000:92': 38.0,
  'ffun:10:40000:56': 268.0,
  'ffun:10:20000:82': 527.0,
  'mem_40:10:5000:113': 145.0,
  'mem:10:2000:115': 59.0,
  'ffun_add:10:15000:17': 17.0,
  'mem:10:20000:82': 220.0,
  'adb:10:7500:59': 14.0,
  'mem:10:50000:50': 92.0,
  'ffun_add:10:10000:23': 15.0,
  'mem:10:40000:52': 112.0,
  'mem_7:10:7500:14': 1.0,
  'adb:15:30000:56': 46.0,
  'ffun_add:10:5000:35': 13.0,
  'mem_7:15:20000:37': 7.0,
  'adb:10:30000:57': 56.0,
  'ffun:15:10000:84': 201.0,
  'mem_40:15:20000:22': 5.0,
  'ffun_add:15:2000:12': 7.0,
  'adb:10:2000:4': 2.0,
  'mem_40:15:7500:59': 19.0,
  'mem:10:25000:30': 10.0,
  'ffun_add:10:20000:84': 545.0,
  'ffun_add:15:30000:34': 37.0,
  'mem_7:15:50000:90': 422.0,
  'mem_40:15:7500:23': 2.0,
  'mem_7:10:50000:110': 866.0,
  'adb:10:25000:71': 34.0,
  'mem_40:10:7500:81': 83.0,
  'adb:10:30000:3': 23.0,
  'mem:15:7500:94': 144.0,
  'adb:10:30000:1': 23.0,
  'adb:10:20000:6': 16.0,
  'ffun:10:20000:34': 49.0,
  'ffun_add:10:50000:68': 552.0,
  'mem_40:15:50000:23': 12.0,
  'mem:10:25000:104': 722.0,
  'mem:15:10000:71': 54.0,
  'mem_40:15:40000:113': 1154.0,
  'ffun_add:15:30000:94': 1343.0,
  'mem_40:15:10000:0': 2.0,
  'adb:10:7500:52': 22.0,
  'ffun_add:10:30000:65': 237.0,
  'adb:15:30000:97': 424.0,
  'ffun:10:2000:12': 33.0,
  'mem:10:15000:79': 124.0,
  'adb:15:10000:13': 6.0,
  'mem_40:10:25000:18': 7.0,
  'ffun_add:15:20000:31': 27.0,
  'ffun:15:20000:53': 110.0,
  'mem_40:10:7500:2': 2.0,
  'mem_7:15:40000:48': 35.0,
  'dis:15:10000:40': 9.0,
  'adb:10:25000:112': 353.0,
  'mem_7:10:5000:41': 5.0,
  'adb:15:10000:6': 6.0,
  'mem:10:15000:120': 433.0,
  'mem_7:15:7500:1': 1.0,
  'mem_40:10:5000:69': 24.0,
  'hcb:10:80:23': 9.0,
  'hcb:15:100:26': 12.0,
  'mem_7:15:20000:18': 4.0,
  'ffun:10:5000:103': 363.0,
  'mem_7:10:50000:54': 84.0,
  'mem_7:15:7500:61': 12.0,
  'ffun:10:2000:77': 69.0,
  'ffun_add:15:15000:0': 14.0,
  'mem_40:15:30000:52': 66.0,
  'adb:10:40000:20': 31.0,
  'mem:15:50000:29': 14.0,
  'mem_40:15:15000:57': 37.0,
  'mem:10:7500:93': 145.0,
  'ffun_add:15:30000:15': 23.0,
  'adb:15:40000:103': 564.0,
  'mem:15:10000:46': 16.0,
  'ffun:10:15000:23': 40.0,
  'ffun:10:5000:70': 81.0,
  'mem:10:2000:65': 7.0,
  'mem_40:10:5000:101': 145.0,
  'mem_40:15:30000:37': 18.0,
  'adb:10:7500:79': 19.0,
  'ffun_add:10:30000:27': 35.0,
  'ffun:10:25000:115': 1626.0,
  'ffun_add:15:20000:52': 118.0,
  'mem_7:10:15000:100': 260.0,
  'mem_40:10:25000:68': 115.0,
  'mem:10:25000:97': 722.0,
  'ffun:10:2000:56': 51.0,
  'ffun:15:20000:45': 65.0,
  'mem:15:15000:63': 41.0,
  'ffun:15:2000:18': 31.0,
  'mem_7:15:50000:10': 6.0,
  'mem:15:15000:119': 433.0,
  'mem_40:10:40000:94': 772.0,
  'mem_7:10:7500:25': 2.0,
  'mem_7:15:15000:120': 260.0,
  'mem_40:10:20000:111': 577.0,
  'ffun:10:25000:29': 47.0,
  'ffun_add:10:40000:87': 1430.0,
  'adb:15:25000:28': 17.0,
  'ffun_add:15:15000:72': 194.0,
  'ffun:15:7500:107': 391.0,
  'adb:15:5000:90': 25.0,
  'adb:10:15000:118': 212.0,
  'mem_40:15:5000:20': 1.0,
  'mem_40:15:5000:71': 30.0,
  'ffun_add:10:15000:56': 120.0,
  'mem:10:30000:63': 97.0,
  'adb:10:50000:23': 40.0,
  'mem_7:15:30000:65': 48.0,
  'ffun:15:50000:60': 239.0,
  'mem_40:15:50000:7': 11.0,
  'ffun_add:15:40000:49': 156.0,
  'mem:10:20000:95': 386.0,
  'adb:10:20000:30': 17.0,
  'ffun:15:7500:57': 62.0,
  'mem:15:5000:30': 2.0,
  'mem:15:2000:52': 5.0,
  'adb:10:30000:36': 35.0,
  'mem_7:10:15000:49': 17.0,
  'mem_40:10:30000:0': 8.0,
  'ffun_add:15:20000:118': 1353.0,
  'adb:10:2000:59': 5.0,
  'ffun_add:10:15000:110': 1065.0,
  'mem_40:15:40000:112': 1154.0,
  'mem_40:10:40000:34': 20.0,
  'adb:15:5000:5': 4.0,
  'mem_7:10:50000:116': 866.0,
  'hcb:10:80:22': 9.0,
  'ffun_add:15:2000:1': 7.0,
  'ffun:15:15000:67': 127.0,
  'mem:10:50000:53': 139.0,
  'mem:10:2000:80': 17.0,
  'ffun:10:15000:73': 209.0,
  'mem_40:15:50000:73': 290.0,
  'ffun:15:25000:55': 130.0,
  'hcb:15:50:46': 3.0,
  'mem:15:20000:81': 205.0,
  'dis:10:10000:29': 11.0,
  'ffun_add:10:2000:108': 151.0,
  'mem_40:15:5000:38': 4.0,
  'mem_7:15:15000:85': 92.0,
  'ffun_add:10:25000:88': 897.0,
  'mem_40:15:30000:112': 865.0,
  'ffun:10:30000:111': 1940.0,
  'ffun_add:10:10000:19': 14.0,
  'ffun:10:15000:92': 681.0,
  'mem:10:20000:39': 16.0,
  'mem_40:10:10000:97': 289.0,
  'ffun_add:10:15000:26': 21.0,
  'ffun_add:15:40000:87': 1319.0,
  'ffun_add:10:7500:6': 12.0,
  'mem_40:15:40000:62': 107.0,
  'ffun:15:5000:48': 44.0,
  'mem_40:15:7500:73': 44.0,
  'mem_40:10:30000:90': 437.0,
  'mem_7:10:15000:102': 260.0,
  'mem_7:15:15000:37': 6.0,
  'mem_40:15:2000:104': 59.0,
  'mem_7:15:50000:67': 116.0,
  'ffun_add:10:15000:15': 17.0,
  'ffun_add:15:15000:68': 142.0,
  'mem:15:40000:98': 1154.0,
  'ffun_add:15:40000:100': 2698.0,
  'mem_7:15:30000:31': 7.0,
  'mem_40:15:20000:11': 5.0,
  'ffun:10:7500:81': 227.0,
  'adb:10:10000:34': 10.0,
  'mem:15:20000:17': 5.0,
  'mem_40:10:40000:63': 130.0,
  'ffun:15:25000:51': 130.0,
  'ffun:15:15000:117': 752.0,
  'mem_7:15:25000:68': 59.0,
  'mem_40:15:2000:84': 22.0,
  'ffun:10:30000:1': 44.0,
  'ffun:15:10000:118': 511.0,
  'ffun:15:2000:5': 31.0,
  'mem_40:15:10000:68': 40.0,
  'mem_40:10:20000:10': 6.0,
  'mem_7:15:40000:113': 692.0,
  'adb:10:20000:17': 16.0,
  'hcb:15:100:58': 11.0,
  'ffun_add:10:20000:4': 20.0,
  'mem_7:10:30000:104': 520.0,
  'ffun_add:10:10000:54': 80.0,
  'adb:15:2000:33': 2.0,
  'adb:15:40000:76': 86.0,
  'mem:15:2000:37': 1.0,
  'mem_7:10:5000:99': 88.0,
  'mem:15:2000:4': 1.0,
  'mem_40:15:10000:27': 4.0,
  'mem_40:15:40000:117': 1154.0,
  'ffun:15:20000:82': 372.0,
  'ffun_add:10:5000:116': 362.0,
  'mem_40:10:25000:28': 10.0,
  'mem:10:40000:69': 184.0,
  'adb:10:40000:40': 46.0,
  'mem_7:10:50000:9': 8.0,
  'mem_40:15:50000:97': 1444.0,
  'adb:10:7500:54': 22.0,
  'mem_40:15:40000:40': 24.0,
  'mem:15:5000:21': 1.0,
  'ffun:15:20000:19': 38.0,
  'mem:15:50000:4': 11.0,
  'ffun_add:15:25000:45': 68.0,
  'mem_7:15:50000:96': 866.0,
  'mem:10:2000:81': 23.0,
  'adb:10:7500:34': 7.0,
  'adb:10:40000:31': 37.0,
  'mem_40:15:25000:40': 16.0,
  'ffun_add:10:20000:112': 1416.0,
  'ffun:15:2000:89': 77.0,
  'ffun_add:10:25000:86': 897.0,
  'mem:15:25000:5': 6.0,
  'adb:10:5000:54': 14.0,
  'adb:15:40000:54': 88.0,
  'ffun_add:15:25000:21': 23.0,
  'ffun:10:15000:58': 125.0,
  'ffun_add:15:25000:54': 146.0,
  'ffun:10:2000:53': 51.0,
  'ffun:15:5000:45': 39.0,
  'mem_7:15:40000:8': 5.0,
  'adb:10:40000:1': 31.0,
  'adb:10:5000:97': 71.0,
  'ffun_add:10:15000:106': 1065.0,
  'ffun:15:50000:15': 50.0,
  'mem_40:15:15000:25': 4.0,
  'mem_7:15:7500:83': 47.0,
  'ffun_add:15:30000:85': 723.0,
  'ffun_add:10:25000:43': 86.0,
  'adb:15:2000:92': 16.0,
  'mem:15:30000:49': 43.0,
  'adb:10:20000:90': 101.0,
  'ffun_add:15:15000:37': 30.0,
  'mem_7:15:15000:71': 53.0,
  'mem_7:10:30000:119': 520.0,
  'ffun:15:5000:6': 32.0,
  'ffun:10:2000:47': 40.0,
  'ffun:15:15000:103': 752.0,
  'ffun_add:15:2000:68': 24.0,
  'adb:15:30000:76': 65.0,
  'mem:10:40000:72': 247.0,
  'mem_40:10:2000:29': 1.0,
  'mem:15:20000:118': 577.0,
  'ffun_add:10:30000:31': 46.0,
  'dis:15:30000:39': 11.0,
  'ffun:15:2000:17': 31.0,
  'mem_40:15:2000:23': 1.0,
  'mem_7:10:15000:112': 260.0,
  'mem_40:10:50000:30': 19.0,
  'mem_40:10:5000:104': 145.0,
  'ffun_add:10:25000:14': 24.0,
  'mem_40:15:40000:110': 1154.0,
  'mem:10:7500:51': 22.0,
  'mem_7:10:5000:88': 44.0,
  'mem_7:15:5000:42': 4.0,
  'mem:15:2000:2': 1.0,
  'adb:10:10000:27': 8.0,
  'hcb:10:120:42': 21.0,
  'mem_40:10:40000:10': 11.0,
  'mem:10:10000:6': 4.0,
  'mem_40:15:20000:36': 12.0,
  'adb:15:40000:109': 564.0,
  'adb:15:20000:116': 282.0,
  'mem_7:15:30000:58': 43.0,
  'ffun_add:15:15000:3': 14.0,
  'ffun_add:10:40000:25': 38.0,
  'adb:15:10000:119': 142.0,
  'mem_40:10:25000:72': 155.0,
  'mem:10:40000:74': 247.0,
  'mem_40:10:10000:24': 4.0,
  'mem_7:15:20000:115': 347.0,
  'mem:15:40000:28': 12.0,
  'mem_40:10:40000:84': 439.0,
  'mem_40:10:40000:93': 772.0,
  'ffun_add:10:2000:21': 9.0,
  'mem_7:10:25000:72': 94.0,
  'ffun_add:10:7500:114': 538.0,
  'ffun:15:7500:49': 50.0,
  'mem_7:10:15000:39': 7.0,
  'mem_40:10:5000:16': 2.0,
  'adb:15:5000:68': 2.0,
  'hcb:10:150:48': 33.0,
  'mem:15:15000:21': 4.0,
  'adb:15:2000:52': 5.0,
  'mem_7:10:25000:42': 19.0,
  'ffun_add:10:15000:113': 1065.0,
  'ffun:15:40000:37': 74.0,
  'ffun_add:15:5000:57': 35.0,
  'mem_40:15:15000:31': 6.0,
  'ffun_add:10:10000:47': 55.0,
  'mem_40:10:30000:24': 10.0,
  'mem_7:10:5000:20': 1.0,
  'hcb:10:80:41': 9.0,
  'ffun:10:20000:42': 77.0,
  'mem_7:15:50000:15': 7.0,
  'ffun_add:10:50000:42': 164.0,
  'adb:10:20000:24': 16.0,
  'ffun:15:50000:38': 85.0,
  'ffun_add:15:15000:42': 44.0,
  'mem_7:15:7500:33': 2.0,
  'ffun_add:15:30000:73': 380.0,
  'mem:15:50000:15': 11.0,
  'mem_40:15:5000:67': 20.0,
  'mem_40:15:50000:119': 1444.0,
  'mem_40:15:10000:45': 10.0,
  'mem:15:10000:114': 289.0,
  'ffun_add:15:15000:1': 14.0,
  'mem_7:10:5000:72': 19.0,
  'adb:15:5000:22': 4.0,
  'adb:15:10000:73': 12.0,
  'mem_40:10:50000:45': 61.0,
  'mem:15:40000:105': 1154.0,
  'ffun_add:10:30000:63': 237.0,
  'ffun:10:5000:60': 68.0,
  'mem_40:10:40000:44': 49.0,
  'mem_7:10:7500:108': 131.0,
  'ffun_add:10:50000:15': 41.0,
  'ffun_add:15:30000:97': 2025.0,
  'ffun_add:10:10000:102': 713.0,
  'mem_7:10:20000:17': 4.0,
  'mem_40:10:20000:47': 37.0,
  'ffun:10:7500:36': 42.0,
  'mem_40:15:7500:113': 217.0,
  'mem:10:5000:43': 7.0,
  'mem_7:10:7500:36': 4.0,
  'ffun_add:10:5000:54': 45.0,
  'mem_40:10:50000:18': 14.0,
  'ffun:10:15000:95': 681.0,
  'mem_40:10:40000:68': 184.0,
  'adb:10:50000:65': 31.0,
  'mem_40:15:30000:80': 236.0,
  'ffun_add:10:5000:75': 82.0,
  'adb:15:20000:75': 23.0,
  'mem_40:15:7500:91': 144.0,
  'ffun_add:15:2000:105': 142.0,
  'adb:15:7500:77': 17.0,
  'ffun_add:15:20000:76': 349.0,
  'mem_40:15:40000:72': 233.0,
  'mem:10:10000:30': 5.0,
  'mem_40:15:20000:4': 5.0,
  'ffun_add:15:40000:16': 30.0,
  'dis:10:40000:37': 14.0,
  'mem_7:15:5000:60': 8.0,
  'ffun_add:15:5000:2': 9.0,
  'adb:10:5000:79': 13.0,
  'ffun_add:15:25000:105': 1690.0,
  'mem_40:10:10000:21': 4.0,
  'mem:15:30000:12': 7.0,
  'adb:10:5000:104': 71.0,
  'adb:10:7500:9': 6.0,
  'ffun_add:15:15000:19': 16.0,
  'mem_40:15:15000:68': 59.0,
  'mem_40:10:7500:6': 2.0,
  'mem_7:10:20000:23': 5.0,
  'adb:15:50000:70': 23.0,
  'adb:10:15000:97': 212.0,
  'ffun:10:2000:3': 33.0,
  'mem_7:10:5000:18': 1.0,
  'mem_40:10:7500:66': 35.0,
  'mem_40:10:30000:66': 138.0,
  'ffun_add:15:7500:16': 11.0,
  'adb:15:10000:20': 6.0,
  'mem_40:10:30000:1': 8.0,
  'ffun:10:25000:66': 250.0,
  'mem_40:10:30000:93': 578.0,
  'adb:15:7500:95': 56.0,
  'mem_40:10:25000:115': 722.0,
  'ffun:15:40000:109': 1953.0,
  'mem_40:15:20000:34': 8.0,
  'mem_7:15:30000:59': 43.0,
  'ffun_add:15:7500:79': 135.0,
  'adb:10:15000:79': 37.0,
  'adb:10:10000:21': 8.0,
  'mem:15:10000:48': 16.0,
  'adb:10:5000:108': 71.0,
  'mem:15:10000:33': 5.0,
  'adb:10:10000:13': 8.0,
  'ffun:10:25000:50': 112.0,
  'mem_40:15:7500:56': 19.0,
  'mem_7:15:30000:29': 6.0,
  'ffun_add:10:50000:113': 3527.0,
  'mem_40:10:5000:40': 5.0,
  'mem_40:15:40000:14': 8.0,
  'dis:10:30000:45': 13.0,
  'mem_7:15:25000:90': 211.0,
  'mem_40:15:20000:94': 383.0,
  'ffun:10:20000:98': 1310.0,
  'mem_7:10:30000:7': 5.0,
  'ffun_add:10:15000:12': 17.0,
  'mem:10:20000:29': 8.0,
  'adb:15:7500:41': 10.0,
  'mem_40:15:30000:113': 865.0,
  'ffun:15:5000:113': 271.0,
  'mem:15:20000:20': 5.0,
  'ffun_add:15:40000:70': 366.0,
  'ffun_add:15:7500:48': 34.0,
  'ffun_add:10:40000:105': 2824.0,
  'mem:15:25000:42': 24.0,
  'mem:10:15000:97': 433.0,
  'adb:10:10000:108': 142.0,
  'mem_40:15:2000:20': 1.0,
  'mem_7:10:20000:40': 10.0,
  'mem:10:10000:118': 289.0,
  'adb:15:30000:85': 96.0,
  'ffun_add:10:7500:79': 154.0,
  'ffun:10:2000:22': 33.0,
  'ffun:15:40000:67': 287.0,
  'mem:10:30000:37': 24.0,
  'mem:15:40000:24': 10.0,
  'mem_7:15:10000:104': 174.0,
  'adb:15:20000:120': 282.0,
  'dis:10:50000:56': 22.0,
  'ffun:10:30000:55': 205.0,
  'mem_7:10:5000:60': 10.0,
  'adb:10:5000:51': 14.0,
  'ffun_add:10:10000:51': 80.0,
  'ffun_add:15:20000:43': 55.0,
  'mem_7:15:10000:26': 2.0,
  'ffun_add:15:30000:36': 52.0,
  'adb:10:15000:89': 76.0,
  'ffun:10:10000:81': 288.0,
  'hcb:10:120:32': 20.0,
  'mem:10:40000:36': 31.0,
  'mem:10:15000:35': 8.0,
  'ffun:15:50000:0': 49.0,
  'mem_40:10:40000:102': 1154.0,
  'adb:10:7500:37': 10.0,
  'ffun:15:15000:14': 36.0,
  'adb:15:25000:52': 55.0,
  'mem:15:2000:51': 5.0,
  'mem:10:2000:77': 17.0,
  'ffun:10:10000:83': 288.0,
  'adb:15:15000:119': 212.0,
  'mem_7:10:20000:25': 5.0,
  'ffun:10:40000:10': 48.0,
  'mem_7:10:15000:20': 4.0,
  'ffun:15:20000:38': 52.0,
  'mem:15:30000:56': 72.0,
  'mem_40:10:40000:9': 11.0,
  'ffun:10:20000:65': 157.0,
  'ffun:15:30000:22': 44.0,
  'ffun:10:25000:90': 843.0,
  'mem:10:25000:113': 722.0,
  'ffun:15:40000:19': 46.0,
  'mem:15:5000:38': 4.0,
  'ffun:10:40000:1': 48.0,
  'adb:10:15000:73': 20.0,
  'mem_7:15:7500:24': 1.0,
  'mem_7:15:50000:60': 73.0,
  'mem:15:40000:116': 1154.0,
  'adb:15:40000:105': 564.0,
  'adb:15:15000:37': 13.0,
  'adb:15:5000:35': 5.0,
  'ffun:15:50000:72': 475.0,
  'mem_7:10:5000:95': 59.0,
  'ffun:10:20000:66': 208.0,
  'ffun_add:15:40000:6': 28.0,
  'ffun:15:2000:32': 32.0,
  'dis:10:10000:37': 11.0,
  'mem:10:10000:20': 4.0,
  'ffun_add:15:7500:63': 54.0,
  'ffun_add:10:20000:17': 21.0,
  'mem:15:7500:49': 12.0,
  'adb:15:20000:59': 30.0,
  'ffun:15:5000:119': 271.0,
  'hcb:10:50:46': 4.0,
  'adb:15:20000:56': 30.0,
  'ffun_add:10:30000:29': 35.0,
  'ffun_add:10:7500:59': 65.0,
  'mem_7:15:25000:78': 119.0,
  'ffun:15:15000:98': 752.0,
  'ffun_add:15:25000:77': 434.0,
  'mem_40:10:50000:1': 13.0,
  'mem_40:10:50000:0': 13.0,
  'adb:15:5000:109': 71.0,
  'mem:10:7500:4': 2.0,
  'adb:15:25000:78': 54.0,
  'ffun_add:10:20000:105': 1416.0,
  'mem_7:10:7500:70': 22.0,
  'dis:15:50000:18': 12.0,
  'mem_40:10:25000:74': 155.0,
  'mem_40:10:40000:111': 1154.0,
  'adb:15:20000:114': 282.0,
  'adb:15:2000:5': 1.0,
  'mem:15:25000:91': 479.0,
  'adb:10:10000:52': 28.0,
  'ffun_add:10:10000:15': 13.0,
  'mem_40:15:10000:54': 23.0,
  'ffun:10:2000:81': 95.0,
  'mem:15:25000:0': 6.0,
  'ffun:10:30000:35': 57.0,
  'mem:15:15000:54': 34.0,
  'mem:15:15000:116': 433.0,
  'mem:10:40000:62': 130.0,
  'ffun_add:10:20000:6': 20.0,
  'ffun_add:10:40000:44': 132.0,
  'adb:15:2000:82': 7.0,
  'dis:10:30000:28': 13.0,
  'mem_40:15:20000:102': 577.0,
  'hcb:15:50:47': 3.0,
  'mem_40:15:30000:45': 29.0,
  'hcb:10:80:24': 9.0,
  'ffun_add:10:15000:40': 37.0,
  'ffun:10:5000:102': 363.0,
  'ffun:15:20000:3': 38.0,
  'mem:15:2000:16': 1.0,
  'ffun_add:10:5000:77': 106.0,
  'ffun:15:30000:50': 110.0,
  'mem_7:15:30000:19': 5.0,
  'ffun:10:20000:14': 41.0,
  'adb:15:25000:66': 12.0,
  'mem:10:7500:119': 217.0,
  'mem_7:10:50000:21': 10.0,
  'ffun:15:30000:105': 1472.0,
  'ffun_add:10:7500:116': 538.0,
  'mem_40:15:30000:32': 12.0,
  'ffun:15:25000:18': 40.0,
  'dis:10:10000:33': 11.0,
  'ffun:10:5000:69': 81.0,
  'adb:15:20000:79': 43.0,
  'mem:15:5000:81': 52.0,
  'ffun_add:10:20000:25': 23.0,
  'mem_40:10:40000:73': 247.0,
  'adb:15:20000:92': 149.0,
  'ffun_add:15:20000:40': 37.0,
  'mem_40:10:25000:35': 13.0,
  'mem:15:15000:120': 433.0,
  'mem_40:10:15000:114': 433.0,
  'mem:15:20000:23': 5.0,
  'ffun:15:2000:52': 38.0,
  'mem_7:10:50000:94': 578.0,
  'mem_40:15:5000:3': 1.0,
  'mem:15:20000:120': 577.0,
  'mem:10:20000:14': 6.0,
  'ffun:10:20000:64': 157.0,
  'mem_7:15:40000:74': 140.0,
  'mem_40:15:2000:117': 59.0,
  'mem_40:15:10000:16': 2.0,
  'adb:10:40000:95': 300.0,
  'mem_7:10:10000:13': 2.0,
  'mem:10:5000:114': 145.0,
  'ffun_add:15:10000:6': 12.0,
  'ffun:15:5000:44': 39.0,
  'ffun_add:10:10000:1': 13.0,
  'ffun:10:50000:60': 326.0,
  'adb:10:30000:13': 23.0,
  'ffun_add:10:10000:115': 713.0,
  'mem_40:15:20000:7': 5.0,
  'mem:15:50000:73': 268.0,
  'adb:10:15000:53': 42.0,
  'mem:15:7500:17': 2.0,
  'ffun_add:10:25000:62': 199.0,
  'mem_40:10:10000:71': 62.0,
  'ffun_add:15:20000:73': 255.0,
  'ffun:15:30000:68': 223.0,
  'mem:10:20000:87': 292.0,
  'mem_40:10:10000:110': 289.0,
  'mem:10:20000:13': 6.0,
  'mem_7:15:30000:109': 520.0,
  'adb:10:10000:39': 12.0,
  'mem:15:15000:117': 433.0,
  'mem_7:15:40000:6': 5.0,
  'ffun_add:15:10000:82': 245.0,
  'mem_7:10:25000:30': 6.0,
  'adb:15:20000:101': 282.0,
  'ffun:10:10000:41': 55.0,
  'mem_40:15:10000:29': 4.0,
  'ffun:10:2000:112': 173.0,
  'ffun:10:30000:51': 205.0,
  'adb:10:7500:81': 26.0,
  'mem_40:15:20000:44': 19.0,
  'mem:15:25000:61': 67.0,
  'adb:15:50000:61': 23.0,
  'mem:15:50000:44': 47.0,
  'mem:15:10000:37': 6.0,
  'mem_40:10:50000:100': 1444.0,
  'hcb:15:100:36': 12.0,
  'mem_40:10:10000:114': 289.0,
  'mem:10:15000:6': 5.0,
  'mem_40:15:2000:89': 29.0,
  'ffun_add:15:15000:8': 14.0,
  'mem:10:7500:62': 25.0,
  'adb:10:10000:41': 16.0,
  'ffun_add:10:40000:35': 59.0,
  'mem:15:30000:64': 80.0,
  'ffun_add:15:5000:49': 26.0,
  'adb:15:30000:39': 26.0,
  'mem:15:7500:101': 217.0,
  'adb:10:20000:95': 150.0,
  'hcb:10:120:25': 19.0,
  'adb:15:40000:38': 36.0,
  'ffun_add:10:25000:59': 194.0,
  'hcb:15:50:48': 3.0,
  'mem_7:10:2000:66': 6.0,
  'adb:10:25000:36': 29.0,
  'mem:10:15000:111': 433.0,
  'mem_40:15:25000:91': 479.0,
  'mem_7:10:2000:69': 6.0,
  'adb:15:7500:92': 56.0,
  'adb:15:10000:31': 8.0,
  'mem:15:15000:23': 4.0,
  'ffun_add:10:5000:26': 12.0,
  'ffun:15:2000:62': 39.0,
  'ffun_add:10:50000:7': 39.0,
  'mem_40:15:7500:80': 60.0,
  'adb:15:15000:52': 34.0,
  'mem_40:15:30000:92': 574.0,
  'mem_40:15:50000:49': 73.0,
  'adb:15:2000:74': 2.0,
  'adb:15:40000:20': 23.0,
  'mem_40:10:10000:80': 83.0,
  'ffun_add:10:50000:88': 1785.0,
  'mem:10:40000:113': 1154.0,
  'ffun_add:15:50000:79': 863.0,
  'mem:15:25000:22': 6.0,
  'mem:10:25000:93': 482.0,
  'adb:15:15000:22': 10.0,
  'mem_40:15:7500:74': 44.0,
  'adb:10:10000:77': 25.0,
  'mem_7:10:5000:120': 88.0,
  'mem_40:15:2000:21': 1.0,
  'ffun:15:7500:30': 34.0,
  'adb:15:10000:76': 22.0,
  'mem_40:10:5000:46': 10.0,
  'mem_7:10:7500:34': 2.0,
  'adb:10:5000:23': 5.0,
  'mem_40:10:50000:24': 16.0,
  'mem_7:10:25000:95': 289.0,
  'adb:10:30000:34': 29.0,
  'ffun:10:7500:29': 36.0,
  'hcb:10:80:57': 9.0,
  'mem_40:15:40000:28': 12.0,
  'mem_7:10:5000:102': 88.0,
  'mem_7:15:2000:56': 4.0,
  'mem_7:15:50000:54': 66.0,
  'mem_7:10:40000:48': 44.0,
  'mem_7:10:25000:106': 433.0,
  'mem:10:30000:72': 186.0,
  'ffun_add:15:10000:103': 679.0,
  'mem:10:5000:1': 2.0,
  'ffun_add:15:50000:103': 3373.0,
  'ffun:15:10000:3': 34.0,
  'ffun:15:30000:99': 1472.0,
  'adb:15:50000:93': 372.0,
  'adb:10:50000:31': 47.0,
  'mem:10:10000:110': 289.0,
  'mem_7:10:30000:94': 347.0,
  'adb:10:7500:13': 6.0,
  'mem_40:10:20000:56': 60.0,
  'mem_7:10:5000:84': 34.0,
  'adb:15:15000:53': 34.0,
  'mem_7:10:30000:80': 148.0,
  'mem_7:15:15000:88': 127.0,
  'adb:15:7500:31': 6.0,
  'mem_7:10:5000:70': 14.0,
  'ffun:10:25000:82': 648.0,
  'dis:15:50000:48': 13.0,
  'hcb:10:150:28': 31.0,
  'mem_40:15:25000:43': 24.0,
  'mem_7:15:15000:74': 53.0,
  'hcb:15:120:48': 18.0,
  'mem_7:10:2000:59': 4.0,
  'adb:10:5000:34': 5.0,
  'ffun:15:10000:101': 511.0,
  'mem_7:10:5000:105': 88.0,
  'mem_40:15:40000:48': 59.0,
  'ffun_add:15:40000:120': 2698.0,
  'mem_40:10:50000:101': 1444.0,
  'mem_7:10:15000:42': 12.0,
  'ffun:10:7500:5': 34.0,
  'ffun:10:20000:106': 1310.0,
  'mem:15:20000:27': 6.0,
  'mem_7:10:50000:30': 12.0,
  'ffun:15:10000:8': 34.0,
  'mem_7:10:40000:43': 30.0,
  'hcb:10:50:31': 3.0,
  'ffun_add:10:5000:107': 362.0,
  'mem:10:15000:70': 70.0,
  'adb:15:5000:54': 12.0,
  'mem:15:5000:63': 14.0,
  'ffun:15:7500:59': 62.0,
  'mem_40:15:15000:73': 88.0,
  'mem_40:10:20000:113': 577.0,
  'mem_7:15:50000:25': 7.0,
  'mem_7:15:5000:36': 2.0,
  'mem_7:15:15000:80': 71.0,
  'ffun_add:10:50000:114': 3527.0,
  'ffun_add:15:50000:39': 83.0,
  'ffun_add:15:40000:2': 28.0,
  'mem_40:10:40000:100': 1154.0,
  'mem:15:10000:25': 2.0,
  'mem_40:15:30000:79': 236.0,
  'ffun:15:20000:68': 159.0,
  'ffun_add:15:2000:36': 10.0,
  'adb:15:40000:10': 23.0,
  'ffun:10:10000:98': 679.0,
  'mem:15:10000:26': 4.0,
  'mem:10:15000:76': 124.0,
  'mem:15:50000:96': 1444.0,
  'mem_40:10:7500:40': 6.0,
  'mem_40:15:20000:37': 12.0,
  'mem_7:15:10000:59': 16.0,
  'hcb:15:80:43': 8.0,
  'mem_40:15:10000:37': 6.0,
  'mem:10:40000:34': 20.0,
  'mem:15:10000:65': 28.0,
  'mem_7:10:30000:91': 347.0,
  'mem_40:10:30000:88': 437.0,
  'hcb:10:100:52': 15.0,
  'mem_40:10:7500:79': 62.0,
  'ffun_add:10:7500:8': 12.0,
  'hcb:15:100:56': 11.0,
  'ffun_add:15:10000:72': 131.0,
  'mem_40:15:30000:70': 116.0,
  'dis:15:10000:53': 10.0,
  'mem_7:15:40000:34': 10.0,
  'hcb:10:80:52': 9.0,
  'mem_7:10:7500:29': 2.0,
  'ffun_add:10:30000:50': 149.0,
  'ffun_add:10:40000:45': 132.0,
  'ffun_add:10:15000:78': 299.0,
  'adb:10:25000:116': 353.0,
  'mem_40:10:10000:27': 5.0,
  'mem:10:2000:28': 1.0,
  'mem_40:15:7500:2': 2.0,
  'ffun:15:30000:111': 1472.0,
  'ffun:15:20000:108': 992.0,
  'mem_40:15:50000:16': 11.0,
  'adb:15:30000:15': 18.0,
  'ffun:10:40000:23': 52.0,
  'mem_40:15:7500:54': 17.0,
  'ffun_add:10:10000:43': 39.0,
  'mem_7:15:40000:93': 460.0,
  'hcb:10:120:36': 20.0,
  'ffun_add:10:30000:94': 1418.0,
  'mem_40:15:7500:21': 2.0,
  'ffun:10:2000:83': 95.0,
  'mem:10:7500:16': 2.0,
  'mem_7:15:15000:1': 2.0,
  'adb:15:20000:118': 282.0,
  'ffun_add:15:15000:78': 264.0,
  'hcb:10:150:23': 30.0,
  'hcb:15:50:38': 3.0,
  'mem_40:10:15000:17': 5.0,
  'mem_40:10:10000:15': 4.0,
  'adb:10:2000:46': 5.0,
  'mem_7:10:5000:25': 1.0,
  'ffun_add:10:5000:39': 17.0,
  'ffun_add:15:20000:117': 1353.0,
  'mem_40:15:5000:12': 1.0,
  'ffun:15:30000:54': 149.0,
  'mem_40:15:15000:53': 34.0,
  'adb:15:5000:58': 8.0,
  'mem:10:15000:29': 6.0,
  'ffun_add:15:2000:6': 7.0,
  'ffun_add:15:50000:83': 1202.0,
  'ffun_add:15:30000:105': 2025.0,
  'mem_40:10:20000:108': 577.0,
  'hcb:10:50:36': 4.0,
  'mem_40:10:25000:50': 47.0,
  'adb:15:15000:5': 10.0,
  'mem:10:5000:62': 17.0,
  'ffun_add:15:15000:92': 675.0,
  'dis:10:40000:28': 14.0,
  'ffun:15:20000:49': 84.0,
  'adb:10:50000:30': 41.0,
  'adb:15:5000:112': 71.0,
  'hcb:15:50:42': 3.0,
  'adb:15:30000:89': 145.0,
  'mem_40:15:30000:54': 66.0,
  'ffun_add:10:40000:21': 38.0,
  'mem_40:15:2000:17': 1.0,
  'ffun_add:10:5000:16': 10.0,
  'ffun_add:15:10000:58': 65.0,
  'mem_7:10:25000:57': 46.0,
  'ffun_add:10:50000:44': 164.0,
  'adb:15:50000:34': 37.0,
  'ffun_add:10:10000:80': 202.0,
  'adb:15:5000:20': 4.0,
  'mem_7:10:10000:103': 174.0,
  'dis:10:20000:21': 12.0,
  'mem_40:15:25000:113': 722.0,
  'mem:10:5000:104': 145.0,
  'ffun_add:10:2000:5': 8.0,
  'ffun:10:25000:79': 415.0,
  'ffun:10:7500:69': 102.0,
  'mem:10:10000:18': 4.0,
  'ffun:15:10000:110': 511.0,
  'adb:10:5000:70': 4.0,
  'mem:10:7500:34': 5.0,
  'mem:10:7500:99': 217.0,
  'mem_7:10:30000:33': 10.0,
  'ffun:10:5000:66': 81.0,
  'ffun:10:15000:82': 407.0,
  'mem_40:15:40000:0': 8.0,
  'dis:15:50000:28': 12.0,
  'mem_40:10:25000:48': 47.0,
  'hcb:10:100:21': 14.0,
  'ffun_add:15:30000:81': 723.0,
  'mem:15:30000:108': 865.0,
  'mem_7:10:30000:44': 23.0,
  'adb:15:50000:55': 109.0,
  'mem_7:10:30000:114': 520.0,
  'ffun_add:15:2000:28': 9.0,
  'mem:15:2000:75': 11.0,
  'mem_40:10:40000:57': 120.0,
  'dis:15:20000:19': 10.0,
  'ffun_add:10:5000:57': 46.0,
  'mem:15:25000:119': 722.0,
  'mem_40:10:15000:88': 218.0,
  'ffun_add:10:7500:71': 118.0,
  'adb:15:10000:42': 12.0,
  'mem_7:10:20000:49': 23.0,
  'ffun:15:10000:77': 153.0,
  'adb:10:30000:102': 424.0,
  'adb:10:25000:92': 188.0,
  'ffun:10:10000:99': 679.0,
  'mem_40:10:30000:26': 12.0,
  'ffun_add:15:2000:46': 14.0,
  'mem_40:15:25000:83': 257.0,
  'ffun:15:50000:17': 50.0,
  'adb:15:50000:102': 706.0,
  'ffun_add:15:40000:118': 2698.0,
  'ffun_add:10:2000:23': 9.0,
  'mem_7:10:40000:23': 8.0,
  'ffun:10:25000:47': 112.0,
  'mem:10:5000:120': 145.0,
  'ffun:10:30000:5': 44.0,
  'hcb:15:150:22': 26.0,
  'mem:15:25000:85': 257.0,
  'mem_7:10:20000:93': 232.0,
  'mem_40:15:40000:114': 1154.0,
  'adb:15:40000:60': 60.0,
  'mem:10:5000:100': 145.0,
  'ffun_add:15:50000:69': 457.0,
  'ffun_add:15:25000:97': 1690.0,
  'mem_40:10:50000:112': 1444.0,
  'ffun:15:7500:85': 158.0,
  'ffun:15:25000:62': 141.0,
  'adb:15:10000:25': 6.0,
  'dis:15:20000:50': 10.0,
  'mem:15:10000:17': 2.0,
  'mem_7:10:20000:43': 16.0,
  'mem_7:10:10000:115': 174.0,
  'ffun_add:15:5000:26': 10.0,
  'ffun_add:10:2000:74': 38.0,
  'mem_40:10:20000:7': 6.0,
  'ffun:15:7500:114': 391.0,
  'mem_7:15:30000:36': 11.0,
  'ffun_add:10:30000:79': 588.0,
  'dis:10:10000:45': 11.0,
  'adb:10:5000:16': 5.0,
  'ffun_add:15:30000:72': 380.0,
  'adb:15:25000:101': 353.0,
  'adb:15:30000:71': 34.0,
  'mem_7:15:10000:114': 174.0,
  'mem_7:15:50000:46': 44.0,
  'ffun:15:20000:64': 119.0,
  'adb:15:30000:96': 424.0,
  'ffun:10:10000:108': 679.0,
  'mem_7:15:20000:70': 47.0,
  'mem_7:15:5000:98': 88.0,
  'mem_7:15:20000:25': 4.0,
  'mem_7:15:7500:112': 131.0,
  'dis:10:20000:25': 12.0,
  'adb:15:20000:15': 12.0,
  'mem_7:15:2000:80': 10.0,
  'adb:15:25000:105': 353.0,
  'mem_7:15:5000:67': 12.0,
  'ffun_add:10:25000:37': 56.0,
  'mem_7:10:40000:97': 692.0,
  'mem_40:15:15000:105': 433.0,
  'ffun:15:2000:108': 126.0,
  'ffun_add:10:2000:111': 151.0,
  'ffun_add:15:7500:76': 135.0,
  'ffun:15:40000:22': 48.0,
  'mem_40:10:15000:68': 70.0,
  'mem_7:10:50000:15': 8.0,
  'dis:15:30000:41': 11.0,
  'mem_7:10:50000:117': 866.0,
  'ffun:10:50000:55': 316.0,
  'adb:15:20000:17': 12.0,
  'adb:15:50000:14': 29.0,
  'ffun:15:7500:1': 33.0,
  'mem_7:15:7500:93': 86.0,
  'mem:15:20000:77': 148.0,
  'mem_40:10:5000:56': 16.0,
  'mem_40:15:50000:0': 11.0,
  'adb:15:20000:63': 10.0,
  'mem_40:15:40000:82': 410.0,
  'adb:15:20000:11': 12.0,
  'ffun_add:15:30000:60': 182.0,
  'mem_7:10:15000:27': 4.0,
  'mem:15:15000:38': 10.0,
  'ffun_add:15:10000:113': 679.0,
  'ffun_add:15:30000:117': 2025.0,
  'mem_7:10:2000:56': 4.0,
  'mem_7:15:25000:77': 119.0,
  'adb:10:10000:64': 7.0,
  'mem_7:15:2000:8': 1.0,
  'hcb:10:120:51': 21.0,
  'ffun_add:15:5000:103': 343.0,
  'adb:10:7500:65': 5.0,
  'hcb:15:100:48': 13.0,
  'mem_7:15:10000:58': 16.0,
  'mem:10:30000:110': 865.0,
  'mem_40:10:5000:60': 16.0,
  'mem_40:10:30000:53': 84.0,
  'ffun:15:40000:77': 519.0,
  'mem_40:10:40000:21': 13.0,
  'dis:10:30000:24': 13.0,
  'mem_7:15:40000:39': 14.0,
  'ffun:15:30000:33': 52.0,
  'mem:15:15000:83': 155.0,
  'ffun:15:40000:115': 1953.0,
  'mem_40:10:7500:89': 109.0,
  'mem:15:40000:27': 12.0,
  'adb:15:2000:13': 1.0,
  'mem_7:15:2000:62': 4.0,
  'mem_7:10:40000:66': 110.0,
  'mem_40:15:30000:72': 174.0,
  'adb:10:7500:101': 107.0,
  'adb:10:30000:70': 19.0,
  'ffun_add:15:5000:39': 14.0,
  'mem_7:10:5000:11': 1.0,
  'ffun:10:50000:42': 140.0,
  'ffun:15:5000:62': 52.0,
  'ffun_add:10:25000:61': 199.0,
  'dis:15:20000:29': 10.0,
  'mem_7:10:25000:69': 70.0,
  'hcb:10:100:31': 14.0,
  'adb:15:2000:41': 2.0,
  'mem_7:10:25000:74': 94.0,
  'ffun_add:10:15000:120': 1065.0,
  'adb:10:50000:89': 250.0,
  'ffun_add:15:25000:28': 26.0,
  'mem:15:25000:4': 6.0,
  'mem_40:10:50000:95': 964.0,
  'ffun:10:25000:42': 87.0,
  'mem:15:25000:74': 134.0,
  'adb:10:20000:35': 19.0,
  'ffun:10:25000:31': 53.0,
  'mem_40:15:40000:35': 17.0,
  'hcb:10:80:45': 9.0,
  'mem_40:15:30000:98': 865.0,
  'mem_7:15:2000:40': 1.0,
  'mem_7:15:30000:93': 344.0,
  'mem:10:20000:91': 386.0,
  'mem_40:10:7500:59': 23.0,
  'mem_7:15:25000:21': 4.0,
  'ffun:15:7500:4': 33.0,
  'mem:15:7500:99': 217.0,
  'adb:15:20000:70': 10.0,
  'ffun:15:40000:78': 519.0,
  'adb:15:20000:25': 12.0,
  'mem_40:10:5000:19': 2.0,
  'adb:15:2000:91': 16.0,
  'mem_7:10:15000:46': 17.0,
  'adb:10:20000:67': 13.0,
  'ffun:15:15000:78': 214.0,
  'ffun:10:5000:74': 96.0,
  'ffun_add:10:20000:18': 21.0,
  'mem_40:15:2000:102': 59.0,
  'adb:10:2000:110': 29.0,
  'ffun_add:10:5000:45': 23.0,
  'mem_40:15:40000:44': 38.0,
  'ffun:10:50000:21': 57.0,
  'ffun_add:10:40000:109': 2824.0,
  'ffun:15:10000:42': 48.0,
  'ffun:15:30000:13': 42.0,
  'adb:15:2000:79': 5.0,
  'dis:15:20000:22': 10.0,
  'ffun_add:10:20000:107': 1416.0,
  'mem_7:10:10000:5': 2.0,
  'adb:15:40000:15': 23.0,
  'mem_40:15:5000:21': 1.0,
  'ffun_add:10:30000:82': 811.0,
  'ffun:10:7500:119': 522.0,
  'ffun_add:10:10000:116': 713.0,
  'mem_40:10:30000:110': 865.0,
  'mem_40:15:5000:61': 14.0,
  'ffun:15:25000:9': 40.0,
  'mem:10:50000:99': 1444.0,
  'adb:10:2000:78': 6.0,
  'mem_7:15:10000:33': 2.0,
  'ffun:10:25000:20': 43.0,
  'mem:15:20000:83': 205.0,
  'ffun_add:10:10000:58': 83.0,
  'mem:10:50000:26': 19.0,
  'mem_40:15:50000:21': 12.0,
  'mem_7:15:20000:8': 2.0,
  'adb:15:5000:25': 4.0,
  'adb:10:7500:80': 19.0,
  'ffun_add:10:10000:42': 39.0,
  'adb:10:25000:51': 68.0,
  'adb:10:50000:94': 376.0,
  'mem:10:50000:7': 13.0,
  'ffun:10:50000:19': 55.0,
  'hcb:10:150:24': 30.0,
  'mem:15:50000:45': 47.0,
  'ffun_add:10:2000:2': 8.0,
  'ffun_add:10:5000:65': 46.0,
  'mem_7:10:7500:30': 2.0,
  'mem:10:50000:96': 1444.0,
  'ffun_add:10:5000:85': 144.0,
  'mem_7:15:15000:5': 2.0,
  'mem:15:30000:22': 7.0,
  'mem:15:20000:7': 5.0,
  'mem_40:10:20000:96': 577.0,
  'mem_40:10:20000:118': 577.0,
  'mem:10:50000:38': 38.0,
  'ffun:10:2000:62': 52.0,
  'mem_40:10:25000:110': 722.0,
  'ffun:10:50000:31': 74.0,
  'ffun:15:40000:80': 519.0,
  'adb:15:10000:102': 142.0,
  'adb:10:30000:116': 424.0,
  'ffun:15:40000:94': 1304.0,
  'ffun:10:7500:102': 522.0,
  'mem_40:10:5000:116': 145.0,
  'mem_7:15:50000:39': 18.0,
  'mem_40:10:7500:34': 5.0,
  'mem_7:15:5000:44': 4.0,
  'ffun_add:15:10000:70': 97.0,
  'ffun:10:5000:43': 45.0,
  'ffun_add:15:50000:111': 3373.0,
  'ffun_add:10:5000:58': 46.0,
  'mem_7:10:20000:18': 4.0,
  'mem:10:2000:82': 23.0,
  'ffun:10:5000:65': 69.0,
  'mem_7:15:2000:61': 4.0,
  'ffun_add:10:7500:20': 12.0,
  'hcb:15:150:20': 26.0,
  'mem_7:10:20000:11': 4.0,
  'mem_40:10:7500:26': 4.0,
  'mem:15:15000:7': 4.0,
  'mem_40:10:20000:73': 124.0,
  'mem_7:10:2000:104': 35.0,
  'mem_7:10:2000:85': 13.0,
  'mem_7:15:15000:2': 2.0,
  'mem_40:10:25000:53': 70.0,
  'mem_40:15:7500:49': 12.0,
  'mem_7:10:50000:7': 8.0,
  'mem:15:25000:108': 722.0,
  'ffun:10:20000:22': 41.0,
  'adb:10:15000:18': 12.0,
  'mem:10:7500:56': 23.0,
  'mem_40:15:20000:47': 30.0,
  'ffun:15:10000:86': 265.0,
  'ffun_add:15:20000:60': 124.0,
  'adb:10:15000:120': 212.0,
  'mem_7:15:25000:22': 4.0,
  'mem_7:15:30000:7': 4.0,
  'ffun:15:20000:96': 992.0,
  'mem:10:15000:63': 49.0,
  'ffun_add:10:20000:47': 102.0,
  'mem:10:10000:8': 4.0,
  'mem:15:2000:0': 1.0,
  'dis:15:10000:25': 9.0,
  'mem:10:2000:2': 1.0,
  'mem:15:20000:84': 205.0,
  'mem_7:10:20000:94': 232.0,
  'ffun_add:15:20000:39': 37.0,
  'hcb:15:50:53': 3.0,
  'ffun_add:15:7500:19': 11.0,
  'mem_7:15:15000:75': 53.0,
  'mem_7:15:50000:19': 7.0,
  'mem_40:15:2000:113': 59.0,
  'ffun_add:10:20000:32': 33.0,
  'mem_40:15:30000:75': 174.0,
  'mem:10:15000:22': 5.0,
  'mem:10:20000:40': 16.0,
  'mem:10:2000:87': 30.0,
  'mem:15:10000:1': 2.0,
  'ffun_add:10:25000:81': 679.0,
  'mem_7:15:2000:9': 1.0,
  'ffun_add:10:2000:86': 81.0,
  'hcb:15:100:22': 12.0,
  'mem_40:10:40000:77': 328.0,
  'ffun_add:15:25000:64': 161.0,
  'mem_7:15:50000:47': 44.0,
  'adb:15:20000:77': 43.0,
  'mem_7:15:25000:106': 433.0,
  'mem_7:10:7500:15': 1.0,
  'adb:10:30000:66': 19.0,
  'dis:15:40000:36': 12.0,
  'mem_40:15:2000:14': 1.0,
  'ffun_add:10:30000:111': 2118.0,
  'adb:15:7500:90': 37.0,
  'ffun_add:10:50000:29': 56.0,
  'mem:15:15000:71': 80.0,
  'ffun:15:15000:102': 752.0,
  'mem:15:50000:32': 20.0,
  'mem_7:10:15000:70': 42.0,
  'adb:10:15000:5': 12.0,
  'ffun:15:40000:104': 1953.0,
  'mem:10:15000:82': 166.0,
  'mem_40:10:2000:83': 23.0,
  'ffun_add:10:5000:52': 45.0,
  'mem:10:10000:92': 193.0,
  'hcb:10:120:59': 20.0,
  'mem_7:15:50000:53': 66.0,
  'mem_40:15:25000:64': 67.0,
  'mem_7:15:15000:92': 173.0,
  'ffun_add:15:30000:48': 118.0,
  'ffun:10:40000:47': 160.0,
  'mem_7:10:25000:14': 5.0,
  'mem_40:15:7500:53': 17.0,
  'dis:15:50000:50': 13.0,
  'mem:15:15000:34': 6.0,
  'adb:10:10000:30': 8.0,
  'mem_7:10:15000:104': 260.0,
  'adb:10:2000:118': 29.0,
  'ffun:15:10000:112': 511.0,
  'mem:15:40000:77': 294.0,
  'ffun:10:10000:45': 55.0,
  'ffun:15:5000:50': 44.0,
  'hcb:15:120:25': 17.0,
  'adb:10:10000:115': 142.0,
  'ffun:10:40000:89': 1322.0,
  'ffun_add:15:20000:20': 18.0,
  'ffun:15:40000:79': 519.0,
  'ffun_add:10:50000:39': 106.0,
  'mem_40:10:7500:110': 217.0,
  'mem_40:10:30000:12': 8.0,
  'mem:10:10000:14': 4.0,
  'ffun:15:30000:61': 163.0,
  'mem:10:50000:5': 13.0,
  'hcb:10:120:50': 21.0,
  'mem_7:10:25000:60': 46.0,
  'ffun:10:15000:60': 125.0,
  'ffun_add:10:25000:119': 1769.0,
  'adb:10:15000:28': 13.0,
  'mem_40:10:30000:94': 578.0,
  'ffun_add:15:5000:116': 343.0,
  'ffun_add:10:50000:59': 378.0,
  'ffun_add:15:2000:119': 142.0,
  'mem_7:10:10000:24': 2.0,
  'ffun:10:50000:57': 326.0,
  'ffun_add:10:50000:90': 1785.0,
  'dis:15:30000:59': 16.0,
  'ffun:10:10000:88': 365.0,
  'mem_40:10:2000:6': 1.0,
  'mem:10:50000:55': 139.0,
  'ffun:15:40000:25': 48.0,
  'ffun:10:10000:11': 36.0,
  'ffun:10:30000:23': 47.0,
  'ffun_add:10:10000:100': 713.0,
  'mem_7:10:30000:13': 5.0,
  'mem_7:10:2000:81': 13.0,
  'mem_40:15:2000:51': 5.0,
  'mem_7:10:7500:11': 1.0,
  'ffun_add:15:30000:27': 30.0,
  'mem_7:15:50000:29': 10.0,
  'mem_40:10:40000:49': 74.0,
  'ffun:10:15000:32': 44.0,
  'mem_7:15:50000:4': 6.0,
  'mem_7:10:30000:18': 6.0,
  'ffun_add:10:40000:54': 293.0,
  'mem:10:40000:56': 120.0,
  'hcb:10:50:52': 4.0,
  'ffun:10:5000:73': 96.0,
  'adb:10:20000:91': 150.0,
  'ffun_add:15:7500:46': 34.0,
  'ffun:10:30000:42': 98.0,
  'mem_7:10:40000:53': 67.0,
  'ffun:15:30000:42': 82.0,
  'ffun:15:50000:29': 57.0,
  'mem_40:10:2000:101': 59.0,
  'mem_40:15:2000:30': 1.0,
  'ffun:15:7500:58': 62.0,
  'dis:10:10000:56': 17.0,
  'mem_7:10:30000:59': 54.0,
  'ffun:15:40000:10': 45.0,
  'ffun:15:10000:107': 511.0,
  'mem_7:15:10000:107': 174.0,
  'mem_7:10:40000:45': 30.0,
  'ffun:10:7500:1': 34.0,
  'mem_7:10:10000:48': 12.0,
  'adb:10:5000:58': 10.0,
  'ffun:10:40000:14': 48.0,
  'mem_40:15:10000:58': 25.0,
  'adb:15:7500:17': 5.0,
  'mem_40:15:2000:108': 59.0,
  'ffun:10:40000:101': 2574.0,
  'ffun:10:30000:106': 1940.0,
  'mem:15:50000:55': 110.0,
  'dis:15:50000:23': 12.0,
  'ffun_add:10:25000:107': 1769.0,
  'mem_40:10:30000:21': 10.0,
  'mem:10:5000:106': 145.0,
  'adb:10:50000:72': 67.0,
  'mem_40:15:7500:32': 4.0,
  'mem_40:15:40000:60': 97.0,
  'mem_40:10:40000:37': 31.0,
  'mem:15:2000:119': 59.0,
  'ffun:15:30000:35': 52.0,
  'adb:10:40000:73': 54.0,
  'adb:15:50000:114': 706.0,
  'adb:15:20000:108': 282.0,
  'ffun:15:50000:71': 475.0,
  'adb:15:10000:39': 10.0,
  'mem_40:15:5000:32': 2.0,
  'mem_7:15:20000:72': 71.0,
  'mem_7:15:30000:87': 253.0,
  'ffun:10:5000:76': 114.0,
  'mem:15:50000:11': 11.0,
  'ffun:15:7500:63': 64.0,
  'mem:15:15000:73': 80.0,
  'dis:15:50000:19': 12.0,
  'adb:10:5000:12': 5.0,
  'mem_40:10:40000:29': 16.0,
  'ffun_add:10:15000:67': 171.0,
  'mem_7:15:30000:113': 520.0,
  'ffun_add:15:20000:112': 1353.0,
  'adb:10:25000:67': 16.0,
  'mem:10:30000:51': 84.0,
  'mem:10:20000:94': 386.0,
  'mem_7:15:25000:48': 23.0,
  'mem_40:15:7500:38': 5.0,
  'ffun:10:2000:87': 110.0,
  'ffun:10:30000:40': 71.0,
  'adb:10:50000:56': 95.0,
  'mem_40:10:15000:58': 46.0,
  'ffun:10:50000:18': 55.0,
  'ffun_add:10:30000:101': 2118.0,
  'mem_40:10:2000:12': 1.0,
  'mem_40:15:20000:6': 5.0,
  'ffun_add:15:5000:31': 12.0,
  'ffun:10:7500:17': 35.0,
  'ffun:15:50000:25': 53.0,
  'mem_40:10:25000:21': 8.0,
  'adb:10:40000:9': 31.0,
  'ffun_add:15:40000:5': 28.0,
  'mem_40:10:7500:70': 35.0,
  'ffun_add:15:50000:5': 34.0,
  'mem:10:5000:55': 14.0,
  'mem:10:30000:70': 138.0,
  'adb:10:7500:43': 12.0,
  'dis:10:30000:25': 13.0,
  'ffun_add:10:10000:38': 27.0,
  'mem_40:15:25000:100': 722.0,
  'mem_7:10:5000:80': 25.0,
  'adb:15:2000:10': 1.0,
  'ffun:10:5000:109': 363.0,
  'mem_40:10:5000:118': 145.0,
  'mem_7:10:15000:101': 260.0,
  'ffun_add:10:40000:101': 2824.0,
  'mem:15:5000:39': 4.0,
  'mem_7:15:10000:37': 4.0,
  'mem:15:20000:72': 107.0,
  'adb:15:40000:106': 564.0,
  'mem_7:10:20000:100': 347.0,
  'adb:10:7500:36': 10.0,
  'mem_7:10:30000:102': 520.0,
  'mem:15:15000:84': 155.0,
  'adb:15:2000:8': 1.0,
  'adb:10:2000:21': 2.0,
  'ffun_add:15:10000:34': 17.0,
  'ffun:15:5000:116': 271.0,
  'mem:15:2000:27': 1.0,
  'ffun:15:2000:0': 31.0,
  'mem:10:5000:18': 2.0,
  'ffun:10:2000:61': 52.0,
  'ffun:10:40000:88': 1322.0,
  'ffun_add:10:7500:42': 30.0,
  'mem_7:10:30000:68': 83.0,
  'mem_7:10:7500:63': 16.0,
  'ffun:10:20000:25': 41.0,
  'mem_40:10:25000:64': 82.0,
  'hcb:15:100:20': 12.0,
  'ffun_add:15:7500:50': 34.0,
  'mem_40:10:25000:57': 76.0,
  'adb:10:15000:41': 23.0,
  'mem_40:15:20000:18': 5.0,
  'adb:15:50000:80': 108.0,
  'hcb:15:150:29': 26.0,
  'ffun_add:15:20000:38': 37.0,
  'mem_40:15:15000:59': 37.0,
  'ffun:10:20000:119': 1310.0,
  'ffun:15:40000:0': 46.0,
  'mem_7:15:5000:109': 88.0,
  'mem_40:15:40000:45': 38.0,
  'mem:10:20000:99': 577.0,
  'mem_40:10:40000:38': 31.0,
  'adb:10:50000:79': 122.0,
  'mem_40:10:50000:83': 550.0,
  'ffun:15:20000:52': 110.0,
  'mem_40:15:20000:49': 30.0,
  'mem_7:15:15000:38': 6.0,
  'ffun:10:50000:104': 3205.0,
  'mem_40:10:10000:91': 193.0,
  'ffun:15:2000:7': 31.0,
  'mem_7:15:25000:111': 433.0,
  'mem:10:30000:22': 10.0,
  'mem_7:15:25000:26': 5.0,
  'mem_7:15:5000:26': 1.0,
  'mem_7:10:15000:97': 260.0,
  'mem_7:10:50000:44': 37.0,
  'ffun_add:10:50000:5': 39.0,
  'mem_7:15:15000:44': 10.0,
  'ffun_add:10:25000:116': 1769.0,
  'ffun_add:10:7500:4': 12.0,
  'adb:15:25000:104': 353.0,
  'mem_40:10:5000:43': 7.0,
  'mem_7:15:40000:89': 338.0,
  'adb:10:30000:76': 73.0,
  'mem_40:15:10000:79': 79.0,
  'mem:15:7500:120': 217.0,
  'mem:15:15000:70': 59.0,
  'mem:10:40000:76': 328.0,
  'ffun:15:30000:34': 52.0,
  'hcb:15:80:19': 7.0,
  'ffun:10:40000:50': 160.0,
  'ffun:10:50000:83': 1248.0,
  'adb:15:25000:82': 80.0,
  'mem_40:10:7500:99': 217.0,
  'ffun:15:25000:75': 252.0,
  'adb:10:15000:31': 14.0,
  'mem_7:15:7500:53': 11.0,
  'ffun_add:15:30000:0': 23.0,
  'ffun_add:10:2000:95': 104.0,
  'mem_7:10:10000:101': 174.0,
  'mem_7:10:2000:83': 13.0,
  'ffun_add:10:15000:39': 37.0,
  'ffun_add:10:7500:74': 118.0,
  'mem:15:50000:110': 1444.0,
  'mem_7:15:20000:30': 4.0,
  'ffun_add:10:2000:66': 30.0,
  'ffun:15:2000:91': 94.0,
  'mem:15:50000:19': 11.0,
  'adb:10:40000:82': 138.0,
  'mem_40:10:7500:95': 145.0,
  'mem_7:10:30000:25': 6.0,
  'ffun_add:10:20000:73': 300.0,
  'ffun_add:15:25000:85': 604.0,
  'mem_7:10:30000:37': 14.0,
  'ffun_add:15:15000:86': 499.0,
  'mem:15:10000:120': 289.0,
  'ffun_add:15:2000:94': 96.0,
  'dis:15:40000:56': 17.0,
  'ffun:15:15000:34': 41.0,
  'mem:10:2000:59': 6.0,
  'mem_40:15:25000:66': 97.0,
  'mem:15:30000:74': 161.0,
  'hcb:10:80:47': 9.0,
  'mem_7:15:25000:97': 433.0,
  'mem_40:10:30000:79': 246.0,
  'ffun:10:15000:83': 407.0,
  'adb:10:15000:51': 42.0,
  'ffun_add:15:50000:35': 58.0,
  'ffun_add:15:25000:93': 1122.0,
  'ffun_add:10:10000:32': 20.0,
  'mem_40:10:5000:105': 145.0,
  'adb:15:7500:29': 5.0,
  'mem_7:15:25000:116': 433.0,
  'ffun:15:20000:54': 110.0,
  'ffun:15:50000:55': 229.0,
  'adb:10:50000:44': 73.0,
  'mem_7:15:2000:81': 13.0,
  'ffun_add:15:2000:80': 41.0,
  'ffun_add:10:10000:75': 154.0,
  'mem_40:15:10000:83': 103.0,
  'ffun:15:50000:106': 2435.0,
  'ffun:10:25000:18': 43.0,
  'ffun:15:50000:80': 642.0,
  'ffun:10:40000:29': 57.0,
  'adb:10:10000:113': 142.0,
  'ffun_add:10:40000:60': 304.0,
  'ffun:15:40000:7': 45.0,
  'mem_7:15:2000:57': 4.0,
  'ffun_add:15:30000:52': 174.0,
  'mem:15:5000:78': 37.0,
  'mem_7:15:25000:85': 155.0,
  'ffun_add:10:25000:27': 31.0,
  'mem_40:10:2000:31': 1.0,
  'mem_7:15:30000:115': 520.0,
  'adb:10:7500:84': 26.0,
  'ffun_add:15:50000:114': 3373.0,
  'ffun:10:40000:61': 276.0,
  'dis:10:20000:46': 12.0,
  'adb:15:10000:17': 6.0,
  'adb:15:10000:2': 6.0,
  'mem_40:15:50000:78': 394.0,
  'adb:10:30000:26': 25.0,
  'hcb:10:100:33': 14.0,
  'ffun_add:15:10000:62': 69.0,
  'adb:15:2000:1': 1.0,
  'ffun_add:15:40000:72': 504.0,
  'dis:10:30000:21': 13.0,
  'adb:15:15000:90': 73.0,
  'mem_40:15:2000:29': 1.0,
  'mem_40:10:10000:67': 47.0,
  'mem:10:10000:93': 193.0,
  'mem_40:10:7500:65': 25.0,
  'mem:10:10000:51': 29.0,
  'hcb:15:80:18': 7.0,
  'mem_7:10:50000:36': 24.0,
  'dis:15:10000:26': 9.0,
  'mem_7:15:10000:52': 13.0,
  'mem_7:10:30000:38': 14.0,
  'mem:10:25000:85': 275.0,
  'dis:10:40000:27': 14.0,
  'mem:10:5000:10': 2.0,
  'mem_40:10:40000:97': 1154.0,
  'ffun:15:50000:26': 57.0,
  'hcb:15:100:29': 12.0,
  'mem:15:10000:44': 10.0,
  'ffun:15:50000:68': 352.0,
  'mem_7:10:20000:41': 16.0,
  'hcb:10:100:55': 15.0,
  'mem:10:5000:61': 17.0,
  'ffun_add:10:5000:108': 362.0,
  'mem:10:25000:64': 82.0,
  'mem:10:5000:110': 145.0,
  'ffun_add:15:20000:54': 118.0,
  'adb:15:20000:5': 12.0,
  'ffun:15:50000:89': 1203.0,
  'ffun:10:25000:65': 187.0,
  'mem_40:10:40000:120': 1154.0,
  'ffun:15:15000:7': 36.0,
  'mem:15:15000:37': 10.0,
  'mem_40:15:2000:11': 1.0,
  'mem_40:10:25000:60': 76.0,
  'mem_7:15:50000:32': 12.0,
  'adb:10:10000:83': 35.0,
  'hcb:15:50:39': 3.0,
  'mem:10:25000:19': 7.0,
  'mem:15:7500:76': 55.0,
  'mem_40:10:25000:23': 8.0,
  'ffun_add:10:2000:93': 104.0,
  'adb:15:15000:118': 212.0,
  'mem_40:15:25000:8': 6.0,
  'mem:10:2000:78': 17.0,
  'mem:10:25000:44': 31.0,
  'ffun_add:10:15000:117': 1065.0,
  'adb:10:20000:44': 30.0,
  'ffun_add:15:30000:25': 26.0,
  'mem_40:15:15000:97': 433.0,
  'ffun_add:15:20000:116': 1353.0,
  'adb:15:7500:61': 4.0,
  'adb:15:25000:24': 16.0,
  'mem:10:20000:68': 92.0,
  'mem_7:10:2000:0': 1.0,
  'ffun_add:15:10000:56': 65.0,
  'mem_7:15:7500:10': 1.0,
  'mem:15:50000:9': 11.0,
  'ffun:10:40000:74': 492.0,
  'ffun:15:10000:87': 265.0,
  'ffun_add:15:5000:40': 14.0,
  'ffun_add:15:40000:22': 31.0,
  'ffun_add:15:40000:58': 241.0,
  'mem_40:10:40000:0': 11.0,
  'mem_40:10:50000:90': 728.0,
  'ffun:10:30000:96': 1940.0,
  'mem:15:7500:61': 20.0,
  'mem:10:10000:24': 4.0,
  'ffun:10:10000:95': 469.0,
  'mem_40:15:25000:25': 6.0,
  'mem_40:15:20000:26': 6.0,
  'adb:15:7500:73': 8.0,
  'ffun:15:20000:26': 41.0,
  'ffun_add:15:30000:22': 26.0,
  'ffun_add:10:5000:51': 45.0,
  'mem:15:7500:87': 106.0,
  'ffun_add:15:30000:53': 174.0,
  'mem_7:10:10000:80': 49.0,
  'mem_40:10:10000:47': 19.0,
  'mem_40:15:25000:46': 37.0,
  'dis:15:10000:59': 14.0,
  'ffun_add:10:5000:76': 106.0,
  'ffun_add:10:30000:26': 35.0,
  'ffun_add:10:50000:117': 3527.0,
  'mem_40:10:7500:102': 217.0,
  'ffun_add:10:25000:104': 1769.0,
  'mem_7:10:50000:5': 8.0,
  'mem:10:20000:15': 6.0,
  'ffun_add:10:7500:61': 66.0,
  'ffun_add:10:2000:56': 23.0,
  'ffun_add:10:15000:43': 54.0,
  'mem_7:10:10000:90': 88.0,
  'mem:15:20000:100': 577.0,
  'mem_40:10:20000:41': 25.0,
  'hcb:10:50:22': 3.0,
  'hcb:15:80:38': 8.0,
  'mem_7:10:30000:4': 5.0,
  'mem:15:10000:58': 25.0,
  'mem_7:10:15000:44': 12.0,
  'ffun:10:40000:38': 84.0,
  'adb:10:30000:82': 103.0,
  'mem_40:10:50000:77': 409.0,
  'adb:15:30000:1': 18.0,
  'ffun_add:15:50000:20': 35.0,
  'adb:15:50000:25': 30.0,
  'adb:10:50000:20': 38.0,
  'ffun:10:25000:59': 183.0,
  'mem_40:10:50000:63': 162.0,
  'mem_40:15:40000:61': 107.0,
  'mem_40:10:7500:118': 217.0,
  'mem_40:10:20000:88': 292.0,
  'adb:15:30000:108': 424.0,
  'ffun:15:7500:12': 33.0,
  'adb:15:30000:113': 424.0,
  'ffun_add:15:7500:1': 10.0,
  'mem:15:2000:24': 1.0,
  'ffun:10:7500:107': 522.0,
  'mem_40:15:7500:37': 5.0,
  'mem_7:15:7500:89': 64.0,
  'ffun_add:10:25000:92': 1184.0,
  'ffun_add:15:25000:31': 33.0,
  'adb:15:30000:7': 18.0,
  'adb:15:40000:70': 19.0,
  'mem_7:15:7500:39': 4.0,
  'hcb:15:150:46': 28.0,
  'adb:15:7500:82': 24.0,
  'ffun_add:15:25000:51': 146.0,
  'mem_7:15:7500:101': 131.0,
  'adb:10:15000:94': 113.0,
  'dis:15:10000:18': 9.0,
  'mem_7:15:50000:62': 80.0,
  'mem_7:15:30000:75': 104.0,
  'mem_7:15:50000:24': 7.0,
  'mem:15:5000:5': 1.0,
  'ffun_add:15:2000:40': 10.0,
  'mem:10:2000:99': 59.0,
  'mem_7:10:2000:100': 35.0,
  'adb:10:7500:69': 5.0,
  'mem_7:10:2000:39': 1.0,
  'adb:10:5000:113': 71.0,
  'ffun_add:15:30000:31': 37.0,
  'hcb:15:120:42': 18.0,
  'ffun:10:2000:119': 173.0,
  'mem:10:40000:50': 74.0,
  'ffun_add:10:40000:19': 35.0,
  'adb:10:25000:27': 20.0,
  'ffun:10:20000:63': 157.0,
  'mem:15:50000:86': 704.0,
  'ffun:10:10000:43': 55.0,
  'dis:10:50000:49': 16.0,
  'mem_7:15:20000:81': 124.0,
  'mem_7:15:50000:50': 44.0,
  'mem:15:30000:112': 865.0,
  'ffun_add:15:15000:54': 90.0,
  'mem_40:10:25000:52': 70.0,
  'mem_40:15:10000:78': 79.0,
  'mem_7:15:5000:83': 31.0,
  'ffun:10:7500:19': 35.0,
  'mem_7:15:15000:54': 20.0,
  'adb:10:2000:15': 2.0,
  'ffun_add:15:2000:24': 7.0,
  'mem_7:10:2000:77': 11.0,
  'ffun_add:10:25000:57': 194.0,
  'dis:15:40000:30': 12.0,
  'mem:10:2000:42': 4.0,
  'adb:15:5000:55': 12.0,
  'adb:10:20000:93': 150.0,
  'mem:10:15000:10': 5.0,
  'ffun_add:10:5000:11': 10.0,
  'mem_7:15:30000:89': 253.0,
  'mem_40:10:50000:111': 1444.0,
  'adb:15:2000:109': 29.0,
  'ffun:15:10000:90': 265.0,
  'mem_7:10:5000:54': 8.0,
  'ffun_add:15:25000:78': 434.0,
  'ffun_add:15:10000:76': 178.0,
  'ffun:10:2000:118': 173.0,
  'hcb:15:150:42': 28.0,
  'ffun:10:50000:114': 3205.0,
  'mem_40:15:7500:44': 7.0,
  'adb:10:50000:98': 706.0,
  'ffun:10:40000:86': 1322.0,
  'ffun:15:25000:104': 1232.0,
  'ffun_add:10:25000:118': 1769.0,
  'mem_7:10:50000:95': 578.0,
  'ffun:10:40000:32': 66.0,
  'mem_40:10:30000:91': 578.0,
  'mem_40:10:5000:47': 10.0,
  'adb:10:10000:51': 28.0,
  'mem_40:15:10000:82': 103.0,
  'adb:10:40000:51': 110.0,
  'mem_40:15:10000:74': 59.0,
  'ffun:10:2000:2': 33.0,
  'hcb:10:100:34': 14.0,
  'adb:10:10000:74': 14.0,
  'ffun:15:5000:13': 32.0,
  'adb:15:15000:54': 34.0,
  'ffun:15:2000:19': 31.0,
  'ffun_add:10:40000:61': 313.0,
  'mem_40:10:7500:39': 6.0,
  'mem_7:15:25000:62': 41.0,
  'mem_40:15:7500:86': 106.0,
  'ffun:10:10000:75': 153.0,
  'adb:15:7500:20': 5.0,
  'adb:10:40000:86': 200.0,
  'mem_7:15:2000:111': 35.0,
  'adb:15:30000:109': 424.0,
  'ffun:10:20000:109': 1310.0,
  'mem_40:10:40000:81': 439.0,
  'ffun:10:2000:26': 33.0,
  'mem_40:15:15000:91': 287.0,
  'mem:10:50000:28': 19.0,
  'ffun:15:2000:95': 94.0,
  'adb:15:15000:34': 12.0,
  'ffun:15:10000:72': 119.0,
  'mem:10:40000:22': 13.0,
  'ffun_add:10:50000:63': 390.0,
  'adb:10:25000:80': 61.0,
  'mem:10:20000:74': 124.0,
  'ffun:15:15000:75': 164.0,
  'ffun:15:25000:78': 336.0,
  'hcb:10:50:51': 4.0,
  'adb:10:40000:41': 59.0,
  'mem_7:10:40000:16': 7.0,
  'ffun_add:15:40000:97': 2698.0,
  'mem_40:10:40000:109': 1154.0,
  'ffun_add:10:10000:67': 117.0,
  'adb:15:5000:63': 2.0,
  'ffun_add:10:40000:22': 38.0,
  'mem_7:15:20000:119': 347.0,
  'adb:15:20000:43': 23.0,
  'adb:15:5000:79': 11.0,
  'adb:10:7500:91': 56.0,
  'mem_40:10:7500:23': 2.0,
  'mem_7:15:25000:66': 59.0,
  'ffun:15:10000:73': 119.0,
  'mem:10:10000:47': 19.0,
  'ffun:10:10000:46': 65.0,
  'ffun:15:15000:17': 36.0,
  'ffun_add:15:10000:106': 679.0,
  'mem_7:10:20000:57': 36.0,
  'mem_40:10:10000:13': 4.0,
  'ffun:10:25000:15': 42.0,
  'mem:15:10000:84': 103.0,
  'mem_7:10:7500:71': 29.0,
  'ffun:10:5000:106': 363.0,
  'adb:15:30000:78': 65.0,
  'hcb:15:100:55': 12.0,
  'hcb:15:50:27': 3.0,
  'ffun_add:15:15000:15': 14.0,
  'mem_7:10:2000:3': 1.0,
  'mem_40:15:30000:53': 66.0,
  'ffun:15:50000:37': 85.0,
  'mem:15:30000:98': 865.0,
  'mem_40:10:25000:45': 31.0,
  'ffun:15:25000:25': 41.0,
  'adb:10:5000:92': 38.0,
  'mem:15:7500:107': 217.0,
  'ffun:10:40000:107': 2574.0,
  'ffun:15:10000:13': 34.0,
  'mem_40:15:50000:70': 193.0,
  'ffun:10:20000:18': 41.0,
  'ffun_add:10:20000:72': 300.0,
  'adb:15:25000:108': 353.0,
  'mem_7:10:2000:29': 1.0,
  'mem_40:10:7500:42': 10.0,
  'ffun_add:15:10000:49': 44.0,
  'ffun:10:15000:61': 128.0,
  'mem:10:30000:26': 12.0,
  'adb:15:7500:107': 107.0,
  'dis:10:50000:27': 15.0,
  'mem:10:5000:46': 10.0,
  'mem_7:10:5000:10': 1.0,
  'mem_7:15:10000:94': 115.0,
  'ffun:10:20000:89': 684.0,
  'mem:10:25000:120': 722.0,
  'adb:15:30000:84': 96.0,
  'mem_40:10:25000:118': 722.0,
  'ffun_add:15:30000:90': 991.0,
  'mem_40:15:30000:119': 865.0,
  'mem_40:15:7500:66': 30.0,
  'ffun_add:15:20000:9': 17.0,
  'mem_7:15:20000:73': 71.0,
  'ffun_add:10:50000:105': 3527.0,
  'adb:10:7500:42': 12.0,
  'mem:10:15000:72': 94.0,
  'ffun:10:2000:24': 33.0,
  'adb:10:30000:43': 44.0,
  'ffun:15:15000:84': 286.0,
  'mem:10:10000:58': 30.0,
  'ffun:15:10000:53': 70.0,
  'adb:10:7500:100': 107.0,
  'ffun_add:10:40000:71': 593.0,
  'mem_40:10:30000:98': 865.0,
  'mem:15:20000:97': 577.0,
  'mem:10:2000:29': 1.0,
  'ffun_add:15:5000:27': 10.0,
  'ffun_add:15:25000:47': 100.0,
  'mem_40:10:20000:57': 60.0,
  'dis:15:20000:25': 10.0,
  'ffun_add:10:15000:13': 17.0,
  'mem_40:15:5000:64': 14.0,
  'mem_7:10:5000:5': 1.0,
  'mem_7:10:7500:47': 8.0,
  'ffun:10:20000:94': 891.0,
  'ffun:10:50000:3': 53.0,
  'ffun:10:30000:57': 211.0,
  'ffun:15:20000:77': 275.0,
  'mem:15:10000:11': 2.0,
  'mem_7:15:40000:105': 692.0,
  'mem:10:7500:21': 2.0,
  'mem_7:10:2000:27': 1.0,
  'dis:15:30000:44': 11.0,
  'mem_7:15:40000:120': 692.0,
  'dis:15:40000:19': 11.0,
  'mem_7:10:50000:66': 138.0,
  'mem_40:10:15000:92': 289.0,
  'ffun:10:40000:35': 66.0,
  'mem:15:50000:57': 121.0,
  'mem_40:15:50000:57': 121.0,
  'mem:10:7500:31': 5.0,
  'ffun:10:5000:1': 33.0,
  'ffun:10:25000:110': 1626.0,
  'ffun:10:25000:87': 843.0,
  'ffun:15:20000:31': 45.0,
  'ffun:10:10000:117': 679.0,
  'mem_7:10:25000:61': 49.0,
  'adb:15:40000:119': 564.0,
  'ffun_add:15:15000:12': 14.0,
  'adb:15:25000:56': 37.0,
  'mem_40:10:5000:24': 2.0,
  'adb:15:30000:106': 424.0,
  'mem_40:15:25000:77': 197.0,
  'mem:15:30000:110': 865.0,
  'mem_40:10:5000:64': 17.0,
  'ffun:15:7500:24': 34.0,
  'mem_40:10:50000:59': 150.0,
  'mem:15:2000:96': 59.0,
  'ffun:10:20000:85': 527.0,
  'ffun_add:15:7500:104': 511.0,
  'ffun:15:15000:104': 752.0,
  'mem_7:10:50000:108': 866.0,
  'hcb:15:120:59': 16.0,
  'mem:10:25000:119': 722.0,
  'ffun:15:5000:103': 271.0,
  'ffun_add:15:10000:7': 12.0,
  'mem_40:15:25000:84': 257.0,
  'mem_40:15:30000:76': 236.0,
  'mem_7:15:25000:47': 23.0,
  'mem_7:10:15000:10': 2.0,
  'mem_40:10:50000:4': 13.0,
  'mem_40:10:2000:46': 5.0,
  'mem_40:15:25000:71': 145.0,
  'ffun:10:20000:100': 1310.0,
  'mem_7:10:20000:60': 36.0,
  'mem_7:15:50000:112': 866.0,
  'mem:10:20000:10': 6.0,
  'ffun:10:40000:108': 2574.0,
  'ffun:10:10000:20': 36.0,
  'mem:10:10000:111': 289.0,
  'ffun:10:10000:118': 679.0,
  'mem:10:10000:25': 4.0,
  'ffun:10:5000:35': 36.0,
  'ffun_add:10:15000:97': 1065.0,
  'mem_40:10:25000:8': 7.0,
  'ffun:10:2000:93': 131.0,
  'mem_40:15:10000:44': 10.0,
  'adb:10:40000:55': 110.0,
  'ffun:15:30000:113': 1472.0,
  'adb:10:2000:3': 2.0,
  'mem_40:15:40000:89': 563.0,
  'ffun_add:10:2000:104': 151.0,
  'ffun_add:10:5000:29': 12.0,
  'ffun:10:5000:16': 33.0,
  'ffun:15:40000:36': 74.0,
  'mem:15:2000:42': 2.0,
  'mem:10:2000:106': 59.0,
  'ffun:15:20000:51': 110.0,
  'ffun_add:15:2000:69': 24.0,
  'mem:10:25000:61': 82.0,
  'mem:10:10000:60': 30.0,
  'mem_40:10:50000:76': 409.0,
  'ffun:10:5000:29': 35.0,
  'adb:10:5000:64': 4.0,
  'ffun_add:15:40000:65': 255.0,
  'mem:15:2000:107': 59.0,
  'adb:10:50000:33': 47.0,
  'adb:10:50000:70': 31.0,
  'mem:15:2000:29': 1.0,
  'ffun_add:15:50000:107': 3373.0,
  'mem:10:25000:62': 82.0,
  'mem_40:15:40000:1': 8.0,
  'mem_7:10:30000:99': 520.0,
  'ffun_add:15:15000:5': 14.0,
  'adb:15:20000:9': 12.0,
  'mem_7:15:15000:9': 2.0,
  'ffun_add:15:50000:85': 1202.0,
  'mem_7:10:20000:16': 4.0,
  'mem:15:10000:57': 25.0,
  'mem_40:10:40000:96': 1154.0,
  'mem_7:10:7500:89': 66.0,
  'mem_40:10:20000:87': 292.0,
  'hcb:10:150:51': 33.0,
  'adb:10:2000:19': 2.0,
  'mem:15:40000:57': 97.0,
  'adb:15:30000:29': 19.0,
  'mem_40:15:20000:62': 54.0,
  'mem_7:10:10000:16': 2.0,
  'mem_7:15:10000:85': 62.0,
  'ffun_add:15:7500:42': 25.0,
  'ffun_add:15:5000:9': 9.0,
  'mem_7:15:40000:95': 460.0,
  'ffun:10:20000:116': 1310.0,
  'adb:10:30000:65': 19.0,
  'mem_7:15:25000:107': 433.0,
  'ffun:10:30000:59': 211.0,
  'mem:15:5000:34': 2.0,
  'ffun:10:40000:18': 49.0,
  'ffun:10:50000:24': 57.0,
  'dis:10:50000:31': 15.0,
  'ffun_add:15:2000:66': 24.0,
  'mem_7:10:20000:0': 4.0,
  'mem:15:2000:101': 59.0,
  'mem_40:15:7500:101': 217.0,
  'mem_7:10:30000:0': 5.0,
  'mem_7:15:10000:10': 1.0,
  'mem:10:15000:114': 433.0,
  'ffun:15:10000:10': 34.0,
  'ffun:15:15000:70': 127.0,
  'ffun:10:30000:62': 217.0,
  'ffun_add:15:20000:34': 27.0,
  'mem:15:10000:64': 28.0,
  'adb:10:40000:22': 31.0,
  'hcb:10:150:42': 33.0,
  'mem:10:20000:44': 25.0,
  'mem_40:10:20000:34': 11.0,
  'adb:10:5000:75': 7.0,
  'mem_40:10:7500:0': 2.0,
  'mem_7:10:7500:43': 6.0,
  'mem_7:15:7500:58': 12.0,
  'mem_40:15:50000:56': 121.0,
  'mem_40:10:50000:119': 1444.0,
  'mem_40:15:7500:6': 2.0,
  'ffun_add:10:50000:34': 72.0,
  'mem_40:10:30000:38': 24.0,
  'mem_40:10:10000:41': 13.0,
  'ffun_add:15:50000:78': 863.0,
  'adb:10:40000:69': 25.0,
  'ffun:10:50000:118': 3205.0,
  'mem:15:20000:96': 577.0,
  'mem_7:10:30000:107': 520.0,
  'adb:10:50000:5': 38.0,
  'mem:10:10000:119': 289.0,
  'mem_7:15:25000:40': 10.0,
  'ffun_add:10:10000:95': 480.0,
  'mem_40:10:15000:21': 5.0,
  'mem_7:15:10000:24': 2.0,
  'adb:15:25000:27': 17.0,
  'mem:10:15000:5': 5.0,
  'mem:15:30000:47': 43.0,
  'mem:10:2000:94': 40.0,
  'mem:10:20000:36': 16.0,
  'mem:15:5000:11': 1.0,
  'ffun:10:50000:108': 3205.0,
  'mem_40:15:25000:41': 24.0,
  'ffun:10:40000:28': 57.0,
  'mem_40:10:2000:93': 40.0,
  'ffun:10:50000:117': 3205.0,
  'mem_7:10:30000:30': 7.0,
  'ffun_add:15:25000:91': 1122.0,
  'adb:10:15000:108': 212.0,
  'mem_40:10:30000:109': 865.0,
  'mem_7:10:2000:97': 35.0,
  'ffun_add:15:15000:11': 14.0,
  'ffun:10:50000:0': 53.0,
  'adb:15:30000:83': 96.0,
  'mem:15:10000:38': 6.0,
  'ffun:15:20000:97': 992.0,
  'mem_40:15:50000:25': 12.0,
  'mem:15:40000:44': 38.0,
  'mem_40:15:15000:64': 41.0,
  'ffun_add:10:50000:112': 3527.0,
  'mem_7:15:20000:108': 347.0,
  'ffun:15:25000:40': 58.0,
  'mem_40:10:50000:49': 92.0,
  'ffun_add:15:30000:55': 174.0,
  'mem_40:15:25000:68': 97.0,
  'mem_40:15:40000:24': 10.0,
  'ffun:15:7500:18': 33.0,
  'mem:10:30000:81': 329.0,
  'mem_7:15:25000:101': 433.0,
  'ffun_add:10:15000:94': 715.0,
  'mem_7:10:7500:82': 50.0,
  'mem_40:15:10000:12': 2.0,
  'ffun_add:10:25000:83': 679.0,
  'ffun_add:15:25000:4': 20.0,
  'mem_40:10:7500:120': 217.0,
  'adb:10:7500:12': 6.0,
  'mem:15:25000:44': 24.0,
  'ffun:15:15000:113': 752.0,
  'mem_40:15:30000:34': 12.0,
  'mem_7:15:2000:41': 1.0,
  'adb:15:2000:112': 29.0,
  'mem:15:30000:50': 43.0,
  'dis:10:40000:38': 14.0,
  'mem_40:10:25000:96': 722.0,
  'hcb:15:100:35': 12.0,
  'mem:15:7500:20': 2.0,
  'dis:10:50000:41': 15.0,
  'mem:10:30000:11': 8.0,
  'mem:15:20000:50': 30.0,
  'mem_40:15:7500:15': 2.0,
  'mem_7:10:2000:22': 1.0,
  'mem:15:15000:79': 110.0,
  'adb:15:50000:99': 706.0,
  'hcb:15:50:21': 3.0,
  'ffun:10:30000:80': 488.0,
  'adb:10:7500:96': 107.0,
  'mem_7:15:25000:71': 88.0,
  'adb:10:7500:78': 19.0,
  'mem:10:7500:114': 217.0,
  'hcb:15:80:25': 7.0,
  'mem:15:40000:79': 294.0,
  'adb:15:15000:94': 112.0,
  'adb:15:10000:70': 5.0,
  'mem_40:15:5000:87': 71.0,
  'ffun_add:15:5000:79': 93.0,
  'mem_40:15:10000:98': 289.0,
  'mem:10:25000:108': 722.0,
  'ffun:15:50000:39': 85.0,
  'adb:10:40000:94': 300.0,
  'adb:15:2000:23': 1.0,
  'mem:10:30000:7': 8.0,
  'mem_7:15:30000:102': 520.0,
  'mem_7:15:40000:96': 692.0,
  'mem:15:30000:77': 221.0,
  'adb:15:20000:20': 12.0,
  'mem:10:20000:113': 577.0,
  'adb:15:15000:6': 10.0,
  'mem:15:20000:1': 5.0,
  'ffun_add:10:40000:11': 34.0,
  'ffun_add:10:20000:42': 69.0,
  'ffun_add:10:10000:52': 80.0,
  'mem_7:10:15000:98': 260.0,
  'adb:10:50000:29': 41.0,
  'mem_40:15:10000:39': 6.0,
  'ffun:10:10000:86': 365.0,
  'ffun_add:10:40000:70': 442.0,
  'mem_7:10:30000:101': 520.0,
  'ffun:15:30000:84': 542.0,
  'mem_40:10:25000:75': 155.0,
  'adb:10:15000:2': 12.0,
  'ffun:10:2000:54': 51.0,
  'ffun:15:50000:84': 884.0,
  'mem:15:30000:39': 18.0,
  'ffun_add:15:5000:90': 171.0,
  'hcb:15:80:45': 8.0,
  'mem:10:15000:36': 12.0,
  'ffun:10:5000:21': 34.0,
  'mem_40:10:2000:66': 10.0,
  'mem:15:2000:19': 1.0,
  'mem_40:10:15000:103': 433.0,
  'ffun:15:2000:112': 126.0,
  'ffun:15:10000:91': 349.0,
  'adb:10:20000:57': 38.0,
  'mem_7:15:50000:8': 6.0,
  'ffun_add:10:7500:44': 30.0,
  'ffun:10:40000:12': 48.0,
  'mem:15:5000:76': 37.0,
  'mem_40:10:15000:48': 29.0,
  'mem_40:15:50000:48': 73.0,
  'mem_7:10:15000:4': 2.0,
  'adb:15:50000:117': 706.0,
  'mem_40:15:20000:59': 49.0,
  'ffun:15:2000:44': 34.0,
  'adb:10:7500:83': 26.0,
  'mem_7:15:5000:116': 88.0,
  'adb:15:15000:65': 7.0,
  'dis:10:20000:53': 13.0,
  'mem:10:20000:101': 577.0,
  'ffun:15:25000:17': 40.0,
  'ffun_add:15:2000:63': 19.0,
  'ffun_add:15:50000:52': 285.0,
  'adb:10:50000:101': 706.0,
  'ffun_add:15:7500:106': 511.0,
  'dis:15:40000:59': 17.0,
  'adb:10:40000:11': 31.0,
  'mem_7:15:7500:109': 131.0,
  'adb:15:7500:81': 24.0,
  'ffun_add:10:25000:91': 1184.0,
  'ffun:15:10000:59': 72.0,
  'mem_40:10:7500:108': 217.0,
  'ffun_add:10:50000:64': 390.0,
  'mem_40:15:10000:89': 142.0,
  'ffun:10:5000:57': 68.0,
  'ffun:15:20000:100': 992.0,
  'mem:15:10000:119': 289.0,
  'mem_40:15:5000:22': 1.0,
  'ffun:10:30000:11': 45.0,
  'ffun_add:10:7500:38': 22.0,
  'mem:15:7500:21': 2.0,
  'mem_7:10:2000:26': 1.0,
  'adb:10:25000:83': 86.0,
  'mem_40:15:10000:94': 192.0,
  'mem_40:10:10000:72': 62.0,
  'ffun_add:10:40000:74': 593.0,
  'mem_40:15:30000:60': 72.0,
  'mem_7:15:20000:36': 7.0,
  'hcb:10:80:53': 9.0,
  'mem:10:2000:67': 10.0,
  'ffun_add:15:50000:91': 2236.0,
  'ffun_add:10:25000:11': 24.0,
  'ffun:10:10000:54': 95.0,
  'mem:10:10000:68': 47.0,
  'mem_40:10:25000:97': 722.0,
  'hcb:15:100:41': 12.0,
  'adb:10:25000:6': 19.0,
  'mem_40:10:15000:26': 6.0,
  'ffun:15:25000:76': 336.0,
  'dis:15:20000:21': 10.0,
  'adb:15:20000:96': 282.0,
  'ffun:10:30000:31': 57.0,
  'mem:10:25000:43': 31.0,
  'mem_7:15:40000:22': 6.0,
  'mem_7:10:10000:21': 2.0,
  'mem_40:15:15000:108': 433.0,
  'mem_40:15:40000:55': 88.0,
  'mem:15:40000:100': 1154.0,
  'mem_7:15:30000:24': 5.0,
  'mem:10:50000:15': 13.0,
  'ffun:15:15000:4': 36.0,
  'ffun_add:15:25000:6': 20.0,
  'adb:15:5000:78': 11.0,
  'ffun_add:10:10000:94': 480.0,
  'mem:15:10000:76': 74.0,
  'ffun:10:7500:43': 50.0,
  'adb:10:10000:80': 25.0,
  'adb:15:50000:30': 32.0,
  'mem_7:10:20000:99': 347.0,
  'adb:15:5000:98': 71.0,
  'mem_7:10:40000:63': 78.0,
  'adb:15:40000:97': 564.0,
  'ffun:15:15000:27': 38.0,
  'mem_40:10:30000:46': 56.0,
  'mem_40:15:5000:62': 14.0,
  'ffun_add:15:2000:50': 14.0,
  'mem_40:15:10000:99': 289.0,
  'adb:10:10000:66': 7.0,
  'adb:15:10000:36': 10.0,
  'mem_7:15:5000:12': 1.0,
  'mem_7:10:20000:44': 16.0,
  'mem_40:10:2000:89': 30.0,
  'mem:15:40000:89': 563.0,
  'mem:10:25000:99': 722.0,
  'ffun_add:10:2000:73': 38.0,
  'mem_7:15:7500:8': 1.0,
  'ffun_add:15:5000:29': 10.0,
  'ffun_add:15:30000:78': 520.0,
  'ffun_add:10:40000:33': 59.0,
  'ffun_add:15:50000:67': 457.0,
  'adb:10:2000:77': 6.0,
  'mem_40:15:5000:24': 1.0,
  'mem_40:10:10000:111': 289.0,
  'mem:15:25000:76': 184.0,
  'mem:15:2000:118': 59.0,
  'mem_40:15:25000:48': 37.0,
  'mem_7:15:15000:70': 35.0,
  'mem:15:10000:106': 289.0,
  'adb:15:40000:69': 19.0,
  'mem_7:10:20000:8': 4.0,
  'mem_7:10:5000:50': 6.0,
  'ffun_add:15:5000:113': 343.0,
  'mem:10:15000:0': 5.0,
  'mem_7:10:10000:67': 28.0,
  'adb:10:15000:106': 212.0,
  'dis:10:50000:38': 15.0,
  'ffun:15:7500:72': 97.0,
  'mem:10:2000:16': 1.0,
  'ffun:15:7500:93': 269.0,
  'ffun:15:7500:71': 97.0,
  'mem:10:5000:105': 145.0,
  'adb:10:50000:37': 58.0,
  'mem_7:10:15000:110': 260.0,
  'ffun_add:15:25000:52': 146.0,
  'mem_40:15:15000:29': 5.0,
  'mem_7:10:5000:40': 2.0,
  'ffun_add:10:30000:114': 2118.0,
  'mem:10:50000:88': 728.0,
  'mem:10:5000:93': 97.0,
  'ffun:10:2000:82': 95.0,
  'mem_40:15:40000:64': 107.0,
  'mem_40:10:30000:82': 329.0,
  'ffun_add:10:50000:41': 164.0,
  'dis:15:40000:26': 12.0,
  'mem_40:10:7500:14': 2.0,
  'ffun:15:10000:76': 153.0,
  'mem_7:10:40000:89': 350.0,
  'adb:15:15000:51': 34.0,
  'ffun:10:15000:57': 125.0,
  'adb:15:40000:55': 88.0,
  'mem:15:40000:82': 410.0,
  'mem_7:10:10000:34': 4.0,
  'adb:10:10000:18': 8.0,
  'mem_7:10:40000:110': 692.0,
  'ffun:10:20000:70': 208.0,
  'ffun_add:15:5000:105': 343.0,
  'mem:10:40000:1': 11.0,
  'ffun:15:20000:6': 38.0,
  'mem_7:10:50000:12': 8.0,
  'mem:10:40000:97': 1154.0,
  'mem:15:5000:19': 1.0,
  'mem:10:2000:108': 59.0,
  'mem:10:2000:95': 40.0,
  'mem_7:10:7500:110': 131.0,
  'mem_7:10:40000:10': 7.0,
  'adb:15:2000:11': 1.0,
  'mem_7:15:2000:6': 1.0,
  'mem_40:15:15000:9': 4.0,
  'ffun_add:15:40000:105': 2698.0,
  'ffun:10:15000:94': 681.0,
  'adb:10:25000:94': 188.0,
  'ffun_add:15:15000:45': 44.0,
  'adb:10:40000:83': 138.0,
  'mem:10:50000:110': 1444.0,
  'mem_7:10:25000:25': 5.0,
  'hcb:15:50:22': 3.0,
  'ffun_add:15:20000:105': 1353.0,
  'ffun_add:10:5000:61': 46.0,
  'adb:15:7500:109': 107.0,
  'ffun:15:30000:85': 542.0,
  'adb:15:10000:56': 16.0,
  'adb:15:15000:103': 212.0,
  'mem_7:15:2000:27': 1.0,
  'ffun:15:2000:101': 126.0,
  'mem_40:10:5000:99': 145.0,
  'mem_40:15:40000:50': 59.0,
  'mem_7:10:25000:39': 12.0,
  'ffun:15:10000:92': 349.0,
  'ffun_add:10:5000:28': 12.0,
  'mem_40:15:10000:107': 289.0,
  'adb:10:50000:108': 706.0,
  'ffun_add:10:2000:97': 151.0,
  'mem:10:2000:83': 23.0,
  'ffun:15:30000:11': 42.0,
  'mem_7:10:50000:71': 186.0,
  'hcb:15:100:19': 12.0,
  'mem_40:15:2000:86': 29.0,
  'dis:10:30000:34': 13.0,
  'adb:15:15000:85': 48.0,
  'mem_40:10:50000:39': 38.0,
  'ffun_add:10:10000:68': 117.0,
  'mem_40:10:25000:114': 722.0,
  'ffun_add:15:25000:29': 26.0,
  'mem_7:15:25000:7': 4.0,
  'adb:10:2000:99': 29.0,
  'adb:15:40000:4': 23.0,
  'mem_40:15:15000:3': 4.0,
  'ffun_add:15:7500:30': 13.0,
  'mem_7:15:40000:46': 35.0,
  'ffun_add:10:25000:49': 126.0,
  'mem_7:10:2000:11': 1.0,
  'ffun_add:10:10000:89': 365.0,
  'ffun:15:50000:49': 164.0,
  'adb:15:10000:100': 142.0,
  'adb:10:15000:91': 113.0,
  'ffun:15:30000:7': 42.0,
  'mem_40:15:30000:18': 7.0,
  'ffun:15:20000:62': 119.0,
  'ffun_add:15:25000:53': 146.0,
  'mem_7:10:30000:115': 520.0,
  'adb:15:20000:84': 64.0,
  'ffun:15:5000:118': 271.0,
  'hcb:15:50:40': 3.0,
  'mem_40:10:5000:32': 4.0,
  'adb:15:20000:29': 13.0,
  'mem_7:10:15000:53': 25.0,
  'ffun_add:15:5000:41': 19.0,
  'mem_7:15:2000:42': 1.0,
  'ffun_add:15:15000:107': 1017.0,
  'ffun_add:10:50000:26': 56.0,
  'ffun_add:15:30000:62': 192.0,
  'adb:15:10000:12': 6.0,
  'mem:10:25000:17': 7.0,
  'hcb:15:50:30': 3.0,
  'adb:10:7500:7': 6.0,
  'ffun:15:10000:111': 511.0,
  'ffun_add:10:40000:91': 1889.0,
  'ffun_add:15:40000:0': 28.0,
  'adb:10:5000:78': 13.0,
  'mem:15:30000:79': 221.0,
  'ffun_add:10:2000:107': 151.0,
  'ffun_add:15:7500:20': 11.0,
  'ffun_add:15:7500:85': 186.0,
  'mem_40:15:40000:42': 38.0,
  'mem:15:40000:45': 38.0,
  'mem_7:15:50000:74': 175.0,
  'mem:10:10000:19': 4.0,
  'dis:15:40000:29': 12.0,
  'mem_40:15:50000:107': 1444.0,
  'mem_7:10:20000:47': 23.0,
  'adb:10:7500:24': 6.0,
  'mem:10:30000:116': 865.0,
  'mem:15:15000:44': 14.0,
  'ffun:15:7500:20': 33.0,
  'dis:10:30000:23': 13.0,
  'ffun_add:15:25000:18': 21.0,
  'ffun:10:50000:70': 460.0,
  'adb:15:15000:61': 7.0,
  'ffun:10:30000:65': 217.0,
  'mem_40:15:30000:118': 865.0,
  'ffun:15:5000:72': 75.0,
  'mem_40:10:30000:83': 329.0,
  'mem:15:10000:68': 40.0,
  'ffun_add:15:2000:43': 12.0,
  'hcb:15:120:43': 18.0,
  'mem_40:15:5000:0': 1.0,
  'mem_40:15:40000:80': 314.0,
  'mem_40:15:5000:86': 71.0,
  'mem_40:10:15000:79': 124.0,
  'mem_40:10:25000:88': 365.0,
  'mem:10:30000:41': 37.0,
  'mem_40:15:40000:76': 314.0,
  'mem_7:10:50000:58': 90.0,
  'ffun:10:25000:38': 64.0,
  'ffun:10:10000:13': 36.0,
  'ffun_add:15:25000:120': 1690.0,
  'ffun_add:10:25000:32': 39.0,
  'mem_7:15:15000:99': 260.0,
  'ffun_add:15:5000:21': 10.0,
  'hcb:15:80:52': 8.0,
  'mem_40:10:50000:73': 310.0,
  'mem_7:10:5000:78': 25.0,
  'dis:10:50000:43': 15.0,
  'ffun_add:15:5000:23': 10.0,
  'mem_7:15:2000:120': 35.0,
  'mem:10:30000:66': 138.0,
  'mem_40:10:20000:35': 11.0,
  'mem:15:10000:53': 23.0,
  'adb:15:5000:72': 6.0,
  'mem:10:50000:8': 13.0,
  'mem_40:15:50000:30': 14.0,
  'mem_40:15:40000:86': 563.0,
  'dis:15:50000:29': 12.0,
  'ffun:10:25000:4': 42.0,
  'ffun_add:10:30000:71': 446.0,
  'ffun_add:15:15000:67': 142.0,
  'mem:15:30000:82': 307.0,
  'mem:15:2000:36': 1.0,
  'ffun_add:10:50000:1': 39.0,
  'mem:10:2000:89': 30.0,
  'mem_40:10:30000:114': 865.0,
  'ffun:15:40000:31': 60.0,
  'adb:15:25000:53': 55.0,
  'ffun:10:10000:8': 36.0,
  'mem:15:2000:98': 59.0,
  'mem_40:10:40000:95': 772.0,
  'mem_40:15:25000:4': 6.0,
  'dis:10:10000:41': 11.0,
  'adb:10:20000:71': 28.0,
  'mem_40:10:15000:27': 6.0,
  'ffun:10:10000:30': 38.0,
  'mem_7:10:2000:67': 6.0,
  'dis:15:50000:47': 13.0,
  'ffun:10:7500:25': 35.0,
  'mem_7:15:10000:16': 2.0,
  'mem_40:10:2000:52': 6.0,
  'hcb:15:100:27': 12.0,
  'mem_40:10:5000:29': 2.0,
  'adb:10:5000:74': 7.0,
  'hcb:15:100:23': 12.0,
  'mem:15:50000:33': 20.0,
  'mem:10:30000:27': 12.0,
  'mem:15:50000:51': 110.0,
  'mem_40:10:50000:17': 14.0,
  'ffun:10:40000:76': 639.0,
  'hcb:10:80:34': 9.0,
  'mem_40:15:7500:115': 217.0,
  'ffun_add:10:10000:35': 20.0,
  'adb:10:10000:5': 8.0,
  'mem_7:10:15000:93': 174.0,
  'mem:15:15000:60': 37.0,
  'ffun:10:50000:77': 789.0,
  'hcb:15:100:32': 12.0,
  'mem_7:10:10000:113': 174.0,
  'ffun_add:10:20000:110': 1416.0,
  'mem_40:10:50000:62': 162.0,
  'mem_40:10:15000:4': 5.0,
  'dis:15:40000:41': 12.0,
  'ffun_add:10:30000:46': 149.0,
  'adb:15:30000:19': 18.0,
  'mem_7:15:20000:6': 2.0,
  'mem_7:10:40000:0': 6.0,
  'mem_40:15:30000:117': 865.0,
  'mem_7:10:40000:44': 30.0,
  'adb:10:15000:20': 12.0,
  'ffun_add:15:40000:64': 255.0,
  'mem:10:2000:74': 13.0,
  'adb:15:40000:85': 127.0,
  'adb:10:20000:11': 16.0,
  'mem:15:15000:75': 80.0,
  'mem_40:10:7500:53': 22.0,
  'adb:15:40000:77': 86.0,
  'mem_40:15:30000:10': 6.0,
  'mem_40:10:25000:51': 70.0,
  'mem_7:10:15000:80': 74.0,
  'hcb:15:150:55': 28.0,
  'hcb:15:80:36': 8.0,
  'mem_40:10:30000:37': 24.0,
  'ffun:15:30000:102': 1472.0,
  'mem_40:10:5000:0': 2.0,
  'mem:15:25000:111': 722.0,
  'adb:10:10000:32': 10.0,
  'mem:10:30000:92': 578.0,
  'ffun_add:10:5000:4': 10.0,
  'ffun_add:15:15000:111': 1017.0,
  'adb:15:50000:75': 56.0,
  'mem:15:5000:3': 1.0,
  'ffun_add:15:30000:50': 118.0,
  'ffun_add:10:2000:12': 8.0,
  'mem_40:15:5000:27': 2.0,
  'ffun_add:10:2000:103': 151.0,
  'ffun:10:15000:66': 165.0,
  'ffun:10:20000:104': 1310.0,
  'mem:15:25000:40': 16.0,
  'ffun:10:25000:113': 1626.0,
  'mem_40:10:2000:119': 59.0,
  'ffun:10:40000:44': 119.0,
  'mem_40:10:10000:33': 6.0,
  'mem_7:10:5000:111': 88.0,
  'adb:10:2000:23': 2.0,
  'ffun:15:10000:99': 511.0,
  'ffun:15:25000:49': 97.0,
  'mem_40:10:5000:11': 2.0,
  'adb:15:5000:75': 6.0,
  'ffun_add:10:10000:71': 154.0,
  'mem_40:15:5000:69': 20.0,
  'dis:15:10000:36': 9.0,
  'ffun_add:10:30000:109': 2118.0,
  'ffun_add:15:25000:68': 231.0,
  'mem:15:40000:78': 294.0,
  'ffun_add:15:40000:119': 2698.0,
  'mem:10:40000:29': 16.0,
  'mem:15:40000:115': 1154.0,
  'ffun:15:20000:104': 992.0,
  'mem_40:15:20000:72': 116.0,
  'mem_40:10:40000:82': 439.0,
  'ffun_add:15:7500:26': 13.0,
  'mem_40:10:15000:78': 124.0,
  'ffun:10:2000:10': 33.0,
  'mem_7:15:5000:39': 2.0,
  'adb:15:2000:68': 1.0,
  'ffun_add:15:25000:1': 20.0,
  'adb:15:30000:8': 18.0,
  'mem:10:20000:62': 65.0,
  'adb:15:10000:99': 142.0,
  'mem:10:5000:112': 145.0,
  'ffun:10:25000:62': 187.0,
  'ffun_add:15:15000:36': 30.0,
  'mem_40:10:20000:32': 11.0,
  'mem_40:15:5000:72': 30.0,
  'mem_40:10:30000:27': 12.0,
  'mem_7:10:10000:61': 20.0,
  'mem_40:10:40000:62': 130.0,
  'ffun_add:15:5000:70': 52.0,
  'ffun_add:10:20000:61': 161.0,
  'ffun:15:25000:79': 336.0,
  'mem_7:15:7500:108': 131.0,
  'adb:10:40000:25': 31.0,
  'mem_40:10:15000:60': 46.0,
  'mem:10:25000:91': 482.0,
  'mem_7:10:7500:68': 22.0,
  'mem_40:15:40000:37': 24.0,
  'adb:10:15000:21': 12.0,
  'mem_7:15:10000:15': 2.0,
  'mem_7:15:15000:86': 127.0,
  'mem:15:2000:7': 1.0,
  'ffun:10:50000:96': 3205.0,
  'ffun_add:15:2000:60': 19.0,
  'hcb:10:120:27': 20.0,
  'mem_40:10:2000:105': 59.0,
  'ffun:15:20000:34': 45.0,
  'mem_7:10:10000:82': 66.0,
  'mem_40:15:10000:97': 289.0,
  'ffun:15:50000:13': 50.0,
  'ffun_add:15:30000:47': 118.0,
  'ffun_add:15:50000:77': 863.0,
  'mem_7:10:5000:104': 88.0,
  'dis:15:20000:24': 10.0,
  'mem:10:20000:118': 577.0,
  'mem_40:15:10000:31': 5.0,
  'ffun_add:15:5000:3': 9.0,
  'ffun:10:15000:20': 39.0,
  'mem_40:10:25000:69': 115.0,
  'mem_40:15:2000:40': 1.0,
  'mem:10:5000:37': 5.0,
  'mem:10:7500:116': 217.0,
  'ffun:15:25000:103': 1232.0,
  'mem:10:7500:117': 217.0,
  'ffun:15:20000:13': 38.0,
  'mem_40:15:20000:120': 577.0,
  'mem:15:2000:41': 2.0,
  'adb:10:5000:8': 5.0,
  'ffun_add:10:40000:78': 782.0,
  'mem_7:10:5000:8': 1.0,
  'ffun_add:10:2000:19': 9.0,
  'mem:10:30000:78': 246.0,
  'adb:15:30000:80': 65.0,
  'adb:15:25000:59': 37.0,
  'ffun_add:10:7500:1': 12.0,
  'mem_40:15:30000:3': 6.0,
  'dis:15:20000:56': 15.0,
  'ffun:10:7500:66': 102.0,
  'ffun:10:20000:114': 1310.0,
  'mem_40:10:50000:52': 139.0,
  'mem_40:15:25000:12': 6.0,
  'mem_40:15:25000:97': 722.0,
  'adb:10:40000:62': 25.0,
  'adb:10:20000:79': 49.0,
  'ffun:15:15000:44': 56.0,
  'ffun_add:10:2000:67': 30.0,
  'mem_40:15:30000:115': 865.0,
  'ffun:15:15000:111': 752.0,
  'mem:10:7500:55': 22.0,
  'mem:15:30000:6': 6.0,
  'dis:10:10000:47': 11.0,
  'mem:15:10000:54': 23.0,
  'mem:15:50000:100': 1444.0,
  'mem:10:15000:86': 218.0,
  'mem:15:5000:64': 14.0,
  'ffun:10:20000:120': 1310.0,
  'adb:10:25000:28': 20.0,
  'mem_40:10:30000:40': 24.0,
  'adb:10:25000:44': 37.0,
  'ffun_add:15:30000:32': 37.0,
  'mem_7:15:2000:106': 35.0,
  'ffun_add:15:50000:54': 285.0,
  'ffun_add:15:5000:11': 9.0,
  'mem_40:15:20000:30': 6.0,
  'mem:10:5000:48': 10.0,
  'ffun_add:15:5000:54': 34.0,
  'mem:10:10000:1': 4.0,
  'ffun:10:10000:80': 189.0,
  'mem_7:15:25000:11': 4.0,
  'mem:15:25000:51': 55.0,
  'mem_7:15:10000:43': 6.0,
  'mem_7:10:50000:32': 16.0,
  'mem:15:40000:91': 764.0,
  'mem:15:20000:110': 577.0,
  'ffun:15:40000:61': 208.0,
  'mem_7:10:5000:66': 14.0,
  'ffun_add:15:2000:101': 142.0,
  'mem_7:15:40000:81': 246.0,
  'mem_40:15:15000:65': 41.0,
  'mem_7:15:50000:64': 80.0,
  'mem_7:15:30000:111': 520.0,
  'adb:10:20000:88': 101.0,
  'mem_40:10:40000:72': 247.0,
  'mem_40:10:15000:101': 433.0,
  'ffun:15:40000:74': 386.0,
  'mem_7:10:25000:34': 8.0,
  'mem_7:15:10000:83': 62.0,
  'mem_40:10:7500:73': 47.0,
  'ffun_add:15:2000:29': 9.0,
  'mem_40:15:2000:7': 1.0,
  'mem_7:10:25000:71': 94.0,
  'adb:10:25000:99': 353.0,
  'mem_40:15:40000:47': 59.0,
  'adb:10:50000:59': 95.0,
  'adb:15:2000:50': 4.0,
  'mem:15:20000:18': 5.0,
  'adb:10:40000:65': 25.0,
  'mem_7:10:20000:68': 55.0,
  'mem_40:15:5000:25': 1.0,
  'mem_40:10:5000:23': 2.0,
  'ffun:10:2000:117': 173.0,
  'mem:15:2000:18': 1.0,
  'adb:10:15000:14': 12.0,
  'ffun:10:2000:99': 173.0,
  'mem:15:5000:82': 52.0,
  'mem_40:10:40000:107': 1154.0,
  'mem:10:25000:69': 115.0,
  'adb:10:25000:0': 19.0,
  'mem_7:10:30000:60': 54.0,
  'mem_7:10:10000:65': 20.0,
  'mem_7:10:2000:94': 24.0,
  'adb:15:7500:42': 10.0,
  'mem_7:10:25000:63': 49.0,
  'mem_7:10:20000:84': 132.0,
  'mem_40:10:5000:109': 145.0,
  'mem_7:15:7500:35': 2.0,
  'ffun:15:25000:95': 826.0,
  'mem:15:30000:46': 43.0,
  'mem_7:10:30000:78': 148.0,
  'mem_7:10:25000:10': 5.0,
  'adb:10:5000:85': 18.0,
  'mem_40:10:10000:20': 4.0,
  'ffun:15:50000:64': 252.0,
  'mem:15:5000:52': 12.0,
  'mem_40:15:20000:68': 78.0,
  'ffun:15:5000:107': 271.0,
  'ffun:15:10000:2': 34.0,
  'adb:15:20000:21': 12.0,
  'mem_40:15:25000:6': 6.0,
  'ffun:10:50000:59': 326.0,
  'adb:15:40000:28': 26.0,
  'mem_40:10:2000:45': 4.0,
  'mem_40:10:25000:100': 722.0,
  'mem_40:15:30000:96': 865.0,
  'ffun_add:15:30000:112': 2025.0,
  'adb:10:2000:40': 2.0,
  'ffun:10:5000:0': 33.0,
  'mem_7:10:20000:87': 175.0,
  'mem_7:15:2000:69': 5.0,
  'ffun_add:15:30000:8': 23.0,
  'ffun_add:15:15000:56': 94.0,
  'ffun_add:15:25000:16': 21.0,
  'ffun_add:15:15000:35': 23.0,
  'adb:10:50000:87': 250.0,
  'mem_40:10:25000:71': 155.0,
  'mem_7:15:40000:112': 692.0,
  'dis:10:20000:48': 12.0,
  'mem:15:20000:98': 577.0,
  'mem_40:10:10000:87': 146.0,
  'mem_40:15:30000:87': 422.0,
  'mem:10:20000:108': 577.0,
  'mem_7:10:15000:25': 4.0,
  'ffun_add:10:10000:26': 17.0,
  'ffun:10:50000:23': 57.0,
  'mem_7:10:2000:45': 2.0,
  'ffun_add:10:10000:69': 117.0,
  'hcb:10:150:26': 31.0,
  'mem_40:10:20000:51': 56.0,
  'mem:10:7500:92': 145.0,
  'hcb:10:120:44': 21.0,
  'mem:15:50000:120': 1444.0,
  'mem_7:15:20000:79': 95.0,
  'ffun_add:10:7500:19': 12.0,
  'ffun_add:15:2000:87': 72.0,
  'mem:10:25000:2': 7.0,
  'mem_7:15:40000:68': 94.0,
  'mem_7:10:15000:77': 74.0,
  'adb:15:7500:6': 5.0,
  'adb:15:7500:48': 12.0,
  'ffun_add:15:20000:69': 187.0,
  'adb:10:25000:84': 86.0,
  'mem:10:7500:81': 83.0,
  'mem_7:10:25000:19': 5.0,
  'mem:10:2000:104': 59.0,
  'mem_40:15:20000:99': 577.0,
  'adb:10:40000:43': 59.0,
  'ffun:10:40000:91': 1734.0,
  'mem_40:10:2000:69': 10.0,
  'mem_40:10:30000:17': 8.0,
  'mem_40:15:50000:9': 11.0,
  'mem:10:7500:36': 6.0,
  'ffun_add:10:25000:105': 1769.0,
  'mem_7:10:10000:53': 17.0,
  'adb:15:7500:74': 8.0,
  'ffun:10:15000:15': 38.0,
  'mem_7:15:5000:19': 1.0,
  'mem_7:15:2000:70': 5.0,
  'adb:10:20000:18': 16.0,
  'ffun:15:20000:66': 159.0,
  'mem_7:10:7500:107': 131.0,
  'ffun:10:15000:17': 39.0,
  'mem_40:10:7500:22': 2.0,
  'ffun:15:2000:78': 55.0,
  'mem_40:10:5000:86': 73.0,
  'ffun_add:15:20000:58': 124.0,
  'dis:15:10000:37': 9.0,
  'adb:10:5000:26': 5.0,
  'mem_7:10:50000:80': 246.0,
  'mem:10:40000:80': 328.0,
  'adb:15:30000:119': 424.0,
  'ffun:10:30000:117': 1940.0,
  'mem_40:10:30000:99': 865.0,
  'ffun:15:20000:116': 992.0,
  'hcb:15:150:19': 26.0,
  'ffun_add:15:2000:42': 12.0,
  'ffun:10:5000:87': 206.0,
  'ffun_add:15:50000:27': 45.0,
  'mem_7:15:40000:52': 53.0,
  'mem_7:15:5000:107': 88.0,
  'adb:15:50000:4': 29.0,
  'mem_7:10:7500:23': 2.0,
  'ffun:15:5000:18': 32.0,
  'mem_7:10:15000:41': 12.0,
  'mem_7:10:7500:1': 1.0,
  'ffun_add:10:15000:93': 715.0,
  'mem_40:15:20000:84': 205.0,
  'mem_40:15:10000:77': 79.0,
  'ffun:15:7500:101': 391.0,
  'mem:10:5000:53': 14.0,
  'ffun:15:15000:41': 56.0,
  'mem:15:7500:14': 2.0,
  'ffun:10:20000:39': 58.0,
  'mem_7:10:2000:17': 1.0,
  'mem:10:5000:20': 2.0,
  'ffun:10:5000:93': 257.0,
  'mem_40:15:40000:13': 8.0,
  'ffun:15:30000:56': 156.0,
  'ffun:10:20000:73': 266.0,
  'mem:15:15000:36': 10.0,
  'adb:15:25000:117': 353.0,
  'ffun_add:15:15000:58': 94.0,
  'mem_40:10:2000:117': 59.0,
  'mem:10:5000:32': 4.0,
  'ffun_add:10:15000:90': 542.0,
  'mem_7:10:40000:38': 19.0,
  'mem_7:10:30000:98': 520.0,
  'adb:15:40000:79': 86.0,
  'adb:10:15000:30': 13.0,
  'ffun_add:10:30000:12': 27.0,
  'mem_7:10:25000:26': 6.0,
  'adb:10:7500:49': 16.0,
  'mem_40:15:10000:42': 10.0,
  'adb:15:30000:52': 66.0,
  'mem_7:15:50000:88': 422.0,
  'mem:10:25000:84': 275.0,
  'mem_40:15:15000:76': 119.0,
  'mem:15:50000:68': 193.0,
  'mem_7:10:50000:77': 246.0,
  'adb:10:25000:87': 125.0,
  'ffun:10:15000:86': 525.0,
  'ffun_add:15:15000:108': 1017.0,
  'mem_7:15:7500:29': 2.0,
  'mem:10:20000:27': 8.0,
  'mem_40:10:7500:13': 2.0,
  'mem:10:30000:24': 10.0,
  'adb:15:40000:67': 19.0,
  'mem:15:10000:51': 23.0,
  'mem_7:10:50000:101': 866.0,
  'ffun:15:5000:1': 32.0,
  'mem_7:10:50000:37': 24.0,
  'adb:15:30000:72': 34.0,
  'ffun_add:15:15000:85': 366.0,
  'adb:10:15000:109': 212.0,
  'mem_40:15:15000:56': 37.0,
  'mem_7:15:7500:36': 4.0,
  'mem_40:10:50000:57': 150.0,
  'mem_7:10:5000:67': 14.0,
  'ffun_add:10:2000:98': 151.0,
  'ffun_add:15:7500:47': 34.0,
  'mem:15:7500:51': 17.0,
  'ffun:15:10000:25': 35.0,
  'mem_7:15:50000:18': 7.0,
  'mem_7:10:7500:31': 2.0,
  'adb:15:5000:9': 4.0,
  'adb:15:30000:11': 18.0,
  'mem_40:10:40000:99': 1154.0,
  'mem_40:15:15000:96': 433.0,
  'mem:10:5000:17': 2.0,
  'ffun:15:30000:82': 542.0,
  'ffun:15:2000:28': 31.0,
  'ffun:15:25000:107': 1232.0,
  'ffun_add:10:30000:118': 2118.0,
  'ffun:15:50000:90': 1203.0,
  'mem_40:15:30000:19': 7.0,
  'mem_7:15:7500:71': 26.0,
  'adb:15:10000:35': 8.0,
  'mem:10:30000:105': 865.0,
  'ffun_add:15:40000:66': 366.0,
  'mem_40:15:30000:74': 174.0,
  'ffun:10:50000:116': 3205.0,
  'mem_7:15:7500:6': 1.0,
  'ffun:15:40000:15': 46.0,
  'mem_7:10:30000:21': 6.0,
  'ffun:10:25000:30': 47.0,
  'mem_7:15:40000:26': 7.0,
  'adb:10:10000:56': 19.0,
  'mem:10:20000:0': 6.0,
  'mem_40:10:50000:113': 1444.0,
  'ffun:10:2000:108': 173.0,
  'adb:15:7500:67': 4.0,
  'ffun_add:10:7500:2': 12.0,
  'mem_7:15:20000:93': 230.0,
  'ffun_add:10:25000:22': 27.0,
  'ffun:10:5000:37': 39.0,
  'adb:10:2000:11': 2.0,
  'ffun:10:7500:61': 84.0,
  'ffun:15:10000:98': 511.0,
  'mem_40:10:10000:88': 146.0,
  'ffun:15:5000:41': 39.0,
  'adb:10:50000:10': 38.0,
  'mem_7:10:10000:47': 12.0,
  'mem:10:25000:47': 47.0,
  'mem_40:10:7500:17': 2.0,
  'mem_7:15:10000:112': 174.0,
  'ffun:10:15000:40': 51.0,
  'mem:10:7500:100': 217.0,
  'ffun:10:10000:65': 99.0,
  'ffun:10:2000:102': 173.0,
  'mem:15:30000:57': 72.0,
  'adb:15:7500:28': 5.0,
  'mem_40:15:15000:117': 433.0,
  'dis:10:10000:40': 11.0,
  'mem_40:15:40000:90': 563.0,
  'mem:10:40000:40': 31.0,
  'adb:15:20000:82': 64.0,
  'ffun_add:10:5000:88': 188.0,
  'mem_40:15:25000:104': 722.0,
  'mem:10:2000:111': 59.0,
  'mem_7:15:50000:119': 866.0,
  'ffun:10:25000:35': 53.0,
  'mem_40:15:20000:65': 54.0,
  'mem:10:10000:97': 289.0,
  'mem:10:2000:120': 59.0,
  'adb:15:7500:120': 107.0,
  'mem_40:10:50000:68': 230.0,
  'mem_40:10:20000:49': 37.0,
  'mem_7:10:30000:111': 520.0,
  'adb:15:30000:6': 18.0,
  'mem_7:15:40000:102': 692.0,
  'ffun_add:10:40000:99': 2824.0,
  'mem_7:10:30000:79': 148.0,
  'ffun_add:10:25000:17': 24.0,
  'ffun_add:15:50000:12': 34.0,
  'mem_40:10:15000:67': 70.0,
  'ffun_add:15:15000:74': 194.0,
  'adb:15:25000:87': 121.0,
  'mem:15:30000:48': 43.0,
  'mem:10:7500:94': 145.0,
  'ffun_add:15:2000:102': 142.0,
  'mem:15:10000:86': 142.0,
  'mem_7:15:30000:116': 520.0,
  'ffun:15:25000:36': 58.0,
  'ffun_add:15:20000:14': 17.0,
  'mem_7:15:2000:74': 7.0,
  'ffun_add:10:20000:44': 69.0,
  'mem_40:10:7500:90': 109.0,
  'mem_7:15:5000:8': 1.0,
  'dis:15:50000:25': 12.0,
  'mem_7:15:30000:74': 104.0,
  'adb:10:25000:79': 61.0,
  'adb:15:40000:95': 298.0,
  'ffun_add:10:20000:29': 27.0,
  'mem_40:10:50000:65': 162.0,
  'ffun:15:5000:80': 91.0,
  'mem_40:10:25000:120': 722.0,
  'mem_7:10:50000:105': 866.0,
  'mem_40:10:7500:69': 35.0,
  'ffun_add:15:20000:15': 17.0,
  'ffun:15:50000:111': 2435.0,
  'mem:15:15000:30': 5.0,
  'ffun:15:50000:108': 2435.0,
  'mem_7:10:7500:0': 1.0,
  'mem_40:15:10000:59': 25.0,
  'ffun:10:10000:67': 123.0,
  'mem_7:15:5000:10': 1.0,
  'mem:10:15000:110': 433.0,
  'mem:15:50000:16': 11.0,
  'ffun:10:7500:84': 227.0,
  'dis:15:10000:52': 10.0,
  'ffun:15:2000:3': 31.0,
  'ffun_add:10:7500:54': 62.0,
  'mem_40:15:25000:31': 11.0,
  'mem_7:10:5000:49': 6.0,
  'mem:15:5000:2': 1.0,
  'ffun_add:15:40000:108': 2698.0,
  'adb:10:5000:10': 5.0,
  'ffun_add:10:40000:7': 34.0,
  'ffun:15:40000:111': 1953.0,
  'mem_40:15:30000:95': 574.0,
  'mem_40:10:30000:107': 865.0,
  'ffun:15:5000:85': 116.0,
  'ffun_add:15:15000:38': 30.0,
  'ffun_add:10:5000:2': 10.0,
  'mem_40:15:10000:26': 4.0,
  'ffun:10:50000:15': 53.0,
  'mem_7:15:20000:43': 12.0,
  'mem_40:15:50000:17': 11.0,
  'mem_7:15:50000:68': 116.0,
  'mem:15:30000:81': 307.0,
  'ffun_add:10:30000:4': 27.0,
  'mem_7:10:15000:50': 17.0,
  'mem_7:15:30000:98': 520.0,
  'adb:10:5000:0': 5.0,
  'ffun_add:10:2000:75': 38.0,
  'adb:10:10000:101': 142.0,
  'mem_40:15:15000:93': 287.0,
  'ffun_add:10:50000:79': 976.0,
  'ffun:10:30000:108': 1940.0,
  'mem_7:15:2000:12': 1.0,
  'mem_40:10:30000:69': 138.0,
  'adb:15:7500:91': 56.0,
  'adb:10:25000:13': 19.0,
  'mem_40:15:10000:7': 2.0,
  'ffun_add:15:25000:33': 33.0,
  'dis:15:30000:48': 11.0,
  'adb:15:50000:95': 372.0,
  'ffun_add:15:30000:44': 80.0,
  'ffun:15:2000:100': 126.0,
  'ffun_add:15:30000:116': 2025.0,
  'ffun:10:7500:93': 364.0,
  'dis:15:20000:57': 15.0,
  'ffun:15:40000:27': 52.0,
  'ffun_add:15:50000:116': 3373.0,
  'mem_7:15:30000:105': 520.0,
  'adb:10:40000:56': 76.0,
  'mem_7:10:30000:54': 50.0,
  'ffun:15:5000:28': 33.0,
  'adb:10:5000:21': 5.0,
  'mem_40:15:50000:114': 1444.0,
  'mem_7:15:40000:114': 692.0,
  'ffun:15:15000:107': 752.0,
  'ffun_add:15:5000:99': 343.0,
  'ffun_add:15:40000:77': 692.0,
  'ffun_add:10:10000:117': 713.0,
  'ffun_add:15:25000:100': 1690.0,
  'ffun:15:25000:65': 141.0,
  'dis:10:40000:45': 14.0,
  'adb:10:10000:106': 142.0,
  'mem_40:15:50000:92': 956.0,
  'ffun:10:10000:17': 36.0,
  'adb:10:40000:75': 54.0,
  'mem:15:7500:75': 41.0,
  'ffun:15:15000:12': 36.0,
  'mem_40:15:15000:22': 4.0,
  'ffun_add:10:30000:85': 811.0,
  'adb:15:5000:52': 12.0,
  'ffun:10:40000:68': 375.0,
  'ffun_add:10:7500:99': 538.0,
  'ffun_add:10:40000:43': 132.0,
  'adb:15:2000:42': 2.0,
  'hcb:10:150:22': 30.0,
  'mem:10:25000:18': 7.0,
  'mem_40:10:5000:75': 31.0,
  'ffun:15:7500:76': 122.0,
  'adb:15:10000:79': 22.0,
  'adb:10:7500:105': 107.0,
  'mem_7:15:20000:68': 47.0,
  'mem_40:15:50000:72': 290.0,
  'mem:10:25000:48': 47.0,
  'mem:15:7500:24': 2.0,
  'mem_7:15:2000:84': 13.0,
  'adb:15:5000:38': 5.0,
  'adb:10:50000:91': 376.0,
  'mem_40:15:10000:101': 289.0,
  'adb:10:20000:36': 23.0,
  'dis:10:50000:48': 16.0,
  'mem_7:10:20000:83': 132.0,
  'ffun_add:15:10000:90': 335.0,
  'mem:10:7500:68': 35.0,
  'ffun:15:5000:71': 75.0,
  'mem_40:15:30000:47': 43.0,
  'mem_7:15:15000:50': 13.0,
  'adb:10:15000:33': 14.0,
  'hcb:15:120:21': 17.0,
  'mem_40:15:50000:85': 512.0,
  'mem_40:10:2000:16': 1.0,
  'dis:15:30000:50': 11.0,
  'mem:15:20000:24': 5.0,
  'mem_7:10:40000:1': 6.0,
  'ffun_add:15:50000:14': 34.0,
  'mem_7:15:40000:101': 692.0,
  'hcb:10:120:56': 20.0,
  'hcb:10:50:44': 4.0,
  'mem_7:10:7500:53': 13.0,
  'mem_40:15:40000:111': 1154.0,
  'mem_40:10:10000:73': 62.0,
  'mem_7:15:50000:69': 116.0,
  'adb:10:50000:81': 172.0,
  'mem_7:10:5000:115': 88.0,
  'ffun:10:5000:67': 81.0,
  'ffun:10:25000:36': 64.0,
  'ffun_add:10:40000:107': 2824.0,
  'mem_7:10:5000:81': 34.0,
  'ffun:10:50000:26': 63.0,
  'ffun:10:25000:26': 47.0,
  'mem:15:7500:111': 217.0,
  'ffun:15:25000:59': 135.0,
  'ffun_add:15:20000:83': 485.0,
  'mem_40:10:30000:89': 437.0,
  'ffun:15:10000:41': 48.0,
  'ffun_add:15:5000:5': 9.0,
  'adb:10:20000:69': 13.0,
  'mem_7:15:5000:58': 8.0,
  'ffun_add:15:10000:0': 12.0,
  'adb:10:10000:85': 35.0,
  'mem:15:5000:17': 1.0,
  'mem_40:10:5000:33': 4.0,
  'ffun_add:10:15000:42': 54.0,
  'mem_7:15:20000:9': 2.0,
  'ffun_add:15:15000:14': 14.0,
  'mem_7:15:10000:6': 1.0,
  'mem_40:15:15000:33': 6.0,
  'mem_7:15:40000:85': 246.0,
  'mem_7:10:15000:45': 12.0,
  'dis:10:40000:46': 14.0,
  'ffun:15:25000:64': 141.0,
  'mem_40:10:10000:103': 289.0,
  'mem_40:15:25000:120': 722.0,
  'ffun_add:10:20000:82': 545.0,
  'mem_7:10:15000:22': 4.0,
  'ffun:10:10000:29': 38.0,
  'ffun_add:15:30000:114': 2025.0,
  'adb:15:40000:82': 127.0,
  'ffun:10:50000:78': 789.0,
  'hcb:15:50:31': 3.0,
  'mem:10:40000:48': 74.0,
  'ffun_add:15:25000:95': 1122.0,
  'adb:15:50000:84': 160.0,
  'ffun:10:25000:70': 250.0,
  'dis:15:50000:54': 14.0,
  'mem_7:10:2000:42': 2.0,
  'mem_40:15:5000:113': 145.0,
  'mem_7:15:40000:1': 5.0,
  'adb:10:30000:50': 59.0,
  'mem_40:15:40000:70': 155.0,
  'ffun_add:10:10000:88': 365.0,
  'mem_40:10:50000:86': 728.0,
  'mem:15:25000:57': 61.0,
  'mem:15:30000:99': 865.0,
  'adb:15:50000:31': 37.0,
  'dis:10:50000:29': 15.0,
  'mem:15:50000:31': 20.0,
  'mem_7:10:15000:24': 4.0,
  'ffun_add:15:10000:57': 65.0,
  'mem_40:15:20000:100': 577.0,
  'adb:10:30000:77': 73.0,
  'mem_40:10:2000:118': 59.0,
  'mem_7:15:7500:72': 26.0,
  'mem:10:2000:43': 4.0,
  'mem:15:15000:100': 433.0,
  'mem:15:10000:101': 289.0,
  'mem_40:15:2000:78': 17.0,
  'hcb:10:100:58': 14.0,
  'mem_40:10:15000:35': 8.0,
  'ffun:15:5000:2': 32.0,
  'mem_40:15:2000:26': 1.0,
  'mem_7:15:40000:14': 6.0,
  'mem:15:30000:107': 865.0,
  'mem:10:20000:59': 60.0,
  'mem_7:15:2000:113': 35.0,
  'mem_40:10:10000:36': 8.0,
  'ffun:10:7500:78': 152.0,
  'adb:10:15000:81': 52.0,
  'ffun:15:7500:29': 34.0,
  'ffun_add:15:20000:104': 1353.0,
  'mem_7:15:20000:67': 47.0,
  'adb:15:25000:88': 121.0,
  'mem_7:10:10000:92': 116.0,
  'adb:15:25000:89': 121.0,
  'ffun_add:15:25000:7': 20.0,
  'ffun_add:15:15000:32': 23.0,
  'ffun_add:10:50000:98': 3527.0,
  'mem:10:15000:105': 433.0,
  'adb:10:30000:24': 24.0,
  'mem:10:50000:18': 14.0,
  'mem_40:15:2000:28': 1.0,
  'hcb:15:80:31': 8.0,
  'mem_7:10:50000:39': 24.0,
  'adb:10:50000:118': 706.0,
  'dis:10:20000:41': 12.0,
  'ffun:10:15000:8': 38.0,
  'hcb:15:150:50': 28.0,
  'adb:15:40000:44': 46.0,
  'ffun:15:15000:47': 70.0,
  'adb:15:30000:81': 96.0,
  'hcb:15:150:28': 26.0,
  'adb:15:10000:11': 6.0,
  'ffun_add:15:10000:69': 97.0,
  'mem:10:7500:74': 47.0,
  'mem_40:15:30000:7': 6.0,
  'mem:10:25000:83': 275.0,
  'mem_40:15:20000:23': 5.0,
  'adb:10:5000:2': 5.0,
  'adb:15:30000:82': 96.0,
  'mem:15:40000:48': 59.0,
  'ffun:15:20000:107': 992.0,
  'adb:15:20000:2': 12.0,
  'mem_40:15:40000:33': 17.0,
  'mem:15:2000:64': 6.0,
  'adb:10:10000:67': 7.0,
  'ffun_add:15:15000:96': 1017.0,
  'mem:15:30000:65': 80.0,
  'mem:10:20000:75': 124.0,
  'ffun:10:15000:72': 209.0,
  'mem_7:10:15000:95': 174.0,
  'mem_40:10:40000:85': 439.0,
  'mem:15:30000:90': 422.0,
  'ffun_add:15:40000:62': 255.0,
  'mem_40:10:10000:65': 32.0,
  'ffun:15:25000:13': 40.0,
  'ffun:10:10000:27': 38.0,
  'ffun:15:2000:118': 126.0,
  'mem_7:15:20000:116': 347.0,
  'adb:10:10000:110': 142.0,
  'adb:15:25000:63': 12.0,
  'mem:10:40000:81': 439.0,
  'ffun:10:2000:27': 33.0,
  'ffun:15:5000:61': 52.0,
  'ffun:10:50000:112': 3205.0,
  'mem_7:10:5000:87': 44.0,
  'adb:15:5000:44': 6.0,
  'mem_7:15:40000:103': 692.0,
  'hcb:15:80:37': 8.0,
  'adb:15:7500:113': 107.0,
  'adb:10:15000:114': 212.0,
  'mem_7:15:15000:26': 4.0,
  'mem_40:10:5000:76': 42.0,
  'mem_40:15:5000:50': 8.0,
  'mem_7:10:5000:112': 88.0,
  'adb:10:2000:6': 2.0,
  'adb:15:7500:69': 4.0,
  'adb:10:25000:53': 68.0,
  'mem_40:10:7500:112': 217.0,
  'dis:15:20000:32': 10.0,
  'mem:15:20000:37': 12.0,
  'adb:10:30000:31': 29.0,
  'mem:15:15000:13': 4.0,
  'ffun:10:50000:14': 53.0,
  'mem_7:15:20000:91': 230.0,
  'ffun:15:7500:109': 391.0,
  'adb:10:40000:6': 31.0,
  'mem_40:10:20000:112': 577.0,
  'ffun:15:50000:95': 1623.0,
  'ffun:10:5000:40': 39.0,
  'mem_40:10:2000:48': 5.0,
  'mem_7:15:10000:54': 13.0,
  'mem_7:10:50000:35': 16.0,
  'ffun:15:25000:105': 1232.0,
  'ffun:10:5000:86': 206.0,
  'dis:10:10000:51': 12.0,
  'adb:15:30000:22': 18.0,
  'mem:15:5000:33': 2.0,
  'mem_7:15:7500:104': 131.0,
  'adb:10:30000:25': 24.0,
  'ffun_add:10:15000:57': 120.0,
  'dis:15:40000:39': 12.0,
  'adb:15:7500:89': 37.0,
  'mem_40:10:50000:22': 16.0,
  'mem_40:15:2000:0': 1.0,
  'adb:10:7500:104': 107.0,
  'ffun_add:15:15000:26': 18.0,
  'ffun_add:10:7500:47': 43.0,
  'mem:10:10000:74': 62.0,
  'adb:10:30000:38': 35.0,
  'adb:15:2000:64': 1.0,
  'adb:10:40000:87': 200.0,
  'ffun:15:5000:60': 51.0,
  'ffun:10:40000:90': 1322.0,
  'mem_40:15:15000:50': 23.0,
  'mem_40:15:7500:77': 60.0,
  'mem_40:15:50000:20': 11.0,
  'mem_40:15:50000:26': 14.0,
  'adb:10:20000:76': 49.0,
  'adb:10:7500:106': 107.0,
  'adb:10:50000:51': 137.0,
  'ffun:15:7500:68': 78.0,
  'adb:15:5000:82': 17.0,
  'adb:10:30000:5': 23.0,
  'mem_7:10:25000:110': 433.0,
  'adb:15:7500:64': 4.0,
  'mem_7:15:25000:17': 4.0,
  'adb:15:10000:5': 6.0,
  'adb:15:30000:63': 14.0,
  'ffun:10:5000:91': 257.0,
  'ffun_add:15:40000:89': 1319.0,
  'mem:10:30000:38': 24.0,
  'mem:15:40000:107': 1154.0,
  'mem:15:7500:13': 2.0,
  'adb:10:5000:111': 71.0,
  'ffun:15:5000:88': 148.0,
  'mem_7:15:30000:104': 520.0,
  'mem_40:10:15000:11': 5.0,
  'ffun_add:15:25000:38': 45.0,
  'ffun_add:15:10000:61': 69.0,
  'ffun_add:15:40000:115': 2698.0,
  'mem:10:2000:85': 23.0,
  'mem_7:15:10000:72': 36.0,
  'mem:15:30000:40': 18.0,
  'mem_40:10:5000:107': 145.0,
  'mem_7:15:20000:88': 169.0,
  'mem_7:10:20000:104': 347.0,
  'mem:15:30000:23': 7.0,
  'ffun:10:30000:93': 1312.0,
  'ffun:15:30000:62': 163.0,
  'ffun_add:10:30000:58': 229.0,
  'ffun:15:40000:110': 1953.0,
  'mem_7:15:50000:51': 66.0,
  'mem:15:7500:80': 55.0,
  'mem:10:10000:57': 30.0,
  'mem:15:40000:16': 10.0,
  'mem_7:10:2000:88': 18.0,
  'mem_40:10:15000:25': 5.0,
  'mem_40:10:15000:55': 42.0,
  'mem:15:7500:7': 2.0,
  'ffun_add:10:2000:77': 47.0,
  'mem_7:15:40000:25': 6.0,
  'adb:15:30000:13': 18.0,
  'adb:15:5000:76': 11.0,
  'mem_40:10:15000:108': 433.0,
  'ffun_add:15:20000:35': 27.0,
  'ffun:15:20000:78': 275.0,
  'mem_7:10:15000:7': 2.0,
  'ffun_add:15:20000:17': 18.0,
  'mem_40:15:50000:13': 11.0,
  'mem:15:50000:47': 73.0,
  'ffun:10:15000:109': 994.0,
  'mem_7:10:30000:81': 198.0,
  'adb:10:10000:90': 50.0,
  'mem_40:10:2000:39': 2.0,
  'mem_40:15:2000:1': 1.0,
  'ffun_add:15:50000:51': 285.0,
  'adb:15:7500:60': 12.0,
  'ffun_add:15:5000:0': 9.0,
  'hcb:15:120:31': 17.0,
  'mem_7:10:30000:92': 347.0,
  'ffun_add:15:5000:30': 10.0,
  'adb:15:20000:0': 12.0,
  'ffun_add:15:2000:110': 142.0,
  'dis:10:50000:35': 15.0,
  'ffun:15:2000:63': 39.0,
  'mem_7:15:20000:34': 5.0,
  'mem_7:10:2000:60': 4.0,
  'ffun_add:10:15000:61': 123.0,
  'adb:10:7500:5': 6.0,
  'ffun_add:15:25000:67': 231.0,
  'mem:10:20000:19': 6.0,
  'adb:15:25000:67': 12.0,
  'ffun_add:10:10000:14': 13.0,
  'ffun_add:10:20000:46': 102.0,
  'ffun:15:50000:63': 252.0,
  'mem_7:10:7500:65': 16.0,
  'ffun_add:15:7500:24': 12.0,
  'mem_7:10:25000:41': 19.0,
  'ffun_add:10:15000:63': 123.0,
  'ffun_add:10:5000:25': 10.0,
  'mem_40:10:15000:87': 218.0,
  'ffun:15:30000:67': 223.0,
  'mem_40:10:20000:89': 292.0,
  'mem_7:15:7500:43': 5.0,
  'ffun_add:15:50000:11': 34.0,
  'ffun_add:10:30000:84': 811.0,
  'ffun_add:10:25000:73': 374.0,
  'hcb:10:150:29': 31.0,
  'mem:15:10000:12': 2.0,
  'mem_7:15:50000:82': 308.0,
  'mem:10:40000:3': 11.0,
  'mem_40:10:30000:57': 90.0,
  'mem_7:10:5000:47': 6.0,
  'ffun:10:15000:63': 128.0,
  'adb:15:30000:28': 19.0,
  'mem:10:20000:112': 577.0,
  'mem_7:10:2000:35': 1.0,
  'mem:15:5000:107': 145.0,
  'mem:10:25000:35': 13.0,
  'mem_7:15:25000:76': 119.0,
  'ffun_add:15:7500:98': 511.0,
  'ffun:15:30000:106': 1472.0,
  'mem_40:10:50000:54': 139.0,
  'dis:10:50000:51': 17.0,
  'mem_40:15:40000:5': 8.0,
  'ffun:15:10000:36': 41.0,
  'adb:10:7500:70': 5.0,
  'adb:15:30000:57': 46.0,
  'ffun:15:40000:44': 100.0,
  'ffun_add:10:2000:58': 23.0,
  'adb:15:7500:4': 5.0,
  'ffun:10:10000:58': 97.0,
  'mem_7:15:40000:59': 59.0,
  'mem_7:15:40000:9': 5.0,
  'mem_7:15:7500:96': 131.0,
  'hcb:15:100:57': 11.0,
  'adb:10:25000:106': 353.0,
  'ffun:15:10000:104': 511.0,
  'ffun_add:10:5000:110': 362.0,
  'ffun:15:7500:99': 391.0,
  'ffun_add:15:10000:94': 453.0,
  'ffun_add:15:30000:17': 24.0,
  'adb:10:50000:67': 31.0,
  'ffun:15:2000:72': 48.0,
  'ffun:10:30000:72': 378.0,
  'ffun:10:15000:59': 125.0,
  'ffun:10:30000:7': 44.0,
  'mem_7:10:5000:14': 1.0,
  'hcb:10:100:42': 14.0,
  'mem:10:25000:63': 82.0,
  'dis:10:10000:39': 11.0,
  'adb:15:25000:103': 353.0,
  'adb:15:10000:81': 32.0,
  'adb:15:30000:43': 35.0,
  'mem_7:15:30000:110': 520.0,
  'mem:10:25000:90': 365.0,
  'mem_7:15:50000:6': 6.0,
  'mem_40:10:2000:24': 1.0,
  'ffun_add:10:5000:15': 10.0,
  'mem_7:15:15000:119': 260.0,
  'mem_7:10:50000:64': 97.0,
  'ffun_add:10:30000:78': 588.0,
  'ffun_add:15:5000:62': 38.0,
  'adb:10:10000:61': 7.0,
  'ffun_add:15:40000:39': 68.0,
  'ffun:15:40000:48': 137.0,
  'ffun_add:10:7500:118': 538.0,
  'ffun:15:15000:59': 93.0,
  'ffun:10:10000:115': 679.0,
  'ffun:15:20000:83': 372.0,
  'ffun:10:7500:41': 50.0,
  'adb:15:15000:64': 7.0,
  'mem:15:15000:53': 34.0,
  'mem:10:2000:12': 1.0,
  'adb:10:10000:98': 142.0,
  'mem_7:15:10000:95': 115.0,
  'adb:15:30000:0': 18.0,
  'ffun_add:15:15000:79': 264.0,
  'mem_40:15:7500:24': 2.0,
  'ffun_add:10:5000:112': 362.0,
  'ffun:10:7500:89': 286.0,
  'ffun:15:25000:50': 97.0,
  'mem_40:15:40000:53': 88.0,
  'ffun_add:10:10000:9': 13.0,
  'adb:15:15000:93': 112.0,
  'ffun_add:10:25000:44': 86.0,
  'mem_40:15:25000:112': 722.0,
  'hcb:10:80:44': 9.0,
  'adb:10:15000:26': 13.0,
  'adb:15:50000:13': 29.0,
  'mem_40:15:7500:95': 144.0,
  'ffun_add:10:15000:48': 79.0,
  'ffun_add:15:2000:77': 41.0,
  'mem_7:10:5000:89': 44.0,
  'hcb:15:50:20': 3.0,
  'adb:15:30000:77': 65.0,
  'mem_7:10:5000:116': 88.0,
  'mem:10:7500:45': 10.0,
  'mem_40:10:20000:0': 6.0,
  'mem_7:15:40000:104': 692.0,
  'ffun_add:15:10000:91': 453.0,
  'mem:10:7500:111': 217.0,
  'ffun_add:15:40000:94': 1790.0,
  'mem_7:10:2000:58': 4.0,
  'ffun_add:10:20000:28': 27.0,
  'adb:10:20000:114': 282.0,
  'ffun:15:40000:52': 189.0,
  'mem_7:15:40000:45': 23.0,
  'ffun:15:7500:0': 33.0,
  'ffun_add:10:25000:31': 39.0,
  'ffun:10:10000:35': 40.0,
  'ffun_add:15:7500:4': 10.0,
  'mem:15:25000:106': 722.0,
  'mem_40:15:10000:48': 16.0,
  'hcb:15:100:42': 12.0,
  'mem:10:10000:49': 19.0,
  'ffun_add:15:5000:42': 19.0,
  'ffun:10:40000:25': 52.0,
  'ffun:10:10000:32': 40.0,
  'mem:10:50000:76': 409.0,
  'adb:10:20000:4': 16.0,
  'ffun_add:10:20000:20': 21.0,
  'mem_7:10:25000:51': 42.0,
  'adb:15:2000:61': 1.0,
  'mem_40:15:30000:69': 116.0,
  'mem:15:5000:116': 145.0,
  'mem_7:10:20000:85': 132.0,
  'dis:15:50000:55': 14.0,
  'mem_7:15:40000:60': 59.0,
  'adb:15:25000:112': 353.0,
  'mem:15:2000:53': 5.0,
  'dis:15:50000:51': 14.0,
  'adb:10:10000:109': 142.0,
  'mem_7:15:50000:76': 236.0,
  'ffun_add:15:15000:117': 1017.0,
  'mem_40:10:30000:9': 8.0,
  'ffun:10:20000:67': 208.0,
  'ffun_add:15:15000:44': 44.0,
  'hcb:10:120:21': 19.0,
  'ffun_add:10:7500:25': 13.0,
  'adb:15:10000:97': 142.0,
  'mem_7:15:30000:88': 253.0,
  'ffun:10:2000:50': 40.0,
  'mem:15:2000:15': 1.0,
  'mem:15:30000:27': 10.0,
  'mem_7:15:25000:117': 433.0,
  'adb:15:5000:3': 4.0,
  'ffun:10:10000:96': 679.0,
  'adb:10:10000:69': 7.0,
  'adb:10:5000:93': 38.0,
  'ffun_add:10:7500:9': 12.0,
  'adb:10:5000:28': 5.0,
  'adb:10:20000:26': 17.0,
  'mem_40:10:5000:51': 14.0,
  'ffun:15:30000:74': 297.0,
  'mem:10:25000:76': 205.0,
  'mem_40:15:25000:82': 257.0,
  'mem_7:10:2000:93': 24.0,
  'mem:10:20000:65': 65.0,
  'adb:15:5000:51': 12.0,
  'adb:10:40000:99': 564.0,
  'mem:15:30000:115': 865.0,
  'mem_40:10:40000:19': 12.0,
  'dis:10:50000:59': 22.0,
  'ffun:15:25000:39': 58.0,
  'ffun_add:15:15000:17': 16.0,
  'adb:15:5000:113': 71.0,
  'adb:15:7500:44': 10.0,
  'mem_7:10:50000:98': 866.0,
  'adb:10:30000:111': 424.0,
  'ffun:10:7500:16': 35.0,
  'ffun_add:10:40000:53': 293.0,
  'mem_40:10:50000:3': 13.0,
  'ffun_add:10:5000:3': 10.0,
  'mem_7:10:40000:47': 44.0,
  'mem:10:30000:115': 865.0,
  'mem_40:15:10000:32': 5.0,
  'adb:15:10000:69': 5.0,
  'ffun:15:40000:40': 74.0,
  'mem_7:10:25000:11': 5.0,
  'ffun:15:5000:37': 36.0,
  'mem:15:20000:16': 5.0,
  'mem_7:10:10000:76': 49.0,
  'mem_7:10:7500:92': 88.0,
  'mem_40:15:40000:17': 10.0,
  'mem:15:50000:46': 73.0,
  'mem:10:2000:24': 1.0,
  'ffun:15:50000:87': 1203.0,
  'dis:15:40000:22': 11.0,
  'mem_40:15:2000:79': 17.0,
  'hcb:10:50:37': 4.0,
  'mem_7:15:10000:106': 174.0,
  'ffun:15:15000:87': 382.0,
  'mem_40:15:10000:52': 23.0,
  'adb:10:25000:32': 24.0,
  'mem_40:15:7500:110': 217.0,
  'ffun:15:50000:105': 2435.0,
  'mem:10:20000:97': 577.0,
  'ffun:15:25000:84': 457.0,
  'mem_40:15:25000:62': 67.0,
  'mem:15:50000:91': 956.0,
  'mem_7:15:15000:39': 6.0,
  'mem_40:15:50000:104': 1444.0,
  'ffun_add:15:20000:55': 118.0,
  'mem:15:50000:64': 133.0,
  'adb:15:20000:104': 282.0,
  'ffun_add:15:7500:81': 186.0,
  'ffun:15:10000:11': 34.0,
  'dis:10:10000:28': 11.0,
  'mem:10:30000:45': 37.0,
  'ffun_add:15:10000:51': 62.0,
  'mem_7:15:5000:34': 1.0,
  'mem_40:15:7500:43': 7.0,
  'ffun_add:15:25000:76': 434.0,
  'mem_40:15:25000:22': 6.0,
  'ffun:15:15000:109': 752.0,
  'ffun_add:10:30000:110': 2118.0,
  'ffun_add:15:40000:33': 48.0,
  'mem_7:10:7500:33': 2.0,
  'ffun_add:15:15000:21': 16.0,
  'ffun:15:40000:18': 46.0,
  'ffun:15:30000:12': 42.0,
  'mem:10:40000:86': 583.0,
  'adb:10:25000:58': 48.0,
  'hcb:15:50:36': 3.0,
  'ffun:10:40000:6': 48.0,
  'mem_7:10:15000:63': 30.0,
  'ffun_add:10:2000:25': 9.0,
  'ffun:15:15000:99': 752.0,
  'ffun_add:10:20000:114': 1416.0,
  'ffun:10:2000:29': 33.0,
  'ffun:10:25000:39': 64.0,
  'adb:15:25000:85': 80.0,
  'ffun_add:10:5000:100': 362.0,
  'adb:15:7500:1': 5.0,
  'mem:15:5000:18': 1.0,
  'mem_40:10:5000:83': 55.0,
  'mem:10:25000:88': 365.0,
  'mem_40:15:30000:88': 422.0,
  'ffun_add:15:30000:49': 118.0,
  'ffun:10:2000:111': 173.0,
  'adb:15:15000:46': 24.0,
  'ffun:10:7500:94': 364.0,
  'ffun:10:20000:83': 527.0,
  'ffun_add:15:10000:38': 21.0,
  'ffun_add:15:5000:6': 9.0,
  'ffun:10:20000:6': 40.0,
  'mem_7:10:7500:26': 2.0,
  'ffun_add:10:30000:5': 27.0,
  'adb:10:7500:26': 7.0,
  'ffun_add:15:10000:92': 453.0,
  'adb:10:15000:22': 12.0,
  'hcb:10:150:32': 31.0,
  'mem_7:10:7500:41': 6.0,
  'mem_7:15:15000:25': 2.0,
  'mem:10:30000:73': 186.0,
  'adb:10:50000:100': 706.0,
  'ffun_add:15:5000:43': 19.0,
  'mem_40:10:40000:103': 1154.0,
  'ffun_add:10:15000:96': 1065.0,
  'mem:10:10000:63': 32.0,
  'ffun_add:15:7500:61': 54.0,
  'dis:15:10000:24': 9.0,
  'ffun:10:30000:92': 1312.0,
  'mem_7:15:2000:5': 1.0,
  'ffun_add:15:10000:105': 679.0,
  'ffun:10:20000:118': 1310.0,
  'adb:10:2000:44': 4.0,
  'ffun_add:15:15000:20': 16.0,
  'ffun:15:50000:82': 884.0,
  'ffun:15:10000:5': 34.0,
  'ffun:10:15000:106': 994.0,
  'mem:15:5000:72': 28.0,
  'mem_40:15:2000:56': 6.0,
  'dis:15:20000:30': 10.0,
  'mem:15:7500:73': 41.0,
  'mem_40:15:25000:0': 6.0,
  'mem_7:15:30000:5': 4.0,
  'ffun:15:30000:60': 156.0,
  'mem_40:15:5000:15': 1.0,
  'adb:10:7500:71': 11.0,
  'mem_7:15:50000:56': 73.0,
  'mem:15:25000:41': 24.0,
  'mem_40:10:7500:83': 83.0,
  'mem:10:30000:102': 865.0,
  'ffun_add:10:40000:103': 2824.0,
  'ffun_add:15:40000:116': 2698.0,
  'mem_40:10:50000:8': 13.0,
  'dis:15:30000:45': 11.0,
  'ffun:15:7500:56': 62.0,
  'mem:10:15000:49': 29.0,
  'mem_40:15:7500:116': 217.0,
  'adb:15:40000:22': 24.0,
  'mem:10:15000:30': 6.0,
  'mem:10:30000:46': 56.0,
  'adb:10:15000:11': 12.0,
  'mem_40:15:2000:67': 8.0,
  'hcb:15:50:44': 3.0,
  'mem:15:20000:5': 5.0,
  'adb:10:5000:36': 6.0,
  'mem_40:10:15000:80': 124.0,
  'mem_40:10:40000:13': 11.0,
  'mem_40:10:7500:117': 217.0,
  'adb:10:15000:71': 20.0,
  'ffun:10:25000:106': 1626.0,
  'mem_7:10:5000:107': 88.0,
  'adb:10:50000:53': 137.0,
  'ffun_add:10:50000:81': 1348.0,
  'ffun_add:10:15000:55': 116.0,
  'mem_40:10:30000:20': 8.0,
  'ffun_add:10:10000:92': 480.0,
  'ffun:15:2000:38': 32.0,
  'ffun_add:15:50000:28': 45.0,
  'mem_7:10:30000:42': 23.0,
  'mem_40:15:7500:22': 2.0,
  'mem_40:10:2000:33': 1.0,
  'ffun_add:15:20000:119': 1353.0,
  'hcb:10:150:59': 31.0,
  'mem_40:15:20000:14': 5.0,
  'ffun_add:15:2000:39': 10.0,
  'ffun_add:10:25000:12': 24.0,
  'mem_40:10:40000:80': 328.0,
  'mem:15:40000:60': 97.0,
  'ffun:15:40000:106': 1953.0,
  'ffun_add:15:25000:117': 1690.0,
  'ffun:10:50000:44': 140.0,
  'mem_40:10:5000:36': 5.0,
  'mem_7:15:50000:83': 308.0,
  'mem:10:2000:48': 5.0,
  'mem_40:15:20000:45': 19.0,
  'ffun_add:10:40000:93': 1889.0,
  'dis:15:10000:55': 10.0,
  'ffun_add:10:2000:27': 9.0,
  'ffun:10:40000:0': 48.0,
  'ffun_add:10:10000:37': 27.0,
  'mem_7:15:15000:63': 24.0,
  'mem:15:2000:97': 59.0,
  'hcb:10:50:53': 4.0,
  'hcb:10:120:55': 21.0,
  'adb:15:7500:62': 4.0,
  'mem_7:10:2000:95': 24.0,
  'mem:10:30000:50': 56.0,
  'hcb:10:80:31': 9.0,
  'adb:10:2000:100': 29.0,
  'mem:15:50000:97': 1444.0,
  'dis:15:20000:52': 11.0,
  'mem_7:10:15000:118': 260.0,
  'adb:10:10000:4': 8.0,
  'ffun:15:40000:108': 1953.0,
  'adb:10:25000:57': 48.0,
  'ffun:10:10000:2': 36.0,
  'ffun:10:50000:5': 53.0,
  'adb:10:7500:47': 16.0,
  'mem:10:15000:40': 12.0,
  'ffun_add:15:40000:71': 504.0,
  'adb:15:5000:18': 4.0,
  'adb:10:10000:119': 142.0,
  'ffun:10:20000:30': 44.0,
  'mem_40:15:50000:101': 1444.0,
  'mem_7:10:15000:43': 12.0,
  'mem:10:10000:91': 193.0,
  'mem:10:10000:73': 62.0,
  'adb:10:30000:63': 19.0,
  'ffun_add:15:5000:17': 10.0,
  'mem_40:15:40000:74': 233.0,
  'ffun:15:40000:91': 1304.0,
  'mem:15:25000:58': 61.0,
  'mem_40:10:2000:64': 7.0,
  'ffun_add:10:2000:65': 24.0,
  'ffun:15:10000:108': 511.0,
  'ffun_add:10:50000:21': 46.0,
  'ffun:15:25000:30': 44.0,
  'mem:15:15000:95': 287.0,
  'mem:15:50000:102': 1444.0,
  'ffun:15:25000:67': 191.0,
  'mem_7:15:2000:31': 1.0,
  'mem_40:15:50000:110': 1444.0,
  'adb:15:7500:33': 6.0,
  'mem_40:10:2000:100': 59.0,
  'adb:10:15000:74': 20.0,
  'mem_40:15:25000:29': 7.0,
  'ffun_add:15:40000:95': 1790.0,
  'ffun_add:15:15000:50': 62.0,
  'adb:10:7500:10': 6.0,
  'mem_7:15:30000:112': 520.0,
  'mem_40:10:30000:25': 10.0,
  'mem:15:30000:55': 66.0,
  'mem_40:10:40000:24': 13.0,
  'adb:15:25000:42': 29.0,
  'ffun_add:10:50000:107': 3527.0,
  'adb:15:20000:88': 97.0,
  'ffun:15:30000:109': 1472.0,
  'mem:15:15000:81': 155.0,
  'mem_40:10:2000:49': 5.0,
  'hcb:10:120:52': 21.0,
  'ffun_add:10:10000:5': 13.0,
  'ffun:10:15000:46': 81.0,
  'mem:15:2000:102': 59.0,
  'ffun:10:10000:59': 97.0,
  'ffun:15:15000:39': 47.0,
  'dis:15:30000:35': 11.0,
  'mem_7:15:15000:23': 2.0,
  'mem:15:40000:114': 1154.0,
  'adb:10:7500:11': 6.0,
  'ffun:15:15000:51': 90.0,
  'mem_7:15:40000:29': 7.0,
  'mem:10:7500:35': 5.0,
  'ffun:10:5000:81': 167.0,
  'mem_40:10:7500:32': 5.0,
  'mem:15:7500:59': 19.0,
  'ffun_add:15:15000:113': 1017.0,
  'ffun:15:10000:119': 511.0,
  'ffun:15:10000:17': 34.0,
  'ffun:15:25000:12': 40.0,
  'mem_7:15:15000:34': 4.0,
  'mem_7:10:5000:13': 1.0,
  'ffun:10:7500:21': 35.0,
  'mem_7:15:7500:106': 131.0,
  'ffun:10:7500:85': 227.0,
  'ffun_add:15:25000:50': 100.0,
  'mem_7:10:25000:16': 5.0,
  'ffun:15:2000:92': 94.0,
  'ffun:10:30000:50': 128.0,
  'mem_7:15:30000:96': 520.0,
  'ffun_add:15:50000:58': 300.0,
  'mem_7:10:20000:80': 98.0,
  'hcb:10:150:47': 33.0,
  'ffun:10:50000:71': 605.0,
  'adb:10:40000:119': 564.0,
  'mem_40:10:20000:70': 92.0,
  'ffun:10:25000:81': 648.0,
  'mem_7:10:2000:61': 5.0,
  'mem:10:25000:40': 19.0,
  'ffun_add:15:30000:95': 1343.0,
  'adb:10:10000:68': 7.0,
  'adb:10:2000:22': 2.0,
  'mem:15:2000:90': 29.0,
  'mem_7:10:15000:31': 5.0,
  'mem_40:10:2000:17': 1.0,
  'ffun_add:10:40000:65': 313.0,
  'mem_7:15:2000:102': 35.0,
  'mem_7:10:2000:79': 11.0,
  'ffun_add:15:20000:82': 485.0,
  'dis:15:50000:42': 13.0,
  'ffun:10:7500:95': 364.0,
  'mem_7:10:10000:63': 20.0,
  'mem:10:30000:107': 865.0,
  'adb:10:15000:99': 212.0,
  'mem_40:15:25000:26': 7.0,
  'ffun_add:15:15000:47': 62.0,
  'ffun_add:10:20000:81': 545.0,
  'adb:10:5000:76': 13.0,
  'mem:15:5000:51': 12.0,
  'ffun_add:10:7500:106': 538.0,
  'mem_7:15:15000:15': 2.0,
  'mem_40:15:10000:47': 16.0,
  'hcb:15:80:55': 8.0,
  'ffun:15:2000:73': 48.0,
  'ffun_add:10:30000:33': 46.0,
  'mem_7:10:7500:39': 4.0,
  'mem_40:15:2000:119': 59.0,
  'adb:15:5000:0': 4.0,
  'dis:10:20000:30': 12.0,
  'mem:10:30000:16': 8.0,
  'mem_40:10:30000:81': 329.0,
  'ffun:15:10000:81': 201.0,
  'adb:10:20000:97': 282.0,
  'mem_40:10:20000:14': 6.0,
  'mem_7:10:50000:99': 866.0,
  'mem_7:15:25000:96': 433.0,
  'mem_7:10:15000:66': 42.0,
  'mem_7:10:7500:22': 2.0,
  'ffun_add:10:20000:87': 720.0,
  'mem:10:30000:101': 865.0,
  'adb:15:7500:84': 24.0,
  'adb:10:40000:15': 31.0,
  'ffun:10:7500:3': 34.0,
  'mem_40:10:10000:2': 4.0,
  'mem_7:10:30000:53': 50.0,
  'ffun:15:2000:110': 126.0,
  'adb:15:25000:71': 29.0,
  'mem_7:15:25000:102': 433.0,
  'mem_40:15:10000:87': 142.0,
  'mem:10:40000:65': 130.0,
  'mem_7:10:15000:12': 2.0,
  'mem_7:10:40000:49': 44.0,
  'ffun:10:40000:51': 261.0,
  'adb:15:20000:7': 12.0,
  'ffun:15:2000:34': 32.0,
  'mem:15:5000:77': 37.0,
  'mem_40:15:2000:115': 59.0,
  'mem:10:2000:19': 1.0,
  'mem:10:2000:91': 40.0,
  'adb:15:15000:89': 73.0,
  'ffun:15:25000:46': 97.0,
  'adb:15:25000:106': 353.0,
  'dis:10:50000:47': 16.0,
  'mem_7:10:7500:64': 16.0,
  'mem:10:50000:3': 13.0,
  'ffun:10:5000:108': 363.0,
  'adb:15:30000:62': 14.0,
  'mem_7:15:5000:111': 88.0,
  'hcb:10:80:46': 9.0,
  'mem_7:10:15000:59': 28.0,
  'mem_7:10:20000:81': 132.0,
  'mem_40:10:15000:50': 29.0,
  'hcb:15:150:40': 27.0,
  'mem_40:10:5000:91': 97.0,
  'adb:15:5000:59': 8.0,
  'mem_40:15:5000:79': 40.0,
  'mem_7:15:2000:72': 7.0,
  'adb:10:40000:59': 76.0,
  'ffun:15:5000:77': 91.0,
  'adb:10:5000:25': 5.0,
  'mem_7:15:25000:9': 4.0,
  'mem:10:40000:60': 120.0,
  'ffun:15:15000:96': 752.0,
  'ffun:15:30000:108': 1472.0,
  'mem_7:15:50000:80': 236.0,
  'ffun:15:10000:21': 35.0,
  'mem_7:15:20000:35': 5.0,
  'dis:15:20000:23': 10.0,
  'ffun:10:40000:37': 84.0,
  'ffun:15:50000:33': 67.0,
  'adb:10:15000:25': 12.0,
  'dis:15:30000:58': 16.0,
  'mem_7:10:40000:2': 6.0,
  'mem:15:15000:42': 14.0,
  'mem_7:10:30000:108': 520.0,
  'ffun:15:40000:99': 1953.0,
  'mem:10:5000:116': 145.0,
  'ffun_add:15:50000:96': 3373.0,
  'mem_7:15:7500:7': 1.0,
  'adb:15:10000:33': 8.0,
  'mem_7:10:7500:32': 2.0,
  'adb:10:50000:39': 58.0,
  'ffun_add:15:10000:101': 679.0,
  'ffun_add:10:20000:11': 20.0,
  'adb:15:40000:41': 46.0,
  'ffun_add:10:2000:32': 9.0,
  'mem_7:10:20000:66': 55.0,
  'dis:10:20000:37': 12.0,
  'ffun:15:25000:45': 74.0,
  'mem_40:15:7500:61': 20.0,
  'adb:15:25000:12': 14.0,
  'ffun:15:30000:9': 42.0,
  'mem:15:50000:54': 110.0,
  'hcb:15:120:41': 18.0,
  'mem:10:5000:33': 4.0,
  'adb:15:7500:21': 5.0,
  'mem:15:7500:68': 30.0,
  'mem:10:2000:56': 6.0,
  'mem_40:10:30000:64': 97.0,
  'mem_40:10:15000:41': 19.0,
  'adb:10:30000:58': 56.0,
  'ffun_add:15:2000:95': 96.0,
  'mem:10:7500:78': 62.0,
  'mem_40:15:25000:23': 6.0,
  'mem_40:10:40000:66': 184.0,
  'mem:15:2000:28': 1.0,
  'mem:15:20000:88': 282.0,
  'adb:15:7500:47': 12.0,
  'ffun:10:15000:10': 38.0,
  'mem:10:15000:28': 6.0,
  'ffun:15:15000:94': 508.0,
  'ffun:15:15000:0': 36.0,
  'mem_7:15:15000:4': 2.0,
  'ffun_add:15:2000:55': 17.0,
  'ffun_add:10:7500:68': 90.0,
  'ffun_add:10:10000:103': 713.0,
  'ffun:10:7500:18': 35.0,
  'adb:15:30000:104': 424.0,
  'mem_40:15:40000:93': 764.0,
  'ffun_add:15:10000:1': 12.0,
  'ffun_add:10:10000:36': 27.0,
  'mem:15:15000:77': 110.0,
  'adb:10:40000:92': 300.0,
  'mem:10:5000:97': 145.0,
  'ffun_add:10:50000:22': 46.0,
  'mem_40:10:10000:6': 4.0,
  'mem_40:15:7500:47': 12.0,
  'ffun_add:15:20000:75': 255.0,
  'ffun_add:15:40000:109': 2698.0,
  'mem_7:10:7500:83': 50.0,
  'mem_40:10:7500:57': 23.0,
  'ffun_add:15:50000:106': 3373.0,
  'ffun_add:15:20000:11': 17.0,
  'ffun_add:15:7500:9': 10.0,
  'mem_7:10:50000:4': 8.0,
  'ffun_add:10:7500:94': 362.0,
  'ffun:10:15000:56': 125.0,
  'ffun:15:30000:91': 985.0,
  'mem:10:40000:19': 12.0,
  'ffun:15:15000:88': 382.0,
  'dis:15:10000:22': 9.0,
  'mem_7:15:5000:25': 1.0,
  'mem_7:15:50000:71': 175.0,
  'ffun:15:5000:15': 32.0,
  'mem:10:15000:32': 8.0,
  'adb:15:20000:81': 64.0,
  'mem:15:20000:70': 78.0,
  'mem:15:5000:56': 13.0,
  'ffun:10:2000:66': 56.0,
  'ffun_add:10:30000:112': 2118.0,
  'ffun:10:25000:21': 44.0,
  'mem_7:15:20000:50': 18.0,
  'ffun_add:10:15000:0': 17.0,
  'ffun:15:10000:83': 201.0,
  'adb:10:25000:45': 37.0,
  'adb:15:15000:59': 23.0,
  'adb:15:10000:90': 49.0,
  'ffun:10:10000:14': 36.0,
  'mem_7:10:10000:6': 2.0,
  'mem:15:30000:80': 221.0,
  'mem:15:10000:108': 289.0,
  'mem_7:10:50000:42': 37.0,
  'ffun:15:25000:22': 41.0,
  'hcb:10:80:49': 9.0,
  'mem:15:30000:3': 6.0,
  'ffun:15:50000:65': 252.0,
  'adb:15:7500:88': 37.0,
  'mem_40:15:50000:108': 1444.0,
  'dis:15:50000:35': 12.0,
  'mem:10:15000:21': 5.0,
  'adb:10:15000:12': 12.0,
  'ffun:15:40000:29': 52.0,
  'ffun:15:15000:118': 752.0,
  'ffun_add:10:40000:16': 35.0,
  'adb:15:40000:74': 44.0,
  'mem:15:7500:16': 2.0,
  'mem:10:10000:55': 29.0,
  'ffun_add:10:20000:68': 225.0,
  'ffun_add:15:7500:58': 51.0,
  'ffun_add:10:5000:119': 362.0,
  'mem:10:10000:116': 289.0,
  'adb:10:7500:99': 107.0,
  'mem_7:10:10000:74': 37.0,
  'mem_40:15:25000:80': 197.0,
  'mem:15:15000:45': 14.0,
  'mem:10:50000:109': 1444.0,
  'mem_7:15:15000:93': 173.0,
  'adb:10:50000:114': 706.0,
  'mem_7:10:15000:57': 28.0,
  'mem_40:10:5000:1': 2.0,
  'ffun:15:30000:43': 82.0,
  'mem_40:15:10000:25': 2.0,
  'mem_7:15:10000:12': 2.0,
  'mem:15:7500:66': 30.0,
  'ffun_add:10:7500:75': 118.0,
  'ffun_add:15:50000:81': 1202.0,
  'ffun:10:40000:21': 52.0,
  'mem_7:10:2000:21': 1.0,
  'ffun:10:20000:16': 41.0,
  'mem:15:40000:68': 155.0,
  'ffun_add:15:40000:113': 2698.0,
  'ffun_add:15:15000:114': 1017.0,
  'ffun:15:15000:120': 752.0,
  'ffun:10:40000:106': 2574.0,
  'ffun_add:10:50000:19': 42.0,
  'ffun_add:10:50000:87': 1785.0,
  'ffun_add:15:25000:65': 161.0,
  'ffun:10:5000:68': 81.0,
  'mem:10:50000:19': 14.0,
  'ffun:10:40000:24': 52.0,
  'ffun:10:20000:56': 154.0,
  'mem_7:15:7500:79': 36.0,
  'adb:10:30000:59': 56.0,
  'ffun:10:10000:111': 679.0,
  'ffun_add:15:40000:80': 692.0,
  'hcb:15:100:39': 12.0,
  'ffun_add:10:5000:66': 63.0,
  'mem:10:2000:53': 6.0,
  'ffun:10:15000:117': 994.0,
  'ffun:10:30000:61': 217.0,
  'adb:15:20000:1': 12.0,
  'adb:10:20000:103': 282.0,
  'mem_7:15:2000:52': 4.0,
  'hcb:10:80:58': 9.0,
  'mem_40:15:15000:116': 433.0,
  'mem_40:10:5000:7': 2.0,
  'adb:10:7500:92': 56.0,
  'mem_7:10:40000:19': 7.0,
  'ffun:15:30000:71': 297.0,
  'mem_40:15:20000:16': 5.0,
  'ffun:10:10000:87': 365.0,
  'mem_40:10:20000:97': 577.0,
  'ffun_add:10:7500:73': 118.0,
  'ffun_add:10:40000:28': 46.0,
  'mem_7:15:30000:23': 5.0,
  'mem:15:25000:10': 6.0,
  'mem:10:30000:79': 246.0,
  'mem_7:10:30000:97': 520.0,
  'ffun_add:15:20000:78': 349.0,
  'hcb:10:150:21': 30.0,
  'adb:15:30000:114': 424.0,
  'mem_40:10:30000:74': 186.0,
  'adb:10:40000:107': 564.0,
  'ffun:15:40000:66': 287.0,
  'mem_7:15:5000:48': 5.0,
  'mem_40:15:5000:17': 1.0,
  'ffun_add:15:50000:41': 128.0,
  'mem_40:15:15000:75': 88.0,
  'adb:10:5000:86': 25.0,
  'mem_7:10:50000:61': 97.0,
  'mem_40:15:15000:79': 119.0,
  'ffun_add:10:2000:42': 14.0,
  'adb:10:15000:7': 12.0,
  'mem:15:2000:60': 6.0,
  'ffun_add:15:10000:79': 178.0,
  'mem:10:7500:11': 2.0,
  'adb:10:15000:87': 76.0,
  'mem:15:10000:40': 6.0,
  'adb:10:25000:75': 34.0,
  'mem:10:50000:30': 19.0,
  'adb:10:25000:95': 188.0,
  'mem_40:10:40000:31': 20.0,
  'mem_40:10:20000:2': 6.0,
  'ffun_add:15:30000:118': 2025.0,
  'mem_7:10:30000:3': 5.0,
  'mem_7:15:2000:22': 1.0,
  'hcb:10:150:18': 30.0,
  'mem_7:10:2000:37': 1.0,
  'ffun:10:7500:86': 286.0,
  'adb:15:30000:87': 145.0,
  'mem_40:10:20000:109': 577.0,
  'adb:10:2000:27': 2.0,
  'ffun:10:2000:37': 35.0,
  'mem_7:15:15000:100': 260.0,
  'mem_40:10:2000:0': 1.0,
  'ffun_add:15:20000:56': 124.0,
  'adb:15:10000:64': 5.0,
  'ffun_add:10:30000:93': 1418.0,
  'ffun:15:2000:85': 64.0,
  'mem_7:10:40000:93': 463.0,
  'mem_7:10:25000:92': 289.0,
  'mem_40:15:2000:9': 1.0,
  'ffun_add:10:40000:47': 196.0,
  'mem:10:5000:0': 2.0,
  'adb:10:50000:58': 95.0,
  'ffun:15:20000:44': 65.0,
  'ffun_add:15:25000:15': 20.0,
  'ffun_add:15:10000:9': 12.0,
  'ffun:10:50000:97': 3205.0,
  'ffun_add:10:25000:102': 1769.0,
  'mem_7:15:10000:50': 10.0,
  'ffun_add:15:40000:41': 104.0,
  'mem_40:15:2000:95': 38.0,
  'adb:15:30000:12': 18.0,
  'hcb:10:50:19': 3.0,
  'mem:10:2000:109': 59.0,
  'ffun:15:10000:69': 95.0,
  'ffun_add:15:30000:3': 23.0,
  'mem_40:15:7500:1': 2.0,
  'adb:10:15000:93': 113.0,
  'mem_40:15:50000:91': 956.0,
  'mem:15:2000:71': 11.0,
  'mem_7:10:10000:3': 2.0,
  'ffun_add:10:40000:72': 593.0,
  'mem:15:7500:88': 106.0,
  'mem:10:7500:89': 109.0,
  'mem_7:15:2000:65': 4.0,
  'mem_40:15:25000:52': 55.0,
  'ffun_add:10:25000:38': 56.0,
  'mem_40:10:25000:95': 482.0,
  'ffun_add:15:50000:10': 34.0,
  'mem_40:10:10000:25': 4.0,
  'ffun_add:10:15000:75': 228.0,
  'ffun:15:20000:15': 38.0,
  'mem_40:10:20000:19': 6.0,
  'mem:15:40000:21': 10.0,
  'mem_40:15:10000:104': 289.0,
  'ffun:15:30000:17': 42.0,
  'mem_7:10:40000:99': 692.0,
  'mem:10:10000:45': 13.0,
  'mem_7:15:20000:76': 95.0,
  'ffun_add:15:15000:24': 16.0,
  'mem_40:10:7500:46': 14.0,
  'mem_7:10:20000:110': 347.0,
  'mem_40:15:15000:47': 23.0,
  'ffun:15:5000:55': 50.0,
  'mem_7:15:30000:1': 4.0,
  'mem_40:10:7500:49': 14.0,
  'mem_40:15:25000:60': 61.0,
  'ffun:15:25000:114': 1232.0,
  'mem:10:15000:11': 5.0,
  'ffun_add:15:2000:116': 142.0,
  'adb:10:10000:79': 25.0,
  'ffun:10:40000:62': 276.0,
  'mem:15:40000:110': 1154.0,
  'adb:15:10000:4': 6.0,
  'hcb:15:100:38': 12.0,
  'ffun:15:20000:7': 38.0,
  'adb:15:15000:102': 212.0,
  'ffun_add:10:30000:88': 1074.0,
  'mem_7:10:15000:114': 260.0,
  'mem_7:15:15000:105': 260.0,
  'adb:15:10000:45': 12.0,
  'mem_40:15:10000:100': 289.0,
  'ffun:15:5000:114': 271.0,
  'mem_7:10:2000:75': 8.0,
  'mem:10:10000:71': 62.0,
  'adb:15:5000:97': 71.0,
  'dis:15:40000:33': 12.0,
  'adb:15:5000:95': 37.0,
  'mem:15:30000:61': 80.0,
  'dis:15:10000:34': 9.0,
  'adb:10:25000:102': 353.0,
  'adb:15:5000:33': 5.0,
  'ffun:10:10000:16': 36.0,
  'mem_40:15:20000:50': 30.0,
  'mem_40:10:40000:91': 772.0,
  'ffun_add:10:40000:76': 782.0,
  'adb:10:7500:90': 38.0,
  'mem_7:15:2000:25': 1.0,
  'hcb:10:50:55': 4.0,
  'ffun:15:15000:42': 56.0,
  'mem_7:10:5000:51': 8.0,
  'mem_40:15:25000:56': 61.0,
  'adb:15:5000:48': 8.0,
  'ffun:15:20000:110': 992.0,
  'mem:15:15000:80': 110.0,
  'dis:15:30000:33': 11.0,
  'ffun_add:10:20000:7': 20.0,
  'adb:10:40000:10': 31.0,
  'ffun_add:15:15000:66': 142.0,
  'ffun_add:15:40000:92': 1790.0,
  'mem_7:15:2000:91': 24.0,
  'dis:10:40000:43': 14.0,
  'mem_7:10:10000:44': 8.0,
  'mem_7:15:50000:7': 6.0,
  'adb:15:50000:103': 706.0,
  'ffun:15:25000:69': 191.0,
  'mem_7:15:40000:19': 6.0,
  'mem_40:10:40000:71': 247.0,
  'mem_7:10:15000:67': 42.0,
  'ffun_add:15:50000:46': 194.0,
  'ffun_add:10:2000:119': 151.0,
  'ffun_add:10:7500:11': 12.0,
  'mem_40:15:30000:13': 7.0,
  'ffun_add:10:7500:76': 154.0,
  'mem_7:10:10000:45': 8.0,
  'adb:10:5000:11': 5.0,
  'adb:10:30000:71': 41.0,
  'mem_40:10:40000:64': 130.0,
  'ffun_add:10:15000:102': 1065.0,
  'adb:15:20000:102': 282.0,
  'adb:15:50000:119': 706.0,
  'ffun:10:50000:84': 1248.0,
  'mem:15:30000:52': 66.0,
  'ffun_add:10:2000:10': 8.0,
  'adb:15:30000:47': 47.0,
  'mem_7:15:50000:87': 422.0,
  'hcb:15:120:35': 17.0,
  'mem_40:15:50000:8': 11.0,
  'mem:15:5000:86': 71.0,
  'ffun_add:10:7500:80': 154.0,
  'dis:15:40000:21': 11.0,
  'mem_7:15:10000:117': 174.0,
  'adb:10:10000:23': 8.0,
  'mem_7:10:7500:72': 29.0,
  'mem:10:7500:96': 217.0,
  'ffun_add:15:20000:25': 19.0,
  'adb:15:30000:45': 35.0,
  'ffun:15:30000:10': 42.0,
  'hcb:15:80:47': 8.0,
  'ffun:15:30000:47': 110.0,
  'mem_40:10:15000:77': 124.0,
  'adb:15:25000:98': 353.0,
  'mem_40:15:25000:9': 6.0,
  'mem:15:5000:9': 1.0,
  'mem_40:10:40000:46': 74.0,
  'mem_7:15:30000:100': 520.0,
  'mem:15:7500:38': 5.0,
  'mem:10:7500:9': 2.0,
  'mem_40:10:40000:60': 120.0,
  'hcb:10:80:35': 9.0,
  'mem_7:15:10000:53': 13.0,
  'mem_7:15:10000:22': 2.0,
  'mem_40:15:50000:34': 20.0,
  'ffun_add:15:7500:100': 511.0,
  'adb:10:5000:115': 71.0,
  'mem:15:2000:17': 1.0,
  'adb:15:50000:60': 74.0,
  'adb:15:7500:56': 12.0,
  'mem_7:10:2000:28': 1.0,
  'mem:15:20000:85': 205.0,
  'mem_40:15:30000:21': 7.0,
  'ffun:15:10000:60': 72.0,
  'ffun_add:15:50000:87': 1648.0,
  'ffun_add:15:7500:51': 48.0,
  'mem:15:5000:87': 71.0,
  'ffun:15:5000:53': 50.0,
  'ffun_add:15:20000:94': 898.0,
  'adb:15:20000:69': 10.0,
  'adb:10:7500:93': 56.0,
  'adb:15:50000:33': 37.0,
  'ffun_add:10:7500:35': 17.0,
  'adb:10:15000:83': 52.0,
  'ffun_add:15:50000:70': 457.0,
  'mem_7:10:40000:37': 19.0,
  'ffun:15:5000:52': 50.0,
  'mem_40:10:10000:104': 289.0,
  'mem:10:2000:100': 59.0,
  'mem_40:15:25000:51': 55.0,
  'ffun:10:40000:116': 2574.0,
  'mem:10:30000:71': 186.0,
  'mem_40:10:40000:2': 11.0,
  'adb:15:2000:51': 5.0,
  'ffun_add:15:50000:36': 83.0,
  'mem:10:30000:47': 56.0,
  'mem_7:10:20000:35': 7.0,
  'mem:15:20000:52': 44.0,
  'mem:15:5000:69': 20.0,
  'mem_7:15:2000:46': 2.0,
  'mem:10:25000:111': 722.0,
  'hcb:15:80:39': 8.0,
  'mem:15:25000:37': 16.0,
  'mem_40:15:5000:116': 145.0,
  'mem_40:15:10000:19': 2.0,
  'mem:10:15000:25': 5.0,
  'mem_7:15:5000:77': 24.0,
  'mem:10:2000:37': 2.0,
  'mem_40:10:5000:89': 73.0,
  'mem_7:15:10000:0': 1.0,
  'mem:10:20000:119': 577.0,
  'ffun_add:10:2000:114': 151.0,
  'mem_7:15:10000:65': 17.0,
  'mem_40:10:25000:42': 31.0,
  'mem_7:15:30000:82': 185.0,
  'ffun_add:15:2000:113': 142.0,
  'ffun:15:20000:59': 114.0,
  'dis:15:10000:33': 9.0,
  'mem_7:15:25000:45': 14.0,
  'mem_7:10:25000:64': 49.0,
  'ffun_add:10:2000:109': 151.0,
  'mem:15:30000:32': 12.0,
  'mem_40:10:2000:57': 6.0,
  'ffun_add:15:7500:87': 252.0,
  'adb:15:7500:58': 12.0,
  'adb:15:15000:120': 212.0,
  'mem_40:10:2000:96': 59.0,
  'ffun:10:20000:43': 77.0,
  'mem:10:25000:37': 19.0,
  'mem_7:15:5000:89': 43.0,
  'mem_40:10:2000:23': 1.0,
  'mem_40:15:30000:36': 18.0,
  'mem_40:10:25000:94': 482.0,
  'ffun_add:10:5000:34': 13.0,
  'mem:15:2000:3': 1.0,
  'ffun:15:2000:81': 64.0,
  'mem_7:15:5000:0': 1.0,
  'mem_40:10:7500:27': 4.0,
  'ffun:15:10000:103': 511.0,
  'ffun_add:10:5000:103': 362.0,
  'mem:10:40000:59': 120.0,
  'mem_40:10:30000:50': 56.0,
  'adb:10:50000:77': 122.0,
  'mem_7:15:30000:48': 26.0,
  'mem:15:30000:118': 865.0,
  'ffun_add:10:25000:99': 1769.0,
  'mem_40:15:20000:74': 116.0,
  'mem_40:10:30000:104': 865.0,
  'mem_7:15:30000:15': 5.0,
  'ffun:15:7500:95': 269.0,
  'adb:15:25000:93': 186.0,
  'ffun_add:10:40000:15': 34.0,
  'mem_7:15:20000:64': 32.0,
  'mem:15:30000:119': 865.0,
  'hcb:15:150:32': 27.0,
  'mem:15:40000:39': 24.0,
  'adb:10:7500:18': 6.0,
  'ffun:10:40000:81': 1008.0,
  'adb:10:25000:18': 19.0,
  'mem:10:50000:73': 310.0,
  'ffun_add:15:50000:31': 58.0,
  'ffun_add:10:30000:86': 1074.0,
  'mem_7:15:5000:28': 1.0,
  'mem:15:5000:60': 13.0,
  'mem:15:7500:10': 2.0,
  'ffun:10:25000:14': 42.0,
  'ffun:15:5000:22': 33.0,
  'ffun:15:25000:106': 1232.0,
  'ffun:10:25000:9': 42.0,
  'ffun_add:10:25000:5': 23.0,
  'adb:15:50000:46': 78.0,
  'mem_7:15:10000:34': 2.0,
  'mem_40:15:5000:70': 20.0,
  'mem:15:10000:61': 28.0,
  'dis:10:10000:20': 11.0,
  'mem_40:10:40000:112': 1154.0,
  'ffun:10:25000:120': 1626.0,
  'mem_40:15:2000:18': 1.0,
  'ffun_add:10:7500:100': 538.0,
  'adb:10:30000:14': 23.0,
  'ffun_add:15:2000:23': 7.0,
  'adb:10:40000:24': 31.0,
  'mem:15:7500:77': 55.0,
  'mem_7:15:7500:59': 12.0,
  'mem_7:10:30000:50': 34.0,
  'mem_7:10:30000:87': 263.0,
  'ffun_add:10:2000:91': 104.0,
  'mem_40:10:15000:104': 433.0,
  'mem:10:7500:120': 217.0,
  'mem_40:10:15000:112': 433.0,
  'adb:15:50000:98': 706.0,
  'mem_7:10:50000:67': 138.0,
  'mem_7:15:7500:22': 1.0,
  'hcb:15:150:36': 27.0,
  'mem_7:15:2000:14': 1.0,
  'mem_40:10:30000:85': 329.0,
  'adb:15:25000:18': 14.0,
  'mem_7:15:7500:118': 131.0,
  'mem_40:15:15000:111': 433.0,
  'mem_7:10:20000:51': 34.0,
  'mem_40:10:5000:54': 14.0,
  'ffun:10:25000:109': 1626.0,
  'mem_40:15:10000:120': 289.0,
  'mem:10:50000:95': 964.0,
  'ffun_add:10:10000:6': 13.0,
  'ffun:10:20000:40': 58.0,
  'mem:10:40000:6': 11.0,
  'mem_40:15:15000:120': 433.0,
  'ffun_add:15:25000:108': 1690.0,
  'mem_40:10:30000:49': 56.0,
  'hcb:10:120:53': 21.0,
  'dis:10:50000:53': 17.0,
  'mem_7:10:20000:10': 4.0,
  'mem_7:15:5000:78': 24.0,
  'dis:15:20000:47': 10.0,
  'adb:10:15000:44': 23.0,
  'ffun_add:15:50000:4': 34.0,
  'ffun_add:15:25000:115': 1690.0,
  'mem_40:10:20000:82': 220.0,
  'mem:10:2000:30': 1.0,
  'adb:15:50000:68': 23.0,
  'mem:10:25000:98': 722.0,
  'ffun_add:10:15000:114': 1065.0,
  'ffun:10:30000:17': 45.0,
  'mem_40:10:30000:87': 437.0,
  'hcb:15:80:59': 7.0,
  'ffun:10:15000:45': 66.0,
  'mem:10:5000:101': 145.0,
  'adb:10:2000:104': 29.0,
  'ffun:10:7500:55': 81.0,
  'mem_7:10:2000:4': 1.0,
  'mem_40:15:10000:28': 4.0,
  'mem_40:10:25000:47': 47.0,
  'mem:15:7500:90': 106.0,
  'ffun_add:15:20000:8': 17.0,
  'mem:10:15000:42': 19.0,
  'adb:15:10000:83': 32.0,
  'mem_40:15:25000:110': 722.0,
  'mem:10:10000:72': 62.0,
  'dis:10:40000:19': 13.0,
  'ffun_add:15:2000:16': 7.0,
  'mem:15:25000:43': 24.0,
  'mem:15:30000:5': 6.0,
  'mem:15:2000:100': 59.0,
  'ffun:15:30000:112': 1472.0,
  'ffun:15:20000:67': 159.0,
  'ffun:15:30000:70': 223.0,
  'mem:10:15000:50': 29.0,
  'mem_40:10:2000:73': 13.0,
  'dis:15:50000:44': 13.0,
  'adb:10:5000:65': 4.0,
  'ffun_add:10:2000:90': 81.0,
  'ffun_add:15:30000:82': 723.0,
  'ffun:15:2000:111': 126.0,
  'hcb:10:120:40': 20.0,
  'mem:15:5000:4': 1.0,
  'adb:10:10000:117': 142.0,
  'mem:10:40000:110': 1154.0,
  'mem_40:15:10000:21': 2.0,
  'ffun_add:15:7500:80': 135.0,
  'ffun_add:10:10000:24': 15.0,
  'ffun:15:25000:116': 1232.0,
  'mem_40:15:40000:65': 107.0,
  'mem:10:30000:99': 865.0,
  'adb:15:25000:68': 12.0,
  'ffun_add:15:2000:30': 9.0,
  'mem:10:5000:25': 2.0,
  'mem:15:7500:1': 2.0,
  'adb:10:7500:23': 6.0,
  'adb:15:25000:60': 37.0,
  'adb:15:30000:116': 424.0,
  'mem_40:15:40000:116': 1154.0,
  'mem_7:10:30000:55': 50.0,
  'ffun:10:10000:18': 36.0,
  'adb:10:5000:117': 71.0,
  'adb:10:20000:21': 16.0,
  'ffun_add:15:7500:57': 51.0,
  'ffun:10:7500:48': 57.0,
  'hcb:10:80:21': 9.0,
  'mem_40:10:50000:11': 13.0,
  'ffun:15:40000:72': 386.0,
  'adb:10:7500:25': 6.0,
  'adb:10:50000:106': 706.0,
  'ffun:15:2000:33': 32.0,
  'mem_7:15:15000:76': 71.0,
  'adb:15:15000:114': 212.0,
  'mem_40:15:20000:110': 577.0,
  'adb:15:10000:61': 5.0,
  'ffun_add:10:20000:104': 1416.0,
  'mem_40:10:50000:117': 1444.0,
  'ffun_add:10:2000:78': 47.0,
  'mem:10:15000:8': 5.0,
  'mem_40:15:7500:36': 5.0,
  'ffun_add:10:50000:96': 3527.0,
  'mem_7:15:7500:34': 2.0,
  'ffun_add:10:2000:53': 23.0,
  'mem_7:10:2000:5': 1.0,
  'ffun_add:15:5000:74': 69.0,
  'adb:15:10000:34': 8.0,
  'mem_7:15:25000:44': 14.0,
  'ffun_add:15:50000:18': 35.0,
  'ffun:10:2000:101': 173.0,
  'mem_7:10:10000:59': 18.0,
  'mem_7:10:40000:78': 197.0,
  'mem:10:50000:33': 26.0,
  'ffun:15:30000:55': 149.0,
  'ffun:15:30000:72': 297.0,
  'mem_7:10:7500:60': 14.0,
  'mem:15:2000:47': 4.0,
  'adb:10:2000:101': 29.0,
  'hcb:10:80:29': 9.0,
  'adb:10:40000:8': 31.0,
  'mem:15:20000:78': 148.0,
  'mem:10:2000:44': 4.0,
  'mem_7:15:15000:22': 2.0,
  'hcb:15:120:28': 17.0,
  'mem_7:10:40000:39': 19.0,
  'adb:10:40000:80': 97.0,
  'ffun:15:2000:119': 126.0,
  'mem_7:10:30000:113': 520.0,
  'mem:15:15000:32': 6.0,
  'hcb:10:120:30': 20.0,
  'dis:10:20000:58': 18.0,
  'mem_40:10:20000:84': 220.0,
  'adb:10:10000:104': 142.0,
  'dis:15:20000:39': 10.0,
  'mem:15:50000:72': 268.0,
  'dis:10:20000:34': 12.0,
  'adb:15:15000:44': 18.0,
  'ffun_add:10:15000:8': 17.0,
  'adb:15:30000:31': 23.0,
  'adb:10:40000:113': 564.0,
  'adb:10:5000:119': 71.0,
  'mem_40:10:2000:113': 59.0,
  'adb:10:10000:100': 142.0,
  'adb:10:10000:26': 8.0,
  'mem_7:10:30000:16': 6.0,
  'dis:15:20000:44': 10.0,
  'mem_40:10:40000:42': 49.0,
  'ffun:15:7500:11': 33.0,
  'mem:10:10000:11': 4.0,
  'mem_7:10:7500:42': 6.0,
  'mem:10:40000:12': 11.0,
  'mem_40:10:15000:22': 5.0,
  'adb:10:40000:77': 97.0,
  'ffun:15:5000:74': 75.0,
  'mem_40:15:50000:71': 290.0,
  'mem_40:10:5000:28': 2.0,
  'adb:15:10000:113': 142.0,
  'mem:10:30000:69': 138.0,
  'adb:15:30000:70': 14.0,
  'mem:10:30000:95': 578.0,
  'adb:15:15000:79': 32.0,
  'mem_7:15:20000:114': 347.0,
  'ffun:15:10000:97': 511.0,
  'ffun_add:10:25000:34': 39.0,
  'mem_40:15:50000:43': 47.0,
  'ffun:15:30000:80': 397.0,
  'adb:10:25000:5': 19.0,
  'ffun:10:40000:63': 276.0,
  'ffun:15:2000:64': 39.0,
  'ffun_add:15:2000:8': 7.0,
  'mem:15:30000:76': 221.0,
  'adb:15:50000:29': 32.0,
  'ffun:15:25000:7': 40.0,
  'mem:15:15000:50': 23.0,
  'mem:10:20000:23': 7.0,
  'ffun:10:50000:11': 53.0,
  'mem:10:15000:38': 12.0,
  'ffun:15:25000:92': 826.0,
  'mem_40:10:10000:55': 29.0,
  'ffun:10:30000:87': 1002.0,
  'ffun:10:50000:62': 334.0,
  'adb:15:7500:65': 4.0,
  'ffun_add:10:50000:2': 39.0,
  'ffun:10:2000:51': 51.0,
  'ffun:15:15000:63': 97.0,
  'mem:15:20000:112': 577.0,
  'adb:15:15000:81': 48.0,
  'mem_7:15:20000:53': 26.0,
  'mem_40:10:20000:65': 65.0,
  'mem_7:15:40000:10': 5.0,
  'mem:10:10000:76': 83.0,
  'mem_7:10:40000:59': 72.0,
  'adb:10:15000:9': 12.0,
  'ffun:10:40000:95': 1734.0,
  'ffun_add:15:40000:3': 28.0,
  'ffun_add:15:5000:36': 14.0,
  'mem:15:7500:86': 106.0,
  'ffun:10:15000:47': 81.0,
  'ffun_add:15:2000:18': 7.0,
  'adb:10:2000:85': 7.0,
  'mem_7:15:25000:54': 34.0,
  'adb:10:5000:47': 11.0,
  'mem_40:10:5000:30': 2.0,
  'ffun:10:5000:46': 49.0,
  'mem_7:10:20000:114': 347.0,
  'ffun_add:15:10000:60': 65.0,
  'dis:10:40000:42': 14.0,
  'mem_7:10:50000:69': 138.0,
  'ffun:10:2000:91': 131.0,
  'ffun_add:15:30000:89': 991.0,
  'mem_40:10:2000:102': 59.0,
  'adb:15:10000:37': 10.0,
  'ffun:10:2000:43': 38.0,
  'mem_7:15:7500:120': 131.0,
  'mem:10:30000:44': 37.0,
  'mem_40:15:10000:80': 79.0,
  'mem_7:15:7500:23': 1.0,
  'ffun:15:25000:91': 826.0,
  'mem_40:15:50000:87': 704.0,
  'adb:10:10000:29': 8.0,
  'ffun:10:30000:47': 128.0,
  'adb:10:2000:114': 29.0,
  'dis:15:10000:58': 14.0,
  'mem_40:15:15000:67': 59.0,
  'adb:15:50000:48': 78.0,
  'adb:15:50000:62': 23.0,
  'mem_7:15:40000:77': 188.0,
  'mem_40:15:10000:73': 59.0,
  'mem_7:15:5000:43': 4.0,
  'mem:10:40000:18': 12.0,
  'mem_7:10:20000:21': 5.0,
  'ffun:15:10000:79': 153.0,
  'ffun:15:15000:65': 97.0,
  'ffun_add:10:5000:56': 46.0,
  'mem_40:10:40000:61': 130.0,
  'mem:10:25000:8': 7.0,
  'mem_40:15:25000:13': 6.0,
  'ffun:15:20000:48': 84.0,
  'mem_40:15:40000:26': 12.0,
  'mem_7:15:10000:31': 2.0,
  'mem_7:15:7500:54': 11.0,
  'ffun_add:15:25000:62': 161.0,
  'ffun_add:10:30000:15': 27.0,
  'mem:15:2000:109': 59.0,
  'mem_40:10:15000:71': 94.0,
  'ffun_add:15:10000:37': 21.0,
  'mem_7:10:15000:8': 2.0,
  'adb:15:50000:100': 706.0,
  'ffun_add:10:40000:1': 34.0,
  'mem:15:20000:82': 205.0,
  'mem_7:10:5000:39': 2.0,
  'hcb:15:120:54': 18.0,
  'mem_40:15:5000:46': 8.0,
  'mem:15:2000:8': 1.0,
  'ffun:15:30000:4': 42.0,
  'ffun_add:15:50000:84': 1202.0,
  'adb:10:50000:32': 47.0,
  'mem_40:10:2000:63': 7.0,
  'ffun_add:15:50000:17': 35.0,
  'mem_40:10:5000:103': 145.0,
  'mem_40:15:20000:115': 577.0,
  'mem_40:15:30000:6': 6.0,
  'hcb:15:50:50': 3.0,
  'adb:15:20000:3': 12.0,
  'mem_40:15:40000:57': 97.0,
  'mem_40:10:10000:10': 4.0,
  'ffun:10:30000:70': 292.0,
  'ffun_add:15:40000:11': 28.0,
  'dis:15:40000:52': 13.0,
  'mem:10:10000:38': 8.0,
  'mem_40:10:50000:12': 13.0,
  'ffun_add:15:30000:63': 192.0,
  'ffun:15:20000:56': 114.0,
  'mem:10:40000:88': 583.0,
  'mem:15:20000:30': 6.0,
  'ffun:15:20000:105': 992.0,
  'adb:10:5000:6': 5.0,
  'mem_7:15:40000:51': 53.0,
  'mem_40:10:20000:107': 577.0,
  'ffun:15:5000:75': 75.0,
  'ffun:10:10000:48': 65.0,
  'mem_40:15:20000:63': 54.0,
  'ffun_add:15:50000:112': 3373.0,
  'ffun_add:10:5000:48': 31.0,
  'ffun:15:2000:45': 34.0,
  'mem_7:15:30000:35': 7.0,
  'mem:15:40000:32': 17.0,
  'dis:10:10000:21': 11.0,
  'mem_7:15:20000:11': 4.0,
  'mem:10:2000:62': 7.0,
  'mem_7:15:15000:14': 2.0,
  'ffun_add:15:15000:90': 499.0,
  'mem_7:15:25000:82': 155.0,
  'adb:10:50000:69': 31.0,
  'mem_40:15:25000:21': 6.0,
  'mem:15:7500:70': 30.0,
  'mem:10:30000:96': 865.0,
  'ffun_add:10:50000:23': 46.0,
  'mem_7:15:2000:94': 24.0,
  'mem_40:15:25000:57': 61.0,
  'adb:10:15000:62': 10.0,
  'ffun:15:25000:33': 49.0,
  'mem:10:25000:5': 7.0,
  'ffun:15:5000:115': 271.0,
  'mem_40:10:30000:51': 84.0,
  'mem:15:25000:92': 479.0,
  'mem_7:15:40000:119': 692.0,
  'mem_40:15:40000:75': 233.0,
  'mem_7:10:30000:112': 520.0,
  'mem_7:15:30000:101': 520.0,
  'mem_7:15:15000:17': 2.0,
  'adb:10:15000:58': 29.0,
  'mem_40:10:15000:15': 5.0,
  'adb:10:15000:43': 23.0,
  'mem_7:15:10000:80': 48.0,
  'ffun:15:25000:90': 617.0,
  'dis:15:20000:40': 10.0,
  'ffun_add:15:15000:65': 100.0,
  'ffun:15:7500:64': 64.0,
  'adb:15:2000:106': 29.0,
  'ffun_add:15:7500:72': 100.0,
  'adb:10:20000:45': 30.0,
  'mem_40:10:50000:9': 13.0,
  'ffun:15:10000:43': 48.0,
  'mem:15:7500:55': 17.0,
  'mem:15:2000:66': 8.0,
  'ffun_add:10:10000:50': 55.0,
  'adb:10:50000:8': 38.0,
  'adb:10:40000:18': 31.0,
  'dis:10:40000:53': 16.0,
  'ffun_add:10:25000:24': 27.0,
  'dis:10:40000:50': 14.0,
  'ffun:15:15000:69': 127.0,
  'ffun:10:20000:79': 339.0,
  'mem_40:15:2000:92': 38.0,
  'mem:10:40000:44': 49.0,
  'ffun_add:15:7500:78': 135.0,
  'mem:10:20000:60': 60.0,
  'ffun:10:50000:36': 96.0,
  'mem_7:10:50000:49': 56.0,
  'ffun:15:7500:31': 36.0,
  'mem:10:50000:98': 1444.0,
  'adb:10:2000:42': 4.0,
  'mem:15:50000:69': 193.0,
  'ffun:10:15000:102': 994.0,
  'adb:10:5000:39': 6.0,
  'ffun:10:40000:33': 66.0,
  'ffun_add:15:25000:104': 1690.0,
  'mem_7:15:7500:87': 64.0,
  'ffun:15:20000:85': 372.0,
  'mem_7:15:2000:2': 1.0,
  'dis:10:40000:32': 14.0,
  'adb:15:40000:110': 564.0,
  'mem_40:15:50000:27': 14.0,
  'ffun:10:15000:29': 41.0,
  'mem_40:15:5000:16': 1.0,
  'ffun:15:7500:117': 391.0,
  'ffun_add:15:25000:63': 161.0,
  'mem_7:15:2000:116': 35.0,
  'mem_40:15:50000:24': 12.0,
  'mem_7:15:7500:28': 2.0,
  'mem_40:10:7500:4': 2.0,
  'ffun_add:15:2000:9': 7.0,
  'ffun:15:15000:58': 93.0,
  'adb:15:25000:32': 19.0,
  'mem:10:10000:84': 110.0,
  'ffun_add:10:25000:68': 280.0,
  'ffun_add:15:25000:94': 1122.0,
  'adb:10:7500:45': 12.0,
  'mem_7:10:30000:62': 59.0,
  'mem_7:10:20000:116': 347.0,
  'mem:10:40000:107': 1154.0,
  'mem_40:15:15000:107': 433.0,
  'mem_7:15:2000:67': 5.0,
  'mem:10:20000:47': 37.0,
  'mem_40:15:50000:79': 394.0,
  'mem_40:15:5000:23': 1.0,
  'ffun_add:10:15000:53': 116.0,
  'mem_7:10:5000:97': 88.0,
  'mem_40:10:20000:119': 577.0,
  'ffun:10:30000:98': 1940.0,
  'mem_7:15:5000:117': 88.0,
  'ffun_add:15:5000:91': 230.0,
  'ffun_add:10:25000:16': 24.0,
  'ffun:10:40000:4': 48.0,
  'ffun_add:15:15000:112': 1017.0,
  'adb:10:25000:62': 16.0,
  'adb:15:2000:44': 2.0,
  'mem_7:15:50000:45': 29.0,
  'mem:10:40000:64': 130.0,
  'mem_40:15:25000:47': 37.0,
  'mem_7:10:2000:118': 35.0,
  'mem_40:10:25000:46': 47.0,
  'adb:10:20000:80': 49.0,
  'adb:15:5000:30': 4.0,
  'ffun_add:10:5000:24': 10.0,
  'mem_7:15:30000:76': 142.0,
  'adb:10:7500:119': 107.0,
  'ffun_add:10:25000:1': 23.0,
  'mem_7:10:25000:105': 433.0,
  'mem_40:10:5000:74': 31.0,
  'adb:15:15000:117': 212.0,
  'adb:15:50000:90': 241.0,
  'ffun:15:2000:47': 36.0,
  'mem_40:15:2000:87': 29.0,
  'ffun_add:10:20000:9': 20.0,
  'ffun_add:15:15000:16': 16.0,
  'mem_7:15:20000:62': 32.0,
  'mem_7:10:7500:3': 1.0,
  'ffun_add:10:15000:83': 411.0,
  'mem_40:15:15000:72': 88.0,
  'ffun_add:10:10000:93': 480.0,
  'mem_7:15:30000:26': 6.0,
  'mem:15:5000:68': 20.0,
  'ffun_add:15:30000:75': 380.0,
  'ffun_add:15:10000:108': 679.0,
  'adb:15:10000:103': 142.0,
  'mem:15:50000:26': 14.0,
  'mem:10:20000:41': 25.0,
  'ffun_add:10:2000:51': 23.0,
  'mem:10:15000:93': 289.0,
  'mem_7:10:2000:14': 1.0,
  'adb:10:2000:74': 4.0,
  'adb:10:5000:102': 71.0,
  'ffun:10:25000:37': 64.0,
  'mem_40:15:2000:105': 59.0,
  'adb:15:10000:118': 142.0,
  'mem:15:20000:19': 5.0,
  'mem:15:5000:108': 145.0,
  'mem_7:15:10000:40': 4.0,
  'mem_7:15:2000:112': 35.0,
  'dis:10:50000:28': 15.0,
  'mem_7:15:5000:108': 88.0,
  'ffun_add:15:7500:10': 10.0,
  'ffun_add:15:30000:110': 2025.0,
  'dis:15:40000:34': 12.0,
  'mem:10:20000:46': 37.0,
  'ffun:10:30000:116': 1940.0,
  'mem:15:30000:88': 422.0,
  'mem:15:30000:25': 7.0,
  'mem_40:15:30000:2': 6.0,
  'mem_7:10:10000:0': 2.0,
  'mem_40:15:15000:19': 4.0,
  'mem_7:15:10000:17': 2.0,
  'ffun:10:10000:66': 123.0,
  'mem:15:10000:52': 23.0,
  'mem:10:25000:16': 7.0,
  'mem_40:10:10000:117': 289.0,
  'mem_7:15:30000:103': 520.0,
  'mem_7:10:10000:97': 174.0,
  'mem_7:15:30000:27': 6.0,
  'hcb:15:100:18': 12.0,
  'ffun_add:10:30000:81': 811.0,
  'adb:15:5000:83': 17.0,
  'ffun:15:7500:45': 43.0,
  'ffun_add:10:25000:87': 897.0,
  'ffun_add:10:25000:51': 187.0,
  'ffun_add:15:30000:69': 276.0,
  'mem_7:10:7500:16': 2.0,
  'ffun_add:10:15000:6': 17.0,
  'mem_7:10:50000:89': 437.0,
  'mem_7:10:25000:90': 218.0,
  'dis:10:20000:29': 12.0,
  'mem:10:7500:5': 2.0,
  'hcb:10:100:56': 14.0,
  'mem_40:10:25000:84': 275.0,
  'hcb:15:50:56': 3.0,
  'ffun:10:7500:31': 38.0,
  'ffun_add:15:30000:103': 2025.0,
  'mem_7:10:40000:22': 8.0,
  'ffun:10:7500:39': 42.0,
  'mem:15:40000:36': 24.0,
  'adb:15:20000:40': 18.0,
  'ffun:15:10000:120': 511.0,
  'ffun_add:15:5000:47': 26.0,
  'mem_7:15:50000:105': 866.0,
  'adb:15:40000:75': 44.0,
  'dis:10:40000:33': 14.0,
  'ffun_add:15:50000:57': 300.0,
  'ffun_add:10:25000:30': 31.0,
  'adb:15:7500:59': 12.0,
  'mem_40:15:10000:41': 10.0,
  'ffun:10:15000:48': 81.0,
  'adb:10:15000:63': 10.0,
  'adb:15:5000:114': 71.0,
  'ffun:10:5000:59': 68.0,
  'mem_7:15:7500:48': 7.0,
  'ffun_add:10:40000:34': 59.0,
  'ffun_add:10:15000:31': 27.0,
  'ffun_add:15:30000:101': 2025.0,
  'adb:10:15000:75': 20.0,
  'ffun:10:50000:101': 3205.0,
  'hcb:15:150:56': 26.0,
  'ffun:10:15000:28': 41.0,
  'mem_40:15:30000:78': 236.0,
  'mem_40:10:25000:2': 7.0,
  'hcb:10:150:33': 31.0,
  'adb:15:25000:95': 186.0,
  'ffun_add:15:40000:25': 31.0,
  'dis:15:40000:37': 12.0,
  'mem_7:15:5000:59': 8.0,
  'mem_7:10:20000:74': 74.0,
  'ffun:10:50000:9': 53.0,
  'mem:15:10000:59': 25.0,
  'mem_40:10:5000:25': 2.0,
  'mem:15:40000:80': 294.0,
  'mem:15:20000:4': 5.0,
  'adb:10:25000:119': 353.0,
  'adb:15:15000:4': 10.0,
  'adb:15:25000:73': 29.0,
  'adb:10:40000:105': 564.0,
  'ffun_add:10:30000:37': 66.0,
  'mem_40:15:2000:100': 59.0,
  'adb:15:15000:112': 212.0,
  'mem_7:15:40000:2': 5.0,
  'mem_40:10:30000:92': 578.0,
  'dis:15:40000:32': 12.0,
  'ffun_add:10:20000:57': 157.0,
  'ffun:10:25000:53': 177.0,
  'mem_40:10:15000:76': 124.0,
  'ffun:15:15000:105': 752.0,
  'ffun_add:15:7500:40': 19.0,
  'mem:15:7500:103': 217.0,
  'mem_40:10:20000:26': 8.0,
  'adb:10:2000:50': 5.0,
  'mem:15:5000:50': 8.0,
  'ffun_add:15:2000:79': 41.0,
  'mem_40:15:30000:44': 29.0,
  'mem_40:10:7500:115': 217.0,
  'adb:10:50000:48': 98.0,
  'ffun:10:2000:52': 51.0,
  'mem_40:15:50000:18': 11.0,
  'mem:15:7500:60': 19.0,
  'mem_7:15:7500:16': 1.0,
  'ffun:10:25000:54': 177.0,
  'mem_40:10:10000:56': 30.0,
  'ffun_add:15:25000:75': 318.0,
  'mem_40:10:25000:22': 8.0,
  'mem:10:2000:70': 10.0,
  'mem_7:15:40000:75': 140.0,
  'adb:15:25000:118': 353.0,
  'ffun:10:15000:52': 122.0,
  'mem:15:10000:89': 142.0,
  'mem:15:15000:29': 5.0,
  'mem_40:10:10000:86': 146.0,
  'mem_7:15:40000:28': 7.0,
  'mem_40:15:10000:20': 2.0,
  'ffun:10:5000:39': 39.0,
  'ffun_add:10:2000:45': 14.0,
  'ffun_add:15:25000:3': 20.0,
  'ffun:15:25000:47': 97.0,
  'mem_7:10:20000:117': 347.0,
  'mem:15:7500:12': 2.0,
  'mem_7:15:2000:100': 35.0,
  'ffun:10:10000:33': 40.0,
  'mem_40:15:25000:75': 145.0,
  'mem_40:10:50000:26': 19.0,
  'mem:15:5000:57': 13.0,
  'ffun:10:10000:78': 189.0,
  'adb:15:15000:45': 18.0,
  'mem_7:10:25000:94': 289.0,
  'ffun_add:15:5000:75': 69.0,
  'adb:15:20000:41': 23.0,
  'ffun_add:10:20000:15': 20.0,
  'mem_7:10:15000:72': 56.0,
  'ffun_add:10:2000:30': 9.0,
  'ffun:15:50000:91': 1623.0,
  'ffun_add:10:7500:66': 90.0,
  'hcb:10:100:28': 14.0,
  'mem:10:15000:90': 218.0,
  'ffun:10:15000:108': 994.0,
  'mem_40:15:40000:118': 1154.0,
  'ffun:10:50000:8': 53.0,
  'mem:10:40000:41': 49.0,
  'mem_40:10:50000:36': 38.0,
  'adb:10:40000:49': 79.0,
  'adb:15:2000:18': 1.0,
  'mem:15:15000:58': 37.0,
  'mem:10:10000:104': 289.0,
  'mem:15:20000:109': 577.0,
  'mem_40:15:5000:53': 12.0,
  'ffun_add:15:20000:41': 55.0,
  'mem_40:10:10000:99': 289.0,
  'mem_7:15:10000:57': 16.0,
  'mem:15:25000:60': 61.0,
  'mem:15:30000:63': 80.0,
  'ffun:15:5000:0': 32.0,
  'mem:10:25000:71': 155.0,
  'adb:10:5000:106': 71.0,
  'mem_7:15:10000:66': 24.0,
  'ffun_add:10:10000:53': 80.0,
  'mem_40:15:7500:88': 106.0,
  'ffun_add:15:25000:36': 45.0,
  'dis:15:40000:25': 11.0,
  'adb:10:2000:36': 2.0,
  'mem:10:2000:118': 59.0,
  'mem_7:15:10000:61': 17.0,
  'ffun_add:10:5000:8': 10.0,
  'mem_40:15:15000:61': 41.0,
  'ffun_add:15:7500:34': 14.0,
  'adb:10:50000:110': 706.0,
  'adb:15:2000:2': 1.0,
  'ffun:10:15000:27': 41.0,
  'ffun_add:15:25000:11': 20.0,
  'ffun:15:20000:41': 65.0,
  'ffun:15:5000:81': 116.0,
  'mem:15:2000:10': 1.0,
  'ffun_add:10:20000:100': 1416.0,
  'adb:10:50000:25': 40.0,
  'adb:10:30000:53': 83.0,
  'mem_7:15:7500:56': 12.0,
  'mem_40:10:40000:32': 20.0,
  'mem:15:2000:108': 59.0,
  'mem:10:30000:65': 97.0,
  'adb:15:50000:78': 108.0,
  'mem_7:15:10000:19': 2.0,
  'ffun:10:5000:95': 257.0,
  'ffun:15:2000:96': 126.0,
  'mem:10:2000:34': 1.0,
  'mem:10:7500:59': 23.0,
  'ffun_add:10:25000:41': 86.0,
  'ffun:15:15000:29': 38.0,
  'mem_40:10:50000:98': 1444.0,
  'adb:10:25000:93': 188.0,
  'mem:15:50000:112': 1444.0,
  'dis:15:40000:45': 12.0,
  'hcb:10:150:30': 31.0,
  'ffun:15:5000:78': 91.0,
  'mem_40:15:2000:4': 1.0,
  'mem:10:25000:74': 155.0,
  'adb:15:50000:105': 706.0,
  'adb:10:40000:115': 564.0,
  'mem_40:15:2000:81': 22.0,
  'mem:15:50000:56': 121.0,
  'mem_40:15:15000:115': 433.0,
  'adb:10:50000:50': 98.0,
  'mem:10:5000:19': 2.0,
  'ffun:15:2000:83': 64.0,
  'mem_7:15:20000:100': 347.0,
  'mem_7:10:15000:35': 5.0,
  'ffun:15:7500:70': 78.0,
  'ffun:15:20000:40': 52.0,
  'mem_40:10:40000:3': 11.0,
  'ffun:10:40000:54': 261.0,
  'mem_7:10:15000:38': 7.0,
  'mem:10:25000:92': 482.0,
  'mem_40:15:15000:41': 14.0,
  'mem:15:25000:8': 6.0,
  'ffun:15:30000:18': 42.0,
  'mem_40:15:5000:104': 145.0,
  'mem_40:10:10000:59': 30.0,
  'mem:10:15000:77': 124.0,
  'ffun_add:10:40000:104': 2824.0,
  'ffun_add:10:7500:58': 65.0,
  'adb:10:25000:59': 48.0,
  'mem_40:15:15000:23': 4.0,
  'mem_40:10:40000:75': 247.0,
  'ffun:15:7500:43': 43.0,
  'mem_7:15:25000:59': 37.0,
  'mem_40:15:20000:86': 282.0,
  'adb:10:10000:17': 8.0,
  'mem_7:15:25000:32': 6.0,
  'mem_7:15:7500:49': 7.0,
  'mem_40:15:2000:12': 1.0,
  'adb:15:5000:111': 71.0,
  'mem:15:20000:101': 577.0,
  'mem:10:5000:16': 2.0,
  'ffun:15:25000:2': 40.0,
  'ffun:15:30000:31': 52.0,
  'mem_40:10:25000:80': 205.0,
  'ffun:10:15000:120': 994.0,
  'mem:10:30000:52': 84.0,
  'ffun:15:5000:79': 91.0,
  'mem_7:10:2000:40': 1.0,
  'hcb:15:150:38': 27.0,
  'hcb:15:100:50': 13.0,
  'mem_40:10:20000:25': 7.0,
  'hcb:10:50:23': 3.0,
  'ffun_add:10:10000:25': 15.0,
  'mem_40:15:25000:15': 6.0,
  'mem_40:10:7500:107': 217.0,
  'adb:10:20000:62': 13.0,
  'mem_7:15:10000:93': 115.0,
  'mem_40:15:5000:81': 52.0,
  'ffun:10:2000:60': 51.0,
  'ffun:10:20000:1': 40.0,
  'adb:15:25000:29': 17.0,
  'mem:10:25000:4': 7.0,
  'mem_7:10:7500:48': 8.0,
  'adb:10:10000:95': 76.0,
  'ffun:10:25000:72': 322.0,
  'mem_7:15:25000:4': 4.0,
  'adb:10:2000:83': 7.0,
  'ffun_add:15:40000:8': 28.0,
  'mem_7:15:15000:116': 260.0,
  'ffun_add:15:40000:55': 229.0,
  'adb:15:5000:32': 5.0,
  'mem_40:15:40000:92': 764.0,
  'mem_40:10:15000:32': 8.0,
  'mem_7:10:15000:3': 2.0,
  'mem_7:10:15000:92': 174.0,
  'adb:15:25000:116': 353.0,
  'ffun_add:15:40000:20': 30.0,
  'mem_40:15:5000:118': 145.0,
  'adb:10:50000:45': 73.0,
  'mem:10:20000:120': 577.0,
  'ffun:15:10000:93': 349.0,
  'mem_7:10:50000:52': 84.0,
  'ffun:15:50000:50': 164.0,
  'dis:10:10000:54': 12.0,
  'mem_7:15:10000:14': 2.0,
  'mem_40:10:20000:12': 6.0,
  'mem:15:5000:31': 2.0,
  'mem_40:10:15000:63': 49.0,
  'mem_40:10:15000:94': 289.0,
  'ffun_add:10:25000:117': 1769.0,
  'mem:15:30000:35': 12.0,
  'mem_7:10:5000:114': 88.0,
  'mem_7:10:7500:96': 131.0,
  'adb:15:7500:96': 107.0,
  'mem_7:10:2000:48': 2.0,
  'mem_40:10:20000:115': 577.0,
  'adb:15:25000:7': 14.0,
  'mem_40:15:40000:81': 410.0,
  'ffun:10:7500:77': 152.0,
  'ffun_add:15:25000:39': 45.0,
  'ffun:15:40000:76': 519.0,
  'ffun:10:15000:30': 41.0,
  'adb:10:5000:30': 5.0,
  'mem_7:10:15000:0': 2.0,
  'mem_7:15:15000:107': 260.0,
  'mem_40:10:20000:13': 6.0,
  'ffun_add:15:5000:7': 9.0,
  'mem_7:10:20000:65': 40.0,
  'mem:15:50000:37': 30.0,
  'ffun:15:10000:18': 34.0,
  'mem_40:15:2000:55': 5.0,
  'mem:10:25000:52': 70.0,
  'ffun:10:30000:100': 1940.0,
  'mem_40:15:2000:60': 6.0,
  'adb:10:7500:19': 6.0,
  'mem:15:20000:75': 107.0,
  'adb:15:40000:64': 19.0,
  'ffun_add:15:15000:63': 100.0,
  'adb:15:10000:28': 7.0,
  'hcb:15:150:45': 28.0,
  'adb:10:10000:20': 8.0,
  'ffun:10:50000:115': 3205.0,
  'ffun:15:25000:43': 74.0,
  'mem_7:10:5000:23': 1.0,
  'mem_7:10:50000:55': 84.0,
  'ffun_add:15:20000:13': 17.0,
  'adb:15:5000:27': 4.0,
  'mem_7:10:10000:43': 8.0,
  'adb:10:40000:45': 59.0,
  'mem:15:2000:33': 1.0,
  'mem_40:10:15000:38': 12.0,
  'ffun_add:10:30000:89': 1074.0,
  'ffun_add:10:20000:22': 23.0,
  'adb:10:15000:103': 212.0,
  'mem_40:10:15000:97': 433.0,
  'adb:10:25000:81': 86.0,
  'mem_7:10:20000:95': 232.0,
  'ffun:10:7500:108': 522.0,
  'mem_40:10:25000:15': 7.0,
  'mem_7:15:2000:49': 2.0,
  'adb:10:20000:8': 16.0,
  'mem_40:15:5000:92': 96.0,
  'dis:15:10000:54': 10.0,
  'ffun_add:10:2000:48': 16.0,
  'adb:10:30000:95': 226.0,
  'adb:15:40000:30': 26.0,
  'ffun_add:10:2000:16': 9.0,
  'mem_7:10:30000:109': 520.0,
  'ffun:15:50000:113': 2435.0,
  'mem_40:10:50000:56': 150.0,
  'adb:10:40000:60': 76.0,
  'mem_7:10:25000:117': 433.0,
  'ffun_add:10:30000:76': 588.0,
  'mem:10:30000:12': 8.0,
  'mem_40:15:50000:105': 1444.0,
  'mem:10:7500:101': 217.0,
  'adb:10:5000:17': 5.0,
  'ffun:10:25000:11': 42.0,
  'mem_7:10:25000:76': 124.0,
  'mem:10:40000:93': 772.0,
  'mem_7:15:10000:25': 2.0,
  'mem_7:15:7500:85': 47.0,
  'mem_7:15:2000:18': 1.0,
  'mem_40:10:7500:119': 217.0,
  'adb:10:10000:44': 16.0,
  'mem_40:10:2000:36': 2.0,
  'ffun_add:10:15000:84': 411.0,
  'mem_40:10:20000:102': 577.0,
  'hcb:15:120:27': 17.0,
  'mem:10:5000:70': 24.0,
  'mem_7:10:25000:78': 124.0,
  'ffun:15:40000:83': 713.0,
  'mem_7:10:20000:32': 7.0,
  'ffun:10:40000:65': 276.0,
  'adb:15:30000:18': 18.0,
  'mem:10:2000:101': 59.0,
  'ffun:15:5000:96': 271.0,
  'mem:10:20000:110': 577.0,
  'ffun_add:15:7500:12': 10.0,
  'mem_40:15:2000:47': 4.0,
  'hcb:15:100:47': 13.0,
  'ffun_add:10:7500:12': 12.0,
  'ffun_add:15:25000:88': 828.0,
  'mem:15:15000:101': 433.0,
  'ffun:10:30000:120': 1940.0,
  'mem_40:10:10000:62': 32.0,
  'mem:15:7500:71': 41.0,
  'mem:10:10000:34': 6.0,
  'adb:15:10000:101': 142.0,
  'hcb:10:50:57': 3.0,
  'mem_40:10:25000:41': 31.0,
  'mem:15:25000:95': 479.0,
  'mem:15:7500:26': 2.0,
  'mem_7:10:30000:43': 23.0,
  'adb:10:5000:41': 8.0,
  'ffun:15:5000:24': 33.0,
  'ffun_add:10:15000:21': 19.0,
  'adb:15:15000:58': 23.0,
  'ffun_add:10:50000:30': 56.0,
  'adb:15:20000:94': 149.0,
  'ffun_add:15:2000:62': 19.0,
  'adb:15:20000:105': 282.0,
  'mem_40:15:2000:48': 4.0,
  'adb:15:50000:81': 160.0,
  'mem_40:15:50000:80': 394.0,
  'mem:10:20000:11': 6.0,
  'mem_40:10:25000:79': 205.0,
  'mem_40:10:15000:13': 5.0,
  'mem:15:20000:66': 78.0,
  'mem_7:10:2000:6': 1.0,
  'ffun_add:10:50000:118': 3527.0,
  'mem_40:15:50000:3': 11.0,
  'mem:15:20000:53': 44.0,
  'mem_40:10:15000:111': 433.0,
  'mem_7:10:10000:28': 2.0,
  'mem:15:10000:87': 142.0,
  'ffun_add:15:10000:95': 453.0,
  'mem_40:15:40000:18': 10.0,
  'mem:15:20000:10': 5.0,
  'mem:15:25000:29': 7.0,
  'hcb:15:50:49': 3.0,
  'mem_40:10:5000:80': 42.0,
  'adb:10:30000:85': 103.0,
  'ffun_add:15:2000:70': 24.0,
  'adb:10:25000:85': 86.0,
  'ffun:10:7500:10': 34.0,
  'ffun:10:7500:42': 50.0,
  'ffun_add:15:25000:116': 1690.0,
  'hcb:10:100:45': 14.0,
  'mem_40:10:15000:96': 433.0,
  'mem_40:15:5000:54': 12.0,
  'ffun_add:15:30000:74': 380.0,
  'adb:10:25000:10': 19.0,
  'mem_40:10:10000:107': 289.0,
  'mem_7:15:7500:20': 1.0,
  'ffun_add:15:30000:9': 23.0,
  'mem_7:15:15000:68': 35.0,
  'mem:15:10000:56': 25.0,
  'mem_7:10:5000:17': 1.0,
  'adb:15:15000:18': 10.0,
  'mem_40:10:40000:118': 1154.0,
  'adb:10:7500:58': 14.0,
  'mem_7:10:25000:13': 5.0,
  'mem_40:10:2000:47': 5.0,
  'adb:15:10000:106': 142.0,
  'mem:10:2000:79': 17.0,
  'mem:15:10000:2': 2.0,
  'mem:10:10000:69': 47.0,
  'mem:15:10000:29': 4.0,
  'mem_7:10:25000:53': 42.0,
  'mem:10:30000:19': 8.0,
  'dis:15:30000:37': 11.0,
  'mem:15:30000:87': 422.0,
  'adb:10:20000:38': 23.0,
  'mem:15:30000:36': 18.0,
  'mem_40:10:50000:103': 1444.0,
  'adb:15:5000:87': 25.0,
  'ffun:15:30000:44': 82.0,
  'ffun_add:10:20000:54': 151.0,
  'adb:15:5000:42': 6.0,
  'mem:15:25000:18': 6.0,
  'mem_7:10:5000:45': 5.0,
  'adb:15:25000:120': 353.0,
  'mem_40:10:5000:65': 17.0,
  'adb:10:30000:19': 24.0,
  'mem_7:10:50000:76': 246.0,
  'adb:15:25000:94': 186.0,
  'ffun:10:25000:8': 42.0,
  'mem_40:10:30000:78': 246.0,
  'ffun_add:10:5000:6': 10.0,
  'mem:15:2000:69': 8.0,
  'mem_40:10:50000:50': 92.0,
  'mem_40:15:40000:49': 59.0,
  'hcb:10:100:26': 14.0,
  'adb:15:7500:30': 5.0,
  'ffun_add:15:20000:18': 18.0,
  'mem_7:15:40000:70': 94.0,
  'adb:15:15000:14': 10.0,
  'ffun_add:10:10000:66': 117.0,
  'ffun:10:25000:5': 42.0,
  'ffun:15:5000:38': 36.0,
  'adb:10:50000:64': 31.0,
  'ffun_add:15:30000:120': 2025.0,
  'ffun:15:25000:81': 457.0,
  'ffun_add:10:20000:14': 20.0,
  'mem_7:15:5000:120': 88.0,
  'ffun:10:15000:98': 994.0,
  'mem_40:15:7500:3': 2.0,
  'hcb:10:120:39': 20.0,
  'hcb:10:150:41': 33.0,
  'adb:10:5000:99': 71.0,
  'mem_7:10:20000:115': 347.0,
  'ffun:15:40000:42': 100.0,
  'ffun_add:10:20000:86': 720.0,
  'mem_40:15:50000:88': 704.0,
  'ffun:10:20000:117': 1310.0,
  'adb:15:50000:40': 44.0,
  'mem_40:10:7500:38': 6.0,
  'adb:15:20000:57': 30.0,
  'ffun:15:2000:109': 126.0,
  'adb:15:30000:69': 14.0,
  'mem:15:10000:82': 103.0,
  'ffun:15:5000:32': 34.0,
  'ffun:10:50000:103': 3205.0,
  'mem:10:10000:28': 5.0,
  'mem_7:15:50000:13': 7.0,
  'mem_40:15:50000:83': 512.0,
  'hcb:15:80:27': 7.0,
  'ffun:15:25000:6': 40.0,
  'ffun:15:5000:46': 44.0,
  'mem:10:40000:95': 772.0,
  'ffun_add:10:10000:96': 713.0,
  'adb:15:40000:36': 36.0,
  'mem:15:15000:67': 59.0,
  'ffun:10:10000:55': 95.0,
  'hcb:15:100:34': 12.0,
  'adb:15:25000:20': 14.0,
  'adb:10:40000:120': 564.0,
  'mem:10:40000:118': 1154.0,
  'ffun:15:15000:83': 286.0,
  'ffun:15:10000:74': 119.0,
  'mem_7:10:40000:35': 13.0,
  'ffun_add:15:50000:88': 1648.0,
  'mem:15:40000:30': 12.0,
  'adb:10:40000:23': 31.0,
  'adb:10:25000:100': 353.0,
  'ffun:10:20000:10': 40.0,
  'ffun:15:20000:76': 275.0,
  'mem_7:15:5000:20': 1.0,
  'dis:10:20000:27': 12.0,
  'mem_7:15:40000:38': 14.0,
  'ffun_add:15:10000:81': 245.0,
  'mem:15:20000:63': 54.0,
  'mem_40:10:30000:28': 12.0,
  'adb:15:2000:90': 11.0,
  'adb:10:7500:109': 107.0,
  'mem_7:15:20000:29': 4.0,
  'mem:15:15000:99': 433.0,
  'adb:15:2000:98': 29.0,
  'mem:10:5000:23': 2.0,
  'mem:15:25000:50': 37.0,
  'mem_40:10:20000:78': 164.0,
  'mem_7:15:15000:98': 260.0,
  'mem:10:5000:35': 4.0,
  'mem_40:15:30000:120': 865.0,
  'ffun:15:40000:100': 1953.0,
  'ffun:15:40000:34': 60.0,
  'mem_7:15:2000:105': 35.0,
  'mem_7:10:5000:3': 1.0,
  'mem:10:25000:0': 7.0,
  'ffun:15:25000:31': 49.0,
  'ffun:15:30000:24': 44.0,
  'ffun_add:15:20000:4': 17.0,
  'ffun:15:2000:37': 32.0,
  'mem:15:5000:97': 145.0,
  'mem_7:10:7500:106': 131.0,
  'hcb:10:80:25': 9.0,
  'ffun:15:25000:71': 252.0,
  'adb:15:7500:106': 107.0,
  'ffun_add:15:40000:37': 68.0,
  'mem:15:40000:40': 24.0,
  'ffun:10:2000:116': 173.0,
  'adb:15:40000:107': 564.0,
  'mem_7:10:2000:46': 2.0,
  'ffun:10:50000:16': 55.0,
  'ffun:15:25000:56': 135.0,
  'ffun:15:20000:65': 119.0,
  'hcb:10:50:43': 4.0,
  'ffun_add:15:7500:102': 511.0,
  'adb:15:50000:22': 30.0,
  'ffun_add:15:40000:19': 30.0,
  'ffun_add:15:2000:0': 7.0,
  'mem_40:10:25000:20': 7.0,
  'ffun_add:15:5000:38': 14.0,
  'mem_7:15:40000:53': 53.0,
  'mem:10:15000:1': 5.0,
  'ffun:10:2000:104': 173.0,
  'mem:10:20000:37': 16.0,
  'ffun:15:5000:54': 50.0,
  'mem_7:15:7500:86': 64.0,
  'mem:10:5000:75': 31.0,
  'ffun:15:30000:81': 542.0,
  'mem_7:10:15000:65': 30.0,
  'adb:15:40000:50': 62.0,
  'ffun_add:15:30000:4': 23.0,
  'mem_7:10:30000:61': 59.0,
  'ffun_add:15:7500:113': 511.0,
  'ffun:10:20000:52': 150.0,
  'hcb:15:120:32': 17.0,
  'ffun_add:10:2000:47': 16.0,
  'mem:10:40000:21': 13.0,
  'ffun:15:50000:92': 1623.0,
  'mem:15:25000:17': 6.0,
  'mem_7:10:15000:78': 74.0,
  'ffun:10:30000:15': 45.0,
  'adb:10:5000:31': 5.0,
  'mem_7:10:7500:17': 2.0,
  'ffun_add:15:40000:28': 37.0,
  'mem_7:15:50000:40': 18.0,
  'ffun:15:30000:117': 1472.0,
  'mem:15:10000:4': 2.0,
  'dis:10:10000:26': 11.0,
  'ffun_add:15:10000:22': 13.0,
  'mem:10:7500:72': 47.0,
  'adb:15:5000:80': 11.0,
  'adb:10:25000:74': 34.0,
  'mem:15:2000:11': 1.0,
  'mem_7:15:25000:58': 37.0,
  'adb:15:25000:19': 14.0,
  'ffun_add:10:7500:64': 66.0,
  'mem_7:10:40000:112': 692.0,
  'mem:15:25000:59': 61.0,
  'mem_7:10:40000:7': 7.0,
  'mem:10:40000:25': 13.0,
  'ffun_add:15:30000:80': 520.0,
  'mem:15:25000:115': 722.0,
  'adb:10:50000:4': 38.0,
  'hcb:10:150:45': 33.0,
  'mem_7:10:15000:106': 260.0,
  'mem:10:10000:35': 6.0,
  'mem:15:25000:80': 184.0,
  'mem:10:7500:67': 35.0,
  'ffun_add:10:25000:90': 897.0,
  'adb:10:2000:30': 2.0,
  'ffun:10:20000:57': 154.0,
  'ffun:10:15000:41': 66.0,
  'mem_40:15:40000:102': 1154.0,
  'mem_7:15:40000:11': 6.0,
  'mem_7:10:7500:88': 66.0,
  'mem_40:10:5000:63': 17.0,
  'mem_7:10:50000:120': 866.0,
  'ffun_add:15:7500:44': 25.0,
  'dis:10:20000:36': 12.0,
  'mem_7:10:15000:55': 25.0,
  'mem:10:2000:31': 1.0,
  'mem_7:15:50000:84': 308.0,
  'adb:10:40000:2': 31.0,
  'mem:15:25000:102': 722.0,
  'adb:15:25000:97': 353.0,
  'mem_7:15:25000:56': 37.0,
  'mem_40:15:30000:83': 307.0,
  'adb:10:20000:27': 17.0,
  'adb:10:15000:78': 37.0,
  'mem:15:20000:111': 577.0,
  'mem_40:10:50000:87': 728.0,
  'adb:10:2000:117': 29.0,
  'ffun:10:2000:110': 173.0,
  'ffun_add:10:25000:79': 492.0,
  'mem_7:15:10000:79': 48.0,
  'mem_40:15:40000:30': 12.0,
  'mem_7:10:50000:88': 437.0,
  'dis:15:30000:42': 11.0,
  'mem:15:50000:8': 11.0,
  'ffun:15:25000:68': 191.0,
  'ffun_add:10:15000:108': 1065.0,
  'mem:10:50000:9': 13.0,
  'mem:10:50000:104': 1444.0,
  'ffun_add:15:7500:28': 13.0,
  'mem_7:15:25000:8': 4.0,
  'hcb:15:120:22': 17.0,
  'ffun_add:10:20000:74': 300.0,
  'adb:10:10000:37': 12.0,
  'adb:15:5000:60': 8.0,
  'mem_7:15:50000:106': 866.0,
  'ffun_add:10:5000:20': 10.0,
  'mem_40:10:50000:32': 26.0,
  'mem_40:15:15000:16': 4.0,
  'hcb:15:150:57': 26.0,
  'mem:10:50000:42': 61.0,
  'dis:10:40000:21': 13.0,
  'mem:15:25000:20': 6.0,
  'ffun_add:15:7500:83': 186.0,
  'mem:15:30000:33': 12.0,
  'dis:10:30000:46': 13.0,
  'adb:10:25000:61': 16.0,
  'mem_40:15:7500:0': 2.0,
  'mem:10:40000:37': 31.0,
  'ffun_add:10:2000:11': 8.0,
  'mem_40:15:30000:12': 7.0,
  'mem_7:15:20000:38': 7.0,
  'ffun_add:10:50000:38': 106.0,
  'ffun:15:50000:59': 239.0,
  'mem_7:10:25000:49': 29.0,
  'mem_40:15:7500:9': 2.0,
  'adb:15:40000:118': 564.0,
  'mem:10:15000:102': 433.0,
  'dis:15:10000:56': 14.0,
  'adb:10:2000:92': 16.0,
  'mem_40:15:2000:69': 8.0,
  'ffun_add:15:5000:44': 19.0,
  'mem_40:10:7500:45': 10.0,
  'mem_40:10:5000:8': 2.0,
  'mem_40:10:30000:55': 84.0,
  'ffun_add:10:15000:69': 171.0,
  'dis:10:30000:37': 13.0,
  'ffun_add:15:7500:43': 25.0,
  'mem:15:15000:28': 5.0,
  'adb:15:15000:108': 212.0,
  'mem:10:50000:46': 92.0,
  'mem_40:10:50000:96': 1444.0,
  'dis:10:50000:25': 14.0,
  'ffun_add:10:50000:0': 39.0,
  'ffun:10:20000:90': 684.0,
  'hcb:15:100:21': 12.0,
  'mem_40:15:30000:9': 6.0,
  'mem:10:20000:35': 11.0,
  'adb:15:7500:8': 5.0,
  'mem_7:10:2000:24': 1.0,
  'mem_40:10:30000:77': 246.0,
  'ffun_add:15:10000:107': 679.0,
  'adb:15:50000:86': 241.0,
  'mem_7:15:10000:90': 85.0,
  'ffun_add:15:20000:72': 255.0,
  'ffun:10:10000:36': 45.0,
  'mem_40:15:10000:55': 23.0,
  'ffun:10:7500:72': 124.0,
  'mem_40:15:10000:61': 28.0,
  'mem:15:20000:47': 30.0,
  'mem_40:15:25000:18': 6.0,
  'adb:10:2000:26': 2.0,
  'ffun:10:30000:56': 211.0,
  'adb:15:30000:88': 145.0,
  'ffun_add:15:15000:101': 1017.0,
  'mem_7:10:20000:34': 7.0,
  'mem_40:15:5000:33': 2.0,
  'mem_40:15:15000:70': 59.0,
  'mem_7:10:2000:120': 35.0,
  'adb:10:25000:21': 20.0,
  'adb:15:10000:91': 74.0,
  'ffun:10:50000:81': 1248.0,
  'mem_40:15:20000:93': 383.0,
  'ffun_add:15:25000:112': 1690.0,
  'adb:10:25000:114': 353.0,
  'dis:10:30000:36': 13.0,
  'ffun:15:25000:118': 1232.0,
  'ffun:15:20000:39': 52.0,
  'hcb:15:100:59': 11.0,
  'ffun:15:10000:33': 38.0,
  'adb:15:5000:26': 4.0,
  'ffun_add:15:7500:65': 54.0,
  'adb:10:25000:113': 353.0,
  'mem:10:20000:80': 164.0,
  'ffun:15:40000:11': 46.0,
  'adb:10:50000:40': 58.0,
  'ffun:10:10000:9': 36.0,
  'ffun_add:10:5000:93': 245.0,
  'adb:15:10000:52': 23.0,
  'ffun:10:30000:45': 98.0,
  'adb:10:30000:86': 150.0,
  'ffun_add:10:15000:23': 19.0,
  'mem_40:10:50000:42': 61.0,
  'ffun_add:15:5000:28': 10.0,
  'mem_7:10:5000:119': 88.0,
  'mem_40:15:2000:107': 59.0,
  'ffun_add:15:50000:63': 317.0,
  'adb:10:20000:78': 49.0,
  'mem:15:5000:59': 13.0,
  'adb:15:7500:114': 107.0,
  'adb:15:30000:20': 18.0,
  'adb:15:5000:84': 17.0,
  'mem_40:15:7500:14': 2.0,
  'ffun:15:30000:29': 47.0,
  'mem_40:10:20000:36': 16.0,
  'mem_7:10:30000:67': 83.0,
  'adb:10:7500:97': 107.0,
  'ffun:15:30000:36': 63.0,
  'mem_7:15:40000:23': 6.0,
  'dis:10:40000:23': 13.0,
  'adb:15:40000:1': 23.0,
  'mem_40:10:25000:16': 7.0,
  'ffun_add:15:5000:71': 69.0,
  'ffun:10:7500:71': 124.0,
  'ffun_add:10:10000:87': 365.0,
  'mem_7:10:5000:62': 11.0,
  'mem:15:2000:99': 59.0,
  'adb:10:25000:42': 37.0,
  'ffun_add:15:15000:59': 94.0,
  'ffun_add:10:50000:119': 3527.0,
  'mem_40:10:20000:103': 577.0,
  'mem_7:10:15000:83': 100.0,
  'ffun_add:10:30000:32': 46.0,
  'mem:15:15000:24': 4.0,
  'ffun:15:15000:68': 127.0,
  'mem_40:15:10000:3': 2.0,
  'mem_40:15:25000:95': 479.0,
  'ffun_add:10:50000:78': 976.0,
  'mem_7:10:30000:11': 5.0,
  'mem:10:30000:48': 56.0,
  'adb:15:40000:100': 564.0,
  'adb:15:7500:23': 5.0,
  'mem_7:10:50000:0': 8.0,
  'ffun_add:15:15000:103': 1017.0,
  'mem_7:10:15000:105': 260.0,
  'mem_40:15:10000:36': 6.0,
  'ffun:10:20000:36': 58.0,
  'ffun:10:2000:90': 110.0,
  'adb:15:2000:32': 2.0,
  'ffun_add:10:10000:4': 13.0,
  'adb:10:15000:6': 12.0,
  'mem:15:7500:102': 217.0,
  'mem_7:15:20000:84': 124.0,
  'mem:10:25000:9': 7.0,
  'mem_40:10:40000:69': 184.0,
  'mem_7:10:30000:49': 34.0,
  'adb:10:15000:95': 113.0,
  'mem_7:15:20000:13': 4.0,
  'mem_7:10:2000:53': 4.0,
  'mem_40:15:10000:1': 2.0,
  'mem_40:10:2000:18': 1.0,
  'mem_7:15:10000:38': 4.0,
  'ffun_add:15:10000:102': 679.0,
  'ffun:15:30000:119': 1472.0,
  'mem_40:15:50000:112': 1444.0,
  'ffun:15:7500:116': 391.0,
  'ffun:10:15000:6': 38.0,
  'mem_7:10:20000:88': 175.0,
  'mem_40:15:40000:107': 1154.0,
  'mem:10:25000:89': 365.0,
  'mem_7:15:50000:36': 18.0,
  'mem_40:10:50000:106': 1444.0,
  'adb:15:50000:82': 160.0,
  'mem_40:15:15000:82': 155.0,
  'mem:15:50000:108': 1444.0,
  'mem_7:10:30000:32': 10.0,
  'adb:15:2000:69': 1.0,
  'adb:10:30000:120': 424.0,
  'mem_7:10:7500:111': 131.0,
  'mem:15:7500:46': 12.0,
  'mem_7:15:5000:113': 88.0,
  'mem_7:15:5000:2': 1.0,
  'mem_40:15:7500:70': 30.0,
  'mem_7:15:30000:43': 17.0,
  'mem:10:20000:105': 577.0,
  'adb:15:7500:85': 24.0,
  'hcb:10:50:42': 4.0,
  'mem_40:10:5000:6': 2.0,
  'adb:10:50000:102': 706.0,
  'mem:15:25000:105': 722.0,
  'mem_40:15:2000:6': 1.0,
  'adb:10:5000:95': 38.0,
  'adb:10:10000:1': 8.0,
  'adb:10:15000:32': 14.0,
  'mem_7:10:30000:63': 59.0,
  'mem_7:10:15000:76': 74.0,
  'mem:10:30000:83': 329.0,
  'mem:10:7500:49': 14.0,
  'adb:10:2000:116': 29.0,
  'ffun_add:10:7500:43': 30.0,
  'mem_40:10:25000:78': 205.0,
  'mem_40:15:30000:51': 66.0,
  'adb:15:40000:46': 62.0,
  'ffun_add:10:15000:3': 17.0,
  'mem:10:2000:14': 1.0,
  'dis:15:20000:26': 10.0,
  'mem_40:15:5000:59': 13.0,
  'ffun_add:10:7500:115': 538.0,
  'adb:15:10000:80': 22.0,
  'mem:15:20000:95': 383.0,
  'mem:10:30000:94': 578.0,
  'ffun:10:10000:103': 679.0,
  'adb:10:2000:111': 29.0,
  'mem:15:40000:92': 764.0,
  'adb:10:10000:36': 12.0,
  'adb:15:30000:44': 35.0,
  'dis:10:50000:22': 14.0,
  'mem_40:10:50000:53': 139.0,
  'ffun_add:15:5000:114': 343.0,
  'mem_40:10:15000:109': 433.0,
  'mem_40:15:40000:32': 17.0,
  'mem_7:15:50000:33': 12.0,
  'ffun_add:10:20000:99': 1416.0,
  'mem:10:5000:49': 10.0,
  'ffun:10:10000:90': 365.0,
  'ffun:15:2000:57': 39.0,
  'mem_7:10:50000:34': 16.0,
  'mem:15:50000:42': 47.0,
  'mem_40:15:15000:106': 433.0,
  'mem_40:15:40000:95': 764.0,
  'ffun:15:2000:9': 31.0,
  'mem_40:15:40000:52': 88.0,
  'mem_7:15:5000:96': 88.0,
  'mem_7:15:20000:32': 5.0,
  'ffun_add:15:20000:77': 349.0,
  'adb:15:20000:83': 64.0,
  'mem_40:15:15000:80': 119.0,
  'mem:15:15000:11': 4.0,
  'mem_7:10:30000:17': 6.0,
  'mem:10:10000:90': 146.0,
  'ffun:15:15000:72': 164.0,
  'ffun_add:15:7500:60': 51.0,
  'ffun_add:10:30000:73': 446.0,
  'mem_40:15:50000:53': 110.0,
  'mem_40:15:5000:28': 2.0,
  'ffun:10:2000:38': 35.0,
  'mem_40:10:7500:67': 35.0,
  'mem:15:5000:96': 145.0,
  'mem:15:10000:117': 289.0,
  'ffun:15:10000:14': 34.0,
  'adb:15:7500:45': 10.0,
  'mem_40:10:25000:14': 7.0,
  'ffun:10:2000:98': 173.0,
  'mem:15:2000:32': 1.0,
  'mem_40:10:7500:18': 2.0,
  'mem_40:15:7500:62': 20.0,
  'mem_7:15:40000:31': 10.0,
  'mem_7:10:30000:118': 520.0,
  'adb:10:7500:107': 107.0,
  'ffun_add:10:5000:70': 63.0,
  'mem:15:7500:96': 217.0,
  'ffun_add:10:15000:58': 120.0,
  'mem:10:2000:63': 7.0,
  'mem_7:15:7500:21': 1.0,
  'mem_7:10:15000:88': 132.0,
  'ffun_add:15:40000:79': 692.0,
  'mem:15:20000:0': 5.0,
  'mem_40:10:15000:54': 42.0,
  'ffun:10:15000:80': 264.0,
  'ffun_add:10:5000:49': 31.0,
  'ffun:10:15000:12': 38.0,
  'dis:10:40000:56': 21.0,
  'mem_40:10:50000:97': 1444.0,
  'mem_7:15:40000:79': 188.0,
  'ffun_add:10:5000:19': 10.0,
  'mem:10:7500:40': 6.0,
  'ffun:10:25000:24': 44.0,
  'ffun_add:10:5000:14': 10.0,
  'mem:15:7500:84': 78.0,
  'mem:10:5000:72': 31.0,
  'mem_40:15:40000:109': 1154.0,
  'ffun:10:5000:23': 34.0,
  'mem_7:10:40000:36': 19.0,
  'ffun:15:2000:55': 38.0,
  'mem_7:10:30000:96': 520.0,
  'ffun:10:5000:120': 363.0,
  'mem:10:5000:65': 17.0,
  'hcb:15:50:25': 3.0,
  'mem_7:10:5000:75': 19.0,
  'mem_7:15:40000:94': 460.0,
  'mem:15:5000:95': 96.0,
  'ffun:10:30000:71': 378.0,
  'ffun:15:5000:16': 32.0,
  'adb:10:20000:9': 16.0,
  'mem_40:10:2000:2': 1.0,
  'adb:15:2000:113': 29.0,
  'adb:15:15000:30': 10.0,
  'mem_40:10:30000:19': 8.0,
  'ffun_add:10:20000:59': 157.0,
  'mem:10:10000:9': 4.0,
  'mem_40:15:10000:38': 6.0,
  'mem_40:10:50000:75': 310.0,
  'adb:15:25000:5': 14.0,
  'ffun_add:15:20000:81': 485.0,
  'adb:15:10000:88': 49.0,
  'mem:15:25000:62': 67.0,
  'adb:10:20000:72': 28.0,
  'ffun_add:10:10000:109': 713.0,
  'mem_40:15:20000:55': 44.0,
  'ffun:10:5000:115': 363.0,
  'mem_7:10:15000:91': 174.0,
  'ffun:15:7500:96': 391.0,
  'adb:10:2000:54': 6.0,
  'ffun:15:40000:8': 45.0,
  'mem:15:2000:115': 59.0,
  'ffun:10:30000:85': 767.0,
  'adb:10:10000:75': 14.0,
  'adb:15:7500:97': 107.0,
  'mem:15:30000:97': 865.0,
  'ffun_add:15:25000:20': 21.0,
  'mem_40:10:50000:27': 19.0,
  'mem:15:50000:34': 20.0,
  'dis:15:10000:31': 9.0,
  'mem_7:10:40000:4': 6.0,
  'hcb:15:50:41': 3.0,
  'ffun:10:5000:44': 45.0,
  'mem_7:15:2000:44': 1.0,
  'mem_40:15:5000:78': 40.0,
  'mem:15:40000:34': 17.0,
  'mem_40:15:2000:111': 59.0,
  'mem_7:15:30000:39': 11.0,
  'mem_40:10:15000:120': 433.0,
  'ffun_add:10:7500:65': 66.0,
  'ffun_add:10:10000:72': 154.0,
  'mem:10:2000:96': 59.0,
  'mem:10:30000:90': 437.0,
  'mem_7:15:40000:84': 246.0,
  'ffun:10:20000:87': 684.0,
  'ffun_add:10:50000:31': 72.0,
  'ffun:10:2000:16': 33.0,
  'adb:15:15000:36': 13.0,
  'ffun:10:50000:95': 2157.0,
  'mem_7:15:30000:73': 104.0,
  'mem:15:15000:17': 4.0,
  'ffun:15:30000:0': 42.0,
  'mem:10:30000:55': 84.0,
  'mem_7:15:30000:37': 11.0,
  'ffun_add:15:30000:113': 2025.0,
  'mem_40:15:10000:96': 289.0,
  'mem_40:10:5000:39': 5.0,
  'ffun_add:10:20000:95': 950.0,
  'adb:15:15000:39': 13.0,
  'mem_40:15:10000:75': 59.0,
  'dis:15:20000:55': 11.0,
  'adb:15:7500:2': 5.0,
  'hcb:10:50:26': 3.0,
  'adb:10:2000:113': 29.0,
  'ffun_add:15:20000:71': 255.0,
  'mem_7:10:5000:59': 10.0,
  'adb:15:50000:16': 29.0,
  'mem_7:15:20000:3': 2.0,
  'ffun:15:7500:2': 33.0,
  'adb:15:2000:48': 4.0,
  'mem_7:10:5000:36': 2.0,
  'ffun:10:40000:36': 84.0,
  'ffun_add:10:2000:113': 151.0,
  'ffun_add:15:7500:68': 74.0,
  'mem_7:10:7500:66': 22.0,
  'mem_40:15:25000:45': 24.0,
  'mem_40:15:30000:91': 574.0,
  'adb:10:2000:87': 11.0,
  'adb:10:5000:98': 71.0,
  'ffun_add:10:10000:62': 85.0,
  'adb:15:30000:120': 424.0,
  'hcb:10:50:28': 3.0,
  'ffun_add:10:7500:48': 43.0,
  'ffun:10:50000:67': 460.0,
  'hcb:15:100:45': 12.0,
  'mem:15:10000:109': 289.0,
  'mem_7:15:50000:92': 574.0,
  'mem:10:40000:102': 1154.0,
  'ffun:10:10000:114': 679.0,
  'mem_40:15:2000:59': 6.0,
  'mem_7:10:30000:88': 263.0,
  'mem_40:10:50000:33': 26.0,
  'mem_40:10:5000:73': 31.0,
  'mem_7:15:25000:103': 433.0,
  'mem_40:15:2000:114': 59.0,
  'ffun:15:50000:88': 1203.0,
  'mem:15:50000:103': 1444.0,
  'mem_40:15:5000:93': 96.0,
  'adb:15:40000:2': 23.0,
  'adb:15:5000:64': 2.0,
  'mem_40:15:15000:110': 433.0,
  'adb:15:25000:45': 29.0,
  'ffun_add:10:50000:20': 42.0,
  'ffun:10:15000:93': 681.0,
  'adb:15:40000:48': 62.0,
  'adb:15:30000:53': 66.0,
  'ffun:10:20000:111': 1310.0,
  'ffun:10:20000:58': 154.0,
  'mem_40:15:7500:99': 217.0,
  'ffun_add:15:15000:48': 62.0,
  'mem_7:10:30000:36': 14.0,
  'adb:10:30000:74': 41.0,
  'ffun_add:10:40000:92': 1889.0,
  'adb:10:7500:77': 19.0,
  'adb:15:25000:58': 37.0,
  'ffun_add:10:2000:60': 23.0,
  'mem_7:15:10000:108': 174.0,
  'ffun:10:2000:18': 33.0,
  'mem:10:10000:2': 4.0,
  'adb:15:50000:94': 372.0,
  'ffun:15:10000:70': 95.0,
  'mem_7:15:20000:110': 347.0,
  'hcb:10:150:43': 33.0,
  'mem:15:40000:55': 88.0,
  'ffun:15:30000:107': 1472.0,
  'mem_40:15:7500:5': 2.0,
  'adb:10:30000:55': 83.0,
  'dis:15:30000:40': 11.0,
  'ffun:15:5000:9': 32.0,
  'adb:10:25000:24': 20.0,
  'adb:10:15000:49': 30.0,
  'mem:10:30000:6': 8.0,
  'mem_7:15:20000:103': 347.0,
  'hcb:10:80:42': 9.0,
  'ffun:10:50000:30': 63.0,
  'mem_7:15:15000:97': 260.0,
  'mem:10:30000:119': 865.0,
  'mem_7:15:50000:20': 7.0,
  'mem:15:2000:68': 8.0,
  'ffun_add:10:7500:29': 14.0,
  'mem:15:40000:95': 764.0,
  'ffun_add:10:50000:16': 42.0,
  'adb:10:40000:79': 97.0,
  'ffun_add:15:40000:40': 68.0,
  'ffun_add:15:50000:99': 3373.0,
  'adb:15:10000:105': 142.0,
  'ffun:15:50000:62': 252.0,
  'mem:15:15000:62': 41.0,
  'ffun_add:15:5000:94': 230.0,
  'mem_40:15:15000:66': 59.0,
  'adb:15:20000:100': 282.0,
  'ffun_add:10:25000:48': 126.0,
  'ffun:10:15000:74': 209.0,
  'mem:15:25000:54': 55.0,
  'ffun_add:15:25000:109': 1690.0,
  'ffun_add:15:10000:48': 44.0,
  'ffun:10:2000:25': 33.0,
  'mem_7:10:30000:14': 5.0,
  'mem_7:15:5000:5': 1.0,
  'adb:15:10000:49': 16.0,
  'adb:10:20000:53': 55.0,
  'ffun_add:15:2000:2': 7.0,
  'mem_7:15:40000:47': 35.0,
  'mem_7:10:15000:90': 132.0,
  'mem_7:15:10000:69': 24.0,
  'mem_7:15:10000:18': 2.0,
  'mem:15:2000:95': 38.0,
  'adb:10:30000:30': 25.0,
  'adb:15:2000:38': 2.0,
  'mem_7:10:20000:13': 4.0,
  'ffun_add:10:7500:60': 65.0,
  'mem_7:10:10000:54': 17.0,
  'adb:15:30000:26': 19.0,
  'mem_7:10:10000:114': 174.0,
  'dis:15:20000:45': 10.0,
  'mem_40:15:20000:114': 577.0,
  'ffun_add:15:15000:81': 366.0,
  'ffun:10:50000:53': 316.0,
  'adb:15:15000:78': 32.0,
  'ffun:10:2000:76': 69.0,
  'adb:15:30000:115': 424.0,
  'adb:15:10000:47': 16.0,
  'ffun_add:15:15000:95': 675.0,
  'ffun_add:15:7500:29': 13.0,
  'ffun_add:15:40000:1': 28.0,
  'mem:15:20000:55': 44.0,
  'dis:15:40000:18': 11.0,
  'mem_7:10:15000:64': 30.0,
  'mem_40:15:20000:73': 116.0,
  'ffun:10:20000:113': 1310.0,
  'ffun_add:10:7500:52': 62.0,
  'ffun:15:25000:53': 130.0,
  'mem_7:15:40000:33': 10.0,
  'mem:10:7500:13': 2.0,
  'mem:10:50000:102': 1444.0,
  'adb:10:30000:80': 73.0,
  'adb:15:15000:95': 112.0,
  'ffun:15:25000:70': 191.0,
  'ffun_add:15:7500:71': 100.0,
  'ffun:15:7500:120': 391.0,
  'ffun:15:40000:2': 46.0,
  'mem_7:15:10000:87': 85.0,
  'mem_40:10:15000:19': 5.0,
  'ffun_add:10:25000:108': 1769.0,
  'ffun_add:10:20000:119': 1416.0,
  'adb:10:2000:120': 29.0,
  'ffun:10:7500:91': 364.0,
  'mem_7:15:15000:27': 4.0,
  'ffun:15:10000:31': 38.0,
  'ffun:10:25000:34': 53.0,
  'adb:15:40000:57': 60.0,
  'mem_7:10:2000:44': 2.0,
  'mem_7:10:30000:89': 263.0,
  'mem_7:10:25000:62': 49.0,
  'mem:15:40000:51': 88.0,
  'ffun_add:15:2000:35': 9.0,
  'mem_7:10:5000:93': 59.0,
  'ffun:10:2000:89': 110.0,
  'mem_40:15:5000:97': 145.0,
  'mem:10:40000:115': 1154.0,
  'ffun_add:10:50000:116': 3527.0,
  'ffun_add:10:50000:4': 39.0,
  'hcb:15:120:52': 18.0,
  'mem_7:15:5000:31': 1.0,
  'mem_7:10:7500:2': 1.0,
  'dis:10:50000:33': 15.0,
  'mem:10:10000:56': 30.0,
  'mem:15:7500:81': 78.0,
  'mem_7:15:7500:90': 64.0,
  'ffun:10:20000:108': 1310.0,
  'mem:15:50000:18': 11.0,
  'ffun:15:7500:50': 50.0,
  'ffun:15:25000:88': 617.0,
  'ffun_add:15:5000:107': 343.0,
  'ffun:15:40000:58': 197.0,
  'adb:10:2000:80': 6.0,
  'mem_7:15:2000:16': 1.0,
  'adb:10:25000:76': 61.0,
  'mem:15:7500:116': 217.0,
  'mem_40:10:25000:117': 722.0,
  'mem_7:15:10000:96': 174.0,
  'mem_40:10:2000:43': 4.0,
  'mem_7:15:2000:66': 5.0,
  'ffun_add:15:2000:114': 142.0,
  'mem:15:25000:3': 6.0,
  'mem_40:10:5000:31': 4.0,
  'adb:15:10000:53': 23.0,
  'mem_40:15:5000:37': 4.0,
  'adb:15:20000:28': 13.0,
  'mem_40:10:10000:102': 289.0,
  'ffun_add:10:40000:29': 46.0,
  'mem_40:10:10000:43': 13.0,
  'ffun:10:5000:53': 67.0,
  'ffun_add:15:5000:12': 9.0,
  'mem_7:15:25000:115': 433.0,
  'mem:10:40000:108': 1154.0,
  'adb:10:10000:57': 19.0,
  'ffun:10:30000:83': 767.0,
  'mem:10:40000:92': 772.0,
  'ffun:15:40000:73': 386.0,
  'adb:15:20000:74': 23.0,
  'hcb:15:100:25': 12.0,
  'ffun:15:7500:36': 39.0,
  'ffun_add:15:5000:64': 38.0,
  'mem_40:15:15000:77': 119.0,
  'adb:10:50000:19': 38.0,
  'mem_40:10:30000:42': 37.0,
  'adb:10:5000:105': 71.0,
  'ffun:15:2000:24': 31.0,
  'adb:15:10000:120': 142.0,
  'mem_7:15:50000:21': 7.0,
  'adb:10:2000:61': 2.0,
  'hcb:10:120:58': 20.0,
  'mem_7:10:20000:112': 347.0,
  'mem_7:15:25000:109': 433.0,
  'mem:10:7500:61': 25.0,
  'mem_40:10:10000:35': 6.0,
  'mem_7:15:50000:85': 308.0,
  'mem_7:15:25000:55': 34.0,
  'mem:10:2000:39': 2.0,
  'mem:15:40000:74': 214.0,
  'mem:15:7500:41': 7.0,
  'mem:10:20000:20': 6.0,
  'dis:10:30000:35': 13.0,
  'mem_40:15:7500:75': 44.0,
  'adb:15:15000:24': 10.0,
  'ffun:10:10000:6': 36.0,
  'adb:15:10000:86': 49.0,
  'adb:10:25000:55': 68.0,
  'ffun:15:30000:104': 1472.0,
  'ffun_add:10:5000:114': 362.0,
  'mem:15:5000:8': 1.0,
  'adb:10:30000:35': 29.0,
  'ffun:10:20000:60': 154.0,
  'ffun_add:10:15000:29': 21.0,
  'ffun:10:25000:102': 1626.0,
  'mem_7:15:7500:64': 12.0,
  'mem:15:30000:104': 865.0,
  'mem:15:5000:7': 1.0,
  'ffun:15:2000:48': 36.0,
  'mem_7:10:20000:67': 55.0,
  'ffun:10:15000:97': 994.0,
  'mem:10:2000:49': 5.0,
  'mem:15:5000:70': 20.0,
  'adb:10:40000:17': 31.0,
  'ffun_add:10:15000:107': 1065.0,
  'ffun:10:2000:34': 34.0,
  'mem:10:20000:103': 577.0,
  'ffun_add:10:25000:29': 31.0,
  'ffun_add:10:30000:47': 149.0,
  'adb:15:25000:47': 40.0,
  'mem:10:7500:85': 83.0,
  'adb:10:5000:84': 18.0,
  'mem_7:10:10000:84': 66.0,
  'mem_7:10:10000:55': 17.0,
  'mem_40:15:20000:54': 44.0,
  'hcb:10:80:43': 9.0,
  'adb:15:7500:22': 5.0,
  'adb:10:20000:66': 13.0,
  'ffun_add:15:5000:78': 93.0,
  'hcb:10:80:55': 9.0,
  'mem:10:50000:69': 230.0,
  'ffun_add:10:2000:118': 151.0,
  'ffun:15:5000:26': 33.0,
  'mem_40:10:7500:9': 2.0,
  'adb:10:50000:21': 40.0,
  'mem:10:40000:91': 772.0,
  'mem_7:15:50000:113': 866.0,
  'ffun_add:10:5000:74': 82.0,
  'mem_7:10:15000:99': 260.0,
  'mem_7:15:40000:80': 188.0,
  'mem_40:15:5000:65': 14.0,
  'mem_40:10:10000:79': 83.0,
  'adb:15:2000:17': 1.0,
  'mem_7:10:5000:77': 25.0,
  'adb:10:10000:65': 7.0,
  'mem:15:5000:46': 8.0,
  'mem_7:15:10000:44': 6.0,
  'adb:10:5000:114': 71.0,
  'adb:10:30000:68': 19.0,
  'ffun:15:15000:101': 752.0,
  'ffun_add:10:20000:58': 157.0,
  'dis:15:20000:59': 15.0,
  'mem_40:15:40000:91': 764.0,
  'mem_7:15:40000:78': 188.0,
  'ffun_add:15:2000:21': 7.0,
  'ffun:10:7500:117': 522.0,
  'mem:15:15000:92': 287.0,
  'adb:15:30000:117': 424.0,
  'ffun_add:15:5000:33': 12.0,
  'mem:15:7500:19': 2.0,
  'adb:10:40000:50': 79.0,
  'adb:15:5000:74': 6.0,
  'dis:15:30000:29': 11.0,
  'ffun:15:25000:120': 1232.0,
  'dis:15:20000:58': 15.0,
  'mem_40:10:20000:77': 164.0,
  'mem:10:50000:118': 1444.0,
  'adb:10:25000:34': 24.0,
  'mem_7:10:10000:118': 174.0,
  'mem_40:15:10000:92': 192.0,
  'ffun:15:20000:37': 52.0,
  'ffun:10:30000:8': 44.0,
  'adb:10:15000:80': 37.0,
  'mem:10:5000:47': 10.0,
  'adb:10:20000:107': 282.0,
  'mem_7:15:50000:59': 73.0,
  'ffun_add:10:10000:27': 17.0,
  'mem_40:15:5000:8': 1.0,
  'ffun_add:15:5000:95': 230.0,
  'ffun_add:15:15000:110': 1017.0,
  'adb:15:50000:21': 30.0,
  'mem_40:15:15000:38': 10.0,
  'mem:10:40000:67': 184.0,
  'mem:15:40000:2': 8.0,
  'mem:10:7500:106': 217.0,
  'mem_7:10:7500:117': 131.0,
  'mem_7:15:30000:4': 4.0,
  'mem:15:15000:55': 34.0,
  'ffun:10:10000:0': 36.0,
  'mem:10:7500:12': 2.0,
  'dis:10:20000:44': 12.0,
  'ffun:10:2000:31': 34.0,
  'adb:10:50000:61': 31.0,
  'mem_7:10:2000:119': 35.0,
  'ffun_add:10:10000:113': 713.0,
  'adb:10:7500:62': 5.0,
  'ffun_add:10:40000:112': 2824.0,
  'mem_7:10:50000:14': 8.0,
  'mem_7:15:25000:84': 155.0,
  'dis:15:20000:46': 10.0,
  'mem_7:15:25000:95': 287.0,
  'mem:10:7500:65': 25.0,
  'adb:10:25000:86': 125.0,
  'adb:10:50000:7': 38.0,
  'mem_40:10:10000:23': 4.0,
  'dis:10:20000:56': 18.0,
  'ffun_add:15:50000:0': 34.0,
  'mem_40:15:7500:58': 19.0,
  'mem_7:10:25000:7': 5.0,
  'mem_40:10:2000:10': 1.0,
  'mem:10:20000:84': 220.0,
  'ffun:10:2000:6': 33.0,
  'mem_7:10:25000:103': 433.0,
  'mem_40:10:50000:74': 310.0,
  'mem_40:10:40000:78': 328.0,
  'mem:10:40000:15': 11.0,
  'dis:15:30000:18': 11.0,
  'ffun_add:10:7500:46': 43.0,
  'mem_7:10:10000:29': 2.0,
  'mem_7:10:7500:104': 131.0,
  'ffun:10:5000:3': 33.0,
  'ffun_add:15:2000:100': 142.0,
  'ffun:15:50000:98': 2435.0,
  'mem_7:10:40000:74': 149.0,
  'mem_7:15:40000:111': 692.0,
  'mem_7:10:25000:113': 433.0,
  'mem_7:15:25000:93': 287.0,
  'mem_40:10:2000:5': 1.0,
  'dis:10:40000:44': 14.0,
  'ffun:10:10000:112': 679.0,
  'mem_40:15:30000:94': 574.0,
  'adb:15:5000:43': 6.0,
  'mem_7:15:10000:92': 115.0,
  'ffun:10:5000:98': 363.0,
  'mem_7:15:7500:105': 131.0,
  'ffun_add:15:5000:84': 126.0,
  'ffun_add:10:50000:67': 552.0,
  'mem_40:15:20000:8': 5.0,
  'mem_40:15:30000:11': 7.0,
  'adb:10:20000:14': 16.0,
  'adb:15:10000:58': 16.0,
  'mem:10:20000:3': 6.0,
  'ffun:10:15000:62': 128.0,
  'mem_40:15:10000:33': 5.0,
  'mem_7:15:7500:100': 131.0,
  'mem_40:15:10000:67': 40.0,
  'mem_40:10:2000:71': 13.0,
  'ffun_add:10:40000:94': 1889.0,
  'ffun:10:25000:94': 1102.0,
  'ffun:15:20000:27': 41.0,
  'hcb:15:120:57': 16.0,
  'mem_40:10:10000:78': 83.0,
  'mem_40:10:40000:83': 439.0,
  'ffun_add:15:5000:72': 69.0,
  'ffun_add:10:7500:119': 538.0,
  'ffun_add:15:2000:26': 9.0,
  'adb:15:30000:74': 34.0,
  'ffun_add:15:15000:89': 499.0,
  'ffun:10:50000:79': 789.0,
  'ffun_add:15:5000:119': 343.0,
  'mem_7:10:20000:73': 74.0,
  'mem_7:15:2000:71': 7.0,
  'ffun_add:15:7500:31': 14.0,
  'ffun_add:15:50000:75': 629.0,
  'mem_40:15:2000:106': 59.0,
  'mem_7:15:5000:52': 7.0,
  'mem:15:25000:68': 97.0,
  'mem:10:40000:10': 11.0,
  'mem_40:15:2000:75': 12.0,
  'dis:10:20000:18': 12.0,
  'adb:15:2000:78': 5.0,
  'mem:10:30000:2': 8.0,
  'mem_7:15:50000:41': 29.0,
  'adb:10:25000:37': 29.0,
  'mem_40:15:20000:81': 205.0,
  'hcb:10:150:31': 31.0,
  'ffun_add:10:5000:104': 362.0,
  'ffun_add:10:5000:81': 144.0,
  'ffun_add:10:5000:102': 362.0,
  'mem_7:10:15000:86': 132.0,
  'ffun:10:30000:67': 292.0,
  'mem_7:15:25000:39': 10.0,
  'mem:10:7500:69': 35.0,
  'mem_7:15:15000:7': 2.0,
  'mem:10:5000:5': 2.0,
  'ffun_add:10:40000:89': 1430.0,
  'mem_7:15:25000:100': 433.0,
  'adb:15:2000:9': 1.0,
  'adb:10:15000:34': 14.0,
  'hcb:10:80:18': 9.0,
  'dis:15:30000:55': 12.0,
  'ffun:10:25000:51': 177.0,
  'ffun_add:15:5000:56': 35.0,
  'mem:10:40000:16': 12.0,
  'ffun:10:25000:2': 42.0,
  'mem:10:10000:115': 289.0,
  'mem:15:20000:104': 577.0,
  'mem_40:10:20000:24': 7.0,
  'mem_40:15:15000:103': 433.0,
  'adb:15:40000:116': 564.0,
  'adb:10:5000:88': 25.0,
  'adb:10:5000:55': 14.0,
  'mem:15:5000:32': 2.0,
  'adb:15:2000:55': 5.0,
  'mem:15:20000:45': 19.0,
  'ffun:15:20000:5': 38.0,
  'mem_7:15:10000:105': 174.0,
  'mem:10:15000:54': 42.0,
  'dis:10:40000:57': 21.0,
  'mem_7:15:15000:72': 53.0,
  'adb:15:10000:96': 142.0,
  'mem_40:15:50000:82': 512.0,
  'adb:15:50000:12': 29.0,
  'mem:10:10000:39': 8.0,
  'mem_7:10:50000:90': 437.0,
  'adb:15:15000:35': 12.0,
  'mem:10:25000:41': 31.0,
  'mem_40:10:50000:5': 13.0,
  'mem:10:40000:4': 11.0,
  'mem:15:2000:106': 59.0,
  'mem_40:15:40000:96': 1154.0,
  'mem:10:40000:106': 1154.0,
  'mem:10:15000:116': 433.0,
  'ffun:15:15000:36': 47.0,
  'mem:10:50000:75': 310.0,
  'ffun:10:50000:47': 191.0,
  'adb:10:20000:23': 16.0,
  'adb:10:15000:86': 76.0,
  'mem:15:2000:43': 2.0,
  'ffun_add:10:30000:18': 28.0,
  'adb:15:30000:35': 23.0,
  'ffun_add:10:15000:92': 715.0,
  'mem_40:10:2000:42': 4.0,
  'mem_40:10:50000:115': 1444.0,
  'mem_40:15:50000:74': 290.0,
  'ffun_add:10:30000:25': 31.0,
  'ffun_add:10:2000:29': 9.0,
  'mem_7:15:50000:115': 866.0,
  'mem_7:10:10000:37': 5.0,
  'ffun:15:20000:14': 38.0,
  'mem_7:15:5000:93': 58.0,
  'mem_7:10:20000:86': 175.0,
  'mem:10:7500:15': 2.0,
  'ffun:15:50000:4': 49.0,
  'adb:10:25000:91': 188.0,
  'ffun_add:10:10000:0': 13.0,
  'hcb:15:120:39': 17.0,
  'mem_7:10:15000:73': 56.0,
  'mem:15:50000:5': 11.0,
  'ffun_add:15:30000:87': 991.0,
  'ffun_add:10:40000:40': 85.0,
  'adb:15:7500:72': 8.0,
  'ffun:10:10000:110': 679.0,
  'adb:15:2000:72': 2.0,
  'adb:10:50000:63': 31.0,
  'dis:15:20000:18': 10.0,
  'adb:15:50000:96': 706.0,
  'ffun_add:15:25000:59': 153.0,
  'mem_40:10:2000:65': 7.0,
  'mem_40:15:20000:97': 577.0,
  'adb:10:10000:48': 20.0,
  'ffun:10:25000:107': 1626.0,
  'mem:10:10000:4': 4.0,
  'adb:10:50000:83': 172.0,
  'mem_7:10:40000:6': 7.0,
  'mem_40:10:20000:106': 577.0,
  'mem_7:10:15000:32': 5.0,
  'mem:15:5000:26': 2.0,
  'mem_7:15:7500:60': 12.0,
  'adb:15:15000:113': 212.0,
  'mem_40:15:7500:94': 144.0,
  'hcb:15:150:24': 26.0,
  'adb:10:50000:86': 250.0,
  'adb:10:25000:33': 24.0,
  'hcb:10:120:35': 20.0,
  'mem_7:15:20000:92': 230.0,
  'ffun_add:10:50000:75': 739.0,
  'mem_7:15:50000:91': 574.0,
  'mem:10:40000:120': 1154.0,
  'ffun_add:10:50000:93': 2359.0,
  'ffun_add:15:50000:1': 34.0,
  'mem:10:15000:71': 94.0,
  'ffun_add:10:5000:86': 188.0,
  'mem:15:7500:64': 20.0,
  'ffun_add:15:25000:58': 153.0,
  'ffun_add:10:25000:42': 86.0,
  'adb:10:5000:60': 10.0,
  'adb:15:25000:55': 55.0,
  'adb:10:5000:118': 71.0,
  'adb:15:15000:100': 212.0,
  'mem:10:40000:46': 74.0,
  'mem_40:15:10000:14': 2.0,
  'ffun:15:30000:69': 223.0,
  'mem_7:15:7500:117': 131.0,
  'adb:10:50000:105': 706.0,
  'mem_7:15:2000:45': 1.0,
  'mem_40:15:2000:37': 1.0,
  'mem:15:25000:103': 722.0,
  'mem:15:2000:48': 4.0,
  'hcb:15:120:44': 18.0,
  'mem_7:15:10000:91': 115.0,
  'mem_40:15:15000:12': 4.0,
  'adb:10:5000:71': 7.0,
  'dis:10:20000:31': 12.0,
  'ffun_add:10:40000:77': 782.0,
  'ffun_add:10:20000:70': 225.0,
  'mem_7:10:2000:105': 35.0,
  'adb:15:5000:24': 4.0,
  'ffun:10:40000:43': 119.0,
  'adb:10:15000:38': 18.0,
  'ffun_add:15:40000:91': 1790.0,
  'mem_40:10:2000:55': 6.0,
  'hcb:15:80:22': 7.0,
  'ffun_add:15:50000:24': 38.0,
  'ffun_add:10:40000:90': 1430.0,
  'mem_7:10:25000:45': 19.0,
  'mem_40:15:5000:95': 96.0,
  'mem_7:10:25000:88': 218.0,
  'ffun:10:40000:57': 268.0,
  'ffun:10:2000:78': 69.0,
  'mem_40:15:30000:25': 7.0,
  'mem_40:15:7500:34': 4.0,
  'mem:10:7500:115': 217.0,
  'mem:10:20000:70': 92.0,
  'mem:15:30000:116': 865.0,
  'ffun_add:10:2000:69': 30.0,
  'mem:10:50000:92': 964.0,
  'mem_7:15:7500:52': 11.0,
  'mem_7:10:10000:66': 28.0,
  'adb:15:40000:51': 88.0,
  'mem_40:15:20000:67': 78.0,
  'ffun_add:10:15000:19': 17.0,
  'mem:10:25000:117': 722.0,
  'hcb:15:120:18': 17.0,
  'ffun_add:10:50000:106': 3527.0,
  'ffun_add:10:20000:8': 20.0,
  'ffun_add:10:15000:103': 1065.0,
  'ffun_add:10:20000:88': 720.0,
  'ffun:10:50000:69': 460.0,
  'ffun_add:15:10000:80': 178.0,
  'adb:15:2000:0': 1.0,
  'mem_40:10:2000:106': 59.0,
  'mem_40:10:40000:22': 13.0,
  'mem:15:15000:91': 287.0,
  'mem:10:40000:24': 13.0,
  'adb:15:40000:101': 564.0,
  'mem_7:10:5000:86': 44.0,
  'mem:15:25000:15': 6.0,
  'ffun_add:15:50000:50': 194.0,
  'adb:10:7500:117': 107.0,
  'adb:10:30000:60': 56.0,
  'mem_40:10:10000:29': 5.0,
  'mem_40:10:30000:70': 138.0,
  'adb:15:50000:47': 78.0,
  'ffun:10:5000:24': 34.0,
  'mem:15:7500:34': 4.0,
  'mem_40:15:20000:64': 54.0,
  'mem_40:10:20000:16': 6.0,
  'mem_7:10:7500:37': 4.0,
  'ffun_add:15:50000:68': 457.0,
  'ffun_add:10:50000:61': 390.0,
  'ffun:10:50000:65': 334.0,
  'mem_7:15:10000:84': 62.0,
  'mem_40:10:50000:2': 13.0,
  'ffun:15:30000:14': 42.0,
  'adb:15:40000:114': 564.0,
  'ffun_add:15:10000:14': 12.0,
  'mem:15:40000:56': 97.0,
  'mem_40:15:15000:48': 23.0,
  'mem:15:15000:1': 4.0,
  'mem_7:15:10000:111': 174.0,
  'ffun:10:15000:53': 122.0,
  'mem_40:10:2000:90': 30.0,
  'ffun:10:25000:49': 112.0,
  'mem_40:10:5000:77': 42.0,
  'mem:15:30000:45': 29.0,
  'mem_40:15:50000:93': 956.0,
  'ffun:15:25000:89': 617.0,
  'mem:15:7500:98': 217.0,
  'adb:15:7500:102': 107.0,
  'mem:10:40000:83': 439.0,
  'mem_7:10:5000:91': 59.0,
  'mem_40:10:5000:90': 73.0,
  'ffun_add:10:20000:67': 225.0,
  'mem_7:10:25000:50': 29.0,
  'adb:10:2000:72': 4.0,
  'adb:15:7500:104': 107.0,
  'ffun:10:40000:78': 639.0,
  'adb:15:30000:59': 46.0,
  'mem_7:10:5000:31': 2.0,
  'mem_40:15:25000:2': 6.0,
  'mem:15:5000:118': 145.0,
  'ffun_add:15:7500:41': 25.0,
  'mem_40:15:2000:49': 4.0,
  'adb:10:40000:34': 37.0,
  'mem:10:10000:88': 146.0,
  'mem_40:15:20000:61': 54.0,
  'ffun:10:30000:78': 488.0,
  'mem_40:10:20000:68': 92.0,
  'ffun:15:30000:114': 1472.0,
  'dis:15:40000:35': 12.0,
  'ffun:15:7500:67': 78.0,
  'dis:15:10000:27': 9.0,
  'ffun_add:15:7500:52': 48.0,
  'mem_7:10:10000:110': 174.0,
  'adb:10:2000:102': 29.0,
  'hcb:10:100:53': 15.0,
  'mem:15:15000:64': 41.0,
  'mem:10:15000:31': 8.0,
  'ffun_add:15:25000:82': 604.0,
  'mem:15:50000:90': 704.0,
  'ffun_add:15:20000:93': 898.0,
  'mem_7:10:30000:28': 7.0,
  'ffun:15:7500:42': 43.0,
  'mem_7:10:30000:77': 148.0,
  'ffun:10:10000:64': 99.0,
  'mem:15:25000:100': 722.0,
  'hcb:15:120:53': 18.0,
  'mem_7:15:5000:1': 1.0,
  'ffun_add:15:30000:98': 2025.0,
  'mem_40:15:30000:93': 574.0,
  'mem:10:30000:30': 12.0,
  'mem:15:7500:56': 19.0,
  'mem:10:50000:108': 1444.0,
  'mem:15:50000:107': 1444.0,
  'adb:15:30000:27': 19.0,
  'mem_7:15:20000:69': 47.0,
  'mem_40:15:5000:96': 145.0,
  'adb:10:30000:110': 424.0,
  'adb:15:7500:0': 5.0,
  'ffun_add:15:2000:118': 142.0,
  'mem_7:15:15000:69': 35.0,
  'mem_7:15:2000:110': 35.0,
  'mem_40:15:25000:76': 197.0,
  'ffun_add:10:15000:81': 411.0,
  'mem:10:30000:103': 865.0,
  'mem_40:15:25000:114': 722.0,
  'adb:15:15000:29': 10.0,
  'adb:10:30000:100': 424.0,
  'ffun_add:15:2000:3': 7.0,
  'mem_40:15:7500:63': 20.0,
  'adb:15:40000:61': 19.0,
  'mem_7:15:15000:118': 260.0,
  'mem:10:2000:66': 10.0,
  'mem_7:10:15000:23': 4.0,
  'ffun_add:10:10000:39': 27.0,
  'mem_7:10:5000:27': 1.0,
  'mem_40:10:30000:60': 90.0,
  'mem_7:15:20000:51': 26.0,
  'ffun:15:2000:39': 32.0,
  'ffun:10:2000:46': 40.0,
  'dis:10:30000:44': 13.0,
  'dis:10:50000:55': 17.0,
  'mem_7:10:7500:120': 131.0,
  'ffun:10:7500:99': 522.0,
  'mem_40:15:30000:56': 72.0,
  'mem_40:10:30000:106': 865.0,
  'mem_40:10:2000:97': 59.0,
  'ffun:15:25000:66': 191.0,
  'ffun:10:7500:22': 35.0,
  'mem_40:10:7500:44': 10.0,
  'ffun_add:15:40000:106': 2698.0,
  'ffun_add:10:7500:109': 538.0,
  'mem:10:25000:12': 7.0,
  'ffun_add:15:50000:7': 34.0,
  'adb:10:50000:84': 172.0,
  'ffun:15:5000:117': 271.0,
  'ffun_add:15:40000:107': 2698.0,
  'mem:10:15000:101': 433.0,
  'mem_7:10:10000:1': 2.0,
  'ffun_add:15:50000:94': 2236.0,
  'mem_7:15:40000:30': 7.0,
  'adb:10:25000:72': 34.0,
  'ffun:10:20000:5': 40.0,
  'adb:15:50000:51': 109.0,
  'adb:10:40000:32': 37.0,
  'mem_7:15:50000:57': 73.0,
  'mem_40:10:20000:30': 8.0,
  'mem_7:15:10000:56': 16.0,
  'adb:15:30000:92': 223.0,
  'mem_40:10:2000:92': 40.0,
  'mem:15:50000:84': 512.0,
  'mem:15:5000:13': 1.0,
  'mem:10:2000:114': 59.0,
  'ffun:10:40000:85': 1008.0,
  'mem_7:10:25000:102': 433.0,
  'mem_7:10:30000:58': 54.0,
  'ffun_add:15:10000:115': 679.0,
  'adb:10:7500:51': 22.0,
  'ffun:10:5000:5': 33.0,
  'mem_40:10:5000:117': 145.0,
  'adb:15:15000:23': 10.0,
  'mem_40:10:50000:91': 964.0,
  'ffun_add:15:10000:93': 453.0,
  'adb:10:5000:49': 11.0,
  'mem_7:15:30000:70': 70.0,
  'ffun_add:15:15000:10': 14.0,
  'adb:10:20000:19': 16.0,
  'adb:15:20000:10': 12.0,
  'adb:15:15000:20': 10.0,
  'ffun_add:15:15000:77': 264.0,
  'adb:10:40000:103': 564.0,
  'ffun_add:15:15000:29': 18.0,
  'ffun:15:15000:62': 97.0,
  'mem_7:10:20000:58': 36.0,
  'mem_7:15:50000:22': 7.0,
  'mem_40:10:30000:105': 865.0,
  'ffun_add:10:40000:26': 46.0,
  'hcb:15:120:38': 17.0,
  'ffun:10:20000:50': 97.0,
  'ffun:10:40000:40': 84.0,
  'mem_7:15:50000:89': 422.0,
  'hcb:15:100:49': 13.0,
  'ffun_add:15:10000:35': 17.0,
  'ffun_add:10:10000:65': 85.0,
  'mem_40:15:50000:63': 133.0,
  'mem_7:15:5000:3': 1.0,
  'ffun:15:30000:118': 1472.0,
  'adb:15:25000:13': 14.0,
  'ffun_add:10:25000:19': 24.0,
  'ffun_add:10:30000:99': 2118.0,
  'ffun_add:15:20000:0': 17.0,
  'ffun_add:15:50000:80': 863.0,
  'mem_40:15:15000:84': 155.0,
  'adb:10:7500:46': 16.0,
  'adb:10:40000:33': 37.0,
  'mem_7:15:20000:106': 347.0,
  'ffun_add:15:10000:55': 62.0,
  'adb:10:30000:45': 44.0,
  'adb:15:15000:28': 10.0,
  'hcb:15:100:43': 12.0,
  'adb:15:7500:46': 12.0,
  'adb:10:20000:48': 40.0,
  'ffun_add:10:2000:34': 9.0,
  'ffun_add:15:40000:17': 30.0,
  'mem_7:10:25000:75': 94.0,
  'adb:10:20000:119': 282.0,
  'ffun:15:2000:74': 48.0,
  'hcb:15:50:26': 3.0,
  'mem_40:10:50000:40': 38.0,
  'mem_40:15:25000:27': 7.0,
  'mem_7:10:30000:52': 50.0,
  'mem_40:15:30000:86': 422.0,
  'adb:10:30000:27': 25.0,
  'ffun_add:15:25000:98': 1690.0,
  'adb:15:15000:106': 212.0,
  'ffun:15:2000:30': 31.0,
  'mem_40:15:2000:46': 4.0,
  'adb:10:30000:112': 424.0,
  'mem:10:20000:45': 25.0,
  'mem_40:10:30000:18': 8.0,
  'ffun:15:5000:120': 271.0,
  'mem:10:10000:50': 19.0,
  'ffun_add:15:15000:28': 18.0,
  'mem_40:10:7500:74': 47.0,
  'ffun:10:30000:33': 57.0,
  'mem_40:15:7500:84': 78.0,
  'mem_7:10:50000:40': 24.0,
  'adb:15:2000:3': 1.0,
  'mem:15:25000:98': 722.0,
  'mem:10:10000:120': 289.0,
  'dis:15:50000:43': 13.0,
  'adb:10:10000:38': 12.0,
  'mem:15:2000:50': 4.0,
  'ffun:15:7500:103': 391.0,
  'ffun_add:15:20000:33': 27.0,
  'mem:15:50000:24': 12.0,
  'adb:15:25000:81': 80.0,
  'adb:10:7500:20': 6.0,
  'mem_40:10:25000:83': 275.0,
  'ffun:15:15000:19': 36.0,
  'mem_40:15:20000:28': 6.0,
  'ffun:10:10000:79': 189.0,
  'adb:15:15000:88': 73.0,
  'ffun:10:50000:68': 460.0,
  'ffun_add:15:5000:83': 126.0,
  'mem_40:10:50000:114': 1444.0,
  'ffun_add:15:40000:104': 2698.0,
  'mem_40:15:20000:10': 5.0,
  'ffun_add:15:50000:25': 38.0,
  'mem_40:15:2000:2': 1.0,
  'mem_7:10:15000:113': 260.0,
  'dis:15:40000:50': 12.0,
  'mem_40:10:25000:111': 722.0,
  'mem_40:15:7500:89': 106.0,
  'ffun:15:20000:58': 114.0,
  'ffun:15:15000:76': 214.0,
  'ffun:15:5000:56': 51.0,
  'mem_7:15:40000:87': 338.0,
  'ffun_add:10:30000:23': 31.0,
  'mem:15:15000:35': 6.0,
  'mem_40:10:7500:3': 2.0,
  'mem:15:40000:103': 1154.0,
  'adb:15:25000:6': 14.0,
  'mem_40:15:5000:34': 2.0,
  'adb:15:15000:87': 73.0,
  'mem_7:15:2000:108': 35.0,
  'ffun_add:15:25000:19': 21.0,
  'ffun_add:15:2000:53': 17.0,
  'ffun:10:7500:65': 84.0,
  'dis:15:50000:49': 13.0,
  'mem_7:10:2000:8': 1.0,
  'mem_40:15:20000:96': 577.0,
  'ffun:10:5000:41': 45.0,
  'mem:15:10000:85': 103.0,
  'ffun_add:10:20000:118': 1416.0,
  'adb:15:25000:35': 19.0,
  'adb:15:20000:48': 31.0,
  'adb:10:15000:13': 12.0,
  'mem:15:7500:33': 4.0,
  'ffun_add:15:50000:118': 3373.0,
  'adb:10:25000:101': 353.0,
  'adb:10:15000:107': 212.0,
  'mem_40:15:40000:21': 10.0,
  'ffun:10:10000:1': 36.0,
  'mem_40:10:20000:95': 386.0,
  'ffun_add:15:15000:98': 1017.0,
  'mem:15:20000:76': 148.0,
  'ffun_add:15:7500:35': 14.0,
  'mem:15:30000:7': 6.0,
  'mem_40:10:25000:29': 10.0,
  'adb:15:2000:4': 1.0,
  'mem_40:10:10000:1': 4.0,
  'mem_40:10:20000:18': 6.0,
  'mem_7:10:10000:12': 2.0,
  'mem_40:15:2000:34': 1.0,
  'adb:10:2000:73': 4.0,
  'mem_40:15:25000:55': 55.0,
  'mem_7:15:10000:81': 62.0,
  'adb:10:7500:53': 22.0,
  'mem_40:10:30000:65': 97.0,
  'hcb:10:50:18': 3.0,
  'mem_7:15:30000:41': 17.0,
  'ffun:15:10000:50': 57.0,
  'mem_7:15:15000:29': 4.0,
  'adb:10:2000:38': 2.0,
  'ffun_add:10:40000:119': 2824.0,
  'hcb:10:100:30': 14.0,
  'ffun_add:15:10000:29': 14.0,
  'ffun:10:5000:111': 363.0,
  'ffun:15:2000:1': 31.0,
  'ffun:10:15000:1': 38.0,
  'mem:15:2000:30': 1.0,
  'adb:15:5000:89': 25.0,
  'ffun:15:5000:105': 271.0,
  'mem_40:15:50000:120': 1444.0,
  'mem_7:15:30000:80': 142.0,
  'hcb:15:100:54': 12.0,
  'mem_7:10:40000:98': 692.0,
  'mem:15:20000:68': 78.0,
  'ffun:15:2000:113': 126.0,
  'mem_40:10:20000:3': 6.0,
  'mem:10:30000:35': 16.0,
  'hcb:10:120:37': 20.0,
  'adb:15:30000:34': 23.0,
  'ffun:10:25000:44': 87.0,
  'dis:15:50000:20': 12.0,
  'mem:15:10000:103': 289.0,
  'mem:15:20000:3': 5.0,
  'mem_40:10:10000:26': 5.0,
  'ffun:10:30000:103': 1940.0,
  'adb:10:7500:2': 6.0,
  'ffun:15:2000:50': 36.0,
  'mem_40:15:30000:59': 72.0,
  'mem_40:15:50000:51': 110.0,
  'mem:15:50000:1': 11.0,
  'mem:10:2000:20': 1.0,
  'mem_7:10:15000:115': 260.0,
  'mem:10:15000:37': 12.0,
  'mem_7:15:7500:75': 26.0,
  'hcb:10:120:28': 20.0,
  'mem_40:15:2000:62': 6.0,
  'hcb:10:80:54': 9.0,
  'mem:15:2000:70': 8.0,
  'adb:15:50000:43': 58.0,
  'ffun_add:15:50000:21': 38.0,
  'mem_7:10:50000:111': 866.0,
  'mem_40:10:30000:120': 865.0,
  'adb:10:50000:104': 706.0,
  'ffun_add:10:2000:26': 9.0,
  'adb:10:15000:67': 10.0,
  'mem_7:15:30000:61': 48.0,
  'adb:15:5000:31': 5.0,
  'mem_40:10:10000:100': 289.0,
  'adb:10:10000:24': 8.0,
  'mem_7:15:50000:30': 10.0,
  'mem_7:15:25000:2': 4.0,
  'ffun_add:10:20000:90': 720.0,
  'adb:10:25000:60': 48.0,
  'mem_7:15:40000:106': 692.0,
  'ffun:15:50000:73': 475.0,
  'adb:10:10000:14': 8.0,
  'ffun_add:15:7500:15': 10.0,
  'dis:10:50000:23': 14.0,
  'mem_40:10:50000:37': 38.0,
  'mem_7:10:10000:62': 20.0,
  'mem_7:10:2000:92': 24.0,
  'mem_40:10:50000:38': 38.0,
  'mem:15:5000:113': 145.0,
  'mem_40:10:2000:3': 1.0,
  'mem_40:15:15000:112': 433.0,
  'adb:15:5000:15': 4.0,
  'ffun_add:10:50000:56': 378.0,
  'adb:15:40000:94': 298.0,
  'ffun:10:30000:86': 1002.0,
  'mem:10:20000:42': 25.0,
  'mem_40:10:50000:55': 139.0,
  'mem:10:40000:79': 328.0,
  'hcb:15:80:46': 8.0,
  'dis:15:30000:46': 11.0,
  'ffun_add:15:2000:120': 142.0,
  'ffun_add:10:20000:30': 27.0,
  'mem_7:10:40000:51': 67.0,
  'mem_7:15:20000:61': 32.0,
  'mem_40:10:15000:16': 5.0,
  'ffun:10:10000:84': 288.0,
  'mem_7:15:25000:88': 211.0,
  'mem_7:10:15000:111': 260.0,
  'ffun_add:10:50000:27': 56.0,
  'ffun:15:15000:95': 508.0,
  'adb:15:15000:116': 212.0,
  'mem_7:10:10000:100': 174.0,
  'mem_40:10:20000:117': 577.0,
  'ffun_add:10:15000:41': 54.0,
  'ffun_add:10:10000:110': 713.0,
  'mem_40:15:40000:88': 563.0,
  'mem_40:15:50000:90': 704.0,
  'mem_7:10:40000:69': 110.0,
  'mem_40:10:5000:111': 145.0,
  'mem_40:15:5000:84': 52.0,
  'ffun_add:15:30000:61': 192.0,
  'ffun_add:10:7500:93': 362.0,
  'mem_7:10:30000:116': 520.0,
  'mem_40:15:5000:106': 145.0,
  'dis:10:40000:49': 14.0,
  'mem_40:10:25000:66': 115.0,
  'mem_40:10:5000:115': 145.0,
  'mem:15:5000:109': 145.0,
  'mem_40:15:10000:64': 28.0,
  'ffun:10:25000:12': 42.0,
  'mem:15:50000:74': 268.0,
  'ffun_add:15:2000:14': 7.0,
  'ffun:10:2000:96': 173.0,
  'dis:10:40000:34': 14.0,
  'ffun:10:20000:29': 44.0,
  'mem_7:10:2000:36': 1.0,
  'ffun_add:10:50000:28': 56.0,
  'mem:10:50000:22': 16.0,
  'mem:15:50000:21': 12.0,
  'mem_7:10:2000:74': 8.0,
  'mem_40:10:25000:108': 722.0,
  'mem:10:5000:86': 73.0,
  'mem_40:10:15000:98': 433.0,
  'ffun:15:7500:79': 122.0,
  'ffun_add:10:30000:14': 27.0,
  'mem_7:10:2000:89': 18.0,
  'ffun:15:7500:88': 206.0,
  'ffun_add:15:25000:34': 33.0,
  'ffun:10:25000:73': 322.0,
  'mem:10:10000:61': 32.0,
  'mem_7:15:25000:31': 6.0,
  'ffun_add:15:30000:100': 2025.0,
  'adb:15:10000:10': 6.0,
  'ffun:10:50000:56': 326.0,
  'mem_40:10:10000:48': 19.0,
  'ffun:10:10000:93': 469.0,
  'mem:10:30000:82': 329.0,
  'mem_40:10:20000:53': 56.0,
  'ffun:15:50000:93': 1623.0,
  'mem_40:15:7500:7': 2.0,
  'ffun_add:10:10000:70': 117.0,
  'mem_7:15:40000:71': 140.0,
  'hcb:10:50:50': 4.0,
  'mem_40:15:2000:70': 8.0,
  'ffun_add:15:5000:32': 12.0,
  'mem_40:10:30000:119': 865.0,
  'ffun:15:40000:64': 208.0,
  'ffun:15:25000:87': 617.0,
  'adb:15:30000:42': 35.0,
  'ffun_add:10:10000:106': 713.0,
  'mem:15:7500:72': 41.0,
  'mem_7:10:10000:17': 2.0,
  'mem_7:10:20000:82': 132.0,
  'mem_40:15:30000:15': 7.0,
  'mem_7:15:7500:92': 86.0,
  'mem:15:15000:93': 287.0,
  'adb:15:25000:46': 40.0,
  'mem_40:15:5000:82': 52.0,
  'dis:15:30000:20': 11.0,
  'hcb:15:150:25': 26.0,
  'adb:10:40000:29': 32.0,
  'mem_7:15:15000:0': 2.0,
  'mem_40:10:15000:28': 6.0,
  'mem:10:20000:90': 292.0,
  'mem_40:10:30000:111': 865.0,
  'mem_7:15:2000:7': 1.0,
  'mem:10:15000:107': 433.0,
  'ffun:15:2000:2': 31.0,
  'adb:15:5000:12': 4.0,
  'adb:10:50000:52': 137.0,
  'adb:15:20000:24': 12.0,
  'ffun_add:15:10000:54': 62.0,
  'ffun_add:10:2000:6': 8.0,
  'mem:15:20000:22': 5.0,
  'mem:10:50000:48': 92.0,
  'adb:10:50000:49': 98.0,
  'adb:15:50000:42': 58.0,
  'mem_40:15:20000:71': 116.0,
  'adb:15:50000:71': 56.0,
  'mem_7:15:20000:26': 4.0,
  'ffun_add:10:7500:96': 538.0,
  'mem:15:30000:58': 72.0,
  'adb:15:30000:60': 46.0,
  'ffun:10:40000:94': 1734.0,
  'mem:15:20000:46': 30.0,
  'ffun_add:10:20000:19': 21.0,
  'ffun_add:15:25000:90': 828.0,
  'dis:10:50000:36': 15.0,
  'dis:10:30000:27': 13.0,
  'mem_40:15:2000:97': 59.0,
  'mem_40:10:2000:30': 1.0,
  'adb:15:50000:110': 706.0,
  'mem_7:15:40000:13': 6.0,
  'mem:10:30000:1': 8.0,
  'ffun_add:10:10000:79': 202.0,
  'mem:10:10000:64': 32.0,
  'mem_7:15:7500:62': 12.0,
  'ffun:10:2000:13': 33.0,
  'mem_40:15:5000:11': 1.0,
  'mem_40:15:40000:38': 24.0,
  'adb:15:20000:113': 282.0,
  'ffun_add:10:7500:107': 538.0,
  'adb:15:10000:44': 12.0,
  'ffun_add:10:30000:64': 237.0,
  'ffun_add:15:30000:93': 1343.0,
  'ffun_add:10:50000:70': 552.0,
  'mem_7:10:20000:102': 347.0,
  'mem:10:5000:115': 145.0,
  'dis:10:40000:31': 14.0,
  'ffun_add:15:50000:92': 2236.0,
  'mem_7:10:30000:51': 50.0,
  'adb:15:7500:9': 5.0,
  'adb:10:15000:16': 12.0,
  'mem:15:25000:107': 722.0,
  'mem_7:10:10000:32': 4.0,
  'dis:10:30000:53': 15.0,
  'adb:15:10000:43': 12.0,
  'adb:10:40000:5': 31.0,
  'adb:10:25000:17': 19.0,
  'ffun:15:20000:99': 992.0,
  'ffun:10:30000:94': 1312.0,
  'mem_7:15:25000:70': 59.0,
  'ffun_add:15:25000:27': 26.0,
  'mem:15:10000:69': 40.0,
  'adb:15:10000:60': 16.0,
  'ffun:15:30000:26': 47.0,
  'adb:15:20000:86': 97.0,
  'adb:15:25000:110': 353.0,
  'ffun_add:15:20000:98': 1353.0,
  'ffun_add:15:10000:31': 17.0,
  'adb:10:20000:118': 282.0,
  'ffun:15:20000:69': 159.0,
  'ffun_add:10:7500:33': 17.0,
  'mem_7:15:5000:46': 5.0,
  'mem:15:25000:49': 37.0,
  'ffun:10:30000:41': 98.0,
  'mem:15:50000:63': 133.0,
  'mem:10:2000:72': 13.0,
  'mem:10:5000:73': 31.0,
  'adb:10:5000:63': 4.0,
  'adb:15:2000:100': 29.0,
  'ffun:10:25000:64': 187.0,
  'mem_7:15:10000:49': 10.0,
  'mem_7:10:7500:94': 88.0,
  'ffun:15:40000:90': 968.0,
  'adb:10:25000:39': 29.0,
  'ffun:10:20000:75': 266.0,
  'adb:10:25000:50': 49.0,
  'adb:15:15000:31': 12.0,
  'mem_7:10:20000:108': 347.0,
  'mem_40:10:25000:105': 722.0,
  'mem_40:10:25000:61': 82.0,
  'adb:15:7500:10': 5.0,
  'ffun_add:15:15000:88': 499.0,
  'adb:10:10000:7': 8.0,
  'mem:15:5000:25': 1.0,
  'mem:10:50000:90': 728.0,
  'ffun_add:10:20000:51': 151.0,
  'adb:10:7500:39': 10.0,
  'mem:15:25000:56': 61.0,
  'adb:10:2000:20': 2.0,
  'mem_40:10:25000:63': 82.0,
  'mem_7:10:7500:103': 131.0,
  'ffun:15:20000:30': 41.0,
  'mem_40:10:15000:12': 5.0,
  'adb:10:5000:48': 11.0,
  'adb:15:2000:56': 4.0,
  'mem_40:15:2000:3': 1.0,
  'mem_40:15:5000:13': 1.0,
  'mem:10:15000:75': 94.0,
  'mem:15:25000:118': 722.0,
  'ffun_add:10:50000:14': 41.0,
  'adb:15:15000:19': 10.0,
  'ffun:15:30000:27': 47.0,
  'mem:15:10000:14': 2.0,
  'adb:10:40000:42': 59.0,
  'adb:15:50000:57': 74.0,
  'mem_7:10:5000:16': 1.0,
  'mem:10:40000:101': 1154.0,
  'ffun_add:10:30000:11': 27.0,
  'mem:10:7500:39': 6.0,
  'mem_40:15:15000:74': 88.0,
  'mem:15:2000:89': 29.0,
  'mem:10:2000:105': 59.0,
  'mem_7:15:5000:73': 18.0,
  'ffun_add:15:20000:3': 17.0,
  'hcb:15:100:30': 12.0,
  'hcb:10:50:25': 3.0,
  'ffun:10:2000:30': 33.0,
  'mem:15:15000:19': 4.0,
  'mem_7:10:30000:10': 5.0,
  'mem_40:15:2000:68': 8.0,
  'mem:10:50000:34': 26.0,
  'ffun_add:10:30000:103': 2118.0,
  'ffun:10:10000:21': 37.0,
  'ffun:15:15000:86': 382.0,
  'ffun_add:15:15000:43': 44.0,
  'ffun_add:15:2000:91': 96.0,
  'adb:10:7500:57': 14.0,
  'hcb:15:120:26': 17.0,
  'mem_7:10:25000:32': 8.0,
  'dis:15:40000:44': 12.0,
  'mem_7:10:50000:23': 10.0,
  'dis:15:40000:28': 12.0,
  'mem:15:15000:46': 23.0,
  'mem_7:15:7500:42': 5.0,
  'ffun_add:10:15000:11': 17.0,
  'ffun:10:20000:26': 44.0,
  'mem_7:15:2000:23': 1.0,
  'ffun_add:10:10000:105': 713.0,
  'mem_7:15:5000:16': 1.0,
  'mem_40:15:25000:101': 722.0,
  'adb:10:30000:39': 35.0,
  'mem_40:10:15000:91': 289.0,
  'mem_7:10:5000:22': 1.0,
  'mem_40:15:20000:38': 12.0,
  'ffun:10:30000:44': 98.0,
  'mem_40:10:40000:8': 11.0,
  'ffun:10:10000:116': 679.0,
  'adb:10:20000:7': 16.0,
  'ffun_add:15:5000:22': 10.0,
  'ffun:10:7500:58': 83.0,
  'mem:10:50000:116': 1444.0,
  'mem_40:15:5000:40': 4.0,
  'mem_40:10:30000:96': 865.0,
  'mem_7:10:40000:88': 350.0,
  'mem:15:40000:59': 97.0,
  'ffun_add:10:15000:54': 116.0,
  'ffun:15:5000:66': 62.0,
  'ffun:15:5000:111': 271.0,
  'ffun:15:7500:38': 39.0,
  'ffun:10:50000:80': 789.0,
  'ffun_add:15:20000:23': 19.0,
  'adb:10:2000:115': 29.0,
  'ffun:10:30000:107': 1940.0,
  'adb:15:25000:23': 16.0,
  'adb:10:15000:10': 12.0,
  'dis:10:10000:27': 11.0,
  'mem_7:15:50000:28': 10.0,
  'mem:10:40000:63': 130.0,
  'ffun_add:10:50000:72': 739.0,
  'ffun:10:2000:114': 173.0,
  'mem_7:10:7500:97': 131.0,
  'ffun_add:10:2000:28': 9.0,
  'mem_40:15:40000:103': 1154.0,
  'mem_7:10:10000:79': 49.0,
  'adb:15:25000:15': 14.0,
  'ffun_add:10:30000:17': 28.0,
  'ffun:15:40000:86': 968.0,
  'ffun:15:50000:23': 53.0,
  'adb:15:50000:83': 160.0,
  'adb:15:10000:85': 32.0,
  'ffun:10:25000:98': 1626.0,
  'mem_7:15:10000:2': 1.0,
  'ffun:10:40000:8': 48.0,
  'dis:10:10000:36': 11.0,
  'ffun_add:10:5000:18': 10.0,
  'mem_7:15:10000:39': 4.0,
  'ffun_add:10:40000:14': 34.0,
  'hcb:10:50:41': 4.0,
  'ffun_add:15:40000:26': 37.0,
  'mem_40:10:20000:79': 164.0,
  'ffun_add:10:15000:76': 299.0,
  'mem_40:10:7500:72': 47.0,
  'ffun:15:15000:38': 47.0,
  'ffun:15:15000:116': 752.0,
  'adb:10:15000:101': 212.0,
  'mem_7:15:5000:4': 1.0,
  'mem:15:40000:62': 107.0,
  'mem:15:5000:35': 2.0,
  'mem:15:40000:31': 17.0,
  'adb:10:15000:39': 18.0,
  'adb:10:30000:108': 424.0,
  'mem_7:15:30000:99': 520.0,
  'ffun:15:50000:119': 2435.0,
  'ffun_add:10:2000:8': 8.0,
  'ffun_add:15:25000:23': 23.0,
  'mem:15:40000:8': 8.0,
  'ffun_add:10:50000:58': 378.0,
  'adb:15:7500:49': 12.0,
  'mem_7:10:30000:31': 10.0,
  'mem_40:10:20000:45': 25.0,
  'adb:10:20000:50': 40.0,
  'ffun_add:10:10000:82': 277.0,
  'ffun:15:50000:57': 239.0,
  'mem_7:10:2000:87': 18.0,
  'ffun_add:10:30000:44': 101.0,
  'mem:15:2000:111': 59.0,
  'ffun:10:10000:12': 36.0,
  'mem_7:15:30000:42': 17.0,
  'mem_40:10:30000:86': 437.0,
  'mem_7:15:7500:26': 2.0,
  'mem:10:5000:42': 7.0,
  'ffun_add:15:5000:93': 230.0,
  'adb:15:2000:62': 1.0,
  'ffun:15:20000:22': 39.0,
  'adb:10:7500:56': 14.0,
  'dis:15:50000:59': 18.0,
  'mem:15:40000:3': 8.0,
  'adb:15:25000:92': 186.0,
  'mem_40:10:50000:66': 230.0,
  'adb:15:40000:47': 62.0,
  'mem_40:15:30000:24': 7.0,
  'hcb:10:50:48': 4.0,
  'mem_40:10:25000:113': 722.0,
  'ffun_add:15:50000:60': 300.0,
  'ffun:15:50000:75': 475.0,
  'ffun:15:40000:84': 713.0,
  'mem:15:2000:116': 59.0,
  'mem_7:10:2000:12': 1.0,
  'ffun:10:10000:28': 38.0,
  'mem_7:15:30000:64': 48.0,
  'mem_40:10:40000:119': 1154.0,
  'mem_40:15:7500:68': 30.0,
  'ffun_add:15:40000:68': 366.0,
  'mem_40:15:25000:103': 722.0,
  'ffun:15:40000:35': 60.0,
  'mem:10:15000:34': 8.0,
  'adb:15:7500:19': 5.0,
  'mem:10:40000:51': 112.0,
  'mem_40:10:5000:3': 2.0,
  'ffun_add:15:10000:63': 69.0,
  'adb:10:2000:9': 2.0,
  'mem_40:10:7500:52': 22.0,
  'ffun_add:10:40000:8': 34.0,
  'dis:10:10000:59': 17.0,
  'dis:15:30000:25': 11.0,
  'ffun:15:5000:14': 32.0,
  'mem_40:10:15000:61': 49.0,
  'mem:15:15000:105': 433.0,
  'mem_40:10:40000:55': 112.0,
  'adb:10:5000:24': 5.0,
  'adb:15:5000:105': 71.0,
  'adb:10:2000:34': 2.0,
  'dis:10:30000:48': 13.0,
  'adb:15:15000:77': 32.0,
  'mem:15:7500:82': 78.0,
  'adb:10:2000:57': 5.0,
  'hcb:10:150:49': 33.0,
  'ffun_add:10:40000:116': 2824.0,
  'adb:15:2000:89': 11.0,
  'ffun_add:15:7500:13': 10.0,
  'mem:10:7500:53': 22.0,
  'adb:15:25000:17': 14.0,
  'adb:10:30000:114': 424.0,
  'ffun_add:10:7500:88': 276.0,
  'hcb:15:80:51': 8.0,
  'mem_7:15:2000:33': 1.0,
  'mem_40:10:15000:116': 433.0,
  'ffun:15:50000:21': 53.0,
  'adb:10:50000:113': 706.0,
  'mem:15:30000:42': 29.0,
  'mem_40:15:30000:77': 236.0,
  'adb:10:10000:47': 20.0,
  'ffun:10:10000:74': 153.0,
  'mem_40:15:15000:46': 23.0,
  'ffun:15:30000:5': 42.0,
  'mem_7:15:15000:48': 13.0,
  'mem:15:20000:36': 12.0,
  'mem:15:20000:56': 49.0,
  'adb:10:2000:7': 2.0,
  'adb:15:30000:94': 223.0,
  'ffun_add:15:5000:110': 343.0,
  'mem_40:15:25000:108': 722.0,
  'ffun:10:25000:63': 187.0,
  'mem:10:25000:106': 722.0,
  'ffun_add:15:40000:4': 28.0,
  'ffun_add:10:10000:98': 713.0,
  'ffun_add:10:7500:22': 13.0,
  'mem_40:15:2000:41': 2.0,
  'mem_7:10:7500:90': 66.0,
  'mem_7:10:40000:116': 692.0,
  'ffun_add:10:5000:89': 188.0,
  'mem:15:5000:84': 52.0,
  'hcb:15:120:23': 17.0,
  'ffun_add:15:2000:76': 41.0,
  'adb:10:50000:93': 376.0,
  'ffun:15:20000:80': 275.0,
  'mem_7:10:20000:111': 347.0,
  'mem:15:10000:78': 74.0,
  'mem:15:15000:97': 433.0,
  'ffun_add:15:25000:107': 1690.0,
  'ffun_add:10:10000:107': 713.0,
  'mem_40:10:10000:108': 289.0,
  'mem:10:50000:60': 150.0,
  'ffun:15:30000:92': 985.0,
  'ffun:15:25000:73': 252.0,
  'ffun_add:15:25000:101': 1690.0,
  'mem_40:10:7500:16': 2.0,
  'mem:15:7500:78': 55.0,
  'mem:10:25000:103': 722.0,
  'mem_40:10:10000:113': 289.0,
  'mem_40:10:15000:81': 166.0,
  'mem_7:10:10000:41': 8.0,
  'adb:15:50000:18': 29.0,
  'mem_7:10:40000:73': 149.0,
  'ffun:15:7500:66': 78.0,
  'mem:10:25000:53': 70.0,
  'mem:10:15000:48': 29.0,
  'mem:10:10000:107': 289.0,
  'ffun_add:10:25000:9': 23.0,
  'mem:15:5000:54': 12.0,
  'ffun:15:50000:54': 229.0,
  'mem_7:15:5000:33': 1.0,
  'mem_7:10:5000:71': 19.0,
  'mem:15:10000:28': 4.0,
  'mem_7:10:40000:114': 692.0,
  'adb:15:25000:25': 16.0,
  'ffun:15:10000:54': 70.0,
  'ffun:10:2000:0': 33.0,
  'mem_7:10:25000:3': 5.0,
  'adb:10:2000:43': 4.0,
  'mem_7:10:5000:7': 1.0,
  'mem_7:15:5000:22': 1.0,
  'ffun:10:25000:119': 1626.0,
  'ffun_add:10:30000:19': 28.0,
  'ffun_add:10:5000:27': 12.0,
  'ffun:10:10000:4': 36.0,
  'mem_7:10:50000:6': 8.0,
  'ffun:15:40000:112': 1953.0,
  'ffun:10:50000:7': 53.0,
  'ffun_add:10:50000:74': 739.0,
  'mem_7:15:7500:5': 1.0,
  'adb:10:30000:40': 35.0,
  'mem_40:10:30000:44': 37.0,
  'adb:15:2000:28': 2.0,
  'mem:15:7500:113': 217.0,
  'mem_40:10:20000:5': 6.0,
  'adb:10:50000:11': 38.0,
  'adb:15:5000:6': 4.0,
  'mem_40:15:50000:37': 30.0,
  'ffun_add:10:7500:78': 154.0,
  'ffun:10:2000:19': 33.0,
  'mem_40:10:25000:62': 82.0,
  'mem_40:15:2000:110': 59.0,
  'mem_7:10:25000:29': 6.0,
  'mem:15:5000:6': 1.0,
  'mem_7:15:10000:116': 174.0,
  'adb:10:2000:47': 5.0,
  'ffun:15:25000:101': 1232.0,
  'mem_7:15:30000:71': 104.0,
  'mem_7:10:15000:56': 28.0,
  'mem_40:15:20000:69': 78.0,
  'mem_7:15:10000:74': 36.0,
  'mem:10:30000:68': 138.0,
  'mem:10:20000:92': 386.0,
  'mem:15:50000:75': 268.0,
  'ffun:10:30000:112': 1940.0,
  'mem:15:10000:113': 289.0,
  'mem_7:15:25000:64': 41.0,
  'mem_40:10:5000:62': 17.0,
  'mem_40:15:5000:117': 145.0,
  'mem_40:10:10000:69': 47.0,
  'ffun_add:10:25000:112': 1769.0,
  'mem:15:20000:12': 5.0,
  'ffun_add:10:40000:38': 85.0,
  'ffun_add:15:25000:99': 1690.0,
  'mem_40:15:7500:16': 2.0,
  'ffun_add:10:50000:62': 390.0,
  'mem_40:10:20000:110': 577.0,
  'mem_40:10:40000:54': 112.0,
  'ffun_add:15:10000:15': 12.0,
  'mem:10:40000:77': 328.0,
  'mem_7:15:30000:120': 520.0,
  'mem:10:20000:32': 11.0,
  'mem_40:15:50000:94': 956.0,
  'ffun:15:5000:25': 33.0,
  'ffun_add:10:15000:89': 542.0,
  'ffun:15:10000:34': 38.0,
  'mem_40:10:2000:104': 59.0,
  'adb:10:5000:90': 25.0,
  'adb:10:20000:16': 16.0,
  'mem_7:10:10000:89': 88.0,
  'mem_7:15:20000:80': 95.0,
  'adb:10:5000:91': 38.0,
  'mem:10:40000:13': 11.0,
  'mem:15:2000:112': 59.0,
  'adb:15:50000:3': 29.0,
  'ffun_add:15:25000:42': 68.0,
  'mem_7:15:25000:74': 88.0,
  'mem:10:50000:29': 19.0,
  'mem_7:10:2000:64': 5.0,
  'mem:15:30000:73': 161.0,
  'mem_40:15:30000:58': 72.0,
  'mem_7:15:15000:82': 92.0,
  'mem_40:10:2000:78': 17.0,
  'ffun:10:25000:27': 47.0,
  'ffun:10:50000:76': 789.0,
  'ffun_add:10:7500:34': 17.0,
  'hcb:10:50:39': 4.0,
  'mem_40:15:30000:102': 865.0,
  'mem_40:15:15000:7': 4.0,
  'mem:10:25000:11': 7.0,
  'adb:15:40000:24': 24.0,
  'ffun_add:15:10000:40': 21.0,
  'mem_7:10:20000:48': 23.0,
  'mem_40:10:5000:27': 2.0,
  'mem:10:10000:27': 5.0,
  'adb:10:10000:12': 8.0,
  'mem_40:15:30000:20': 7.0,
  'mem_7:15:5000:80': 24.0,
  'mem_40:15:50000:42': 47.0,
  'adb:15:7500:26': 5.0,
  'adb:15:15000:72': 17.0,
  'ffun:15:30000:46': 110.0,
  'adb:15:20000:58': 30.0,
  'mem_40:10:30000:41': 37.0,
  'mem_7:10:25000:97': 433.0,
  'ffun_add:10:50000:66': 552.0,
  'mem_40:10:2000:9': 1.0,
  'ffun_add:15:10000:66': 97.0,
  'mem_7:15:5000:119': 88.0,
  'mem_40:15:15000:58': 37.0,
  'mem:15:20000:108': 577.0,
  'ffun_add:15:25000:74': 318.0,
  'mem_40:15:2000:50': 4.0,
  'mem_7:15:2000:35': 1.0,
  'mem:10:7500:10': 2.0,
  'mem_7:15:2000:47': 2.0,
  'ffun_add:10:30000:10': 27.0,
  'mem_40:10:7500:15': 2.0,
  'ffun:10:5000:110': 363.0,
  'mem:15:2000:74': 11.0,
  'mem:15:25000:79': 184.0,
  'ffun:10:40000:93': 1734.0,
  'mem_7:15:15000:21': 2.0,
  'mem:15:5000:114': 145.0,
  'adb:10:30000:78': 73.0,
  'mem_40:10:15000:73': 94.0,
  'mem_40:10:5000:38': 5.0,
  'mem_40:10:15000:100': 433.0,
  'hcb:10:150:50': 33.0,
  'dis:15:20000:43': 10.0,
  'adb:10:50000:6': 38.0,
  'adb:15:15000:21': 10.0,
  'mem:15:20000:117': 577.0,
  'ffun:15:7500:92': 269.0,
  'dis:15:30000:38': 11.0,
  'dis:15:40000:42': 12.0,
  'hcb:15:80:57': 7.0,
  'ffun_add:15:20000:86': 663.0,
  'ffun:10:25000:78': 415.0,
  'dis:15:50000:26': 12.0,
  'mem:10:5000:36': 5.0,
  'ffun:10:40000:73': 492.0,
  'mem_7:15:15000:33': 4.0,
  'mem_7:15:40000:73': 140.0,
  'mem:15:25000:65': 67.0,
  'mem:10:15000:68': 70.0,
  'adb:10:20000:109': 282.0,
  'hcb:15:120:29': 17.0,
  'ffun_add:15:7500:117': 511.0,
  'ffun_add:15:2000:83': 55.0,
  'ffun:15:10000:114': 511.0,
  'mem:10:2000:93': 40.0,
  'mem_7:10:50000:74': 186.0,
  'ffun:10:7500:33': 38.0,
  'ffun:15:30000:95': 985.0,
  'mem_7:10:5000:29': 1.0,
  'hcb:15:50:52': 3.0,
  'adb:15:25000:79': 54.0,
  'adb:10:30000:75': 41.0,
  'ffun:15:10000:64': 75.0,
  'adb:15:20000:111': 282.0,
  'ffun:10:5000:14': 33.0,
  'mem:15:40000:20': 10.0,
  'ffun:15:5000:30': 33.0,
  'mem:10:20000:33': 11.0,
  'hcb:10:150:37': 32.0,
  'mem:10:20000:30': 8.0,
  'mem:10:30000:17': 8.0,
  'mem:10:10000:22': 4.0,
  'ffun:15:2000:103': 126.0,
  'mem_40:10:5000:85': 55.0,
  'ffun:15:7500:112': 391.0,
  'mem:10:5000:45': 7.0,
  'mem:10:50000:32': 26.0,
  'ffun_add:10:25000:114': 1769.0,
  'mem_40:15:25000:118': 722.0,
  'ffun_add:15:20000:57': 124.0,
  'mem_40:15:7500:48': 12.0,
  'adb:15:2000:96': 29.0,
  'mem_7:15:40000:100': 692.0,
  'mem_7:10:20000:106': 347.0,
  'mem_7:10:5000:4': 1.0,
  'mem_40:15:40000:12': 8.0,
  'mem:15:7500:29': 2.0,
  'mem_40:10:25000:49': 47.0,
  'ffun_add:10:25000:75': 374.0,
  'adb:15:2000:59': 4.0,
  'mem_7:15:25000:37': 10.0,
  'ffun_add:10:40000:2': 34.0,
  'ffun:10:30000:58': 211.0,
  'mem_7:15:20000:99': 347.0,
  'hcb:10:80:26': 9.0,
  'adb:10:50000:13': 38.0,
  'ffun:15:2000:97': 126.0,
  'ffun:15:7500:86': 206.0,
  'mem_40:15:40000:59': 97.0,
  'mem_40:10:2000:80': 17.0,
  'ffun_add:10:5000:17': 10.0,
  'mem:10:5000:34': 4.0,
  'ffun_add:10:7500:26': 14.0,
  'ffun_add:15:25000:9': 20.0,
  'mem_40:15:40000:54': 88.0,
  'mem:15:5000:120': 145.0,
  'adb:10:10000:91': 76.0,
  'ffun_add:10:50000:99': 3527.0,
  'mem_7:10:20000:70': 55.0,
  'mem:15:20000:90': 282.0,
  'ffun:10:5000:64': 69.0,
  'mem_40:15:5000:60': 13.0,
  'mem_7:15:20000:4': 2.0,
  'mem:15:30000:51': 66.0,
  'adb:10:30000:91': 226.0,
  'ffun_add:15:20000:113': 1353.0,
  'mem_7:10:15000:11': 2.0,
  'ffun_add:10:30000:83': 811.0,
  'ffun:15:40000:38': 74.0,
  'mem:15:50000:22': 12.0,
  'ffun_add:10:7500:57': 65.0,
  'mem_40:15:15000:1': 4.0,
  'adb:15:10000:40': 10.0,
  'ffun:15:5000:90': 148.0,
  'mem_7:15:20000:55': 26.0,
  'mem_7:10:2000:82': 13.0,
  'ffun:15:40000:114': 1953.0,
  'ffun:15:7500:118': 391.0,
  'ffun:10:10000:62': 99.0,
  'mem_40:10:40000:48': 74.0,
  'mem:15:7500:58': 19.0,
  'mem_7:15:25000:23': 4.0,
  'mem_40:15:7500:26': 2.0,
  'adb:15:7500:14': 5.0,
  'adb:15:5000:92': 37.0,
  'mem_40:10:25000:27': 10.0,
  'hcb:10:50:49': 4.0,
  'mem_7:15:2000:50': 2.0,
  'ffun:15:2000:61': 39.0,
  'mem:10:7500:41': 10.0,
  'mem_40:15:2000:96': 59.0,
  'mem:10:10000:44': 13.0,
  'mem_40:10:5000:52': 14.0,
  'mem_7:10:5000:76': 25.0,
  'adb:10:20000:81': 70.0,
  'adb:15:5000:71': 6.0,
  'adb:10:15000:65': 10.0,
  'mem_40:10:20000:21': 7.0,
  'mem_40:10:2000:110': 59.0,
  'mem_40:15:20000:58': 49.0,
  'mem_7:10:40000:65': 78.0,
  'ffun:10:20000:48': 97.0,
  'adb:15:20000:14': 12.0,
  'mem_40:15:7500:13': 2.0,
  'ffun_add:15:5000:76': 93.0,
  'mem:10:5000:40': 5.0,
  'ffun:15:50000:86': 1203.0,
  'ffun:15:20000:47': 84.0,
  'adb:10:5000:59': 10.0,
  'mem:15:2000:104': 59.0,
  'hcb:10:120:43': 21.0,
  'adb:15:50000:11': 29.0,
  'mem_40:10:50000:6': 13.0,
  'mem_7:15:7500:65': 12.0,
  'adb:10:40000:89': 200.0,
  'ffun:10:50000:2': 53.0,
  'ffun_add:10:2000:7': 8.0,
  'mem_40:10:15000:3': 5.0,
  'dis:10:20000:50': 12.0,
  'ffun:10:7500:27': 36.0,
  'mem_7:10:30000:27': 7.0,
  'mem_7:15:50000:79': 236.0,
  'ffun_add:10:2000:83': 63.0,
  'ffun:15:50000:24': 53.0,
  'mem:15:25000:67': 97.0,
  'adb:10:25000:30': 20.0,
  'dis:10:40000:18': 13.0,
  'mem_40:15:7500:20': 2.0,
  'dis:15:30000:24': 11.0,
  'adb:10:20000:13': 16.0,
  'mem:15:5000:40': 4.0,
  'mem:10:50000:93': 964.0,
  'mem_7:10:5000:68': 14.0,
  'mem_7:10:2000:96': 35.0,
  'mem_7:15:2000:97': 35.0,
  'ffun_add:15:25000:41': 68.0,
  'mem_40:10:7500:29': 4.0,
  'mem_40:10:40000:52': 112.0,
  'mem:15:25000:16': 6.0,
  'ffun_add:10:7500:41': 30.0,
  'ffun:15:10000:32': 38.0,
  'mem:10:15000:99': 433.0,
  'ffun_add:10:50000:49': 243.0,
  'mem:15:5000:48': 8.0,
  'mem_40:15:50000:55': 110.0,
  'ffun_add:10:7500:14': 12.0,
  'hcb:15:150:58': 26.0,
  'mem_7:15:40000:17': 6.0,
  'ffun_add:10:2000:14': 8.0,
  'ffun_add:15:40000:38': 68.0,
  'mem:10:7500:17': 2.0,
  'ffun:15:25000:38': 58.0,
  'ffun:15:15000:66': 127.0,
  'mem_40:15:25000:61': 67.0,
  'mem_7:15:7500:63': 12.0,
  'ffun_add:10:30000:21': 31.0,
  'hcb:10:100:38': 14.0,
  'mem:10:2000:6': 1.0,
  'hcb:15:100:53': 12.0,
  'mem:10:30000:53': 84.0,
  'ffun_add:10:25000:52': 187.0,
  'mem_40:15:40000:56': 97.0,
  'ffun_add:15:25000:66': 231.0,
  'adb:10:7500:28': 7.0,
  'mem_40:10:2000:99': 59.0,
  'ffun_add:10:50000:102': 3527.0,
  'mem_7:10:25000:68': 70.0,
  'mem:10:15000:33': 8.0,
  'adb:10:30000:83': 103.0,
  'mem_7:10:50000:63': 97.0,
  'mem:15:40000:49': 59.0,
  'mem_40:10:50000:110': 1444.0,
  'ffun:15:40000:5': 46.0,
  'mem_40:10:7500:8': 2.0,
  'mem_7:15:7500:38': 4.0,
  'mem_40:10:40000:90': 583.0,
  'ffun:15:40000:98': 1953.0,
  'mem:15:30000:16': 7.0,
  'adb:15:20000:38': 18.0,
  'mem_7:10:25000:47': 29.0,
  'mem:10:2000:58': 6.0,
  'mem:10:50000:37': 38.0,
  'mem_40:10:25000:24': 8.0,
  'adb:10:50000:18': 38.0,
  'ffun:10:5000:97': 363.0,
  'mem:15:25000:38': 16.0,
  'mem_7:10:30000:20': 6.0,
  'mem_7:15:7500:3': 1.0,
  'adb:15:7500:24': 5.0,
  'ffun:10:25000:43': 87.0,
  'mem:10:30000:117': 865.0,
  'ffun_add:15:10000:3': 12.0,
  'ffun:15:15000:10': 36.0,
  'mem_7:10:2000:80': 11.0,
  'mem_40:15:30000:62': 80.0,
  'adb:10:40000:104': 564.0,
  'mem:10:30000:18': 8.0,
  'ffun_add:15:25000:43': 68.0,
  'adb:10:7500:40': 10.0,
  'mem_40:15:50000:35': 20.0,
  'mem_40:10:7500:10': 2.0,
  'mem:15:25000:112': 722.0,
  'mem_7:10:40000:62': 78.0,
  'mem_7:15:10000:7': 1.0,
  'ffun:15:10000:51': 70.0,
  'adb:10:20000:70': 13.0,
  'mem_40:10:20000:50': 37.0,
  'mem_40:15:2000:13': 1.0,
  'mem_40:10:7500:11': 2.0,
  'mem_40:15:25000:81': 257.0,
  'mem:10:25000:38': 19.0,
  'adb:15:2000:35': 2.0,
  'ffun_add:15:25000:30': 26.0,
  'ffun:10:50000:82': 1248.0,
  'mem_7:15:10000:36': 4.0,
  'mem:15:10000:102': 289.0,
  'ffun:10:50000:37': 96.0,
  'mem:15:40000:10': 8.0,
  'mem_40:15:20000:109': 577.0,
  'mem_7:15:20000:95': 230.0,
  'mem_7:15:15000:35': 4.0,
  'ffun_add:15:2000:5': 7.0,
  'ffun_add:15:15000:83': 366.0,
  'mem_40:10:15000:5': 5.0,
  'adb:15:15000:55': 34.0,
  'dis:10:10000:34': 11.0,
  'mem_40:10:50000:34': 26.0,
  'mem_7:15:25000:114': 433.0,
  'ffun:10:50000:119': 3205.0,
  'mem_40:10:5000:48': 10.0,
  'adb:15:40000:12': 23.0,
  'mem:10:10000:79': 83.0,
  'ffun_add:10:40000:24': 38.0,
  'adb:15:15000:50': 24.0,
  'ffun:15:2000:76': 55.0,
  'hcb:15:80:41': 8.0,
  'ffun:10:5000:71': 96.0,
  'ffun:10:30000:27': 50.0,
  'ffun:10:15000:84': 407.0,
  'adb:10:5000:57': 10.0,
  'ffun_add:15:40000:9': 28.0,
  'mem_7:10:10000:77': 49.0,
  'mem_7:15:40000:107': 692.0,
  'mem_7:15:10000:48': 10.0,
  'mem_7:15:10000:109': 174.0,
  'adb:15:5000:93': 37.0,
  'dis:10:20000:59': 18.0,
  'mem_40:15:40000:120': 1154.0,
  'mem:15:50000:61': 133.0,
  'adb:15:20000:55': 44.0,
  'ffun:15:25000:93': 826.0,
  'mem:15:2000:9': 1.0,
  'mem:15:15000:52': 34.0,
  'mem_40:15:7500:12': 2.0,
  'ffun:15:30000:32': 52.0,
  'mem_40:10:7500:114': 217.0,
  'ffun_add:15:10000:36': 21.0,
  'mem:15:7500:11': 2.0,
  'adb:10:30000:16': 24.0,
  'adb:15:7500:78': 17.0,
  'mem_40:10:30000:16': 8.0,
  'ffun:10:40000:16': 49.0,
  'mem_40:15:30000:97': 865.0,
  'ffun_add:15:15000:41': 44.0,
  'mem_40:10:30000:58': 90.0,
  'mem:15:30000:2': 6.0,
  'ffun:10:15000:67': 165.0,
  'adb:15:20000:35': 16.0,
  'adb:10:5000:96': 71.0,
  'mem_7:15:20000:42': 12.0,
  'mem_40:10:20000:44': 25.0,
  'hcb:10:150:39': 32.0,
  'adb:10:15000:85': 52.0,
  'mem_40:15:50000:10': 11.0,
  'mem:15:30000:18': 7.0,
  'adb:15:7500:38': 7.0,
  'adb:15:20000:119': 282.0,
  'dis:15:10000:21': 9.0,
  'mem_40:10:2000:72': 13.0,
  'mem_7:15:40000:66': 94.0,
  'adb:10:40000:66': 25.0,
  'mem_40:10:50000:64': 162.0,
  'ffun:10:30000:2': 44.0,
  'ffun_add:10:15000:100': 1065.0,
  'adb:10:30000:109': 424.0,
  'hcb:15:150:39': 27.0,
  'ffun_add:15:50000:42': 128.0,
  'ffun_add:15:50000:119': 3373.0,
  'mem:10:15000:84': 166.0,
  'ffun_add:10:30000:39': 66.0,
  'mem_7:15:10000:27': 2.0,
  'mem_7:10:20000:38': 10.0,
  'mem_40:10:5000:18': 2.0,
  'mem_7:15:2000:0': 1.0,
  'mem_7:10:15000:109': 260.0,
  'ffun_add:15:25000:113': 1690.0,
  'mem:10:25000:7': 7.0,
  'mem:15:25000:86': 353.0,
  'mem_7:10:7500:114': 131.0,
  'ffun_add:10:10000:11': 13.0,
  'ffun:15:15000:20': 36.0,
  'mem_7:15:25000:19': 4.0,
  'adb:10:20000:65': 13.0,
  'mem:15:20000:15': 5.0,
  'ffun:10:50000:49': 191.0,
  'mem_7:10:5000:43': 5.0,
  'mem_7:10:40000:41': 30.0,
  'ffun:15:25000:14': 40.0,
  'adb:10:40000:81': 138.0,
  'mem_7:10:50000:96': 866.0,
  'mem_7:10:7500:9': 1.0,
  'ffun:10:20000:9': 40.0,
  'adb:15:20000:98': 282.0,
  'ffun_add:10:30000:74': 446.0,
  'mem_40:15:25000:24': 6.0,
  'ffun_add:10:40000:58': 304.0,
  'adb:15:50000:39': 44.0,
  'mem_40:10:25000:85': 275.0,
  'mem_40:15:5000:52': 12.0,
  'mem:10:5000:56': 16.0,
  'mem:10:5000:59': 16.0,
  'adb:10:7500:120': 107.0,
  'ffun_add:15:7500:95': 341.0,
  'mem_7:15:10000:101': 174.0,
  'mem:10:25000:118': 722.0,
  'adb:15:25000:2': 14.0,
  'mem:10:10000:81': 110.0,
  'mem:10:7500:48': 14.0,
  'adb:15:40000:92': 298.0,
  'mem_40:10:40000:88': 583.0,
  'ffun_add:10:30000:56': 229.0,
  'dis:10:50000:19': 14.0,
  'ffun:15:7500:78': 122.0,
  'mem_40:15:30000:39': 18.0,
  'mem:10:40000:84': 439.0,
  'mem_40:10:40000:65': 130.0,
  'mem:10:5000:85': 55.0,
  'adb:10:10000:103': 142.0,
  'ffun:10:50000:105': 3205.0,
  'ffun_add:15:7500:108': 511.0,
  'ffun:10:7500:60': 83.0,
  'ffun_add:15:2000:109': 142.0,
  'ffun_add:15:15000:93': 675.0,
  'adb:15:5000:13': 4.0,
  'adb:15:7500:86': 37.0,
  'ffun:10:40000:7': 48.0,
  'ffun:15:2000:56': 39.0,
  'adb:10:5000:101': 71.0,
  'ffun_add:10:7500:69': 90.0,
  'mem_40:10:2000:111': 59.0,
  'mem:15:10000:97': 289.0,
  'ffun_add:15:40000:10': 28.0,
  'hcb:10:120:46': 21.0,
  'mem:10:30000:112': 865.0,
  'mem_40:10:50000:85': 550.0,
  'mem_40:10:25000:112': 722.0,
  'mem_40:15:30000:26': 10.0,
  'mem_7:15:10000:88': 85.0,
  'mem:10:7500:33': 5.0,
  'mem:10:2000:10': 1.0,
  'mem_7:15:7500:81': 47.0,
  'adb:15:2000:84': 7.0,
  'mem_40:15:40000:85': 410.0,
  'ffun_add:15:10000:11': 12.0,
  'mem_40:15:7500:33': 4.0,
  'adb:10:25000:56': 48.0,
  'mem:15:2000:83': 22.0,
  'ffun:15:40000:68': 287.0,
  'ffun_add:10:2000:54': 23.0,
  'mem_7:15:50000:94': 574.0,
  'ffun:10:15000:42': 66.0,
  'ffun_add:10:25000:20': 24.0,
  'ffun_add:15:10000:18': 13.0,
  'ffun:15:15000:5': 36.0,
  'ffun_add:15:15000:100': 1017.0,
  'mem:15:30000:41': 29.0,
  'ffun_add:15:20000:101': 1353.0,
  'mem:15:30000:38': 18.0,
  'adb:15:2000:111': 29.0,
  'ffun:10:50000:94': 2157.0,
  'mem:10:50000:120': 1444.0,
  'mem:10:20000:17': 6.0,
  'mem:10:20000:63': 65.0,
  'mem_40:10:25000:89': 365.0,
  'ffun:15:7500:16': 33.0,
  'ffun:15:10000:61': 75.0,
  'mem_7:15:50000:63': 80.0,
  'mem_7:10:25000:119': 433.0,
  'dis:10:20000:43': 12.0,
  'mem:15:15000:74': 80.0,
  'ffun:10:30000:6': 44.0,
  'ffun_add:10:10000:74': 154.0,
  'mem:15:20000:13': 5.0,
  'mem_7:10:7500:74': 29.0,
  'adb:15:25000:34': 19.0,
  'adb:15:5000:69': 2.0,
  'adb:15:20000:30': 13.0,
  'mem_40:10:25000:32': 13.0,
  'ffun:15:7500:40': 39.0,
  'mem_40:10:20000:39': 16.0,
  'mem_7:10:25000:40': 12.0,
  'mem_40:15:5000:26': 2.0,
  'adb:15:20000:95': 149.0,
  'mem_7:15:30000:38': 11.0,
  'mem_7:10:15000:96': 260.0,
  'mem_40:15:2000:98': 59.0,
  'ffun_add:10:50000:120': 3527.0,
  'ffun:15:20000:4': 38.0,
  'mem_40:15:25000:33': 11.0,
  'mem_40:10:2000:54': 6.0,
  'adb:10:20000:89': 101.0,
  'mem_40:15:25000:85': 257.0,
  'mem_7:10:50000:8': 8.0,
  'mem_40:10:20000:1': 6.0,
  'adb:10:20000:105': 282.0,
  'ffun_add:15:20000:99': 1353.0,
  'mem_7:15:5000:41': 4.0,
  'mem:10:15000:16': 5.0,
  'mem:10:7500:90': 109.0,
  'adb:10:40000:102': 564.0,
  'mem_40:10:30000:13': 8.0,
  'ffun:15:25000:52': 130.0,
  'ffun:10:40000:31': 66.0,
  'ffun:10:50000:28': 63.0,
  'ffun:15:50000:67': 352.0,
  'mem_7:15:10000:113': 174.0,
  'ffun:15:40000:16': 46.0,
  'ffun_add:10:10000:77': 202.0,
  'mem:15:20000:87': 282.0,
  'ffun:15:40000:43': 100.0,
  'adb:15:2000:19': 1.0,
  'adb:15:40000:99': 564.0,
  'mem_40:15:5000:63': 14.0,
  'mem:10:5000:109': 145.0,
  'mem_7:15:7500:107': 131.0,
  'adb:15:40000:34': 30.0,
  'mem_40:15:5000:109': 145.0,
  'adb:15:40000:19': 23.0,
  'mem_7:15:30000:106': 520.0,
  'ffun:10:25000:97': 1626.0,
  'ffun:15:7500:81': 158.0,
  'mem_40:15:7500:92': 144.0,
  'mem_7:10:50000:19': 8.0,
  'mem:10:25000:50': 47.0,
  'mem_7:15:30000:81': 185.0,
  'ffun_add:15:5000:104': 343.0,
  'adb:10:40000:88': 200.0,
  'ffun_add:10:25000:115': 1769.0,
  'mem_40:10:2000:107': 59.0,
  'ffun:15:50000:100': 2435.0,
  'ffun:15:30000:45': 82.0,
  'mem:10:7500:25': 2.0,
  'mem_7:15:50000:23': 7.0,
  'mem:15:2000:21': 1.0,
  'adb:15:25000:84': 80.0,
  'ffun:10:7500:7': 34.0,
  'mem:15:50000:3': 11.0,
  'adb:10:25000:64': 16.0,
  'mem_40:15:20000:13': 5.0,
  'ffun_add:15:15000:9': 14.0,
  'mem:10:50000:49': 92.0,
  'mem_7:15:30000:40': 11.0,
  'mem_7:10:40000:42': 30.0,
  'ffun:10:2000:107': 173.0,
  'ffun:10:50000:38': 96.0,
  'ffun_add:15:2000:58': 19.0,
  'adb:10:2000:91': 16.0,
  'ffun_add:15:20000:88': 663.0,
  'mem_7:15:40000:117': 692.0,
  'mem_7:15:10000:29': 2.0,
  'mem:15:30000:95': 574.0,
  'mem_7:10:30000:41': 23.0,
  'ffun:15:15000:85': 286.0,
  'ffun:10:7500:90': 286.0,
  'mem_7:15:20000:83': 124.0,
  'hcb:15:150:51': 28.0,
  'ffun_add:15:15000:31': 23.0,
  'mem:15:2000:73': 11.0,
  'mem_7:15:30000:79': 142.0,
  'ffun_add:15:20000:48': 82.0,
  'mem_40:10:40000:116': 1154.0,
  'mem_7:10:30000:73': 112.0,
  'ffun_add:15:10000:64': 69.0,
  'mem_40:10:40000:35': 20.0,
  'mem_40:15:25000:105': 722.0,
  'ffun_add:15:30000:29': 30.0,
  'ffun_add:10:40000:3': 34.0,
  'ffun:15:20000:95': 667.0,
  'mem_7:10:10000:26': 2.0,
  'ffun:15:40000:21': 48.0,
  'adb:10:10000:3': 8.0,
  'ffun:15:15000:30': 38.0,
  'ffun_add:15:25000:37': 45.0,
  'mem_7:10:10000:81': 66.0,
  'mem_40:10:2000:20': 1.0,
  'mem_40:10:15000:110': 433.0,
  'ffun:10:25000:84': 648.0,
  'ffun:10:20000:23': 41.0,
  'adb:15:40000:6': 23.0,
  'ffun:15:25000:117': 1232.0,
  'adb:10:40000:106': 564.0,
  'adb:15:10000:87': 49.0,
  'adb:15:40000:59': 60.0,
  'mem_40:15:2000:99': 59.0,
  'ffun:10:7500:13': 35.0,
  'mem_7:15:2000:24': 1.0,
  'adb:15:15000:105': 212.0,
  'mem_7:10:7500:87': 66.0,
  'adb:15:5000:36': 5.0,
  'mem_7:10:40000:115': 692.0,
  'mem_40:15:5000:19': 1.0,
  'ffun_add:10:20000:39': 46.0,
  'hcb:15:50:35': 3.0,
  'ffun_add:15:30000:106': 2025.0,
  'ffun:15:7500:44': 43.0,
  'mem:15:20000:99': 577.0,
  'mem_40:10:15000:62': 49.0,
  'mem_7:10:10000:33': 4.0,
  'mem:10:15000:41': 19.0,
  'ffun:15:25000:97': 1232.0,
  'ffun:10:15000:78': 264.0,
  'adb:15:50000:58': 74.0,
  'ffun_add:15:10000:97': 679.0,
  'adb:10:15000:88': 76.0,
  'mem_40:15:5000:68': 20.0,
  'adb:15:40000:117': 564.0,
  'adb:15:15000:80': 32.0,
  'ffun_add:10:15000:51': 116.0,
  'adb:10:5000:37': 6.0,
  'adb:10:20000:113': 282.0,
  'dis:10:30000:51': 15.0,
  'adb:15:50000:112': 706.0,
  'mem_40:10:30000:10': 8.0,
  'mem_7:10:10000:106': 174.0,
  'adb:10:20000:96': 282.0,
  'adb:10:30000:2': 23.0,
  'ffun:15:25000:61': 141.0,
  'ffun_add:10:10000:91': 480.0,
  'mem:15:2000:94': 38.0,
  'mem_40:10:25000:5': 7.0,
  'adb:10:5000:62': 4.0,
  'ffun:10:25000:104': 1626.0,
  'ffun:10:25000:99': 1626.0,
  'mem:15:10000:105': 289.0,
  'ffun_add:15:2000:108': 142.0,
  'ffun_add:15:40000:99': 2698.0,
  'dis:10:10000:30': 11.0,
  'mem_40:15:5000:115': 145.0,
  'mem:15:50000:111': 1444.0,
  'adb:15:40000:112': 564.0,
  'ffun_add:10:10000:41': 39.0,
  'mem:10:7500:103': 217.0,
  'mem_7:10:25000:116': 433.0,
  'ffun_add:10:15000:82': 411.0,
  'hcb:15:50:33': 3.0,
  'mem_40:10:10000:16': 4.0,
  'mem:10:50000:52': 139.0,
  'mem_7:10:20000:20': 4.0,
  'mem_7:15:50000:38': 18.0,
  'ffun:15:20000:28': 41.0,
  'adb:15:2000:53': 5.0,
  'ffun_add:10:40000:0': 34.0,
  'ffun_add:15:20000:44': 55.0,
  'mem_7:10:30000:46': 34.0,
  'mem_40:15:50000:52': 110.0,
  'mem:15:10000:36': 6.0,
  'mem_7:15:30000:62': 48.0,
  'ffun_add:15:15000:49': 62.0,
  'mem_7:15:50000:2': 6.0,
  'adb:15:40000:45': 46.0,
  'adb:15:10000:62': 5.0,
  'ffun:15:5000:91': 189.0,
  'mem_7:10:5000:109': 88.0,
  'ffun_add:15:30000:20': 24.0,
  'mem_7:10:50000:65': 97.0,
  'ffun_add:15:30000:40': 52.0,
  'mem_7:10:30000:40': 14.0,
  'mem:10:2000:50': 5.0,
  'adb:15:2000:85': 7.0,
  'ffun:10:25000:58': 183.0,
  'ffun_add:10:5000:95': 245.0,
  'mem_7:15:25000:36': 10.0,
  'ffun:10:50000:20': 55.0,
  'mem:10:2000:1': 1.0,
  'mem_7:10:2000:9': 1.0,
  'mem_40:15:10000:30': 4.0,
  'mem:10:15000:109': 433.0,
  'hcb:15:120:33': 17.0,
  'ffun_add:10:10000:76': 202.0,
  'ffun:10:20000:93': 891.0,
  'adb:10:30000:23': 24.0,
  'mem_40:15:40000:6': 8.0,
  'mem:15:30000:0': 6.0,
  'mem:10:5000:4': 2.0,
  'ffun:15:5000:86': 148.0,
  'ffun_add:10:15000:71': 228.0,
  'mem_40:15:25000:102': 722.0,
  'ffun:15:5000:17': 32.0,
  'adb:15:30000:24': 18.0,
  'ffun_add:10:20000:97': 1416.0,
  'ffun:10:25000:0': 42.0,
  'mem:10:20000:117': 577.0,
  'ffun_add:10:25000:65': 199.0,
  'mem:10:25000:115': 722.0,
  'adb:15:15000:56': 23.0,
  'mem:10:10000:7': 4.0,
  'adb:15:25000:39': 23.0,
  'mem:15:15000:6': 4.0,
  'hcb:15:50:34': 3.0,
  'mem_7:10:10000:120': 174.0,
  'ffun:15:2000:4': 31.0,
  'ffun_add:10:40000:81': 1080.0,
  'mem_7:15:7500:91': 86.0,
  'mem_7:15:2000:15': 1.0,
  'mem_40:15:10000:50': 16.0,
  'adb:10:5000:68': 4.0,
  'mem:15:20000:39': 12.0,
  'mem:10:50000:117': 1444.0,
  'mem_40:15:15000:17': 4.0,
  'mem_7:15:25000:1': 4.0,
  'ffun_add:10:20000:102': 1416.0,
  'mem:10:30000:85': 329.0,
  'ffun:15:10000:1': 34.0,
  'mem:10:7500:82': 83.0,
  'mem_7:10:15000:14': 2.0,
  'ffun:15:50000:45': 117.0,
  'ffun:10:30000:16': 45.0,
  'mem_40:10:50000:14': 13.0,
  'mem:10:5000:38': 5.0,
  'mem_40:10:5000:20': 2.0,
  'ffun:10:25000:41': 87.0,
  'mem_7:10:50000:28': 12.0,
  'ffun:15:50000:101': 2435.0,
  'mem:10:40000:66': 184.0,
  'mem_7:10:50000:92': 578.0,
  'adb:15:30000:54': 66.0,
  'ffun:10:7500:82': 227.0,
  'mem_40:10:15000:29': 6.0,
  'mem:15:15000:103': 433.0,
  'mem:15:7500:47': 12.0,
  'ffun_add:15:7500:84': 186.0,
  'mem:15:30000:43': 29.0,
  'adb:10:25000:110': 353.0,
  'adb:15:40000:93': 298.0,
  'hcb:10:50:38': 4.0,
  'mem_7:15:50000:55': 66.0,
  'ffun:10:7500:111': 522.0,
  'adb:15:7500:36': 7.0,
  'ffun_add:15:25000:86': 828.0,
  'ffun_add:15:40000:98': 2698.0,
  'mem_40:10:5000:100': 145.0,
  'mem_7:15:50000:95': 574.0,
  'mem:15:10000:55': 23.0,
  'ffun:15:30000:21': 44.0,
  'ffun:10:5000:58': 68.0,
  'mem:15:25000:66': 97.0,
  'mem_40:15:2000:54': 5.0,
  'adb:15:2000:77': 5.0,
  'adb:10:50000:15': 38.0,
  'ffun:15:30000:57': 156.0,
  'mem_7:10:15000:6': 2.0,
  'ffun_add:10:15000:104': 1065.0,
  'ffun:10:40000:83': 1008.0,
  'ffun_add:10:50000:94': 2359.0,
  'mem:10:2000:102': 59.0,
  'ffun:15:50000:2': 49.0,
  'mem:10:2000:90': 30.0,
  'mem_7:15:30000:52': 40.0,
  'ffun:15:15000:110': 752.0,
  'dis:15:30000:32': 11.0,
  'adb:15:30000:5': 18.0,
  'adb:15:20000:99': 282.0,
  'mem:15:20000:2': 5.0,
  'ffun_add:10:2000:18': 9.0,
  'mem:15:30000:60': 72.0,
  'mem_40:15:10000:56': 25.0,
  'ffun_add:10:7500:7': 12.0,
  'mem:10:25000:6': 7.0,
  'ffun:15:20000:24': 39.0,
  'ffun:15:40000:50': 137.0,
  'ffun:15:10000:113': 511.0,
  'mem_40:15:25000:20': 6.0,
  'hcb:10:150:55': 33.0,
  'mem_7:15:7500:46': 7.0,
  'mem_7:15:20000:85': 124.0,
  'hcb:15:150:35': 27.0,
  'ffun_add:15:5000:55': 34.0,
  'mem_40:10:25000:55': 70.0,
  'mem:15:50000:6': 11.0,
  'ffun_add:15:40000:7': 28.0,
  'mem:10:2000:3': 1.0,
  'mem:15:40000:50': 59.0,
  'mem_7:10:50000:24': 10.0,
  'adb:15:20000:39': 18.0,
  'mem_40:15:15000:90': 211.0,
  'mem_40:15:40000:115': 1154.0,
  'ffun_add:15:50000:44': 128.0,
  'adb:15:10000:82': 32.0,
  'adb:15:25000:57': 37.0,
  'mem_7:15:10000:51': 13.0,
  'mem:10:10000:70': 47.0,
  'mem_40:10:20000:59': 60.0,
  'adb:15:7500:76': 17.0,
  'ffun:15:50000:81': 884.0,
  'ffun:10:25000:117': 1626.0,
  'mem_40:15:2000:61': 6.0,
  'mem_7:10:5000:48': 6.0,
  'adb:10:2000:107': 29.0,
  'mem_40:15:15000:5': 4.0,
  'mem_40:10:15000:117': 433.0,
  'mem_40:10:30000:75': 186.0,
  'mem_40:10:20000:91': 386.0,
  'mem_40:15:25000:50': 37.0,
  'adb:15:7500:3': 5.0,
  'mem_7:10:5000:37': 2.0,
  'mem_7:15:7500:115': 131.0,
  'adb:10:30000:115': 424.0,
  'mem:15:25000:87': 353.0,
  'mem:15:30000:102': 865.0,
  'hcb:15:150:27': 26.0,
  'mem:15:7500:48': 12.0,
  'mem_40:10:10000:98': 289.0,
  'ffun:10:7500:96': 522.0,
  'ffun:15:50000:66': 352.0,
  'ffun:15:20000:84': 372.0,
  'adb:10:40000:96': 564.0,
  'mem:10:7500:76': 62.0,
  'mem_40:10:7500:62': 25.0,
  'ffun_add:10:2000:1': 8.0,
  'adb:15:2000:31': 2.0,
  'mem_40:10:10000:7': 4.0,
  'adb:10:50000:35': 47.0,
  'ffun_add:15:40000:69': 366.0,
  'mem_7:10:40000:75': 149.0,
  'ffun:15:20000:43': 65.0,
  'adb:10:50000:14': 38.0,
  'ffun_add:10:10000:16': 14.0,
  'mem_40:15:2000:66': 8.0,
  'ffun:15:2000:99': 126.0,
  'mem_40:15:40000:79': 314.0,
  'mem_7:15:40000:21': 6.0,
  'ffun_add:15:15000:57': 94.0,
  'adb:10:30000:93': 226.0,
  'ffun_add:15:50000:43': 128.0,
  'mem_7:15:2000:59': 4.0,
  'adb:15:2000:115': 29.0,
  'ffun_add:15:40000:30': 37.0,
  'ffun_add:10:25000:6': 23.0,
  'mem:15:30000:86': 422.0,
  'ffun:15:5000:43': 39.0,
  'ffun:15:50000:114': 2435.0,
  'ffun_add:15:40000:44': 104.0,
  'ffun:10:5000:26': 35.0,
  'ffun:10:7500:62': 84.0,
  'mem:10:40000:53': 112.0,
  'mem_40:10:50000:46': 92.0,
  'mem_7:15:25000:30': 5.0,
  'mem:10:50000:23': 16.0,
  'mem_40:10:25000:103': 722.0,
  'mem_7:15:25000:105': 433.0,
  'mem_7:15:15000:103': 260.0,
  'mem_40:10:2000:8': 1.0,
  'adb:15:30000:58': 46.0,
  'mem_7:10:40000:11': 7.0,
  'ffun:15:50000:107': 2435.0,
  'adb:10:20000:51': 55.0,
  'mem:10:2000:22': 1.0,
  'mem_7:10:40000:68': 110.0,
  'mem_40:15:20000:5': 5.0,
  'ffun:10:2000:8': 33.0,
  'mem_7:15:2000:76': 10.0,
  'mem_7:15:2000:17': 1.0,
  'mem_40:15:7500:108': 217.0,
  'dis:15:10000:29': 9.0,
  'mem:15:30000:83': 307.0,
  'mem_40:15:40000:3': 8.0,
  'dis:15:30000:43': 11.0,
  'adb:10:20000:41': 30.0,
  'ffun:15:7500:80': 122.0,
  'mem_40:10:5000:41': 7.0,
  'ffun_add:15:7500:6': 10.0,
  'mem:15:40000:18': 10.0,
  'ffun:15:30000:73': 297.0,
  'mem:10:25000:81': 275.0,
  'ffun_add:10:15000:10': 17.0,
  'adb:15:40000:32': 30.0,
  'adb:15:40000:80': 86.0,
  'mem:10:5000:27': 2.0,
  'mem:10:2000:0': 1.0,
  'mem:10:10000:31': 6.0,
  'ffun:15:7500:17': 33.0,
  'ffun_add:10:5000:33': 13.0,
  'ffun:15:10000:44': 48.0,
  'adb:15:5000:120': 71.0,
  'mem_7:15:25000:5': 4.0,
  'adb:10:15000:90': 76.0,
  'mem:15:30000:30': 10.0,
  'adb:10:7500:44': 12.0,
  'hcb:10:120:45': 21.0,
  'adb:10:40000:74': 54.0,
  'ffun:10:10000:76': 189.0,
  'ffun:15:30000:86': 733.0,
  'adb:10:10000:76': 25.0,
  'mem:15:30000:84': 307.0,
  'mem_40:15:25000:65': 67.0,
  'ffun:10:25000:45': 87.0,
  'mem_40:15:40000:20': 10.0,
  'mem_7:10:40000:72': 149.0,
  'ffun_add:10:20000:45': 69.0,
  'mem:15:40000:61': 107.0,
  'mem_40:15:25000:119': 722.0,
  'mem_7:15:40000:36': 14.0,
  'mem:10:7500:20': 2.0,
  'mem_40:15:50000:45': 47.0,
  'dis:15:20000:54': 11.0,
  'ffun:15:40000:56': 197.0,
  'mem_7:15:20000:66': 47.0,
  'ffun:10:40000:60': 268.0,
  'mem_40:10:20000:62': 65.0,
  'ffun_add:10:25000:96': 1769.0,
  'adb:15:50000:2': 29.0,
  'mem:15:5000:47': 8.0,
  'ffun_add:15:30000:111': 2025.0,
  'mem_7:10:5000:92': 59.0,
  'adb:15:50000:38': 44.0,
  'mem_40:10:30000:76': 246.0,
  'ffun_add:10:50000:53': 366.0,
  'ffun:10:15000:54': 122.0,
  'adb:15:5000:119': 71.0,
  'mem:15:50000:12': 11.0,
  'ffun:10:20000:49': 97.0,
  'mem:15:30000:24': 7.0,
  'mem_40:15:25000:116': 722.0,
  'adb:15:50000:87': 241.0,
  'mem_7:10:10000:46': 12.0,
  'adb:15:40000:42': 46.0,
  'mem_40:10:50000:89': 728.0,
  'mem_7:10:5000:58': 10.0,
  'hcb:15:50:23': 3.0,
  'ffun_add:10:25000:95': 1184.0,
  'ffun:15:50000:99': 2435.0,
  'ffun_add:10:2000:24': 9.0,
  'mem_7:15:20000:118': 347.0,
  'mem_7:10:20000:54': 34.0,
  'mem_40:10:15000:8': 5.0,
  'adb:10:30000:18': 24.0,
  'mem_40:15:20000:118': 577.0,
  'ffun_add:10:25000:56': 194.0,
  'mem_40:15:5000:88': 71.0,
  'adb:15:7500:32': 6.0,
  'adb:15:2000:120': 29.0,
  'mem_7:15:40000:65': 65.0,
  'ffun_add:10:20000:78': 395.0,
  'mem:10:10000:0': 4.0,
  'adb:10:15000:42': 23.0,
  'ffun:10:7500:35': 38.0,
  'mem_40:15:15000:83': 155.0,
  'adb:15:50000:92': 372.0,
  'mem_7:10:50000:91': 578.0,
  'ffun_add:10:5000:84': 144.0,
  'ffun_add:10:50000:55': 366.0,
  'adb:15:50000:91': 372.0,
  'adb:15:30000:51': 66.0,
  'ffun:15:25000:21': 41.0,
  'mem_7:10:30000:57': 54.0,
  'hcb:10:100:59': 14.0,
  'mem:15:40000:52': 88.0,
  'mem:15:7500:42': 7.0,
  'mem_40:10:7500:63': 25.0,
  'mem_7:15:20000:96': 347.0,
  'adb:15:2000:47': 4.0,
  'ffun_add:15:2000:67': 24.0,
  'ffun:10:40000:105': 2574.0,
  'ffun:10:2000:85': 95.0,
  'dis:15:10000:23': 9.0,
  'mem_40:10:10000:44': 13.0,
  'ffun_add:15:7500:49': 34.0,
  'mem_40:10:10000:76': 83.0,
  'adb:15:40000:113': 564.0,
  'mem_7:10:5000:0': 1.0,
  'ffun_add:10:2000:9': 8.0,
  'hcb:15:80:42': 8.0,
  'ffun_add:10:2000:4': 8.0,
  'adb:10:15000:98': 212.0,
  'mem:10:15000:45': 19.0,
  'mem_7:15:5000:97': 88.0,
  'adb:10:5000:100': 71.0,
  'ffun:10:40000:30': 57.0,
  'mem_40:10:20000:40': 16.0,
  'ffun_add:10:20000:75': 300.0,
  'mem_7:15:25000:104': 433.0,
  'mem_7:10:2000:116': 35.0,
  'dis:10:20000:26': 12.0,
  'mem_40:10:30000:71': 186.0,
  'mem_40:15:7500:100': 217.0,
  'mem_40:10:50000:35': 26.0,
  'ffun_add:15:40000:117': 2698.0,
  'ffun:15:50000:34': 67.0,
  'mem:10:25000:45': 31.0,
  'adb:10:5000:67': 4.0,
  'mem:10:2000:4': 1.0,
  'ffun_add:15:10000:27': 14.0,
  'mem_40:10:20000:11': 6.0,
  'adb:10:20000:32': 19.0,
  'adb:10:7500:15': 6.0,
  'adb:10:2000:86': 11.0,
  'ffun:15:10000:105': 511.0,
  'mem_7:15:40000:109': 692.0,
  'dis:10:10000:43': 11.0,
  'mem:15:7500:62': 20.0,
  'mem:15:25000:9': 6.0,
  'adb:15:40000:7': 23.0,
  'ffun_add:15:50000:73': 629.0,
  'mem_7:15:50000:118': 866.0,
  'mem:10:5000:26': 2.0,
  'adb:10:2000:108': 29.0,
  'ffun_add:15:2000:13': 7.0,
  'ffun_add:10:50000:101': 3527.0,
  'mem_40:10:30000:72': 186.0,
  'dis:10:40000:20': 13.0,
  'ffun_add:15:50000:82': 1202.0,
  'ffun:15:30000:40': 63.0,
  'mem_40:10:5000:102': 145.0,
  'mem:10:15000:73': 94.0,
  'mem_7:10:50000:62': 97.0,
  'mem_7:10:7500:69': 22.0,
  'mem:15:20000:48': 30.0,
  'ffun:15:20000:32': 45.0,
  'ffun:10:10000:91': 469.0,
  'mem_40:10:30000:95': 578.0,
  'ffun_add:15:7500:99': 511.0,
  'mem:10:10000:36': 8.0,
  'adb:10:7500:6': 6.0,
  'mem_7:15:2000:13': 1.0,
  'mem_7:15:15000:8': 2.0,
  'ffun_add:15:30000:119': 2025.0,
  'mem_7:10:30000:93': 347.0,
  'ffun:10:40000:114': 2574.0,
  'ffun_add:15:7500:105': 511.0,
  'mem_7:15:50000:61': 80.0,
  'ffun_add:10:5000:43': 23.0,
  'ffun_add:15:7500:5': 10.0,
  'mem:10:30000:59': 90.0,
  'mem_40:15:5000:1': 1.0,
  'ffun:15:40000:59': 197.0,
  'ffun:15:20000:90': 499.0,
  'mem_40:15:25000:87': 353.0,
  'mem_7:15:20000:112': 347.0,
  'mem_40:10:5000:55': 14.0,
  'ffun:15:2000:12': 31.0,
  'mem:10:15000:27': 6.0,
  'ffun_add:15:20000:7': 17.0,
  'mem_40:15:7500:85': 78.0,
  'mem_7:15:5000:94': 58.0,
  'ffun:10:25000:80': 415.0,
  'mem_7:10:10000:72': 37.0,
  'ffun_add:15:25000:80': 434.0,
  'adb:15:5000:11': 4.0,
  'mem_40:10:40000:114': 1154.0,
  'mem_7:10:5000:103': 88.0,
  'mem_7:15:10000:4': 1.0,
  'adb:15:7500:100': 107.0,
  'adb:10:10000:81': 35.0,
  'ffun:15:25000:28': 44.0,
  'ffun_add:10:20000:41': 69.0,
  'mem_7:10:50000:10': 8.0,
  'ffun_add:10:20000:48': 102.0,
  'mem_7:15:20000:2': 2.0,
  'ffun_add:15:5000:15': 9.0,
  'ffun:10:25000:108': 1626.0,
  'mem:15:10000:9': 2.0,
  'dis:15:40000:57': 17.0,
  'adb:10:30000:64': 19.0,
  'ffun_add:15:2000:88': 72.0,
  'mem:10:5000:117': 145.0,
  'mem:15:10000:75': 54.0,
  'ffun_add:10:40000:36': 85.0,
  'ffun_add:15:7500:69': 74.0,
  'mem:15:10000:39': 6.0,
  'dis:10:50000:57': 22.0,
  'adb:15:50000:97': 706.0,
  'ffun:15:25000:23': 41.0,
  'dis:10:30000:58': 19.0,
  'mem:15:5000:24': 1.0,
  'hcb:10:150:44': 33.0,
  'mem_7:15:15000:110': 260.0,
  'adb:15:40000:31': 30.0,
  'mem_7:15:10000:8': 1.0,
  'mem_7:10:5000:26': 1.0,
  'ffun_add:15:20000:1': 17.0,
  'mem_7:10:25000:114': 433.0,
  'mem:10:25000:32': 13.0,
  'mem_7:10:20000:61': 40.0,
  'ffun_add:10:7500:40': 22.0,
  'hcb:10:120:29': 20.0,
  'mem_40:10:10000:101': 289.0,
  'mem_40:10:25000:11': 7.0,
  'adb:10:20000:61': 13.0,
  'adb:15:7500:5': 5.0,
  'ffun_add:15:15000:30': 18.0,
  'mem_40:15:10000:17': 2.0,
  'ffun:15:7500:89': 206.0,
  'mem_40:10:10000:116': 289.0,
  'ffun:10:25000:75': 322.0,
  'adb:10:10000:107': 142.0,
  'mem_7:15:30000:108': 520.0,
  'ffun:15:50000:48': 164.0,
  'hcb:10:50:45': 4.0,
  'ffun_add:15:40000:93': 1790.0,
  'mem_7:15:20000:48': 18.0,
  'mem:15:7500:31': 4.0,
  'mem:15:10000:45': 10.0,
  'mem:10:25000:26': 10.0,
  'mem:10:7500:37': 6.0,
  'adb:10:5000:50': 11.0,
  'mem_7:10:7500:95': 88.0,
  'mem:10:10000:94': 193.0,
  'ffun_add:15:10000:99': 679.0,
  'adb:10:10000:10': 8.0,
  'mem_40:15:50000:61': 133.0,
  'adb:10:10000:9': 8.0,
  'ffun:10:20000:88': 684.0,
  'adb:10:7500:63': 5.0,
  'ffun_add:10:7500:62': 66.0,
  'mem_40:10:5000:71': 31.0,
  'ffun:15:40000:6': 45.0,
  'ffun_add:15:20000:49': 82.0,
  'mem_7:15:20000:98': 347.0,
  'ffun_add:10:7500:117': 538.0,
  'ffun_add:10:25000:113': 1769.0,
  'adb:10:20000:12': 16.0,
  'mem_40:15:15000:0': 4.0,
  'ffun:15:5000:84': 116.0,
  'ffun_add:10:30000:54': 222.0,
  'mem:10:25000:112': 722.0,
  'ffun:15:30000:28': 47.0,
  'ffun:15:50000:116': 2435.0,
  'ffun:10:25000:23': 44.0,
  'adb:15:50000:23': 30.0,
  'ffun_add:10:15000:101': 1065.0,
  'mem_7:10:25000:31': 8.0,
  'dis:10:20000:38': 12.0,
  'adb:10:7500:17': 6.0,
  'ffun_add:15:30000:59': 182.0,
  'mem_40:15:50000:89': 704.0,
  'adb:15:25000:62': 12.0,
  'mem_7:10:5000:46': 6.0,
  'adb:10:15000:60': 29.0,
  'adb:10:2000:76': 6.0,
  'mem_40:15:20000:24': 5.0,
  'mem_7:10:25000:5': 5.0,
  'ffun_add:10:25000:89': 897.0,
  'mem:15:25000:70': 97.0,
  'ffun_add:10:50000:80': 976.0,
  'adb:10:20000:40': 23.0,
  'adb:15:15000:91': 112.0,
  'ffun:15:50000:12': 50.0,
  'mem:15:30000:113': 865.0,
  'mem:10:30000:33': 16.0,
  'mem_7:15:25000:119': 433.0,
  'dis:15:30000:19': 11.0,
  'ffun_add:10:40000:4': 34.0,
  'ffun_add:15:50000:38': 83.0,
  'ffun_add:10:10000:30': 17.0,
  'hcb:15:150:41': 28.0,
  'adb:10:15000:68': 10.0,
  'mem_7:10:30000:6': 5.0,
  'ffun_add:10:10000:55': 80.0,
  'mem:15:50000:101': 1444.0,
  'mem_7:15:25000:50': 23.0,
  'ffun_add:15:40000:56': 241.0,
  'hcb:15:150:53': 28.0,
  'mem_40:15:20000:52': 44.0,
  'mem:10:15000:113': 433.0,
  'mem_40:10:2000:60': 6.0,
  'ffun_add:15:5000:100': 343.0,
  'mem_7:15:30000:33': 7.0,
  'mem_7:15:20000:45': 12.0,
  'ffun:10:20000:44': 77.0,
  'mem_7:10:20000:42': 16.0,
  'ffun_add:10:30000:42': 101.0,
  'adb:15:15000:17': 10.0,
  'mem_7:10:15000:5': 2.0,
  'adb:15:7500:71': 8.0,
  'mem_40:10:20000:43': 25.0,
  'ffun:15:5000:98': 271.0,
  'ffun:15:15000:22': 37.0,
  'mem:10:5000:9': 2.0,
  'mem:10:15000:108': 433.0,
  'mem_7:15:15000:61': 24.0,
  'ffun:10:15000:18': 39.0,
  'adb:15:5000:10': 4.0,
  'adb:10:40000:90': 200.0,
  'ffun_add:10:5000:7': 10.0,
  'adb:10:10000:84': 35.0,
  'ffun:15:5000:82': 116.0,
  'ffun:15:2000:13': 31.0,
  'mem:10:10000:12': 4.0,
  'ffun:10:2000:109': 173.0,
  'adb:10:10000:46': 20.0,
  'adb:10:20000:54': 55.0,
  'ffun_add:10:25000:69': 280.0,
  'adb:15:20000:19': 12.0,
  'adb:15:7500:110': 107.0,
  'mem_40:10:15000:20': 5.0,
  'mem_7:15:25000:112': 433.0,
  'mem:15:7500:18': 2.0,
  'mem_7:15:10000:76': 48.0,
  'mem:15:10000:73': 54.0,
  'mem:15:25000:63': 67.0,
  'mem_40:15:5000:80': 40.0,
  'mem_7:15:2000:83': 13.0,
  'ffun_add:15:5000:50': 26.0,
  'mem_40:15:30000:67': 116.0,
  'mem:10:20000:89': 292.0,
  'adb:10:40000:109': 564.0,
  'adb:10:5000:42': 8.0,
  'mem_7:15:2000:63': 4.0,
  'mem:10:2000:92': 40.0,
  'mem_7:15:15000:45': 10.0,
  'mem_7:10:25000:81': 166.0,
  'ffun:10:7500:74': 124.0,
  'mem_40:10:15000:95': 289.0,
  'mem_7:15:5000:62': 8.0,
  'ffun:10:2000:71': 62.0,
  'mem_40:15:5000:55': 12.0,
  'ffun_add:10:20000:52': 151.0,
  'mem_7:15:15000:81': 92.0,
  'adb:10:20000:42': 30.0,
  'ffun:10:7500:11': 35.0,
  'mem_7:10:25000:120': 433.0,
  'ffun_add:10:7500:45': 30.0,
  'ffun_add:10:25000:110': 1769.0,
  'mem:15:10000:32': 5.0,
  'adb:15:50000:10': 29.0,
  'adb:15:40000:108': 564.0,
  'mem_7:15:5000:91': 58.0,
  'ffun_add:10:40000:69': 442.0,
  'mem:15:20000:28': 6.0,
  'mem_40:10:15000:69': 70.0,
  'mem_7:10:2000:55': 4.0,
  'mem_7:15:7500:19': 1.0,
  'mem_40:10:50000:31': 26.0,
  'adb:15:15000:69': 7.0,
  'mem_7:15:40000:42': 23.0,
  'ffun_add:10:25000:15': 24.0,
  'ffun_add:15:50000:40': 83.0,
  'mem:10:50000:31': 26.0,
  'mem_7:10:40000:76': 197.0,
  'mem:15:50000:23': 12.0,
  'adb:10:30000:7': 23.0,
  'mem_40:10:30000:45': 37.0,
  'ffun_add:15:30000:79': 520.0,
  'mem:15:10000:0': 2.0,
  'dis:15:30000:34': 11.0,
  'mem:10:5000:15': 2.0,
  'ffun:10:5000:83': 167.0,
  'ffun:10:20000:112': 1310.0,
  'adb:15:50000:1': 29.0,
  'ffun:15:2000:16': 31.0,
  'adb:15:7500:79': 17.0,
  'mem_7:10:7500:54': 13.0,
  'mem:10:40000:49': 74.0,
  'adb:10:25000:15': 19.0,
  'mem_40:10:10000:54': 29.0,
  'adb:10:25000:7': 19.0,
  'adb:10:7500:16': 6.0,
  'hcb:10:80:27': 9.0,
  'ffun_add:10:5000:105': 362.0,
  'mem_7:10:30000:24': 6.0,
  'ffun_add:10:20000:13': 20.0,
  'ffun:15:50000:28': 57.0,
  'mem_40:10:40000:17': 12.0,
  'mem:10:30000:61': 97.0,
  'ffun:15:50000:8': 49.0,
  'ffun:15:20000:74': 208.0,
  'adb:15:40000:35': 30.0,
  'adb:15:2000:114': 29.0,
  'ffun:10:5000:56': 68.0,
  'ffun_add:10:10000:59': 83.0,
  'mem_40:15:50000:106': 1444.0,
  'mem:15:7500:37': 5.0,
  'adb:15:50000:19': 29.0,
  'mem_40:10:25000:59': 76.0,
  'ffun_add:10:30000:75': 446.0,
  'ffun_add:15:2000:99': 142.0,
  'mem:15:2000:35': 1.0,
  'ffun:10:15000:21': 40.0,
  'ffun:10:30000:13': 45.0,
  'ffun:15:10000:23': 35.0,
  'adb:10:30000:103': 424.0,
  'mem_40:15:5000:58': 13.0,
  'mem:15:40000:76': 294.0,
  'ffun:15:10000:109': 511.0,
  'adb:10:7500:82': 26.0,
  'ffun:15:15000:89': 382.0,
  'adb:10:20000:94': 150.0,
  'mem_40:10:7500:60': 23.0,
  'ffun_add:15:30000:23': 26.0,
  'adb:15:40000:5': 23.0,
  'adb:10:50000:3': 38.0,
  'ffun_add:10:10000:56': 83.0,
  'mem:10:25000:34': 13.0,
  'mem_40:15:25000:117': 722.0,
  'ffun:10:20000:95': 891.0,
  'mem_7:15:40000:118': 692.0,
  'ffun:15:7500:25': 34.0,
  'ffun_add:10:25000:66': 280.0,
  'mem_40:10:5000:95': 97.0,
  'ffun_add:15:25000:26': 26.0,
  'mem_40:15:25000:37': 16.0,
  'ffun_add:10:7500:27': 14.0,
  'mem_7:15:40000:41': 23.0,
  'mem:15:15000:85': 155.0,
  'mem:15:2000:76': 16.0,
  'adb:10:40000:63': 25.0,
  'adb:10:2000:52': 6.0,
  'adb:10:2000:8': 2.0,
  'mem:15:5000:45': 5.0,
  'mem:15:15000:96': 433.0,
  'ffun_add:15:40000:81': 962.0,
  'mem_7:15:15000:94': 173.0,
  'ffun_add:15:50000:22': 38.0,
  'mem_7:15:25000:61': 41.0,
  'ffun:10:2000:17': 33.0,
  'ffun_add:10:2000:89': 81.0,
  'hcb:10:80:48': 9.0,
  'mem_40:15:7500:111': 217.0,
  'mem_7:10:50000:115': 866.0,
  'mem_40:15:30000:38': 18.0,
  'ffun:10:15000:33': 44.0,
  'ffun_add:15:10000:116': 679.0,
  'mem:15:10000:10': 2.0,
  'ffun_add:10:25000:98': 1769.0,
  'mem_7:15:7500:0': 1.0,
  'mem:10:40000:47': 74.0,
  'adb:15:25000:83': 80.0,
  'mem:10:20000:100': 577.0,
  'ffun_add:10:5000:55': 45.0,
  'mem:10:25000:107': 722.0,
  'ffun_add:15:5000:65': 38.0,
  'mem:10:50000:13': 13.0,
  'mem_40:10:5000:98': 145.0,
  'mem_7:10:25000:89': 218.0,
  'mem_40:15:50000:99': 1444.0,
  'mem_7:10:10000:23': 2.0,
  'adb:15:50000:36': 44.0,
  'mem_40:10:30000:100': 865.0,
  'mem_7:15:25000:38': 10.0,
  'ffun_add:10:15000:9': 17.0,
  'mem:10:2000:52': 6.0,
  'ffun:15:2000:14': 31.0,
  'adb:15:2000:6': 1.0,
  'hcb:15:80:50': 8.0,
  'mem_40:15:30000:109': 865.0,
  'ffun:10:15000:104': 994.0,
  'adb:10:20000:98': 282.0,
  'ffun:15:25000:11': 40.0,
  'mem:10:5000:57': 16.0,
  'mem_7:10:15000:33': 5.0,
  'ffun_add:10:25000:28': 31.0,
  'mem:15:30000:103': 865.0,
  'adb:15:40000:33': 30.0,
  'mem_7:10:15000:103': 260.0,
  'ffun_add:10:2000:99': 151.0,
  'mem_40:10:15000:74': 94.0,
  'mem:10:20000:49': 37.0,
  'mem_7:15:5000:86': 43.0,
  'ffun_add:15:15000:46': 62.0,
  'mem:15:2000:80': 16.0,
  'mem:15:50000:2': 11.0,
  'mem_40:15:7500:11': 2.0,
  'hcb:10:120:20': 19.0,
  'ffun_add:15:25000:96': 1690.0,
  'mem_40:10:50000:19': 14.0,
  'mem:10:30000:109': 865.0,
  'mem:15:25000:73': 134.0,
  'mem:10:5000:107': 145.0,
  'mem_40:15:5000:56': 13.0,
  'ffun_add:15:10000:26': 14.0,
  'mem_40:10:25000:86': 365.0,
  'ffun:10:2000:79': 69.0,
  'mem_7:10:15000:51': 25.0,
  'adb:10:30000:67': 19.0,
  'ffun_add:10:30000:9': 27.0,
  'mem_7:15:25000:52': 34.0,
  'mem_7:10:30000:82': 198.0,
  'dis:10:10000:50': 11.0,
  'mem_40:15:10000:110': 289.0,
  'mem:15:2000:54': 5.0,
  'ffun_add:10:15000:36': 37.0,
  'mem_40:10:50000:41': 61.0,
  'mem:15:40000:43': 38.0,
  'ffun:10:7500:26': 36.0,
  'mem_7:10:50000:38': 24.0,
  'hcb:10:100:22': 14.0,
  'ffun:15:2000:80': 55.0,
  'mem_40:15:30000:84': 307.0,
  'adb:10:50000:41': 73.0,
  'dis:10:10000:25': 11.0,
  'mem_7:15:30000:44': 17.0,
  'mem_40:10:25000:17': 7.0,
  'mem:15:50000:66': 193.0,
  'adb:15:25000:51': 55.0,
  'mem:15:40000:64': 107.0,
  'mem_40:15:20000:98': 577.0,
  'hcb:15:80:33': 8.0,
  'mem_40:15:40000:19': 10.0,
  'ffun_add:15:30000:66': 276.0,
  'ffun:10:20000:3': 40.0,
  'mem_40:15:40000:73': 233.0,
  'dis:10:10000:23': 11.0,
  'mem:10:15000:53': 42.0,
  'mem_7:15:50000:103': 866.0,
  'ffun_add:10:15000:38': 37.0,
  'adb:15:10000:67': 5.0,
  'mem:15:10000:20': 2.0,
  'mem_7:15:7500:97': 131.0,
  'mem:15:10000:63': 28.0,
  'ffun:15:10000:26': 36.0,
  'hcb:10:100:47': 15.0,
  'adb:15:10000:93': 74.0,
  'mem_7:10:10000:51': 17.0,
  'mem:10:2000:103': 59.0,
  'mem:10:25000:79': 205.0,
  'ffun_add:15:2000:31': 9.0,
  'mem_7:10:15000:61': 30.0,
  'mem:15:30000:59': 72.0,
  'mem_40:10:5000:96': 145.0,
  'dis:10:10000:24': 11.0,
  'mem:15:50000:94': 956.0,
  'ffun:15:20000:120': 992.0,
  'adb:15:2000:71': 2.0,
  'ffun_add:15:50000:95': 2236.0,
  'mem:15:50000:114': 1444.0,
  'mem:10:5000:119': 145.0,
  'adb:15:20000:64': 10.0,
  'dis:15:20000:36': 10.0,
  'mem_40:15:50000:4': 11.0,
  'mem_7:10:30000:95': 347.0,
  'ffun_add:10:40000:79': 782.0,
  'mem_7:15:10000:70': 24.0,
  'ffun_add:10:15000:20': 17.0,
  'ffun_add:10:25000:55': 187.0,
  'ffun_add:15:2000:89': 72.0,
  'ffun_add:15:5000:77': 93.0,
  'mem_40:10:10000:0': 4.0,
  'ffun_add:10:15000:22': 19.0,
  'mem:15:40000:11': 8.0,
  'mem:15:40000:35': 17.0,
  'mem_7:15:30000:107': 520.0,
  'ffun_add:10:50000:47': 243.0,
  'ffun_add:15:40000:15': 28.0,
  'ffun:15:15000:11': 36.0,
  'ffun:10:2000:55': 51.0,
  'ffun_add:15:15000:80': 264.0,
  'mem:15:15000:118': 433.0,
  'adb:15:20000:51': 44.0,
  'adb:10:5000:72': 7.0,
  'mem_40:15:15000:86': 211.0,
  'mem_40:15:7500:105': 217.0,
  'ffun_add:15:50000:76': 863.0,
  'mem_7:15:20000:22': 4.0,
  'mem_40:15:10000:118': 289.0,
  'ffun_add:15:15000:51': 90.0,
  'mem:10:50000:4': 13.0,
  'mem_7:10:10000:39': 5.0,
  'mem:10:10000:101': 289.0,
  'mem:10:40000:61': 130.0,
  'mem:15:15000:31': 6.0,
  'ffun_add:10:30000:92': 1418.0,
  'mem_7:15:2000:89': 18.0,
  'ffun_add:10:7500:83': 211.0,
  'mem_40:15:2000:22': 1.0,
  'mem_7:15:2000:98': 35.0,
  'mem_7:10:40000:77': 197.0,
  'adb:15:2000:37': 2.0,
  'mem_7:10:25000:38': 12.0,
  'mem_40:15:5000:112': 145.0,
  'adb:15:15000:38': 13.0,
  'mem:15:10000:99': 289.0,
  'ffun:15:2000:53': 38.0,
  'ffun_add:10:5000:94': 245.0,
  'mem:10:7500:50': 14.0,
  'ffun:15:7500:27': 34.0,
  'ffun:15:50000:44': 117.0,
  'mem_7:10:25000:67': 70.0,
  'adb:10:40000:7': 31.0,
  'ffun_add:10:2000:100': 151.0,
  'mem_40:10:30000:117': 865.0,
  'mem_7:10:10000:69': 28.0,
  'hcb:15:120:34': 17.0,
  'adb:10:50000:88': 250.0,
  'mem_7:15:50000:109': 866.0,
  'dis:15:40000:31': 12.0,
  'ffun:10:50000:107': 3205.0,
  'ffun_add:10:25000:33': 39.0,
  'mem:15:5000:62': 14.0,
  'ffun:10:2000:35': 34.0,
  'adb:10:10000:6': 8.0,
  'ffun_add:10:10000:101': 713.0,
  'hcb:15:80:44': 8.0,
  'ffun_add:15:15000:119': 1017.0,
  'ffun:15:5000:21': 33.0,
  'mem:10:7500:38': 6.0,
  'ffun_add:15:2000:34': 9.0,
  'ffun:15:2000:60': 39.0,
  'mem_7:15:25000:49': 23.0,
  'adb:15:2000:30': 2.0,
  'ffun_add:15:10000:88': 335.0,
  'adb:15:40000:8': 23.0,
  'mem:10:15000:24': 5.0,
  'ffun_add:10:15000:119': 1065.0,
  'hcb:15:150:26': 26.0,
  'ffun:10:2000:58': 51.0,
  'adb:10:15000:48': 30.0,
  'mem_7:10:2000:51': 4.0,
  'mem:15:20000:29': 6.0,
  'ffun_add:10:20000:101': 1416.0,
  'ffun:15:15000:8': 36.0,
  'ffun:15:25000:102': 1232.0,
  'mem_7:15:7500:9': 1.0,
  'adb:15:2000:110': 29.0,
  'mem_40:10:15000:70': 70.0,
  'ffun_add:15:20000:114': 1353.0,
  'ffun:10:2000:36': 35.0,
  'mem:10:40000:0': 11.0,
  'adb:15:20000:80': 43.0,
  'mem_7:15:7500:37': 4.0,
  'adb:10:25000:65': 16.0,
  'ffun_add:15:5000:108': 343.0,
  'ffun:15:50000:11': 50.0,
  'adb:15:20000:68': 10.0,
  'ffun:15:20000:81': 372.0,
  'mem:10:50000:27': 19.0,
  'adb:10:5000:44': 8.0,
  'mem_7:10:5000:30': 1.0,
  'ffun_add:10:30000:91': 1418.0,
  'ffun:15:5000:40': 36.0,
  'ffun_add:15:30000:56': 182.0,
  'adb:15:20000:117': 282.0,
  'mem_7:10:30000:75': 112.0,
  'mem_7:10:2000:52': 4.0,
  'ffun_add:10:7500:0': 12.0,
  'dis:10:40000:41': 14.0,
  'mem_7:15:50000:26': 10.0,
  'ffun_add:10:15000:14': 17.0,
  'mem:10:30000:32': 16.0,
  'mem:15:2000:23': 1.0,
  'mem:15:15000:2': 4.0,
  'mem:10:15000:4': 5.0,
  'mem_40:10:30000:68': 138.0,
  'mem_40:15:7500:79': 60.0,
  'ffun_add:10:40000:108': 2824.0,
  'mem_7:15:50000:0': 6.0,
  'mem_40:10:7500:113': 217.0,
  'adb:15:2000:97': 29.0,
  'mem_7:10:2000:13': 1.0,
  'mem_40:15:20000:103': 577.0,
  'mem:10:15000:18': 5.0,
  'adb:10:10000:93': 76.0,
  'mem:10:5000:88': 73.0,
  'ffun:10:50000:91': 2157.0,
  'ffun_add:10:10000:44': 39.0,
  'ffun_add:15:30000:83': 723.0,
  'ffun_add:10:20000:109': 1416.0,
  'ffun_add:10:15000:45': 54.0,
  'mem_7:10:10000:116': 174.0,
  'mem_7:10:7500:113': 131.0,
  'ffun:10:10000:25': 37.0,
  'mem_40:10:50000:67': 230.0,
  'mem_40:15:2000:64': 6.0,
  'mem_7:15:20000:59': 30.0,
  'mem_40:10:15000:52': 42.0,
  'mem_40:10:10000:68': 47.0,
  'mem_7:15:10000:21': 2.0,
  'mem_40:10:15000:119': 433.0,
  'ffun_add:15:40000:51': 229.0,
  'mem:10:20000:25': 7.0,
  'ffun:10:5000:77': 114.0,
  'mem_7:10:7500:55': 13.0,
  'ffun:10:50000:32': 74.0,
  'ffun:10:2000:9': 33.0,
  'mem_7:15:10000:64': 17.0,
  'adb:10:20000:46': 40.0,
  'mem_40:15:50000:1': 11.0,
  'adb:15:50000:73': 56.0,
  'mem:15:7500:5': 2.0,
  'mem:10:5000:11': 2.0,
  'mem_7:10:40000:50': 44.0,
  'ffun_add:15:40000:60': 241.0,
  'ffun_add:10:7500:110': 538.0,
  'mem_40:10:2000:81': 23.0,
  'hcb:10:100:32': 14.0,
  'ffun:15:15000:53': 90.0,
  'mem:10:5000:14': 2.0,
  'mem_40:15:30000:48': 43.0,
  'adb:15:25000:72': 29.0,
  'mem:15:15000:76': 110.0,
  'dis:15:40000:20': 11.0,
  'adb:10:2000:66': 2.0,
  'ffun:10:7500:68': 102.0,
  'ffun_add:10:30000:100': 2118.0,
  'hcb:10:50:59': 3.0,
  'ffun:10:7500:73': 124.0,
  'ffun_add:10:25000:103': 1769.0,
  'mem:15:10000:49': 16.0,
  'mem:10:20000:77': 164.0,
  'mem:10:50000:82': 550.0,
  'ffun:10:7500:4': 34.0,
  'mem_7:15:40000:88': 338.0,
  'mem:15:7500:105': 217.0,
  'mem_40:10:15000:118': 433.0,
  'adb:15:20000:6': 12.0,
  'adb:15:50000:104': 706.0,
  'adb:10:5000:40': 6.0,
  'mem:15:5000:98': 145.0,
  'mem_40:10:30000:73': 186.0,
  'adb:15:50000:32': 37.0,
  'ffun_add:15:15000:91': 675.0,
  'ffun:15:7500:104': 391.0,
  'mem:15:25000:93': 479.0,
  'ffun:10:25000:103': 1626.0,
  'adb:15:5000:49': 8.0,
  'ffun:10:30000:3': 44.0,
  'adb:10:20000:116': 282.0,
  'adb:15:25000:14': 14.0,
  'mem:10:2000:112': 59.0,
  'ffun_add:10:50000:17': 42.0,
  'mem_40:15:15000:78': 119.0,
  'ffun_add:15:15000:75': 194.0,
  'ffun:15:30000:83': 542.0,
  'adb:15:40000:96': 564.0,
  'ffun_add:10:5000:21': 10.0,
  'mem_7:10:30000:90': 263.0,
  'mem:15:25000:30': 7.0,
  'mem_40:10:5000:9': 2.0,
  'mem_7:15:20000:94': 230.0,
  'ffun:15:50000:94': 1623.0,
  'adb:10:7500:102': 107.0,
  'ffun:10:20000:76': 339.0,
  'mem:15:7500:0': 2.0,
  'ffun_add:10:7500:36': 22.0,
  'adb:15:50000:50': 78.0,
  'mem:10:2000:60': 6.0,
  'adb:10:7500:85': 26.0,
  'ffun:10:7500:37': 42.0,
  'mem:15:40000:106': 1154.0,
  'adb:10:2000:37': 2.0,
  'mem_7:10:15000:19': 4.0,
  'ffun:10:20000:38': 58.0,
  'adb:10:30000:10': 23.0,
  'mem_40:10:20000:48': 37.0,
  'mem_7:10:25000:59': 46.0,
  'ffun:10:50000:25': 57.0,
  'mem_40:10:2000:68': 10.0,
  'ffun:10:7500:79': 152.0,
  'ffun_add:15:30000:13': 23.0,
  'mem:10:50000:51': 139.0,
  'mem_40:15:25000:19': 6.0,
  'ffun_add:15:20000:37': 37.0,
  'ffun:10:30000:43': 98.0,
  'mem_40:10:30000:67': 138.0,
  'mem_40:15:50000:11': 11.0,
  'mem_7:10:40000:120': 692.0,
  'ffun:15:40000:23': 48.0,
  'dis:15:40000:48': 12.0,
  'adb:15:20000:60': 30.0,
  'adb:15:25000:115': 353.0,
  'mem_7:10:7500:115': 131.0,
  'mem:10:40000:39': 31.0,
  'mem:15:10000:94': 192.0,
  'ffun_add:10:15000:109': 1065.0,
  'ffun_add:10:50000:36': 106.0,
  'mem:10:10000:109': 289.0,
  'mem:10:40000:98': 1154.0,
  'hcb:10:80:50': 9.0,
  'mem_40:15:10000:106': 289.0,
  'mem_40:10:2000:41': 4.0,
  'ffun:15:50000:112': 2435.0,
  'mem:10:50000:39': 38.0,
  'mem_7:15:50000:5': 6.0,
  'mem_7:15:25000:15': 4.0,
  'mem_40:10:15000:66': 70.0,
  'ffun_add:10:40000:31': 59.0,
  'hcb:10:100:27': 14.0,
  'mem_40:15:15000:85': 155.0,
  'mem_7:10:15000:119': 260.0,
  'ffun:15:20000:102': 992.0,
  'adb:10:30000:106': 424.0,
  'mem:15:15000:41': 14.0,
  'ffun_add:15:10000:89': 335.0,
  'dis:15:30000:47': 11.0,
  'mem_7:15:30000:47': 26.0,
  'mem_7:10:10000:91': 116.0,
  'adb:10:25000:104': 353.0,
  'mem:10:2000:84': 23.0,
  'adb:15:50000:109': 706.0,
  'ffun_add:10:40000:64': 313.0,
  'mem_40:15:30000:8': 6.0,
  'mem:10:7500:28': 4.0,
  'mem:10:2000:88': 30.0,
  'adb:10:15000:8': 12.0,
  'mem_7:10:5000:53': 8.0,
  'mem:15:20000:31': 8.0,
  'ffun:10:50000:99': 3205.0,
  'mem_7:10:15000:69': 42.0,
  'mem_40:15:20000:25': 5.0,
  'ffun_add:10:10000:57': 83.0,
  'mem_7:10:7500:91': 88.0,
  'ffun_add:15:5000:60': 35.0,
  'adb:10:15000:64': 10.0,
  'mem_40:15:7500:87': 106.0,
  'adb:10:7500:32': 7.0,
  'ffun:10:30000:52': 205.0,
  'ffun_add:10:40000:49': 196.0,
  'mem_40:10:25000:119': 722.0,
  'ffun_add:10:5000:12': 10.0,
  'ffun:15:25000:15': 40.0,
  'mem_40:15:10000:43': 10.0,
  'ffun:10:5000:2': 33.0,
  'mem:15:5000:15': 1.0,
  'ffun:10:15000:13': 38.0,
  'mem_40:10:7500:31': 5.0,
  'ffun:15:15000:18': 36.0,
  'ffun_add:15:50000:102': 3373.0,
  'mem:15:2000:77': 16.0,
  'mem_7:15:25000:33': 6.0,
  'mem:10:40000:94': 772.0,
  'mem:15:20000:113': 577.0,
  'ffun:15:7500:65': 64.0,
  'mem:10:30000:93': 578.0,
  'ffun:15:20000:94': 667.0,
  'ffun:15:50000:118': 2435.0,
  'adb:10:50000:71': 67.0,
  'ffun_add:10:30000:107': 2118.0,
  'mem_7:10:40000:57': 72.0,
  'mem_7:15:5000:7': 1.0,
  'ffun:15:10000:28': 36.0,
  'mem_7:10:50000:3': 8.0,
  'adb:15:25000:111': 353.0,
  'mem:10:30000:118': 865.0,
  'mem_7:10:2000:41': 2.0,
  'mem_40:10:40000:115': 1154.0,
  'ffun:15:5000:106': 271.0,
  'ffun:10:40000:119': 2574.0,
  'mem_7:10:7500:75': 29.0,
  'mem:15:20000:34': 8.0,
  'mem_40:15:50000:15': 11.0,
  'mem:15:25000:114': 722.0,
  'adb:10:15000:3': 12.0,
  'ffun_add:10:30000:41': 101.0,
  'mem:15:40000:108': 1154.0,
  'adb:15:20000:62': 10.0,
  'mem_7:10:10000:19': 2.0,
  'ffun:10:50000:52': 316.0,
  'mem_40:15:30000:110': 865.0,
  'mem:15:40000:7': 8.0,
  'mem_40:15:25000:42': 24.0,
  'hcb:10:100:49': 15.0,
  'mem_7:15:2000:90': 18.0,
  'ffun:15:10000:35': 38.0,
  'dis:15:20000:31': 10.0,
  'mem:15:50000:10': 11.0,
  'mem:10:15000:3': 5.0,
  'ffun_add:10:50000:3': 39.0,
  'dis:10:50000:21': 14.0,
  'adb:10:10000:97': 142.0,
  'mem_40:10:2000:116': 59.0,
  'mem_7:10:20000:92': 232.0,
  'mem:15:7500:67': 30.0,
  'dis:10:30000:32': 13.0,
  'mem_40:15:7500:18': 2.0,
  'ffun:10:2000:21': 33.0,
  'mem_40:15:50000:44': 47.0,
  'mem:10:10000:46': 19.0,
  'adb:15:10000:104': 142.0,
  'mem:15:2000:81': 22.0,
  'mem_40:15:40000:31': 17.0,
  'ffun_add:15:30000:76': 520.0,
  'ffun_add:15:15000:39': 30.0,
  'adb:15:10000:92': 74.0,
  'adb:15:50000:26': 32.0,
  'adb:10:2000:16': 2.0,
  'mem_40:15:15000:118': 433.0,
  'hcb:15:150:33': 27.0,
  'ffun_add:15:10000:71': 131.0,
  'adb:15:5000:77': 11.0,
  'hcb:10:100:39': 14.0,
  'dis:15:10000:20': 9.0,
  'mem_40:10:15000:102': 433.0,
  'mem:10:2000:36': 2.0,
  'mem:15:40000:71': 214.0,
  'ffun:15:2000:115': 126.0,
  'adb:10:25000:77': 61.0,
  'mem_40:10:40000:20': 12.0,
  'mem_7:15:10000:13': 2.0,
  'adb:10:25000:2': 19.0,
  'mem_40:10:7500:98': 217.0,
  'ffun:15:20000:73': 208.0,
  'ffun_add:10:30000:96': 2118.0,
  'adb:10:5000:1': 5.0,
  'mem_40:15:5000:49': 8.0,
  'mem_7:10:15000:40': 7.0,
  'ffun:10:7500:114': 522.0,
  'mem_7:10:15000:18': 4.0,
  'adb:10:7500:38': 10.0,
  'adb:10:30000:4': 23.0,
  'mem:15:2000:13': 1.0,
  'mem_40:15:10000:105': 289.0,
  'adb:10:5000:83': 18.0,
  'mem:15:10000:93': 192.0,
  'mem_7:15:20000:16': 4.0,
  'mem_7:15:10000:20': 2.0,
  'mem_40:15:25000:34': 11.0,
  'ffun_add:15:50000:26': 45.0,
  'mem:10:5000:111': 145.0,
  'ffun:15:7500:7': 33.0,
  'mem:10:30000:23': 10.0,
  'ffun_add:15:2000:117': 142.0,
  'mem_7:10:25000:118': 433.0,
  'mem_7:10:2000:110': 35.0,
  'mem:15:40000:102': 1154.0,
  'mem_7:10:10000:64': 20.0,
  'ffun_add:15:30000:33': 37.0,
  'ffun_add:10:5000:91': 245.0,
  'mem_40:10:20000:104': 577.0,
  'mem_7:15:15000:32': 4.0,
  'ffun:10:15000:81': 407.0,
  'ffun:15:10000:52': 70.0,
  'mem_7:10:40000:71': 149.0,
  'ffun_add:10:20000:120': 1416.0,
  'ffun_add:10:25000:80': 492.0,
  'ffun:10:50000:35': 74.0,
  'adb:15:20000:34': 16.0,
  'mem:15:2000:88': 29.0,
  'adb:10:40000:64': 25.0,
  'ffun_add:15:10000:50': 44.0,
  'dis:10:10000:58': 17.0,
  'mem:15:10000:7': 2.0,
  'mem:15:30000:94': 574.0,
  'mem_40:10:2000:50': 5.0,
  'ffun_add:10:15000:118': 1065.0,
  'ffun_add:10:5000:72': 82.0,
  'ffun_add:15:5000:118': 343.0,
  'mem:10:2000:33': 1.0,
  'adb:15:25000:8': 14.0,
  'mem_7:10:50000:33': 16.0,
  'dis:10:30000:59': 19.0,
  'ffun:10:30000:74': 378.0,
  'adb:10:10000:92': 76.0,
  'ffun:10:10000:89': 365.0,
  'mem_7:15:15000:30': 4.0,
  'ffun_add:15:10000:75': 131.0,
  'mem:15:2000:87': 29.0,
  'mem_40:10:7500:51': 22.0,
  'ffun:10:40000:71': 492.0,
  'adb:10:2000:81': 7.0,
  'ffun_add:15:10000:19': 13.0,
  'adb:15:7500:103': 107.0,
  'adb:15:7500:111': 107.0,
  'mem_40:15:40000:10': 8.0,
  'mem:10:15000:7': 5.0,
  'mem_7:15:10000:47': 10.0,
  'ffun_add:10:20000:69': 225.0,
  'ffun:15:7500:91': 269.0,
  'ffun_add:15:15000:71': 194.0,
  'mem_7:10:30000:86': 263.0,
  'mem_7:10:20000:39': 10.0,
  'ffun:15:10000:63': 75.0,
  'mem_40:10:50000:48': 92.0,
  'ffun:15:20000:18': 38.0,
  'ffun:15:5000:104': 271.0,
  'hcb:10:100:51': 15.0,
  'mem:15:30000:75': 161.0,
  'adb:10:50000:97': 706.0,
  'mem_40:10:40000:101': 1154.0,
  'mem_7:15:40000:18': 6.0,
  'mem_40:15:40000:77': 314.0,
  'mem:10:7500:88': 109.0,
  'adb:10:40000:97': 564.0,
  'mem:10:50000:2': 13.0,
  'ffun:15:2000:106': 126.0,
  'mem_7:10:20000:77': 98.0,
  'mem_40:15:5000:30': 2.0,
  'mem_7:15:2000:38': 1.0,
  'mem:15:30000:117': 865.0,
  'adb:10:20000:99': 282.0,
  'hcb:10:120:47': 21.0,
  'ffun_add:10:25000:10': 23.0,
  'mem_40:10:25000:40': 19.0,
  'ffun:10:40000:98': 2574.0,
  'ffun_add:15:20000:103': 1353.0,
  'ffun:15:40000:71': 386.0,
  'adb:15:20000:49': 31.0,
  'ffun_add:10:20000:34': 33.0,
  'mem_40:10:40000:53': 112.0,
  'adb:10:2000:98': 29.0,
  'ffun_add:15:25000:0': 20.0,
  'adb:15:20000:97': 282.0,
  'ffun_add:10:20000:35': 33.0,
  'adb:10:5000:18': 5.0,
  'mem_40:10:50000:94': 964.0,
  'mem_40:15:20000:88': 282.0,
  'ffun_add:15:25000:103': 1690.0,
  'mem_40:10:10000:57': 30.0,
  'ffun:15:20000:29': 41.0,
  'ffun_add:10:25000:70': 280.0,
  'ffun_add:15:5000:58': 35.0,
  'adb:10:40000:100': 564.0,
  'mem:10:25000:105': 722.0,
  'adb:10:20000:59': 38.0,
  'mem_7:10:30000:100': 520.0,
  'mem:10:5000:8': 2.0,
  'mem:15:30000:69': 116.0,
  'adb:10:7500:1': 6.0,
  'mem_7:15:30000:86': 253.0,
  'mem_40:15:20000:53': 44.0,
  'mem_7:10:10000:4': 2.0,
  'hcb:15:80:29': 7.0,
  'ffun_add:15:10000:28': 14.0,
  'mem_7:15:40000:90': 338.0,
  'mem:15:7500:92': 144.0,
  'mem_7:10:20000:105': 347.0,
  'dis:10:10000:38': 11.0,
  'mem:10:5000:102': 145.0,
  'hcb:10:50:27': 3.0,
  'hcb:10:120:49': 21.0,
  'adb:10:10000:111': 142.0,
  'ffun_add:10:5000:60': 46.0,
  'mem_40:15:50000:2': 11.0,
  'ffun:15:2000:20': 31.0,
  'adb:15:30000:36': 26.0,
  'ffun:15:40000:46': 137.0,
  'adb:15:2000:107': 29.0,
  'mem_7:10:30000:56': 54.0,
  'adb:15:10000:98': 142.0,
  'ffun_add:15:2000:104': 142.0,
  'adb:15:15000:98': 212.0,
  'ffun:10:7500:63': 84.0,
  'ffun_add:15:40000:90': 1319.0,
  'mem:10:30000:75': 186.0,
  'mem_7:10:40000:32': 13.0,
  'mem:10:40000:27': 16.0,
  'mem_40:10:10000:3': 4.0,
  'mem_40:10:10000:37': 8.0,
  'dis:15:30000:21': 11.0,
  'mem:10:5000:74': 31.0,
  'ffun:10:20000:110': 1310.0,
  'mem_7:15:5000:17': 1.0,
  'ffun:15:20000:88': 499.0,
  'mem_7:15:40000:86': 338.0,
  'mem:15:40000:96': 1154.0,
  'ffun_add:10:25000:77': 492.0,
  'ffun:15:30000:96': 1472.0,
  'ffun_add:15:40000:35': 48.0,
  'ffun:15:25000:108': 1232.0,
  'ffun:15:30000:66': 223.0,
  'ffun_add:10:50000:103': 3527.0,
  'mem_40:10:7500:103': 217.0,
  'mem_40:15:5000:91': 96.0,
  'mem_7:15:7500:25': 1.0,
  'mem_40:15:40000:46': 59.0,
  'mem_7:15:7500:45': 5.0,
  'mem_40:15:40000:7': 8.0,
  'mem_40:10:15000:86': 218.0,
  'hcb:10:120:19': 19.0,
  'mem_40:10:5000:45': 7.0,
  'ffun_add:15:30000:57': 182.0,
  'ffun_add:15:15000:87': 499.0,
  'mem_7:10:20000:64': 40.0,
  'mem_7:10:20000:90': 175.0,
  'mem_40:10:40000:110': 1154.0,
  'mem_40:15:40000:100': 1154.0,
  'ffun_add:15:40000:96': 2698.0,
  'adb:10:7500:33': 7.0,
  'ffun:10:40000:34': 66.0,
  'mem_7:10:15000:34': 5.0,
  'mem_7:10:5000:19': 1.0,
  'mem_40:15:15000:30': 5.0,
  'adb:10:25000:49': 49.0,
  'adb:15:40000:73': 44.0,
  'mem_7:15:30000:8': 4.0,
  'mem_40:10:25000:93': 482.0,
  'ffun_add:10:30000:72': 446.0,
  'adb:10:25000:20': 19.0,
  'mem_40:10:25000:101': 722.0,
  'ffun_add:10:10000:3': 13.0,
  'ffun_add:15:30000:99': 2025.0,
  'ffun:10:25000:13': 42.0,
  'ffun:10:40000:111': 2574.0,
  'adb:10:10000:89': 50.0,
  'adb:15:5000:37': 5.0,
  'mem:15:15000:49': 23.0,
  'ffun_add:10:25000:7': 23.0,
  'adb:15:40000:71': 44.0,
  'adb:10:30000:99': 424.0,
  'mem_7:10:5000:82': 34.0,
  'ffun_add:10:30000:119': 2118.0,
  'ffun:10:25000:6': 42.0,
  'mem_40:15:7500:52': 17.0,
  'ffun:15:10000:29': 36.0,
  'mem:15:15000:109': 433.0,
  'mem_7:15:40000:64': 65.0,
  'adb:10:40000:39': 46.0,
  'mem_7:10:40000:60': 72.0,
  'ffun:15:30000:59': 156.0,
  'mem_40:15:10000:72': 59.0,
  'ffun_add:15:20000:32': 27.0,
  'mem_7:10:7500:5': 1.0,
  'mem_40:10:7500:97': 217.0,
  'mem_7:15:50000:11': 7.0,
  'mem_7:15:5000:11': 1.0,
  'ffun:10:10000:92': 469.0,
  'ffun_add:15:15000:99': 1017.0,
  'mem:10:2000:38': 2.0,
  'mem_40:10:7500:86': 109.0,
  'dis:10:10000:53': 12.0,
  'ffun_add:15:15000:13': 14.0,
  'adb:15:2000:49': 4.0,
  'dis:10:50000:42': 15.0,
  'mem:15:2000:45': 2.0,
  'mem_7:15:2000:86': 18.0,
  'mem:15:15000:78': 110.0,
  'mem_7:10:5000:110': 88.0,
  'ffun:15:7500:41': 43.0,
  'adb:15:20000:90': 97.0,
  'mem:15:40000:5': 8.0,
  'mem_7:15:10000:42': 6.0,
  'mem:10:50000:97': 1444.0,
  'adb:15:15000:40': 13.0,
  'adb:10:10000:43': 16.0,
  'dis:10:20000:49': 12.0,
  'mem_40:10:30000:35': 16.0,
  'adb:15:5000:41': 6.0,
  'mem:15:20000:102': 577.0,
  'mem_7:15:30000:119': 520.0,
  'mem_7:10:7500:99': 131.0,
  'hcb:10:150:25': 30.0,
  'mem_7:10:25000:55': 42.0,
  'adb:10:40000:38': 46.0,
  'mem_40:10:10000:31': 6.0,
  'mem:15:25000:78': 184.0,
  'mem_40:15:2000:76': 17.0,
  'ffun_add:15:20000:70': 187.0,
  'adb:15:10000:8': 6.0,
  'adb:15:2000:94': 16.0,
  'adb:15:20000:89': 97.0,
  'mem_7:15:15000:87': 127.0,
  'ffun:10:15000:5': 38.0,
  'mem_7:10:50000:82': 330.0,
  'ffun_add:15:10000:23': 13.0,
  'ffun:10:40000:27': 57.0,
  'mem:10:40000:17': 12.0,
  'adb:10:30000:41': 44.0,
  'dis:10:30000:31': 13.0,
  'ffun:10:50000:34': 74.0,
  'mem_7:10:50000:18': 8.0,
  'ffun:10:30000:29': 50.0,
  'mem_7:10:40000:117': 692.0,
  'mem_7:15:7500:69': 18.0,
  'mem_40:10:50000:71': 310.0,
  'mem:10:10000:26': 5.0,
  'mem_40:15:10000:84': 103.0,
  'ffun_add:10:10000:83': 277.0,
  'ffun:15:15000:64': 97.0,
  'ffun_add:10:2000:62': 24.0,
  'ffun_add:10:10000:21': 15.0,
  'mem_40:15:10000:60': 25.0,
  'adb:15:20000:52': 44.0,
  'mem_40:10:20000:52': 56.0,
  'mem:10:50000:87': 728.0,
  'mem_7:15:20000:14': 4.0,
  'mem_7:15:20000:117': 347.0,
  'mem:15:10000:77': 74.0,
  'mem:10:15000:19': 5.0,
  'mem:15:20000:114': 577.0,
  'adb:15:5000:17': 4.0,
  'mem_7:10:15000:120': 260.0,
  'mem_40:15:25000:32': 11.0,
  'adb:15:50000:111': 706.0,
  'ffun_add:15:2000:111': 142.0,
  'adb:10:10000:78': 25.0,
  'mem_40:15:7500:93': 144.0,
  'adb:10:40000:110': 564.0,
  'mem:10:2000:97': 59.0,
  'ffun_add:10:5000:111': 362.0,
  'hcb:15:80:56': 7.0,
  'mem_40:15:20000:32': 8.0,
  'mem:15:25000:28': 7.0,
  'ffun:10:2000:42': 38.0,
  'adb:10:50000:109': 706.0,
  'ffun:15:40000:41': 100.0,
  'ffun_add:10:10000:64': 85.0,
  'mem:15:50000:60': 121.0,
  'mem_40:15:7500:97': 217.0,
  'mem_7:10:2000:57': 4.0,
  'mem_40:15:2000:52': 5.0,
  'mem_7:15:50000:117': 866.0,
  'mem_40:15:40000:99': 1154.0,
  'ffun_add:15:5000:109': 343.0,
  'mem:10:15000:60': 46.0,
  'mem_40:10:2000:58': 6.0,
  'mem_40:15:50000:96': 1444.0,
  'ffun_add:10:40000:10': 34.0,
  'mem_40:15:25000:53': 55.0,
  'ffun:15:5000:68': 62.0,
  'mem_40:15:10000:11': 2.0,
  'mem_40:10:5000:88': 73.0,
  'mem_40:15:7500:104': 217.0,
  'ffun_add:15:30000:30': 30.0,
  'mem_7:15:30000:94': 344.0,
  'dis:15:10000:32': 9.0,
  'mem_40:15:5000:36': 4.0,
  'adb:10:5000:56': 10.0,
  'adb:10:40000:72': 54.0,
  'adb:15:5000:62': 2.0,
  'mem:10:25000:102': 722.0,
  'mem_7:10:2000:63': 5.0,
  'ffun:15:30000:38': 63.0,
  'adb:10:20000:82': 70.0,
  'mem_40:15:7500:39': 5.0,
  'mem_40:15:20000:95': 383.0,
  'mem:15:7500:43': 7.0,
  'ffun_add:10:5000:67': 63.0,
  'ffun:15:20000:50': 84.0,
  'mem_7:10:2000:107': 35.0,
  'mem:15:50000:106': 1444.0,
  'mem_7:10:10000:96': 174.0,
  'mem_7:10:30000:66': 83.0,
  'ffun:10:15000:43': 66.0,
  'adb:10:20000:115': 282.0,
  'adb:10:30000:37': 35.0,
  'ffun:10:25000:16': 43.0,
  'mem_7:10:50000:118': 866.0,
  'dis:15:50000:21': 12.0,
  'mem_40:10:5000:44': 7.0,
  'mem_40:10:40000:45': 49.0,
  'mem_40:15:15000:4': 4.0,
  'mem_7:15:20000:75': 71.0,
  'ffun:10:40000:77': 639.0,
  'ffun_add:15:25000:79': 434.0,
  'mem_7:15:7500:116': 131.0,
  'ffun:10:10000:49': 65.0,
  'adb:10:50000:117': 706.0,
  'ffun_add:15:10000:33': 17.0,
  'ffun_add:10:7500:91': 362.0,
  'mem_40:10:50000:70': 230.0,
  'dis:10:30000:50': 13.0,
  'mem_40:15:30000:106': 865.0,
  'ffun:10:30000:22': 47.0,
  'mem_40:10:7500:55': 22.0,
  'mem_40:15:15000:87': 211.0,
  'mem:10:5000:94': 97.0,
  'mem_7:15:40000:98': 692.0,
  'mem_40:10:50000:78': 409.0,
  'adb:10:15000:29': 13.0,
  'mem:10:20000:34': 11.0,
  'mem_7:10:7500:46': 8.0,
  'hcb:15:120:46': 18.0,
  'adb:10:50000:22': 40.0,
  'mem_7:10:50000:45': 37.0,
  'adb:15:7500:34': 6.0,
  'mem:10:2000:68': 10.0,
  'dis:10:10000:18': 11.0,
  'mem_40:15:25000:73': 145.0,
  'adb:10:7500:66': 5.0,
  'adb:10:5000:61': 4.0,
  'ffun:10:25000:28': 47.0,
  'ffun_add:10:2000:13': 8.0,
  'adb:10:15000:82': 52.0,
  'dis:10:40000:36': 14.0,
  'ffun:15:5000:59': 51.0,
  'ffun_add:10:5000:83': 144.0,
  'ffun_add:10:7500:18': 12.0,
  'mem:15:30000:20': 7.0,
  'mem_7:15:15000:51': 20.0,
  'dis:15:20000:28': 10.0,
  'mem:15:10000:118': 289.0,
  'hcb:10:100:50': 15.0,
  'ffun_add:15:25000:14': 20.0,
  'ffun:10:50000:10': 53.0,
  'mem_7:15:20000:52': 26.0,
  'mem_7:10:40000:20': 7.0,
  'ffun:15:25000:35': 49.0,
  'mem_40:15:30000:30': 10.0,
  'ffun_add:15:15000:27': 18.0,
  'mem:10:15000:80': 124.0,
  'ffun:15:5000:92': 189.0,
  'mem_7:15:15000:40': 6.0,
  'ffun_add:15:30000:5': 23.0,
  'adb:10:20000:56': 38.0,
  'mem:15:15000:90': 211.0,
  'mem:15:25000:1': 6.0,
  'adb:15:15000:12': 10.0,
  'mem_40:15:50000:41': 47.0,
  'ffun:10:10000:44': 55.0,
  'dis:15:50000:37': 12.0,
  'adb:10:5000:112': 71.0,
  'mem:10:40000:114': 1154.0,
  'ffun_add:10:30000:35': 46.0,
  'ffun_add:15:30000:64': 192.0,
  'mem_7:10:10000:18': 2.0,
  'mem_7:15:25000:73': 88.0,
  'mem_7:10:5000:117': 88.0,
  'mem_40:15:25000:69': 97.0,
  'ffun_add:15:25000:24': 23.0,
  'ffun:10:30000:46': 128.0,
  'ffun_add:15:5000:10': 9.0,
  'ffun:15:25000:98': 1232.0,
  'mem:10:5000:39': 5.0,
  'ffun_add:15:50000:33': 58.0,
  'adb:15:10000:115': 142.0,
  'mem_40:15:10000:5': 2.0,
  'adb:15:15000:10': 10.0,
  'ffun:10:30000:77': 488.0,
  'ffun:10:30000:79': 488.0,
  'mem:10:10000:41': 13.0,
  'mem_40:10:5000:93': 97.0,
  'ffun:10:5000:7': 33.0,
  'mem_7:10:20000:63': 40.0,
  'ffun_add:10:30000:13': 27.0,
  'mem_40:10:7500:111': 217.0,
  'ffun:15:25000:54': 130.0,
  'ffun:15:2000:107': 126.0,
  'mem_40:10:2000:95': 40.0,
  'adb:15:7500:53': 17.0,
  'mem:10:20000:4': 6.0,
  'mem:10:10000:52': 29.0,
  'mem_40:15:7500:28': 2.0,
  'mem:15:30000:70': 116.0,
  'mem_40:15:10000:24': 2.0,
  'ffun:10:20000:81': 527.0,
  'mem_40:15:7500:50': 12.0,
  'adb:15:7500:13': 5.0,
  'mem_7:10:15000:94': 174.0,
  'adb:15:10000:41': 12.0,
  'mem_7:15:30000:60': 43.0,
  'mem_7:15:20000:40': 7.0,
  'mem_7:15:50000:99': 866.0,
  'ffun:10:10000:71': 153.0,
  'adb:15:7500:39': 7.0,
  'ffun:10:40000:96': 2574.0,
  'adb:10:30000:49': 59.0,
  'mem:15:40000:83': 410.0,
  'ffun_add:10:25000:106': 1769.0,
  'mem:15:30000:114': 865.0,
  'mem_7:15:10000:119': 174.0,
  'adb:15:50000:59': 74.0,
  'ffun_add:15:5000:66': 52.0,
  'adb:10:15000:52': 42.0,
  'ffun:15:15000:35': 41.0,
  'ffun_add:10:40000:118': 2824.0,
  'ffun:15:50000:14': 50.0,
  'adb:15:2000:46': 4.0,
  'mem_7:10:10000:73': 37.0,
  'ffun:15:20000:72': 208.0,
  'mem:10:25000:51': 70.0,
  'adb:15:50000:64': 23.0,
  'ffun:15:25000:41': 74.0,
  'mem_40:15:25000:10': 6.0,
  'ffun_add:10:30000:70': 334.0,
  'hcb:10:150:54': 33.0,
  'adb:15:25000:11': 14.0,
  'ffun_add:15:30000:88': 991.0,
  'ffun:10:10000:106': 679.0,
  'adb:10:5000:9': 5.0,
  'mem:15:15000:26': 5.0,
  'ffun_add:15:10000:120': 679.0,
  'mem:10:50000:77': 409.0,
  'mem_40:10:40000:105': 1154.0,
  'mem_40:10:30000:62': 97.0,
  'mem:10:7500:0': 2.0,
  'adb:15:30000:55': 66.0,
  'mem:15:5000:99': 145.0,
  'mem_7:10:10000:22': 2.0,
  'ffun_add:10:40000:63': 313.0,
  'mem:15:25000:24': 6.0,
  'adb:10:7500:86': 38.0,
  'ffun:10:20000:51': 150.0,
  'ffun:15:50000:43': 117.0,
  'ffun_add:15:2000:48': 14.0,
  'mem_40:10:10000:84': 110.0,
  'adb:15:10000:84': 32.0,
  'mem_7:10:2000:32': 1.0,
  'mem:15:10000:47': 16.0,
  'mem_7:15:5000:55': 7.0,
  'mem:10:50000:59': 150.0,
  'ffun_add:15:7500:53': 48.0,
  'mem:10:5000:77': 42.0,
  'ffun:10:20000:35': 49.0,
  'ffun:15:20000:55': 110.0,
  'ffun:15:15000:43': 56.0,
  'ffun:10:25000:105': 1626.0,
  'ffun:10:7500:98': 522.0,
  'ffun_add:10:7500:39': 22.0,
  'mem:15:15000:27': 5.0,
  'hcb:10:100:23': 14.0,
  'ffun:15:30000:101': 1472.0,
  'adb:15:2000:21': 1.0,
  'hcb:15:80:48': 8.0,
  'adb:15:30000:98': 424.0,
  'mem_40:15:40000:58': 97.0,
  'ffun:15:25000:34': 49.0,
  'adb:15:15000:97': 212.0,
  'adb:10:20000:39': 23.0,
  'mem_40:10:25000:13': 7.0,
  'ffun:10:5000:19': 33.0,
  'mem_7:15:10000:67': 24.0,
  'mem_7:15:2000:77': 10.0,
  'mem:10:50000:16': 14.0,
  'mem:10:40000:54': 112.0,
  'mem:10:50000:21': 16.0,
  'mem_7:15:25000:12': 4.0,
  'adb:10:2000:60': 5.0,
  'mem_7:10:5000:12': 1.0,
  'mem:15:2000:40': 1.0,
  'mem:15:7500:3': 2.0,
  'mem:10:2000:113': 59.0,
  'mem:10:50000:112': 1444.0,
  'mem_40:10:40000:98': 1154.0,
  'ffun_add:15:7500:93': 341.0,
  'adb:10:5000:32': 5.0,
  'ffun_add:15:50000:113': 3373.0,
  'mem_7:10:7500:102': 131.0,
  'mem_7:10:20000:91': 232.0,
  'adb:15:30000:68': 14.0,
  'ffun:10:5000:6': 33.0,
  'ffun_add:10:25000:21': 27.0,
  'ffun_add:10:40000:18': 35.0,
  'mem_7:15:15000:28': 4.0,
  'mem_7:10:7500:20': 2.0,
  'mem_40:10:5000:10': 2.0,
  'mem:15:7500:45': 7.0,
  'ffun_add:10:40000:20': 35.0,
  'adb:15:30000:73': 34.0,
  'mem:10:2000:25': 1.0,
  'mem:10:5000:41': 7.0,
  'mem_40:10:25000:34': 13.0,
  'mem_40:15:15000:98': 433.0,
  'adb:10:5000:116': 71.0,
  'ffun:10:2000:74': 62.0,
  'ffun_add:15:40000:29': 37.0,
  'ffun_add:15:2000:107': 142.0,
  'adb:10:7500:48': 16.0,
  'mem_40:15:15000:14': 4.0,
  'mem_40:10:30000:61': 97.0,
  'adb:10:40000:85': 138.0,
  'mem:15:7500:6': 2.0,
  'mem:10:50000:72': 310.0,
  'adb:10:15000:47': 30.0,
  'hcb:15:50:51': 3.0,
  'mem:10:25000:56': 76.0,
  'adb:15:40000:120': 564.0,
  'mem_7:15:15000:91': 173.0,
  'mem_7:10:20000:119': 347.0,
  'mem:15:5000:115': 145.0,
  'ffun:15:40000:89': 968.0,
  'mem:15:50000:0': 11.0,
  'adb:10:40000:98': 564.0,
  'adb:15:2000:117': 29.0,
  'mem_7:15:30000:55': 40.0,
  'ffun_add:15:10000:10': 12.0,
  'ffun_add:10:10000:85': 277.0,
  'mem:15:30000:78': 221.0,
  'mem_40:10:40000:43': 49.0,
  'adb:10:10000:28': 8.0,
  'mem_40:10:15000:9': 5.0,
  'ffun:10:25000:55': 177.0,
  'mem_40:10:20000:64': 65.0,
  'ffun_add:10:40000:55': 293.0,
  'hcb:15:80:53': 8.0,
  'ffun:10:15000:113': 994.0,
  'adb:15:2000:88': 11.0,
  'mem_7:15:5000:84': 31.0,
  'adb:15:5000:104': 71.0,
  'mem_7:10:25000:104': 433.0,
  'mem_7:15:5000:50': 5.0,
  'adb:10:40000:35': 37.0,
  'mem:15:5000:44': 5.0,
  'adb:15:5000:96': 71.0,
  'mem_40:10:10000:11': 4.0,
  'mem_7:10:7500:24': 2.0,
  'mem_7:10:20000:12': 4.0,
  'adb:10:2000:105': 29.0,
  'ffun_add:15:50000:93': 2236.0,
  'mem_7:10:7500:12': 1.0,
  'mem:15:7500:30': 2.0,
  'hcb:10:100:36': 14.0,
  'mem_40:10:25000:31': 13.0,
  'adb:15:40000:18': 23.0,
  'mem_40:10:25000:107': 722.0,
  'mem_40:15:50000:86': 704.0,
  'ffun:10:40000:2': 48.0,
  'adb:10:50000:76': 122.0,
  'ffun:15:10000:116': 511.0,
  'mem_7:15:30000:56': 43.0,
  'dis:10:30000:33': 13.0,
  'mem:10:30000:21': 10.0,
  'ffun_add:10:50000:50': 243.0,
  'mem_40:10:7500:80': 62.0,
  'mem_7:15:5000:104': 88.0,
  'ffun:15:7500:8': 33.0,
  'ffun:10:7500:40': 42.0,
  'adb:15:40000:104': 564.0,
  'ffun_add:10:2000:44': 14.0,
  'ffun:10:20000:17': 41.0,
  'ffun_add:10:2000:112': 151.0,
  'mem_7:10:10000:117': 174.0,
  'ffun:10:20000:97': 1310.0,
  'mem_40:15:10000:117': 289.0,
  'dis:10:50000:52': 17.0,
  'mem_7:10:25000:91': 289.0,
  'mem_40:10:50000:108': 1444.0,
  'ffun_add:15:50000:53': 285.0,
  'ffun:10:20000:12': 41.0,
  'mem_40:15:30000:35': 12.0,
  'mem_40:15:5000:77': 40.0,
  'mem_7:15:15000:62': 24.0,
  'ffun_add:15:2000:4': 7.0,
  'mem:15:40000:4': 8.0,
  'ffun_add:10:20000:40': 46.0,
  'mem_7:10:2000:91': 24.0,
  'mem_7:15:7500:78': 36.0,
  'mem_40:15:7500:81': 78.0,
  'ffun:15:30000:41': 82.0,
  'ffun:15:2000:90': 77.0,
  'mem_7:10:40000:101': 692.0,
  'mem_7:10:7500:13': 1.0,
  'adb:10:5000:14': 5.0,
  'mem_40:15:40000:63': 107.0,
  'adb:15:25000:16': 14.0,
  'mem:10:15000:65': 49.0,
  'mem_7:15:40000:115': 692.0,
  'ffun:10:7500:64': 84.0,
  'ffun_add:10:5000:113': 362.0,
  'adb:10:20000:85': 70.0,
  'dis:15:20000:35': 10.0,
  'mem_7:10:7500:73': 29.0,
  'ffun_add:15:7500:73': 100.0,
  'mem_7:15:40000:116': 692.0,
  'mem_40:10:15000:99': 433.0,
  'mem_7:10:50000:109': 866.0,
  'dis:10:20000:47': 12.0,
  'ffun_add:15:50000:71': 629.0,
  'adb:10:10000:50': 20.0,
  'mem:15:50000:40': 30.0,
  'adb:15:30000:110': 424.0,
  'hcb:15:50:57': 3.0,
  'hcb:10:50:40': 4.0,
  'ffun_add:10:25000:60': 194.0,
  'ffun:15:15000:3': 36.0,
  'adb:10:25000:105': 353.0,
  'ffun:10:15000:87': 525.0,
  'mem_7:10:7500:118': 131.0,
  'mem_7:15:25000:94': 287.0,
  'ffun_add:15:25000:12': 20.0,
  'ffun:15:30000:78': 397.0,
  'mem:10:15000:64': 49.0,
  'dis:10:10000:57': 17.0,
  'mem:15:40000:88': 563.0,
  'adb:10:5000:110': 71.0,
  'mem_40:10:50000:72': 310.0,
  'ffun_add:15:5000:35': 12.0,
  'ffun_add:15:20000:107': 1353.0,
  'ffun:10:15000:101': 994.0,
  'adb:15:50000:27': 32.0,
  'adb:10:40000:13': 31.0,
  'ffun_add:15:15000:40': 30.0,
  'adb:15:7500:51': 17.0,
  'mem_40:10:30000:102': 865.0,
  'mem:15:50000:65': 133.0,
  'ffun_add:15:40000:57': 241.0,
  'ffun_add:10:5000:23': 10.0,
  'mem_40:15:2000:44': 2.0,
  'ffun_add:10:30000:116': 2118.0,
  'mem:10:5000:30': 2.0,
  'mem_40:15:20000:116': 577.0,
  'mem_7:15:2000:82': 13.0,
  'ffun_add:15:2000:81': 55.0,
  'adb:10:40000:91': 300.0,
  'ffun:10:7500:15': 35.0,
  'ffun_add:10:7500:24': 13.0,
  'adb:10:20000:83': 70.0,
  'mem:15:2000:120': 59.0,
  'ffun_add:15:20000:19': 18.0,
  'adb:10:15000:100': 212.0,
  'ffun:15:15000:60': 93.0,
  'ffun_add:10:2000:117': 151.0,
  'mem:15:20000:43': 19.0,
  'mem_7:10:40000:34': 13.0,
  'ffun_add:15:25000:32': 33.0,
  'mem:15:25000:84': 257.0,
  'adb:15:2000:87': 11.0,
  'mem_40:10:30000:4': 8.0,
  'ffun:15:10000:47': 57.0,
  'mem_7:10:40000:40': 19.0,
  'mem_40:10:50000:28': 19.0,
  'mem:15:2000:56': 6.0,
  'ffun:15:20000:119': 992.0,
  'mem_7:15:15000:79': 71.0,
  'ffun:15:50000:70': 352.0,
  'ffun_add:10:10000:33': 20.0,
  'adb:15:5000:118': 71.0,
  'mem:10:50000:17': 14.0,
  'ffun_add:15:50000:61': 317.0,
  'mem_7:10:20000:79': 98.0,
  'mem_7:10:10000:42': 8.0,
  'adb:15:50000:107': 706.0,
  'adb:10:7500:60': 14.0,
  'ffun_add:10:15000:37': 37.0,
  'ffun:15:50000:40': 85.0,
  'ffun:10:20000:102': 1310.0,
  'ffun:10:25000:92': 1102.0,
  'mem:10:15000:9': 5.0,
  'mem_40:15:25000:88': 353.0,
  'ffun_add:10:30000:69': 334.0,
  'mem:10:40000:55': 112.0,
  'adb:10:40000:21': 31.0,
  'mem_40:15:7500:30': 2.0,
  'mem_7:10:7500:59': 14.0,
  'mem:10:40000:100': 1154.0,
  'mem_40:10:10000:61': 32.0,
  'adb:10:50000:27': 41.0,
  'adb:10:7500:103': 107.0,
  'ffun_add:10:20000:71': 300.0,
  'adb:15:50000:118': 706.0,
  'dis:10:20000:35': 12.0,
  'adb:10:30000:44': 44.0,
  'mem:10:50000:11': 13.0,
  'mem_7:15:5000:21': 1.0,
  'mem_7:15:10000:115': 174.0,
  'adb:10:15000:112': 212.0,
  'adb:15:15000:0': 10.0,
  'adb:15:5000:67': 2.0,
  'ffun_add:10:5000:96': 362.0,
  'adb:10:30000:117': 424.0,
  'ffun_add:15:20000:28': 21.0,
  'ffun_add:10:2000:33': 9.0,
  'mem_40:15:7500:17': 2.0,
  'mem_7:10:2000:90': 18.0,
  'ffun_add:10:30000:40': 66.0,
  'mem:10:30000:67': 138.0,
  'mem:15:7500:44': 7.0,
  'mem:15:20000:92': 383.0,
  'mem:15:15000:0': 4.0,
  'ffun:10:7500:52': 81.0,
  'ffun_add:10:15000:30': 21.0,
  'adb:15:40000:43': 46.0,
  'mem_40:15:5000:114': 145.0,
  'ffun_add:15:15000:2': 14.0,
  'mem_40:15:5000:9': 1.0,
  'hcb:10:150:27': 31.0,
  'adb:10:15000:105': 212.0,
  'ffun:15:25000:32': 49.0,
  'mem_7:10:15000:36': 7.0,
  'mem:15:10000:6': 2.0,
  'ffun:15:25000:96': 1232.0,
  'adb:10:2000:48': 5.0,
  'adb:15:25000:64': 12.0,
  'mem_40:15:2000:93': 38.0,
  'ffun:10:7500:101': 522.0,
  'ffun_add:15:40000:111': 2698.0,
  'ffun_add:10:30000:61': 237.0,
  'ffun_add:10:20000:56': 157.0,
  'mem_7:10:7500:57': 14.0,
  'mem_7:15:50000:73': 175.0,
  'mem:10:7500:112': 217.0,
  'mem:15:2000:93': 38.0,
  'adb:10:15000:117': 212.0,
  'ffun_add:15:2000:82': 55.0,
  'ffun_add:15:15000:97': 1017.0,
  'ffun_add:10:30000:98': 2118.0,
  'hcb:15:120:24': 17.0,
  'mem_7:15:50000:114': 866.0,
  'ffun_add:15:10000:74': 131.0,
  'mem_7:10:30000:47': 34.0,
  'mem:10:5000:89': 73.0,
  'mem_7:10:30000:22': 6.0,
  'ffun:15:15000:9': 36.0,
  'ffun:10:5000:79': 114.0,
  'mem:15:25000:6': 6.0,
  'hcb:10:120:38': 20.0,
  'mem_40:10:20000:72': 124.0,
  'ffun_add:10:10000:31': 20.0,
  'adb:10:15000:57': 29.0,
  'mem_40:10:25000:38': 19.0,
  'ffun_add:10:20000:50': 102.0,
  'dis:15:30000:52': 12.0,
  'ffun:15:50000:30': 57.0,
  'ffun:10:20000:74': 266.0,
  'adb:15:30000:64': 14.0,
  'mem:15:40000:58': 97.0,
  'ffun:10:15000:85': 407.0,
  'mem_40:15:15000:37': 10.0,
  'mem_7:15:40000:3': 5.0,
  'ffun:15:10000:67': 95.0,
  'mem:10:20000:58': 60.0,
  'mem:10:50000:94': 964.0,
  'adb:15:40000:84': 127.0,
  'mem_7:10:40000:64': 78.0,
  'mem:10:50000:105': 1444.0,
  'ffun_add:15:50000:86': 1648.0,
  'mem_7:15:50000:72': 175.0,
  'mem:10:30000:20': 8.0,
  'mem_7:10:25000:2': 5.0,
  'ffun_add:10:7500:102': 538.0,
  'mem:10:50000:89': 728.0,
  'mem:15:25000:113': 722.0,
  'ffun:10:5000:61': 69.0,
  'ffun:15:40000:119': 1953.0,
  'mem_7:15:30000:49': 26.0,
  'ffun_add:15:50000:23': 38.0,
  'adb:15:30000:9': 18.0,
  'mem:10:7500:71': 47.0,
  'ffun:15:2000:58': 39.0,
  'mem:15:20000:107': 577.0,
  'adb:10:2000:95': 16.0,
  'mem:10:7500:2': 2.0,
  'mem_7:15:2000:87': 18.0,
  'mem:10:50000:119': 1444.0,
  'ffun_add:15:2000:64': 19.0,
  'hcb:10:80:20': 9.0,
  'mem_40:15:2000:27': 1.0,
  'mem_40:10:25000:98': 722.0,
  'adb:10:25000:120': 353.0,
  'adb:15:40000:0': 23.0,
  'mem_7:15:2000:55': 4.0,
  'adb:10:40000:114': 564.0,
  'ffun:15:2000:98': 126.0,
  'mem_7:10:2000:108': 35.0,
  'adb:10:2000:53': 6.0,
  'mem_7:10:20000:19': 4.0,
  'mem_7:15:30000:92': 344.0,
  'mem_7:15:7500:77': 36.0,
  'ffun_add:15:20000:2': 17.0,
  'mem_40:15:25000:7': 6.0,
  'mem_40:15:40000:15': 8.0,
  'mem_7:15:30000:22': 5.0,
  'adb:10:25000:38': 29.0,
  'mem:15:10000:5': 2.0,
  'mem:10:30000:62': 97.0,
  'mem_7:15:2000:58': 4.0,
  'mem_7:10:30000:12': 5.0,
  'mem_7:10:20000:36': 10.0,
  'ffun:10:50000:93': 2157.0,
  'mem:10:40000:89': 583.0,
  'mem_7:10:25000:52': 42.0,
  'adb:10:7500:94': 56.0,
  'adb:10:50000:95': 376.0,
  'adb:10:7500:87': 38.0,
  'mem_7:10:20000:98': 347.0,
  'adb:10:2000:79': 6.0,
  'mem_40:10:20000:42': 25.0,
  'mem_40:10:25000:90': 365.0,
  'ffun_add:10:7500:30': 14.0,
  'hcb:15:100:46': 13.0,
  'mem:15:2000:84': 22.0,
  'mem_40:15:20000:46': 30.0,
  'ffun:10:25000:3': 42.0,
  'mem_40:10:15000:113': 433.0,
  'ffun:10:15000:79': 264.0,
  'ffun:10:40000:82': 1008.0,
  'ffun:15:2000:102': 126.0,
  'adb:10:25000:43': 37.0,
  'mem_40:15:50000:76': 394.0,
  'adb:15:30000:17': 18.0,
  'adb:10:7500:31': 7.0,
  'ffun_add:15:15000:34': 23.0,
  'adb:10:15000:40': 18.0,
  'ffun:15:40000:33': 60.0,
  'mem_40:10:2000:19': 1.0,
  'ffun:15:40000:62': 208.0,
  'ffun:10:10000:56': 97.0,
  'mem_7:10:40000:82': 264.0,
  'mem_40:15:10000:40': 6.0,
  'mem_7:10:40000:92': 463.0,
  'ffun:15:2000:70': 43.0,
  'hcb:10:80:56': 9.0,
  'mem_7:10:5000:64': 11.0,
  'ffun:10:40000:48': 160.0,
  'ffun:10:40000:64': 276.0,
  'adb:15:25000:33': 19.0,
  'mem_7:15:30000:67': 70.0,
  'adb:10:20000:75': 28.0,
  'ffun_add:15:50000:59': 300.0,
  'adb:10:2000:71': 4.0,
  'adb:15:7500:16': 5.0,
  'ffun:10:50000:33': 74.0,
  'mem:10:25000:22': 8.0,
  'mem:15:25000:94': 479.0,
  'mem_7:10:50000:81': 330.0,
  'hcb:15:150:23': 26.0,
  'ffun_add:15:40000:47': 156.0,
  'mem:10:40000:2': 11.0,
  'ffun:10:40000:13': 48.0,
  'mem:15:40000:6': 8.0,
  'ffun:10:10000:97': 679.0,
  'mem_7:10:25000:35': 8.0,
  'ffun:15:15000:40': 47.0,
  'ffun:10:2000:88': 110.0,
  'ffun:15:20000:10': 38.0,
  'adb:10:10000:35': 10.0,
  'mem_40:10:20000:27': 8.0,
  'ffun_add:10:30000:16': 28.0,
  'mem:10:7500:57': 23.0,
  'ffun_add:10:2000:15': 8.0,
  'mem_40:10:10000:28': 5.0,
  'mem:15:30000:34': 12.0,
  'mem_7:15:5000:85': 31.0,
  'ffun_add:15:50000:109': 3373.0,
  'adb:15:40000:63': 19.0,
  'mem_40:10:40000:86': 583.0,
  'mem:10:40000:7': 11.0,
  'mem_40:10:5000:106': 145.0,
  'mem:10:40000:116': 1154.0,
  'adb:10:20000:117': 282.0,
  'mem:10:5000:3': 2.0,
  'ffun_add:15:25000:13': 20.0,
  'mem_40:15:5000:51': 12.0,
  'mem_40:10:2000:85': 23.0,
  'mem_7:10:10000:99': 174.0,
  'mem:15:15000:65': 41.0,
  'adb:10:10000:45': 16.0,
  'ffun:10:50000:29': 63.0,
  'mem:10:2000:7': 1.0,
  'ffun:10:30000:114': 1940.0,
  'mem_40:15:20000:82': 205.0,
  'adb:10:10000:58': 19.0,
  'mem_40:10:15000:43': 19.0,
  'mem:15:15000:9': 4.0,
  'ffun_add:15:20000:80': 349.0,
  'mem_40:15:2000:19': 1.0,
  'adb:15:15000:101': 212.0,
  'adb:10:25000:96': 353.0,
  'ffun_add:15:40000:61': 255.0,
  'mem:15:40000:1': 8.0,
  'mem:15:7500:50': 12.0,
  'mem_7:10:2000:62': 5.0,
  'mem_7:10:25000:54': 42.0,
  'mem_40:10:20000:120': 577.0,
  'adb:10:10000:22': 8.0,
  'adb:10:2000:35': 2.0,
  'mem_7:15:7500:2': 1.0,
  'adb:15:40000:111': 564.0,
  'mem_40:15:7500:67': 30.0,
  'ffun:10:15000:68': 165.0,
  'ffun_add:15:25000:87': 828.0,
  'adb:10:20000:64': 13.0,
  'mem_40:15:7500:107': 217.0,
  'ffun:10:15000:118': 994.0,
  'mem_40:15:25000:38': 16.0,
  'ffun_add:10:15000:88': 542.0,
  'ffun_add:10:20000:94': 950.0,
  'adb:10:30000:9': 23.0,
  'ffun:10:40000:19': 49.0,
  'mem_40:15:10000:35': 5.0,
  'adb:15:5000:7': 4.0,
  'ffun:15:20000:70': 159.0,
  'adb:10:10000:60': 19.0,
  'mem_7:10:5000:69': 14.0,
  'adb:10:20000:28': 17.0,
  'ffun:15:50000:19': 50.0,
  'mem:15:5000:58': 13.0,
  'mem:10:5000:81': 55.0,
  'mem:10:25000:58': 76.0,
  'mem_7:15:10000:63': 17.0,
  'ffun:15:15000:26': 38.0,
  'mem_7:10:30000:9': 5.0,
  'adb:15:20000:53': 44.0,
  'ffun:10:50000:1': 53.0,
  'mem:15:40000:87': 563.0,
  'dis:15:40000:53': 13.0,
  'ffun:15:30000:8': 42.0,
  'mem_7:10:50000:102': 866.0,
  'mem:10:7500:109': 217.0,
  'ffun:10:2000:63': 52.0,
  'ffun_add:10:5000:87': 188.0,
  'ffun:15:2000:11': 31.0,
  'ffun_add:10:2000:88': 81.0,
  'ffun:15:5000:73': 75.0,
  'mem_7:15:2000:99': 35.0,
  'ffun_add:10:15000:99': 1065.0,
  'mem:15:7500:4': 2.0,
  'adb:15:40000:87': 193.0,
  'ffun_add:10:10000:13': 13.0,
  'mem:10:10000:112': 289.0,
  'adb:15:5000:39': 5.0,
  'mem_40:10:10000:51': 29.0,
  'mem:15:2000:49': 4.0,
  'ffun_add:15:5000:61': 38.0,
  'mem_7:10:10000:119': 174.0,
  'mem:15:50000:20': 11.0,
  'mem_7:10:40000:107': 692.0,
  'adb:15:5000:88': 25.0,
  'adb:15:2000:27': 2.0,
  'mem_7:15:10000:28': 2.0,
  'dis:15:50000:56': 18.0,
  'mem_7:10:40000:18': 7.0,
  'ffun_add:15:20000:64': 131.0,
  'mem_40:15:20000:0': 5.0,
  'adb:10:20000:31': 19.0,
  'mem_40:15:40000:98': 1154.0,
  'mem_40:10:20000:98': 577.0,
  'mem_40:10:2000:114': 59.0,
  'mem_7:15:7500:31': 2.0,
  'ffun:10:40000:52': 261.0,
  'ffun:10:25000:7': 42.0,
  'ffun_add:15:7500:18': 11.0,
  'mem:10:15000:74': 94.0,
  'ffun_add:15:10000:111': 679.0,
  'mem:10:40000:45': 49.0,
  'dis:15:50000:31': 12.0,
  'mem_40:10:30000:34': 16.0,
  'mem_7:15:10000:11': 2.0,
  'dis:10:30000:47': 13.0,
  'mem_7:10:15000:29': 4.0,
  'ffun_add:15:7500:67': 74.0,
  'ffun_add:10:50000:35': 72.0,
  'adb:10:2000:67': 2.0,
  'adb:10:10000:105': 142.0,
  'mem:10:15000:69': 70.0,
  'mem_40:10:50000:109': 1444.0,
  'mem_40:15:30000:40': 18.0,
  'mem:10:50000:74': 310.0,
  'mem_40:15:20000:9': 5.0,
  'mem:10:30000:100': 865.0,
  'mem:15:20000:8': 5.0,
  'ffun_add:15:7500:17': 11.0,
  'ffun:10:7500:34': 38.0,
  'ffun:15:10000:30': 36.0,
  'mem:10:25000:116': 722.0,
  'mem:15:40000:46': 59.0,
  'mem_7:15:40000:63': 65.0,
  'adb:10:25000:40': 29.0,
  'adb:15:5000:101': 71.0,
  'mem:10:7500:14': 2.0,
  'adb:15:7500:115': 107.0,
  'mem:15:50000:36': 30.0,
  'ffun_add:10:40000:114': 2824.0,
  'ffun:10:40000:15': 48.0,
  'mem:10:50000:63': 162.0,
  'ffun:15:5000:64': 52.0,
  'ffun_add:15:2000:15': 7.0,
  'mem_7:15:5000:64': 8.0,
  'mem_40:15:40000:97': 1154.0,
  'ffun:15:7500:19': 33.0,
  'mem:15:10000:70': 40.0,
  'ffun_add:15:40000:82': 962.0,
  'adb:15:20000:85': 64.0,
  'mem_40:15:15000:42': 14.0,
  'mem_7:15:7500:99': 131.0,
  'mem:10:20000:1': 6.0,
  'mem_7:10:20000:3': 4.0,
  'adb:10:25000:19': 19.0,
  'mem:15:5000:106': 145.0,
  'mem:10:7500:86': 109.0,
  'adb:15:7500:7': 5.0,
  'mem_7:10:40000:15': 7.0,
  'ffun_add:10:2000:70': 30.0,
  'hcb:10:150:38': 32.0,
  'adb:15:7500:37': 7.0,
  'ffun_add:10:5000:69': 63.0,
  'mem_7:15:2000:36': 1.0,
  'mem:10:7500:77': 62.0,
  'adb:15:40000:27': 26.0,
  'mem_7:15:25000:79': 119.0,
  'mem_7:10:40000:17': 7.0,
  'mem_7:10:50000:11': 8.0,
  'ffun_add:10:10000:73': 154.0,
  'mem_7:15:30000:6': 4.0,
  'mem_40:15:7500:46': 12.0,
  'ffun_add:10:50000:57': 378.0,
  'mem_7:15:5000:37': 2.0,
  'ffun:15:20000:71': 208.0,
  'ffun:10:50000:40': 96.0,
  'ffun:10:2000:70': 56.0,
  'ffun:10:40000:22': 52.0,
  'mem:10:50000:43': 61.0,
  'mem:15:5000:83': 52.0,
  'ffun_add:10:40000:75': 593.0,
  'ffun_add:15:25000:83': 604.0,
  'mem_40:10:2000:108': 59.0,
  'mem_40:10:15000:44': 19.0,
  'dis:15:40000:49': 12.0,
  'mem_40:10:40000:108': 1154.0,
  'hcb:15:120:49': 18.0,
  'ffun:15:2000:42': 34.0,
  'mem_7:15:2000:26': 1.0,
  'adb:10:7500:76': 19.0,
  'mem:15:7500:23': 2.0,
  'ffun_add:15:20000:26': 21.0,
  'mem_7:10:30000:69': 83.0,
  'ffun:10:25000:68': 250.0,
  'mem:10:2000:116': 59.0,
  'mem_40:10:5000:34': 4.0,
  'mem:15:40000:41': 38.0,
  'ffun:15:2000:75': 48.0,
  'adb:15:2000:93': 16.0,
  'ffun_add:15:2000:106': 142.0,
  'mem_40:10:2000:35': 1.0,
  'mem_40:15:25000:109': 722.0,
  'mem:10:50000:58': 150.0,
  'ffun:10:15000:77': 264.0,
  'mem_40:10:50000:69': 230.0,
  'ffun:15:40000:28': 52.0,
  'mem_40:15:2000:16': 1.0,
  'ffun:10:30000:64': 217.0,
  'ffun_add:15:50000:120': 3373.0,
  'adb:15:10000:24': 6.0,
  'mem_7:10:30000:8': 5.0,
  'ffun_add:10:25000:85': 679.0,
  'mem:10:10000:32': 6.0,
  'mem_7:15:7500:66': 18.0,
  'adb:10:50000:96': 706.0,
  'ffun:15:40000:9': 45.0,
  'mem:10:25000:66': 115.0,
  'mem:15:5000:89': 71.0,
  'ffun_add:15:30000:86': 991.0,
  'ffun:10:25000:101': 1626.0,
  'mem:15:5000:20': 1.0,
  'ffun_add:15:25000:61': 161.0,
  'mem_7:15:5000:105': 88.0,
  'mem_40:15:10000:116': 289.0,
  'mem_7:15:5000:71': 18.0,
  'ffun_add:10:2000:38': 11.0,
  'mem:15:5000:41': 5.0,
  'mem:10:10000:54': 29.0,
  'ffun:15:5000:42': 39.0,
  'mem:15:40000:85': 410.0,
  'mem_40:15:30000:14': 7.0,
  'mem_7:15:25000:98': 433.0,
  'mem_7:15:2000:34': 1.0,
  'ffun:15:20000:36': 52.0,
  'ffun_add:15:20000:45': 55.0,
  'mem_40:15:50000:109': 1444.0,
  'adb:15:7500:101': 107.0,
  'mem_40:15:30000:103': 865.0,
  'mem:15:20000:67': 78.0,
  'mem:10:7500:64': 25.0,
  'mem:10:20000:67': 92.0,
  'mem:15:5000:94': 96.0,
  'mem_40:15:25000:11': 6.0,
  'mem:10:40000:75': 247.0,
  'mem_7:15:7500:70': 18.0,
  'dis:10:10000:48': 11.0,
  'adb:15:10000:116': 142.0,
  'mem_7:15:2000:117': 35.0,
  'ffun:10:30000:66': 292.0,
  'ffun_add:10:50000:92': 2359.0,
  'ffun_add:15:7500:56': 51.0,
  'mem_7:15:20000:65': 32.0,
  'mem_40:15:5000:94': 96.0,
  'mem_40:15:40000:104': 1154.0,
  'ffun:10:15000:4': 38.0,
  'hcb:15:50:58': 3.0,
  'ffun:15:40000:107': 1953.0,
  'mem:10:20000:24': 7.0,
  'adb:10:50000:74': 67.0,
  'mem_7:10:10000:98': 174.0,
  'ffun_add:15:25000:71': 318.0,
  'mem_40:15:10000:113': 289.0,
  'mem:10:30000:5': 8.0,
  'mem_40:15:30000:71': 174.0,
  'ffun_add:10:30000:95': 1418.0,
  'mem:15:5000:65': 14.0,
  'ffun:15:20000:0': 38.0,
  'ffun_add:10:50000:60': 378.0,
  'mem_40:15:2000:15': 1.0,
  'ffun:10:15000:96': 994.0,
  'mem_7:10:50000:112': 866.0,
  'adb:15:10000:94': 74.0,
  'mem:15:7500:79': 55.0,
  'mem:10:7500:29': 4.0,
  'mem_7:15:5000:61': 8.0,
  'mem:10:30000:34': 16.0,
  'ffun_add:15:20000:95': 898.0,
  'mem_40:15:30000:61': 80.0,
  'adb:15:50000:67': 23.0,
  'mem:15:15000:33': 6.0,
  'ffun:15:15000:21': 37.0,
  'mem_40:15:15000:51': 34.0,
  'adb:10:25000:97': 353.0,
  'mem_7:10:30000:45': 23.0,
  'ffun_add:10:7500:15': 12.0,
  'adb:15:10000:74': 12.0,
  'hcb:10:100:48': 15.0,
  'dis:15:40000:27': 12.0,
  'mem_7:10:25000:18': 5.0,
  'ffun:15:25000:0': 40.0,
  'adb:15:25000:38': 23.0,
  'mem_7:15:7500:32': 2.0,
  'mem_7:10:25000:83': 166.0,
  'mem_7:10:10000:7': 2.0,
  'mem:15:25000:89': 353.0,
  'adb:10:7500:64': 5.0,
  'dis:10:50000:26': 15.0,
  'dis:15:10000:46': 9.0,
  'mem_40:10:30000:97': 865.0,
  'ffun:10:40000:53': 261.0,
  'mem:15:5000:16': 1.0,
  'mem_7:15:10000:3': 1.0,
  'ffun_add:10:5000:73': 82.0,
  'mem_7:10:15000:58': 28.0,
  'adb:10:7500:89': 38.0,
  'ffun:15:50000:110': 2435.0,
  'ffun_add:15:50000:56': 300.0,
  'ffun:10:50000:46': 191.0,
  'ffun_add:15:20000:92': 898.0,
  'ffun_add:15:10000:73': 131.0,
  'ffun:10:20000:20': 41.0,
  'ffun_add:10:2000:31': 9.0,
  'dis:10:20000:22': 12.0,
  'ffun:15:50000:7': 49.0,
  'ffun_add:10:30000:62': 237.0,
  'mem_40:15:30000:0': 6.0,
  'ffun_add:15:30000:18': 24.0,
  'mem_7:10:2000:47': 2.0,
  'ffun_add:10:7500:56': 65.0,
  'ffun:10:50000:120': 3205.0,
  'ffun_add:10:2000:64': 24.0,
  'ffun:10:5000:10': 33.0,
  'mem:10:7500:52': 22.0,
  'mem_7:10:50000:13': 8.0,
  'adb:15:25000:102': 353.0,
  'dis:15:10000:45': 9.0,
  'mem_40:15:20000:87': 282.0,
  'adb:15:5000:34': 5.0,
  'ffun:15:15000:79': 214.0,
  'mem_7:15:20000:58': 30.0,
  'adb:15:10000:111': 142.0,
  'mem_7:15:7500:27': 2.0,
  'mem:10:10000:100': 289.0,
  'ffun_add:15:5000:102': 343.0,
  'adb:10:10000:2': 8.0,
  'mem:15:5000:92': 96.0,
  'mem_40:15:15000:36': 10.0,
  'adb:10:30000:61': 19.0,
  'mem:10:50000:86': 728.0,
  'mem:15:5000:43': 5.0,
  'mem_7:10:40000:56': 72.0,
  'hcb:10:150:53': 33.0,
  'mem_40:15:5000:2': 1.0,
  'ffun:15:10000:96': 511.0,
  'mem_7:10:40000:30': 10.0,
  'ffun:15:40000:49': 137.0,
  'ffun:15:30000:98': 1472.0,
  'mem_7:10:50000:68': 138.0,
  'mem:15:25000:72': 134.0,
  'ffun_add:10:50000:40': 106.0,
  'adb:10:40000:78': 97.0,
  'mem:10:5000:13': 2.0,
  'mem_7:15:15000:114': 260.0,
  'mem_40:10:2000:86': 30.0,
  'mem:10:2000:107': 59.0,
  'ffun_add:10:2000:61': 24.0,
  'hcb:15:80:21': 7.0,
  'dis:10:30000:42': 13.0,
  'mem_40:10:30000:56': 90.0,
  'ffun:15:30000:6': 42.0,
  'ffun_add:10:40000:39': 85.0,
  'dis:10:50000:44': 15.0,
  'ffun_add:15:5000:59': 35.0,
  'adb:15:2000:103': 29.0,
  'adb:15:15000:73': 17.0,
  'mem_40:15:40000:34': 17.0,
  'mem_7:10:40000:113': 692.0,
  'ffun:10:20000:15': 41.0,
  'mem_7:10:40000:119': 692.0,
  'hcb:10:120:41': 21.0,
  'mem_40:10:40000:6': 11.0,
  'ffun_add:15:7500:14': 10.0,
  'hcb:10:120:33': 20.0,
  'mem_40:15:10000:85': 103.0,
  'ffun:10:30000:69': 292.0,
  'ffun:15:10000:24': 35.0,
  'adb:10:7500:50': 16.0,
  'ffun:15:30000:79': 397.0,
  'adb:15:20000:23': 12.0,
  'mem_7:15:30000:3': 4.0,
  'ffun:10:50000:110': 3205.0,
  'ffun:10:30000:75': 378.0,
  'ffun_add:15:2000:84': 55.0,
  'adb:15:30000:16': 18.0,
  'mem_7:10:50000:79': 246.0,
  'ffun_add:10:25000:97': 1769.0,
  'mem:10:20000:76': 164.0,
  'hcb:10:50:35': 3.0,
  'ffun_add:15:5000:4': 9.0,
  'adb:15:30000:30': 19.0,
  'ffun_add:10:50000:37': 106.0,
  'mem_40:10:30000:103': 865.0,
  'mem:10:2000:32': 1.0,
  'mem:10:40000:78': 328.0,
  'mem_40:10:15000:93': 289.0,
  'ffun:15:40000:57': 197.0,
  'ffun:10:7500:6': 34.0,
  'mem_7:15:40000:82': 246.0,
  'mem:15:7500:85': 78.0,
  'mem_7:10:15000:1': 2.0,
  'ffun_add:10:15000:70': 171.0,
  'mem:15:7500:74': 41.0,
  'ffun_add:15:5000:111': 343.0,
  'ffun_add:10:2000:36': 11.0,
  'mem_7:10:30000:1': 5.0,
  'mem:10:30000:3': 8.0,
  'ffun_add:10:25000:2': 23.0,
  'mem_7:15:2000:101': 35.0,
  'mem:10:10000:77': 83.0,
  'adb:15:25000:48': 40.0,
  'mem_40:15:7500:90': 106.0,
  'adb:10:50000:68': 31.0,
  'ffun_add:10:7500:95': 362.0,
  'dis:10:40000:51': 16.0,
  'mem_7:10:30000:35': 10.0,
  'mem:10:50000:61': 162.0,
  'ffun_add:15:25000:2': 20.0,
  'ffun_add:15:20000:46': 82.0,
  'adb:15:15000:41': 18.0,
  'ffun_add:10:5000:109': 362.0,
  'mem_7:15:50000:108': 866.0,
  'hcb:10:150:34': 31.0,
  'adb:15:5000:14': 4.0,
  'ffun:10:2000:80': 69.0,
  'mem_40:10:15000:2': 5.0,
  'hcb:10:120:57': 20.0,
  'ffun_add:10:40000:67': 442.0,
  'adb:10:25000:52': 68.0,
  'mem_7:10:5000:94': 59.0,
  'ffun:15:25000:1': 40.0,
  'ffun_add:10:7500:70': 90.0,
  'mem_40:10:2000:27': 1.0,
  'ffun_add:10:30000:8': 27.0,
  'mem_7:15:10000:1': 1.0,
  'mem_40:15:15000:60': 37.0,
  'ffun:15:25000:27': 44.0,
  'ffun_add:15:10000:77': 178.0,
  'hcb:15:100:52': 12.0,
  'ffun:15:30000:90': 733.0,
  'mem:15:25000:55': 55.0,
  'mem_40:15:50000:40': 30.0,
  'dis:10:40000:39': 14.0,
  'adb:10:5000:35': 5.0,
  'ffun:15:7500:33': 36.0,
  'mem_7:15:25000:57': 37.0,
  'mem_40:10:20000:8': 6.0,
  'ffun_add:15:30000:11': 23.0,
  'mem:10:2000:98': 59.0,
  'mem_7:15:30000:32': 7.0,
  'mem:15:40000:112': 1154.0,
  'mem:15:50000:87': 704.0,
  'ffun:10:7500:23': 35.0,
  'mem:10:5000:21': 2.0,
  'mem_40:10:20000:22': 7.0,
  'ffun_add:15:7500:75': 100.0,
  'adb:10:50000:26': 41.0,
  'mem_7:15:10000:82': 62.0,
  'mem:15:40000:84': 410.0,
  'ffun:15:20000:111': 992.0,
  'ffun:10:5000:92': 257.0,
  'adb:15:20000:72': 23.0,
  'mem_40:10:2000:1': 1.0,
  'adb:15:5000:21': 4.0,
  'ffun_add:15:2000:115': 142.0,
  'mem_40:10:40000:33': 20.0,
  'adb:10:10000:63': 7.0,
  'ffun:15:7500:6': 33.0,
  'adb:15:20000:4': 12.0,
  'adb:15:15000:99': 212.0,
  'mem_40:15:20000:1': 5.0,
  'adb:15:40000:56': 60.0,
  'adb:15:40000:53': 88.0,
  'ffun:15:50000:103': 2435.0,
  'ffun:15:30000:30': 47.0,
  'dis:15:10000:43': 9.0,
  'ffun_add:10:20000:116': 1416.0,
  'ffun:10:5000:54': 67.0,
  'mem:15:20000:6': 5.0,
  'adb:10:2000:112': 29.0,
  'ffun_add:15:10000:83': 245.0,
  'adb:10:2000:41': 4.0,
  'mem_7:10:20000:53': 34.0,
  'mem:15:7500:40': 5.0,
  'mem_40:15:10000:18': 2.0,
  'ffun:10:15000:55': 122.0,
  'ffun_add:15:5000:46': 26.0,
  'adb:10:40000:54': 110.0,
  'mem:10:5000:99': 145.0,
  'ffun_add:10:50000:91': 2359.0,
  'mem_40:15:10000:63': 28.0,
  'adb:10:40000:48': 79.0,
  'ffun_add:15:5000:13': 9.0,
  'mem_40:10:2000:103': 59.0,
  'mem_40:10:15000:0': 5.0,
  'adb:10:50000:112': 706.0,
  'mem_40:15:20000:75': 116.0,
  'ffun:10:30000:119': 1940.0,
  'mem:10:50000:12': 13.0,
  'hcb:10:100:44': 14.0,
  'mem_7:15:7500:67': 18.0,
  'ffun_add:15:50000:8': 34.0,
  'mem_40:15:15000:40': 10.0,
  'mem_7:15:25000:14': 4.0,
  'mem:15:40000:81': 410.0,
  'mem_40:15:30000:114': 865.0,
  'ffun:10:30000:60': 211.0,
  'mem:15:25000:26': 7.0,
  'mem:10:30000:29': 12.0,
  'mem:10:20000:56': 60.0,
  'adb:10:7500:55': 22.0,
  'hcb:15:150:18': 26.0,
  'ffun_add:10:5000:115': 362.0,
  'mem:10:40000:5': 11.0,
  'adb:10:10000:31': 10.0,
  'adb:15:50000:7': 29.0,
  'mem_40:15:15000:44': 14.0,
  'mem_40:15:15000:18': 4.0,
  'ffun_add:15:5000:16': 10.0,
  'mem:15:40000:75': 214.0,
  'adb:15:7500:15': 5.0,
  'mem:15:7500:8': 2.0,
  'mem_7:15:15000:89': 127.0,
  'mem:10:2000:45': 4.0,
  'mem:15:7500:110': 217.0,
  'adb:10:50000:2': 38.0,
  'ffun:15:7500:14': 33.0,
  'ffun:15:40000:4': 46.0,
  'ffun:15:10000:58': 72.0,
  'mem:15:7500:106': 217.0,
  'ffun_add:15:20000:16': 18.0,
  'hcb:15:80:20': 7.0,
  'ffun_add:10:50000:100': 3527.0,
  'mem_40:10:50000:10': 13.0,
  'ffun_add:10:10000:60': 83.0,
  'ffun_add:10:20000:23': 23.0,
  'adb:10:15000:27': 13.0,
  'mem_7:15:25000:89': 211.0,
  'ffun:15:7500:77': 122.0,
  'ffun_add:15:30000:107': 2025.0,
  'mem_7:15:50000:52': 66.0,
  'ffun:15:30000:120': 1472.0,
  'mem_7:10:50000:25': 10.0,
  'ffun_add:10:20000:49': 102.0,
  'ffun:10:5000:28': 35.0,
  'ffun:15:15000:54': 90.0,
  'ffun:15:50000:58': 239.0,
  'adb:10:25000:68': 16.0,
  'ffun_add:15:7500:119': 511.0,
  'mem:10:40000:8': 11.0,
  'mem_7:10:25000:0': 5.0,
  'mem_40:10:25000:91': 482.0,
  'mem_7:10:7500:19': 2.0,
  'adb:10:50000:80': 122.0,
  'mem_40:15:7500:71': 44.0,
  'ffun_add:10:30000:0': 27.0,
  'ffun:15:15000:52': 90.0,
  'ffun_add:10:40000:66': 442.0,
  'mem_7:15:40000:61': 65.0,
  'ffun_add:15:7500:110': 511.0,
  'adb:15:2000:73': 2.0,
  'adb:15:25000:44': 29.0,
  'mem:15:5000:12': 1.0,
  'mem:15:20000:74': 107.0,
  'dis:10:30000:49': 13.0,
  'mem_40:15:10000:4': 2.0,
  'adb:10:15000:35': 14.0,
  'ffun_add:15:25000:60': 153.0,
  'mem:15:30000:106': 865.0,
  'mem_40:10:7500:48': 14.0,
  'mem_40:15:25000:54': 55.0,
  'adb:15:40000:13': 23.0,
  'mem_7:15:5000:56': 8.0,
  'ffun:15:5000:89': 148.0,
  'mem_7:15:7500:94': 86.0,
  'mem_40:15:7500:82': 78.0,
  'mem_40:15:5000:7': 1.0,
  'adb:15:25000:91': 186.0,
  'mem:15:40000:26': 12.0,
  'mem_40:15:50000:36': 30.0,
  'ffun_add:15:2000:72': 31.0,
  'adb:10:10000:40': 12.0,
  'mem_40:10:2000:26': 1.0,
  'ffun_add:10:7500:55': 62.0,
  'ffun:15:50000:77': 642.0,
  'mem_7:10:20000:72': 74.0,
  'mem_40:10:30000:15': 8.0,
  'mem_7:10:2000:65': 5.0,
  'ffun_add:10:5000:63': 46.0,
  'mem:10:25000:87': 365.0,
  'mem:15:15000:108': 433.0,
  'mem:15:40000:101': 1154.0,
  'mem_7:15:15000:106': 260.0,
  'ffun_add:10:25000:76': 492.0,
  'ffun:10:5000:63': 69.0,
  'ffun:10:10000:5': 36.0,
  'mem:15:40000:109': 1154.0,
  'dis:10:40000:22': 13.0,
  'adb:10:50000:46': 98.0,
  'ffun:10:2000:33': 34.0,
  'mem_7:15:15000:65': 24.0,
  'mem:10:15000:62': 49.0,
  'adb:10:20000:37': 23.0,
  'ffun_add:10:25000:84': 679.0,
  'mem_40:10:10000:93': 193.0,
  'mem_7:10:30000:19': 6.0,
  'mem:15:25000:23': 6.0,
  'ffun:15:25000:63': 141.0,
  'ffun:10:7500:9': 34.0,
  'ffun_add:10:15000:77': 299.0,
  'mem:15:50000:79': 367.0,
  'hcb:10:100:41': 14.0,
  'hcb:10:120:26': 20.0,
  'mem_7:10:25000:33': 8.0,
  'dis:15:50000:52': 14.0,
  'mem_40:15:2000:109': 59.0,
  'mem_40:15:5000:5': 1.0,
  'adb:10:20000:15': 16.0,
  'mem:15:2000:67': 8.0,
  'mem_7:10:2000:20': 1.0,
  'mem_7:15:25000:27': 5.0,
  'mem_7:15:2000:75': 7.0,
  'mem:15:10000:80': 74.0,
  'mem:10:50000:25': 16.0,
  'adb:10:40000:4': 31.0,
  'adb:10:15000:115': 212.0,
  'mem:15:7500:91': 144.0,
  'adb:15:10000:72': 12.0,
  'mem_40:10:25000:43': 31.0,
  'mem_7:15:25000:81': 155.0,
  'adb:15:7500:80': 17.0,
  'ffun:15:5000:3': 32.0,
  'mem:10:50000:35': 26.0,
  'mem:15:20000:91': 383.0,
  'adb:10:15000:15': 12.0,
  'adb:10:30000:118': 424.0,
  'ffun:15:30000:63': 163.0,
  'mem:15:25000:34': 11.0,
  'mem_40:10:50000:92': 964.0,
  'dis:10:40000:35': 14.0,
  'ffun:10:30000:25': 47.0,
  'mem:10:7500:104': 217.0,
  'ffun:10:5000:114': 363.0,
  'mem_40:10:15000:34': 8.0,
  'ffun:10:15000:90': 525.0,
  'mem:15:20000:32': 8.0,
  'ffun:10:40000:84': 1008.0,
  'ffun:10:25000:93': 1102.0,
  'mem:15:20000:54': 44.0,
  'mem:15:10000:16': 2.0,
  'mem:15:50000:88': 704.0,
  'ffun_add:10:25000:111': 1769.0,
  'ffun_add:15:5000:51': 34.0,
  'mem_40:15:15000:43': 14.0,
  'adb:15:5000:1': 4.0,
  'adb:15:50000:53': 109.0,
  'ffun_add:10:40000:13': 34.0,
  'ffun:10:25000:46': 112.0,
  'ffun_add:10:10000:114': 713.0,
  'mem_7:15:10000:41': 6.0,
  'ffun_add:10:2000:59': 23.0,
  'mem_7:10:2000:15': 1.0,
  'mem:10:50000:114': 1444.0,
  'mem:10:25000:29': 10.0,
  'mem_40:15:7500:57': 19.0,
  'mem:15:15000:98': 433.0,
  'ffun:15:15000:74': 164.0,
  'ffun_add:15:7500:97': 511.0,
  'ffun:15:40000:93': 1304.0,
  'mem:10:40000:42': 49.0,
  'ffun:15:50000:36': 85.0,
  'mem:15:2000:63': 6.0,
  'mem_40:10:10000:58': 30.0,
  'mem_7:10:2000:84': 13.0,
  'mem_40:10:25000:39': 19.0,
  'mem_40:15:40000:94': 764.0,
  'mem_7:10:10000:57': 18.0,
  'ffun_add:15:7500:39': 19.0,
  'mem_40:15:15000:119': 433.0,
  'mem_40:10:25000:7': 7.0,
  'mem_7:15:2000:103': 35.0,
  'ffun:15:50000:61': 252.0,
  'adb:15:5000:45': 6.0,
  'ffun_add:15:10000:5': 12.0,
  'dis:10:30000:20': 13.0,
  'adb:10:25000:63': 16.0,
  'ffun_add:10:2000:40': 11.0,
  'ffun:10:7500:118': 522.0,
  'adb:10:15000:4': 12.0,
  'mem:10:25000:54': 70.0,
  'ffun_add:15:10000:16': 13.0,
  'ffun_add:15:20000:27': 21.0,
  'mem:10:7500:98': 217.0,
  'mem_7:10:20000:75': 74.0,
  'mem:10:20000:38': 16.0,
  'adb:15:2000:34': 2.0,
  'mem_7:15:5000:24': 1.0,
  'ffun_add:10:30000:48': 149.0,
  'adb:15:25000:96': 353.0,
  'mem_40:15:40000:9': 8.0,
  'mem_40:15:40000:105': 1154.0,
  'ffun:10:20000:47': 97.0,
  'adb:10:40000:70': 25.0,
  'adb:15:20000:27': 13.0,
  'ffun_add:15:5000:68': 52.0,
  'ffun:15:5000:63': 52.0,
  'ffun:10:25000:100': 1626.0,
  'adb:15:50000:6': 29.0,
  'ffun:10:2000:45': 38.0,
  'adb:10:20000:5': 16.0,
  'ffun:10:40000:69': 375.0,
  'mem_7:15:10000:78': 48.0,
  'ffun:15:7500:119': 391.0,
  'ffun_add:10:5000:62': 46.0,
  'adb:15:40000:9': 23.0,
  'ffun:15:20000:75': 208.0,
  'ffun:10:20000:32': 49.0,
  'ffun:10:25000:114': 1626.0,
  'mem:10:20000:7': 6.0,
  'ffun_add:10:10000:2': 13.0,
  'ffun:10:20000:24': 41.0,
  'mem_7:15:40000:49': 35.0,
  'mem_7:15:20000:109': 347.0,
  'mem_40:10:5000:79': 42.0,
  'mem:10:5000:2': 2.0,
  'ffun_add:15:10000:87': 335.0,
  'ffun:10:7500:53': 81.0,
  'mem:10:30000:88': 437.0,
  'mem:15:5000:105': 145.0,
  'mem:10:30000:77': 246.0,
  'mem:15:25000:69': 97.0,
  'mem_7:10:30000:106': 520.0,
  'adb:10:50000:107': 706.0,
  'mem:10:50000:67': 230.0,
  'mem_7:15:15000:64': 24.0,
  'ffun:15:30000:65': 163.0,
  'adb:15:50000:37': 44.0,
  'adb:15:30000:100': 424.0,
  'ffun:10:20000:2': 40.0,
  'ffun:10:30000:105': 1940.0,
  'mem_7:15:40000:50': 35.0,
  'mem_40:15:2000:83': 22.0,
  'ffun_add:15:10000:65': 69.0,
  'ffun:15:15000:57': 93.0,
  'ffun_add:10:20000:55': 151.0,
  'adb:10:15000:1': 12.0,
  'mem_40:10:20000:90': 292.0,
  'ffun:15:50000:69': 352.0,
  'ffun:15:30000:75': 297.0,
  'ffun:10:7500:80': 152.0,
  'mem_7:15:25000:41': 14.0,
  'ffun_add:15:20000:109': 1353.0,
  'mem:15:25000:25': 6.0,
  'mem:10:15000:26': 6.0,
  'mem:15:10000:34': 5.0,
  'mem:10:5000:60': 16.0,
  'mem:10:40000:82': 439.0,
  'mem:10:5000:98': 145.0,
  'adb:10:7500:27': 7.0,
  'mem:10:7500:7': 2.0,
  'mem:10:7500:73': 47.0,
  'mem_7:10:20000:59': 36.0,
  'ffun_add:10:40000:68': 442.0,
  'adb:15:15000:75': 17.0,
  'mem_40:15:50000:12': 11.0,
  'ffun:15:40000:82': 713.0,
  'ffun_add:15:20000:29': 21.0,
  'dis:15:30000:22': 11.0,
  'ffun:15:40000:105': 1953.0,
  'ffun:10:15000:31': 44.0,
  'mem_40:10:2000:115': 59.0,
  'ffun:15:15000:45': 56.0,
  'mem_7:10:20000:33': 7.0,
  'ffun_add:10:5000:47': 31.0,
  'adb:10:15000:119': 212.0,
  'ffun:15:50000:78': 642.0,
  'mem_7:10:30000:2': 5.0,
  'mem:15:30000:109': 865.0,
  'mem_7:15:50000:48': 44.0,
  'mem_7:10:5000:28': 1.0,
  'mem:15:15000:57': 37.0,
  'ffun_add:15:7500:94': 341.0,
  'ffun_add:10:15000:52': 116.0,
  'dis:10:30000:54': 15.0,
  'mem_40:10:2000:34': 1.0,
  'mem_40:10:30000:52': 84.0,
  'mem_40:10:2000:37': 2.0,
  'ffun:15:40000:32': 60.0,
  'dis:15:10000:51': 10.0,
  'ffun:10:15000:19': 39.0,
  'ffun:15:2000:93': 94.0,
  'mem_7:15:5000:102': 88.0,
  'ffun_add:10:20000:12': 20.0,
  'ffun:10:50000:13': 53.0,
  'ffun_add:10:2000:85': 63.0,
  'ffun:15:25000:10': 40.0,
  'adb:10:30000:104': 424.0,
  'mem_7:10:15000:60': 28.0,
  'ffun:15:50000:85': 884.0,
  'mem_40:10:40000:87': 583.0,
  'mem_40:15:30000:28': 10.0,
  'adb:15:2000:45': 2.0,
  'mem:15:50000:80': 367.0,
  'ffun:10:2000:95': 131.0,
  'adb:10:15000:55': 42.0,
  'mem_40:15:50000:60': 121.0,
  'ffun_add:10:5000:30': 12.0,
  'adb:15:10000:95': 74.0,
  'mem_7:10:40000:81': 264.0,
  'mem:10:40000:90': 583.0,
  'adb:15:10000:107': 142.0,
  'ffun:15:30000:19': 42.0,
  'mem:15:2000:61': 6.0,
  'ffun:10:7500:30': 36.0,
  'mem_7:15:30000:69': 70.0,
  'mem_40:10:20000:4': 6.0,
  'ffun:15:5000:109': 271.0,
  'ffun_add:15:30000:19': 24.0,
  'ffun:10:25000:61': 187.0,
  'ffun_add:10:5000:99': 362.0,
  'ffun_add:10:2000:105': 151.0,
  'mem_40:10:7500:96': 217.0,
  'mem:15:2000:117': 59.0,
  'mem_40:15:20000:112': 577.0,
  'ffun:15:15000:15': 36.0,
  'mem_40:15:30000:31': 12.0,
  'mem_7:15:20000:21': 4.0,
  'dis:10:30000:30': 13.0,
  'adb:15:5000:23': 4.0,
  'mem:15:40000:118': 1154.0,
  'ffun:10:7500:106': 522.0,
  'ffun:15:25000:5': 40.0,
  'mem_7:15:50000:9': 6.0,
  'ffun:10:50000:4': 53.0,
  'mem_40:15:40000:119': 1154.0,
  'ffun_add:10:30000:38': 66.0,
  'ffun_add:15:7500:59': 51.0,
  'ffun:15:40000:39': 74.0,
  'mem_7:10:2000:7': 1.0,
  'adb:15:25000:75': 29.0,
  'adb:10:40000:108': 564.0,
  'hcb:15:150:31': 27.0,
  'mem_7:15:7500:11': 1.0,
  'mem:10:20000:69': 92.0,
  'mem:10:20000:53': 56.0,
  'adb:10:10000:87': 50.0,
  'adb:15:2000:86': 11.0,
  'mem_7:15:10000:71': 36.0,
  'mem_40:10:5000:12': 2.0,
  'mem:10:20000:54': 56.0,
  'mem_7:10:25000:79': 124.0,
  'ffun_add:10:50000:111': 3527.0,
  'adb:15:25000:10': 14.0,
  'mem:10:15000:2': 5.0,
  'mem_7:15:10000:100': 174.0,
  'mem_40:10:7500:100': 217.0,
  'mem_40:15:40000:66': 155.0,
  'adb:10:25000:31': 24.0,
  'adb:15:7500:75': 8.0,
  'mem_40:15:20000:77': 157.0,
  'mem_40:15:7500:120': 217.0,
  'dis:15:10000:30': 9.0,
  'adb:15:30000:33': 23.0,
  'mem_7:10:10000:14': 2.0,
  'ffun:10:20000:4': 40.0,
  'mem_7:10:15000:52': 25.0,
  'adb:15:2000:36': 2.0,
  'ffun_add:10:50000:85': 1348.0,
  'adb:15:20000:18': 12.0,
  'mem_40:10:20000:37': 16.0,
  'dis:10:30000:41': 13.0,
  'mem_40:15:50000:50': 73.0,
  'adb:15:40000:49': 62.0,
  'mem:15:50000:58': 121.0,
  'mem:15:25000:109': 722.0,
  'ffun_add:15:40000:36': 68.0,
  'adb:10:40000:116': 564.0,
  'dis:10:20000:42': 12.0,
  'ffun_add:10:7500:113': 538.0,
  'ffun_add:15:40000:18': 30.0,
  'mem_7:15:25000:43': 14.0,
  'mem:10:5000:68': 24.0,
  'ffun_add:10:2000:17': 9.0,
  'adb:15:10000:109': 142.0,
  'mem_7:10:40000:58': 72.0,
  'mem_40:15:20000:113': 577.0,
  'ffun_add:10:20000:80': 395.0,
  'mem_40:10:2000:7': 1.0,
  'ffun:10:5000:17': 33.0,
  'adb:15:40000:29': 26.0,
  'mem_40:10:5000:78': 42.0,
  'ffun:10:5000:18': 33.0,
  'ffun_add:10:7500:108': 538.0,
  'mem_40:15:30000:1': 6.0,
  'ffun:15:5000:65': 52.0,
  'adb:15:15000:47': 24.0,
  'mem:10:2000:9': 1.0,
  'mem_40:15:5000:73': 30.0,
  'mem_7:15:15000:113': 260.0,
  'mem:10:25000:60': 76.0,
  'mem:10:5000:103': 145.0,
  'hcb:15:50:37': 3.0,
  'ffun:10:10000:107': 679.0,
  'mem_7:15:5000:74': 18.0,
  'mem:15:20000:64': 54.0,
  'mem_7:10:10000:38': 5.0,
  'adb:15:30000:2': 18.0,
  'ffun:10:20000:27': 44.0,
  'adb:15:40000:17': 23.0,
  'ffun_add:15:25000:25': 23.0,
  'ffun:10:15000:103': 994.0,
  'mem_40:10:20000:31': 11.0,
  'mem:15:25000:35': 11.0,
  'ffun:10:2000:23': 33.0,
  'adb:15:25000:49': 40.0,
  'mem:10:30000:97': 865.0,
  'ffun:15:15000:28': 38.0,
  'mem:15:25000:48': 37.0,
  'mem_40:15:15000:13': 4.0,
  'mem:10:7500:105': 217.0,
  'mem_40:15:25000:28': 7.0,
  'mem_7:15:2000:85': 13.0,
  'ffun_add:15:25000:49': 100.0,
  'ffun:15:25000:77': 336.0,
  'ffun_add:15:7500:90': 252.0,
  'adb:10:30000:54': 83.0,
  'mem:15:2000:62': 6.0,
  'hcb:10:50:32': 3.0,
  'mem_40:15:15000:101': 433.0,
  'ffun:10:7500:83': 227.0,
  'mem:10:2000:61': 7.0,
  'mem_7:15:10000:118': 174.0,
  'adb:10:7500:108': 107.0,
  'ffun_add:10:50000:9': 39.0,
  'ffun_add:10:40000:42': 132.0,
  'mem_40:15:30000:55': 66.0,
  'mem_40:15:30000:111': 865.0,
  'mem:15:10000:62': 28.0,
  'adb:15:10000:3': 6.0,
  'mem_40:10:40000:74': 247.0,
  'mem:10:50000:91': 964.0,
  'mem:10:50000:45': 61.0,
  'mem:10:10000:89': 146.0,
  'ffun_add:15:5000:34': 12.0,
  'ffun_add:15:2000:49': 14.0,
  'ffun:10:40000:49': 160.0,
  'dis:10:40000:25': 13.0,
  'mem_7:10:25000:84': 166.0,
  'mem:10:25000:14': 7.0,
  'ffun_add:15:5000:53': 34.0,
  'mem_40:10:40000:16': 12.0,
  'hcb:15:80:58': 7.0,
  'mem_40:10:25000:36': 19.0,
  'ffun_add:15:15000:18': 16.0,
  'ffun_add:15:2000:71': 31.0,
  'ffun_add:10:20000:96': 1416.0,
  'adb:15:15000:33': 12.0,
  'adb:15:10000:50': 16.0,
  'ffun_add:15:10000:96': 679.0,
  'mem:15:20000:57': 49.0,
  'ffun_add:15:20000:5': 17.0,
  'mem_7:15:50000:3': 6.0,
  'ffun:15:7500:100': 391.0,
  'adb:15:10000:114': 142.0,
  'mem_7:15:2000:28': 1.0,
  'ffun_add:10:10000:40': 27.0,
  'mem:15:2000:26': 1.0,
  'ffun:15:30000:39': 63.0,
  'ffun:10:10000:104': 679.0,
  'ffun_add:10:50000:54': 366.0,
  'mem_40:15:7500:109': 217.0,
  'ffun:10:40000:41': 119.0,
  'ffun_add:15:30000:109': 2025.0,
  'dis:15:50000:57': 18.0,
  'ffun:15:10000:9': 34.0,
  'ffun_add:15:5000:8': 9.0,
  'mem:10:40000:109': 1154.0,
  'adb:15:10000:1': 6.0,
  'mem:15:2000:114': 59.0,
  'ffun:15:2000:69': 43.0,
  'ffun:15:15000:82': 286.0,
  'ffun_add:10:20000:113': 1416.0,
  'adb:15:30000:38': 26.0,
  'ffun_add:15:7500:77': 135.0,
  'mem_7:10:40000:28': 10.0,
  'ffun_add:15:2000:10': 7.0,
  'ffun:10:2000:28': 33.0,
  'ffun_add:10:30000:28': 35.0,
  'mem_40:10:30000:14': 8.0,
  'ffun_add:15:50000:66': 457.0,
  'mem:15:40000:17': 10.0,
  'ffun:10:20000:7': 40.0,
  'ffun_add:15:20000:63': 131.0,
  'mem_7:10:20000:28': 5.0,
  'mem_7:10:15000:87': 132.0,
  'ffun_add:10:40000:56': 304.0,
  'mem_40:10:30000:118': 865.0,
  'adb:15:50000:5': 29.0,
  'ffun_add:15:10000:47': 44.0,
  'ffun_add:15:15000:22': 16.0,
  'mem_40:15:50000:47': 73.0,
  'mem:10:7500:84': 83.0,
  'hcb:15:150:52': 28.0,
  'adb:10:5000:89': 25.0,
  'ffun:15:50000:1': 49.0,
  'mem_40:15:5000:83': 52.0,
  'adb:10:5000:3': 5.0,
  'ffun:10:10000:63': 99.0,
  'mem_40:10:30000:31': 16.0,
  'mem_40:10:7500:37': 6.0,
  'mem_40:10:2000:76': 17.0,
  'mem_7:15:10000:99': 174.0,
  'ffun_add:15:7500:88': 252.0,
  'mem_7:10:50000:100': 866.0,
  'adb:15:20000:32': 16.0,
  'ffun:10:40000:66': 375.0,
  'dis:15:40000:55': 13.0,
  'adb:10:40000:93': 300.0,
  'mem_40:10:50000:43': 61.0,
  'ffun_add:10:30000:24': 31.0,
  'mem:15:7500:35': 4.0,
  'adb:10:20000:110': 282.0,
  'mem_40:10:7500:19': 2.0,
  'mem:15:25000:99': 722.0,
  'ffun_add:10:10000:63': 85.0,
  'mem_7:15:50000:17': 7.0,
  'hcb:10:50:54': 4.0,
  'mem:10:50000:56': 150.0,
  'ffun_add:15:50000:32': 58.0,
  'ffun_add:10:2000:120': 151.0,
  'mem:15:40000:70': 155.0,
  'mem_40:15:10000:115': 289.0,
  'adb:15:40000:26': 26.0,
  'mem_40:10:25000:82': 275.0,
  'mem:15:15000:61': 41.0,
  'ffun:15:10000:56': 72.0,
  'ffun:15:7500:5': 33.0,
  'mem_40:10:10000:30': 5.0,
  'ffun_add:15:7500:114': 511.0,
  'mem_7:10:5000:74': 19.0,
  'mem:15:20000:89': 282.0,
  'mem:10:20000:28': 8.0,
  'mem:10:20000:18': 6.0,
  'mem_40:10:30000:22': 10.0,
  'mem_7:10:25000:20': 5.0,
  'ffun:15:20000:2': 38.0,
  'adb:15:15000:92': 112.0,
  'ffun:10:40000:99': 2574.0,
  'hcb:10:50:30': 3.0,
  'adb:10:15000:84': 52.0,
  'mem_7:15:10000:30': 2.0,
  'ffun:15:15000:112': 752.0,
  'mem_7:10:15000:116': 260.0,
  'mem_7:15:30000:68': 70.0,
  'mem:10:30000:31': 16.0,
  'mem_7:15:2000:109': 35.0,
  'mem_7:15:20000:19': 4.0,
  'mem_7:10:40000:9': 7.0,
  'mem:15:30000:15': 7.0,
  'adb:15:10000:48': 16.0,
  'ffun_add:10:50000:97': 3527.0,
  'ffun_add:15:15000:109': 1017.0,
  'ffun_add:10:40000:48': 196.0,
  'ffun_add:15:7500:118': 511.0,
  'ffun_add:10:7500:28': 14.0,
  'ffun:15:40000:24': 48.0,
  'adb:15:40000:62': 19.0,
  'ffun_add:10:10000:28': 17.0,
  'mem_40:10:20000:86': 292.0,
  'adb:10:2000:49': 5.0,
  'adb:10:30000:22': 24.0,
  'mem_40:15:40000:22': 10.0,
  'adb:15:20000:76': 43.0,
  'adb:15:40000:98': 564.0,
  'mem:10:2000:21': 1.0,
  'ffun:15:7500:53': 60.0,
  'adb:15:15000:32': 12.0,
  'ffun_add:10:10000:18': 14.0,
  'mem_40:10:25000:92': 482.0,
  'mem_7:15:20000:5': 2.0,
  'ffun_add:15:2000:57': 19.0,
  'adb:10:50000:42': 73.0,
  'mem:15:20000:69': 78.0,
  'ffun:15:7500:52': 60.0,
  'ffun_add:15:50000:9': 34.0,
  'mem_7:15:7500:51': 11.0,
  'ffun:15:10000:88': 265.0,
  'ffun:15:2000:79': 55.0,
  'adb:10:10000:42': 16.0,
  'ffun:10:20000:107': 1310.0,
  'ffun:15:7500:54': 60.0,
  'ffun:10:15000:35': 44.0,
  'mem_40:15:20000:19': 5.0,
  'mem_7:15:50000:70': 116.0,
  'mem:10:5000:44': 7.0,
  'hcb:10:80:38': 9.0,
  'ffun:15:50000:102': 2435.0,
  'ffun:15:30000:49': 110.0,
  'mem_7:15:25000:34': 6.0,
  'adb:15:40000:81': 127.0,
  'adb:10:10000:55': 28.0,
  'mem:10:15000:89': 218.0,
  'mem_7:10:10000:2': 2.0,
  'ffun:15:10000:38': 41.0,
  'ffun:15:50000:35': 67.0,
  'mem_40:10:40000:70': 184.0,
  'ffun:10:20000:59': 154.0,
  'ffun:10:30000:95': 1312.0,
  'ffun:15:15000:93': 508.0,
  'mem_7:15:25000:24': 4.0,
  'mem_7:15:15000:20': 2.0,
  'mem_40:15:15000:15': 4.0,
  'mem:10:25000:100': 722.0,
  'adb:10:25000:78': 61.0,
  'mem_40:15:7500:103': 217.0,
  'mem:15:40000:86': 563.0,
  'ffun:10:5000:42': 45.0,
  'ffun:15:5000:67': 62.0,
  'ffun:15:2000:67': 43.0,
  'mem_7:15:5000:68': 12.0,
  'adb:15:40000:39': 36.0,
  'mem_40:15:7500:60': 19.0,
  'ffun_add:15:20000:6': 17.0,
  'mem:10:50000:36': 38.0,
  'ffun:10:15000:91': 681.0,
  'ffun_add:15:25000:92': 1122.0,
  'adb:15:30000:40': 26.0,
  'mem_7:15:10000:89': 85.0,
  'ffun_add:15:40000:112': 2698.0,
  'ffun:15:25000:113': 1232.0,
  'ffun:10:50000:98': 3205.0,
  'ffun:15:7500:106': 391.0,
  'mem_7:10:40000:52': 67.0,
  'mem_40:15:20000:66': 78.0,
  'mem_40:10:7500:88': 109.0,
  'mem:10:10000:87': 146.0,
  'mem_40:15:5000:6': 1.0,
  'mem_40:15:25000:49': 37.0,
  'hcb:10:150:36': 32.0,
  'mem_40:10:40000:92': 772.0,
  'mem_7:10:2000:109': 35.0,
  'adb:15:10000:51': 23.0,
  'mem_40:10:50000:60': 150.0,
  'mem_7:15:10000:98': 174.0,
  'mem_40:15:5000:120': 145.0,
  'adb:15:5000:81': 17.0,
  'mem:10:5000:118': 145.0,
  'ffun_add:10:40000:46': 196.0,
  'adb:15:25000:4': 14.0,
  'adb:15:50000:101': 706.0,
  'mem_7:10:40000:21': 8.0,
  'ffun:15:25000:19': 40.0,
  'adb:10:50000:92': 376.0,
  'mem_40:10:10000:105': 289.0,
  'ffun_add:15:15000:7': 14.0,
  'ffun:15:2000:27': 31.0,
  'mem_40:10:25000:99': 722.0,
  'ffun:10:20000:71': 266.0,
  'mem:15:2000:79': 16.0,
  'mem_40:10:10000:70': 47.0,
  'mem_40:15:30000:90': 422.0,
  'mem_7:15:20000:77': 95.0,
  'mem_40:10:15000:57': 46.0,
  'mem_40:15:5000:42': 5.0,
  'ffun:15:40000:1': 46.0,
  'adb:10:2000:58': 5.0,
  'mem_7:10:15000:117': 260.0,
  'mem_7:10:7500:8': 1.0,
  'ffun:15:10000:22': 35.0,
  'ffun:15:15000:31': 41.0,
  'mem_7:15:30000:14': 5.0,
  'ffun:10:10000:120': 679.0,
  'mem:10:40000:35': 20.0,
  'mem_40:15:2000:42': 2.0,
  'adb:15:30000:37': 26.0,
  'ffun_add:10:7500:112': 538.0,
  'ffun:10:30000:101': 1940.0,
  'mem_40:10:10000:75': 62.0,
  'mem:15:25000:47': 37.0,
  'mem_7:10:2000:49': 2.0,
  'ffun_add:15:2000:90': 72.0,
  'ffun_add:10:7500:21': 13.0,
  'hcb:15:100:33': 12.0,
  'mem_40:10:20000:33': 11.0,
  'ffun:10:15000:64': 128.0,
  'mem:10:10000:15': 4.0,
  'ffun_add:15:10000:13': 12.0,
  'mem_7:10:50000:47': 56.0,
  'ffun:10:40000:100': 2574.0,
  'ffun_add:10:30000:7': 27.0,
  'ffun:15:5000:101': 271.0,
  'mem_40:10:10000:39': 8.0,
  'hcb:15:120:36': 17.0,
  'mem:10:7500:60': 23.0,
  'ffun:10:50000:45': 140.0,
  'ffun:10:20000:53': 150.0,
  'mem:15:30000:54': 66.0,
  'ffun_add:15:30000:68': 276.0,
  'mem:10:5000:29': 2.0,
  'ffun:10:40000:67': 375.0,
  'adb:10:40000:36': 46.0,
  'adb:15:50000:41': 58.0,
  'ffun_add:10:15000:5': 17.0,
  'ffun_add:15:40000:48': 156.0,
  'mem:15:40000:33': 17.0,
  'ffun:10:5000:12': 33.0,
  'ffun:15:7500:15': 33.0,
  'mem_7:15:50000:110': 866.0,
  'adb:10:40000:47': 79.0,
  'adb:15:5000:28': 4.0,
  'mem:10:40000:87': 583.0,
  'mem:10:10000:86': 146.0,
  'mem_40:10:10000:32': 6.0,
  'mem:10:20000:111': 577.0,
  'ffun_add:10:15000:68': 171.0,
  'ffun:15:15000:108': 752.0,
  'mem_40:15:2000:71': 12.0,
  'mem_7:10:2000:30': 1.0,
  'mem_40:10:7500:36': 6.0,
  'mem_7:15:5000:88': 43.0,
  'adb:10:10000:114': 142.0,
  'mem_7:10:2000:18': 1.0,
  'ffun_add:10:15000:60': 120.0,
  'adb:15:7500:108': 107.0,
  'ffun:15:2000:120': 126.0,
  'mem:10:5000:76': 42.0,
  'adb:10:7500:67': 5.0,
  'mem:10:10000:65': 32.0,
  'adb:15:10000:108': 142.0,
  'ffun_add:10:25000:40': 56.0,
  'adb:15:25000:21': 16.0,
  'dis:10:20000:33': 12.0,
  'ffun:10:15000:26': 41.0,
  'adb:15:15000:62': 7.0,
  'mem_7:10:15000:54': 25.0,
  'mem_7:15:20000:10': 2.0,
  'mem_40:10:7500:50': 14.0,
  'ffun_add:15:40000:27': 37.0,
  'adb:10:15000:37': 18.0,
  'mem:15:10000:13': 2.0,
  'ffun:15:5000:83': 116.0,
  'ffun_add:10:2000:115': 151.0,
  'ffun:15:25000:58': 135.0,
  'mem_40:15:50000:116': 1444.0,
  'ffun:15:20000:118': 992.0,
  'adb:15:15000:110': 212.0,
  'ffun:10:30000:97': 1940.0,
  'mem_40:15:20000:12': 5.0,
  'ffun:10:25000:57': 183.0,
  'adb:15:15000:96': 212.0,
  'mem_40:10:5000:97': 145.0,
  'mem:10:5000:66': 24.0,
  'mem_7:10:40000:111': 692.0,
  'mem:10:10000:105': 289.0,
  'mem:10:40000:31': 20.0,
  'mem_40:15:10000:93': 192.0,
  'mem_40:10:2000:84': 23.0,
  'ffun:10:7500:104': 522.0,
  'ffun:15:40000:103': 1953.0,
  'ffun:15:7500:115': 391.0,
  'adb:10:20000:3': 16.0,
  'adb:10:5000:53': 14.0,
  'ffun_add:15:10000:109': 679.0,
  'mem_7:10:15000:30': 4.0,
  'mem_7:15:20000:105': 347.0,
  'mem:10:25000:73': 155.0,
  'ffun:10:40000:112': 2574.0,
  'ffun_add:10:40000:95': 1889.0,
  'ffun:15:5000:57': 51.0,
  'mem_7:10:50000:75': 186.0,
  'ffun:10:15000:51': 122.0,
  'ffun:10:30000:73': 378.0,
  'mem_7:10:10000:56': 18.0,
  'mem:15:40000:97': 1154.0,
  'mem_7:10:2000:86': 18.0,
  'ffun:15:25000:8': 40.0,
  'ffun:15:5000:33': 34.0,
  'mem:10:30000:104': 865.0,
}
