/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'
import InputSymbol from './InputSymbol'
import ValidationErrors from './ValidationErrors'

const useTooltips = process.env.APPLICATION_USE_TOOLTIPS === 'true'

const Datalist = ({
  value,
  id,
  label,
  readonly,
  inputType,
  itemType,
  setFocus,
  required,
  errors,
  hideLabel,
  itemIndex,
  tooltip,
  onChange,
  options
}) => {
  const elementId = hideLabel && itemIndex ? `${id}-${itemIndex}` : id
  const listId = `${elementId}-options`
  return (
    <div
      className={classNames({
        editable: !readonly,
        field: true,
        'has-tooltip': useTooltips && tooltip,
        'has-value': value.length > 0,
        datalist: true
      })}
    >
      {!hideLabel && (
        <label
          htmlFor={elementId}
          className={classNames({ hidden: hideLabel, label: true })}
        >
          {label}
        </label>
      )}
      <div className='input-group datalist'>
        <input
          type='search'
          list={listId}
          autoFocus={setFocus}
          required={required}
          className={classNames({
            disabled: readonly,
            'has-error': errors && errors.length > 0,
            'is-required': required,
            placeholder: value.length === 0 && !useTooltips && tooltip
          })}
          name={label}
          id={elementId}
          placeholder={useTooltips ? null : tooltip}
          readOnly={readonly}
          value={value}
          onChange={onChange}
          noValidate
          autoComplete='off'
        />
        <InputSymbol id={id} inputType={inputType} itemType={itemType} />
        <datalist id={listId}>
          {options.map((option, i) => (
            <option value={option} key={i}>
              {option}
            </option>
          ))}
        </datalist>
      </div>
      {errors && errors.length > 0 && <ValidationErrors errors={errors} />}
    </div>
  )
}

export default Datalist
