/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import { connect } from 'react-redux'
import { installPrompt } from '../core/actions'

const InstallPrompt = ({ prompt, onComplete }) =>
  prompt ? (
    <a
      href='#'
      id='install-button'
      className='button'
      onClick={e => {
        e.preventDefault()
        if (window.installPromptEvent) {
          try {
            window.installPromptEvent.prompt()
            window.installPromptEvent.userChoice.then(c => {
              if (window.ga) {
                window.ga('send', 'event', 'Installed', c.outcome)
              }
              window.installPromptEvent = undefined
              onComplete()
            })
          } catch (err) {
            console.error(err)
          }
        } else {
          onComplete()
        }
      }}
    >
      Install App
    </a>
  ) : null

export default connect(
  state => ({ prompt: state.environment.installPrompt }),
  dispatch => ({ onComplete: () => dispatch(installPrompt(false)) })
)(InstallPrompt)
