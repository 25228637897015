/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */

import {
  pages,
  acceptancePaymentDetails,
  familyMemberLabels,
  familyMemberOptions,
  additionalLife,
  createFamilyMember,
  buttonFromLifeAssured,
  idFromLifeAssured,
  familyMemberTablePrefix,
  familyMemberItem,
} from './funeral-smart-plan/pages'

import {
  createItem,
  updateItemIn,
  findItemsIn,
  createButton,
  slugify,
} from '../../../src/core/dataitems'
import { form } from './funeral-smart-plan/form'
import shortid from 'shortid'
import { cancelProcess, completeProcess } from '../../../src/core/actions'
import dayjs from 'dayjs'
import { original } from 'immer'
import { createSelector } from 'reselect'
import { validate } from './funeral-smart-plan/validations'

// Change the page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}

const dateFormat = 'D MMMM YYYY'
const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

const mainLifeAge = createSelector(
  (form) => form['life-assured-identification']['date-of-birth'],
  (dob) => dayjs().diff(dob, 'year')
)

const optionsForRelationship = (opts, relationship) => {
  if (relationship in opts) {
    return opts[relationship].options || []
  } else {
    const alt = Object.values(opts).find((v) => v.rename === relationship)
    return alt ? alt.options || [] : null
  }
}

const hasSpouse = (state) =>
  state.form['lives-assured'].find((l) => l.relationship === 'Spouse')

const hasAdditionalSpouse = (state) =>
  state.form['lives-assured'].find(
    (l) => l.relationship === 'Additional-Spouse'
  )

//Map Spouse details from tablel to payer details

const mapSpousedetails = createSelector(
  (form) => form['lives-assured'],
  (spouse) => {
    const items = spouse.filter(
      (item) => item.relationship.indexOf('Spouse') >= 0
    )
    return items
  }
)

// Prodct rules say spouse gender is opposite to main life gender :(
const spouseGender = (gender) => {
  switch (gender) {
    case 'Male':
      return 'Female'
    case 'Female':
      return 'Male'
    default:
      return null
  }
}

//PREMIUM CALCULATION SECTION
var main_life_age_index = function (age) {
  if (17 < age && age <= 25) {
    return 1
  } else if (26 <= age && age <= 30) {
    return 2
  } else if (31 <= age && age <= 35) {
    return 3
  } else if (36 <= age && age <= 40) {
    return 4
  } else if (41 <= age && age <= 45) {
    return 5
  } else if (46 <= age && age <= 50) {
    return 6
  } else if (51 <= age && age <= 55) {
    return 7
  } else if (56 <= age && age <= 59) {
    return 8
  } else {
    return -1
  }
}

//MAIN LIFE COVER INDEX
var main_life_cover_index = function (cover) {
  if (cover === 2500) {
    return 0
  } else if (cover === 5000) {
    return 1
  } else if (cover === 7500) {
    return 2
  } else if (cover === 10000) {
    return 3
  } else {
    return -1
  }
}

//MAIN LIFE RATES
var main_life_payment_rates = [
  [13.15, 26.3, 39.45, 52.6],
  [13.24, 26.47, 39.71, 52.94],
  [13.44, 26.88, 40.32, 53.76],
  [13.89, 27.79, 41.68, 55.57],
  [14.8, 29.6, 44.4, 59.2],
  [16.32, 32.63, 48.95, 65.26],
  [18.25, 36.49, 54.74, 72.99],
  [20.7, 41.4, 62.1, 82.8],
]

var main_payment_rate = function (type, age, cover) {
  switch (type) {
    case 'main':
      return main_life_payment_rates[main_life_age_index(age) - 1][
        main_life_cover_index(cover)
      ]
    default:
      return '0.00'
  }
}

let coverOptions = {
  'ghc-250000': 2500,
  'ghc-500000': 5000,
  'ghc-750000': 7500,
  'ghc-1000000': 10000,
}

const paymentFrequencyLookup = {
  'bi-annually': 6,
  monthly: 1,
  quarterly: 3,
  annually: 12,
}

//MAIN LIFE RATE CALCULATION
var mainPremium = createSelector(
  (form) => form['life-assured-identification']['date-of-birth'],
  (form) => form['main-cover-level'],
  (dob, cover) => {
    const age = dayjs().diff(dob, 'year')
    const coverLevel = Object.keys(cover).find((p) => cover[p] === 'Y')
    if (!isNaN(age) && age > 17 && coverLevel) {
      let maincover = coverOptions[coverLevel]
      return 1 + main_payment_rate('main', age, maincover)
    }
    return '0.00'
  }
)

//ADD ADDITIONAL LIVES RATES, TABLES && CALCULATION
//WIDER AGE CALC
var wider_life_age_index = function (age) {
  if (0 <= age && age <= 1) {
    return 0
  } else if (2 <= age && age <= 6) {
    return 1
  } else if (7 <= age && age <= 11) {
    return 2
  } else if (12 <= age && age <= 16) {
    return 3
  } else if (17 <= age && age <= 21) {
    return 4
  } else if (22 <= age && age <= 26) {
    return 5
  } else if (27 <= age && age <= 31) {
    return 6
  } else if (32 <= age && age <= 36) {
    return 7
  } else if (37 <= age && age <= 41) {
    return 8
  } else if (42 <= age && age <= 46) {
    return 9
  } else if (47 <= age && age <= 51) {
    return 10
  } else if (52 <= age && age <= 56) {
    return 11
  } else if (57 <= age && age <= 61) {
    return 12
  } else if (62 <= age && age <= 66) {
    return 13
  } else if (67 <= age && age <= 71) {
    return 14
  } else if (72 <= age && age <= 75) {
    return 15
  } else {
    return -1
  }
}

//WIDER COVER SELECTION
var wider_life_cover_index = function (cover) {
  if (cover === 2500) {
    return 0
  } else if (cover === 5000) {
    return 1
  } else if (cover === 7500) {
    return 2
  } else if (cover === 10000) {
    return 3
  } else {
    return -1
  }
}

//WIDER PREMIUM TABLE
var wider_payment_rates = [
  [1, 1.45, 2.17, 2.89],
  [1, 1.44, 2.16, 2.88],
  [1, 1.44, 2.16, 2.89],
  [1, 1.46, 2.19, 2.92],
  [1, 1.5, 2.25, 3.01],
  [1, 1.65, 2.47, 3.29],
  [1.01, 2.02, 3.02, 4.03],
  [1.46, 2.91, 4.37, 5.83],
  [2.31, 4.61, 6.92, 9.23],
  [3.79, 7.58, 11.36, 15.15],
  [5.89, 11.77, 17.66, 23.55],
  [8.67, 17.34, 26.01, 34.69],
  [12.75, 25.51, 38.26, 51.02],
  [18.77, 37.55, 56.32, 75.09],
  [25.5, 50.99, 76.49, 0],
]

var additional_payment_rate = function (type, age, cover) {
  switch (type) {
    case 'wider':
      return wider_payment_rates[wider_life_age_index(age) - 1][
        wider_life_cover_index(cover)
      ]
    default:
      return 0.0
  }
}

let additionalCover = {
  'option-e': 2500,
  'option-f': 5000,
  'option-g': 7500,
  'option-h': 10000,
}

//ADDITIONAL LIFE PREMIUM CALCULATION
const additionalPremium = createSelector(
  (form) => form['lives-assured'],
  (life) => {
    let premium = life.map((l) => {
      const age = l['age-of-member']
      let optione = l['option-e'] || ''
      let optionf = l['option-f'] || ''
      let optiong = l['option-g'] || ''
      let optionh = l['option-h'] || ''
      let cover = null
      if (optione === 'Y') {
        cover = 'option-e'
      } else if (optionf === 'Y') {
        cover = 'option-f'
      } else if (optiong === 'Y') {
        cover = 'option-g'
      } else if (optionh === 'Y') {
        cover = 'option-h'
      }
      let coverindex = additionalCover[cover]
      if (age && coverindex) {
        return additional_payment_rate('wider', age, coverindex)
      } else {
        return 0
      }
    }, 0)
    if (life.length > 0) {
      let sum = 0
      for (let m = 0; m < premium.length; m++) {
        sum += premium[m]
      }
      return sum
    } else {
      return 0
    }
  }
)

//TOTAL PREMIUM
export const totalPremium = createSelector(
  mainPremium,
  additionalPremium,
  (form) => form['cash-bonus-premium'],
  (form) => form['payment-frequency'],
  (mainrate, widerrate, cbp, paymentFrequency) => {
    const cashB = parseFloat(cbp)
    const rate = []
    const pfkey = Object.keys(paymentFrequency).find(
      (key) => paymentFrequency[key] === 'Y'
    )
    const pfmultiplier =
      pfkey in paymentFrequencyLookup ? paymentFrequencyLookup[pfkey] : 1
    if (!isNaN(mainrate) && mainrate > 0) {
      rate.push(mainrate)
    }
    if (!isNaN(widerrate) && widerrate > 0) {
      rate.push(widerrate)
    }
    if (!isNaN(cashB) && cashB > 0) {
      rate.push(cashB)
    }
    if (rate.length > 0) {
      let sum = 0
      for (let m = 0; m < rate.length; m++) {
        sum += rate[m]
      }
      return sum * pfmultiplier
    } else {
      return '0.00'
    }
  }
)

//BENEFIT RATE ADDITIONAL LIVES
const benefitRatelives = createSelector(
  (form) => form['lives-assured'],
  (lives) => {
    let premium = lives.map((l) => {
      let optione = l['option-e'] || ''
      let optionf = l['option-f'] || ''
      let optiong = l['option-g'] || ''
      let optionh = l['option-h'] || ''
      let cover = null
      if (optione === 'Y') {
        cover = 'option-e'
      } else if (optionf === 'Y') {
        cover = 'option-f'
      } else if (optiong === 'Y') {
        cover = 'option-g'
      } else if (optionh === 'Y') {
        cover = 'option-h'
      }
      let coverindex = additionalCover[cover]
      if (coverindex) {
        return coverindex
      } else {
        return 0
      }
    }, 0)
    if (lives.length > 0) {
      let sum = 0
      for (let m = 0; m < premium.length; m++) {
        sum += premium[m]
      }
      return sum
    } else {
      return 0
    }
  }
)

const benefitRate = createSelector(
  (form) => form['life-assured-identification']['date-of-birth'],
  (form) => form['main-cover-level'],
  (dob, coverlevel) => {
    const age = dayjs().diff(dob, 'year')
    const c = Object.keys(coverlevel).find((p) => coverlevel[p] === 'Y')
    if (c && age > 17) {
      let cover = coverOptions[c]
      return cover
    } else {
      return '0.00'
    }
  }
)

const totalBenefitRate = createSelector(
  benefitRatelives,
  benefitRate,
  (benefit, mainbenefit) => {
    const benefitRate = []
    if (!isNaN(benefit) && benefit > 0) {
      benefitRate.push(benefit)
    }
    if (!isNaN(mainbenefit) && mainbenefit > 0) {
      benefitRate.push(mainbenefit)
    }
    if (benefitRate.length > 0) {
      let sum = 0
      for (let m = 0; m < benefitRate.length; m++) {
        sum += benefitRate[m]
      }
      return sum
    } else {
      return '0.00'
    }
  }
)

// Create the state for a new process
const createState = (state) => {
  state.currentProcess = {} // Create a new process
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  ) // Overall Process state
  state.currentProcess.step = 'quotation-screen' // Current process step
  state.currentProcess.page = pages['quotation-screen'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

const generateAcceptancePage = (state) => {
  // Update the reference number
  updateItemIn(
    state.page.item,
    { id: 'contract-id' },
    { content: state.form['reference-number'] }
  )
  // Update all sections
  Object.keys(state.form).forEach((section) => {
    findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
      Object.keys(state.form[section]).forEach((field) => {
        if (state.form[section][field]) {
          updateItemIn(
            sItem,
            { id: field },
            { content: state.form[section][field] }
          )
        }
      })
    })
  })

  updateItemIn(
    state.page.item,
    { id: 'ghanaian', type: 'field' },
    {
      content:
        state.form['life-assured-identification']['ghanaian'] === 'Y'
          ? state.form['life-assured-identification']['ghanaian']
          : '' || '',
    }
  )

  // Update the payment details
  let paymentDetails = acceptancePaymentDetails(state.form)
  if (paymentDetails) {
    updateItemIn(
      state.page.item,
      { id: 'payer-employment-details', type: 'list' },
      paymentDetails
    )
  }
}

const setPageItemFromState = (state) => {
  validate(state, state.local && state.local.failed_validation ? false : true)
  switch (state.step) {
    case 'quotation-screen':
      // Date of birth
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
          maxYear: dayjs().year() - 18,
          minYear: dayjs().year() - 59,
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'marital-status' },
        {
          content:
            state.form['life-assured-identification']['marital-status'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'gender' },
        {
          content: state.form['life-assured-identification']['gender'] || '',
        }
      )

      Object.keys(state.form['main-cover-level']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['main-cover-level'][field] || '' }
        )
      )
      break

    case 'additional-cover':
      updateItemIn(
        state.page.item,
        { id: 'cash-bonus-premium', type: 'amount' },
        {
          content: state.form['cash-bonus-premium'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'inflation-protector', type: 'field' },
        { content: state.form['inflation-protector'] || '' }
      )

      //Additional Lives
      updateItemIn(
        state.page.item,
        { id: 'lives-added-basic' },
        {
          invisible: state.form['lives-assured'].length === 0,
          content: state.form['lives-assured'].map(additionalLife),
        }
      )

      updateItemIn(
        state.page.item,
        { uid: '44612adcb73f4f97' },
        {
          invisible: state.form['lives-assured'].length === 4,
        }
      )

      // Only show buttons if there are lives assured
      state.page.buttons.forEach((button) => {
        if (['remove-family', 'edit-family'].indexOf(button.id) >= 0) {
          button.invisible = state.form['lives-assured'].length < 1
        }
      })
      break

    // Add additional lives
    case 'add-family-members':
      let familyButtonLabels = familyMemberLabels
      if (
        state.local &&
        state.local.relationship &&
        familyMemberLabels.indexOf(state.local.relationship) > -1
      ) {
        familyButtonLabels = Object.keys(
          familyMemberOptions(0)[state.local.relationship]
        )
      } else {
        // Hide spouse button if there already is a spouse
        if (
          hasSpouse(state) ||
          state.form['life-assured-identification']['marital-status'] !==
            'Married'
        ) {
          familyButtonLabels = familyButtonLabels.filter((b) => b !== 'Spouse')
        }
        if (
          hasAdditionalSpouse(state) ||
          state.form['life-assured-identification']['marital-status'] !==
            'Married'
        ) {
          familyButtonLabels = familyButtonLabels.filter(
            (b) => b !== 'Additional-Spouse'
          )
        }
      }
      state.page.buttons = familyButtonLabels.map(createButton)
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'family-members-age-and-gender':
      state.page.text = [
        `Please provide the age and gender of your ${
          state.local.relationship || 'family member'
        }`,
      ]

      updateItemIn(
        state.page.item,
        { id: 'age-of-member' },
        {
          content: state.local['age-of-member'] || '',
          options: state.local.options || [],
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'gender' },
        {
          content:
            (state.local.relationship === 'Mother' ||
            state.local.relationship === 'Mother in law' ||
            state.local.relationship === 'Step mother' ||
            state.local.relationship === 'Sister' ||
            state.local.relationship === 'Grandmother'
              ? (state.local.gender = 'Female')
              : state.local.gender || '') ||
            (state.local.relationship === 'Father' ||
            state.local.relationship === 'Father in law' ||
            state.local.relationship === 'Grandfather' ||
            state.local.relationship === 'Step father' ||
            state.local.relationship === 'Brother' ||
            state.local.relationship === 'Grandfather'
              ? (state.local.gender = 'Male')
              : state.local.gender || '') ||
            (state.local.relationship === 'Spouse'
              ? (state.local.gender = spouseGender(
                  state.form['life-assured-identification'].gender
                ))
              : state.local.gender || '') ||
            (state.local.relationship === 'Additional-Spouse'
              ? (state.local.gender = spouseGender(
                  state.form['life-assured-identification'].gender
                ))
              : state.local.gender || '') ||
            (state.local.relationship === 'Child' ||
            state.local.relationship === 'Guardian'
              ? (state.local.gender = '')
              : (state.local.gender = '')),
        }
      )
      break

    case 'family-members-quotation-details':
      state.page.text = [
        `Select the cover for your ${
          state.local.relationship || 'family member'
        }`,
      ]

      updateItemIn(
        state.page.item,
        { id: 'option-e' },
        { content: state.local['option-e'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'option-f' },
        { content: state.local['option-f'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'option-g' },
        { content: state.local['option-g'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'option-h' },
        { content: state.local['option-h'] || '' }
      )

      // Update the quotation
      updateItemIn(
        state.page.item,
        { uid: '89247d9fc3a19518' },
        { content: totalPremium(state.form).toFixed(2) }
      )
      break

    case 'edit-family-members':
      state.page.buttons = state.form['lives-assured'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'remove-family-members':
      state.page.buttons = state.form['lives-assured'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-identification-type',
        'life-assured-contact-details',
        'life-assured-postal-details',
        'life-assured-residential-address',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })

      updateItemIn(
        state.page.item,
        { id: 'gender' },
        { content: state.form['life-assured-identification']['gender'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'marital-status' },
        {
          content:
            state.form['life-assured-identification']['marital-status'] || '',
        }
      )
      break

    case 'trustee-details':
      ;['trustee-identification'].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      break

    case 'additional-lives':
      updateItemIn(
        state.page.item,
        { id: 'additional-lives' },
        {
          content: familyMemberTablePrefix.concat(
            state.form['lives-assured'].map(familyMemberItem)
          ),
        }
      )
      validate(
        state,
        state.local && state.local.failed_validation ? false : true
      )
      break

    case 'payment-details':
      Object.keys(state.form['payment-method-selection']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-method-selection'][field] || '' }
        )
      )

      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )

      Object.keys(state.form['payment-frequency']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-frequency'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { id: 'payer-person', type: 'list' },
        {
          invisible: !(
            state.form['life-assured-identification']['marital-status'] ===
            'Married'
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'myself' },
        { content: state.form['payer-person']['myself'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'my-spouse' },
        { content: state.form['payer-person']['my-spouse'] || '' }
      )
      break

    case 'payer-details':
      ;['payer-person-identification'].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      break

    case 'debit-order-details':
      Object.keys(state.form['payment-bank-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['payment-bank-details'][field] || '',
          }
        )
      )
      break

    case 'stop-order-details':
      Object.keys(state.form['stop-order-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['stop-order-details'][field] || '',
          }
        )
      )
      break

    case 'mobile-wallet-details':
      Object.keys(state.form['mobile-wallet-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['mobile-wallet-details'][field] || '',
          }
        )
      )
      break

    case 'payment-details-acceptance':
      updateItemIn(
        state.page.item,
        { id: 'contract-id' },
        { content: state.form['reference-number'] }
      )

      // Update the payment details
      let paymentDetails = acceptancePaymentDetails(state.form)
      if (paymentDetails) {
        updateItemIn(
          state.page.item,
          { id: 'payer-employment-details', type: 'list' },
          paymentDetails
        )
      }
      break

    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break
  }
  // Update the quotation
  updateItemIn(
    state.page.item,
    { id: 'premium' },
    { content: totalPremium(state.form) }
  )
  // Update the quotation
  updateItemIn(
    state.page.item,
    { id: 'benefit-rate' },
    { content: totalBenefitRate(state.form) }
  )
}

// processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break
      case 'quotation-screen':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              changePage(state, 'additional-cover')
            }
            break
          case 'more-info':
            changePage(state, 'more-info')
            break
        }
        break
      case 'more-info':
        switch (button.id) {
          case 'done':
            changePage(state, 'quotation-screen')
            break
        }
        break
      case 'additional-cover':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-screen')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'life-assured-identification')
            }
            break
          case 'add-family':
            changePage(state, 'add-family-members')
            break
          case 'remove-family':
            changePage(state, 'remove-family-members')
            break
          case 'edit-family':
            const locals = state.local
            changePage(state, 'edit-family-members')
            state.local = locals
            break
        }
        break
      case 'add-family-members':
        if (button.id === 'back') {
          changePage(state, 'additional-cover')
        } else {
          let optionLevel = familyMemberOptions(mainLifeAge(state.form) || 0)
          if (
            state.local &&
            state.local.relationship &&
            familyMemberLabels.indexOf(state.local.relationship) > -1
          ) {
            optionLevel = optionLevel[state.local.relationship]
          }
          const elem = Object.keys(optionLevel).find(
            (l) => button.id === slugify(l)
          )
          if (elem) {
            const options = optionLevel[elem]
            if ('options' in options) {
              changePage(state, 'family-members-age-and-gender')
              state.local = state.local || {}
              state.local.options = options.options
              state.local.relationship = options.rename || elem
            } else {
              state.local = state.local || {}
              state.local.relationship = elem
            }
          }
        }
        break

      case 'family-members-age-and-gender':
        switch (button.id) {
          case 'back':
            changePage(state, 'additional-cover')
            break
          case 'next':
            if (validate(state)) {
              const local = state.local
              changePage(state, 'family-members-quotation-details')
              state.local = local
            }
            break
        }
        break

      case 'family-members-quotation-details':
        switch (button.id) {
          case 'back':
            const local = state.local
            changePage(state, 'family-members-age-and-gender')
            state.local = local
            break
          case 'next':
            if (validate(state)) {
              if (
                state.local &&
                ['relationship', 'age-of-member', 'gender'].every(
                  (f) => f in state.local
                )
              ) {
                const life = createFamilyMember(
                  state.local.relationship,
                  state.local['age-of-member'],
                  state.local.gender,
                  state.local['option-e'] || '',
                  state.local['option-f'] || '',
                  state.local['option-g'] || '',
                  state.local['option-h'] || ''
                )
                if (
                  (state.local.replace || state.local.replace === 0) &&
                  state.local.replace >= 0 &&
                  state.local.replace < state.form['lives-assured'].length
                ) {
                  state.form['lives-assured'][state.local.replace] = life
                } else {
                  state.form['lives-assured'].push(life)
                }
                changePage(state, 'additional-cover')
              }
            }
            break
        }
        break

      case 'edit-family-members':
        if (button.id === 'back') {
          changePage(state, 'additional-cover')
        } else {
          const lifePosition = state.form['lives-assured'].findIndex(
            (life, position, lives) =>
              idFromLifeAssured(life, position, lives) === button.id
          )
          if (lifePosition >= 0) {
            changePage(state, 'family-members-age-and-gender')
            const life = original(state.form['lives-assured'][lifePosition])
            state.local = {
              replace: lifePosition,
              relationship: life.relationship,
              'age-of-member': life['age-of-member'],
              gender: life.gender,
              'option-e': life['option-e'] === 'Y' ? 'Y' : undefined,
              'option-f': life['option-f'] === 'Y' ? 'Y' : undefined,
              'option-g': life['option-g'] === 'Y' ? 'Y' : undefined,
              'option-h': life['option-h'] === 'Y' ? 'Y' : undefined,
            }
            const optionLevel =
              familyMemberOptions(mainLifeAge(state.form) || 0) || []
            state.local.options = optionsForRelationship(
              optionLevel,
              life.relationship
            )
            if (state.local.options === null) {
              state.local.options = Object.keys(optionLevel).reduce(
                (options, key) =>
                  optionsForRelationship(optionLevel[key], life.relationship) ||
                  options,
                []
              )
            }
          }
        }
        break

      case 'remove-family-members':
        state.form['lives-assured'] = state.form['lives-assured'].filter(
          (life, position, lives) =>
            idFromLifeAssured(life, position, lives) !== button.id
        )
        changePage(state, 'additional-cover')
        break

      case 'life-assured-identification':
        switch (button.id) {
          case 'back':
            changePage(state, 'additional-cover')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'trustee-details')
              if (
                ['life-assured-identification']['marital-status'] !== 'Married'
              ) {
                state.form['payer-person']['myself'] = 'Y'
              }
            }
            break
        }
        break
      case 'trustee-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'life-assured-identification')
            break
          case 'next':
            if (validate(state)) {
              changePage(
                state,
                state.form['lives-assured'].length > 0
                  ? 'additional-lives'
                  : 'payment-details'
              )
              if (state.form['trustee-identification'].relationship === 'Son') {
                state.form['trustee-identification'].relationship = 'Son-1'
              }
              if (
                state.form['trustee-identification'].relationship === 'Daughter'
              ) {
                state.form['trustee-identification'].relationship = 'Daughter-1'
              }
            }
            break
        }
        break
      case 'additional-lives':
        switch (button.id) {
          case 'back':
            changePage(state, 'trustee-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details')
            }
            break
        }
        break

      case 'payment-details':
        switch (button.id) {
          case 'back':
            changePage(
              state,
              state.form['lives-assured'].length > 0
                ? 'additional-lives'
                : 'trustee-details'
            )
            break
          case 'next':
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              ;(state.form['payer-person']['myself'] = 'Y') &&
                (state.form['payer-person']['my-spouse'] = 'N')
            }
            if (validate(state)) {
              if (state.form['payer-person']['my-spouse'] === 'Y') {
                state.form['spouse'] = mapSpousedetails(state.form)
                state.form['spouse'].forEach((user) => {
                  state.form['payer-person-identification']['first-names'] =
                    user['first-names']
                  state.form['payer-person-identification']['surname'] =
                    user['surname']
                  state.form['payer-person-identification']['date-of-birth'] =
                    user['date-of-birth']
                })
                changePage(state, 'payer-details')
              } else if (
                state.form['payment-method-selection']['debit-order'] === 'Y'
              ) {
                changePage(state, 'debit-order-details')
              } else if (
                state.form['payment-method-selection']['stop-order'] === 'Y'
              ) {
                changePage(state, 'stop-order-details')
              } else if (
                state.form['payment-method-selection']['mobile-wallet'] === 'Y'
              ) {
                changePage(state, 'mobile-wallet-details')
              }
            }
            if (state.form['payer-person']['myself'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['life-assured-identification'][field])
              )
            }
            break
        }
        break

      case 'payer-details':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              if (
                state.form['payment-method-selection']['debit-order'] === 'Y'
              ) {
                changePage(state, 'debit-order-details')
              } else if (
                state.form['payment-method-selection']['stop-order'] === 'Y'
              ) {
                changePage(state, 'stop-order-details')
              } else if (
                state.form['payment-method-selection']['mobile-wallet'] === 'Y'
              ) {
                changePage(state, 'mobile-wallet-details')
              }
            }
            break

          case 'back':
            changePage(state, 'payment-details')
            break
        }
        break

      case 'debit-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details-acceptance')
            }
            break
        }
        break

      case 'stop-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details-acceptance')
            }
            break
        }
        break

      case 'mobile-wallet-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details-acceptance')
            }
            break
        }
        break

      case 'payment-details-acceptance':
        switch (button.id) {
          case 'back':
            if (state.form['payment-method-selection']['debit-order'] === 'Y') {
              changePage(state, 'debit-order-details')
            } else if (
              state.form['payment-method-selection']['stop-order'] === 'Y'
            ) {
              changePage(state, 'stop-order-details')
            } else if (
              state.form['payment-method-selection']['mobile-wallet'] === 'Y'
            ) {
              changePage(state, 'mobile-wallet-details')
            }
            break

          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
            break
        }
        break

      case 'upload-documents':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details-acceptance')
            break
          case 'upload-id-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
          case 'next':
            changePage(state, 'acceptance-screen')
            generateAcceptancePage(state)
            break
        }
        break

      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            if (state.form.documents[state.local.document].length > 0) {
              changePage(state, 'upload-documents')
            } else {
              changePage(state, 'upload-documents')
            }
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'back':
            changePage(state, 'upload-documents')
            break
          case 'submit':
            if (validate(state)) {
              state.form['application-accepted'] = 'Y'
              state.form['application-acceptance-date'] =
                dayjs().format('YYYYMMDD')
              state.form['total-premium'] = totalPremium(state.form)
              asyncDispatch(completeProcess(state.form['reference-number']))
            }
            break
        }
        break
    }
    setPageItemFromState(state)
  }
}

// processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-screen':
      switch (data.id) {
        case 'date-of-birth':
          state.form['life-assured-identification']['date-of-birth'] =
            data.value
          dirty = true
          break
        case 'marital-status':
          state.form['life-assured-identification']['marital-status'] =
            data.value
          dirty = true
          break
        case 'gender':
          state.form['life-assured-identification']['gender'] = data.value
          dirty = true
          break
        case 'ghc-250000':
        case 'ghc-500000':
        case 'ghc-750000':
        case 'ghc-1000000':
          state.form['main-cover-level'][data.id] = data.value
          dirty = true
          break
      }
      break
    case 'additional-cover':
      switch (data.id) {
        case 'cash-bonus-premium':
          state.form['cash-bonus-premium'] = data.value
          dirty = true
          break
        case 'inflation-protector':
          state.form['inflation-protector'] = data.value
          dirty = true
          break
      }
      break

    case 'family-members-age-and-gender':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'family-members-quotation-details':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-identification-type',
        'life-assured-contact-details',
        'life-assured-postal-details',
        'life-assured-residential-address',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      break

    case 'trustee-details':
      ;['trustee-identification'].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      break

    case 'additional-lives':
      let id = data.id.split('-')
      const index = parseInt(id.pop())
      if (!isNaN(index) && index < state.form['lives-assured'].length) {
        id = id.join('-')
        if (id in state.form['lives-assured'][index]) {
          state.form['lives-assured'][index][id] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break

    case 'payment-details':
      switch (data.id) {
        case 'debit-order':
        case 'stop-order':
        case 'mobile-wallet':
          state.form['payment-method-selection'][data.id] = data.value
          dirty = true
          break
        case 'payment-start-date':
          const date = dayjs(data.value)
          state.form['payment-start-date'] = date.isValid()
            ? date.format('YYYYMMDD')
            : null
          dirty = true
          break
        case 'monthly':
        case 'quarterly':
        case 'bi-annually':
        case 'annually':
          state.form['payment-frequency'][data.id] = data.value
          dirty = true
          break
        case 'myself':
        case 'my-spouse':
          state.form['payer-person'][data.id] = data.value
          dirty = true
          break
      }
      break

    case 'payer-details':
      ;['payer-person-identification'].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      break

    case 'debit-order-details':
      if (data.id in state.form['payment-bank-details']) {
        state.form['payment-bank-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'stop-order-details':
      if (data.id in state.form['stop-order-details']) {
        state.form['stop-order-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'mobile-wallet-details':
      if (data.id in state.form['mobile-wallet-details']) {
        state.form['mobile-wallet-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'payment-details-acceptance':
      if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

export default {
  createState,
  processButton,
  processChange,
}
