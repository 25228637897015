/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { connect } from 'react-redux'
import { startProcess, resumeProcess, exportData } from '../core/actions'
import { products } from '../core/logic'
import Form from '../components/Form'

dayjs.extend(relativeTime)

const tooltip = (status) => {
  switch (status) {
    case 'pending':
      return 'Pending'
    case 'uploading':
      return 'Uploading'
    case 'uploaded':
      return 'Received'
    case 'complete':
      return 'Processed'
    case 'rejected':
      return 'Rejected'
  }
}

const ProductSelection = ({ postponed, completed, dispatch }) => (
  <div className='container'>
    <Form items={[]} incomplete={[]} onSubmit={() => {}}>
      <ul className='product-page'>
        <li id='products'>
          {products.map((product, idx) => (
            <div className='product' id={product.id} key={idx}>
              <h1 className='product-label'>{product.label}</h1>
              <a
                className='button'
                href='#'
                onClick={() => dispatch(startProcess({ product: product.id }))}
              >
                Start new application
              </a>
            </div>
          ))}
        </li>
        <li>
          {postponed.length > 0 && (
            <div className='applications'>
              <div className='section-title'>
                <h3>Applications in Progress</h3>
              </div>
              {postponed.map((p, k) => (
                <a
                  href='#'
                  className='row'
                  key={k}
                  onClick={(e) => {
                    e.preventDefault()
                    dispatch(resumeProcess(p.id))
                  }}
                >
                  <div className='content'>
                    <div>
                      {p.process.label}
                      <br />
                      <span>
                        <b>
                          {
                            p.process.form['life-assured-identification'][
                              'first-names'
                            ]
                          }{' '}
                          {
                            p.process.form['life-assured-identification']
                              .surname
                          }
                        </b>
                      </span>
                    </div>
                    <span className='status'>
                      Postponed {dayjs(p.at).fromNow()}
                    </span>
                  </div>
                  <span className='incomplete icon'>icon</span>
                </a>
              ))}
            </div>
          )}
          {completed.length > 0 && (
            <div className='applications'>
              <div className='section-title'>
                <h3>Completed Applications</h3>
                <span
                  className='export-data'
                  onClick={() => dispatch(exportData())}
                >
                  <span className='tooltip'>Export</span>
                </span>
              </div>
              {completed.map((p, k) => (
                <div className='row' key={k + postponed.length}>
                  <div className='content'>
                    <div>
                      {p.label} |{' '}
                      <span className='application-name'>{p.reference}</span>
                      <br />
                      <span>
                        <b>
                          {p.form['life-assured-identification']['first-names']}{' '}
                          {p.form['life-assured-identification'].surname} |{' '}
                          {p.processId && `Process Id: ${p.processId}`}
                        </b>
                      </span>
                    </div>
                    <span className='status'>
                      Completed {dayjs(p.at).fromNow()}
                    </span>
                  </div>
                  <span className={`${p.status} icon`}>
                    icon <span className='tooltip'>{tooltip(p.status)}</span>
                  </span>
                </div>
              ))}
            </div>
          )}
        </li>
      </ul>
    </Form>
  </div>
)

export default connect((state) => ({
  postponed: state.environment.postponed.filter(
    (p) => p.process.agent === state.environment.agentContractId
  ),
  completed: state.environment.completed.filter(
    (p) => p.agent === state.environment.agentContractId
  ),
}))(ProductSelection)
