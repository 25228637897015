/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'

const useTooltips = process.env.APPLICATION_USE_TOOLTIPS === 'true'

const Field = ({
  value,
  id,
  inputType,
  itemType,
  elementType,
  itemIndex,
  tooltip,
  onClick
}) => {
  const isCurrency =
    process.env.APPLICATION_CURRENCY &&
    inputType === 'number' &&
    itemType === 'amount'
  const elementId = itemIndex ? `${id}-${itemIndex}` : id
  return (
    <div
      onClick={onClick}
      className={classNames({
        field: true,
        'has-tooltip': useTooltips && tooltip,
        'has-value': value.length > 0,
        'trigger-click': onClick ? true : undefined,
        [elementType]: true
      })}
    >
      <span id={elementId} data-tooltip={tooltip}>
        {isCurrency && `${process.env.APPLICATION_CURRENCY} `}
        {value}
      </span>
    </div>
  )
}

export default Field
