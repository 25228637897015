/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'

const Form = ({ items, hasTable, incomplete, children, onSubmit }) => (
  <form
    className={classNames({
      form: true,
      incomplete: incomplete.length > 0,
      'has-editable-table': hasTable,
    })}
    id='input-value'
    onSubmit={onSubmit}
  >
    {children}
    <input
      type='submit'
      formNoValidate
      style={{
        position: 'absolute',
        left: '-9999px',
        width: '1px',
        height: '1px',
      }}
      tabIndex='-1'
    />
  </form>
)

export default Form
