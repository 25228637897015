/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const rates = {
  Male: {
    'option-2:350000:44': 885.39,
    'option-3:350000:55': 1208.18,
    'option-1:75000:37': 81.29,
    'option-3:40000:40': 80.37,
    'option-2:65000:11': 47.31,
    'option-3:500000:25': 306.01,
    'option-1:450000:50': 962.08,
    'option-3:250000:26': 184.83,
    'option-2:90000:21': 59.87,
    'option-1:500000:7': 198.81,
    'option-3:55000:60': 263.16,
    'option-1:40000:16': 29.6,
    'option-3:80000:30': 68.9,
    'option-4:90000:1': 78.73,
    'option-2:500000:38': 782.76,
    'option-1:95000:9': 49.46,
    'option-1:70000:58': 316.26,
    'option-1:20000:10': 22.35,
    'option-3:90000:50': 220.41,
    'option-3:150000:43': 251.06,
    'option-4:450000:60': 4191.97,
    'option-4:450000:10': 339.37,
    'option-3:80000:31': 80.67,
    'option-4:150000:34': 194.94,
    'option-2:15000:3': 22.6,
    'option-3:450000:12': 277.08,
    'option-4:65000:27': 73.28,
    'option-2:450000:4': 243.1,
    'option-4:95000:9': 82.32,
    'option-4:95000:32': 128.07,
    'option-3:10000:55': 46.58,
    'option-4:50000:0': 50.18,
    'option-4:85000:19': 75.13,
    'option-4:150000:12': 121.72,
    'option-4:40000:48': 177.27,
    'option-3:60000:23': 49.3,
    'option-3:20000:11': 26.19,
    'option-1:400000:38': 376.1,
    'option-1:400000:58': 1777.3,
    'option-1:40000:55': 136.18,
    'option-4:500000:13': 375.29,
    'option-3:10000:22': 21.08,
    'option-3:500000:6': 306.01,
    'option-4:70000:36': 134.66,
    'option-1:20000:33': 26.89,
    'option-3:50000:0': 43.52,
    'option-3:30000:5': 31.96,
    'option-1:40000:53': 136.18,
    'option-2:95000:12': 62.38,
    'option-2:20000:7': 24.83,
    'option-2:500000:23': 268.23,
    'option-3:45000:17': 40.63,
    'option-4:5000:0': 19.16,
    'option-3:200000:32': 180.94,
    'option-1:400000:25': 162.51,
    'option-2:250000:38': 395.66,
    'option-3:150000:46': 360.94,
    'option-3:20000:33': 30.78,
    'option-3:500000:12': 306.01,
    'option-2:30000:51': 192.28,
    'option-1:200000:60': 890.4,
    'option-1:15000:48': 44.13,
    'option-4:5000:11': 19.16,
    'option-2:35000:0': 32.36,
    'option-3:85000:28': 72.33,
    'option-1:95000:48': 211.19,
    'option-4:30000:31': 49.59,
    'option-2:5000:0': 18.18,
    'option-2:70000:52': 446.04,
    'option-2:450000:17': 243.1,
    'option-4:75000:47': 326.51,
    'option-1:45000:58': 205.69,
    'option-1:45000:30': 35.9,
    'option-2:400000:25': 217.84,
    'option-2:55000:3': 42.39,
    'option-2:80000:14': 54.84,
    'option-1:150000:26': 84.91,
    'option-4:55000:25': 53.77,
    'option-4:400000:40': 715.69,
    'option-2:80000:36': 135.33,
    'option-2:80000:54': 509.5,
    'option-2:45000:42': 122.44,
    'option-3:100000:55': 349.79,
    'option-4:100000:50': 432.3,
    'option-2:35000:18': 32.36,
    'option-2:400000:4': 217.84,
    'option-1:70000:9': 40.39,
    'option-3:75000:47': 185.87,
    'option-3:65000:6': 52.19,
    'option-2:100000:34': 113.09,
    'option-4:25000:46': 113.09,
    'option-3:300000:27': 221.87,
    'option-2:55000:34': 68.44,
    'option-2:55000:46': 229.12,
    'option-1:15000:24': 20.75,
    'option-3:150000:32': 139.03,
    'option-4:150000:52': 988.55,
    'option-2:30000:34': 43.5,
    'option-2:65000:37': 112.28,
    'option-2:400000:48': 1625.34,
    'option-1:350000:45': 495.51,
    'option-3:30000:9': 31.96,
    'option-2:45000:26': 45.77,
    'option-1:250000:6': 105.52,
    'option-1:5000:23': 17.54,
    'option-4:90000:25': 78.73,
    'option-3:5000:59': 37.75,
    'option-3:500000:36': 573.54,
    'option-3:50000:33': 55.79,
    'option-3:65000:18': 52.19,
    'option-4:80000:52': 527.97,
    'option-4:75000:56': 695.84,
    'option-1:85000:42': 129.42,
    'option-3:95000:34': 93.2,
    'option-3:450000:25': 277.08,
    'option-2:45000:51': 287.23,
    'option-3:15000:14': 23.62,
    'option-2:500000:16': 268.23,
    'option-1:200000:38': 193.58,
    'option-3:450000:22': 277.08,
    'option-2:60000:32': 73.43,
    'option-2:95000:28': 80.55,
    'option-4:15000:47': 73.02,
    'option-3:450000:60': 2107.81,
    'option-2:10000:28': 22.14,
    'option-4:30000:51': 198.87,
    'option-4:250000:25': 193.24,
    'option-4:45000:51': 297.37,
    'option-1:95000:54': 311.0,
    'option-1:5000:5': 17.54,
    'option-3:450000:55': 1548.61,
    'option-2:400000:53': 2542.17,
    'option-3:75000:23': 57.84,
    'option-4:100000:48': 432.3,
    'option-1:400000:57': 1777.3,
    'option-1:5000:54': 30.38,
    'option-1:40000:10': 29.6,
    'option-3:250000:19': 158.81,
    'option-3:40000:15': 37.75,
    'option-3:400000:48': 947.38,
    'option-1:150000:50': 327.62,
    'option-1:50000:23': 33.22,
    'option-4:70000:22': 64.52,
    'option-4:55000:11': 53.77,
    'option-3:80000:8': 60.74,
    'option-1:100000:57': 447.9,
    'option-3:45000:50': 115.77,
    'option-1:300000:42': 426.63,
    'option-2:35000:52': 224.09,
    'option-3:45000:43': 83.96,
    'option-3:90000:9': 66.53,
    'option-3:55000:0': 46.41,
    'option-3:300000:54': 1036.52,
    'option-1:65000:41': 101.99,
    'option-3:25000:8': 29.07,
    'option-2:60000:17': 44.9,
    'option-2:85000:20': 57.35,
    'option-1:300000:13': 126.42,
    'option-2:40000:29': 42.27,
    'option-2:90000:46': 370.56,
    'option-3:25000:3': 29.07,
    'option-1:20000:51': 72.59,
    'option-4:100000:2': 85.91,
    'option-3:350000:26': 256.17,
    'option-4:85000:45': 240.45,
    'option-3:55000:51': 196.52,
    'option-2:200000:35': 212.89,
    'option-4:35000:60': 322.73,
    'option-4:50000:43': 145.46,
    'option-1:35000:27': 31.23,
    'option-2:75000:36': 127.65,
    'option-4:5000:35': 21.38,
    'option-4:65000:38': 125.89,
    'option-1:500000:41': 700.73,
    'option-3:15000:1': 23.62,
    'option-2:30000:20': 29.85,
    'option-3:70000:6': 54.96,
    'option-4:450000:54': 2961.88,
    'option-2:95000:37': 158.06,
    'option-1:90000:42': 136.0,
    'option-1:50000:38': 58.82,
    'option-3:400000:23': 247.96,
    'option-3:250000:54': 861.57,
    'option-2:50000:27': 49.26,
    'option-3:65000:27': 58.8,
    'option-1:100000:14': 51.27,
    'option-3:80000:40': 102.69,
    'option-4:450000:45': 1231.7,
    'option-4:250000:31': 315.44,
    'option-1:15000:16': 20.75,
    'option-2:55000:4': 42.39,
    'option-3:5000:14': 18.54,
    'option-4:25000:15': 32.23,
    'option-4:300000:37': 539.95,
    'option-2:10000:40': 29.61,
    'option-1:25000:40': 36.38,
    'option-1:250000:59': 1108.9,
    'option-3:25000:41': 52.18,
    'option-2:45000:18': 37.38,
    'option-2:30000:1': 29.85,
    'option-3:400000:29': 289.7,
    'option-2:500000:46': 2027.44,
    'option-2:95000:0': 62.38,
    'option-1:150000:22': 69.4,
    'option-1:40000:36': 49.82,
    'option-3:150000:26': 116.71,
    'option-2:85000:4': 57.35,
    'option-2:10000:42': 38.16,
    'option-2:300000:27': 226.37,
    'option-1:10000:20': 19.14,
    'option-4:80000:36': 152.18,
    'option-2:150000:39': 242.88,
    'option-1:20000:30': 24.22,
    'option-3:300000:16': 190.55,
    'option-3:250000:49': 593.1,
    'option-3:5000:12': 18.54,
    'option-3:150000:0': 101.2,
    'option-1:200000:48': 432.98,
    'option-3:300000:3': 190.55,
    'option-3:450000:9': 277.08,
    'option-3:500000:47': 1179.92,
    'option-1:55000:13': 34.95,
    'option-1:350000:2': 144.61,
    'option-2:55000:8': 42.39,
    'option-4:65000:22': 60.94,
    'option-2:25000:8': 27.34,
    'option-2:65000:25': 47.31,
    'option-3:250000:23': 158.81,
    'option-1:50000:11': 33.22,
    'option-2:200000:42': 508.78,
    'option-4:350000:32': 439.93,
    'option-3:200000:34': 180.94,
    'option-4:10000:39': 31.57,
    'option-3:15000:53': 61.73,
    'option-4:40000:2': 43.01,
    'option-2:30000:48': 128.21,
    'option-3:55000:27': 51.97,
    'option-1:500000:56': 2217.34,
    'option-4:50000:27': 68.71,
    'option-2:400000:54': 2542.17,
    'option-3:500000:27': 358.38,
    'option-1:90000:36': 94.58,
    'option-2:35000:8': 32.36,
    'option-4:200000:42': 552.22,
    'option-2:90000:38': 150.39,
    'option-2:100000:54': 635.05,
    'option-4:70000:2': 64.52,
    'option-4:450000:47': 1926.72,
    'option-4:95000:3': 82.32,
    'option-4:40000:32': 61.66,
    'option-3:55000:48': 139.15,
    'option-2:500000:58': 4532.46,
    'option-2:75000:49': 309.96,
    'option-3:35000:60': 169.91,
    'option-1:50000:31': 45.45,
    'option-1:55000:5': 34.95,
    'option-1:250000:4': 105.52,
    'option-2:30000:10': 29.85,
    'option-4:35000:20': 39.42,
    'option-3:30000:31': 39.08,
    'option-3:50000:15': 43.52,
    'option-2:65000:23': 47.31,
    'option-4:75000:33': 104.09,
    'option-3:50000:14': 43.52,
    'option-1:20000:38': 31.89,
    'option-3:15000:51': 61.73,
    'option-2:50000:50': 208.9,
    'option-2:250000:53': 1584.07,
    'option-4:450000:49': 1926.72,
    'option-1:20000:31': 26.89,
    'option-2:50000:16': 39.88,
    'option-3:75000:53': 264.97,
    'option-2:70000:39': 119.97,
    'option-2:65000:55': 414.3,
    'option-2:70000:24': 49.81,
    'option-1:100000:22': 51.27,
    'option-3:45000:49': 115.77,
    'option-2:15000:15': 22.6,
    'option-2:95000:26': 80.55,
    'option-4:40000:59': 369.44,
    'option-3:50000:58': 239.79,
    'option-2:30000:45': 85.15,
    'option-3:95000:5': 69.42,
    'option-2:300000:52': 1906.22,
    'option-1:300000:17': 126.42,
    'option-1:300000:44': 426.63,
    'option-1:5000:48': 25.44,
    'option-2:75000:17': 52.32,
    'option-4:25000:28': 36.76,
    'option-1:75000:21': 42.21,
    'option-4:90000:35': 122.0,
    'option-4:350000:18': 267.99,
    'option-2:450000:52': 2855.71,
    'option-2:95000:34': 108.1,
    'option-1:300000:30': 157.67,
    'option-4:45000:56': 415.5,
    'option-1:35000:28': 31.23,
    'option-1:350000:55': 1126.92,
    'option-1:100000:10': 51.27,
    'option-1:200000:58': 890.4,
    'option-3:400000:35': 350.64,
    'option-1:5000:7': 17.54,
    'option-2:450000:49': 1825.01,
    'option-1:30000:38': 40.82,
    'option-3:400000:8': 247.96,
    'option-4:50000:6': 50.18,
    'option-2:70000:28': 63.23,
    'option-2:60000:53': 382.56,
    'option-1:75000:54': 247.96,
    'option-3:85000:6': 63.63,
    'option-1:500000:2': 198.81,
    'option-2:15000:26': 25.24,
    'option-2:90000:31': 103.11,
    'option-2:25000:6': 27.34,
    'option-3:50000:46': 127.46,
    'option-4:85000:12': 75.13,
    'option-1:500000:5': 198.81,
    'option-4:95000:51': 625.35,
    'option-2:450000:20': 243.1,
    'option-4:90000:23': 78.73,
    'option-4:30000:28': 41.27,
    'option-2:60000:59': 540.97,
    'option-1:10000:19': 19.14,
    'option-4:450000:19': 339.37,
    'option-2:95000:50': 389.94,
    'option-3:35000:2': 34.85,
    'option-4:90000:53': 593.8,
    'option-4:45000:60': 415.5,
    'option-4:65000:57': 602.48,
    'option-4:95000:37': 178.09,
    'option-2:100000:47': 410.11,
    'option-4:25000:22': 32.23,
    'option-4:70000:60': 649.16,
    'option-1:75000:38': 81.29,
    'option-2:35000:60': 314.31,
    'option-1:75000:35': 60.79,
    'option-4:5000:50': 35.24,
    'option-4:40000:24': 43.01,
    'option-2:45000:39': 81.53,
    'option-1:45000:3': 31.41,
    'option-1:10000:14': 19.14,
    'option-4:200000:21': 157.69,
    'option-2:40000:5': 34.87,
    'option-3:500000:29': 358.38,
    'option-3:95000:12': 69.42,
    'option-3:55000:9': 46.41,
    'option-3:200000:20': 130.15,
    'option-1:75000:23': 42.21,
    'option-4:95000:25': 82.32,
    'option-4:40000:16': 43.01,
    'option-1:30000:53': 104.56,
    'option-2:85000:39': 143.02,
    'option-1:40000:18': 29.6,
    'option-1:30000:57': 139.54,
    'option-4:75000:23': 67.96,
    'option-4:80000:32': 110.14,
    'option-3:40000:28': 41.72,
    'option-4:75000:31': 104.09,
    'option-4:80000:3': 71.54,
    'option-3:350000:0': 219.56,
    'option-3:150000:14': 101.2,
    'option-4:150000:32': 194.94,
    'option-1:100000:51': 326.98,
    'option-1:70000:0': 40.39,
    'option-4:25000:30': 36.76,
    'option-4:5000:52': 45.81,
    'option-1:95000:17': 49.46,
    'option-4:15000:10': 25.51,
    'option-4:70000:37': 134.66,
    'option-2:90000:1': 59.87,
    'option-2:50000:26': 49.26,
    'option-4:20000:41': 64.1,
    'option-2:450000:55': 2855.71,
    'option-2:30000:12': 29.85,
    'option-1:15000:47': 44.13,
    'option-2:70000:42': 184.83,
    'option-3:100000:0': 72.32,
    'option-4:35000:14': 39.42,
    'option-2:10000:24': 20.37,
    'option-3:10000:54': 46.58,
    'option-1:25000:30': 26.55,
    'option-3:20000:6': 26.19,
    'option-1:95000:5': 49.46,
    'option-1:75000:24': 42.21,
    'option-1:30000:21': 25.97,
    'option-1:30000:18': 25.97,
    'option-2:5000:47': 34.21,
    'option-4:300000:38': 539.95,
    'option-2:30000:22': 29.85,
    'option-4:500000:59': 4660.16,
    'option-1:45000:34': 42.36,
    'option-2:65000:8': 47.31,
    'option-4:450000:24': 339.37,
    'option-3:350000:46': 830.5,
    'option-4:25000:59': 229.54,
    'option-4:100000:4': 85.91,
    'option-2:50000:46': 208.9,
    'option-4:500000:21': 375.29,
    'option-1:70000:8': 40.39,
    'option-2:500000:50': 2027.44,
    'option-1:75000:49': 169.45,
    'option-3:30000:30': 34.89,
    'option-4:70000:0': 64.52,
    'option-4:25000:55': 165.77,
    'option-4:35000:51': 231.88,
    'option-2:60000:37': 104.6,
    'option-3:25000:16': 29.07,
    'option-3:400000:55': 1379.44,
    'option-4:400000:60': 3730.95,
    'option-1:5000:9': 17.54,
    'option-1:250000:22': 105.52,
    'option-3:10000:58': 58.52,
    'option-2:100000:58': 902.07,
    'option-4:400000:32': 500.58,
    'option-3:30000:23': 31.96,
    'option-3:20000:58': 99.92,
    'option-3:5000:20': 18.54,
    'option-4:20000:36': 47.06,
    'option-4:35000:26': 45.85,
    'option-4:45000:33': 67.72,
    'option-2:70000:55': 446.04,
    'option-1:30000:16': 25.97,
    'option-1:55000:56': 249.94,
    'option-4:85000:3': 75.13,
    'option-4:25000:52': 165.77,
    'option-2:40000:54': 255.85,
    'option-1:400000:5': 162.51,
    'option-2:400000:5': 217.84,
    'option-3:60000:30': 55.39,
    'option-3:90000:59': 425.64,
    'option-3:85000:21': 63.63,
    'option-3:50000:43': 91.93,
    'option-3:55000:55': 196.52,
    'option-1:45000:8': 31.41,
    'option-4:30000:23': 35.83,
    'option-3:65000:16': 52.19,
    'option-4:85000:1': 75.13,
    'option-4:50000:23': 50.18,
    'option-3:30000:25': 31.96,
    'option-3:450000:40': 517.66,
    'option-4:40000:42': 118.22,
    'option-3:75000:1': 57.84,
    'option-2:35000:58': 314.31,
    'option-2:100000:44': 259.11,
    'option-4:30000:46': 134.53,
    'option-2:20000:43': 60.18,
    'option-1:10000:1': 19.14,
    'option-4:95000:60': 880.53,
    'option-2:300000:28': 226.37,
    'option-1:25000:2': 24.16,
    'option-3:70000:38': 91.53,
    'option-1:100000:21': 51.27,
    'option-2:30000:5': 29.85,
    'option-1:10000:41': 28.16,
    'option-1:40000:41': 67.67,
    'option-4:400000:59': 3730.95,
    'option-1:35000:47': 85.11,
    'option-4:25000:31': 43.53,
    'option-1:400000:41': 564.17,
    'option-2:65000:21': 47.31,
    'option-2:100000:59': 902.07,
    'option-1:200000:13': 87.52,
    'option-4:40000:12': 43.01,
    'option-3:200000:31': 180.94,
    'option-4:65000:19': 60.94,
    'option-4:90000:60': 835.82,
    'option-1:40000:43': 67.67,
    'option-4:300000:24': 231.99,
    'option-1:80000:29': 52.16,
    'option-1:85000:23': 45.83,
    'option-3:15000:36': 30.81,
    'option-1:5000:46': 25.44,
    'option-4:10000:56': 100.14,
    'option-4:30000:60': 275.93,
    'option-1:5000:56': 36.78,
    'option-1:55000:55': 184.18,
    'option-3:400000:49': 947.38,
    'option-1:450000:35': 295.33,
    'option-3:45000:39': 85.95,
    'option-1:300000:4': 126.42,
    'option-3:35000:50': 92.52,
    'option-3:400000:31': 350.64,
    'option-3:100000:54': 349.79,
    'option-4:40000:31': 61.66,
    'option-4:65000:15': 60.94,
    'option-2:85000:57': 767.91,
    'option-2:80000:47': 330.16,
    'option-1:350000:54': 1126.92,
    'option-4:85000:5': 75.13,
    'option-4:65000:9': 60.94,
    'option-3:200000:52': 692.37,
    'option-1:400000:48': 857.69,
    'option-1:15000:53': 58.6,
    'option-2:10000:4': 20.37,
    'option-4:450000:39': 802.43,
    'option-2:15000:48': 69.98,
    'option-4:300000:30': 290.48,
    'option-2:500000:15': 268.23,
    'option-1:75000:34': 60.79,
    'option-4:75000:15': 67.96,
    'option-3:85000:29': 72.33,
    'option-4:45000:35': 67.72,
    'option-3:55000:10': 46.41,
    'option-2:150000:31': 162.77,
    'option-3:65000:3': 52.19,
    'option-4:75000:41': 213.32,
    'option-2:300000:41': 760.29,
    'option-3:55000:44': 99.89,
    'option-4:150000:51': 988.55,
    'option-1:85000:4': 45.83,
    'option-1:100000:12': 51.27,
    'option-3:40000:17': 37.75,
    'option-1:50000:55': 168.22,
    'option-2:55000:45': 147.41,
    'option-4:70000:29': 77.85,
    'option-1:60000:43': 95.13,
    'option-1:95000:27': 59.19,
    'option-1:50000:56': 227.81,
    'option-1:5000:16': 17.54,
    'option-3:30000:10': 31.96,
    'option-2:50000:58': 450.11,
    'option-2:75000:58': 677.15,
    'option-1:30000:7': 25.97,
    'option-2:90000:49': 370.56,
    'option-3:80000:33': 80.67,
    'option-2:95000:11': 62.38,
    'option-4:100000:37': 186.87,
    'option-4:90000:32': 122.0,
    'option-4:50000:34': 73.79,
    'option-3:35000:23': 34.85,
    'option-3:80000:37': 102.69,
    'option-1:60000:11': 36.76,
    'option-2:50000:30': 49.26,
    'option-4:85000:31': 115.95,
    'option-2:400000:38': 629.74,
    'option-2:45000:54': 287.23,
    'option-3:85000:35': 84.83,
    'option-4:450000:12': 339.37,
    'option-2:200000:19': 115.13,
    'option-1:400000:8': 162.51,
    'option-4:35000:48': 155.91,
    'option-1:500000:11': 198.81,
    'option-4:95000:58': 880.53,
    'option-4:95000:12': 82.32,
    'option-2:300000:50': 1220.44,
    'option-4:95000:22': 82.32,
    'option-2:200000:40': 319.64,
    'option-2:5000:19': 18.18,
    'option-2:70000:26': 63.23,
    'option-1:450000:5': 180.68,
    'option-3:65000:58': 309.89,
    'option-3:450000:16': 277.08,
    'option-3:400000:16': 247.96,
    'option-1:350000:51': 1126.92,
    'option-3:45000:45': 83.96,
    'option-1:25000:31': 29.98,
    'option-4:450000:41': 1231.7,
    'option-2:5000:7': 18.18,
    'option-2:35000:2': 32.36,
    'option-3:500000:28': 358.38,
    'option-1:55000:0': 34.95,
    'option-2:200000:60': 1810.8,
    'option-4:90000:52': 593.8,
    'option-1:65000:34': 54.73,
    'option-3:50000:23': 43.52,
    'option-1:20000:50': 53.43,
    'option-3:250000:35': 222.3,
    'option-4:250000:50': 1073.05,
    'option-3:95000:14': 69.42,
    'option-3:70000:12': 54.96,
    'option-4:50000:36': 99.59,
    'option-2:65000:2': 47.31,
    'option-1:55000:42': 88.27,
    'option-3:25000:51': 93.87,
    'option-1:200000:57': 890.4,
    'option-4:60000:54': 396.28,
    'option-4:95000:54': 625.35,
    'option-1:40000:48': 95.55,
    'option-3:10000:3': 21.08,
    'option-1:35000:36': 45.32,
    'option-1:80000:9': 44.02,
    'option-3:40000:16': 37.75,
    'option-4:25000:47': 113.09,
    'option-3:200000:18': 130.15,
    'option-4:80000:14': 71.54,
    'option-4:250000:15': 193.24,
    'option-3:200000:40': 236.51,
    'option-2:25000:50': 107.91,
    'option-2:400000:8': 217.84,
    'option-1:45000:59': 205.69,
    'option-4:20000:24': 28.65,
    'option-1:15000:3': 20.75,
    'option-2:75000:57': 677.15,
    'option-2:20000:46': 87.9,
    'option-1:200000:17': 87.52,
    'option-1:250000:12': 105.52,
    'option-2:100000:17': 64.89,
    'option-2:65000:44': 172.36,
    'option-3:100000:34': 97.38,
    'option-3:30000:13': 31.96,
    'option-2:200000:41': 508.78,
    'option-4:95000:29': 100.52,
    'option-2:80000:39': 135.33,
    'option-2:150000:14': 90.0,
    'option-3:45000:22': 40.63,
    'option-4:450000:27': 427.33,
    'option-4:15000:17': 25.51,
    'option-1:85000:46': 190.55,
    'option-4:250000:11': 193.24,
    'option-2:90000:55': 572.96,
    'option-4:90000:6': 78.73,
    'option-3:450000:32': 391.97,
    'option-3:40000:55': 144.99,
    'option-2:300000:18': 167.94,
    'option-1:400000:14': 162.51,
    'option-2:100000:41': 259.11,
    'option-3:85000:13': 63.63,
    'option-3:35000:6': 34.85,
    'option-3:15000:32': 27.07,
    'option-1:65000:7': 38.58,
    'option-2:75000:37': 127.65,
    'option-3:250000:55': 861.57,
    'option-2:70000:33': 83.39,
    'option-3:35000:41': 67.97,
    'option-4:250000:21': 193.24,
    'option-1:65000:5': 38.58,
    'option-1:85000:27': 54.51,
    'option-3:25000:19': 29.07,
    'option-1:500000:28': 251.07,
    'option-2:95000:5': 62.38,
    'option-2:65000:41': 172.36,
    'option-1:350000:35': 233.75,
    'option-1:5000:59': 36.78,
    'option-4:25000:0': 32.23,
    'option-4:250000:57': 2322.83,
    'option-1:400000:46': 857.69,
    'option-3:50000:12': 43.52,
    'option-1:65000:40': 72.3,
    'option-2:350000:45': 885.39,
    'option-3:20000:31': 30.78,
    'option-1:450000:22': 180.68,
    'option-1:250000:15': 105.52,
    'option-2:80000:53': 509.5,
    'option-1:50000:6': 33.22,
    'option-1:25000:29': 26.55,
    'option-2:350000:0': 193.15,
    'option-3:90000:22': 66.53,
    'option-3:10000:29': 22.02,
    'option-1:95000:20': 49.46,
    'option-2:200000:43': 508.78,
    'option-4:70000:10': 64.52,
    'option-2:50000:7': 39.88,
    'option-1:10000:2': 19.14,
    'option-4:10000:11': 22.33,
    'option-4:40000:54': 264.83,
    'option-4:90000:29': 95.94,
    'option-3:500000:52': 1720.14,
    'option-2:80000:22': 54.84,
    'option-2:35000:53': 224.09,
    'option-4:200000:19': 157.69,
    'option-3:400000:26': 289.7,
    'option-2:200000:44': 508.78,
    'option-1:80000:49': 180.0,
    'option-4:400000:4': 303.18,
    'option-3:10000:44': 30.14,
    'option-4:450000:56': 4191.97,
    'option-1:350000:16': 144.61,
    'option-1:65000:42': 101.99,
    'option-2:450000:12': 243.1,
    'option-1:80000:12': 44.02,
    'option-3:350000:37': 406.59,
    'option-4:80000:34': 110.14,
    'option-4:85000:39': 160.95,
    'option-1:15000:18': 20.75,
    'option-4:450000:15': 339.37,
    'option-4:450000:6': 339.37,
    'option-1:250000:60': 1108.9,
    'option-4:45000:58': 415.5,
    'option-1:55000:12': 34.95,
    'option-2:450000:37': 705.79,
    'option-3:85000:22': 63.63,
    'option-2:10000:29': 22.14,
    'option-1:80000:0': 44.02,
    'option-1:350000:57': 1556.09,
    'option-2:85000:24': 57.35,
    'option-2:5000:21': 18.18,
    'option-2:30000:59': 268.81,
    'option-4:85000:36': 160.95,
    'option-4:30000:57': 275.93,
    'option-1:500000:0': 198.81,
    'option-2:65000:12': 47.31,
    'option-4:5000:45': 28.16,
    'option-1:150000:60': 669.67,
    'option-2:25000:24': 27.34,
    'option-4:75000:16': 67.96,
    'option-4:500000:37': 890.21,
    'option-2:15000:29': 25.24,
    'option-2:90000:6': 59.87,
    'option-3:90000:53': 315.56,
    'option-4:400000:0': 303.18,
    'option-3:200000:15': 130.15,
    'option-4:150000:42': 416.72,
    'option-4:20000:45': 64.1,
    'option-4:50000:5': 50.18,
    'option-3:75000:10': 57.84,
    'option-2:300000:15': 167.94,
    'option-4:65000:11': 60.94,
    'option-4:30000:56': 275.93,
    'option-1:20000:2': 22.35,
    'option-2:55000:0': 42.39,
    'option-4:10000:12': 22.33,
    'option-2:50000:10': 39.88,
    'option-2:95000:30': 80.55,
    'option-4:400000:15': 303.18,
    'option-1:400000:43': 564.17,
    'option-4:40000:5': 43.01,
    'option-1:150000:41': 218.55,
    'option-2:500000:51': 3173.99,
    'option-4:45000:25': 46.6,
    'option-2:35000:39': 66.1,
    'option-2:80000:10': 54.84,
    'option-2:35000:20': 32.36,
    'option-1:10000:44': 28.16,
    'option-3:35000:18': 34.85,
    'option-1:500000:25': 198.81,
    'option-4:65000:32': 91.97,
    'option-4:70000:24': 64.52,
    'option-4:25000:3': 32.23,
    'option-4:10000:51': 74.88,
    'option-2:30000:3': 29.85,
    'option-3:25000:6': 29.07,
    'option-1:500000:32': 326.33,
    'option-1:5000:41': 22.09,
    'option-3:80000:3': 60.74,
    'option-1:450000:45': 632.04,
    'option-1:30000:17': 25.97,
    'option-2:250000:37': 395.66,
    'option-2:5000:3': 18.18,
    'option-4:5000:17': 19.16,
    'option-2:35000:4': 32.36,
    'option-2:400000:47': 1625.34,
    'option-2:95000:43': 246.66,
    'option-2:450000:53': 2855.71,
    'option-2:30000:52': 192.28,
    'option-4:25000:48': 113.09,
    'option-4:75000:5': 67.96,
    'option-3:300000:26': 221.87,
    'option-2:25000:47': 107.91,
    'option-1:150000:14': 69.4,
    'option-3:85000:50': 209.22,
    'option-1:95000:57': 425.75,
    'option-4:70000:16': 64.52,
    'option-1:45000:5': 31.41,
    'option-2:5000:54': 44.79,
    'option-1:85000:60': 382.56,
    'option-4:150000:46': 646.43,
    'option-4:100000:49': 432.3,
    'option-3:65000:55': 230.75,
    'option-1:60000:39': 67.8,
    'option-2:75000:14': 52.32,
    'option-3:450000:38': 517.66,
    'option-3:20000:30': 28.06,
    'option-4:250000:59': 2322.83,
    'option-1:45000:46': 106.12,
    'option-2:250000:29': 188.58,
    'option-4:300000:46': 1288.12,
    'option-4:95000:46': 410.99,
    'option-3:40000:60': 193.28,
    'option-2:350000:55': 2224.55,
    'option-1:150000:37': 148.41,
    'option-1:30000:60': 139.54,
    'option-2:60000:22': 44.9,
    'option-2:200000:45': 508.78,
    'option-2:250000:7': 140.03,
    'option-3:400000:50': 947.38,
    'option-4:400000:52': 2636.61,
    'option-1:300000:56': 1334.37,
    'option-2:55000:39': 96.91,
    'option-2:300000:11': 167.94,
    'option-2:60000:19': 44.9,
    'option-1:250000:23': 105.52,
    'option-1:15000:20': 20.75,
    'option-2:30000:18': 29.85,
    'option-2:150000:12': 90.0,
    'option-1:40000:6': 29.6,
    'option-1:400000:23': 162.51,
    'option-1:55000:20': 34.95,
    'option-3:50000:21': 43.52,
    'option-3:10000:28': 22.02,
    'option-1:30000:14': 25.97,
    'option-2:70000:59': 631.76,
    'option-3:20000:39': 35.77,
    'option-4:5000:56': 58.98,
    'option-4:200000:40': 362.39,
    'option-2:150000:28': 118.93,
    'option-3:300000:15': 190.55,
    'option-1:60000:42': 95.13,
    'option-4:40000:50': 177.27,
    'option-3:70000:50': 174.19,
    'option-4:45000:47': 198.4,
    'option-2:10000:20': 20.37,
    'option-1:250000:49': 537.32,
    'option-2:55000:16': 42.39,
    'option-3:100000:8': 72.32,
    'option-4:5000:18': 19.16,
    'option-2:80000:20': 54.84,
    'option-1:85000:30': 54.51,
    'option-4:150000:45': 416.72,
    'option-3:400000:17': 247.96,
    'option-2:25000:29': 31.79,
    'option-3:100000:13': 72.32,
    'option-1:500000:19': 198.81,
    'option-1:5000:13': 17.54,
    'option-2:80000:13': 54.84,
    'option-2:45000:16': 37.38,
    'option-4:500000:45': 1367.67,
    'option-4:20000:39': 47.06,
    'option-3:60000:50': 150.83,
    'option-3:450000:49': 1062.91,
    'option-2:450000:29': 330.95,
    'option-4:65000:20': 60.94,
    'option-4:65000:2': 60.94,
    'option-1:350000:29': 181.15,
    'option-3:500000:45': 810.09,
    'option-2:75000:55': 477.77,
    'option-1:45000:28': 35.9,
    'option-4:10000:40': 31.57,
    'option-1:10000:45': 28.16,
    'option-2:250000:57': 2259.38,
    'option-3:30000:4': 31.96,
    'option-1:200000:53': 646.16,
    'option-4:85000:27': 91.36,
    'option-2:70000:18': 49.81,
    'option-3:100000:43': 171.2,
    'option-2:85000:1': 57.35,
    'option-2:65000:56': 586.37,
    'option-3:20000:17': 26.19,
    'option-4:55000:4': 53.77,
    'option-4:500000:49': 2140.7,
    'option-1:500000:14': 198.81,
    'option-4:60000:56': 555.79,
    'option-1:25000:26': 26.55,
    'option-2:400000:41': 1010.21,
    'option-2:200000:23': 115.13,
    'option-3:70000:24': 54.96,
    'option-4:30000:40': 64.6,
    'option-2:450000:41': 1133.53,
    'option-3:55000:14': 46.41,
    'option-2:60000:54': 382.56,
    'option-3:55000:50': 139.15,
    'option-4:55000:10': 53.77,
    'option-2:150000:25': 90.0,
    'option-1:75000:55': 247.96,
    'option-3:450000:2': 277.08,
    'option-2:60000:46': 249.34,
    'option-3:95000:1': 69.42,
    'option-3:40000:33': 47.44,
    'option-3:95000:10': 69.42,
    'option-2:300000:56': 2719.24,
    'option-4:20000:57': 182.88,
    'option-3:90000:29': 75.75,
    'option-2:15000:40': 36.35,
    'option-4:45000:36': 90.81,
    'option-1:450000:15': 180.68,
    'option-2:25000:13': 27.34,
    'option-2:50000:45': 134.93,
    'option-4:150000:4': 121.72,
    'option-4:100000:44': 280.55,
    'option-4:40000:57': 369.44,
    'option-3:250000:37': 291.74,
    'option-2:55000:58': 495.55,
    'option-1:65000:43': 101.99,
    'option-1:400000:49': 857.69,
    'option-1:75000:50': 169.45,
    'option-1:25000:38': 36.38,
    'option-2:55000:21': 42.39,
    'option-2:85000:55': 541.23,
    'option-2:20000:22': 24.83,
    'option-1:45000:39': 54.32,
    'option-3:25000:30': 31.46,
    'option-4:50000:21': 50.18,
    'option-1:95000:40': 99.08,
    'option-1:45000:17': 31.41,
    'option-4:80000:22': 71.54,
    'option-4:100000:1': 85.91,
    'option-1:55000:53': 184.18,
    'option-3:450000:50': 1062.91,
    'option-4:100000:5': 85.91,
    'option-3:40000:53': 144.99,
    'option-3:75000:4': 57.84,
    'option-1:350000:25': 144.61,
    'option-2:70000:6': 49.81,
    'option-3:65000:23': 52.19,
    'option-4:300000:0': 231.99,
    'option-4:100000:45': 280.55,
    'option-1:60000:33': 51.64,
    'option-2:100000:5': 64.89,
    'option-2:35000:46': 148.45,
    'option-2:20000:48': 87.9,
    'option-2:15000:50': 69.98,
    'option-4:55000:28': 64.14,
    'option-3:35000:52': 128.19,
    'option-3:250000:57': 1170.85,
    'option-1:15000:0': 20.75,
    'option-1:35000:10': 27.79,
    'option-3:40000:1': 37.75,
    'option-4:450000:40': 802.43,
    'option-4:45000:30': 59.57,
    'option-1:25000:27': 26.55,
    'option-4:80000:16': 71.54,
    'option-1:450000:60': 1995.74,
    'option-4:35000:39': 73.37,
    'option-1:25000:23': 24.16,
    'option-2:350000:36': 552.81,
    'option-4:200000:8': 157.69,
    'option-1:350000:19': 144.61,
    'option-4:150000:10': 121.72,
    'option-1:45000:20': 31.41,
    'option-3:35000:1': 34.85,
    'option-3:400000:52': 1379.44,
    'option-4:400000:48': 1715.79,
    'option-1:65000:48': 148.35,
    'option-1:75000:4': 42.21,
    'option-1:150000:32': 107.19,
    'option-4:50000:22': 50.18,
    'option-3:350000:35': 308.87,
    'option-1:85000:43': 129.42,
    'option-2:80000:3': 54.84,
    'option-3:95000:18': 69.42,
    'option-3:500000:3': 306.01,
    'option-1:10000:11': 19.14,
    'option-3:200000:26': 150.97,
    'option-2:35000:13': 32.36,
    'option-1:10000:43': 28.16,
    'option-2:500000:4': 268.23,
    'option-2:100000:16': 64.89,
    'option-3:450000:10': 277.08,
    'option-3:20000:21': 26.19,
    'option-4:400000:42': 1097.57,
    'option-3:450000:34': 391.97,
    'option-1:450000:53': 1444.1,
    'option-3:75000:60': 356.61,
    'option-3:60000:22': 49.3,
    'option-4:30000:14': 35.83,
    'option-2:5000:23': 18.18,
    'option-1:20000:3': 22.35,
    'option-4:90000:48': 390.53,
    'option-2:5000:14': 18.18,
    'option-3:300000:50': 713.32,
    'option-1:55000:50': 127.24,
    'option-4:15000:53': 104.02,
    'option-3:300000:28': 221.87,
    'option-3:450000:13': 277.08,
    'option-1:65000:1': 38.58,
    'option-2:55000:2': 42.39,
    'option-1:10000:46': 34.77,
    'option-3:15000:45': 37.13,
    'option-3:40000:30': 41.72,
    'option-4:350000:6': 267.99,
    'option-1:10000:48': 34.77,
    'option-3:80000:22': 60.74,
    'option-3:5000:17': 18.54,
    'option-2:30000:49': 128.21,
    'option-3:15000:55': 61.73,
    'option-4:10000:14': 22.33,
    'option-1:350000:33': 233.75,
    'option-3:40000:47': 104.04,
    'option-1:95000:7': 49.46,
    'option-1:150000:57': 669.67,
    'option-4:75000:2': 67.96,
    'option-1:85000:33': 66.98,
    'option-2:25000:14': 27.34,
    'option-1:75000:9': 42.21,
    'option-4:60000:21': 57.35,
    'option-4:55000:19': 53.77,
    'option-1:45000:49': 106.12,
    'option-4:45000:13': 46.6,
    'option-2:25000:3': 27.34,
    'option-2:30000:42': 85.15,
    'option-2:95000:2': 62.38,
    'option-2:25000:41': 72.65,
    'option-3:150000:49': 360.94,
    'option-4:20000:43': 64.1,
    'option-2:20000:32': 33.62,
    'option-1:400000:55': 1286.48,
    'option-4:60000:44': 172.61,
    'option-3:80000:13': 60.74,
    'option-2:500000:28': 365.95,
    'option-4:450000:43': 1231.7,
    'option-4:70000:13': 64.52,
    'option-3:85000:10': 63.63,
    'option-4:65000:49': 283.83,
    'option-4:250000:19': 193.24,
    'option-4:300000:31': 379.08,
    'option-2:90000:30': 77.06,
    'option-2:10000:27': 22.14,
    'option-2:40000:19': 34.87,
    'option-2:35000:5': 32.36,
    'option-3:250000:60': 1170.85,
    'option-3:30000:8': 31.96,
    'option-3:85000:0': 63.63,
    'option-4:95000:1': 82.32,
    'option-3:25000:54': 93.87,
    'option-3:10000:19': 21.08,
    'option-4:50000:38': 99.59,
    'option-1:60000:60': 272.05,
    'option-1:15000:37': 27.9,
    'option-2:65000:27': 59.74,
    'option-2:70000:8': 49.81,
    'option-4:15000:6': 25.51,
    'option-4:300000:49': 1288.12,
    'option-4:75000:49': 326.51,
    'option-1:55000:28': 40.57,
    'option-1:55000:11': 34.95,
    'option-1:15000:8': 20.75,
    'option-3:60000:25': 49.3,
    'option-3:30000:7': 31.96,
    'option-1:50000:28': 38.24,
    'option-3:45000:30': 45.14,
    'option-2:75000:54': 477.77,
    'option-4:15000:2': 25.51,
    'option-1:60000:48': 137.8,
    'option-4:75000:3': 67.96,
    'option-4:150000:50': 646.43,
    'option-1:100000:36': 103.59,
    'option-1:25000:25': 24.16,
    'option-2:350000:56': 3174.52,
    'option-2:75000:39': 127.65,
    'option-4:75000:18': 67.96,
    'option-3:500000:41': 810.09,
    'option-3:350000:41': 571.96,
    'option-3:250000:43': 409.28,
    'option-2:350000:53': 2224.55,
    'option-2:70000:16': 49.81,
    'option-3:350000:43': 571.96,
    'option-1:55000:6': 34.95,
    'option-2:5000:46': 34.21,
    'option-3:5000:37': 20.96,
    'option-4:150000:7': 121.72,
    'option-3:80000:24': 60.74,
    'option-4:85000:14': 75.13,
    'option-3:5000:38': 20.96,
    'option-2:5000:42': 27.19,
    'option-4:60000:48': 262.48,
    'option-2:5000:24': 18.18,
    'option-2:150000:11': 90.0,
    'option-4:150000:14': 121.72,
    'option-3:45000:1': 40.63,
    'option-2:500000:43': 1258.47,
    'option-1:40000:44': 67.67,
    'option-3:350000:32': 308.87,
    'option-1:30000:40': 40.82,
    'option-4:250000:0': 193.24,
    'option-4:80000:44': 226.89,
    'option-3:85000:27': 72.33,
    'option-3:450000:1': 277.08,
    'option-2:15000:2': 22.6,
    'option-2:150000:2': 90.0,
    'option-1:450000:36': 420.59,
    'option-4:75000:60': 695.84,
    'option-2:200000:48': 814.59,
    'option-3:250000:30': 184.83,
    'option-2:200000:51': 1270.4,
    'option-4:80000:30': 86.8,
    'option-4:150000:17': 121.72,
    'option-3:500000:57': 2341.99,
    'option-4:450000:17': 339.37,
    'option-4:90000:24': 78.73,
    'option-2:75000:2': 52.32,
    'option-1:15000:40': 27.9,
    'option-1:50000:5': 33.22,
    'option-1:250000:42': 354.92,
    'option-4:15000:1': 25.51,
    'option-3:55000:39': 74.79,
    'option-1:70000:39': 76.79,
    'option-3:60000:41': 107.86,
    'option-2:350000:50': 1423.1,
    'option-2:10000:30': 22.14,
    'option-4:95000:57': 880.53,
    'option-3:150000:60': 706.7,
    'option-4:45000:49': 198.4,
    'option-3:25000:10': 29.07,
    'option-2:150000:15': 90.0,
    'option-1:10000:22': 19.14,
    'option-1:55000:31': 48.55,
    'option-3:90000:25': 66.53,
    'option-4:350000:36': 627.94,
    'option-1:400000:22': 162.51,
    'option-1:150000:28': 84.91,
    'option-3:65000:57': 309.89,
    'option-4:60000:33': 85.91,
    'option-2:250000:28': 188.58,
    'option-3:90000:26': 75.75,
    'option-1:200000:47': 432.98,
    'option-3:150000:58': 706.7,
    'option-2:150000:20': 90.0,
    'option-1:60000:31': 51.64,
    'option-3:350000:28': 256.17,
    'option-4:400000:30': 382.08,
    'option-1:400000:26': 204.16,
    'option-2:100000:26': 84.05,
    'option-3:10000:51': 46.58,
    'option-4:150000:21': 121.72,
    'option-3:400000:21': 247.96,
    'option-4:55000:34': 79.85,
    'option-3:250000:38': 291.74,
    'option-2:25000:57': 223.69,
    'option-4:20000:3': 28.65,
    'option-2:65000:39': 112.28,
    'option-2:35000:6': 32.36,
    'option-3:100000:11': 72.32,
    'option-1:450000:20': 180.68,
    'option-3:95000:59': 448.95,
    'option-4:30000:42': 91.29,
    'option-2:65000:48': 269.55,
    'option-3:75000:8': 57.84,
    'option-3:500000:58': 2341.99,
    'option-2:100000:60': 902.07,
    'option-3:35000:46': 92.52,
    'option-3:450000:7': 277.08,
    'option-4:15000:42': 52.19,
    'option-1:90000:31': 70.08,
    'option-4:5000:27': 20.06,
    'option-3:90000:28': 75.75,
    'option-2:80000:50': 330.16,
    'option-3:5000:15': 18.54,
    'option-1:45000:14': 31.41,
    'option-4:50000:44': 145.46,
    'option-4:500000:44': 1367.67,
    'option-4:65000:55': 429.21,
    'option-4:90000:45': 254.02,
    'option-3:20000:51': 76.74,
    'option-3:450000:51': 1548.61,
    'option-4:35000:16': 39.42,
    'option-3:450000:11': 277.08,
    'option-3:250000:16': 158.81,
    'option-3:80000:59': 379.96,
    'option-3:500000:10': 306.01,
    'option-2:100000:46': 410.11,
    'option-3:200000:33': 180.94,
    'option-3:5000:46': 26.44,
    'option-2:450000:43': 1133.53,
    'option-4:65000:26': 73.28,
    'option-4:75000:9': 67.96,
    'option-4:350000:55': 2307.1,
    'option-1:250000:35': 168.89,
    'option-4:75000:20': 67.96,
    'option-2:250000:55': 1584.07,
    'option-4:450000:36': 802.43,
    'option-1:100000:33': 76.28,
    'option-4:80000:37': 152.18,
    'option-1:40000:57': 183.8,
    'option-2:90000:60': 813.29,
    'option-2:80000:38': 135.33,
    'option-1:55000:46': 127.24,
    'option-4:200000:32': 255.47,
    'option-1:80000:51': 263.9,
    'option-1:15000:19': 20.75,
    'option-4:75000:1': 67.96,
    'option-2:85000:54': 541.23,
    'option-4:300000:16': 231.99,
    'option-4:20000:7': 28.65,
    'option-3:95000:33': 93.2,
    'option-1:75000:5': 42.21,
    'option-3:95000:27': 79.17,
    'option-1:200000:8': 87.52,
    'option-4:45000:10': 46.6,
    'option-3:35000:43': 67.97,
    'option-2:25000:38': 50.86,
    'option-3:300000:25': 190.55,
    'option-3:15000:37': 30.81,
    'option-4:25000:6': 32.23,
    'option-2:20000:59': 178.32,
    'option-1:450000:30': 227.67,
    'option-4:150000:30': 150.66,
    'option-3:5000:13': 18.54,
    'option-1:300000:50': 646.22,
    'option-3:65000:30': 58.8,
    'option-1:300000:57': 1334.37,
    'option-2:250000:50': 1016.7,
    'option-3:500000:51': 1720.14,
    'option-1:85000:22': 45.83,
    'option-2:45000:55': 287.23,
    'option-1:85000:44': 129.42,
    'option-3:50000:35': 55.79,
    'option-1:60000:29': 42.91,
    'option-4:70000:28': 77.85,
    'option-2:70000:4': 49.81,
    'option-3:75000:38': 97.11,
    'option-2:100000:35': 113.09,
    'option-3:25000:11': 29.07,
    'option-4:15000:54': 104.02,
    'option-2:5000:60': 57.88,
    'option-2:15000:60': 138.01,
    'option-1:35000:26': 31.23,
    'option-4:350000:57': 3263.79,
    'option-1:95000:26': 59.19,
    'option-4:450000:2': 339.37,
    'option-1:25000:36': 36.38,
    'option-4:80000:20': 71.54,
    'option-1:5000:24': 17.54,
    'option-4:250000:16': 193.24,
    'option-1:200000:3': 87.52,
    'option-1:350000:58': 1556.09,
    'option-4:300000:34': 379.08,
    'option-1:80000:17': 44.02,
    'option-1:25000:6': 24.16,
    'option-4:80000:59': 742.5,
    'option-2:50000:38': 89.22,
    'option-1:20000:4': 22.35,
    'option-4:70000:46': 305.17,
    'option-2:50000:36': 89.22,
    'option-4:65000:28': 73.28,
    'option-4:400000:31': 500.58,
    'option-3:100000:39': 124.79,
    'option-3:10000:36': 25.84,
    'option-4:55000:49': 241.13,
    'option-1:20000:57': 95.46,
    'option-4:50000:41': 145.46,
    'option-1:55000:22': 34.95,
    'option-1:65000:55': 216.07,
    'option-1:90000:25': 47.65,
    'option-4:25000:39': 55.82,
    'option-4:5000:38': 23.81,
    'option-4:65000:60': 602.48,
    'option-1:10000:36': 23.91,
    'option-1:55000:39': 63.31,
    'option-4:20000:46': 91.96,
    'option-2:80000:49': 330.16,
    'option-4:95000:28': 100.52,
    'option-3:95000:0': 69.42,
    'option-3:45000:0': 40.63,
    'option-1:500000:46': 1067.77,
    'option-3:10000:5': 21.08,
    'option-1:30000:3': 25.97,
    'option-1:80000:55': 263.9,
    'option-1:80000:27': 52.16,
    'option-3:500000:33': 433.77,
    'option-1:450000:21': 180.68,
    'option-2:100000:56': 902.07,
    'option-1:300000:47': 646.22,
    'option-3:70000:45': 123.78,
    'option-2:250000:18': 140.03,
    'option-2:50000:42': 134.93,
    'option-1:300000:26': 157.67,
    'option-3:300000:24': 190.55,
    'option-2:40000:22': 34.87,
    'option-1:35000:12': 27.79,
    'option-1:250000:20': 105.52,
    'option-4:70000:11': 64.52,
    'option-2:70000:0': 49.81,
    'option-2:200000:58': 1810.8,
    'option-4:55000:38': 108.36,
    'option-1:400000:44': 564.17,
    'option-4:55000:43': 159.04,
    'option-3:45000:33': 51.62,
    'option-2:55000:18': 42.39,
    'option-2:400000:10': 217.84,
    'option-4:150000:29': 150.66,
    'option-4:30000:55': 198.87,
    'option-4:15000:29': 28.13,
    'option-4:35000:59': 322.73,
    'option-4:100000:16': 85.91,
    'option-1:15000:43': 34.19,
    'option-3:95000:17': 69.42,
    'option-3:10000:60': 58.52,
    'option-1:450000:55': 1444.1,
    'option-1:90000:35': 70.08,
    'option-2:200000:52': 1270.4,
    'option-2:45000:27': 45.77,
    'option-1:30000:48': 74.55,
    'option-4:55000:42': 159.04,
    'option-2:60000:51': 382.56,
    'option-1:300000:59': 1334.37,
    'option-1:95000:25': 49.46,
    'option-1:200000:43': 287.07,
    'option-3:85000:42': 147.65,
    'option-3:450000:15': 277.08,
    'option-2:85000:50': 350.36,
    'option-2:70000:5': 49.81,
    'option-1:75000:59': 338.36,
    'option-4:25000:32': 43.53,
    'option-1:250000:52': 803.8,
    'option-4:350000:46': 1502.17,
    'option-3:400000:6': 247.96,
    'option-3:20000:18': 26.19,
    'option-2:450000:6': 243.1,
    'option-3:350000:60': 1643.23,
    'option-3:350000:50': 830.5,
    'option-4:250000:22': 193.24,
    'option-3:70000:49': 174.19,
    'option-1:30000:1': 25.97,
    'option-3:5000:47': 26.44,
    'option-3:15000:6': 23.62,
    'option-4:80000:23': 71.54,
    'option-1:40000:13': 29.6,
    'option-4:35000:32': 55.59,
    'option-2:75000:56': 677.15,
    'option-4:20000:37': 47.06,
    'option-3:400000:13': 247.96,
    'option-2:70000:56': 631.76,
    'option-3:20000:53': 76.74,
    'option-4:20000:40': 47.06,
    'option-4:150000:41': 416.72,
    'option-3:400000:38': 462.43,
    'option-3:55000:11': 46.41,
    'option-4:100000:43': 280.55,
    'option-1:25000:20': 24.16,
    'option-4:25000:20': 32.23,
    'option-1:55000:16': 34.95,
    'option-4:25000:37': 55.82,
    'option-4:15000:9': 25.51,
    'option-3:85000:24': 63.63,
    'option-2:350000:11': 193.15,
    'option-3:95000:9': 69.42,
    'option-4:65000:5': 60.94,
    'option-3:35000:25': 34.85,
    'option-4:80000:48': 347.85,
    'option-3:65000:19': 52.19,
    'option-1:500000:22': 198.81,
    'option-1:75000:16': 42.21,
    'option-1:25000:3': 24.16,
    'option-3:25000:31': 34.95,
    'option-4:100000:28': 105.09,
    'option-3:75000:28': 65.49,
    'option-1:65000:46': 148.35,
    'option-4:85000:8': 75.13,
    'option-3:50000:5': 43.52,
    'option-3:55000:49': 139.15,
    'option-1:250000:10': 105.52,
    'option-3:250000:28': 184.83,
    'option-4:5000:22': 19.16,
    'option-3:45000:54': 162.25,
    'option-4:100000:36': 186.87,
    'option-2:250000:40': 395.66,
    'option-2:200000:49': 814.59,
    'option-4:75000:35': 104.09,
    'option-2:500000:40': 782.76,
    'option-2:10000:17': 20.37,
    'option-1:25000:53': 88.55,
    'option-4:45000:23': 46.6,
    'option-3:250000:17': 158.81,
    'option-1:25000:11': 24.16,
    'option-2:50000:22': 39.88,
    'option-1:150000:16': 69.4,
    'option-3:450000:35': 391.97,
    'option-3:90000:17': 66.53,
    'option-4:200000:24': 157.69,
    'option-4:200000:9': 157.69,
    'option-2:95000:21': 62.38,
    'option-2:50000:20': 39.88,
    'option-3:400000:39': 462.43,
    'option-2:75000:53': 477.77,
    'option-1:45000:2': 31.41,
    'option-1:25000:44': 47.15,
    'option-4:55000:33': 79.85,
    'option-3:60000:8': 49.3,
    'option-1:300000:27': 157.67,
    'option-1:100000:48': 221.76,
    'option-3:90000:3': 66.53,
    'option-2:20000:40': 43.19,
    'option-2:20000:25': 24.83,
    'option-1:65000:54': 216.07,
    'option-2:10000:37': 29.61,
    'option-4:5000:5': 19.16,
    'option-3:5000:43': 23.1,
    'option-4:80000:7': 71.54,
    'option-3:15000:60': 79.2,
    'option-1:60000:49': 137.8,
    'option-3:100000:16': 72.32,
    'option-4:500000:19': 375.29,
    'option-2:75000:18': 52.32,
    'option-4:95000:13': 82.32,
    'option-3:95000:15': 69.42,
    'option-3:50000:30': 48.56,
    'option-2:80000:37': 135.33,
    'option-1:200000:30': 108.3,
    'option-4:300000:1': 231.99,
    'option-4:35000:17': 39.42,
    'option-3:250000:53': 861.57,
    'option-1:150000:3': 69.4,
    'option-4:150000:23': 121.72,
    'option-4:400000:19': 303.18,
    'option-2:300000:44': 760.29,
    'option-3:10000:40': 25.84,
    'option-1:85000:11': 45.83,
    'option-2:150000:13': 90.0,
    'option-2:350000:54': 2224.55,
    'option-2:65000:33': 78.41,
    'option-4:35000:27': 45.85,
    'option-3:40000:46': 104.04,
    'option-4:5000:12': 19.16,
    'option-3:500000:24': 306.01,
    'option-3:150000:17': 101.2,
    'option-4:80000:57': 742.5,
    'option-4:95000:14': 82.32,
    'option-4:65000:59': 602.48,
    'option-1:45000:27': 35.9,
    'option-2:15000:31': 29.2,
    'option-2:65000:52': 414.3,
    'option-1:30000:39': 40.82,
    'option-3:5000:54': 31.43,
    'option-4:90000:42': 254.02,
    'option-1:25000:7': 24.16,
    'option-3:60000:45': 107.86,
    'option-1:75000:7': 42.21,
    'option-2:95000:15': 62.38,
    'option-4:200000:57': 1861.57,
    'option-4:35000:1': 39.42,
    'option-1:500000:45': 700.73,
    'option-2:75000:9': 52.32,
    'option-4:150000:25': 121.72,
    'option-2:400000:21': 217.84,
    'option-2:80000:24': 54.84,
    'option-2:55000:50': 229.12,
    'option-4:200000:37': 362.39,
    'option-2:500000:7': 268.23,
    'option-4:50000:50': 219.77,
    'option-3:40000:51': 144.99,
    'option-2:400000:22': 217.84,
    'option-1:20000:59': 95.46,
    'option-1:40000:47': 95.55,
    'option-4:45000:26': 59.57,
    'option-2:65000:1': 47.31,
    'option-1:15000:39': 27.9,
    'option-3:300000:52': 1036.52,
    'option-2:350000:46': 1423.1,
    'option-3:10000:4': 21.08,
    'option-1:20000:21': 22.35,
    'option-2:50000:31': 63.46,
    'option-1:40000:40': 49.82,
    'option-3:150000:28': 116.71,
    'option-1:40000:50': 95.55,
    'option-1:400000:34': 264.14,
    'option-1:60000:32': 51.64,
    'option-3:80000:45': 139.69,
    'option-1:35000:24': 27.79,
    'option-1:40000:22': 29.6,
    'option-3:100000:29': 82.59,
    'option-4:400000:47': 1715.79,
    'option-1:70000:33': 57.82,
    'option-3:20000:8': 26.19,
    'option-3:55000:33': 59.97,
    'option-4:35000:15': 39.42,
    'option-3:250000:5': 158.81,
    'option-2:65000:50': 269.55,
    'option-3:30000:14': 31.96,
    'option-1:100000:38': 103.59,
    'option-1:85000:53': 279.83,
    'option-4:25000:35': 43.53,
    'option-3:50000:41': 91.93,
    'option-2:200000:28': 153.97,
    'option-3:200000:8': 130.15,
    'option-3:90000:41': 155.28,
    'option-2:45000:11': 37.38,
    'option-3:60000:27': 55.39,
    'option-2:90000:17': 59.87,
    'option-2:150000:19': 90.0,
    'option-2:450000:3': 243.1,
    'option-2:150000:49': 612.82,
    'option-2:45000:12': 37.38,
    'option-4:55000:40': 108.36,
    'option-3:400000:54': 1379.44,
    'option-2:250000:1': 140.03,
    'option-4:55000:52': 363.34,
    'option-1:90000:51': 295.09,
    'option-2:35000:12': 32.36,
    'option-1:25000:28': 26.55,
    'option-2:95000:54': 603.37,
    'option-4:45000:8': 46.6,
    'option-1:250000:24': 105.52,
    'option-2:35000:35': 48.5,
    'option-1:300000:31': 202.67,
    'option-4:85000:20': 75.13,
    'option-4:400000:29': 382.08,
    'option-1:60000:50': 137.8,
    'option-2:400000:37': 629.74,
    'option-4:60000:60': 555.79,
    'option-2:85000:5': 57.35,
    'option-2:35000:44': 97.64,
    'option-2:250000:30': 188.58,
    'option-2:70000:30': 63.23,
    'option-2:90000:43': 234.7,
    'option-1:40000:28': 33.56,
    'option-2:95000:1': 62.38,
    'option-2:5000:59': 57.88,
    'option-3:250000:59': 1170.85,
    'option-2:50000:29': 49.26,
    'option-2:65000:35': 78.41,
    'option-4:150000:37': 274.84,
    'option-2:450000:40': 705.79,
    'option-3:35000:37': 52.46,
    'option-3:150000:23': 101.2,
    'option-4:450000:28': 427.33,
    'option-1:45000:7': 31.41,
    'option-2:20000:3': 24.83,
    'option-1:85000:39': 90.07,
    'option-4:30000:9': 35.83,
    'option-3:150000:24': 101.2,
    'option-1:70000:37': 76.79,
    'option-1:500000:44': 700.73,
    'option-1:65000:35': 54.73,
    'option-4:30000:6': 35.83,
    'option-3:45000:21': 40.63,
    'option-2:15000:56': 138.01,
    'option-3:95000:47': 232.07,
    'option-2:150000:45': 384.24,
    'option-4:30000:25': 35.83,
    'option-2:75000:22': 52.32,
    'option-4:60000:6': 57.35,
    'option-2:15000:32': 29.2,
    'option-3:60000:49': 150.83,
    'option-1:450000:47': 962.08,
    'option-3:95000:46': 232.07,
    'option-2:5000:56': 57.88,
    'option-3:70000:23': 54.96,
    'option-3:15000:41': 37.13,
    'option-2:35000:56': 314.31,
    'option-3:10000:23': 21.08,
    'option-1:55000:54': 184.18,
    'option-4:85000:48': 369.19,
    'option-2:15000:22': 22.6,
    'option-2:30000:16': 29.85,
    'option-2:450000:59': 4077.17,
    'option-2:65000:26': 59.74,
    'option-1:400000:12': 162.51,
    'option-1:150000:34': 107.19,
    'option-4:65000:56': 602.48,
    'option-2:90000:16': 59.87,
    'option-3:65000:24': 52.19,
    'option-2:90000:9': 59.87,
    'option-3:300000:36': 350.56,
    'option-3:30000:52': 111.05,
    'option-2:300000:5': 167.94,
    'option-3:20000:19': 26.19,
    'option-3:75000:32': 76.66,
    'option-1:80000:10': 44.02,
    'option-2:70000:31': 83.39,
    'option-2:400000:1': 217.84,
    'option-1:65000:8': 38.58,
    'option-3:35000:13': 34.85,
    'option-4:150000:36': 274.84,
    'option-3:80000:10': 60.74,
    'option-1:45000:9': 31.41,
    'option-4:90000:18': 78.73,
    'option-4:20000:19': 28.65,
    'option-2:150000:48': 612.82,
    'option-3:50000:11': 43.52,
    'option-2:55000:28': 52.75,
    'option-4:40000:33': 61.66,
    'option-4:200000:52': 1317.35,
    'option-2:75000:13': 52.32,
    'option-4:75000:14': 67.96,
    'option-3:15000:19': 23.62,
    'option-3:35000:58': 169.91,
    'option-4:40000:4': 43.01,
    'option-3:450000:20': 277.08,
    'option-1:70000:11': 40.39,
    'option-1:25000:9': 24.16,
    'option-3:150000:40': 180.37,
    'option-2:10000:1': 20.37,
    'option-3:200000:11': 130.15,
    'option-3:65000:38': 85.95,
    'option-2:5000:9': 18.18,
    'option-4:95000:39': 178.09,
    'option-2:50000:23': 39.88,
    'option-3:55000:38': 74.79,
    'option-3:45000:52': 162.25,
    'option-3:60000:16': 49.3,
    'option-2:70000:51': 446.04,
    'option-2:45000:52': 287.23,
    'option-4:80000:24': 71.54,
    'option-1:200000:2': 87.52,
    'option-2:70000:9': 49.81,
    'option-4:350000:20': 267.99,
    'option-2:250000:6': 140.03,
    'option-4:75000:37': 143.42,
    'option-1:80000:38': 85.59,
    'option-3:90000:49': 220.41,
    'option-2:70000:53': 446.04,
    'option-2:5000:8': 18.18,
    'option-1:45000:1': 31.41,
    'option-1:200000:42': 287.07,
    'option-3:350000:52': 1208.18,
    'option-1:200000:12': 87.52,
    'option-2:200000:21': 115.13,
    'option-3:300000:22': 190.55,
    'option-1:150000:44': 218.55,
    'option-2:250000:59': 2259.38,
    'option-4:70000:49': 305.17,
    'option-1:30000:4': 25.97,
    'option-3:70000:51': 247.86,
    'option-3:40000:10': 37.75,
    'option-2:80000:42': 209.76,
    'option-1:40000:35': 39.26,
    'option-2:150000:59': 1357.5,
    'option-4:500000:22': 375.29,
    'option-2:30000:41': 85.15,
    'option-3:400000:19': 247.96,
    'option-1:50000:42': 81.4,
    'option-2:20000:60': 178.32,
    'option-1:80000:32': 63.88,
    'option-3:65000:51': 230.75,
    'option-2:80000:19': 54.84,
    'option-3:95000:23': 69.42,
    'option-3:5000:28': 19.01,
    'option-3:90000:47': 220.41,
    'option-1:150000:47': 327.62,
    'option-2:300000:14': 167.94,
    'option-1:85000:32': 66.98,
    'option-1:65000:47': 148.35,
    'option-1:10000:25': 19.14,
    'option-2:25000:46': 107.91,
    'option-2:50000:60': 450.11,
    'option-1:300000:25': 126.42,
    'option-1:70000:15': 40.39,
    'option-3:55000:4': 46.41,
    'option-1:25000:52': 88.55,
    'option-2:400000:0': 217.84,
    'option-3:50000:34': 55.79,
    'option-1:60000:26': 42.91,
    'option-4:150000:39': 274.84,
    'option-4:150000:6': 121.72,
    'option-2:100000:53': 635.05,
    'option-4:65000:42': 186.18,
    'option-2:95000:52': 603.37,
    'option-2:90000:20': 59.87,
    'option-1:10000:54': 44.48,
    'option-4:450000:58': 4191.97,
    'option-2:75000:23': 52.32,
    'option-2:450000:24': 243.1,
    'option-3:500000:11': 306.01,
    'option-1:95000:1': 49.46,
    'option-2:15000:37': 36.35,
    'option-1:350000:59': 1556.09,
    'option-4:30000:26': 41.27,
    'option-2:70000:54': 446.04,
    'option-2:45000:21': 37.38,
    'option-1:100000:47': 221.76,
    'option-4:5000:15': 19.16,
    'option-4:5000:2': 19.16,
    'option-1:50000:46': 116.68,
    'option-1:40000:39': 49.82,
    'option-4:250000:28': 241.84,
    'option-1:45000:53': 152.25,
    'option-1:45000:36': 54.32,
    'option-3:80000:48': 197.55,
    'option-3:15000:21': 23.62,
    'option-2:70000:14': 49.81,
    'option-2:45000:8': 37.38,
    'option-4:75000:28': 82.42,
    'option-4:80000:39': 152.18,
    'option-1:15000:46': 44.13,
    'option-1:55000:45': 88.27,
    'option-1:90000:15': 47.65,
    'option-3:70000:33': 72.49,
    'option-1:150000:17': 69.4,
    'option-1:20000:47': 53.43,
    'option-3:5000:29': 19.01,
    'option-1:55000:15': 34.95,
    'option-2:95000:20': 62.38,
    'option-1:15000:38': 27.9,
    'option-2:75000:15': 52.32,
    'option-4:40000:10': 43.01,
    'option-2:500000:52': 3173.99,
    'option-3:45000:44': 83.96,
    'option-1:80000:50': 180.0,
    'option-4:500000:42': 1367.67,
    'option-3:35000:59': 169.91,
    'option-4:70000:6': 64.52,
    'option-4:40000:53': 264.83,
    'option-2:250000:3': 140.03,
    'option-2:70000:19': 49.81,
    'option-1:5000:33': 18.73,
    'option-4:150000:54': 988.55,
    'option-4:450000:31': 560.54,
    'option-2:200000:38': 319.64,
    'option-2:100000:10': 64.89,
    'option-4:450000:23': 339.37,
    'option-4:90000:50': 390.53,
    'option-3:35000:7': 34.85,
    'option-3:100000:4': 72.32,
    'option-3:80000:7': 60.74,
    'option-1:400000:1': 162.51,
    'option-2:55000:26': 52.75,
    'option-3:50000:13': 43.52,
    'option-2:40000:58': 359.74,
    'option-1:80000:30': 52.16,
    'option-4:40000:6': 43.01,
    'option-2:350000:27': 261.45,
    'option-1:150000:51': 486.94,
    'option-3:400000:7': 247.96,
    'option-4:5000:59': 58.98,
    'option-2:350000:25': 193.15,
    'option-2:250000:19': 140.03,
    'option-3:35000:31': 43.26,
    'option-4:10000:45': 40.12,
    'option-4:45000:29': 59.57,
    'option-4:65000:1': 60.94,
    'option-2:30000:44': 85.15,
    'option-4:500000:0': 375.29,
    'option-4:100000:23': 85.91,
    'option-3:350000:29': 256.17,
    'option-4:25000:4': 32.23,
    'option-3:150000:6': 101.2,
    'option-3:35000:11': 34.85,
    'option-3:90000:39': 113.61,
    'option-3:300000:10': 190.55,
    'option-4:35000:30': 45.85,
    'option-4:45000:55': 297.37,
    'option-3:95000:20': 69.42,
    'option-4:30000:45': 91.29,
    'option-1:350000:5': 144.61,
    'option-2:350000:35': 365.08,
    'option-1:25000:16': 24.16,
    'option-4:35000:38': 73.37,
    'option-2:30000:55': 192.28,
    'option-4:70000:40': 134.66,
    'option-2:300000:2': 167.94,
    'option-4:250000:44': 687.95,
    'option-3:80000:41': 139.69,
    'option-2:20000:20': 24.83,
    'option-3:500000:9': 306.01,
    'option-3:20000:60': 99.92,
    'option-4:55000:26': 64.14,
    'option-4:70000:18': 64.52,
    'option-4:20000:8': 28.65,
    'option-1:75000:33': 60.79,
    'option-1:20000:49': 53.43,
    'option-4:10000:30': 24.07,
    'option-2:20000:36': 43.19,
    'option-1:40000:17': 29.6,
    'option-4:20000:47': 91.96,
    'option-1:20000:15': 22.35,
    'option-4:10000:23': 22.33,
    'option-1:20000:39': 31.89,
    'option-3:65000:49': 162.51,
    'option-2:75000:11': 52.32,
    'option-3:95000:40': 119.2,
    'option-1:25000:12': 24.16,
    'option-3:85000:14': 63.63,
    'option-2:150000:23': 90.0,
    'option-3:500000:14': 306.01,
    'option-3:80000:60': 379.96,
    'option-1:100000:6': 51.27,
    'option-1:35000:35': 36.17,
    'option-3:200000:56': 939.96,
    'option-1:450000:42': 632.04,
    'option-3:30000:1': 31.96,
    'option-2:85000:33': 98.13,
    'option-2:10000:33': 24.77,
    'option-1:350000:11': 144.61,
    'option-3:35000:14': 34.85,
    'option-1:35000:57': 161.69,
    'option-3:50000:27': 48.56,
    'option-3:60000:44': 107.86,
    'option-2:100000:33': 113.09,
    'option-4:500000:16': 375.29,
    'option-2:300000:34': 315.03,
    'option-4:40000:44': 118.22,
    'option-3:40000:5': 37.75,
    'option-4:65000:53': 429.21,
    'option-3:90000:18': 66.53,
    'option-2:15000:49': 69.98,
    'option-4:35000:29': 45.85,
    'option-3:25000:43': 52.18,
    'option-4:35000:55': 231.88,
    'option-3:5000:16': 18.54,
    'option-1:150000:33': 107.19,
    'option-2:60000:35': 73.43,
    'option-1:500000:37': 465.6,
    'option-3:350000:5': 219.56,
    'option-3:25000:12': 29.07,
    'option-1:40000:4': 29.6,
    'option-1:75000:15': 42.21,
    'option-4:100000:54': 658.21,
    'option-4:5000:32': 21.38,
    'option-3:35000:38': 52.46,
    'option-3:65000:15': 52.19,
    'option-1:35000:43': 60.78,
    'option-3:55000:41': 99.89,
    'option-3:90000:16': 66.53,
    'option-2:50000:2': 39.88,
    'option-1:100000:46': 221.76,
    'option-1:300000:3': 126.42,
    'option-3:85000:57': 403.31,
    'option-2:250000:56': 2259.38,
    'option-2:35000:22': 32.36,
    'option-4:30000:30': 41.27,
    'option-1:20000:16': 22.35,
    'option-1:200000:52': 646.16,
    'option-2:450000:31': 464.32,
    'option-2:90000:25': 59.87,
    'option-3:20000:24': 26.19,
    'option-3:70000:11': 54.96,
    'option-2:30000:58': 268.81,
    'option-1:250000:28': 131.5,
    'option-4:30000:35': 49.59,
    'option-1:400000:31': 264.14,
    'option-3:250000:25': 158.81,
    'option-2:85000:35': 98.13,
    'option-1:250000:31': 168.89,
    'option-4:30000:33': 49.59,
    'option-3:50000:16': 43.52,
    'option-2:100000:24': 64.89,
    'option-4:15000:50': 73.02,
    'option-4:100000:58': 927.12,
    'option-2:70000:34': 83.39,
    'option-3:250000:33': 222.3,
    'option-1:150000:35': 107.19,
    'option-4:200000:17': 157.69,
    'option-4:20000:25': 28.65,
    'option-1:90000:52': 295.09,
    'option-3:40000:56': 193.28,
    'option-2:55000:20': 42.39,
    'option-1:500000:13': 198.81,
    'option-3:45000:27': 45.14,
    'option-3:65000:52': 230.75,
    'option-2:300000:53': 1906.22,
    'option-2:350000:51': 2224.55,
    'option-2:150000:5': 90.0,
    'option-2:35000:55': 224.09,
    'option-3:150000:20': 101.2,
    'option-4:25000:26': 36.76,
    'option-2:45000:56': 404.54,
    'option-1:85000:19': 45.83,
    'option-2:40000:26': 42.27,
    'option-1:350000:20': 144.61,
    'option-2:200000:0': 115.13,
    'option-3:500000:7': 306.01,
    'option-4:300000:6': 231.99,
    'option-2:60000:47': 249.34,
    'option-2:500000:27': 365.95,
    'option-2:100000:3': 64.89,
    'option-4:20000:23': 28.65,
    'option-2:200000:18': 115.13,
    'option-2:300000:0': 167.94,
    'option-2:500000:32': 514.23,
    'option-1:15000:23': 20.75,
    'option-2:40000:13': 34.87,
    'option-1:450000:6': 180.68,
    'option-4:300000:12': 231.99,
    'option-4:500000:15': 375.29,
    'option-2:30000:43': 85.15,
    'option-4:450000:38': 802.43,
    'option-2:350000:19': 193.15,
    'option-2:85000:52': 541.23,
    'option-1:300000:18': 126.42,
    'option-4:15000:19': 25.51,
    'option-3:85000:33': 84.83,
    'option-4:70000:43': 199.75,
    'option-3:60000:18': 49.3,
    'option-3:30000:12': 31.96,
    'option-3:350000:33': 308.87,
    'option-1:90000:4': 47.65,
    'option-2:45000:20': 37.38,
    'option-4:70000:20': 64.52,
    'option-3:95000:57': 448.95,
    'option-1:40000:25': 29.6,
    'option-4:200000:55': 1317.35,
    'option-2:45000:60': 404.54,
    'option-2:15000:33': 29.2,
    'option-1:85000:51': 279.83,
    'option-3:35000:44': 67.97,
    'option-4:50000:18': 50.18,
    'option-4:100000:60': 927.12,
    'option-2:80000:43': 209.76,
    'option-4:40000:38': 82.04,
    'option-4:350000:24': 267.99,
    'option-4:35000:9': 39.42,
    'option-3:300000:53': 1036.52,
    'option-1:250000:21': 105.52,
    'option-1:30000:6': 25.97,
    'option-3:75000:19': 57.84,
    'option-1:200000:21': 87.52,
    'option-3:200000:24': 130.15,
    'option-4:45000:52': 297.37,
    'option-3:95000:49': 232.07,
    'option-4:70000:48': 305.17,
    'option-4:200000:14': 157.69,
    'option-2:55000:47': 229.12,
    'option-1:65000:17': 38.58,
    'option-4:85000:54': 560.89,
    'option-4:35000:4': 39.42,
    'option-1:40000:8': 29.6,
    'option-2:90000:34': 103.11,
    'option-1:55000:41': 88.27,
    'option-1:50000:0': 33.22,
    'option-3:500000:42': 810.09,
    'option-1:300000:0': 126.42,
    'option-3:85000:5': 63.63,
    'option-4:40000:26': 55.0,
    'option-3:50000:29': 48.56,
    'option-3:25000:7': 29.07,
    'option-2:85000:6': 57.35,
    'option-4:15000:15': 25.51,
    'option-3:250000:31': 222.3,
    'option-1:500000:57': 2217.34,
    'option-1:75000:3': 42.21,
    'option-1:400000:45': 564.17,
    'option-2:20000:56': 178.32,
    'option-2:30000:27': 35.29,
    'option-3:15000:38': 30.81,
    'option-1:95000:41': 142.85,
    'option-3:350000:4': 219.56,
    'option-3:80000:27': 68.9,
    'option-2:60000:16': 44.9,
    'option-4:70000:8': 64.52,
    'option-4:65000:21': 60.94,
    'option-4:55000:35': 79.85,
    'option-2:350000:39': 552.81,
    'option-1:15000:35': 24.13,
    'option-1:45000:29': 35.9,
    'option-1:35000:32': 36.17,
    'option-2:5000:33': 20.4,
    'option-2:80000:41': 209.76,
    'option-1:50000:4': 33.22,
    'option-3:55000:19': 46.41,
    'option-1:150000:49': 327.62,
    'option-2:450000:16': 243.1,
    'option-2:30000:38': 58.55,
    'option-3:90000:4': 66.53,
    'option-4:200000:50': 859.56,
    'option-4:70000:4': 64.52,
    'option-1:50000:49': 116.68,
    'option-2:300000:21': 167.94,
    'option-3:40000:34': 47.44,
    'option-3:45000:19': 40.63,
    'option-2:80000:45': 209.76,
    'option-3:95000:11': 69.42,
    'option-2:500000:47': 2027.44,
    'option-1:500000:10': 198.81,
    'option-1:45000:42': 74.54,
    'option-2:25000:1': 27.34,
    'option-3:35000:27': 38.3,
    'option-4:90000:34': 122.0,
    'option-4:65000:29': 73.28,
    'option-3:25000:37': 41.35,
    'option-1:10000:40': 23.91,
    'option-1:200000:41': 287.07,
    'option-1:25000:54': 88.55,
    'option-2:350000:2': 193.15,
    'option-4:450000:3': 339.37,
    'option-4:5000:3': 19.16,
    'option-1:50000:15': 33.22,
    'option-1:100000:34': 76.28,
    'option-3:80000:46': 197.55,
    'option-3:400000:60': 1876.99,
    'option-2:70000:29': 63.23,
    'option-3:350000:22': 219.56,
    'option-3:30000:38': 46.93,
    'option-3:5000:26': 19.01,
    'option-2:85000:56': 767.91,
    'option-4:15000:41': 52.19,
    'option-2:85000:43': 222.23,
    'option-3:85000:20': 63.63,
    'option-3:5000:48': 26.44,
    'option-3:5000:10': 18.54,
    'option-4:35000:54': 231.88,
    'option-2:20000:33': 33.62,
    'option-4:45000:16': 46.6,
    'option-1:350000:30': 181.15,
    'option-1:95000:38': 99.08,
    'option-2:90000:23': 59.87,
    'option-4:30000:53': 198.87,
    'option-4:150000:40': 274.84,
    'option-2:90000:52': 572.96,
    'option-2:10000:59': 97.85,
    'option-2:500000:37': 782.76,
    'option-1:90000:12': 47.65,
    'option-1:5000:42': 22.09,
    'option-4:15000:24': 25.51,
    'option-2:55000:36': 96.91,
    'option-3:100000:25': 72.32,
    'option-4:15000:37': 39.25,
    'option-3:400000:30': 289.7,
    'option-2:200000:55': 1270.4,
    'option-2:25000:39': 50.86,
    'option-1:65000:4': 38.58,
    'option-1:400000:47': 857.69,
    'option-1:350000:18': 144.61,
    'option-3:35000:32': 43.26,
    'option-2:10000:7': 20.37,
    'option-4:5000:39': 23.81,
    'option-4:60000:25': 57.35,
    'option-3:250000:22': 158.81,
    'option-3:90000:48': 220.41,
    'option-4:250000:34': 315.44,
    'option-1:10000:13': 19.14,
    'option-2:80000:31': 93.36,
    'option-2:5000:36': 22.83,
    'option-3:95000:7': 69.42,
    'option-2:10000:36': 29.61,
    'option-2:350000:15': 193.15,
    'option-4:30000:41': 91.29,
    'option-1:80000:58': 360.46,
    'option-3:55000:58': 263.16,
    'option-2:5000:2': 18.18,
    'option-2:250000:54': 1584.07,
    'option-3:60000:13': 49.3,
    'option-4:300000:39': 539.95,
    'option-4:50000:40': 99.59,
    'option-4:45000:22': 46.6,
    'option-2:10000:32': 24.77,
    'option-4:95000:50': 410.99,
    'option-3:5000:23': 18.54,
    'option-1:350000:36': 331.11,
    'option-1:10000:56': 56.28,
    'option-3:200000:28': 150.97,
    'option-2:55000:43': 147.41,
    'option-2:75000:24': 52.32,
    'option-3:200000:48': 477.58,
    'option-1:60000:13': 36.76,
    'option-3:85000:9': 63.63,
    'option-2:60000:8': 44.9,
    'option-4:200000:44': 552.22,
    'option-2:70000:38': 119.97,
    'option-1:250000:1': 105.52,
    'option-2:85000:32': 98.13,
    'option-2:10000:2': 20.37,
    'option-3:15000:23': 23.62,
    'option-4:80000:50': 347.85,
    'option-2:15000:16': 22.6,
    'option-2:25000:16': 27.34,
    'option-2:15000:12': 22.6,
    'option-3:10000:47': 36.78,
    'option-4:45000:15': 46.6,
    'option-3:65000:12': 52.19,
    'option-1:250000:58': 1108.9,
    'option-3:350000:49': 830.5,
    'option-2:10000:31': 24.77,
    'option-1:60000:52': 200.13,
    'option-1:60000:8': 36.76,
    'option-1:25000:57': 117.34,
    'option-2:40000:35': 53.49,
    'option-1:75000:29': 49.82,
    'option-1:200000:14': 87.52,
    'option-3:300000:55': 1036.52,
    'option-1:15000:30': 22.14,
    'option-4:75000:26': 82.42,
    'option-2:150000:7': 90.0,
    'option-2:250000:25': 140.03,
    'option-2:15000:14': 22.6,
    'option-1:90000:26': 56.85,
    'option-1:300000:14': 126.42,
    'option-4:15000:36': 39.25,
    'option-3:45000:23': 40.63,
    'option-1:10000:3': 19.14,
    'option-2:35000:32': 48.5,
    'option-4:35000:13': 39.42,
    'option-4:55000:54': 363.34,
    'option-1:45000:23': 31.41,
    'option-2:60000:31': 73.43,
    'option-4:65000:10': 60.94,
    'option-4:300000:2': 231.99,
    'option-3:450000:29': 324.16,
    'option-2:65000:38': 112.28,
    'option-1:95000:19': 49.46,
    'option-3:15000:43': 37.13,
    'option-2:10000:51': 72.75,
    'option-1:400000:13': 162.51,
    'option-3:15000:10': 23.62,
    'option-1:50000:48': 116.68,
    'option-1:40000:21': 29.6,
    'option-1:60000:6': 36.76,
    'option-3:65000:8': 52.19,
    'option-1:20000:7': 22.35,
    'option-3:80000:32': 80.67,
    'option-4:15000:22': 25.51,
    'option-2:350000:43': 885.39,
    'option-1:90000:8': 47.65,
    'option-3:40000:38': 80.37,
    'option-2:90000:27': 77.06,
    'option-3:25000:22': 29.07,
    'option-4:15000:45': 52.19,
    'option-3:450000:17': 277.08,
    'option-4:90000:22': 78.73,
    'option-3:150000:48': 360.94,
    'option-3:70000:36': 91.53,
    'option-3:350000:40': 406.59,
    'option-3:100000:31': 97.38,
    'option-1:80000:40': 85.59,
    'option-2:250000:0': 140.03,
    'option-3:75000:52': 264.97,
    'option-4:150000:28': 150.66,
    'option-1:200000:55': 646.16,
    'option-2:25000:28': 31.79,
    'option-4:500000:17': 375.29,
    'option-1:15000:55': 58.6,
    'option-4:50000:2': 50.18,
    'option-1:25000:10': 24.16,
    'option-1:150000:59': 669.67,
    'option-3:100000:42': 171.2,
    'option-3:65000:13': 52.19,
    'option-3:150000:35': 139.03,
    'option-3:80000:35': 80.67,
    'option-4:35000:46': 155.91,
    'option-4:350000:56': 3263.79,
    'option-2:85000:59': 767.91,
    'option-2:200000:57': 1810.8,
    'option-4:250000:55': 1642.76,
    'option-1:65000:38': 72.3,
    'option-4:95000:44': 267.01,
    'option-2:450000:5': 243.1,
    'option-4:300000:29': 290.48,
    'option-1:300000:2': 126.42,
    'option-2:75000:44': 197.3,
    'option-1:95000:14': 49.46,
    'option-4:55000:8': 53.77,
    'option-1:55000:2': 34.95,
    'option-1:15000:34': 24.13,
    'option-3:15000:50': 47.15,
    'option-4:200000:54': 1317.35,
    'option-3:40000:18': 37.75,
    'option-3:75000:59': 356.61,
    'option-3:5000:39': 20.96,
    'option-1:35000:11': 27.79,
    'option-2:15000:0': 22.6,
    'option-3:55000:20': 46.41,
    'option-3:55000:16': 46.41,
    'option-1:85000:28': 54.51,
    'option-1:25000:0': 24.16,
    'option-4:15000:33': 32.09,
    'option-3:60000:46': 150.83,
    'option-3:500000:16': 306.01,
    'option-4:5000:36': 23.81,
    'option-2:40000:2': 34.87,
    'option-4:40000:21': 43.01,
    'option-3:300000:60': 1408.94,
    'option-4:300000:17': 231.99,
    'option-4:350000:14': 267.99,
    'option-4:50000:25': 50.18,
    'option-3:90000:20': 66.53,
    'option-2:30000:2': 29.85,
    'option-2:35000:30': 38.78,
    'option-3:450000:5': 277.08,
    'option-4:200000:11': 157.69,
    'option-1:200000:24': 87.52,
    'option-1:25000:42': 47.15,
    'option-3:250000:0': 158.81,
    'option-3:250000:40': 291.74,
    'option-1:5000:35': 18.73,
    'option-4:300000:28': 290.48,
    'option-4:150000:35': 194.94,
    'option-1:55000:37': 63.31,
    'option-4:500000:25': 375.29,
    'option-1:70000:3': 40.39,
    'option-2:80000:32': 93.36,
    'option-2:40000:45': 109.92,
    'option-1:25000:32': 29.98,
    'option-3:500000:53': 1720.14,
    'option-4:20000:17': 28.65,
    'option-3:90000:15': 66.53,
    'option-4:200000:34': 255.47,
    'option-3:90000:34': 89.01,
    'option-1:70000:20': 40.39,
    'option-4:95000:16': 82.32,
    'option-1:95000:23': 49.46,
    'option-3:35000:17': 34.85,
    'option-1:500000:38': 465.6,
    'option-2:200000:6': 115.13,
    'option-2:300000:30': 226.37,
    'option-4:300000:15': 231.99,
    'option-3:25000:9': 29.07,
    'option-4:65000:47': 283.83,
    'option-4:20000:56': 182.88,
    'option-3:45000:48': 115.77,
    'option-4:450000:57': 4191.97,
    'option-3:350000:24': 219.56,
    'option-1:65000:9': 38.58,
    'option-2:55000:17': 42.39,
    'option-3:15000:9': 23.62,
    'option-4:45000:2': 46.6,
    'option-3:95000:24': 69.42,
    'option-4:55000:5': 53.77,
    'option-3:35000:22': 34.85,
    'option-4:250000:41': 687.95,
    'option-2:400000:20': 217.84,
    'option-4:10000:52': 74.88,
    'option-2:20000:30': 28.31,
    'option-1:60000:55': 200.13,
    'option-3:45000:9': 40.63,
    'option-1:350000:1': 144.61,
    'option-1:85000:1': 45.83,
    'option-2:300000:31': 315.03,
    'option-3:150000:16': 101.2,
    'option-1:200000:44': 287.07,
    'option-1:30000:26': 28.89,
    'option-2:450000:8': 243.1,
    'option-1:45000:60': 205.69,
    'option-2:50000:35': 63.46,
    'option-4:10000:31': 26.75,
    'option-1:50000:25': 33.22,
    'option-1:350000:49': 752.09,
    'option-2:75000:41': 197.3,
    'option-1:400000:4': 162.51,
    'option-3:90000:7': 66.53,
    'option-4:75000:30': 82.42,
    'option-4:65000:8': 60.94,
    'option-4:100000:20': 85.91,
    'option-3:65000:45': 115.82,
    'option-1:80000:11': 44.02,
    'option-4:200000:15': 157.69,
    'option-3:300000:42': 492.06,
    'option-4:55000:50': 241.13,
    'option-1:15000:57': 75.85,
    'option-1:45000:15': 31.41,
    'option-4:300000:53': 1976.86,
    'option-1:350000:12': 144.61,
    'option-3:60000:54': 213.63,
    'option-4:35000:31': 55.59,
    'option-1:250000:51': 803.8,
    'option-2:95000:57': 856.77,
    'option-1:85000:45': 129.42,
    'option-1:100000:32': 76.28,
    'option-2:5000:38': 22.83,
    'option-2:450000:51': 2855.71,
    'option-3:90000:27': 75.75,
    'option-1:500000:16': 198.81,
    'option-4:400000:25': 303.18,
    'option-2:25000:4': 27.34,
    'option-3:150000:2': 101.2,
    'option-2:500000:13': 268.23,
    'option-1:300000:10': 126.42,
    'option-1:200000:0': 87.52,
    'option-4:350000:48': 1502.17,
    'option-4:350000:42': 961.75,
    'option-3:95000:31': 93.2,
    'option-3:450000:48': 1062.91,
    'option-4:35000:37': 73.37,
    'option-4:500000:39': 890.21,
    'option-4:45000:1': 46.6,
    'option-2:45000:49': 188.67,
    'option-3:100000:37': 124.79,
    'option-2:300000:1': 167.94,
    'option-4:80000:1': 71.54,
    'option-3:250000:56': 1170.85,
    'option-3:30000:26': 34.89,
    'option-2:30000:6': 29.85,
    'option-1:350000:0': 144.61,
    'option-1:300000:1': 126.42,
    'option-3:350000:31': 308.87,
    'option-2:55000:24': 42.39,
    'option-1:75000:8': 42.21,
    'option-3:30000:34': 39.08,
    'option-1:150000:7': 69.4,
    'option-4:20000:0': 28.65,
    'option-4:30000:5': 35.83,
    'option-1:500000:59': 2217.34,
    'option-1:250000:8': 105.52,
    'option-4:400000:26': 382.08,
    'option-2:70000:12': 49.81,
    'option-4:75000:0': 67.96,
    'option-3:25000:56': 123.04,
    'option-3:70000:4': 54.96,
    'option-1:90000:59': 403.69,
    'option-3:30000:35': 39.08,
    'option-2:70000:58': 631.76,
    'option-1:10000:30': 20.07,
    'option-4:75000:48': 326.51,
    'option-1:70000:31': 57.82,
    'option-3:80000:5': 60.74,
    'option-2:30000:35': 43.5,
    'option-3:150000:27': 116.71,
    'option-4:95000:8': 82.32,
    'option-1:85000:13': 45.83,
    'option-4:450000:55': 2961.88,
    'option-1:30000:19': 25.97,
    'option-3:55000:42': 99.89,
    'option-2:150000:10': 90.0,
    'option-4:50000:49': 219.77,
    'option-2:25000:21': 27.34,
    'option-3:250000:21': 158.81,
    'option-1:300000:48': 646.22,
    'option-3:95000:37': 119.2,
    'option-3:250000:34': 222.3,
    'option-4:65000:12': 60.94,
    'option-1:500000:54': 1603.9,
    'option-1:300000:24': 126.42,
    'option-4:350000:34': 439.93,
    'option-4:35000:28': 45.85,
    'option-2:80000:1': 54.84,
    'option-1:65000:51': 216.07,
    'option-2:70000:11': 49.81,
    'option-1:60000:19': 36.76,
    'option-3:25000:50': 69.12,
    'option-1:90000:7': 47.65,
    'option-3:200000:30': 150.97,
    'option-3:80000:19': 60.74,
    'option-4:100000:40': 186.87,
    'option-1:55000:57': 249.94,
    'option-3:150000:18': 101.2,
    'option-1:30000:15': 25.97,
    'option-3:25000:27': 31.46,
    'option-2:5000:15': 18.18,
    'option-1:300000:28': 157.67,
    'option-1:30000:33': 33.07,
    'option-4:5000:1': 19.16,
    'option-1:40000:20': 29.6,
    'option-3:5000:35': 19.69,
    'option-4:75000:53': 495.06,
    'option-3:10000:50': 36.78,
    'option-3:35000:56': 169.91,
    'option-3:65000:36': 85.95,
    'option-3:200000:58': 939.96,
    'option-4:85000:15': 75.13,
    'option-4:450000:35': 560.54,
    'option-1:75000:41': 115.71,
    'option-4:75000:8': 67.96,
    'option-1:35000:1': 27.79,
    'option-2:100000:51': 635.05,
    'option-1:85000:41': 129.42,
    'option-3:500000:17': 306.01,
    'option-2:10000:34': 24.77,
    'option-2:350000:12': 193.15,
    'option-1:10000:39': 23.91,
    'option-4:400000:44': 1097.57,
    'option-2:50000:8': 39.88,
    'option-4:5000:60': 58.98,
    'option-2:15000:17': 22.6,
    'option-1:60000:9': 36.76,
    'option-1:400000:30': 204.16,
    'option-3:5000:45': 23.1,
    'option-3:35000:39': 52.46,
    'option-1:15000:17': 20.75,
    'option-3:20000:1': 26.19,
    'option-3:200000:19': 130.15,
    'option-2:65000:10': 47.31,
    'option-4:75000:25': 67.96,
    'option-4:15000:48': 73.02,
    'option-2:500000:24': 268.23,
    'option-2:400000:14': 217.84,
    'option-4:350000:28': 336.36,
    'option-1:85000:21': 45.83,
    'option-3:200000:46': 477.58,
    'option-1:65000:15': 38.58,
    'option-1:30000:31': 33.07,
    'option-1:25000:24': 24.16,
    'option-4:60000:19': 57.35,
    'option-4:100000:33': 134.14,
    'option-1:200000:33': 138.22,
    'option-3:75000:14': 57.84,
    'option-4:250000:32': 315.44,
    'option-3:70000:58': 333.25,
    'option-2:90000:57': 813.29,
    'option-3:25000:55': 93.87,
    'option-1:300000:9': 126.42,
    'option-2:350000:1': 193.15,
    'option-2:40000:25': 34.87,
    'option-1:80000:14': 44.02,
    'option-3:250000:32': 222.3,
    'option-4:5000:47': 35.24,
    'option-3:200000:1': 130.15,
    'option-4:400000:13': 303.18,
    'option-4:450000:29': 427.33,
    'option-2:30000:0': 29.85,
    'option-2:90000:32': 103.11,
    'option-3:30000:15': 31.96,
    'option-2:40000:31': 53.49,
    'option-1:300000:7': 126.42,
    'option-1:10000:37': 23.91,
    'option-3:250000:41': 409.28,
    'option-3:100000:36': 124.79,
    'option-2:65000:5': 47.31,
    'option-2:50000:41': 134.93,
    'option-2:450000:2': 243.1,
    'option-3:60000:38': 80.37,
    'option-4:150000:20': 121.72,
    'option-2:75000:10': 52.32,
    'option-1:200000:35': 138.22,
    'option-1:10000:4': 19.14,
    'option-2:300000:35': 315.03,
    'option-4:50000:52': 330.38,
    'option-4:5000:21': 19.16,
    'option-1:450000:43': 632.04,
    'option-1:25000:4': 24.16,
    'option-3:40000:4': 37.75,
    'option-1:350000:52': 1126.92,
    'option-1:25000:8': 24.16,
    'option-1:100000:54': 326.98,
    'option-1:80000:57': 360.46,
    'option-4:95000:47': 410.99,
    'option-3:90000:57': 425.64,
    'option-2:500000:54': 3173.99,
    'option-1:25000:48': 63.98,
    'option-4:250000:42': 687.95,
    'option-3:20000:10': 26.19,
    'option-1:85000:2': 45.83,
    'option-3:75000:17': 57.84,
    'option-4:45000:20': 46.6,
    'option-1:500000:17': 198.81,
    'option-4:450000:59': 4191.97,
    'option-1:200000:19': 87.52,
    'option-2:100000:31': 113.09,
    'option-3:150000:50': 360.94,
    'option-2:50000:56': 450.11,
    'option-4:60000:28': 68.71,
    'option-3:250000:42': 409.28,
    'option-4:65000:24': 60.94,
    'option-1:20000:14': 22.35,
    'option-2:150000:58': 1357.5,
    'option-1:20000:45': 40.3,
    'option-3:40000:11': 37.75,
    'option-3:400000:43': 651.63,
    'option-3:90000:10': 66.53,
    'option-3:150000:3': 101.2,
    'option-2:35000:59': 314.31,
    'option-2:55000:37': 96.91,
    'option-2:65000:36': 112.28,
    'option-4:100000:31': 134.14,
    'option-1:30000:52': 104.56,
    'option-1:150000:40': 148.41,
    'option-4:45000:28': 59.57,
    'option-3:55000:54': 196.52,
    'option-3:40000:13': 37.75,
    'option-1:55000:51': 184.18,
    'option-3:95000:36': 119.2,
    'option-3:350000:56': 1643.23,
    'option-4:90000:14': 78.73,
    'option-2:25000:15': 27.34,
    'option-2:250000:22': 140.03,
    'option-3:5000:24': 18.54,
    'option-4:40000:7': 43.01,
    'option-2:300000:51': 1906.22,
    'option-1:60000:12': 36.76,
    'option-3:85000:7': 63.63,
    'option-2:85000:15': 57.35,
    'option-3:5000:1': 18.54,
    'option-1:30000:45': 54.02,
    'option-1:45000:33': 42.36,
    'option-2:45000:58': 404.54,
    'option-3:25000:36': 41.35,
    'option-1:55000:4': 34.95,
    'option-1:90000:53': 295.09,
    'option-4:500000:28': 473.08,
    'option-4:50000:14': 50.18,
    'option-3:250000:4': 158.81,
    'option-1:150000:9': 69.4,
    'option-4:35000:25': 39.42,
    'option-2:65000:43': 172.36,
    'option-4:45000:50': 198.4,
    'option-3:90000:5': 66.53,
    'option-2:30000:37': 58.55,
    'option-3:350000:11': 219.56,
    'option-2:20000:18': 24.83,
    'option-4:80000:8': 71.54,
    'option-2:350000:47': 1423.1,
    'option-4:25000:10': 32.23,
    'option-2:15000:18': 22.6,
    'option-1:300000:6': 126.42,
    'option-4:95000:48': 410.99,
    'option-2:80000:17': 54.84,
    'option-2:55000:41': 147.41,
    'option-3:300000:58': 1408.94,
    'option-3:65000:37': 85.95,
    'option-2:150000:41': 384.24,
    'option-2:10000:23': 20.37,
    'option-1:500000:48': 1067.77,
    'option-1:80000:35': 63.88,
    'option-2:250000:12': 140.03,
    'option-3:20000:12': 26.19,
    'option-4:150000:44': 416.72,
    'option-2:20000:54': 129.0,
    'option-2:60000:10': 44.9,
    'option-3:5000:42': 23.1,
    'option-1:15000:25': 20.75,
    'option-4:55000:44': 159.04,
    'option-3:80000:52': 282.08,
    'option-3:55000:2': 46.41,
    'option-3:60000:39': 80.37,
    'option-2:20000:31': 33.62,
    'option-1:70000:35': 57.82,
    'option-1:200000:23': 87.52,
    'option-4:450000:7': 339.37,
    'option-3:10000:21': 21.08,
    'option-3:95000:44': 163.23,
    'option-4:60000:8': 57.35,
    'option-2:85000:21': 57.35,
    'option-3:75000:42': 131.73,
    'option-1:85000:10': 45.83,
    'option-3:65000:53': 230.75,
    'option-2:85000:28': 73.56,
    'option-3:45000:56': 216.4,
    'option-1:75000:51': 247.96,
    'option-1:50000:20': 33.22,
    'option-1:85000:36': 90.07,
    'option-4:75000:44': 213.32,
    'option-2:60000:11': 44.9,
    'option-4:65000:35': 91.97,
    'option-4:70000:47': 305.17,
    'option-2:60000:56': 540.97,
    'option-1:10000:27': 20.07,
    'option-1:5000:60': 36.78,
    'option-4:35000:22': 39.42,
    'option-2:65000:57': 586.37,
    'option-3:400000:47': 947.38,
    'option-2:45000:57': 404.54,
    'option-3:25000:2': 29.07,
    'option-3:15000:7': 23.62,
    'option-1:250000:37': 238.09,
    'option-2:150000:54': 953.46,
    'option-4:5000:58': 58.98,
    'option-2:65000:60': 586.37,
    'option-2:20000:4': 24.83,
    'option-2:500000:44': 1258.47,
    'option-1:20000:5': 22.35,
    'option-4:400000:27': 382.08,
    'option-1:15000:41': 34.19,
    'option-1:65000:11': 38.58,
    'option-1:350000:10': 144.61,
    'option-2:400000:49': 1625.34,
    'option-2:85000:18': 57.35,
    'option-3:90000:23': 66.53,
    'option-3:30000:44': 60.15,
    'option-1:60000:51': 200.13,
    'option-4:200000:60': 1861.57,
    'option-3:45000:2': 40.63,
    'option-2:5000:18': 18.18,
    'option-4:400000:43': 1097.57,
    'option-3:25000:15': 29.07,
    'option-1:60000:2': 36.76,
    'option-4:250000:13': 193.24,
    'option-4:15000:56': 141.43,
    'option-1:70000:16': 40.39,
    'option-2:100000:49': 410.11,
    'option-1:70000:56': 316.26,
    'option-4:300000:33': 379.08,
    'option-4:50000:37': 99.59,
    'option-1:5000:37': 19.96,
    'option-2:75000:3': 52.32,
    'option-3:55000:5': 46.41,
    'option-1:55000:59': 249.94,
    'option-1:10000:49': 34.77,
    'option-2:90000:58': 813.29,
    'option-4:10000:60': 100.14,
    'option-3:60000:36': 80.37,
    'option-4:250000:6': 193.24,
    'option-2:300000:3': 167.94,
    'option-4:65000:39': 125.89,
    'option-4:60000:41': 172.61,
    'option-3:35000:16': 34.85,
    'option-4:75000:55': 495.06,
    'option-3:30000:32': 39.08,
    'option-4:75000:45': 213.32,
    'option-4:150000:8': 121.72,
    'option-1:70000:23': 40.39,
    'option-2:200000:17': 115.13,
    'option-2:55000:40': 96.91,
    'option-4:15000:16': 25.51,
    'option-4:350000:49': 1502.17,
    'option-1:500000:55': 1603.9,
    'option-3:45000:4': 40.63,
    'option-4:35000:44': 104.88,
    'option-1:25000:15': 24.16,
    'option-1:150000:18': 69.4,
    'option-4:20000:11': 28.65,
    'option-3:25000:14': 29.07,
    'option-1:35000:4': 27.79,
    'option-4:450000:21': 339.37,
    'option-3:70000:47': 174.19,
    'option-4:65000:44': 186.18,
    'option-2:450000:54': 2855.71,
    'option-4:30000:19': 35.83,
    'option-1:20000:11': 22.35,
    'option-3:40000:19': 37.75,
    'option-2:55000:42': 147.41,
    'option-2:15000:13': 22.6,
    'option-2:5000:45': 27.19,
    'option-2:100000:45': 259.11,
    'option-4:90000:4': 78.73,
    'option-2:500000:14': 268.23,
    'option-4:35000:43': 104.88,
    'option-4:45000:39': 90.81,
    'option-2:35000:26': 38.78,
    'option-1:30000:20': 25.97,
    'option-4:80000:25': 71.54,
    'option-4:100000:12': 85.91,
    'option-2:60000:49': 249.34,
    'option-2:15000:34': 29.2,
    'option-1:40000:23': 29.6,
    'option-3:55000:22': 46.41,
    'option-1:30000:11': 25.97,
    'option-2:60000:48': 249.34,
    'option-1:65000:49': 148.35,
    'option-1:150000:6': 69.4,
    'option-2:10000:18': 20.37,
    'option-1:100000:15': 51.27,
    'option-1:50000:57': 227.81,
    'option-1:70000:54': 232.02,
    'option-1:50000:35': 45.45,
    'option-1:150000:46': 327.62,
    'option-2:40000:46': 168.67,
    'option-4:25000:40': 55.82,
    'option-3:30000:51': 111.05,
    'option-3:10000:45': 30.14,
    'option-1:85000:8': 45.83,
    'option-2:500000:33': 514.23,
    'option-3:95000:56': 448.95,
    'option-1:400000:53': 1286.48,
    'option-2:150000:35': 162.77,
    'option-1:35000:3': 27.79,
    'option-2:65000:45': 172.36,
    'option-4:25000:44': 77.68,
    'option-3:250000:2': 158.81,
    'option-1:90000:9': 47.65,
    'option-4:35000:21': 39.42,
    'option-1:400000:2': 162.51,
    'option-3:85000:39': 108.04,
    'option-3:70000:37': 91.53,
    'option-3:80000:2': 60.74,
    'option-1:300000:43': 426.63,
    'option-2:5000:35': 20.4,
    'option-2:65000:53': 414.3,
    'option-3:150000:37': 180.37,
    'option-3:300000:31': 266.96,
    'option-2:5000:1': 18.18,
    'option-1:25000:46': 63.98,
    'option-4:20000:26': 32.19,
    'option-1:70000:36': 76.79,
    'option-4:10000:38': 31.57,
    'option-2:25000:56': 223.69,
    'option-2:500000:18': 268.23,
    'option-4:60000:55': 396.28,
    'option-1:20000:56': 95.46,
    'option-4:40000:45': 118.22,
    'option-2:65000:18': 47.31,
    'option-2:250000:36': 395.66,
    'option-1:90000:0': 47.65,
    'option-1:40000:14': 29.6,
    'option-1:50000:33': 45.45,
    'option-1:250000:33': 168.89,
    'option-1:70000:28': 47.48,
    'option-2:450000:32': 464.32,
    'option-3:50000:25': 43.52,
    'option-2:75000:45': 197.3,
    'option-3:50000:53': 179.39,
    'option-1:70000:10': 40.39,
    'option-2:30000:50': 128.21,
    'option-2:5000:34': 20.4,
    'option-3:250000:10': 158.81,
    'option-2:40000:20': 34.87,
    'option-4:35000:36': 73.37,
    'option-3:50000:24': 43.52,
    'option-2:15000:30': 25.24,
    'option-2:30000:29': 35.29,
    'option-3:200000:44': 330.56,
    'option-4:300000:36': 539.95,
    'option-4:400000:16': 303.18,
    'option-2:50000:12': 39.88,
    'option-2:75000:32': 88.37,
    'option-2:30000:60': 268.81,
    'option-1:400000:52': 1286.48,
    'option-3:75000:39': 97.11,
    'option-1:60000:47': 137.8,
    'option-2:200000:3': 115.13,
    'option-4:5000:25': 19.16,
    'option-1:60000:25': 36.76,
    'option-3:10000:41': 30.14,
    'option-4:150000:60': 1395.44,
    'option-2:45000:19': 37.38,
    'option-1:80000:20': 44.02,
    'option-3:300000:44': 492.06,
    'option-2:35000:57': 314.31,
    'option-1:60000:58': 272.05,
    'option-2:60000:14': 44.9,
    'option-4:75000:52': 495.06,
    'option-2:65000:14': 47.31,
    'option-2:50000:43': 134.93,
    'option-1:200000:5': 87.52,
    'option-3:150000:12': 101.2,
    'option-3:40000:39': 80.37,
    'option-3:25000:25': 29.07,
    'option-4:60000:31': 85.91,
    'option-3:65000:50': 162.51,
    'option-2:25000:60': 223.69,
    'option-1:90000:46': 200.66,
    'option-1:400000:17': 162.51,
    'option-4:30000:2': 35.83,
    'option-1:50000:17': 33.22,
    'option-1:250000:14': 105.52,
    'option-3:85000:31': 84.83,
    'option-3:100000:40': 124.79,
    'option-2:25000:33': 38.59,
    'option-1:50000:59': 227.81,
    'option-1:100000:9': 51.27,
    'option-1:50000:1': 33.22,
    'option-2:250000:10': 140.03,
    'option-1:80000:42': 122.56,
    'option-1:90000:45': 136.0,
    'option-2:80000:16': 54.84,
    'option-1:250000:47': 537.32,
    'option-1:5000:30': 18.03,
    'option-3:20000:5': 26.19,
    'option-3:70000:25': 54.96,
    'option-3:70000:57': 333.25,
    'option-2:400000:46': 1625.34,
    'option-3:5000:21': 18.54,
    'option-2:95000:44': 246.66,
    'option-3:75000:54': 264.97,
    'option-1:400000:3': 162.51,
    'option-4:60000:1': 57.35,
    'option-2:500000:10': 268.23,
    'option-3:60000:15': 49.3,
    'option-2:100000:28': 84.05,
    'option-3:50000:36': 91.53,
    'option-4:100000:51': 658.21,
    'option-1:500000:43': 700.73,
    'option-4:65000:48': 283.83,
    'option-2:10000:39': 29.61,
    'option-3:20000:54': 76.74,
    'option-3:300000:56': 1408.94,
    'option-4:450000:11': 339.37,
    'option-3:400000:11': 247.96,
    'option-4:55000:41': 159.04,
    'option-2:200000:39': 319.64,
    'option-4:10000:47': 54.13,
    'option-1:45000:52': 152.25,
    'option-4:500000:3': 375.29,
    'option-2:85000:41': 222.23,
    'option-2:100000:32': 113.09,
    'option-2:350000:59': 3174.52,
    'option-3:300000:40': 350.56,
    'option-3:90000:19': 66.53,
    'option-2:15000:45': 49.23,
    'option-1:20000:25': 22.35,
    'option-1:90000:39': 94.58,
    'option-3:45000:6': 40.63,
    'option-1:50000:12': 33.22,
    'option-3:200000:43': 330.56,
    'option-2:350000:14': 193.15,
    'option-3:15000:0': 23.62,
    'option-3:35000:55': 128.19,
    'option-2:200000:12': 115.13,
    'option-4:85000:33': 115.95,
    'option-4:100000:29': 105.09,
    'option-1:10000:42': 28.16,
    'option-3:35000:57': 169.91,
    'option-4:90000:44': 254.02,
    'option-3:350000:19': 219.56,
    'option-2:75000:12': 52.32,
    'option-2:450000:57': 4077.17,
    'option-3:450000:59': 2107.81,
    'option-3:85000:8': 63.63,
    'option-2:400000:28': 295.72,
    'option-3:45000:5': 40.63,
    'option-2:25000:54': 160.37,
    'option-2:150000:29': 118.93,
    'option-3:200000:4': 130.15,
    'option-3:95000:6': 69.42,
    'option-4:95000:15': 82.32,
    'option-4:200000:18': 157.69,
    'option-2:70000:7': 49.81,
    'option-2:80000:46': 330.16,
    'option-3:400000:41': 651.63,
    'option-4:95000:11': 82.32,
    'option-3:20000:32': 30.78,
    'option-4:5000:43': 28.16,
    'option-4:45000:31': 67.72,
    'option-2:20000:23': 24.83,
    'option-2:450000:35': 464.32,
    'option-4:500000:48': 2140.7,
    'option-4:15000:23': 25.51,
    'option-1:70000:22': 40.39,
    'option-1:40000:46': 95.55,
    'option-4:10000:6': 22.33,
    'option-3:70000:32': 72.49,
    'option-4:35000:5': 39.42,
    'option-3:250000:29': 184.83,
    'option-2:65000:29': 59.74,
    'option-2:20000:41': 60.18,
    'option-2:30000:21': 29.85,
    'option-1:35000:34': 36.17,
    'option-4:90000:12': 78.73,
    'option-2:85000:37': 143.02,
    'option-4:45000:42': 131.87,
    'option-3:45000:16': 40.63,
    'option-4:300000:52': 1976.86,
    'option-3:70000:53': 247.86,
    'option-3:10000:49': 36.78,
    'option-2:85000:30': 73.56,
    'option-3:90000:60': 425.64,
    'option-4:150000:26': 150.66,
    'option-1:100000:27': 61.53,
    'option-1:70000:13': 40.39,
    'option-2:450000:0': 243.1,
    'option-3:65000:41': 115.82,
    'option-4:400000:58': 3730.95,
    'option-3:15000:17': 23.62,
    'option-1:70000:2': 40.39,
    'option-3:75000:30': 65.49,
    'option-4:25000:21': 32.23,
    'option-1:200000:54': 646.16,
    'option-4:15000:34': 32.09,
    'option-1:55000:48': 127.24,
    'option-4:35000:8': 39.42,
    'option-1:200000:10': 87.52,
    'option-2:45000:3': 37.38,
    'option-2:90000:48': 370.56,
    'option-1:350000:60': 1556.09,
    'option-4:400000:45': 1097.57,
    'option-1:200000:36': 193.58,
    'option-4:70000:25': 64.52,
    'option-4:25000:18': 32.23,
    'option-1:55000:49': 127.24,
    'option-2:70000:25': 49.81,
    'option-3:200000:47': 477.58,
    'option-3:80000:15': 60.74,
    'option-3:250000:11': 158.81,
    'option-3:30000:11': 31.96,
    'option-2:300000:42': 760.29,
    'option-2:20000:28': 28.31,
    'option-3:90000:36': 113.61,
    'option-4:60000:39': 117.13,
    'option-3:25000:13': 29.07,
    'option-1:90000:10': 47.65,
    'option-3:300000:19': 190.55,
    'option-4:300000:26': 290.48,
    'option-4:95000:21': 82.32,
    'option-3:85000:15': 63.63,
    'option-2:85000:53': 541.23,
    'option-1:50000:21': 33.22,
    'option-2:30000:28': 35.29,
    'option-4:450000:4': 339.37,
    'option-3:90000:58': 425.64,
    'option-3:200000:54': 692.37,
    'option-4:35000:42': 104.88,
    'option-3:150000:19': 101.2,
    'option-3:500000:37': 573.54,
    'option-3:65000:34': 68.32,
    'option-3:5000:52': 31.43,
    'option-1:450000:57': 1995.74,
    'option-4:50000:26': 68.71,
    'option-4:400000:51': 2636.61,
    'option-1:250000:54': 803.8,
    'option-4:85000:55': 560.89,
    'option-3:100000:53': 349.79,
    'option-2:95000:17': 62.38,
    'option-3:95000:58': 448.95,
    'option-3:500000:34': 433.77,
    'option-4:400000:8': 303.18,
    'option-4:500000:10': 375.29,
    'option-1:350000:24': 144.61,
    'option-1:50000:13': 33.22,
    'option-3:100000:32': 97.38,
    'option-4:500000:18': 375.29,
    'option-1:400000:6': 162.51,
    'option-1:55000:25': 34.95,
    'option-3:100000:15': 72.32,
    'option-2:85000:25': 57.35,
    'option-1:500000:6': 198.81,
    'option-4:20000:32': 37.47,
    'option-1:35000:29': 31.23,
    'option-2:50000:48': 208.9,
    'option-3:500000:15': 306.01,
    'option-1:400000:60': 1777.3,
    'option-1:200000:4': 87.52,
    'option-4:45000:46': 198.4,
    'option-3:10000:2': 21.08,
    'option-3:350000:13': 219.56,
    'option-2:150000:33': 162.77,
    'option-1:55000:3': 34.95,
    'option-4:20000:50': 91.96,
    'option-1:150000:36': 148.41,
    'option-1:70000:50': 158.9,
    'option-1:400000:51': 1286.48,
    'option-4:150000:56': 1395.44,
    'option-1:95000:47': 211.19,
    'option-1:55000:58': 249.94,
    'option-2:250000:45': 633.53,
    'option-1:75000:40': 81.29,
    'option-3:95000:41': 163.23,
    'option-3:85000:43': 147.65,
    'option-2:10000:35': 24.77,
    'option-3:10000:53': 46.58,
    'option-1:100000:18': 51.27,
    'option-3:20000:56': 99.92,
    'option-4:200000:2': 157.69,
    'option-1:300000:8': 126.42,
    'option-1:100000:31': 76.28,
    'option-4:70000:56': 649.16,
    'option-4:75000:29': 82.42,
    'option-2:500000:3': 268.23,
    'option-2:350000:16': 193.15,
    'option-3:50000:28': 48.56,
    'option-3:400000:56': 1876.99,
    'option-3:10000:57': 58.52,
    'option-4:95000:7': 82.32,
    'option-2:450000:26': 330.95,
    'option-3:50000:49': 127.46,
    'option-4:95000:34': 128.07,
    'option-2:50000:54': 319.04,
    'option-3:55000:25': 46.41,
    'option-3:30000:28': 34.89,
    'option-4:500000:1': 375.29,
    'option-4:40000:14': 43.01,
    'option-4:20000:14': 28.65,
    'option-3:250000:52': 861.57,
    'option-4:350000:13': 267.99,
    'option-2:20000:42': 60.18,
    'option-2:40000:57': 359.74,
    'option-3:80000:18': 60.74,
    'option-1:20000:24': 22.35,
    'option-2:50000:39': 89.22,
    'option-1:50000:3': 33.22,
    'option-1:85000:58': 382.56,
    'option-3:10000:16': 21.08,
    'option-1:450000:52': 1444.1,
    'option-2:70000:48': 289.76,
    'option-4:35000:34': 55.59,
    'option-2:70000:36': 119.97,
    'option-2:15000:9': 22.6,
    'option-3:65000:42': 115.82,
    'option-4:45000:14': 46.6,
    'option-4:50000:30': 68.71,
    'option-2:250000:42': 633.53,
    'option-2:25000:20': 27.34,
    'option-4:500000:54': 3292.08,
    'option-3:250000:44': 409.28,
    'option-4:95000:43': 267.01,
    'option-1:60000:46': 137.8,
    'option-2:50000:17': 39.88,
    'option-1:100000:60': 447.9,
    'option-2:300000:54': 1906.22,
    'option-3:25000:26': 31.46,
    'option-1:200000:34': 138.22,
    'option-3:55000:15': 46.41,
    'option-4:250000:60': 2322.83,
    'option-4:90000:13': 78.73,
    'option-4:300000:25': 231.99,
    'option-4:250000:1': 193.24,
    'option-4:500000:31': 621.23,
    'option-1:35000:38': 45.32,
    'option-3:400000:25': 247.96,
    'option-1:5000:49': 25.44,
    'option-4:10000:35': 26.75,
    'option-1:85000:15': 45.83,
    'option-2:300000:55': 1906.22,
    'option-3:100000:10': 72.32,
    'option-1:40000:11': 29.6,
    'option-1:20000:35': 26.89,
    'option-4:85000:23': 75.13,
    'option-4:150000:33': 194.94,
    'option-1:450000:59': 1995.74,
    'option-1:400000:50': 857.69,
    'option-3:250000:48': 593.1,
    'option-3:350000:38': 406.59,
    'option-2:40000:9': 34.87,
    'option-1:15000:22': 20.75,
    'option-3:5000:49': 26.44,
    'option-3:45000:36': 85.95,
    'option-4:35000:18': 39.42,
    'option-1:100000:28': 61.53,
    'option-3:40000:49': 104.04,
    'option-4:55000:24': 53.77,
    'option-3:400000:37': 462.43,
    'option-3:35000:33': 43.26,
    'option-2:80000:58': 722.53,
    'option-3:400000:36': 462.43,
    'option-2:150000:24': 90.0,
    'option-4:85000:41': 240.45,
    'option-3:250000:45': 409.28,
    'option-4:35000:58': 322.73,
    'option-1:35000:7': 27.79,
    'option-3:30000:36': 46.93,
    'option-2:200000:7': 115.13,
    'option-3:60000:33': 64.14,
    'option-2:15000:1': 22.6,
    'option-3:350000:58': 1643.23,
    'option-1:95000:44': 142.85,
    'option-3:30000:17': 31.96,
    'option-3:40000:54': 144.99,
    'option-4:350000:40': 627.94,
    'option-3:75000:13': 57.84,
    'option-4:300000:19': 231.99,
    'option-1:90000:37': 94.58,
    'option-1:45000:12': 31.41,
    'option-4:55000:3': 53.77,
    'option-3:70000:55': 247.86,
    'option-1:70000:19': 40.39,
    'option-4:30000:11': 35.83,
    'option-4:40000:20': 43.01,
    'option-4:10000:18': 22.33,
    'option-4:150000:27': 150.66,
    'option-2:75000:6': 52.32,
    'option-2:35000:31': 48.5,
    'option-2:400000:34': 414.97,
    'option-2:450000:33': 464.32,
    'option-1:10000:12': 19.14,
    'option-2:80000:4': 54.84,
    'option-1:500000:21': 198.81,
    'option-3:95000:42': 163.23,
    'option-4:20000:29': 32.19,
    'option-4:60000:10': 57.35,
    'option-4:55000:1': 53.77,
    'option-1:400000:56': 1777.3,
    'option-2:40000:23': 34.87,
    'option-3:95000:4': 69.42,
    'option-4:90000:43': 254.02,
    'option-4:85000:4': 75.13,
    'option-4:30000:32': 49.59,
    'option-1:15000:4': 20.75,
    'option-3:10000:52': 46.58,
    'option-1:300000:21': 126.42,
    'option-1:15000:50': 44.13,
    'option-3:45000:35': 51.62,
    'option-1:95000:24': 49.46,
    'option-4:300000:50': 1288.12,
    'option-4:55000:0': 53.77,
    'option-1:200000:46': 432.98,
    'option-2:250000:58': 2259.38,
    'option-1:90000:27': 56.85,
    'option-2:20000:44': 60.18,
    'option-1:450000:16': 180.68,
    'option-2:40000:33': 53.49,
    'option-3:200000:37': 236.51,
    'option-3:150000:39': 180.37,
    'option-4:15000:38': 39.25,
    'option-2:75000:40': 127.65,
    'option-4:85000:46': 369.19,
    'option-1:450000:56': 1995.74,
    'option-1:10000:29': 20.07,
    'option-2:15000:39': 36.35,
    'option-4:5000:48': 35.24,
    'option-2:350000:29': 261.45,
    'option-2:15000:44': 49.23,
    'option-2:5000:12': 18.18,
    'option-3:75000:22': 57.84,
    'option-1:55000:17': 34.95,
    'option-2:85000:10': 57.35,
    'option-1:90000:56': 403.69,
    'option-3:55000:6': 46.41,
    'option-3:65000:4': 52.19,
    'option-2:15000:41': 49.23,
    'option-2:250000:21': 140.03,
    'option-4:400000:10': 303.18,
    'option-2:75000:30': 66.57,
    'option-2:400000:11': 217.84,
    'option-4:10000:59': 100.14,
    'option-1:90000:5': 47.65,
    'option-3:70000:43': 123.78,
    'option-1:80000:1': 44.02,
    'option-3:250000:9': 158.81,
    'option-1:40000:42': 67.67,
    'option-4:10000:20': 22.33,
    'option-4:75000:39': 143.42,
    'option-2:35000:38': 66.1,
    'option-3:35000:29': 38.3,
    'option-3:80000:21': 60.74,
    'option-1:400000:9': 162.51,
    'option-2:95000:18': 62.38,
    'option-3:25000:52': 93.87,
    'option-1:45000:11': 31.41,
    'option-2:350000:22': 193.15,
    'option-2:35000:17': 32.36,
    'option-3:80000:9': 60.74,
    'option-2:45000:29': 45.77,
    'option-3:90000:40': 113.61,
    'option-4:65000:50': 283.83,
    'option-2:150000:21': 90.0,
    'option-1:250000:36': 238.09,
    'option-3:55000:17': 46.41,
    'option-4:10000:41': 40.12,
    'option-3:25000:1': 29.07,
    'option-3:500000:4': 306.01,
    'option-4:70000:3': 64.52,
    'option-3:50000:20': 43.52,
    'option-1:200000:49': 432.98,
    'option-4:400000:18': 303.18,
    'option-4:15000:12': 25.51,
    'option-3:75000:34': 76.66,
    'option-2:450000:50': 1825.01,
    'option-2:85000:9': 57.35,
    'option-3:35000:10': 34.85,
    'option-3:15000:3': 23.62,
    'option-3:45000:38': 85.95,
    'option-1:450000:0': 180.68,
    'option-3:70000:30': 62.22,
    'option-4:450000:22': 339.37,
    'option-1:15000:9': 20.75,
    'option-3:15000:56': 79.2,
    'option-2:15000:25': 22.6,
    'option-3:300000:13': 190.55,
    'option-4:45000:6': 46.6,
    'option-2:100000:2': 64.89,
    'option-2:100000:21': 64.89,
    'option-4:30000:21': 35.83,
    'option-2:40000:47': 168.67,
    'option-2:350000:3': 193.15,
    'option-1:35000:48': 85.11,
    'option-3:70000:40': 91.53,
    'option-2:20000:34': 33.62,
    'option-2:100000:52': 635.05,
    'option-3:350000:3': 219.56,
    'option-1:70000:48': 158.9,
    'option-1:95000:22': 49.46,
    'option-2:300000:23': 167.94,
    'option-4:5000:28': 20.06,
    'option-1:80000:24': 44.02,
    'option-1:250000:17': 105.52,
    'option-1:10000:55': 44.48,
    'option-4:90000:17': 78.73,
    'option-1:350000:14': 144.61,
    'option-2:35000:27': 38.78,
    'option-2:65000:54': 414.3,
    'option-4:80000:2': 71.54,
    'option-3:400000:45': 651.63,
    'option-2:25000:17': 27.34,
    'option-3:25000:4': 29.07,
    'option-2:60000:41': 159.88,
    'option-3:55000:1': 46.41,
    'option-2:250000:27': 188.58,
    'option-2:400000:60': 3628.82,
    'option-1:400000:20': 162.51,
    'option-2:5000:41': 27.19,
    'option-3:40000:3': 37.75,
    'option-3:400000:0': 247.96,
    'option-1:60000:59': 272.05,
    'option-3:10000:11': 21.08,
    'option-1:45000:31': 42.36,
    'option-4:400000:5': 303.18,
    'option-1:15000:14': 20.75,
    'option-3:200000:29': 150.97,
    'option-2:500000:41': 1258.47,
    'option-4:20000:33': 37.47,
    'option-2:40000:24': 34.87,
    'option-2:30000:53': 192.28,
    'option-2:45000:44': 122.44,
    'option-1:40000:38': 49.82,
    'option-1:25000:35': 29.98,
    'option-2:80000:28': 70.06,
    'option-2:350000:52': 2224.55,
    'option-2:45000:13': 37.38,
    'option-2:65000:51': 414.3,
    'option-4:350000:3': 267.99,
    'option-2:200000:50': 814.59,
    'option-2:20000:52': 129.0,
    'option-3:150000:31': 139.03,
    'option-2:90000:59': 813.29,
    'option-1:100000:11': 51.27,
    'option-3:90000:12': 66.53,
    'option-4:45000:41': 131.87,
    'option-1:75000:28': 49.82,
    'option-1:75000:10': 42.21,
    'option-3:20000:26': 28.06,
    'option-3:450000:0': 277.08,
    'option-2:250000:51': 1584.07,
    'option-1:70000:59': 316.26,
    'option-1:250000:38': 238.09,
    'option-3:95000:52': 332.64,
    'option-3:35000:0': 34.85,
    'option-3:75000:26': 65.49,
    'option-4:400000:23': 303.18,
    'option-1:200000:11': 87.52,
    'option-2:25000:5': 27.34,
    'option-2:15000:28': 25.24,
    'option-2:60000:15': 44.9,
    'option-3:80000:49': 197.55,
    'option-4:25000:27': 36.76,
    'option-2:20000:13': 24.83,
    'option-4:15000:20': 25.51,
    'option-2:55000:32': 68.44,
    'option-4:40000:8': 43.01,
    'option-4:50000:29': 68.71,
    'option-3:150000:45': 251.06,
    'option-4:150000:47': 646.43,
    'option-3:20000:36': 35.77,
    'option-3:400000:22': 247.96,
    'option-3:10000:31': 23.36,
    'option-4:400000:14': 303.18,
    'option-4:85000:59': 789.16,
    'option-2:450000:45': 1133.53,
    'option-1:350000:31': 233.75,
    'option-3:450000:18': 277.08,
    'option-2:350000:26': 261.45,
    'option-1:90000:33': 70.08,
    'option-1:55000:9': 34.95,
    'option-1:40000:0': 29.6,
    'option-3:450000:41': 730.38,
    'option-2:250000:8': 140.03,
    'option-2:45000:17': 37.38,
    'option-4:85000:29': 91.36,
    'option-3:20000:15': 26.19,
    'option-2:15000:43': 49.23,
    'option-1:60000:18': 36.76,
    'option-1:20000:9': 22.35,
    'option-4:150000:59': 1395.44,
    'option-4:15000:49': 73.02,
    'option-3:15000:34': 27.07,
    'option-3:70000:41': 123.78,
    'option-3:60000:29': 55.39,
    'option-3:75000:57': 356.61,
    'option-3:85000:2': 63.63,
    'option-2:150000:16': 90.0,
    'option-3:90000:45': 155.28,
    'option-3:60000:17': 49.3,
    'option-1:450000:37': 420.59,
    'option-2:40000:12': 34.87,
    'option-1:40000:12': 29.6,
    'option-1:45000:40': 54.32,
    'option-2:400000:9': 217.84,
    'option-2:95000:58': 856.77,
    'option-3:80000:55': 282.08,
    'option-3:30000:3': 31.96,
    'option-2:80000:35': 93.36,
    'option-4:70000:59': 649.16,
    'option-1:75000:22': 42.21,
    'option-4:60000:2': 57.35,
    'option-1:100000:23': 51.27,
    'option-1:20000:22': 22.35,
    'option-3:300000:46': 713.32,
    'option-3:350000:10': 219.56,
    'option-1:350000:48': 752.09,
    'option-1:500000:51': 1603.9,
    'option-1:35000:33': 36.17,
    'option-1:80000:18': 44.02,
    'option-2:30000:31': 43.5,
    'option-3:90000:52': 315.56,
    'option-4:300000:3': 231.99,
    'option-3:15000:58': 79.2,
    'option-2:55000:6': 42.39,
    'option-2:85000:26': 73.56,
    'option-2:20000:10': 24.83,
    'option-3:200000:21': 130.15,
    'option-2:75000:47': 309.96,
    'option-1:85000:17': 45.83,
    'option-1:35000:46': 85.11,
    'option-4:90000:59': 835.82,
    'option-2:75000:29': 66.57,
    'option-1:90000:21': 47.65,
    'option-2:20000:53': 129.0,
    'option-4:250000:53': 1642.76,
    'option-2:35000:3': 32.36,
    'option-3:5000:51': 31.43,
    'option-4:20000:59': 182.88,
    'option-2:75000:31': 88.37,
    'option-2:250000:48': 1016.7,
    'option-2:55000:7': 42.39,
    'option-1:100000:53': 326.98,
    'option-1:20000:52': 72.59,
    'option-1:450000:49': 962.08,
    'option-2:25000:53': 160.37,
    'option-4:90000:2': 78.73,
    'option-1:15000:12': 20.75,
    'option-4:90000:37': 169.34,
    'option-3:20000:4': 26.19,
    'option-2:55000:10': 42.39,
    'option-2:95000:19': 62.38,
    'option-2:60000:44': 159.88,
    'option-2:100000:57': 902.07,
    'option-2:60000:33': 73.43,
    'option-2:90000:24': 59.87,
    'option-3:50000:9': 43.52,
    'option-4:55000:17': 53.77,
    'option-2:200000:10': 115.13,
    'option-2:400000:3': 217.84,
    'option-1:350000:40': 331.11,
    'option-1:450000:40': 420.59,
    'option-1:50000:8': 33.22,
    'option-2:450000:7': 243.1,
    'option-4:80000:54': 527.97,
    'option-4:10000:5': 22.33,
    'option-1:90000:40': 94.58,
    'option-2:150000:26': 118.93,
    'option-1:500000:33': 326.33,
    'option-4:85000:10': 75.13,
    'option-1:95000:45': 142.85,
    'option-4:70000:54': 462.14,
    'option-3:100000:14': 72.32,
    'option-4:5000:46': 35.24,
    'option-1:65000:25': 38.58,
    'option-2:40000:21': 34.87,
    'option-1:80000:56': 360.46,
    'option-4:350000:10': 267.99,
    'option-3:350000:39': 406.59,
    'option-1:30000:37': 40.82,
    'option-3:400000:51': 1379.44,
    'option-4:30000:10': 35.83,
    'option-1:25000:39': 36.38,
    'option-2:85000:34': 98.13,
    'option-2:40000:11': 34.87,
    'option-2:20000:12': 24.83,
    'option-4:450000:42': 1231.7,
    'option-3:50000:55': 179.39,
    'option-2:15000:5': 22.6,
    'option-1:250000:26': 131.5,
    'option-1:150000:25': 69.4,
    'option-1:85000:50': 190.55,
    'option-2:50000:3': 39.88,
    'option-1:250000:25': 105.52,
    'option-1:90000:23': 47.65,
    'option-4:85000:47': 369.19,
    'option-1:75000:32': 60.79,
    'option-4:10000:37': 31.57,
    'option-4:60000:43': 172.61,
    'option-4:75000:4': 67.96,
    'option-2:45000:5': 37.38,
    'option-4:350000:22': 267.99,
    'option-4:15000:5': 25.51,
    'option-1:95000:30': 59.19,
    'option-2:200000:32': 212.89,
    'option-1:45000:19': 31.41,
    'option-3:95000:48': 232.07,
    'option-4:500000:46': 2140.7,
    'option-3:25000:58': 123.04,
    'option-4:95000:42': 267.01,
    'option-2:85000:38': 143.02,
    'option-4:300000:57': 2795.63,
    'option-1:55000:19': 34.95,
    'option-3:60000:2': 49.3,
    'option-1:80000:16': 44.02,
    'option-3:5000:36': 20.96,
    'option-2:25000:35': 38.59,
    'option-4:10000:1': 22.33,
    'option-4:70000:21': 64.52,
    'option-3:20000:0': 26.19,
    'option-3:95000:26': 79.17,
    'option-3:35000:19': 34.85,
    'option-4:450000:34': 560.54,
    'option-3:450000:33': 391.97,
    'option-1:150000:56': 669.67,
    'option-4:15000:28': 28.13,
    'option-4:250000:54': 1642.76,
    'option-3:85000:38': 108.04,
    'option-2:20000:57': 178.32,
    'option-4:75000:13': 67.96,
    'option-1:10000:5': 19.14,
    'option-1:35000:52': 120.52,
    'option-1:5000:15': 17.54,
    'option-4:45000:19': 46.6,
    'option-4:80000:5': 71.54,
    'option-2:70000:10': 49.81,
    'option-3:350000:48': 830.5,
    'option-1:65000:24': 38.58,
    'option-3:40000:45': 75.99,
    'option-2:10000:38': 29.61,
    'option-1:60000:28': 42.91,
    'option-3:500000:40': 573.54,
    'option-1:100000:52': 326.98,
    'option-3:50000:51': 179.39,
    'option-4:30000:22': 35.83,
    'option-3:20000:41': 44.22,
    'option-4:45000:12': 46.6,
    'option-4:5000:40': 23.81,
    'option-1:300000:54': 966.98,
    'option-2:35000:28': 38.78,
    'option-1:20000:26': 24.22,
    'option-2:30000:9': 29.85,
    'option-1:90000:55': 295.09,
    'option-2:200000:54': 1270.4,
    'option-4:300000:22': 231.99,
    'option-1:25000:49': 63.98,
    'option-1:65000:0': 38.58,
    'option-4:150000:15': 121.72,
    'option-1:20000:32': 26.89,
    'option-4:20000:9': 28.65,
    'option-4:450000:25': 339.37,
    'option-2:40000:30': 42.27,
    'option-2:60000:21': 44.9,
    'option-3:200000:53': 692.37,
    'option-2:5000:10': 18.18,
    'option-2:40000:51': 255.85,
    'option-4:75000:42': 213.32,
    'option-4:10000:53': 74.88,
    'option-2:400000:12': 217.84,
    'option-4:200000:20': 157.69,
    'option-2:90000:51': 572.96,
    'option-4:400000:49': 1715.79,
    'option-3:50000:17': 43.52,
    'option-3:10000:25': 21.08,
    'option-1:55000:27': 40.57,
    'option-3:350000:9': 219.56,
    'option-2:90000:42': 234.7,
    'option-2:50000:34': 63.46,
    'option-3:15000:25': 23.62,
    'option-3:45000:58': 216.4,
    'option-4:250000:45': 687.95,
    'option-4:80000:38': 152.18,
    'option-4:15000:39': 39.25,
    'option-2:50000:37': 89.22,
    'option-3:250000:58': 1170.85,
    'option-3:100000:47': 243.77,
    'option-1:500000:58': 2217.34,
    'option-4:45000:17': 46.6,
    'option-4:45000:44': 131.87,
    'option-3:100000:49': 243.77,
    'option-1:250000:55': 803.8,
    'option-2:100000:9': 64.89,
    'option-2:300000:26': 226.37,
    'option-3:50000:39': 91.53,
    'option-4:15000:60': 141.43,
    'option-2:35000:47': 148.45,
    'option-2:20000:55': 129.0,
    'option-3:100000:5': 72.32,
    'option-3:95000:60': 448.95,
    'option-2:350000:18': 193.15,
    'option-1:450000:19': 180.68,
    'option-4:80000:56': 742.5,
    'option-1:90000:49': 200.66,
    'option-4:350000:19': 267.99,
    'option-2:300000:7': 167.94,
    'option-3:55000:13': 46.41,
    'option-3:250000:8': 158.81,
    'option-3:75000:7': 57.84,
    'option-3:350000:20': 219.56,
    'option-3:400000:4': 247.96,
    'option-2:500000:53': 3173.99,
    'option-1:200000:31': 138.22,
    'option-4:400000:1': 303.18,
    'option-3:45000:15': 40.63,
    'option-4:75000:32': 104.09,
    'option-3:35000:49': 92.52,
    'option-1:35000:41': 60.78,
    'option-2:55000:12': 42.39,
    'option-4:200000:45': 552.22,
    'option-1:15000:51': 58.6,
    'option-4:50000:16': 50.18,
    'option-1:30000:51': 104.56,
    'option-3:45000:46': 115.77,
    'option-3:500000:20': 306.01,
    'option-3:45000:40': 85.95,
    'option-2:95000:56': 856.77,
    'option-3:95000:13': 69.42,
    'option-3:85000:52': 299.19,
    'option-4:70000:17': 64.52,
    'option-4:60000:18': 57.35,
    'option-2:450000:36': 705.79,
    'option-4:65000:46': 283.83,
    'option-3:70000:15': 54.96,
    'option-3:400000:28': 289.7,
    'option-2:10000:54': 72.75,
    'option-1:200000:32': 138.22,
    'option-4:55000:48': 241.13,
    'option-3:5000:56': 37.75,
    'option-4:100000:9': 85.91,
    'option-4:10000:22': 22.33,
    'option-4:100000:0': 85.91,
    'option-4:80000:31': 110.14,
    'option-1:100000:39': 103.59,
    'option-4:20000:42': 64.1,
    'option-1:55000:33': 48.55,
    'option-4:80000:9': 71.54,
    'option-3:60000:32': 64.14,
    'option-2:200000:30': 153.97,
    'option-3:70000:20': 54.96,
    'option-2:70000:46': 289.76,
    'option-1:60000:21': 36.76,
    'option-3:15000:52': 61.73,
    'option-2:200000:26': 153.97,
    'option-2:450000:19': 243.1,
    'option-1:350000:8': 144.61,
    'option-2:80000:27': 70.06,
    'option-2:95000:42': 246.66,
    'option-4:30000:3': 35.83,
    'option-4:50000:57': 462.36,
    'option-3:400000:59': 1876.99,
    'option-1:55000:34': 48.55,
    'option-1:60000:23': 36.76,
    'option-1:350000:53': 1126.92,
    'option-4:250000:8': 193.24,
    'option-4:5000:31': 21.38,
    'option-1:100000:16': 51.27,
    'option-1:55000:14': 34.95,
    'option-1:95000:12': 49.46,
    'option-2:75000:19': 52.32,
    'option-4:100000:55': 658.21,
    'option-2:50000:4': 39.88,
    'option-2:50000:57': 450.11,
    'option-2:200000:34': 212.89,
    'option-3:85000:37': 108.04,
    'option-4:60000:38': 117.13,
    'option-3:350000:47': 830.5,
    'option-3:300000:14': 190.55,
    'option-4:75000:36': 143.42,
    'option-3:60000:34': 64.14,
    'option-2:85000:44': 222.23,
    'option-1:75000:52': 247.96,
    'option-4:50000:8': 50.18,
    'option-4:500000:32': 621.23,
    'option-3:70000:52': 247.86,
    'option-4:85000:60': 789.16,
    'option-4:85000:35': 115.95,
    'option-3:300000:47': 713.32,
    'option-2:300000:19': 167.94,
    'option-3:70000:3': 54.96,
    'option-3:40000:20': 37.75,
    'option-2:55000:14': 42.39,
    'option-1:200000:29': 108.3,
    'option-2:35000:50': 148.45,
    'option-3:100000:52': 349.79,
    'option-4:500000:2': 375.29,
    'option-2:60000:0': 44.9,
    'option-2:75000:33': 88.37,
    'option-4:50000:45': 145.46,
    'option-3:15000:33': 27.07,
    'option-4:10000:10': 22.33,
    'option-2:30000:8': 29.85,
    'option-2:25000:36': 50.86,
    'option-2:20000:24': 24.83,
    'option-4:5000:57': 58.98,
    'option-3:350000:7': 219.56,
    'option-1:45000:43': 74.54,
    'option-3:200000:39': 236.51,
    'option-3:80000:12': 60.74,
    'option-4:15000:0': 25.51,
    'option-1:10000:9': 19.14,
    'option-1:250000:40': 238.09,
    'option-2:35000:25': 32.36,
    'option-3:75000:33': 76.66,
    'option-4:10000:25': 22.33,
    'option-1:350000:21': 144.61,
    'option-2:25000:34': 38.59,
    'option-1:10000:10': 19.14,
    'option-3:200000:22': 130.15,
    'option-2:250000:46': 1016.7,
    'option-3:20000:42': 44.22,
    'option-1:55000:24': 34.95,
    'option-1:95000:60': 425.75,
    'option-1:80000:2': 44.02,
    'option-1:50000:22': 33.22,
    'option-1:75000:30': 49.82,
    'option-2:500000:59': 4532.46,
    'option-1:25000:22': 24.16,
    'option-1:30000:47': 74.55,
    'option-4:450000:16': 339.37,
    'option-3:60000:60': 286.53,
    'option-3:95000:29': 79.17,
    'option-3:500000:23': 306.01,
    'option-1:35000:30': 31.23,
    'option-4:100000:13': 85.91,
    'option-3:65000:46': 162.51,
    'option-2:75000:7': 52.32,
    'option-3:80000:34': 80.67,
    'option-1:25000:59': 117.34,
    'option-1:55000:21': 34.95,
    'option-4:5000:42': 28.16,
    'option-1:40000:34': 39.26,
    'option-1:30000:55': 104.56,
    'option-4:35000:24': 39.42,
    'option-4:30000:1': 35.83,
    'option-1:5000:29': 18.03,
    'option-3:15000:47': 47.15,
    'option-3:450000:58': 2107.81,
    'option-1:65000:10': 38.58,
    'option-3:95000:30': 79.17,
    'option-4:80000:41': 226.89,
    'option-4:20000:54': 133.23,
    'option-1:70000:24': 40.39,
    'option-1:60000:4': 36.76,
    'option-2:400000:42': 1010.21,
    'option-2:45000:32': 58.48,
    'option-2:35000:19': 32.36,
    'option-4:65000:23': 60.94,
    'option-1:25000:56': 117.34,
    'option-3:15000:20': 23.62,
    'option-3:100000:28': 82.59,
    'option-2:450000:28': 330.95,
    'option-1:55000:8': 34.95,
    'option-3:50000:45': 91.93,
    'option-1:90000:2': 47.65,
    'option-1:300000:45': 426.63,
    'option-3:45000:47': 115.77,
    'option-4:10000:36': 31.57,
    'option-2:5000:39': 22.83,
    'option-3:100000:50': 243.77,
    'option-2:350000:33': 365.08,
    'option-3:95000:19': 69.42,
    'option-4:85000:42': 240.45,
    'option-4:90000:41': 254.02,
    'option-2:25000:43': 72.65,
    'option-1:10000:6': 19.14,
    'option-1:20000:55': 72.59,
    'option-3:80000:38': 102.69,
    'option-3:5000:4': 18.54,
    'option-3:60000:19': 49.3,
    'option-2:150000:17': 90.0,
    'option-4:450000:1': 339.37,
    'option-1:50000:19': 33.22,
    'option-4:30000:38': 64.6,
    'option-3:25000:29': 31.46,
    'option-1:30000:59': 139.54,
    'option-3:75000:11': 57.84,
    'option-2:5000:5': 18.18,
    'option-2:300000:58': 2719.24,
    'option-2:500000:5': 268.23,
    'option-1:30000:2': 25.97,
    'option-4:200000:48': 859.56,
    'option-2:40000:42': 109.92,
    'option-1:45000:22': 31.41,
    'option-1:50000:44': 81.4,
    'option-3:30000:20': 31.96,
    'option-2:200000:53': 1270.4,
    'option-3:25000:45': 52.18,
    'option-2:90000:54': 572.96,
    'option-3:20000:57': 99.92,
    'option-2:80000:8': 54.84,
    'option-4:60000:22': 57.35,
    'option-1:45000:37': 54.32,
    'option-1:450000:23': 180.68,
    'option-4:350000:21': 267.99,
    'option-3:100000:46': 243.77,
    'option-2:80000:59': 722.53,
    'option-3:500000:26': 358.38,
    'option-4:350000:15': 267.99,
    'option-2:60000:27': 56.24,
    'option-1:70000:51': 232.02,
    'option-1:150000:5': 69.4,
    'option-4:90000:19': 78.73,
    'option-2:55000:11': 42.39,
    'option-2:500000:21': 268.23,
    'option-2:300000:43': 760.29,
    'option-1:60000:24': 36.76,
    'option-3:85000:11': 63.63,
    'option-1:250000:16': 105.52,
    'option-3:75000:40': 97.11,
    'option-2:400000:36': 629.74,
    'option-1:35000:19': 27.79,
    'option-2:40000:39': 73.84,
    'option-4:50000:10': 50.18,
    'option-3:20000:34': 30.78,
    'option-2:350000:38': 552.81,
    'option-2:350000:13': 193.15,
    'option-4:45000:53': 297.37,
    'option-3:40000:27': 41.72,
    'option-4:50000:53': 330.38,
    'option-2:150000:38': 242.88,
    'option-4:250000:4': 193.24,
    'option-2:20000:16': 24.83,
    'option-3:100000:30': 82.59,
    'option-2:250000:60': 2259.38,
    'option-3:15000:30': 25.05,
    'option-2:30000:19': 29.85,
    'option-1:20000:37': 31.89,
    'option-4:10000:43': 40.12,
    'option-3:150000:36': 180.37,
    'option-2:60000:28': 56.24,
    'option-3:5000:6': 18.54,
    'option-4:200000:5': 157.69,
    'option-3:55000:35': 59.97,
    'option-1:35000:49': 85.11,
    'option-2:15000:35': 29.2,
    'option-2:45000:33': 58.48,
    'option-1:85000:25': 45.83,
    'option-2:55000:38': 96.91,
    'option-3:60000:55': 213.63,
    'option-3:80000:51': 282.08,
    'option-3:100000:17': 72.32,
    'option-4:25000:41': 77.68,
    'option-2:15000:19': 22.6,
    'option-3:400000:27': 289.7,
    'option-1:80000:33': 63.88,
    'option-3:250000:13': 158.81,
    'option-1:50000:18': 33.22,
    'option-2:25000:0': 27.34,
    'option-1:300000:16': 126.42,
    'option-2:75000:20': 52.32,
    'option-1:100000:41': 149.72,
    'option-2:5000:52': 44.79,
    'option-3:5000:19': 18.54,
    'option-1:75000:57': 338.36,
    'option-2:200000:11': 115.13,
    'option-3:5000:22': 18.54,
    'option-2:300000:59': 2719.24,
    'option-4:95000:18': 82.32,
    'option-3:35000:30': 38.3,
    'option-3:250000:46': 593.1,
    'option-2:50000:53': 319.04,
    'option-4:200000:22': 157.69,
    'option-3:40000:59': 193.28,
    'option-2:95000:31': 108.1,
    'option-2:55000:60': 495.55,
    'option-4:70000:14': 64.52,
    'option-2:85000:40': 143.02,
    'option-4:70000:39': 134.66,
    'option-2:45000:38': 81.53,
    'option-1:5000:25': 17.54,
    'option-4:15000:11': 25.51,
    'option-2:200000:20': 115.13,
    'option-3:30000:50': 80.83,
    'option-1:25000:33': 29.98,
    'option-1:70000:27': 47.48,
    'option-4:70000:23': 64.52,
    'option-4:300000:55': 1976.86,
    'option-1:100000:19': 51.27,
    'option-1:70000:34': 57.82,
    'option-4:40000:52': 264.83,
    'option-2:300000:45': 760.29,
    'option-1:35000:42': 60.78,
    'option-3:450000:44': 730.38,
    'option-3:75000:15': 57.84,
    'option-4:55000:57': 509.09,
    'option-3:80000:14': 60.74,
    'option-2:250000:23': 140.03,
    'option-1:40000:30': 33.56,
    'option-3:75000:55': 264.97,
    'option-2:10000:57': 97.85,
    'option-2:5000:16': 18.18,
    'option-4:20000:12': 28.65,
    'option-2:75000:5': 52.32,
    'option-3:450000:30': 324.16,
    'option-3:85000:46': 209.22,
    'option-3:50000:32': 55.79,
    'option-3:85000:16': 63.63,
    'option-3:95000:35': 93.2,
    'option-3:45000:59': 216.4,
    'option-2:500000:25': 268.23,
    'option-1:70000:21': 40.39,
    'option-1:90000:13': 47.65,
    'option-2:20000:49': 87.9,
    'option-1:450000:31': 295.33,
    'option-3:35000:54': 128.19,
    'option-2:90000:26': 77.06,
    'option-4:60000:58': 555.79,
    'option-1:15000:49': 44.13,
    'option-2:10000:58': 97.85,
    'option-3:95000:55': 332.64,
    'option-2:80000:2': 54.84,
    'option-3:400000:15': 247.96,
    'option-1:5000:47': 25.44,
    'option-4:100000:21': 85.91,
    'option-1:25000:34': 29.98,
    'option-3:50000:37': 91.53,
    'option-4:250000:39': 449.08,
    'option-1:5000:40': 19.96,
    'option-2:250000:33': 262.24,
    'option-1:400000:18': 162.51,
    'option-2:20000:15': 24.83,
    'option-3:45000:32': 51.62,
    'option-2:70000:20': 49.81,
    'option-4:300000:21': 231.99,
    'option-3:55000:8': 46.41,
    'option-1:15000:21': 20.75,
    'option-2:50000:28': 49.26,
    'option-3:60000:43': 107.86,
    'option-1:20000:58': 95.46,
    'option-4:30000:34': 49.59,
    'option-3:60000:40': 80.37,
    'option-4:400000:12': 303.18,
    'option-3:10000:56': 58.52,
    'option-3:5000:33': 19.69,
    'option-2:500000:30': 365.95,
    'option-4:300000:4': 231.99,
    'option-1:65000:50': 148.35,
    'option-1:60000:7': 36.76,
    'option-2:60000:39': 104.6,
    'option-3:70000:34': 72.49,
    'option-4:95000:2': 82.32,
    'option-2:90000:45': 234.7,
    'option-2:40000:10': 34.87,
    'option-2:65000:30': 59.74,
    'option-4:10000:17': 22.33,
    'option-2:50000:13': 39.88,
    'option-2:45000:37': 81.53,
    'option-4:85000:6': 75.13,
    'option-1:50000:41': 81.4,
    'option-2:300000:12': 167.94,
    'option-2:150000:8': 90.0,
    'option-4:50000:60': 462.36,
    'option-2:100000:19': 64.89,
    'option-3:50000:60': 239.79,
    'option-1:75000:43': 115.71,
    'option-4:60000:23': 57.35,
    'option-3:150000:53': 521.48,
    'option-1:50000:34': 45.45,
    'option-4:500000:58': 4660.16,
    'option-2:85000:36': 143.02,
    'option-1:300000:39': 285.96,
    'option-1:80000:7': 44.02,
    'option-4:25000:53': 165.77,
    'option-4:500000:12': 375.29,
    'option-4:100000:52': 658.21,
    'option-3:15000:29': 25.05,
    'option-2:5000:40': 22.83,
    'option-4:25000:34': 43.53,
    'option-1:70000:25': 40.39,
    'option-1:25000:37': 36.38,
    'option-1:95000:8': 49.46,
    'option-1:80000:43': 122.56,
    'option-2:250000:24': 140.03,
    'option-4:250000:46': 1073.05,
    'option-3:65000:60': 309.89,
    'option-2:200000:56': 1810.8,
    'option-4:95000:17': 82.32,
    'option-3:10000:18': 21.08,
    'option-2:70000:27': 63.23,
    'option-3:65000:44': 115.82,
    'option-4:450000:20': 339.37,
    'option-4:200000:39': 362.39,
    'option-2:25000:44': 72.65,
    'option-2:10000:19': 20.37,
    'option-1:70000:42': 108.85,
    'option-2:60000:6': 44.9,
    'option-2:100000:22': 64.89,
    'option-4:15000:26': 28.13,
    'option-4:85000:22': 75.13,
    'option-2:70000:47': 289.76,
    'option-1:75000:60': 338.36,
    'option-3:70000:9': 54.96,
    'option-2:10000:44': 38.16,
    'option-3:80000:26': 68.9,
    'option-4:300000:5': 231.99,
    'option-3:40000:9': 37.75,
    'option-4:70000:57': 649.16,
    'option-1:100000:7': 51.27,
    'option-1:250000:9': 105.52,
    'option-4:40000:37': 82.04,
    'option-2:400000:30': 295.72,
    'option-1:35000:14': 27.79,
    'option-4:50000:24': 50.18,
    'option-3:5000:32': 19.69,
    'option-1:65000:28': 45.15,
    'option-4:40000:29': 55.0,
    'option-2:80000:40': 135.33,
    'option-1:450000:7': 180.68,
    'option-2:50000:40': 89.22,
    'option-2:45000:4': 37.38,
    'option-4:80000:6': 71.54,
    'option-3:250000:24': 158.81,
    'option-2:300000:39': 475.65,
    'option-4:50000:4': 50.18,
    'option-1:35000:37': 45.32,
    'option-3:60000:6': 49.3,
    'option-4:55000:22': 53.77,
    'option-1:15000:29': 22.14,
    'option-1:60000:34': 51.64,
    'option-1:300000:23': 126.42,
    'option-4:30000:50': 134.53,
    'option-3:15000:49': 47.15,
    'option-2:60000:23': 44.9,
    'option-4:35000:50': 155.91,
    'option-1:400000:15': 162.51,
    'option-4:30000:47': 134.53,
    'option-3:40000:36': 80.37,
    'option-1:5000:6': 17.54,
    'option-1:20000:48': 53.43,
    'option-1:95000:32': 73.18,
    'option-4:20000:51': 133.23,
    'option-3:45000:57': 216.4,
    'option-2:450000:11': 243.1,
    'option-1:25000:47': 63.98,
    'option-1:5000:50': 25.44,
    'option-1:500000:42': 700.73,
    'option-2:400000:59': 3628.82,
    'option-1:200000:50': 432.98,
    'option-2:20000:9': 24.83,
    'option-1:60000:36': 67.8,
    'option-2:100000:48': 410.11,
    'option-1:500000:20': 198.81,
    'option-2:65000:59': 586.37,
    'option-3:40000:35': 47.44,
    'option-4:10000:24': 22.33,
    'option-3:450000:23': 277.08,
    'option-1:80000:44': 122.56,
    'option-1:350000:28': 181.15,
    'option-1:200000:39': 193.58,
    'option-3:35000:28': 38.3,
    'option-4:250000:10': 193.24,
    'option-1:30000:0': 25.97,
    'option-4:90000:58': 835.82,
    'option-4:75000:27': 82.42,
    'option-4:65000:25': 60.94,
    'option-4:400000:9': 303.18,
    'option-1:300000:51': 966.98,
    'option-1:35000:8': 27.79,
    'option-1:150000:45': 218.55,
    'option-1:30000:30': 28.89,
    'option-3:30000:53': 111.05,
    'option-4:85000:56': 789.16,
    'option-2:450000:15': 243.1,
    'option-4:25000:43': 77.68,
    'option-1:80000:28': 52.16,
    'option-3:70000:13': 54.96,
    'option-2:250000:17': 140.03,
    'option-4:20000:44': 64.1,
    'option-2:450000:22': 243.1,
    'option-2:150000:3': 90.0,
    'option-1:20000:18': 22.35,
    'option-4:75000:50': 326.51,
    'option-2:100000:8': 64.89,
    'option-4:300000:9': 231.99,
    'option-3:65000:43': 115.82,
    'option-3:400000:5': 247.96,
    'option-3:500000:60': 2341.99,
    'option-4:100000:56': 927.12,
    'option-3:40000:24': 37.75,
    'option-3:60000:52': 213.63,
    'option-1:25000:1': 24.16,
    'option-1:95000:10': 49.46,
    'option-2:25000:23': 27.34,
    'option-2:350000:24': 193.15,
    'option-4:150000:38': 274.84,
    'option-2:150000:43': 384.24,
    'option-1:100000:5': 51.27,
    'option-2:450000:39': 705.79,
    'option-4:30000:27': 41.27,
    'option-3:15000:27': 25.05,
    'option-1:60000:22': 36.76,
    'option-4:500000:40': 890.21,
    'option-1:65000:37': 72.3,
    'option-4:450000:18': 339.37,
    'option-4:25000:7': 32.23,
    'option-1:45000:51': 152.25,
    'option-3:75000:3': 57.84,
    'option-1:450000:14': 180.68,
    'option-1:90000:50': 200.66,
    'option-2:300000:33': 315.03,
    'option-1:500000:49': 1067.77,
    'option-4:15000:57': 141.43,
    'option-4:150000:1': 121.72,
    'option-1:300000:53': 966.98,
    'option-4:60000:47': 262.48,
    'option-3:20000:2': 26.19,
    'option-4:25000:45': 77.68,
    'option-3:60000:47': 150.83,
    'option-2:100000:27': 84.05,
    'option-4:45000:18': 46.6,
    'option-2:15000:21': 22.6,
    'option-4:10000:55': 74.88,
    'option-1:25000:55': 88.55,
    'option-2:90000:47': 370.56,
    'option-3:450000:52': 1548.61,
    'option-3:80000:43': 139.69,
    'option-4:50000:13': 50.18,
    'option-3:45000:20': 40.63,
    'option-2:90000:29': 77.06,
    'option-1:100000:43': 149.72,
    'option-1:35000:59': 161.69,
    'option-3:50000:1': 43.52,
    'option-1:55000:43': 88.27,
    'option-2:20000:2': 24.83,
    'option-1:30000:25': 25.97,
    'option-1:70000:18': 40.39,
    'option-2:25000:27': 31.79,
    'option-3:400000:3': 247.96,
    'option-1:75000:20': 42.21,
    'option-4:400000:17': 303.18,
    'option-2:30000:54': 192.28,
    'option-4:20000:1': 28.65,
    'option-4:85000:0': 75.13,
    'option-2:150000:4': 90.0,
    'option-2:150000:32': 162.77,
    'option-2:75000:21': 52.32,
    'option-1:45000:10': 31.41,
    'option-3:75000:37': 97.11,
    'option-2:65000:34': 78.41,
    'option-4:30000:52': 198.87,
    'option-3:15000:15': 23.62,
    'option-4:90000:33': 122.0,
    'option-1:80000:6': 44.02,
    'option-3:25000:28': 31.46,
    'option-4:90000:31': 122.0,
    'option-3:70000:22': 54.96,
    'option-1:50000:26': 38.24,
    'option-3:80000:54': 282.08,
    'option-4:75000:10': 67.96,
    'option-2:200000:4': 115.13,
    'option-3:50000:44': 91.93,
    'option-2:55000:48': 229.12,
    'option-2:45000:53': 287.23,
    'option-1:30000:46': 74.55,
    'option-2:5000:27': 19.06,
    'option-1:55000:32': 48.55,
    'option-2:45000:9': 37.38,
    'option-3:5000:25': 18.54,
    'option-3:300000:48': 713.32,
    'option-3:70000:19': 54.96,
    'option-3:50000:7': 43.52,
    'option-3:15000:13': 23.62,
    'option-1:80000:48': 180.0,
    'option-2:75000:4': 52.32,
    'option-2:500000:2': 268.23,
    'option-2:30000:17': 29.85,
    'option-4:150000:16': 121.72,
    'option-1:10000:58': 56.28,
    'option-2:200000:22': 115.13,
    'option-3:500000:18': 306.01,
    'option-1:20000:42': 40.3,
    'option-2:90000:22': 59.87,
    'option-4:100000:10': 85.91,
    'option-2:400000:33': 414.97,
    'option-2:10000:55': 72.75,
    'option-4:80000:33': 110.14,
    'option-4:450000:37': 802.43,
    'option-1:45000:50': 106.12,
    'option-2:45000:43': 122.44,
    'option-2:80000:15': 54.84,
    'option-4:55000:27': 64.14,
    'option-2:40000:53': 255.85,
    'option-1:450000:10': 180.68,
    'option-1:10000:34': 21.43,
    'option-4:55000:14': 53.77,
    'option-4:150000:31': 194.94,
    'option-1:300000:49': 646.22,
    'option-4:75000:22': 67.96,
    'option-1:250000:27': 131.5,
    'option-2:40000:17': 34.87,
    'option-1:500000:9': 198.81,
    'option-4:500000:11': 375.29,
    'option-4:350000:27': 336.36,
    'option-2:50000:59': 450.11,
    'option-2:10000:22': 20.37,
    'option-4:100000:34': 134.14,
    'option-3:85000:47': 209.22,
    'option-1:150000:4': 69.4,
    'option-1:80000:31': 63.88,
    'option-4:20000:27': 32.19,
    'option-4:55000:20': 53.77,
    'option-4:10000:27': 24.07,
    'option-1:250000:50': 537.32,
    'option-1:400000:36': 376.1,
    'option-2:30000:56': 268.81,
    'option-2:250000:4': 140.03,
    'option-3:65000:21': 52.19,
    'option-2:5000:51': 44.79,
    'option-4:95000:5': 82.32,
    'option-2:90000:8': 59.87,
    'option-3:30000:27': 34.89,
    'option-2:80000:7': 54.84,
    'option-4:200000:58': 1861.57,
    'option-3:10000:12': 21.08,
    'option-2:50000:24': 39.88,
    'option-1:90000:43': 136.0,
    'option-3:20000:45': 44.22,
    'option-2:100000:38': 165.76,
    'option-3:40000:0': 37.75,
    'option-3:30000:42': 60.15,
    'option-3:350000:57': 1643.23,
    'option-4:30000:17': 35.83,
    'option-4:90000:0': 78.73,
    'option-1:70000:49': 158.9,
    'option-2:40000:48': 168.67,
    'option-4:500000:9': 375.29,
    'option-4:450000:14': 339.37,
    'option-4:40000:3': 43.01,
    'option-2:85000:60': 767.91,
    'option-1:50000:50': 116.68,
    'option-2:40000:7': 34.87,
    'option-3:300000:5': 190.55,
    'option-1:350000:37': 331.11,
    'option-3:20000:47': 57.43,
    'option-3:15000:31': 27.07,
    'option-2:200000:15': 115.13,
    'option-4:5000:54': 45.81,
    'option-2:15000:51': 100.85,
    'option-1:35000:15': 27.79,
    'option-1:35000:58': 161.69,
    'option-4:95000:55': 625.35,
    'option-3:45000:11': 40.63,
    'option-4:85000:11': 75.13,
    'option-2:95000:36': 158.06,
    'option-3:40000:50': 104.04,
    'option-3:5000:60': 37.75,
    'option-3:85000:53': 299.19,
    'option-4:40000:13': 43.01,
    'option-1:45000:41': 74.54,
    'option-1:10000:17': 19.14,
    'option-1:25000:50': 63.98,
    'option-2:70000:21': 49.81,
    'option-3:30000:47': 80.83,
    'option-1:300000:55': 966.98,
    'option-4:450000:30': 427.33,
    'option-3:30000:6': 31.96,
    'option-3:80000:39': 102.69,
    'option-3:40000:6': 37.75,
    'option-3:85000:36': 108.04,
    'option-4:10000:9': 22.33,
    'option-1:5000:45': 22.09,
    'option-3:35000:21': 34.85,
    'option-1:70000:41': 108.85,
    'option-4:200000:49': 859.56,
    'option-3:350000:18': 219.56,
    'option-4:400000:3': 303.18,
    'option-1:95000:42': 142.85,
    'option-3:65000:5': 52.19,
    'option-1:85000:55': 279.83,
    'option-4:200000:29': 196.57,
    'option-4:200000:27': 196.57,
    'option-1:20000:41': 40.3,
    'option-4:40000:18': 43.01,
    'option-3:350000:16': 219.56,
    'option-2:300000:60': 2719.24,
    'option-4:40000:0': 43.01,
    'option-2:75000:25': 52.32,
    'option-2:45000:1': 37.38,
    'option-4:150000:49': 646.43,
    'option-3:150000:42': 251.06,
    'option-3:20000:13': 26.19,
    'option-1:150000:30': 84.91,
    'option-2:70000:44': 184.83,
    'option-3:100000:56': 472.35,
    'option-2:95000:24': 62.38,
    'option-1:60000:40': 67.8,
    'option-3:15000:12': 23.62,
    'option-2:450000:10': 243.1,
    'option-2:15000:38': 36.35,
    'option-1:500000:3': 198.81,
    'option-4:50000:7': 50.18,
    'option-1:5000:52': 30.38,
    'option-3:100000:1': 72.32,
    'option-3:100000:3': 72.32,
    'option-2:400000:24': 217.84,
    'option-4:5000:8': 19.16,
    'option-3:300000:17': 190.55,
    'option-2:55000:9': 42.39,
    'option-4:65000:18': 60.94,
    'option-4:60000:59': 555.79,
    'option-1:70000:38': 76.79,
    'option-3:70000:29': 62.22,
    'option-3:200000:27': 150.97,
    'option-3:250000:3': 158.81,
    'option-4:20000:18': 28.65,
    'option-4:350000:47': 1502.17,
    'option-3:60000:21': 49.3,
    'option-2:10000:48': 52.09,
    'option-1:95000:50': 211.19,
    'option-2:25000:55': 160.37,
    'option-4:20000:55': 133.23,
    'option-4:200000:4': 157.69,
    'option-2:100000:13': 64.89,
    'option-4:350000:1': 267.99,
    'option-4:60000:17': 57.35,
    'option-1:60000:14': 36.76,
    'option-1:40000:5': 29.6,
    'option-4:400000:53': 2636.61,
    'option-3:250000:51': 861.57,
    'option-4:60000:16': 57.35,
    'option-1:85000:47': 190.55,
    'option-2:45000:7': 37.38,
    'option-2:40000:60': 359.74,
    'option-1:45000:32': 42.36,
    'option-1:200000:27': 108.3,
    'option-3:70000:39': 91.53,
    'option-3:15000:26': 25.05,
    'option-4:90000:55': 593.8,
    'option-3:40000:52': 144.99,
    'option-1:35000:0': 27.79,
    'option-1:80000:15': 44.02,
    'option-3:90000:33': 89.01,
    'option-1:500000:23': 198.81,
    'option-4:70000:9': 64.52,
    'option-1:65000:29': 45.15,
    'option-4:150000:55': 988.55,
    'option-4:50000:33': 73.79,
    'option-4:250000:52': 1642.76,
    'option-2:95000:22': 62.38,
    'option-3:85000:58': 403.31,
    'option-3:10000:20': 21.08,
    'option-3:5000:27': 19.01,
    'option-3:95000:28': 79.17,
    'option-2:20000:58': 178.32,
    'option-4:95000:10': 82.32,
    'option-3:80000:0': 60.74,
    'option-4:45000:48': 198.4,
    'option-4:250000:7': 193.24,
    'option-4:10000:16': 22.33,
    'option-4:55000:13': 53.77,
    'option-3:200000:60': 939.96,
    'option-3:55000:29': 51.97,
    'option-4:65000:52': 429.21,
    'option-3:200000:2': 130.15,
    'option-4:60000:14': 57.35,
    'option-4:400000:11': 303.18,
    'option-4:10000:58': 100.14,
    'option-3:95000:38': 119.2,
    'option-4:500000:47': 2140.7,
    'option-4:300000:20': 231.99,
    'option-1:65000:56': 294.16,
    'option-3:350000:8': 219.56,
    'option-1:400000:7': 162.51,
    'option-1:85000:12': 45.83,
    'option-3:300000:12': 190.55,
    'option-2:400000:55': 2542.17,
    'option-2:30000:25': 29.85,
    'option-3:500000:8': 306.01,
    'option-4:60000:0': 57.35,
    'option-2:90000:56': 813.29,
    'option-3:75000:12': 57.84,
    'option-2:20000:21': 24.83,
    'option-1:75000:42': 115.71,
    'option-1:300000:19': 126.42,
    'option-1:10000:47': 34.77,
    'option-4:300000:59': 2795.63,
    'option-3:450000:27': 324.16,
    'option-2:25000:26': 31.79,
    'option-2:45000:50': 188.67,
    'option-4:300000:13': 231.99,
    'option-4:75000:7': 67.96,
    'option-4:85000:38': 160.95,
    'option-4:90000:36': 169.34,
    'option-4:500000:8': 375.29,
    'option-1:30000:42': 54.02,
    'option-1:350000:3': 144.61,
    'option-4:70000:50': 305.17,
    'option-2:35000:1': 32.36,
    'option-2:75000:16': 52.32,
    'option-1:50000:53': 168.22,
    'option-1:10000:59': 56.28,
    'option-3:60000:57': 286.53,
    'option-1:250000:57': 1108.9,
    'option-3:55000:7': 46.41,
    'option-3:150000:47': 360.94,
    'option-3:90000:21': 66.53,
    'option-3:200000:36': 236.51,
    'option-4:90000:21': 78.73,
    'option-1:15000:36': 27.9,
    'option-2:65000:47': 269.55,
    'option-3:55000:23': 46.41,
    'option-2:400000:17': 217.84,
    'option-4:25000:29': 36.76,
    'option-1:350000:38': 331.11,
    'option-4:70000:44': 199.75,
    'option-1:350000:17': 144.61,
    'option-4:20000:4': 28.65,
    'option-3:90000:54': 315.56,
    'option-2:80000:25': 54.84,
    'option-3:450000:31': 391.97,
    'option-3:65000:1': 52.19,
    'option-3:5000:9': 18.54,
    'option-1:300000:37': 285.96,
    'option-4:70000:33': 98.03,
    'option-2:70000:2': 49.81,
    'option-4:15000:55': 104.02,
    'option-4:55000:31': 79.85,
    'option-1:85000:56': 382.56,
    'option-2:85000:45': 222.23,
    'option-3:35000:4': 34.85,
    'option-3:35000:20': 34.85,
    'option-1:400000:28': 204.16,
    'option-4:60000:53': 396.28,
    'option-2:40000:8': 34.87,
    'option-4:55000:32': 79.85,
    'option-3:95000:39': 119.2,
    'option-3:150000:4': 101.2,
    'option-3:450000:36': 517.66,
    'option-2:40000:32': 53.49,
    'option-4:45000:3': 46.6,
    'option-1:85000:14': 45.83,
    'option-3:350000:34': 308.87,
    'option-2:500000:20': 268.23,
    'option-2:250000:5': 140.03,
    'option-3:80000:6': 60.74,
    'option-1:400000:19': 162.51,
    'option-2:55000:54': 350.81,
    'option-2:80000:60': 722.53,
    'option-4:20000:38': 47.06,
    'option-3:80000:58': 379.96,
    'option-3:50000:3': 43.52,
    'option-3:350000:12': 219.56,
    'option-4:300000:8': 231.99,
    'option-2:40000:43': 109.92,
    'option-3:350000:27': 256.17,
    'option-3:45000:34': 51.62,
    'option-3:500000:39': 573.54,
    'option-2:35000:40': 66.1,
    'option-2:150000:18': 90.0,
    'option-2:20000:50': 87.9,
    'option-4:450000:46': 1926.72,
    'option-4:350000:50': 1502.17,
    'option-4:200000:59': 1861.57,
    'option-1:200000:56': 890.4,
    'option-4:200000:36': 362.39,
    'option-4:95000:41': 267.01,
    'option-3:60000:53': 213.63,
    'option-4:100000:7': 85.91,
    'option-4:85000:57': 789.16,
    'option-4:150000:2': 121.72,
    'option-1:450000:12': 180.68,
    'option-2:400000:15': 217.84,
    'option-3:85000:18': 63.63,
    'option-1:200000:37': 193.58,
    'option-4:450000:48': 1926.72,
    'option-3:10000:27': 22.02,
    'option-3:55000:47': 139.15,
    'option-4:200000:56': 1861.57,
    'option-3:150000:59': 706.7,
    'option-1:10000:26': 20.07,
    'option-3:100000:44': 171.2,
    'option-4:85000:50': 369.19,
    'option-4:400000:55': 2636.61,
    'option-1:90000:57': 403.69,
    'option-3:80000:1': 60.74,
    'option-2:80000:52': 509.5,
    'option-4:95000:40': 178.09,
    'option-3:75000:44': 131.73,
    'option-3:90000:0': 66.53,
    'option-3:75000:41': 131.73,
    'option-3:500000:1': 306.01,
    'option-4:65000:34': 91.97,
    'option-2:35000:16': 32.36,
    'option-4:80000:10': 71.54,
    'option-4:35000:2': 39.42,
    'option-4:10000:15': 22.33,
    'option-3:5000:7': 18.54,
    'option-3:35000:26': 38.3,
    'option-1:450000:28': 227.67,
    'option-1:70000:40': 76.79,
    'option-3:20000:28': 28.06,
    'option-2:400000:2': 217.84,
    'option-2:65000:3': 47.31,
    'option-1:95000:33': 73.18,
    'option-3:300000:59': 1408.94,
    'option-3:5000:11': 18.54,
    'option-3:350000:25': 219.56,
    'option-4:300000:18': 231.99,
    'option-2:90000:4': 59.87,
    'option-3:15000:22': 23.62,
    'option-2:10000:49': 52.09,
    'option-2:400000:45': 1010.21,
    'option-1:300000:40': 285.96,
    'option-2:100000:11': 64.89,
    'option-2:55000:35': 68.44,
    'option-4:85000:25': 75.13,
    'option-4:250000:20': 193.24,
    'option-2:40000:49': 168.67,
    'option-1:55000:52': 184.18,
    'option-1:45000:48': 106.12,
    'option-3:150000:5': 101.2,
    'option-1:45000:0': 31.41,
    'option-2:95000:53': 603.37,
    'option-2:95000:48': 389.94,
    'option-4:500000:55': 3292.08,
    'option-2:250000:34': 262.24,
    'option-2:200000:9': 115.13,
    'option-4:300000:60': 2795.63,
    'option-4:55000:23': 53.77,
    'option-1:75000:14': 42.21,
    'option-1:80000:23': 44.02,
    'option-1:450000:29': 227.67,
    'option-4:35000:11': 39.42,
    'option-4:25000:60': 229.54,
    'option-3:150000:21': 101.2,
    'option-2:20000:6': 24.83,
    'option-1:15000:13': 20.75,
    'option-1:40000:2': 29.6,
    'option-3:25000:39': 41.35,
    'option-4:450000:51': 2961.88,
    'option-1:60000:3': 36.76,
    'option-2:60000:30': 56.24,
    'option-4:80000:60': 742.5,
    'option-2:350000:17': 193.15,
    'option-4:5000:41': 28.16,
    'option-4:35000:52': 231.88,
    'option-4:300000:45': 825.63,
    'option-3:300000:57': 1408.94,
    'option-4:40000:25': 43.01,
    'option-4:80000:26': 86.8,
    'option-4:95000:53': 625.35,
    'option-3:90000:38': 113.61,
    'option-1:5000:3': 17.54,
    'option-2:85000:22': 57.35,
    'option-1:100000:1': 51.27,
    'option-3:150000:22': 101.2,
    'option-3:80000:56': 379.96,
    'option-4:450000:50': 1926.72,
    'option-1:65000:36': 72.3,
    'option-4:200000:41': 552.22,
    'option-4:35000:6': 39.42,
    'option-2:300000:29': 226.37,
    'option-4:80000:58': 742.5,
    'option-3:350000:44': 571.96,
    'option-3:10000:14': 21.08,
    'option-3:95000:22': 69.42,
    'option-1:30000:54': 104.56,
    'option-3:40000:41': 75.99,
    'option-1:60000:30': 42.91,
    'option-1:200000:40': 193.58,
    'option-3:50000:42': 91.93,
    'option-1:10000:18': 19.14,
    'option-1:70000:29': 47.48,
    'option-3:450000:28': 324.16,
    'option-4:5000:24': 19.16,
    'option-4:40000:47': 177.27,
    'option-3:450000:19': 277.08,
    'option-4:85000:52': 560.89,
    'option-4:90000:39': 169.34,
    'option-2:85000:11': 57.35,
    'option-1:15000:15': 20.75,
    'option-1:75000:27': 49.82,
    'option-1:450000:9': 180.68,
    'option-4:200000:46': 859.56,
    'option-2:30000:24': 29.85,
    'option-3:5000:30': 19.01,
    'option-2:45000:15': 37.38,
    'option-1:20000:40': 31.89,
    'option-3:25000:33': 34.95,
    'option-1:75000:6': 42.21,
    'option-4:30000:48': 134.53,
    'option-3:45000:3': 40.63,
    'option-1:20000:29': 24.22,
    'option-2:65000:19': 47.31,
    'option-4:150000:0': 121.72,
    'option-3:85000:3': 63.63,
    'option-2:45000:24': 37.38,
    'option-3:15000:54': 61.73,
    'option-2:70000:45': 184.83,
    'option-3:85000:56': 403.31,
    'option-3:80000:11': 60.74,
    'option-2:80000:29': 70.06,
    'option-1:400000:21': 162.51,
    'option-4:60000:24': 57.35,
    'option-2:300000:40': 475.65,
    'option-1:150000:8': 69.4,
    'option-3:95000:32': 93.2,
    'option-4:50000:19': 50.18,
    'option-4:15000:27': 28.13,
    'option-2:40000:28': 42.27,
    'option-1:55000:10': 34.95,
    'option-3:85000:40': 108.04,
    'option-4:45000:11': 46.6,
    'option-4:30000:39': 64.6,
    'option-3:15000:46': 47.15,
    'option-1:20000:12': 22.35,
    'option-3:150000:38': 180.37,
    'option-3:80000:28': 68.9,
    'option-4:5000:9': 19.16,
    'option-4:40000:15': 43.01,
    'option-2:400000:39': 629.74,
    'option-2:200000:8': 115.13,
    'option-4:85000:28': 91.36,
    'option-2:400000:32': 414.97,
    'option-1:75000:11': 42.21,
    'option-3:400000:46': 947.38,
    'option-4:60000:36': 117.13,
    'option-4:50000:54': 330.38,
    'option-3:75000:2': 57.84,
    'option-1:75000:39': 81.29,
    'option-2:75000:42': 197.3,
    'option-4:250000:17': 193.24,
    'option-1:100000:59': 447.9,
    'option-2:250000:47': 1016.7,
    'option-3:150000:52': 521.48,
    'option-3:200000:59': 939.96,
    'option-4:20000:10': 28.65,
    'option-3:45000:28': 45.14,
    'option-4:300000:48': 1288.12,
    'option-4:65000:13': 60.94,
    'option-3:80000:50': 197.55,
    'option-4:5000:10': 19.16,
    'option-4:60000:30': 68.71,
    'option-4:5000:34': 21.38,
    'option-1:250000:30': 131.5,
    'option-2:10000:12': 20.37,
    'option-4:250000:30': 241.84,
    'option-2:80000:44': 209.76,
    'option-4:15000:30': 28.13,
    'option-2:500000:0': 268.23,
    'option-2:500000:56': 4532.46,
    'option-1:95000:16': 49.46,
    'option-2:45000:14': 37.38,
    'option-4:30000:43': 91.29,
    'option-1:200000:59': 890.4,
    'option-4:300000:40': 539.95,
    'option-4:5000:30': 20.06,
    'option-2:25000:7': 27.34,
    'option-2:5000:32': 20.4,
    'option-2:10000:0': 20.37,
    'option-2:450000:1': 243.1,
    'option-3:350000:17': 219.56,
    'option-3:30000:57': 146.51,
    'option-4:450000:5': 339.37,
    'option-4:60000:5': 57.35,
    'option-3:300000:0': 190.55,
    'option-4:45000:37': 90.81,
    'option-4:25000:13': 32.23,
    'option-4:20000:35': 37.47,
    'option-1:150000:20': 69.4,
    'option-2:100000:4': 64.89,
    'option-3:30000:24': 31.96,
    'option-3:95000:2': 69.42,
    'option-3:95000:50': 232.07,
    'option-4:25000:56': 229.54,
    'option-2:300000:6': 167.94,
    'option-1:300000:34': 202.67,
    'option-3:65000:25': 52.19,
    'option-1:70000:60': 316.26,
    'option-1:5000:12': 17.54,
    'option-4:20000:15': 28.65,
    'option-1:40000:60': 183.8,
    'option-1:450000:39': 420.59,
    'option-1:150000:52': 486.94,
    'option-2:5000:44': 27.19,
    'option-3:90000:35': 89.01,
    'option-1:30000:44': 54.02,
    'option-1:5000:10': 17.54,
    'option-2:500000:6': 268.23,
    'option-1:85000:26': 54.51,
    'option-4:350000:4': 267.99,
    'option-1:100000:26': 61.53,
    'option-2:95000:6': 62.38,
    'option-3:70000:21': 54.96,
    'option-3:200000:42': 330.56,
    'option-4:40000:51': 264.83,
    'option-2:400000:58': 3628.82,
    'option-2:85000:8': 57.35,
    'option-3:350000:23': 219.56,
    'option-3:100000:18': 72.32,
    'option-2:90000:36': 150.39,
    'option-3:300000:4': 190.55,
    'option-4:50000:51': 330.38,
    'option-1:40000:45': 67.67,
    'option-3:350000:54': 1208.18,
    'option-3:450000:45': 730.38,
    'option-2:25000:51': 160.37,
    'option-1:95000:39': 99.08,
    'option-3:400000:20': 247.96,
    'option-1:35000:18': 27.79,
    'option-3:25000:18': 29.07,
    'option-4:10000:21': 22.33,
    'option-2:35000:37': 66.1,
    'option-3:95000:43': 163.23,
    'option-3:70000:0': 54.96,
    'option-4:55000:58': 509.09,
    'option-1:40000:54': 136.18,
    'option-2:70000:60': 631.76,
    'option-4:80000:29': 86.8,
    'option-1:75000:45': 115.71,
    'option-2:150000:47': 612.82,
    'option-3:85000:19': 63.63,
    'option-4:300000:10': 231.99,
    'option-4:200000:7': 157.69,
    'option-3:35000:15': 34.85,
    'option-4:350000:17': 267.99,
    'option-1:40000:24': 29.6,
    'option-4:400000:22': 303.18,
    'option-2:75000:50': 309.96,
    'option-4:45000:32': 67.72,
    'option-3:200000:50': 477.58,
    'option-3:30000:56': 146.51,
    'option-2:20000:37': 43.19,
    'option-2:200000:59': 1810.8,
    'option-3:75000:58': 356.61,
    'option-2:60000:12': 44.9,
    'option-1:400000:35': 264.14,
    'option-4:300000:42': 825.63,
    'option-2:450000:27': 330.95,
    'option-3:200000:6': 130.15,
    'option-3:20000:35': 30.78,
    'option-4:350000:43': 961.75,
    'option-2:350000:49': 1423.1,
    'option-1:15000:42': 34.19,
    'option-3:45000:41': 83.96,
    'option-1:450000:27': 227.67,
    'option-1:60000:15': 36.76,
    'option-4:55000:29': 64.14,
    'option-3:10000:13': 21.08,
    'option-3:300000:11': 190.55,
    'option-4:40000:34': 61.66,
    'option-1:90000:30': 56.85,
    'option-2:85000:7': 57.35,
    'option-4:5000:51': 45.81,
    'option-2:5000:4': 18.18,
    'option-1:300000:20': 126.42,
    'option-2:75000:1': 52.32,
    'option-1:200000:16': 87.52,
    'option-1:65000:22': 38.58,
    'option-3:200000:41': 330.56,
    'option-1:55000:36': 63.31,
    'option-2:10000:21': 20.37,
    'option-2:30000:23': 29.85,
    'option-1:45000:26': 35.9,
    'option-2:95000:32': 108.1,
    'option-4:25000:57': 229.54,
    'option-2:30000:46': 128.21,
    'option-4:500000:53': 3292.08,
    'option-3:400000:44': 651.63,
    'option-1:300000:11': 126.42,
    'option-4:80000:21': 71.54,
    'option-2:10000:43': 38.16,
    'option-3:50000:48': 127.46,
    'option-1:450000:25': 180.68,
    'option-3:400000:42': 651.63,
    'option-1:200000:6': 87.52,
    'option-4:200000:35': 255.47,
    'option-2:150000:34': 162.77,
    'option-3:350000:51': 1208.18,
    'option-2:65000:49': 269.55,
    'option-1:70000:30': 47.48,
    'option-3:85000:48': 209.22,
    'option-4:75000:58': 695.84,
    'option-3:30000:18': 31.96,
    'option-4:500000:23': 375.29,
    'option-2:70000:43': 184.83,
    'option-3:85000:32': 84.83,
    'option-2:75000:59': 677.15,
    'option-1:95000:28': 59.19,
    'option-1:250000:13': 105.52,
    'option-4:65000:58': 602.48,
    'option-2:15000:59': 138.01,
    'option-1:95000:0': 49.46,
    'option-2:5000:30': 19.06,
    'option-2:55000:44': 147.41,
    'option-4:250000:29': 241.84,
    'option-1:90000:16': 47.65,
    'option-4:25000:49': 113.09,
    'option-1:350000:7': 144.61,
    'option-3:65000:7': 52.19,
    'option-4:70000:12': 64.52,
    'option-1:450000:48': 962.08,
    'option-4:5000:53': 45.81,
    'option-2:60000:4': 44.9,
    'option-4:400000:36': 715.69,
    'option-3:70000:28': 62.22,
    'option-3:150000:15': 101.2,
    'option-2:150000:44': 384.24,
    'option-4:90000:46': 390.53,
    'option-2:500000:57': 4532.46,
    'option-4:90000:28': 95.94,
    'option-1:500000:30': 251.07,
    'option-1:50000:51': 168.22,
    'option-1:450000:54': 1444.1,
    'option-2:95000:47': 389.94,
    'option-3:65000:11': 52.19,
    'option-3:55000:59': 263.16,
    'option-1:45000:13': 31.41,
    'option-4:80000:27': 86.8,
    'option-2:25000:18': 27.34,
    'option-1:300000:36': 285.96,
    'option-2:450000:34': 464.32,
    'option-2:70000:35': 83.39,
    'option-4:50000:32': 73.79,
    'option-4:75000:34': 104.09,
    'option-2:250000:11': 140.03,
    'option-1:300000:12': 126.42,
    'option-4:45000:54': 297.37,
    'option-1:85000:35': 66.98,
    'option-4:200000:26': 196.57,
    'option-3:75000:35': 76.66,
    'option-4:350000:31': 439.93,
    'option-3:400000:1': 247.96,
    'option-1:60000:53': 200.13,
    'option-2:60000:18': 44.9,
    'option-1:500000:12': 198.81,
    'option-2:200000:29': 153.97,
    'option-2:90000:7': 59.87,
    'option-3:200000:25': 130.15,
    'option-1:10000:52': 44.48,
    'option-3:85000:60': 403.31,
    'option-4:55000:59': 509.09,
    'option-4:5000:19': 19.16,
    'option-1:500000:8': 198.81,
    'option-2:10000:8': 20.37,
    'option-4:20000:49': 91.96,
    'option-1:95000:56': 425.75,
    'option-1:85000:3': 45.83,
    'option-2:90000:19': 59.87,
    'option-3:65000:9': 52.19,
    'option-1:40000:1': 29.6,
    'option-1:75000:19': 42.21,
    'option-1:75000:46': 169.45,
    'option-1:70000:32': 57.82,
    'option-1:15000:26': 22.14,
    'option-2:500000:35': 514.23,
    'option-4:100000:27': 105.09,
    'option-1:75000:56': 338.36,
    'option-3:55000:30': 51.97,
    'option-4:250000:49': 1073.05,
    'option-4:10000:54': 74.88,
    'option-3:65000:39': 85.95,
    'option-4:30000:4': 35.83,
    'option-1:75000:47': 169.45,
    'option-2:95000:41': 246.66,
    'option-1:75000:48': 169.45,
    'option-3:450000:43': 730.38,
    'option-3:300000:2': 190.55,
    'option-1:95000:2': 49.46,
    'option-4:70000:32': 98.03,
    'option-3:250000:50': 593.1,
    'option-1:450000:58': 1995.74,
    'option-4:75000:38': 143.42,
    'option-2:10000:41': 38.16,
    'option-1:75000:2': 42.21,
    'option-2:10000:16': 20.37,
    'option-2:95000:59': 856.77,
    'option-3:35000:34': 43.26,
    'option-2:70000:40': 119.97,
    'option-1:10000:15': 19.14,
    'option-4:85000:44': 240.45,
    'option-3:80000:20': 60.74,
    'option-1:20000:46': 53.43,
    'option-4:500000:41': 1367.67,
    'option-3:85000:30': 72.33,
    'option-3:200000:10': 130.15,
    'option-1:50000:54': 168.22,
    'option-2:60000:1': 44.9,
    'option-1:45000:21': 31.41,
    'option-3:200000:35': 180.94,
    'option-1:20000:0': 22.35,
    'option-3:35000:40': 52.46,
    'option-4:40000:41': 118.22,
    'option-4:15000:32': 32.09,
    'option-4:55000:21': 53.77,
    'option-4:65000:37': 125.89,
    'option-2:75000:46': 309.96,
    'option-4:25000:12': 32.23,
    'option-2:70000:50': 289.76,
    'option-3:65000:48': 162.51,
    'option-3:85000:26': 72.33,
    'option-4:70000:55': 462.14,
    'option-1:45000:16': 31.41,
    'option-1:10000:51': 44.48,
    'option-1:10000:38': 23.91,
    'option-1:30000:41': 54.02,
    'option-1:30000:36': 40.82,
    'option-3:45000:37': 85.95,
    'option-1:35000:39': 45.32,
    'option-1:250000:18': 105.52,
    'option-1:100000:3': 51.27,
    'option-1:5000:53': 30.38,
    'option-1:10000:28': 20.07,
    'option-4:60000:34': 85.91,
    'option-2:150000:27': 118.93,
    'option-1:5000:39': 19.96,
    'option-2:90000:50': 370.56,
    'option-2:45000:45': 122.44,
    'option-1:150000:1': 69.4,
    'option-3:65000:17': 52.19,
    'option-4:40000:30': 55.0,
    'option-4:80000:12': 71.54,
    'option-1:25000:58': 117.34,
    'option-2:55000:52': 350.81,
    'option-1:200000:51': 646.16,
    'option-3:40000:57': 193.28,
    'option-3:250000:27': 184.83,
    'option-3:150000:56': 706.7,
    'option-2:55000:25': 42.39,
    'option-2:50000:33': 63.46,
    'option-2:250000:43': 633.53,
    'option-3:100000:12': 72.32,
    'option-2:500000:22': 268.23,
    'option-4:55000:9': 53.77,
    'option-2:250000:2': 140.03,
    'option-2:400000:29': 295.72,
    'option-4:10000:3': 22.33,
    'option-4:350000:60': 3263.79,
    'option-4:5000:20': 19.16,
    'option-3:35000:12': 34.85,
    'option-4:55000:7': 53.77,
    'option-3:30000:54': 111.05,
    'option-2:80000:33': 93.36,
    'option-1:250000:53': 803.8,
    'option-4:25000:5': 32.23,
    'option-1:65000:39': 72.3,
    'option-1:85000:31': 66.98,
    'option-1:70000:4': 40.39,
    'option-1:85000:48': 190.55,
    'option-1:85000:18': 45.83,
    'option-3:30000:33': 39.08,
    'option-3:20000:43': 44.22,
    'option-1:350000:44': 495.51,
    'option-4:95000:6': 82.32,
    'option-1:350000:46': 752.09,
    'option-3:250000:1': 158.81,
    'option-1:25000:51': 88.55,
    'option-1:30000:23': 25.97,
    'option-4:80000:4': 71.54,
    'option-4:500000:24': 375.29,
    'option-4:25000:11': 32.23,
    'option-3:5000:50': 26.44,
    'option-1:15000:28': 22.14,
    'option-2:15000:11': 22.6,
    'option-1:60000:37': 67.8,
    'option-1:15000:56': 75.85,
    'option-1:60000:16': 36.76,
    'option-3:20000:37': 35.77,
    'option-1:95000:31': 73.18,
    'option-3:500000:50': 1179.92,
    'option-2:50000:1': 39.88,
    'option-1:500000:29': 251.07,
    'option-3:50000:10': 43.52,
    'option-2:25000:9': 27.34,
    'option-2:80000:57': 722.53,
    'option-3:95000:8': 69.42,
    'option-1:450000:24': 180.68,
    'option-2:85000:47': 350.36,
    'option-1:15000:6': 20.75,
    'option-2:40000:3': 34.87,
    'option-3:300000:8': 190.55,
    'option-3:450000:24': 277.08,
    'option-3:60000:28': 55.39,
    'option-2:50000:0': 39.88,
    'option-1:95000:58': 425.75,
    'option-1:55000:7': 34.95,
    'option-4:40000:60': 369.44,
    'option-3:300000:20': 190.55,
    'option-1:250000:44': 354.92,
    'option-1:500000:24': 198.81,
    'option-1:60000:41': 95.13,
    'option-4:100000:39': 186.87,
    'option-3:300000:49': 713.32,
    'option-4:85000:49': 369.19,
    'option-4:15000:51': 104.02,
    'option-1:55000:40': 63.31,
    'option-1:75000:12': 42.21,
    'option-4:45000:43': 131.87,
    'option-4:450000:13': 339.37,
    'option-3:85000:45': 147.65,
    'option-3:400000:58': 1876.99,
    'option-2:100000:12': 64.89,
    'option-1:150000:43': 218.55,
    'option-1:450000:51': 1444.1,
    'option-2:70000:1': 49.81,
    'option-4:40000:19': 43.01,
    'option-1:40000:9': 29.6,
    'option-1:70000:1': 40.39,
    'option-3:45000:60': 216.4,
    'option-4:25000:24': 32.23,
    'option-1:50000:36': 58.82,
    'option-4:15000:18': 25.51,
    'option-1:85000:40': 90.07,
    'option-4:95000:27': 100.52,
    'option-2:200000:13': 115.13,
    'option-2:500000:8': 268.23,
    'option-1:65000:6': 38.58,
    'option-4:45000:7': 46.6,
    'option-1:50000:40': 58.82,
    'option-2:15000:46': 69.98,
    'option-2:500000:1': 268.23,
    'option-2:10000:26': 22.14,
    'option-1:95000:52': 311.0,
    'option-4:250000:58': 2322.83,
    'option-3:100000:26': 82.59,
    'option-2:75000:8': 52.32,
    'option-2:15000:55': 100.85,
    'option-4:85000:40': 160.95,
    'option-4:70000:26': 77.85,
    'option-3:100000:20': 72.32,
    'option-4:25000:2': 32.23,
    'option-4:350000:12': 267.99,
    'option-4:350000:58': 3263.79,
    'option-4:200000:16': 157.69,
    'option-4:20000:60': 182.88,
    'option-3:75000:5': 57.84,
    'option-1:30000:34': 33.07,
    'option-4:15000:13': 25.51,
    'option-3:45000:53': 162.25,
    'option-1:100000:55': 326.98,
    'option-1:45000:6': 31.41,
    'option-3:50000:2': 43.52,
    'option-1:95000:34': 73.18,
    'option-4:25000:38': 55.82,
    'option-1:65000:30': 45.15,
    'option-4:250000:27': 241.84,
    'option-3:70000:2': 54.96,
    'option-3:90000:6': 66.53,
    'option-3:50000:22': 43.52,
    'option-1:350000:41': 495.51,
    'option-2:60000:20': 44.9,
    'option-1:100000:2': 51.27,
    'option-1:350000:32': 233.75,
    'option-3:30000:39': 46.93,
    'option-4:400000:41': 1097.57,
    'option-1:500000:31': 326.33,
    'option-2:20000:17': 24.83,
    'option-3:25000:59': 123.04,
    'option-4:250000:47': 1073.05,
    'option-4:100000:59': 927.12,
    'option-4:30000:24': 35.83,
    'option-4:5000:13': 19.16,
    'option-4:100000:53': 658.21,
    'option-2:45000:0': 37.38,
    'option-2:450000:14': 243.1,
    'option-1:80000:46': 180.0,
    'option-2:150000:53': 953.46,
    'option-4:35000:35': 55.59,
    'option-1:15000:31': 24.13,
    'option-1:80000:47': 180.0,
    'option-2:15000:24': 22.6,
    'option-1:75000:26': 49.82,
    'option-1:90000:3': 47.65,
    'option-3:300000:35': 266.96,
    'option-1:85000:20': 45.83,
    'option-4:80000:49': 347.85,
    'option-1:350000:34': 233.75,
    'option-2:400000:7': 217.84,
    'option-1:55000:29': 40.57,
    'option-1:35000:6': 27.79,
    'option-3:300000:18': 190.55,
    'option-4:95000:36': 178.09,
    'option-3:100000:45': 171.2,
    'option-2:300000:38': 475.65,
    'option-1:500000:47': 1067.77,
    'option-3:500000:55': 1720.14,
    'option-2:95000:40': 158.06,
    'option-2:35000:10': 32.36,
    'option-3:200000:12': 130.15,
    'option-4:5000:23': 19.16,
    'option-4:80000:51': 527.97,
    'option-1:5000:17': 17.54,
    'option-1:40000:27': 33.56,
    'option-2:60000:50': 249.34,
    'option-4:50000:48': 219.77,
    'option-1:25000:41': 47.15,
    'option-1:50000:7': 33.22,
    'option-3:150000:8': 101.2,
    'option-2:70000:49': 289.76,
    'option-4:400000:7': 303.18,
    'option-2:95000:9': 62.38,
    'option-2:300000:17': 167.94,
    'option-2:95000:4': 62.38,
    'option-3:10000:7': 21.08,
    'option-4:400000:35': 500.58,
    'option-3:85000:55': 299.19,
    'option-4:100000:38': 186.87,
    'option-3:15000:5': 23.62,
    'option-1:90000:28': 56.85,
    'option-3:55000:31': 59.97,
    'option-3:80000:36': 102.69,
    'option-3:65000:47': 162.51,
    'option-1:80000:52': 263.9,
    'option-3:90000:32': 89.01,
    'option-1:75000:44': 115.71,
    'option-1:400000:10': 162.51,
    'option-2:20000:29': 28.31,
    'option-3:200000:23': 130.15,
    'option-2:85000:17': 57.35,
    'option-2:45000:6': 37.38,
    'option-1:70000:14': 40.39,
    'option-2:85000:31': 98.13,
    'option-2:90000:12': 59.87,
    'option-3:30000:2': 31.96,
    'option-3:15000:16': 23.62,
    'option-1:450000:4': 180.68,
    'option-3:35000:53': 128.19,
    'option-3:30000:55': 111.05,
    'option-2:50000:21': 39.88,
    'option-4:250000:36': 449.08,
    'option-4:10000:46': 54.13,
    'option-2:60000:34': 73.43,
    'option-4:250000:56': 2322.83,
    'option-2:45000:10': 37.38,
    'option-4:80000:47': 347.85,
    'option-4:15000:7': 25.51,
    'option-3:15000:48': 47.15,
    'option-3:20000:44': 44.22,
    'option-3:40000:32': 47.44,
    'option-3:450000:42': 730.38,
    'option-3:70000:1': 54.96,
    'option-2:80000:26': 70.06,
    'option-4:85000:16': 75.13,
    'option-4:100000:17': 85.91,
    'option-2:350000:60': 3174.52,
    'option-4:90000:5': 78.73,
    'option-3:350000:14': 219.56,
    'option-3:65000:35': 68.32,
    'option-3:250000:15': 158.81,
    'option-3:75000:48': 185.87,
    'option-4:75000:59': 695.84,
    'option-1:90000:1': 47.65,
    'option-1:90000:34': 70.08,
    'option-3:40000:25': 37.75,
    'option-4:200000:1': 157.69,
    'option-4:500000:43': 1367.67,
    'option-3:60000:31': 64.14,
    'option-4:250000:9': 193.24,
    'option-1:65000:32': 54.73,
    'option-2:40000:27': 42.27,
    'option-3:75000:24': 57.84,
    'option-2:300000:20': 167.94,
    'option-2:90000:35': 103.11,
    'option-2:150000:9': 90.0,
    'option-2:40000:44': 109.92,
    'option-3:400000:2': 247.96,
    'option-4:55000:56': 509.09,
    'option-3:85000:44': 147.65,
    'option-2:95000:49': 389.94,
    'option-2:80000:30': 70.06,
    'option-4:450000:53': 2961.88,
    'option-4:95000:0': 82.32,
    'option-4:50000:17': 50.18,
    'option-2:55000:1': 42.39,
    'option-4:200000:53': 1317.35,
    'option-4:100000:6': 85.91,
    'option-1:10000:32': 21.43,
    'option-3:65000:26': 58.8,
    'option-2:60000:43': 159.88,
    'option-4:100000:19': 85.91,
    'option-2:450000:25': 243.1,
    'option-4:200000:31': 255.47,
    'option-3:70000:48': 174.19,
    'option-1:70000:55': 232.02,
    'option-4:40000:49': 177.27,
    'option-3:60000:10': 49.3,
    'option-3:20000:49': 57.43,
    'option-3:150000:11': 101.2,
    'option-1:85000:49': 190.55,
    'option-2:60000:26': 56.24,
    'option-3:20000:29': 28.06,
    'option-1:90000:29': 56.85,
    'option-4:70000:34': 98.03,
    'option-4:20000:20': 28.65,
    'option-2:35000:51': 224.09,
    'option-1:250000:0': 105.52,
    'option-1:25000:5': 24.16,
    'option-1:95000:49': 211.19,
    'option-3:85000:1': 63.63,
    'option-1:85000:9': 45.83,
    'option-4:45000:5': 46.6,
    'option-1:5000:44': 22.09,
    'option-1:10000:21': 19.14,
    'option-1:95000:3': 49.46,
    'option-2:200000:2': 115.13,
    'option-3:80000:25': 60.74,
    'option-2:55000:27': 52.75,
    'option-4:65000:40': 125.89,
    'option-3:450000:47': 1062.91,
    'option-1:20000:23': 22.35,
    'option-1:30000:9': 25.97,
    'option-2:150000:30': 118.93,
    'option-1:100000:4': 51.27,
    'option-4:250000:24': 193.24,
    'option-3:100000:2': 72.32,
    'option-4:45000:38': 90.81,
    'option-1:70000:26': 47.48,
    'option-2:350000:5': 193.15,
    'option-4:150000:11': 121.72,
    'option-2:5000:28': 19.06,
    'option-3:60000:26': 55.39,
    'option-4:80000:17': 71.54,
    'option-2:250000:49': 1016.7,
    'option-1:250000:5': 105.52,
    'option-2:25000:58': 223.69,
    'option-1:65000:33': 54.73,
    'option-2:5000:49': 34.21,
    'option-3:150000:54': 521.48,
    'option-1:250000:34': 168.89,
    'option-2:25000:45': 72.65,
    'option-2:250000:15': 140.03,
    'option-3:40000:31': 47.44,
    'option-2:500000:45': 1258.47,
    'option-3:35000:3': 34.85,
    'option-1:80000:54': 263.9,
    'option-1:5000:34': 18.73,
    'option-1:65000:16': 38.58,
    'option-3:75000:0': 57.84,
    'option-4:25000:58': 229.54,
    'option-3:15000:35': 27.07,
    'option-1:250000:32': 168.89,
    'option-3:80000:42': 139.69,
    'option-3:45000:18': 40.63,
    'option-1:65000:23': 38.58,
    'option-4:400000:28': 382.08,
    'option-2:70000:13': 49.81,
    'option-3:30000:46': 80.83,
    'option-1:85000:34': 66.98,
    'option-2:450000:23': 243.1,
    'option-4:35000:57': 322.73,
    'option-2:300000:24': 167.94,
    'option-2:35000:49': 148.45,
    'option-1:85000:7': 45.83,
    'option-2:40000:1': 34.87,
    'option-4:40000:23': 43.01,
    'option-2:25000:48': 107.91,
    'option-3:500000:59': 2341.99,
    'option-4:85000:53': 560.89,
    'option-3:70000:59': 333.25,
    'option-4:60000:37': 117.13,
    'option-3:55000:3': 46.41,
    'option-2:50000:25': 39.88,
    'option-2:350000:10': 193.15,
    'option-1:400000:42': 564.17,
    'option-1:5000:27': 18.03,
    'option-4:15000:40': 39.25,
    'option-1:70000:12': 40.39,
    'option-4:55000:39': 108.36,
    'option-2:30000:11': 29.85,
    'option-2:5000:48': 34.21,
    'option-1:95000:37': 99.08,
    'option-1:450000:44': 632.04,
    'option-4:60000:15': 57.35,
    'option-4:300000:32': 379.08,
    'option-4:150000:18': 121.72,
    'option-1:450000:32': 295.33,
    'option-3:60000:1': 49.3,
    'option-3:35000:5': 34.85,
    'option-3:80000:4': 60.74,
    'option-3:50000:54': 179.39,
    'option-1:100000:40': 103.59,
    'option-3:300000:45': 492.06,
    'option-2:350000:41': 885.39,
    'option-3:80000:47': 197.55,
    'option-2:5000:55': 44.79,
    'option-3:55000:32': 59.97,
    'option-4:150000:58': 1395.44,
    'option-4:35000:10': 39.42,
    'option-4:20000:22': 28.65,
    'option-4:95000:52': 625.35,
    'option-3:45000:14': 40.63,
    'option-4:10000:42': 40.12,
    'option-1:100000:49': 221.76,
    'option-2:100000:30': 84.05,
    'option-3:30000:41': 60.15,
    'option-1:250000:45': 354.92,
    'option-3:85000:54': 299.19,
    'option-2:55000:30': 52.75,
    'option-1:20000:28': 24.22,
    'option-2:15000:8': 22.6,
    'option-4:40000:1': 43.01,
    'option-2:65000:0': 47.31,
    'option-2:40000:18': 34.87,
    'option-4:50000:31': 73.79,
    'option-2:80000:12': 54.84,
    'option-4:30000:44': 91.29,
    'option-3:500000:19': 306.01,
    'option-3:55000:12': 46.41,
    'option-2:70000:41': 184.83,
    'option-1:30000:12': 25.97,
    'option-4:90000:10': 78.73,
    'option-4:45000:24': 46.6,
    'option-3:65000:33': 68.32,
    'option-4:50000:55': 330.38,
    'option-2:300000:22': 167.94,
    'option-3:350000:36': 406.59,
    'option-1:65000:13': 38.58,
    'option-2:40000:55': 255.85,
    'option-4:95000:30': 100.52,
    'option-4:5000:16': 19.16,
    'option-4:500000:33': 621.23,
    'option-3:500000:56': 2341.99,
    'option-3:10000:43': 30.14,
    'option-2:65000:40': 112.28,
    'option-1:450000:1': 180.68,
    'option-2:200000:24': 115.13,
    'option-2:20000:1': 24.83,
    'option-4:25000:8': 32.23,
    'option-2:15000:23': 22.6,
    'option-2:20000:47': 87.9,
    'option-3:400000:12': 247.96,
    'option-3:50000:38': 91.53,
    'option-3:300000:37': 350.56,
    'option-3:350000:42': 571.96,
    'option-4:150000:43': 416.72,
    'option-2:500000:34': 514.23,
    'option-2:40000:56': 359.74,
    'option-3:15000:24': 23.62,
    'option-1:25000:19': 24.16,
    'option-3:45000:7': 40.63,
    'option-4:250000:5': 193.24,
    'option-4:20000:6': 28.65,
    'option-4:60000:12': 57.35,
    'option-3:90000:56': 425.64,
    'option-4:55000:2': 53.77,
    'option-2:50000:52': 319.04,
    'option-2:400000:31': 414.97,
    'option-4:30000:15': 35.83,
    'option-2:80000:5': 54.84,
    'option-3:70000:5': 54.96,
    'option-1:200000:18': 87.52,
    'option-3:60000:5': 49.3,
    'option-4:200000:43': 552.22,
    'option-4:60000:29': 68.71,
    'option-1:150000:21': 69.4,
    'option-1:90000:54': 295.09,
    'option-2:95000:16': 62.38,
    'option-3:450000:6': 277.08,
    'option-4:75000:24': 67.96,
    'option-3:50000:57': 239.79,
    'option-2:65000:15': 47.31,
    'option-3:350000:15': 219.56,
    'option-4:40000:40': 82.04,
    'option-3:5000:31': 19.69,
    'option-4:100000:14': 85.91,
    'option-1:80000:45': 122.56,
    'option-1:25000:18': 24.16,
    'option-2:70000:15': 49.81,
    'option-3:450000:57': 2107.81,
    'option-3:90000:1': 66.53,
    'option-2:40000:36': 73.84,
    'option-4:350000:25': 267.99,
    'option-2:65000:9': 47.31,
    'option-4:300000:41': 825.63,
    'option-4:500000:52': 3292.08,
    'option-3:350000:45': 571.96,
    'option-2:450000:30': 330.95,
    'option-3:10000:26': 22.02,
    'option-4:50000:11': 50.18,
    'option-4:60000:27': 68.71,
    'option-1:5000:55': 30.38,
    'option-2:85000:51': 541.23,
    'option-1:70000:57': 316.26,
    'option-2:100000:42': 259.11,
    'option-4:85000:43': 240.45,
    'option-1:200000:15': 87.52,
    'option-4:25000:54': 165.77,
    'option-4:200000:38': 362.39,
    'option-1:95000:13': 49.46,
    'option-1:300000:15': 126.42,
    'option-2:100000:6': 64.89,
    'option-1:150000:19': 69.4,
    'option-1:500000:26': 251.07,
    'option-3:450000:4': 277.08,
    'option-1:35000:60': 161.69,
    'option-2:90000:15': 59.87,
    'option-3:400000:34': 350.64,
    'option-1:45000:55': 152.25,
    'option-2:65000:17': 47.31,
    'option-1:35000:22': 27.79,
    'option-4:200000:0': 157.69,
    'option-3:75000:45': 131.73,
    'option-4:15000:31': 32.09,
    'option-4:400000:39': 715.69,
    'option-2:85000:13': 57.35,
    'option-1:65000:12': 38.58,
    'option-2:60000:45': 159.88,
    'option-1:85000:24': 45.83,
    'option-2:150000:6': 90.0,
    'option-4:5000:26': 20.06,
    'option-1:350000:43': 495.51,
    'option-2:45000:22': 37.38,
    'option-1:15000:58': 75.85,
    'option-4:70000:30': 77.85,
    'option-3:45000:26': 45.14,
    'option-2:150000:1': 90.0,
    'option-1:90000:47': 200.66,
    'option-1:55000:26': 40.57,
    'option-1:45000:24': 31.41,
    'option-4:80000:46': 347.85,
    'option-4:25000:1': 32.23,
    'option-2:150000:56': 1357.5,
    'option-3:45000:25': 40.63,
    'option-2:95000:45': 246.66,
    'option-2:55000:51': 350.81,
    'option-1:40000:32': 39.26,
    'option-4:350000:23': 267.99,
    'option-1:300000:32': 202.67,
    'option-3:65000:40': 85.95,
    'option-3:250000:7': 158.81,
    'option-2:100000:14': 64.89,
    'option-3:35000:9': 34.85,
    'option-2:75000:26': 66.57,
    'option-3:100000:21': 72.32,
    'option-1:45000:56': 205.69,
    'option-4:80000:42': 226.89,
    'option-3:75000:6': 57.84,
    'option-4:100000:3': 85.91,
    'option-3:65000:2': 52.19,
    'option-1:65000:27': 45.15,
    'option-2:400000:16': 217.84,
    'option-4:200000:47': 859.56,
    'option-4:70000:31': 98.03,
    'option-3:100000:48': 243.77,
    'option-1:75000:17': 42.21,
    'option-3:300000:30': 221.87,
    'option-3:300000:38': 350.56,
    'option-2:45000:40': 81.53,
    'option-4:60000:57': 555.79,
    'option-4:100000:41': 280.55,
    'option-2:150000:40': 242.88,
    'option-4:75000:40': 143.42,
    'option-4:50000:39': 99.59,
    'option-1:150000:48': 327.62,
    'option-1:350000:13': 144.61,
    'option-4:10000:28': 24.07,
    'option-2:10000:56': 97.85,
    'option-4:400000:38': 715.69,
    'option-2:30000:47': 128.21,
    'option-3:450000:54': 1548.61,
    'option-3:150000:9': 101.2,
    'option-2:5000:26': 19.06,
    'option-2:40000:14': 34.87,
    'option-3:400000:9': 247.96,
    'option-3:500000:2': 306.01,
    'option-3:100000:22': 72.32,
    'option-4:85000:2': 75.13,
    'option-4:400000:33': 500.58,
    'option-3:100000:41': 171.2,
    'option-3:40000:37': 80.37,
    'option-3:500000:32': 433.77,
    'option-4:250000:48': 1073.05,
    'option-3:100000:57': 472.35,
    'option-4:25000:25': 32.23,
    'option-2:400000:6': 217.84,
    'option-2:55000:19': 42.39,
    'option-2:350000:32': 365.08,
    'option-1:20000:44': 40.3,
    'option-1:450000:18': 180.68,
    'option-2:95000:51': 603.37,
    'option-1:80000:41': 122.56,
    'option-1:70000:45': 108.85,
    'option-2:55000:59': 495.55,
    'option-3:60000:14': 49.3,
    'option-1:30000:58': 139.54,
    'option-3:20000:46': 57.43,
    'option-1:50000:37': 58.82,
    'option-2:450000:47': 1825.01,
    'option-2:40000:37': 73.84,
    'option-2:250000:41': 633.53,
    'option-2:10000:13': 20.37,
    'option-3:35000:8': 34.85,
    'option-4:40000:11': 43.01,
    'option-4:90000:40': 169.34,
    'option-1:60000:20': 36.76,
    'option-2:35000:34': 48.5,
    'option-3:35000:36': 52.46,
    'option-4:250000:43': 687.95,
    'option-3:10000:33': 23.36,
    'option-1:65000:14': 38.58,
    'option-2:350000:4': 193.15,
    'option-1:65000:53': 216.07,
    'option-1:35000:54': 120.52,
    'option-4:60000:42': 172.61,
    'option-2:80000:48': 330.16,
    'option-1:250000:39': 238.09,
    'option-4:350000:54': 2307.1,
    'option-1:85000:0': 45.83,
    'option-2:65000:32': 78.41,
    'option-3:5000:3': 18.54,
    'option-4:350000:35': 439.93,
    'option-4:30000:49': 134.53,
    'option-2:35000:29': 38.78,
    'option-1:50000:2': 33.22,
    'option-1:150000:53': 486.94,
    'option-3:500000:54': 1720.14,
    'option-3:65000:14': 52.19,
    'option-4:85000:7': 75.13,
    'option-3:65000:29': 58.8,
    'option-2:80000:56': 722.53,
    'option-3:70000:54': 247.86,
    'option-2:500000:39': 782.76,
    'option-4:500000:57': 4660.16,
    'option-4:60000:11': 57.35,
    'option-1:300000:22': 126.42,
    'option-2:10000:60': 97.85,
    'option-2:40000:41': 109.92,
    'option-4:30000:20': 35.83,
    'option-2:50000:14': 39.88,
    'option-3:70000:31': 72.49,
    'option-3:100000:58': 472.35,
    'option-1:5000:22': 17.54,
    'option-3:20000:9': 26.19,
    'option-3:10000:35': 23.36,
    'option-1:350000:9': 144.61,
    'option-4:75000:21': 67.96,
    'option-2:25000:11': 27.34,
    'option-4:250000:33': 315.44,
    'option-1:45000:54': 152.25,
    'option-2:45000:59': 404.54,
    'option-4:65000:45': 186.18,
    'option-1:35000:13': 27.79,
    'option-3:5000:58': 37.75,
    'option-2:150000:22': 90.0,
    'option-1:75000:36': 81.29,
    'option-4:200000:3': 157.69,
    'option-3:25000:24': 29.07,
    'option-1:35000:50': 85.11,
    'option-1:40000:58': 183.8,
    'option-4:40000:28': 55.0,
    'option-4:65000:14': 60.94,
    'option-4:10000:2': 22.33,
    'option-2:20000:0': 24.83,
    'option-1:45000:18': 31.41,
    'option-2:60000:5': 44.9,
    'option-4:20000:52': 133.23,
    'option-1:60000:57': 272.05,
    'option-1:60000:17': 36.76,
    'option-2:85000:58': 767.91,
    'option-4:60000:45': 172.61,
    'option-3:75000:49': 185.87,
    'option-3:15000:57': 79.2,
    'option-1:75000:18': 42.21,
    'option-4:10000:57': 100.14,
    'option-2:25000:12': 27.34,
    'option-3:5000:44': 23.1,
    'option-2:85000:3': 57.35,
    'option-2:200000:47': 814.59,
    'option-2:75000:0': 52.32,
    'option-4:70000:51': 462.14,
    'option-4:45000:0': 46.6,
    'option-4:15000:8': 25.51,
    'option-3:10000:24': 21.08,
    'option-4:45000:59': 415.5,
    'option-4:85000:13': 75.13,
    'option-4:70000:42': 199.75,
    'option-1:55000:35': 48.55,
    'option-4:90000:11': 78.73,
    'option-4:400000:24': 303.18,
    'option-3:50000:56': 239.79,
    'option-1:75000:53': 247.96,
    'option-2:150000:37': 242.88,
    'option-2:500000:55': 3173.99,
    'option-2:35000:23': 32.36,
    'option-3:25000:32': 34.95,
    'option-3:400000:32': 350.64,
    'option-4:100000:18': 85.91,
    'option-2:100000:20': 64.89,
    'option-1:65000:60': 294.16,
    'option-2:5000:11': 18.18,
    'option-2:250000:13': 140.03,
    'option-4:25000:51': 165.77,
    'option-2:60000:7': 44.9,
    'option-2:450000:46': 1825.01,
    'option-2:90000:28': 77.06,
    'option-4:90000:7': 78.73,
    'option-1:20000:54': 72.59,
    'option-2:75000:38': 127.65,
    'option-1:50000:60': 227.81,
    'option-2:25000:31': 38.59,
    'option-3:30000:45': 60.15,
    'option-2:45000:25': 37.38,
    'option-4:500000:7': 375.29,
    'option-1:90000:22': 47.65,
    'option-3:400000:10': 247.96,
    'option-2:100000:7': 64.89,
    'option-3:25000:5': 29.07,
    'option-1:300000:38': 285.96,
    'option-4:10000:13': 22.33,
    'option-2:300000:48': 1220.44,
    'option-1:300000:46': 646.22,
    'option-3:30000:48': 80.83,
    'option-1:15000:33': 24.13,
    'option-3:100000:60': 472.35,
    'option-3:45000:10': 40.63,
    'option-4:90000:9': 78.73,
    'option-2:90000:0': 59.87,
    'option-3:25000:53': 93.87,
    'option-2:95000:60': 856.77,
    'option-1:500000:53': 1603.9,
    'option-1:60000:44': 95.13,
    'option-2:60000:42': 159.88,
    'option-3:200000:14': 130.15,
    'option-2:250000:26': 188.58,
    'option-3:200000:55': 692.37,
    'option-3:70000:56': 333.25,
    'option-3:450000:39': 517.66,
    'option-1:350000:15': 144.61,
    'option-3:75000:9': 57.84,
    'option-2:45000:34': 58.48,
    'option-4:250000:23': 193.24,
    'option-3:300000:39': 350.56,
    'option-4:500000:60': 4660.16,
    'option-3:85000:41': 147.65,
    'option-4:100000:42': 280.55,
    'option-1:5000:58': 36.78,
    'option-2:65000:31': 78.41,
    'option-1:5000:38': 19.96,
    'option-3:300000:29': 221.87,
    'option-4:350000:39': 627.94,
    'option-2:55000:33': 68.44,
    'option-4:80000:43': 226.89,
    'option-2:20000:27': 28.31,
    'option-2:500000:9': 268.23,
    'option-3:40000:44': 75.99,
    'option-4:300000:51': 1976.86,
    'option-1:450000:11': 180.68,
    'option-2:85000:23': 57.35,
    'option-3:5000:2': 18.54,
    'option-1:75000:0': 42.21,
    'option-2:5000:58': 57.88,
    'option-2:40000:59': 359.74,
    'option-1:40000:51': 136.18,
    'option-1:65000:45': 101.99,
    'option-4:30000:59': 275.93,
    'option-1:100000:44': 149.72,
    'option-1:65000:21': 38.58,
    'option-4:200000:30': 196.57,
    'option-1:85000:38': 90.07,
    'option-2:500000:31': 514.23,
    'option-3:10000:38': 25.84,
    'option-3:95000:21': 69.42,
    'option-4:70000:27': 77.85,
    'option-3:65000:31': 68.32,
    'option-3:150000:30': 116.71,
    'option-3:35000:47': 92.52,
    'option-2:75000:27': 66.57,
    'option-3:60000:35': 64.14,
    'option-2:20000:8': 24.83,
    'option-3:20000:22': 26.19,
    'option-4:450000:9': 339.37,
    'option-3:40000:26': 41.72,
    'option-4:300000:7': 231.99,
    'option-3:75000:43': 131.73,
    'option-1:250000:29': 131.5,
    'option-2:95000:35': 108.1,
    'option-3:450000:14': 277.08,
    'option-1:80000:22': 44.02,
    'option-2:350000:48': 1423.1,
    'option-4:30000:58': 275.93,
    'option-4:75000:19': 67.96,
    'option-2:95000:8': 62.38,
    'option-4:40000:22': 43.01,
    'option-1:400000:54': 1286.48,
    'option-1:85000:57': 382.56,
    'option-4:250000:38': 449.08,
    'option-4:200000:10': 157.69,
    'option-4:35000:19': 39.42,
    'option-2:20000:5': 24.83,
    'option-2:55000:15': 42.39,
    'option-2:350000:23': 193.15,
    'option-2:90000:14': 59.87,
    'option-1:450000:38': 420.59,
    'option-1:40000:56': 183.8,
    'option-2:250000:14': 140.03,
    'option-2:95000:10': 62.38,
    'option-1:10000:50': 34.77,
    'option-2:20000:38': 43.19,
    'option-4:85000:24': 75.13,
    'option-2:350000:37': 552.81,
    'option-1:150000:15': 69.4,
    'option-4:30000:13': 35.83,
    'option-4:80000:11': 71.54,
    'option-2:350000:58': 3174.52,
    'option-4:150000:53': 988.55,
    'option-3:85000:12': 63.63,
    'option-1:200000:20': 87.52,
    'option-1:350000:50': 752.09,
    'option-4:55000:36': 108.36,
    'option-2:100000:37': 165.76,
    'option-1:400000:29': 204.16,
    'option-3:75000:18': 57.84,
    'option-1:100000:8': 51.27,
    'option-3:25000:20': 29.07,
    'option-2:300000:10': 167.94,
    'option-4:350000:2': 267.99,
    'option-3:5000:57': 37.75,
    'option-1:30000:24': 25.97,
    'option-2:100000:15': 64.89,
    'option-3:70000:26': 62.22,
    'option-4:55000:51': 363.34,
    'option-2:350000:20': 193.15,
    'option-3:15000:44': 37.13,
    'option-2:55000:5': 42.39,
    'option-3:30000:37': 46.93,
    'option-3:15000:40': 30.81,
    'option-3:40000:23': 37.75,
    'option-4:70000:19': 64.52,
    'option-1:250000:19': 105.52,
    'option-3:100000:35': 97.38,
    'option-3:35000:48': 92.52,
    'option-3:150000:25': 101.2,
    'option-2:95000:13': 62.38,
    'option-4:100000:24': 85.91,
    'option-4:35000:53': 231.88,
    'option-1:70000:47': 158.9,
    'option-4:50000:56': 462.36,
    'option-4:95000:33': 128.07,
    'option-3:5000:18': 18.54,
    'option-2:200000:37': 319.64,
    'option-4:60000:13': 57.35,
    'option-3:10000:6': 21.08,
    'option-4:65000:30': 73.28,
    'option-2:15000:47': 69.98,
    'option-3:250000:20': 158.81,
    'option-1:30000:50': 74.55,
    'option-1:20000:34': 26.89,
    'option-3:90000:46': 220.41,
    'option-2:5000:43': 27.19,
    'option-3:85000:25': 63.63,
    'option-1:20000:53': 72.59,
    'option-3:25000:38': 41.35,
    'option-1:200000:9': 87.52,
    'option-4:450000:0': 339.37,
    'option-1:5000:2': 17.54,
    'option-1:15000:32': 24.13,
    'option-2:30000:7': 29.85,
    'option-4:85000:21': 75.13,
    'option-2:70000:22': 49.81,
    'option-4:85000:58': 789.16,
    'option-1:5000:8': 17.54,
    'option-2:350000:8': 193.15,
    'option-3:500000:30': 358.38,
    'option-4:35000:12': 39.42,
    'option-4:25000:33': 43.53,
    'option-1:400000:32': 264.14,
    'option-2:500000:19': 268.23,
    'option-2:85000:16': 57.35,
    'option-3:55000:43': 99.89,
    'option-2:30000:15': 29.85,
    'option-4:30000:16': 35.83,
    'option-3:300000:43': 492.06,
    'option-1:65000:57': 294.16,
    'option-4:35000:41': 104.88,
    'option-3:300000:6': 190.55,
    'option-1:95000:6': 49.46,
    'option-3:10000:42': 30.14,
    'option-3:250000:14': 158.81,
    'option-1:100000:35': 76.28,
    'option-4:75000:11': 67.96,
    'option-2:100000:25': 64.89,
    'option-3:500000:44': 810.09,
    'option-3:75000:16': 57.84,
    'option-3:60000:3': 49.3,
    'option-4:350000:8': 267.99,
    'option-1:350000:23': 144.61,
    'option-1:95000:35': 73.18,
    'option-4:95000:56': 880.53,
    'option-2:300000:49': 1220.44,
    'option-4:200000:33': 255.47,
    'option-3:100000:27': 82.59,
    'option-4:350000:9': 267.99,
    'option-1:5000:1': 17.54,
    'option-4:65000:31': 91.97,
    'option-2:250000:44': 633.53,
    'option-1:400000:37': 376.1,
    'option-4:300000:35': 379.08,
    'option-3:400000:57': 1876.99,
    'option-4:85000:32': 115.95,
    'option-4:65000:16': 60.94,
    'option-3:150000:44': 251.06,
    'option-2:80000:11': 54.84,
    'option-2:85000:48': 350.36,
    'option-1:5000:43': 22.09,
    'option-2:90000:5': 59.87,
    'option-4:95000:24': 82.32,
    'option-2:5000:13': 18.18,
    'option-2:45000:36': 81.53,
    'option-2:85000:42': 222.23,
    'option-1:10000:31': 21.43,
    'option-1:5000:28': 18.03,
    'option-3:65000:59': 309.89,
    'option-1:80000:60': 360.46,
    'option-3:450000:53': 1548.61,
    'option-4:250000:26': 241.84,
    'option-4:35000:40': 73.37,
    'option-2:250000:31': 262.24,
    'option-1:5000:11': 17.54,
    'option-4:250000:2': 193.24,
    'option-2:400000:26': 295.72,
    'option-4:85000:9': 75.13,
    'option-2:150000:60': 1357.5,
    'option-1:60000:10': 36.76,
    'option-4:300000:27': 290.48,
    'option-3:60000:20': 49.3,
    'option-1:400000:0': 162.51,
    'option-1:60000:27': 42.91,
    'option-1:80000:34': 63.88,
    'option-2:100000:43': 259.11,
    'option-4:350000:51': 2307.1,
    'option-3:250000:12': 158.81,
    'option-3:350000:6': 219.56,
    'option-1:200000:28': 108.3,
    'option-3:30000:59': 146.51,
    'option-2:95000:33': 108.1,
    'option-3:50000:40': 91.53,
    'option-1:450000:33': 295.33,
    'option-2:150000:57': 1357.5,
    'option-3:200000:9': 130.15,
    'option-2:250000:52': 1584.07,
    'option-3:60000:59': 286.53,
    'option-3:20000:38': 35.77,
    'option-4:500000:6': 375.29,
    'option-3:100000:38': 124.79,
    'option-4:450000:8': 339.37,
    'option-3:60000:4': 49.3,
    'option-2:25000:40': 50.86,
    'option-3:85000:49': 209.22,
    'option-1:250000:3': 105.52,
    'option-2:35000:45': 97.64,
    'option-4:20000:16': 28.65,
    'option-1:85000:16': 45.83,
    'option-4:90000:30': 95.94,
    'option-2:90000:44': 234.7,
    'option-1:95000:18': 49.46,
    'option-4:90000:54': 593.8,
    'option-1:300000:58': 1334.37,
    'option-2:500000:11': 268.23,
    'option-3:250000:39': 291.74,
    'option-1:90000:20': 47.65,
    'option-1:20000:27': 24.22,
    'option-4:50000:42': 145.46,
    'option-1:250000:7': 105.52,
    'option-3:30000:19': 31.96,
    'option-3:65000:0': 52.19,
    'option-2:95000:55': 603.37,
    'option-2:65000:4': 47.31,
    'option-3:100000:59': 472.35,
    'option-3:90000:31': 89.01,
    'option-2:10000:52': 72.75,
    'option-4:10000:7': 22.33,
    'option-1:100000:24': 51.27,
    'option-4:80000:55': 527.97,
    'option-2:200000:27': 153.97,
    'option-2:25000:37': 50.86,
    'option-4:70000:1': 64.52,
    'option-3:60000:37': 80.37,
    'option-3:300000:51': 1036.52,
    'option-4:80000:28': 86.8,
    'option-2:400000:57': 3628.82,
    'option-2:100000:1': 64.89,
    'option-4:150000:13': 121.72,
    'option-1:15000:45': 34.19,
    'option-3:15000:42': 37.13,
    'option-1:15000:44': 34.19,
    'option-4:55000:15': 53.77,
    'option-2:55000:22': 42.39,
    'option-2:95000:27': 80.55,
    'option-1:90000:11': 47.65,
    'option-3:250000:18': 158.81,
    'option-2:30000:33': 43.5,
    'option-3:500000:22': 306.01,
    'option-2:90000:37': 150.39,
    'option-3:80000:29': 68.9,
    'option-2:30000:4': 29.85,
    'option-4:90000:47': 390.53,
    'option-3:70000:17': 54.96,
    'option-1:70000:7': 40.39,
    'option-1:400000:40': 376.1,
    'option-2:50000:51': 319.04,
    'option-3:300000:9': 190.55,
    'option-4:200000:28': 196.57,
    'option-4:95000:31': 128.07,
    'option-1:25000:13': 24.16,
    'option-4:5000:33': 21.38,
    'option-4:450000:26': 427.33,
    'option-1:35000:2': 27.79,
    'option-1:100000:42': 149.72,
    'option-2:100000:36': 165.76,
    'option-1:400000:24': 162.51,
    'option-3:25000:34': 34.95,
    'option-3:100000:19': 72.32,
    'option-1:20000:6': 22.35,
    'option-2:400000:50': 1625.34,
    'option-4:45000:45': 131.87,
    'option-3:55000:36': 74.79,
    'option-2:25000:19': 27.34,
    'option-4:85000:26': 91.36,
    'option-3:10000:48': 36.78,
    'option-2:75000:35': 88.37,
    'option-1:90000:60': 403.69,
    'option-3:10000:15': 21.08,
    'option-1:85000:29': 54.51,
    'option-1:5000:57': 36.78,
    'option-1:10000:57': 56.28,
    'option-2:100000:0': 64.89,
    'option-1:10000:60': 56.28,
    'option-3:25000:35': 34.95,
    'option-4:60000:3': 57.35,
    'option-2:55000:49': 229.12,
    'option-2:70000:3': 49.81,
    'option-3:80000:17': 60.74,
    'option-1:35000:16': 27.79,
    'option-3:70000:10': 54.96,
    'option-1:450000:46': 962.08,
    'option-3:350000:30': 256.17,
    'option-1:95000:21': 49.46,
    'option-1:50000:10': 33.22,
    'option-4:55000:47': 241.13,
    'option-2:50000:15': 39.88,
    'option-2:50000:6': 39.88,
    'option-2:450000:38': 705.79,
    'option-4:90000:16': 78.73,
    'option-3:250000:6': 158.81,
    'option-4:65000:51': 429.21,
    'option-1:250000:43': 354.92,
    'option-2:75000:28': 66.57,
    'option-4:65000:33': 91.97,
    'option-4:75000:17': 67.96,
    'option-3:60000:51': 213.63,
    'option-4:65000:7': 60.94,
    'option-4:20000:34': 37.47,
    'option-3:55000:37': 74.79,
    'option-1:70000:52': 232.02,
    'option-2:85000:29': 73.56,
    'option-1:25000:60': 117.34,
    'option-1:300000:5': 126.42,
    'option-4:20000:2': 28.65,
    'option-2:65000:20': 47.31,
    'option-3:200000:0': 130.15,
    'option-4:100000:8': 85.91,
    'option-1:150000:58': 669.67,
    'option-1:100000:25': 51.27,
    'option-3:70000:16': 54.96,
    'option-3:200000:16': 130.15,
    'option-1:450000:34': 295.33,
    'option-1:80000:8': 44.02,
    'option-2:400000:52': 2542.17,
    'option-4:500000:36': 890.21,
    'option-4:60000:20': 57.35,
    'option-3:65000:20': 52.19,
    'option-3:25000:40': 41.35,
    'option-3:45000:42': 83.96,
    'option-3:55000:21': 46.41,
    'option-1:55000:44': 88.27,
    'option-1:500000:18': 198.81,
    'option-3:55000:53': 196.52,
    'option-3:95000:54': 332.64,
    'option-1:450000:41': 632.04,
    'option-4:75000:6': 67.96,
    'option-1:150000:31': 107.19,
    'option-4:500000:56': 4660.16,
    'option-2:45000:2': 37.38,
    'option-4:400000:6': 303.18,
    'option-2:200000:31': 212.89,
    'option-3:25000:17': 29.07,
    'option-2:400000:27': 295.72,
    'option-4:60000:9': 57.35,
    'option-4:35000:3': 39.42,
    'option-2:150000:50': 612.82,
    'option-2:65000:46': 269.55,
    'option-3:90000:42': 155.28,
    'option-1:200000:45': 287.07,
    'option-4:55000:37': 108.36,
    'option-2:90000:33': 103.11,
    'option-2:450000:60': 4077.17,
    'option-2:200000:14': 115.13,
    'option-3:300000:7': 190.55,
    'option-2:25000:52': 160.37,
    'option-1:80000:26': 52.16,
    'option-3:100000:51': 349.79,
    'option-2:45000:35': 58.48,
    'option-1:70000:53': 232.02,
    'option-2:250000:32': 262.24,
    'option-1:450000:26': 227.67,
    'option-1:85000:54': 279.83,
    'option-4:450000:33': 560.54,
    'option-3:20000:7': 26.19,
    'option-2:400000:13': 217.84,
    'option-1:50000:9': 33.22,
    'option-3:500000:5': 306.01,
    'option-3:15000:4': 23.62,
    'option-3:90000:24': 66.53,
    'option-4:400000:20': 303.18,
    'option-2:60000:3': 44.9,
    'option-4:20000:21': 28.65,
    'option-1:5000:36': 19.96,
    'option-3:55000:46': 139.15,
    'option-4:500000:38': 890.21,
    'option-4:35000:49': 155.91,
    'option-3:20000:55': 76.74,
    'option-3:300000:34': 266.96,
    'option-1:85000:52': 279.83,
    'option-1:150000:23': 69.4,
    'option-4:80000:40': 152.18,
    'option-2:35000:11': 32.36,
    'option-1:35000:44': 60.78,
    'option-1:45000:44': 74.54,
    'option-1:350000:6': 144.61,
    'option-1:50000:14': 33.22,
    'option-4:350000:38': 627.94,
    'option-2:40000:50': 168.67,
    'option-3:60000:0': 49.3,
    'option-2:200000:46': 814.59,
    'option-3:350000:21': 219.56,
    'option-4:85000:51': 560.89,
    'option-4:90000:26': 95.94,
    'option-2:40000:16': 34.87,
    'option-3:80000:57': 379.96,
    'option-2:90000:10': 59.87,
    'option-3:20000:27': 28.06,
    'option-4:10000:0': 22.33,
    'option-3:40000:14': 37.75,
    'option-3:55000:28': 51.97,
    'option-2:50000:44': 134.93,
    'option-2:30000:39': 58.55,
    'option-3:20000:40': 35.77,
    'option-2:250000:9': 140.03,
    'option-1:40000:52': 136.18,
    'option-2:150000:0': 90.0,
    'option-4:70000:45': 199.75,
    'option-3:70000:8': 54.96,
    'option-2:45000:47': 188.67,
    'option-2:150000:52': 953.46,
    'option-1:30000:29': 28.89,
    'option-3:300000:1': 190.55,
    'option-4:15000:25': 25.51,
    'option-2:10000:25': 20.37,
    'option-2:40000:38': 73.84,
    'option-3:30000:49': 80.83,
    'option-2:50000:18': 39.88,
    'option-4:55000:6': 53.77,
    'option-1:15000:52': 58.6,
    'option-2:75000:34': 88.37,
    'option-2:40000:34': 53.49,
    'option-4:50000:47': 219.77,
    'option-2:20000:39': 43.19,
    'option-2:350000:30': 261.45,
    'option-2:5000:22': 18.18,
    'option-1:95000:55': 311.0,
    'option-4:200000:25': 157.69,
    'option-3:20000:14': 26.19,
    'option-1:35000:17': 27.79,
    'option-2:95000:7': 62.38,
    'option-2:35000:9': 32.36,
    'option-2:35000:54': 224.09,
    'option-3:40000:2': 37.75,
    'option-4:400000:57': 3730.95,
    'option-3:90000:30': 75.75,
    'option-1:50000:24': 33.22,
    'option-2:10000:50': 52.09,
    'option-2:90000:3': 59.87,
    'option-3:90000:37': 113.61,
    'option-3:500000:31': 433.77,
    'option-4:95000:35': 128.07,
    'option-4:25000:42': 77.68,
    'option-1:5000:26': 18.03,
    'option-2:500000:29': 365.95,
    'option-3:5000:40': 20.96,
    'option-2:90000:40': 150.39,
    'option-2:50000:32': 63.46,
    'option-4:40000:9': 43.01,
    'option-4:40000:27': 55.0,
    'option-1:65000:19': 38.58,
    'option-2:35000:41': 97.64,
    'option-2:45000:41': 122.44,
    'option-3:500000:48': 1179.92,
    'option-2:450000:42': 1133.53,
    'option-2:100000:55': 635.05,
    'option-2:65000:24': 47.31,
    'option-4:35000:47': 155.91,
    'option-4:55000:60': 509.09,
    'option-4:10000:49': 54.13,
    'option-3:500000:49': 1179.92,
    'option-2:250000:35': 262.24,
    'option-4:100000:35': 134.14,
    'option-1:90000:58': 403.69,
    'option-4:45000:57': 415.5,
    'option-4:85000:30': 91.36,
    'option-4:55000:18': 53.77,
    'option-3:65000:32': 68.32,
    'option-2:45000:30': 45.77,
    'option-4:85000:34': 115.95,
    'option-2:90000:53': 572.96,
    'option-1:450000:17': 180.68,
    'option-2:500000:17': 268.23,
    'option-3:150000:1': 101.2,
    'option-4:60000:35': 85.91,
    'option-1:30000:32': 33.07,
    'option-3:500000:38': 573.54,
    'option-3:90000:44': 155.28,
    'option-2:300000:13': 167.94,
    'option-2:100000:23': 64.89,
    'option-1:80000:39': 85.59,
    'option-4:20000:31': 37.47,
    'option-3:15000:2': 23.62,
    'option-2:55000:31': 68.44,
    'option-3:40000:29': 41.72,
    'option-4:100000:46': 432.3,
    'option-2:10000:10': 20.37,
    'option-2:15000:20': 22.6,
    'option-4:15000:52': 104.02,
    'option-2:20000:51': 129.0,
    'option-3:15000:8': 23.62,
    'option-4:65000:3': 60.94,
    'option-4:500000:30': 473.08,
    'option-4:350000:26': 336.36,
    'option-4:5000:49': 35.24,
    'option-4:250000:12': 193.24,
    'option-4:40000:43': 118.22,
    'option-2:25000:59': 223.69,
    'option-1:80000:19': 44.02,
    'option-1:150000:2': 69.4,
    'option-4:35000:23': 39.42,
    'option-1:500000:34': 326.33,
    'option-2:75000:52': 477.77,
    'option-3:75000:29': 65.49,
    'option-1:5000:14': 17.54,
    'option-2:65000:13': 47.31,
    'option-4:35000:45': 104.88,
    'option-1:200000:7': 87.52,
    'option-1:5000:4': 17.54,
    'option-2:60000:13': 44.9,
    'option-2:100000:50': 410.11,
    'option-3:50000:31': 55.79,
    'option-2:500000:48': 2027.44,
    'option-1:95000:11': 49.46,
    'option-4:300000:11': 231.99,
    'option-1:150000:27': 84.91,
    'option-3:400000:33': 350.64,
    'option-1:65000:2': 38.58,
    'option-4:45000:9': 46.6,
    'option-1:100000:20': 51.27,
    'option-4:350000:52': 2307.1,
    'option-4:500000:50': 2140.7,
    'option-4:95000:23': 82.32,
    'option-1:300000:52': 966.98,
    'option-1:60000:5': 36.76,
    'option-3:20000:59': 99.92,
    'option-2:35000:33': 48.5,
    'option-4:500000:51': 3292.08,
    'option-2:55000:29': 52.75,
    'option-1:70000:6': 40.39,
    'option-1:75000:13': 42.21,
    'option-2:35000:48': 148.45,
    'option-1:80000:4': 44.02,
    'option-4:350000:16': 267.99,
    'option-1:65000:52': 216.07,
    'option-3:65000:56': 309.89,
    'option-2:65000:6': 47.31,
    'option-1:35000:21': 27.79,
    'option-1:45000:38': 54.32,
    'option-4:65000:6': 60.94,
    'option-2:400000:56': 3628.82,
    'option-1:30000:28': 28.89,
    'option-4:75000:46': 326.51,
    'option-3:10000:32': 23.36,
    'option-1:25000:45': 47.15,
    'option-1:65000:18': 38.58,
    'option-3:25000:57': 123.04,
    'option-1:90000:18': 47.65,
    'option-1:85000:37': 90.07,
    'option-2:60000:40': 104.6,
    'option-3:5000:5': 18.54,
    'option-1:350000:4': 144.61,
    'option-1:55000:18': 34.95,
    'option-3:70000:14': 54.96,
    'option-2:70000:37': 119.97,
    'option-3:60000:42': 107.86,
    'option-4:90000:27': 95.94,
    'option-3:60000:58': 286.53,
    'option-2:60000:25': 44.9,
    'option-1:300000:41': 426.63,
    'option-4:30000:37': 64.6,
    'option-1:70000:43': 108.85,
    'option-2:30000:40': 58.55,
    'option-2:200000:25': 115.13,
    'option-1:40000:49': 95.55,
    'option-2:80000:55': 509.5,
    'option-1:350000:56': 1556.09,
    'option-1:30000:22': 25.97,
    'option-2:70000:57': 631.76,
    'option-4:100000:47': 432.3,
    'option-1:90000:17': 47.65,
    'option-4:40000:35': 61.66,
    'option-3:50000:19': 43.52,
    'option-4:150000:5': 121.72,
    'option-1:50000:29': 38.24,
    'option-3:85000:4': 63.63,
    'option-3:50000:47': 127.46,
    'option-4:350000:53': 2307.1,
    'option-4:70000:53': 462.14,
    'option-1:150000:24': 69.4,
    'option-4:10000:26': 24.07,
    'option-3:55000:52': 196.52,
    'option-4:350000:37': 627.94,
    'option-2:150000:51': 953.46,
    'option-4:350000:0': 267.99,
    'option-4:40000:56': 369.44,
    'option-1:55000:1': 34.95,
    'option-2:10000:46': 52.09,
    'option-2:300000:47': 1220.44,
    'option-3:40000:12': 37.75,
    'option-3:55000:18': 46.41,
    'option-1:90000:32': 70.08,
    'option-1:100000:50': 221.76,
    'option-3:55000:34': 59.97,
    'option-2:250000:20': 140.03,
    'option-2:15000:57': 138.01,
    'option-1:150000:0': 69.4,
    'option-2:60000:2': 44.9,
    'option-3:50000:52': 179.39,
    'option-1:65000:20': 38.58,
    'option-4:25000:14': 32.23,
    'option-3:20000:48': 57.43,
    'option-1:100000:58': 447.9,
    'option-3:250000:47': 593.1,
    'option-3:45000:13': 40.63,
    'option-1:150000:12': 69.4,
    'option-4:90000:49': 390.53,
    'option-4:400000:50': 1715.79,
    'option-2:95000:39': 158.06,
    'option-1:350000:42': 495.51,
    'option-4:5000:7': 19.16,
    'option-4:350000:11': 267.99,
    'option-3:35000:24': 34.85,
    'option-1:5000:18': 17.54,
    'option-3:25000:49': 69.12,
    'option-4:25000:36': 55.82,
    'option-2:95000:29': 80.55,
    'option-1:90000:48': 200.66,
    'option-3:5000:41': 23.1,
    'option-4:350000:30': 336.36,
    'option-1:10000:23': 19.14,
    'option-2:10000:6': 20.37,
    'option-1:30000:8': 25.97,
    'option-4:350000:41': 961.75,
    'option-1:65000:26': 45.15,
    'option-2:300000:8': 167.94,
    'option-4:400000:21': 303.18,
    'option-2:5000:17': 18.18,
    'option-2:200000:16': 115.13,
    'option-2:90000:13': 59.87,
    'option-3:10000:46': 36.78,
    'option-3:65000:28': 58.8,
    'option-1:80000:37': 85.59,
    'option-3:40000:58': 193.28,
    'option-2:10000:45': 38.16,
    'option-1:70000:44': 108.85,
    'option-4:15000:3': 25.51,
    'option-1:250000:2': 105.52,
    'option-2:300000:16': 167.94,
    'option-2:400000:51': 2542.17,
    'option-1:15000:7': 20.75,
    'option-4:80000:35': 110.14,
    'option-4:40000:46': 177.27,
    'option-3:25000:47': 69.12,
    'option-2:15000:4': 22.6,
    'option-1:80000:53': 263.9,
    'option-1:10000:0': 19.14,
    'option-3:350000:53': 1208.18,
    'option-3:25000:21': 29.07,
    'option-2:80000:51': 509.5,
    'option-4:5000:14': 19.16,
    'option-3:20000:23': 26.19,
    'option-3:60000:9': 49.3,
    'option-4:400000:56': 3730.95,
    'option-3:35000:51': 128.19,
    'option-1:300000:60': 1334.37,
    'option-2:65000:42': 172.36,
    'option-1:400000:11': 162.51,
    'option-2:30000:30': 35.29,
    'option-2:300000:25': 167.94,
    'option-1:10000:16': 19.14,
    'option-4:50000:35': 73.79,
    'option-2:75000:51': 477.77,
    'option-2:35000:36': 66.1,
    'option-4:35000:33': 55.59,
    'option-4:45000:4': 46.6,
    'option-2:350000:7': 193.15,
    'option-4:70000:7': 64.52,
    'option-1:95000:15': 49.46,
    'option-1:350000:47': 752.09,
    'option-4:85000:37': 160.95,
    'option-3:55000:56': 263.16,
    'option-4:15000:46': 73.02,
    'option-4:80000:53': 527.97,
    'option-4:10000:33': 26.75,
    'option-2:100000:40': 165.76,
    'option-1:70000:17': 40.39,
    'option-4:20000:48': 91.96,
    'option-2:25000:30': 31.79,
    'option-3:55000:26': 51.97,
    'option-4:20000:28': 32.19,
    'option-3:200000:7': 130.15,
    'option-2:200000:1': 115.13,
    'option-2:400000:40': 629.74,
    'option-1:15000:5': 20.75,
    'option-1:250000:11': 105.52,
    'option-2:10000:14': 20.37,
    'option-2:30000:26': 35.29,
    'option-1:400000:59': 1777.3,
    'option-2:90000:11': 59.87,
    'option-2:500000:60': 4532.46,
    'option-2:450000:13': 243.1,
    'option-4:350000:59': 3263.79,
    'option-2:80000:9': 54.84,
    'option-1:15000:59': 75.85,
    'option-3:35000:42': 67.97,
    'option-2:250000:16': 140.03,
    'option-3:45000:24': 40.63,
    'option-1:250000:41': 354.92,
    'option-1:20000:1': 22.35,
    'option-2:65000:22': 47.31,
    'option-4:15000:59': 141.43,
    'option-2:500000:26': 365.95,
    'option-2:15000:54': 100.85,
    'option-1:150000:55': 486.94,
    'option-2:15000:42': 49.23,
    'option-2:60000:36': 104.6,
    'option-2:80000:21': 54.84,
    'option-2:100000:29': 84.05,
    'option-4:50000:28': 68.71,
    'option-2:60000:9': 44.9,
    'option-4:500000:27': 473.08,
    'option-1:100000:0': 51.27,
    'option-1:60000:45': 95.13,
    'option-4:20000:53': 133.23,
    'option-1:30000:56': 139.54,
    'option-1:250000:48': 537.32,
    'option-3:450000:3': 277.08,
    'option-4:100000:57': 927.12,
    'option-2:5000:53': 44.79,
    'option-4:75000:43': 213.32,
    'option-2:80000:23': 54.84,
    'option-1:60000:54': 200.13,
    'option-1:10000:24': 19.14,
    'option-2:5000:20': 18.18,
    'option-3:150000:34': 139.03,
    'option-4:100000:11': 85.91,
    'option-3:40000:42': 75.99,
    'option-1:70000:5': 40.39,
    'option-1:90000:24': 47.65,
    'option-2:15000:52': 100.85,
    'option-3:200000:51': 692.37,
    'option-2:75000:60': 677.15,
    'option-4:40000:17': 43.01,
    'option-4:95000:59': 880.53,
    'option-1:40000:37': 49.82,
    'option-2:200000:5': 115.13,
    'option-4:5000:44': 28.16,
    'option-4:5000:6': 19.16,
    'option-4:30000:0': 35.83,
    'option-2:350000:40': 552.81,
    'option-2:500000:12': 268.23,
    'option-3:60000:24': 49.3,
    'option-2:25000:22': 27.34,
    'option-3:25000:23': 29.07,
    'option-3:5000:0': 18.54,
    'option-2:30000:32': 43.5,
    'option-2:10000:15': 20.37,
    'option-1:20000:13': 22.35,
    'option-1:250000:56': 1108.9,
    'option-4:80000:13': 71.54,
    'option-1:100000:37': 103.59,
    'option-2:40000:4': 34.87,
    'option-1:30000:27': 28.89,
    'option-3:30000:43': 60.15,
    'option-1:40000:26': 33.56,
    'option-4:50000:59': 462.36,
    'option-4:60000:32': 85.91,
    'option-1:50000:39': 58.82,
    'option-1:65000:31': 54.73,
    'option-1:80000:36': 85.59,
    'option-4:25000:17': 32.23,
    'option-1:300000:35': 202.67,
    'option-1:20000:17': 22.35,
    'option-1:35000:20': 27.79,
    'option-3:200000:38': 236.51,
    'option-1:500000:60': 2217.34,
    'option-1:35000:56': 161.69,
    'option-2:450000:21': 243.1,
    'option-4:95000:26': 100.52,
    'option-1:75000:25': 42.21,
    'option-1:90000:19': 47.65,
    'option-2:50000:47': 208.9,
    'option-1:450000:2': 180.68,
    'option-1:35000:55': 120.52,
    'option-4:300000:43': 825.63,
    'option-3:40000:8': 37.75,
    'option-1:25000:21': 24.16,
    'option-3:85000:59': 403.31,
    'option-2:100000:18': 64.89,
    'option-3:30000:58': 146.51,
    'option-3:200000:5': 130.15,
    'option-4:30000:18': 35.83,
    'option-4:450000:32': 560.54,
    'option-1:20000:19': 22.35,
    'option-4:95000:38': 178.09,
    'option-3:75000:36': 97.11,
    'option-1:60000:35': 51.64,
    'option-3:90000:55': 315.56,
    'option-2:15000:36': 36.35,
    'option-4:45000:40': 90.81,
    'option-4:500000:5': 375.29,
    'option-4:300000:44': 825.63,
    'option-4:40000:55': 264.83,
    'option-2:50000:9': 39.88,
    'option-3:80000:16': 60.74,
    'option-3:10000:1': 21.08,
    'option-4:55000:55': 363.34,
    'option-4:40000:58': 369.44,
    'option-2:35000:21': 32.36,
    'option-4:20000:13': 28.65,
    'option-4:80000:0': 71.54,
    'option-3:95000:51': 332.64,
    'option-1:5000:21': 17.54,
    'option-2:200000:36': 319.64,
    'option-3:250000:36': 291.74,
    'option-4:45000:27': 59.57,
    'option-1:40000:59': 183.8,
    'option-3:500000:21': 306.01,
    'option-2:300000:46': 1220.44,
    'option-2:5000:57': 57.88,
    'option-1:500000:39': 465.6,
    'option-3:75000:21': 57.84,
    'option-2:85000:12': 57.35,
    'option-2:45000:23': 37.38,
    'option-4:75000:57': 695.84,
    'option-1:200000:22': 87.52,
    'option-1:300000:29': 157.67,
    'option-4:250000:40': 449.08,
    'option-4:70000:38': 134.66,
    'option-3:75000:50': 185.87,
    'option-4:15000:21': 25.51,
    'option-1:80000:3': 44.02,
    'option-4:85000:18': 75.13,
    'option-1:50000:32': 45.45,
    'option-4:30000:54': 198.87,
    'option-3:450000:46': 1062.91,
    'option-3:65000:10': 52.19,
    'option-3:90000:8': 66.53,
    'option-4:95000:4': 82.32,
    'option-2:20000:19': 24.83,
    'option-2:5000:29': 19.06,
    'option-2:60000:24': 44.9,
    'option-4:500000:20': 375.29,
    'option-3:30000:0': 31.96,
    'option-4:400000:2': 303.18,
    'option-2:65000:7': 47.31,
    'option-4:10000:4': 22.33,
    'option-3:450000:56': 2107.81,
    'option-2:35000:24': 32.36,
    'option-3:70000:7': 54.96,
    'option-4:50000:12': 50.18,
    'option-4:60000:26': 68.71,
    'option-2:75000:43': 197.3,
    'option-1:40000:7': 29.6,
    'option-1:150000:29': 84.91,
    'option-3:20000:52': 76.74,
    'option-2:30000:36': 58.55,
    'option-1:95000:29': 59.19,
    'option-2:60000:29': 56.24,
    'option-2:35000:15': 32.36,
    'option-3:50000:8': 43.52,
    'option-2:60000:52': 382.56,
    'option-3:55000:24': 46.41,
    'option-1:400000:16': 162.51,
    'option-4:100000:22': 85.91,
    'option-1:5000:0': 17.54,
    'option-1:15000:2': 20.75,
    'option-2:15000:6': 22.6,
    'option-2:35000:14': 32.36,
    'option-3:45000:8': 40.63,
    'option-2:25000:32': 38.59,
    'option-1:95000:4': 49.46,
    'option-1:35000:31': 36.17,
    'option-3:300000:32': 266.96,
    'option-4:250000:3': 193.24,
    'option-3:45000:12': 40.63,
    'option-2:400000:44': 1010.21,
    'option-3:60000:12': 49.3,
    'option-1:85000:6': 45.83,
    'option-2:20000:35': 33.62,
    'option-2:20000:45': 60.18,
    'option-2:15000:58': 138.01,
    'option-3:15000:11': 23.62,
    'option-3:25000:42': 52.18,
    'option-1:15000:11': 20.75,
    'option-1:95000:46': 211.19,
    'option-2:85000:0': 57.35,
    'option-1:45000:35': 42.36,
    'option-4:5000:29': 20.06,
    'option-3:5000:53': 31.43,
    'option-3:60000:7': 49.3,
    'option-3:70000:18': 54.96,
    'option-1:55000:23': 34.95,
    'option-3:150000:7': 101.2,
    'option-4:35000:56': 322.73,
    'option-1:65000:44': 101.99,
    'option-4:70000:35': 98.03,
    'option-3:300000:33': 266.96,
    'option-4:35000:7': 39.42,
    'option-1:50000:43': 81.4,
    'option-4:40000:36': 82.04,
    'option-4:250000:14': 193.24,
    'option-1:30000:5': 25.97,
    'option-1:55000:30': 40.57,
    'option-1:500000:36': 465.6,
    'option-2:80000:18': 54.84,
    'option-4:400000:37': 715.69,
    'option-4:70000:5': 64.52,
    'option-3:300000:41': 492.06,
    'option-1:95000:43': 142.85,
    'option-2:10000:3': 20.37,
    'option-3:150000:10': 101.2,
    'option-4:150000:24': 121.72,
    'option-1:95000:36': 99.08,
    'option-1:95000:53': 311.0,
    'option-1:200000:25': 87.52,
    'option-2:400000:23': 217.84,
    'option-2:75000:48': 309.96,
    'option-3:500000:46': 1179.92,
    'option-2:300000:9': 167.94,
    'option-4:200000:13': 157.69,
    'option-2:35000:42': 97.64,
    'option-4:150000:9': 121.72,
    'option-3:30000:21': 31.96,
    'option-3:45000:51': 162.25,
    'option-1:35000:45': 60.78,
    'option-4:500000:34': 621.23,
    'option-4:5000:4': 19.16,
    'option-1:5000:19': 17.54,
    'option-1:100000:13': 51.27,
    'option-3:300000:23': 190.55,
    'option-1:500000:52': 1603.9,
    'option-4:55000:46': 241.13,
    'option-4:450000:44': 1231.7,
    'option-2:85000:49': 350.36,
    'option-3:75000:25': 57.84,
    'option-3:400000:53': 1379.44,
    'option-4:60000:40': 117.13,
    'option-1:50000:27': 38.24,
    'option-2:45000:28': 45.77,
    'option-4:50000:3': 50.18,
    'option-4:90000:8': 78.73,
    'option-3:500000:0': 306.01,
    'option-4:95000:20': 82.32,
    'option-3:400000:14': 247.96,
    'option-4:100000:15': 85.91,
    'option-3:75000:31': 76.66,
    'option-3:70000:44': 123.78,
    'option-4:90000:20': 78.73,
    'option-1:50000:58': 227.81,
    'option-2:350000:6': 193.15,
    'option-1:25000:17': 24.16,
    'option-4:350000:5': 267.99,
    'option-3:70000:42': 123.78,
    'option-2:150000:36': 242.88,
    'option-4:400000:54': 2636.61,
    'option-3:90000:2': 66.53,
    'option-4:25000:19': 32.23,
    'option-4:10000:29': 24.07,
    'option-3:70000:60': 333.25,
    'option-4:95000:45': 267.01,
    'option-1:500000:40': 465.6,
    'option-2:450000:58': 4077.17,
    'option-3:70000:35': 72.49,
    'option-2:400000:35': 414.97,
    'option-4:350000:44': 961.75,
    'option-1:90000:41': 136.0,
    'option-1:35000:53': 120.52,
    'option-3:100000:7': 72.32,
    'option-1:500000:50': 1067.77,
    'option-2:300000:32': 315.03,
    'option-3:30000:29': 34.89,
    'option-2:450000:9': 243.1,
    'option-1:10000:8': 19.14,
    'option-1:450000:3': 180.68,
    'option-2:25000:25': 27.34,
    'option-2:100000:39': 165.76,
    'option-2:50000:5': 39.88,
    'option-1:15000:54': 58.6,
    'option-1:5000:20': 17.54,
    'option-1:15000:27': 22.14,
    'option-4:500000:35': 621.23,
    'option-3:150000:51': 521.48,
    'option-3:90000:13': 66.53,
    'option-3:200000:57': 939.96,
    'option-2:55000:13': 42.39,
    'option-3:10000:37': 25.84,
    'option-3:350000:59': 1643.23,
    'option-1:500000:4': 198.81,
    'option-3:450000:8': 277.08,
    'option-3:85000:17': 63.63,
    'option-3:30000:60': 146.51,
    'option-2:85000:46': 350.36,
    'option-2:30000:57': 268.81,
    'option-1:500000:35': 326.33,
    'option-2:350000:28': 261.45,
    'option-2:85000:19': 57.35,
    'option-3:10000:34': 23.36,
    'option-1:10000:35': 21.43,
    'option-4:100000:26': 105.09,
    'option-2:10000:11': 20.37,
    'option-2:25000:2': 27.34,
    'option-3:200000:45': 330.56,
    'option-2:80000:34': 93.36,
    'option-4:90000:51': 593.8,
    'option-2:40000:52': 255.85,
    'option-2:95000:46': 389.94,
    'option-4:65000:43': 186.18,
    'option-2:95000:14': 62.38,
    'option-3:50000:26': 48.56,
    'option-2:40000:40': 73.84,
    'option-4:65000:54': 429.21,
    'option-4:25000:50': 113.09,
    'option-2:55000:57': 495.55,
    'option-2:55000:53': 350.81,
    'option-4:60000:46': 262.48,
    'option-1:50000:52': 168.22,
    'option-3:95000:53': 332.64,
    'option-2:350000:34': 365.08,
    'option-2:40000:6': 34.87,
    'option-2:90000:41': 234.7,
    'option-4:250000:18': 193.24,
    'option-1:75000:58': 338.36,
    'option-4:20000:30': 32.19,
    'option-2:95000:3': 62.38,
    'option-2:30000:13': 29.85,
    'option-3:450000:26': 324.16,
    'option-3:50000:6': 43.52,
    'option-3:75000:46': 185.87,
    'option-4:55000:16': 53.77,
    'option-3:80000:53': 282.08,
    'option-2:30000:14': 29.85,
    'option-1:500000:15': 198.81,
    'option-1:35000:23': 27.79,
    'option-1:500000:1': 198.81,
    'option-4:100000:32': 134.14,
    'option-3:60000:48': 150.83,
    'option-4:30000:29': 41.27,
    'option-4:70000:58': 649.16,
    'option-4:75000:51': 495.06,
    'option-3:150000:55': 521.48,
    'option-1:70000:46': 158.9,
    'option-4:65000:36': 125.89,
    'option-1:35000:9': 27.79,
    'option-3:5000:8': 18.54,
    'option-1:65000:58': 294.16,
    'option-1:50000:47': 116.68,
    'option-3:400000:40': 462.43,
    'option-3:300000:21': 190.55,
    'option-1:80000:59': 360.46,
    'option-4:250000:35': 315.44,
    'option-1:60000:38': 67.8,
    'option-2:5000:37': 22.83,
    'option-1:400000:33': 264.14,
    'option-2:55000:55': 350.81,
    'option-2:60000:60': 540.97,
    'option-3:10000:10': 21.08,
    'option-1:45000:57': 205.69,
    'option-4:500000:26': 473.08,
    'option-3:95000:16': 69.42,
    'option-2:350000:42': 885.39,
    'option-4:85000:17': 75.13,
    'option-2:15000:7': 22.6,
    'option-1:75000:31': 60.79,
    'option-4:150000:22': 121.72,
    'option-4:25000:16': 32.23,
    'option-2:65000:16': 47.31,
    'option-3:30000:40': 46.93,
    'option-2:95000:38': 158.06,
    'option-2:85000:27': 73.56,
    'option-3:45000:29': 45.14,
    'option-1:90000:44': 136.0,
    'option-3:90000:51': 315.56,
    'option-4:70000:52': 462.14,
    'option-4:60000:4': 57.35,
    'option-3:55000:45': 99.89,
    'option-1:55000:38': 63.31,
    'option-4:300000:23': 231.99,
    'option-2:50000:55': 319.04,
    'option-2:5000:25': 18.18,
    'option-4:300000:56': 2795.63,
    'option-2:35000:7': 32.36,
    'option-4:60000:51': 396.28,
    'option-3:400000:18': 247.96,
    'option-1:90000:6': 47.65,
    'option-2:5000:6': 18.18,
    'option-1:150000:38': 148.41,
    'option-2:10000:9': 20.37,
    'option-4:30000:36': 64.6,
    'option-3:60000:56': 286.53,
    'option-2:15000:10': 22.6,
    'option-1:20000:60': 95.46,
    'option-3:75000:51': 264.97,
    'option-4:60000:52': 396.28,
    'option-4:450000:52': 2961.88,
    'option-3:200000:3': 130.15,
    'option-4:55000:53': 363.34,
    'option-1:75000:1': 42.21,
    'option-3:85000:34': 84.83,
    'option-3:15000:39': 30.81,
    'option-3:40000:7': 37.75,
    'option-4:30000:8': 35.83,
    'option-3:50000:59': 239.79,
    'option-1:40000:29': 33.56,
    'option-1:55000:60': 249.94,
    'option-3:25000:44': 52.18,
    'option-4:10000:48': 54.13,
    'option-4:300000:58': 2795.63,
    'option-1:200000:26': 108.3,
    'option-3:80000:44': 139.69,
    'option-2:450000:18': 243.1,
    'option-4:500000:4': 375.29,
    'option-1:30000:43': 54.02,
    'option-3:90000:11': 66.53,
    'option-2:50000:19': 39.88,
    'option-3:90000:14': 66.53,
    'option-2:90000:39': 150.39,
    'option-1:10000:33': 21.43,
    'option-4:150000:48': 646.43,
    'option-4:75000:54': 495.06,
    'option-3:150000:41': 251.06,
    'option-3:100000:9': 72.32,
    'option-4:400000:34': 500.58,
    'option-2:20000:11': 24.83,
    'option-4:5000:55': 45.81,
    'option-2:65000:58': 586.37,
    'option-3:45000:31': 51.62,
    'option-3:450000:21': 277.08,
    'option-2:500000:36': 782.76,
    'option-1:350000:22': 144.61,
    'option-1:65000:3': 38.58,
    'option-2:15000:27': 25.24,
    'option-1:45000:45': 74.54,
    'option-4:40000:39': 82.04,
    'option-1:30000:10': 25.97,
    'option-1:50000:45': 81.4,
    'option-1:10000:7': 19.14,
    'option-4:250000:51': 1642.76,
    'option-1:150000:54': 486.94,
    'option-2:350000:31': 365.08,
    'option-4:10000:34': 26.75,
    'option-1:400000:27': 204.16,
    'option-1:35000:51': 120.52,
    'option-3:40000:48': 104.04,
    'option-4:50000:9': 50.18,
    'option-1:5000:51': 30.38,
    'option-1:65000:59': 294.16,
    'option-1:35000:40': 45.32,
    'option-3:85000:23': 63.63,
    'option-1:95000:59': 425.75,
    'option-2:450000:48': 1825.01,
    'option-2:70000:17': 49.81,
    'option-4:45000:34': 67.72,
    'option-2:20000:26': 28.31,
    'option-3:10000:17': 21.08,
    'option-4:150000:3': 121.72,
    'option-3:45000:55': 162.25,
    'option-4:10000:50': 54.13,
    'option-1:25000:14': 24.16,
    'option-4:200000:51': 1317.35,
    'option-4:60000:7': 57.35,
    'option-4:15000:58': 141.43,
    'option-3:100000:24': 72.32,
    'option-3:150000:13': 101.2,
    'option-2:45000:31': 58.48,
    'option-3:20000:20': 26.19,
    'option-3:10000:59': 58.52,
    'option-4:200000:12': 157.69,
    'option-2:95000:23': 62.38,
    'option-4:50000:20': 50.18,
    'option-1:400000:39': 376.1,
    'option-4:100000:25': 85.91,
    'option-1:350000:39': 331.11,
    'option-2:80000:6': 54.84,
    'option-4:350000:33': 439.93,
    'option-2:40000:0': 34.87,
    'option-2:80000:0': 54.84,
    'option-2:300000:36': 475.65,
    'option-4:65000:0': 60.94,
    'option-4:100000:30': 105.09,
    'option-1:150000:13': 69.4,
    'option-3:55000:57': 263.16,
    'option-1:5000:31': 18.73,
    'option-4:80000:18': 71.54,
    'option-2:300000:4': 167.94,
    'option-2:40000:15': 34.87,
    'option-2:150000:42': 384.24,
    'option-3:35000:35': 43.26,
    'option-4:60000:49': 262.48,
    'option-1:100000:30': 61.53,
    'option-2:10000:53': 72.75,
    'option-3:95000:45': 163.23,
    'option-3:20000:3': 26.19,
    'option-4:10000:44': 40.12,
    'option-1:80000:25': 44.02,
    'option-2:350000:21': 193.15,
    'option-4:20000:5': 28.65,
    'option-1:25000:43': 47.15,
    'option-3:65000:22': 52.19,
    'option-4:55000:30': 64.14,
    'option-2:500000:42': 1258.47,
    'option-3:20000:16': 26.19,
    'option-3:150000:57': 706.7,
    'option-4:250000:37': 449.08,
    'option-4:200000:6': 157.69,
    'option-1:20000:36': 31.89,
    'option-3:100000:6': 72.32,
    'option-1:80000:5': 44.02,
    'option-2:90000:18': 59.87,
    'option-2:55000:56': 495.55,
    'option-2:25000:49': 107.91,
    'option-1:300000:33': 202.67,
    'option-2:15000:53': 100.85,
    'option-1:90000:14': 47.65,
    'option-4:15000:35': 32.09,
    'option-2:65000:28': 59.74,
    'option-1:30000:35': 33.07,
    'option-1:200000:1': 87.52,
    'option-4:70000:41': 199.75,
    'option-3:40000:43': 75.99,
    'option-1:35000:25': 27.79,
    'option-2:250000:39': 395.66,
    'option-2:300000:37': 475.65,
    'option-2:150000:46': 612.82,
    'option-4:150000:57': 1395.44,
    'option-1:350000:27': 181.15,
    'option-4:45000:21': 46.6,
    'option-1:150000:11': 69.4,
    'option-2:5000:50': 34.21,
    'option-3:15000:59': 79.2,
    'option-3:10000:39': 25.84,
    'option-2:150000:55': 953.46,
    'option-3:70000:27': 62.22,
    'option-1:10000:53': 44.48,
    'option-3:30000:22': 31.96,
    'option-4:500000:29': 473.08,
    'option-3:10000:8': 21.08,
    'option-4:5000:37': 23.81,
    'option-4:60000:50': 262.48,
    'option-4:300000:47': 1288.12,
    'option-3:20000:25': 26.19,
    'option-1:45000:25': 31.41,
    'option-2:60000:57': 540.97,
    'option-3:50000:50': 127.46,
    'option-3:65000:54': 230.75,
    'option-2:60000:55': 382.56,
    'option-4:15000:14': 25.51,
    'option-3:75000:20': 57.84,
    'option-1:100000:29': 61.53,
    'option-1:40000:15': 29.6,
    'option-2:60000:58': 540.97,
    'option-3:450000:37': 517.66,
    'option-1:500000:27': 251.07,
    'option-3:400000:24': 247.96,
    'option-3:90000:43': 155.28,
    'option-4:55000:45': 159.04,
    'option-4:35000:0': 39.42,
    'option-1:40000:3': 29.6,
    'option-4:80000:45': 226.89,
    'option-1:60000:0': 36.76,
    'option-4:95000:19': 82.32,
    'option-4:30000:7': 35.83,
    'option-1:15000:60': 75.85,
    'option-2:10000:47': 52.09,
    'option-4:80000:15': 71.54,
    'option-4:25000:23': 32.23,
    'option-4:15000:43': 52.19,
    'option-2:450000:44': 1133.53,
    'option-4:500000:14': 375.29,
    'option-4:150000:19': 121.72,
    'option-3:5000:34': 19.69,
    'option-2:45000:48': 188.67,
    'option-2:450000:56': 4077.17,
    'option-3:40000:22': 37.75,
    'option-1:55000:47': 127.24,
    'option-3:50000:18': 43.52,
    'option-4:70000:15': 64.52,
    'option-3:200000:13': 130.15,
    'option-2:400000:18': 217.84,
    'option-3:15000:18': 23.62,
    'option-1:150000:39': 148.41,
    'option-1:40000:19': 29.6,
    'option-4:65000:41': 186.18,
    'option-2:85000:2': 57.35,
    'option-4:90000:56': 835.82,
    'option-3:200000:17': 130.15,
    'option-4:50000:1': 50.18,
    'option-2:90000:2': 59.87,
    'option-4:95000:49': 410.99,
    'option-1:85000:59': 382.56,
    'option-2:95000:25': 62.38,
    'option-1:100000:56': 447.9,
    'option-1:20000:43': 40.3,
    'option-4:350000:7': 267.99,
    'option-1:100000:45': 149.72,
    'option-4:55000:12': 53.77,
    'option-3:500000:35': 433.77,
    'option-2:500000:49': 2027.44,
    'option-1:250000:46': 537.32,
    'option-2:25000:10': 27.34,
    'option-1:60000:1': 36.76,
    'option-4:50000:46': 219.77,
    'option-3:10000:30': 22.02,
    'option-1:30000:13': 25.97,
    'option-2:350000:57': 3174.52,
    'option-1:20000:20': 22.35,
    'option-2:10000:5': 20.37,
    'option-1:50000:16': 33.22,
    'option-2:300000:57': 2719.24,
    'option-3:10000:0': 21.08,
    'option-3:75000:27': 65.49,
    'option-2:50000:11': 39.88,
    'option-4:30000:12': 35.83,
    'option-4:90000:15': 78.73,
    'option-2:200000:33': 212.89,
    'option-4:300000:14': 231.99,
    'option-4:75000:12': 67.96,
    'option-4:10000:32': 26.75,
    'option-3:30000:16': 31.96,
    'option-4:90000:3': 78.73,
    'option-1:30000:49': 74.55,
    'option-3:40000:21': 37.75,
    'option-3:95000:3': 69.42,
    'option-4:90000:57': 835.82,
    'option-2:45000:46': 188.67,
    'option-2:400000:43': 1010.21,
    'option-4:50000:58': 462.36,
    'option-2:70000:23': 49.81,
    'option-4:90000:38': 169.34,
    'option-1:60000:56': 272.05,
    'option-1:95000:51': 311.0,
    'option-1:450000:13': 180.68,
    'option-3:500000:43': 810.09,
    'option-3:75000:56': 356.61,
    'option-4:400000:46': 1715.79,
    'option-3:60000:11': 49.3,
    'option-1:85000:5': 45.83,
    'option-4:10000:19': 22.33,
    'option-3:25000:0': 29.07,
    'option-4:80000:19': 71.54,
    'option-1:15000:10': 20.75,
    'option-3:25000:46': 69.12,
    'option-3:25000:60': 123.04,
    'option-2:50000:49': 208.9,
    'option-1:40000:31': 39.26,
    'option-4:50000:15': 50.18,
    'option-1:100000:17': 51.27,
    'option-3:200000:49': 477.58,
    'option-4:350000:45': 961.75,
    'option-2:25000:42': 72.65,
    'option-4:65000:17': 60.94,
    'option-3:50000:4': 43.52,
    'option-3:95000:25': 69.42,
    'option-3:5000:55': 31.43,
    'option-3:350000:2': 219.56,
    'option-2:350000:9': 193.15,
    'option-3:70000:46': 174.19,
    'option-2:85000:14': 57.35,
    'option-4:200000:23': 157.69,
    'option-1:50000:30': 38.24,
    'option-1:150000:42': 218.55,
    'option-1:45000:4': 31.41,
    'option-3:500000:13': 306.01,
    'option-3:35000:45': 67.97,
    'option-3:150000:29': 116.71,
    'option-2:60000:38': 104.6,
    'option-1:150000:10': 69.4,
    'option-4:15000:4': 25.51,
    'option-2:35000:43': 97.64,
    'option-2:55000:23': 42.39,
    'option-4:20000:58': 182.88,
    'option-3:15000:28': 25.05,
    'option-3:80000:23': 60.74,
    'option-2:5000:31': 20.4,
    'option-3:350000:1': 219.56,
    'option-3:100000:33': 97.38,
    'option-1:35000:5': 27.79,
    'option-2:70000:32': 83.39,
    'option-3:150000:33': 139.03,
    'option-4:25000:9': 32.23,
    'option-2:400000:19': 217.84,
    'option-1:450000:8': 180.68,
    'option-1:80000:13': 44.02,
    'option-3:25000:48': 69.12,
    'option-1:15000:1': 20.75,
    'option-4:10000:8': 22.33,
    'option-4:15000:44': 52.19,
    'option-4:350000:29': 336.36,
    'option-3:55000:40': 74.79,
    'option-1:90000:38': 94.58,
    'option-3:85000:51': 299.19,
    'option-4:300000:54': 1976.86,
    'option-2:20000:14': 24.83,
    'option-1:5000:32': 18.73,
    'option-1:45000:47': 106.12,
    'option-1:80000:21': 44.02,
    'option-1:40000:33': 39.26,
    'option-3:10000:9': 21.08,
    'option-4:65000:4': 60.94,
    'option-3:100000:23': 72.32,
    'option-1:350000:26': 181.15,
    'option-1:20000:8': 22.35,
    'option-3:20000:50': 57.43
  },
  Female: {
    'option-2:350000:44': 876.7,
    'option-3:350000:55': 1193.19,
    'option-1:75000:37': 79.84,
    'option-3:40000:40': 57.29,
    'option-2:65000:11': 46.5,
    'option-3:500000:25': 299.59,
    'option-1:450000:50': 947.42,
    'option-3:250000:26': 181.12,
    'option-2:90000:21': 58.74,
    'option-1:500000:7': 192.42,
    'option-3:55000:60': 260.3,
    'option-1:40000:16': 29.11,
    'option-3:80000:30': 67.74,
    'option-4:90000:1': 77.6,
    'option-2:500000:38': 772.96,
    'option-1:95000:9': 48.27,
    'option-1:70000:58': 312.6,
    'option-1:20000:10': 22.13,
    'option-3:90000:50': 217.53,
    'option-3:150000:43': 247.33,
    'option-4:450000:60': 4167.41,
    'option-4:450000:10': 333.6,
    'option-3:80000:31': 79.37,
    'option-4:150000:34': 192.49,
    'option-2:15000:3': 22.43,
    'option-3:450000:12': 271.3,
    'option-4:65000:27': 72.34,
    'option-2:450000:4': 237.36,
    'option-4:95000:9': 81.12,
    'option-4:95000:32': 126.53,
    'option-3:10000:55': 46.19,
    'option-4:50000:0': 49.57,
    'option-4:85000:19': 74.07,
    'option-4:150000:12': 119.82,
    'option-4:40000:48': 176.02,
    'option-3:60000:23': 48.56,
    'option-3:20000:11': 25.96,
    'option-1:400000:38': 368.23,
    'option-1:400000:58': 1755.82,
    'option-1:40000:55': 134.57,
    'option-4:500000:13': 368.87,
    'option-3:10000:22': 20.97,
    'option-3:500000:6': 299.59,
    'option-4:70000:36': 133.31,
    'option-1:20000:33': 26.6,
    'option-3:50000:0': 42.91,
    'option-3:30000:5': 31.61,
    'option-1:40000:53': 134.57,
    'option-2:95000:12': 61.19,
    'option-2:20000:7': 24.6,
    'option-2:500000:23': 261.85,
    'option-3:45000:17': 40.08,
    'option-4:5000:0': 19.1,
    'option-3:200000:32': 177.65,
    'option-1:400000:25': 157.41,
    'option-2:250000:38': 390.8,
    'option-3:150000:46': 356.08,
    'option-3:20000:33': 30.48,
    'option-3:500000:12': 299.59,
    'option-2:30000:51': 191.08,
    'option-1:200000:60': 879.72,
    'option-1:15000:48': 43.69,
    'option-4:5000:11': 19.1,
    'option-2:35000:0': 31.93,
    'option-3:85000:28': 71.09,
    'option-1:95000:48': 208.15,
    'option-4:30000:31': 49.13,
    'option-2:5000:0': 18.11,
    'option-2:70000:52': 443.12,
    'option-2:450000:17': 237.36,
    'option-4:75000:47': 324.12,
    'option-1:45000:58': 203.38,
    'option-1:45000:30': 35.26,
    'option-2:400000:25': 212.75,
    'option-2:55000:3': 41.71,
    'option-2:80000:14': 53.84,
    'option-1:150000:26': 82.7,
    'option-4:55000:25': 53.09,
    'option-4:400000:40': 707.81,
    'option-2:80000:36': 133.8,
    'option-2:80000:54': 506.15,
    'option-2:45000:42': 121.37,
    'option-3:100000:55': 345.58,
    'option-4:100000:50': 429.09,
    'option-2:35000:18': 31.93,
    'option-2:400000:4': 212.75,
    'option-1:70000:9': 39.52,
    'option-3:75000:47': 183.47,
    'option-3:65000:6': 51.38,
    'option-2:100000:34': 111.48,
    'option-4:25000:46': 112.34,
    'option-3:300000:27': 217.4,
    'option-2:55000:34': 67.57,
    'option-2:55000:46': 227.39,
    'option-1:15000:24': 20.58,
    'option-3:150000:32': 136.57,
    'option-4:150000:52': 982.15,
    'option-2:30000:34': 43.04,
    'option-2:65000:37': 111.04,
    'option-2:400000:48': 1612.3,
    'option-1:350000:45': 486.78,
    'option-3:30000:9': 31.61,
    'option-2:45000:26': 45.13,
    'option-1:250000:6': 102.34,
    'option-1:5000:23': 17.45,
    'option-4:90000:25': 77.6,
    'option-3:5000:59': 37.5,
    'option-3:500000:36': 563.64,
    'option-3:50000:33': 55.0,
    'option-3:65000:18': 51.38,
    'option-4:80000:52': 524.6,
    'option-4:75000:56': 691.84,
    'option-1:85000:42': 127.34,
    'option-3:95000:34': 91.65,
    'option-3:450000:25': 271.3,
    'option-2:45000:51': 285.38,
    'option-3:15000:14': 23.45,
    'option-2:500000:16': 261.85,
    'option-1:200000:38': 189.66,
    'option-3:450000:22': 271.3,
    'option-2:60000:32': 72.47,
    'option-2:95000:28': 79.17,
    'option-4:15000:47': 72.58,
    'option-3:450000:60': 2083.57,
    'option-2:10000:28': 22.01,
    'option-4:30000:51': 197.67,
    'option-4:250000:25': 190.05,
    'option-4:45000:51': 295.52,
    'option-1:95000:54': 307.03,
    'option-1:5000:5': 17.45,
    'option-3:450000:55': 1529.33,
    'option-2:400000:53': 2525.0,
    'option-3:75000:23': 56.91,
    'option-4:100000:48': 429.09,
    'option-1:400000:57': 1755.82,
    'option-1:5000:54': 30.18,
    'option-1:40000:10': 29.11,
    'option-3:250000:19': 155.61,
    'option-3:40000:15': 37.26,
    'option-3:400000:48': 934.29,
    'option-1:150000:50': 322.77,
    'option-1:50000:23': 32.6,
    'option-4:70000:22': 63.65,
    'option-4:55000:11': 53.09,
    'option-3:80000:8': 59.74,
    'option-1:100000:57': 442.63,
    'option-3:45000:50': 114.36,
    'option-1:300000:42': 419.16,
    'option-2:35000:52': 222.68,
    'option-3:45000:43': 82.88,
    'option-3:90000:9': 65.4,
    'option-3:55000:0': 45.73,
    'option-3:300000:54': 1023.69,
    'option-1:65000:41': 100.41,
    'option-3:25000:8': 28.78,
    'option-2:60000:17': 44.16,
    'option-2:85000:20': 56.29,
    'option-1:300000:13': 122.59,
    'option-2:40000:29': 41.71,
    'option-2:90000:46': 367.68,
    'option-3:25000:3': 28.78,
    'option-1:20000:51': 71.83,
    'option-4:100000:2': 84.65,
    'option-3:350000:26': 250.95,
    'option-4:85000:45': 238.37,
    'option-3:55000:51': 194.24,
    'option-2:200000:35': 209.63,
    'option-4:35000:60': 320.93,
    'option-4:50000:43': 144.26,
    'option-1:35000:27': 30.74,
    'option-2:75000:36': 126.22,
    'option-4:5000:35': 21.31,
    'option-4:65000:38': 124.65,
    'option-1:500000:41': 688.25,
    'option-3:15000:1': 23.45,
    'option-2:30000:20': 29.49,
    'option-3:70000:6': 54.09,
    'option-4:450000:54': 2942.5,
    'option-2:95000:37': 156.24,
    'option-1:90000:42': 133.8,
    'option-1:50000:38': 57.87,
    'option-3:400000:23': 242.84,
    'option-3:250000:54': 850.91,
    'option-2:50000:27': 48.55,
    'option-3:65000:27': 57.86,
    'option-1:100000:14': 50.02,
    'option-3:80000:40': 101.14,
    'option-4:450000:45': 1220.48,
    'option-4:250000:31': 311.33,
    'option-1:15000:16': 20.58,
    'option-2:55000:4': 41.71,
    'option-3:5000:14': 18.47,
    'option-4:25000:15': 31.94,
    'option-4:300000:37': 534.06,
    'option-2:10000:40': 29.44,
    'option-1:25000:40': 35.93,
    'option-1:250000:59': 1095.56,
    'option-3:25000:41': 51.6,
    'option-2:45000:18': 36.83,
    'option-2:30000:1': 29.49,
    'option-3:400000:29': 283.75,
    'option-2:500000:46': 2011.11,
    'option-2:95000:0': 61.19,
    'option-1:150000:22': 67.5,
    'option-1:40000:36': 49.07,
    'option-3:150000:26': 114.5,
    'option-2:85000:4': 56.29,
    'option-2:10000:42': 37.93,
    'option-2:300000:27': 221.94,
    'option-1:10000:20': 19.02,
    'option-4:80000:36': 150.64,
    'option-2:150000:39': 239.97,
    'option-1:20000:30': 23.95,
    'option-3:300000:16': 186.71,
    'option-3:250000:49': 584.96,
    'option-3:5000:12': 18.47,
    'option-3:150000:0': 99.29,
    'option-1:200000:48': 426.5,
    'option-3:300000:3': 186.71,
    'option-3:450000:9': 271.3,
    'option-3:500000:47': 1163.55,
    'option-1:55000:13': 34.28,
    'option-1:350000:2': 140.14,
    'option-2:55000:8': 41.71,
    'option-4:65000:22': 60.13,
    'option-2:25000:8': 27.04,
    'option-2:65000:25': 46.5,
    'option-3:250000:23': 155.61,
    'option-1:50000:11': 32.6,
    'option-2:200000:42': 503.84,
    'option-4:350000:32': 434.15,
    'option-3:200000:34': 177.65,
    'option-4:10000:39': 31.39,
    'option-3:15000:53': 61.16,
    'option-4:40000:2': 42.52,
    'option-2:30000:48': 127.29,
    'option-3:55000:27': 51.18,
    'option-1:500000:56': 2190.49,
    'option-4:50000:27': 58.86,
    'option-2:400000:54': 2525.0,
    'option-3:500000:27': 350.91,
    'option-1:90000:36': 92.84,
    'option-2:35000:8': 31.93,
    'option-4:200000:42': 547.26,
    'option-2:90000:38': 148.66,
    'option-2:100000:54': 630.83,
    'option-4:70000:2': 63.65,
    'option-4:450000:47': 1912.0,
    'option-4:95000:3': 81.12,
    'option-4:40000:32': 61.03,
    'option-3:55000:48': 137.41,
    'option-2:500000:58': 4505.25,
    'option-2:75000:49': 307.57,
    'option-3:35000:60': 168.13,
    'option-1:50000:31': 44.66,
    'option-1:55000:5': 34.28,
    'option-1:250000:4': 102.34,
    'option-2:30000:10': 29.49,
    'option-4:35000:20': 39.0,
    'option-3:30000:31': 38.62,
    'option-3:50000:15': 42.91,
    'option-2:65000:23': 46.5,
    'option-4:75000:33': 102.88,
    'option-3:50000:14': 42.91,
    'option-1:20000:38': 31.54,
    'option-3:15000:51': 61.16,
    'option-2:50000:50': 207.33,
    'option-2:250000:53': 1573.4,
    'option-4:450000:49': 1912.0,
    'option-1:20000:31': 26.6,
    'option-2:50000:16': 39.27,
    'option-3:75000:53': 261.83,
    'option-2:70000:39': 118.63,
    'option-2:65000:55': 411.59,
    'option-2:70000:24': 48.94,
    'option-1:100000:22': 50.02,
    'option-3:45000:49': 114.36,
    'option-2:15000:15': 22.43,
    'option-2:95000:26': 79.17,
    'option-4:40000:59': 367.37,
    'option-3:50000:58': 237.2,
    'option-2:30000:45': 84.46,
    'option-3:95000:5': 68.23,
    'option-2:300000:52': 1893.38,
    'option-1:300000:17': 122.59,
    'option-1:300000:44': 419.16,
    'option-1:5000:48': 25.29,
    'option-2:75000:17': 51.39,
    'option-4:25000:28': 36.41,
    'option-1:75000:21': 41.27,
    'option-4:90000:35': 120.54,
    'option-4:350000:18': 263.51,
    'option-2:450000:52': 2836.41,
    'option-2:95000:34': 106.57,
    'option-1:300000:30': 153.23,
    'option-4:45000:56': 413.16,
    'option-1:35000:28': 30.74,
    'option-1:350000:55': 1111.99,
    'option-1:100000:10': 50.02,
    'option-1:200000:58': 879.72,
    'option-3:400000:35': 344.01,
    'option-1:5000:7': 17.45,
    'option-2:450000:49': 1810.35,
    'option-1:30000:38': 40.28,
    'option-3:400000:8': 242.84,
    'option-4:50000:6': 49.57,
    'option-2:70000:28': 62.22,
    'option-2:60000:53': 380.06,
    'option-1:75000:54': 244.83,
    'option-3:85000:6': 62.57,
    'option-1:500000:2': 192.42,
    'option-2:15000:26': 25.04,
    'option-2:90000:31': 101.66,
    'option-2:25000:6': 27.04,
    'option-3:50000:46': 125.89,
    'option-4:85000:12': 74.07,
    'option-1:500000:5': 192.42,
    'option-4:95000:51': 621.34,
    'option-2:450000:20': 237.36,
    'option-4:90000:23': 77.6,
    'option-4:30000:28': 40.86,
    'option-2:60000:59': 537.8,
    'option-1:10000:19': 19.02,
    'option-4:450000:19': 333.6,
    'option-2:95000:50': 386.9,
    'option-3:35000:2': 34.43,
    'option-4:90000:53': 590.0,
    'option-4:45000:60': 413.16,
    'option-4:65000:57': 599.04,
    'option-4:95000:37': 176.25,
    'option-2:100000:47': 406.91,
    'option-4:25000:22': 31.94,
    'option-4:70000:60': 645.44,
    'option-1:75000:38': 79.84,
    'option-2:35000:60': 312.52,
    'option-1:75000:35': 59.58,
    'option-4:5000:50': 35.14,
    'option-4:40000:24': 42.52,
    'option-2:45000:39': 80.69,
    'option-1:45000:3': 30.86,
    'option-1:10000:14': 19.02,
    'option-4:200000:21': 155.14,
    'option-2:40000:5': 34.38,
    'option-3:500000:29': 350.91,
    'option-3:95000:12': 68.23,
    'option-3:55000:9': 45.73,
    'option-3:200000:20': 127.59,
    'option-1:75000:23': 41.27,
    'option-4:95000:25': 81.12,
    'option-4:40000:16': 42.52,
    'option-1:30000:53': 103.36,
    'option-2:85000:39': 141.39,
    'option-1:40000:18': 29.11,
    'option-1:30000:57': 138.04,
    'option-4:75000:23': 67.02,
    'option-4:80000:32': 108.85,
    'option-3:40000:28': 41.16,
    'option-4:75000:31': 102.88,
    'option-4:80000:3': 70.54,
    'option-3:350000:0': 215.07,
    'option-3:150000:14': 99.29,
    'option-4:150000:32': 192.49,
    'option-1:100000:51': 322.79,
    'option-1:70000:0': 39.52,
    'option-4:25000:30': 36.41,
    'option-4:5000:52': 45.61,
    'option-1:95000:17': 48.27,
    'option-4:15000:10': 25.34,
    'option-4:70000:37': 133.31,
    'option-2:90000:1': 58.74,
    'option-2:50000:26': 48.55,
    'option-4:20000:41': 63.66,
    'option-2:450000:55': 2836.41,
    'option-2:30000:12': 29.49,
    'option-1:15000:47': 43.69,
    'option-2:70000:42': 183.13,
    'option-3:100000:0': 71.05,
    'option-4:35000:14': 39.0,
    'option-2:10000:24': 20.26,
    'option-3:10000:54': 46.19,
    'option-1:25000:30': 26.21,
    'option-3:20000:6': 25.96,
    'option-1:95000:5': 48.27,
    'option-1:75000:24': 41.27,
    'option-1:30000:21': 25.62,
    'option-1:30000:18': 25.62,
    'option-2:5000:47': 34.06,
    'option-4:300000:38': 534.06,
    'option-2:30000:22': 29.49,
    'option-4:500000:59': 4632.84,
    'option-1:45000:34': 41.65,
    'option-2:65000:8': 46.5,
    'option-4:450000:24': 333.6,
    'option-3:350000:46': 819.06,
    'option-4:25000:59': 228.29,
    'option-4:100000:4': 84.65,
    'option-2:50000:46': 207.33,
    'option-4:500000:21': 368.87,
    'option-1:70000:8': 39.52,
    'option-2:500000:50': 2011.11,
    'option-1:75000:49': 167.06,
    'option-3:30000:30': 34.47,
    'option-4:70000:0': 63.65,
    'option-4:25000:55': 164.78,
    'option-4:35000:51': 230.46,
    'option-2:60000:37': 103.46,
    'option-3:25000:16': 28.78,
    'option-3:400000:55': 1362.29,
    'option-4:400000:60': 3709.1,
    'option-1:5000:9': 17.45,
    'option-1:250000:22': 102.34,
    'option-3:10000:58': 58.04,
    'option-2:100000:58': 896.74,
    'option-4:400000:32': 493.98,
    'option-3:30000:23': 31.61,
    'option-3:20000:58': 98.96,
    'option-3:5000:20': 18.47,
    'option-4:20000:36': 46.71,
    'option-4:35000:26': 45.36,
    'option-4:45000:33': 67.01,
    'option-2:70000:55': 443.12,
    'option-1:30000:16': 25.62,
    'option-1:55000:56': 247.09,
    'option-4:85000:3': 74.07,
    'option-4:25000:52': 164.78,
    'option-2:40000:54': 254.22,
    'option-1:400000:5': 157.41,
    'option-2:400000:5': 212.75,
    'option-3:60000:30': 54.52,
    'option-3:90000:59': 420.89,
    'option-3:85000:21': 62.57,
    'option-3:50000:43': 90.72,
    'option-3:55000:55': 194.24,
    'option-1:45000:8': 30.86,
    'option-4:30000:23': 35.47,
    'option-3:65000:16': 51.38,
    'option-4:85000:1': 74.07,
    'option-4:50000:23': 49.57,
    'option-3:30000:25': 31.61,
    'option-3:450000:40': 508.76,
    'option-4:40000:42': 117.28,
    'option-3:75000:1': 56.91,
    'option-2:35000:58': 312.52,
    'option-2:100000:44': 256.67,
    'option-4:30000:46': 133.61,
    'option-2:20000:43': 59.74,
    'option-1:10000:1': 19.02,
    'option-4:95000:60': 875.45,
    'option-2:300000:28': 221.94,
    'option-1:25000:2': 23.87,
    'option-3:70000:38': 90.18,
    'option-1:100000:21': 50.02,
    'option-2:30000:5': 29.49,
    'option-1:10000:41': 27.93,
    'option-1:40000:41': 66.72,
    'option-4:400000:59': 3709.1,
    'option-1:35000:47': 84.04,
    'option-4:25000:31': 43.15,
    'option-1:400000:41': 554.19,
    'option-2:65000:21': 46.5,
    'option-2:100000:59': 896.74,
    'option-1:200000:13': 84.98,
    'option-4:40000:12': 42.52,
    'option-3:200000:31': 177.65,
    'option-4:65000:19': 60.13,
    'option-4:90000:60': 831.0,
    'option-1:40000:43': 66.72,
    'option-4:300000:24': 228.15,
    'option-1:80000:29': 51.01,
    'option-1:85000:23': 44.77,
    'option-3:15000:36': 30.55,
    'option-1:5000:46': 25.29,
    'option-4:10000:56': 99.65,
    'option-4:30000:60': 274.41,
    'option-1:5000:56': 36.54,
    'option-1:55000:55': 181.91,
    'option-3:400000:49': 934.29,
    'option-1:450000:35': 287.92,
    'option-3:45000:39': 62.78,
    'option-1:300000:4': 122.59,
    'option-3:35000:50': 91.44,
    'option-3:400000:31': 344.01,
    'option-3:100000:54': 345.58,
    'option-4:40000:31': 61.03,
    'option-4:65000:15': 60.13,
    'option-2:85000:57': 763.38,
    'option-2:80000:47': 327.61,
    'option-1:350000:54': 1111.99,
    'option-4:85000:5': 74.07,
    'option-4:65000:9': 60.13,
    'option-3:200000:52': 683.85,
    'option-1:400000:48': 844.64,
    'option-1:15000:53': 58.03,
    'option-2:10000:4': 20.26,
    'option-4:450000:39': 793.57,
    'option-2:15000:48': 69.54,
    'option-4:300000:30': 286.02,
    'option-2:500000:15': 261.85,
    'option-1:75000:34': 59.58,
    'option-4:75000:15': 67.02,
    'option-3:85000:29': 71.09,
    'option-4:45000:35': 67.01,
    'option-3:55000:10': 45.73,
    'option-2:150000:31': 160.34,
    'option-3:65000:3': 51.38,
    'option-4:75000:41': 211.49,
    'option-2:300000:41': 752.86,
    'option-3:55000:44': 98.56,
    'option-4:150000:51': 982.15,
    'option-1:85000:4': 44.77,
    'option-1:100000:12': 50.02,
    'option-3:40000:17': 37.26,
    'option-1:50000:55': 166.17,
    'option-2:55000:45': 146.09,
    'option-4:70000:29': 76.83,
    'option-1:60000:43': 93.68,
    'option-1:95000:27': 57.8,
    'option-1:50000:56': 225.23,
    'option-1:5000:16': 17.45,
    'option-3:30000:10': 31.61,
    'option-2:50000:58': 447.49,
    'option-2:75000:58': 673.17,
    'option-1:30000:7': 25.62,
    'option-2:90000:49': 367.68,
    'option-3:80000:33': 79.37,
    'option-2:95000:11': 61.19,
    'option-4:100000:37': 184.93,
    'option-4:90000:32': 120.54,
    'option-4:50000:34': 72.99,
    'option-3:35000:23': 34.43,
    'option-3:80000:37': 101.14,
    'option-1:60000:11': 36.02,
    'option-2:50000:30': 48.55,
    'option-4:85000:31': 114.58,
    'option-2:400000:38': 621.91,
    'option-2:45000:54': 285.38,
    'option-3:85000:35': 83.46,
    'option-4:450000:12': 333.6,
    'option-2:200000:19': 112.59,
    'option-1:400000:8': 157.41,
    'option-4:35000:48': 154.83,
    'option-1:500000:11': 192.42,
    'option-4:95000:58': 875.45,
    'option-4:95000:12': 81.12,
    'option-2:300000:50': 1210.68,
    'option-4:95000:22': 81.12,
    'option-2:200000:40': 315.75,
    'option-2:5000:19': 18.11,
    'option-2:70000:26': 62.22,
    'option-1:450000:5': 174.93,
    'option-3:65000:58': 306.49,
    'option-3:450000:16': 271.3,
    'option-3:400000:16': 242.84,
    'option-1:350000:51': 1111.99,
    'option-3:45000:45': 82.88,
    'option-1:25000:31': 29.6,
    'option-4:450000:41': 1220.48,
    'option-2:5000:7': 18.11,
    'option-2:35000:2': 31.93,
    'option-3:500000:28': 350.91,
    'option-1:55000:0': 34.28,
    'option-2:200000:60': 1799.98,
    'option-4:90000:52': 590.0,
    'option-1:65000:34': 53.69,
    'option-3:50000:23': 42.91,
    'option-1:20000:50': 52.84,
    'option-3:250000:35': 218.18,
    'option-4:250000:50': 1064.89,
    'option-3:95000:14': 68.23,
    'option-3:70000:12': 54.09,
    'option-4:50000:36': 98.64,
    'option-2:65000:2': 46.5,
    'option-1:55000:42': 86.94,
    'option-3:25000:51': 92.89,
    'option-1:200000:57': 879.72,
    'option-4:60000:54': 393.77,
    'option-4:95000:54': 621.34,
    'option-1:40000:48': 94.31,
    'option-3:10000:3': 20.97,
    'option-1:35000:36': 44.67,
    'option-1:80000:9': 43.02,
    'option-3:40000:16': 37.26,
    'option-4:25000:47': 112.34,
    'option-3:200000:18': 127.59,
    'option-4:80000:14': 70.54,
    'option-4:250000:15': 190.05,
    'option-3:200000:40': 232.57,
    'option-2:25000:50': 107.16,
    'option-2:400000:8': 212.75,
    'option-1:45000:59': 203.38,
    'option-4:20000:24': 28.43,
    'option-1:15000:3': 20.58,
    'option-2:75000:57': 673.17,
    'option-2:20000:46': 87.32,
    'option-1:200000:17': 84.98,
    'option-1:250000:12': 102.34,
    'option-2:100000:17': 63.64,
    'option-2:65000:44': 170.79,
    'option-3:100000:34': 95.75,
    'option-3:30000:13': 31.61,
    'option-2:200000:41': 503.84,
    'option-4:95000:29': 99.13,
    'option-2:80000:39': 133.8,
    'option-2:150000:14': 88.1,
    'option-3:45000:22': 40.08,
    'option-4:450000:27': 420.63,
    'option-4:15000:17': 25.34,
    'option-1:85000:46': 187.83,
    'option-4:250000:11': 190.05,
    'option-2:90000:55': 569.17,
    'option-4:90000:6': 77.6,
    'option-3:450000:32': 384.52,
    'option-3:40000:55': 143.36,
    'option-2:300000:18': 164.12,
    'option-1:400000:14': 157.41,
    'option-2:100000:41': 256.67,
    'option-3:85000:13': 62.57,
    'option-3:35000:6': 34.43,
    'option-3:15000:32': 26.85,
    'option-1:65000:7': 37.77,
    'option-2:75000:37': 126.22,
    'option-3:250000:55': 850.91,
    'option-2:70000:33': 82.27,
    'option-3:35000:41': 67.15,
    'option-4:250000:21': 190.05,
    'option-1:65000:5': 37.77,
    'option-1:85000:27': 53.27,
    'option-3:25000:19': 28.78,
    'option-1:500000:28': 243.64,
    'option-2:95000:5': 61.19,
    'option-2:65000:41': 170.79,
    'option-1:350000:35': 227.99,
    'option-1:5000:59': 36.54,
    'option-4:25000:0': 31.94,
    'option-4:250000:57': 2309.26,
    'option-1:400000:46': 844.64,
    'option-3:50000:12': 42.91,
    'option-1:65000:40': 71.05,
    'option-2:350000:45': 876.7,
    'option-3:20000:31': 30.48,
    'option-1:450000:22': 174.93,
    'option-1:250000:15': 102.34,
    'option-2:80000:53': 506.15,
    'option-1:50000:6': 32.6,
    'option-1:25000:29': 26.21,
    'option-2:350000:0': 188.68,
    'option-3:90000:22': 65.4,
    'option-3:10000:29': 21.89,
    'option-1:95000:20': 48.27,
    'option-2:200000:43': 503.84,
    'option-4:70000:10': 63.65,
    'option-2:50000:7': 39.27,
    'option-1:10000:2': 19.02,
    'option-4:10000:11': 22.21,
    'option-4:40000:54': 263.19,
    'option-4:90000:29': 94.62,
    'option-3:500000:52': 1698.69,
    'option-2:80000:22': 53.84,
    'option-2:35000:53': 222.68,
    'option-4:200000:19': 155.14,
    'option-3:400000:26': 283.75,
    'option-2:200000:44': 503.84,
    'option-1:80000:49': 177.45,
    'option-4:400000:4': 298.07,
    'option-3:10000:44': 29.91,
    'option-4:450000:56': 4167.41,
    'option-1:350000:16': 140.14,
    'option-1:65000:42': 100.41,
    'option-2:450000:12': 237.36,
    'option-1:80000:12': 43.02,
    'option-3:350000:37': 399.67,
    'option-4:80000:34': 108.85,
    'option-4:85000:39': 159.31,
    'option-1:15000:18': 20.58,
    'option-4:450000:15': 333.6,
    'option-4:450000:6': 333.6,
    'option-1:250000:60': 1095.56,
    'option-4:45000:58': 413.16,
    'option-1:55000:12': 34.28,
    'option-2:450000:37': 696.98,
    'option-3:85000:22': 62.57,
    'option-2:10000:29': 22.01,
    'option-1:80000:0': 43.02,
    'option-1:350000:57': 1537.32,
    'option-2:85000:24': 56.29,
    'option-2:5000:21': 18.11,
    'option-2:30000:59': 267.29,
    'option-4:85000:36': 159.31,
    'option-4:30000:57': 274.41,
    'option-1:500000:0': 192.42,
    'option-2:65000:12': 46.5,
    'option-4:5000:45': 28.05,
    'option-1:150000:60': 661.69,
    'option-2:25000:24': 27.04,
    'option-4:75000:16': 67.02,
    'option-4:500000:37': 880.37,
    'option-2:15000:29': 25.04,
    'option-2:90000:6': 58.74,
    'option-3:90000:53': 311.79,
    'option-4:400000:0': 298.07,
    'option-3:200000:15': 127.59,
    'option-4:150000:42': 413.01,
    'option-4:20000:45': 63.66,
    'option-4:50000:5': 49.57,
    'option-3:75000:10': 56.91,
    'option-2:300000:15': 164.12,
    'option-4:65000:11': 60.13,
    'option-4:30000:56': 274.41,
    'option-1:20000:2': 22.13,
    'option-2:55000:0': 41.71,
    'option-4:10000:12': 22.21,
    'option-2:50000:10': 39.27,
    'option-2:95000:30': 79.17,
    'option-4:400000:15': 298.07,
    'option-1:400000:43': 554.19,
    'option-4:40000:5': 42.52,
    'option-1:150000:41': 214.84,
    'option-2:500000:51': 3152.52,
    'option-4:45000:25': 46.04,
    'option-2:35000:39': 65.45,
    'option-2:80000:10': 53.84,
    'option-2:35000:20': 31.93,
    'option-1:10000:44': 27.93,
    'option-3:35000:18': 34.43,
    'option-1:500000:25': 192.42,
    'option-4:65000:32': 90.92,
    'option-4:70000:24': 63.65,
    'option-4:25000:3': 31.94,
    'option-4:10000:51': 74.49,
    'option-2:30000:3': 29.49,
    'option-3:25000:6': 28.78,
    'option-1:500000:32': 318.09,
    'option-1:5000:41': 21.98,
    'option-3:80000:3': 59.74,
    'option-1:450000:45': 620.81,
    'option-1:30000:17': 25.62,
    'option-2:250000:37': 390.8,
    'option-2:5000:3': 18.11,
    'option-4:5000:17': 19.1,
    'option-2:35000:4': 31.93,
    'option-2:400000:47': 1612.3,
    'option-2:95000:43': 244.35,
    'option-2:450000:53': 2836.41,
    'option-2:30000:52': 191.08,
    'option-4:25000:48': 112.34,
    'option-4:75000:5': 67.02,
    'option-3:300000:26': 217.4,
    'option-2:25000:47': 107.16,
    'option-1:150000:14': 67.5,
    'option-3:85000:50': 206.5,
    'option-1:95000:57': 420.75,
    'option-4:70000:16': 63.65,
    'option-1:45000:5': 30.86,
    'option-2:5000:54': 44.52,
    'option-1:85000:60': 378.08,
    'option-4:150000:46': 641.57,
    'option-4:100000:49': 429.09,
    'option-3:65000:55': 228.04,
    'option-1:60000:39': 66.66,
    'option-2:75000:14': 51.39,
    'option-3:450000:38': 508.76,
    'option-3:20000:30': 27.79,
    'option-4:250000:59': 2309.26,
    'option-1:45000:46': 104.72,
    'option-2:250000:29': 184.9,
    'option-4:300000:46': 1278.33,
    'option-4:95000:46': 407.95,
    'option-3:40000:60': 191.23,
    'option-2:350000:55': 2209.54,
    'option-1:150000:37': 145.49,
    'option-1:30000:60': 138.04,
    'option-2:60000:22': 44.16,
    'option-2:200000:45': 503.84,
    'option-2:250000:7': 136.85,
    'option-3:400000:50': 934.29,
    'option-4:400000:52': 2619.38,
    'option-1:300000:56': 1318.3,
    'option-2:55000:39': 95.87,
    'option-2:300000:11': 164.12,
    'option-2:60000:19': 44.16,
    'option-1:250000:23': 102.34,
    'option-1:15000:20': 20.58,
    'option-2:30000:18': 29.49,
    'option-2:150000:12': 88.1,
    'option-1:40000:6': 29.11,
    'option-1:400000:23': 157.41,
    'option-1:55000:20': 34.28,
    'option-3:50000:21': 42.91,
    'option-3:10000:28': 21.89,
    'option-1:30000:14': 25.62,
    'option-2:70000:59': 628.06,
    'option-3:20000:39': 35.42,
    'option-4:5000:56': 58.73,
    'option-4:200000:40': 358.47,
    'option-2:150000:28': 116.73,
    'option-3:300000:15': 186.71,
    'option-1:60000:42': 93.68,
    'option-4:40000:50': 176.02,
    'option-3:70000:50': 171.96,
    'option-4:45000:47': 196.99,
    'option-2:10000:20': 20.26,
    'option-1:250000:49': 529.22,
    'option-2:55000:16': 41.71,
    'option-3:100000:8': 71.05,
    'option-4:5000:18': 19.1,
    'option-2:80000:20': 53.84,
    'option-1:85000:30': 53.27,
    'option-4:150000:45': 413.01,
    'option-3:400000:17': 242.84,
    'option-2:25000:29': 31.45,
    'option-3:100000:13': 71.05,
    'option-1:500000:19': 192.42,
    'option-1:5000:13': 17.45,
    'option-2:80000:13': 53.84,
    'option-2:45000:16': 36.83,
    'option-4:500000:45': 1355.18,
    'option-4:20000:39': 46.71,
    'option-3:60000:50': 148.93,
    'option-3:450000:49': 1048.19,
    'option-2:450000:29': 324.29,
    'option-4:65000:20': 60.13,
    'option-4:65000:2': 60.13,
    'option-1:350000:29': 175.95,
    'option-3:500000:45': 797.55,
    'option-2:75000:55': 474.63,
    'option-1:45000:28': 35.26,
    'option-4:10000:40': 31.39,
    'option-1:10000:45': 27.93,
    'option-2:250000:57': 2245.85,
    'option-3:30000:4': 31.61,
    'option-1:200000:53': 637.67,
    'option-4:85000:27': 90.12,
    'option-2:70000:18': 48.94,
    'option-3:100000:43': 168.74,
    'option-2:85000:1': 56.29,
    'option-2:65000:56': 582.94,
    'option-3:20000:17': 25.96,
    'option-4:55000:4': 53.09,
    'option-4:500000:49': 2124.34,
    'option-1:500000:14': 192.42,
    'option-4:60000:56': 552.62,
    'option-1:25000:26': 26.21,
    'option-2:400000:41': 1000.28,
    'option-2:200000:23': 112.59,
    'option-3:70000:24': 54.09,
    'option-4:30000:40': 64.05,
    'option-2:450000:41': 1122.36,
    'option-3:55000:14': 45.73,
    'option-2:60000:54': 380.06,
    'option-3:55000:50': 137.41,
    'option-4:55000:10': 53.09,
    'option-2:150000:25': 88.1,
    'option-1:75000:55': 244.83,
    'option-3:450000:2': 271.3,
    'option-2:60000:46': 247.45,
    'option-3:95000:1': 68.23,
    'option-3:40000:33': 46.81,
    'option-3:95000:10': 68.23,
    'option-2:300000:56': 2702.97,
    'option-4:20000:57': 181.91,
    'option-3:90000:29': 74.43,
    'option-2:15000:40': 36.09,
    'option-4:45000:36': 89.97,
    'option-1:450000:15': 174.93,
    'option-2:25000:13': 27.04,
    'option-2:50000:45': 133.74,
    'option-4:150000:4': 119.82,
    'option-4:100000:44': 278.1,
    'option-4:40000:57': 367.37,
    'option-3:250000:37': 286.82,
    'option-2:55000:58': 492.66,
    'option-1:65000:43': 100.41,
    'option-1:400000:49': 844.64,
    'option-1:75000:50': 167.06,
    'option-1:25000:38': 35.93,
    'option-2:55000:21': 41.71,
    'option-2:85000:55': 537.66,
    'option-2:20000:22': 24.6,
    'option-1:45000:39': 53.47,
    'option-3:25000:30': 31.12,
    'option-4:50000:21': 49.57,
    'option-1:95000:40': 97.25,
    'option-1:45000:17': 30.86,
    'option-4:80000:22': 70.54,
    'option-4:100000:1': 84.65,
    'option-1:55000:53': 181.91,
    'option-3:450000:50': 1048.19,
    'option-4:100000:5': 84.65,
    'option-3:40000:53': 143.36,
    'option-3:75000:4': 56.91,
    'option-1:350000:25': 140.14,
    'option-2:70000:6': 48.94,
    'option-3:65000:23': 51.38,
    'option-4:300000:0': 228.15,
    'option-4:100000:45': 278.1,
    'option-1:60000:33': 50.68,
    'option-2:100000:5': 63.64,
    'option-2:35000:46': 147.38,
    'option-2:20000:48': 87.32,
    'option-2:15000:50': 69.54,
    'option-4:55000:28': 63.35,
    'option-3:35000:52': 126.78,
    'option-3:250000:57': 1157.45,
    'option-1:15000:0': 20.58,
    'option-1:35000:10': 27.36,
    'option-3:40000:1': 37.26,
    'option-4:450000:40': 793.57,
    'option-4:45000:30': 54.36,
    'option-1:25000:27': 26.21,
    'option-4:80000:16': 70.54,
    'option-1:450000:60': 1971.6,
    'option-4:35000:39': 72.72,
    'option-1:25000:23': 23.87,
    'option-2:350000:36': 545.96,
    'option-4:200000:8': 155.14,
    'option-1:350000:19': 140.14,
    'option-4:150000:10': 119.82,
    'option-1:45000:20': 30.86,
    'option-3:35000:1': 34.43,
    'option-3:400000:52': 1362.29,
    'option-4:400000:48': 1702.7,
    'option-1:65000:48': 146.29,
    'option-1:75000:4': 41.27,
    'option-1:150000:32': 104.75,
    'option-4:50000:22': 49.57,
    'option-3:350000:35': 303.08,
    'option-1:85000:43': 127.34,
    'option-2:80000:3': 53.84,
    'option-3:95000:18': 68.23,
    'option-3:500000:3': 299.59,
    'option-1:10000:11': 19.02,
    'option-3:200000:26': 148.0,
    'option-2:35000:13': 31.93,
    'option-1:10000:43': 27.93,
    'option-2:500000:4': 261.85,
    'option-2:100000:16': 63.64,
    'option-3:450000:10': 271.3,
    'option-3:20000:21': 25.96,
    'option-4:400000:42': 1087.59,
    'option-3:450000:34': 384.52,
    'option-1:450000:53': 1424.9,
    'option-3:75000:60': 352.66,
    'option-3:60000:22': 48.56,
    'option-4:30000:14': 35.47,
    'option-2:5000:23': 18.11,
    'option-1:20000:3': 22.13,
    'option-4:90000:48': 387.64,
    'option-2:5000:14': 18.11,
    'option-3:300000:50': 703.53,
    'option-1:55000:50': 125.51,
    'option-4:15000:53': 103.44,
    'option-3:300000:28': 217.4,
    'option-3:450000:13': 271.3,
    'option-1:65000:1': 37.77,
    'option-2:55000:2': 41.71,
    'option-1:10000:46': 34.47,
    'option-3:15000:45': 36.79,
    'option-3:40000:30': 41.16,
    'option-4:350000:6': 263.51,
    'option-1:10000:48': 34.47,
    'option-3:80000:22': 59.74,
    'option-3:5000:17': 18.47,
    'option-2:30000:49': 127.29,
    'option-3:15000:55': 61.16,
    'option-4:10000:14': 22.21,
    'option-1:350000:33': 227.99,
    'option-3:40000:47': 102.8,
    'option-1:95000:7': 48.27,
    'option-1:150000:57': 661.69,
    'option-4:75000:2': 67.02,
    'option-1:85000:33': 65.61,
    'option-2:25000:14': 27.04,
    'option-1:75000:9': 41.27,
    'option-4:60000:21': 56.61,
    'option-4:55000:19': 53.09,
    'option-1:45000:49': 104.72,
    'option-4:45000:13': 46.04,
    'option-2:25000:3': 27.04,
    'option-2:30000:42': 84.46,
    'option-2:95000:2': 61.19,
    'option-2:25000:41': 72.08,
    'option-3:150000:49': 356.08,
    'option-4:20000:43': 63.66,
    'option-2:20000:32': 33.32,
    'option-1:400000:55': 1269.39,
    'option-4:60000:44': 171.16,
    'option-3:80000:13': 59.74,
    'option-2:500000:28': 358.54,
    'option-4:450000:43': 1220.48,
    'option-4:70000:13': 63.65,
    'option-3:85000:10': 62.57,
    'option-4:65000:49': 281.76,
    'option-4:250000:19': 190.05,
    'option-4:300000:31': 374.14,
    'option-2:90000:30': 75.75,
    'option-2:10000:27': 22.01,
    'option-2:40000:19': 34.38,
    'option-2:35000:5': 31.93,
    'option-3:250000:60': 1157.45,
    'option-3:30000:8': 31.61,
    'option-3:85000:0': 62.57,
    'option-4:95000:1': 81.12,
    'option-3:25000:54': 92.89,
    'option-3:10000:19': 20.97,
    'option-4:50000:38': 98.64,
    'option-1:60000:60': 268.93,
    'option-1:15000:37': 27.64,
    'option-2:65000:27': 58.8,
    'option-2:70000:8': 48.94,
    'option-4:15000:6': 25.34,
    'option-4:300000:49': 1278.33,
    'option-4:75000:49': 324.12,
    'option-1:55000:28': 39.78,
    'option-1:55000:11': 34.28,
    'option-1:15000:8': 20.58,
    'option-3:60000:25': 48.56,
    'option-3:30000:7': 31.61,
    'option-1:50000:28': 37.52,
    'option-3:45000:30': 44.5,
    'option-2:75000:54': 474.63,
    'option-4:15000:2': 25.34,
    'option-1:60000:48': 135.9,
    'option-4:75000:3': 67.02,
    'option-4:150000:50': 641.57,
    'option-1:100000:36': 101.65,
    'option-1:25000:25': 23.87,
    'option-2:350000:56': 3155.5,
    'option-2:75000:39': 126.22,
    'option-4:75000:18': 67.02,
    'option-3:500000:41': 797.55,
    'option-3:350000:41': 563.2,
    'option-3:250000:43': 403.04,
    'option-2:350000:53': 2209.54,
    'option-2:70000:16': 48.94,
    'option-3:350000:43': 563.2,
    'option-1:55000:6': 34.28,
    'option-2:5000:46': 34.06,
    'option-3:5000:37': 20.86,
    'option-4:150000:7': 119.82,
    'option-3:80000:24': 59.74,
    'option-4:85000:14': 74.07,
    'option-3:5000:38': 20.86,
    'option-2:5000:42': 27.09,
    'option-4:60000:48': 260.58,
    'option-2:5000:24': 18.11,
    'option-2:150000:11': 88.1,
    'option-4:150000:14': 119.82,
    'option-3:45000:1': 40.08,
    'option-2:500000:43': 1246.05,
    'option-1:40000:44': 66.72,
    'option-3:350000:32': 303.08,
    'option-1:30000:40': 40.28,
    'option-4:250000:0': 190.05,
    'option-4:80000:44': 224.93,
    'option-3:85000:27': 71.09,
    'option-3:450000:1': 271.3,
    'option-2:15000:2': 22.43,
    'option-2:150000:2': 88.1,
    'option-1:450000:36': 411.74,
    'option-4:75000:60': 691.84,
    'option-2:200000:48': 808.11,
    'option-3:250000:30': 181.12,
    'option-2:200000:51': 1261.86,
    'option-4:80000:30': 85.63,
    'option-4:150000:17': 119.82,
    'option-3:500000:57': 2315.03,
    'option-4:450000:17': 333.6,
    'option-4:90000:24': 77.6,
    'option-2:75000:2': 51.39,
    'option-1:15000:40': 27.64,
    'option-1:50000:5': 32.6,
    'option-1:250000:42': 348.72,
    'option-4:15000:1': 25.34,
    'option-3:55000:39': 73.74,
    'option-1:70000:39': 75.45,
    'option-3:60000:41': 106.4,
    'option-2:350000:50': 1411.7,
    'option-2:10000:30': 22.01,
    'option-4:95000:57': 875.45,
    'option-3:150000:60': 698.69,
    'option-4:45000:49': 196.99,
    'option-3:25000:10': 28.78,
    'option-2:150000:15': 88.1,
    'option-1:10000:22': 19.02,
    'option-1:55000:31': 47.67,
    'option-3:90000:25': 65.4,
    'option-4:350000:36': 621.06,
    'option-1:400000:22': 157.41,
    'option-1:150000:28': 82.7,
    'option-3:65000:57': 306.49,
    'option-4:60000:33': 84.95,
    'option-2:250000:28': 184.9,
    'option-3:90000:26': 74.43,
    'option-1:200000:47': 426.5,
    'option-3:150000:58': 698.69,
    'option-2:150000:20': 88.1,
    'option-1:60000:31': 50.68,
    'option-3:350000:28': 250.95,
    'option-4:400000:30': 376.12,
    'option-1:400000:26': 198.24,
    'option-2:100000:26': 82.6,
    'option-3:10000:51': 46.19,
    'option-4:150000:21': 119.82,
    'option-3:400000:21': 242.84,
    'option-4:55000:34': 78.97,
    'option-3:250000:38': 286.82,
    'option-2:25000:57': 222.44,
    'option-4:20000:3': 28.43,
    'option-2:65000:39': 111.04,
    'option-2:35000:6': 31.93,
    'option-3:100000:11': 71.05,
    'option-1:450000:20': 174.93,
    'option-3:95000:59': 443.93,
    'option-4:30000:42': 90.59,
    'option-2:65000:48': 267.49,
    'option-3:75000:8': 56.91,
    'option-3:500000:58': 2315.03,
    'option-2:100000:60': 896.74,
    'option-3:35000:46': 91.44,
    'option-3:450000:7': 271.3,
    'option-4:15000:42': 51.85,
    'option-1:90000:31': 68.63,
    'option-4:5000:27': 20.0,
    'option-3:90000:28': 74.43,
    'option-2:80000:50': 327.61,
    'option-3:5000:15': 18.47,
    'option-1:45000:14': 30.86,
    'option-4:50000:44': 144.26,
    'option-4:500000:44': 1355.18,
    'option-4:65000:55': 426.49,
    'option-4:90000:45': 251.81,
    'option-3:20000:51': 75.98,
    'option-3:450000:51': 1529.33,
    'option-4:35000:16': 39.0,
    'option-3:450000:11': 271.3,
    'option-3:250000:16': 155.61,
    'option-3:80000:59': 375.74,
    'option-3:500000:10': 299.59,
    'option-2:100000:46': 406.91,
    'option-3:200000:33': 177.65,
    'option-3:5000:46': 26.29,
    'option-2:450000:43': 1122.36,
    'option-4:65000:26': 72.34,
    'option-4:75000:9': 67.02,
    'option-4:350000:55': 2292.03,
    'option-1:250000:35': 164.79,
    'option-4:75000:20': 67.02,
    'option-2:250000:55': 1573.4,
    'option-4:450000:36': 793.57,
    'option-1:100000:33': 74.66,
    'option-4:80000:37': 150.64,
    'option-1:40000:57': 181.76,
    'option-2:90000:60': 808.49,
    'option-2:80000:38': 133.8,
    'option-1:55000:46': 125.51,
    'option-4:200000:32': 252.19,
    'option-1:80000:51': 260.55,
    'option-1:15000:19': 20.58,
    'option-4:75000:1': 67.02,
    'option-2:85000:54': 537.66,
    'option-4:300000:16': 228.15,
    'option-4:20000:7': 28.43,
    'option-3:95000:33': 91.65,
    'option-1:75000:5': 41.27,
    'option-3:95000:27': 77.78,
    'option-1:200000:8': 84.98,
    'option-4:45000:10': 46.04,
    'option-3:35000:43': 67.15,
    'option-2:25000:38': 50.41,
    'option-3:300000:25': 186.71,
    'option-3:15000:37': 30.55,
    'option-4:25000:6': 31.94,
    'option-2:20000:59': 177.35,
    'option-1:450000:30': 220.99,
    'option-4:150000:30': 148.46,
    'option-3:5000:13': 18.47,
    'option-1:300000:50': 636.47,
    'option-3:65000:30': 57.86,
    'option-1:300000:57': 1318.3,
    'option-2:250000:50': 1008.58,
    'option-3:500000:51': 1698.69,
    'option-1:85000:22': 44.77,
    'option-2:45000:55': 285.38,
    'option-1:85000:44': 127.34,
    'option-3:50000:35': 55.0,
    'option-1:60000:29': 42.04,
    'option-4:70000:28': 76.83,
    'option-2:70000:4': 48.94,
    'option-3:75000:38': 95.66,
    'option-2:100000:35': 111.48,
    'option-3:25000:11': 28.78,
    'option-4:15000:54': 103.44,
    'option-2:5000:60': 57.62,
    'option-2:15000:60': 137.28,
    'option-1:35000:26': 30.74,
    'option-4:350000:57': 3244.69,
    'option-1:95000:26': 57.8,
    'option-4:450000:2': 333.6,
    'option-1:25000:36': 35.93,
    'option-4:80000:20': 70.54,
    'option-1:5000:24': 17.45,
    'option-4:250000:16': 190.05,
    'option-1:200000:3': 84.98,
    'option-1:350000:58': 1537.32,
    'option-4:300000:34': 374.14,
    'option-1:80000:17': 43.02,
    'option-1:25000:6': 23.87,
    'option-4:80000:59': 738.23,
    'option-2:50000:38': 88.28,
    'option-1:20000:4': 22.13,
    'option-4:70000:46': 302.94,
    'option-2:50000:36': 88.28,
    'option-4:65000:28': 72.34,
    'option-4:400000:31': 493.98,
    'option-3:100000:39': 122.85,
    'option-3:10000:36': 25.66,
    'option-4:55000:49': 239.39,
    'option-1:20000:57': 94.49,
    'option-4:50000:41': 144.26,
    'option-1:55000:22': 34.28,
    'option-1:65000:55': 213.37,
    'option-1:90000:25': 46.52,
    'option-4:25000:39': 55.37,
    'option-4:5000:38': 23.72,
    'option-4:65000:60': 599.04,
    'option-1:10000:36': 23.73,
    'option-1:55000:39': 62.26,
    'option-4:20000:46': 91.37,
    'option-2:80000:49': 327.61,
    'option-4:95000:28': 99.13,
    'option-3:95000:0': 68.23,
    'option-3:45000:0': 40.08,
    'option-1:500000:46': 1051.46,
    'option-3:10000:5': 20.97,
    'option-1:30000:3': 25.62,
    'option-1:80000:55': 260.55,
    'option-1:80000:27': 51.01,
    'option-3:500000:33': 425.48,
    'option-1:450000:21': 174.93,
    'option-2:100000:56': 896.74,
    'option-1:300000:47': 636.47,
    'option-3:70000:45': 122.06,
    'option-2:250000:18': 136.85,
    'option-2:50000:42': 133.74,
    'option-1:300000:26': 153.23,
    'option-3:300000:24': 186.71,
    'option-2:40000:22': 34.38,
    'option-1:35000:12': 27.36,
    'option-1:250000:20': 102.34,
    'option-4:70000:11': 63.65,
    'option-2:70000:0': 48.94,
    'option-2:200000:58': 1799.98,
    'option-4:55000:38': 107.31,
    'option-1:400000:44': 554.19,
    'option-4:55000:43': 157.71,
    'option-3:45000:33': 50.9,
    'option-2:55000:18': 41.71,
    'option-2:400000:10': 212.75,
    'option-4:150000:29': 148.46,
    'option-4:30000:55': 197.67,
    'option-4:15000:29': 27.93,
    'option-4:35000:59': 320.93,
    'option-4:100000:16': 84.65,
    'option-1:15000:43': 33.86,
    'option-3:95000:17': 68.23,
    'option-3:10000:60': 58.04,
    'option-1:450000:55': 1424.9,
    'option-1:90000:35': 68.63,
    'option-2:200000:52': 1261.86,
    'option-2:45000:27': 45.13,
    'option-1:30000:48': 73.64,
    'option-4:55000:42': 157.71,
    'option-2:60000:51': 380.06,
    'option-1:300000:59': 1318.3,
    'option-1:95000:25': 48.27,
    'option-1:200000:43': 282.11,
    'option-3:85000:42': 145.56,
    'option-3:450000:15': 271.3,
    'option-2:85000:50': 347.65,
    'option-2:70000:5': 48.94,
    'option-1:75000:59': 334.43,
    'option-4:25000:32': 43.15,
    'option-1:250000:52': 793.19,
    'option-4:350000:46': 1490.73,
    'option-3:400000:6': 242.84,
    'option-3:20000:18': 25.96,
    'option-2:450000:6': 237.36,
    'option-3:350000:60': 1624.38,
    'option-3:350000:50': 819.06,
    'option-4:250000:22': 190.05,
    'option-3:70000:49': 171.96,
    'option-1:30000:1': 25.62,
    'option-3:5000:47': 26.29,
    'option-3:15000:6': 23.45,
    'option-4:80000:23': 70.54,
    'option-1:40000:13': 29.11,
    'option-4:35000:32': 55.04,
    'option-2:75000:56': 673.17,
    'option-4:20000:37': 46.71,
    'option-3:400000:13': 242.84,
    'option-2:70000:56': 628.06,
    'option-3:20000:53': 75.98,
    'option-4:20000:40': 46.71,
    'option-4:150000:41': 413.01,
    'option-3:400000:38': 454.51,
    'option-3:55000:11': 45.73,
    'option-4:100000:43': 278.1,
    'option-1:25000:20': 23.87,
    'option-4:25000:20': 31.94,
    'option-1:55000:16': 34.28,
    'option-4:25000:37': 55.37,
    'option-4:15000:9': 25.34,
    'option-3:85000:24': 62.57,
    'option-2:350000:11': 188.68,
    'option-3:95000:9': 68.23,
    'option-4:65000:5': 60.13,
    'option-3:35000:25': 34.43,
    'option-4:80000:48': 345.29,
    'option-3:65000:19': 51.38,
    'option-1:500000:22': 192.42,
    'option-1:75000:16': 41.27,
    'option-1:25000:3': 23.87,
    'option-3:25000:31': 34.49,
    'option-4:100000:28': 103.63,
    'option-3:75000:28': 64.4,
    'option-1:65000:46': 146.29,
    'option-4:85000:8': 74.07,
    'option-3:50000:5': 42.91,
    'option-3:55000:49': 137.41,
    'option-1:250000:10': 102.34,
    'option-3:250000:28': 181.12,
    'option-4:5000:22': 19.1,
    'option-3:45000:54': 160.41,
    'option-4:100000:36': 184.93,
    'option-2:250000:40': 390.8,
    'option-2:200000:49': 808.11,
    'option-4:75000:35': 102.88,
    'option-2:500000:40': 772.96,
    'option-2:10000:17': 20.26,
    'option-1:25000:53': 87.57,
    'option-4:45000:23': 46.04,
    'option-3:250000:17': 155.61,
    'option-1:25000:11': 23.87,
    'option-2:50000:22': 39.27,
    'option-1:150000:16': 67.5,
    'option-3:450000:35': 384.52,
    'option-3:90000:17': 65.4,
    'option-4:200000:24': 155.14,
    'option-4:200000:9': 155.14,
    'option-2:95000:21': 61.19,
    'option-2:50000:20': 39.27,
    'option-3:400000:39': 454.51,
    'option-2:75000:53': 474.63,
    'option-1:45000:2': 30.86,
    'option-1:25000:44': 46.58,
    'option-4:55000:33': 78.97,
    'option-3:60000:8': 48.56,
    'option-1:300000:27': 153.23,
    'option-1:100000:48': 218.56,
    'option-3:90000:3': 65.4,
    'option-2:20000:40': 42.84,
    'option-2:20000:25': 24.6,
    'option-1:65000:54': 213.37,
    'option-2:10000:37': 29.44,
    'option-4:5000:5': 19.1,
    'option-3:5000:43': 22.98,
    'option-4:80000:7': 70.54,
    'option-3:15000:60': 78.48,
    'option-1:60000:49': 135.9,
    'option-3:100000:16': 71.05,
    'option-4:500000:19': 368.87,
    'option-2:75000:18': 51.39,
    'option-4:95000:13': 81.12,
    'option-3:95000:15': 68.23,
    'option-3:50000:30': 47.84,
    'option-2:80000:37': 133.8,
    'option-1:200000:30': 105.35,
    'option-4:300000:1': 228.15,
    'option-4:35000:17': 39.0,
    'option-3:250000:53': 850.91,
    'option-1:150000:3': 67.5,
    'option-4:150000:23': 119.82,
    'option-4:400000:19': 298.07,
    'option-2:300000:44': 752.86,
    'option-3:10000:40': 25.66,
    'option-1:85000:11': 44.77,
    'option-2:150000:13': 88.1,
    'option-2:350000:54': 2209.54,
    'option-2:65000:33': 77.37,
    'option-4:35000:27': 45.36,
    'option-3:40000:46': 102.8,
    'option-4:5000:12': 19.1,
    'option-3:500000:24': 299.59,
    'option-3:150000:17': 99.29,
    'option-4:80000:57': 738.23,
    'option-4:95000:14': 81.12,
    'option-4:65000:59': 599.04,
    'option-1:45000:27': 35.26,
    'option-2:15000:31': 28.98,
    'option-2:65000:52': 411.59,
    'option-1:30000:39': 40.28,
    'option-3:5000:54': 31.23,
    'option-4:90000:42': 251.81,
    'option-1:25000:7': 23.87,
    'option-3:60000:45': 106.4,
    'option-1:75000:7': 41.27,
    'option-2:95000:15': 61.19,
    'option-4:200000:57': 1850.71,
    'option-4:35000:1': 39.0,
    'option-1:500000:45': 688.25,
    'option-2:75000:9': 51.39,
    'option-4:150000:25': 119.82,
    'option-2:400000:21': 212.75,
    'option-2:80000:24': 53.84,
    'option-2:55000:50': 227.39,
    'option-4:200000:37': 358.47,
    'option-2:500000:7': 261.85,
    'option-4:50000:50': 218.19,
    'option-3:40000:51': 143.36,
    'option-2:400000:22': 212.75,
    'option-1:20000:59': 94.49,
    'option-1:40000:47': 94.31,
    'option-4:45000:26': 54.36,
    'option-2:65000:1': 46.5,
    'option-1:15000:39': 27.64,
    'option-3:300000:52': 1023.69,
    'option-2:350000:46': 1411.7,
    'option-3:10000:4': 20.97,
    'option-1:20000:21': 22.13,
    'option-2:50000:31': 62.67,
    'option-1:40000:40': 49.07,
    'option-3:150000:28': 114.5,
    'option-1:40000:50': 94.31,
    'option-1:400000:34': 257.57,
    'option-1:60000:32': 50.68,
    'option-3:80000:45': 137.73,
    'option-1:35000:24': 27.36,
    'option-1:40000:22': 29.11,
    'option-3:100000:29': 81.13,
    'option-4:400000:47': 1702.7,
    'option-1:70000:33': 56.57,
    'option-3:20000:8': 25.96,
    'option-3:55000:33': 59.09,
    'option-4:35000:15': 39.0,
    'option-3:250000:5': 155.61,
    'option-2:65000:50': 267.49,
    'option-3:30000:14': 31.61,
    'option-1:100000:38': 101.65,
    'option-1:85000:53': 276.28,
    'option-4:25000:35': 43.15,
    'option-3:50000:41': 90.72,
    'option-2:200000:28': 151.02,
    'option-3:200000:8': 127.59,
    'option-3:90000:41': 153.07,
    'option-2:45000:11': 36.83,
    'option-3:60000:27': 54.52,
    'option-2:90000:17': 58.74,
    'option-2:150000:19': 88.1,
    'option-2:450000:3': 237.36,
    'option-2:150000:49': 607.98,
    'option-2:45000:12': 36.83,
    'option-4:55000:40': 107.31,
    'option-3:400000:54': 1362.29,
    'option-2:250000:1': 136.85,
    'option-4:55000:52': 361.05,
    'option-1:90000:51': 291.33,
    'option-2:35000:12': 31.93,
    'option-1:25000:28': 26.21,
    'option-2:95000:54': 599.37,
    'option-4:45000:8': 46.04,
    'option-1:250000:24': 102.34,
    'option-2:35000:35': 47.96,
    'option-1:300000:31': 197.74,
    'option-4:85000:20': 74.07,
    'option-4:400000:29': 376.12,
    'option-1:60000:50': 135.9,
    'option-2:400000:37': 621.91,
    'option-4:60000:60': 552.62,
    'option-2:85000:5': 56.29,
    'option-2:35000:44': 96.81,
    'option-2:250000:30': 184.9,
    'option-2:70000:30': 62.22,
    'option-2:90000:43': 232.5,
    'option-1:40000:28': 33.0,
    'option-2:95000:1': 61.19,
    'option-2:5000:59': 57.62,
    'option-3:250000:59': 1157.45,
    'option-2:50000:29': 48.55,
    'option-2:65000:35': 77.37,
    'option-4:150000:37': 271.91,
    'option-2:450000:40': 696.98,
    'option-3:35000:37': 51.8,
    'option-3:150000:23': 99.29,
    'option-4:450000:28': 420.63,
    'option-1:45000:7': 30.86,
    'option-2:20000:3': 24.6,
    'option-1:85000:39': 88.44,
    'option-4:30000:9': 35.47,
    'option-3:150000:24': 99.29,
    'option-1:70000:37': 75.45,
    'option-1:500000:44': 688.25,
    'option-1:65000:35': 53.69,
    'option-4:30000:6': 35.47,
    'option-3:45000:21': 40.08,
    'option-2:15000:56': 137.28,
    'option-3:95000:47': 229.02,
    'option-2:150000:45': 380.55,
    'option-4:30000:25': 35.47,
    'option-2:75000:22': 51.39,
    'option-4:60000:6': 56.61,
    'option-2:15000:32': 28.98,
    'option-3:60000:49': 148.93,
    'option-1:450000:47': 947.42,
    'option-3:95000:46': 229.02,
    'option-2:5000:56': 57.62,
    'option-3:70000:23': 54.09,
    'option-3:15000:41': 36.79,
    'option-2:35000:56': 312.52,
    'option-3:10000:23': 20.97,
    'option-1:55000:54': 181.91,
    'option-4:85000:48': 366.47,
    'option-2:15000:22': 22.43,
    'option-2:30000:16': 29.49,
    'option-2:450000:59': 4052.7,
    'option-2:65000:26': 58.8,
    'option-1:400000:12': 157.41,
    'option-1:150000:34': 104.75,
    'option-4:65000:56': 599.04,
    'option-2:90000:16': 58.74,
    'option-3:65000:24': 51.38,
    'option-2:90000:9': 58.74,
    'option-3:300000:36': 344.64,
    'option-3:30000:52': 109.85,
    'option-2:300000:5': 164.12,
    'option-3:20000:19': 25.96,
    'option-3:75000:32': 75.45,
    'option-1:80000:10': 43.02,
    'option-2:70000:31': 82.27,
    'option-2:400000:1': 212.75,
    'option-1:65000:8': 37.77,
    'option-3:35000:13': 34.43,
    'option-4:150000:36': 271.91,
    'option-3:80000:10': 59.74,
    'option-1:45000:9': 30.86,
    'option-4:90000:18': 77.6,
    'option-4:20000:19': 28.43,
    'option-2:150000:48': 607.98,
    'option-3:50000:11': 42.91,
    'option-2:55000:28': 51.96,
    'option-4:40000:33': 61.03,
    'option-4:200000:52': 1308.78,
    'option-2:75000:13': 51.39,
    'option-4:75000:14': 67.02,
    'option-3:15000:19': 23.45,
    'option-3:35000:58': 168.13,
    'option-4:40000:4': 42.52,
    'option-3:450000:20': 271.3,
    'option-1:70000:11': 39.52,
    'option-1:25000:9': 23.87,
    'option-3:150000:40': 177.45,
    'option-2:10000:1': 20.26,
    'option-3:200000:11': 127.59,
    'option-3:65000:38': 84.7,
    'option-2:5000:9': 18.11,
    'option-4:95000:39': 176.25,
    'option-2:50000:23': 39.27,
    'option-3:55000:38': 73.74,
    'option-3:45000:52': 160.41,
    'option-3:60000:16': 48.56,
    'option-2:70000:51': 443.12,
    'option-2:45000:52': 285.38,
    'option-4:80000:24': 70.54,
    'option-1:200000:2': 84.98,
    'option-2:70000:9': 48.94,
    'option-4:350000:20': 263.51,
    'option-2:250000:6': 136.85,
    'option-4:75000:37': 141.98,
    'option-1:80000:38': 84.05,
    'option-3:90000:49': 217.53,
    'option-2:70000:53': 443.12,
    'option-2:5000:8': 18.11,
    'option-1:45000:1': 30.86,
    'option-1:200000:42': 282.11,
    'option-3:350000:52': 1193.19,
    'option-1:200000:12': 84.98,
    'option-2:200000:21': 112.59,
    'option-3:300000:22': 186.71,
    'option-1:150000:44': 214.84,
    'option-2:250000:59': 2245.85,
    'option-4:70000:49': 302.94,
    'option-1:30000:4': 25.62,
    'option-3:70000:51': 244.94,
    'option-3:40000:10': 37.26,
    'option-2:80000:42': 207.82,
    'option-1:40000:35': 38.64,
    'option-2:150000:59': 1349.42,
    'option-4:500000:22': 368.87,
    'option-2:30000:41': 84.46,
    'option-3:400000:19': 242.84,
    'option-1:50000:42': 80.2,
    'option-2:20000:60': 177.35,
    'option-1:80000:32': 62.59,
    'option-3:65000:51': 228.04,
    'option-2:80000:19': 53.84,
    'option-3:95000:23': 68.23,
    'option-3:5000:28': 18.94,
    'option-3:90000:47': 217.53,
    'option-1:150000:47': 322.77,
    'option-2:300000:14': 164.12,
    'option-1:85000:32': 65.61,
    'option-1:65000:47': 146.29,
    'option-1:10000:25': 19.02,
    'option-2:25000:46': 107.16,
    'option-2:50000:60': 447.49,
    'option-1:300000:25': 122.59,
    'option-1:70000:15': 39.52,
    'option-3:55000:4': 45.73,
    'option-1:25000:52': 87.57,
    'option-2:400000:0': 212.75,
    'option-3:50000:34': 55.0,
    'option-1:60000:26': 42.04,
    'option-4:150000:39': 271.91,
    'option-4:150000:6': 119.82,
    'option-2:100000:53': 630.83,
    'option-4:65000:42': 184.61,
    'option-2:95000:52': 599.37,
    'option-2:90000:20': 58.74,
    'option-1:10000:54': 44.1,
    'option-4:450000:58': 4167.41,
    'option-2:75000:23': 51.39,
    'option-2:450000:24': 237.36,
    'option-3:500000:11': 299.59,
    'option-1:95000:1': 48.27,
    'option-2:15000:37': 36.09,
    'option-1:350000:59': 1537.32,
    'option-4:30000:26': 40.86,
    'option-2:70000:54': 443.12,
    'option-2:45000:21': 36.83,
    'option-1:100000:47': 218.56,
    'option-4:5000:15': 19.1,
    'option-4:5000:2': 19.1,
    'option-1:50000:46': 115.12,
    'option-1:40000:39': 49.07,
    'option-4:250000:28': 238.13,
    'option-1:45000:53': 150.42,
    'option-1:45000:36': 53.47,
    'option-3:80000:48': 194.98,
    'option-3:15000:21': 23.45,
    'option-2:70000:14': 48.94,
    'option-2:45000:8': 36.83,
    'option-4:75000:28': 81.33,
    'option-4:80000:39': 150.64,
    'option-1:15000:46': 43.69,
    'option-1:55000:45': 86.94,
    'option-1:90000:15': 46.52,
    'option-3:70000:33': 71.36,
    'option-1:150000:17': 67.5,
    'option-1:20000:47': 52.84,
    'option-3:5000:29': 18.94,
    'option-1:55000:15': 34.28,
    'option-2:95000:20': 61.19,
    'option-1:15000:38': 27.64,
    'option-2:75000:15': 51.39,
    'option-4:40000:10': 42.52,
    'option-2:500000:52': 3152.52,
    'option-3:45000:44': 82.88,
    'option-1:80000:50': 177.45,
    'option-4:500000:42': 1355.18,
    'option-3:35000:59': 168.13,
    'option-4:70000:6': 63.65,
    'option-4:40000:53': 263.19,
    'option-2:250000:3': 136.85,
    'option-2:70000:19': 48.94,
    'option-1:5000:33': 18.62,
    'option-4:150000:54': 982.15,
    'option-4:450000:31': 553.12,
    'option-2:200000:38': 315.75,
    'option-2:100000:10': 63.64,
    'option-4:450000:23': 333.6,
    'option-4:90000:50': 387.64,
    'option-3:35000:7': 34.43,
    'option-3:100000:4': 71.05,
    'option-3:80000:7': 59.74,
    'option-1:400000:1': 157.41,
    'option-2:55000:26': 51.96,
    'option-3:50000:13': 42.91,
    'option-2:40000:58': 357.68,
    'option-1:80000:30': 51.01,
    'option-4:40000:6': 42.52,
    'option-2:350000:27': 256.27,
    'option-1:150000:51': 480.59,
    'option-3:400000:7': 242.84,
    'option-4:5000:59': 58.73,
    'option-2:350000:25': 188.68,
    'option-2:250000:19': 136.85,
    'option-3:35000:31': 42.72,
    'option-4:10000:45': 39.9,
    'option-4:45000:29': 54.36,
    'option-4:65000:1': 60.13,
    'option-2:30000:44': 84.46,
    'option-4:500000:0': 368.87,
    'option-4:100000:23': 84.65,
    'option-3:350000:29': 250.95,
    'option-4:25000:4': 31.94,
    'option-3:150000:6': 99.29,
    'option-3:35000:11': 34.43,
    'option-3:90000:39': 111.87,
    'option-3:300000:10': 186.71,
    'option-4:35000:30': 45.36,
    'option-4:45000:55': 295.52,
    'option-3:95000:20': 68.23,
    'option-4:30000:45': 90.59,
    'option-1:350000:5': 140.14,
    'option-2:350000:35': 359.35,
    'option-1:25000:16': 23.87,
    'option-4:35000:38': 72.72,
    'option-2:30000:55': 191.08,
    'option-4:70000:40': 133.31,
    'option-2:300000:2': 164.12,
    'option-4:250000:44': 681.73,
    'option-3:80000:41': 137.73,
    'option-2:20000:20': 24.6,
    'option-3:500000:9': 299.59,
    'option-3:20000:60': 98.96,
    'option-4:55000:26': 63.35,
    'option-4:70000:18': 63.65,
    'option-4:20000:8': 28.43,
    'option-1:75000:33': 59.58,
    'option-1:20000:49': 52.84,
    'option-4:10000:30': 23.93,
    'option-2:20000:36': 42.84,
    'option-1:40000:17': 29.11,
    'option-4:20000:47': 91.37,
    'option-1:20000:15': 22.13,
    'option-4:10000:23': 22.21,
    'option-1:20000:39': 31.54,
    'option-3:65000:49': 160.45,
    'option-2:75000:11': 51.39,
    'option-3:95000:40': 117.36,
    'option-1:25000:12': 23.87,
    'option-3:85000:14': 62.57,
    'option-2:150000:23': 88.1,
    'option-3:500000:14': 299.59,
    'option-3:80000:60': 375.74,
    'option-1:100000:6': 50.02,
    'option-1:35000:35': 35.63,
    'option-3:200000:56': 929.24,
    'option-1:450000:42': 620.81,
    'option-3:30000:1': 31.61,
    'option-2:85000:33': 96.77,
    'option-2:10000:33': 24.62,
    'option-1:350000:11': 140.14,
    'option-3:35000:14': 34.43,
    'option-1:35000:57': 159.92,
    'option-3:50000:27': 47.84,
    'option-3:60000:44': 106.4,
    'option-2:100000:33': 111.48,
    'option-4:500000:16': 368.87,
    'option-2:300000:34': 310.13,
    'option-4:40000:44': 117.28,
    'option-3:40000:5': 37.26,
    'option-4:65000:53': 426.49,
    'option-3:90000:18': 65.4,
    'option-2:15000:49': 69.54,
    'option-4:35000:29': 45.36,
    'option-3:25000:43': 51.6,
    'option-4:35000:55': 230.46,
    'option-3:5000:16': 18.47,
    'option-1:150000:33': 104.75,
    'option-2:60000:35': 72.47,
    'option-1:500000:37': 455.77,
    'option-3:350000:5': 215.07,
    'option-3:25000:12': 28.78,
    'option-1:40000:4': 29.11,
    'option-1:75000:15': 41.27,
    'option-4:100000:54': 653.99,
    'option-4:5000:32': 21.31,
    'option-3:35000:38': 51.8,
    'option-3:65000:15': 51.38,
    'option-1:35000:43': 59.96,
    'option-3:55000:41': 98.56,
    'option-3:90000:16': 65.4,
    'option-2:50000:2': 39.27,
    'option-1:100000:46': 218.56,
    'option-1:300000:3': 122.59,
    'option-3:85000:57': 398.82,
    'option-2:250000:56': 2245.85,
    'option-2:35000:22': 31.93,
    'option-4:30000:30': 40.86,
    'option-1:20000:16': 22.13,
    'option-1:200000:52': 637.67,
    'option-2:450000:31': 456.94,
    'option-2:90000:25': 58.74,
    'option-3:20000:24': 25.96,
    'option-3:70000:11': 54.09,
    'option-2:30000:58': 267.29,
    'option-1:250000:28': 127.8,
    'option-4:30000:35': 49.13,
    'option-1:400000:31': 257.57,
    'option-3:250000:25': 155.61,
    'option-2:85000:35': 96.77,
    'option-1:250000:31': 164.79,
    'option-4:30000:33': 49.13,
    'option-3:50000:16': 42.91,
    'option-2:100000:24': 63.64,
    'option-4:15000:50': 72.58,
    'option-4:100000:58': 921.76,
    'option-2:70000:34': 82.27,
    'option-3:250000:33': 218.18,
    'option-1:150000:35': 104.75,
    'option-4:200000:17': 155.14,
    'option-4:20000:25': 28.43,
    'option-1:90000:52': 291.33,
    'option-3:40000:56': 191.23,
    'option-2:55000:20': 41.71,
    'option-1:500000:13': 192.42,
    'option-3:45000:27': 44.5,
    'option-3:65000:52': 228.04,
    'option-2:300000:53': 1893.38,
    'option-2:350000:51': 2209.54,
    'option-2:150000:5': 88.1,
    'option-2:35000:55': 222.68,
    'option-3:150000:20': 99.29,
    'option-4:25000:26': 36.41,
    'option-2:45000:56': 402.21,
    'option-1:85000:19': 44.77,
    'option-2:40000:26': 41.71,
    'option-1:350000:20': 140.14,
    'option-2:200000:0': 112.59,
    'option-3:500000:7': 299.59,
    'option-4:300000:6': 228.15,
    'option-2:60000:47': 247.45,
    'option-2:500000:27': 358.54,
    'option-2:100000:3': 63.64,
    'option-4:20000:23': 28.43,
    'option-2:200000:18': 112.59,
    'option-2:300000:0': 164.12,
    'option-2:500000:32': 506.03,
    'option-1:15000:23': 20.58,
    'option-2:40000:13': 34.38,
    'option-1:450000:6': 174.93,
    'option-4:300000:12': 228.15,
    'option-4:500000:15': 368.87,
    'option-2:30000:43': 84.46,
    'option-4:450000:38': 793.57,
    'option-2:350000:19': 188.68,
    'option-2:85000:52': 537.66,
    'option-1:300000:18': 122.59,
    'option-4:15000:19': 25.34,
    'option-3:85000:33': 83.46,
    'option-4:70000:43': 198.05,
    'option-3:60000:18': 48.56,
    'option-3:30000:12': 31.61,
    'option-3:350000:33': 303.08,
    'option-1:90000:4': 46.52,
    'option-2:45000:20': 36.83,
    'option-4:70000:20': 63.65,
    'option-3:95000:57': 443.93,
    'option-1:40000:25': 29.11,
    'option-4:200000:55': 1308.78,
    'option-2:45000:60': 402.21,
    'option-2:15000:33': 28.98,
    'option-1:85000:51': 276.28,
    'option-3:35000:44': 67.15,
    'option-4:50000:18': 49.57,
    'option-4:100000:60': 921.76,
    'option-2:80000:43': 207.82,
    'option-4:40000:38': 81.29,
    'option-4:350000:24': 263.51,
    'option-4:35000:9': 39.0,
    'option-3:300000:53': 1023.69,
    'option-1:250000:21': 102.34,
    'option-1:30000:6': 25.62,
    'option-3:75000:19': 56.91,
    'option-1:200000:21': 84.98,
    'option-3:200000:24': 127.59,
    'option-4:45000:52': 295.52,
    'option-3:95000:49': 229.02,
    'option-4:70000:48': 302.94,
    'option-4:200000:14': 155.14,
    'option-2:55000:47': 227.39,
    'option-1:65000:17': 37.77,
    'option-4:85000:54': 557.3,
    'option-4:35000:4': 39.0,
    'option-1:40000:8': 29.11,
    'option-2:90000:34': 101.66,
    'option-1:55000:41': 86.94,
    'option-1:50000:0': 32.6,
    'option-3:500000:42': 797.55,
    'option-1:300000:0': 122.59,
    'option-3:85000:5': 62.57,
    'option-4:40000:26': 49.86,
    'option-3:50000:29': 47.84,
    'option-3:25000:7': 28.78,
    'option-2:85000:6': 56.29,
    'option-4:15000:15': 25.34,
    'option-3:250000:31': 218.18,
    'option-1:500000:57': 2190.49,
    'option-1:75000:3': 41.27,
    'option-1:400000:45': 554.19,
    'option-2:20000:56': 177.35,
    'option-2:30000:27': 34.87,
    'option-3:15000:38': 30.55,
    'option-1:95000:41': 140.53,
    'option-3:350000:4': 215.07,
    'option-3:80000:27': 67.74,
    'option-2:60000:16': 44.16,
    'option-4:70000:8': 63.65,
    'option-4:65000:21': 60.13,
    'option-4:55000:35': 78.97,
    'option-2:350000:39': 545.96,
    'option-1:15000:35': 23.92,
    'option-1:45000:29': 35.26,
    'option-1:35000:32': 35.63,
    'option-2:5000:33': 20.32,
    'option-2:80000:41': 207.82,
    'option-1:50000:4': 32.6,
    'option-3:55000:19': 45.73,
    'option-1:150000:49': 322.77,
    'option-2:450000:16': 237.36,
    'option-2:30000:38': 58.0,
    'option-3:90000:4': 65.4,
    'option-4:200000:50': 853.05,
    'option-4:70000:4': 63.65,
    'option-1:50000:49': 115.12,
    'option-2:300000:21': 164.12,
    'option-3:40000:34': 46.81,
    'option-3:45000:19': 40.08,
    'option-2:80000:45': 207.82,
    'option-3:95000:11': 68.23,
    'option-2:500000:47': 2011.11,
    'option-1:500000:10': 192.42,
    'option-1:45000:42': 73.46,
    'option-2:25000:1': 27.04,
    'option-3:35000:27': 37.81,
    'option-4:90000:34': 120.54,
    'option-4:65000:29': 72.34,
    'option-3:25000:37': 40.9,
    'option-1:10000:40': 23.73,
    'option-1:200000:41': 282.11,
    'option-1:25000:54': 87.57,
    'option-2:350000:2': 188.68,
    'option-4:450000:3': 333.6,
    'option-4:5000:3': 19.1,
    'option-1:50000:15': 32.6,
    'option-1:100000:34': 74.66,
    'option-3:80000:46': 194.98,
    'option-3:400000:60': 1855.43,
    'option-2:70000:29': 62.22,
    'option-3:350000:22': 215.07,
    'option-3:30000:38': 46.27,
    'option-3:5000:26': 18.94,
    'option-2:85000:56': 763.38,
    'option-4:15000:41': 51.85,
    'option-2:85000:43': 220.16,
    'option-3:85000:20': 62.57,
    'option-3:5000:48': 26.29,
    'option-3:5000:10': 18.47,
    'option-4:35000:54': 230.46,
    'option-2:20000:33': 33.32,
    'option-4:45000:16': 46.04,
    'option-1:350000:30': 175.95,
    'option-1:95000:38': 97.25,
    'option-2:90000:23': 58.74,
    'option-4:30000:53': 197.67,
    'option-4:150000:40': 271.91,
    'option-2:90000:52': 569.17,
    'option-2:10000:59': 97.36,
    'option-2:500000:37': 772.96,
    'option-1:90000:12': 46.52,
    'option-1:5000:42': 21.98,
    'option-4:15000:24': 25.34,
    'option-2:55000:36': 95.87,
    'option-3:100000:25': 71.05,
    'option-4:15000:37': 38.98,
    'option-3:400000:30': 283.75,
    'option-2:200000:55': 1261.86,
    'option-2:25000:39': 50.41,
    'option-1:65000:4': 37.77,
    'option-1:400000:47': 844.64,
    'option-1:350000:18': 140.14,
    'option-3:35000:32': 42.72,
    'option-2:10000:7': 20.26,
    'option-4:5000:39': 23.72,
    'option-4:60000:25': 56.61,
    'option-3:250000:22': 155.61,
    'option-3:90000:48': 217.53,
    'option-4:250000:34': 311.33,
    'option-1:10000:13': 19.02,
    'option-2:80000:31': 92.07,
    'option-2:5000:36': 22.74,
    'option-3:95000:7': 68.23,
    'option-2:10000:36': 29.44,
    'option-2:350000:15': 188.68,
    'option-4:30000:41': 90.59,
    'option-1:80000:58': 356.26,
    'option-3:55000:58': 260.3,
    'option-2:5000:2': 18.11,
    'option-2:250000:54': 1573.4,
    'option-3:60000:13': 48.56,
    'option-4:300000:39': 534.06,
    'option-4:50000:40': 98.64,
    'option-4:45000:22': 46.04,
    'option-2:10000:32': 24.62,
    'option-4:95000:50': 407.95,
    'option-3:5000:23': 18.47,
    'option-1:350000:36': 324.23,
    'option-1:10000:56': 55.8,
    'option-3:200000:28': 148.0,
    'option-2:55000:43': 146.09,
    'option-2:75000:24': 51.39,
    'option-3:200000:48': 471.07,
    'option-1:60000:13': 36.02,
    'option-3:85000:9': 62.57,
    'option-2:60000:8': 44.16,
    'option-4:200000:44': 547.26,
    'option-2:70000:38': 118.63,
    'option-1:250000:1': 102.34,
    'option-2:85000:32': 96.77,
    'option-2:10000:2': 20.26,
    'option-3:15000:23': 23.45,
    'option-4:80000:50': 345.29,
    'option-2:15000:16': 22.43,
    'option-2:25000:16': 27.04,
    'option-2:15000:12': 22.43,
    'option-3:10000:47': 36.49,
    'option-4:45000:15': 46.04,
    'option-3:65000:12': 51.38,
    'option-1:250000:58': 1095.56,
    'option-3:350000:49': 819.06,
    'option-2:10000:31': 24.62,
    'option-1:60000:52': 197.64,
    'option-1:60000:8': 36.02,
    'option-1:25000:57': 116.12,
    'option-2:40000:35': 52.87,
    'option-1:75000:29': 48.74,
    'option-1:200000:14': 84.98,
    'option-3:300000:55': 1023.69,
    'option-1:15000:30': 21.94,
    'option-4:75000:26': 81.33,
    'option-2:150000:7': 88.1,
    'option-2:250000:25': 136.85,
    'option-2:15000:14': 22.43,
    'option-1:90000:26': 55.54,
    'option-1:300000:14': 122.59,
    'option-4:15000:36': 38.98,
    'option-3:45000:23': 40.08,
    'option-1:10000:3': 19.02,
    'option-2:35000:32': 47.96,
    'option-4:35000:13': 39.0,
    'option-4:55000:54': 361.05,
    'option-1:45000:23': 30.86,
    'option-2:60000:31': 72.47,
    'option-4:65000:10': 60.13,
    'option-4:300000:2': 228.15,
    'option-3:450000:29': 317.45,
    'option-2:65000:38': 111.04,
    'option-1:95000:19': 48.27,
    'option-3:15000:43': 36.79,
    'option-2:10000:51': 72.37,
    'option-1:400000:13': 157.41,
    'option-3:15000:10': 23.45,
    'option-1:50000:48': 115.12,
    'option-1:40000:21': 29.11,
    'option-1:60000:6': 36.02,
    'option-3:65000:8': 51.38,
    'option-1:20000:7': 22.13,
    'option-3:80000:32': 79.37,
    'option-4:15000:22': 25.34,
    'option-2:350000:43': 876.7,
    'option-1:90000:8': 46.52,
    'option-3:40000:38': 57.29,
    'option-2:90000:27': 75.75,
    'option-3:25000:22': 28.78,
    'option-4:15000:45': 51.85,
    'option-3:450000:17': 271.3,
    'option-4:90000:22': 77.6,
    'option-3:150000:48': 356.08,
    'option-3:70000:36': 90.18,
    'option-3:350000:40': 399.67,
    'option-3:100000:31': 95.75,
    'option-1:80000:40': 84.05,
    'option-2:250000:0': 136.85,
    'option-3:75000:52': 261.83,
    'option-4:150000:28': 148.46,
    'option-1:200000:55': 637.67,
    'option-2:25000:28': 31.45,
    'option-4:500000:17': 368.87,
    'option-1:15000:55': 58.03,
    'option-4:50000:2': 49.57,
    'option-1:25000:10': 23.87,
    'option-1:150000:59': 661.69,
    'option-3:100000:42': 168.74,
    'option-3:65000:13': 51.38,
    'option-3:150000:35': 136.57,
    'option-3:80000:35': 79.37,
    'option-4:35000:46': 154.83,
    'option-4:350000:56': 3244.69,
    'option-2:85000:59': 763.38,
    'option-2:200000:57': 1799.98,
    'option-4:250000:55': 1632.05,
    'option-1:65000:38': 71.05,
    'option-4:95000:44': 264.68,
    'option-2:450000:5': 237.36,
    'option-4:300000:29': 286.02,
    'option-1:300000:2': 122.59,
    'option-2:75000:44': 195.48,
    'option-1:95000:14': 48.27,
    'option-4:55000:8': 53.09,
    'option-1:55000:2': 34.28,
    'option-1:15000:34': 23.92,
    'option-3:15000:50': 46.71,
    'option-4:200000:54': 1308.78,
    'option-3:40000:18': 37.26,
    'option-3:75000:59': 352.66,
    'option-3:5000:39': 20.86,
    'option-1:35000:11': 27.36,
    'option-2:15000:0': 22.43,
    'option-3:55000:20': 45.73,
    'option-3:55000:16': 45.73,
    'option-1:85000:28': 53.27,
    'option-1:25000:0': 23.87,
    'option-4:15000:33': 31.87,
    'option-3:60000:46': 148.93,
    'option-3:500000:16': 299.59,
    'option-4:5000:36': 23.72,
    'option-2:40000:2': 34.38,
    'option-4:40000:21': 42.52,
    'option-3:300000:60': 1392.81,
    'option-4:300000:17': 228.15,
    'option-4:350000:14': 263.51,
    'option-4:50000:25': 49.57,
    'option-3:90000:20': 65.4,
    'option-2:30000:2': 29.49,
    'option-2:35000:30': 38.29,
    'option-3:450000:5': 271.3,
    'option-4:200000:11': 155.14,
    'option-1:200000:24': 84.98,
    'option-1:25000:42': 46.58,
    'option-3:250000:0': 155.61,
    'option-3:250000:40': 286.82,
    'option-1:5000:35': 18.62,
    'option-4:300000:28': 286.02,
    'option-4:150000:35': 192.49,
    'option-1:55000:37': 62.26,
    'option-4:500000:25': 368.87,
    'option-1:70000:3': 39.52,
    'option-2:80000:32': 92.07,
    'option-2:40000:45': 108.97,
    'option-1:25000:32': 29.6,
    'option-3:500000:53': 1698.69,
    'option-4:20000:17': 28.43,
    'option-3:90000:15': 65.4,
    'option-4:200000:34': 252.19,
    'option-3:90000:34': 87.56,
    'option-1:70000:20': 39.52,
    'option-4:95000:16': 81.12,
    'option-1:95000:23': 48.27,
    'option-3:35000:17': 34.43,
    'option-1:500000:38': 455.77,
    'option-2:200000:6': 112.59,
    'option-2:300000:30': 221.94,
    'option-4:300000:15': 228.15,
    'option-3:25000:9': 28.78,
    'option-4:65000:47': 281.76,
    'option-4:20000:56': 181.91,
    'option-3:45000:48': 114.36,
    'option-4:450000:57': 4167.41,
    'option-3:350000:24': 215.07,
    'option-1:65000:9': 37.77,
    'option-2:55000:17': 41.71,
    'option-3:15000:9': 23.45,
    'option-4:45000:2': 46.04,
    'option-3:95000:24': 68.23,
    'option-4:55000:5': 53.09,
    'option-3:35000:22': 34.43,
    'option-4:250000:41': 681.73,
    'option-2:400000:20': 212.75,
    'option-4:10000:52': 74.49,
    'option-2:20000:30': 28.04,
    'option-1:60000:55': 197.64,
    'option-3:45000:9': 40.08,
    'option-1:350000:1': 140.14,
    'option-1:85000:1': 44.77,
    'option-2:300000:31': 310.13,
    'option-3:150000:16': 99.29,
    'option-1:200000:44': 282.11,
    'option-1:30000:26': 28.47,
    'option-2:450000:8': 237.36,
    'option-1:45000:60': 203.38,
    'option-2:50000:35': 62.67,
    'option-4:10000:31': 26.6,
    'option-1:50000:25': 32.6,
    'option-1:350000:49': 740.69,
    'option-2:75000:41': 195.48,
    'option-1:400000:4': 157.41,
    'option-3:90000:7': 65.4,
    'option-4:75000:30': 81.33,
    'option-4:65000:8': 60.13,
    'option-4:100000:20': 84.65,
    'option-3:65000:45': 114.23,
    'option-1:80000:11': 43.02,
    'option-4:200000:15': 155.14,
    'option-3:300000:42': 484.56,
    'option-4:55000:50': 239.39,
    'option-1:15000:57': 75.13,
    'option-1:45000:15': 30.86,
    'option-4:300000:53': 1963.97,
    'option-1:350000:12': 140.14,
    'option-3:60000:54': 211.14,
    'option-4:35000:31': 55.04,
    'option-1:250000:51': 793.19,
    'option-2:95000:57': 851.7,
    'option-1:85000:45': 127.34,
    'option-1:100000:32': 74.66,
    'option-2:5000:38': 22.74,
    'option-2:450000:51': 2836.41,
    'option-3:90000:27': 74.43,
    'option-1:500000:16': 192.42,
    'option-4:400000:25': 298.07,
    'option-2:25000:4': 27.04,
    'option-3:150000:2': 99.29,
    'option-2:500000:13': 261.85,
    'option-1:300000:10': 122.59,
    'option-1:200000:0': 84.98,
    'option-4:350000:48': 1490.73,
    'option-4:350000:42': 953.02,
    'option-3:95000:31': 91.65,
    'option-3:450000:48': 1048.19,
    'option-4:35000:37': 72.72,
    'option-4:500000:39': 880.37,
    'option-4:45000:1': 46.04,
    'option-2:45000:49': 187.27,
    'option-3:100000:37': 122.85,
    'option-2:300000:1': 164.12,
    'option-4:80000:1': 70.54,
    'option-3:250000:56': 1157.45,
    'option-3:30000:26': 34.47,
    'option-2:30000:6': 29.49,
    'option-1:350000:0': 140.14,
    'option-1:300000:1': 122.59,
    'option-3:350000:31': 303.08,
    'option-2:55000:24': 41.71,
    'option-1:75000:8': 41.27,
    'option-3:30000:34': 38.62,
    'option-1:150000:7': 67.5,
    'option-4:20000:0': 28.43,
    'option-4:30000:5': 35.47,
    'option-1:500000:59': 2190.49,
    'option-1:250000:8': 102.34,
    'option-4:400000:26': 376.12,
    'option-2:70000:12': 48.94,
    'option-4:75000:0': 67.02,
    'option-3:25000:56': 121.81,
    'option-3:70000:4': 54.09,
    'option-1:90000:59': 398.96,
    'option-3:30000:35': 38.62,
    'option-2:70000:58': 628.06,
    'option-1:10000:30': 19.94,
    'option-4:75000:48': 324.12,
    'option-1:70000:31': 56.57,
    'option-3:80000:5': 59.74,
    'option-2:30000:35': 43.04,
    'option-3:150000:27': 114.5,
    'option-4:95000:8': 81.12,
    'option-1:85000:13': 44.77,
    'option-4:450000:55': 2942.5,
    'option-1:30000:19': 25.62,
    'option-3:55000:42': 98.56,
    'option-2:150000:10': 88.1,
    'option-4:50000:49': 218.19,
    'option-2:25000:21': 27.04,
    'option-3:250000:21': 155.61,
    'option-1:300000:48': 636.47,
    'option-3:95000:37': 117.36,
    'option-3:250000:34': 218.18,
    'option-4:65000:12': 60.13,
    'option-1:500000:54': 1582.54,
    'option-1:300000:24': 122.59,
    'option-4:350000:34': 434.15,
    'option-4:35000:28': 45.36,
    'option-2:80000:1': 53.84,
    'option-1:65000:51': 213.37,
    'option-2:70000:11': 48.94,
    'option-1:60000:19': 36.02,
    'option-3:25000:50': 68.37,
    'option-1:90000:7': 46.52,
    'option-3:200000:30': 148.0,
    'option-3:80000:19': 59.74,
    'option-4:100000:40': 184.93,
    'option-1:55000:57': 247.09,
    'option-3:150000:18': 99.29,
    'option-1:30000:15': 25.62,
    'option-3:25000:27': 31.12,
    'option-2:5000:15': 18.11,
    'option-1:300000:28': 153.23,
    'option-1:30000:33': 32.61,
    'option-4:5000:1': 19.1,
    'option-1:40000:20': 29.11,
    'option-3:5000:35': 19.62,
    'option-4:75000:53': 491.9,
    'option-3:10000:50': 36.49,
    'option-3:35000:56': 168.13,
    'option-3:65000:36': 84.7,
    'option-3:200000:58': 929.24,
    'option-4:85000:15': 74.07,
    'option-4:450000:35': 553.12,
    'option-1:75000:41': 113.88,
    'option-4:75000:8': 67.02,
    'option-1:35000:1': 27.36,
    'option-2:100000:51': 630.83,
    'option-1:85000:41': 127.34,
    'option-3:500000:17': 299.59,
    'option-2:10000:34': 24.62,
    'option-2:350000:12': 188.68,
    'option-1:10000:39': 23.73,
    'option-4:400000:44': 1087.59,
    'option-2:50000:8': 39.27,
    'option-4:5000:60': 58.73,
    'option-2:15000:17': 22.43,
    'option-1:60000:9': 36.02,
    'option-1:400000:30': 198.24,
    'option-3:5000:45': 22.98,
    'option-3:35000:39': 51.8,
    'option-1:15000:17': 20.58,
    'option-3:20000:1': 25.96,
    'option-3:200000:19': 127.59,
    'option-2:65000:10': 46.5,
    'option-4:75000:25': 67.02,
    'option-4:15000:48': 72.58,
    'option-2:500000:24': 261.85,
    'option-2:400000:14': 212.75,
    'option-4:350000:28': 331.15,
    'option-1:85000:21': 44.77,
    'option-3:200000:46': 471.07,
    'option-1:65000:15': 37.77,
    'option-1:30000:31': 32.61,
    'option-1:25000:24': 23.87,
    'option-4:60000:19': 56.61,
    'option-4:100000:33': 132.52,
    'option-1:200000:33': 134.94,
    'option-3:75000:14': 56.91,
    'option-4:250000:32': 311.33,
    'option-3:70000:58': 329.58,
    'option-2:90000:57': 808.49,
    'option-3:25000:55': 92.89,
    'option-1:300000:9': 122.59,
    'option-2:350000:1': 188.68,
    'option-2:40000:25': 34.38,
    'option-1:80000:14': 43.02,
    'option-3:250000:32': 218.18,
    'option-4:5000:47': 35.14,
    'option-3:200000:1': 127.59,
    'option-4:400000:13': 298.07,
    'option-4:450000:29': 420.63,
    'option-2:30000:0': 29.49,
    'option-2:90000:32': 101.66,
    'option-3:30000:15': 31.61,
    'option-2:40000:31': 52.87,
    'option-1:300000:7': 122.59,
    'option-1:10000:37': 23.73,
    'option-3:250000:41': 403.04,
    'option-3:100000:36': 122.85,
    'option-2:65000:5': 46.5,
    'option-2:50000:41': 133.74,
    'option-2:450000:2': 237.36,
    'option-3:60000:38': 79.22,
    'option-4:150000:20': 119.82,
    'option-2:75000:10': 51.39,
    'option-1:200000:35': 134.94,
    'option-1:10000:4': 19.02,
    'option-2:300000:35': 310.13,
    'option-4:50000:52': 328.31,
    'option-4:5000:21': 19.1,
    'option-1:450000:43': 620.81,
    'option-1:25000:4': 23.87,
    'option-3:40000:4': 37.26,
    'option-1:350000:52': 1111.99,
    'option-1:25000:8': 23.87,
    'option-1:100000:54': 322.79,
    'option-1:80000:57': 356.26,
    'option-4:95000:47': 407.95,
    'option-3:90000:57': 420.89,
    'option-2:500000:54': 3152.52,
    'option-1:25000:48': 63.23,
    'option-4:250000:42': 681.73,
    'option-3:20000:10': 25.96,
    'option-1:85000:2': 44.77,
    'option-3:75000:17': 56.91,
    'option-4:45000:20': 46.04,
    'option-1:500000:17': 192.42,
    'option-4:450000:59': 4167.41,
    'option-1:200000:19': 84.98,
    'option-2:100000:31': 111.48,
    'option-3:150000:50': 356.08,
    'option-2:50000:56': 447.49,
    'option-4:60000:28': 67.85,
    'option-3:250000:42': 403.04,
    'option-4:65000:24': 60.13,
    'option-1:20000:14': 22.13,
    'option-2:150000:58': 1349.42,
    'option-1:20000:45': 39.85,
    'option-3:40000:11': 37.26,
    'option-3:400000:43': 641.6,
    'option-3:90000:10': 65.4,
    'option-3:150000:3': 99.29,
    'option-2:35000:59': 312.52,
    'option-2:55000:37': 95.87,
    'option-2:65000:36': 111.04,
    'option-4:100000:31': 132.52,
    'option-1:30000:52': 103.36,
    'option-1:150000:40': 145.49,
    'option-4:45000:28': 54.36,
    'option-3:55000:54': 194.24,
    'option-3:40000:13': 37.26,
    'option-1:55000:51': 181.91,
    'option-3:95000:36': 117.36,
    'option-3:350000:56': 1624.38,
    'option-4:90000:14': 77.6,
    'option-2:25000:15': 27.04,
    'option-2:250000:22': 136.85,
    'option-3:5000:24': 18.47,
    'option-4:40000:7': 42.52,
    'option-2:300000:51': 1893.38,
    'option-1:60000:12': 36.02,
    'option-3:85000:7': 62.57,
    'option-2:85000:15': 56.29,
    'option-3:5000:1': 18.47,
    'option-1:30000:45': 53.32,
    'option-1:45000:33': 41.65,
    'option-2:45000:58': 402.21,
    'option-3:25000:36': 40.9,
    'option-1:55000:4': 34.28,
    'option-1:90000:53': 291.33,
    'option-4:500000:28': 465.63,
    'option-4:50000:14': 49.57,
    'option-3:250000:4': 155.61,
    'option-1:150000:9': 67.5,
    'option-4:35000:25': 39.0,
    'option-2:65000:43': 170.79,
    'option-4:45000:50': 196.99,
    'option-3:90000:5': 65.4,
    'option-2:30000:37': 58.0,
    'option-3:350000:11': 215.07,
    'option-2:20000:18': 24.6,
    'option-4:80000:8': 70.54,
    'option-2:350000:47': 1411.7,
    'option-4:25000:10': 31.94,
    'option-2:15000:18': 22.43,
    'option-1:300000:6': 122.59,
    'option-4:95000:48': 407.95,
    'option-2:80000:17': 53.84,
    'option-2:55000:41': 146.09,
    'option-3:300000:58': 1392.81,
    'option-3:65000:37': 84.7,
    'option-2:150000:41': 380.55,
    'option-2:10000:23': 20.26,
    'option-1:500000:48': 1051.46,
    'option-1:80000:35': 62.59,
    'option-2:250000:12': 136.85,
    'option-3:20000:12': 25.96,
    'option-4:150000:44': 413.01,
    'option-2:20000:54': 128.24,
    'option-2:60000:10': 44.16,
    'option-3:5000:42': 22.98,
    'option-1:15000:25': 20.58,
    'option-4:55000:44': 157.71,
    'option-3:80000:52': 278.73,
    'option-3:55000:2': 45.73,
    'option-3:60000:39': 79.22,
    'option-2:20000:31': 33.32,
    'option-1:70000:35': 56.57,
    'option-1:200000:23': 84.98,
    'option-4:450000:7': 333.6,
    'option-3:10000:21': 20.97,
    'option-3:95000:44': 160.89,
    'option-4:60000:8': 56.61,
    'option-2:85000:21': 56.29,
    'option-3:75000:42': 129.9,
    'option-1:85000:10': 44.77,
    'option-3:65000:53': 228.04,
    'option-2:85000:28': 72.32,
    'option-3:45000:56': 214.09,
    'option-1:75000:51': 244.83,
    'option-1:50000:20': 32.6,
    'option-1:85000:36': 88.44,
    'option-4:75000:44': 211.49,
    'option-2:60000:11': 44.16,
    'option-4:65000:35': 90.92,
    'option-4:70000:47': 302.94,
    'option-2:60000:56': 537.8,
    'option-1:10000:27': 19.94,
    'option-1:5000:60': 36.54,
    'option-4:35000:22': 39.0,
    'option-2:65000:57': 582.94,
    'option-3:400000:47': 934.29,
    'option-2:45000:57': 402.21,
    'option-3:25000:2': 28.78,
    'option-3:15000:7': 23.45,
    'option-1:250000:37': 233.2,
    'option-2:150000:54': 947.09,
    'option-4:5000:58': 58.73,
    'option-2:65000:60': 582.94,
    'option-2:20000:4': 24.6,
    'option-2:500000:44': 1246.05,
    'option-1:20000:5': 22.13,
    'option-4:400000:27': 376.12,
    'option-1:15000:41': 33.86,
    'option-1:65000:11': 37.77,
    'option-1:350000:10': 140.14,
    'option-2:400000:49': 1612.3,
    'option-2:85000:18': 56.29,
    'option-3:90000:23': 65.4,
    'option-3:30000:44': 59.45,
    'option-1:60000:51': 197.64,
    'option-4:200000:60': 1850.71,
    'option-3:45000:2': 40.08,
    'option-2:5000:18': 18.11,
    'option-4:400000:43': 1087.59,
    'option-3:25000:15': 28.78,
    'option-1:60000:2': 36.02,
    'option-4:250000:13': 190.05,
    'option-4:15000:56': 140.7,
    'option-1:70000:16': 39.52,
    'option-2:100000:49': 406.91,
    'option-1:70000:56': 312.6,
    'option-4:300000:33': 374.14,
    'option-4:50000:37': 98.64,
    'option-1:5000:37': 19.87,
    'option-2:75000:3': 51.39,
    'option-3:55000:5': 45.73,
    'option-1:55000:59': 247.09,
    'option-1:10000:49': 34.47,
    'option-2:90000:58': 808.49,
    'option-4:10000:60': 99.65,
    'option-3:60000:36': 79.22,
    'option-4:250000:6': 190.05,
    'option-2:300000:3': 164.12,
    'option-4:65000:39': 124.65,
    'option-4:60000:41': 171.16,
    'option-3:35000:16': 34.43,
    'option-4:75000:55': 491.9,
    'option-3:30000:32': 38.62,
    'option-4:75000:45': 211.49,
    'option-4:150000:8': 119.82,
    'option-1:70000:23': 39.52,
    'option-2:200000:17': 112.59,
    'option-2:55000:40': 95.87,
    'option-4:15000:16': 25.34,
    'option-4:350000:49': 1490.73,
    'option-1:500000:55': 1582.54,
    'option-3:45000:4': 40.08,
    'option-4:35000:44': 104.05,
    'option-1:25000:15': 23.87,
    'option-1:150000:18': 67.5,
    'option-4:20000:11': 28.43,
    'option-3:25000:14': 28.78,
    'option-1:35000:4': 27.36,
    'option-4:450000:21': 333.6,
    'option-3:70000:47': 171.96,
    'option-4:65000:44': 184.61,
    'option-2:450000:54': 2836.41,
    'option-4:30000:19': 35.47,
    'option-1:20000:11': 22.13,
    'option-3:40000:19': 37.26,
    'option-2:55000:42': 146.09,
    'option-2:15000:13': 22.43,
    'option-2:5000:45': 27.09,
    'option-2:100000:45': 256.67,
    'option-4:90000:4': 77.6,
    'option-2:500000:14': 261.85,
    'option-4:35000:43': 104.05,
    'option-4:45000:39': 89.97,
    'option-2:35000:26': 38.29,
    'option-1:30000:20': 25.62,
    'option-4:80000:25': 70.54,
    'option-4:100000:12': 84.65,
    'option-2:60000:49': 247.45,
    'option-2:15000:34': 28.98,
    'option-1:40000:23': 29.11,
    'option-3:55000:22': 45.73,
    'option-1:30000:11': 25.62,
    'option-2:60000:48': 247.45,
    'option-1:65000:49': 146.29,
    'option-1:150000:6': 67.5,
    'option-2:10000:18': 20.26,
    'option-1:100000:15': 50.02,
    'option-1:50000:57': 225.23,
    'option-1:70000:54': 229.1,
    'option-1:50000:35': 44.66,
    'option-1:150000:46': 322.77,
    'option-2:40000:46': 167.43,
    'option-4:25000:40': 55.37,
    'option-3:30000:51': 109.85,
    'option-3:10000:45': 29.91,
    'option-1:85000:8': 44.77,
    'option-2:500000:33': 506.03,
    'option-3:95000:56': 443.93,
    'option-1:400000:53': 1269.39,
    'option-2:150000:35': 160.34,
    'option-1:35000:3': 27.36,
    'option-2:65000:45': 170.79,
    'option-4:25000:44': 77.1,
    'option-3:250000:2': 155.61,
    'option-1:90000:9': 46.52,
    'option-4:35000:21': 39.0,
    'option-1:400000:2': 157.41,
    'option-3:85000:39': 106.4,
    'option-3:70000:37': 90.18,
    'option-3:80000:2': 59.74,
    'option-1:300000:43': 419.16,
    'option-2:5000:35': 20.32,
    'option-2:65000:53': 411.59,
    'option-3:150000:37': 177.45,
    'option-3:300000:31': 262.0,
    'option-2:5000:1': 18.11,
    'option-1:25000:46': 63.23,
    'option-4:20000:26': 31.92,
    'option-1:70000:36': 75.45,
    'option-4:10000:38': 31.39,
    'option-2:25000:56': 222.44,
    'option-2:500000:18': 261.85,
    'option-4:60000:55': 393.77,
    'option-1:20000:56': 94.49,
    'option-4:40000:45': 117.28,
    'option-2:65000:18': 46.5,
    'option-2:250000:36': 390.8,
    'option-1:90000:0': 46.52,
    'option-1:40000:14': 29.11,
    'option-1:50000:33': 44.66,
    'option-1:250000:33': 164.79,
    'option-1:70000:28': 46.47,
    'option-2:450000:32': 456.94,
    'option-3:50000:25': 42.91,
    'option-2:75000:45': 195.48,
    'option-3:50000:53': 177.33,
    'option-1:70000:10': 39.52,
    'option-2:30000:50': 127.29,
    'option-2:5000:34': 20.32,
    'option-3:250000:10': 155.61,
    'option-2:40000:20': 34.38,
    'option-4:35000:36': 72.72,
    'option-3:50000:24': 42.91,
    'option-2:15000:30': 25.04,
    'option-2:30000:29': 34.87,
    'option-3:200000:44': 325.57,
    'option-4:300000:36': 534.06,
    'option-4:400000:16': 298.07,
    'option-2:50000:12': 39.27,
    'option-2:75000:32': 87.17,
    'option-2:30000:60': 267.29,
    'option-1:400000:52': 1269.39,
    'option-3:75000:39': 95.66,
    'option-1:60000:47': 135.9,
    'option-2:200000:3': 112.59,
    'option-4:5000:25': 19.1,
    'option-1:60000:25': 36.02,
    'option-3:10000:41': 29.91,
    'option-4:150000:60': 1387.32,
    'option-2:45000:19': 36.83,
    'option-1:80000:20': 43.02,
    'option-3:300000:44': 484.56,
    'option-2:35000:57': 312.52,
    'option-1:60000:58': 268.93,
    'option-2:60000:14': 44.16,
    'option-4:75000:52': 491.9,
    'option-2:65000:14': 46.5,
    'option-2:50000:43': 133.74,
    'option-1:200000:5': 84.98,
    'option-3:150000:12': 99.29,
    'option-3:40000:39': 57.29,
    'option-3:25000:25': 28.78,
    'option-4:60000:31': 84.95,
    'option-3:65000:50': 160.45,
    'option-2:25000:60': 222.44,
    'option-1:90000:46': 197.79,
    'option-1:400000:17': 157.41,
    'option-4:30000:2': 35.47,
    'option-1:50000:17': 32.6,
    'option-1:250000:14': 102.34,
    'option-3:85000:31': 83.46,
    'option-3:100000:40': 122.85,
    'option-2:25000:33': 38.22,
    'option-1:50000:59': 225.23,
    'option-1:100000:9': 50.02,
    'option-1:50000:1': 32.6,
    'option-2:250000:10': 136.85,
    'option-1:80000:42': 120.61,
    'option-1:90000:45': 133.8,
    'option-2:80000:16': 53.84,
    'option-1:250000:47': 529.22,
    'option-1:5000:30': 17.96,
    'option-3:20000:5': 25.96,
    'option-3:70000:25': 54.09,
    'option-3:70000:57': 329.58,
    'option-2:400000:46': 1612.3,
    'option-3:5000:21': 18.47,
    'option-2:95000:44': 244.35,
    'option-3:75000:54': 261.83,
    'option-1:400000:3': 157.41,
    'option-4:60000:1': 56.61,
    'option-2:500000:10': 261.85,
    'option-3:60000:15': 48.56,
    'option-2:100000:28': 82.6,
    'option-3:50000:36': 68.26,
    'option-4:100000:51': 653.99,
    'option-1:500000:43': 688.25,
    'option-4:65000:48': 281.76,
    'option-2:10000:39': 29.44,
    'option-3:20000:54': 75.98,
    'option-3:300000:56': 1392.81,
    'option-4:450000:11': 333.6,
    'option-3:400000:11': 242.84,
    'option-4:55000:41': 157.71,
    'option-2:200000:39': 315.75,
    'option-4:10000:47': 53.83,
    'option-1:45000:52': 150.42,
    'option-4:500000:3': 368.87,
    'option-2:85000:41': 220.16,
    'option-2:100000:32': 111.48,
    'option-2:350000:59': 3155.5,
    'option-3:300000:40': 344.64,
    'option-3:90000:19': 65.4,
    'option-2:15000:45': 48.89,
    'option-1:20000:25': 22.13,
    'option-1:90000:39': 92.84,
    'option-3:45000:6': 40.08,
    'option-1:50000:12': 32.6,
    'option-3:200000:43': 325.57,
    'option-2:350000:14': 188.68,
    'option-3:15000:0': 23.45,
    'option-3:35000:55': 126.78,
    'option-2:200000:12': 112.59,
    'option-4:85000:33': 114.58,
    'option-4:100000:29': 103.63,
    'option-1:10000:42': 27.93,
    'option-3:35000:57': 168.13,
    'option-4:90000:44': 251.81,
    'option-3:350000:19': 215.07,
    'option-2:75000:12': 51.39,
    'option-2:450000:57': 4052.7,
    'option-3:450000:59': 2083.57,
    'option-3:85000:8': 62.57,
    'option-2:400000:28': 289.81,
    'option-3:45000:5': 40.08,
    'option-2:25000:54': 159.39,
    'option-2:150000:29': 116.73,
    'option-3:200000:4': 127.59,
    'option-3:95000:6': 68.23,
    'option-4:95000:15': 81.12,
    'option-4:200000:18': 155.14,
    'option-2:70000:7': 48.94,
    'option-2:80000:46': 327.61,
    'option-3:400000:41': 641.6,
    'option-4:95000:11': 81.12,
    'option-3:20000:32': 30.48,
    'option-4:5000:43': 28.05,
    'option-4:45000:31': 67.01,
    'option-2:20000:23': 24.6,
    'option-2:450000:35': 456.94,
    'option-4:500000:48': 2124.34,
    'option-4:15000:23': 25.34,
    'option-1:70000:22': 39.52,
    'option-1:40000:46': 94.31,
    'option-4:10000:6': 22.21,
    'option-3:70000:32': 71.36,
    'option-4:35000:5': 39.0,
    'option-3:250000:29': 181.12,
    'option-2:65000:29': 58.8,
    'option-2:20000:41': 59.74,
    'option-2:30000:21': 29.49,
    'option-1:35000:34': 35.63,
    'option-4:90000:12': 77.6,
    'option-2:85000:37': 141.39,
    'option-4:45000:42': 130.8,
    'option-3:45000:16': 40.08,
    'option-4:300000:52': 1963.97,
    'option-3:70000:53': 244.94,
    'option-3:10000:49': 36.49,
    'option-2:85000:30': 72.32,
    'option-3:90000:60': 420.89,
    'option-4:150000:26': 148.46,
    'option-1:100000:27': 60.07,
    'option-1:70000:13': 39.52,
    'option-2:450000:0': 237.36,
    'option-3:65000:41': 114.23,
    'option-4:400000:58': 3709.1,
    'option-3:15000:17': 23.45,
    'option-1:70000:2': 39.52,
    'option-3:75000:30': 64.4,
    'option-4:25000:21': 31.94,
    'option-1:200000:54': 637.67,
    'option-4:15000:34': 31.87,
    'option-1:55000:48': 125.51,
    'option-4:35000:8': 39.0,
    'option-1:200000:10': 84.98,
    'option-2:45000:3': 36.83,
    'option-2:90000:48': 367.68,
    'option-1:350000:60': 1537.32,
    'option-4:400000:45': 1087.59,
    'option-1:200000:36': 189.66,
    'option-4:70000:25': 63.65,
    'option-4:25000:18': 31.94,
    'option-1:55000:49': 125.51,
    'option-2:70000:25': 48.94,
    'option-3:200000:47': 471.07,
    'option-3:80000:15': 59.74,
    'option-3:250000:11': 155.61,
    'option-3:30000:11': 31.61,
    'option-2:300000:42': 752.86,
    'option-2:20000:28': 28.04,
    'option-3:90000:36': 111.87,
    'option-4:60000:39': 115.98,
    'option-3:25000:13': 28.78,
    'option-1:90000:10': 46.52,
    'option-3:300000:19': 186.71,
    'option-4:300000:26': 286.02,
    'option-4:95000:21': 81.12,
    'option-3:85000:15': 62.57,
    'option-2:85000:53': 537.66,
    'option-1:50000:21': 32.6,
    'option-2:30000:28': 34.87,
    'option-4:450000:4': 333.6,
    'option-3:90000:58': 420.89,
    'option-3:200000:54': 683.85,
    'option-4:35000:42': 104.05,
    'option-3:150000:19': 99.29,
    'option-3:500000:37': 563.64,
    'option-3:65000:34': 67.27,
    'option-3:5000:52': 31.23,
    'option-1:450000:57': 1971.6,
    'option-4:50000:26': 58.86,
    'option-4:400000:51': 2619.38,
    'option-1:250000:54': 793.19,
    'option-4:85000:55': 557.3,
    'option-3:100000:53': 345.58,
    'option-2:95000:17': 61.19,
    'option-3:95000:58': 443.93,
    'option-3:500000:34': 425.48,
    'option-4:400000:8': 298.07,
    'option-4:500000:10': 368.87,
    'option-1:350000:24': 140.14,
    'option-1:50000:13': 32.6,
    'option-3:100000:32': 95.75,
    'option-4:500000:18': 368.87,
    'option-1:400000:6': 157.41,
    'option-1:55000:25': 34.28,
    'option-3:100000:15': 71.05,
    'option-2:85000:25': 56.29,
    'option-1:500000:6': 192.42,
    'option-4:20000:32': 37.18,
    'option-1:35000:29': 30.74,
    'option-2:50000:48': 207.33,
    'option-3:500000:15': 299.59,
    'option-1:400000:60': 1755.82,
    'option-1:200000:4': 84.98,
    'option-4:45000:46': 196.99,
    'option-3:10000:2': 20.97,
    'option-3:350000:13': 215.07,
    'option-2:150000:33': 160.34,
    'option-1:55000:3': 34.28,
    'option-4:20000:50': 91.37,
    'option-1:150000:36': 145.49,
    'option-1:70000:50': 156.68,
    'option-1:400000:51': 1269.39,
    'option-4:150000:56': 1387.32,
    'option-1:95000:47': 208.15,
    'option-1:55000:58': 247.09,
    'option-2:250000:45': 627.34,
    'option-1:75000:40': 79.84,
    'option-3:95000:41': 160.89,
    'option-3:85000:43': 145.56,
    'option-2:10000:35': 24.62,
    'option-3:10000:53': 46.19,
    'option-1:100000:18': 50.02,
    'option-3:20000:56': 98.96,
    'option-4:200000:2': 155.14,
    'option-1:300000:8': 122.59,
    'option-1:100000:31': 74.66,
    'option-4:70000:56': 645.44,
    'option-4:75000:29': 81.33,
    'option-2:500000:3': 261.85,
    'option-2:350000:16': 188.68,
    'option-3:50000:28': 47.84,
    'option-3:400000:56': 1855.43,
    'option-3:10000:57': 58.04,
    'option-4:95000:7': 81.12,
    'option-2:450000:26': 324.29,
    'option-3:50000:49': 125.89,
    'option-4:95000:34': 126.53,
    'option-2:50000:54': 316.98,
    'option-3:55000:25': 45.73,
    'option-3:30000:28': 34.47,
    'option-4:500000:1': 368.87,
    'option-4:40000:14': 42.52,
    'option-4:20000:14': 28.43,
    'option-3:250000:52': 850.91,
    'option-4:350000:13': 263.51,
    'option-2:20000:42': 59.74,
    'option-2:40000:57': 357.68,
    'option-3:80000:18': 59.74,
    'option-1:20000:24': 22.13,
    'option-2:50000:39': 88.28,
    'option-1:50000:3': 32.6,
    'option-1:85000:58': 378.08,
    'option-3:10000:16': 20.97,
    'option-1:450000:52': 1424.9,
    'option-2:70000:48': 287.53,
    'option-4:35000:34': 55.04,
    'option-2:70000:36': 118.63,
    'option-2:15000:9': 22.43,
    'option-3:65000:42': 114.23,
    'option-4:45000:14': 46.04,
    'option-4:50000:30': 58.86,
    'option-2:250000:42': 627.34,
    'option-2:25000:20': 27.04,
    'option-4:500000:54': 3270.53,
    'option-3:250000:44': 403.04,
    'option-4:95000:43': 264.68,
    'option-1:60000:46': 135.9,
    'option-2:50000:17': 39.27,
    'option-1:100000:60': 442.63,
    'option-2:300000:54': 1893.38,
    'option-3:25000:26': 31.12,
    'option-1:200000:34': 134.94,
    'option-3:55000:15': 45.73,
    'option-4:250000:60': 2309.26,
    'option-4:90000:13': 77.6,
    'option-4:300000:25': 228.15,
    'option-4:250000:1': 190.05,
    'option-4:500000:31': 612.97,
    'option-1:35000:38': 44.67,
    'option-3:400000:25': 242.84,
    'option-1:5000:49': 25.29,
    'option-4:10000:35': 26.6,
    'option-1:85000:15': 44.77,
    'option-2:300000:55': 1893.38,
    'option-3:100000:10': 71.05,
    'option-1:40000:11': 29.11,
    'option-1:20000:35': 26.6,
    'option-4:85000:23': 74.07,
    'option-4:150000:33': 192.49,
    'option-1:450000:59': 1971.6,
    'option-1:400000:50': 844.64,
    'option-3:250000:48': 584.96,
    'option-3:350000:38': 399.67,
    'option-2:40000:9': 34.38,
    'option-1:15000:22': 20.58,
    'option-3:5000:49': 26.29,
    'option-3:45000:36': 62.78,
    'option-4:35000:18': 39.0,
    'option-1:100000:28': 60.07,
    'option-3:40000:49': 102.8,
    'option-4:55000:24': 53.09,
    'option-3:400000:37': 454.51,
    'option-3:35000:33': 42.72,
    'option-2:80000:58': 718.28,
    'option-3:400000:36': 454.51,
    'option-2:150000:24': 88.1,
    'option-4:85000:41': 238.37,
    'option-3:250000:45': 403.04,
    'option-4:35000:58': 320.93,
    'option-1:35000:7': 27.36,
    'option-3:30000:36': 46.27,
    'option-2:200000:7': 112.59,
    'option-3:60000:33': 63.18,
    'option-2:15000:1': 22.43,
    'option-3:350000:58': 1624.38,
    'option-1:95000:44': 140.53,
    'option-3:30000:17': 31.61,
    'option-3:40000:54': 143.36,
    'option-4:350000:40': 621.06,
    'option-3:75000:13': 56.91,
    'option-4:300000:19': 228.15,
    'option-1:90000:37': 92.84,
    'option-1:45000:12': 30.86,
    'option-4:55000:3': 53.09,
    'option-3:70000:55': 244.94,
    'option-1:70000:19': 39.52,
    'option-4:30000:11': 35.47,
    'option-4:40000:20': 42.52,
    'option-4:10000:18': 22.21,
    'option-4:150000:27': 148.46,
    'option-2:75000:6': 51.39,
    'option-2:35000:31': 47.96,
    'option-2:400000:34': 408.41,
    'option-2:450000:33': 456.94,
    'option-1:10000:12': 19.02,
    'option-2:80000:4': 53.84,
    'option-1:500000:21': 192.42,
    'option-3:95000:42': 160.89,
    'option-4:20000:29': 31.92,
    'option-4:60000:10': 56.61,
    'option-4:55000:1': 53.09,
    'option-1:400000:56': 1755.82,
    'option-2:40000:23': 34.38,
    'option-3:95000:4': 68.23,
    'option-4:90000:43': 251.81,
    'option-4:85000:4': 74.07,
    'option-4:30000:32': 49.13,
    'option-1:15000:4': 20.58,
    'option-3:10000:52': 46.19,
    'option-1:300000:21': 122.59,
    'option-1:15000:50': 43.69,
    'option-3:45000:35': 50.9,
    'option-1:95000:24': 48.27,
    'option-4:300000:50': 1278.33,
    'option-4:55000:0': 53.09,
    'option-1:200000:46': 426.5,
    'option-2:250000:58': 2245.85,
    'option-1:90000:27': 55.54,
    'option-2:20000:44': 59.74,
    'option-1:450000:16': 174.93,
    'option-2:40000:33': 52.87,
    'option-3:200000:37': 232.57,
    'option-3:150000:39': 177.45,
    'option-4:15000:38': 38.98,
    'option-2:75000:40': 126.22,
    'option-4:85000:46': 366.47,
    'option-1:450000:56': 1971.6,
    'option-1:10000:29': 19.94,
    'option-2:15000:39': 36.09,
    'option-4:5000:48': 35.14,
    'option-2:350000:29': 256.27,
    'option-2:15000:44': 48.89,
    'option-2:5000:12': 18.11,
    'option-3:75000:22': 56.91,
    'option-1:55000:17': 34.28,
    'option-2:85000:10': 56.29,
    'option-1:90000:56': 398.96,
    'option-3:55000:6': 45.73,
    'option-3:65000:4': 51.38,
    'option-2:15000:41': 48.89,
    'option-2:250000:21': 136.85,
    'option-4:400000:10': 298.07,
    'option-2:75000:30': 65.49,
    'option-2:400000:11': 212.75,
    'option-4:10000:59': 99.65,
    'option-1:90000:5': 46.52,
    'option-3:70000:43': 122.06,
    'option-1:80000:1': 43.02,
    'option-3:250000:9': 155.61,
    'option-1:40000:42': 66.72,
    'option-4:10000:20': 22.21,
    'option-4:75000:39': 141.98,
    'option-2:35000:38': 65.45,
    'option-3:35000:29': 37.81,
    'option-3:80000:21': 59.74,
    'option-1:400000:9': 157.41,
    'option-2:95000:18': 61.19,
    'option-3:25000:52': 92.89,
    'option-1:45000:11': 30.86,
    'option-2:350000:22': 188.68,
    'option-2:35000:17': 31.93,
    'option-3:80000:9': 59.74,
    'option-2:45000:29': 45.13,
    'option-3:90000:40': 111.87,
    'option-4:65000:50': 281.76,
    'option-2:150000:21': 88.1,
    'option-1:250000:36': 233.2,
    'option-3:55000:17': 45.73,
    'option-4:10000:41': 39.9,
    'option-3:25000:1': 28.78,
    'option-3:500000:4': 299.59,
    'option-4:70000:3': 63.65,
    'option-3:50000:20': 42.91,
    'option-1:200000:49': 426.5,
    'option-4:400000:18': 298.07,
    'option-4:15000:12': 25.34,
    'option-3:75000:34': 75.45,
    'option-2:450000:50': 1810.35,
    'option-2:85000:9': 56.29,
    'option-3:35000:10': 34.43,
    'option-3:15000:3': 23.45,
    'option-3:45000:38': 62.78,
    'option-1:450000:0': 174.93,
    'option-3:70000:30': 61.2,
    'option-4:450000:22': 333.6,
    'option-1:15000:9': 20.58,
    'option-3:15000:56': 78.48,
    'option-2:15000:25': 22.43,
    'option-3:300000:13': 186.71,
    'option-4:45000:6': 46.04,
    'option-2:100000:2': 63.64,
    'option-2:100000:21': 63.64,
    'option-4:30000:21': 35.47,
    'option-2:40000:47': 167.43,
    'option-2:350000:3': 188.68,
    'option-1:35000:48': 84.04,
    'option-3:70000:40': 90.18,
    'option-2:20000:34': 33.32,
    'option-2:100000:52': 630.83,
    'option-3:350000:3': 215.07,
    'option-1:70000:48': 156.68,
    'option-1:95000:22': 48.27,
    'option-2:300000:23': 164.12,
    'option-4:5000:28': 20.0,
    'option-1:80000:24': 43.02,
    'option-1:250000:17': 102.34,
    'option-1:10000:55': 44.1,
    'option-4:90000:17': 77.6,
    'option-1:350000:14': 140.14,
    'option-2:35000:27': 38.29,
    'option-2:65000:54': 411.59,
    'option-4:80000:2': 70.54,
    'option-3:400000:45': 641.6,
    'option-2:25000:17': 27.04,
    'option-3:25000:4': 28.78,
    'option-2:60000:41': 158.44,
    'option-3:55000:1': 45.73,
    'option-2:250000:27': 184.9,
    'option-2:400000:60': 3607.06,
    'option-1:400000:20': 157.41,
    'option-2:5000:41': 27.09,
    'option-3:40000:3': 37.26,
    'option-3:400000:0': 242.84,
    'option-1:60000:59': 268.93,
    'option-3:10000:11': 20.97,
    'option-1:45000:31': 41.65,
    'option-4:400000:5': 298.07,
    'option-1:15000:14': 20.58,
    'option-3:200000:29': 148.0,
    'option-2:500000:41': 1246.05,
    'option-4:20000:33': 37.18,
    'option-2:40000:24': 34.38,
    'option-2:30000:53': 191.08,
    'option-2:45000:44': 121.37,
    'option-1:40000:38': 49.07,
    'option-1:25000:35': 29.6,
    'option-2:80000:28': 68.9,
    'option-2:350000:52': 2209.54,
    'option-2:45000:13': 36.83,
    'option-2:65000:51': 411.59,
    'option-4:350000:3': 263.51,
    'option-2:200000:50': 808.11,
    'option-2:20000:52': 128.24,
    'option-3:150000:31': 136.57,
    'option-2:90000:59': 808.49,
    'option-1:100000:11': 50.02,
    'option-3:90000:12': 65.4,
    'option-4:45000:41': 130.8,
    'option-1:75000:28': 48.74,
    'option-1:75000:10': 41.27,
    'option-3:20000:26': 27.79,
    'option-3:450000:0': 271.3,
    'option-2:250000:51': 1573.4,
    'option-1:70000:59': 312.6,
    'option-1:250000:38': 233.2,
    'option-3:95000:52': 328.65,
    'option-3:35000:0': 34.43,
    'option-3:75000:26': 64.4,
    'option-4:400000:23': 298.07,
    'option-1:200000:11': 84.98,
    'option-2:25000:5': 27.04,
    'option-2:15000:28': 25.04,
    'option-2:60000:15': 44.16,
    'option-3:80000:49': 194.98,
    'option-4:25000:27': 36.41,
    'option-2:20000:13': 24.6,
    'option-4:15000:20': 25.34,
    'option-2:55000:32': 67.57,
    'option-4:40000:8': 42.52,
    'option-4:50000:29': 58.86,
    'option-3:150000:45': 247.33,
    'option-4:150000:47': 641.57,
    'option-3:20000:36': 35.42,
    'option-3:400000:22': 242.84,
    'option-3:10000:31': 23.22,
    'option-4:400000:14': 298.07,
    'option-4:85000:59': 784.62,
    'option-2:450000:45': 1122.36,
    'option-1:350000:31': 227.99,
    'option-3:450000:18': 271.3,
    'option-2:350000:26': 256.27,
    'option-1:90000:33': 68.63,
    'option-1:55000:9': 34.28,
    'option-1:40000:0': 29.11,
    'option-3:450000:41': 719.1,
    'option-2:250000:8': 136.85,
    'option-2:45000:17': 36.83,
    'option-4:85000:29': 90.12,
    'option-3:20000:15': 25.96,
    'option-2:15000:43': 48.89,
    'option-1:60000:18': 36.02,
    'option-1:20000:9': 22.13,
    'option-4:150000:59': 1387.32,
    'option-4:15000:49': 72.58,
    'option-3:15000:34': 26.85,
    'option-3:70000:41': 122.06,
    'option-3:60000:29': 54.52,
    'option-3:75000:57': 352.66,
    'option-3:85000:2': 62.57,
    'option-2:150000:16': 88.1,
    'option-3:90000:45': 153.07,
    'option-3:60000:17': 48.56,
    'option-1:450000:37': 411.74,
    'option-2:40000:12': 34.38,
    'option-1:40000:12': 29.11,
    'option-1:45000:40': 53.47,
    'option-2:400000:9': 212.75,
    'option-2:95000:58': 851.7,
    'option-3:80000:55': 278.73,
    'option-3:30000:3': 31.61,
    'option-2:80000:35': 92.07,
    'option-4:70000:59': 645.44,
    'option-1:75000:22': 41.27,
    'option-4:60000:2': 56.61,
    'option-1:100000:23': 50.02,
    'option-1:20000:22': 22.13,
    'option-3:300000:46': 703.53,
    'option-3:350000:10': 215.07,
    'option-1:350000:48': 740.69,
    'option-1:500000:51': 1582.54,
    'option-1:35000:33': 35.63,
    'option-1:80000:18': 43.02,
    'option-2:30000:31': 43.04,
    'option-3:90000:52': 311.79,
    'option-4:300000:3': 228.15,
    'option-3:15000:58': 78.48,
    'option-2:55000:6': 41.71,
    'option-2:85000:26': 72.32,
    'option-2:20000:10': 24.6,
    'option-3:200000:21': 127.59,
    'option-2:75000:47': 307.57,
    'option-1:85000:17': 44.77,
    'option-1:35000:46': 84.04,
    'option-4:90000:59': 831.0,
    'option-2:75000:29': 65.49,
    'option-1:90000:21': 46.52,
    'option-2:20000:53': 128.24,
    'option-4:250000:53': 1632.05,
    'option-2:35000:3': 31.93,
    'option-3:5000:51': 31.23,
    'option-4:20000:59': 181.91,
    'option-2:75000:31': 87.17,
    'option-2:250000:48': 1008.58,
    'option-2:55000:7': 41.71,
    'option-1:100000:53': 322.79,
    'option-1:20000:52': 71.83,
    'option-1:450000:49': 947.42,
    'option-2:25000:53': 159.39,
    'option-4:90000:2': 77.6,
    'option-1:15000:12': 20.58,
    'option-4:90000:37': 167.6,
    'option-3:20000:4': 25.96,
    'option-2:55000:10': 41.71,
    'option-2:95000:19': 61.19,
    'option-2:60000:44': 158.44,
    'option-2:100000:57': 896.74,
    'option-2:60000:33': 72.47,
    'option-2:90000:24': 58.74,
    'option-3:50000:9': 42.91,
    'option-4:55000:17': 53.09,
    'option-2:200000:10': 112.59,
    'option-2:400000:3': 212.75,
    'option-1:350000:40': 324.23,
    'option-1:450000:40': 411.74,
    'option-1:50000:8': 32.6,
    'option-2:450000:7': 237.36,
    'option-4:80000:54': 524.6,
    'option-4:10000:5': 22.21,
    'option-1:90000:40': 92.84,
    'option-2:150000:26': 116.73,
    'option-1:500000:33': 318.09,
    'option-4:85000:10': 74.07,
    'option-1:95000:45': 140.53,
    'option-4:70000:54': 459.2,
    'option-3:100000:14': 71.05,
    'option-4:5000:46': 35.14,
    'option-1:65000:25': 37.77,
    'option-2:40000:21': 34.38,
    'option-1:80000:56': 356.26,
    'option-4:350000:10': 263.51,
    'option-3:350000:39': 399.67,
    'option-1:30000:37': 40.28,
    'option-3:400000:51': 1362.29,
    'option-4:30000:10': 35.47,
    'option-1:25000:39': 35.93,
    'option-2:85000:34': 96.77,
    'option-2:40000:11': 34.38,
    'option-2:20000:12': 24.6,
    'option-4:450000:42': 1220.48,
    'option-3:50000:55': 177.33,
    'option-2:15000:5': 22.43,
    'option-1:250000:26': 127.8,
    'option-1:150000:25': 67.5,
    'option-1:85000:50': 187.83,
    'option-2:50000:3': 39.27,
    'option-1:250000:25': 102.34,
    'option-1:90000:23': 46.52,
    'option-4:85000:47': 366.47,
    'option-1:75000:32': 59.58,
    'option-4:10000:37': 31.39,
    'option-4:60000:43': 171.16,
    'option-4:75000:4': 67.02,
    'option-2:45000:5': 36.83,
    'option-4:350000:22': 263.51,
    'option-4:15000:5': 25.34,
    'option-1:95000:30': 57.8,
    'option-2:200000:32': 209.63,
    'option-1:45000:19': 30.86,
    'option-3:95000:48': 229.02,
    'option-4:500000:46': 2124.34,
    'option-3:25000:58': 121.81,
    'option-4:95000:42': 264.68,
    'option-2:85000:38': 141.39,
    'option-4:300000:57': 2779.29,
    'option-1:55000:19': 34.28,
    'option-3:60000:2': 48.56,
    'option-1:80000:16': 43.02,
    'option-3:5000:36': 20.86,
    'option-2:25000:35': 38.22,
    'option-4:10000:1': 22.21,
    'option-4:70000:21': 63.65,
    'option-3:20000:0': 25.96,
    'option-3:95000:26': 77.78,
    'option-3:35000:19': 34.43,
    'option-4:450000:34': 553.12,
    'option-3:450000:33': 384.52,
    'option-1:150000:56': 661.69,
    'option-4:15000:28': 27.93,
    'option-4:250000:54': 1632.05,
    'option-3:85000:38': 106.4,
    'option-2:20000:57': 177.35,
    'option-4:75000:13': 67.02,
    'option-1:10000:5': 19.02,
    'option-1:35000:52': 119.11,
    'option-1:5000:15': 17.45,
    'option-4:45000:19': 46.04,
    'option-4:80000:5': 70.54,
    'option-2:70000:10': 48.94,
    'option-3:350000:48': 819.06,
    'option-1:65000:24': 37.77,
    'option-3:40000:45': 75.04,
    'option-2:10000:38': 29.44,
    'option-1:60000:28': 42.04,
    'option-3:500000:40': 563.64,
    'option-1:100000:52': 322.79,
    'option-3:50000:51': 177.33,
    'option-4:30000:22': 35.47,
    'option-3:20000:41': 43.77,
    'option-4:45000:12': 46.04,
    'option-4:5000:40': 23.72,
    'option-1:300000:54': 954.21,
    'option-2:35000:28': 38.29,
    'option-1:20000:26': 23.95,
    'option-2:30000:9': 29.49,
    'option-1:90000:55': 291.33,
    'option-2:200000:54': 1261.86,
    'option-4:300000:22': 228.15,
    'option-1:25000:49': 63.23,
    'option-1:65000:0': 37.77,
    'option-4:150000:15': 119.82,
    'option-1:20000:32': 26.6,
    'option-4:20000:9': 28.43,
    'option-4:450000:25': 333.6,
    'option-2:40000:30': 41.71,
    'option-2:60000:21': 44.16,
    'option-3:200000:53': 683.85,
    'option-2:5000:10': 18.11,
    'option-2:40000:51': 254.22,
    'option-4:75000:42': 211.49,
    'option-4:10000:53': 74.49,
    'option-2:400000:12': 212.75,
    'option-4:200000:20': 155.14,
    'option-2:90000:51': 569.17,
    'option-4:400000:49': 1702.7,
    'option-3:50000:17': 42.91,
    'option-3:10000:25': 20.97,
    'option-1:55000:27': 39.78,
    'option-3:350000:9': 215.07,
    'option-2:90000:42': 232.5,
    'option-2:50000:34': 62.67,
    'option-3:15000:25': 23.45,
    'option-3:45000:58': 214.09,
    'option-4:250000:45': 681.73,
    'option-4:80000:38': 150.64,
    'option-4:15000:39': 38.98,
    'option-2:50000:37': 88.28,
    'option-3:250000:58': 1157.45,
    'option-3:100000:47': 240.56,
    'option-1:500000:58': 2190.49,
    'option-4:45000:17': 46.04,
    'option-4:45000:44': 130.8,
    'option-3:100000:49': 240.56,
    'option-1:250000:55': 793.19,
    'option-2:100000:9': 63.64,
    'option-2:300000:26': 221.94,
    'option-3:50000:39': 68.26,
    'option-4:15000:60': 140.7,
    'option-2:35000:47': 147.38,
    'option-2:20000:55': 128.24,
    'option-3:100000:5': 71.05,
    'option-3:95000:60': 443.93,
    'option-2:350000:18': 188.68,
    'option-1:450000:19': 174.93,
    'option-4:80000:56': 738.23,
    'option-1:90000:49': 197.79,
    'option-4:350000:19': 263.51,
    'option-2:300000:7': 164.12,
    'option-3:55000:13': 45.73,
    'option-3:250000:8': 155.61,
    'option-3:75000:7': 56.91,
    'option-3:350000:20': 215.07,
    'option-3:400000:4': 242.84,
    'option-2:500000:53': 3152.52,
    'option-1:200000:31': 134.94,
    'option-4:400000:1': 298.07,
    'option-3:45000:15': 40.08,
    'option-4:75000:32': 102.88,
    'option-3:35000:49': 91.44,
    'option-1:35000:41': 59.96,
    'option-2:55000:12': 41.71,
    'option-4:200000:45': 547.26,
    'option-1:15000:51': 58.03,
    'option-4:50000:16': 49.57,
    'option-1:30000:51': 103.36,
    'option-3:45000:46': 114.36,
    'option-3:500000:20': 299.59,
    'option-3:45000:40': 62.78,
    'option-2:95000:56': 851.7,
    'option-3:95000:13': 68.23,
    'option-3:85000:52': 295.62,
    'option-4:70000:17': 63.65,
    'option-4:60000:18': 56.61,
    'option-2:450000:36': 696.98,
    'option-4:65000:46': 281.76,
    'option-3:70000:15': 54.09,
    'option-3:400000:28': 283.75,
    'option-2:10000:54': 72.37,
    'option-1:200000:32': 134.94,
    'option-4:55000:48': 239.39,
    'option-3:5000:56': 37.5,
    'option-4:100000:9': 84.65,
    'option-4:10000:22': 22.21,
    'option-4:100000:0': 84.65,
    'option-4:80000:31': 108.85,
    'option-1:100000:39': 101.65,
    'option-4:20000:42': 63.66,
    'option-1:55000:33': 47.67,
    'option-4:80000:9': 70.54,
    'option-3:60000:32': 63.18,
    'option-2:200000:30': 151.02,
    'option-3:70000:20': 54.09,
    'option-2:70000:46': 287.53,
    'option-1:60000:21': 36.02,
    'option-3:15000:52': 61.16,
    'option-2:200000:26': 151.02,
    'option-2:450000:19': 237.36,
    'option-1:350000:8': 140.14,
    'option-2:80000:27': 68.9,
    'option-2:95000:42': 244.35,
    'option-4:30000:3': 35.47,
    'option-4:50000:57': 459.74,
    'option-3:400000:59': 1855.43,
    'option-1:55000:34': 47.67,
    'option-1:60000:23': 36.02,
    'option-1:350000:53': 1111.99,
    'option-4:250000:8': 190.05,
    'option-4:5000:31': 21.31,
    'option-1:100000:16': 50.02,
    'option-1:55000:14': 34.28,
    'option-1:95000:12': 48.27,
    'option-2:75000:19': 51.39,
    'option-4:100000:55': 653.99,
    'option-2:50000:4': 39.27,
    'option-2:50000:57': 447.49,
    'option-2:200000:34': 209.63,
    'option-3:85000:37': 106.4,
    'option-4:60000:38': 115.98,
    'option-3:350000:47': 819.06,
    'option-3:300000:14': 186.71,
    'option-4:75000:36': 141.98,
    'option-3:60000:34': 63.18,
    'option-2:85000:44': 220.16,
    'option-1:75000:52': 244.83,
    'option-4:50000:8': 49.57,
    'option-4:500000:32': 612.97,
    'option-3:70000:52': 244.94,
    'option-4:85000:60': 784.62,
    'option-4:85000:35': 114.58,
    'option-3:300000:47': 703.53,
    'option-2:300000:19': 164.12,
    'option-3:70000:3': 54.09,
    'option-3:40000:20': 37.26,
    'option-2:55000:14': 41.71,
    'option-1:200000:29': 105.35,
    'option-2:35000:50': 147.38,
    'option-3:100000:52': 345.58,
    'option-4:500000:2': 368.87,
    'option-2:60000:0': 44.16,
    'option-2:75000:33': 87.17,
    'option-4:50000:45': 144.26,
    'option-3:15000:33': 26.85,
    'option-4:10000:10': 22.21,
    'option-2:30000:8': 29.49,
    'option-2:25000:36': 50.41,
    'option-2:20000:24': 24.6,
    'option-4:5000:57': 58.73,
    'option-3:350000:7': 215.07,
    'option-1:45000:43': 73.46,
    'option-3:200000:39': 232.57,
    'option-3:80000:12': 59.74,
    'option-4:15000:0': 25.34,
    'option-1:10000:9': 19.02,
    'option-1:250000:40': 233.2,
    'option-2:35000:25': 31.93,
    'option-3:75000:33': 75.45,
    'option-4:10000:25': 22.21,
    'option-1:350000:21': 140.14,
    'option-2:25000:34': 38.22,
    'option-1:10000:10': 19.02,
    'option-3:200000:22': 127.59,
    'option-2:250000:46': 1008.58,
    'option-3:20000:42': 43.77,
    'option-1:55000:24': 34.28,
    'option-1:95000:60': 420.75,
    'option-1:80000:2': 43.02,
    'option-1:50000:22': 32.6,
    'option-1:75000:30': 48.74,
    'option-2:500000:59': 4505.25,
    'option-1:25000:22': 23.87,
    'option-1:30000:47': 73.64,
    'option-4:450000:16': 333.6,
    'option-3:60000:60': 283.4,
    'option-3:95000:29': 77.78,
    'option-3:500000:23': 299.59,
    'option-1:35000:30': 30.74,
    'option-4:100000:13': 84.65,
    'option-3:65000:46': 160.45,
    'option-2:75000:7': 51.39,
    'option-3:80000:34': 79.37,
    'option-1:25000:59': 116.12,
    'option-1:55000:21': 34.28,
    'option-4:5000:42': 28.05,
    'option-1:40000:34': 38.64,
    'option-1:30000:55': 103.36,
    'option-4:35000:24': 39.0,
    'option-4:30000:1': 35.47,
    'option-1:5000:29': 17.96,
    'option-3:15000:47': 46.71,
    'option-3:450000:58': 2083.57,
    'option-1:65000:10': 37.77,
    'option-3:95000:30': 77.78,
    'option-4:80000:41': 224.93,
    'option-4:20000:54': 132.46,
    'option-1:70000:24': 39.52,
    'option-1:60000:4': 36.02,
    'option-2:400000:42': 1000.28,
    'option-2:45000:32': 57.77,
    'option-2:35000:19': 31.93,
    'option-4:65000:23': 60.13,
    'option-1:25000:56': 116.12,
    'option-3:15000:20': 23.45,
    'option-3:100000:28': 81.13,
    'option-2:450000:28': 324.29,
    'option-1:55000:8': 34.28,
    'option-3:50000:45': 90.72,
    'option-1:90000:2': 46.52,
    'option-1:300000:45': 419.16,
    'option-3:45000:47': 114.36,
    'option-4:10000:36': 31.39,
    'option-2:5000:39': 22.74,
    'option-3:100000:50': 240.56,
    'option-2:350000:33': 359.35,
    'option-3:95000:19': 68.23,
    'option-4:85000:42': 238.37,
    'option-4:90000:41': 251.81,
    'option-2:25000:43': 72.08,
    'option-1:10000:6': 19.02,
    'option-1:20000:55': 71.83,
    'option-3:80000:38': 101.14,
    'option-3:5000:4': 18.47,
    'option-3:60000:19': 48.56,
    'option-2:150000:17': 88.1,
    'option-4:450000:1': 333.6,
    'option-1:50000:19': 32.6,
    'option-4:30000:38': 64.05,
    'option-3:25000:29': 31.12,
    'option-1:30000:59': 138.04,
    'option-3:75000:11': 56.91,
    'option-2:5000:5': 18.11,
    'option-2:300000:58': 2702.97,
    'option-2:500000:5': 261.85,
    'option-1:30000:2': 25.62,
    'option-4:200000:48': 853.05,
    'option-2:40000:42': 108.97,
    'option-1:45000:22': 30.86,
    'option-1:50000:44': 80.2,
    'option-3:30000:20': 31.61,
    'option-2:200000:53': 1261.86,
    'option-3:25000:45': 51.6,
    'option-2:90000:54': 569.17,
    'option-3:20000:57': 98.96,
    'option-2:80000:8': 53.84,
    'option-4:60000:22': 56.61,
    'option-1:45000:37': 53.47,
    'option-1:450000:23': 174.93,
    'option-4:350000:21': 263.51,
    'option-3:100000:46': 240.56,
    'option-2:80000:59': 718.28,
    'option-3:500000:26': 350.91,
    'option-4:350000:15': 263.51,
    'option-2:60000:27': 55.38,
    'option-1:70000:51': 229.1,
    'option-1:150000:5': 67.5,
    'option-4:90000:19': 77.6,
    'option-2:55000:11': 41.71,
    'option-2:500000:21': 261.85,
    'option-2:300000:43': 752.86,
    'option-1:60000:24': 36.02,
    'option-3:85000:11': 62.57,
    'option-1:250000:16': 102.34,
    'option-3:75000:40': 95.66,
    'option-2:400000:36': 621.91,
    'option-1:35000:19': 27.36,
    'option-2:40000:39': 73.09,
    'option-4:50000:10': 49.57,
    'option-3:20000:34': 30.48,
    'option-2:350000:38': 545.96,
    'option-2:350000:13': 188.68,
    'option-4:45000:53': 295.52,
    'option-3:40000:27': 41.16,
    'option-4:50000:53': 328.31,
    'option-2:150000:38': 239.97,
    'option-4:250000:4': 190.05,
    'option-2:20000:16': 24.6,
    'option-3:100000:30': 81.13,
    'option-2:250000:60': 2245.85,
    'option-3:15000:30': 24.85,
    'option-2:30000:19': 29.49,
    'option-1:20000:37': 31.54,
    'option-4:10000:43': 39.9,
    'option-3:150000:36': 177.45,
    'option-2:60000:28': 55.38,
    'option-3:5000:6': 18.47,
    'option-4:200000:5': 155.14,
    'option-3:55000:35': 59.09,
    'option-1:35000:49': 84.04,
    'option-2:15000:35': 28.98,
    'option-2:45000:33': 57.77,
    'option-1:85000:25': 44.77,
    'option-2:55000:38': 95.87,
    'option-3:60000:55': 211.14,
    'option-3:80000:51': 278.73,
    'option-3:100000:17': 71.05,
    'option-4:25000:41': 77.1,
    'option-2:15000:19': 22.43,
    'option-3:400000:27': 283.75,
    'option-1:80000:33': 62.59,
    'option-3:250000:13': 155.61,
    'option-1:50000:18': 32.6,
    'option-2:25000:0': 27.04,
    'option-1:300000:16': 122.59,
    'option-2:75000:20': 51.39,
    'option-1:100000:41': 147.27,
    'option-2:5000:52': 44.52,
    'option-3:5000:19': 18.47,
    'option-1:75000:57': 334.43,
    'option-2:200000:11': 112.59,
    'option-3:5000:22': 18.47,
    'option-2:300000:59': 2702.97,
    'option-4:95000:18': 81.12,
    'option-3:35000:30': 37.81,
    'option-3:250000:46': 584.96,
    'option-2:50000:53': 316.98,
    'option-4:200000:22': 155.14,
    'option-3:40000:59': 191.23,
    'option-2:95000:31': 106.57,
    'option-2:55000:60': 492.66,
    'option-4:70000:14': 63.65,
    'option-2:85000:40': 141.39,
    'option-4:70000:39': 133.31,
    'option-2:45000:38': 80.69,
    'option-1:5000:25': 17.45,
    'option-4:15000:11': 25.34,
    'option-2:200000:20': 112.59,
    'option-3:30000:50': 79.91,
    'option-1:25000:33': 29.6,
    'option-1:70000:27': 46.47,
    'option-4:70000:23': 63.65,
    'option-4:300000:55': 1963.97,
    'option-1:100000:19': 50.02,
    'option-1:70000:34': 56.57,
    'option-4:40000:52': 263.19,
    'option-2:300000:45': 752.86,
    'option-1:35000:42': 59.96,
    'option-3:450000:44': 719.1,
    'option-3:75000:15': 56.91,
    'option-4:55000:57': 506.19,
    'option-3:80000:14': 59.74,
    'option-2:250000:23': 136.85,
    'option-1:40000:30': 33.0,
    'option-3:75000:55': 261.83,
    'option-2:10000:57': 97.36,
    'option-2:5000:16': 18.11,
    'option-4:20000:12': 28.43,
    'option-2:75000:5': 51.39,
    'option-3:450000:30': 317.45,
    'option-3:85000:46': 206.5,
    'option-3:50000:32': 55.0,
    'option-3:85000:16': 62.57,
    'option-3:95000:35': 91.65,
    'option-3:45000:59': 214.09,
    'option-2:500000:25': 261.85,
    'option-1:70000:21': 39.52,
    'option-1:90000:13': 46.52,
    'option-2:20000:49': 87.32,
    'option-1:450000:31': 287.92,
    'option-3:35000:54': 126.78,
    'option-2:90000:26': 75.75,
    'option-4:60000:58': 552.62,
    'option-1:15000:49': 43.69,
    'option-2:10000:58': 97.36,
    'option-3:95000:55': 328.65,
    'option-2:80000:2': 53.84,
    'option-3:400000:15': 242.84,
    'option-1:5000:47': 25.29,
    'option-4:100000:21': 84.65,
    'option-1:25000:34': 29.6,
    'option-3:50000:37': 68.26,
    'option-4:250000:39': 444.19,
    'option-1:5000:40': 19.87,
    'option-2:250000:33': 258.17,
    'option-1:400000:18': 157.41,
    'option-2:20000:15': 24.6,
    'option-3:45000:32': 50.9,
    'option-2:70000:20': 48.94,
    'option-4:300000:21': 228.15,
    'option-3:55000:8': 45.73,
    'option-1:15000:21': 20.58,
    'option-2:50000:28': 48.55,
    'option-3:60000:43': 106.4,
    'option-1:20000:58': 94.49,
    'option-4:30000:34': 49.13,
    'option-3:60000:40': 79.22,
    'option-4:400000:12': 298.07,
    'option-3:10000:56': 58.04,
    'option-3:5000:33': 19.62,
    'option-2:500000:30': 358.54,
    'option-4:300000:4': 228.15,
    'option-1:65000:50': 146.29,
    'option-1:60000:7': 36.02,
    'option-2:60000:39': 103.46,
    'option-3:70000:34': 71.36,
    'option-4:95000:2': 81.12,
    'option-2:90000:45': 232.5,
    'option-2:40000:10': 34.38,
    'option-2:65000:30': 58.8,
    'option-4:10000:17': 22.21,
    'option-2:50000:13': 39.27,
    'option-2:45000:37': 80.69,
    'option-4:85000:6': 74.07,
    'option-1:50000:41': 80.2,
    'option-2:300000:12': 164.12,
    'option-2:150000:8': 88.1,
    'option-4:50000:60': 459.74,
    'option-2:100000:19': 63.64,
    'option-3:50000:60': 237.2,
    'option-1:75000:43': 113.88,
    'option-4:60000:23': 56.61,
    'option-3:150000:53': 515.11,
    'option-1:50000:34': 44.66,
    'option-4:500000:58': 4632.84,
    'option-2:85000:36': 141.39,
    'option-1:300000:39': 280.08,
    'option-1:80000:7': 43.02,
    'option-4:25000:53': 164.78,
    'option-4:500000:12': 368.87,
    'option-4:100000:52': 653.99,
    'option-3:15000:29': 24.85,
    'option-2:5000:40': 22.74,
    'option-4:25000:34': 43.15,
    'option-1:70000:25': 39.52,
    'option-1:25000:37': 35.93,
    'option-1:95000:8': 48.27,
    'option-1:80000:43': 120.61,
    'option-2:250000:24': 136.85,
    'option-4:250000:46': 1064.89,
    'option-3:65000:60': 306.49,
    'option-2:200000:56': 1799.98,
    'option-4:95000:17': 81.12,
    'option-3:10000:18': 20.97,
    'option-2:70000:27': 62.22,
    'option-3:65000:44': 114.23,
    'option-4:450000:20': 333.6,
    'option-4:200000:39': 358.47,
    'option-2:25000:44': 72.08,
    'option-2:10000:19': 20.26,
    'option-1:70000:42': 107.14,
    'option-2:60000:6': 44.16,
    'option-2:100000:22': 63.64,
    'option-4:15000:26': 27.93,
    'option-4:85000:22': 74.07,
    'option-2:70000:47': 287.53,
    'option-1:75000:60': 334.43,
    'option-3:70000:9': 54.09,
    'option-2:10000:44': 37.93,
    'option-3:80000:26': 67.74,
    'option-4:300000:5': 228.15,
    'option-3:40000:9': 37.26,
    'option-4:70000:57': 645.44,
    'option-1:100000:7': 50.02,
    'option-1:250000:9': 102.34,
    'option-4:40000:37': 81.29,
    'option-2:400000:30': 289.81,
    'option-1:35000:14': 27.36,
    'option-4:50000:24': 49.57,
    'option-3:5000:32': 19.62,
    'option-1:65000:28': 44.21,
    'option-4:40000:29': 49.86,
    'option-2:80000:40': 133.8,
    'option-1:450000:7': 174.93,
    'option-2:50000:40': 88.28,
    'option-2:45000:4': 36.83,
    'option-4:80000:6': 70.54,
    'option-3:250000:24': 155.61,
    'option-2:300000:39': 469.79,
    'option-4:50000:4': 49.57,
    'option-1:35000:37': 44.67,
    'option-3:60000:6': 48.56,
    'option-4:55000:22': 53.09,
    'option-1:15000:29': 21.94,
    'option-1:60000:34': 50.68,
    'option-1:300000:23': 122.59,
    'option-4:30000:50': 133.61,
    'option-3:15000:49': 46.71,
    'option-2:60000:23': 44.16,
    'option-4:35000:50': 154.83,
    'option-1:400000:15': 157.41,
    'option-4:30000:47': 133.61,
    'option-3:40000:36': 57.29,
    'option-1:5000:6': 17.45,
    'option-1:20000:48': 52.84,
    'option-1:95000:32': 71.64,
    'option-4:20000:51': 132.46,
    'option-3:45000:57': 214.09,
    'option-2:450000:11': 237.36,
    'option-1:25000:47': 63.23,
    'option-1:5000:50': 25.29,
    'option-1:500000:42': 688.25,
    'option-2:400000:59': 3607.06,
    'option-1:200000:50': 426.5,
    'option-2:20000:9': 24.6,
    'option-1:60000:36': 66.66,
    'option-2:100000:48': 406.91,
    'option-1:500000:20': 192.42,
    'option-2:65000:59': 582.94,
    'option-3:40000:35': 46.81,
    'option-4:10000:24': 22.21,
    'option-3:450000:23': 271.3,
    'option-1:80000:44': 120.61,
    'option-1:350000:28': 175.95,
    'option-1:200000:39': 189.66,
    'option-3:35000:28': 37.81,
    'option-4:250000:10': 190.05,
    'option-1:30000:0': 25.62,
    'option-4:90000:58': 831.0,
    'option-4:75000:27': 81.33,
    'option-4:65000:25': 60.13,
    'option-4:400000:9': 298.07,
    'option-1:300000:51': 954.21,
    'option-1:35000:8': 27.36,
    'option-1:150000:45': 214.84,
    'option-1:30000:30': 28.47,
    'option-3:30000:53': 109.85,
    'option-4:85000:56': 784.62,
    'option-2:450000:15': 237.36,
    'option-4:25000:43': 77.1,
    'option-1:80000:28': 51.01,
    'option-3:70000:13': 54.09,
    'option-2:250000:17': 136.85,
    'option-4:20000:44': 63.66,
    'option-2:450000:22': 237.36,
    'option-2:150000:3': 88.1,
    'option-1:20000:18': 22.13,
    'option-4:75000:50': 324.12,
    'option-2:100000:8': 63.64,
    'option-4:300000:9': 228.15,
    'option-3:65000:43': 114.23,
    'option-3:400000:5': 242.84,
    'option-3:500000:60': 2315.03,
    'option-4:100000:56': 921.76,
    'option-3:40000:24': 37.26,
    'option-3:60000:52': 211.14,
    'option-1:25000:1': 23.87,
    'option-1:95000:10': 48.27,
    'option-2:25000:23': 27.04,
    'option-2:350000:24': 188.68,
    'option-4:150000:38': 271.91,
    'option-2:150000:43': 380.55,
    'option-1:100000:5': 50.02,
    'option-2:450000:39': 696.98,
    'option-4:30000:27': 40.86,
    'option-3:15000:27': 24.85,
    'option-1:60000:22': 36.02,
    'option-4:500000:40': 880.37,
    'option-1:65000:37': 71.05,
    'option-4:450000:18': 333.6,
    'option-4:25000:7': 31.94,
    'option-1:45000:51': 150.42,
    'option-3:75000:3': 56.91,
    'option-1:450000:14': 174.93,
    'option-1:90000:50': 197.79,
    'option-2:300000:33': 310.13,
    'option-1:500000:49': 1051.46,
    'option-4:15000:57': 140.7,
    'option-4:150000:1': 119.82,
    'option-1:300000:53': 954.21,
    'option-4:60000:47': 260.58,
    'option-3:20000:2': 25.96,
    'option-4:25000:45': 77.1,
    'option-3:60000:47': 148.93,
    'option-2:100000:27': 82.6,
    'option-4:45000:18': 46.04,
    'option-2:15000:21': 22.43,
    'option-4:10000:55': 74.49,
    'option-1:25000:55': 87.57,
    'option-2:90000:47': 367.68,
    'option-3:450000:52': 1529.33,
    'option-3:80000:43': 137.73,
    'option-4:50000:13': 49.57,
    'option-3:45000:20': 40.08,
    'option-2:90000:29': 75.75,
    'option-1:100000:43': 147.27,
    'option-1:35000:59': 159.92,
    'option-3:50000:1': 42.91,
    'option-1:55000:43': 86.94,
    'option-2:20000:2': 24.6,
    'option-1:30000:25': 25.62,
    'option-1:70000:18': 39.52,
    'option-2:25000:27': 31.45,
    'option-3:400000:3': 242.84,
    'option-1:75000:20': 41.27,
    'option-4:400000:17': 298.07,
    'option-2:30000:54': 191.08,
    'option-4:20000:1': 28.43,
    'option-4:85000:0': 74.07,
    'option-2:150000:4': 88.1,
    'option-2:150000:32': 160.34,
    'option-2:75000:21': 51.39,
    'option-1:45000:10': 30.86,
    'option-3:75000:37': 95.66,
    'option-2:65000:34': 77.37,
    'option-4:30000:52': 197.67,
    'option-3:15000:15': 23.45,
    'option-4:90000:33': 120.54,
    'option-1:80000:6': 43.02,
    'option-3:25000:28': 31.12,
    'option-4:90000:31': 120.54,
    'option-3:70000:22': 54.09,
    'option-1:50000:26': 37.52,
    'option-3:80000:54': 278.73,
    'option-4:75000:10': 67.02,
    'option-2:200000:4': 112.59,
    'option-3:50000:44': 90.72,
    'option-2:55000:48': 227.39,
    'option-2:45000:53': 285.38,
    'option-1:30000:46': 73.64,
    'option-2:5000:27': 18.95,
    'option-1:55000:32': 47.67,
    'option-2:45000:9': 36.83,
    'option-3:5000:25': 18.47,
    'option-3:300000:48': 703.53,
    'option-3:70000:19': 54.09,
    'option-3:50000:7': 42.91,
    'option-3:15000:13': 23.45,
    'option-1:80000:48': 177.45,
    'option-2:75000:4': 51.39,
    'option-2:500000:2': 261.85,
    'option-2:30000:17': 29.49,
    'option-4:150000:16': 119.82,
    'option-1:10000:58': 55.8,
    'option-2:200000:22': 112.59,
    'option-3:500000:18': 299.59,
    'option-1:20000:42': 39.85,
    'option-2:90000:22': 58.74,
    'option-4:100000:10': 84.65,
    'option-2:400000:33': 408.41,
    'option-2:10000:55': 72.37,
    'option-4:80000:33': 108.85,
    'option-4:450000:37': 793.57,
    'option-1:45000:50': 104.72,
    'option-2:45000:43': 121.37,
    'option-2:80000:15': 53.84,
    'option-4:55000:27': 63.35,
    'option-2:40000:53': 254.22,
    'option-1:450000:10': 174.93,
    'option-1:10000:34': 21.28,
    'option-4:55000:14': 53.09,
    'option-4:150000:31': 192.49,
    'option-1:300000:49': 636.47,
    'option-4:75000:22': 67.02,
    'option-1:250000:27': 127.8,
    'option-2:40000:17': 34.38,
    'option-1:500000:9': 192.42,
    'option-4:500000:11': 368.87,
    'option-4:350000:27': 331.15,
    'option-2:50000:59': 447.49,
    'option-2:10000:22': 20.26,
    'option-4:100000:34': 132.52,
    'option-3:85000:47': 206.5,
    'option-1:150000:4': 67.5,
    'option-1:80000:31': 62.59,
    'option-4:20000:27': 31.92,
    'option-4:55000:20': 53.09,
    'option-4:10000:27': 23.93,
    'option-1:250000:50': 529.22,
    'option-1:400000:36': 368.23,
    'option-2:30000:56': 267.29,
    'option-2:250000:4': 136.85,
    'option-3:65000:21': 51.38,
    'option-2:5000:51': 44.52,
    'option-4:95000:5': 81.12,
    'option-2:90000:8': 58.74,
    'option-3:30000:27': 34.47,
    'option-2:80000:7': 53.84,
    'option-4:200000:58': 1850.71,
    'option-3:10000:12': 20.97,
    'option-2:50000:24': 39.27,
    'option-1:90000:43': 133.8,
    'option-3:20000:45': 43.77,
    'option-2:100000:38': 163.84,
    'option-3:40000:0': 37.26,
    'option-3:30000:42': 59.45,
    'option-3:350000:57': 1624.38,
    'option-4:30000:17': 35.47,
    'option-4:90000:0': 77.6,
    'option-1:70000:49': 156.68,
    'option-2:40000:48': 167.43,
    'option-4:500000:9': 368.87,
    'option-4:450000:14': 333.6,
    'option-4:40000:3': 42.52,
    'option-2:85000:60': 763.38,
    'option-1:50000:50': 115.12,
    'option-2:40000:7': 34.38,
    'option-3:300000:5': 186.71,
    'option-1:350000:37': 324.23,
    'option-3:20000:47': 56.85,
    'option-3:15000:31': 26.85,
    'option-2:200000:15': 112.59,
    'option-4:5000:54': 45.61,
    'option-2:15000:51': 100.27,
    'option-1:35000:15': 27.36,
    'option-1:35000:58': 159.92,
    'option-4:95000:55': 621.34,
    'option-3:45000:11': 40.08,
    'option-4:85000:11': 74.07,
    'option-2:95000:36': 156.24,
    'option-3:40000:50': 102.8,
    'option-3:5000:60': 37.5,
    'option-3:85000:53': 295.62,
    'option-4:40000:13': 42.52,
    'option-1:45000:41': 73.46,
    'option-1:10000:17': 19.02,
    'option-1:25000:50': 63.23,
    'option-2:70000:21': 48.94,
    'option-3:30000:47': 79.91,
    'option-1:300000:55': 954.21,
    'option-4:450000:30': 420.63,
    'option-3:30000:6': 31.61,
    'option-3:80000:39': 101.14,
    'option-3:40000:6': 37.26,
    'option-3:85000:36': 106.4,
    'option-4:10000:9': 22.21,
    'option-1:5000:45': 21.98,
    'option-3:35000:21': 34.43,
    'option-1:70000:41': 107.14,
    'option-4:200000:49': 853.05,
    'option-3:350000:18': 215.07,
    'option-4:400000:3': 298.07,
    'option-1:95000:42': 140.53,
    'option-3:65000:5': 51.38,
    'option-1:85000:55': 276.28,
    'option-4:200000:29': 193.61,
    'option-4:200000:27': 193.61,
    'option-1:20000:41': 39.85,
    'option-4:40000:18': 42.52,
    'option-3:350000:16': 215.07,
    'option-2:300000:60': 2702.97,
    'option-4:40000:0': 42.52,
    'option-2:75000:25': 51.39,
    'option-2:45000:1': 36.83,
    'option-4:150000:49': 641.57,
    'option-3:150000:42': 247.33,
    'option-3:20000:13': 25.96,
    'option-1:150000:30': 82.7,
    'option-2:70000:44': 183.13,
    'option-3:100000:56': 467.06,
    'option-2:95000:24': 61.19,
    'option-1:60000:40': 66.66,
    'option-3:15000:12': 23.45,
    'option-2:450000:10': 237.36,
    'option-2:15000:38': 36.09,
    'option-1:500000:3': 192.42,
    'option-4:50000:7': 49.57,
    'option-1:5000:52': 30.18,
    'option-3:100000:1': 71.05,
    'option-3:100000:3': 71.05,
    'option-2:400000:24': 212.75,
    'option-4:5000:8': 19.1,
    'option-3:300000:17': 186.71,
    'option-2:55000:9': 41.71,
    'option-4:65000:18': 60.13,
    'option-4:60000:59': 552.62,
    'option-1:70000:38': 75.45,
    'option-3:70000:29': 61.2,
    'option-3:200000:27': 148.0,
    'option-3:250000:3': 155.61,
    'option-4:20000:18': 28.43,
    'option-4:350000:47': 1490.73,
    'option-3:60000:21': 48.56,
    'option-2:10000:48': 51.8,
    'option-1:95000:50': 208.15,
    'option-2:25000:55': 159.39,
    'option-4:20000:55': 132.46,
    'option-4:200000:4': 155.14,
    'option-2:100000:13': 63.64,
    'option-4:350000:1': 263.51,
    'option-4:60000:17': 56.61,
    'option-1:60000:14': 36.02,
    'option-1:40000:5': 29.11,
    'option-4:400000:53': 2619.38,
    'option-3:250000:51': 850.91,
    'option-4:60000:16': 56.61,
    'option-1:85000:47': 187.83,
    'option-2:45000:7': 36.83,
    'option-2:40000:60': 357.68,
    'option-1:45000:32': 41.65,
    'option-1:200000:27': 105.35,
    'option-3:70000:39': 90.18,
    'option-3:15000:26': 24.85,
    'option-4:90000:55': 590.0,
    'option-3:40000:52': 143.36,
    'option-1:35000:0': 27.36,
    'option-1:80000:15': 43.02,
    'option-3:90000:33': 87.56,
    'option-1:500000:23': 192.42,
    'option-4:70000:9': 63.65,
    'option-1:65000:29': 44.21,
    'option-4:150000:55': 982.15,
    'option-4:50000:33': 72.99,
    'option-4:250000:52': 1632.05,
    'option-2:95000:22': 61.19,
    'option-3:85000:58': 398.82,
    'option-3:10000:20': 20.97,
    'option-3:5000:27': 18.94,
    'option-3:95000:28': 77.78,
    'option-2:20000:58': 177.35,
    'option-4:95000:10': 81.12,
    'option-3:80000:0': 59.74,
    'option-4:45000:48': 196.99,
    'option-4:250000:7': 190.05,
    'option-4:10000:16': 22.21,
    'option-4:55000:13': 53.09,
    'option-3:200000:60': 929.24,
    'option-3:55000:29': 51.18,
    'option-4:65000:52': 426.49,
    'option-3:200000:2': 127.59,
    'option-4:60000:14': 56.61,
    'option-4:400000:11': 298.07,
    'option-4:10000:58': 99.65,
    'option-3:95000:38': 117.36,
    'option-4:500000:47': 2124.34,
    'option-4:300000:20': 228.15,
    'option-1:65000:56': 290.77,
    'option-3:350000:8': 215.07,
    'option-1:400000:7': 157.41,
    'option-1:85000:12': 44.77,
    'option-3:300000:12': 186.71,
    'option-2:400000:55': 2525.0,
    'option-2:30000:25': 29.49,
    'option-3:500000:8': 299.59,
    'option-4:60000:0': 56.61,
    'option-2:90000:56': 808.49,
    'option-3:75000:12': 56.91,
    'option-2:20000:21': 24.6,
    'option-1:75000:42': 113.88,
    'option-1:300000:19': 122.59,
    'option-1:10000:47': 34.47,
    'option-4:300000:59': 2779.29,
    'option-3:450000:27': 317.45,
    'option-2:25000:26': 31.45,
    'option-2:45000:50': 187.27,
    'option-4:300000:13': 228.15,
    'option-4:75000:7': 67.02,
    'option-4:85000:38': 159.31,
    'option-4:90000:36': 167.6,
    'option-4:500000:8': 368.87,
    'option-1:30000:42': 53.32,
    'option-1:350000:3': 140.14,
    'option-4:70000:50': 302.94,
    'option-2:35000:1': 31.93,
    'option-2:75000:16': 51.39,
    'option-1:50000:53': 166.17,
    'option-1:10000:59': 55.8,
    'option-3:60000:57': 283.4,
    'option-1:250000:57': 1095.56,
    'option-3:55000:7': 45.73,
    'option-3:150000:47': 356.08,
    'option-3:90000:21': 65.4,
    'option-3:200000:36': 232.57,
    'option-4:90000:21': 77.6,
    'option-1:15000:36': 27.64,
    'option-2:65000:47': 267.49,
    'option-3:55000:23': 45.73,
    'option-2:400000:17': 212.75,
    'option-4:25000:29': 36.41,
    'option-1:350000:38': 324.23,
    'option-4:70000:44': 198.05,
    'option-1:350000:17': 140.14,
    'option-4:20000:4': 28.43,
    'option-3:90000:54': 311.79,
    'option-2:80000:25': 53.84,
    'option-3:450000:31': 384.52,
    'option-3:65000:1': 51.38,
    'option-3:5000:9': 18.47,
    'option-1:300000:37': 280.08,
    'option-4:70000:33': 96.9,
    'option-2:70000:2': 48.94,
    'option-4:15000:55': 103.44,
    'option-4:55000:31': 78.97,
    'option-1:85000:56': 378.08,
    'option-2:85000:45': 220.16,
    'option-3:35000:4': 34.43,
    'option-3:35000:20': 34.43,
    'option-1:400000:28': 198.24,
    'option-4:60000:53': 393.77,
    'option-2:40000:8': 34.38,
    'option-4:55000:32': 78.97,
    'option-3:95000:39': 117.36,
    'option-3:150000:4': 99.29,
    'option-3:450000:36': 508.76,
    'option-2:40000:32': 52.87,
    'option-4:45000:3': 46.04,
    'option-1:85000:14': 44.77,
    'option-3:350000:34': 303.08,
    'option-2:500000:20': 261.85,
    'option-2:250000:5': 136.85,
    'option-3:80000:6': 59.74,
    'option-1:400000:19': 157.41,
    'option-2:55000:54': 348.53,
    'option-2:80000:60': 718.28,
    'option-4:20000:38': 46.71,
    'option-3:80000:58': 375.74,
    'option-3:50000:3': 42.91,
    'option-3:350000:12': 215.07,
    'option-4:300000:8': 228.15,
    'option-2:40000:43': 108.97,
    'option-3:350000:27': 250.95,
    'option-3:45000:34': 50.9,
    'option-3:500000:39': 563.64,
    'option-2:35000:40': 65.45,
    'option-2:150000:18': 88.1,
    'option-2:20000:50': 87.32,
    'option-4:450000:46': 1912.0,
    'option-4:350000:50': 1490.73,
    'option-4:200000:59': 1850.71,
    'option-1:200000:56': 879.72,
    'option-4:200000:36': 358.47,
    'option-4:95000:41': 264.68,
    'option-3:60000:53': 211.14,
    'option-4:100000:7': 84.65,
    'option-4:85000:57': 784.62,
    'option-4:150000:2': 119.82,
    'option-1:450000:12': 174.93,
    'option-2:400000:15': 212.75,
    'option-3:85000:18': 62.57,
    'option-1:200000:37': 189.66,
    'option-4:450000:48': 1912.0,
    'option-3:10000:27': 21.89,
    'option-3:55000:47': 137.41,
    'option-4:200000:56': 1850.71,
    'option-3:150000:59': 698.69,
    'option-1:10000:26': 19.94,
    'option-3:100000:44': 168.74,
    'option-4:85000:50': 366.47,
    'option-4:400000:55': 2619.38,
    'option-1:90000:57': 398.96,
    'option-3:80000:1': 59.74,
    'option-2:80000:52': 506.15,
    'option-4:95000:40': 176.25,
    'option-3:75000:44': 129.9,
    'option-3:90000:0': 65.4,
    'option-3:75000:41': 129.9,
    'option-3:500000:1': 299.59,
    'option-4:65000:34': 90.92,
    'option-2:35000:16': 31.93,
    'option-4:80000:10': 70.54,
    'option-4:35000:2': 39.0,
    'option-4:10000:15': 22.21,
    'option-3:5000:7': 18.47,
    'option-3:35000:26': 37.81,
    'option-1:450000:28': 220.99,
    'option-1:70000:40': 75.45,
    'option-3:20000:28': 27.79,
    'option-2:400000:2': 212.75,
    'option-2:65000:3': 46.5,
    'option-1:95000:33': 71.64,
    'option-3:300000:59': 1392.81,
    'option-3:5000:11': 18.47,
    'option-3:350000:25': 215.07,
    'option-4:300000:18': 228.15,
    'option-2:90000:4': 58.74,
    'option-3:15000:22': 23.45,
    'option-2:10000:49': 51.8,
    'option-2:400000:45': 1000.28,
    'option-1:300000:40': 280.08,
    'option-2:100000:11': 63.64,
    'option-2:55000:35': 67.57,
    'option-4:85000:25': 74.07,
    'option-4:250000:20': 190.05,
    'option-2:40000:49': 167.43,
    'option-1:55000:52': 181.91,
    'option-1:45000:48': 104.72,
    'option-3:150000:5': 99.29,
    'option-1:45000:0': 30.86,
    'option-2:95000:53': 599.37,
    'option-2:95000:48': 386.9,
    'option-4:500000:55': 3270.53,
    'option-2:250000:34': 258.17,
    'option-2:200000:9': 112.59,
    'option-4:300000:60': 2779.29,
    'option-4:55000:23': 53.09,
    'option-1:75000:14': 41.27,
    'option-1:80000:23': 43.02,
    'option-1:450000:29': 220.99,
    'option-4:35000:11': 39.0,
    'option-4:25000:60': 228.29,
    'option-3:150000:21': 99.29,
    'option-2:20000:6': 24.6,
    'option-1:15000:13': 20.58,
    'option-1:40000:2': 29.11,
    'option-3:25000:39': 40.9,
    'option-4:450000:51': 2942.5,
    'option-1:60000:3': 36.02,
    'option-2:60000:30': 55.38,
    'option-4:80000:60': 738.23,
    'option-2:350000:17': 188.68,
    'option-4:5000:41': 28.05,
    'option-4:35000:52': 230.46,
    'option-4:300000:45': 818.16,
    'option-3:300000:57': 1392.81,
    'option-4:40000:25': 42.52,
    'option-4:80000:26': 85.63,
    'option-4:95000:53': 621.34,
    'option-3:90000:38': 111.87,
    'option-1:5000:3': 17.45,
    'option-2:85000:22': 56.29,
    'option-1:100000:1': 50.02,
    'option-3:150000:22': 99.29,
    'option-3:80000:56': 375.74,
    'option-4:450000:50': 1912.0,
    'option-1:65000:36': 71.05,
    'option-4:200000:41': 547.26,
    'option-4:35000:6': 39.0,
    'option-2:300000:29': 221.94,
    'option-4:80000:58': 738.23,
    'option-3:350000:44': 563.2,
    'option-3:10000:14': 20.97,
    'option-3:95000:22': 68.23,
    'option-1:30000:54': 103.36,
    'option-3:40000:41': 75.04,
    'option-1:60000:30': 42.04,
    'option-1:200000:40': 189.66,
    'option-3:50000:42': 90.72,
    'option-1:10000:18': 19.02,
    'option-1:70000:29': 46.47,
    'option-3:450000:28': 317.45,
    'option-4:5000:24': 19.1,
    'option-4:40000:47': 176.02,
    'option-3:450000:19': 271.3,
    'option-4:85000:52': 557.3,
    'option-4:90000:39': 167.6,
    'option-2:85000:11': 56.29,
    'option-1:15000:15': 20.58,
    'option-1:75000:27': 48.74,
    'option-1:450000:9': 174.93,
    'option-4:200000:46': 853.05,
    'option-2:30000:24': 29.49,
    'option-3:5000:30': 18.94,
    'option-2:45000:15': 36.83,
    'option-1:20000:40': 31.54,
    'option-3:25000:33': 34.49,
    'option-1:75000:6': 41.27,
    'option-4:30000:48': 133.61,
    'option-3:45000:3': 40.08,
    'option-1:20000:29': 23.95,
    'option-2:65000:19': 46.5,
    'option-4:150000:0': 119.82,
    'option-3:85000:3': 62.57,
    'option-2:45000:24': 36.83,
    'option-3:15000:54': 61.16,
    'option-2:70000:45': 183.13,
    'option-3:85000:56': 398.82,
    'option-3:80000:11': 59.74,
    'option-2:80000:29': 68.9,
    'option-1:400000:21': 157.41,
    'option-4:60000:24': 56.61,
    'option-2:300000:40': 469.79,
    'option-1:150000:8': 67.5,
    'option-3:95000:32': 91.65,
    'option-4:50000:19': 49.57,
    'option-4:15000:27': 27.93,
    'option-2:40000:28': 41.71,
    'option-1:55000:10': 34.28,
    'option-3:85000:40': 106.4,
    'option-4:45000:11': 46.04,
    'option-4:30000:39': 64.05,
    'option-3:15000:46': 46.71,
    'option-1:20000:12': 22.13,
    'option-3:150000:38': 177.45,
    'option-3:80000:28': 67.74,
    'option-4:5000:9': 19.1,
    'option-4:40000:15': 42.52,
    'option-2:400000:39': 621.91,
    'option-2:200000:8': 112.59,
    'option-4:85000:28': 90.12,
    'option-2:400000:32': 408.41,
    'option-1:75000:11': 41.27,
    'option-3:400000:46': 934.29,
    'option-4:60000:36': 115.98,
    'option-4:50000:54': 328.31,
    'option-3:75000:2': 56.91,
    'option-1:75000:39': 79.84,
    'option-2:75000:42': 195.48,
    'option-4:250000:17': 190.05,
    'option-1:100000:59': 442.63,
    'option-2:250000:47': 1008.58,
    'option-3:150000:52': 515.11,
    'option-3:200000:59': 929.24,
    'option-4:20000:10': 28.43,
    'option-3:45000:28': 44.5,
    'option-4:300000:48': 1278.33,
    'option-4:65000:13': 60.13,
    'option-3:80000:50': 194.98,
    'option-4:5000:10': 19.1,
    'option-4:60000:30': 67.85,
    'option-4:5000:34': 21.31,
    'option-1:250000:30': 127.8,
    'option-2:10000:12': 20.26,
    'option-4:250000:30': 238.13,
    'option-2:80000:44': 207.82,
    'option-4:15000:30': 27.93,
    'option-2:500000:0': 261.85,
    'option-2:500000:56': 4505.25,
    'option-1:95000:16': 48.27,
    'option-2:45000:14': 36.83,
    'option-4:30000:43': 90.59,
    'option-1:200000:59': 879.72,
    'option-4:300000:40': 534.06,
    'option-4:5000:30': 20.0,
    'option-2:25000:7': 27.04,
    'option-2:5000:32': 20.32,
    'option-2:10000:0': 20.26,
    'option-2:450000:1': 237.36,
    'option-3:350000:17': 215.07,
    'option-3:30000:57': 145.0,
    'option-4:450000:5': 333.6,
    'option-4:60000:5': 56.61,
    'option-3:300000:0': 186.71,
    'option-4:45000:37': 89.97,
    'option-4:25000:13': 31.94,
    'option-4:20000:35': 37.18,
    'option-1:150000:20': 67.5,
    'option-2:100000:4': 63.64,
    'option-3:30000:24': 31.61,
    'option-3:95000:2': 68.23,
    'option-3:95000:50': 229.02,
    'option-4:25000:56': 228.29,
    'option-2:300000:6': 164.12,
    'option-1:300000:34': 197.74,
    'option-3:65000:25': 51.38,
    'option-1:70000:60': 312.6,
    'option-1:5000:12': 17.45,
    'option-4:20000:15': 28.43,
    'option-1:40000:60': 181.76,
    'option-1:450000:39': 411.74,
    'option-1:150000:52': 480.59,
    'option-2:5000:44': 27.09,
    'option-3:90000:35': 87.56,
    'option-1:30000:44': 53.32,
    'option-1:5000:10': 17.45,
    'option-2:500000:6': 261.85,
    'option-1:85000:26': 53.27,
    'option-4:350000:4': 263.51,
    'option-1:100000:26': 60.07,
    'option-2:95000:6': 61.19,
    'option-3:70000:21': 54.09,
    'option-3:200000:42': 325.57,
    'option-4:40000:51': 263.19,
    'option-2:400000:58': 3607.06,
    'option-2:85000:8': 56.29,
    'option-3:350000:23': 215.07,
    'option-3:100000:18': 71.05,
    'option-2:90000:36': 148.66,
    'option-3:300000:4': 186.71,
    'option-4:50000:51': 328.31,
    'option-1:40000:45': 66.72,
    'option-3:350000:54': 1193.19,
    'option-3:450000:45': 719.1,
    'option-2:25000:51': 159.39,
    'option-1:95000:39': 97.25,
    'option-3:400000:20': 242.84,
    'option-1:35000:18': 27.36,
    'option-3:25000:18': 28.78,
    'option-4:10000:21': 22.21,
    'option-2:35000:37': 65.45,
    'option-3:95000:43': 160.89,
    'option-3:70000:0': 54.09,
    'option-4:55000:58': 506.19,
    'option-1:40000:54': 134.57,
    'option-2:70000:60': 628.06,
    'option-4:80000:29': 85.63,
    'option-1:75000:45': 113.88,
    'option-2:150000:47': 607.98,
    'option-3:85000:19': 62.57,
    'option-4:300000:10': 228.15,
    'option-4:200000:7': 155.14,
    'option-3:35000:15': 34.43,
    'option-4:350000:17': 263.51,
    'option-1:40000:24': 29.11,
    'option-4:400000:22': 298.07,
    'option-2:75000:50': 307.57,
    'option-4:45000:32': 67.01,
    'option-3:200000:50': 471.07,
    'option-3:30000:56': 145.0,
    'option-2:20000:37': 42.84,
    'option-2:200000:59': 1799.98,
    'option-3:75000:58': 352.66,
    'option-2:60000:12': 44.16,
    'option-1:400000:35': 257.57,
    'option-4:300000:42': 818.16,
    'option-2:450000:27': 324.29,
    'option-3:200000:6': 127.59,
    'option-3:20000:35': 30.48,
    'option-4:350000:43': 953.02,
    'option-2:350000:49': 1411.7,
    'option-1:15000:42': 33.86,
    'option-3:45000:41': 82.88,
    'option-1:450000:27': 220.99,
    'option-1:60000:15': 36.02,
    'option-4:55000:29': 63.35,
    'option-3:10000:13': 20.97,
    'option-3:300000:11': 186.71,
    'option-4:40000:34': 61.03,
    'option-1:90000:30': 55.54,
    'option-2:85000:7': 56.29,
    'option-4:5000:51': 45.61,
    'option-2:5000:4': 18.11,
    'option-1:300000:20': 122.59,
    'option-2:75000:1': 51.39,
    'option-1:200000:16': 84.98,
    'option-1:65000:22': 37.77,
    'option-3:200000:41': 325.57,
    'option-1:55000:36': 62.26,
    'option-2:10000:21': 20.26,
    'option-2:30000:23': 29.49,
    'option-1:45000:26': 35.26,
    'option-2:95000:32': 106.57,
    'option-4:25000:57': 228.29,
    'option-2:30000:46': 127.29,
    'option-4:500000:53': 3270.53,
    'option-3:400000:44': 641.6,
    'option-1:300000:11': 122.59,
    'option-4:80000:21': 70.54,
    'option-2:10000:43': 37.93,
    'option-3:50000:48': 125.89,
    'option-1:450000:25': 174.93,
    'option-3:400000:42': 641.6,
    'option-1:200000:6': 84.98,
    'option-4:200000:35': 252.19,
    'option-2:150000:34': 160.34,
    'option-3:350000:51': 1193.19,
    'option-2:65000:49': 267.49,
    'option-1:70000:30': 46.47,
    'option-3:85000:48': 206.5,
    'option-4:75000:58': 691.84,
    'option-3:30000:18': 31.61,
    'option-4:500000:23': 368.87,
    'option-2:70000:43': 183.13,
    'option-3:85000:32': 83.46,
    'option-2:75000:59': 673.17,
    'option-1:95000:28': 57.8,
    'option-1:250000:13': 102.34,
    'option-4:65000:58': 599.04,
    'option-2:15000:59': 137.28,
    'option-1:95000:0': 48.27,
    'option-2:5000:30': 18.95,
    'option-2:55000:44': 146.09,
    'option-4:250000:29': 238.13,
    'option-1:90000:16': 46.52,
    'option-4:25000:49': 112.34,
    'option-1:350000:7': 140.14,
    'option-3:65000:7': 51.38,
    'option-4:70000:12': 63.65,
    'option-1:450000:48': 947.42,
    'option-4:5000:53': 45.61,
    'option-2:60000:4': 44.16,
    'option-4:400000:36': 707.81,
    'option-3:70000:28': 61.2,
    'option-3:150000:15': 99.29,
    'option-2:150000:44': 380.55,
    'option-4:90000:46': 387.64,
    'option-2:500000:57': 4505.25,
    'option-4:90000:28': 94.62,
    'option-1:500000:30': 243.64,
    'option-1:50000:51': 166.17,
    'option-1:450000:54': 1424.9,
    'option-2:95000:47': 386.9,
    'option-3:65000:11': 51.38,
    'option-3:55000:59': 260.3,
    'option-1:45000:13': 30.86,
    'option-4:80000:27': 85.63,
    'option-2:25000:18': 27.04,
    'option-1:300000:36': 280.08,
    'option-2:450000:34': 456.94,
    'option-2:70000:35': 82.27,
    'option-4:50000:32': 72.99,
    'option-4:75000:34': 102.88,
    'option-2:250000:11': 136.85,
    'option-1:300000:12': 122.59,
    'option-4:45000:54': 295.52,
    'option-1:85000:35': 65.61,
    'option-4:200000:26': 193.61,
    'option-3:75000:35': 75.45,
    'option-4:350000:31': 434.15,
    'option-3:400000:1': 242.84,
    'option-1:60000:53': 197.64,
    'option-2:60000:18': 44.16,
    'option-1:500000:12': 192.42,
    'option-2:200000:29': 151.02,
    'option-2:90000:7': 58.74,
    'option-3:200000:25': 127.59,
    'option-1:10000:52': 44.1,
    'option-3:85000:60': 398.82,
    'option-4:55000:59': 506.19,
    'option-4:5000:19': 19.1,
    'option-1:500000:8': 192.42,
    'option-2:10000:8': 20.26,
    'option-4:20000:49': 91.37,
    'option-1:95000:56': 420.75,
    'option-1:85000:3': 44.77,
    'option-2:90000:19': 58.74,
    'option-3:65000:9': 51.38,
    'option-1:40000:1': 29.11,
    'option-1:75000:19': 41.27,
    'option-1:75000:46': 167.06,
    'option-1:70000:32': 56.57,
    'option-1:15000:26': 21.94,
    'option-2:500000:35': 506.03,
    'option-4:100000:27': 103.63,
    'option-1:75000:56': 334.43,
    'option-3:55000:30': 51.18,
    'option-4:250000:49': 1064.89,
    'option-4:10000:54': 74.49,
    'option-3:65000:39': 84.7,
    'option-4:30000:4': 35.47,
    'option-1:75000:47': 167.06,
    'option-2:95000:41': 244.35,
    'option-1:75000:48': 167.06,
    'option-3:450000:43': 719.1,
    'option-3:300000:2': 186.71,
    'option-1:95000:2': 48.27,
    'option-4:70000:32': 96.9,
    'option-3:250000:50': 584.96,
    'option-1:450000:58': 1971.6,
    'option-4:75000:38': 141.98,
    'option-2:10000:41': 37.93,
    'option-1:75000:2': 41.27,
    'option-2:10000:16': 20.26,
    'option-2:95000:59': 851.7,
    'option-3:35000:34': 42.72,
    'option-2:70000:40': 118.63,
    'option-1:10000:15': 19.02,
    'option-4:85000:44': 238.37,
    'option-3:80000:20': 59.74,
    'option-1:20000:46': 52.84,
    'option-4:500000:41': 1355.18,
    'option-3:85000:30': 71.09,
    'option-3:200000:10': 127.59,
    'option-1:50000:54': 166.17,
    'option-2:60000:1': 44.16,
    'option-1:45000:21': 30.86,
    'option-3:200000:35': 177.65,
    'option-1:20000:0': 22.13,
    'option-3:35000:40': 51.8,
    'option-4:40000:41': 117.28,
    'option-4:15000:32': 31.87,
    'option-4:55000:21': 53.09,
    'option-4:65000:37': 124.65,
    'option-2:75000:46': 307.57,
    'option-4:25000:12': 31.94,
    'option-2:70000:50': 287.53,
    'option-3:65000:48': 160.45,
    'option-3:85000:26': 71.09,
    'option-4:70000:55': 459.2,
    'option-1:45000:16': 30.86,
    'option-1:10000:51': 44.1,
    'option-1:10000:38': 23.73,
    'option-1:30000:41': 53.32,
    'option-1:30000:36': 40.28,
    'option-3:45000:37': 62.78,
    'option-1:35000:39': 44.67,
    'option-1:250000:18': 102.34,
    'option-1:100000:3': 50.02,
    'option-1:5000:53': 30.18,
    'option-1:10000:28': 19.94,
    'option-4:60000:34': 84.95,
    'option-2:150000:27': 116.73,
    'option-1:5000:39': 19.87,
    'option-2:90000:50': 367.68,
    'option-2:45000:45': 121.37,
    'option-1:150000:1': 67.5,
    'option-3:65000:17': 51.38,
    'option-4:40000:30': 49.86,
    'option-4:80000:12': 70.54,
    'option-1:25000:58': 116.12,
    'option-2:55000:52': 348.53,
    'option-1:200000:51': 637.67,
    'option-3:40000:57': 191.23,
    'option-3:250000:27': 181.12,
    'option-3:150000:56': 698.69,
    'option-2:55000:25': 41.71,
    'option-2:50000:33': 62.67,
    'option-2:250000:43': 627.34,
    'option-3:100000:12': 71.05,
    'option-2:500000:22': 261.85,
    'option-4:55000:9': 53.09,
    'option-2:250000:2': 136.85,
    'option-2:400000:29': 289.81,
    'option-4:10000:3': 22.21,
    'option-4:350000:60': 3244.69,
    'option-4:5000:20': 19.1,
    'option-3:35000:12': 34.43,
    'option-4:55000:7': 53.09,
    'option-3:30000:54': 109.85,
    'option-2:80000:33': 92.07,
    'option-1:250000:53': 793.19,
    'option-4:25000:5': 31.94,
    'option-1:65000:39': 71.05,
    'option-1:85000:31': 65.61,
    'option-1:70000:4': 39.52,
    'option-1:85000:48': 187.83,
    'option-1:85000:18': 44.77,
    'option-3:30000:33': 38.62,
    'option-3:20000:43': 43.77,
    'option-1:350000:44': 486.78,
    'option-4:95000:6': 81.12,
    'option-1:350000:46': 740.69,
    'option-3:250000:1': 155.61,
    'option-1:25000:51': 87.57,
    'option-1:30000:23': 25.62,
    'option-4:80000:4': 70.54,
    'option-4:500000:24': 368.87,
    'option-4:25000:11': 31.94,
    'option-3:5000:50': 26.29,
    'option-1:15000:28': 21.94,
    'option-2:15000:11': 22.43,
    'option-1:60000:37': 66.66,
    'option-1:15000:56': 75.13,
    'option-1:60000:16': 36.02,
    'option-3:20000:37': 35.42,
    'option-1:95000:31': 71.64,
    'option-3:500000:50': 1163.55,
    'option-2:50000:1': 39.27,
    'option-1:500000:29': 243.64,
    'option-3:50000:10': 42.91,
    'option-2:25000:9': 27.04,
    'option-2:80000:57': 718.28,
    'option-3:95000:8': 68.23,
    'option-1:450000:24': 174.93,
    'option-2:85000:47': 347.65,
    'option-1:15000:6': 20.58,
    'option-2:40000:3': 34.38,
    'option-3:300000:8': 186.71,
    'option-3:450000:24': 271.3,
    'option-3:60000:28': 54.52,
    'option-2:50000:0': 39.27,
    'option-1:95000:58': 420.75,
    'option-1:55000:7': 34.28,
    'option-4:40000:60': 367.37,
    'option-3:300000:20': 186.71,
    'option-1:250000:44': 348.72,
    'option-1:500000:24': 192.42,
    'option-1:60000:41': 93.68,
    'option-4:100000:39': 184.93,
    'option-3:300000:49': 703.53,
    'option-4:85000:49': 366.47,
    'option-4:15000:51': 103.44,
    'option-1:55000:40': 62.26,
    'option-1:75000:12': 41.27,
    'option-4:45000:43': 130.8,
    'option-4:450000:13': 333.6,
    'option-3:85000:45': 145.56,
    'option-3:400000:58': 1855.43,
    'option-2:100000:12': 63.64,
    'option-1:150000:43': 214.84,
    'option-1:450000:51': 1424.9,
    'option-2:70000:1': 48.94,
    'option-4:40000:19': 42.52,
    'option-1:40000:9': 29.11,
    'option-1:70000:1': 39.52,
    'option-3:45000:60': 214.09,
    'option-4:25000:24': 31.94,
    'option-1:50000:36': 57.87,
    'option-4:15000:18': 25.34,
    'option-1:85000:40': 88.44,
    'option-4:95000:27': 99.13,
    'option-2:200000:13': 112.59,
    'option-2:500000:8': 261.85,
    'option-1:65000:6': 37.77,
    'option-4:45000:7': 46.04,
    'option-1:50000:40': 57.87,
    'option-2:15000:46': 69.54,
    'option-2:500000:1': 261.85,
    'option-2:10000:26': 22.01,
    'option-1:95000:52': 307.03,
    'option-4:250000:58': 2309.26,
    'option-3:100000:26': 81.13,
    'option-2:75000:8': 51.39,
    'option-2:15000:55': 100.27,
    'option-4:85000:40': 159.31,
    'option-4:70000:26': 76.83,
    'option-3:100000:20': 71.05,
    'option-4:25000:2': 31.94,
    'option-4:350000:12': 263.51,
    'option-4:350000:58': 3244.69,
    'option-4:200000:16': 155.14,
    'option-4:20000:60': 181.91,
    'option-3:75000:5': 56.91,
    'option-1:30000:34': 32.61,
    'option-4:15000:13': 25.34,
    'option-3:45000:53': 160.41,
    'option-1:100000:55': 322.79,
    'option-1:45000:6': 30.86,
    'option-3:50000:2': 42.91,
    'option-1:95000:34': 71.64,
    'option-4:25000:38': 55.37,
    'option-1:65000:30': 44.21,
    'option-4:250000:27': 238.13,
    'option-3:70000:2': 54.09,
    'option-3:90000:6': 65.4,
    'option-3:50000:22': 42.91,
    'option-1:350000:41': 486.78,
    'option-2:60000:20': 44.16,
    'option-1:100000:2': 50.02,
    'option-1:350000:32': 227.99,
    'option-3:30000:39': 46.27,
    'option-4:400000:41': 1087.59,
    'option-1:500000:31': 318.09,
    'option-2:20000:17': 24.6,
    'option-3:25000:59': 121.81,
    'option-4:250000:47': 1064.89,
    'option-4:100000:59': 921.76,
    'option-4:30000:24': 35.47,
    'option-4:5000:13': 19.1,
    'option-4:100000:53': 653.99,
    'option-2:45000:0': 36.83,
    'option-2:450000:14': 237.36,
    'option-1:80000:46': 177.45,
    'option-2:150000:53': 947.09,
    'option-4:35000:35': 55.04,
    'option-1:15000:31': 23.92,
    'option-1:80000:47': 177.45,
    'option-2:15000:24': 22.43,
    'option-1:75000:26': 48.74,
    'option-1:90000:3': 46.52,
    'option-3:300000:35': 262.0,
    'option-1:85000:20': 44.77,
    'option-4:80000:49': 345.29,
    'option-1:350000:34': 227.99,
    'option-2:400000:7': 212.75,
    'option-1:55000:29': 39.78,
    'option-1:35000:6': 27.36,
    'option-3:300000:18': 186.71,
    'option-4:95000:36': 176.25,
    'option-3:100000:45': 168.74,
    'option-2:300000:38': 469.79,
    'option-1:500000:47': 1051.46,
    'option-3:500000:55': 1698.69,
    'option-2:95000:40': 156.24,
    'option-2:35000:10': 31.93,
    'option-3:200000:12': 127.59,
    'option-4:5000:23': 19.1,
    'option-4:80000:51': 524.6,
    'option-1:5000:17': 17.45,
    'option-1:40000:27': 33.0,
    'option-2:60000:50': 247.45,
    'option-4:50000:48': 218.19,
    'option-1:25000:41': 46.58,
    'option-1:50000:7': 32.6,
    'option-3:150000:8': 99.29,
    'option-2:70000:49': 287.53,
    'option-4:400000:7': 298.07,
    'option-2:95000:9': 61.19,
    'option-2:300000:17': 164.12,
    'option-2:95000:4': 61.19,
    'option-3:10000:7': 20.97,
    'option-4:400000:35': 493.98,
    'option-3:85000:55': 295.62,
    'option-4:100000:38': 184.93,
    'option-3:15000:5': 23.45,
    'option-1:90000:28': 55.54,
    'option-3:55000:31': 59.09,
    'option-3:80000:36': 101.14,
    'option-3:65000:47': 160.45,
    'option-1:80000:52': 260.55,
    'option-3:90000:32': 87.56,
    'option-1:75000:44': 113.88,
    'option-1:400000:10': 157.41,
    'option-2:20000:29': 28.04,
    'option-3:200000:23': 127.59,
    'option-2:85000:17': 56.29,
    'option-2:45000:6': 36.83,
    'option-1:70000:14': 39.52,
    'option-2:85000:31': 96.77,
    'option-2:90000:12': 58.74,
    'option-3:30000:2': 31.61,
    'option-3:15000:16': 23.45,
    'option-1:450000:4': 174.93,
    'option-3:35000:53': 126.78,
    'option-3:30000:55': 109.85,
    'option-2:50000:21': 39.27,
    'option-4:250000:36': 444.19,
    'option-4:10000:46': 53.83,
    'option-2:60000:34': 72.47,
    'option-4:250000:56': 2309.26,
    'option-2:45000:10': 36.83,
    'option-4:80000:47': 345.29,
    'option-4:15000:7': 25.34,
    'option-3:15000:48': 46.71,
    'option-3:20000:44': 43.77,
    'option-3:40000:32': 46.81,
    'option-3:450000:42': 719.1,
    'option-3:70000:1': 54.09,
    'option-2:80000:26': 68.9,
    'option-4:85000:16': 74.07,
    'option-4:100000:17': 84.65,
    'option-2:350000:60': 3155.5,
    'option-4:90000:5': 77.6,
    'option-3:350000:14': 215.07,
    'option-3:65000:35': 67.27,
    'option-3:250000:15': 155.61,
    'option-3:75000:48': 183.47,
    'option-4:75000:59': 691.84,
    'option-1:90000:1': 46.52,
    'option-1:90000:34': 68.63,
    'option-3:40000:25': 37.26,
    'option-4:200000:1': 155.14,
    'option-4:500000:43': 1355.18,
    'option-3:60000:31': 63.18,
    'option-4:250000:9': 190.05,
    'option-1:65000:32': 53.69,
    'option-2:40000:27': 41.71,
    'option-3:75000:24': 56.91,
    'option-2:300000:20': 164.12,
    'option-2:90000:35': 101.66,
    'option-2:150000:9': 88.1,
    'option-2:40000:44': 108.97,
    'option-3:400000:2': 242.84,
    'option-4:55000:56': 506.19,
    'option-3:85000:44': 145.56,
    'option-2:95000:49': 386.9,
    'option-2:80000:30': 68.9,
    'option-4:450000:53': 2942.5,
    'option-4:95000:0': 81.12,
    'option-4:50000:17': 49.57,
    'option-2:55000:1': 41.71,
    'option-4:200000:53': 1308.78,
    'option-4:100000:6': 84.65,
    'option-1:10000:32': 21.28,
    'option-3:65000:26': 57.86,
    'option-2:60000:43': 158.44,
    'option-4:100000:19': 84.65,
    'option-2:450000:25': 237.36,
    'option-4:200000:31': 252.19,
    'option-3:70000:48': 171.96,
    'option-1:70000:55': 229.1,
    'option-4:40000:49': 176.02,
    'option-3:60000:10': 48.56,
    'option-3:20000:49': 56.85,
    'option-3:150000:11': 99.29,
    'option-1:85000:49': 187.83,
    'option-2:60000:26': 55.38,
    'option-3:20000:29': 27.79,
    'option-1:90000:29': 55.54,
    'option-4:70000:34': 96.9,
    'option-4:20000:20': 28.43,
    'option-2:35000:51': 222.68,
    'option-1:250000:0': 102.34,
    'option-1:25000:5': 23.87,
    'option-1:95000:49': 208.15,
    'option-3:85000:1': 62.57,
    'option-1:85000:9': 44.77,
    'option-4:45000:5': 46.04,
    'option-1:5000:44': 21.98,
    'option-1:10000:21': 19.02,
    'option-1:95000:3': 48.27,
    'option-2:200000:2': 112.59,
    'option-3:80000:25': 59.74,
    'option-2:55000:27': 51.96,
    'option-4:65000:40': 124.65,
    'option-3:450000:47': 1048.19,
    'option-1:20000:23': 22.13,
    'option-1:30000:9': 25.62,
    'option-2:150000:30': 116.73,
    'option-1:100000:4': 50.02,
    'option-4:250000:24': 190.05,
    'option-3:100000:2': 71.05,
    'option-4:45000:38': 89.97,
    'option-1:70000:26': 46.47,
    'option-2:350000:5': 188.68,
    'option-4:150000:11': 119.82,
    'option-2:5000:28': 18.95,
    'option-3:60000:26': 54.52,
    'option-4:80000:17': 70.54,
    'option-2:250000:49': 1008.58,
    'option-1:250000:5': 102.34,
    'option-2:25000:58': 222.44,
    'option-1:65000:33': 53.69,
    'option-2:5000:49': 34.06,
    'option-3:150000:54': 515.11,
    'option-1:250000:34': 164.79,
    'option-2:25000:45': 72.08,
    'option-2:250000:15': 136.85,
    'option-3:40000:31': 46.81,
    'option-2:500000:45': 1246.05,
    'option-3:35000:3': 34.43,
    'option-1:80000:54': 260.55,
    'option-1:5000:34': 18.62,
    'option-1:65000:16': 37.77,
    'option-3:75000:0': 56.91,
    'option-4:25000:58': 228.29,
    'option-3:15000:35': 26.85,
    'option-1:250000:32': 164.79,
    'option-3:80000:42': 137.73,
    'option-3:45000:18': 40.08,
    'option-1:65000:23': 37.77,
    'option-4:400000:28': 376.12,
    'option-2:70000:13': 48.94,
    'option-3:30000:46': 79.91,
    'option-1:85000:34': 65.61,
    'option-2:450000:23': 237.36,
    'option-4:35000:57': 320.93,
    'option-2:300000:24': 164.12,
    'option-2:35000:49': 147.38,
    'option-1:85000:7': 44.77,
    'option-2:40000:1': 34.38,
    'option-4:40000:23': 42.52,
    'option-2:25000:48': 107.16,
    'option-3:500000:59': 2315.03,
    'option-4:85000:53': 557.3,
    'option-3:70000:59': 329.58,
    'option-4:60000:37': 115.98,
    'option-3:55000:3': 45.73,
    'option-2:50000:25': 39.27,
    'option-2:350000:10': 188.68,
    'option-1:400000:42': 554.19,
    'option-1:5000:27': 17.96,
    'option-4:15000:40': 38.98,
    'option-1:70000:12': 39.52,
    'option-4:55000:39': 107.31,
    'option-2:30000:11': 29.49,
    'option-2:5000:48': 34.06,
    'option-1:95000:37': 97.25,
    'option-1:450000:44': 620.81,
    'option-4:60000:15': 56.61,
    'option-4:300000:32': 374.14,
    'option-4:150000:18': 119.82,
    'option-1:450000:32': 287.92,
    'option-3:60000:1': 48.56,
    'option-3:35000:5': 34.43,
    'option-3:80000:4': 59.74,
    'option-3:50000:54': 177.33,
    'option-1:100000:40': 101.65,
    'option-3:300000:45': 484.56,
    'option-2:350000:41': 876.7,
    'option-3:80000:47': 194.98,
    'option-2:5000:55': 44.52,
    'option-3:55000:32': 59.09,
    'option-4:150000:58': 1387.32,
    'option-4:35000:10': 39.0,
    'option-4:20000:22': 28.43,
    'option-4:95000:52': 621.34,
    'option-3:45000:14': 40.08,
    'option-4:10000:42': 39.9,
    'option-1:100000:49': 218.56,
    'option-2:100000:30': 82.6,
    'option-3:30000:41': 59.45,
    'option-1:250000:45': 348.72,
    'option-3:85000:54': 295.62,
    'option-2:55000:30': 51.96,
    'option-1:20000:28': 23.95,
    'option-2:15000:8': 22.43,
    'option-4:40000:1': 42.52,
    'option-2:65000:0': 46.5,
    'option-2:40000:18': 34.38,
    'option-4:50000:31': 72.99,
    'option-2:80000:12': 53.84,
    'option-4:30000:44': 90.59,
    'option-3:500000:19': 299.59,
    'option-3:55000:12': 45.73,
    'option-2:70000:41': 183.13,
    'option-1:30000:12': 25.62,
    'option-4:90000:10': 77.6,
    'option-4:45000:24': 46.04,
    'option-3:65000:33': 67.27,
    'option-4:50000:55': 328.31,
    'option-2:300000:22': 164.12,
    'option-3:350000:36': 399.67,
    'option-1:65000:13': 37.77,
    'option-2:40000:55': 254.22,
    'option-4:95000:30': 99.13,
    'option-4:5000:16': 19.1,
    'option-4:500000:33': 612.97,
    'option-3:500000:56': 2315.03,
    'option-3:10000:43': 29.91,
    'option-2:65000:40': 111.04,
    'option-1:450000:1': 174.93,
    'option-2:200000:24': 112.59,
    'option-2:20000:1': 24.6,
    'option-4:25000:8': 31.94,
    'option-2:15000:23': 22.43,
    'option-2:20000:47': 87.32,
    'option-3:400000:12': 242.84,
    'option-3:50000:38': 68.26,
    'option-3:300000:37': 344.64,
    'option-3:350000:42': 563.2,
    'option-4:150000:43': 413.01,
    'option-2:500000:34': 506.03,
    'option-2:40000:56': 357.68,
    'option-3:15000:24': 23.45,
    'option-1:25000:19': 23.87,
    'option-3:45000:7': 40.08,
    'option-4:250000:5': 190.05,
    'option-4:20000:6': 28.43,
    'option-4:60000:12': 56.61,
    'option-3:90000:56': 420.89,
    'option-4:55000:2': 53.09,
    'option-2:50000:52': 316.98,
    'option-2:400000:31': 408.41,
    'option-4:30000:15': 35.47,
    'option-2:80000:5': 53.84,
    'option-3:70000:5': 54.09,
    'option-1:200000:18': 84.98,
    'option-3:60000:5': 48.56,
    'option-4:200000:43': 547.26,
    'option-4:60000:29': 67.85,
    'option-1:150000:21': 67.5,
    'option-1:90000:54': 291.33,
    'option-2:95000:16': 61.19,
    'option-3:450000:6': 271.3,
    'option-4:75000:24': 67.02,
    'option-3:50000:57': 237.2,
    'option-2:65000:15': 46.5,
    'option-3:350000:15': 215.07,
    'option-4:40000:40': 81.29,
    'option-3:5000:31': 19.62,
    'option-4:100000:14': 84.65,
    'option-1:80000:45': 120.61,
    'option-1:25000:18': 23.87,
    'option-2:70000:15': 48.94,
    'option-3:450000:57': 2083.57,
    'option-3:90000:1': 65.4,
    'option-2:40000:36': 73.09,
    'option-4:350000:25': 263.51,
    'option-2:65000:9': 46.5,
    'option-4:300000:41': 818.16,
    'option-4:500000:52': 3270.53,
    'option-3:350000:45': 563.2,
    'option-2:450000:30': 324.29,
    'option-3:10000:26': 21.89,
    'option-4:50000:11': 49.57,
    'option-4:60000:27': 67.85,
    'option-1:5000:55': 30.18,
    'option-2:85000:51': 537.66,
    'option-1:70000:57': 312.6,
    'option-2:100000:42': 256.67,
    'option-4:85000:43': 238.37,
    'option-1:200000:15': 84.98,
    'option-4:25000:54': 164.78,
    'option-4:200000:38': 358.47,
    'option-1:95000:13': 48.27,
    'option-1:300000:15': 122.59,
    'option-2:100000:6': 63.64,
    'option-1:150000:19': 67.5,
    'option-1:500000:26': 243.64,
    'option-3:450000:4': 271.3,
    'option-1:35000:60': 159.92,
    'option-2:90000:15': 58.74,
    'option-3:400000:34': 344.01,
    'option-1:45000:55': 150.42,
    'option-2:65000:17': 46.5,
    'option-1:35000:22': 27.36,
    'option-4:200000:0': 155.14,
    'option-3:75000:45': 129.9,
    'option-4:15000:31': 31.87,
    'option-4:400000:39': 707.81,
    'option-2:85000:13': 56.29,
    'option-1:65000:12': 37.77,
    'option-2:60000:45': 158.44,
    'option-1:85000:24': 44.77,
    'option-2:150000:6': 88.1,
    'option-4:5000:26': 20.0,
    'option-1:350000:43': 486.78,
    'option-2:45000:22': 36.83,
    'option-1:15000:58': 75.13,
    'option-4:70000:30': 76.83,
    'option-3:45000:26': 44.5,
    'option-2:150000:1': 88.1,
    'option-1:90000:47': 197.79,
    'option-1:55000:26': 39.78,
    'option-1:45000:24': 30.86,
    'option-4:80000:46': 345.29,
    'option-4:25000:1': 31.94,
    'option-2:150000:56': 1349.42,
    'option-3:45000:25': 40.08,
    'option-2:95000:45': 244.35,
    'option-2:55000:51': 348.53,
    'option-1:40000:32': 38.64,
    'option-4:350000:23': 263.51,
    'option-1:300000:32': 197.74,
    'option-3:65000:40': 84.7,
    'option-3:250000:7': 155.61,
    'option-2:100000:14': 63.64,
    'option-3:35000:9': 34.43,
    'option-2:75000:26': 65.49,
    'option-3:100000:21': 71.05,
    'option-1:45000:56': 203.38,
    'option-4:80000:42': 224.93,
    'option-3:75000:6': 56.91,
    'option-4:100000:3': 84.65,
    'option-3:65000:2': 51.38,
    'option-1:65000:27': 44.21,
    'option-2:400000:16': 212.75,
    'option-4:200000:47': 853.05,
    'option-4:70000:31': 96.9,
    'option-3:100000:48': 240.56,
    'option-1:75000:17': 41.27,
    'option-3:300000:30': 217.4,
    'option-3:300000:38': 344.64,
    'option-2:45000:40': 80.69,
    'option-4:60000:57': 552.62,
    'option-4:100000:41': 278.1,
    'option-2:150000:40': 239.97,
    'option-4:75000:40': 141.98,
    'option-4:50000:39': 98.64,
    'option-1:150000:48': 322.77,
    'option-1:350000:13': 140.14,
    'option-4:10000:28': 23.93,
    'option-2:10000:56': 97.36,
    'option-4:400000:38': 707.81,
    'option-2:30000:47': 127.29,
    'option-3:450000:54': 1529.33,
    'option-3:150000:9': 99.29,
    'option-2:5000:26': 18.95,
    'option-2:40000:14': 34.38,
    'option-3:400000:9': 242.84,
    'option-3:500000:2': 299.59,
    'option-3:100000:22': 71.05,
    'option-4:85000:2': 74.07,
    'option-4:400000:33': 493.98,
    'option-3:100000:41': 168.74,
    'option-3:40000:37': 57.29,
    'option-3:500000:32': 425.48,
    'option-4:250000:48': 1064.89,
    'option-3:100000:57': 467.06,
    'option-4:25000:25': 31.94,
    'option-2:400000:6': 212.75,
    'option-2:55000:19': 41.71,
    'option-2:350000:32': 359.35,
    'option-1:20000:44': 39.85,
    'option-1:450000:18': 174.93,
    'option-2:95000:51': 599.37,
    'option-1:80000:41': 120.61,
    'option-1:70000:45': 107.14,
    'option-2:55000:59': 492.66,
    'option-3:60000:14': 48.56,
    'option-1:30000:58': 138.04,
    'option-3:20000:46': 56.85,
    'option-1:50000:37': 57.87,
    'option-2:450000:47': 1810.35,
    'option-2:40000:37': 73.09,
    'option-2:250000:41': 627.34,
    'option-2:10000:13': 20.26,
    'option-3:35000:8': 34.43,
    'option-4:40000:11': 42.52,
    'option-4:90000:40': 167.6,
    'option-1:60000:20': 36.02,
    'option-2:35000:34': 47.96,
    'option-3:35000:36': 51.8,
    'option-4:250000:43': 681.73,
    'option-3:10000:33': 23.22,
    'option-1:65000:14': 37.77,
    'option-2:350000:4': 188.68,
    'option-1:65000:53': 213.37,
    'option-1:35000:54': 119.11,
    'option-4:60000:42': 171.16,
    'option-2:80000:48': 327.61,
    'option-1:250000:39': 233.2,
    'option-4:350000:54': 2292.03,
    'option-1:85000:0': 44.77,
    'option-2:65000:32': 77.37,
    'option-3:5000:3': 18.47,
    'option-4:350000:35': 434.15,
    'option-4:30000:49': 133.61,
    'option-2:35000:29': 38.29,
    'option-1:50000:2': 32.6,
    'option-1:150000:53': 480.59,
    'option-3:500000:54': 1698.69,
    'option-3:65000:14': 51.38,
    'option-4:85000:7': 74.07,
    'option-3:65000:29': 57.86,
    'option-2:80000:56': 718.28,
    'option-3:70000:54': 244.94,
    'option-2:500000:39': 772.96,
    'option-4:500000:57': 4632.84,
    'option-4:60000:11': 56.61,
    'option-1:300000:22': 122.59,
    'option-2:10000:60': 97.36,
    'option-2:40000:41': 108.97,
    'option-4:30000:20': 35.47,
    'option-2:50000:14': 39.27,
    'option-3:70000:31': 71.36,
    'option-3:100000:58': 467.06,
    'option-1:5000:22': 17.45,
    'option-3:20000:9': 25.96,
    'option-3:10000:35': 23.22,
    'option-1:350000:9': 140.14,
    'option-4:75000:21': 67.02,
    'option-2:25000:11': 27.04,
    'option-4:250000:33': 311.33,
    'option-1:45000:54': 150.42,
    'option-2:45000:59': 402.21,
    'option-4:65000:45': 184.61,
    'option-1:35000:13': 27.36,
    'option-3:5000:58': 37.5,
    'option-2:150000:22': 88.1,
    'option-1:75000:36': 79.84,
    'option-4:200000:3': 155.14,
    'option-3:25000:24': 28.78,
    'option-1:35000:50': 84.04,
    'option-1:40000:58': 181.76,
    'option-4:40000:28': 49.86,
    'option-4:65000:14': 60.13,
    'option-4:10000:2': 22.21,
    'option-2:20000:0': 24.6,
    'option-1:45000:18': 30.86,
    'option-2:60000:5': 44.16,
    'option-4:20000:52': 132.46,
    'option-1:60000:57': 268.93,
    'option-1:60000:17': 36.02,
    'option-2:85000:58': 763.38,
    'option-4:60000:45': 171.16,
    'option-3:75000:49': 183.47,
    'option-3:15000:57': 78.48,
    'option-1:75000:18': 41.27,
    'option-4:10000:57': 99.65,
    'option-2:25000:12': 27.04,
    'option-3:5000:44': 22.98,
    'option-2:85000:3': 56.29,
    'option-2:200000:47': 808.11,
    'option-2:75000:0': 51.39,
    'option-4:70000:51': 459.2,
    'option-4:45000:0': 46.04,
    'option-4:15000:8': 25.34,
    'option-3:10000:24': 20.97,
    'option-4:45000:59': 413.16,
    'option-4:85000:13': 74.07,
    'option-4:70000:42': 198.05,
    'option-1:55000:35': 47.67,
    'option-4:90000:11': 77.6,
    'option-4:400000:24': 298.07,
    'option-3:50000:56': 237.2,
    'option-1:75000:53': 244.83,
    'option-2:150000:37': 239.97,
    'option-2:500000:55': 3152.52,
    'option-2:35000:23': 31.93,
    'option-3:25000:32': 34.49,
    'option-3:400000:32': 344.01,
    'option-4:100000:18': 84.65,
    'option-2:100000:20': 63.64,
    'option-1:65000:60': 290.77,
    'option-2:5000:11': 18.11,
    'option-2:250000:13': 136.85,
    'option-4:25000:51': 164.78,
    'option-2:60000:7': 44.16,
    'option-2:450000:46': 1810.35,
    'option-2:90000:28': 75.75,
    'option-4:90000:7': 77.6,
    'option-1:20000:54': 71.83,
    'option-2:75000:38': 126.22,
    'option-1:50000:60': 225.23,
    'option-2:25000:31': 38.22,
    'option-3:30000:45': 59.45,
    'option-2:45000:25': 36.83,
    'option-4:500000:7': 368.87,
    'option-1:90000:22': 46.52,
    'option-3:400000:10': 242.84,
    'option-2:100000:7': 63.64,
    'option-3:25000:5': 28.78,
    'option-1:300000:38': 280.08,
    'option-4:10000:13': 22.21,
    'option-2:300000:48': 1210.68,
    'option-1:300000:46': 636.47,
    'option-3:30000:48': 79.91,
    'option-1:15000:33': 23.92,
    'option-3:100000:60': 467.06,
    'option-3:45000:10': 40.08,
    'option-4:90000:9': 77.6,
    'option-2:90000:0': 58.74,
    'option-3:25000:53': 92.89,
    'option-2:95000:60': 851.7,
    'option-1:500000:53': 1582.54,
    'option-1:60000:44': 93.68,
    'option-2:60000:42': 158.44,
    'option-3:200000:14': 127.59,
    'option-2:250000:26': 184.9,
    'option-3:200000:55': 683.85,
    'option-3:70000:56': 329.58,
    'option-3:450000:39': 508.76,
    'option-1:350000:15': 140.14,
    'option-3:75000:9': 56.91,
    'option-2:45000:34': 57.77,
    'option-4:250000:23': 190.05,
    'option-3:300000:39': 344.64,
    'option-4:500000:60': 4632.84,
    'option-3:85000:41': 145.56,
    'option-4:100000:42': 278.1,
    'option-1:5000:58': 36.54,
    'option-2:65000:31': 77.37,
    'option-1:5000:38': 19.87,
    'option-3:300000:29': 217.4,
    'option-4:350000:39': 621.06,
    'option-2:55000:33': 67.57,
    'option-4:80000:43': 224.93,
    'option-2:20000:27': 28.04,
    'option-2:500000:9': 261.85,
    'option-3:40000:44': 75.04,
    'option-4:300000:51': 1963.97,
    'option-1:450000:11': 174.93,
    'option-2:85000:23': 56.29,
    'option-3:5000:2': 18.47,
    'option-1:75000:0': 41.27,
    'option-2:5000:58': 57.62,
    'option-2:40000:59': 357.68,
    'option-1:40000:51': 134.57,
    'option-1:65000:45': 100.41,
    'option-4:30000:59': 274.41,
    'option-1:100000:44': 147.27,
    'option-1:65000:21': 37.77,
    'option-4:200000:30': 193.61,
    'option-1:85000:38': 88.44,
    'option-2:500000:31': 506.03,
    'option-3:10000:38': 25.66,
    'option-3:95000:21': 68.23,
    'option-4:70000:27': 76.83,
    'option-3:65000:31': 67.27,
    'option-3:150000:30': 114.5,
    'option-3:35000:47': 91.44,
    'option-2:75000:27': 65.49,
    'option-3:60000:35': 63.18,
    'option-2:20000:8': 24.6,
    'option-3:20000:22': 25.96,
    'option-4:450000:9': 333.6,
    'option-3:40000:26': 41.16,
    'option-4:300000:7': 228.15,
    'option-3:75000:43': 129.9,
    'option-1:250000:29': 127.8,
    'option-2:95000:35': 106.57,
    'option-3:450000:14': 271.3,
    'option-1:80000:22': 43.02,
    'option-2:350000:48': 1411.7,
    'option-4:30000:58': 274.41,
    'option-4:75000:19': 67.02,
    'option-2:95000:8': 61.19,
    'option-4:40000:22': 42.52,
    'option-1:400000:54': 1269.39,
    'option-1:85000:57': 378.08,
    'option-4:250000:38': 444.19,
    'option-4:200000:10': 155.14,
    'option-4:35000:19': 39.0,
    'option-2:20000:5': 24.6,
    'option-2:55000:15': 41.71,
    'option-2:350000:23': 188.68,
    'option-2:90000:14': 58.74,
    'option-1:450000:38': 411.74,
    'option-1:40000:56': 181.76,
    'option-2:250000:14': 136.85,
    'option-2:95000:10': 61.19,
    'option-1:10000:50': 34.47,
    'option-2:20000:38': 42.84,
    'option-4:85000:24': 74.07,
    'option-2:350000:37': 545.96,
    'option-1:150000:15': 67.5,
    'option-4:30000:13': 35.47,
    'option-4:80000:11': 70.54,
    'option-2:350000:58': 3155.5,
    'option-4:150000:53': 982.15,
    'option-3:85000:12': 62.57,
    'option-1:200000:20': 84.98,
    'option-1:350000:50': 740.69,
    'option-4:55000:36': 107.31,
    'option-2:100000:37': 163.84,
    'option-1:400000:29': 198.24,
    'option-3:75000:18': 56.91,
    'option-1:100000:8': 50.02,
    'option-3:25000:20': 28.78,
    'option-2:300000:10': 164.12,
    'option-4:350000:2': 263.51,
    'option-3:5000:57': 37.5,
    'option-1:30000:24': 25.62,
    'option-2:100000:15': 63.64,
    'option-3:70000:26': 61.2,
    'option-4:55000:51': 361.05,
    'option-2:350000:20': 188.68,
    'option-3:15000:44': 36.79,
    'option-2:55000:5': 41.71,
    'option-3:30000:37': 46.27,
    'option-3:15000:40': 30.55,
    'option-3:40000:23': 37.26,
    'option-4:70000:19': 63.65,
    'option-1:250000:19': 102.34,
    'option-3:100000:35': 95.75,
    'option-3:35000:48': 91.44,
    'option-3:150000:25': 99.29,
    'option-2:95000:13': 61.19,
    'option-4:100000:24': 84.65,
    'option-4:35000:53': 230.46,
    'option-1:70000:47': 156.68,
    'option-4:50000:56': 459.74,
    'option-4:95000:33': 126.53,
    'option-3:5000:18': 18.47,
    'option-2:200000:37': 315.75,
    'option-4:60000:13': 56.61,
    'option-3:10000:6': 20.97,
    'option-4:65000:30': 72.34,
    'option-2:15000:47': 69.54,
    'option-3:250000:20': 155.61,
    'option-1:30000:50': 73.64,
    'option-1:20000:34': 26.6,
    'option-3:90000:46': 217.53,
    'option-2:5000:43': 27.09,
    'option-3:85000:25': 62.57,
    'option-1:20000:53': 71.83,
    'option-3:25000:38': 40.9,
    'option-1:200000:9': 84.98,
    'option-4:450000:0': 333.6,
    'option-1:5000:2': 17.45,
    'option-1:15000:32': 23.92,
    'option-2:30000:7': 29.49,
    'option-4:85000:21': 74.07,
    'option-2:70000:22': 48.94,
    'option-4:85000:58': 784.62,
    'option-1:5000:8': 17.45,
    'option-2:350000:8': 188.68,
    'option-3:500000:30': 350.91,
    'option-4:35000:12': 39.0,
    'option-4:25000:33': 43.15,
    'option-1:400000:32': 257.57,
    'option-2:500000:19': 261.85,
    'option-2:85000:16': 56.29,
    'option-3:55000:43': 98.56,
    'option-2:30000:15': 29.49,
    'option-4:30000:16': 35.47,
    'option-3:300000:43': 484.56,
    'option-1:65000:57': 290.77,
    'option-4:35000:41': 104.05,
    'option-3:300000:6': 186.71,
    'option-1:95000:6': 48.27,
    'option-3:10000:42': 29.91,
    'option-3:250000:14': 155.61,
    'option-1:100000:35': 74.66,
    'option-4:75000:11': 67.02,
    'option-2:100000:25': 63.64,
    'option-3:500000:44': 797.55,
    'option-3:75000:16': 56.91,
    'option-3:60000:3': 48.56,
    'option-4:350000:8': 263.51,
    'option-1:350000:23': 140.14,
    'option-1:95000:35': 71.64,
    'option-4:95000:56': 875.45,
    'option-2:300000:49': 1210.68,
    'option-4:200000:33': 252.19,
    'option-3:100000:27': 81.13,
    'option-4:350000:9': 263.51,
    'option-1:5000:1': 17.45,
    'option-4:65000:31': 90.92,
    'option-2:250000:44': 627.34,
    'option-1:400000:37': 368.23,
    'option-4:300000:35': 374.14,
    'option-3:400000:57': 1855.43,
    'option-4:85000:32': 114.58,
    'option-4:65000:16': 60.13,
    'option-3:150000:44': 247.33,
    'option-2:80000:11': 53.84,
    'option-2:85000:48': 347.65,
    'option-1:5000:43': 21.98,
    'option-2:90000:5': 58.74,
    'option-4:95000:24': 81.12,
    'option-2:5000:13': 18.11,
    'option-2:45000:36': 80.69,
    'option-2:85000:42': 220.16,
    'option-1:10000:31': 21.28,
    'option-1:5000:28': 17.96,
    'option-3:65000:59': 306.49,
    'option-1:80000:60': 356.26,
    'option-3:450000:53': 1529.33,
    'option-4:250000:26': 238.13,
    'option-4:35000:40': 72.72,
    'option-2:250000:31': 258.17,
    'option-1:5000:11': 17.45,
    'option-4:250000:2': 190.05,
    'option-2:400000:26': 289.81,
    'option-4:85000:9': 74.07,
    'option-2:150000:60': 1349.42,
    'option-1:60000:10': 36.02,
    'option-4:300000:27': 286.02,
    'option-3:60000:20': 48.56,
    'option-1:400000:0': 157.41,
    'option-1:60000:27': 42.04,
    'option-1:80000:34': 62.59,
    'option-2:100000:43': 256.67,
    'option-4:350000:51': 2292.03,
    'option-3:250000:12': 155.61,
    'option-3:350000:6': 215.07,
    'option-1:200000:28': 105.35,
    'option-3:30000:59': 145.0,
    'option-2:95000:33': 106.57,
    'option-3:50000:40': 68.26,
    'option-1:450000:33': 287.92,
    'option-2:150000:57': 1349.42,
    'option-3:200000:9': 127.59,
    'option-2:250000:52': 1573.4,
    'option-3:60000:59': 283.4,
    'option-3:20000:38': 35.42,
    'option-4:500000:6': 368.87,
    'option-3:100000:38': 122.85,
    'option-4:450000:8': 333.6,
    'option-3:60000:4': 48.56,
    'option-2:25000:40': 50.41,
    'option-3:85000:49': 206.5,
    'option-1:250000:3': 102.34,
    'option-2:35000:45': 96.81,
    'option-4:20000:16': 28.43,
    'option-1:85000:16': 44.77,
    'option-4:90000:30': 94.62,
    'option-2:90000:44': 232.5,
    'option-1:95000:18': 48.27,
    'option-4:90000:54': 590.0,
    'option-1:300000:58': 1318.3,
    'option-2:500000:11': 261.85,
    'option-3:250000:39': 286.82,
    'option-1:90000:20': 46.52,
    'option-1:20000:27': 23.95,
    'option-4:50000:42': 144.26,
    'option-1:250000:7': 102.34,
    'option-3:30000:19': 31.61,
    'option-3:65000:0': 51.38,
    'option-2:95000:55': 599.37,
    'option-2:65000:4': 46.5,
    'option-3:100000:59': 467.06,
    'option-3:90000:31': 87.56,
    'option-2:10000:52': 72.37,
    'option-4:10000:7': 22.21,
    'option-1:100000:24': 50.02,
    'option-4:80000:55': 524.6,
    'option-2:200000:27': 151.02,
    'option-2:25000:37': 50.41,
    'option-4:70000:1': 63.65,
    'option-3:60000:37': 79.22,
    'option-3:300000:51': 1023.69,
    'option-4:80000:28': 85.63,
    'option-2:400000:57': 3607.06,
    'option-2:100000:1': 63.64,
    'option-4:150000:13': 119.82,
    'option-1:15000:45': 33.86,
    'option-3:15000:42': 36.79,
    'option-1:15000:44': 33.86,
    'option-4:55000:15': 53.09,
    'option-2:55000:22': 41.71,
    'option-2:95000:27': 79.17,
    'option-1:90000:11': 46.52,
    'option-3:250000:18': 155.61,
    'option-2:30000:33': 43.04,
    'option-3:500000:22': 299.59,
    'option-2:90000:37': 148.66,
    'option-3:80000:29': 67.74,
    'option-2:30000:4': 29.49,
    'option-4:90000:47': 387.64,
    'option-3:70000:17': 54.09,
    'option-1:70000:7': 39.52,
    'option-1:400000:40': 368.23,
    'option-2:50000:51': 316.98,
    'option-3:300000:9': 186.71,
    'option-4:200000:28': 193.61,
    'option-4:95000:31': 126.53,
    'option-1:25000:13': 23.87,
    'option-4:5000:33': 21.31,
    'option-4:450000:26': 420.63,
    'option-1:35000:2': 27.36,
    'option-1:100000:42': 147.27,
    'option-2:100000:36': 163.84,
    'option-1:400000:24': 157.41,
    'option-3:25000:34': 34.49,
    'option-3:100000:19': 71.05,
    'option-1:20000:6': 22.13,
    'option-2:400000:50': 1612.3,
    'option-4:45000:45': 130.8,
    'option-3:55000:36': 73.74,
    'option-2:25000:19': 27.04,
    'option-4:85000:26': 90.12,
    'option-3:10000:48': 36.49,
    'option-2:75000:35': 87.17,
    'option-1:90000:60': 398.96,
    'option-3:10000:15': 20.97,
    'option-1:85000:29': 53.27,
    'option-1:5000:57': 36.54,
    'option-1:10000:57': 55.8,
    'option-2:100000:0': 63.64,
    'option-1:10000:60': 55.8,
    'option-3:25000:35': 34.49,
    'option-4:60000:3': 56.61,
    'option-2:55000:49': 227.39,
    'option-2:70000:3': 48.94,
    'option-3:80000:17': 59.74,
    'option-1:35000:16': 27.36,
    'option-3:70000:10': 54.09,
    'option-1:450000:46': 947.42,
    'option-3:350000:30': 250.95,
    'option-1:95000:21': 48.27,
    'option-1:50000:10': 32.6,
    'option-4:55000:47': 239.39,
    'option-2:50000:15': 39.27,
    'option-2:50000:6': 39.27,
    'option-2:450000:38': 696.98,
    'option-4:90000:16': 77.6,
    'option-3:250000:6': 155.61,
    'option-4:65000:51': 426.49,
    'option-1:250000:43': 348.72,
    'option-2:75000:28': 65.49,
    'option-4:65000:33': 90.92,
    'option-4:75000:17': 67.02,
    'option-3:60000:51': 211.14,
    'option-4:65000:7': 60.13,
    'option-4:20000:34': 37.18,
    'option-3:55000:37': 73.74,
    'option-1:70000:52': 229.1,
    'option-2:85000:29': 72.32,
    'option-1:25000:60': 116.12,
    'option-1:300000:5': 122.59,
    'option-4:20000:2': 28.43,
    'option-2:65000:20': 46.5,
    'option-3:200000:0': 127.59,
    'option-4:100000:8': 84.65,
    'option-1:150000:58': 661.69,
    'option-1:100000:25': 50.02,
    'option-3:70000:16': 54.09,
    'option-3:200000:16': 127.59,
    'option-1:450000:34': 287.92,
    'option-1:80000:8': 43.02,
    'option-2:400000:52': 2525.0,
    'option-4:500000:36': 880.37,
    'option-4:60000:20': 56.61,
    'option-3:65000:20': 51.38,
    'option-3:25000:40': 40.9,
    'option-3:45000:42': 82.88,
    'option-3:55000:21': 45.73,
    'option-1:55000:44': 86.94,
    'option-1:500000:18': 192.42,
    'option-3:55000:53': 194.24,
    'option-3:95000:54': 328.65,
    'option-1:450000:41': 620.81,
    'option-4:75000:6': 67.02,
    'option-1:150000:31': 104.75,
    'option-4:500000:56': 4632.84,
    'option-2:45000:2': 36.83,
    'option-4:400000:6': 298.07,
    'option-2:200000:31': 209.63,
    'option-3:25000:17': 28.78,
    'option-2:400000:27': 289.81,
    'option-4:60000:9': 56.61,
    'option-4:35000:3': 39.0,
    'option-2:150000:50': 607.98,
    'option-2:65000:46': 267.49,
    'option-3:90000:42': 153.07,
    'option-1:200000:45': 282.11,
    'option-4:55000:37': 107.31,
    'option-2:90000:33': 101.66,
    'option-2:450000:60': 4052.7,
    'option-2:200000:14': 112.59,
    'option-3:300000:7': 186.71,
    'option-2:25000:52': 159.39,
    'option-1:80000:26': 51.01,
    'option-3:100000:51': 345.58,
    'option-2:45000:35': 57.77,
    'option-1:70000:53': 229.1,
    'option-2:250000:32': 258.17,
    'option-1:450000:26': 220.99,
    'option-1:85000:54': 276.28,
    'option-4:450000:33': 553.12,
    'option-3:20000:7': 25.96,
    'option-2:400000:13': 212.75,
    'option-1:50000:9': 32.6,
    'option-3:500000:5': 299.59,
    'option-3:15000:4': 23.45,
    'option-3:90000:24': 65.4,
    'option-4:400000:20': 298.07,
    'option-2:60000:3': 44.16,
    'option-4:20000:21': 28.43,
    'option-1:5000:36': 19.87,
    'option-3:55000:46': 137.41,
    'option-4:500000:38': 880.37,
    'option-4:35000:49': 154.83,
    'option-3:20000:55': 75.98,
    'option-3:300000:34': 262.0,
    'option-1:85000:52': 276.28,
    'option-1:150000:23': 67.5,
    'option-4:80000:40': 150.64,
    'option-2:35000:11': 31.93,
    'option-1:35000:44': 59.96,
    'option-1:45000:44': 73.46,
    'option-1:350000:6': 140.14,
    'option-1:50000:14': 32.6,
    'option-4:350000:38': 621.06,
    'option-2:40000:50': 167.43,
    'option-3:60000:0': 48.56,
    'option-2:200000:46': 808.11,
    'option-3:350000:21': 215.07,
    'option-4:85000:51': 557.3,
    'option-4:90000:26': 94.62,
    'option-2:40000:16': 34.38,
    'option-3:80000:57': 375.74,
    'option-2:90000:10': 58.74,
    'option-3:20000:27': 27.79,
    'option-4:10000:0': 22.21,
    'option-3:40000:14': 37.26,
    'option-3:55000:28': 51.18,
    'option-2:50000:44': 133.74,
    'option-2:30000:39': 58.0,
    'option-3:20000:40': 35.42,
    'option-2:250000:9': 136.85,
    'option-1:40000:52': 134.57,
    'option-2:150000:0': 88.1,
    'option-4:70000:45': 198.05,
    'option-3:70000:8': 54.09,
    'option-2:45000:47': 187.27,
    'option-2:150000:52': 947.09,
    'option-1:30000:29': 28.47,
    'option-3:300000:1': 186.71,
    'option-4:15000:25': 25.34,
    'option-2:10000:25': 20.26,
    'option-2:40000:38': 73.09,
    'option-3:30000:49': 79.91,
    'option-2:50000:18': 39.27,
    'option-4:55000:6': 53.09,
    'option-1:15000:52': 58.03,
    'option-2:75000:34': 87.17,
    'option-2:40000:34': 52.87,
    'option-4:50000:47': 218.19,
    'option-2:20000:39': 42.84,
    'option-2:350000:30': 256.27,
    'option-2:5000:22': 18.11,
    'option-1:95000:55': 307.03,
    'option-4:200000:25': 155.14,
    'option-3:20000:14': 25.96,
    'option-1:35000:17': 27.36,
    'option-2:95000:7': 61.19,
    'option-2:35000:9': 31.93,
    'option-2:35000:54': 222.68,
    'option-3:40000:2': 37.26,
    'option-4:400000:57': 3709.1,
    'option-3:90000:30': 74.43,
    'option-1:50000:24': 32.6,
    'option-2:10000:50': 51.8,
    'option-2:90000:3': 58.74,
    'option-3:90000:37': 111.87,
    'option-3:500000:31': 425.48,
    'option-4:95000:35': 126.53,
    'option-4:25000:42': 77.1,
    'option-1:5000:26': 17.96,
    'option-2:500000:29': 358.54,
    'option-3:5000:40': 20.86,
    'option-2:90000:40': 148.66,
    'option-2:50000:32': 62.67,
    'option-4:40000:9': 42.52,
    'option-4:40000:27': 49.86,
    'option-1:65000:19': 37.77,
    'option-2:35000:41': 96.81,
    'option-2:45000:41': 121.37,
    'option-3:500000:48': 1163.55,
    'option-2:450000:42': 1122.36,
    'option-2:100000:55': 630.83,
    'option-2:65000:24': 46.5,
    'option-4:35000:47': 154.83,
    'option-4:55000:60': 506.19,
    'option-4:10000:49': 53.83,
    'option-3:500000:49': 1163.55,
    'option-2:250000:35': 258.17,
    'option-4:100000:35': 132.52,
    'option-1:90000:58': 398.96,
    'option-4:45000:57': 413.16,
    'option-4:85000:30': 90.12,
    'option-4:55000:18': 53.09,
    'option-3:65000:32': 67.27,
    'option-2:45000:30': 45.13,
    'option-4:85000:34': 114.58,
    'option-2:90000:53': 569.17,
    'option-1:450000:17': 174.93,
    'option-2:500000:17': 261.85,
    'option-3:150000:1': 99.29,
    'option-4:60000:35': 84.95,
    'option-1:30000:32': 32.61,
    'option-3:500000:38': 563.64,
    'option-3:90000:44': 153.07,
    'option-2:300000:13': 164.12,
    'option-2:100000:23': 63.64,
    'option-1:80000:39': 84.05,
    'option-4:20000:31': 37.18,
    'option-3:15000:2': 23.45,
    'option-2:55000:31': 67.57,
    'option-3:40000:29': 41.16,
    'option-4:100000:46': 429.09,
    'option-2:10000:10': 20.26,
    'option-2:15000:20': 22.43,
    'option-4:15000:52': 103.44,
    'option-2:20000:51': 128.24,
    'option-3:15000:8': 23.45,
    'option-4:65000:3': 60.13,
    'option-4:500000:30': 465.63,
    'option-4:350000:26': 331.15,
    'option-4:5000:49': 35.14,
    'option-4:250000:12': 190.05,
    'option-4:40000:43': 117.28,
    'option-2:25000:59': 222.44,
    'option-1:80000:19': 43.02,
    'option-1:150000:2': 67.5,
    'option-4:35000:23': 39.0,
    'option-1:500000:34': 318.09,
    'option-2:75000:52': 474.63,
    'option-3:75000:29': 64.4,
    'option-1:5000:14': 17.45,
    'option-2:65000:13': 46.5,
    'option-4:35000:45': 104.05,
    'option-1:200000:7': 84.98,
    'option-1:5000:4': 17.45,
    'option-2:60000:13': 44.16,
    'option-2:100000:50': 406.91,
    'option-3:50000:31': 55.0,
    'option-2:500000:48': 2011.11,
    'option-1:95000:11': 48.27,
    'option-4:300000:11': 228.15,
    'option-1:150000:27': 82.7,
    'option-3:400000:33': 344.01,
    'option-1:65000:2': 37.77,
    'option-4:45000:9': 46.04,
    'option-1:100000:20': 50.02,
    'option-4:350000:52': 2292.03,
    'option-4:500000:50': 2124.34,
    'option-4:95000:23': 81.12,
    'option-1:300000:52': 954.21,
    'option-1:60000:5': 36.02,
    'option-3:20000:59': 98.96,
    'option-2:35000:33': 47.96,
    'option-4:500000:51': 3270.53,
    'option-2:55000:29': 51.96,
    'option-1:70000:6': 39.52,
    'option-1:75000:13': 41.27,
    'option-2:35000:48': 147.38,
    'option-1:80000:4': 43.02,
    'option-4:350000:16': 263.51,
    'option-1:65000:52': 213.37,
    'option-3:65000:56': 306.49,
    'option-2:65000:6': 46.5,
    'option-1:35000:21': 27.36,
    'option-1:45000:38': 53.47,
    'option-4:65000:6': 60.13,
    'option-2:400000:56': 3607.06,
    'option-1:30000:28': 28.47,
    'option-4:75000:46': 324.12,
    'option-3:10000:32': 23.22,
    'option-1:25000:45': 46.58,
    'option-1:65000:18': 37.77,
    'option-3:25000:57': 121.81,
    'option-1:90000:18': 46.52,
    'option-1:85000:37': 88.44,
    'option-2:60000:40': 103.46,
    'option-3:5000:5': 18.47,
    'option-1:350000:4': 140.14,
    'option-1:55000:18': 34.28,
    'option-3:70000:14': 54.09,
    'option-2:70000:37': 118.63,
    'option-3:60000:42': 106.4,
    'option-4:90000:27': 94.62,
    'option-3:60000:58': 283.4,
    'option-2:60000:25': 44.16,
    'option-1:300000:41': 419.16,
    'option-4:30000:37': 64.05,
    'option-1:70000:43': 107.14,
    'option-2:30000:40': 58.0,
    'option-2:200000:25': 112.59,
    'option-1:40000:49': 94.31,
    'option-2:80000:55': 506.15,
    'option-1:350000:56': 1537.32,
    'option-1:30000:22': 25.62,
    'option-2:70000:57': 628.06,
    'option-4:100000:47': 429.09,
    'option-1:90000:17': 46.52,
    'option-4:40000:35': 61.03,
    'option-3:50000:19': 42.91,
    'option-4:150000:5': 119.82,
    'option-1:50000:29': 37.52,
    'option-3:85000:4': 62.57,
    'option-3:50000:47': 125.89,
    'option-4:350000:53': 2292.03,
    'option-4:70000:53': 459.2,
    'option-1:150000:24': 67.5,
    'option-4:10000:26': 23.93,
    'option-3:55000:52': 194.24,
    'option-4:350000:37': 621.06,
    'option-2:150000:51': 947.09,
    'option-4:350000:0': 263.51,
    'option-4:40000:56': 367.37,
    'option-1:55000:1': 34.28,
    'option-2:10000:46': 51.8,
    'option-2:300000:47': 1210.68,
    'option-3:40000:12': 37.26,
    'option-3:55000:18': 45.73,
    'option-1:90000:32': 68.63,
    'option-1:100000:50': 218.56,
    'option-3:55000:34': 59.09,
    'option-2:250000:20': 136.85,
    'option-2:15000:57': 137.28,
    'option-1:150000:0': 67.5,
    'option-2:60000:2': 44.16,
    'option-3:50000:52': 177.33,
    'option-1:65000:20': 37.77,
    'option-4:25000:14': 31.94,
    'option-3:20000:48': 56.85,
    'option-1:100000:58': 442.63,
    'option-3:250000:47': 584.96,
    'option-3:45000:13': 40.08,
    'option-1:150000:12': 67.5,
    'option-4:90000:49': 387.64,
    'option-4:400000:50': 1702.7,
    'option-2:95000:39': 156.24,
    'option-1:350000:42': 486.78,
    'option-4:5000:7': 19.1,
    'option-4:350000:11': 263.51,
    'option-3:35000:24': 34.43,
    'option-1:5000:18': 17.45,
    'option-3:25000:49': 68.37,
    'option-4:25000:36': 55.37,
    'option-2:95000:29': 79.17,
    'option-1:90000:48': 197.79,
    'option-3:5000:41': 22.98,
    'option-4:350000:30': 331.15,
    'option-1:10000:23': 19.02,
    'option-2:10000:6': 20.26,
    'option-1:30000:8': 25.62,
    'option-4:350000:41': 953.02,
    'option-1:65000:26': 44.21,
    'option-2:300000:8': 164.12,
    'option-4:400000:21': 298.07,
    'option-2:5000:17': 18.11,
    'option-2:200000:16': 112.59,
    'option-2:90000:13': 58.74,
    'option-3:10000:46': 36.49,
    'option-3:65000:28': 57.86,
    'option-1:80000:37': 84.05,
    'option-3:40000:58': 191.23,
    'option-2:10000:45': 37.93,
    'option-1:70000:44': 107.14,
    'option-4:15000:3': 25.34,
    'option-1:250000:2': 102.34,
    'option-2:300000:16': 164.12,
    'option-2:400000:51': 2525.0,
    'option-1:15000:7': 20.58,
    'option-4:80000:35': 108.85,
    'option-4:40000:46': 176.02,
    'option-3:25000:47': 68.37,
    'option-2:15000:4': 22.43,
    'option-1:80000:53': 260.55,
    'option-1:10000:0': 19.02,
    'option-3:350000:53': 1193.19,
    'option-3:25000:21': 28.78,
    'option-2:80000:51': 506.15,
    'option-4:5000:14': 19.1,
    'option-3:20000:23': 25.96,
    'option-3:60000:9': 48.56,
    'option-4:400000:56': 3709.1,
    'option-3:35000:51': 126.78,
    'option-1:300000:60': 1318.3,
    'option-2:65000:42': 170.79,
    'option-1:400000:11': 157.41,
    'option-2:30000:30': 34.87,
    'option-2:300000:25': 164.12,
    'option-1:10000:16': 19.02,
    'option-4:50000:35': 72.99,
    'option-2:75000:51': 474.63,
    'option-2:35000:36': 65.45,
    'option-4:35000:33': 55.04,
    'option-4:45000:4': 46.04,
    'option-2:350000:7': 188.68,
    'option-4:70000:7': 63.65,
    'option-1:95000:15': 48.27,
    'option-1:350000:47': 740.69,
    'option-4:85000:37': 159.31,
    'option-3:55000:56': 260.3,
    'option-4:15000:46': 72.58,
    'option-4:80000:53': 524.6,
    'option-4:10000:33': 26.6,
    'option-2:100000:40': 163.84,
    'option-1:70000:17': 39.52,
    'option-4:20000:48': 91.37,
    'option-2:25000:30': 31.45,
    'option-3:55000:26': 51.18,
    'option-4:20000:28': 31.92,
    'option-3:200000:7': 127.59,
    'option-2:200000:1': 112.59,
    'option-2:400000:40': 621.91,
    'option-1:15000:5': 20.58,
    'option-1:250000:11': 102.34,
    'option-2:10000:14': 20.26,
    'option-2:30000:26': 34.87,
    'option-1:400000:59': 1755.82,
    'option-2:90000:11': 58.74,
    'option-2:500000:60': 4505.25,
    'option-2:450000:13': 237.36,
    'option-4:350000:59': 3244.69,
    'option-2:80000:9': 53.84,
    'option-1:15000:59': 75.13,
    'option-3:35000:42': 67.15,
    'option-2:250000:16': 136.85,
    'option-3:45000:24': 40.08,
    'option-1:250000:41': 348.72,
    'option-1:20000:1': 22.13,
    'option-2:65000:22': 46.5,
    'option-4:15000:59': 140.7,
    'option-2:500000:26': 358.54,
    'option-2:15000:54': 100.27,
    'option-1:150000:55': 480.59,
    'option-2:15000:42': 48.89,
    'option-2:60000:36': 103.46,
    'option-2:80000:21': 53.84,
    'option-2:100000:29': 82.6,
    'option-4:50000:28': 58.86,
    'option-2:60000:9': 44.16,
    'option-4:500000:27': 465.63,
    'option-1:100000:0': 50.02,
    'option-1:60000:45': 93.68,
    'option-4:20000:53': 132.46,
    'option-1:30000:56': 138.04,
    'option-1:250000:48': 529.22,
    'option-3:450000:3': 271.3,
    'option-4:100000:57': 921.76,
    'option-2:5000:53': 44.52,
    'option-4:75000:43': 211.49,
    'option-2:80000:23': 53.84,
    'option-1:60000:54': 197.64,
    'option-1:10000:24': 19.02,
    'option-2:5000:20': 18.11,
    'option-3:150000:34': 136.57,
    'option-4:100000:11': 84.65,
    'option-3:40000:42': 75.04,
    'option-1:70000:5': 39.52,
    'option-1:90000:24': 46.52,
    'option-2:15000:52': 100.27,
    'option-3:200000:51': 683.85,
    'option-2:75000:60': 673.17,
    'option-4:40000:17': 42.52,
    'option-4:95000:59': 875.45,
    'option-1:40000:37': 49.07,
    'option-2:200000:5': 112.59,
    'option-4:5000:44': 28.05,
    'option-4:5000:6': 19.1,
    'option-4:30000:0': 35.47,
    'option-2:350000:40': 545.96,
    'option-2:500000:12': 261.85,
    'option-3:60000:24': 48.56,
    'option-2:25000:22': 27.04,
    'option-3:25000:23': 28.78,
    'option-3:5000:0': 18.47,
    'option-2:30000:32': 43.04,
    'option-2:10000:15': 20.26,
    'option-1:20000:13': 22.13,
    'option-1:250000:56': 1095.56,
    'option-4:80000:13': 70.54,
    'option-1:100000:37': 101.65,
    'option-2:40000:4': 34.38,
    'option-1:30000:27': 28.47,
    'option-3:30000:43': 59.45,
    'option-1:40000:26': 33.0,
    'option-4:50000:59': 459.74,
    'option-4:60000:32': 84.95,
    'option-1:50000:39': 57.87,
    'option-1:65000:31': 53.69,
    'option-1:80000:36': 84.05,
    'option-4:25000:17': 31.94,
    'option-1:300000:35': 197.74,
    'option-1:20000:17': 22.13,
    'option-1:35000:20': 27.36,
    'option-3:200000:38': 232.57,
    'option-1:500000:60': 2190.49,
    'option-1:35000:56': 159.92,
    'option-2:450000:21': 237.36,
    'option-4:95000:26': 99.13,
    'option-1:75000:25': 41.27,
    'option-1:90000:19': 46.52,
    'option-2:50000:47': 207.33,
    'option-1:450000:2': 174.93,
    'option-1:35000:55': 119.11,
    'option-4:300000:43': 818.16,
    'option-3:40000:8': 37.26,
    'option-1:25000:21': 23.87,
    'option-3:85000:59': 398.82,
    'option-2:100000:18': 63.64,
    'option-3:30000:58': 145.0,
    'option-3:200000:5': 127.59,
    'option-4:30000:18': 35.47,
    'option-4:450000:32': 553.12,
    'option-1:20000:19': 22.13,
    'option-4:95000:38': 176.25,
    'option-3:75000:36': 95.66,
    'option-1:60000:35': 50.68,
    'option-3:90000:55': 311.79,
    'option-2:15000:36': 36.09,
    'option-4:45000:40': 89.97,
    'option-4:500000:5': 368.87,
    'option-4:300000:44': 818.16,
    'option-4:40000:55': 263.19,
    'option-2:50000:9': 39.27,
    'option-3:80000:16': 59.74,
    'option-3:10000:1': 20.97,
    'option-4:55000:55': 361.05,
    'option-4:40000:58': 367.37,
    'option-2:35000:21': 31.93,
    'option-4:20000:13': 28.43,
    'option-4:80000:0': 70.54,
    'option-3:95000:51': 328.65,
    'option-1:5000:21': 17.45,
    'option-2:200000:36': 315.75,
    'option-3:250000:36': 286.82,
    'option-4:45000:27': 54.36,
    'option-1:40000:59': 181.76,
    'option-3:500000:21': 299.59,
    'option-2:300000:46': 1210.68,
    'option-2:5000:57': 57.62,
    'option-1:500000:39': 455.77,
    'option-3:75000:21': 56.91,
    'option-2:85000:12': 56.29,
    'option-2:45000:23': 36.83,
    'option-4:75000:57': 691.84,
    'option-1:200000:22': 84.98,
    'option-1:300000:29': 153.23,
    'option-4:250000:40': 444.19,
    'option-4:70000:38': 133.31,
    'option-3:75000:50': 183.47,
    'option-4:15000:21': 25.34,
    'option-1:80000:3': 43.02,
    'option-4:85000:18': 74.07,
    'option-1:50000:32': 44.66,
    'option-4:30000:54': 197.67,
    'option-3:450000:46': 1048.19,
    'option-3:65000:10': 51.38,
    'option-3:90000:8': 65.4,
    'option-4:95000:4': 81.12,
    'option-2:20000:19': 24.6,
    'option-2:5000:29': 18.95,
    'option-2:60000:24': 44.16,
    'option-4:500000:20': 368.87,
    'option-3:30000:0': 31.61,
    'option-4:400000:2': 298.07,
    'option-2:65000:7': 46.5,
    'option-4:10000:4': 22.21,
    'option-3:450000:56': 2083.57,
    'option-2:35000:24': 31.93,
    'option-3:70000:7': 54.09,
    'option-4:50000:12': 49.57,
    'option-4:60000:26': 67.85,
    'option-2:75000:43': 195.48,
    'option-1:40000:7': 29.11,
    'option-1:150000:29': 82.7,
    'option-3:20000:52': 75.98,
    'option-2:30000:36': 58.0,
    'option-1:95000:29': 57.8,
    'option-2:60000:29': 55.38,
    'option-2:35000:15': 31.93,
    'option-3:50000:8': 42.91,
    'option-2:60000:52': 380.06,
    'option-3:55000:24': 45.73,
    'option-1:400000:16': 157.41,
    'option-4:100000:22': 84.65,
    'option-1:5000:0': 17.45,
    'option-1:15000:2': 20.58,
    'option-2:15000:6': 22.43,
    'option-2:35000:14': 31.93,
    'option-3:45000:8': 40.08,
    'option-2:25000:32': 38.22,
    'option-1:95000:4': 48.27,
    'option-1:35000:31': 35.63,
    'option-3:300000:32': 262.0,
    'option-4:250000:3': 190.05,
    'option-3:45000:12': 40.08,
    'option-2:400000:44': 1000.28,
    'option-3:60000:12': 48.56,
    'option-1:85000:6': 44.77,
    'option-2:20000:35': 33.32,
    'option-2:20000:45': 59.74,
    'option-2:15000:58': 137.28,
    'option-3:15000:11': 23.45,
    'option-3:25000:42': 51.6,
    'option-1:15000:11': 20.58,
    'option-1:95000:46': 208.15,
    'option-2:85000:0': 56.29,
    'option-1:45000:35': 41.65,
    'option-4:5000:29': 20.0,
    'option-3:5000:53': 31.23,
    'option-3:60000:7': 48.56,
    'option-3:70000:18': 54.09,
    'option-1:55000:23': 34.28,
    'option-3:150000:7': 99.29,
    'option-4:35000:56': 320.93,
    'option-1:65000:44': 100.41,
    'option-4:70000:35': 96.9,
    'option-3:300000:33': 262.0,
    'option-4:35000:7': 39.0,
    'option-1:50000:43': 80.2,
    'option-4:40000:36': 81.29,
    'option-4:250000:14': 190.05,
    'option-1:30000:5': 25.62,
    'option-1:55000:30': 39.78,
    'option-1:500000:36': 455.77,
    'option-2:80000:18': 53.84,
    'option-4:400000:37': 707.81,
    'option-4:70000:5': 63.65,
    'option-3:300000:41': 484.56,
    'option-1:95000:43': 140.53,
    'option-2:10000:3': 20.26,
    'option-3:150000:10': 99.29,
    'option-4:150000:24': 119.82,
    'option-1:95000:36': 97.25,
    'option-1:95000:53': 307.03,
    'option-1:200000:25': 84.98,
    'option-2:400000:23': 212.75,
    'option-2:75000:48': 307.57,
    'option-3:500000:46': 1163.55,
    'option-2:300000:9': 164.12,
    'option-4:200000:13': 155.14,
    'option-2:35000:42': 96.81,
    'option-4:150000:9': 119.82,
    'option-3:30000:21': 31.61,
    'option-3:45000:51': 160.41,
    'option-1:35000:45': 59.96,
    'option-4:500000:34': 612.97,
    'option-4:5000:4': 19.1,
    'option-1:5000:19': 17.45,
    'option-1:100000:13': 50.02,
    'option-3:300000:23': 186.71,
    'option-1:500000:52': 1582.54,
    'option-4:55000:46': 239.39,
    'option-4:450000:44': 1220.48,
    'option-2:85000:49': 347.65,
    'option-3:75000:25': 56.91,
    'option-3:400000:53': 1362.29,
    'option-4:60000:40': 115.98,
    'option-1:50000:27': 37.52,
    'option-2:45000:28': 45.13,
    'option-4:50000:3': 49.57,
    'option-4:90000:8': 77.6,
    'option-3:500000:0': 299.59,
    'option-4:95000:20': 81.12,
    'option-3:400000:14': 242.84,
    'option-4:100000:15': 84.65,
    'option-3:75000:31': 75.45,
    'option-3:70000:44': 122.06,
    'option-4:90000:20': 77.6,
    'option-1:50000:58': 225.23,
    'option-2:350000:6': 188.68,
    'option-1:25000:17': 23.87,
    'option-4:350000:5': 263.51,
    'option-3:70000:42': 122.06,
    'option-2:150000:36': 239.97,
    'option-4:400000:54': 2619.38,
    'option-3:90000:2': 65.4,
    'option-4:25000:19': 31.94,
    'option-4:10000:29': 23.93,
    'option-3:70000:60': 329.58,
    'option-4:95000:45': 264.68,
    'option-1:500000:40': 455.77,
    'option-2:450000:58': 4052.7,
    'option-3:70000:35': 71.36,
    'option-2:400000:35': 408.41,
    'option-4:350000:44': 953.02,
    'option-1:90000:41': 133.8,
    'option-1:35000:53': 119.11,
    'option-3:100000:7': 71.05,
    'option-1:500000:50': 1051.46,
    'option-2:300000:32': 310.13,
    'option-3:30000:29': 34.47,
    'option-2:450000:9': 237.36,
    'option-1:10000:8': 19.02,
    'option-1:450000:3': 174.93,
    'option-2:25000:25': 27.04,
    'option-2:100000:39': 163.84,
    'option-2:50000:5': 39.27,
    'option-1:15000:54': 58.03,
    'option-1:5000:20': 17.45,
    'option-1:15000:27': 21.94,
    'option-4:500000:35': 612.97,
    'option-3:150000:51': 515.11,
    'option-3:90000:13': 65.4,
    'option-3:200000:57': 929.24,
    'option-2:55000:13': 41.71,
    'option-3:10000:37': 25.66,
    'option-3:350000:59': 1624.38,
    'option-1:500000:4': 192.42,
    'option-3:450000:8': 271.3,
    'option-3:85000:17': 62.57,
    'option-3:30000:60': 145.0,
    'option-2:85000:46': 347.65,
    'option-2:30000:57': 267.29,
    'option-1:500000:35': 318.09,
    'option-2:350000:28': 256.27,
    'option-2:85000:19': 56.29,
    'option-3:10000:34': 23.22,
    'option-1:10000:35': 21.28,
    'option-4:100000:26': 103.63,
    'option-2:10000:11': 20.26,
    'option-2:25000:2': 27.04,
    'option-3:200000:45': 325.57,
    'option-2:80000:34': 92.07,
    'option-4:90000:51': 590.0,
    'option-2:40000:52': 254.22,
    'option-2:95000:46': 386.9,
    'option-4:65000:43': 184.61,
    'option-2:95000:14': 61.19,
    'option-3:50000:26': 47.84,
    'option-2:40000:40': 73.09,
    'option-4:65000:54': 426.49,
    'option-4:25000:50': 112.34,
    'option-2:55000:57': 492.66,
    'option-2:55000:53': 348.53,
    'option-4:60000:46': 260.58,
    'option-1:50000:52': 166.17,
    'option-3:95000:53': 328.65,
    'option-2:350000:34': 359.35,
    'option-2:40000:6': 34.38,
    'option-2:90000:41': 232.5,
    'option-4:250000:18': 190.05,
    'option-1:75000:58': 334.43,
    'option-4:20000:30': 31.92,
    'option-2:95000:3': 61.19,
    'option-2:30000:13': 29.49,
    'option-3:450000:26': 317.45,
    'option-3:50000:6': 42.91,
    'option-3:75000:46': 183.47,
    'option-4:55000:16': 53.09,
    'option-3:80000:53': 278.73,
    'option-2:30000:14': 29.49,
    'option-1:500000:15': 192.42,
    'option-1:35000:23': 27.36,
    'option-1:500000:1': 192.42,
    'option-4:100000:32': 132.52,
    'option-3:60000:48': 148.93,
    'option-4:30000:29': 40.86,
    'option-4:70000:58': 645.44,
    'option-4:75000:51': 491.9,
    'option-3:150000:55': 515.11,
    'option-1:70000:46': 156.68,
    'option-4:65000:36': 124.65,
    'option-1:35000:9': 27.36,
    'option-3:5000:8': 18.47,
    'option-1:65000:58': 290.77,
    'option-1:50000:47': 115.12,
    'option-3:400000:40': 454.51,
    'option-3:300000:21': 186.71,
    'option-1:80000:59': 356.26,
    'option-4:250000:35': 311.33,
    'option-1:60000:38': 66.66,
    'option-2:5000:37': 22.74,
    'option-1:400000:33': 257.57,
    'option-2:55000:55': 348.53,
    'option-2:60000:60': 537.8,
    'option-3:10000:10': 20.97,
    'option-1:45000:57': 203.38,
    'option-4:500000:26': 465.63,
    'option-3:95000:16': 68.23,
    'option-2:350000:42': 876.7,
    'option-4:85000:17': 74.07,
    'option-2:15000:7': 22.43,
    'option-1:75000:31': 59.58,
    'option-4:150000:22': 119.82,
    'option-4:25000:16': 31.94,
    'option-2:65000:16': 46.5,
    'option-3:30000:40': 46.27,
    'option-2:95000:38': 156.24,
    'option-2:85000:27': 72.32,
    'option-3:45000:29': 44.5,
    'option-1:90000:44': 133.8,
    'option-3:90000:51': 311.79,
    'option-4:70000:52': 459.2,
    'option-4:60000:4': 56.61,
    'option-3:55000:45': 98.56,
    'option-1:55000:38': 62.26,
    'option-4:300000:23': 228.15,
    'option-2:50000:55': 316.98,
    'option-2:5000:25': 18.11,
    'option-4:300000:56': 2779.29,
    'option-2:35000:7': 31.93,
    'option-4:60000:51': 393.77,
    'option-3:400000:18': 242.84,
    'option-1:90000:6': 46.52,
    'option-2:5000:6': 18.11,
    'option-1:150000:38': 145.49,
    'option-2:10000:9': 20.26,
    'option-4:30000:36': 64.05,
    'option-3:60000:56': 283.4,
    'option-2:15000:10': 22.43,
    'option-1:20000:60': 94.49,
    'option-3:75000:51': 261.83,
    'option-4:60000:52': 393.77,
    'option-4:450000:52': 2942.5,
    'option-3:200000:3': 127.59,
    'option-4:55000:53': 361.05,
    'option-1:75000:1': 41.27,
    'option-3:85000:34': 83.46,
    'option-3:15000:39': 30.55,
    'option-3:40000:7': 37.26,
    'option-4:30000:8': 35.47,
    'option-3:50000:59': 237.2,
    'option-1:40000:29': 33.0,
    'option-1:55000:60': 247.09,
    'option-3:25000:44': 51.6,
    'option-4:10000:48': 53.83,
    'option-4:300000:58': 2779.29,
    'option-1:200000:26': 105.35,
    'option-3:80000:44': 137.73,
    'option-2:450000:18': 237.36,
    'option-4:500000:4': 368.87,
    'option-1:30000:43': 53.32,
    'option-3:90000:11': 65.4,
    'option-2:50000:19': 39.27,
    'option-3:90000:14': 65.4,
    'option-2:90000:39': 148.66,
    'option-1:10000:33': 21.28,
    'option-4:150000:48': 641.57,
    'option-4:75000:54': 491.9,
    'option-3:150000:41': 247.33,
    'option-3:100000:9': 71.05,
    'option-4:400000:34': 493.98,
    'option-2:20000:11': 24.6,
    'option-4:5000:55': 45.61,
    'option-2:65000:58': 582.94,
    'option-3:45000:31': 50.9,
    'option-3:450000:21': 271.3,
    'option-2:500000:36': 772.96,
    'option-1:350000:22': 140.14,
    'option-1:65000:3': 37.77,
    'option-2:15000:27': 25.04,
    'option-1:45000:45': 73.46,
    'option-4:40000:39': 81.29,
    'option-1:30000:10': 25.62,
    'option-1:50000:45': 80.2,
    'option-1:10000:7': 19.02,
    'option-4:250000:51': 1632.05,
    'option-1:150000:54': 480.59,
    'option-2:350000:31': 359.35,
    'option-4:10000:34': 26.6,
    'option-1:400000:27': 198.24,
    'option-1:35000:51': 119.11,
    'option-3:40000:48': 102.8,
    'option-4:50000:9': 49.57,
    'option-1:5000:51': 30.18,
    'option-1:65000:59': 290.77,
    'option-1:35000:40': 44.67,
    'option-3:85000:23': 62.57,
    'option-1:95000:59': 420.75,
    'option-2:450000:48': 1810.35,
    'option-2:70000:17': 48.94,
    'option-4:45000:34': 67.01,
    'option-2:20000:26': 28.04,
    'option-3:10000:17': 20.97,
    'option-4:150000:3': 119.82,
    'option-3:45000:55': 160.41,
    'option-4:10000:50': 53.83,
    'option-1:25000:14': 23.87,
    'option-4:200000:51': 1308.78,
    'option-4:60000:7': 56.61,
    'option-4:15000:58': 140.7,
    'option-3:100000:24': 71.05,
    'option-3:150000:13': 99.29,
    'option-2:45000:31': 57.77,
    'option-3:20000:20': 25.96,
    'option-3:10000:59': 58.04,
    'option-4:200000:12': 155.14,
    'option-2:95000:23': 61.19,
    'option-4:50000:20': 49.57,
    'option-1:400000:39': 368.23,
    'option-4:100000:25': 84.65,
    'option-1:350000:39': 324.23,
    'option-2:80000:6': 53.84,
    'option-4:350000:33': 434.15,
    'option-2:40000:0': 34.38,
    'option-2:80000:0': 53.84,
    'option-2:300000:36': 469.79,
    'option-4:65000:0': 60.13,
    'option-4:100000:30': 103.63,
    'option-1:150000:13': 67.5,
    'option-3:55000:57': 260.3,
    'option-1:5000:31': 18.62,
    'option-4:80000:18': 70.54,
    'option-2:300000:4': 164.12,
    'option-2:40000:15': 34.38,
    'option-2:150000:42': 380.55,
    'option-3:35000:35': 42.72,
    'option-4:60000:49': 260.58,
    'option-1:100000:30': 60.07,
    'option-2:10000:53': 72.37,
    'option-3:95000:45': 160.89,
    'option-3:20000:3': 25.96,
    'option-4:10000:44': 39.9,
    'option-1:80000:25': 43.02,
    'option-2:350000:21': 188.68,
    'option-4:20000:5': 28.43,
    'option-1:25000:43': 46.58,
    'option-3:65000:22': 51.38,
    'option-4:55000:30': 63.35,
    'option-2:500000:42': 1246.05,
    'option-3:20000:16': 25.96,
    'option-3:150000:57': 698.69,
    'option-4:250000:37': 444.19,
    'option-4:200000:6': 155.14,
    'option-1:20000:36': 31.54,
    'option-3:100000:6': 71.05,
    'option-1:80000:5': 43.02,
    'option-2:90000:18': 58.74,
    'option-2:55000:56': 492.66,
    'option-2:25000:49': 107.16,
    'option-1:300000:33': 197.74,
    'option-2:15000:53': 100.27,
    'option-1:90000:14': 46.52,
    'option-4:15000:35': 31.87,
    'option-2:65000:28': 58.8,
    'option-1:30000:35': 32.61,
    'option-1:200000:1': 84.98,
    'option-4:70000:41': 198.05,
    'option-3:40000:43': 75.04,
    'option-1:35000:25': 27.36,
    'option-2:250000:39': 390.8,
    'option-2:300000:37': 469.79,
    'option-2:150000:46': 607.98,
    'option-4:150000:57': 1387.32,
    'option-1:350000:27': 175.95,
    'option-4:45000:21': 46.04,
    'option-1:150000:11': 67.5,
    'option-2:5000:50': 34.06,
    'option-3:15000:59': 78.48,
    'option-3:10000:39': 25.66,
    'option-2:150000:55': 947.09,
    'option-3:70000:27': 61.2,
    'option-1:10000:53': 44.1,
    'option-3:30000:22': 31.61,
    'option-4:500000:29': 465.63,
    'option-3:10000:8': 20.97,
    'option-4:5000:37': 23.72,
    'option-4:60000:50': 260.58,
    'option-4:300000:47': 1278.33,
    'option-3:20000:25': 25.96,
    'option-1:45000:25': 30.86,
    'option-2:60000:57': 537.8,
    'option-3:50000:50': 125.89,
    'option-3:65000:54': 228.04,
    'option-2:60000:55': 380.06,
    'option-4:15000:14': 25.34,
    'option-3:75000:20': 56.91,
    'option-1:100000:29': 60.07,
    'option-1:40000:15': 29.11,
    'option-2:60000:58': 537.8,
    'option-3:450000:37': 508.76,
    'option-1:500000:27': 243.64,
    'option-3:400000:24': 242.84,
    'option-3:90000:43': 153.07,
    'option-4:55000:45': 157.71,
    'option-4:35000:0': 39.0,
    'option-1:40000:3': 29.11,
    'option-4:80000:45': 224.93,
    'option-1:60000:0': 36.02,
    'option-4:95000:19': 81.12,
    'option-4:30000:7': 35.47,
    'option-1:15000:60': 75.13,
    'option-2:10000:47': 51.8,
    'option-4:80000:15': 70.54,
    'option-4:25000:23': 31.94,
    'option-4:15000:43': 51.85,
    'option-2:450000:44': 1122.36,
    'option-4:500000:14': 368.87,
    'option-4:150000:19': 119.82,
    'option-3:5000:34': 19.62,
    'option-2:45000:48': 187.27,
    'option-2:450000:56': 4052.7,
    'option-3:40000:22': 37.26,
    'option-1:55000:47': 125.51,
    'option-3:50000:18': 42.91,
    'option-4:70000:15': 63.65,
    'option-3:200000:13': 127.59,
    'option-2:400000:18': 212.75,
    'option-3:15000:18': 23.45,
    'option-1:150000:39': 145.49,
    'option-1:40000:19': 29.11,
    'option-4:65000:41': 184.61,
    'option-2:85000:2': 56.29,
    'option-4:90000:56': 831.0,
    'option-3:200000:17': 127.59,
    'option-4:50000:1': 49.57,
    'option-2:90000:2': 58.74,
    'option-4:95000:49': 407.95,
    'option-1:85000:59': 378.08,
    'option-2:95000:25': 61.19,
    'option-1:100000:56': 442.63,
    'option-1:20000:43': 39.85,
    'option-4:350000:7': 263.51,
    'option-1:100000:45': 147.27,
    'option-4:55000:12': 53.09,
    'option-3:500000:35': 425.48,
    'option-2:500000:49': 2011.11,
    'option-1:250000:46': 529.22,
    'option-2:25000:10': 27.04,
    'option-1:60000:1': 36.02,
    'option-4:50000:46': 218.19,
    'option-3:10000:30': 21.89,
    'option-1:30000:13': 25.62,
    'option-2:350000:57': 3155.5,
    'option-1:20000:20': 22.13,
    'option-2:10000:5': 20.26,
    'option-1:50000:16': 32.6,
    'option-2:300000:57': 2702.97,
    'option-3:10000:0': 20.97,
    'option-3:75000:27': 64.4,
    'option-2:50000:11': 39.27,
    'option-4:30000:12': 35.47,
    'option-4:90000:15': 77.6,
    'option-2:200000:33': 209.63,
    'option-4:300000:14': 228.15,
    'option-4:75000:12': 67.02,
    'option-4:10000:32': 26.6,
    'option-3:30000:16': 31.61,
    'option-4:90000:3': 77.6,
    'option-1:30000:49': 73.64,
    'option-3:40000:21': 37.26,
    'option-3:95000:3': 68.23,
    'option-4:90000:57': 831.0,
    'option-2:45000:46': 187.27,
    'option-2:400000:43': 1000.28,
    'option-4:50000:58': 459.74,
    'option-2:70000:23': 48.94,
    'option-4:90000:38': 167.6,
    'option-1:60000:56': 268.93,
    'option-1:95000:51': 307.03,
    'option-1:450000:13': 174.93,
    'option-3:500000:43': 797.55,
    'option-3:75000:56': 352.66,
    'option-4:400000:46': 1702.7,
    'option-3:60000:11': 48.56,
    'option-1:85000:5': 44.77,
    'option-4:10000:19': 22.21,
    'option-3:25000:0': 28.78,
    'option-4:80000:19': 70.54,
    'option-1:15000:10': 20.58,
    'option-3:25000:46': 68.37,
    'option-3:25000:60': 121.81,
    'option-2:50000:49': 207.33,
    'option-1:40000:31': 38.64,
    'option-4:50000:15': 49.57,
    'option-1:100000:17': 50.02,
    'option-3:200000:49': 471.07,
    'option-4:350000:45': 953.02,
    'option-2:25000:42': 72.08,
    'option-4:65000:17': 60.13,
    'option-3:50000:4': 42.91,
    'option-3:95000:25': 68.23,
    'option-3:5000:55': 31.23,
    'option-3:350000:2': 215.07,
    'option-2:350000:9': 188.68,
    'option-3:70000:46': 171.96,
    'option-2:85000:14': 56.29,
    'option-4:200000:23': 155.14,
    'option-1:50000:30': 37.52,
    'option-1:150000:42': 214.84,
    'option-1:45000:4': 30.86,
    'option-3:500000:13': 299.59,
    'option-3:35000:45': 67.15,
    'option-3:150000:29': 114.5,
    'option-2:60000:38': 103.46,
    'option-1:150000:10': 67.5,
    'option-4:15000:4': 25.34,
    'option-2:35000:43': 96.81,
    'option-2:55000:23': 41.71,
    'option-4:20000:58': 181.91,
    'option-3:15000:28': 24.85,
    'option-3:80000:23': 59.74,
    'option-2:5000:31': 20.32,
    'option-3:350000:1': 215.07,
    'option-3:100000:33': 95.75,
    'option-1:35000:5': 27.36,
    'option-2:70000:32': 82.27,
    'option-3:150000:33': 136.57,
    'option-4:25000:9': 31.94,
    'option-2:400000:19': 212.75,
    'option-1:450000:8': 174.93,
    'option-1:80000:13': 43.02,
    'option-3:25000:48': 68.37,
    'option-1:15000:1': 20.58,
    'option-4:10000:8': 22.21,
    'option-4:15000:44': 51.85,
    'option-4:350000:29': 331.15,
    'option-3:55000:40': 73.74,
    'option-1:90000:38': 92.84,
    'option-3:85000:51': 295.62,
    'option-4:300000:54': 1963.97,
    'option-2:20000:14': 24.6,
    'option-1:5000:32': 18.62,
    'option-1:45000:47': 104.72,
    'option-1:80000:21': 43.02,
    'option-1:40000:33': 38.64,
    'option-3:10000:9': 20.97,
    'option-4:65000:4': 60.13,
    'option-3:100000:23': 71.05,
    'option-1:350000:26': 175.95,
    'option-1:20000:8': 22.13,
    'option-3:20000:50': 56.85
  }
}
