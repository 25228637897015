/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const form = {
  'benefit-option': null,
  'premium-updates': null,
  'standalone-options': {
    'death-sum-assured': null,
    'total-personal-disability-sum-assured': null,
    'illness-sum-assured': null,
  },
  'accelerated-options': {
    'death-and-total-personal-disability-sum-assured': null,
    'death-total-personal-disability-and-illness-sum-assured': null,
  },
  'hospitalisation-units': null,
  'personal-accident-death-cover': {
    'accidental-death-units': null,
    'incapacity-units': null,
  },
  'educational-support-benefit': {
    'educational-support-premium': null,
    'educational-support-term': null,
  },
  'retirement-benefit': {
    'retirement-premium': null,
    'retirement-annuity-term': null,
  },
  'funeral-cover-options': {
    executive: null,
    premier: null,
    exclusive: null,
    'no-option': null,
  },
  'rider-options': {
    memorial: null,
    'pre-burial': null,
    celebration: null,
  },
  'asset-prevention-benefit': {
    'type-of-asset': null,
    'value-of-asset': null,
    'address-of-asset': null,
  },
  'benefit-payout-options': {
    'full-upfront': null,
    'full-investment': null,
    'upfront-and-investment': null,
  },
  'life-assured-identification': {
    'first-names': null,
    surname: null,
    'idpassport-number': null,
    'idpassport-type': null,
    title: null,
    gender: null,
    'date-of-birth': null,
    'marital-status': null,
    nationality: null,
    'university-degree': null,
  },
  'life-assured-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
    fax: null,
    email: null,
  },
  'life-assured-postal-address': {
    addressee: null,
    'po-box-or-private-bag': null,
    'area-code': null,
    street: null,
    suburb: null,
    town: null,
    region: null,
  },
  'life-assured-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
    'basic-earnings': null,
    'pay-day': null,
    'salary-payment-frequency': null,
    occupation: null,
    'employment-date': null,
  },
  'trustee-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    gender: null,
    title: null,
    'marital-status': null,
    'id-type': null,
  },
  'trustee-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
  },
  'spouse-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
    gender: null,
    title: null,
    'marital-status': null,
  },
  'spouse-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
  },
  'spouse-funeral': null,
  'spouse-is-trustee': null,
  'additional-lives': [],
  'beneficiary-details': [],
  'existing-policies': {
    'existing-coverage': null,
    'application-replaces-existing': null,
  },
  'existing-policy-details': {
    'company-name-1': null,
    'existing-contract-id-1': null,
    'sum-assured-1': null,
    'company-name-2': null,
    'existing-contract-id-2': null,
    'sum-assured-2': null,
    'company-name-3': null,
    'existing-contract-id-3': null,
    'sum-assured-3': null,
  },
  'life-assured-general-habits': {
    'planning-other-insurance': null,
    'planning-other-insurance-details': null,
    'other-assurance-past12-months': null,
    'other-assurance-past12-months-details': null,
    'cover-exceeds-one-hundred-thousand': null,
    'average-monthly-gross-income': null,
    'total-existing-assurance-cover-in-force-on-your-life': null,
    'full-reasons-for-effecting-this-additional-cover': null,
    'risky-occupation-hobbies': null,
    'risky-occupation-hobbies-details': null,
    'refused-application-or-increased-premium': null,
    'refused-application-or-increased-premium-details': null,
    'insolvency-issue': null,
    'insolvency-issue-details': null,
    'occupation-not-clerical': null,
    'occupation-not-clerical-details': null,
    smoker: null,
    'non-smoker': null,
    'never-smoked': null,
    'years-smoking': null,
    'years-not-smoking': null,
    'cigarettes-per-day': null,
    'cigars-per-day': null,
    pipe: null,
    'medical-advice-stop-smoking': null,
    'medical-advice-reduce-drinking': null,
    'excessive-drinking': null,
    'excessive-drinking-past': null,
  },
  'life-assured-health-details': {
    'surgery-last-five-years': null,
    'medical-tests-last-five-years': null,
    disability: null,
    'assurance-risk': null,
    pregnant: null,
    'family-history-of-illness': null,
    'family-history-of-illness-details': null,
    'close-family-early-death': null,
    'height-cm': null,
    'mass-weight-in-kg': null,
  },
  'life-assured-medical-details': {
    'chest-pain': null,
    'high-blood-pressure': null,
    'respiratory-condition': null,
    'digestive-condition': null,
    'urinary-condition': null,
    'anxiety-epilepsy': null,
    diabetes: null,
    'cancer-or-tumors': null,
    'arthritis-osteoporosis': null,
    aids: null,
    'sexually-transmitted-disease': null,
    'taking-medication': null,
  },
  'medical-attendant-details': {
    'medical-institution': null,
    'hospital-phone': null,
    'doctor-first-names': null,
    'doctor-surname': null,
    'doctor-cell-phone': null,
    'contact-doctor': null,
  },
  'health-concerns': [],
  'payment-method-selection': {
    'debit-order': null,
    'stop-order': null,
  },
  'payer-person-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
  },
  'payment-start-date': null,
  'payment-frequency': {
    monthly: null,
    annual: null,
    'bi-annual': null,
    quarterly: null,
  },
  'payer-person': {
    myself: null,
    'my-spouse': null,
  },
  'payment-bank-details': {
    'account-holder-name': null,
    'account-type': null,
    'account-entity-name': null,
    'account-branch-name': null,
    'account-number': null,
  },
  'payment-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
  },
  'payer-signature': null,
  documents: {
    'id-document': [],
  },
  'acceptance-signature': null,
  'application-acceptance-date': null,
  'application-accepted': null,
}
