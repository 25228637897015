/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'

const ValidationErrors = ({ errors }) => (
  <ul className={classNames({ 'error-description': true })}>
    {errors.map((error, i) => (
      <li
        className={classNames({ 'required-indicator': error === '*' })}
        key={i}
      >
        {error}
      </li>
    ))}
  </ul>
)

export default ValidationErrors
