/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import classNames from 'classnames'
import ValidationErrors from './ValidationErrors'

const useTooltips = process.env.APPLICATION_USE_TOOLTIPS === 'true'

const PhoneNumber = ({
  id,
  uid,
  key,
  label,
  hideLabel,
  readonly,
  value,
  errors,
  markers,
  itemIndex,
  tooltip,
  onChange,
}) => {
  const elementId = hideLabel && itemIndex ? `${id}-${itemIndex}` : id
  return (
    <div
      className={classNames({
        editable: !readonly,
        field: true,
        'has-icon': markers && markers.length > 0,
        'has-tooltip': useTooltips && tooltip,
        'has-value': value && value.length > 0,
        input: true,
      })}
    >
      {!hideLabel && (
        <label
          htmlFor={elementId}
          className={classNames({
            hidden: hideLabel,
            label: true,
          })}
        >
          {label}
        </label>
      )}
      <ReactPhoneInput
        containerClass='phone-number'
        inputClass={classNames({
          'has-error': errors && errors.length > 0,
        })}
        inputProps={{ id: id, disabled: readonly }}
        placeholder={useTooltips ? null : tooltip}
        country={process.env.APPLICATION_COUNTRY}
        preferredCountries={[process.env.APPLICATION_COUNTRY]}
        onChange={(v) => onChange('+' + v.replace(/\s/g, ''))}
        specialLabel={null}
        value={value}
      />
      {errors && errors.length > 0 && <ValidationErrors errors={errors} />}
    </div>
  )
}

export default PhoneNumber
