/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const form = {
  'life-assured-identification': {
    'first-names': null,
    surname: null,
    'idpassport-number': null,
    title: null,
    'date-of-birth': null,
    gender: null,
    'marital-status': null,
    ghanaian: null,
    'other-nationality': null,
  },
  'life-assured-identification-type': {
    dvla: null,
    passport: null,
    'voter-id': null,
    nhis: null,
    ssnit: null,
    'nia-ghana-card': null,
  },
  'life-assured-contact-details': {
    'cell-phone': null,
    email: null,
  },
  'life-assured-postal-address': {
    addressee: null,
    town: null,
    region: null,
    'po-box-or-private-bag': null,
  },
  'life-assured-residential-address': {
    street: null,
    suburb: null,
  },
  'life-assured-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
    'basic-earnings': null,
    'tin-number': null,
    occupation: null,
  },
  'life-assured-digital-address': {
    'digital-address': null,
  },
  'covered-details': {
    'joint-life': null,
    'main-life-only': null,
  },
  'benefit-option': null,
  'standalone-options': {
    'death-sum-assured': null,
    'total-personal-disability-sum-assured': null,
    'illness-sum-assured': null,
  },
  'accelerated-options': {
    'death-and-total-personal-disability': null,
    'death-and-critical-illness': null,
    'death-and-total-disability-and-critical-illness': null,
  },
  'accelerated-cover-sum-assured': null,
  'term-in-years': null,
  'update-option': null,
  'funeral-cover-for-main-life': null,
  'no-option': null,
  'hospitilization-cover-main-life-only': null,
  'hospitilization-cover-joint-life': null,
  'hospitalisation-units': null,
  'educational-support-benefit': {
    'educational-support-benefit': null,
    'educational-support-term': null,
  },
  'retirement-premium': null,
  'cash-bonus': null,
  'main-life-funeral-benefit': null,
  'policy-term': {
    'term-ten': null,
    'term-fifteen': null,
  },
  'rider-options': {
    'memorial-seven-day': null,
    'memorial-forty-day': null,
    'memorial-one-year': null,
  },
  'double-accident-benefit': null,
  'lives-assured': [],
  'parents-details': [],
  'children-details': [],
  'spouse-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    gender: null,
    title: null,
  },
  'spouse-contact-details': {
    'cell-phone': null,
  },
  'spouse-identification-type': {
    dvla: null,
    passport: null,
    'voter-id': null,
    nhis: null,
    ssnit: null,
    'nia-ghana-card': null,
  },
  'spouse-employment-details': {
    occupation: null,
    'employer-organisation': null,
    'employee-number': null,
    'tin-number': null,
  },
  'spouse-is-trustee': null,
  'trustee-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    title: null,
    relationship: null,
  },
  'trustee-contact-details': {
    'cell-phone': null,
  },
  'beneficiary-details': [],
  'existing-policies': {
    'existing-coverage': null,
    'application-replaces-existing': null,
  },
  'existing-policy-details': {
    'company-name-1': null,
    'existing-contract-id-1': null,
    'sum-assured-1': null,
    'company-name-2': null,
    'existing-contract-id-2': null,
    'sum-assured-2': null,
    'company-name-3': null,
    'existing-contract-id-3': null,
    'sum-assured-3': null,
  },
  'existing-policy-details': [],
  'life-assured-general-habits': {
    'planning-other-insurance': null,
    'planning-other-insurance-details': null,
    'other-assurance-past12-months': null,
    'other-assurance-past12-months-details': null,
    'cover-exceeds-one-hundred-thousand': null,
    'average-monthly-gross-income': null,
    'total-existing-assurance-cover-in-force-on-your-life': null,
    'full-reasons-for-effecting-this-additional-cover': null,
    'risky-occupation-hobbies': null,
    'risky-occupation-hobbies-details': null,
    'refused-application-or-increased-premium': null,
    'refused-application-or-increased-premium-details': null,
    'insolvency-issue': null,
    'insolvency-issue-details': null,
    'occupation-not-clerical': null,
    'occupation-not-clerical-details': null,
    smoker: null,
    'non-smoker': null,
    'never-smoked': null,
    'years-smoking': null,
    'years-not-smoking': null,
    'cigarettes-per-day': null,
    'cigars-per-day': null,
    pipe: null,
    'medical-advice-stop-smoking': null,
    'medical-advice-reduce-drinking': null,
    'excessive-drinking': null,
    'excessive-drinking-past': null,
  },
  'life-assured-health-tests': {
    'surgery-last-five-years': null,
    'medical-tests-last-five-years': null,
  },
  'life-assured-health-details': {
    'chest-pain': null,
    'high-blood-pressure': null,
    'respiratory-condition': null,
    'digestive-condition': null,
    'urinary-condition': null,
    'anxiety-epilepsy': null,
    diabetes: null,
    'cancer-or-tumors': null,
    'arthritis-osteoporosis': null,
    aids: null,
    'sexually-transmitted-disease': null,
    'taking-medication': null,
    disability: null,
    'assurance-risk': null,
    pregnant: null,
    'family-history-of-illness': null,
    'family-history-of-illness-details': null,
    'close-family-early-death': null,
    'height-in-cm': null,
    'weight-in-kg': null,
  },
  'health-concerns': [],
  'payment-method': {
    'debit-order': null,
    'stop-order': null,
  },
  'payment-start-date': null,
  'payment-frequency': {
    monthly: null,
    quarterly: null,
    annual: null,
    'bi-annual': null,
  },
  'life-assured-is-payer': null,
  'payer-person': {
    myself: null,
    'my-spouse': null,
  },
  'payment-bank-details': {
    'account-holder-name': null,
    'account-type': null,
    'account-entity-name': null,
    'account-branch-name': null,
    'account-number': null,
  },
  'payer-signature': null,
  'mobile-wallet-details': {
    'mobile-wallet-network-provider': null,
    'mobile-wallet-number': null,
  },
  'payment-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
  },
  'payer-person-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    relationship: null,
  },
  documents: {
    'id-document': [],
  },
  'payer-signature': null,
  'acceptance-signature': null,
  'application-acceptance-date': null,
  'application-accepted': null,
}
