/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import dayjs from 'dayjs'
import produce, { setAutoFreeze } from 'immer'
import { handleActions } from 'redux-actions'
import {
  checkCompleted,
  getUploadedStatus,
  recordEvent,
  uploadCompletedProcess
} from './actions'
import { newProcess, select, update } from './logic'
import { upload, getStatus, check, exportData } from './sync'

export const initialState = {
  online: navigator.onLine,
  at: dayjs().format(),
  route: window.location.hash,
  postponed: [],
  completed: [],
  agentContractId: null,
  currentProcess: null
}

const logEvent = ({ event, value }) => {
  console.group(
    '%cEvent',
    'background:#ffcc00; color: white; border-radius: 8px; padding: 0 8px'
  )
  console.log(`%c${event}%c: ${value}`, 'color: green', 'color: black')
  console.groupEnd()
}

setAutoFreeze(false)

/* Update state based on actions */
export const environment = handleActions(
  {
    // Set the online status
    SET_ONLINE_STATUS: (state, action) => {
      if (action.payload.online && state.completed.length > 0) {
        action.asyncDispatch(checkCompleted())
      }
      return produce(state, draft => {
        draft.online = action.payload.online
        draft.at = dayjs().format()
      })
    },
    // Trigger a hash routing update
    CHANGE_ROUTE: (state, action) =>
      produce(state, draft => {
        draft.route = action.payload.route
        select(draft, action.payload, action.asyncDispatch)
      }),
    // Trigger a button click
    CLICK_BUTTON: (state, action) =>
      produce(state, draft => {
        select(draft, action.payload, action.asyncDispatch)
      }),
    // Trigger an item click
    CLICK_ITEM: (state, action) =>
      produce(state, draft => {
        select(draft, action.payload, action.asyncDispatch)
      }),
    // Set value of an input
    SET_VALUE: (state, action) =>
      produce(state, draft => {
        update(draft, action.payload, action.asyncDispatch)
      }),
    START_PROCESS: (state, action) =>
      produce(state, draft => {
        newProcess(draft, action.payload.product, action.asyncDispatch)
        action.asyncDispatch(
          recordEvent('Start Application', action.payload.product.product)
        )
      }),
    POSTPONE_PROCESS: (state, action) =>
      produce(state, draft => {
        if (draft.currentProcess) {
          draft.postponed.push({
            at: dayjs().format(),
            id: action.payload.id,
            process: draft.currentProcess
          })
          action.asyncDispatch(
            recordEvent('Postpone Application', draft.currentProcess.product)
          )
          draft.currentProcess = null
        }
      }),
    CANCEL_PROCESS: (state, action) =>
      produce(state, draft => {
        if (draft.currentProcess) {
          action.asyncDispatch(
            recordEvent('Cancel Application', draft.currentProcess.product)
          )
        }
        draft.currentProcess = null
      }),
    RESUME_PROCESS: (state, action) =>
      produce(state, draft => {
        const proc = draft.postponed.find(p => p.id === action.payload.id)
        if (proc) {
          draft.currentProcess = proc.process
          draft.postponed = draft.postponed.filter(
            p => p.id !== action.payload.id
          )
          if (draft.currentProcess) {
            action.asyncDispatch(
              recordEvent('Resume Application', draft.currentProcess.product)
            )
          }
        } else {
          draft.currentProcess = null
          draft.error = `Process ${action.payload.id} could not be found`
        }
      }),
    COMPLETE_PROCESS: (state, action) =>
      produce(state, draft => {
        if (draft.currentProcess) {
          draft.completed.push({
            at: dayjs().format(),
            label: state.currentProcess.label,
            product: state.currentProcess.product,
            form: state.currentProcess.form,
            id: state.currentProcess['process-id'],
            agent: state.currentProcess.agent || state.agentContractId,
            reference: action.payload.reference,
            environment: process.env.APPLICATION_ENV,
            version: process.env.REVISION,
            release: process.env.APPLICATION_VERSION,
            status: 'pending'
          })
          action.asyncDispatch(
            recordEvent('Complete Application', draft.currentProcess.product)
          )
          draft.currentProcess = null
          if (state.online) {
            action.asyncDispatch(
              uploadCompletedProcess(action.payload.reference, 'start', {})
            )
          }
        }
      }),
    CHECK_COMPLETED: (state, action) => {
      if (state.completed.length > 0) {
        check(state.completed, action.asyncDispatch)
      }
      return state
    },
    UPLOAD_COMPLETED_PROCESS: (state, action) =>
      produce(state, draft => {
        const app = draft.completed.find(
          p => p.reference === action.payload.ref
        )
        if (app) {
          switch (action.payload.status) {
            case 'start':
              app.status = 'uploading'
              upload(
                state.completed.find(p => p.reference === action.payload.ref),
                action.asyncDispatch
              )
              break
            case 'success':
              app.status = 'uploaded'
              app.upload_result = action.payload.info
              setTimeout(() => {
                action.asyncDispatch(
                  getUploadedStatus(action.payload.ref, action.payload.info.id)
                )
              }, 60000)
              action.asyncDispatch(
                recordEvent('Uploaded Application', action.payload.ref)
              )
              break
            case 'failure':
              app.status = 'pending'
              break
            case 'rejected':
              app.status = 'rejected'
              action.asyncDispatch(
                recordEvent('Rejected Application', action.payload.ref)
              )
              break
          }
        }
      }),
    GET_UPLOADED_STATUS: (state, action) => {
      getStatus(
        action.payload.ref,
        action.payload.requestId,
        action.asyncDispatch
      )
      return state
    },
    SET_UPLOADED_STATUS: (state, action) =>
      produce(state, draft => {
        const app = draft.completed.find(
          p => p.reference === action.payload.ref
        )
        if (app) {
          app.status = 'complete'
          app.processId = action.payload.processId
        }
      }),
    SET_AGENT_DETAILS: (state, action) =>
      produce(state, draft => {
        if (action.payload.contractId) {
          draft.agentContractId = action.payload.contractId
          draft.completed = state.completed.map(f => {
            if (!f.agent) {
              f.agent = action.payload.contractId
            }
            return f
          })
          draft.postponed = state.postponed.map(f => {
            if (f.process && !f.process.agent) {
              f.process.agent = action.payload.contractId
            }
            return f
          })
          if (process.env.ANALYTICS && window.ga) {
            window.ga('set', 'userId', action.payload.contractId)
          }
          if (process.env.SENTRY && window.Sentry) {
            window.Sentry.setUser({ id: action.payload.contractId })
          }
          action.asyncDispatch(recordEvent('Login', action.payload.contractId))
        } else {
          draft.agentContractId = null
          if (process.env.ANALYTICS && window.ga) {
            window.ga('set', 'userId', null)
          }
          if (process.env.SENTRY && window.Sentry) {
            window.Sentry.setUser(null)
          }
          if (state.agentContractId) {
            action.asyncDispatch(recordEvent('Logout', state.agentContractId))
          }
        }
      }),
    RECORD_EVENT: (state, action) => {
      if (process.env.ANALYTICS && window.ga) {
        window.ga('send', 'event', action.payload.event, action.payload.value)
      }
      if (process.env.NODE_ENV !== 'production') {
        logEvent(action.payload)
      }
      return state
    },
    INSTALL_PROMPT: (state, action) =>
      produce(state, draft => {
        draft.installPrompt = action.payload.prompt
      }),
    EXPORT_DATA: (state, action) => {
      exportData(state.completed, state.agentContractId)
      action.asyncDispatch(recordEvent('Data Export', state.agentContractId))
      return state
    }
  },
  initialState
)

export default environment
