/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const rates = {
  '2:main:28': 1.88,
  '3:main:35': 2.78,
  '3:main:54': 3.02,
  '2:main:31': 1.9,
  '5:main:41': 6.19,
  '2:main:38': 1.93,
  '3:main:41': 2.89,
  '2:main:47': 2.01,
  '4:main:38': 4.15,
  '3:main:42': 2.89,
  '2:main:21': 1.86,
  '2:main:22': 1.86,
  '3:main:39': 2.83,
  '1:main:28': 1.01,
  '2:main:57': 2.11,
  '5:main:57': 6.7,
  '4:main:59': 4.58,
  '3:main:56': 3.12,
  '1:main:56': 1.11,
  '2:main:24': 1.86,
  '4:main:23': 3.98,
  '5:main:21': 5.79,
  '3:main:52': 3.02,
  '1:main:18': 1.0,
  '4:main:52': 4.44,
  '2:main:54': 2.05,
  '4:main:42': 4.24,
  '4:main:54': 4.44,
  '5:main:28': 5.86,
  '1:main:44': 1.05,
  '3:main:19': 2.72,
  '4:main:57': 4.58,
  '5:main:37': 6.05,
  '5:main:53': 6.49,
  '1:main:25': 1.0,
  '3:main:21': 2.72,
  '5:main:52': 6.49,
  '5:main:58': 6.7,
  '1:main:30': 1.01,
  '2:main:44': 1.97,
  '4:main:33': 4.07,
  '4:main:56': 4.58,
  '1:main:36': 1.03,
  '4:main:29': 4.02,
  '1:main:52': 1.08,
  '5:main:36': 6.05,
  '5:main:27': 5.86,
  '3:main:45': 2.89,
  '5:main:43': 6.19,
  '2:main:52': 2.05,
  '3:main:24': 2.72,
  '3:main:51': 3.02,
  '1:main:50': 1.06,
  '1:main:53': 1.08,
  '3:main:18': 2.72,
  '4:main:21': 3.98,
  '2:main:29': 1.88,
  '2:main:41': 1.97,
  '5:main:55': 6.49,
  '1:main:37': 1.03,
  '4:main:40': 4.15,
  '2:main:32': 1.9,
  '5:main:30': 5.86,
  '2:main:39': 1.93,
  '4:main:25': 3.98,
  '1:main:19': 1.0,
  '4:main:41': 4.24,
  '4:main:53': 4.44,
  '4:main:24': 3.98,
  '1:main:49': 1.06,
  '3:main:58': 3.12,
  '2:main:25': 1.86,
  '3:main:49': 2.95,
  '1:main:21': 1.0,
  '3:main:31': 2.78,
  '1:main:26': 1.01,
  '3:main:37': 2.83,
  '2:main:56': 2.11,
  '5:main:29': 5.86,
  '1:main:23': 1.0,
  '4:main:32': 4.07,
  '2:main:18': 1.86,
  '5:main:51': 6.49,
  '3:main:28': 2.75,
  '3:main:53': 3.02,
  '1:main:42': 1.05,
  '1:main:38': 1.03,
  '1:main:35': 1.02,
  '5:main:59': 6.7,
  '3:main:40': 2.83,
  '3:main:55': 3.02,
  '1:main:59': 1.11,
  '2:main:45': 1.97,
  '4:main:26': 4.02,
  '4:main:55': 4.44,
  '1:main:46': 1.06,
  '4:main:30': 4.02,
  '4:main:58': 4.58,
  '1:main:57': 1.11,
  '4:main:43': 4.24,
  '3:main:44': 2.89,
  '3:main:50': 2.95,
  '1:main:24': 1.0,
  '3:main:38': 2.83,
  '4:main:46': 4.34,
  '2:main:19': 1.86,
  '1:main:22': 1.0,
  '2:main:30': 1.88,
  '3:main:48': 2.95,
  '5:main:32': 5.94,
  '5:main:50': 6.34,
  '1:main:27': 1.01,
  '2:main:53': 2.05,
  '3:main:27': 2.75,
  '2:main:37': 1.93,
  '4:main:19': 3.98,
  '2:main:49': 2.01,
  '4:main:39': 4.15,
  '5:main:26': 5.86,
  '2:main:26': 1.88,
  '4:main:31': 4.07,
  '3:main:34': 2.78,
  '5:main:23': 5.79,
  '5:main:38': 6.05,
  '1:main:48': 1.06,
  '2:main:27': 1.88,
  '4:main:45': 4.24,
  '3:main:26': 2.75,
  '3:main:30': 2.75,
  '3:main:59': 3.12,
  '1:main:31': 1.02,
  '5:main:40': 6.05,
  '3:main:57': 3.12,
  '5:main:44': 6.19,
  '1:main:54': 1.08,
  '5:main:20': 5.79,
  '2:main:48': 2.01,
  '3:main:47': 2.95,
  '2:main:40': 1.93,
  '1:main:20': 1.0,
  '3:main:29': 2.75,
  '4:main:35': 4.07,
  '1:main:29': 1.01,
  '3:main:23': 2.72,
  '4:main:18': 3.98,
  '5:main:24': 5.79,
  '5:main:22': 5.79,
  '4:main:36': 4.15,
  '5:main:56': 6.7,
  '2:main:23': 1.86,
  '4:main:51': 4.44,
  '3:main:22': 2.72,
  '2:main:34': 1.9,
  '4:main:28': 4.02,
  '3:main:43': 2.89,
  '5:main:42': 6.19,
  '1:main:51': 1.08,
  '4:main:34': 4.07,
  '4:main:20': 3.98,
  '2:main:58': 2.11,
  '5:main:54': 6.49,
  '1:main:47': 1.06,
  '5:main:49': 6.34,
  '5:main:33': 5.94,
  '3:main:36': 2.83,
  '1:main:34': 1.02,
  '1:main:33': 1.02,
  '1:main:45': 1.05,
  '2:main:51': 2.05,
  '4:main:22': 3.98,
  '1:main:41': 1.05,
  '5:main:39': 6.05,
  '1:main:55': 1.08,
  '4:main:48': 4.34,
  '5:main:35': 5.94,
  '2:main:20': 1.86,
  '2:main:46': 2.01,
  '5:main:47': 6.34,
  '5:main:48': 6.34,
  '1:main:40': 1.03,
  '3:main:20': 2.72,
  '3:main:25': 2.72,
  '5:main:34': 5.94,
  '1:main:43': 1.05,
  '4:main:49': 4.34,
  '5:main:31': 5.94,
  '1:main:39': 1.03,
  '2:main:36': 1.93,
  '5:main:19': 5.79,
  '4:main:27': 4.02,
  '3:main:32': 2.78,
  '5:main:25': 5.79,
  '3:main:33': 2.78,
  '1:main:58': 1.11,
  '2:main:55': 2.05,
  '5:main:45': 6.19,
  '1:main:32': 1.02,
  '3:main:46': 2.95,
  '4:main:44': 4.24,
  '2:main:59': 2.11,
  '5:main:46': 6.34,
  '2:main:33': 1.9,
  '4:main:37': 4.15,
  '2:main:50': 2.01,
  '2:main:35': 1.9,
  '2:main:43': 1.97,
  '4:main:47': 4.34,
  '2:main:42': 1.97,
  '4:main:50': 4.34,
  '5:main:18': 5.79
}
