/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */
import {
  createItem,
  createButton,
  slugify,
} from '../../../../src/core/dataitems'
import dayjs from 'dayjs'
const thisYear = dayjs().year()

export const pages = {
  'confirm-cancel': {
    modal: true,
    text: [],
    buttons: [
      { label: 'Yes', id: 'yes', uid: '0baa62eba1b1a7d7', control: true },
      { label: 'No', id: 'no', uid: '6c0e0e2956ec3933', control: true },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '9bc15702a2e49228',
      content: ['Are you sure you want to cancel this application?'],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'quotation-page': {
    text: [],
    buttons: [
      { label: 'Next', id: 'next', uid: '26ca340069ec84e5', control: true },
      { label: 'Cancel', id: 'cancel', uid: 'e25c3188a733209e', control: true },
    ],
    item: {
      id: 'quote-screen',
      type: 'list',
      readonly: false,
      label: 'Quote Screen',
      uid: 'bf508a295a853dc3',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '552d19e7a2f676a3',
          content: '<h2>Choose your cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a1fcd090d9138ba4',
          content:
            '<p>To be able to provide you with a quick quotation we will need the following information from you.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '56dfc210727e82c4',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'your-details',
          type: 'list',
          readonly: false,
          label: 'Your Details',
          uid: 'ae30ddc8ea6b5f75',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'b85bf420db7a7753',
              content: '<p>When were you born?</p>\n',
            },
            {
              id: 'date-of-birth',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Date Of Birth',
              uid: 'f0e0a8a867c4bd0c',
              content: '',
              publishChange: true,
              inputType: 'date',
              component: 'DateSelector',
              maxYear: thisYear,
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '36a48639064fc078',
              content: '<p>What is your gender?</p>\n',
            },
            {
              id: 'gender',
              type: 'field',
              elementType: 'radio',
              readonly: false,
              label: 'Gender',
              uid: 'a8a4db22b3901177',
              content: '',
              publishChange: true,
              options: ['Female', 'Male'],
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '91ffa524b2b4c765',
              content: '<p>What is your marital status?</p>\n',
            },
            {
              id: 'marital-status',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Marital Status',
              uid: 'fcd68df2ea5d3b92',
              content: '',
              publishChange: true,
              tooltip: 'Marital Status',
              options: [
                '',
                'Divorced',
                'Married',
                'Single',
                'Widow',
                'Widower',
              ],
            },
          ],
        },
        {
          id: 'cover-details',
          type: 'list',
          readonly: false,
          label: 'Cover Details',
          uid: '08888c57646f2ea2',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '177c66accb1464a3',
              content:
                '<p>The basic risk benefits included in this product comprise of Death, Total Permanent Disability (TPD) and Critical Illness(CI), and this come in Accelerated or Standalone forms.</p>\n',
            },
            {
              id: 'system-list',
              type: 'list',
              readonly: false,
              label: 'System List',
              uid: '0cf8e482cbd11e3b',
              content: [
                {
                  id: 'covered-life-selection-options',
                  type: 'list',
                  readonly: false,
                  label: 'Covered Life Selection Options',
                  uid: 'cfb7d7b9fce9448b',
                  content: [
                    {
                      id: 'system-constant',
                      type: 'html',
                      readonly: true,
                      label: '',
                      uid: '91687a5f23f095df',
                      content:
                        '<p>Would you like to take out a joint life policy?</p>\n',
                    },
                    {
                      id: 'covered-life-selection-radio-group',
                      type: 'list',
                      readonly: false,
                      label: 'Covered Life Selection Radio Group',
                      uid: 'e36aa8862537ba6e',
                      content: [
                        {
                          id: 'joint-life',
                          type: 'field',
                          elementType: 'checkbox',
                          readonly: false,
                          label: 'Joint Life',
                          uid: 'ecaaae9873493d37',
                          content: '',
                          publishChange: true,
                          options: ['Y', 'N'],
                        },
                        {
                          id: 'main-life-only',
                          type: 'field',
                          elementType: 'checkbox',
                          readonly: false,
                          label: 'Main Life Only',
                          uid: 'fe4c6440e6b5d713',
                          content: '',
                          publishChange: true,
                          options: ['Y', 'N'],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 'spouse-details',
                  type: 'list',
                  readonly: false,
                  label: 'Spouse Details',
                  uid: '3e032cfcc085d3ed',
                  content: [
                    {
                      id: 'system-constant',
                      type: 'html',
                      readonly: true,
                      label: '',
                      uid: '30393d6f96d3ed81',
                      content: '<p>When was your spouse born?</p>\n',
                    },
                    {
                      id: 'date-of-birth-spouse',
                      type: 'component',
                      elementType: 'input',
                      readonly: false,
                      label: 'Date Of Birth',
                      uid: '0d8e0852303cf721',
                      content: '',
                      publishChange: true,
                      inputType: 'date',
                      component: 'DateSelector',
                      maxYear: thisYear,
                    },
                  ],
                },
              ],
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '68188bd9a517e03b',
              content: '<p>What form of basic risk cover would you like?</p>\n',
            },
            {
              id: 'benefit-option',
              type: 'field',
              elementType: 'radio',
              readonly: false,
              label: 'Benefit Option',
              uid: 'adaf05e6248b85f8',
              content: '',
              publishChange: true,
              options: ['Standalone', 'Accelerated'],
            },
            {
              id: 'standalone-options',
              type: 'list',
              readonly: false,
              label: 'Standalone Options',
              uid: '17f5a69ffc982802',
              content: [
                {
                  id: 'death-sum-assured',
                  type: 'amount',
                  elementType: 'input',
                  readonly: false,
                  label: 'Death Sum Assured',
                  uid: '8ea74585f713380e',
                  content: '',
                  publishChange: true,
                  inputType: 'number',
                },
                {
                  id: 'total-personal-disability-sum-assured',
                  type: 'amount',
                  elementType: 'input',
                  readonly: false,
                  label: 'Total Personal Disability Sum Assured',
                  uid: '75c95b5f8b5f765a',
                  content: '',
                  publishChange: true,
                  inputType: 'number',
                },
                {
                  id: 'illness-sum-assured',
                  type: 'amount',
                  elementType: 'input',
                  readonly: false,
                  label: 'Illness Sum Assured',
                  uid: 'ba4200389af9d556',
                  content: '',
                  publishChange: true,
                  inputType: 'number',
                },
              ],
            },
            {
              id: 'accelerated-options',
              type: 'list',
              readonly: false,
              label: 'Accelerated Options',
              uid: '409fdfaa45405865',
              content: [
                {
                  id: 'accelerated-options-radio-group',
                  type: 'list',
                  readonly: false,
                  label: 'Accelerated Options Radio Group',
                  uid: '608ca9de54e1dea5',
                  content: [
                    {
                      id: 'death-and-total-personal-disability',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Death And Total Personal Disability',
                      uid: 'af54b757f3a54633',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'death-and-critical-illness',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Death And Critical Illness',
                      uid: 'a2174747cbfc9951',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'death-and-total-disability-and-critical-illness',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Death And Total Disability And Critical Illness',
                      uid: 'cab8e09d938c636e',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                  ],
                },
                {
                  id: 'accelerated-cover-sum-assured',
                  type: 'amount',
                  elementType: 'input',
                  readonly: false,
                  label: 'Accelerated Cover Sum Assured',
                  uid: '92221bff75ba717a',
                  content: '',
                  publishChange: true,
                  errors: ['Please choose an accelerated benefit option.'],
                  inputType: 'number',
                },
              ],
            },
            {
              id: 'policy-term-selection',
              type: 'list',
              readonly: false,
              label: 'Policy Term Selection',
              uid: '70e307d298a682fe',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '60dc71c6632c83db',
                  content: '<p>Please select a policy term.</p>\n',
                },
                {
                  id: 'term-in-years',
                  type: 'integer',
                  elementType: 'select',
                  readonly: false,
                  label: 'Term In Years',
                  uid: 'b0a7f277211089ec',
                  content: '',
                  publishChange: true,
                  options: [
                    '',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                    '22',
                    '23',
                    '24',
                    '25',
                    '26',
                    '27',
                    '28',
                    '29',
                    '30',
                    '31',
                    '32',
                    '33',
                    '34',
                    '35',
                    '36',
                    '37',
                    '38',
                    '39',
                    '40',
                    '41',
                    '42',
                    '43',
                    '44',
                    '45',
                    '46',
                    '47',
                    '48',
                    '49',
                    '50',
                    '51',
                    '52',
                    '53',
                    '54',
                    '55',
                    '56',
                    '57',
                    '58',
                    '59',
                    '60',
                    '61',
                    '62',
                  ],
                },
              ],
            },
            {
              id: 'premium-updates',
              type: 'list',
              readonly: false,
              label: 'Premium Updates',
              uid: 'aa80d7a85c7493e6',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '6af7f522d6cc4f9f',
                  content:
                    '<p>Do you want to add automatic annual payment and benefit increases? If so - please select the level of payment rate increase you want to apply</p>\n',
                },
                {
                  id: 'update-option',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Update Option',
                  uid: '618ad3a78085326e',
                  content: '',
                  publishChange: true,
                  options: ['', '0', '5', '10', '15', '20', '25', '30'],
                },
              ],
            },
            {
              id: 'main-life-funeral-cover',
              type: 'list',
              readonly: false,
              label: 'Main Life Funeral Cover',
              uid: '141778bbed4c0db4',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '1fe6cfb652272981',
                  content:
                    '<p>Do you want to include the main life under funeral cover?</p>\n',
                },
                {
                  id: 'funeral-cover-for-main-life',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Funeral Cover for Main Life',
                  uid: '6db48547844bea11',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'b9b725287aab6006',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '0c124aa6a0432212',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '969307f8261da257',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '64d2b90d19ed9873',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'fc544a215783cef6',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Policyholder Quick Quote',
    uri: 'fipp-enhanced/policyholder-quick-quote',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-policyholder-quick-quote',
  },
  'additional-benefits': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'benefits',
      type: 'list',
      readonly: false,
      label: 'Benefits',
      uid: '4f4c3bab83cf35c4',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f0a65bd313a06372',
          content: '<h2>Choose your additional benefits</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '369a3e7b4c3ae508',
          content:
            '<p>Please indicate which additional benefits you would like to add to your plan:</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'c0c5c3fbd28aebe3',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'hospitalisation-cover-benefit',
          type: 'list',
          readonly: false,
          label: 'Hospitalisation Cover Benefit',
          uid: '6118ea6d8b345332',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '8acca4a4f3e9a2ca',
              content:
                '<p>This benefit option provides the Policyholder with daily fixed benefits for when the policyholder is hospitilized for more than 3 days.</p>\n',
            },
            {
              id: 'system-list',
              type: 'list',
              readonly: false,
              label: 'System List',
              uid: '1f3dcdbd8870ab17',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '57685becd066876b',
                  content:
                    '<p>Please select if you want to hospitilization cover for main life</p>\n',
                },
                {
                  id: 'hospitilization-cover-main-life-only',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Hospitilization Cover: Main Life only',
                  uid: '929173c0c442cb14',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
            {
              id: 'system-list',
              type: 'list',
              readonly: false,
              label: 'System List',
              uid: '5e2b367a83f688f9',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'e0bbddbbd1afafaf',
                  content:
                    '<p>Please select if you want to hospitilization cover for main life or joint life.</p>\n',
                },
                {
                  id: 'hospitalisation-cover-options-radio-group',
                  type: 'list',
                  readonly: false,
                  label: 'Hospitalisation Cover Options Radio Group',
                  uid: '652c90b9d19fefcb',
                  content: [
                    {
                      id: 'no-option',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'No Option',
                      uid: '9177420dff953a3d',
                      content: 'Y',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'hospitilization-cover-main-life-only-1',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Hospitilization Cover: Main Life only',
                      uid: 'b526b79d72772b9c',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'hospitilization-cover-joint-life',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Hospitilization Cover: Joint Life',
                      uid: 'd07122e67c65b894',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                  ],
                },
              ],
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '49f48f5392289c11',
              content:
                '<p>Please select a option (unit) below based on the fixed daily benefit you require.</p>\n',
            },
            {
              id: 'hospitalisation-units',
              type: 'amount',
              elementType: 'select',
              readonly: false,
              label: 'Hospitalisation Units',
              uid: 'b20f6e03510f10cc',
              content: '',
              publishChange: true,
              options: ['', '50', '80', '100', '120', '150', '200'],
            },
          ],
        },
        {
          id: 'educational-support-benefit-premium',
          type: 'list',
          readonly: false,
          label: 'Educational Support Benefit',
          uid: 'df6cdec39ec03657',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '06ecd7aad8126d3f',
              content:
                '<p>This benefit is designed to support the educational needs of the Policyholder’s beneficiary (ies).</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'ff956affd3ae1461',
              content: '<p>The maximum benefit is GHS100000.00</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '0c4ede21c4c5f85a',
              content: '<p>The minimum term is 5 years.</p>\n',
            },
            {
              id: 'educational-support-benefit',
              type: 'amount',
              elementType: 'input',
              readonly: false,
              label: 'Educational Support Benefit',
              uid: 'f6d5df54dd11243c',
              content: '',
              publishChange: true,
              inputType: 'number',
            },
            {
              id: 'educational-support-term',
              type: 'amount',
              elementType: 'select',
              readonly: false,
              label: 'Educational Support Term',
              uid: '5fe74e216bff3189',
              content: '',
              publishChange: true,
              options: [
                '',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
              ],
            },
          ],
        },
        {
          id: 'retirement-benefit',
          type: 'list',
          readonly: false,
          label: 'Retirement Benefit',
          uid: '87207c4caa87c7e1',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'e902a3e337a27b44',
              content:
                '<p>This benefit provides the policyholder with the option to save for retirement.</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '60d4bbb314a07c5f',
              content:
                '<p>The minimum premium is GHS200.00 and the maximum premium is GHS20000.00</p>\n',
            },
            {
              id: 'retirement-premium',
              type: 'amount',
              elementType: 'input',
              readonly: false,
              label: 'Retirement Premium',
              uid: 'f7d782412da539dc',
              content: '',
              publishChange: true,
              inputType: 'number',
            },
          ],
        },
        {
          id: 'funeral-cover-options',
          type: 'list',
          readonly: false,
          label: 'Funeral Cover Options',
          uid: 'e54476f4baccb9e7',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '3f1e109c67d6c1d1',
              content: '<p>This benefit covers the Policyholder.</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '3b3eb2cc79a268ff',
              content:
                '<p>Please select a cover level based on the benefit you require.</p>\n',
            },
            {
              id: 'main-life-funeral-benefit',
              type: 'amount',
              elementType: 'select',
              readonly: false,
              label: 'Main Life Funeral Benefit',
              uid: 'ae874fdbc1256bf9',
              content: '',
              publishChange: true,
              options: [
                '',
                '2000',
                '5000',
                '7500',
                '10000',
                '15000',
                '20000',
                '25000',
                '30000',
                '40000',
                '50000',
              ],
            },
            {
              id: 'funeral-options',
              type: 'list',
              readonly: false,
              label: 'Funeral Options',
              uid: 'b2c255ebaf744310',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '73ef9352ce522190',
                  content:
                    '<p>Please select the term for your funeral policy:</p>\n',
                },
                {
                  id: 'policy-term-radio-group',
                  type: 'list',
                  readonly: false,
                  label: 'Policy Term Radio Group',
                  uid: '3e1b183066b0ea91',
                  content: [
                    {
                      id: 'term-ten',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Term Ten',
                      uid: 'c493d7308279b8f7',
                      content: 'Y',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'term-fifteen',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Term Fifteen',
                      uid: 'be57b9ab5489d6b1',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                  ],
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '663d4a50727aa502',
                  content:
                    '<p>Please indicate if you want any rider options:</p>\n',
                },
                {
                  id: 'rider-options',
                  type: 'list',
                  readonly: false,
                  label: 'Rider Options',
                  uid: '4c6fe7a2767a8924',
                  content: [
                    {
                      id: 'memorial-seven-day',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Memorial Seven Day',
                      uid: '7abb787e6c338f58',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'memorial-forty-day',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Memorial Forty Day',
                      uid: 'bf291c42bcd1a694',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'memorial-one-year',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Memorial One Year',
                      uid: 'b3b8bb4eefcbe728',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                  ],
                },
                {
                  id: 'accident-double',
                  type: 'list',
                  readonly: false,
                  label: 'Accident Double',
                  uid: '10b68f7098b85148',
                  content: [
                    {
                      id: 'system-constant',
                      type: 'html',
                      readonly: true,
                      label: '',
                      uid: '8626b7cec1bca2ce',
                      content:
                        '<p>Please indicate if you want the Double Accident Benefit</p>\n',
                    },
                    {
                      id: 'double-accident-benefit',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Double Accident Benefit',
                      uid: 'cfad0ce3e0ad1f88',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'cash-bonus',
          type: 'list',
          readonly: false,
          label: 'Cash Bonus',
          uid: 'f959d9707e6e4717',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'f13246cb72854960',
              content:
                '<p>Would you like to add a deposit to your cover?</p>\n',
            },
            {
              id: 'cash-bonus',
              type: 'amount',
              elementType: 'input',
              readonly: false,
              label: 'Cash Bonus',
              uid: '54abe2cb1603e556',
              content: '',
              publishChange: true,
              inputType: 'number',
            },
          ],
        },
        {
          id: 'asset-preservation-benefit',
          type: 'list',
          readonly: true,
          label: 'Asset Preservation Benefit',
          uid: 'd682cc8494bf6f25',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '56f7b1c3a43f0045',
              content:
                '<p>This benefit pays up to 50% of the value of any acquired asset in the event of death, disability or critical illness.</p>\n',
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '5d33e31b17ae6590',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'd457b6c70eace8ae',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '41522439696d3475',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '57a05681b8418fbc',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'c7c71551c4ee3fc8',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Policyholder Additional Benefits',
    uri: 'fipp-enhanced/policyholder-additional-benefits',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-policyholder-additional-benefits',
  },
  'premium-validate': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Done',
        id: 'done',
        uid: '4e5f7031a7643ff6',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '9df131e41b9ed52f',
      content: [
        ' The minimum premium is 100. Please select additional cover in order to meet this requirement.',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Policyholder Additional Benefits',
    uri: 'fipp-enhanced/policyholder-additional-benefits',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-policyholder-additional-benefits',
  },
  'quotation-family': {
    text: [],
    buttons: [
      {
        label: 'Add family',
        id: 'add-family',
        uid: '4944cc6ab7d9e98c',
        control: false,
      },
      {
        label: 'Remove family',
        id: 'remove-family',
        uid: 'a5ca5df087270493',
        control: false,
      },
      {
        label: 'Edit family',
        id: 'edit-family',
        uid: '0d437aba38a8517f',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'add-other-lives-assured',
      type: 'list',
      readonly: false,
      label: 'Add Other Lives Assured',
      uid: 'c39cb0b950948505',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'bbb43ab92f29ddae',
          content: '<h2>Add Other Lives</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '0ce5a88405dcce10',
          content:
            '<p>Please add other lives assured and select the relevant cover benefit.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '399e33a1e116c069',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'additional-lives',
          type: 'list',
          readonly: true,
          label: 'Additional Lives',
          uid: '2a344ffeff926abf',
          content: [
            {
              id: 'fipp-all-lives-added',
              type: 'table',
              readonly: true,
              label: 'Fipp All Lives Added',
              uid: '54cb952080a63835',
              content: [],
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '6eda4a43294678c8',
              content: "<p><a href='#add-family'>Add Member</a></p>\n",
            },
          ],
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: false,
          label: 'System List',
          uid: '037922f0f85648c6',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'b0e6643939bea636',
              content:
                "<p>Please select the term for your life assured's funeral policy:</p>\n",
            },
            {
              id: 'policy-term-radio-group',
              type: 'list',
              readonly: false,
              label: 'Policy Term Radio Group',
              uid: '9ea9e5bd826a7e59',
              content: [
                {
                  id: 'term-ten',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Term Ten',
                  uid: 'a1f88a13fd0cacde',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'term-fifteen',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Term Fifteen',
                  uid: '6e0bfaecc975220d',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'c84662307fd78648',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '641e72981eaca0ce',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '0f856ee5b53ffe73',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '35e55374874e3fd7',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'f968f4500fe13add',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Add Other Lives',
    uri: 'fipp-enhanced/other-lives-add',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-add-other-lives',
  },
  'add-family-members': {
    modal: true,
    text: [],
    buttons: [],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '178b7838c3fade6d',
      content: [
        ' Please specify your relationship to the life you want to add.',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'edit-family-members': {
    modal: true,
    text: [],
    buttons: [],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '4981e8376bfbfbd8',
      content: [
        'Please select the person whose details you would like to edit',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'remove-family-members': {
    modal: true,
    text: [],
    buttons: [
      {
        label: '',
        id: '',
        uid: 'bfb0a1ef332e5a00',
        control: false,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '4981e8376bfbfbd8',
      content: [
        'Please select the person you would like to remove from the policy',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'family-members-age': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'additional-family-details',
      type: 'list',
      readonly: false,
      label: 'Additional Family Details',
      uid: 'c9471c08061c5edc',
      content: [
        {
          id: 'age-of-member',
          type: 'integer',
          elementType: 'select',
          readonly: false,
          label: 'Age Of Member',
          uid: '31635e1db55cb34c',
          content: '',
          publishChange: true,
          errors: ['*'],
          tooltip: 'Age Of Member',
          options: [],
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '139d733913aff3bc',
          content: '<p>Please indicate the gender of the life assured</p>\n',
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Gender',
          tooltip: 'Gender',
          uid: 'defa6021f5cbe751',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: ['', 'Female', 'Male'],
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'd8117de156002692',
          content:
            '<p>Please select the benefit/s you would like to add for this life</p>\n',
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: false,
          label: 'System List',
          uid: '875622a22cd62519',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'f497b6b5cbdd6013',
              content: '<p>Add a Funeral Benefit</p>\n',
            },
            {
              id: 'funeral-cover',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Funeral Cover',
              uid: '69b4a4972c4a24e6',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
          ],
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: false,
          label: 'System List',
          uid: '67567a213fc88138',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '95cd8f20a98b7b54',
              content:
                '<p>Add this child to the free child benefit (max 4 children)</p>\n',
            },
            {
              id: 'free-child-cover',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Free Child Cover',
              uid: '2adf6501cb6fed78',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
          ],
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: false,
          label: 'System List',
          uid: '39bcc649474ed99b',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '9989d74d644f5ea3',
              content: '<p>Add Old Age cover for your parent</p>\n',
            },
            {
              id: 'old-age-cover',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Old Age Cover',
              uid: 'd4cd1121eb86c829',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'family-members-quotation-details': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'additional-family-benefit',
      type: 'list',
      readonly: false,
      label: 'Additional Family Benefit',
      uid: '95201c0b35ca88a6',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '8aaf58426fa6c7cb',
          content: '<p>Please select an option for the benefits chosen</p>\n',
        },
        {
          id: 'funeral-benefit',
          type: 'list',
          readonly: false,
          label: 'Funeral Benefit',
          uid: 'c490a72cc729bc05',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '1293ef344e58a161',
              content: '<p>Please select a cover option.</p>\n',
            },
            {
              id: 'funeral-cover-benefit',
              type: 'amount',
              elementType: 'select',
              readonly: false,
              label: 'Funeral Cover Benefit',
              uid: 'c15fd5b7e4c7fc70',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Cover Benefit',
              options: [
                '',
                '2000',
                '5000',
                '7500',
                '10000',
                '15000',
                '20000',
                '25000',
                '30000',
                '40000',
                '50000',
              ],
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '591e3df5c45181a8',
              content: '<p>Please add on any rider options</p>\n',
            },
            {
              id: 'memorial-cover-options',
              type: 'list',
              readonly: false,
              label: 'Memorial Cover Options',
              uid: '69106347115c5483',
              content: [
                {
                  id: 'memorial-seven-day',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Memorial Seven Day',
                  uid: '836908528a1e34d1',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'memorial-forty-day',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Memorial Forty Day',
                  uid: 'df203b59b5867578',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'memorial-one-year',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Memorial One Year',
                  uid: 'cb6537d90ea2e583',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
          ],
        },
        {
          id: 'old-age-cover',
          type: 'list',
          readonly: false,
          label: 'Old Age Cover',
          uid: '7f8682b5a25cf9df',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '4c90c5c70efec798',
              content: '<p>Please select a cover option</p>\n',
            },
            {
              id: 'old-age-cover-benefit',
              type: 'real',
              elementType: 'input',
              readonly: false,
              label: 'Old Age Cover Benefit',
              uid: 'd19ef867bdfa7ff9',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Cover Benefit',
              inputType: 'number',
            },
          ],
        },
        {
          id: 'free-child-benefit',
          type: 'list',
          readonly: true,
          label: 'Free Child Benefit',
          uid: 'a471bccb36711080',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'b5e0c683188607b4',
              content:
                '<p>This is a free death cover for children of the policyholder. It is applicable to four (4) children and ceases when the child turns age 21.</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '7812fac56748925a',
              content:
                '<p>The benefit is a standard lump sum amount of GH¢5,000</p>\n',
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'life-assured-identification': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'personal-details',
      type: 'list',
      readonly: false,
      label: 'Personal Details',
      uid: '434f07f614c233a1',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'c9d124707f9d1b14',
          content: '<h2>Personal Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a8902e1cd0cee07e',
          content:
            '<p>For us to complete the purchase, we require the following information</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '3dfdc74464a34aa8',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'your-details',
          type: 'list',
          readonly: false,
          label: 'Your Details',
          uid: 'c73719d70c2d68e6',
          content: [
            {
              id: 'first-names',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'First Names',
              uid: '6b7ca958d9ea364b',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'surname',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Surname',
              uid: '464b64f4d28fd6b9',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'idpassport-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'ID/Passport Number',
              uid: '98e2671fcd68feeb',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'id-type-radio-group',
              type: 'list',
              readonly: false,
              label: 'ID Type Radio Group',
              uid: '55144de6e5b38f62',
              content: [
                {
                  id: 'dvla',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Dvla',
                  uid: 'bc235e8781bbe982',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'passport',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Passport',
                  uid: 'e59e68b8ac36a00f',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'voter-id',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Voter ID',
                  uid: '9215dbff34ced1f3',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'nhis',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Nhis',
                  uid: 'c0bebf727748350e',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'ssnit',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Ssnit',
                  uid: 'f79d0cafc5c371cd',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'nia-ghana-card',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Nia Ghana Card',
                  uid: '44966d1518c74a13',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
              ],
              errors: ['*'],
            },
            {
              id: 'title',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Title',
              uid: 'df961919bc248ac4',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Title',
              options: [
                '',
                'Adv',
                'Dr',
                'Miss',
                'Mr',
                'Mrs',
                'Ms',
                'Prof',
                'Rev',
              ],
            },
            {
              id: 'gender',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Gender',
              uid: 'fb0f9fc57b0b4539',
              content: 'Female',
              tooltip: 'Gender',
              inputType: 'text',
            },
            {
              id: 'date-of-birth',
              type: 'integer',
              elementType: 'input',
              readonly: true,
              label: 'Date Of Birth',
              uid: 'af59285b1b5638ec',
              content: '',
              inputType: 'number',
            },
            {
              id: 'marital-status',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Marital Status',
              uid: 'f3f9e7ab5261cdc5',
              content: '',
              tooltip: 'Marital Status',
              inputType: 'text',
            },
            {
              id: 'ghanaian',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Ghanaian',
              uid: 'baf950dbec76f0ef',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
            {
              id: 'other-nationality',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Other Nationality',
              uid: '3e4c1392a502afef',
              content: '',
              publishChange: true,
              errors: [
                'If you are not a ghanaian please select your nationality',
              ],
              tooltip: 'Other Nationality',
              options: [
                '',
                'Afghan',
                'Albanian',
                'Algerian',
                'American',
                'Andorran',
                'Angolan',
                'Argentine',
                'Armenian',
                'Aromanian',
                'Aruban',
                'Australian',
                'Austrian',
                'Azerbaijani',
                'Bahamian',
                'Bahraini',
                'Baltic German',
                'Baltic Russian',
                'Bangladeshi',
                'Barbadian',
                'Belarusian',
                'Belgian',
                'Belizean',
                'Bermudian',
                'Boer',
                'Bosniak',
                'Brazilian',
                'Breton',
                'British',
                'British Virgin Islander',
                'Bruneian',
                'Bulgarian',
                'Burkinabè',
                'Burundian',
                'Cambodian',
                'Cameroonian',
                'Canadian',
                'Cape Verdean',
                'Catalan',
                'Chadian',
                'Chilean',
                'Chinese',
                'Colombian',
                'Comorian',
                'Congolese',
                'Croatian',
                'Cuban',
                'Cypriot',
                'Czech',
                'Dane',
                'Dominican (Commonwealth)',
                'Dominican (Republic)',
                'Dutch',
                'East Timorese',
                'Ecuadorian',
                'Egyptian',
                'Emirati',
                'English',
                'Eritrean',
                'Estonian',
                'Ethiopian',
                'Falkland Islander',
                'Faroese',
                'Fijian',
                'Filipino',
                'Finn',
                'Finnish Swedish',
                'French Citizen',
                'Georgian',
                'German',
                'Ghanaian',
                'Gibraltar',
                'Greek',
                'Greek Macedonian',
                'Grenadian',
                'Guatemalan',
                'Guianese (French)',
                'Guinea Bissau National',
                'Guinean',
                'Guyanese',
                'Haitian',
                'Honduran',
                'Hong Kong',
                'Hungarian',
                'I Kiribati',
                'Icelander',
                'Indian',
                'Indonesian',
                'Iranian',
                'Iraqi',
                'Irish',
                'Israeli',
                'Italian',
                'Ivoirian',
                'Jamaican',
                'Japanese',
                'Jordanian',
                'Kazakh',
                'Kenyan',
                'Korean',
                'Kosovar',
                'Kurd',
                'Kuwaiti',
                'Kyrgyz',
                'Lao',
                'Latvian',
                'Lebanese',
                'Liberian',
                'Libyan',
                'Liechtensteiner',
                'Lithuanian',
                'Luxembourger',
                'Macedonian',
                'Macedonian Bulgarian',
                'Malagasy',
                'Malawian',
                'Malaysian',
                'Maldivian',
                'Malian',
                'Maltese',
                'Manx',
                'Mauritian',
                'Mexican',
                'Moldovan',
                'Mongolian',
                'Montenegrin',
                'Moroccan',
                'Namibian',
                'Nepalese',
                'New Zealander',
                'Nicaraguan',
                'Nigerian',
                'Nigerien',
                'Norwegian',
                'Other',
                'Pakistani',
                'Palauan',
                'Palestinian',
                'Panamanian',
                'Papua New Guinean',
                'Paraguayan',
                'Peruvian',
                'Pole',
                'Portuguese',
                'Puerto Rican',
                'Qatari',
                'Romanian',
                'Russian',
                'Rwandan',
                'Salvadoran',
                'Saudi',
                'Scot',
                'Senegalese',
                'Serb',
                'Sierra Leonean',
                'Sindhian',
                'Singaporean',
                'Slovak',
                'Slovene',
                'Somali',
                'Somalilander',
                'South African',
                'Spaniard',
                'Sri Lankan',
                'St Lucian',
                'Sudanese',
                'Surinamese',
                'Swede',
                'Swiss',
                'Syriac',
                'Syrian',
                'São Tomé And Príncipe',
                'Taiwanese',
                'Tajik',
                'Tanzanian',
                'Thai',
                'Tibetan',
                'Tobagonian',
                'Trinidadian',
                'Tunisian',
                'Turk',
                'Turkish Cypriot',
                'Tuvaluan',
                'Ugandan',
                'Ukrainian',
                'United States',
                'Uruguayan',
                'Uzbek',
                'Vanuatuan',
                'Venezuelan',
                'Vietnamese',
                'Welsh',
                'Yemeni',
                'Zambian',
                'Zimbabwean',
              ],
            },
          ],
        },
        {
          id: 'your-contact-details',
          type: 'list',
          readonly: false,
          label: 'Your Contact Details',
          uid: '0e1e67869d216021',
          content: [
            {
              id: 'cell-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Cell Phone',
              uid: '43bb2ffb6f6594b6',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'email',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Email',
              uid: '1c7c3b04cdd13345',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'email',
            },
          ],
        },
        {
          id: 'your-postal-address',
          type: 'list',
          readonly: false,
          label: 'Your Postal Address',
          uid: 'adb19fbed735bc93',
          content: [
            {
              id: 'life-assured-postal-address',
              type: 'list',
              readonly: false,
              label: 'Life Assured Postal Address',
              uid: '0c0609fbddb0aeae',
              content: [
                {
                  id: 'addressee',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Addressee',
                  uid: 'e301c545cb714ab7',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'town',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Town',
                  uid: 'be7d16c77daccb04',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Abamasarefan',
                    'Abandze',
                    'Abaoba',
                    'Abasa',
                    'Abasa Ajumaku',
                    'Abasi',
                    'Abdulaikrom',
                    'Abease',
                    'Abenasi',
                    'Abene',
                    'Abesem',
                    'Ablornu',
                    'Aboabo No 2',
                    'Abodom',
                    'Abofoo',
                    'Aboma',
                    'Abomoso',
                    'Abompe',
                    'Aboo',
                    'Abooso',
                    'Abora',
                    'Abora Oboasi',
                    'Abore',
                    'Abra',
                    'Abrekum',
                    'Abuakwa',
                    'Abuom',
                    'Abura Dunkwa',
                    'Aburi',
                    'Abutia - Kloe',
                    'Accra',
                    'Achawa Pt.',
                    'Achiase',
                    'Achimota',
                    'Ada',
                    'Adabokurom',
                    'Adaiso',
                    'Adansi',
                    'Adawsu',
                    'Adidakpavui',
                    'Adidome',
                    'Adidwan',
                    'Adina',
                    'Adjena',
                    'Adobo',
                    'Adonso',
                    'Aduaben',
                    'Aduamoa',
                    'Adubea',
                    'Adubiliyili',
                    'Adukrom',
                    'Adumadum',
                    'Adwamadiem',
                    'Adwufia',
                    'Adwum',
                    'Adzakoe',
                    'Afegame',
                    'Afiaso',
                    'Afienya',
                    'Aflao',
                    'Afosu',
                    'Aframso',
                    'Afratwo',
                    'Agogo',
                    'Agona',
                    'Agona Nkwanta',
                    'Aguna',
                    'Agyana',
                    'Ahamasu',
                    'Ahebensu',
                    'Ahemakurom',
                    'Ahenkro',
                    'Ahobre',
                    'Ahunda',
                    'Ahwerewa',
                    'Ahwiren',
                    'Aiyiribi',
                    'Ajemra',
                    'Ajumako',
                    'Ajumaku',
                    'Akantansu',
                    'Akanu',
                    'Akatsi',
                    'Akim Oda',
                    'Akim Swedru',
                    'Akim Tafo',
                    'Akoasi',
                    'Akontaanim',
                    'Akontanim',
                    'Akoroso',
                    'Akosombo',
                    'Akosombo Juapon',
                    'Akpabanya',
                    'Akra',
                    'Akrofuom',
                    'Akrokerri',
                    'Akropon',
                    'Akropong',
                    'Akumadan',
                    'Akuntuase',
                    'Akuse',
                    'Akwantonbra',
                    'Akwidaa',
                    'Akyekyere',
                    'Akyemfo Gyomuro',
                    'Alenda',
                    'Amamsi',
                    'Amankwakrom',
                    'Amasaman',
                    'Amasu',
                    'Ambo',
                    'Amedzofe',
                    'Amenfi',
                    'Amlakpo',
                    'Amoama',
                    'Amoma',
                    'Amoya',
                    'Ampeda',
                    'Ampeyoo',
                    'Anfoaga',
                    'Anibil',
                    'Ankaasi',
                    'Ankaful',
                    'Ankan',
                    'Anloga',
                    'Anomabo',
                    'Antabia',
                    'Anum',
                    'Anyaboni',
                    'Anyako',
                    'Anyanui',
                    'Anyinam',
                    'Anyinasu',
                    'Anyirawase',
                    'Aobo Aduamoa',
                    'Apam',
                    'Apapasu',
                    'Apataim',
                    'Aperade',
                    'Apesokubi',
                    'Apinso',
                    'Apowa',
                    'Asaafa',
                    'Asaam',
                    'Asaaman',
                    'Asafo',
                    'Asakraka',
                    'Asamankese',
                    'Asankrangua',
                    'Asantekrom',
                    'Asasitre',
                    'Asatowe',
                    'Asawinso',
                    'Asebu',
                    'Asekore',
                    'Asesewa',
                    'Ashanti',
                    'Asiakwa',
                    'Asiekpe',
                    'Asikuma',
                    'Assin Breku',
                    'Assin Manso',
                    'Assin Nyankumase',
                    'Assin Praso',
                    'Asuboa',
                    'Asuderi',
                    'Asukawkaw',
                    'Asumenya',
                    'Asunafo',
                    'Asuom',
                    'Asuotiano',
                    'Asutsuare',
                    'Ataase',
                    'Atebubu',
                    'Atesa',
                    'Atiavi',
                    'Atieku',
                    'Atimpoku',
                    'Atobase',
                    'Atuabo',
                    'Atuna',
                    'Ave-afiadenyiga',
                    'Ave-dakpa',
                    'Avenorpeme',
                    'Aveyime',
                    'Awatia',
                    'Awisa',
                    'Awisan',
                    'Awonakrom',
                    'Awurasa',
                    'Awutu',
                    'Axim',
                    'Ayanfure',
                    'Ayerede',
                    'Ayiase',
                    'Ayiem',
                    'Ayikuma',
                    'Ayinwafe',
                    'Ayomfo',
                    'Baabiaraneha',
                    'Baaglo',
                    'Baantama',
                    'Babile',
                    'Babopi',
                    'Bagape',
                    'Bagbiapi',
                    'Bagmare',
                    'Bagurugu',
                    'Bagwiwu',
                    'Baika',
                    'Bakanta',
                    'Bakau',
                    'Bakoniaba',
                    'Bale',
                    'Bamboi',
                    'Banaso',
                    'Banda',
                    'Banda - Nkwanta',
                    'Bandae',
                    'Bandiyili',
                    'Bangwan',
                    'Banko',
                    'Bansang',
                    'Banso',
                    'Banu',
                    'Barae No 2',
                    'Barra',
                    'Basa',
                    'Basie',
                    'Basse',
                    'Battor',
                    'Batume Junction',
                    'Bau',
                    'Bawdie',
                    'Bawiesibal',
                    'Bawjiase',
                    'Bawka',
                    'Bawku',
                    'Bechem',
                    'Bediakokurom',
                    'Begoro',
                    'Beku',
                    'Bekwai',
                    'Bende',
                    'Benso',
                    'Beposo',
                    'Berekum',
                    'Berekuso',
                    'Besease',
                    'Beyin',
                    'Biadam',
                    'Bibiani',
                    'Bielikpang',
                    'Billaw',
                    'Bimbago',
                    'Bimbagokpasenkpe',
                    'Bimbagu',
                    'Bimbila',
                    'Binaba',
                    'Binduri',
                    'Bisa',
                    'Biung',
                    'Boanim',
                    'Bodi',
                    'Bodwesango',
                    'Bofaso',
                    'Bogdoo',
                    'Bogorono',
                    'Bogorop',
                    'Bogoso',
                    'Bole',
                    'Bolgatanga',
                    'Bombori',
                    'Bomburi Fimbo',
                    'Bomfa',
                    'Bonakye',
                    'Bongo',
                    'Bonkrom',
                    'Bonohoma',
                    'Bonyere',
                    'Booyem',
                    'Bopa',
                    'Boro',
                    'Bortianor',
                    'Bososo',
                    'Bowiri',
                    'Brakwa',
                    'Branam',
                    'Brenase',
                    'Bresuanu',
                    'Brewaniase',
                    'Brikama',
                    'Brikamaba',
                    'Brofuyedru',
                    'Brufut',
                    'Brugbani',
                    'Buachipe',
                    'Buaka',
                    'Bugri',
                    'Bugu',
                    'Buipe',
                    'Bukanor',
                    'Bulangsa',
                    'Bulangsa Torsa',
                    'Bulenga',
                    'Bungeli',
                    'Bunkpurugu',
                    'Burai',
                    'Busa',
                    'Busie',
                    'Busunu',
                    'Butie',
                    'Buya',
                    'Cape Coast',
                    'Cape St. Paul',
                    'Cape Three Pts.',
                    'Carnpaga Sakogu',
                    'Chache',
                    'Chaisa',
                    'Chama',
                    'Chamba',
                    'Changbuni',
                    'Chanyili',
                    'Chase',
                    'Chatali',
                    'Chemfe',
                    'Chepuri',
                    'Cherepon',
                    'Cheropon',
                    'Chia',
                    'Chiana-asuma',
                    'Chichari',
                    'Christiansborg',
                    'Chuchiliga',
                    'Dabala',
                    'Daboase',
                    'Dabori',
                    'Dabova',
                    'Daboya',
                    'Dadeeso',
                    'Dado',
                    'Dadwen',
                    'Dafiama',
                    'Dafo',
                    'Dafor',
                    'Dagonkadi',
                    'Dakpam',
                    'Damanko',
                    'Dambai',
                    'Damongo',
                    'Danko',
                    'Dapa',
                    'Dasanyi',
                    'Datako-kurbor',
                    'Datano',
                    'Dawa',
                    'Dawadawa',
                    'Dawhenya',
                    'Debiribi',
                    'Debiso',
                    'Dedeso Wireko',
                    'Degbila',
                    'Dego',
                    'Demoh',
                    'Dena',
                    'Denkyira',
                    'Densubon',
                    'Denu',
                    'Diabakurom',
                    'Diari',
                    'Diasempa',
                    'Diaso',
                    'Digma',
                    'Digmamandari',
                    'Disiga',
                    'Dixcove',
                    'Dodowa',
                    'Dogokrom',
                    'Domeabra',
                    'Domenase',
                    'Dominase',
                    'Dompem',
                    'Dompoase',
                    'Doninga',
                    'Donkponkwanta',
                    'Doriman',
                    'Dormaa-ahenk',
                    'Doryum',
                    'Drakofe',
                    'Drobo',
                    'Dromakese',
                    'Duaburori',
                    'Duadaso',
                    'Duie',
                    'Dukusen',
                    'Dumienu',
                    'Dunkwa',
                    'Dunkwa On Offin',
                    'Duu',
                    'Dwija',
                    'Dwokwa',
                    'Dyayaw-nkwanta',
                    'Dzana',
                    'Dzita',
                    'Dzodze',
                    'Effiduase',
                    'Eikwe',
                    'Ejian',
                    'Ejisu',
                    'Ejura',
                    'Ekumdipe',
                    'Ekuropon',
                    'Ekyiamenfurom',
                    'Elmina',
                    'Elubo',
                    'Enchi',
                    'Enyan',
                    'Enyanmansu',
                    'Enyinabrim',
                    'Esaaman',
                    'Esalikawu',
                    'Essem',
                    'Essiama',
                    'Esuakrom',
                    'Fajara',
                    'Fanti',
                    'Farafenni',
                    'Farifari',
                    'Fawamam',
                    'Fenyi Yokoe',
                    'Feo-nabisi',
                    'Fete',
                    'Fetenta',
                    'Fian',
                    'Fianko',
                    'Fimbo',
                    'Fomena',
                    'Foso',
                    'Frankadua',
                    'Fuchmbluma',
                    'Fufulsu',
                    'Fufuo',
                    'Fumbisi',
                    'Fumsi',
                    'Fumso',
                    'Fuu',
                    'Ga',
                    'Gaa',
                    'Gambia No 1',
                    'Gambia No 2',
                    'Gbal',
                    'Gbalga',
                    'Gbangdaa',
                    'Gbantala',
                    'Gbari',
                    'Gbasinkpa',
                    'Gbateto',
                    'Gbedema',
                    'Gbeini',
                    'Gbemfo',
                    'Gbeni',
                    'Gbimsi',
                    'Gbogu',
                    'Gbulumpe',
                    'Gbung',
                    'Gida',
                    'Gitase',
                    'Gnani',
                    'Goantala',
                    'Goaso',
                    'Goka',
                    'Gokoron',
                    'Golokuati',
                    'Gonnokurom',
                    'Gorgu',
                    'Gpapga',
                    'Grube',
                    'Grupe',
                    'Gulibikomonpa',
                    'Guri',
                    'Guru-natinga',
                    'Gushie',
                    'Gwallo',
                    'Gwellukupulima',
                    'Gwo',
                    'Gwosi',
                    'Gyapa',
                    'Gyedua',
                    'Gyekiti',
                    'Gyema',
                    'Gyenegyene',
                    'Half Assini',
                    'Hamale',
                    'Harglakarpe',
                    'Have Etoe',
                    'Helu',
                    'Heluivi',
                    'Heman',
                    'Hevi',
                    'Hian',
                    'Hiawoanwu',
                    'Ho',
                    'Hohoe',
                    'Honuta',
                    'Huhunya',
                    'Hunt Valley',
                    'Huntodo',
                    'Hwidiem',
                    'Imbraim',
                    'Issa',
                    'Jaba',
                    'Jadima',
                    'Jafor',
                    'Jakai',
                    'Jakobo',
                    'Jamasi',
                    'Jamra',
                    'Jandra',
                    'Jang',
                    'Janjangbureh',
                    'Jasikan',
                    'Jato',
                    'Jawani',
                    'Jeffisi',
                    'Jegbuni',
                    'Jeinsusa',
                    'Jema',
                    'Jemile',
                    'Jerigu',
                    'Jeshwang',
                    'Jewi Wharf',
                    'Jeyiri',
                    'Jimdabuo',
                    'Jinfranukasuridabarshe',
                    'Jinpasi',
                    'Jirapa',
                    'Jogboi',
                    'Jombo',
                    'Juaben',
                    'Juabeso',
                    'Juana',
                    'Juapon',
                    'Juaso',
                    'Jumo',
                    'Junle',
                    'Juo',
                    'Kaase',
                    'Kabaka',
                    'Kablima',
                    'Kabonwele',
                    'Kade',
                    'Kadema',
                    'Kadjebi',
                    'Kafaba',
                    'Kafodzidzi',
                    'Kafuta',
                    'Kakawere',
                    'Kakorasua',
                    'Kakum',
                    'Kalagie',
                    'Kalba',
                    'Kaleo',
                    'Kalini',
                    'Kamsorio',
                    'Kananta',
                    'Kandige',
                    'Kanga',
                    'Kanifing',
                    'Kanilie',
                    'Kanjaga',
                    'Kanseri',
                    'Kantani',
                    'Kanyaga',
                    'Kapro',
                    'Kapua',
                    'Karaga',
                    'Karamenga',
                    'Karni',
                    'Kasa',
                    'Kasapen',
                    'Kasoa',
                    'Kasuridabarshe',
                    'Kataijeli',
                    'Katanga',
                    'Katanka',
                    'Katiu',
                    'Kawga',
                    'Kechebi',
                    'Kedzi',
                    'Kensere',
                    'Kenyasi No 1',
                    'Keta',
                    'Kete Krache',
                    'Kibi',
                    'Kikam',
                    'Kimabui',
                    'Kintampo',
                    'Kissi',
                    'Klavw',
                    'Klikor - Agbozume',
                    'Kobresu',
                    'Kodee',
                    'Kofi Djan',
                    'Kofiase',
                    'Koforidua',
                    'Kogota',
                    'Kogyei',
                    'Kokoben',
                    'Kokofu',
                    'Kokoso',
                    'Kokuma',
                    'Kologo',
                    'Kololi',
                    'Komampo',
                    'Komenda',
                    'Komonpa',
                    'Konfogsi',
                    'Kong',
                    'Kongo',
                    'Konkori',
                    'Kononga',
                    'Kopota',
                    'Kotoko',
                    'Kotu',
                    'Kotua',
                    'Koue',
                    'Kpadui',
                    'Kpagto',
                    'Kpalbe',
                    'Kpalbus',
                    'Kpalguni',
                    'Kpalisogu',
                    'Kpandae',
                    'Kpandu',
                    'Kpanianba',
                    'Kparekpare',
                    'Kparigu',
                    'Kpasa',
                    'Kpasenk',
                    'Kpatinga',
                    'Kpedze',
                    'Kpedzeglo',
                    'Kpembe',
                    'Kpetchu',
                    'Kpetoe',
                    'Kpeve',
                    'Kpoglo',
                    'Kpone',
                    'Kpong',
                    'Kpotsu',
                    'Kptokuomtoas',
                    'Kraboa Coaltar',
                    'Krakurom',
                    'Kroba',
                    'Krobo',
                    'Krokosue',
                    'Krokrobite',
                    'Ksauridabarshe',
                    'Kubari',
                    'Kudani',
                    'Kugnani',
                    'Kugnanidaboya',
                    'Kugri Natinga',
                    'Kuikpanga',
                    'Kujopere',
                    'Kukabila',
                    'Kukuo',
                    'Kukuom',
                    'Kukurantumi',
                    'Kulkpanga',
                    'Kulmae',
                    'Kulmasa',
                    'Kulun',
                    'Kulungugu',
                    'Kulupene',
                    'Kumasi',
                    'Kumawu',
                    'Kumbungu',
                    'Kumfa',
                    'Kunchoge Basirisan',
                    'Kundang',
                    'Kunkwa',
                    'Kunso',
                    'Kuntanse',
                    'Kuntunso',
                    'Kupullma',
                    'Kusawgu',
                    'Kusebi',
                    'Kusinaba',
                    'Kute',
                    'Kutukrom',
                    'Kwaben',
                    'Kwabenadapaa',
                    'Kwadwokurom',
                    'Kwadwonkromkurom',
                    'Kwahu Tafo',
                    'Kwaihunu',
                    'Kwakwanya',
                    'Kwaman',
                    'Kwame Danso',
                    'Kwame Kwasi',
                    'Kwamedwamenakurom',
                    'Kwameseikurom',
                    'Kwanyaku',
                    'Kwawu',
                    'Kwesiadai',
                    'Kyekyewere',
                    'Kyeraa',
                    'Kyeremasu',
                    'Kyinderi',
                    'Labadi',
                    'Labo',
                    'Lambusie',
                    'Lamin',
                    'Lampel',
                    'Lampurgo',
                    'Langantre',
                    'Langasi',
                    'Langbinsi',
                    'Lanja',
                    'Larabanga',
                    'Larekaga',
                    'Larteh',
                    'Laura',
                    'Lawra',
                    'Lepusi',
                    'Lilikse Nankpawie',
                    'Lobi',
                    'Loggo',
                    'Lolonya',
                    'Longoro',
                    'Lovi',
                    'Luari',
                    'Lungbunga',
                    'Lungni',
                    'Lziri',
                    'Maasu',
                    'Maknkuma',
                    'Makongo',
                    'Malume',
                    'Mamfe',
                    'Mampaten',
                    'Mampon',
                    'Mampong',
                    'Mampoten',
                    'Mandari',
                    'Mankarigy',
                    'Mankessim',
                    'Mankpan',
                    'Mankranso',
                    'Mankuma',
                    'Mankwadze',
                    'Mansing',
                    'Manso',
                    'Manso Nkwanta',
                    'Mantukwa',
                    'Masaka',
                    'Matse',
                    'Mawuabammu',
                    'Mengwe',
                    'Mengyi',
                    'Menusu',
                    'Meriche',
                    'Mim',
                    'Mirigu-nabaanga',
                    'Mishue',
                    'Moglo',
                    'Mogono',
                    'Mojina',
                    'Moree',
                    'Moseaso',
                    'Motigy',
                    'Mpana',
                    'Mpasaso',
                    'Mpasem',
                    'Mpataba',
                    'Mpatoam',
                    'Mpeam',
                    'Mpohor',
                    'Mpraeso',
                    'Mrigu-nabara',
                    'Mumford',
                    'Muna',
                    'Mungu',
                    'Murugu',
                    'Nabari',
                    'Nabisi',
                    'Nabogo',
                    'Nabolo',
                    'Nabori',
                    'Nadiyili',
                    'Nafkoliga',
                    'Naga',
                    'Naga Pwalugu',
                    'Nagbo',
                    'Nakong-atinia',
                    'Nakpali',
                    'Nakpayili',
                    'Nakundugu',
                    'Nakwa',
                    'Nakwabi',
                    'Nalerigu',
                    'Namanso',
                    'Namasua',
                    'Nambiri',
                    'Namvili',
                    'Nandawli',
                    'Nandom',
                    'Nangodi',
                    'Nangruma',
                    'Nankanduri',
                    'Nanton',
                    'Naro',
                    'Nasia',
                    'Natinga',
                    'Natog',
                    'Nauli',
                    'Navrongo',
                    'Nawuni',
                    'Nawuniyipala',
                    'Nereso',
                    'New Abirem',
                    'New Debiso',
                    'New Edubiase',
                    'New Longoro',
                    'New Ningo',
                    'New Town',
                    'New Worabon',
                    'Nframa',
                    'Nkasiem',
                    'Nkawie',
                    'Nkawkaw',
                    'Nkenkasu',
                    'Nketepa',
                    'Nkoranza',
                    'Nkroful',
                    'Nkuntunso',
                    'Nkwaben',
                    'Nkwanta',
                    'Nobekaw',
                    'Nogokpo',
                    'Npuasu',
                    'Nsaba',
                    'Nsawam',
                    'Nsawkaw',
                    'Nsinsin',
                    'Nsuaem',
                    'Nsuatre',
                    'Nsuta',
                    'Nteresa',
                    'Ntereso',
                    'Ntronan',
                    'Nungua',
                    'Nwereme',
                    'Nyakrom',
                    'Nyame Bekyere',
                    'Nyamease',
                    'Nyameyeadom',
                    'Nyang',
                    'Nyankpala',
                    'Nyankumase',
                    'Nyanyaano',
                    'Nyensungi',
                    'Nyinahin',
                    'Nyive',
                    'Nzema',
                    'Obawale',
                    'Obo',
                    'Obogu',
                    'Obomeng',
                    'Obuase',
                    'Obuasi',
                    'Odahu',
                    'Odami',
                    'Odonkawkrom',
                    'Odumase',
                    'Odumasi',
                    'Odumasua',
                    'Oduponkpebe',
                    'Ofaose',
                    'Ofe',
                    'Offinso',
                    'Ofoase',
                    'Oforikurom',
                    'Ohiamankyene Kpetchu',
                    'Ohyiayeanisa',
                    'Ojobi',
                    'Okomfookurom',
                    'Old Edabo',
                    'Old Ningo',
                    'Oseekodwokurom',
                    'Osenase',
                    'Osino',
                    'Osudoku',
                    'Otekpolu',
                    'Otrokpe',
                    'Owusukuro',
                    'Pabire',
                    'Paga',
                    'Palangasi',
                    'Palari',
                    'Pampawie',
                    'Pankron',
                    'Panyane',
                    'Papase',
                    'Patriensa',
                    'Pawa',
                    'Peblastre',
                    'Pechi',
                    'Peki',
                    'Pelungo',
                    'Pemenasi',
                    'Perisi',
                    'Pigu',
                    'Pina',
                    'Piong',
                    'Pisie',
                    'Pisigu',
                    'Poano',
                    'Poasi',
                    'Pokoasi',
                    'Polmakom',
                    'Pomadze',
                    'Pong-tamale',
                    'Pramkese',
                    'Prampram',
                    'Prang',
                    'Prestea',
                    'Primase',
                    "Prince's Town",
                    'Pulo',
                    'Pumpuni',
                    'Pusiga',
                    'Pusuga',
                    'Pute',
                    'Pwalugu',
                    'Saani',
                    'Sabari',
                    'Saboba',
                    'Sabonjida',
                    'Sabuli',
                    'Sabusa',
                    'Sabzundi',
                    'Sagadugu',
                    'Sakai',
                    'Sakala',
                    'Sakode - Etae',
                    'Sakomono',
                    'Sakpa',
                    'Salaga',
                    'Salkpang',
                    'Saltpond',
                    'Samambow',
                    'Samanhyia',
                    'Sambo',
                    'Sambreboe',
                    'Sambrun',
                    'Sambu',
                    'Samila',
                    'Samoa',
                    'Sampa',
                    'Sampemo',
                    'Sampraso',
                    'Sandema',
                    'Sang',
                    'Sangbaa',
                    'Sankalaa',
                    'Sankore',
                    'Sankpala',
                    'Sanujan',
                    'Sanyang',
                    'Sapiegu',
                    'Saranouse',
                    'Saripere',
                    'Sarpere Ambo',
                    'Sarri',
                    'Saru',
                    'Savelugu',
                    'Sawla',
                    'Seekwa',
                    'Sefwi Anhwiaso',
                    'Sefwi Bekwai',
                    'Sefwi Wiawso',
                    'Segbasi',
                    'Sekasua',
                    'Seketia',
                    'Sekondi Takoradi',
                    'Sekpe',
                    'Sekyedumase',
                    'Sekyerekurom',
                    'Sema',
                    'Senase',
                    'Senchi',
                    'Seneso',
                    'Senfi',
                    'Senkyiem',
                    'Senya Bereku',
                    'Seripe',
                    'Serrekunda',
                    'Sese',
                    'Seseamam',
                    'Shama',
                    'Shi',
                    'Shia',
                    'Shiega',
                    'Shieni',
                    'Silibele',
                    'Sinebaga',
                    'Singa',
                    'Siriminchu',
                    'Sogakope',
                    'Soma(mansakonko)',
                    'Somanya',
                    'Sombisi',
                    'Soo',
                    'Sovie',
                    'Srogbae',
                    'Subri',
                    'Suggo',
                    'Suhum',
                    'Sukuta',
                    'Sumiso',
                    'Sumsei',
                    'Sung',
                    'Sunsan',
                    'Sunyani',
                    'Surukrom',
                    'Swedru',
                    'Taali',
                    'Tachiman',
                    'Tachimantia',
                    'Tadieso',
                    'Tadzevu',
                    'Taenso',
                    'Tahima',
                    'Takoradi',
                    'Takpo',
                    'Tamale',
                    'Tamale Port',
                    'Tamaligu',
                    'Tampion',
                    'Tamso',
                    'Tanaso',
                    'Tangasia',
                    'Tanina',
                    'Tanji',
                    'Tanoso',
                    'Tantali',
                    'Tarkwa',
                    'Tarongo',
                    'Tatale',
                    'Teainano',
                    'Techigu',
                    'Teekyere',
                    'Tefle',
                    'Tema',
                    'Tenfian',
                    'Teselima',
                    'Teshi',
                    'Tetema',
                    'Tetrem',
                    'Tijo',
                    'Tikobo No 1',
                    'Tikobo No 2',
                    'Tikpang',
                    'Tilli',
                    'Tinga',
                    'Tolon',
                    'Tongo',
                    'Torsa',
                    'Toyanyili',
                    'Trede',
                    'Trom',
                    'Tromeso',
                    'Tsito',
                    'Tsrefe',
                    'Tugu',
                    'Tuluwe',
                    'Tumfakura',
                    'Tumu',
                    'Tuna',
                    'Tuobodom',
                    'Turumi',
                    'Tutukpene',
                    'Tutumi',
                    'Tuwuo',
                    'Twenene',
                    'Twifo Praso',
                    'Twifo-mampon',
                    'Ugando',
                    'Vaggo',
                    'Vakpo',
                    'Vamboi',
                    'Wa',
                    'Waiasi',
                    'Wakawaka',
                    'Walembele',
                    'Walewale',
                    'Wam',
                    'Wamanafo',
                    'Wamfie',
                    'Wangasi - Turu',
                    'Waniada',
                    'Wapuli',
                    'Warinvaga',
                    'Wasipe',
                    'Waso',
                    'Watoro',
                    'Waya',
                    'Wechiau',
                    'Wegbe',
                    'Weija',
                    'Wenchi',
                    'Wenchiki',
                    'Wiaga-yisobso',
                    'Wiamoase',
                    'Wiasi',
                    'Winisa',
                    'Winkogo',
                    'Winneba',
                    'Wiosa',
                    'Woe',
                    'Worakese',
                    'Worawora',
                    'Wuinta',
                    'Wulensi',
                    'Wulensimpana',
                    'Wumenu',
                    'Wungu',
                    'Wurnpigo',
                    'Wuru',
                    'Wute',
                    'Yabraso',
                    'Yabum',
                    'Yagaba',
                    'Yahaiyape',
                    'Yakase',
                    'Yala',
                    'Yamatwa',
                    'Yankoman',
                    'Yankzia',
                    'Yapei',
                    'Yapessa',
                    'Yawga',
                    'Yazori',
                    'Yendi',
                    'Yepalsi',
                    'Yezes',
                    'Yikpabango',
                    'Yipala',
                    'Yundum',
                    'Zabzugu',
                    'Zanduli',
                    'Zankum',
                    'Zariboku',
                    'Zebilla',
                    'Zegbeli',
                    'Zin',
                    'Zion',
                    'Zlope',
                    'Zoggo',
                    'Zoko',
                    'Zongo-markyeri',
                    'Zuarugu',
                    'Zuro',
                  ],
                },
                {
                  id: 'region',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Region',
                  uid: 'e895ed0bbc769606',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Ahafo',
                    'Ashanti',
                    'Bono East',
                    'Brong Ahafo',
                    'Central',
                    'Eastern',
                    'Greater Accra',
                    'North East',
                    'Northern',
                    'Oti',
                    'Savannah',
                    'Upper East',
                    'Upper West',
                    'Volta',
                    'Western',
                    'Western North',
                  ],
                },
                {
                  id: 'po-box-or-private-bag',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'PO Box Or Private Bag',
                  uid: '438a76adb0dd6ffa',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'your-residential-address',
          type: 'list',
          readonly: false,
          label: 'Your Residential Address',
          uid: '92a54ed22a770c20',
          content: [
            {
              id: 'life-assured-residential-address',
              type: 'list',
              readonly: false,
              label: 'Life Assured Residential Address',
              uid: '95cb96f28da36e6e',
              content: [
                {
                  id: 'street',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Street',
                  uid: 'b52dcf12c27f43d8',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'suburb',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Suburb',
                  uid: '777d4fd2b52ae500',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'your-digital-address',
          type: 'list',
          readonly: false,
          label: 'Your Digital Address',
          uid: 'b7a087458e852f01',
          content: [
            {
              id: 'life-assured-digital-address',
              type: 'list',
              readonly: false,
              label: 'Life Assured Digital Address',
              uid: '9a4167ebec4b20da',
              content: [
                {
                  id: 'digital-address',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Digital Address',
                  uid: '21e05158b0069035',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'employment-details',
          type: 'list',
          readonly: false,
          label: 'Employment Details',
          uid: '36ff6dc9cb0776fe',
          content: [
            {
              id: 'employer-organisation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Employer Organisation',
              uid: 'ec5161b6c06cb826',
              content: '',
              publishChange: true,
              options: [
                '',
                'Abokobi Area Rural Bankstaff',
                'Accra Metro Assembly',
                'Adansi Rural Bank',
                'Advans Ghana',
                'Advent Press',
                'African Regent Hotel',
                'Agave Rural Bank',
                'Agric Development Bank',
                'Ahafo Ano Premier Rural Bank',
                'Ahantaman Rural Bankâ ',
                'Akaa Security Services',
                'Akatakyiman Rural Bank',
                'Akim Bosome Rural Bank',
                'Akwapem Rural Bank',
                'Aluworks',
                'Ama Dansowaa Clinic',
                'Amanano Rural Bank',
                'Amansie West Rural Bank',
                'Amatin & Kasei Comm Bank',
                'Antrak Ghana Ltd',
                'Anum Rural Bank Ltd',
                'Apex Bank',
                'Ashante Akyem Rural Bank',
                'Asokore Rural Bank Ltd - Kumasi',
                'Asutifi Rural Bank Limited',
                'Atlantic Int. Holding Co',
                'Atwima Kwanwoma Rural Bank',
                'Atwima Rural Bank',
                'Auto Parts Limited',
                'Awutu Emasa Rural Bank',
                'Ayrton Drug Manufacturing Limited',
                'Bank of Ghana',
                'Baptist Medical Centre',
                'Barge Operators and Allied Services Ltd',
                'Benso Oil Palm Plantationâ ',
                'Bessfa Rural Bank',
                'Bible Society of Ghana',
                'Bonzali Rural Bank',
                'Bosomtwe Rb',
                'Bureau of National Communication',
                'Bureau of National Investigations',
                'Busua Beach Resortâ ',
                'Capital Rural Bank',
                'Carmeuse Lime Productâ ',
                'Cejose Wisdom Int. School',
                'Chemico Limited',
                'Chirano Gold Mines',
                'Christ Ebenezer',
                'Church Hill of God',
                'Cocoa Marketiing Company Ltd',
                'Cocoa Processing Comp',
                'Controller',
                'Cssvd Control Unit',
                'Dangme Rural Bank Ltd - Staff',
                'De Youngsters International School Ltd',
                'Deoke Foundation Schools',
                'Derma Area Rural Bank',
                'Dizengoff Ghana Ltd',
                'Dynamic Insurance Brokers',
                'Ebennezer Osei Brenya Enterprise',
                'Ejuraman Rural Bank',
                'Electricity Company of Ghana',
                'Elmina Beach Resort',
                'Englebert School',
                'Enterprise Funeral Services',
                'Enterprise Group Ltd',
                'Enterprise Insurance',
                'Enterprise Life Reps',
                'Enterprise Life Staff',
                'Enterprise Properties Ltd',
                'Enterprise Trustees Limited',
                'Epa',
                'Eskay Therapeutics Ltd',
                'Fiagya Rural Bank Ltd',
                'Fiaseman Rural Bankâ ',
                'Fiesta Royale Hotel',
                'First National Savings and Loansâ ',
                'Forces Pay Office',
                'Ga East Municipal Assembly',
                'Ga Rural Bank',
                'Ga Rural Customers',
                'Gafco',
                'Garden City - Multi Credit',
                'Gcb Bank Ltd Staff',
                'Ghana Airport Company Ltd.',
                'Ghana Bauxite Company Limted',
                'Ghana Cement',
                'Ghana Chamber of Mines',
                'Ghana Civil Aviation Authority',
                'Ghana Cocoa Board',
                'Ghana Grid Company',
                'Ghana Heavy Equipment Limited',
                'Ghana Link Network Services Ltd',
                'Ghana Maritime Authority',
                'Ghana Medical School',
                'Ghana Oil Co. Ltd',
                'Ghana Police Service',
                'Ghana Post',
                'Ghana Railway Co. Ltd',
                'Ghana Re Company Limited',
                'Ghana Revenue Authority',
                'Ghana Standard Board',
                'Ghana Union Conference of s.d.a.',
                'Gihoc Distilleries Company Limited',
                'Gimpa',
                'Globeserve Ministry Intâ ',
                'Golden Exotics Ltd',
                'Goldengate Services Ltd',
                'Gpha - Takoradi',
                'Gpha - Tema',
                'Grace Baptist School - Kumasi',
                'Guiness Ghana Ltd',
                'Gwcl - Accra East',
                'Gwcl - Accra West',
                'Gwcl - Ashanti North',
                'Gwcl - Ashanti Production',
                'Gwcl - Ashanti South',
                'Gwcl - Cape Coast',
                'Gwcl - Greater Accra Region Production',
                'Gwcl - Head Office',
                'Gwcl - Koforidua',
                'Gwcl - Sunyani',
                'Gwcl - Takoradi',
                'Gwcl - Tema',
                'Hobats Clinic',
                'Holiday Inn Accra',
                'Home Finance Company Bank Ltd',
                'House of Compassion Ministries',
                'Japan Motors Trading Company',
                'Juaben Rural Bank',
                'Jubilee Shipping Services Gh Ltd',
                'k.n.u.s.t',
                'Kaaseman Rural Bank Ltd',
                'Kessben',
                'Kingsgroup of Companies',
                'Kintampo Health Research Centre',
                'Koforidua Stop Order Walk In',
                'Korle Bu Teaching Hospital',
                'Krane Contsruction',
                'Krobo Danish Association',
                'Kuapa Kokoo',
                'Kumawuman Rural Bnk',
                'Kwamaman Rural Bank',
                'La Nkwantanang Madina Municipal Assembly',
                'Labadi Beach Hotel',
                'Life Healthcare Medical Dental and Optical Clinic',
                'Living Star School',
                'Lordship Insurance Brokers',
                'Lower Pra Rural Bank',
                'Maxam Ghana Ltd',
                'Mechanical Lloyd',
                'Metromass',
                'Mojo Investment Ltd',
                'Mondial Veneer Gh. Ltd',
                'Mumuada Rural Bank',
                'Nafana Rural Bank Ltd',
                'National Security',
                'Nexans',
                'Nsoatreman Rural Bank',
                'Nthc Properties Ltd',
                'Nwabiagya Rural Bank',
                'Okomfo Anokye Rural Bank Staff',
                'Opportunity Int',
                'Parliament',
                'Pbc Ltd',
                'Pentecost Preparatory Schoolâ ',
                'Pentecost University College',
                'Phyto Riker',
                'Poly Products',
                'Presbyterian Womens Center - Abokobi',
                'Prince of Peace',
                'Quality Control Division (Cocobod)',
                'Regional Maritime Academy',
                'Ridge Church School',
                'Safebound Company Limited - Tema',
                'Sakworg Company Ltd',
                'Samartex Timber and Plywood Company',
                'Sandvik Mining and Construction Limited',
                'Scanbech Ghana Ltd',
                'Sdv Ghana',
                'Securities Discount Company Limited',
                'Sekyedumase Rural Bank',
                'Sekyere Rural Bank',
                'Services Intergrity Savings and Loans',
                'Sgs Laboratory Services',
                'Sg Ssb',
                'Silver Star Auto Ltd',
                'Sinapi Aba Trust',
                'Sofaamy Company Limited',
                'Sos Childrens Village Ghana',
                'South Birim Rural Bank',
                'South Central Conference of Sda',
                'Special Ice Company Ltd',
                'Ssnit',
                'St Ambrose College',
                'St Michaels Hospital Pramso',
                'Stellar Logistic',
                'Sunyani Cluster Icps',
                'Takoradi Flour Mill Ltd',
                'Takoradi Int. Co.',
                'Tct',
                'Tema Development Corp.',
                'Tema Parents Association School',
                'Tesano Baptist Church',
                'The Church of Pentecost',
                'Toende Rural Bank',
                'Trasacco',
                'Twifo Oil Palm Plantation',
                'Type Company Ltd',
                'Unilever',
                'University College of Education',
                'University for Development Studies',
                'University of Energy and Natural Resources',
                'University of Ghana - Legon',
                'Upper Amenfi Rural Bank',
                'Upper Manya Kro Rural Bank',
                'Utrak Savings and Loans',
                'Valley View',
                'Valley View University Basic School',
                'Vat Service',
                'Volta Hotel',
                'Volta River Authority',
                'Waec - Headquarters',
                'Waec - Main',
                'West Africa Mills Co. Ltd',
                'Weto Rural Bank',
                'Winners Chapel Ghana',
                'Yaakson Herbal Centre',
                'Yarsako',
              ],
            },
            {
              id: 'employee-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Employee Number',
              uid: '7fdef8a2403ea33f',
              content: '',
              publishChange: true,
              inputType: 'text',
            },
            {
              id: 'basic-earnings',
              type: 'amount',
              elementType: 'input',
              readonly: false,
              label: 'Basic Earnings',
              uid: '09c26c35b8fea153',
              content: '',
              publishChange: true,
              inputType: 'number',
            },
            {
              id: 'tin-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Tin Number',
              uid: 'd3b35e9fbcda87a7',
              content: '',
              publishChange: true,
              inputType: 'text',
            },
            {
              id: 'occupation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Occupation',
              uid: 'fb08a0491700416d',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Abattoir Worker',
                'Accountant',
                'Accounting Officer',
                'Actor',
                'Actress',
                'Actuary',
                'Admin Assistant',
                'Administrator',
                'Advertising Agent',
                'Advocate',
                'Affal Washer',
                'Agriculture Assistant',
                'Agriculture Officer',
                'Air Force',
                'Air Force Ground Crew',
                'Aircraft Engineer',
                'Airline Employee',
                'Airline Executive',
                'Airline Pilot',
                'Ambulance Attendant',
                'Ambulance Driver',
                'Announcer',
                'Artisan',
                'Artist',
                'Assessor',
                'Auctioneer',
                'Auditor',
                'Author',
                'Auto Electrician',
                'Auxillary Worker',
                'Aviation',
                'Baker',
                'Bank Teller',
                'Bank-Manager',
                'Banker',
                'Bar Lady',
                'Bar Steward',
                'Barber',
                'Barmaid',
                'Barman',
                'Beautician',
                'Blacksmith',
                'Blockman',
                'Boilermaker',
                'Bookbinder',
                'Bookkeeper',
                'Bookmaker',
                'Boring Contractor',
                'Bottle Store Assistant',
                'Bottle Store Manager',
                'Boxer',
                'Bricklayer',
                'Broker',
                'Builder',
                'Building Contractor',
                'Building Foreman',
                'Building Inspector',
                'Bus Driver',
                'Butcher',
                'Buyer',
                'Cabinetmaker',
                'Cafe Owner',
                'Cameraman',
                'Cardiologist',
                'Caretaker',
                'Carpenter',
                'Carpet Layer',
                'Cashier',
                'Caterer',
                'Checker',
                'Chef',
                'Chief Officer',
                'Chiropodist',
                'Civil Defense Officer',
                'Cleaner',
                'Clergyman',
                'Clerk',
                'Cold Storage Worker',
                'Commercial Photographer',
                'Compositor-Electronic',
                'Compositor-Mechanical',
                'Computer Engineer',
                'Computer Operator',
                'Computer Programmer',
                'Computer Systems Analyst',
                'Computer Technician',
                'Conductor',
                'Constable',
                'Construction Worker',
                'Consultant',
                'Cook',
                'Copywriter',
                'Councillor',
                'Courier',
                'Cropduster',
                'Croupier',
                'Customs Official',
                'Dairy Worker',
                'Dancing Teacher',
                'Dean',
                'Demolition Worker',
                'Dental Assistant',
                'Dental Technician',
                'Dentist',
                'Detective',
                'Diamond Cutter',
                'Dietician',
                'Director',
                'Display Artist',
                'Diver',
                'Dockworker',
                'Doctor',
                'Domestic',
                'Draughtsman',
                'Dressmaker',
                'Driller',
                'Driver',
                'Dry Cleaner',
                'Economist',
                'Editor',
                'Education Inspector',
                'Educator',
                'Electrical Contractor',
                'Electrician',
                'Electronics Engineer',
                'Electroplater',
                'Employee',
                'Engineer',
                'Engineer-Qualified',
                'Engraver',
                'Entomologist',
                'Estate Agent',
                'Executive',
                'Explosives Worker',
                'Exterminator',
                'Farm Worker',
                'Farmer Foreman',
                'Farmer Manager',
                'Farmer Owner',
                'Fashion Designer',
                'Film Maker',
                'Fireman',
                'Fisherman',
                'Fitter And Turner',
                'Floor Surfacer',
                'Florist',
                'Foreman',
                'Forester',
                'Fortune Teller',
                'Foundry Manager',
                'Foundry Worker',
                'Freight Forwarder',
                'Fumigator',
                'Furnace Assistant',
                'Furnace Manager',
                'Furnace Supervisor',
                'Game Ranger',
                'Game Warden',
                'Garage Attendant',
                'Garage Owner',
                'Gardener',
                'General Assistant',
                'General Dealer',
                'General Worker',
                'Geologist',
                'Glassblower',
                'Glazier',
                'Goldsmith',
                'Grain Silo Operator',
                'Graphic Designer',
                'Greengrocer',
                'Groundsman',
                'Guard (Security)',
                'Gunsmith',
                'Hairdresser',
                'Hawker',
                'Head Of Department',
                'Headmaster',
                'Health Inspector',
                'Homeopath',
                'Horticulturist',
                'Hotel Manager',
                'Housewife',
                'Hr Assistant',
                'Inspector',
                'Insurance Agent',
                'Insurance Assessor',
                'Interior Decorator',
                'Interpreter',
                'Investigator',
                'Jeweller',
                'Jockey',
                'Joiner',
                'Journalist',
                'Labourer',
                'Lawyer',
                'Lecturer',
                'Librarian',
                'Lift Inspector',
                'Lift Operator',
                'Lift Technician',
                'Lighthouse Keeper',
                'Locksmith',
                'Machine Operator',
                'Machinist',
                'Magistrate',
                'Major',
                'Manager',
                'Manicurist',
                'Manufacturer',
                'Market Researcher',
                'Marketing Officer',
                'Martial Arts Instructor',
                'Mason',
                'Meat Inspector',
                'Mechanic',
                'Medical Representative',
                'Medical Technologist',
                'Medical-Practitioner',
                'Merchandiser',
                'Merchant Seaman',
                'Messenger',
                'Metallurgist',
                'Meter Reader',
                'Midwife',
                'Millwright',
                'Miner',
                'Minister Of Religion',
                'Model',
                'Motor Salesman',
                'Motor Spares Salesman',
                'Moulder',
                'Musician',
                'Naval Personnel',
                'Night Watchman',
                'Nurse',
                'Nurseryman',
                'Nursing Assistant',
                'Nursing Sister',
                'Occupational Therapist',
                'Officer',
                'Operator',
                'Ophthalmologist',
                'Optical Mechanic',
                'Orthopedic Orthopedist',
                'Packer',
                'Painter',
                'Painting Contractor',
                'Panelbeater',
                'Paramedic',
                'Pastor',
                'Pawnbroker',
                'Pensioner',
                'Permanent Way',
                'Personnel Officer',
                'Pest Exterminator',
                'Petrol Attendant',
                'Petroleum Refinery',
                'Pharmacist',
                'Photographer',
                'Physiotherapist',
                'Piano Tuner',
                'Pilot',
                'Plasterer',
                'Plumber',
                'Police Constable',
                'Police Woman',
                'Policeman',
                'Porter',
                'Postman',
                'Postmaster',
                'Principal',
                'Printer',
                'Prisons Personnel',
                'Private Investigator',
                'Producer',
                'Professor',
                'Programmer',
                'Property Appraiser',
                'Proprietor',
                'Public Prosecutor',
                'Public Relations Officer',
                'Quality Controller',
                'Quantity Surveyor',
                'Quarryman',
                'Racehorse Trainer',
                'Radiographer',
                'Radiologist',
                'Receptionist',
                'Religious Leader',
                'Repairman',
                'Representative',
                'Research Assistant',
                'Restaurant Owner',
                'Retired',
                'Rigger (Docks)',
                'Roadworker',
                'Sailor',
                'Sales Consultant',
                'Sales Lady',
                'Sales Support',
                'Sales Woman',
                'Salesman',
                'Seaman',
                'Seaman (Merchant)',
                'Seamstress',
                'Secretary',
                'Security Guard',
                'Self-Employed',
                'Sergeant',
                'Shedman',
                'Sheet Metal Worker',
                'Shipping Agent',
                'Shop Assistant',
                'Shop Manager',
                'Shopfitter',
                'Shunter-Foreman',
                'Signwriter',
                'Silversmith',
                'Singer',
                'Slaughterer',
                'Smelter',
                'Social Worker',
                'Soldier',
                'Sound Engineer',
                'Sportsman',
                'Sportswoman',
                'Spray Painter',
                'Sprayer',
                'Stationmaster',
                'Statistician',
                'Stevedore',
                'Steward',
                'Stock Broker',
                'Store Lady',
                'Storeman',
                'Student',
                'Supervisor',
                'Surgeon',
                'Sweeper',
                'Swimming Pool Attendant',
                'Switchboard Operator',
                'Systems Analyst',
                'Tailor',
                'Taxi Driver',
                'Teacher',
                'Technician',
                'Telegraphist',
                'Telephone Technician',
                'Telephonist',
                'Tiler',
                'Toolmaker',
                'Town Clerk',
                'Town Planner',
                'Trader',
                'Tradesman',
                'Traffic Officer',
                'Trainer',
                'Translator',
                'Travel Agent',
                'Typesetter',
                'Typist',
                'Undertaker',
                'Underwriter',
                'Unemployed',
                'Upholsterer',
                'Urologist',
                'Vetrinarian',
                'Vetrinary Surgeon',
                'Vulcaniser',
                'Waiter',
                'Waitress',
                'Warden',
                'Watch Maker',
                'Watchman',
                'Welder',
                'Well Sinker',
                'Window Cleaner',
                'Window Dresser',
                'Wood Cutter',
                'Worker',
                'Wrestler',
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'ea8d6db19ed85ff0',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '8e461e31be2b6805',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '13fd70b0237eaeee',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '25157e1a71993bb8',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'd302a75bb5ba94db',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Policyholder Full Details',
    uri: 'fipp-enhanced/policyholder-full-details',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-policyholder-full-details',
  },
  'spouse-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'spouse-details',
      type: 'list',
      readonly: false,
      label: 'Spouse Details',
      uid: '19562d38a4bde96a',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '2a669ec2b7c2d500',
          content: '<h2>Spouse Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f854d5be6971bd88',
          content: '<p>Please complete the details of your spouse</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'e55ea46b1a371db4',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: '4aff2d9293a75912',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: '9812039f751f1d17',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '36aa45a8a2d05e28',
          content: '19900505',
          publishChange: true,
          inputType: 'date',
          component: 'DateSelector',
          maxYear: thisYear,
        },
        {
          id: 'idpassport-number',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'ID/Passport Number',
          uid: '3c5844938854d6ef',
          content: '',
          publishChange: true,
          inputType: 'text',
        },
        {
          id: 'spouse-id-type-radio-group',
          type: 'list',
          readonly: false,
          label: 'Spouse ID Type Radio Group',
          uid: '01c3c58635214431',
          content: [
            {
              id: 'dvla',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Dvla',
              uid: '9d4da915a6f303ff',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
            {
              id: 'passport',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Passport',
              uid: '416042d9ee0afb3f',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
            {
              id: 'voter-id',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Voter ID',
              uid: 'bdbf9029d0e26f3a',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
            {
              id: 'nhis',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Nhis',
              uid: '049879b2e74fd7de',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
            {
              id: 'ssnit',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Ssnit',
              uid: 'e0d207de86ecbfa7',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
            {
              id: 'nia-ghana-card',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Nia Ghana Card',
              uid: 'd5742458fa347c9b',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
          ],
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Gender',
          uid: '748d00be5e69602d',
          content: 'Male',
          inputType: 'text',
        },
        {
          id: 'title',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Title',
          uid: '68e81dd62bcb39c8',
          content: '',
          publishChange: true,
          errors: ['*'],
          tooltip: 'Title',
          options: ['', 'Adv', 'Dr', 'Miss', 'Mr', 'Mrs', 'Ms', 'Prof', 'Rev'],
        },
        {
          id: 'cell-phone',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Cell Phone',
          uid: 'da7092304405e32c',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'tel',
          component: 'PhoneNumber',
        },
        {
          id: 'spouse-employment-details',
          type: 'list',
          readonly: false,
          label: 'Spouse Employment Details',
          uid: '302a5a7f2b401a31',
          content: [
            {
              id: 'occupation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Occupation',
              uid: '20f32df14b1b769a',
              content: '',
              publishChange: true,
              options: [
                '',
                'Abattoir Worker',
                'Accountant',
                'Accounting Officer',
                'Actor',
                'Actress',
                'Actuary',
                'Admin Assistant',
                'Administrator',
                'Advertising Agent',
                'Advocate',
                'Affal Washer',
                'Agriculture Assistant',
                'Agriculture Officer',
                'Air Force',
                'Air Force Ground Crew',
                'Aircraft Engineer',
                'Airline Employee',
                'Airline Executive',
                'Airline Pilot',
                'Ambulance Attendant',
                'Ambulance Driver',
                'Announcer',
                'Architect',
                'Artisan',
                'Artist',
                'Assessor',
                'Auctioneer',
                'Auditor',
                'Author',
                'Auto Electrician',
                'Auxillary Worker',
                'Aviation',
                'Baker',
                'Bank Teller',
                'Bank-Manager',
                'Banker',
                'Bar Lady',
                'Bar Steward',
                'Barber',
                'Barmaid',
                'Barman',
                'Beautician',
                'Blacksmith',
                'Blockman',
                'Boilermaker',
                'Bookbinder',
                'Bookkeeper',
                'Bookmaker',
                'Boring Contractor',
                'Bottle Store Assistant',
                'Bottle Store Manager',
                'Boxer',
                'Bricklayer',
                'Broker',
                'Builder',
                'Building Contractor',
                'Building Foreman',
                'Building Inspector',
                'Bus Driver',
                'Butcher',
                'Buyer',
                'Cabinetmaker',
                'Cafe Owner',
                'Cameraman',
                'Cardiologist',
                'Caretaker',
                'Carpenter',
                'Carpet Layer',
                'Cashier',
                'Caterer',
                'Checker',
                'Chef',
                'Chief Officer',
                'Chiropodist',
                'Civil Defense Officer',
                'Cleaner',
                'Clergyman',
                'Clerk',
                'Cold Storage Worker',
                'Commercial Photographer',
                'Compositor-Electronic',
                'Compositor-Mechanical',
                'Computer Engineer',
                'Computer Operator',
                'Computer Programmer',
                'Computer Systems Analyst',
                'Computer Technician',
                'Conductor',
                'Constable',
                'Construction Worker',
                'Consultant',
                'Cook',
                'Copywriter',
                'Councillor',
                'Courier',
                'Cropduster',
                'Croupier',
                'Customs Official',
                'Dairy Worker',
                'Dancing Teacher',
                'Dean',
                'Demolition Worker',
                'Dental Assistant',
                'Dental Technician',
                'Dentist',
                'Detective',
                'Diamond Cutter',
                'Dietician',
                'Director',
                'Display Artist',
                'Diver',
                'Dockworker',
                'Doctor',
                'Domestic',
                'Draughtsman',
                'Dressmaker',
                'Driller',
                'Driver',
                'Dry Cleaner',
                'Economist',
                'Editor',
                'Education Inspector',
                'Educator',
                'Electrical Contractor',
                'Electrician',
                'Electronics Engineer',
                'Electroplater',
                'Employee',
                'Engineer',
                'Engineer-Qualified',
                'Engraver',
                'Entomologist',
                'Estate Agent',
                'Executive',
                'Explosives Worker',
                'Exterminator',
                'Farm Worker',
                'Farmer Foreman',
                'Farmer Manager',
                'Farmer Owner',
                'Fashion Designer',
                'Film Maker',
                'Fireman',
                'Fisherman',
                'Fitter And Turner',
                'Floor Surfacer',
                'Florist',
                'Foreman',
                'Forester',
                'Fortune Teller',
                'Foundry Manager',
                'Foundry Worker',
                'Freight Forwarder',
                'Fumigator',
                'Furnace Assistant',
                'Furnace Manager',
                'Furnace Supervisor',
                'Game Ranger',
                'Game Warden',
                'Garage Attendant',
                'Garage Owner',
                'Gardener',
                'General Assistant',
                'General Dealer',
                'General Worker',
                'Geologist',
                'Glassblower',
                'Glazier',
                'Goldsmith',
                'Grain Silo Operator',
                'Graphic Designer',
                'Greengrocer',
                'Groundsman',
                'Guard (Security)',
                'Gunsmith',
                'Hairdresser',
                'Hawker',
                'Head Of Department',
                'Headmaster',
                'Health Inspector',
                'Homeopath',
                'Horticulturist',
                'Hotel Manager',
                'Housewife',
                'Hr Assistant',
                'Inspector',
                'Insurance Agent',
                'Insurance Assessor',
                'Interior Decorator',
                'Interpreter',
                'Investigator',
                'Jeweller',
                'Jockey',
                'Joiner',
                'Journalist',
                'Labourer',
                'Lawyer',
                'Lecturer',
                'Librarian',
                'Lift Inspector',
                'Lift Operator',
                'Lift Technician',
                'Lighthouse Keeper',
                'Locksmith',
                'Machine Operator',
                'Machinist',
                'Magistrate',
                'Major',
                'Manager',
                'Manicurist',
                'Manufacturer',
                'Market Researcher',
                'Marketing Officer',
                'Martial Arts Instructor',
                'Mason',
                'Meat Inspector',
                'Mechanic',
                'Medical Representative',
                'Medical Technologist',
                'Medical-Practitioner',
                'Merchandiser',
                'Merchant Seaman',
                'Messenger',
                'Metallurgist',
                'Meter Reader',
                'Midwife',
                'Millwright',
                'Miner',
                'Minister Of Religion',
                'Model',
                'Motor Salesman',
                'Motor Spares Salesman',
                'Moulder',
                'Musician',
                'Naval Personnel',
                'Night Watchman',
                'Nurse',
                'Nurseryman',
                'Nursing Assistant',
                'Nursing Sister',
                'Occupational Therapist',
                'Officer',
                'Operator',
                'Ophthalmologist',
                'Optical Mechanic',
                'Orthopedic Orthopedist',
                'Packer',
                'Painter',
                'Painting Contractor',
                'Panelbeater',
                'Paramedic',
                'Pastor',
                'Pawnbroker',
                'Pensioner',
                'Permanent Way',
                'Personnel Officer',
                'Pest Exterminator',
                'Petrol Attendant',
                'Petroleum Refinery',
                'Pharmacist',
                'Photographer',
                'Physiotherapist',
                'Piano Tuner',
                'Pilot',
                'Plasterer',
                'Plumber',
                'Police Constable',
                'Police Woman',
                'Policeman',
                'Porter',
                'Postman',
                'Postmaster',
                'Principal',
                'Printer',
                'Prisons Personnel',
                'Private Investigator',
                'Producer',
                'Professor',
                'Programmer',
                'Property Appraiser',
                'Proprietor',
                'Public Prosecutor',
                'Public Relations Officer',
                'Quality Controller',
                'Quantity Surveyor',
                'Quarryman',
                'Racehorse Trainer',
                'Radiographer',
                'Radiologist',
                'Receptionist',
                'Religious Leader',
                'Repairman',
                'Representative',
                'Research Assistant',
                'Restaurant Owner',
                'Retired',
                'Rigger (Docks)',
                'Roadworker',
                'Sailor',
                'Sales Consultant',
                'Sales Lady',
                'Sales Support',
                'Sales Woman',
                'Salesman',
                'Seaman',
                'Seaman (Merchant)',
                'Seamstress',
                'Secretary',
                'Security Guard',
                'Self-Employed',
                'Sergeant',
                'Shedman',
                'Sheet Metal Worker',
                'Shipping Agent',
                'Shop Assistant',
                'Shop Manager',
                'Shopfitter',
                'Shunter-Foreman',
                'Signwriter',
                'Silversmith',
                'Singer',
                'Slaughterer',
                'Smelter',
                'Social Worker',
                'Soldier',
                'Sound Engineer',
                'Sportsman',
                'Sportswoman',
                'Spray Painter',
                'Sprayer',
                'Stationmaster',
                'Statistician',
                'Stevedore',
                'Steward',
                'Stock Broker',
                'Store Lady',
                'Storeman',
                'Student',
                'Supervisor',
                'Surgeon',
                'Sweeper',
                'Swimming Pool Attendant',
                'Switchboard Operator',
                'Systems Analyst',
                'Tailor',
                'Taxi Driver',
                'Teacher',
                'Technician',
                'Telegraphist',
                'Telephone Technician',
                'Telephonist',
                'Tiler',
                'Toolmaker',
                'Town Clerk',
                'Town Planner',
                'Trader',
                'Tradesman',
                'Traffic Officer',
                'Trainer',
                'Translator',
                'Travel Agent',
                'Typesetter',
                'Typist',
                'Undertaker',
                'Underwriter',
                'Unemployed',
                'Upholsterer',
                'Urologist',
                'Vetrinarian',
                'Vetrinary Surgeon',
                'Vulcaniser',
                'Waiter',
                'Waitress',
                'Warden',
                'Watch Maker',
                'Watchman',
                'Welder',
                'Well Sinker',
                'Window Cleaner',
                'Window Dresser',
                'Wood Cutter',
                'Worker',
                'Wrestler',
              ],
            },
            {
              id: 'employer-organisation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Employer Organisation',
              uid: 'bc9d2dec54d770d6',
              content: '',
              publishChange: true,
              options: [
                '',
                'Abokobi Area Rural Bankstaff',
                'Accra Metro Assembly',
                'Adansi Rural Bank',
                'Advans Ghana',
                'Advent Press',
                'African Regent Hotel',
                'Agave Rural Bank',
                'Agric Development Bank',
                'Ahafo Ano Premier Rural Bank',
                'Ahantaman Rural Bankâ ',
                'Akaa Security Services',
                'Akatakyiman Rural Bank',
                'Akim Bosome Rural Bank',
                'Akwapem Rural Bank',
                'Aluworks',
                'Ama Dansowaa Clinic',
                'Amanano Rural Bank',
                'Amansie West Rural Bank',
                'Amatin & Kasei Comm Bank',
                'Antrak Ghana Ltd',
                'Anum Rural Bank Ltd',
                'Apex Bank',
                'Ashante Akyem Rural Bank',
                'Asokore Rural Bank Ltd - Kumasi',
                'Asutifi Rural Bank Limited',
                'Atlantic Int. Holding Co',
                'Atwima Kwanwoma Rural Bank',
                'Atwima Rural Bank',
                'Auto Parts Limited',
                'Awutu Emasa Rural Bank',
                'Ayrton Drug Manufacturing Limited',
                'Bank of Ghana',
                'Baptist Medical Centre',
                'Barge Operators and Allied Services Ltd',
                'Benso Oil Palm Plantationâ ',
                'Bessfa Rural Bank',
                'Bible Society of Ghana',
                'Bonzali Rural Bank',
                'Bosomtwe Rb',
                'Bureau of National Communication',
                'Bureau of National Investigations',
                'Busua Beach Resortâ ',
                'Capital Rural Bank',
                'Carmeuse Lime Productâ ',
                'Cejose Wisdom Int. School',
                'Chemico Limited',
                'Chirano Gold Mines',
                'Christ Ebenezer',
                'Church Hill of God',
                'Cocoa Marketiing Company Ltd',
                'Cocoa Processing Comp',
                'Controller',
                'Cssvd Control Unit',
                'Dangme Rural Bank Ltd - Staff',
                'De Youngsters International School Ltd',
                'Deoke Foundation Schools',
                'Derma Area Rural Bank',
                'Dizengoff Ghana Ltd',
                'Dynamic Insurance Brokers',
                'Ebennezer Osei Brenya Enterprise',
                'Ejuraman Rural Bank',
                'Electricity Company of Ghana',
                'Elmina Beach Resort',
                'Englebert School',
                'Enterprise Funeral Services',
                'Enterprise Group Ltd',
                'Enterprise Insurance',
                'Enterprise Life Reps',
                'Enterprise Life Staff',
                'Enterprise Properties Ltd',
                'Enterprise Trustees Limited',
                'Epa',
                'Eskay Therapeutics Ltd',
                'Fiagya Rural Bank Ltd',
                'Fiaseman Rural Bankâ ',
                'Fiesta Royale Hotel',
                'First National Savings and Loansâ ',
                'Forces Pay Office',
                'Ga East Municipal Assembly',
                'Ga Rural Bank',
                'Ga Rural Customers',
                'Gafco',
                'Garden City - Multi Credit',
                'Gcb Bank Ltd Staff',
                'Ghana Airport Company Ltd.',
                'Ghana Bauxite Company Limted',
                'Ghana Cement',
                'Ghana Chamber of Mines',
                'Ghana Civil Aviation Authority',
                'Ghana Cocoa Board',
                'Ghana Grid Company',
                'Ghana Heavy Equipment Limited',
                'Ghana Link Network Services Ltd',
                'Ghana Maritime Authority',
                'Ghana Medical School',
                'Ghana Oil Co. Ltd',
                'Ghana Police Service',
                'Ghana Post',
                'Ghana Railway Co. Ltd',
                'Ghana Re Company Limited',
                'Ghana Revenue Authority',
                'Ghana Standard Board',
                'Ghana Union Conference of s.d.a.',
                'Gihoc Distilleries Company Limited',
                'Gimpa',
                'Globeserve Ministry Intâ ',
                'Golden Exotics Ltd',
                'Goldengate Services Ltd',
                'Gpha - Takoradi',
                'Gpha - Tema',
                'Grace Baptist School - Kumasi',
                'Guiness Ghana Ltd',
                'Gwcl - Accra East',
                'Gwcl - Accra West',
                'Gwcl - Ashanti North',
                'Gwcl - Ashanti Production',
                'Gwcl - Ashanti South',
                'Gwcl - Cape Coast',
                'Gwcl - Greater Accra Region Production',
                'Gwcl - Head Office',
                'Gwcl - Koforidua',
                'Gwcl - Sunyani',
                'Gwcl - Takoradi',
                'Gwcl - Tema',
                'Hobats Clinic',
                'Holiday Inn Accra',
                'Home Finance Company Bank Ltd',
                'House of Compassion Ministries',
                'Japan Motors Trading Company',
                'Juaben Rural Bank',
                'Jubilee Shipping Services Gh Ltd',
                'k.n.u.s.t',
                'Kaaseman Rural Bank Ltd',
                'Kessben',
                'Kingsgroup of Companies',
                'Kintampo Health Research Centre',
                'Koforidua Stop Order Walk In',
                'Korle Bu Teaching Hospital',
                'Krane Contsruction',
                'Krobo Danish Association',
                'Kuapa Kokoo',
                'Kumawuman Rural Bnk',
                'Kwamaman Rural Bank',
                'La Nkwantanang Madina Municipal Assembly',
                'Labadi Beach Hotel',
                'Living Star School',
                'Lordship Insurance Brokers',
                'Lower Pra Rural Bank',
                'Maxam Ghana Ltd',
                'Mechanical Lloyd',
                'Metromass',
                'Mojo Investment Ltd',
                'Mondial Veneer Gh. Ltd',
                'Mumuada Rural Bank',
                'Nafana Rural Bank Ltd',
                'National Security',
                'Nexans',
                'Nsoatreman Rural Bank',
                'Nthc Properties Ltd',
                'Nwabiagya Rural Bank',
                'Okomfo Anokye Rural Bank Staff',
                'Opportunity Int',
                'Parliament',
                'Pbc Ltd',
                'Pentecost Preparatory Schoolâ ',
                'Pentecost University College',
                'Phyto Riker',
                'Poly Products',
                'Presbyterian Womens Center - Abokobi',
                'Prince of Peace',
                'Quality Control Division (Cocobod)',
                'Regional Maritime Academy',
                'Ridge Church School',
                'Safebound Company Limited - Tema',
                'Sakworg Company Ltd',
                'Sandvik Mining and Construction Limited',
                'Scanbech Ghana Ltd',
                'Sdv Ghana',
                'Securities Discount Company Limited',
                'Sekyedumase Rural Bank',
                'Sekyere Rural Bank',
                'Sgs Laboratory Services',
                'Sg Ssb',
                'Silver Star Auto Ltd',
                'Sinapi Aba Trust',
                'Sofaamy Company Limited',
                'Sos Childrens Village Ghana',
                'South Birim Rural Bank',
                'South Central Conference of Sda',
                'Special Ice Company Ltd',
                'Ssnit',
                'St Ambrose College',
                'St Michaels Hospital Pramso',
                'Stellar Logistic',
                'Sunyani Cluster Icps',
                'Takoradi Flour Mill Ltd',
                'Takoradi Int. Co.',
                'Tct',
                'Tema Development Corp.',
                'Tema Parents Association School',
                'Tesano Baptist Church',
                'The Church of Pentecost',
                'Toende Rural Bank',
                'Trasacco',
                'Twifo Oil Palm Plantation',
                'Type Company Ltd',
                'Unilever',
                'University College of Education',
                'University for Development Studies',
                'University of Energy and Natural Resources',
                'University of Ghana - Legon',
                'Upper Amenfi Rural Bank',
                'Upper Manya Kro Rural Bank',
                'Utrak Savings and Loans',
                'Valley View',
                'Valley View University Basic School',
                'Vat Service',
                'Volta Hotel',
                'Volta River Authority',
                'Waec - Headquarters',
                'Waec - Main',
                'West Africa Mills Co. Ltd',
                'Weto Rural Bank',
                'Winners Chapel Ghana',
                'Yaakson Herbal Centre',
                'Yarsako',
              ],
            },
            {
              id: 'employee-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Employee Number',
              uid: '49910534ecfcfa3c',
              content: '',
              publishChange: true,
              inputType: 'text',
            },
            {
              id: 'tin-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Tin Number',
              uid: '7f7bd0a53ee2e0ea',
              content: '',
              publishChange: true,
              inputType: 'text',
            },
          ],
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '105c42d70fbd4e0d',
          content: '<p>Specify your spouse to be the trustee of the plan</p>\n',
        },
        {
          id: 'spouse-is-trustee',
          type: 'field',
          elementType: 'checkbox',
          readonly: false,
          label: 'Spouse Is Trustee',
          uid: '6b58669450576bf8',
          content: '',
          publishChange: true,
          options: ['Y', 'N'],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '412d0b0f41948d7e',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '8a66e7e199e68e87',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '76c10458e36e0e35',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'd8d79789309b91c7',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'a4988c3d7f1e31ea',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Spouse Details',
    uri: 'fipp-enhanced/spouse-details',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-spouse-details',
  },
  'premium-validate': {
    text: [],
    buttons: [
      {
        label: 'Done',
        id: 'done',
        uid: '4e5f7031a7643ff6',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '9df131e41b9ed52f',
      content: [
        ' The minimum premium is 100. Please select another sum assured, add more benefits or add more lives assured in order to meet this requirement.',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Add Other Lives',
    uri: 'fipp-enhanced/other-lives-add',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-add-other-lives',
  },
  'trustee-details': {
    text: [],
    buttons: [
      {
        label: 'Recalculate',
        id: 'recalculate',
        uid: 'f7e7fcc76ead3671',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'trustee-details',
      type: 'list',
      readonly: false,
      label: 'Trustee Details',
      uid: '0b1df9a4b3d71a14',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '716305c544aa14a7',
          content: '<h2>Trustee Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53952a550bf734cc',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '8ac4d130f3be0edd',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'trustee-identification',
          type: 'list',
          readonly: false,
          label: 'Trustee Identification',
          uid: '75cc8902f6411d43',
          content: [
            {
              id: 'first-names',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'First Names',
              uid: '66d1c959d5ff80fb',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'surname',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Surname',
              uid: '939805a03a59ba9a',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'date-of-birth',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Date Of Birth',
              uid: '8c3344fe2f04f1c2',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'date',
              component: 'DateSelector',
              maxYear: thisYear,
            },
            {
              id: 'title',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Title',
              uid: '10e4727a0b4bd062',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Adv',
                'Dr',
                'Miss',
                'Mr',
                'Mrs',
                'Ms',
                'Prof',
                'Rev',
              ],
            },
            {
              id: 'cell-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Cell Phone',
              uid: 'b5fe4f0fda749fc3',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'relationship',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Relationship',
              uid: '14b853cd260d36ae',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Brother',
                'Daughter',
                'Estate',
                'Father',
                'Father-In-Law',
                'Grandfather',
                'Grandmother',
                'Mother',
                'Mother-In-Law',
                'Sister',
                'Son',
                'Spouse',
                'Testament',
                'Trust',
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'a1e9b4346a21f777',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'a28c7b7f37a6eb7b',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '28cbb9762bf8ff22',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'cfbd45d5eff5add0',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '3aa22fa32e1f6ecd',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Trustee Details',
    uri: 'fipp-enhanced/trustee-details',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-trustee-details',
  },
  'additional-lives': {
    text: [],
    buttons: [
      {
        label: 'Recalculate',
        id: 'recalculate',
        uid: 'f7e7fcc76ead3671',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'additional-lives',
      type: 'list',
      readonly: false,
      label: 'Additional Lives',
      uid: '9963dcd553e0402e',
      content: [],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP ENHANCED Lives Assured',
    uri: 'fipp-enhanced-plan/lives-assured',
    template: 'fipp-enhanced-lives-assured-screen',
    class: 'fipp-enhanced-lives-assured',
  },
  'beneficiary-details': {
    text: [],
    buttons: [
      {
        label: 'Add Trustee',
        id: 'add-trustee',
        uid: '1c40f26fba6a8711',
        control: false,
      },
      {
        label: 'Add Additional Life as Beneficiary',
        id: 'add-additional-life-as-beneficiary',
        uid: 'eb05bdecceadecca',
        control: false,
      },
      {
        label: 'Add Another Beneficiary',
        id: 'add-another-beneficiary',
        uid: 'cca8995092d126c2',
        control: false,
      },
      {
        label: 'Update Benefit Split Percentage',
        id: 'update-benefit-split-percentage',
        uid: '6ce12a7a32a8d69a',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'specify-beneficiaries',
      type: 'list',
      readonly: true,
      label: 'Specify Beneficiaries',
      uid: 'ade5ab6d30033977',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'ccd1e945cd5c3045',
          content: '<h2>Beneficiary Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f0d387b3ecd49ed5',
          content: '<p>Please specify your beneficiary details below</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'f46ff380717f1288',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'system-field-outcome',
          type: 'html',
          readonly: true,
          label: '',
          uid: '6c60b1c422ae8373',
          content:
            "<p><a href='#add-trustee'>Add Trustee as beneficiary</a></p>\n",
        },
        {
          id: 'system-field-outcome',
          type: 'html',
          readonly: true,
          label: '',
          uid: '57d2100ecd8854cc',
          content:
            "<p><a href='#add-additional-life-as-beneficiary'>Add Additional Life as beneficiary</a></p>\n",
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '145b199f80e0c8c3',
          content:
            "<p><a href='#add-another-beneficiary'>Add a different beneficiary</a></p>\n",
        },
        {
          id: 'beneficiary-details',
          type: 'table',
          readonly: true,
          label: 'Beneficiary Details',
          uid: 'ce17ccfb97db04dc',
          triggers: ['DoubleClick'],
          content: [],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '55c0bc62281e46bf',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'bd21a6f965d174b8',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '7111be8c31dc1d8c',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '661d735861046a2f',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '73d064eb1a0df579',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Beneficiary Details',
    uri: 'fipp-enhanced/beneficiary-details',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-beneficiary-details',
  },
  'add-beneficiary': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Recalculate',
        id: 'recalculate',
        uid: 'f7e7fcc76ead3671',
        control: false,
      },
      {
        label: 'Add',
        id: 'add',
        uid: '1413145eedf0be58',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'beneficiary-details',
      type: 'list',
      readonly: false,
      label: 'Beneficiary Details',
      uid: '72be27dcc17e771a',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '94e325bd80270712',
          content:
            '<p>Please provide the following information for the beneficiary you would like to add to the policy</p>\n',
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'f33cc0c8de4de1e0',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: '9b46035697c729bc',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'role',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Role',
          uid: '8ca3d9f9ca35bf9f',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: [
            '',
            'Brother',
            'Child',
            'Cousin',
            'Daughter',
            'Family',
            'Father',
            'Father-in-law',
            'Grandchild',
            'Grandfather',
            'Grandmother',
            'Main',
            'Mother',
            'Mother-in-law',
            'Nephew',
            'Niece',
            'Sister',
            'Son',
            'Spouse',
            'Step-Father',
            'Step-Mother',
          ],
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'radio',
          readonly: false,
          label: 'Gender',
          uid: '09f28932184006c8',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: ['Female', 'Male'],
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '6b9a4f659b85294a',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'date',
          component: 'DateSelector',
          maxYear: 2022,
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Beneficiary Details Add',
    uri: 'application/beneficiary-details/add',
    class: 'beneficiary-details-add',
  },
  'edit-beneficiary': {
    text: [],
    buttons: [
      {
        label: 'Recalculate',
        id: 'recalculate',
        uid: 'f7e7fcc76ead3671',
        control: false,
      },
      {
        label: 'Delete Beneficiary',
        id: 'delete-beneficiary',
        uid: '02308b457a7dc6a3',
        control: false,
      },
      {
        label: 'Update',
        id: 'update',
        uid: 'd8035e4553f65492',
        control: true,
      },
    ],
    item: {
      id: 'beneficiary-details',
      type: 'list',
      readonly: false,
      label: 'Beneficiary Details',
      uid: 'e86854b09d2374d4',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '7fa0e505ce6ab73a',
          content:
            "<p>Edit beneficiary information below or <a href='#delete-beneficiary'>click here to remove beneficiary</a></p>\n",
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'f33cc0c8de4de1e0',
          content: '',
          publishChange: true,
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: '9b46035697c729bc',
          content: '',
          publishChange: true,
          inputType: 'text',
        },
        {
          id: 'role',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Role',
          uid: '8ca3d9f9ca35bf9f',
          content: '',
          publishChange: true,
          options: [
            '',
            'Cessionary',
            'Brother',
            'Child',
            'Cousin',
            'Daughter',
            'Family',
            'Father',
            'Father-in-law',
            'Grandchild',
            'Grandfather',
            'Grandmother',
            'Main',
            'Mother',
            'Mother-in-law',
            'Nephew',
            'Niece',
            'Sister',
            'Son',
            'Spouse',
            'Step-Father',
            'Step-Mother',
          ],
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Gender',
          uid: '09f28932184006c8',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: ['', 'Female', 'Male'],
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '6b9a4f659b85294a',
          content: '',
          publishChange: true,
          inputType: 'date',
          component: 'DateSelector',
          maxYear: 2022,
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Beneficiary Details Edit',
    uri: 'application/beneficiary-details/edit',
    class: 'beneficiary-details-edit',
  },
  'update-beneficiary-split-percentage': {
    modal: true,
    text: [],
    buttons: [
      { label: 'Update', id: 'update', uid: 'd8035e4553f65492', control: true },
    ],
    item: {
      id: 'update-benefit-split-percentage',
      type: 'list',
      readonly: false,
      label: 'Update Benefit Split Percentage',
      uid: '8aac45b7cb0ac3f7',
      content: [],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Beneficiary Details Split',
    uri: 'application/beneficiary-details/split',
    class: 'beneficiary-details-split',
  },
  'add-additional-life-as-beneficiary': {
    modal: true,
    text: [],
    buttons: [],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '80145e8e8a03e9a3',
      content: ['Please select the life you want to add'],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'insurance-arrangements': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'existing-policies',
      type: 'list',
      readonly: false,
      label: 'Existing Policies',
      uid: 'f06a48ae3a5c601e',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'e2c5b035f1d1d961',
          content: '<h2>Existing insurance arrangements</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '6162dfa9508b7a86',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '844fbcf147fdfdba',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'existing-coverages',
          type: 'list',
          readonly: false,
          label: 'Existing Coverages',
          uid: '7cc12b2145a5f50c',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'd968197bc8ba287b',
              content:
                '<p>Do you have an existing, or are you presently applying (excluding this application) for life insurance with this or any insurance company?</p>\n',
            },
            {
              id: 'existing-coverage',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Existing Coverage',
              uid: 'bdafc0708798679d',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
          ],
        },
        {
          id: 'replace-coverage',
          type: 'list',
          readonly: false,
          label: 'Replace Coverage',
          uid: 'feb660086d8a4d4f',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '8ac0a4fa34ce350c',
              content:
                '<p>Is this application to replace the whole or part of any application to this or any office?</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'c554f2b6026d0695',
              content:
                '<p>Or to replace all or part of existing assurances with any office?</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'd7c1e41cdfbe8101',
              content:
                '<p>(whether replacement is to occur immediately or to replace an insurance policy discontinued within the past six months or to be discontinued within the next six months)</p>\n',
            },
            {
              id: 'application-replaces-existing',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Application Replaces Existing',
              uid: '2e038ebc229ed92d',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
          ],
        },
        {
          id: 'existing-policies-details',
          type: 'list',
          readonly: false,
          label: 'Existing Policies Details',
          uid: '427309bfc272eea5',
          content: [
            {
              id: 'existing-policy1',
              type: 'list',
              readonly: false,
              label: 'Existing Policy-1',
              uid: 'f8e771d63d741996',
              content: [
                {
                  id: 'company-name-1',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Company Name',
                  uid: 'fc4d77883a57ab48',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'existing-contract-id-1',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Existing Contract ID',
                  uid: '52bb36b4c07f50a5',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'sum-assured-1',
                  type: 'amount',
                  elementType: 'input',
                  readonly: false,
                  label: 'Sum Assured',
                  uid: 'ec4fbf1f63ab7896',
                  content: '',
                  publishChange: true,
                  inputType: 'number',
                },
              ],
            },
            {
              id: 'existing-policy2',
              type: 'list',
              readonly: false,
              label: 'Existing Policy-2',
              uid: 'f27100a9f359f6ee',
              content: [
                {
                  id: 'company-name-2',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Company Name',
                  uid: 'db627ad9150032a4',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'existing-contract-id-2',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Existing Contract ID',
                  uid: '59d107ccd92a2ea1',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'sum-assured-2',
                  type: 'amount',
                  elementType: 'input',
                  readonly: false,
                  label: 'Sum Assured',
                  uid: 'ca72fb7bb9dc7bde',
                  content: '',
                  publishChange: true,
                  inputType: 'number',
                },
              ],
            },
            {
              id: 'existing-policy3',
              type: 'list',
              readonly: false,
              label: 'Existing Policy-3',
              uid: '4ccea37b508e3a11',
              content: [
                {
                  id: 'company-name-3',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Company Name',
                  uid: 'a6db2c3f53484527',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'existing-contract-id-3',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Existing Contract ID',
                  uid: 'adcf9a44040b8b51',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'sum-assured-3',
                  type: 'amount',
                  elementType: 'input',
                  readonly: false,
                  label: 'Sum Assured',
                  uid: '3761da6a391a2146',
                  content: '',
                  publishChange: true,
                  inputType: 'number',
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'e5a34f1bf0d58dd3',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '5dde140177fcf58a',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '1d12ed200fd07ee5',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '2ad90c49ac48e9a0',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'd11d4c897e80dc24',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Spouse Details',
    uri: 'fipp-enhanced/spouse-details',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-spouse-details',
  },
  'medical-underwriting': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'general-occupation-activities-and-smoking-habits',
      type: 'list',
      readonly: false,
      label: 'General Occupation Activities And Smoking Habits',
      uid: 'f54d82f4a16a45e1',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '305332a95993c793',
          content: '<h2>Medical Underwriting</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '628a35e20bfcf58d',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '509234bf60e22682',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'inputs',
          type: 'list',
          readonly: false,
          label: 'Inputs',
          uid: '5a1c2ac4abfed6f4',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'f5953515522188db',
              content:
                '<p><em>Please note that all medical questions are related to the main life assuerd on this policy.</em></p>\n',
            },
            {
              id: 'question-one',
              type: 'list',
              readonly: false,
              label: 'Question One',
              uid: 'df803fd5532224c8',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'b69f9237a4c067e2',
                  content:
                    '<ol>\n<li>Are you presently negotiating or planning to negotiate for any other assurance on your life with this or any other assurer?</li>\n</ol>\n',
                },
                {
                  id: 'planning-other-insurance',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Planning Other Insurance',
                  uid: 'afa5b73efa5eef2c',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'planning-other-insurance-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '1db4cc6cb136397e',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Reason',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-two',
              type: 'list',
              readonly: false,
              label: 'Question Two',
              uid: '6c86ef37e0ae5845',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '00f7ced091e89fd5',
                  content:
                    "<ol start='2'>\n<li>Apart from details supplied in answer to Question 1 above, has any other assurance on your life been effected during the past 12 months?</li>\n</ol>\n",
                },
                {
                  id: 'other-assurance-past12-months',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Other Assurance Past-12 Months',
                  uid: 'dee16f3be8dbd5e6',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'other-assurance-past12-months-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '85bfbf86b41e8656',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Reason',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-three',
              type: 'list',
              readonly: false,
              label: 'Question Three',
              uid: 'a4de28cb26b02b5d',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '5c2fab94f400e917',
                  content:
                    "<ol start='3'>\n<li>Does the cover on your life under this and any simultaneous applications exceed GHc 100,000?</li>\n</ol>\n",
                },
                {
                  id: 'cover-exceeds-one-hundred-thousand',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Cover Exceeds One Hundred Thousand',
                  uid: 'ed52fcbe32fd1ab7',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  content: [
                    {
                      content: '<p>Average Monthly Gross Income</p>\n',
                      id: 'system-constant',
                      label: '',
                      readonly: true,
                      type: 'html',
                      uid: 'fe4c7dd9d24c2a9e',
                    },
                    {
                      content: '',
                      elementType: 'input',
                      errors: ['*'],
                      id: 'average-monthly-gross-income',
                      inputType: 'text',
                      label: 'Details',
                      publishChange: true,
                      readonly: false,
                      type: 'field',
                      uid: '31a3ecab4a74c98a',
                    },
                  ],
                  id: 'system-list',
                  label: 'System List',
                  readonly: false,
                  type: 'list',
                  uid: '5f8b25ed4415c897',
                },
                {
                  content: [
                    {
                      content:
                        '<p>Total Existing Assurance Cover in force on your life</p>\n',
                      id: 'system-constant',
                      label: '',
                      readonly: true,
                      type: 'html',
                      uid: 'ff1462e881f44c02',
                    },
                    {
                      content: '',
                      elementType: 'input',
                      id: 'total-existing-assurance-cover-in-force-on-your-life',
                      inputType: 'text',
                      label: 'Details',
                      publishChange: true,
                      readonly: false,
                      type: 'field',
                      uid: '31a3ecab4a74c98a',
                    },
                  ],
                  id: 'system-list',
                  label: 'System List',
                  readonly: false,
                  type: 'list',
                  uid: 'beaacad6ef6645ee',
                },
                {
                  content: [
                    {
                      content:
                        '<p>Full reasons for effecting this additional cover</p>\n',
                      id: 'system-constant',
                      label: '',
                      readonly: true,
                      type: 'html',
                      uid: '05a98ad847dcad34',
                    },
                    {
                      content: '',
                      elementType: 'input',
                      id: 'full-reasons-for-effecting-this-additional-cover',
                      inputType: 'text',
                      label: 'Details',
                      publishChange: true,
                      readonly: false,
                      type: 'field',
                      uid: '31a3ecab4a74c98a',
                    },
                  ],
                  id: 'system-list',
                  label: 'System List',
                  readonly: false,
                  type: 'list',
                  uid: '91930796f1d9da82',
                },
              ],
            },
            {
              id: 'question-four',
              type: 'list',
              readonly: false,
              label: 'Question Four',
              uid: '916e7801642da0e6',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'a95c89b874158f95',
                  content:
                    "<ol start='4'>\n<li>Have you in the past five years been engaged in or do you intend engaging in any hazardous occupation or pursuit, eg; mining, use of explosives, parachuting, hang-gliding, private flying, underwater diving, mountaineering?</li>\n</ol>\n",
                },
                {
                  id: 'risky-occupation-hobbies',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Risky Occupation Hobbies',
                  uid: '472f03f449a53db2',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'risky-occupation-hobbies-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '48bb179f6b60ab40',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Reason',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-five',
              type: 'list',
              readonly: false,
              label: 'Question Five',
              uid: '4326797724586a8e',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '4415eda17ab12dbe',
                  content:
                    "<ol start='5'>\n<li>Has any application on your life ever been declined, postponed, withdrawn or accepted on special terms: If Yes, give details below</li>\n</ol>\n",
                },
                {
                  id: 'refused-application-or-increased-premium',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Refused Application Or Increased Premium',
                  uid: '96867d2850c5fc62',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'refused-application-or-increased-premium-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '20ad0f6e4b733b27',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Reason',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-six',
              type: 'list',
              readonly: false,
              label: 'Question Six',
              uid: '99b7484fe7f5f5ed',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '38c54c48b2a748d3',
                  content:
                    "<ol start='6'>\n<li>Are your affairs currently subject to the control of a trustee or curator, due to insolvency or other reasons? If Yes, give details below</li>\n</ol>\n",
                },
                {
                  id: 'insolvency-issue',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Insolvency Issues',
                  uid: 'aa3968daf7ccece0',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'insolvency-issue-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: 'cd6edb73a6b53740',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Reason',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-seven',
              type: 'list',
              readonly: false,
              label: 'Question Seven',
              uid: '7345b2932559b891',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '130c3c9eb8edbc68',
                  content:
                    "<ol start='7'>\n<li>Do you in the course of your occupation perform duties that are not clerical or administrative?</li>\n</ol>\n",
                },
                {
                  id: 'occupation-not-clerical',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Occupation Not Clerical',
                  uid: '4a17700ed64ac83e',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'occupation-not-clerical-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: 'f278dd8861bfc0ef',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Reason',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-eight',
              type: 'list',
              readonly: false,
              label: 'Question Eight',
              uid: 'c2ff0534314e63e2',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'cfb0d9bf19450299',
                  content: "<ol start='8'>\n<li>Smoking habits</li>\n</ol>\n",
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '43e493e7fca1e759',
                  content:
                    '<p>NB: For rating purposes, a non-smoker is a person who has not smoked tobacco in any form for the past two years,</p>\n',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'e729e1ef79c1e527',
                  content:
                    '<p>has no intention of smoking in the future and accepts that Enterprise Life Assurance Limited may require a urine test to check for nicotine traces.</p>\n',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '152dcc779b4abff0',
                  content: '<p>8.1.1 Current Status</p>\n',
                },
                {
                  id: 'smoker-options-radio-group',
                  type: 'list',
                  readonly: false,
                  label: 'Smoker Options Radio Group',
                  uid: '12a99cfcf9087946',
                  content: [
                    {
                      id: 'smoker',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Smoker',
                      uid: '4f6b0394d93e12cb',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'non-smoker',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Non Smoker',
                      uid: 'c9e943aae7f9936a',
                      content: '',
                      publishChange: true,
                      errors: ['*'],
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'never-smoked',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Never Smoked',
                      uid: '94e911ab05895fa3',
                      content: '',
                      publishChange: true,
                      errors: ['*'],
                      options: ['Y', 'N'],
                    },
                  ],
                },
                {
                  id: 'years-smoking',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Years Smoking',
                  uid: '154114de46ecb5c4',
                  content: '',
                  publishChange: true,
                  errors: ['*', '*'],
                  inputType: 'text',
                },
                {
                  content: '',
                  elementType: 'input',
                  errors: ['*'],
                  id: 'years-not-smoking',
                  inputType: 'text',
                  label: 'Years Not Smoking',
                  publishChange: true,
                  readonly: false,
                  type: 'field',
                  uid: '8efdf8d15d84c2f1',
                },
                {
                  id: 'smoking-details',
                  type: 'list',
                  readonly: false,
                  label: 'Smoking Details',
                  uid: '71763b9aeae14f09',
                  content: [
                    {
                      content: '<p>8.1.2 Average daily habit</p>\n',
                      id: 'system-constant',
                      label: '',
                      readonly: true,
                      type: 'html',
                      uid: '786e91281548c845',
                    },
                    {
                      content: '',
                      elementType: 'input',
                      errors: ['*'],
                      id: 'cigarettes-per-day',
                      inputType: 'number',
                      label: 'Cigarettes Per Day',
                      publishChange: true,
                      readonly: false,
                      type: 'real',
                      uid: 'f1b1ed403acc60e6',
                    },
                    {
                      content: '',
                      elementType: 'input',
                      errors: ['*'],
                      id: 'cigars-per-day',
                      inputType: 'number',
                      label: 'Cigars Per Day',
                      publishChange: true,
                      readonly: false,
                      type: 'real',
                      uid: '11486df8c6636515',
                    },
                    {
                      content: '',
                      elementType: 'input',
                      errors: ['*'],
                      id: 'pipe',
                      inputType: 'number',
                      label: 'Pipe',
                      publishChange: true,
                      readonly: false,
                      type: 'real',
                      uid: '541ee2719f6a1075',
                    },
                    {
                      content:
                        '<p>8.1.3 Have you ever stopped smoking on specific medical advice?</p>\n',
                      id: 'system-constant',
                      label: '',
                      readonly: true,
                      type: 'html',
                      uid: '88e8a048c3379d45',
                    },
                    {
                      content: '',
                      elementType: 'radio',
                      errors: ['*'],
                      id: 'medical-advice-stop-smoking',
                      label: 'Medical Advice Stop Smoking',
                      options: ['Yes', 'No'],
                      publishChange: true,
                      readonly: false,
                      type: 'field',
                      uid: 'f945d27cf89f14a0',
                    },
                  ],
                },
              ],
            },
            {
              id: 'question-nine',
              type: 'list',
              readonly: false,
              label: 'Question Nine',
              uid: 'a4515af1f9698a55',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ae1ffb6a0dfde53f',
                  content: "<ol start='9'>\n<li>Alcohol Habits</li>\n</ol>\n",
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'b25527ae6639be0d',
                  content:
                    '<p>Have you ever received medical advice to reduce/discontinue liquor consumption?</p>\n',
                },
                {
                  id: 'medical-advice-reduce-drinking',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Medical Advice Reduce Drinking',
                  uid: 'ce63aade7fe8e2a7',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'cf39f62f8252a730',
                  content:
                    '<p>Do you on average consume more than three alcoholic drinks per day? (1 drink equals 1 tot of spirit or 1 pint of beer or 1 glass of wine)</p>\n',
                },
                {
                  id: 'excessive-drinking',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Excessive Drinking',
                  uid: 'fe6cb47bdbe1896f',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '35f32b5c1c2a2472',
                  content: '<p>Did you ever drink more in the past?</p>\n',
                },
                {
                  id: 'excessive-drinking-past',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Excessive Drinking Past',
                  uid: 'e3477bbf4ff0d3f1',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'bdeafdc680cc4cf9',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'd9d3a8422789a673',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '689e33acbd3b3c22',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'b541e8f16fbaf688',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'd836c41c4f3f51d4',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Policyholder Habits',
    uri: 'fipp-enhanced/policyholder-habits',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-policyholder-habits',
  },
  'medical-declaration': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'medical-declaration',
      type: 'list',
      readonly: false,
      label: 'Medical Declaration',
      uid: 'b47747f7d3eb0193',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'edeb9bb462e75465',
          content: '<h2>Medical Underwriting</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '8cb5b9f7f893ab25',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '7dd45a20ec98eb62',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'inputs',
          type: 'list',
          readonly: false,
          label: 'Inputs',
          uid: '9cd0ddd4fff2d1a9',
          content: [
            {
              id: 'question-one',
              type: 'list',
              readonly: false,
              label: 'Question One',
              uid: '0d9656c3fc437931',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '75ce797fb8e56305',
                  content:
                    '<ol>\n<li>Have you during the past five years sought medical advice, and/or had surgical treatment?</li>\n</ol>\n',
                },
                {
                  id: 'surgery-last-five-years',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Surgery Last Five Years',
                  uid: '18b188d4f9f8e0b2',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'a68b2b837851c2f0',
                  content:
                    '<p>Have you during, the past five years undergone any medical investigation of whatsoever nature such as X-ray, ECGs, blood test, etc?</p>\n',
                },
                {
                  id: 'medical-tests-last-five-years',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Medical Tests Last Five Years',
                  uid: 'eaf5bdf670297a80',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
              ],
            },
            {
              id: 'question-two',
              type: 'list',
              readonly: false,
              label: 'Question Two',
              uid: '9cb48fdf823bdb6b',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '5cef10af8bee58a1',
                  content:
                    "<ol start='2'>\n<li>If not already recorded in answer to the previous question have you ever experienced any of the health problems listed below:</li>\n</ol>\n",
                },
                {
                  id: 'chest-pain',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label:
                    '2.1 Rheumatic fever, heart murmurs, chest pain, angina, coronary thrombosis.',
                  uid: '89c7b191d1133686',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'high-blood-pressure',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label:
                    '2.2 High blood pressure, impaired blood circulation, stroke.',
                  uid: '8b34197c5396c863',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'respiratory-condition',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label:
                    '2.3 Conditions of lungs or respiratory system eg. asthma, shortness of breath, persistent cough or tuberculosis.',
                  uid: 'aed20df7d8cf8005',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'digestive-condition',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label:
                    '2.4 Conditions of digestive system, gall bladder, pancrease or liver, eg: gastric or duodenal ulcer, recurrent indigestion, hiatus hernia or jaundice.',
                  uid: '0190aa2e13d5110d',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'urinary-condition',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label:
                    '2.5 Conditions of the urinary tract, eg. burning urine, nephritis kidney stones, congenital abnormalities.',
                  uid: 'c15e5945fe05a08d',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'anxiety-epilepsy',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label:
                    '2.6 Anxiety or depressive states, epilepsy or black outs',
                  uid: '13f3db61ead4e5ae',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'diabetes',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: '2.7 Diabetes, sugar in urine or glandular disorder',
                  uid: 'ea1d64de1f146aad',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'cancer-or-tumors',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label:
                    '2.8 Cancer, tumors, lumps, swelling or blood disorders e.g. raised cholesterol',
                  uid: '1c4f7caee71fc9e4',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'arthritis-osteoporosis',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: '2.9 Rheumatoid Arthritis, Osteoporosis.',
                  uid: '08ada2b4de6c194a',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
            {
              id: 'question-three',
              type: 'list',
              readonly: false,
              label: 'Question Three',
              uid: '16617e3f1a55f56f',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '32a5c5fe93e9171c',
                  content:
                    "<ol start='3'>\n<li>Have you ever been tested or received medical advice, counselling of treatment in connection with:</li>\n</ol>\n",
                },
                {
                  id: 'aids',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: '3.1 AIDS or infection by one of the AIDS viruses?',
                  uid: '8e0e5879b6eda8cc',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'sexually-transmitted-disease',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label:
                    '3.2 Any sexually transmitted disease, eg. hepatitis B, Gonorhea, Syphylis, Penile or Vaginal discharge?',
                  uid: '5eec406c620554d1',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
            {
              id: 'question-four',
              type: 'list',
              readonly: false,
              label: 'Question Four',
              uid: 'eec8ea9e4d4dfbc4',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ebff9930b45a1462',
                  content:
                    "<ol start='4'>\n<li>Are you currently taking any drugs or prescribed medicines?</li>\n</ol>\n",
                },
                {
                  id: 'taking-medication',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Taking Medication',
                  uid: 'f5358a4160b2062f',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
              ],
            },
            {
              id: 'question-five',
              type: 'list',
              readonly: false,
              label: 'Question Five',
              uid: 'e19ee7feb56d908b',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '3c1d81ef9cf2c442',
                  content:
                    "<ol start='5'>\n<li>Do you have any form of disability, eg: loss of use of any limb, impaired sight or hearing</li>\n</ol>\n",
                },
                {
                  id: 'disability',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Disability',
                  uid: '8fa8d9d3c46c0c1f',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
              ],
            },
            {
              id: 'question-six',
              type: 'list',
              readonly: false,
              label: 'Question Six',
              uid: 'a9e372de58df83b4',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '81503d816f4154e3',
                  content:
                    "<ol start='6'>\n<li>Are you aware of any other circumstances which may influence the risk of an assurance on your life, eg; persistent fever or skin disorders, unexplained weight loss, recurrent diarrhoea or bleeding from the bowels, night sweats?</li>\n</ol>\n",
                },
                {
                  id: 'assurance-risk',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Assurance Risk',
                  uid: '606253c0eb49f0cb',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
              ],
            },
            {
              id: 'question-seven',
              type: 'list',
              readonly: false,
              label: 'Question Seven',
              uid: 'a311984390ff1a10',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '37d256f0922ba845',
                  content:
                    "<ol start='7'>\n<li>Are you or do you suspect that you could be pregnant? If so, when was your last menstrual period?</li>\n</ol>\n",
                },
                {
                  id: 'pregnant',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Pregnant',
                  uid: 'dc8406e52200a236',
                  content: 'Yes',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
              ],
            },
            {
              id: 'question-eight',
              type: 'list',
              readonly: false,
              label: 'Question Eight',
              uid: '548e2228eef099f4',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '51d68fb6149ff895',
                  content:
                    "<ol start='8'>\n<li>Family medical history</li>\n</ol>\n",
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '6ec660a365141b38',
                  content:
                    '<p>8.1 Is there a history in your family of diabetes, raised cholesterol, heart disease, stroke, high blood pressure, nervous or mental disorder, cancer, retinitis pigmentosa, porphyria, haemophillia or any other hereditary disease?</p>\n',
                },
                {
                  id: 'family-history-of-illness',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Family History Of Illness',
                  uid: '3a44474cd2576c11',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
                {
                  content: '',
                  elementType: 'input',
                  id: 'family-history-of-illness-details',
                  inputType: 'text',
                  label: 'Details',
                  publishChange: true,
                  readonly: false,
                  type: 'field',
                  uid: '9a5a65cc9d9c54d5',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '35b6853f4faf1bef',
                  content:
                    '<p>8.2 Has your father, mother, or any of your brothers or sisters died under the age of 55?</p>\n',
                },
                {
                  id: 'close-family-early-death',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Close Family Early Death',
                  uid: '1fe6bf23b71f4f8c',
                  content: 'Yes',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
              ],
            },
            {
              id: 'question-nine',
              type: 'list',
              readonly: false,
              label: 'Question Nine',
              uid: '5c716160f8d2a934',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '98a42ab04293f154',
                  content:
                    "<ol start='9'>\n<li>Your measurements</li>\n</ol>\n",
                },
                {
                  id: 'height-in-cm',
                  type: 'real',
                  elementType: 'input',
                  readonly: false,
                  label: 'Height cm',
                  uid: 'c19e6e7c25bd76a2',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'number',
                },
                {
                  id: 'weight-in-kg',
                  type: 'real',
                  elementType: 'input',
                  readonly: false,
                  label: 'Mass (weight in kg)',
                  uid: '501c2e65b0823d4e',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'number',
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '573d93762b363100',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '394e898bcef7956d',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'fab370c350a9bf82',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '40d2ef572e7eb5be',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'd3b718fa91ff280e',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Policyholder Medical Details ',
    uri: 'fipp-enhanced/policyholder-medical-details',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-policyholder-medical-details-',
  },
  'health-descriptions': {
    text: [],
    buttons: [
      { label: 'Next', id: 'next', uid: '26ca340069ec84e5', control: true },
      { label: 'Back', id: 'back', uid: '1303e2b2da021d46', control: true },
      { label: 'Cancel', id: 'cancel', uid: 'e25c3188a733209e', control: true },
    ],
    item: {
      id: 'health-descriptions',
      type: 'list',
      readonly: false,
      label: 'Health Descriptions',
      uid: 'fed88f569f729c85',
      content: [],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Policyholder Medical Details',
    uri: 'fipp-enhanced/policyholder-medical-details',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-policyholder-medical-details-',
  },
  'payment-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: 'bbc5f454e902d618',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'payment-method',
          type: 'list',
          readonly: false,
          label: 'Payment Method',
          uid: '935b4319fdcbab12',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'b3820d390c57590c',
              content: '<p>Choose a payment method</p>\n',
            },
            {
              id: 'payment-method-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payment Method Radio Group',
              uid: '5f29ef713684dcdd',
              content: [
                {
                  id: 'debit-order',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Debit Order',
                  uid: '66026bce0175bd85',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'stop-order',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Stop Order',
                  uid: 'addc4bc1f4c832eb',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
              ],
              errors: ['*'],
            },
          ],
        },
        {
          id: 'payment-commencement',
          type: 'list',
          readonly: false,
          label: 'Payment Commencement',
          uid: '37d93c65a94795a7',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'a50540585ca12c07',
              content:
                '<p>Choose the payment start date and your payment frequency</p>\n',
            },
            {
              id: 'payment-start-date',
              type: 'integer',
              elementType: 'select',
              readonly: false,
              label: 'Payment Start Date',
              uid: 'cd4537bfa3fb5f7d',
              content: '20220301',
              publishChange: true,
              options: ['', '20220301', '20220401', '20220501'],
            },
            {
              id: 'payment-frequency-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payment Frequency Radio Group',
              uid: 'b57e2178f2c6665f',
              content: [
                {
                  id: 'monthly',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Monthly',
                  uid: '3f6c19ccd48cbeeb',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'quarterly',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Quarterly',
                  uid: '3085ba131761cdaa',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'annual',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Annual',
                  uid: 'a4ebf2837b1d7e08',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'bi-annual',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Bi Annual',
                  uid: 'b31610ed69249641',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
          ],
        },
        {
          id: 'payer-person',
          type: 'list',
          readonly: false,
          label: 'Payer Person',
          uid: 'ce35234c9c489acc',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'ee7c49bb35030b57',
              content: '<p>Who will be paying for this policy?</p>\n',
            },
            {
              id: 'payer-person-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payer Person Radio Group',
              uid: '2c1019491493ebab',
              content: [
                {
                  id: 'myself',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Myself',
                  uid: '4d7d4200a4fd7585',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'my-spouse',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'My Spouse',
                  uid: '6198ae13e0925e88',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'ef0a41b9029aba55',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '4c0dc1e33a3e5341',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'c587b63a7b18b658',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '2aea10aeb7915580',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'fb3a74d8b4c60af2',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Payment Details',
    uri: 'fipp-enhanced/quote',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-payment-details',
  },
  'debit-order-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: '59548e8b8cdc6a7b',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'debit-order-details',
          type: 'list',
          readonly: false,
          label: 'Debit Order Details',
          uid: 'd3c9a331e021ed0b',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '82bc4d510e05f52c',
              content: '<p>Complete the following details</p>\n',
            },
            {
              id: 'new-account-details',
              type: 'list',
              readonly: false,
              label: 'New Account Details',
              uid: 'ab888ee5c456cdea',
              content: [
                {
                  id: 'account-holder-name',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Account Holder Name',
                  uid: '039eb80e4a10de79',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'account-type',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Account Type',
                  uid: 'fbdc797b84a0f751',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['', 'Current', 'Savings'],
                },
                {
                  id: 'account-entity-name',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Account Entity Name',
                  uid: '41ac2dfe1beb4c8d',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    '',
                    'Absa (gh) Ltd',
                    'Access Bank Ghana Ltd',
                    'Agricultural Development Bank',
                    'Bank Of Africa',
                    'Cal Bank Ltd',
                    'Consolidated Bank Ghana',
                    'Ecobank Ghana Ltd',
                    'Fidelity Bank Ghana',
                    'First Atlantic Bank Ltd',
                    'First Bank Nigeria',
                    'Gcb Bank Ltd',
                    'Guaranty Trust (gh) Ltd',
                    'National Investment Bank',
                    'Omni Bank Ghana Ltd',
                    'Prudential Bank Ltd',
                    'Republic Bank (gh) Ltd',
                    'Societe General Ghana Ltd',
                    'Stanbic Bank Ghana Ltd',
                    'Standard Chartered Bank',
                    'United Bank For Africa (gh) Ltd',
                    'Universal Merchant Bank (gh) Ltd',
                    'Zenith Bank',
                  ],
                },
                {
                  content: '',
                  elementType: 'select',
                  errors: ['*'],
                  id: 'account-branch-name',
                  label: 'Account Branch Name',
                  options: [
                    '',
                    '1ST LIGHT',
                    '31ST DECEMBER MARKET',
                    'A & C MALL',
                    'A & C SHOPPING MAIL',
                    'A AND C',
                    'A AND C MALL',
                    'A COMMUNITY BANK LTD-LA',
                    'A RURAL BANK LTD-AMASAMAN',
                    'A&C MALL',
                    'AA ASANTEWAA RURAL BANK LTD',
                    'AARA RURAL BANK LTD-PAGA',
                    'AASEMAN RURAL BANK LTD-KAASE',
                    'ABBOSEY OKAI',
                    'ABBOSEY OKAI BRANCH',
                    'ABEKA',
                    'ABEKA BRANCH',
                    'ABEKA LAPAZ',
                    'ABEKA LAPAZ BRANCH',
                    'ABEKALAPAZ',
                    'ABELENKPE',
                    'ABETIFI',
                    'ABETIFI BRANCH',
                    'ABII NATIONAL SAVINGS AND LOANS LTD',
                    'ABN-50129-TEMA COMMUNITY 9',
                    'ABOABO',
                    'ABOABO MARKET TAMALE',
                    'ABOKOBI RURAL BANK LTD-ABOKOBI',
                    'ABOR',
                    'ABORA',
                    'ABOSEY OKAI',
                    'ABOSSEY OKAI',
                    'ABOSSEY OKINE',
                    'ABREPO JUNCTION',
                    'ABREPO JUNCTION BRANCH',
                    'ABREPO MAIN',
                    'ABREPO MAIN BRANCH',
                    'ABUAKWA',
                    'ABUAKWA BRANCH',
                    'ABURA DUNKWA',
                    'ABURI',
                    'ABZUGU RURAL BANK LTD',
                    'ACCCRA NEWTOWN',
                    'ACCION',
                    'ACCRA',
                    'ACCRA & MAKOLA',
                    'ACCRA BRANCH',
                    'ACCRA CENTRAL',
                    'ACCRA CENTRAL POST',
                    'ACCRA CENTRAL POSTBANK',
                    'ACCRA CORPORATE SERVICE CENTRE',
                    'ACCRA MAIN',
                    'ACCRA MALL',
                    'ACCRA NEW TOWN',
                    'ACCRA SHOPPING MAIL',
                    'ACCRA SHOPPING MALL',
                    'ACHIASE',
                    'ACHIMOTA',
                    'ACHIMOTA BRANCH',
                    'ACHIMOTA MILE 7',
                    'ACTION CHAPEL',
                    'ADA FOAH',
                    'ADA RURAL BANK LTD-KASSEH',
                    'ADABOKROM',
                    'ADABRAKA',
                    'ADABRAKA BRANCH',
                    'ADANSI RURAL BANK LTD-FOMENA',
                    'ADAWSO',
                    'ADB HOUSE',
                    'ADB HOUSE BRANCH',
                    'ADEHYEMAN SAVINGS & LOAN',
                    'ADEHYEMAN SAVINGS AND LOANS',
                    'ADENTA',
                    'ADENTA BRANCH',
                    'ADENTA MARKET',
                    'ADENTAN',
                    'ADI HARBOUR',
                    'ADIAKIO WAYA',
                    'ADIDOME',
                    'ADJABENG',
                    'ADJIRINGANOR',
                    'ADONTEN COMMUNITY BANK LTD-NEW TAFO',
                    'ADUM',
                    'ADUM BRANCH',
                    'ADUM POST',
                    'ADUMAN RURAL BANK LTD-BADU',
                    'ADUM-KUMASI',
                    'AFANA RURAL BANK LTD-SAMPA',
                    'AFFUL NKWANTA',
                    'AFFUL NKWANTA BRANCH',
                    'AFLAO',
                    'AFRAM RURAL BANK LTD-TEASE',
                    'AFRANSIE',
                    'AGAVE RURAL BANK LTD-DABALE',
                    'AGBOBLOSHIE',
                    'AGBOGBLOSHIE',
                    'AGBOGBOLSHIE',
                    'AGBOZUME',
                    'AGOGO',
                    'AGONA ABREM',
                    'AGONA AHANTA',
                    'AGONA ASHANTI',
                    'AGONA NKWANTA',
                    'AGONA RURAL BANK LTD-KWANYAKU',
                    'AGONA SWEDRU',
                    'AHAFO ANO PREMIER RURAL BANK LTD-WIOSO',
                    'AHAFO COMMUNITY RURAL BANK LTD-KUKUOM',
                    'AHANTAMAN RURAL BANK LTD-AGONA NKWANTA',
                    'AHINSAN KUMASI',
                    'AHODWO',
                    'AHODWO BRANCH',
                    'AIRPORT',
                    'AIRPORT BRANCH',
                    'AIRPORT CITY',
                    'AIRPORT CITY BRANCH',
                    'AIRPORT EAST',
                    'AIYINASE',
                    'AJUMAKO',
                    'AKASTI',
                    'AKATAKYIMAN RURAL BANK LTD-KOMENDA',
                    'AKATSI',
                    'AKIM ABENASE',
                    'AKIM ADUASA',
                    'AKIM BOSOME RURAL BANK LTD-AKIM SWEDRU',
                    'AKIM ODA',
                    'AKIM SWEDRU',
                    'AKOFO HOUSE',
                    'AKONTOMBRA',
                    'AKOSOMBO',
                    'AKOSOMBO BRANCH',
                    'AKOTI RURAL BANK LTD-ASSIN AKROPONG',
                    'AKROFUOM AREA RURAL BANK LTD-AKROFUOM',
                    'AKROPONG',
                    'AKROPONG AKWAPIM',
                    'AKROSO',
                    'AKUAPEM RURAL BANK LTD-MAMFE',
                    'AKUM RURAL BANK LTD-ELMINA',
                    'AKUMADAN',
                    'AKUSE',
                    'AKWADUM',
                    'AKWATIA',
                    'AKWETEYMAN',
                    'AKYEM MANSA RURAL BANK LTD-AYIREBI',
                    'AKYEPIM RURAL BANK LTD-GOMOA-DAW',
                    'ALABAR',
                    'ALABAR BRANCH',
                    'ALPHA CAPITAL SAVING&L LTD',
                    'AMAKOM',
                    'AMAKOM BRANCH',
                    'AMANANO RURAL BANK LTD-NYINAHIM',
                    'AMANTEN',
                    'AMASAMAN',
                    'AMASIE WEST RURAL BANK LTD-ANTOAKROM',
                    'AMATIN AND KASEI COMMUNITY BANK LTD-KASEI',
                    'AMENFIMAN RURAL BANK LTD-WASSA AKROPONG',
                    'AMERICAN HOUSE BRANCH',
                    'AMFIE RURAL BANK LTD-WAMFIE',
                    'ANDOM RURAL BANK LTD-NANDOM',
                    'ANGBE RURAL BANK LTD-PRAMPRAM',
                    'ANKOBRA WEST (ESIAMA) RURAL BANK LTD-ESIAMA',
                    'ANLO RURAL BANK LTD-ANLOGA',
                    'ANLOGA',
                    'ANSOMAN RURAL BANK LTD-MANSO-AMENFI',
                    'ANTEAKWA RURAL BANK LTD-KOFORIDUA',
                    'ANUM APAPAM',
                    'ANUM RURAL BANK LTD-ANUM',
                    'ANYA KROBO RURAL BANK LTD-ODUMASE-KROBO',
                    'ANYAA',
                    'ANYAA MARKET BRANCH',
                    'ANYINAM',
                    'APAM',
                    'APENKWA',
                    'APENKWA BRANCH',
                    'APEX BANK - BOLGA CENTRE-BOLGATANGA',
                    'APEX BANK - CAPE COASTCAPE COAST',
                    'APEX BANK - TAMALE CENTRE-TAMALE',
                    'APEX BANK -HEAD OFFICE-SOUTH RIDGE ACCRA',
                    'APEX BANK -HOHOE CENTRE-HOHOE',
                    'APEX BANK KOFORIDUA CENTRE-KOFORIDUA',
                    'APEX BANK -SUNYANI CENTRE-SUNYANI',
                    'APEX BANK -WA CENTRE-WA',
                    'APEX BANKACCRA CENTRE-ACCRA',
                    'APEX BANK-HO CENTRE-HO',
                    'APEX BANK-KUMASIKUMASI',
                    'APEX BANK-TAKORADI CENTRE-TAKORADI',
                    'APEX LINK CENTRE-CEDI HOUSE',
                    'APITAL RURAL BANK LTD-ABESIM',
                    'APLAKU',
                    'APRA RURAL BANK LTD-PRANG',
                    'ARENA BRANCH',
                    'ASAFO',
                    'ASAFO BRANCH',
                    'ASAFO MARKET',
                    'ASAMANKESE',
                    'ASANKRAGUA',
                    'ASANKRAGWA',
                    'ASANTE AKYEM RURAL BANK LTD-JUANSA',
                    'ASAWINSO BRANCH',
                    'ASAWINSO RURAL BANK LTD-SEFWI-ASAWINSO',
                    'ASEBU',
                    'ASEMKROM',
                    'ASEMPANEYE',
                    'ASH TOWN',
                    'ASHAIMAN',
                    'ASHAIMAN BRANCH',
                    'ASHAIMAN LEBABNON',
                    'ASHAIMAN MAIN BRANCH',
                    'ASHAIMAN ROUND ABOUT BRANCH',
                    'ASHALLEY BOTWE BRANCH',
                    'ASHANTI BEKWAI',
                    'ASHANTI MAMPONG',
                    'ASHIAMAN',
                    'ASHIYEA BRANCH',
                    'ASHTOWN',
                    'ASHTOWN BRANCH',
                    'ASHTOWN EAST',
                    'ASHTOWN EAST BRANCH',
                    'ASIAKWA',
                    'ASIKUMA',
                    'ASOKORE RURAL BANK LTD-ASOKRE',
                    'ASOKWA',
                    'ASOKWA BRANCH',
                    'ASSIN FOSO',
                    'ASSIN FOSU',
                    'ASSIN FOSU POST',
                    'ASSINMAN RURAL BANK LTD-ASSIN MANSO',
                    'AST MANPRUSI COMMUNITY BABK LTD-GAMBAGA',
                    'ASTERN GOMOA ASSIN RURAL BANK LTD-GOMOA-DOMINASE',
                    'ASUBONTEN RURAL BANK LTD-WORAWORA',
                    'ASUOGYAMAN RURAL BANK LTD-ACCRA',
                    'ASUOPRA RURAL BANK LTD-AFOSU',
                    'ASUTIFI RURAL BANK LTD-ACERENSUA',
                    'ASUTUARE',
                    'ASYLUM DOWN',
                    'ATAABADZE',
                    'ATEBUBU',
                    'ATIWA RURAL BANK',
                    'ATIWA RURAL BANK LTD-KWABENG',
                    'ATOMIC BRANCH',
                    'ATOMIC JUNCTION BRANCH',
                    'ATONSU',
                    'ATONSU BRANCH',
                    'ATWEABAN RURAL BANK LTD-SUNYANI',
                    'ATWIMA KWANWOMA RURAL BANK LTD-PAKYI NO. 2',
                    'ATWIMA MPONUA RURAL BANK LTD-TOASE',
                    'ATWIMA RURAL BANK LTD-FOASE',
                    'AVENOR RURAL BANK LTD-AKATSI',
                    'AVENUE CENTRAL',
                    'AWASO',
                    'AWJIASE AREA RURAL BANK LTD-AWUTU BAWJIASE',
                    'AWRA RURAL BANK LTD-WA',
                    'AWUTU',
                    'AWUTU EMASA RURAL BANK LTD-AWUMASA',
                    'AXIM',
                    'AXIM ROAD BRANCH',
                    'AYANFURI',
                    'AYETEPA',
                    'AYIREBI',
                    'AYOMSO',
                    'BAATSONA',
                    'BAATSONA BRANCH',
                    'BADUKROM',
                    'BADUMAN RURAL BANK LTD-BADU',
                    'BAMAHU',
                    'BANDA NKWANTA',
                    'BANTAMA',
                    'BANTAMA 1',
                    'BANTAMA BRANCH',
                    'BANTAMA GNTC BRANCH',
                    'BASYONDE',
                    'BATSONA BRANCH',
                    'BAWALESHIE',
                    'BAWDJIASE',
                    'BAWJIASE AREA RURAL BANK LTD-AWUTU BAWJIASE',
                    'BAWKU',
                    'BAWKU BRANCH',
                    'BCM',
                    'BECHEM',
                    'BEGORO',
                    'BEIGE CAPITAL',
                    'BEKWAI',
                    'BEPOSO',
                    'BEREKUM',
                    'BESEASE',
                    'BESSFA RURAL BANK LTD-GARU',
                    'BESTPOINT SAVINGS AND LOANS',
                    'BEYIN',
                    'BI RURAL BANK LTD-HOHOE',
                    'BIA DEBISO',
                    'BIA-TORYA RURAL BANK LTD-BONSU-NKWANTA',
                    'BIBIANI',
                    'BIMBILLA',
                    'BINABA',
                    'BIRIWA',
                    'BOGOSO',
                    'BOGOSU',
                    'BOINSO',
                    'BOKOBI RURAL BANK LTD-ABOKOBI',
                    'BOLE',
                    'BOLE BRANCH',
                    'BOLGA COMMERCIAL ROAD BRANCH',
                    'BOLGA POLICE STATION ROAD BRANCH',
                    'BOLGA POST',
                    'BOLGA POSTBANK',
                    'BOLGATANGA',
                    'BOMAA AREA RURAL BANK LTD-BOMAA',
                    'BOMOSADU RURAL BANK LTD',
                    'BOND SAVINGS AND LOANS',
                    'BONGO',
                    'BONGO RURAL BANK LTD-BOLGATANGA',
                    'BONSO NKWANTA',
                    'BONSU NKWANTA',
                    'BONTRASE',
                    'BONZALI RURAL BANK LTD-KUMBUNGU',
                    'BORIMANGA RURAL BANK LTD-SAVELEGU',
                    'BOSOME FREHO RURAL BANK LTD-KUMASI',
                    'BOSOMTWI RURAL BANK LTD-KUNTANASE',
                    'BOUNDARY ROAD',
                    'BRAKWA BRAMAN RURAL BANK LTD-BRAKWA',
                    'BREEZE EAST LEGON',
                    'BREMAN ASIKUMA',
                    'BREMAN UGC',
                    'BUI',
                    'BUILSA COMMUNITY BANK LTD-SANDEMA',
                    'BUIPE',
                    'BUNKPRUGU',
                    'BURMA CAMP',
                    'BURMA CAMP BRANCH',
                    'BUSIE',
                    'BUSUNYA',
                    'BUTAWU RURAL BANK LTD-HO',
                    'BUWUULONSO RURAL BANK LTD-TAMALE',
                    'CAPE COAST',
                    'CAPE COAST BRANCH',
                    'CAPE COAST- MELCOM',
                    'CAPE COAST- MFANTSIPIM',
                    'CAPECOAST BRANCH',
                    'CAPITAL RURAL BANK LTD-ABESIM',
                    'CASTLE RD',
                    'CASTLE ROAD',
                    'CASTLE ROAD (HEAD OFFICE)',
                    'CASTLE ROAD BRANCH',
                    'CEDI HOUSE',
                    'CHAMBA',
                    'CHAPEL SQUARE',
                    'CHARIA',
                    'CHEREPONI',
                    'CHIRAA',
                    'CHORKOR',
                    'CIRCLE',
                    'CIRCLE BRANCH',
                    'CITIZEN RURAL BANK LTD-ACCRA',
                    'CLEARING UNIT',
                    'COLLINS AVENUE',
                    'COLLINS STREET',
                    'COMMUNITY 25',
                    'COMMUNITY 6',
                    'COMMUNITY1 BRANCH',
                    'CONSUMER FINANCE COMPANY (CFC) LTD',
                    'CORPORATE',
                    'CORPORATE SERVICE CENTRE',
                    'CUC MIOTSO',
                    'DA RURAL BANK LTD-KASSEH',
                    'DABALA',
                    'DABAN',
                    'DABOASE',
                    'DADIESO',
                    'DAMANG',
                    'DAMANGO',
                    'DAMBAI',
                    'DAMONGO',
                    'DANGBE RURAL BANK LTD-PRAMPRAM',
                    'DANICOM HOUSE(KOKOMLEMLE)',
                    'DANQUAH CIRCLE',
                    'DANSI RURAL BANK LTD-FOMENA',
                    'DANSOMAN',
                    'DANSOMAN EXHIBITION BRANCH',
                    'DANSOMAN ROUND-ABOUT BRANCH',
                    'DARKUMAN',
                    'DARKUMAN BRANCH',
                    'DAWA',
                    'DENKYIRAMAN RURAL BANK LTD-AYAMFURI',
                    'DENU',
                    'DERBY AVENUE',
                    'DERMA',
                    'DERMA RURAL BANK LTD-DERMA',
                    'DIRECT SALES',
                    'DODOWA',
                    'DOME',
                    'DOME BRANCH',
                    'DOME ST. JOHNS',
                    'DONKORKROM',
                    'DONTEN COMMUNITY BANK LTD-NEW TAFO',
                    'DORMAA AHENKRO',
                    'DOTOBRI RURAL BANK LTD-JACOBU',
                    'DROBO',
                    'DROBO COMMUNITY RURAL BANK LTD-DROBO',
                    'DRORWULU',
                    'DUADASO',
                    'DUAYAW NKANTA',
                    'DUAYAW NKWANTA',
                    'DUMPONG RURAL BANK LTD-OFRAMASE',
                    'DUNKWA',
                    'DUNKWA OFFIN',
                    'DUNKWA ON-OFFIN',
                    'DUNKWA-ON-OFFIN',
                    'DUNKWAW-ON-OFFIN BRANCH',
                    'DUPONGKPEHE RURAL BANK LTD-KASOA',
                    'DWEN-ANOMA RURAL BANK LTD-KWEEHWEE',
                    'DWORWULU WEST AIRPORT',
                    'DZEMENI',
                    'DZODZE',
                    'DZORWULU',
                    'DZORWULU BRANCH',
                    'DZORWULU MOTORWAY',
                    'DZWORWULU',
                    'EAST AIRPORT BRANCH',
                    'EAST CANTONMENT',
                    'EAST CANTONMENTS BRANCH',
                    'EAST LEGON',
                    'EAST LEGON 1',
                    'EAST LEGON 2',
                    'EAST LEGON BRANCH',
                    'EAST MANPRUSI COMMUNITY BABK LTD-GAMBAGA',
                    'EASTERN GOMOA ASSIN RURAL BANK LTD-GOMOA-DOMINASE',
                    'EBANKESE',
                    'ECOBANK BANTAMA GNTC',
                    'ECOBANK COMMUNITY 1',
                    'ECOBANK E PROCESS',
                    'ECOBANK EAST AIRPORT',
                    'ECOBANK EVANDY HOSTEL',
                    'ECOBANK GH TAMALE',
                    'ECOBANK GHANA',
                    'ECOBANK HIGH STREET',
                    'ECOBANK HOSPITAL ROAD',
                    'ECOBANK KANTAMANTO',
                    'ECOBANK MADINA CENTRAL',
                    'ECOBANK OKPONGLO',
                    'ECOBANK PENTAGON LEGON',
                    'ECOBANK SSNIT HOUSE',
                    'ECOBANK SUAME',
                    'EFFIDUASE',
                    'EFWIMAN RURAL BANK LTD-SEFWI-ASAW',
                    'EJISU',
                    'EJISU BRANCH',
                    'EJURA',
                    'EJURAMAN RURAL BANK LTD-KUMASI',
                    'EKUMFIMAN RURAL BANK LTD-ESSUEHYIA',
                    'EKYEDUMASI RURAL BANK LTD-SEKYEDUMASI',
                    'EKYERE RURAL BANK LTD-JAMASI',
                    'ELMINA',
                    'ELMINA - CHAPEL SQUARE',
                    'ELMINA -ESSUAKYIR',
                    'ELUBO',
                    'ELUBU',
                    'ENCHI',
                    'ENCHI BRANCH',
                    'ENCHI RURAL BANK LTD',
                    'ENKYIRAMAN RURAL BANK LTD-AYAMFURI',
                    'ENYAN DENKYIRA RURAL BANK LTD-ENYAN DENKYIRA',
                    'EPAMAN RURAL BANK LTD - TEPAMAN',
                    'EPE AREA RURAL BANK LTD-MEPE',
                    'ERMA RURAL BANK LTD-DERMA',
                    'ESIAMA',
                    'ESSAM',
                    'ESSFA RURAL BANK LTD-GARU',
                    'EST MANPRUSI COMMUNITY BANK LTD-WALEWALE',
                    'ESTERN RURAL BANK LTD-WASSA AKROPONG',
                    'ETO RURAL BANK LTD-KPEVE',
                    'EVANDY HOSTEL BRANCH',
                    'EZI SAVINGS AND LOANS LTD',
                    'FAANOFA',
                    'FAMAL',
                    'FANTEAKWA RURAL BANK LTD-KOFORIDUA',
                    'FANTSIMAN COMMUNITY RURAL BANK LTD-BIRIWA',
                    'FARRAR',
                    'FFINSO RURAL BANK LTD-KUMASI',
                    'FIAGYA RURAL BANK LTD-BUSUNYA',
                    'FIAPRE',
                    'FIASEMAN (BOGOSO AREA) RURAL BANK LTD-BOGOSO',
                    'FIRST AFRICAN SAVINGS AND LOANS',
                    'FIRST ALLIED SAVINGS & LOANS',
                    'FIRST ALLIED SAVINGS AND LOANS',
                    'FIRST GHANA BUILDING SOCIETY- ACCRA',
                    'FIRST GHANA BUILDING SOCIETY- KOFORIDUA',
                    'FIRST GHANA BUILDING SOCIETY- KUMASI AMAKOM',
                    'FIRST GHANA BUILDING SOCIETY- KUMASI SUAME',
                    'FIRST GHANA BUILDING SOCIETY- SUNYANI',
                    'FIRST GHANA BUILDING SOCIETY- TAKORADI',
                    'FIRST GHANA BUILDING SOCIETY-HEAD OFFICE',
                    'FIRST GHANA BUILDING SOCIETY-HOHOE',
                    'FIRST GHANA BUILDING SOCIETY-TAKORADI',
                    'FIRST GHANA BUILDING SOCIETY-TEMA',
                    'FIRST NATATIONAL S&L',
                    'FIRST NATIONAL SAVINGS & LOANS',
                    'FOASE',
                    'FOREIGN',
                    'FOREIGN OPERATIONS',
                    'FOREIGN OPERATIONS BRANCH',
                    'FRAM RURAL BANK LTD-TEASE',
                    'FULFOSO',
                    'FUMBISI',
                    'GA RURAL BANK LTD-AMASAMAN',
                    'GAMASHIE',
                    'GARU',
                    'GAVE RURAL BANK LTD-DABALE',
                    'GBI RURAL BANK LTD-HOHOE',
                    'GICEL',
                    'GIMPA',
                    'GIMPA BRANCH',
                    'GLOBAL ACCESS SAVINGS & LOANS CO. LTD',
                    'GNPC',
                    'GNPC TEMA',
                    'GOASO',
                    'GOLDEN TULIP KUMASI BRANCH',
                    'GOLOKWATI',
                    'GOMOA AJUMAKO RURAL BANK LTD-AFRANSI',
                    'GOMOA RURAL BANK LTD-APAM',
                    'GONA RURAL BANK LTD-KWANYAKU',
                    'GRAPHIC ROAD',
                    'GREL',
                    'GULF HOUSE',
                    'GUMANI',
                    'GUSHIEGU',
                    'GWB LAPAZ',
                    'HAATSO',
                    'HAATSOO',
                    'HAFO ANO PREMIER RURAL BANK LTD-WIOSO',
                    'HAFO COMMUNITY RURAL BANK LTD-KUKUOM',
                    'HAI RURAL BANK LTD-DODOWA',
                    'HALF ASSINI',
                    'HAMILE',
                    'HANTAMAN RURAL BANK LTD-AGONA NKWANTA',
                    'HAPER ROAD',
                    'HARBOUR AREA',
                    'HARPER ROAD',
                    'HARPER ROAD BRANCH',
                    'HE SEED SAVINGS AND LOANS',
                    'HEAD OFFICE',
                    'HEAD-OFFICE',
                    'HIGH STREET',
                    'HIGH STREET BRANCH',
                    'HIGH STREET TAKORADI',
                    'HIGH STREET-TAKORADI',
                    'HO',
                    'HO AGENCY',
                    'HO BRANCH',
                    'HO MARKET',
                    'HO POLYTECHNIC',
                    'HO POST',
                    'HO POSTBANK',
                    'HOEHOE',
                    'HOHOE',
                    'HOHOE POST',
                    'HOHOE POSTBANK',
                    'HOSPITAL ROAD BRANCH',
                    'HWEDIEM',
                    'HWIDIEM',
                    'HWIDIEM[ B/A]',
                    'IAGYA RURAL BANK LTD-BUSUNYA',
                    'IASEMAN (BOGOSO AREA) RURAL BANK LTD-BOGOSO',
                    'IA-TORYA RURAL BANK LTD-BONSU-NKWANTA',
                    'ICROFIN RURAL BANK LIMITED',
                    'INDEPENDENCE AVENUE',
                    'INDUSTRIAL AREA',
                    'INTAMPO RURAL BANK LTD-KINTAMPO',
                    'INTERNATIONAL BANKING',
                    'IPS',
                    'IPS BRANCH',
                    'IPS LEGON',
                    'IRST GHANA BUILDING SOCIETY- ACCRA',
                    'IRST GHANA BUILDING SOCIETY- KOFORIDUA',
                    'IRST GHANA BUILDING SOCIETY- KUMASI AMAKOM',
                    'IRST GHANA BUILDING SOCIETY- KUMASI SUAME',
                    'IRST GHANA BUILDING SOCIETY- SUNYANI',
                    'IRST GHANA BUILDING SOCIETY- TAKORADI',
                    'IRST GHANA BUILDING SOCIETY-HEAD OFFICE',
                    'IRST GHANA BUILDING SOCIETY-HOHOE',
                    'IRST GHANA BUILDING SOCIETY-TAKORADI',
                    'IRST GHANA BUILDING SOCIETY-TEMA',
                    'ISSALA RURAL BANK LTD-TUMA',
                    'ITIZEN RURAL BANK LTD-ACCRA',
                    'IZAA RURAL BANK LTD-TAMALE',
                    'JACOBU',
                    'JANYAGU',
                    'JAPEKROM',
                    'JAPEKROM/DROBO',
                    'JASIKAN',
                    'JEMA',
                    'JINIJIN',
                    'JIRAPA',
                    'JISONAYILI',
                    'JOMORO RURAL BANK LTD-TIKOBO NO. 1',
                    'JUABEN RURAL BANK LTD-JUABEN',
                    'JUABESO',
                    'JUAPONG',
                    'JUASO',
                    'JUBILEE HOUSE',
                    'JUBILEE HOUSE COCOBOD BUILDING',
                    'JUKWA',
                    'JUNCTION MALL',
                    'JUNCTION MALL BRANCH',
                    'JURAMAN RURAL BANK LTD-KUMASI',
                    'KAASE',
                    'KAASEMAN RURAL BANK LTD-KAASE',
                    'KADE',
                    'KADJEBI',
                    'KAJEBI',
                    'KAKUM RURAL BANK LTD-ELMINA',
                    'KALADAN',
                    'KANASHIE',
                    'KANESHIE',
                    'KANESHIE BRANCH',
                    'KANESHIE INDUSTRIAL AREA',
                    'KANESHIE MAIN',
                    'KANESHIE MARKET',
                    'KANESHIE POST OFFICE',
                    'KANESHIE POST OFFICE BRANCH',
                    'KANESSHIE',
                    'KANTAMANTO',
                    'KANTAMANTO AGENCY',
                    'KANTAMANTO BRANCH',
                    'KARAGA',
                    'KASAPIN',
                    'KASOA',
                    'KASOA BRANCH',
                    'KASOA MAIN',
                    'KASOA MAIN BRANCH',
                    'KASOA MARKET',
                    'KASOA MILLENIUM CITY',
                    'KASOA NEW MARKET',
                    'KATAKYIMAN RURAL BANK LTD-KOMENDA',
                    'KEJETIA',
                    'KEJETIA BRANCH',
                    'KEJETIA- KUMASI',
                    'KENYASE',
                    'KENYASI AGENCY',
                    'KETA',
                    'KETE KRACHI',
                    'KIBI',
                    'KIM BOSOME RURAL BANK LTD-AKIM SWEDRU',
                    'KINTAMPO',
                    'KINTAMPO RURAL BANK LTD-KINTAMPO',
                    'KISSEIMAN BRANCH',
                    'KISSI',
                    'KISSIEMAN',
                    'KNUST',
                    'KNUST BRANCH',
                    'KNUST CAMPUS',
                    'KNUST KUMASI',
                    'KNUTSFORD',
                    'KNUTSFORD AVENUE',
                    'KOFORIDUA',
                    'KOFORIDUA BRANCH',
                    'KOFORIDUA KAMA CENTRE',
                    'KOJO THOMPSON ROAD',
                    'KOKOMLEMLE BRANCH',
                    'KOMENDA',
                    'KOMFO ANOKYE RURAL BANK LTD-WIAMOASE',
                    'KOMLEMLE',
                    'KONGO',
                    'KONONGO',
                    'KORANMAN RURAL BANK LTD-SEIKWA',
                    'KORANZA KWABRE RURAL BANK LTD-AKUMA',
                    'KORKORDZOR',
                    'KORLE BU',
                    'KORLE DUDOR',
                    'KORLE DUDOR BRANCH',
                    'KORLE-BU',
                    'KORLE-BU BRANCH',
                    'KOTI RURAL BANK LTD-ASSIN AKROPONG',
                    'KOTOBABI',
                    'KPADIE',
                    'KPANDAI',
                    'KPANDO',
                    'KPANDU',
                    'KPASSA RURAL BANK LTD-HOHOE',
                    'KPEDZE',
                    'KPETOE',
                    'KPEVE',
                    'KPONE',
                    'KPONG',
                    'KRACHE NKWANTA',
                    'KRANKWANTA RURAL BANK LTD-SUNYANI',
                    'KROFOM',
                    'KROFROM',
                    'KROFUOM AREA RURAL BANK LTD-AKROFUOM',
                    'KRONUM BRANCH',
                    'KUAPEM RURAL BANK LTD-MAMFE',
                    'KUMASI',
                    'KUMASI (ALABAR)',
                    'KUMASI ADUM',
                    'KUMASI -ADUM',
                    'KUMASI AMAKOM',
                    'KUMASI ASAFO',
                    'KUMASI ASAFO BRANCH',
                    'KUMASI ASOKWA',
                    'KUMASI BRANCH',
                    'KUMASI CENT.',
                    'KUMASI CENTRAL',
                    'KUMASI CENTRAL MARKET',
                    'KUMASI HARPER ROAD',
                    'KUMASI MAIN',
                    'KUMASI PREMPEH 11 STREET',
                    'KUMASI PREMPEH STREET',
                    'KUMASI SUAME',
                    'KUMASI TANNOSO',
                    'KUMASI-LAKE  ROAD',
                    'KUMAWUMAN RURAL BANK LTD-KUMAWU',
                    'KUMFIMAN RURAL BANK LTD-ESSUEHYIA',
                    'KWABENYA',
                    'KWABENYA BRANCH',
                    'KWADASO',
                    'KWAEBIBIREM RURAL BANK LTD-ASUOM',
                    'KWAHU PRASO RURAL BANK LTD-KWAHU PRASO',
                    'KWAHU RURAL BANK LTD-KWAHU PEPEASE',
                    'KWAHUTAFO',
                    'KWAMANMAN RURAL BANK LTD-KWAMANG',
                    'KWAME DANSO BRANCH',
                    'KWAME NKRUMAH AVE',
                    'KWAME NKRUMAH CIRCLE',
                    'KWAPONG',
                    'KWASHIEMAN',
                    'KWASHIEMAN BRANCH',
                    'KYEKYEWERE',
                    'KYEM MANSA RURAL BANK LTD-AYIREBI',
                    'KYEPIM RURAL BANK LTD-GOMOA-DAW',
                    'LA',
                    'LA COMMUNITY BANK LTD-LA',
                    'LABONE',
                    'LABONE BRANCH',
                    'LAPAZ',
                    'LAPAZ BRANCH',
                    'LASHIBI',
                    'LASHIBI BRANCH',
                    'LAW COURT COMPLEX',
                    'LAW-COURT',
                    'LAWRA',
                    'LAWRA RURAL BANK LTD-WA',
                    'LEGON',
                    'LEGON MAIN',
                    'LIBERATION ROAD TAKORADI',
                    'LIBERATION ROAD TOKORADI',
                    'LIBERATION ROAD-TAKORADI',
                    'LIBERIA ROAD',
                    'LIBERTY HSE',
                    'LOTTERIES',
                    'LOWER PRA RURAL BANK LTD-SHAMA',
                    'MAAKRO BRANCH',
                    'MAAME KROBO',
                    'MAAMOBI',
                    'MACCARTHY HILL',
                    'MADINA',
                    'MADINA BRANCH',
                    'MADINA CENTRAL BRANCH',
                    'MADINA MAIN BRANCH',
                    'MADINA NEW ROAD BRANCH',
                    'MAKANGO',
                    'MAKOLA',
                    'MAKOLA BRANCH',
                    'MAKOLA SQUARE',
                    'MALLAM',
                    'MALLAM INTERCHANGE',
                    'MALLAM JUNCTION BRANCH',
                    'MAMFE AKUAPIM',
                    'MAMFE BRANCH',
                    'MAMOBI',
                    'MAMOBI BRANCH',
                    'MAMPONG',
                    'MAMPONG AKWAPIM',
                    'MAMPONG ASHANTI',
                    'MAMPROBI',
                    'MAMPROBI POST',
                    'MAMPROBI POSTBANK',
                    'MANANO RURAL BANK LTD-NYINAHIM',
                    'MANET TOWER 3 BRANCH',
                    'MANKESSIM',
                    'MANKESSIM OFFICE COMPLEX',
                    'MANKRASO',
                    'MANSO NKWANTA',
                    'MANSOMAN RURAL BANK LTD-MANSO-AMENFI',
                    'MANYA KROBO RURAL BANK LTD-ODUMASE-KROBO',
                    'MARKET CIRCLE BRANCH',
                    'MARKET CIRCLE TAKORADI',
                    'MASIE WEST RURAL BANK LTD-ANTOAKROM',
                    'MATAHEKO',
                    'MATIN AND KASEI COMMUNITY BANK LTD-KASEI',
                    'MAX MART',
                    'MCCARTHY HILL',
                    'MELCOM',
                    'MENFIMAN RURAL BANK LTD-WASSA AKROPONG',
                    'MEPE AREA RURAL BANK LTD-MEPE',
                    'MERIDIAN BRANCH',
                    'MFANTSIMAN COMMUNITY RURAL BANK LTD-BIRIWA',
                    'MFANTSIPIM',
                    'MICHEL CAMP',
                    'MICROFIN RURAL BANK LIMITED',
                    'MIDLAND SAVING AND LOANS',
                    'MIDLAND SAVINGS & LOANS LTD',
                    'MILE 7/TANTRA',
                    'MIM',
                    'MINISTRIES',
                    'MIOTSO',
                    'MOREE',
                    'MOTOR WAY ROUNDABOUT',
                    'MOTORWAY EXT',
                    'MOTORWAY EXTENSION',
                    'MOTORWAY ROUNDABOUT',
                    'MPATASIE',
                    'MPOHOR',
                    'MPONUA RURAL BANK LTD-AMUANA PRASO',
                    'MPRAESO',
                    'MULTI CREDIT SAVINGS AND LOANS - KUMASI',
                    'MULTICREDIT SAVINGS AND LOANS CO.',
                    'MUMFORD',
                    'MUMUADA RURAL BANK LTD-OSINO',
                    'NAARA RURAL BANK LTD-PAGA',
                    'NAFANA RURAL BANK LTD-SAMPA',
                    'NAGODE',
                    'NANDOM',
                    'NANDOM RURAL BANK LTD-NANDOM',
                    'NAVORONGO',
                    'NAVRONGO',
                    'NBFI-ADVANS GH',
                    'NCHIRAA',
                    'NCR',
                    'NEW ABEKA',
                    'NEW ABIREM',
                    'NEW ABIREM BRANCH',
                    'NEW ABRIEM',
                    'NEW ADUBIASE',
                    'NEW AMAKOM',
                    'NEW DORMAA KOTOKROM',
                    'NEW EDUBIASE',
                    'NEW NINGO',
                    'NEW OFFINSO',
                    'NEW SUAME MAGAZINE',
                    'NEW TAFO',
                    'NEW TOWN',
                    'NEWTOWN',
                    'NEWTOWN ACCRA',
                    'NHYIAESO BRANCH',
                    'NHYIEASO',
                    'NHYIESO',
                    'NIA',
                    'NIA BRANCH',
                    'NIMA',
                    'NIMA BRANCH',
                    'NIMA MAIN BRANCH',
                    'NINGO',
                    'NION RURAL BANK LTD-SENYA BREKU',
                    'NITY RURAL BANK LTD-ZIOPE',
                    'NKAWIE',
                    'NKAWKAW',
                    'NKOBRA WEST (ESIAMA) RURAL BANK LTD-ESIAMA',
                    'NKORANMAN RURAL BANK LTD-SEIKWA',
                    'NKORANSAH',
                    'NKORANZA',
                    'NKORANZA KWABRE RURAL BANK LTD-AKUMA',
                    'NKRANKWANTA',
                    'NKRANKWANTA RURAL BANK LTD-SUNYANI',
                    'NKWAKAW POST',
                    'NKWANTA',
                    'NLO RURAL BANK LTD-ANLOGA',
                    'NOBLE DREAM MICROFINANCE LTD',
                    'NORTH INDUSTRIAL',
                    'NORTH INDUSTRIAL AREA',
                    'NORTH INDUSTRIAL AREA BRANCH',
                    'NORTH KANESHIE',
                    'NORTH RIDGE',
                    'NORTH TONGU RURAL BANK LTD-ADIDOME',
                    'NORTH VOLTA RURAL BANK LTD-GUAMAN',
                    'NSAWAM',
                    'NSOATREMAN RURAL BANK LTD-NSOATRE',
                    'NSUATRE',
                    'NSUFA ASHANTI',
                    'NSUTAMAN RURAL BANK LTD-NSUTA',
                    'NTOROSO',
                    'NUM RURAL BANK LTD-ANUM',
                    'NUNGUA',
                    'NWABIAGYA RURAL BANK LTD-BAREKESE',
                    'NYAKROM RURAL BANK LTD-NYAKROM',
                    'NYAN DENKYIRA RURAL BANK LTD-ENYAN DENKYIRA',
                    'NYANKPALA',
                    'NYANKUMASE AHENKRO RURAL BANK LTD-FANTI NYANKUMASE',
                    'NYIAESO',
                    'NYIAESO AHODWO',
                    'NZEMA MANLE RURAL BANK LTD-AWIEBO',
                    'OBUASI',
                    'ODA',
                    'ODA NKWANTA',
                    'ODORKOR',
                    'ODOTOBRI RURAL BANK LTD-JACOBU',
                    'ODUPONGKPEHE RURAL BANK LTD-KASOA',
                    'ODWEN-ANOMA RURAL BANK LTD-KWEEHWEE',
                    'OENDE RURAL BANK LTD-ZIBILLA',
                    'OFFINSO',
                    'OFFINSO RURAL BANK LTD-KUMASI',
                    'OFFSHORE BANKING',
                    'OFFSHORE BANKING UNIT - GH',
                    'OKAISHIE',
                    'OKAISHIE BRANCH',
                    'OKOFO BRANCH',
                    'OKOMFO ANOKYE RURAL BANK LTD-WIAMOASE',
                    'OKPONGLO',
                    'OKPONGLO BRANCH',
                    'OLA',
                    'OLD NINGO',
                    'OLD SUAME MAGAZINE',
                    'OLD TAFO',
                    'OMAA AREA RURAL BANK LTD-BOMAA',
                    'OMOA AJUMAKO RURAL BANK LTD-AFRANSI',
                    'OMOA RURAL BANK LTD-APAM',
                    'OMORO RURAL BANK LTD-TIKOBO NO. 1',
                    'OMOSADU RURAL BANK LTD',
                    'ONGO RURAL BANK LTD-BOLGATANGA',
                    'ONZALI RURAL BANK LTD-KUMBUNGU',
                    'ONZELE RURAL BANK LTD-JIRAPA',
                    'OPEIBEA HOUSE',
                    'OPERA SQUARE',
                    'OPERA SQUARE BRANCH',
                    'OPPORTUNITY INT. SAVINGS AND LOAN',
                    'OPPORTUNITY INT. SAVINGS AND LOANS',
                    'OPPORTUNITY INTERNATIONAL',
                    'OREIGN',
                    'ORIMANGA RURAL BANK LTD-SAVELEGU',
                    'ORTH TONGU RURAL BANK LTD-ADIDOME',
                    'ORTH VOLTA RURAL BANK LTD-GUAMAN',
                    'OSEI KWADWO KROM',
                    'OSOME FREHO RURAL BANK LTD-KUMASI',
                    'OSOMTWI RURAL BANK LTD-KUNTANASE',
                    'OSU',
                    'OSU  OXFORD BRANCH',
                    'OSU - OXFORD STREET',
                    'OSU - WATSON STREET',
                    'OSU OXFORD ST.',
                    'OSU OXFORD STREET',
                    'OSU WATSON BRANCH',
                    'OTUASEKAN RURAL BANK LTD-KOFIASE',
                    'OUTH AKIM RURAL BANK LTD-NANKESE',
                    'OUTH BIRIM RURAL BANK LTD-ACHIASE',
                    'OWER PRA RURAL BANK LTD-SHAMA',
                    'OXFORD STREET BRANCH',
                    'OYIBI AREA RURAL BANK LIMITED',
                    'PACIFIC SAVINGS AND LOANS LTD',
                    'PAGA',
                    'PALM WINE JUNCTION',
                    'PALMWINE JUNCTION',
                    'PARLIAMENT HOUSE',
                    'PASSA RURAL BANK LTD-HOHOE',
                    'PATASE',
                    'PATRICE LUMUMBA ROAD',
                    'PEKI',
                    'PENTAGON LEGON BRANCH',
                    'PEX BANK - BOLGA CENTRE-BOLGATANGA',
                    'PEX BANK - CAPE COASTCAPE COAST',
                    'PEX BANK - TAMALE CENTRE-TAMALE',
                    'PEX BANK -HEAD OFFICE-SOUTH RIDGE ACCRA',
                    'PEX BANK -HOHOE CENTRE-HOHOE',
                    'PEX BANK KOFORIDUA CENTRE-KOFORIDUA',
                    'PEX BANK -SUNYANI CENTRE-SUNYANI',
                    'PEX BANK -WA CENTRE-WA',
                    'PEX BANKACCRA CENTRE-ACCRA',
                    'PEX BANK-HO CENTRE-HO',
                    'PEX BANK-KUMASIKUMASI',
                    'PEX BANK-TAKORADI CENTRE-TAKORADI',
                    'PEX LINK CENTRE-CEDI HOUSE',
                    'PIG FARM BRANCH',
                    'POKUASE',
                    'POKUASE BRANCH',
                    'PONUA RURAL BANK LTD-AMUANA PRASO',
                    'POST OFFICE SQUARE',
                    'POTSIN',
                    'PPER AMENFI RURAL BANK LTD-ANKWAWSO',
                    'PPER MANYA KRO RURAL BANK LTD-ASESEWA',
                    'PRAMPRAM',
                    'PRANG',
                    'PREMIER TOWERS',
                    'PREMPEH II STREET',
                    'PRESTEA',
                    'PRIVATE BANKING',
                    'PROCREDIT SAVINGS & LOANS',
                    'PUSIGA',
                    'RAKWA BRAMAN RURAL BANK LTD-BRAKWA',
                    'REINSURANCE HOUSE',
                    'REINSURANCE HOUSE BRANCH',
                    'REPUBLIC HOUSE',
                    'RIDGE',
                    'RIDGE BRANCH',
                    'RIDGE TOWERS',
                    'RING ROAD',
                    'RING ROAD BRANCH',
                    'RING ROAD CENTRAL',
                    'RING ROAD CENTRAL BRANCH',
                    'RING ROAD WEST',
                    'ROBO COMMUNITY RURAL BANK LTD-DROBO',
                    'ROMAN HILL',
                    'ROMAN HILL BRANCH',
                    'ROMAN HILL MARKET BRANCH',
                    'RRC',
                    'RRC REMITTANCE CENTRE',
                    'RUSSIA ROAD BRANCH',
                    'SABOBA',
                    'SAFE BOND',
                    'SAFE BOND JUBILEE',
                    'SAFE BOUND JUBILEE',
                    'SAFEBOND',
                    'SAKAMAN',
                    'SAKUMONO',
                    'SAKUMONO BRANCH',
                    'SALAGA',
                    'SALTPOND',
                    'SAMPA',
                    'SAMREBOI',
                    'SANDEMA',
                    'SANKORE',
                    'SANTA MARIA',
                    'SANTASI',
                    'SANTE AKYEM RURAL BANK LTD-JUANSA',
                    'SAVELUGU',
                    'SAWINSO RURAL BANK LTD-SEFWI-ASAWINSO',
                    'SAWLA',
                    'SEFWI BEKWAI',
                    'SEFWI BRANCH',
                    'SEFWI ESSAM',
                    'SEFWI WIAWO',
                    'SEFWI WIAWSO',
                    'SEFWIMAN RURAL BANK LTD-SEFWI-ASAW',
                    'SEFWI-WIASO',
                    'SEGE',
                    'SEKONDI',
                    'SEKYEDUMASI RURAL BANK LTD-SEKYEDUMASI',
                    'SEKYERE RURAL BANK LTD-JAMASI',
                    'SERVICE BRANCH',
                    'SHAI RURAL BANK LTD-DODOWA',
                    'SHIPPERS HOUSE BRANCH',
                    'SILVER STAR',
                    'SINAPI ABA SAVINGS & LOAN',
                    'SINAPI ABA SAVINGS & LOANS',
                    'SISSALA RURAL BANK LTD-TUMA',
                    'SME',
                    'SME CENTRE',
                    'SOATREMAN RURAL BANK LTD-NSOATRE',
                    'SOGAKOPE',
                    'SOKOBAN',
                    'SOKOBAN BRANCH',
                    'SOKORE RURAL BANK LTD-ASOKRE',
                    'SOMANYA',
                    'SONZELE RURAL BANK LTD-JIRAPA',
                    'SOUTH AKIM RURAL BANK LTD-NANKESE',
                    'SOUTH BIRIM RURAL BANK LTD-ACHIASE',
                    'SOUTH INDUSTRIAL AREA',
                    'SOUTH INDUSTRIAL AREA(SIA)',
                    'SOUTH INDUSTRIAL BRANCH',
                    'SOUTH LEGON BRANCH',
                    'SOWUTUOM',
                    'SPINTEX',
                    'SPINTEX BRANCH',
                    'SPINTEX MAIN',
                    'SPINTEX PRESTIGE',
                    'SPINTEX ROAD',
                    'SPINTEX ROAD BRANCH',
                    'SSINMAN RURAL BANK LTD-ASSIN MANSO',
                    'SSNIT HOUSE BRANCH',
                    'ST PETERS CO-OPERATIVE CREDIT UNION',
                    'STADIUM',
                    'STADIUM AMAKOM',
                    'STADIUM POST',
                    'STEWART AVENUE KUMASI',
                    'SUAME',
                    'SUAME BRANCH',
                    'SUAME MAAKRO',
                    'SUBONTEN RURAL BANK LTD-WORAWORA',
                    'SUHUM',
                    'SUHUM RURAL BANK',
                    'SUMA RURAL BANK LTD-SUMA AHENKRO',
                    'SUNYANI',
                    'SUNYANI CENTRAL',
                    'SUNYANI MAIN BRANCH',
                    'SUNYANI NIMPONG',
                    'SUNYANI ODUMASE',
                    'SUNYANI POST OFFICE BRANCH',
                    'SUNYANI POSTBANK',
                    'SUOGYAMAN RURAL BANK LTD-ACCRA',
                    'SUOPRA RURAL BANK LTD-AFOSU',
                    'SUTAMAN RURAL BANK LTD-NSUTA',
                    'SUTIFI RURAL BANK LTD-ACERENSUA',
                    'SWANMILL',
                    'SWEDRU',
                    'SWEDRU BRANCH',
                    'TAFO',
                    'TAFO BRANCH',
                    'TAIFA',
                    'TAKORADI',
                    'TAKORADI - KOKOMPE',
                    'TAKORADI BRANCH',
                    'TAKORADI EFFIEKUMA',
                    'TAKORADI HABOUR BRANCH',
                    'TAKORADI HARBOUR',
                    'TAKORADI HARBOUR ROAD',
                    'TAKORADI KOKOMPE',
                    'TAKORADI MAIN',
                    'TAKORADI MARKET CIRCLE',
                    'TAKORADI MKT CIRCLE',
                    'TAKORADI POST',
                    'TAKORADI POSTBANK',
                    'TAMALE',
                    'TAMALE ABOABO',
                    'TAMALE ABOABO BRANCH',
                    'TAMALE BRANCH',
                    'TAMALE CENTRAL',
                    'TAMALE HOSP ROAD',
                    'TAMALE HOSPITAL ROAD',
                    'TAMALE HOSPITAL ROAD BRANCH',
                    'TAMALE LAMESHEGU',
                    'TAMALE MAIN BRANCH',
                    'TAMALE MARKET',
                    'TANOKROM',
                    'TANOSO',
                    'TANTRA',
                    'TARKORADI MARKET',
                    'TARKWA',
                    'TARKWA BRANCH',
                    'TARKWA MINES',
                    'TEASE',
                    'TECH JUNCTION',
                    'TECHIMAN',
                    'TECHIMAN BRANCH',
                    'TECHIMAN MARKET',
                    'TECHIMAN MARKET BRANCH',
                    'TECHIMAN NORTH BRANCH',
                    'TECHIMAN TIA BRANCH',
                    'TECHIMAN VALLEY VIEW BRANCH',
                    'TECHIMAN WENCHI ROAD BRANCH',
                    'TEMA',
                    'TEMA BRANCH',
                    'TEMA COMM 1',
                    'TEMA COMM 2',
                    'TEMA COMM 4',
                    'TEMA COMM. 25',
                    'TEMA COMM. 6',
                    'TEMA COMMUNITY 1',
                    'TEMA COMMUNITY 1 BRANCH',
                    'TEMA COMMUNITY 2 BRANCH',
                    'TEMA COMMUNITY 25',
                    'TEMA COMMUNITY 25 BRANCH',
                    'TEMA COMMUNITY 6',
                    'TEMA COMMUNITY 9',
                    'TEMA COMMUNITY ONE',
                    'TEMA COMMUNITY TWO',
                    'TEMA DRYDOCK',
                    'TEMA EAST',
                    'TEMA FISHING HABOUR',
                    'TEMA FISHING HARBOUR',
                    'TEMA HABOUR',
                    'TEMA HARBOUR',
                    'TEMA INDUSTRIAL AREA',
                    'TEMA LONG ROOM',
                    'TEMA MAIN',
                    'TEMA MAIN BRANCH',
                    'TEMA MARKET',
                    'TEMA METRO BRANCH',
                    'TEMA OIL REFINERY',
                    'TEMA SAFEBOND',
                    'TEMA SHOPPING MAIL',
                    'TEMA SHOPPING MALL',
                    'TEMPANE BRANCH',
                    'TEPA',
                    'TEPAMAN RURAL BANK LTD - TEPAMAN',
                    'TESANO',
                    'TESANO BRANCH',
                    'TESHIE',
                    'TESHIE BRANCH',
                    'TESHIE NUNGUA',
                    'TETTEH QUARSHIE',
                    'THE SEED SAVINGS AND LOANS',
                    'TIA BRANCH',
                    'TIWA RURAL BANK LTD-KWABENG',
                    'TIZAA RURAL BANK LTD-TAMALE',
                    'TOENDE RURAL BANK LTD-ZIBILLA',
                    'TOLON',
                    'TONGU',
                    'TOR',
                    'TRADE FAIR',
                    'TRADE FAIR BRANCH',
                    'TREASURY',
                    'TREDE',
                    'TRUST TOWERS',
                    'TRUST TOWERS BRANCH',
                    'TSITO',
                    'TSOPOLI',
                    'TSUI BLEOO',
                    'TUASEKAN RURAL BANK LTD-KOFIASE',
                    'TUDU',
                    'TUDU BRANCH',
                    'TUMJU',
                    'TUMU',
                    'TUOBODOM',
                    'TUTU BRANCH',
                    'TWEABAN RURAL BANK LTD-SUNYANI',
                    'TWIFO PRASO',
                    'TWIFO RURAL BANK LTD-TWIFO AGONA',
                    'TWIMA KWANWOMA RURAL BANK LTD-PAKYI NO. 2',
                    'TWIMA MPONUA RURAL BANK LTD-TOASE',
                    'TWIMA RURAL BANK LTD-FOASE',
                    'UABEN RURAL BANK LTD-JUABEN',
                    'UCC',
                    'UEW BRANCH',
                    'UHUM RURAL BANK',
                    'UILSA COMMUNITY BANK LTD-SANDEMA',
                    'UMA RURAL BANK LTD-SUMA AHENKRO',
                    'UMAWUMAN RURAL BANK LTD-KUMAWU',
                    'UMPONG RURAL BANK LTD-OFRAMASE',
                    'UMUADA RURAL BANK LTD-OSINO',
                    'UNDP',
                    'UNION RURAL BANK LTD-SENYA BREKU',
                    'UNION SAVINGS & LOANS',
                    'UNION SAVINGS AND LOANS',
                    'UNION SAVINGS AND LOANS LIMITED',
                    'UNITY RURAL BANK LTD-ZIOPE',
                    'UNIV. OF CAPE COAST BRANCH',
                    'UNIVERSITY OF CAPE COAST',
                    'UNIVERSITY OF CAPE COAST BRANCH',
                    'UNIVERSITY OF GHANA BRANCH',
                    'UPPER AMENFI RURAL BANK LTD-ANKWAWSO',
                    'UPPER MANYA KRO RURAL BANK LTD-ASESEWA',
                    'UTAWU RURAL BANK LTD-HO',
                    'UTRAK SAVINGS AND LOANS',
                    'UWUULONSO RURAL BANK LTD-TAMALE',
                    'VAKPO',
                    'VALLEY VIEW',
                    'VALLEY VIEW UNIVERSITY- TECHIMAN',
                    'VENOR RURAL BANK LTD-AKATSI',
                    'WA',
                    'WA BRANCH',
                    'WABIAGYA RURAL BANK LTD-BAREKESE',
                    'WAEBIBIREM RURAL BANK LTD-ASUOM',
                    'WAHU PRASO RURAL BANK LTD-KWAHU PRASO',
                    'WAHU RURAL BANK LTD-KWAHU PEPEASE',
                    'WALEWALE',
                    'WAMANMAN RURAL BANK LTD-KWAMANG',
                    'WAMFIE',
                    'WAMFIE RURAL BANK LTD-WAMFIE',
                    'WASSA MANSO',
                    'WECHIAU',
                    'WEIJA',
                    'WEIJA BRANCH',
                    'WENCHI',
                    'WENCHI RURAL BANK LTD',
                    'WEST HILLS MALL',
                    'WEST LANDS',
                    'WEST MANPRUSI COMMUNITY BANK LTD-WALEWALE',
                    'WESTERN HILL',
                    'WESTERN RURAL BANK LTD-WASSA AKROPONG',
                    'WESTLANDS',
                    'WETO RURAL BANK LTD-KPEVE',
                    'WIDANA',
                    'WIFO RURAL BANK LTD-TWIFO AGONA',
                    'WINNEBA',
                    'WINNEBA BRANCH',
                    'WINNEBA RD',
                    'WINNEBA ROAD',
                    'WISCONSIN',
                    'WISCONSIN BRANCH',
                    'WOMANS WORLD BANKING',
                    'WOMENS WORLD BANK GHANA SAVINGS AND LOANS',
                    'WOMENS WORLD BANKING',
                    'WULENSI',
                    'WURIYENGA',
                    'WUTU EMASA RURAL BANK LTD-AWUMASA',
                    'YAA ASANTEWAA RURAL BANK LTD',
                    'YAKROM RURAL BANK LTD-NYAKROM',
                    'YANKUMASE AHENKRO RURAL BANK LTD-FANTI NYANKUMASE',
                    'YAPRA RURAL BANK LTD-PRANG',
                    'YEJI',
                    'YENDI',
                    'YESU NKWA',
                    'YIBI AREA RURAL BANK LIMITED',
                    'YUNYOO',
                    'ZABZUGU RURAL BANK LTD',
                    'ZEBILLA',
                    'ZEMA MANLE RURAL BANK LTD-AWIEBO',
                    'ZENITH BANK (GH) LTD-SUNYANI',
                    'ZENU',
                    'ZENU BRANCH',
                    'ZONGO JUNCTION',
                  ],
                  publishChange: true,
                  readonly: false,
                  tooltip: 'Account Branch Name',
                  type: 'field',
                  uid: '503a71583f1182e2',
                },
                {
                  id: 'account-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Account Number',
                  uid: '495c881bcf57de23',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: true,
          label: 'System List',
          uid: 'd1ac4ed6d65c6c5b',
          content: [],
        },
        {
          id: 'signature',
          type: 'list',
          readonly: false,
          label: 'Signature',
          uid: '1848a6126f8f1403',
          content: [
            {
              id: 'interactive-canvas-html',
              type: 'component',
              component: 'InteractiveCanvas',
              readonly: false,
              label: 'Interactive Canvas',
              uid: 'b25a984636d42a56',
              content:
                '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'ef0a41b9029aba55',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '4c0dc1e33a3e5341',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'c587b63a7b18b658',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '2aea10aeb7915580',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'fb3a74d8b4c60af2',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Payment Details',
    uri: 'fipp-enhanced/quote',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-payment-details',
  },
  'stop-order-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: '2179d0895b57f83f',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'stop-order-details',
          type: 'list',
          readonly: false,
          label: 'Stop Order Details',
          uid: '6bd977fde75cac31',
          content: [
            {
              id: 'employer-organisation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Employer Organisation',
              uid: '583c1096a7303045',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Abokobi Area Rural Bankstaff',
                'Accra Metro Assembly',
                'Adansi Rural Bank',
                'Advans Ghana',
                'Advent Press',
                'African Regent Hotel',
                'Agave Rural Bank',
                'Agric Development Bank',
                'Ahafo Ano Premier Rural Bank',
                'Ahantaman Rural Bankâ ',
                'Akaa Security Services',
                'Akatakyiman Rural Bank',
                'Akim Bosome Rural Bank',
                'Akwapem Rural Bank',
                'Aluworks',
                'Ama Dansowaa Clinic',
                'Amanano Rural Bank',
                'Amansie West Rural Bank',
                'Amatin & Kasei Comm Bank',
                'Antrak Ghana Ltd',
                'Anum Rural Bank Ltd',
                'Apex Bank',
                'Ashante Akyem Rural Bank',
                'Asokore Rural Bank Ltd - Kumasi',
                'Asutifi Rural Bank Limited',
                'Atlantic Int. Holding Co',
                'Atwima Kwanwoma Rural Bank',
                'Atwima Rural Bank',
                'Auto Parts Limited',
                'Awutu Emasa Rural Bank',
                'Ayrton Drug Manufacturing Limited',
                'Bank of Ghana',
                'Baptist Medical Centre',
                'Barge Operators and Allied Services Ltd',
                'Benso Oil Palm Plantationâ ',
                'Bessfa Rural Bank',
                'Bible Society of Ghana',
                'Bonzali Rural Bank',
                'Bosomtwe Rb',
                'Bureau of National Communication',
                'Bureau of National Investigations',
                'Busua Beach Resortâ ',
                'Capital Rural Bank',
                'Carmeuse Lime Productâ ',
                'Cejose Wisdom Int. School',
                'Chemico Limited',
                'Chirano Gold Mines',
                'Christ Ebenezer',
                'Church Hill of God',
                'Cocoa Marketiing Company Ltd',
                'Cocoa Processing Comp',
                'Controller',
                'Cssvd Control Unit',
                'Dangme Rural Bank Ltd - Staff',
                'De Youngsters International School Ltd',
                'Deoke Foundation Schools',
                'Derma Area Rural Bank',
                'Dizengoff Ghana Ltd',
                'Dynamic Insurance Brokers',
                'Ebennezer Osei Brenya Enterprise',
                'Ejuraman Rural Bank',
                'Electricity Company of Ghana',
                'Elmina Beach Resort',
                'Englebert School',
                'Enterprise Funeral Services',
                'Enterprise Group Ltd',
                'Enterprise Insurance',
                'Enterprise Life Reps',
                'Enterprise Life Staff',
                'Enterprise Properties Ltd',
                'Enterprise Trustees Limited',
                'Epa',
                'Eskay Therapeutics Ltd',
                'Fiagya Rural Bank Ltd',
                'Fiaseman Rural Bankâ ',
                'Fiesta Royale Hotel',
                'First National Savings and Loansâ ',
                'Forces Pay Office',
                'Ga East Municipal Assembly',
                'Ga Rural Bank',
                'Ga Rural Customers',
                'Gafco',
                'Garden City - Multi Credit',
                'Gcb Bank Ltd Staff',
                'Ghana Airport Company Ltd.',
                'Ghana Bauxite Company Limted',
                'Ghana Cement',
                'Ghana Chamber of Mines',
                'Ghana Civil Aviation Authority',
                'Ghana Cocoa Board',
                'Ghana Grid Company',
                'Ghana Heavy Equipment Limited',
                'Ghana Link Network Services Ltd',
                'Ghana Maritime Authority',
                'Ghana Medical School',
                'Ghana Oil Co. Ltd',
                'Ghana Police Service',
                'Ghana Post',
                'Ghana Railway Co. Ltd',
                'Ghana Re Company Limited',
                'Ghana Revenue Authority',
                'Ghana Standard Board',
                'Ghana Union Conference of s.d.a.',
                'Gihoc Distilleries Company Limited',
                'Gimpa',
                'Globeserve Ministry Intâ ',
                'Golden Exotics Ltd',
                'Goldengate Services Ltd',
                'Gpha - Takoradi',
                'Gpha - Tema',
                'Grace Baptist School - Kumasi',
                'Guiness Ghana Ltd',
                'Gwcl - Accra East',
                'Gwcl - Accra West',
                'Gwcl - Ashanti North',
                'Gwcl - Ashanti Production',
                'Gwcl - Ashanti South',
                'Gwcl - Cape Coast',
                'Gwcl - Greater Accra Region Production',
                'Gwcl - Head Office',
                'Gwcl - Koforidua',
                'Gwcl - Sunyani',
                'Gwcl - Takoradi',
                'Gwcl - Tema',
                'Hobats Clinic',
                'Holiday Inn Accra',
                'Home Finance Company Bank Ltd',
                'House of Compassion Ministries',
                'Japan Motors Trading Company',
                'Juaben Rural Bank',
                'Jubilee Shipping Services Gh Ltd',
                'k.n.u.s.t',
                'Kaaseman Rural Bank Ltd',
                'Kessben',
                'Kingsgroup of Companies',
                'Kintampo Health Research Centre',
                'Koforidua Stop Order Walk In',
                'Korle Bu Teaching Hospital',
                'Krane Contsruction',
                'Krobo Danish Association',
                'Kuapa Kokoo',
                'Kumawuman Rural Bnk',
                'Kwamaman Rural Bank',
                'La Nkwantanang Madina Municipal Assembly',
                'Labadi Beach Hotel',
                'Life Healthcare Medical Dental and Optical Clinic',
                'Living Star School',
                'Lordship Insurance Brokers',
                'Lower Pra Rural Bank',
                'Maxam Ghana Ltd',
                'Mechanical Lloyd',
                'Metromass',
                'Mojo Investment Ltd',
                'Mondial Veneer Gh. Ltd',
                'Mumuada Rural Bank',
                'Nafana Rural Bank Ltd',
                'National Security',
                'Nexans',
                'Nsoatreman Rural Bank',
                'Nthc Properties Ltd',
                'Nwabiagya Rural Bank',
                'Okomfo Anokye Rural Bank Staff',
                'Opportunity Int',
                'Parliament',
                'Pbc Ltd',
                'Pentecost Preparatory Schoolâ ',
                'Pentecost University College',
                'Phyto Riker',
                'Poly Products',
                'Presbyterian Womens Center - Abokobi',
                'Prince of Peace',
                'Quality Control Division (Cocobod)',
                'Regional Maritime Academy',
                'Ridge Church School',
                'Safebound Company Limited - Tema',
                'Sakworg Company Ltd',
                'Samartex Timber and Plywood Company',
                'Sandvik Mining and Construction Limited',
                'Scanbech Ghana Ltd',
                'Sdv Ghana',
                'Securities Discount Company Limited',
                'Sekyedumase Rural Bank',
                'Sekyere Rural Bank',
                'Services Intergrity Savings and Loans',
                'Sgs Laboratory Services',
                'Sg Ssb',
                'Silver Star Auto Ltd',
                'Sinapi Aba Trust',
                'Sofaamy Company Limited',
                'Sos Childrens Village Ghana',
                'South Birim Rural Bank',
                'South Central Conference of Sda',
                'Special Ice Company Ltd',
                'Ssnit',
                'St Ambrose College',
                'St Michaels Hospital Pramso',
                'Stellar Logistic',
                'Sunyani Cluster Icps',
                'Takoradi Flour Mill Ltd',
                'Takoradi Int. Co.',
                'Tct',
                'Tema Development Corp.',
                'Tema Parents Association School',
                'Tesano Baptist Church',
                'The Church of Pentecost',
                'Toende Rural Bank',
                'Trasacco',
                'Twifo Oil Palm Plantation',
                'Type Company Ltd',
                'Unilever',
                'University College of Education',
                'University for Development Studies',
                'University of Energy and Natural Resources',
                'University of Ghana - Legon',
                'Upper Amenfi Rural Bank',
                'Upper Manya Kro Rural Bank',
                'Utrak Savings and Loans',
                'Valley View',
                'Valley View University Basic School',
                'Vat Service',
                'Volta Hotel',
                'Volta River Authority',
                'Waec - Headquarters',
                'Waec - Main',
                'West Africa Mills Co. Ltd',
                'Weto Rural Bank',
                'Winners Chapel Ghana',
                'Yaakson Herbal Centre',
                'Yarsako',
              ],
            },
            {
              id: 'employee-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Employee Number',
              uid: '17ff314b6165c883',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
          ],
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: true,
          label: 'System List',
          uid: 'd1ac4ed6d65c6c5b',
          content: [],
        },
        {
          id: 'signature',
          type: 'list',
          readonly: false,
          label: 'Signature',
          uid: '1848a6126f8f1403',
          content: [
            {
              id: 'interactive-canvas-html',
              type: 'component',
              component: 'InteractiveCanvas',
              readonly: false,
              label: 'Interactive Canvas',
              uid: 'b25a984636d42a56',
              content:
                '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'ef0a41b9029aba55',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '4c0dc1e33a3e5341',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'c587b63a7b18b658',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '2aea10aeb7915580',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'fb3a74d8b4c60af2',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Payment Details',
    uri: 'fipp-enhanced/quote',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-payment-details',
  },
  'mobile-wallet-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: 'bd5bb2c4fb68c248',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: false,
          label: 'System List',
          uid: '51ec63fbc00239ee',
          content: [
            {
              id: 'mobile-wallet-details',
              type: 'list',
              readonly: false,
              label: 'Mobile Wallet Details',
              uid: '2d34ee1c0b75f4a2',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '7577327f9a279ee2',
                  content: '<p>Please provide your mobile wallet details</p>\n',
                },
                {
                  id: 'mobile-wallet-network-provider',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Mobile Wallet Network Provider',
                  uid: '3a6d7817942bbe73',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Mobile Wallet Network Provider',
                  options: [
                    '',
                    'Vodafone',
                    'Mtn',
                    'Tigo',
                    'Airtel',
                    'Expresso',
                    'Glo Mobile',
                  ],
                },
                {
                  id: 'mobile-wallet-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Mobile Wallet Number',
                  uid: '165034a17f3ef9dd',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Mobile Wallet Number',
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'signature',
          type: 'list',
          readonly: false,
          label: 'Signature',
          uid: '1848a6126f8f1403',
          content: [
            {
              id: 'interactive-canvas-html',
              type: 'component',
              component: 'InteractiveCanvas',
              readonly: false,
              label: 'Interactive Canvas',
              uid: 'b25a984636d42a56',
              content:
                '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'ef0a41b9029aba55',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '4c0dc1e33a3e5341',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'c587b63a7b18b658',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '2aea10aeb7915580',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'fb3a74d8b4c60af2',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Payment Details',
    uri: 'fipp-enhanced/quote',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-payment-details',
  },
  'upload-documents': {
    text: [],
    buttons: [
      {
        label: 'Upload ID document',
        id: 'upload-id-document',
        uid: '69a53b6758f4ff80',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'upload-documents',
      type: 'list',
      readonly: true,
      label: 'Upload Documents',
      uid: 'ca4ff8d6702664bd',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '2dd71fd55a00cef9',
          content: '<h2>Upload Documents</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f4179312612d1ce4',
          content:
            '<p>Please upload the necessary documentation or proceed to the next page if you would like to provide it later.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'e567849ef903ec0e',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='done clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'documents-list',
          type: 'list',
          readonly: true,
          label: 'Documents List',
          uid: '8a30a43b57bb332b',
          content: [
            {
              id: 'system-field-outcome',
              type: 'html',
              readonly: true,
              label: '',
              uid: '7721dd56ec3e43de',
              content:
                "<p><a href='#upload-id-document' class=''>ID Document <span>Re Upload</span></a></p>\n",
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '484f3f37b6903114',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '47daed0e6aad6ff1',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ea1569c8a701b01f',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '1fdec6607ee9f967',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'e8d4b11a9bdd5075',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Upload Documents',
    uri: 'fipp-enhanced/upload-documents',
    template: 'fipp-enhanced-app-template',
    class: 'fippenhanced-upload-documents',
  },
  'upload-document-pages': {
    modal: true,
    buttons: [
      {
        control: false,
        id: 'add-page',
        label: 'Add Page',
        uid: '1a5450d98db152d6',
      },
      {
        control: true,
        id: 'abort',
        label: 'Abort',
        uid: 'e11279c7f15a77ea',
      },
      {
        control: true,
        id: 'continue',
        label: 'Continue',
        uid: '0d54090d869303f6',
      },
    ],
    item: {
      content: [
        {
          content:
            '<p>Please upload your document below. If it consists of multiple pages, please upload all of them together.</p>\n',
          id: 'system-field-outcome',
          label: '',
          readonly: true,
          type: 'html',
          uid: '8872705861783d2d',
        },
        {
          content: [],
          id: 'uploads',
          label: 'Uploads',
          readonly: false,
          type: 'table',
          uid: '2d999fbaaefb947c',
        },
        {
          content:
            '<p><a class="button add-page-button" href="#add-page">Add Page</a></p>\n',
          id: 'system-constant',
          label: '',
          readonly: true,
          type: 'html',
          uid: 'a47ce0e3f28e09c5',
        },
      ],
      id: 'upload-section',
      label: 'Upload Section',
      readonly: false,
      type: 'list',
      uid: '74b77d523e9f9b6d',
    },
    class: 'upload-layout',
    context: 'Upload Layout',
    template: '',
    text: [],
    title: 'Public Access \n Welcome to Elac',
    uri: 'upload-document/file/new',
  },
  'acceptance-screen': {
    text: [],
    buttons: [
      {
        label: 'Submit',
        id: 'submit',
        uid: 'ef8243ce592928b2',
        control: false,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'acceptance',
      type: 'list',
      readonly: false,
      label: 'Acceptance',
      uid: '24eb2046e3d8a6f0',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f9d0e1f1ebff8a2a',
          content: '<h1>Summary</h1>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '8b470d282f194b34',
          content: '<p>Please review the information below</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '65dbe261db324f63',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='done clickable'><a href='#uploads'>Uploads</a></li>\n<li class='done clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'summary',
          type: 'list',
          readonly: true,
          label: 'Summary',
          uid: '470d5bb8a7586168',
          content: [
            {
              id: 'contract-id',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Application Reference Number',
              uid: '0d3a5727942b5c07',
              content: '',
              inputType: 'text',
            },
            {
              id: 'life-assured-identification',
              type: 'list',
              readonly: true,
              label: 'Life Assured Identification',
              uid: 'ba084e0d6d5db3ba',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: '4bc984547af74803',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: 'fb5cd4e5887f3e10',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '4fbcb5f5033fa4a4',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'marital-status',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Marital Status',
                  uid: '3ec2074800e0e60c',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'gender',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Gender',
                  uid: '25970a93188b88d4',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: 'edfc4291772f81bf',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'ghanaian',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Ghanaian',
                  uid: '9456d02f16087d0e',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'idpassport-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'ID Number',
                  uid: '8fa4cdcb279c6d77',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'life-assured-contact-details',
              type: 'list',
              readonly: true,
              label: 'Life Assured Contact Details',
              uid: '041f6c15d79f06bf',
              content: [
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '4f9e98cae52cd1fd',
                  content: '',
                  inputType: 'tel',
                },
                {
                  id: 'email',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Email',
                  uid: '80a7938a8d9c33d1',
                  content: '',
                  inputType: 'email',
                },
              ],
            },
            {
              id: 'life-assured-postal-address',
              type: 'list',
              readonly: true,
              label: 'Life Assured Postal Address',
              uid: '8039e01474ff962e',
              content: [
                {
                  id: 'town',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Town',
                  uid: '5ede4adcd9ea129d',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'region',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Region',
                  uid: '94e932258a4747a0',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'po-box-or-private-bag',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'PO Box Or Private Bag',
                  uid: '49445ab27c5f04bb',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'other-lives-assured-funeral-cover',
              type: 'table',
              readonly: true,
              label: 'Other Lives Assured Funeral Cover',
              uid: '8549bdec9d4c02d6',
              columns: [],
              content: [],
            },
            {
              id: 'beneficiary-details',
              type: 'table',
              readonly: true,
              label: 'Beneficiary Details',
              uid: '82221a24f51ffc25',
              content: [],
            },
            {
              id: 'trustee-identification',
              type: 'list',
              readonly: true,
              label: 'Trustee Identification',
              uid: '93583adf9a03d74b',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: '22798e202c4bc3be',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: '3386f1b2fed7eef6',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '890da04c904445a0',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: '487e9507ab0315cd',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'relationship',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Relationship',
                  uid: '7d4b3960ef930bfd',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'trustee-contact-details',
              type: 'list',
              readonly: true,
              label: 'Trustee Contact Details',
              uid: 'c691bb424fec40d3',
              content: [
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '6360b42285a0f5e2',
                  content: '',
                  inputType: 'tel',
                },
              ],
            },
            {
              id: 'payer-person-identification',
              type: 'list',
              readonly: true,
              label: 'Payer Person Identification',
              uid: '5b0af1f9ee56f6b6',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: '073c8b55e009a7df',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: 'd4016a17eddc9f64',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'idpassport-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'ID Number',
                  uid: 'a734ffabc2a463e2',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '9f20d39f7c44486d',
                  content: '',
                  inputType: 'number',
                },
              ],
            },
            {
              id: 'payment-details',
              type: 'list',
              readonly: true,
              label: 'Payment Details',
              uid: '247f3fc44d74c132',
              content: [],
            },
          ],
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '659263c7132d2858',
          content: '<br/>',
        },
        {
          id: 'interactive-canvas-html',
          type: 'component',
          component: 'InteractiveCanvas',
          readonly: false,
          label: 'Interactive Canvas',
          uid: '1fcad0e41194c3d1',
          content:
            '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '326d49edc0c4a2f0',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '4eb3d312b66aba89',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '05e6f7ce55f9697f',
                  content: '<p>Family Income Security Plan</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '28a6cd68ea42c9c9',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '48f061e288e60d8b',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'FIPP-ENHANCED Acceptance Screen',
    uri: 'fipp-enhanced/acceptance',
    template: 'fipp-enhanced-acceptance-screen',
    class: 'fippenhanced-acceptance-screen',
  },
}

function range(start, end) {
  return new Array(end - start + 1).fill(undefined).map((_, i) => i + start)
}

export const healthDetailsPrefix = [
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: '25e8ba914ed925d9',
    content: '<h2 id="health-description">Health description</h2>\n',
  },
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: '4798836a7c99c3c0',
    content:
      '<p>If the answer is &quot;Yes&quot; to questions 2-6 on the previous page please provide details below.</p>\n',
  },
  {
    id: 'output-html',
    type: 'html',
    readonly: true,
    label: 'Output Html',
    uid: '065a26e9f05f3475',
    content:
      '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="done clickable"><a href="#underwriting">Underwriting</a></li>\n<li class="clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
  },
]

export const healthDetailsItem = (count) =>
  range(1, count).map((counter) => ({
    id: `health-concern-${counter}`,
    type: 'list',
    readonly: false,
    label: `Health Concern ${counter}`,
    uid: `39858c9af82649b5-${counter}`,
    content: [
      {
        id: `complaints-${counter}`,
        type: 'field',
        elementType: 'select',
        readonly: false,
        label: 'Complaints',
        uid: `8faa00483cc51798-${counter}`,
        content: '',
        publishChange: true,
        errors: ['*'],
        tooltip: 'Complaints',
        options: [
          '',
          'Alcohol-Past-Consumption',
          'Arthritis',
          'Cancer',
          'Chest-Or-Respiratory-Disorder',
          'Declined-Or-Loaded',
          'Diabetes',
          'Good-Health',
          'Hazardous-Pursuits',
          'Heart-Problems',
          'Hereditary-Diseases',
          'High-Blood-Pressure',
          'Hiv-Positive',
          'Kidney-Problems',
          'Medical-Examination',
          'Mental-Illness',
          'Nervous-System-Problems',
          'Other-Disorders',
          'Prescribed-Medication',
          'Previous-Regular-Treatment',
          'Previous-Surgery',
          'Respiratory-Problems',
          'Sexually-Transmitted-Diseases',
          'Skeletal-Problems',
          'Stable-Weight',
          'Stomach-Problems',
        ],
      },
      {
        id: `complaint-description-${counter}`,
        type: 'field',
        elementType: 'input',
        readonly: false,
        label: 'Complaint Description',
        uid: `26a740e28b18766f-${counter}`,
        content: '',
        publishChange: true,
        errors: ['*'],
        inputType: 'text',
      },
      {
        id: `medical-condition-date-${counter}`,
        type: 'component',
        elementType: 'input',
        readonly: false,
        label: 'Medical Condition Date',
        uid: `f5ec4cc32a4803e5-${counter}`,
        content: '',
        publishChange: true,
        errors: [
          'Please ensure the medical condition date is not selected in the future.',
        ],
        inputType: 'date',
        component: 'DateSelector',
        maxYear: thisYear,
      },
      {
        id: `health-results-${counter}`,
        type: 'field',
        elementType: 'input',
        readonly: false,
        label: 'Health Results',
        uid: `8f07c239d4715901-${counter}`,
        content: '',
        publishChange: true,
        errors: ['*'],
        inputType: 'text',
      },
    ],
  }))

export const quotationPanel = {
  id: 'quotation-panel',
  type: 'list',
  readonly: true,
  label: 'Quotation Panel',
  uid: 'f92bebf5c8e3799e',
  content: [
    {
      id: 'quotation-information',
      type: 'list',
      readonly: true,
      label: 'Quotation Information',
      uid: '874801c29a27d8e3',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '9a9da8b55542d233',
          content: '<p>Family Income Security Plan</p>\n',
        },
        {
          id: 'benefit-rate',
          type: 'amount',
          elementType: 'input',
          readonly: true,
          label: 'Benefit Rate',
          uid: '234f9a4cc9b1210c',
          content: '0.00',
          inputType: 'number',
        },
        {
          id: 'premium',
          type: 'amount',
          elementType: 'input',
          readonly: true,
          label: 'Premium',
          uid: 'd3b718fa91ff280e',
          content: '0.00',
          inputType: 'number',
        },
      ],
    },
  ],
}

export const beneficiaryItem = (b, counter) => [
  {
    id: 'beneficiary-role',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Beneficiary Role',
    uid: `f35595dfe84ccc32-${counter}`,
    content: `Beneficiary-${counter + 1}`,
    inputType: 'text',
  },
  {
    id: 'first-names',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'First Names',
    uid: `1ffb81f6ff08d3e0-${counter}`,
    content: b['first-names'] || '',
    inputType: 'text',
  },
  {
    id: 'surname',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Surname',
    uid: `fa459c20a330cfac-${counter}`,
    content: b.surname || '',
    inputType: 'text',
  },
  {
    id: 'date-of-birth',
    type: 'integer',
    elementType: 'input',
    readonly: true,
    label: 'Date Of Birth',
    uid: `645841de6913fff0-${counter}`,
    content: b['date-of-birth'] || '',
    inputType: 'number',
  },
  {
    id: 'role',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Role',
    uid: `62705a905026834d-${counter}`,
    content: b.role || '',
    inputType: 'text',
  },
  {
    id: 'gender',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Gender',
    uid: `58cb28acbf7c5468-${counter}`,
    content: b.gender || '',
    inputType: 'text',
  },
  {
    id: 'benefit-split',
    type: 'real',
    elementType: 'input',
    readonly: true,
    label: 'Benefit Split',
    uid: `52b03deffc898529-${counter}`,
    content: b['benefit-split'] || '',
    inputType: 'number',
  },
]

export const benefitSplitItem = (b, counter) => ({
  id: `beneficiary-${counter + 1}`,
  type: 'list',
  readonly: false,
  label: `Beneficiary-${counter + 1}`,
  uid: `8dca6eb49ac65bcc-${counter}`,
  content: [
    {
      id: 'system-constant',
      type: 'html',
      readonly: true,
      label: '',
      uid: `7259b360e6ec1490-${counter}`,
      content: `<p>${b['first-names']}</p>\n`,
    },
    {
      id: `beneficiary-split-${counter + 1}`,
      type: 'field',
      elementType: 'input',
      readonly: false,
      label: 'Beneficiary Split',
      uid: `8644e07220d3617d-${counter}`,
      content: b['benefit-split'] || '',
      publishChange: true,
      inputType: 'text',
    },
  ],
})

//ADDING FAMILY MEMBERS
let ageRange = () => {
  const cached = {}
  return (start, end) => {
    if (start > end) {
      start = end
    }
    const k = `[${start}-${end}]`
    if (k in cached) {
      return cached[k]
    }
    const range = ['', `${start}`]
    for (let val = start + 1; val <= end; val++) {
      range.push(`${val}`)
    }
    cached[k] = range
    return range
  }
}
ageRange = ageRange()

// Family member menu
export const familyMemberOptions = (age) => ({
  Child: {
    options: ageRange(0, 21),
  },
  //AGE DIFFERENCE BETWEEN LIFE ASSURED AND PARENTS = MINIMUM OF 13 YEARS
  'Parent or In-Laws': {
    Mother: {
      options: ageRange(age + 13, 100),
    },
    'Mother in law': {
      options: ageRange(age + 13, 100),
    },
    Father: {
      options: ageRange(age + 13, 100),
    },
    'Father in law': {
      options: ageRange(age + 13, 100),
    },
  },
  //AGE DIFFERENCE BETWEEN LIFE ASSURED AND GRAND PARENTS = 26 YEARS
  Grandparents: {
    Grandmother: {
      options: ageRange(age + 26, 100),
    },
    Grandfather: {
      options: ageRange(age + 26, 100),
    },
  },
  'Step Parents': {
    'Step mother': {
      options: ageRange(age + 13, 100),
    },
    'Step father': {
      options: ageRange(age + 13, 100),
    },
  },
  Siblings: {
    Sister: {
      options: ageRange(0, 100),
    },
    Brother: {
      options: ageRange(0, 100),
    },
  },
  //AGE DIFFERENCE BETWEEN LIFE ASSURED AND GAURDIAN = 16 YEARS
  Guardian: {
    options: ageRange(age + 16, 100),
  },
})
export const familyMemberLabels = Object.keys(familyMemberOptions(0))

export const createFamilyMember = (
  relationship,
  ageofmember,
  gender,
  funeralcover,
  freechildcover,
  oldagecover,
  funeralcoverbenefit,
  memorialsevenday,
  memorialfortyday,
  memorialoneyear,
  oldagecoverbenefit
) => ({
  relationship,
  'age-of-member': ageofmember,
  gender,
  'funeral-cover': funeralcover,
  'free-child-cover': freechildcover,
  'old-age-cover': oldagecover,
  'funeral-cover-benefit': funeralcoverbenefit,
  'memorial-seven-day': memorialsevenday,
  'memorial-forty-day': memorialfortyday,
  'memorial-one-year': memorialoneyear,
  'old-age-cover-benefit': oldagecoverbenefit,
  'first-names': null,
  surname: null,
  'date-of-birth': null,
})

export const multipleOccurancesExist = (life, lives) =>
  lives.reduce(
    (c, l) => (l.relationship === life.relationship ? c + 1 : c),
    0
  ) > 1

const findOccurance = (life, position, lives) => {
  let occuranceString = ''
  if (multipleOccurancesExist(life, lives)) {
    let occurance = 1
    for (let i = position - 1; i >= 0; i--) {
      if (lives[i].relationship === life.relationship) {
        occurance += 1
      }
    }
    occuranceString = ` ${occurance}`
  }
  return occuranceString
}

export const additionalLife = (life, position, lives) => {
  const lifeRelationship = `${life.relationship}${findOccurance(
    life,
    position,
    lives
  )}`
  return [
    createItem('Relationship', {
      content: lifeRelationship,
      elementType: 'input',
      inputType: 'text',
      readonly: true,
      type: 'field',
    }),
    createItem('Age', {
      content: life['age-of-member'],
      elementType: 'input',
      inputType: 'number',
      readonly: true,
      type: 'integer',
    }),
    createItem('Gender', {
      content: life['gender'],
      elementType: 'input',
      inputType: 'text',
      readonly: true,
      type: 'field',
    }),
    createItem('Funeral Cover', {
      content: life['funeral-cover'] || '',
      elementType: 'input',
      inputType: 'text',
      readonly: true,
      type: 'field',
    }),
    createItem('Free Child Cover', {
      content: life['free-child-cover'] || '',
      elementType: 'input',
      inputType: 'text',
      readonly: true,
      type: 'field',
    }),
    createItem('system-field-outcome', {
      content: `<p><a class="remove" href="#remove-family#${slugify(
        lifeRelationship
      )}">Remove</a><a class="edit" href="#edit-family#${slugify(
        lifeRelationship
      )}">Edit</a></p>\n`,
      label: '',
      readonly: true,
      type: 'html',
    }),
  ]
}

export const familyMemberTablePrefix = [
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: '0bc795d3e0cb7f34',
    content: '<h2>Additional Lives Details</h2>\n',
  },
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: '4780eecde04adec3',
    content:
      '<p>Please complete the details for your additional lives below</p>\n',
  },
  {
    id: 'output-html',
    type: 'html',
    readonly: true,
    label: 'Output',
    uid: '61f48521f562ddce',
    content:
      "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
  },
]

export const familyMemberItem = (f, counter) => ({
  id: `life-${counter}`,
  type: 'list',
  readonly: false,
  label: f.relationship,
  uid: '8ecfd29866544493',
  content: [
    {
      id: 'system-field-outcome',
      type: 'html',
      readonly: true,
      label: '',
      uid: '1a3c568e4f5afeda',
      content: `<p>Please provide the following information for your <strong>${f.relationship}</strong> aged <strong>${f['age-of-member']}</strong>.</p>\n`,
    },
    {
      id: `first-names-${counter}`,
      type: 'field',
      elementType: 'input',
      readonly: false,
      label: 'First Names',
      uid: 'c25583134b856e84',
      content: f['first-names'] || '',
      errors: f['first-names'] ? [] : ['*'],
      publishChange: true,
      inputType: 'text',
    },
    {
      id: `surname-${counter}`,
      type: 'field',
      elementType: 'input',
      readonly: false,
      label: 'Surname',
      uid: '92b7de7d6e94e8aa',
      content: f.surname || '',
      errors: f.surname ? [] : ['*'],
      publishChange: true,
      inputType: 'text',
    },
    {
      id: `date-of-birth-${counter}`,
      type: 'component',
      elementType: 'input',
      readonly: false,
      label: 'Date Of Birth',
      uid: '5ca6a755437569bb',
      content: f['date-of-birth'] || '',
      errors: f['date-of-birth'] ? [] : ['*'],
      publishChange: true,
      inputType: 'date',
      component: 'DateSelector',
      maxYear: dayjs().year(),
    },
    {
      id: `gender-${counter}`,
      type: 'field',
      elementType: 'input',
      readonly: true,
      label: 'Gender',
      uid: '7d759fa831bbbccb',
      content: f.gender || '',
      inputType: 'text',
      options: ['', 'Female', 'Male'],
    },
    {
      id: 'quotation-panel',
      type: 'list',
      readonly: true,
      label: 'Quotation Panel',
      uid: '1f874a324af01a0a',
      content: [
        {
          id: 'quotation-information',
          type: 'list',
          readonly: true,
          label: 'Quotation Information',
          uid: '4f5a478187ad4b9d',
          content: [
            {
              id: 'product',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Product',
              uid: 'f12ba8a10a52a4dd',
              content: 'Family Income Security Plan',
              inputType: 'text',
            },
            {
              id: 'benefit-rate',
              type: 'amount',
              elementType: 'input',
              readonly: true,
              label: 'Benefit Rate',
              uid: 'deeffb58b9038029',
              content: '',
              inputType: 'number',
            },
            {
              id: 'premium',
              type: 'amount',
              elementType: 'input',
              readonly: true,
              label: 'Premium',
              uid: 'b9f996d5dc386fec',
              content: '',
              inputType: 'number',
            },
          ],
        },
      ],
    },
  ],
})

export const buttonFromLifeAssured = (life, position, lives) =>
  createButton(`${life.relationship}${findOccurance(life, position, lives)}`)

export const idFromLifeAssured = (life, position, lives) =>
  slugify(`${life.relationship}${findOccurance(life, position, lives)}`)

export const paymentDetailsSummary = (form) => {
  if (form['payment-method']['mobile-wallet'] === 'Y') {
    return {
      content: [
        {
          id: 'mobile-wallet-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Mobile Wallet Number',
          uid: '96d79859b70f1d61',
          content: form['mobile-wallet-details']['mobile-wallet-number'] || '',
          inputType: 'text',
        },
        {
          id: 'mobile-wallet-network-provider',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Mobile Wallet Network Provider',
          uid: 'e15ab36d3f397c2e',
          content:
            form['mobile-wallet-details']['mobile-wallet-network-provider'] ||
            '',
          inputType: 'text',
        },
      ],
      id: 'mobile-wallet-details',
      type: 'list',
      readonly: true,
      label: 'Mobile Wallet Details',
    }
  } else if (form['payment-method']['debit-order'] === 'Y') {
    return {
      id: 'payment-bank-details',
      type: 'list',
      readonly: true,
      label: 'Payment Bank Details',
      content: [
        {
          id: 'account-holder-name',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Holder Name',
          uid: 'c48fe6bcd83a0b4a',
          content: form['payment-bank-details']['account-holder-name'] || '',
          inputType: 'text',
        },
        {
          id: 'account-entity-name',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Entity Name',
          uid: '887f2c3da9067907',
          content: form['payment-bank-details']['account-entity-name'] || '',
          inputType: 'text',
        },
        {
          id: 'account-type',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Type',
          uid: '8de41e5b5f22dfaa',
          content: form['payment-bank-details']['account-type'] || '',
          inputType: 'text',
        },
        {
          id: 'account-branch-name',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Branch Name',
          uid: 'faebf7bb7f978ec3',
          content: form['payment-bank-details']['account-branch-name'] || '',
          inputType: 'text',
        },
        {
          id: 'account-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Number',
          uid: '27269012310a5430',
          content: form['payment-bank-details']['account-number'] || '',
          inputType: 'text',
        },
      ],
    }
  } else if (form['payment-method']['stop-order'] === 'Y') {
    return {
      id: 'payer-employment-details',
      type: 'list',
      readonly: true,
      label: 'Payer Employment Details',
      content: [
        {
          id: 'employer-organisation',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employer Organisation',
          uid: '781441cc7b190193',
          content:
            form['payment-employment-details']['employer-organisation'] || '',
          inputType: 'text',
        },
        {
          id: 'employee-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employee Number',
          uid: '27581bcc2706c489',
          content: form['payment-employment-details']['employee-number'] || '',
          inputType: 'text',
        },
      ],
    }
  } else {
    return {}
  }
}
