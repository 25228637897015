/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */
import dayjs from 'dayjs'
const thisYear = dayjs().year()

export const pages = {
  'confirm-cancel': {
    modal: true,
    text: [],
    buttons: [
      { label: 'Yes', id: 'yes', uid: '0baa62eba1b1a7d7', control: true },
      { label: 'No', id: 'no', uid: '6c0e0e2956ec3933', control: true },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '9bc15702a2e49228',
      content: ['Are you sure you want to cancel this application?'],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'quotation-screen': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'quote-screen',
      type: 'list',
      readonly: false,
      label: 'Quote Screen',
      uid: '82e63220ee86076e',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '552d19e7a2f676a3',
          content: '<h2>Choose your cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a1fcd090d9138ba4',
          content:
            '<p>To be able to provide you with a quick quotation we will need the following information from you.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '56dfc210727e82c4',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'product-description',
          type: 'list',
          readonly: true,
          label: 'Product Description',
          uid: 'a7838459451f5202',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'c7082b0ad6ad2283',
              content:
                '<p>The Below 100 Savings plan is designed to support the policyholder to save towards their lifetime needs while securing these needs with cover against death.</p>\n',
            },
          ],
        },
        {
          id: 'term-in-years',
          type: 'list',
          readonly: false,
          label: 'Term In Years',
          uid: '90668f6f9a84de6d',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '5ff1ecff8f1aa565',
              content:
                '<p>For how many years would you like to invest towards the investment fund of this policy.</p>\n',
            },
            {
              id: 'term',
              type: 'integer',
              elementType: 'select',
              readonly: false,
              label: 'Term In Years',
              uid: 'c19ebc9cdf178aef',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
                '25',
                '26',
                '27',
                '28',
                '29',
                '30',
                '31',
                '32',
                '33',
                '34',
                '35',
                '36',
                '37',
                '38',
                '39',
                '40',
                '41',
                '42',
              ],
            },
          ],
        },
        {
          id: 'your-details',
          type: 'list',
          readonly: false,
          label: 'Your Details',
          uid: '149ba33c3dcef1de',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '3335dba07bda188d',
              content: '<p>What is your Date of Birth</p>\n',
            },
            {
              id: 'date-of-birth',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Date Of Birth',
              uid: 'f0e0a8a867c4bd0c',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'date',
              component: 'DateSelector',
              maxYear: 2023,
              minYear: 1923,
            },
          ],
        },
        {
          id: 'inflation-protector',
          type: 'list',
          readonly: false,
          label: 'Inflation Protector',
          uid: 'c7e090f15d2342cb',
          content: [
            {
              id: 'inflation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Inflation Protector %(0, 5, 10, 15, 20, 25, 30)',
              uid: '6b0c7de78779ff33',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: ['', '0', '5', '10', '15', '20', '25', '30'],
            },
          ],
        },
        {
          id: 'investment-premium',
          type: 'list',
          readonly: false,
          label: 'Investment Premium',
          uid: '4094e4d738339e8a',
          content: [
            {
              id: 'investment-amount',
              type: 'amount',
              elementType: 'input',
              readonly: false,
              label: 'Investment Premium (GHC)',
              uid: '5b6ac03ce5b16a2c',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'number',
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '2d957cdc9becd80e',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'fb9c22c04df7650a',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'eeb863d37efc24a1',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'f20aba0e17ed86fa',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '2e71b32f3296dcea',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Quotation Details',
    uri: 'below-100-savings/quote',
    class: 'below-100-savings-quotation-details',
  },
  'maximum-premium': {
    text: [],
    buttons: [
      {
        label: 'Done',
        id: 'done',
        uid: '4e5f7031a7643ff6',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '7e7042ec129c25b8',
      content: [' The maximum premium is GHc 100. Please lower your premium.'],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Quotation Details',
    uri: 'below-100-savings/quote',
    class: 'below-100-savings-quotation-details',
  },
  'minimum-premium': {
    text: [],
    buttons: [
      {
        label: 'Done',
        id: 'done',
        uid: '4e5f7031a7643ff6',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '2266de00bc66eb08',
      content: ['The minimum premium is GHc 50. Please increase your premium.'],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Quotation Details',
    uri: 'below-100-savings/quote',
    class: 'below-100-savings-quotation-details',
  },
  'life-assured-identification': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'personal-details',
      type: 'list',
      readonly: false,
      label: 'Personal Details',
      uid: 'd10e81d61d6ba9f5',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'c9d124707f9d1b14',
          content: '<h2>Personal Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a8902e1cd0cee07e',
          content:
            '<p>For us to complete the purchase, we require the following information</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '3dfdc74464a34aa8',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'your-details',
          type: 'list',
          readonly: false,
          label: 'Your Details',
          uid: '6e447eff4afac58b',
          content: [
            {
              id: 'first-names',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'First Names',
              uid: 'b0cb5e2013562d51',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'surname',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Surname',
              uid: 'd9b3dd2f9b758c95',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'idpassport-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'ID/Passport Number',
              uid: '98e2671fcd68feeb',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'id-type',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'ID Type',
              uid: '5e008fe027dbc741',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Voter Id',
                'Nhis',
                'Ssnit',
                'Drivers Old Id',
                'Drivers New Id',
                'Passport',
                'Ghana Card',
              ],
            },
            {
              id: 'date-of-birth',
              type: 'component',
              elementType: 'input',
              readonly: true,
              label: 'Date Of Birth',
              uid: 'cf5112787e541dd8',
              content: '19900506',
              publishChange: true,
              inputType: 'date',
              component: 'DateSelector',
              maxYear: 2023,
              minYear: 1923,
            },
            {
              id: 'title',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Title',
              uid: 'df961919bc248ac4',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Adv',
                'Dr',
                'Miss',
                'Mr',
                'Mrs',
                'Ms',
                'Prof',
                'Rev',
              ],
            },
            {
              id: 'marital-status',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Marital Status',
              uid: 'b7787bd9a19e2138',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Divorced',
                'Married',
                'Single',
                'Widow',
                'Widower',
              ],
            },
            {
              id: 'nationality',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Nationality',
              uid: '81b86213b18ca43e',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Afghan',
                'Albanian',
                'Algerian',
                'American',
                'Andorran',
                'Angolan',
                'Argentine',
                'Armenian',
                'Aromanian',
                'Aruban',
                'Australian',
                'Austrian',
                'Azerbaijani',
                'Bahamian',
                'Bahraini',
                'Baltic German',
                'Baltic Russian',
                'Bangladeshi',
                'Barbadian',
                'Belarusian',
                'Belgian',
                'Belizean',
                'Bermudian',
                'Boer',
                'Bosniak',
                'Brazilian',
                'Breton',
                'British',
                'British Virgin Islander',
                'Bruneian',
                'Bulgarian',
                'Burkinabè',
                'Burundian',
                'Cambodian',
                'Cameroonian',
                'Canadian',
                'Cape Verdean',
                'Catalan',
                'Chadian',
                'Chilean',
                'Chinese',
                'Colombian',
                'Comorian',
                'Congolese',
                'Croatian',
                'Cuban',
                'Cypriot',
                'Czech',
                'Dane',
                'Dominican (Commonwealth)',
                'Dominican (Republic)',
                'Dutch',
                'East Timorese',
                'Ecuadorian',
                'Egyptian',
                'Emirati',
                'English',
                'Eritrean',
                'Estonian',
                'Ethiopian',
                'Falkland Islander',
                'Faroese',
                'Fijian',
                'Filipino',
                'Finn',
                'Finnish Swedish',
                'French Citizen',
                'Georgian',
                'German',
                'Ghanaian',
                'Gibraltar',
                'Greek',
                'Greek Macedonian',
                'Grenadian',
                'Guatemalan',
                'Guianese (French)',
                'Guinea Bissau National',
                'Guinean',
                'Guyanese',
                'Haitian',
                'Honduran',
                'Hong Kong',
                'Hungarian',
                'I Kiribati',
                'Icelander',
                'Indian',
                'Indonesian',
                'Iranian',
                'Iraqi',
                'Irish',
                'Israeli',
                'Italian',
                'Ivoirian',
                'Jamaican',
                'Japanese',
                'Jordanian',
                'Kazakh',
                'Kenyan',
                'Korean',
                'Kosovar',
                'Kurd',
                'Kuwaiti',
                'Kyrgyz',
                'Lao',
                'Latvian',
                'Lebanese',
                'Liberian',
                'Libyan',
                'Liechtensteiner',
                'Lithuanian',
                'Luxembourger',
                'Macedonian',
                'Macedonian Bulgarian',
                'Malagasy',
                'Malawian',
                'Malaysian',
                'Maldivian',
                'Malian',
                'Maltese',
                'Manx',
                'Mauritian',
                'Mexican',
                'Moldovan',
                'Mongolian',
                'Montenegrin',
                'Moroccan',
                'Namibian',
                'Nepalese',
                'New Zealander',
                'Nicaraguan',
                'Nigerian',
                'Nigerien',
                'Norwegian',
                'Other',
                'Pakistani',
                'Palauan',
                'Palestinian',
                'Panamanian',
                'Papua New Guinean',
                'Paraguayan',
                'Peruvian',
                'Pole',
                'Portuguese',
                'Puerto Rican',
                'Qatari',
                'Romanian',
                'Russian',
                'Rwandan',
                'Salvadoran',
                'Saudi',
                'Scot',
                'Senegalese',
                'Serb',
                'Sierra Leonean',
                'Sindhian',
                'Singaporean',
                'Slovak',
                'Slovene',
                'Somali',
                'Somalilander',
                'South African',
                'Spaniard',
                'Sri Lankan',
                'St Lucian',
                'Sudanese',
                'Surinamese',
                'Swede',
                'Swiss',
                'Syriac',
                'Syrian',
                'São Tomé And Príncipe',
                'Taiwanese',
                'Tajik',
                'Tanzanian',
                'Thai',
                'Tibetan',
                'Tobagonian',
                'Trinidadian',
                'Tunisian',
                'Turk',
                'Turkish Cypriot',
                'Tuvaluan',
                'Ugandan',
                'Ukrainian',
                'United States',
                'Uruguayan',
                'Uzbek',
                'Vanuatuan',
                'Venezuelan',
                'Vietnamese',
                'Welsh',
                'Yemeni',
                'Zambian',
                'Zimbabwean',
              ],
            },
            {
              id: 'gender',
              type: 'field',
              elementType: 'radio',
              readonly: false,
              label: 'Gender',
              uid: '72e95c05ec4cf91a',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: ['Female', 'Male'],
            },
          ],
        },
        {
          id: 'your-contact-details',
          type: 'list',
          readonly: false,
          label: 'Your Contact Details',
          uid: '7176bc80f1cc1210',
          content: [
            {
              id: 'cell-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Cell Phone',
              uid: '43bb2ffb6f6594b6',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'work-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Work Phone',
              uid: '35332cee5c81c2e8',
              content: '',
              publishChange: true,
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'home-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Home Phone',
              uid: '6ff7f8506395684a',
              content: '',
              publishChange: true,
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'fax',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Fax',
              uid: '55762cb3c5055b4b',
              content: '',
              publishChange: true,
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'email',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Email',
              uid: '99e501a1eafa8e29',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'email',
            },
          ],
        },
        {
          id: 'your-postal-address',
          type: 'list',
          readonly: false,
          label: 'Your Postal Address',
          uid: '2eb0075d48672657',
          content: [
            {
              id: 'life-assured-postal-address',
              type: 'list',
              readonly: false,
              label: 'Life Assured Postal Address',
              uid: '11c2de8188fd8d1b',
              content: [
                {
                  id: 'addressee',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Addressee',
                  uid: 'e301c545cb714ab7',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'po-box-or-private-bag',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'PO Box Or Private Bag',
                  uid: '438a76adb0dd6ffa',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'area-code',
                  type: 'field',
                  elementType: 'datalist',
                  readonly: false,
                  label: 'Area Code',
                  uid: '397fc310b06a8777',
                  content: '',
                  publishChange: true,
                  options: [],
                  partialOptions: true,
                  errors: ['*'],
                },
                {
                  id: 'street',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Street',
                  uid: '14ca763eb4f00bc6',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'suburb',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Suburb',
                  uid: 'c65b69006d719e9f',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'town',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Town',
                  uid: '869fa1a1bea8499a',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Abamasarefan',
                    'Abandze',
                    'Abaoba',
                    'Abasa',
                    'Abasa Ajumaku',
                    'Abasi',
                    'Abdulaikrom',
                    'Abease',
                    'Abenasi',
                    'Abene',
                    'Abesem',
                    'Ablornu',
                    'Aboabo No 2',
                    'Abodom',
                    'Abofoo',
                    'Aboma',
                    'Abomoso',
                    'Abompe',
                    'Aboo',
                    'Abooso',
                    'Abora',
                    'Abora Oboasi',
                    'Abore',
                    'Abra',
                    'Abrekum',
                    'Abuakwa',
                    'Abuom',
                    'Abura Dunkwa',
                    'Aburi',
                    'Abutia - Kloe',
                    'Accra',
                    'Achawa Pt.',
                    'Achiase',
                    'Achimota',
                    'Ada',
                    'Adabokurom',
                    'Adaiso',
                    'Adansi',
                    'Adawsu',
                    'Adidakpavui',
                    'Adidome',
                    'Adidwan',
                    'Adina',
                    'Adjena',
                    'Adobo',
                    'Adonso',
                    'Aduaben',
                    'Aduamoa',
                    'Adubea',
                    'Adubiliyili',
                    'Adukrom',
                    'Adumadum',
                    'Adwamadiem',
                    'Adwufia',
                    'Adwum',
                    'Adzakoe',
                    'Afegame',
                    'Afiaso',
                    'Afienya',
                    'Aflao',
                    'Afosu',
                    'Aframso',
                    'Afratwo',
                    'Agogo',
                    'Agona',
                    'Agona Nkwanta',
                    'Aguna',
                    'Agyana',
                    'Ahamasu',
                    'Ahebensu',
                    'Ahemakurom',
                    'Ahenkro',
                    'Ahobre',
                    'Ahunda',
                    'Ahwerewa',
                    'Ahwiren',
                    'Aiyiribi',
                    'Ajemra',
                    'Ajumako',
                    'Ajumaku',
                    'Akantansu',
                    'Akanu',
                    'Akatsi',
                    'Akim Oda',
                    'Akim Swedru',
                    'Akim Tafo',
                    'Akoasi',
                    'Akontaanim',
                    'Akontanim',
                    'Akoroso',
                    'Akosombo',
                    'Akosombo Juapon',
                    'Akpabanya',
                    'Akra',
                    'Akrofuom',
                    'Akrokerri',
                    'Akropon',
                    'Akropong',
                    'Akumadan',
                    'Akuntuase',
                    'Akuse',
                    'Akwantonbra',
                    'Akwidaa',
                    'Akyekyere',
                    'Akyemfo Gyomuro',
                    'Alenda',
                    'Amamsi',
                    'Amankwakrom',
                    'Amasaman',
                    'Amasu',
                    'Ambo',
                    'Amedzofe',
                    'Amenfi',
                    'Amlakpo',
                    'Amoama',
                    'Amoma',
                    'Amoya',
                    'Ampeda',
                    'Ampeyoo',
                    'Anfoaga',
                    'Anibil',
                    'Ankaasi',
                    'Ankaful',
                    'Ankan',
                    'Anloga',
                    'Anomabo',
                    'Antabia',
                    'Anum',
                    'Anyaboni',
                    'Anyako',
                    'Anyanui',
                    'Anyinam',
                    'Anyinasu',
                    'Anyirawase',
                    'Aobo Aduamoa',
                    'Apam',
                    'Apapasu',
                    'Apataim',
                    'Aperade',
                    'Apesokubi',
                    'Apinso',
                    'Apowa',
                    'Asaafa',
                    'Asaam',
                    'Asaaman',
                    'Asafo',
                    'Asakraka',
                    'Asamankese',
                    'Asankrangua',
                    'Asantekrom',
                    'Asasitre',
                    'Asatowe',
                    'Asawinso',
                    'Asebu',
                    'Asekore',
                    'Asesewa',
                    'Ashanti',
                    'Asiakwa',
                    'Asiekpe',
                    'Asikuma',
                    'Assin Breku',
                    'Assin Manso',
                    'Assin Nyankumase',
                    'Assin Praso',
                    'Asuboa',
                    'Asuderi',
                    'Asukawkaw',
                    'Asumenya',
                    'Asunafo',
                    'Asuom',
                    'Asuotiano',
                    'Asutsuare',
                    'Ataase',
                    'Atebubu',
                    'Atesa',
                    'Atiavi',
                    'Atieku',
                    'Atimpoku',
                    'Atobase',
                    'Atuabo',
                    'Atuna',
                    'Ave-afiadenyiga',
                    'Ave-dakpa',
                    'Avenorpeme',
                    'Aveyime',
                    'Awatia',
                    'Awisa',
                    'Awisan',
                    'Awonakrom',
                    'Awurasa',
                    'Awutu',
                    'Axim',
                    'Ayanfure',
                    'Ayerede',
                    'Ayiase',
                    'Ayiem',
                    'Ayikuma',
                    'Ayinwafe',
                    'Ayomfo',
                    'Baabiaraneha',
                    'Baaglo',
                    'Baantama',
                    'Babile',
                    'Babopi',
                    'Bagape',
                    'Bagbiapi',
                    'Bagmare',
                    'Bagurugu',
                    'Bagwiwu',
                    'Baika',
                    'Bakanta',
                    'Bakau',
                    'Bakoniaba',
                    'Bale',
                    'Bamboi',
                    'Banaso',
                    'Banda',
                    'Banda - Nkwanta',
                    'Bandae',
                    'Bandiyili',
                    'Bangwan',
                    'Banko',
                    'Bansang',
                    'Banso',
                    'Banu',
                    'Barae No 2',
                    'Barra',
                    'Basa',
                    'Basie',
                    'Basse',
                    'Battor',
                    'Batume Junction',
                    'Bau',
                    'Bawdie',
                    'Bawiesibal',
                    'Bawjiase',
                    'Bawka',
                    'Bawku',
                    'Bechem',
                    'Bediakokurom',
                    'Begoro',
                    'Beku',
                    'Bekwai',
                    'Bende',
                    'Benso',
                    'Beposo',
                    'Berekum',
                    'Berekuso',
                    'Besease',
                    'Beyin',
                    'Biadam',
                    'Bibiani',
                    'Bielikpang',
                    'Billaw',
                    'Bimbago',
                    'Bimbagokpasenkpe',
                    'Bimbagu',
                    'Bimbila',
                    'Binaba',
                    'Binduri',
                    'Bisa',
                    'Biung',
                    'Boanim',
                    'Bodi',
                    'Bodwesango',
                    'Bofaso',
                    'Bogdoo',
                    'Bogorono',
                    'Bogorop',
                    'Bogoso',
                    'Bole',
                    'Bolgatanga',
                    'Bombori',
                    'Bomburi Fimbo',
                    'Bomfa',
                    'Bonakye',
                    'Bongo',
                    'Bonkrom',
                    'Bonohoma',
                    'Bonyere',
                    'Booyem',
                    'Bopa',
                    'Boro',
                    'Bortianor',
                    'Bososo',
                    'Bowiri',
                    'Brakwa',
                    'Branam',
                    'Brenase',
                    'Bresuanu',
                    'Brewaniase',
                    'Brikama',
                    'Brikamaba',
                    'Brofuyedru',
                    'Brufut',
                    'Brugbani',
                    'Buachipe',
                    'Buaka',
                    'Bugri',
                    'Bugu',
                    'Buipe',
                    'Bukanor',
                    'Bulangsa',
                    'Bulangsa Torsa',
                    'Bulenga',
                    'Bungeli',
                    'Bunkpurugu',
                    'Burai',
                    'Busa',
                    'Busie',
                    'Busunu',
                    'Butie',
                    'Buya',
                    'Cape Coast',
                    'Cape St. Paul',
                    'Cape Three Pts.',
                    'Carnpaga Sakogu',
                    'Chache',
                    'Chaisa',
                    'Chama',
                    'Chamba',
                    'Changbuni',
                    'Chanyili',
                    'Chase',
                    'Chatali',
                    'Chemfe',
                    'Chepuri',
                    'Cherepon',
                    'Cheropon',
                    'Chia',
                    'Chiana-asuma',
                    'Chichari',
                    'Christiansborg',
                    'Chuchiliga',
                    'Dabala',
                    'Daboase',
                    'Dabori',
                    'Dabova',
                    'Daboya',
                    'Dadeeso',
                    'Dado',
                    'Dadwen',
                    'Dafiama',
                    'Dafo',
                    'Dafor',
                    'Dagonkadi',
                    'Dakpam',
                    'Damanko',
                    'Dambai',
                    'Damongo',
                    'Danko',
                    'Dapa',
                    'Dasanyi',
                    'Datako-kurbor',
                    'Datano',
                    'Dawa',
                    'Dawadawa',
                    'Dawhenya',
                    'Debiribi',
                    'Debiso',
                    'Dedeso Wireko',
                    'Degbila',
                    'Dego',
                    'Demoh',
                    'Dena',
                    'Denkyira',
                    'Densubon',
                    'Denu',
                    'Diabakurom',
                    'Diari',
                    'Diasempa',
                    'Diaso',
                    'Digma',
                    'Digmamandari',
                    'Disiga',
                    'Dixcove',
                    'Dodowa',
                    'Dogokrom',
                    'Domeabra',
                    'Domenase',
                    'Dominase',
                    'Dompem',
                    'Dompoase',
                    'Doninga',
                    'Donkponkwanta',
                    'Doriman',
                    'Dormaa-ahenk',
                    'Doryum',
                    'Drakofe',
                    'Drobo',
                    'Dromakese',
                    'Duaburori',
                    'Duadaso',
                    'Duie',
                    'Dukusen',
                    'Dumienu',
                    'Dunkwa',
                    'Dunkwa On Offin',
                    'Duu',
                    'Dwija',
                    'Dwokwa',
                    'Dyayaw-nkwanta',
                    'Dzana',
                    'Dzita',
                    'Dzodze',
                    'Effiduase',
                    'Eikwe',
                    'Ejian',
                    'Ejisu',
                    'Ejura',
                    'Ekumdipe',
                    'Ekuropon',
                    'Ekyiamenfurom',
                    'Elmina',
                    'Elubo',
                    'Enchi',
                    'Enyan',
                    'Enyanmansu',
                    'Enyinabrim',
                    'Esaaman',
                    'Esalikawu',
                    'Essem',
                    'Essiama',
                    'Esuakrom',
                    'Fajara',
                    'Fanti',
                    'Farafenni',
                    'Farifari',
                    'Fawamam',
                    'Fenyi Yokoe',
                    'Feo-nabisi',
                    'Fete',
                    'Fetenta',
                    'Fian',
                    'Fianko',
                    'Fimbo',
                    'Fomena',
                    'Foso',
                    'Frankadua',
                    'Fuchmbluma',
                    'Fufulsu',
                    'Fufuo',
                    'Fumbisi',
                    'Fumsi',
                    'Fumso',
                    'Fuu',
                    'Ga',
                    'Gaa',
                    'Gambia No 1',
                    'Gambia No 2',
                    'Gbal',
                    'Gbalga',
                    'Gbangdaa',
                    'Gbantala',
                    'Gbari',
                    'Gbasinkpa',
                    'Gbateto',
                    'Gbedema',
                    'Gbeini',
                    'Gbemfo',
                    'Gbeni',
                    'Gbimsi',
                    'Gbogu',
                    'Gbulumpe',
                    'Gbung',
                    'Gida',
                    'Gitase',
                    'Gnani',
                    'Goantala',
                    'Goaso',
                    'Goka',
                    'Gokoron',
                    'Golokuati',
                    'Gonnokurom',
                    'Gorgu',
                    'Gpapga',
                    'Grube',
                    'Grupe',
                    'Gulibikomonpa',
                    'Guri',
                    'Guru-natinga',
                    'Gushie',
                    'Gwallo',
                    'Gwellukupulima',
                    'Gwo',
                    'Gwosi',
                    'Gyapa',
                    'Gyedua',
                    'Gyekiti',
                    'Gyema',
                    'Gyenegyene',
                    'Half Assini',
                    'Hamale',
                    'Harglakarpe',
                    'Have Etoe',
                    'Helu',
                    'Heluivi',
                    'Heman',
                    'Hevi',
                    'Hian',
                    'Hiawoanwu',
                    'Ho',
                    'Hohoe',
                    'Honuta',
                    'Huhunya',
                    'Hunt Valley',
                    'Huntodo',
                    'Hwidiem',
                    'Imbraim',
                    'Issa',
                    'Jaba',
                    'Jadima',
                    'Jafor',
                    'Jakai',
                    'Jakobo',
                    'Jamasi',
                    'Jamra',
                    'Jandra',
                    'Jang',
                    'Janjangbureh',
                    'Jasikan',
                    'Jato',
                    'Jawani',
                    'Jeffisi',
                    'Jegbuni',
                    'Jeinsusa',
                    'Jema',
                    'Jemile',
                    'Jerigu',
                    'Jeshwang',
                    'Jewi Wharf',
                    'Jeyiri',
                    'Jimdabuo',
                    'Jinfranukasuridabarshe',
                    'Jinpasi',
                    'Jirapa',
                    'Jogboi',
                    'Jombo',
                    'Juaben',
                    'Juabeso',
                    'Juana',
                    'Juapon',
                    'Juaso',
                    'Jumo',
                    'Junle',
                    'Juo',
                    'Kaase',
                    'Kabaka',
                    'Kablima',
                    'Kabonwele',
                    'Kade',
                    'Kadema',
                    'Kadjebi',
                    'Kafaba',
                    'Kafodzidzi',
                    'Kafuta',
                    'Kakawere',
                    'Kakorasua',
                    'Kakum',
                    'Kalagie',
                    'Kalba',
                    'Kaleo',
                    'Kalini',
                    'Kamsorio',
                    'Kananta',
                    'Kandige',
                    'Kanga',
                    'Kanifing',
                    'Kanilie',
                    'Kanjaga',
                    'Kanseri',
                    'Kantani',
                    'Kanyaga',
                    'Kapro',
                    'Kapua',
                    'Karaga',
                    'Karamenga',
                    'Karni',
                    'Kasa',
                    'Kasapen',
                    'Kasoa',
                    'Kasuridabarshe',
                    'Kataijeli',
                    'Katanga',
                    'Katanka',
                    'Katiu',
                    'Kawga',
                    'Kechebi',
                    'Kedzi',
                    'Kensere',
                    'Kenyasi No 1',
                    'Keta',
                    'Kete Krache',
                    'Kibi',
                    'Kikam',
                    'Kimabui',
                    'Kintampo',
                    'Kissi',
                    'Klavw',
                    'Klikor - Agbozume',
                    'Kobresu',
                    'Kodee',
                    'Kofi Djan',
                    'Kofiase',
                    'Koforidua',
                    'Kogota',
                    'Kogyei',
                    'Kokoben',
                    'Kokofu',
                    'Kokoso',
                    'Kokuma',
                    'Kologo',
                    'Kololi',
                    'Komampo',
                    'Komenda',
                    'Komonpa',
                    'Konfogsi',
                    'Kong',
                    'Kongo',
                    'Konkori',
                    'Kononga',
                    'Kopota',
                    'Kotoko',
                    'Kotu',
                    'Kotua',
                    'Koue',
                    'Kpadui',
                    'Kpagto',
                    'Kpalbe',
                    'Kpalbus',
                    'Kpalguni',
                    'Kpalisogu',
                    'Kpandae',
                    'Kpandu',
                    'Kpanianba',
                    'Kparekpare',
                    'Kparigu',
                    'Kpasa',
                    'Kpasenk',
                    'Kpatinga',
                    'Kpedze',
                    'Kpedzeglo',
                    'Kpembe',
                    'Kpetchu',
                    'Kpetoe',
                    'Kpeve',
                    'Kpoglo',
                    'Kpone',
                    'Kpong',
                    'Kpotsu',
                    'Kptokuomtoas',
                    'Kraboa Coaltar',
                    'Krakurom',
                    'Kroba',
                    'Krobo',
                    'Krokosue',
                    'Krokrobite',
                    'Ksauridabarshe',
                    'Kubari',
                    'Kudani',
                    'Kugnani',
                    'Kugnanidaboya',
                    'Kugri Natinga',
                    'Kuikpanga',
                    'Kujopere',
                    'Kukabila',
                    'Kukuo',
                    'Kukuom',
                    'Kukurantumi',
                    'Kulkpanga',
                    'Kulmae',
                    'Kulmasa',
                    'Kulun',
                    'Kulungugu',
                    'Kulupene',
                    'Kumasi',
                    'Kumawu',
                    'Kumbungu',
                    'Kumfa',
                    'Kunchoge Basirisan',
                    'Kundang',
                    'Kunkwa',
                    'Kunso',
                    'Kuntanse',
                    'Kuntunso',
                    'Kupullma',
                    'Kusawgu',
                    'Kusebi',
                    'Kusinaba',
                    'Kute',
                    'Kutukrom',
                    'Kwaben',
                    'Kwabenadapaa',
                    'Kwadwokurom',
                    'Kwadwonkromkurom',
                    'Kwahu Tafo',
                    'Kwaihunu',
                    'Kwakwanya',
                    'Kwaman',
                    'Kwame Danso',
                    'Kwame Kwasi',
                    'Kwamedwamenakurom',
                    'Kwameseikurom',
                    'Kwanyaku',
                    'Kwawu',
                    'Kwesiadai',
                    'Kyekyewere',
                    'Kyeraa',
                    'Kyeremasu',
                    'Kyinderi',
                    'Labadi',
                    'Labo',
                    'Lambusie',
                    'Lamin',
                    'Lampel',
                    'Lampurgo',
                    'Langantre',
                    'Langasi',
                    'Langbinsi',
                    'Lanja',
                    'Larabanga',
                    'Larekaga',
                    'Larteh',
                    'Laura',
                    'Lawra',
                    'Lepusi',
                    'Lilikse Nankpawie',
                    'Lobi',
                    'Loggo',
                    'Lolonya',
                    'Longoro',
                    'Lovi',
                    'Luari',
                    'Lungbunga',
                    'Lungni',
                    'Lziri',
                    'Maasu',
                    'Maknkuma',
                    'Makongo',
                    'Malume',
                    'Mamfe',
                    'Mampaten',
                    'Mampon',
                    'Mampong',
                    'Mampoten',
                    'Mandari',
                    'Mankarigy',
                    'Mankessim',
                    'Mankpan',
                    'Mankranso',
                    'Mankuma',
                    'Mankwadze',
                    'Mansing',
                    'Manso',
                    'Manso Nkwanta',
                    'Mantukwa',
                    'Masaka',
                    'Matse',
                    'Mawuabammu',
                    'Mengwe',
                    'Mengyi',
                    'Menusu',
                    'Meriche',
                    'Mim',
                    'Mirigu-nabaanga',
                    'Mishue',
                    'Moglo',
                    'Mogono',
                    'Mojina',
                    'Moree',
                    'Moseaso',
                    'Motigy',
                    'Mpana',
                    'Mpasaso',
                    'Mpasem',
                    'Mpataba',
                    'Mpatoam',
                    'Mpeam',
                    'Mpohor',
                    'Mpraeso',
                    'Mrigu-nabara',
                    'Mumford',
                    'Muna',
                    'Mungu',
                    'Murugu',
                    'Nabari',
                    'Nabisi',
                    'Nabogo',
                    'Nabolo',
                    'Nabori',
                    'Nadiyili',
                    'Nafkoliga',
                    'Naga',
                    'Naga Pwalugu',
                    'Nagbo',
                    'Nakong-atinia',
                    'Nakpali',
                    'Nakpayili',
                    'Nakundugu',
                    'Nakwa',
                    'Nakwabi',
                    'Nalerigu',
                    'Namanso',
                    'Namasua',
                    'Nambiri',
                    'Namvili',
                    'Nandawli',
                    'Nandom',
                    'Nangodi',
                    'Nangruma',
                    'Nankanduri',
                    'Nanton',
                    'Naro',
                    'Nasia',
                    'Natinga',
                    'Natog',
                    'Nauli',
                    'Navrongo',
                    'Nawuni',
                    'Nawuniyipala',
                    'Nereso',
                    'New Abirem',
                    'New Debiso',
                    'New Edubiase',
                    'New Longoro',
                    'New Ningo',
                    'New Town',
                    'New Worabon',
                    'Nframa',
                    'Nkasiem',
                    'Nkawie',
                    'Nkawkaw',
                    'Nkenkasu',
                    'Nketepa',
                    'Nkoranza',
                    'Nkroful',
                    'Nkuntunso',
                    'Nkwaben',
                    'Nkwanta',
                    'Nobekaw',
                    'Nogokpo',
                    'Npuasu',
                    'Nsaba',
                    'Nsawam',
                    'Nsawkaw',
                    'Nsinsin',
                    'Nsuaem',
                    'Nsuatre',
                    'Nsuta',
                    'Nteresa',
                    'Ntereso',
                    'Ntronan',
                    'Nungua',
                    'Nwereme',
                    'Nyakrom',
                    'Nyame Bekyere',
                    'Nyamease',
                    'Nyameyeadom',
                    'Nyang',
                    'Nyankpala',
                    'Nyankumase',
                    'Nyanyaano',
                    'Nyensungi',
                    'Nyinahin',
                    'Nyive',
                    'Nzema',
                    'Obawale',
                    'Obo',
                    'Obogu',
                    'Obomeng',
                    'Obuase',
                    'Obuasi',
                    'Odahu',
                    'Odami',
                    'Odonkawkrom',
                    'Odumase',
                    'Odumasi',
                    'Odumasua',
                    'Oduponkpebe',
                    'Ofaose',
                    'Ofe',
                    'Offinso',
                    'Ofoase',
                    'Oforikurom',
                    'Ohiamankyene Kpetchu',
                    'Ohyiayeanisa',
                    'Ojobi',
                    'Okomfookurom',
                    'Old Edabo',
                    'Old Ningo',
                    'Oseekodwokurom',
                    'Osenase',
                    'Osino',
                    'Osudoku',
                    'Otekpolu',
                    'Otrokpe',
                    'Owusukuro',
                    'Pabire',
                    'Paga',
                    'Palangasi',
                    'Palari',
                    'Pampawie',
                    'Pankron',
                    'Panyane',
                    'Papase',
                    'Patriensa',
                    'Pawa',
                    'Peblastre',
                    'Pechi',
                    'Peki',
                    'Pelungo',
                    'Pemenasi',
                    'Perisi',
                    'Pigu',
                    'Pina',
                    'Piong',
                    'Pisie',
                    'Pisigu',
                    'Poano',
                    'Poasi',
                    'Pokoasi',
                    'Polmakom',
                    'Pomadze',
                    'Pong-tamale',
                    'Pramkese',
                    'Prampram',
                    'Prang',
                    'Prestea',
                    'Primase',
                    "Prince's Town",
                    'Pulo',
                    'Pumpuni',
                    'Pusiga',
                    'Pusuga',
                    'Pute',
                    'Pwalugu',
                    'Saani',
                    'Sabari',
                    'Saboba',
                    'Sabonjida',
                    'Sabuli',
                    'Sabusa',
                    'Sabzundi',
                    'Sagadugu',
                    'Sakai',
                    'Sakala',
                    'Sakode - Etae',
                    'Sakomono',
                    'Sakpa',
                    'Salaga',
                    'Salkpang',
                    'Saltpond',
                    'Samambow',
                    'Samanhyia',
                    'Sambo',
                    'Sambreboe',
                    'Sambrun',
                    'Sambu',
                    'Samila',
                    'Samoa',
                    'Sampa',
                    'Sampemo',
                    'Sampraso',
                    'Sandema',
                    'Sang',
                    'Sangbaa',
                    'Sankalaa',
                    'Sankore',
                    'Sankpala',
                    'Sanujan',
                    'Sanyang',
                    'Sapiegu',
                    'Saranouse',
                    'Saripere',
                    'Sarpere Ambo',
                    'Sarri',
                    'Saru',
                    'Savelugu',
                    'Sawla',
                    'Seekwa',
                    'Sefwi Anhwiaso',
                    'Sefwi Bekwai',
                    'Sefwi Wiawso',
                    'Segbasi',
                    'Sekasua',
                    'Seketia',
                    'Sekondi Takoradi',
                    'Sekpe',
                    'Sekyedumase',
                    'Sekyerekurom',
                    'Sema',
                    'Senase',
                    'Senchi',
                    'Seneso',
                    'Senfi',
                    'Senkyiem',
                    'Senya Bereku',
                    'Seripe',
                    'Serrekunda',
                    'Sese',
                    'Seseamam',
                    'Shama',
                    'Shi',
                    'Shia',
                    'Shiega',
                    'Shieni',
                    'Silibele',
                    'Sinebaga',
                    'Singa',
                    'Siriminchu',
                    'Sogakope',
                    'Soma(mansakonko)',
                    'Somanya',
                    'Sombisi',
                    'Soo',
                    'Sovie',
                    'Srogbae',
                    'Subri',
                    'Suggo',
                    'Suhum',
                    'Sukuta',
                    'Sumiso',
                    'Sumsei',
                    'Sung',
                    'Sunsan',
                    'Sunyani',
                    'Surukrom',
                    'Swedru',
                    'Taali',
                    'Tachiman',
                    'Tachimantia',
                    'Tadieso',
                    'Tadzevu',
                    'Taenso',
                    'Tahima',
                    'Takoradi',
                    'Takpo',
                    'Tamale',
                    'Tamale Port',
                    'Tamaligu',
                    'Tampion',
                    'Tamso',
                    'Tanaso',
                    'Tangasia',
                    'Tanina',
                    'Tanji',
                    'Tanoso',
                    'Tantali',
                    'Tarkwa',
                    'Tarongo',
                    'Tatale',
                    'Teainano',
                    'Techigu',
                    'Teekyere',
                    'Tefle',
                    'Tema',
                    'Tenfian',
                    'Teselima',
                    'Teshi',
                    'Tetema',
                    'Tetrem',
                    'Tijo',
                    'Tikobo No 1',
                    'Tikobo No 2',
                    'Tikpang',
                    'Tilli',
                    'Tinga',
                    'Tolon',
                    'Tongo',
                    'Torsa',
                    'Toyanyili',
                    'Trede',
                    'Trom',
                    'Tromeso',
                    'Tsito',
                    'Tsrefe',
                    'Tugu',
                    'Tuluwe',
                    'Tumfakura',
                    'Tumu',
                    'Tuna',
                    'Tuobodom',
                    'Turumi',
                    'Tutukpene',
                    'Tutumi',
                    'Tuwuo',
                    'Twenene',
                    'Twifo Praso',
                    'Twifo-mampon',
                    'Ugando',
                    'Vaggo',
                    'Vakpo',
                    'Vamboi',
                    'Wa',
                    'Waiasi',
                    'Wakawaka',
                    'Walembele',
                    'Walewale',
                    'Wam',
                    'Wamanafo',
                    'Wamfie',
                    'Wangasi - Turu',
                    'Waniada',
                    'Wapuli',
                    'Warinvaga',
                    'Wasipe',
                    'Waso',
                    'Watoro',
                    'Waya',
                    'Wechiau',
                    'Wegbe',
                    'Weija',
                    'Wenchi',
                    'Wenchiki',
                    'Wiaga-yisobso',
                    'Wiamoase',
                    'Wiasi',
                    'Winisa',
                    'Winkogo',
                    'Winneba',
                    'Wiosa',
                    'Woe',
                    'Worakese',
                    'Worawora',
                    'Wuinta',
                    'Wulensi',
                    'Wulensimpana',
                    'Wumenu',
                    'Wungu',
                    'Wurnpigo',
                    'Wuru',
                    'Wute',
                    'Yabraso',
                    'Yabum',
                    'Yagaba',
                    'Yahaiyape',
                    'Yakase',
                    'Yala',
                    'Yamatwa',
                    'Yankoman',
                    'Yankzia',
                    'Yapei',
                    'Yapessa',
                    'Yawga',
                    'Yazori',
                    'Yendi',
                    'Yepalsi',
                    'Yezes',
                    'Yikpabango',
                    'Yipala',
                    'Yundum',
                    'Zabzugu',
                    'Zanduli',
                    'Zankum',
                    'Zariboku',
                    'Zebilla',
                    'Zegbeli',
                    'Zin',
                    'Zion',
                    'Zlope',
                    'Zoggo',
                    'Zoko',
                    'Zongo-markyeri',
                    'Zuarugu',
                    'Zuro',
                  ],
                },
                {
                  id: 'region',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Region',
                  uid: '2018c28ec820aae9',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Ahafo',
                    'Ashanti',
                    'Bono East',
                    'Brong Ahafo',
                    'Central',
                    'Eastern',
                    'Greater Accra',
                    'North East',
                    'Northern',
                    'Oti',
                    'Savannah',
                    'Upper East',
                    'Upper West',
                    'Volta',
                    'Western',
                    'Western North',
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'your-employment-details',
          type: 'list',
          readonly: false,
          label: 'Your Employment Details',
          uid: '2921fce291b62b4c',
          content: [
            {
              id: 'employer-organisation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Employer Organisation',
              uid: '37fb2b29cf036863',
              content: '',
              publishChange: true,
              options: [
                '',
                'Abokobi Area Rural Bankstaff',
                'Accra Metro Assembly',
                'Adansi Rural Bank',
                'Advans Ghana',
                'Advent Press',
                'African Regent Hotel',
                'Agave Rural Bank',
                'Agric Development Bank',
                'Ahafo Ano Premier Rural Bank',
                'Ahantaman Rural Bankâ ',
                'Akaa Security Services',
                'Akatakyiman Rural Bank',
                'Akim Bosome Rural Bank',
                'Akwapem Rural Bank',
                'Aluworks',
                'Ama Dansowaa Clinic',
                'Amanano Rural Bank',
                'Amansie West Rural Bank',
                'Amatin & Kasei Comm Bank',
                'Antrak Ghana Ltd',
                'Anum Rural Bank Ltd',
                'Apex Bank',
                'Ashante Akyem Rural Bank',
                'Asokore Rural Bank Ltd - Kumasi',
                'Asutifi Rural Bank Limited',
                'Atlantic Int. Holding Co',
                'Atwima Kwanwoma Rural Bank',
                'Atwima Rural Bank',
                'Auto Parts Limited',
                'Awutu Emasa Rural Bank',
                'Ayrton Drug Manufacturing Limited',
                'Bank of Ghana',
                'Baptist Medical Centre',
                'Barge Operators and Allied Services Ltd',
                'Benso Oil Palm Plantationâ ',
                'Bessfa Rural Bank',
                'Bible Society of Ghana',
                'Bonzali Rural Bank',
                'Bosomtwe Rb',
                'Bureau of National Communication',
                'Bureau of National Investigations',
                'Busua Beach Resortâ ',
                'Capital Rural Bank',
                'Carmeuse Lime Productâ ',
                'Cejose Wisdom Int. School',
                'Chemico Limited',
                'Chirano Gold Mines',
                'Christ Ebenezer',
                'Church Hill of God',
                'Cocoa Marketiing Company Ltd',
                'Cocoa Processing Comp',
                'Controller',
                'Cssvd Control Unit',
                'Dangme Rural Bank Ltd - Staff',
                'De Youngsters International School Ltd',
                'Deoke Foundation Schools',
                'Derma Area Rural Bank',
                'Dizengoff Ghana Ltd',
                'Dynamic Insurance Brokers',
                'Ebennezer Osei Brenya Enterprise',
                'Ejuraman Rural Bank',
                'Electricity Company of Ghana',
                'Elmina Beach Resort',
                'Englebert School',
                'Enterprise Funeral Services',
                'Enterprise Group Ltd',
                'Enterprise Insurance',
                'Enterprise Life Reps',
                'Enterprise Life Staff',
                'Enterprise Properties Ltd',
                'Enterprise Trustees Limited',
                'Epa',
                'Eskay Therapeutics Ltd',
                'Fiagya Rural Bank Ltd',
                'Fiaseman Rural Bankâ ',
                'Fiesta Royale Hotel',
                'First National Savings and Loansâ ',
                'Forces Pay Office',
                'Ga East Municipal Assembly',
                'Ga Rural Bank',
                'Ga Rural Customers',
                'Gafco',
                'Garden City - Multi Credit',
                'Gcb Bank Ltd Staff',
                'Ghana Airport Company Ltd.',
                'Ghana Bauxite Company Limted',
                'Ghana Cement',
                'Ghana Chamber of Mines',
                'Ghana Civil Aviation Authority',
                'Ghana Cocoa Board',
                'Ghana Grid Company',
                'Ghana Heavy Equipment Limited',
                'Ghana Link Network Services Ltd',
                'Ghana Maritime Authority',
                'Ghana Medical School',
                'Ghana Oil Co. Ltd',
                'Ghana Police Service',
                'Ghana Post',
                'Ghana Railway Co. Ltd',
                'Ghana Re Company Limited',
                'Ghana Revenue Authority',
                'Ghana Standard Board',
                'Ghana Union Conference of s.d.a.',
                'Gihoc Distilleries Company Limited',
                'Gimpa',
                'Globeserve Ministry Intâ ',
                'Golden Exotics Ltd',
                'Goldengate Services Ltd',
                'Gpha - Takoradi',
                'Gpha - Tema',
                'Grace Baptist School - Kumasi',
                'Guiness Ghana Ltd',
                'Gwcl - Accra East',
                'Gwcl - Accra West',
                'Gwcl - Ashanti North',
                'Gwcl - Ashanti Production',
                'Gwcl - Ashanti South',
                'Gwcl - Cape Coast',
                'Gwcl - Greater Accra Region Production',
                'Gwcl - Head Office',
                'Gwcl - Koforidua',
                'Gwcl - Sunyani',
                'Gwcl - Takoradi',
                'Gwcl - Tema',
                'Hobats Clinic',
                'Holiday Inn Accra',
                'Home Finance Company Bank Ltd',
                'House of Compassion Ministries',
                'Japan Motors Trading Company',
                'Juaben Rural Bank',
                'Jubilee Shipping Services Gh Ltd',
                'k.n.u.s.t',
                'Kaaseman Rural Bank Ltd',
                'Kessben',
                'Kingsgroup of Companies',
                'Kintampo Health Research Centre',
                'Kintampo Rural Bank Limited',
                'Koforidua Stop Order Walk In',
                'Korle Bu Teaching Hospital',
                'Krane Contsruction',
                'Krobo Danish Association',
                'Kuapa Kokoo',
                'Kumawuman Rural Bnk',
                'Kwamaman Rural Bank',
                'La Nkwantanang Madina Municipal Assembly',
                'Labadi Beach Hotel',
                'Life Healthcare Medical Dental and Optical Clinic',
                'Living Star School',
                'Lordship Insurance Brokers',
                'Lower Pra Rural Bank',
                'Maxam Ghana Ltd',
                'Mechanical Lloyd',
                'Metromass',
                'Mojo Investment Ltd',
                'Mondial Veneer Gh. Ltd',
                'Mumuada Rural Bank',
                'Nafana Rural Bank Ltd',
                'National Security',
                'Nexans',
                'Nsoatreman Rural Bank',
                'Nthc Properties Ltd',
                'Nwabiagya Rural Bank',
                'Okomfo Anokye Rural Bank Staff',
                'Opportunity Int',
                'Parliament',
                'Pbc Ltd',
                'Pentecost Preparatory Schoolâ ',
                'Pentecost University College',
                'Phyto Riker',
                'Poly Products',
                'Presbyterian Womens Center - Abokobi',
                'Prince of Peace',
                'Quality Control Division (Cocobod)',
                'Regional Maritime Academy',
                'Ridge Church School',
                'Safebound Company Limited - Tema',
                'Sakworg Company Ltd',
                'Samartex Timber and Plywood Company',
                'Sandvik Mining and Construction Limited',
                'Scanbech Ghana Ltd',
                'Sdv Ghana',
                'Securities Discount Company Limited',
                'Sekyedumase Rural Bank',
                'Sekyere Rural Bank',
                'Services Intergrity Savings and Loans',
                'Sgs Laboratory Services',
                'Sg Ssb',
                'Silver Star Auto Ltd',
                'Sinapi Aba Trust',
                'Sofaamy Company Limited',
                'Sos Childrens Village Ghana',
                'South Birim Rural Bank',
                'South Central Conference of Sda',
                'Special Ice Company Ltd',
                'Ssnit',
                'St Ambrose College',
                'St Michaels Hospital Pramso',
                'Stellar Logistic',
                'Sunyani Cluster Icps',
                'Takoradi Flour Mill Ltd',
                'Takoradi Int. Co.',
                'Tct',
                'Tema Development Corp.',
                'Tema Parents Association School',
                'Tesano Baptist Church',
                'The Church of Pentecost',
                'Toende Rural Bank',
                'Trasacco',
                'Twifo Oil Palm Plantation',
                'Type Company Ltd',
                'Unilever',
                'University College of Education',
                'University for Development Studies',
                'University of Energy and Natural Resources',
                'University of Ghana - Legon',
                'Upper Amenfi Rural Bank',
                'Upper Manya Kro Rural Bank',
                'Utrak Savings and Loans',
                'Valley View',
                'Valley View University Basic School',
                'Vat Service',
                'Volta Hotel',
                'Volta River Authority',
                'Waec - Headquarters',
                'Waec - Main',
                'West Africa Mills Co. Ltd',
                'Weto Rural Bank',
                'Winners Chapel Ghana',
                'Yaakson Herbal Centre',
                'Yarsako',
              ],
            },
            {
              id: 'employee-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Employee Number',
              uid: 'a4e5d609330ca962',
              content: '',
              publishChange: true,
              inputType: 'text',
            },
            {
              id: 'basic-earnings',
              type: 'amount',
              elementType: 'input',
              readonly: false,
              label: 'Basic Earnings',
              uid: '7900c8db465dd427',
              content: '',
              publishChange: true,
              inputType: 'number',
            },
            {
              id: 'occupation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Occupation',
              uid: 'd53adfe735260d46',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Abattoir Worker',
                'Accountant',
                'Accounting Officer',
                'Actor',
                'Actress',
                'Actuary',
                'Admin Assistant',
                'Administrator',
                'Advertising Agent',
                'Advocate',
                'Affal Washer',
                'Agriculture Assistant',
                'Agriculture Officer',
                'Air Force',
                'Air Force Ground Crew',
                'Aircraft Engineer',
                'Airline Employee',
                'Airline Executive',
                'Airline Pilot',
                'Ambulance Attendant',
                'Ambulance Driver',
                'Announcer',
                'Architect',
                'Artisan',
                'Artist',
                'Assessor',
                'Auctioneer',
                'Auditor',
                'Author',
                'Auto Electrician',
                'Auxillary Worker',
                'Aviation',
                'Baker',
                'Bank Teller',
                'Bank-Manager',
                'Banker',
                'Bar Lady',
                'Bar Steward',
                'Barber',
                'Barmaid',
                'Barman',
                'Beautician',
                'Blacksmith',
                'Blockman',
                'Boilermaker',
                'Bookbinder',
                'Bookkeeper',
                'Bookmaker',
                'Boring Contractor',
                'Bottle Store Assistant',
                'Bottle Store Manager',
                'Boxer',
                'Bricklayer',
                'Broker',
                'Builder',
                'Building Contractor',
                'Building Foreman',
                'Building Inspector',
                'Bus Driver',
                'Butcher',
                'Buyer',
                'Cabinetmaker',
                'Cafe Owner',
                'Cameraman',
                'Cardiologist',
                'Caretaker',
                'Carpenter',
                'Carpet Layer',
                'Cashier',
                'Caterer',
                'Checker',
                'Chef',
                'Chief Officer',
                'Chiropodist',
                'Civil Defense Officer',
                'Cleaner',
                'Clergyman',
                'Clerk',
                'Cold Storage Worker',
                'Commercial Photographer',
                'Compositor-Electronic',
                'Compositor-Mechanical',
                'Computer Engineer',
                'Computer Operator',
                'Computer Programmer',
                'Computer Systems Analyst',
                'Computer Technician',
                'Conductor',
                'Constable',
                'Construction Worker',
                'Consultant',
                'Cook',
                'Copywriter',
                'Councillor',
                'Courier',
                'Cropduster',
                'Croupier',
                'Customs Official',
                'Dairy Worker',
                'Dancing Teacher',
                'Dean',
                'Demolition Worker',
                'Dental Assistant',
                'Dental Technician',
                'Dentist',
                'Detective',
                'Diamond Cutter',
                'Dietician',
                'Director',
                'Display Artist',
                'Diver',
                'Dockworker',
                'Doctor',
                'Domestic',
                'Draughtsman',
                'Dressmaker',
                'Driller',
                'Driver',
                'Dry Cleaner',
                'Economist',
                'Editor',
                'Education Inspector',
                'Educator',
                'Electrical Contractor',
                'Electrician',
                'Electronics Engineer',
                'Electroplater',
                'Employee',
                'Engineer',
                'Engineer-Qualified',
                'Engraver',
                'Entomologist',
                'Estate Agent',
                'Executive',
                'Explosives Worker',
                'Exterminator',
                'Farm Worker',
                'Farmer Foreman',
                'Farmer Manager',
                'Farmer Owner',
                'Fashion Designer',
                'Film Maker',
                'Fireman',
                'Fisherman',
                'Fitter And Turner',
                'Floor Surfacer',
                'Florist',
                'Foreman',
                'Forester',
                'Fortune Teller',
                'Foundry Manager',
                'Foundry Worker',
                'Freight Forwarder',
                'Fumigator',
                'Furnace Assistant',
                'Furnace Manager',
                'Furnace Supervisor',
                'Game Ranger',
                'Game Warden',
                'Garage Attendant',
                'Garage Owner',
                'Gardener',
                'General Assistant',
                'General Dealer',
                'General Worker',
                'Geologist',
                'Glassblower',
                'Glazier',
                'Goldsmith',
                'Grain Silo Operator',
                'Graphic Designer',
                'Greengrocer',
                'Groundsman',
                'Guard (Security)',
                'Gunsmith',
                'Hairdresser',
                'Hawker',
                'Head Of Department',
                'Headmaster',
                'Health Inspector',
                'Homeopath',
                'Horticulturist',
                'Hotel Manager',
                'Housewife',
                'Hr Assistant',
                'Inspector',
                'Insurance Agent',
                'Insurance Assessor',
                'Interior Decorator',
                'Interpreter',
                'Investigator',
                'Jeweller',
                'Jockey',
                'Joiner',
                'Journalist',
                'Labourer',
                'Lawyer',
                'Lecturer',
                'Librarian',
                'Lift Inspector',
                'Lift Operator',
                'Lift Technician',
                'Lighthouse Keeper',
                'Locksmith',
                'Machine Operator',
                'Machinist',
                'Magistrate',
                'Major',
                'Manager',
                'Manicurist',
                'Manufacturer',
                'Market Researcher',
                'Marketing Officer',
                'Martial Arts Instructor',
                'Mason',
                'Meat Inspector',
                'Mechanic',
                'Medical Representative',
                'Medical Technologist',
                'Medical-Practitioner',
                'Merchandiser',
                'Merchant Seaman',
                'Messenger',
                'Metallurgist',
                'Meter Reader',
                'Midwife',
                'Millwright',
                'Miner',
                'Minister Of Religion',
                'Model',
                'Motor Salesman',
                'Motor Spares Salesman',
                'Moulder',
                'Musician',
                'Naval Personnel',
                'Night Watchman',
                'Nurse',
                'Nurseryman',
                'Nursing Assistant',
                'Nursing Sister',
                'Occupational Therapist',
                'Officer',
                'Operator',
                'Ophthalmologist',
                'Optical Mechanic',
                'Orthopedic Orthopedist',
                'Packer',
                'Painter',
                'Painting Contractor',
                'Panelbeater',
                'Paramedic',
                'Pastor',
                'Pawnbroker',
                'Pensioner',
                'Permanent Way',
                'Personnel Officer',
                'Pest Exterminator',
                'Petrol Attendant',
                'Petroleum Refinery',
                'Pharmacist',
                'Photographer',
                'Physiotherapist',
                'Piano Tuner',
                'Pilot',
                'Plasterer',
                'Plumber',
                'Police Constable',
                'Police Woman',
                'Policeman',
                'Porter',
                'Postman',
                'Postmaster',
                'Principal',
                'Printer',
                'Prisons Personnel',
                'Private Investigator',
                'Producer',
                'Professor',
                'Programmer',
                'Property Appraiser',
                'Proprietor',
                'Public Prosecutor',
                'Public Relations Officer',
                'Quality Controller',
                'Quantity Surveyor',
                'Quarryman',
                'Racehorse Trainer',
                'Radiographer',
                'Radiologist',
                'Receptionist',
                'Religious Leader',
                'Repairman',
                'Representative',
                'Research Assistant',
                'Restaurant Owner',
                'Retired',
                'Rigger (Docks)',
                'Roadworker',
                'Sailor',
                'Sales Consultant',
                'Sales Lady',
                'Sales Support',
                'Sales Woman',
                'Salesman',
                'Seaman',
                'Seaman (Merchant)',
                'Seamstress',
                'Secretary',
                'Security Guard',
                'Self-Employed',
                'Sergeant',
                'Shedman',
                'Sheet Metal Worker',
                'Shipping Agent',
                'Shop Assistant',
                'Shop Manager',
                'Shopfitter',
                'Shunter-Foreman',
                'Signwriter',
                'Silversmith',
                'Singer',
                'Slaughterer',
                'Smelter',
                'Social Worker',
                'Soldier',
                'Sound Engineer',
                'Sportsman',
                'Sportswoman',
                'Spray Painter',
                'Sprayer',
                'Stationmaster',
                'Statistician',
                'Stevedore',
                'Steward',
                'Stock Broker',
                'Store Lady',
                'Storeman',
                'Student',
                'Supervisor',
                'Surgeon',
                'Sweeper',
                'Swimming Pool Attendant',
                'Switchboard Operator',
                'Systems Analyst',
                'Tailor',
                'Taxi Driver',
                'Teacher',
                'Technician',
                'Telegraphist',
                'Telephone Technician',
                'Telephonist',
                'Tiler',
                'Toolmaker',
                'Town Clerk',
                'Town Planner',
                'Trader',
                'Tradesman',
                'Traffic Officer',
                'Trainer',
                'Translator',
                'Travel Agent',
                'Typesetter',
                'Typist',
                'Undertaker',
                'Underwriter',
                'Unemployed',
                'Upholsterer',
                'Urologist',
                'Vetrinarian',
                'Vetrinary Surgeon',
                'Vulcaniser',
                'Waiter',
                'Waitress',
                'Warden',
                'Watch Maker',
                'Watchman',
                'Welder',
                'Well Sinker',
                'Window Cleaner',
                'Window Dresser',
                'Wood Cutter',
                'Worker',
                'Wrestler',
              ],
            },
            {
              id: 'debit-order-day',
              type: 'integer',
              elementType: 'input',
              readonly: false,
              label: 'Debit Order Day',
              uid: '7c6ee9695f64b5aa',
              content: '',
              publishChange: true,
              inputType: 'number',
            },
            {
              id: 'salary-payment-frequency',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Salary Payment Frequency',
              uid: 'b1b1a14135a1391b',
              content: '',
              publishChange: true,
              options: ['', 'Monthly', 'Weekly'],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'e897c4b6d173b8f9',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'eec35610aaad9e47',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '3a7c4bc25f80439d',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'c5ff56a5ca01b467',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'abed9ed6fd97427b',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Personal Details',
    uri: 'below-100-saving/personal-details',
    class: 'below-100-savings-personal-details',
  },
  'spouse-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'spouse-details',
      type: 'list',
      readonly: false,
      label: 'Spouse Details',
      uid: 'f5bbb1cb55542863',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '2a669ec2b7c2d500',
          content: '<h2>Spouse Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '1b69ff24ec5e56a9',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'e55ea46b1a371db4',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: '3cab547819751f51',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: '15907c0a22533cdc',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'title',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Title',
          uid: 'af6c2588b5e2673e',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: ['', 'Adv', 'Dr', 'Miss', 'Mr', 'Mrs', 'Ms', 'Prof', 'Rev'],
        },
        {
          id: 'marital-status',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Marital Status',
          uid: '8fa5ea475004d8fb',
          content: 'Married',
          inputType: 'text',
        },
        {
          id: 'id-type',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'ID Type',
          uid: '1e90654d6df59e20',
          content: '',
          publishChange: true,
          options: [
            '',
            'Voter Id',
            'Nhis',
            'Ssnit',
            'Drivers Old Id',
            'Drivers New Id',
            'Passport',
            'Ghana Card',
          ],
        },
        {
          id: 'idpassport-number',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'ID/Passport Number',
          uid: '3c5844938854d6ef',
          content: '',
          publishChange: true,
          inputType: 'text',
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '36aa45a8a2d05e28',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'date',
          component: 'DateSelector',
          maxYear: 2023,
          minYear: 1923,
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Gender',
          uid: '748d00be5e69602d',
          content: 'Male',
          inputType: 'text',
        },
        {
          id: 'spouse-contact-details',
          type: 'list',
          readonly: false,
          label: 'Spouse Contact Details',
          uid: '7a5a01e07b4451cf',
          content: [
            {
              id: 'cell-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Cell Phone',
              uid: '793b72cc789f651d',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'work-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Work Phone',
              uid: '147c369ae58de9af',
              content: '',
              publishChange: true,
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'home-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Home Phone',
              uid: '194bbd1fc2548543',
              content: '',
              publishChange: true,
              inputType: 'tel',
              component: 'PhoneNumber',
            },
          ],
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '105c42d70fbd4e0d',
          content: '<p>Specify your spouse to be the trustee of the plan</p>\n',
        },
        {
          id: 'spouse-is-trustee',
          type: 'field',
          elementType: 'checkbox',
          readonly: false,
          label: 'Spouse Is Trustee',
          uid: '6b58669450576bf8',
          content: '',
          publishChange: true,
          options: ['Y', 'N'],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '2d29beab957f269c',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'd05e7c9f2c5bc6ca',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '81d79d5796a0950d',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '56d6eb3976d7899b',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'a0a51d7b3dc255ae',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Spouse Details',
    uri: 'below-100-savings/spouse-details',
    class: 'below-100-savings-spouse-details',
  },
  'trustee-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'trustee-details',
      type: 'list',
      readonly: false,
      label: 'Trustee Details',
      uid: 'd1e6774c4c1c817a',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'c57d8cad196453a3',
          content: '<h2>Trustee details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53952a550bf734cc',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '8ac4d130f3be0edd',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'd37b809ecb78a979',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: 'ee290e7bc60991c0',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '5723ca9ce0da97c1',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'date',
          component: 'DateSelector',
          maxYear: 2023,
          minYear: 1923,
        },
        {
          id: 'id-type',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'ID Type',
          uid: 'f8b78d5af12c62c4',
          content: '',
          publishChange: true,
          options: [
            '',
            'Voter Id',
            'Nhis',
            'Ssnit',
            'Drivers Old Id',
            'Drivers New Id',
            'Passport',
            'Ghana Card',
          ],
        },
        {
          id: 'idpassport-number',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'ID Number',
          uid: 'a5dc28ab3bfe31e0',
          content: '',
          publishChange: true,
          inputType: 'text',
        },
        {
          id: 'title',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Title',
          uid: '5e62b5dd60e864f7',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: ['', 'Adv', 'Dr', 'Miss', 'Mr', 'Mrs', 'Ms', 'Prof', 'Rev'],
        },
        {
          id: 'cell-phone',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Cell Phone',
          uid: '7d6ed2ce591e1ce6',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'tel',
          component: 'PhoneNumber',
        },
        {
          id: 'relationship',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Relationship',
          uid: '87473b2d56cc829e',
          content: '',
          publishChange: true,
          errors: ['*', '*'],
          options: [
            '',
            'Brother',
            'Daughter',
            'Estate',
            'Father',
            'Father-In-Law',
            'Grandfather',
            'Grandmother',
            'Mother',
            'Mother-In-Law',
            'Sister',
            'Son',
            'Spouse',
            'Testament',
            'Trust',
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '5e1596001eb68e6c',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '1c9c25fe0fa85b62',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ef363c019d731b16',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '0c8496dd91693bb7',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'c915d127733ac59f',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Trustee Details',
    uri: 'below-100-savings/trustee-details',
    class: 'below-100-savings-trustee-details',
  },
  'beneficiary-details': {
    text: [],
    buttons: [
      {
        label: 'Add Trustee',
        id: 'add-trustee',
        uid: '1c40f26fba6a8711',
        control: false,
      },
      {
        label: 'Add Another Beneficiary',
        id: 'add-another-beneficiary',
        uid: 'cca8995092d126c2',
        control: false,
      },
      {
        label: 'Update Benefit Split Percentage',
        id: 'update-benefit-split-percentage',
        uid: '6ce12a7a32a8d69a',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'specify-beneficiaries',
      type: 'list',
      readonly: true,
      label: 'Specify Beneficiaries',
      uid: '9ca13bc4efb82147',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'ccd1e945cd5c3045',
          content: '<h2>Beneficiary Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f0d387b3ecd49ed5',
          content: '<p>Please specify your beneficiary details below</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'f46ff380717f1288',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'system-field-outcome',
          type: 'html',
          readonly: true,
          label: '',
          uid: '6c60b1c422ae8373',
          content:
            "<p><a href='#add-trustee'>Add Trustee as beneficiary</a></p>\n",
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '145b199f80e0c8c3',
          content:
            "<p><a href='#add-another-beneficiary'>Add a different beneficiary</a></p>\n",
        },
        {
          id: 'beneficiary-details',
          type: 'table',
          readonly: true,
          label: 'Beneficiary Details',
          uid: 'd4ea664dee2ae74b',
          triggers: ['DoubleClick'],
          content: [],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '96a25790b7a523f6',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'c67c8ca6623f89c1',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '07c641dce01e1248',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '599b4f200c323fba',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '1b7980a4759eaf03',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Beneficiary Details',
    uri: 'below-100-savings/beneficiary-details',
    class: 'below-100-savings-beneficiary-details',
  },
  'add-beneficiary': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Recalculate',
        id: 'recalculate',
        uid: 'f7e7fcc76ead3671',
        control: false,
      },
      {
        label: 'Add',
        id: 'add',
        uid: '1413145eedf0be58',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'beneficiary-details',
      type: 'list',
      readonly: false,
      label: 'Beneficiary Details',
      uid: '6a3f5c79557ccfa0',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '94e325bd80270712',
          content:
            '<p>Please provide the following information for the beneficiary you would like to add to the policy</p>\n',
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'e094a59185798ed1',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: 'd7eda3e624e178e2',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'role',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Role',
          uid: '8ca3d9f9ca35bf9f',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: [
            '',
            'Brother',
            'Child',
            'Cousin',
            'Daughter',
            'Family',
            'Father',
            'Father-in-law',
            'Grandchild',
            'Grandfather',
            'Grandmother',
            'Main',
            'Mother',
            'Mother-in-law',
            'Nephew',
            'Niece',
            'Sister',
            'Son',
            'Spouse',
            'Step-Father',
            'Step-Mother',
          ],
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'radio',
          readonly: false,
          label: 'Gender',
          uid: '09f28932184006c8',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: ['Female', 'Male'],
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '6b9a4f659b85294a',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'date',
          component: 'DateSelector',
          maxYear: 2023,
          minYear: 1923,
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Beneficiary Details Add',
    uri: 'application/beneficiary-details/add',
    class: 'beneficiary-details-add',
  },
  'edit-beneficiary': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Delete Beneficiary',
        id: 'delete-beneficiary',
        uid: '02308b457a7dc6a3',
        control: false,
      },
      { label: 'Update', id: 'update', uid: 'd8035e4553f65492', control: true },
    ],
    item: {
      id: 'beneficiary-details',
      type: 'list',
      readonly: false,
      label: 'Beneficiary Details',
      uid: '0eafb4fa265b4de8',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '7fa0e505ce6ab73a',
          content:
            '<p>Edit beneficiary information below or <a href="#delete-beneficiary">click here to remove beneficiary</a>.</p>\n',
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'e094a59185798ed1',
          content: '',
          publishChange: true,
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: 'd7eda3e624e178e2',
          content: '',
          publishChange: true,
          inputType: 'text',
        },
        {
          id: 'role',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Role',
          uid: '8ca3d9f9ca35bf9f',
          content: '',
          publishChange: true,
          tooltip: 'Role',
          options: [
            '',
            'Cessionary',
            'Brother',
            'Child',
            'Cousin',
            'Daughter',
            'Family',
            'Father',
            'Father-in-law',
            'Grandchild',
            'Grandfather',
            'Grandmother',
            'Main',
            'Mother',
            'Mother-in-law',
            'Nephew',
            'Niece',
            'Sister',
            'Son',
            'Spouse',
            'Step-Father',
            'Step-Mother',
          ],
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Gender',
          uid: '09f28932184006c8',
          content: 'Female',
          publishChange: true,
          tooltip: 'Gender',
          options: ['', 'Female', 'Male'],
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '6b9a4f659b85294a',
          content: '',
          publishChange: true,
          inputType: 'date',
          component: 'DateSelector',
          maxYear: thisYear,
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Beneficiary Details Edit',
    uri: 'application/beneficiary-details/edit',
    class: 'beneficiary-details-edit',
  },
  'update-beneficiary-split-percentage': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Update',
        id: 'update',
        uid: 'd8035e4553f65492',
        control: true,
      },
    ],
    item: {
      id: 'update-benefit-split-percentage',
      type: 'list',
      readonly: false,
      label: 'Update Benefit Split Percentage',
      uid: '7b948e665a95ad8c',
      content: [
        {
          id: 'beneficiary1',
          type: 'list',
          readonly: false,
          label: 'Beneficiary-1',
          uid: '9c8886c3c737cf65',
          content: [],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Beneficiary Details Split',
    uri: 'application/beneficiary-details/split',
    class: 'beneficiary-details-split',
  },
  'beneficiary-validation': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Done',
        id: 'done',
        uid: '4e5f7031a7643ff6',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: 'f45eafb695d9fc87',
      content: [' You cannot proceed until you specify a beneficiary'],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Beneficiary Details',
    uri: 'below-100-savings/beneficiary-details',
    class: 'below-100-savings-beneficiary-details',
  },
  'payment-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: 'a217b08364422c8f',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'payment-method',
          type: 'list',
          readonly: false,
          label: 'Payment Method',
          uid: '935b4319fdcbab12',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'b3820d390c57590c',
              content: '<p>Choose a payment method</p>\n',
            },
            {
              id: 'payment-method-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payment Method Radio Group',
              uid: '5f29ef713684dcdd',
              content: [
                {
                  id: 'debit-order',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Debit Order',
                  uid: '66026bce0175bd85',
                  content: 'N',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'stop-order',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Stop Order',
                  uid: 'addc4bc1f4c832eb',
                  content: 'N',
                  publishChange: true,
                  options: ['Y'],
                },
              ],
              errors: ['*'],
            },
          ],
        },
        {
          id: 'payment-commencement',
          type: 'list',
          readonly: false,
          label: 'Payment Commencement',
          uid: 'c8333f3bb779bbad',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'a50540585ca12c07',
              content:
                '<p>Choose the payment start date and your payment frequency</p>\n',
            },
            {
              id: 'payment-start-date',
              type: 'integer',
              elementType: 'select',
              readonly: false,
              label: 'Payment Start Date',
              uid: 'cd4537bfa3fb5f7d',
              content: '20230126',
              publishChange: true,
              options: ['', '20230126', '20230201', '20230301', '20230401'],
            },
            {
              id: 'payment-frequency-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payment Frequency Radio Group',
              uid: '109a457cd91bf639',
              content: [
                {
                  id: 'monthly',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Monthly',
                  uid: '3f6c19ccd48cbeeb',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'quarterly',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Quarterly',
                  uid: '3085ba131761cdaa',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'bi-annually',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Bi Annually',
                  uid: '58f1bdb69bf88bd3',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'annually',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Annually',
                  uid: '9d98a9533a9281e4',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
              ],
              errors: ['*'],
            },
          ],
        },
        {
          id: 'payer-person',
          type: 'list',
          readonly: false,
          label: 'Payer Person',
          uid: 'ce35234c9c489acc',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'ee7c49bb35030b57',
              content: '<p>Who will be paying for this policy?</p>\n',
            },
            {
              id: 'payer-person-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payer Person Radio Group',
              uid: '2c1019491493ebab',
              content: [
                {
                  id: 'myself',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Myself',
                  uid: '4d7d4200a4fd7585',
                  content: 'Y',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'my-spouse',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'My Spouse',
                  uid: '6198ae13e0925e88',
                  content: 'N',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '0e4aa5c3bc499efa',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '1881c4e375aa40d9',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8be3408b492a252e',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '261486a530f75645',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '8588b418b3adff34',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Payment Details',
    uri: 'below-100-savings/quote',
    class: 'below-100-savings-payment-details',
  },
  'debit-order-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: '7503104103feca09',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'debit-order-details',
          type: 'list',
          readonly: false,
          label: 'Debit Order Details',
          uid: 'd3c9a331e021ed0b',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '82bc4d510e05f52c',
              content: '<p>Complete the following details</p>\n',
            },
            {
              id: 'new-account-details',
              type: 'list',
              readonly: false,
              label: 'New Account Details',
              uid: 'ab888ee5c456cdea',
              content: [
                {
                  id: 'account-holder-name',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Account Holder Name',
                  uid: '039eb80e4a10de79',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'account-type',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Account Type',
                  uid: 'fbdc797b84a0f751',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['', 'Current', 'Savings'],
                },
                {
                  id: 'account-entity-name',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Account Entity Name',
                  uid: '41ac2dfe1beb4c8d',
                  content: '',
                  tooltip: 'Account Entity Name',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Absa (gh) Ltd',
                    'Access Bank Ghana Ltd',
                    'Agricultural Development Bank',
                    'Bank Of Africa',
                    'Cal Bank Ltd',
                    'Consolidated Bank Ghana',
                    'Ecobank Ghana Ltd',
                    'Fidelity Bank Ghana',
                    'First Atlantic Bank Ltd',
                    'First Bank Nigeria',
                    'Gcb Bank Ltd',
                    'Guaranty Trust (gh) Ltd',
                    'National Investment Bank',
                    'Omni Bank Ghana Ltd',
                    'Prudential Bank Ltd',
                    'Republic Bank (gh) Ltd',
                    'Societe General Ghana Ltd',
                    'Stanbic Bank Ghana Ltd',
                    'Standard Chartered Bank',
                    'United Bank For Africa (gh) Ltd',
                    'Universal Merchant Bank (gh) Ltd',
                    'Zenith Bank',
                  ],
                },
                {
                  content: '',
                  elementType: 'select',
                  errors: ['*'],
                  id: 'account-branch-name',
                  label: 'Account Branch Name',
                  options: [
                    '',
                    '1ST LIGHT',
                    '31ST DECEMBER MARKET',
                    'A & C MALL',
                    'A & C SHOPPING MAIL',
                    'A AND C',
                    'A AND C MALL',
                    'A COMMUNITY BANK LTD-LA',
                    'A RURAL BANK LTD-AMASAMAN',
                    'A&C MALL',
                    'AA ASANTEWAA RURAL BANK LTD',
                    'AARA RURAL BANK LTD-PAGA',
                    'AASEMAN RURAL BANK LTD-KAASE',
                    'ABBOSEY OKAI',
                    'ABBOSEY OKAI BRANCH',
                    'ABEKA',
                    'ABEKA BRANCH',
                    'ABEKA LAPAZ',
                    'ABEKA LAPAZ BRANCH',
                    'ABEKALAPAZ',
                    'ABELENKPE',
                    'ABETIFI',
                    'ABETIFI BRANCH',
                    'ABII NATIONAL SAVINGS AND LOANS LTD',
                    'ABN-50129-TEMA COMMUNITY 9',
                    'ABOABO',
                    'ABOABO MARKET TAMALE',
                    'ABOKOBI RURAL BANK LTD-ABOKOBI',
                    'ABOR',
                    'ABORA',
                    'ABOSEY OKAI',
                    'ABOSSEY OKAI',
                    'ABOSSEY OKINE',
                    'ABREPO JUNCTION',
                    'ABREPO JUNCTION BRANCH',
                    'ABREPO MAIN',
                    'ABREPO MAIN BRANCH',
                    'ABUAKWA',
                    'ABUAKWA BRANCH',
                    'ABURA DUNKWA',
                    'ABURI',
                    'ABZUGU RURAL BANK LTD',
                    'ACCCRA NEWTOWN',
                    'ACCION',
                    'ACCRA',
                    'ACCRA & MAKOLA',
                    'ACCRA BRANCH',
                    'ACCRA CENTRAL',
                    'ACCRA CENTRAL POST',
                    'ACCRA CENTRAL POSTBANK',
                    'ACCRA CORPORATE SERVICE CENTRE',
                    'ACCRA MAIN',
                    'ACCRA MALL',
                    'ACCRA NEW TOWN',
                    'ACCRA SHOPPING MAIL',
                    'ACCRA SHOPPING MALL',
                    'ACHIASE',
                    'ACHIMOTA',
                    'ACHIMOTA BRANCH',
                    'ACHIMOTA MILE 7',
                    'ACTION CHAPEL',
                    'ADA FOAH',
                    'ADA RURAL BANK LTD-KASSEH',
                    'ADABOKROM',
                    'ADABRAKA',
                    'ADABRAKA BRANCH',
                    'ADANSI RURAL BANK LTD-FOMENA',
                    'ADAWSO',
                    'ADB HOUSE',
                    'ADB HOUSE BRANCH',
                    'ADEHYEMAN SAVINGS & LOAN',
                    'ADEHYEMAN SAVINGS AND LOANS',
                    'ADENTA',
                    'ADENTA BRANCH',
                    'ADENTA MARKET',
                    'ADENTAN',
                    'ADI HARBOUR',
                    'ADIAKIO WAYA',
                    'ADIDOME',
                    'ADJABENG',
                    'ADJIRINGANOR',
                    'ADONTEN COMMUNITY BANK LTD-NEW TAFO',
                    'ADUM',
                    'ADUM BRANCH',
                    'ADUM POST',
                    'ADUMAN RURAL BANK LTD-BADU',
                    'ADUM-KUMASI',
                    'AFANA RURAL BANK LTD-SAMPA',
                    'AFFUL NKWANTA',
                    'AFFUL NKWANTA BRANCH',
                    'AFLAO',
                    'AFRAM RURAL BANK LTD-TEASE',
                    'AFRANSIE',
                    'AGAVE RURAL BANK LTD-DABALE',
                    'AGBOBLOSHIE',
                    'AGBOGBLOSHIE',
                    'AGBOGBOLSHIE',
                    'AGBOZUME',
                    'AGOGO',
                    'AGONA ABREM',
                    'AGONA AHANTA',
                    'AGONA ASHANTI',
                    'AGONA NKWANTA',
                    'AGONA RURAL BANK LTD-KWANYAKU',
                    'AGONA SWEDRU',
                    'AHAFO ANO PREMIER RURAL BANK LTD-WIOSO',
                    'AHAFO COMMUNITY RURAL BANK LTD-KUKUOM',
                    'AHANTAMAN RURAL BANK LTD-AGONA NKWANTA',
                    'AHINSAN KUMASI',
                    'AHODWO',
                    'AHODWO BRANCH',
                    'AIRPORT',
                    'AIRPORT BRANCH',
                    'AIRPORT CITY',
                    'AIRPORT CITY BRANCH',
                    'AIRPORT EAST',
                    'AIYINASE',
                    'AJUMAKO',
                    'AKASTI',
                    'AKATAKYIMAN RURAL BANK LTD-KOMENDA',
                    'AKATSI',
                    'AKIM ABENASE',
                    'AKIM ADUASA',
                    'AKIM BOSOME RURAL BANK LTD-AKIM SWEDRU',
                    'AKIM ODA',
                    'AKIM SWEDRU',
                    'AKOFO HOUSE',
                    'AKONTOMBRA',
                    'AKOSOMBO',
                    'AKOSOMBO BRANCH',
                    'AKOTI RURAL BANK LTD-ASSIN AKROPONG',
                    'AKROFUOM AREA RURAL BANK LTD-AKROFUOM',
                    'AKROPONG',
                    'AKROPONG AKWAPIM',
                    'AKROSO',
                    'AKUAPEM RURAL BANK LTD-MAMFE',
                    'AKUM RURAL BANK LTD-ELMINA',
                    'AKUMADAN',
                    'AKUSE',
                    'AKWADUM',
                    'AKWATIA',
                    'AKWETEYMAN',
                    'AKYEM MANSA RURAL BANK LTD-AYIREBI',
                    'AKYEPIM RURAL BANK LTD-GOMOA-DAW',
                    'ALABAR',
                    'ALABAR BRANCH',
                    'ALPHA CAPITAL SAVING&L LTD',
                    'AMAKOM',
                    'AMAKOM BRANCH',
                    'AMANANO RURAL BANK LTD-NYINAHIM',
                    'AMANTEN',
                    'AMASAMAN',
                    'AMASIE WEST RURAL BANK LTD-ANTOAKROM',
                    'AMATIN AND KASEI COMMUNITY BANK LTD-KASEI',
                    'AMENFIMAN RURAL BANK LTD-WASSA AKROPONG',
                    'AMERICAN HOUSE BRANCH',
                    'AMFIE RURAL BANK LTD-WAMFIE',
                    'ANDOM RURAL BANK LTD-NANDOM',
                    'ANGBE RURAL BANK LTD-PRAMPRAM',
                    'ANKOBRA WEST (ESIAMA) RURAL BANK LTD-ESIAMA',
                    'ANLO RURAL BANK LTD-ANLOGA',
                    'ANLOGA',
                    'ANSOMAN RURAL BANK LTD-MANSO-AMENFI',
                    'ANTEAKWA RURAL BANK LTD-KOFORIDUA',
                    'ANUM APAPAM',
                    'ANUM RURAL BANK LTD-ANUM',
                    'ANYA KROBO RURAL BANK LTD-ODUMASE-KROBO',
                    'ANYAA',
                    'ANYAA MARKET BRANCH',
                    'ANYINAM',
                    'APAM',
                    'APENKWA',
                    'APENKWA BRANCH',
                    'APEX BANK - BOLGA CENTRE-BOLGATANGA',
                    'APEX BANK - CAPE COASTCAPE COAST',
                    'APEX BANK - TAMALE CENTRE-TAMALE',
                    'APEX BANK -HEAD OFFICE-SOUTH RIDGE ACCRA',
                    'APEX BANK -HOHOE CENTRE-HOHOE',
                    'APEX BANK KOFORIDUA CENTRE-KOFORIDUA',
                    'APEX BANK -SUNYANI CENTRE-SUNYANI',
                    'APEX BANK -WA CENTRE-WA',
                    'APEX BANKACCRA CENTRE-ACCRA',
                    'APEX BANK-HO CENTRE-HO',
                    'APEX BANK-KUMASIKUMASI',
                    'APEX BANK-TAKORADI CENTRE-TAKORADI',
                    'APEX LINK CENTRE-CEDI HOUSE',
                    'APITAL RURAL BANK LTD-ABESIM',
                    'APLAKU',
                    'APRA RURAL BANK LTD-PRANG',
                    'ARENA BRANCH',
                    'ASAFO',
                    'ASAFO BRANCH',
                    'ASAFO MARKET',
                    'ASAMANKESE',
                    'ASANKRAGUA',
                    'ASANKRAGWA',
                    'ASANTE AKYEM RURAL BANK LTD-JUANSA',
                    'ASAWINSO BRANCH',
                    'ASAWINSO RURAL BANK LTD-SEFWI-ASAWINSO',
                    'ASEBU',
                    'ASEMKROM',
                    'ASEMPANEYE',
                    'ASH TOWN',
                    'ASHAIMAN',
                    'ASHAIMAN BRANCH',
                    'ASHAIMAN LEBABNON',
                    'ASHAIMAN MAIN BRANCH',
                    'ASHAIMAN ROUND ABOUT BRANCH',
                    'ASHALLEY BOTWE BRANCH',
                    'ASHANTI BEKWAI',
                    'ASHANTI MAMPONG',
                    'ASHIAMAN',
                    'ASHIYEA BRANCH',
                    'ASHTOWN',
                    'ASHTOWN BRANCH',
                    'ASHTOWN EAST',
                    'ASHTOWN EAST BRANCH',
                    'ASIAKWA',
                    'ASIKUMA',
                    'ASOKORE RURAL BANK LTD-ASOKRE',
                    'ASOKWA',
                    'ASOKWA BRANCH',
                    'ASSIN FOSO',
                    'ASSIN FOSU',
                    'ASSIN FOSU POST',
                    'ASSINMAN RURAL BANK LTD-ASSIN MANSO',
                    'AST MANPRUSI COMMUNITY BABK LTD-GAMBAGA',
                    'ASTERN GOMOA ASSIN RURAL BANK LTD-GOMOA-DOMINASE',
                    'ASUBONTEN RURAL BANK LTD-WORAWORA',
                    'ASUOGYAMAN RURAL BANK LTD-ACCRA',
                    'ASUOPRA RURAL BANK LTD-AFOSU',
                    'ASUTIFI RURAL BANK LTD-ACERENSUA',
                    'ASUTUARE',
                    'ASYLUM DOWN',
                    'ATAABADZE',
                    'ATEBUBU',
                    'ATIWA RURAL BANK',
                    'ATIWA RURAL BANK LTD-KWABENG',
                    'ATOMIC BRANCH',
                    'ATOMIC JUNCTION BRANCH',
                    'ATONSU',
                    'ATONSU BRANCH',
                    'ATWEABAN RURAL BANK LTD-SUNYANI',
                    'ATWIMA KWANWOMA RURAL BANK LTD-PAKYI NO. 2',
                    'ATWIMA MPONUA RURAL BANK LTD-TOASE',
                    'ATWIMA RURAL BANK LTD-FOASE',
                    'AVENOR RURAL BANK LTD-AKATSI',
                    'AVENUE CENTRAL',
                    'AWASO',
                    'AWJIASE AREA RURAL BANK LTD-AWUTU BAWJIASE',
                    'AWRA RURAL BANK LTD-WA',
                    'AWUTU',
                    'AWUTU EMASA RURAL BANK LTD-AWUMASA',
                    'AXIM',
                    'AXIM ROAD BRANCH',
                    'AYANFURI',
                    'AYETEPA',
                    'AYIREBI',
                    'AYOMSO',
                    'BAATSONA',
                    'BAATSONA BRANCH',
                    'BADUKROM',
                    'BADUMAN RURAL BANK LTD-BADU',
                    'BAMAHU',
                    'BANDA NKWANTA',
                    'BANTAMA',
                    'BANTAMA 1',
                    'BANTAMA BRANCH',
                    'BANTAMA GNTC BRANCH',
                    'BASYONDE',
                    'BATSONA BRANCH',
                    'BAWALESHIE',
                    'BAWDJIASE',
                    'BAWJIASE AREA RURAL BANK LTD-AWUTU BAWJIASE',
                    'BAWKU',
                    'BAWKU BRANCH',
                    'BCM',
                    'BECHEM',
                    'BEGORO',
                    'BEIGE CAPITAL',
                    'BEKWAI',
                    'BEPOSO',
                    'BEREKUM',
                    'BESEASE',
                    'BESSFA RURAL BANK LTD-GARU',
                    'BESTPOINT SAVINGS AND LOANS',
                    'BEYIN',
                    'BI RURAL BANK LTD-HOHOE',
                    'BIA DEBISO',
                    'BIA-TORYA RURAL BANK LTD-BONSU-NKWANTA',
                    'BIBIANI',
                    'BIMBILLA',
                    'BINABA',
                    'BIRIWA',
                    'BOGOSO',
                    'BOGOSU',
                    'BOINSO',
                    'BOKOBI RURAL BANK LTD-ABOKOBI',
                    'BOLE',
                    'BOLE BRANCH',
                    'BOLGA COMMERCIAL ROAD BRANCH',
                    'BOLGA POLICE STATION ROAD BRANCH',
                    'BOLGA POST',
                    'BOLGA POSTBANK',
                    'BOLGATANGA',
                    'BOMAA AREA RURAL BANK LTD-BOMAA',
                    'BOMOSADU RURAL BANK LTD',
                    'BOND SAVINGS AND LOANS',
                    'BONGO',
                    'BONGO RURAL BANK LTD-BOLGATANGA',
                    'BONSO NKWANTA',
                    'BONSU NKWANTA',
                    'BONTRASE',
                    'BONZALI RURAL BANK LTD-KUMBUNGU',
                    'BORIMANGA RURAL BANK LTD-SAVELEGU',
                    'BOSOME FREHO RURAL BANK LTD-KUMASI',
                    'BOSOMTWI RURAL BANK LTD-KUNTANASE',
                    'BOUNDARY ROAD',
                    'BRAKWA BRAMAN RURAL BANK LTD-BRAKWA',
                    'BREEZE EAST LEGON',
                    'BREMAN ASIKUMA',
                    'BREMAN UGC',
                    'BUI',
                    'BUILSA COMMUNITY BANK LTD-SANDEMA',
                    'BUIPE',
                    'BUNKPRUGU',
                    'BURMA CAMP',
                    'BURMA CAMP BRANCH',
                    'BUSIE',
                    'BUSUNYA',
                    'BUTAWU RURAL BANK LTD-HO',
                    'BUWUULONSO RURAL BANK LTD-TAMALE',
                    'CAPE COAST',
                    'CAPE COAST BRANCH',
                    'CAPE COAST- MELCOM',
                    'CAPE COAST- MFANTSIPIM',
                    'CAPECOAST BRANCH',
                    'CAPITAL RURAL BANK LTD-ABESIM',
                    'CASTLE RD',
                    'CASTLE ROAD',
                    'CASTLE ROAD (HEAD OFFICE)',
                    'CASTLE ROAD BRANCH',
                    'CEDI HOUSE',
                    'CHAMBA',
                    'CHAPEL SQUARE',
                    'CHARIA',
                    'CHEREPONI',
                    'CHIRAA',
                    'CHORKOR',
                    'CIRCLE',
                    'CIRCLE BRANCH',
                    'CITIZEN RURAL BANK LTD-ACCRA',
                    'CLEARING UNIT',
                    'COLLINS AVENUE',
                    'COLLINS STREET',
                    'COMMUNITY 25',
                    'COMMUNITY 6',
                    'COMMUNITY1 BRANCH',
                    'CONSUMER FINANCE COMPANY (CFC) LTD',
                    'CORPORATE',
                    'CORPORATE SERVICE CENTRE',
                    'CUC MIOTSO',
                    'DA RURAL BANK LTD-KASSEH',
                    'DABALA',
                    'DABAN',
                    'DABOASE',
                    'DADIESO',
                    'DAMANG',
                    'DAMANGO',
                    'DAMBAI',
                    'DAMONGO',
                    'DANGBE RURAL BANK LTD-PRAMPRAM',
                    'DANICOM HOUSE(KOKOMLEMLE)',
                    'DANQUAH CIRCLE',
                    'DANSI RURAL BANK LTD-FOMENA',
                    'DANSOMAN',
                    'DANSOMAN EXHIBITION BRANCH',
                    'DANSOMAN ROUND-ABOUT BRANCH',
                    'DARKUMAN',
                    'DARKUMAN BRANCH',
                    'DAWA',
                    'DENKYIRAMAN RURAL BANK LTD-AYAMFURI',
                    'DENU',
                    'DERBY AVENUE',
                    'DERMA',
                    'DERMA RURAL BANK LTD-DERMA',
                    'DIRECT SALES',
                    'DODOWA',
                    'DOME',
                    'DOME BRANCH',
                    'DOME ST. JOHNS',
                    'DONKORKROM',
                    'DONTEN COMMUNITY BANK LTD-NEW TAFO',
                    'DORMAA AHENKRO',
                    'DOTOBRI RURAL BANK LTD-JACOBU',
                    'DROBO',
                    'DROBO COMMUNITY RURAL BANK LTD-DROBO',
                    'DRORWULU',
                    'DUADASO',
                    'DUAYAW NKANTA',
                    'DUAYAW NKWANTA',
                    'DUMPONG RURAL BANK LTD-OFRAMASE',
                    'DUNKWA',
                    'DUNKWA OFFIN',
                    'DUNKWA ON-OFFIN',
                    'DUNKWA-ON-OFFIN',
                    'DUNKWAW-ON-OFFIN BRANCH',
                    'DUPONGKPEHE RURAL BANK LTD-KASOA',
                    'DWEN-ANOMA RURAL BANK LTD-KWEEHWEE',
                    'DWORWULU WEST AIRPORT',
                    'DZEMENI',
                    'DZODZE',
                    'DZORWULU',
                    'DZORWULU BRANCH',
                    'DZORWULU MOTORWAY',
                    'DZWORWULU',
                    'EAST AIRPORT BRANCH',
                    'EAST CANTONMENT',
                    'EAST CANTONMENTS BRANCH',
                    'EAST LEGON',
                    'EAST LEGON 1',
                    'EAST LEGON 2',
                    'EAST LEGON BRANCH',
                    'EAST MANPRUSI COMMUNITY BABK LTD-GAMBAGA',
                    'EASTERN GOMOA ASSIN RURAL BANK LTD-GOMOA-DOMINASE',
                    'EBANKESE',
                    'ECOBANK BANTAMA GNTC',
                    'ECOBANK COMMUNITY 1',
                    'ECOBANK E PROCESS',
                    'ECOBANK EAST AIRPORT',
                    'ECOBANK EVANDY HOSTEL',
                    'ECOBANK GH TAMALE',
                    'ECOBANK GHANA',
                    'ECOBANK HIGH STREET',
                    'ECOBANK HOSPITAL ROAD',
                    'ECOBANK KANTAMANTO',
                    'ECOBANK MADINA CENTRAL',
                    'ECOBANK OKPONGLO',
                    'ECOBANK PENTAGON LEGON',
                    'ECOBANK SSNIT HOUSE',
                    'ECOBANK SUAME',
                    'EFFIDUASE',
                    'EFWIMAN RURAL BANK LTD-SEFWI-ASAW',
                    'EJISU',
                    'EJISU BRANCH',
                    'EJURA',
                    'EJURAMAN RURAL BANK LTD-KUMASI',
                    'EKUMFIMAN RURAL BANK LTD-ESSUEHYIA',
                    'EKYEDUMASI RURAL BANK LTD-SEKYEDUMASI',
                    'EKYERE RURAL BANK LTD-JAMASI',
                    'ELMINA',
                    'ELMINA - CHAPEL SQUARE',
                    'ELMINA -ESSUAKYIR',
                    'ELUBO',
                    'ELUBU',
                    'ENCHI',
                    'ENCHI BRANCH',
                    'ENCHI RURAL BANK LTD',
                    'ENKYIRAMAN RURAL BANK LTD-AYAMFURI',
                    'ENYAN DENKYIRA RURAL BANK LTD-ENYAN DENKYIRA',
                    'EPAMAN RURAL BANK LTD - TEPAMAN',
                    'EPE AREA RURAL BANK LTD-MEPE',
                    'ERMA RURAL BANK LTD-DERMA',
                    'ESIAMA',
                    'ESSAM',
                    'ESSFA RURAL BANK LTD-GARU',
                    'EST MANPRUSI COMMUNITY BANK LTD-WALEWALE',
                    'ESTERN RURAL BANK LTD-WASSA AKROPONG',
                    'ETO RURAL BANK LTD-KPEVE',
                    'EVANDY HOSTEL BRANCH',
                    'EZI SAVINGS AND LOANS LTD',
                    'FAANOFA',
                    'FAMAL',
                    'FANTEAKWA RURAL BANK LTD-KOFORIDUA',
                    'FANTSIMAN COMMUNITY RURAL BANK LTD-BIRIWA',
                    'FARRAR',
                    'FFINSO RURAL BANK LTD-KUMASI',
                    'FIAGYA RURAL BANK LTD-BUSUNYA',
                    'FIAPRE',
                    'FIASEMAN (BOGOSO AREA) RURAL BANK LTD-BOGOSO',
                    'FIRST AFRICAN SAVINGS AND LOANS',
                    'FIRST ALLIED SAVINGS & LOANS',
                    'FIRST ALLIED SAVINGS AND LOANS',
                    'FIRST GHANA BUILDING SOCIETY- ACCRA',
                    'FIRST GHANA BUILDING SOCIETY- KOFORIDUA',
                    'FIRST GHANA BUILDING SOCIETY- KUMASI AMAKOM',
                    'FIRST GHANA BUILDING SOCIETY- KUMASI SUAME',
                    'FIRST GHANA BUILDING SOCIETY- SUNYANI',
                    'FIRST GHANA BUILDING SOCIETY- TAKORADI',
                    'FIRST GHANA BUILDING SOCIETY-HEAD OFFICE',
                    'FIRST GHANA BUILDING SOCIETY-HOHOE',
                    'FIRST GHANA BUILDING SOCIETY-TAKORADI',
                    'FIRST GHANA BUILDING SOCIETY-TEMA',
                    'FIRST NATATIONAL S&L',
                    'FIRST NATIONAL SAVINGS & LOANS',
                    'FOASE',
                    'FOREIGN',
                    'FOREIGN OPERATIONS',
                    'FOREIGN OPERATIONS BRANCH',
                    'FRAM RURAL BANK LTD-TEASE',
                    'FULFOSO',
                    'FUMBISI',
                    'GA RURAL BANK LTD-AMASAMAN',
                    'GAMASHIE',
                    'GARU',
                    'GAVE RURAL BANK LTD-DABALE',
                    'GBI RURAL BANK LTD-HOHOE',
                    'GICEL',
                    'GIMPA',
                    'GIMPA BRANCH',
                    'GLOBAL ACCESS SAVINGS & LOANS CO. LTD',
                    'GNPC',
                    'GNPC TEMA',
                    'GOASO',
                    'GOLDEN TULIP KUMASI BRANCH',
                    'GOLOKWATI',
                    'GOMOA AJUMAKO RURAL BANK LTD-AFRANSI',
                    'GOMOA RURAL BANK LTD-APAM',
                    'GONA RURAL BANK LTD-KWANYAKU',
                    'GRAPHIC ROAD',
                    'GREL',
                    'GULF HOUSE',
                    'GUMANI',
                    'GUSHIEGU',
                    'GWB LAPAZ',
                    'HAATSO',
                    'HAATSOO',
                    'HAFO ANO PREMIER RURAL BANK LTD-WIOSO',
                    'HAFO COMMUNITY RURAL BANK LTD-KUKUOM',
                    'HAI RURAL BANK LTD-DODOWA',
                    'HALF ASSINI',
                    'HAMILE',
                    'HANTAMAN RURAL BANK LTD-AGONA NKWANTA',
                    'HAPER ROAD',
                    'HARBOUR AREA',
                    'HARPER ROAD',
                    'HARPER ROAD BRANCH',
                    'HE SEED SAVINGS AND LOANS',
                    'HEAD OFFICE',
                    'HEAD-OFFICE',
                    'HIGH STREET',
                    'HIGH STREET BRANCH',
                    'HIGH STREET TAKORADI',
                    'HIGH STREET-TAKORADI',
                    'HO',
                    'HO AGENCY',
                    'HO BRANCH',
                    'HO MARKET',
                    'HO POLYTECHNIC',
                    'HO POST',
                    'HO POSTBANK',
                    'HOEHOE',
                    'HOHOE',
                    'HOHOE POST',
                    'HOHOE POSTBANK',
                    'HOSPITAL ROAD BRANCH',
                    'HWEDIEM',
                    'HWIDIEM',
                    'HWIDIEM[ B/A]',
                    'IAGYA RURAL BANK LTD-BUSUNYA',
                    'IASEMAN (BOGOSO AREA) RURAL BANK LTD-BOGOSO',
                    'IA-TORYA RURAL BANK LTD-BONSU-NKWANTA',
                    'ICROFIN RURAL BANK LIMITED',
                    'INDEPENDENCE AVENUE',
                    'INDUSTRIAL AREA',
                    'INTAMPO RURAL BANK LTD-KINTAMPO',
                    'INTERNATIONAL BANKING',
                    'IPS',
                    'IPS BRANCH',
                    'IPS LEGON',
                    'IRST GHANA BUILDING SOCIETY- ACCRA',
                    'IRST GHANA BUILDING SOCIETY- KOFORIDUA',
                    'IRST GHANA BUILDING SOCIETY- KUMASI AMAKOM',
                    'IRST GHANA BUILDING SOCIETY- KUMASI SUAME',
                    'IRST GHANA BUILDING SOCIETY- SUNYANI',
                    'IRST GHANA BUILDING SOCIETY- TAKORADI',
                    'IRST GHANA BUILDING SOCIETY-HEAD OFFICE',
                    'IRST GHANA BUILDING SOCIETY-HOHOE',
                    'IRST GHANA BUILDING SOCIETY-TAKORADI',
                    'IRST GHANA BUILDING SOCIETY-TEMA',
                    'ISSALA RURAL BANK LTD-TUMA',
                    'ITIZEN RURAL BANK LTD-ACCRA',
                    'IZAA RURAL BANK LTD-TAMALE',
                    'JACOBU',
                    'JANYAGU',
                    'JAPEKROM',
                    'JAPEKROM/DROBO',
                    'JASIKAN',
                    'JEMA',
                    'JINIJIN',
                    'JIRAPA',
                    'JISONAYILI',
                    'JOMORO RURAL BANK LTD-TIKOBO NO. 1',
                    'JUABEN RURAL BANK LTD-JUABEN',
                    'JUABESO',
                    'JUAPONG',
                    'JUASO',
                    'JUBILEE HOUSE',
                    'JUBILEE HOUSE COCOBOD BUILDING',
                    'JUKWA',
                    'JUNCTION MALL',
                    'JUNCTION MALL BRANCH',
                    'JURAMAN RURAL BANK LTD-KUMASI',
                    'KAASE',
                    'KAASEMAN RURAL BANK LTD-KAASE',
                    'KADE',
                    'KADJEBI',
                    'KAJEBI',
                    'KAKUM RURAL BANK LTD-ELMINA',
                    'KALADAN',
                    'KANASHIE',
                    'KANESHIE',
                    'KANESHIE BRANCH',
                    'KANESHIE INDUSTRIAL AREA',
                    'KANESHIE MAIN',
                    'KANESHIE MARKET',
                    'KANESHIE POST OFFICE',
                    'KANESHIE POST OFFICE BRANCH',
                    'KANESSHIE',
                    'KANTAMANTO',
                    'KANTAMANTO AGENCY',
                    'KANTAMANTO BRANCH',
                    'KARAGA',
                    'KASAPIN',
                    'KASOA',
                    'KASOA BRANCH',
                    'KASOA MAIN',
                    'KASOA MAIN BRANCH',
                    'KASOA MARKET',
                    'KASOA MILLENIUM CITY',
                    'KASOA NEW MARKET',
                    'KATAKYIMAN RURAL BANK LTD-KOMENDA',
                    'KEJETIA',
                    'KEJETIA BRANCH',
                    'KEJETIA- KUMASI',
                    'KENYASE',
                    'KENYASI AGENCY',
                    'KETA',
                    'KETE KRACHI',
                    'KIBI',
                    'KIM BOSOME RURAL BANK LTD-AKIM SWEDRU',
                    'KINTAMPO',
                    'KINTAMPO RURAL BANK LTD-KINTAMPO',
                    'KISSEIMAN BRANCH',
                    'KISSI',
                    'KISSIEMAN',
                    'KNUST',
                    'KNUST BRANCH',
                    'KNUST CAMPUS',
                    'KNUST KUMASI',
                    'KNUTSFORD',
                    'KNUTSFORD AVENUE',
                    'KOFORIDUA',
                    'KOFORIDUA BRANCH',
                    'KOFORIDUA KAMA CENTRE',
                    'KOJO THOMPSON ROAD',
                    'KOKOMLEMLE BRANCH',
                    'KOMENDA',
                    'KOMFO ANOKYE RURAL BANK LTD-WIAMOASE',
                    'KOMLEMLE',
                    'KONGO',
                    'KONONGO',
                    'KORANMAN RURAL BANK LTD-SEIKWA',
                    'KORANZA KWABRE RURAL BANK LTD-AKUMA',
                    'KORKORDZOR',
                    'KORLE BU',
                    'KORLE DUDOR',
                    'KORLE DUDOR BRANCH',
                    'KORLE-BU',
                    'KORLE-BU BRANCH',
                    'KOTI RURAL BANK LTD-ASSIN AKROPONG',
                    'KOTOBABI',
                    'KPADIE',
                    'KPANDAI',
                    'KPANDO',
                    'KPANDU',
                    'KPASSA RURAL BANK LTD-HOHOE',
                    'KPEDZE',
                    'KPETOE',
                    'KPEVE',
                    'KPONE',
                    'KPONG',
                    'KRACHE NKWANTA',
                    'KRANKWANTA RURAL BANK LTD-SUNYANI',
                    'KROFOM',
                    'KROFROM',
                    'KROFUOM AREA RURAL BANK LTD-AKROFUOM',
                    'KRONUM BRANCH',
                    'KUAPEM RURAL BANK LTD-MAMFE',
                    'KUMASI',
                    'KUMASI (ALABAR)',
                    'KUMASI ADUM',
                    'KUMASI -ADUM',
                    'KUMASI AMAKOM',
                    'KUMASI ASAFO',
                    'KUMASI ASAFO BRANCH',
                    'KUMASI ASOKWA',
                    'KUMASI BRANCH',
                    'KUMASI CENT.',
                    'KUMASI CENTRAL',
                    'KUMASI CENTRAL MARKET',
                    'KUMASI HARPER ROAD',
                    'KUMASI MAIN',
                    'KUMASI PREMPEH 11 STREET',
                    'KUMASI PREMPEH STREET',
                    'KUMASI SUAME',
                    'KUMASI TANNOSO',
                    'KUMASI-LAKE  ROAD',
                    'KUMAWUMAN RURAL BANK LTD-KUMAWU',
                    'KUMFIMAN RURAL BANK LTD-ESSUEHYIA',
                    'KWABENYA',
                    'KWABENYA BRANCH',
                    'KWADASO',
                    'KWAEBIBIREM RURAL BANK LTD-ASUOM',
                    'KWAHU PRASO RURAL BANK LTD-KWAHU PRASO',
                    'KWAHU RURAL BANK LTD-KWAHU PEPEASE',
                    'KWAHUTAFO',
                    'KWAMANMAN RURAL BANK LTD-KWAMANG',
                    'KWAME DANSO BRANCH',
                    'KWAME NKRUMAH AVE',
                    'KWAME NKRUMAH CIRCLE',
                    'KWAPONG',
                    'KWASHIEMAN',
                    'KWASHIEMAN BRANCH',
                    'KYEKYEWERE',
                    'KYEM MANSA RURAL BANK LTD-AYIREBI',
                    'KYEPIM RURAL BANK LTD-GOMOA-DAW',
                    'LA',
                    'LA COMMUNITY BANK LTD-LA',
                    'LABONE',
                    'LABONE BRANCH',
                    'LAPAZ',
                    'LAPAZ BRANCH',
                    'LASHIBI',
                    'LASHIBI BRANCH',
                    'LAW COURT COMPLEX',
                    'LAW-COURT',
                    'LAWRA',
                    'LAWRA RURAL BANK LTD-WA',
                    'LEGON',
                    'LEGON MAIN',
                    'LIBERATION ROAD TAKORADI',
                    'LIBERATION ROAD TOKORADI',
                    'LIBERATION ROAD-TAKORADI',
                    'LIBERIA ROAD',
                    'LIBERTY HSE',
                    'LOTTERIES',
                    'LOWER PRA RURAL BANK LTD-SHAMA',
                    'MAAKRO BRANCH',
                    'MAAME KROBO',
                    'MAAMOBI',
                    'MACCARTHY HILL',
                    'MADINA',
                    'MADINA BRANCH',
                    'MADINA CENTRAL BRANCH',
                    'MADINA MAIN BRANCH',
                    'MADINA NEW ROAD BRANCH',
                    'MAKANGO',
                    'MAKOLA',
                    'MAKOLA BRANCH',
                    'MAKOLA SQUARE',
                    'MALLAM',
                    'MALLAM INTERCHANGE',
                    'MALLAM JUNCTION BRANCH',
                    'MAMFE AKUAPIM',
                    'MAMFE BRANCH',
                    'MAMOBI',
                    'MAMOBI BRANCH',
                    'MAMPONG',
                    'MAMPONG AKWAPIM',
                    'MAMPONG ASHANTI',
                    'MAMPROBI',
                    'MAMPROBI POST',
                    'MAMPROBI POSTBANK',
                    'MANANO RURAL BANK LTD-NYINAHIM',
                    'MANET TOWER 3 BRANCH',
                    'MANKESSIM',
                    'MANKESSIM OFFICE COMPLEX',
                    'MANKRASO',
                    'MANSO NKWANTA',
                    'MANSOMAN RURAL BANK LTD-MANSO-AMENFI',
                    'MANYA KROBO RURAL BANK LTD-ODUMASE-KROBO',
                    'MARKET CIRCLE BRANCH',
                    'MARKET CIRCLE TAKORADI',
                    'MASIE WEST RURAL BANK LTD-ANTOAKROM',
                    'MATAHEKO',
                    'MATIN AND KASEI COMMUNITY BANK LTD-KASEI',
                    'MAX MART',
                    'MCCARTHY HILL',
                    'MELCOM',
                    'MENFIMAN RURAL BANK LTD-WASSA AKROPONG',
                    'MEPE AREA RURAL BANK LTD-MEPE',
                    'MERIDIAN BRANCH',
                    'MFANTSIMAN COMMUNITY RURAL BANK LTD-BIRIWA',
                    'MFANTSIPIM',
                    'MICHEL CAMP',
                    'MICROFIN RURAL BANK LIMITED',
                    'MIDLAND SAVING AND LOANS',
                    'MIDLAND SAVINGS & LOANS LTD',
                    'MILE 7/TANTRA',
                    'MIM',
                    'MINISTRIES',
                    'MIOTSO',
                    'MOREE',
                    'MOTOR WAY ROUNDABOUT',
                    'MOTORWAY EXT',
                    'MOTORWAY EXTENSION',
                    'MOTORWAY ROUNDABOUT',
                    'MPATASIE',
                    'MPOHOR',
                    'MPONUA RURAL BANK LTD-AMUANA PRASO',
                    'MPRAESO',
                    'MULTI CREDIT SAVINGS AND LOANS - KUMASI',
                    'MULTICREDIT SAVINGS AND LOANS CO.',
                    'MUMFORD',
                    'MUMUADA RURAL BANK LTD-OSINO',
                    'NAARA RURAL BANK LTD-PAGA',
                    'NAFANA RURAL BANK LTD-SAMPA',
                    'NAGODE',
                    'NANDOM',
                    'NANDOM RURAL BANK LTD-NANDOM',
                    'NAVORONGO',
                    'NAVRONGO',
                    'NBFI-ADVANS GH',
                    'NCHIRAA',
                    'NCR',
                    'NEW ABEKA',
                    'NEW ABIREM',
                    'NEW ABIREM BRANCH',
                    'NEW ABRIEM',
                    'NEW ADUBIASE',
                    'NEW AMAKOM',
                    'NEW DORMAA KOTOKROM',
                    'NEW EDUBIASE',
                    'NEW NINGO',
                    'NEW OFFINSO',
                    'NEW SUAME MAGAZINE',
                    'NEW TAFO',
                    'NEW TOWN',
                    'NEWTOWN',
                    'NEWTOWN ACCRA',
                    'NHYIAESO BRANCH',
                    'NHYIEASO',
                    'NHYIESO',
                    'NIA',
                    'NIA BRANCH',
                    'NIMA',
                    'NIMA BRANCH',
                    'NIMA MAIN BRANCH',
                    'NINGO',
                    'NION RURAL BANK LTD-SENYA BREKU',
                    'NITY RURAL BANK LTD-ZIOPE',
                    'NKAWIE',
                    'NKAWKAW',
                    'NKOBRA WEST (ESIAMA) RURAL BANK LTD-ESIAMA',
                    'NKORANMAN RURAL BANK LTD-SEIKWA',
                    'NKORANSAH',
                    'NKORANZA',
                    'NKORANZA KWABRE RURAL BANK LTD-AKUMA',
                    'NKRANKWANTA',
                    'NKRANKWANTA RURAL BANK LTD-SUNYANI',
                    'NKWAKAW POST',
                    'NKWANTA',
                    'NLO RURAL BANK LTD-ANLOGA',
                    'NOBLE DREAM MICROFINANCE LTD',
                    'NORTH INDUSTRIAL',
                    'NORTH INDUSTRIAL AREA',
                    'NORTH INDUSTRIAL AREA BRANCH',
                    'NORTH KANESHIE',
                    'NORTH RIDGE',
                    'NORTH TONGU RURAL BANK LTD-ADIDOME',
                    'NORTH VOLTA RURAL BANK LTD-GUAMAN',
                    'NSAWAM',
                    'NSOATREMAN RURAL BANK LTD-NSOATRE',
                    'NSUATRE',
                    'NSUFA ASHANTI',
                    'NSUTAMAN RURAL BANK LTD-NSUTA',
                    'NTOROSO',
                    'NUM RURAL BANK LTD-ANUM',
                    'NUNGUA',
                    'NWABIAGYA RURAL BANK LTD-BAREKESE',
                    'NYAKROM RURAL BANK LTD-NYAKROM',
                    'NYAN DENKYIRA RURAL BANK LTD-ENYAN DENKYIRA',
                    'NYANKPALA',
                    'NYANKUMASE AHENKRO RURAL BANK LTD-FANTI NYANKUMASE',
                    'NYIAESO',
                    'NYIAESO AHODWO',
                    'NZEMA MANLE RURAL BANK LTD-AWIEBO',
                    'OBUASI',
                    'ODA',
                    'ODA NKWANTA',
                    'ODORKOR',
                    'ODOTOBRI RURAL BANK LTD-JACOBU',
                    'ODUPONGKPEHE RURAL BANK LTD-KASOA',
                    'ODWEN-ANOMA RURAL BANK LTD-KWEEHWEE',
                    'OENDE RURAL BANK LTD-ZIBILLA',
                    'OFFINSO',
                    'OFFINSO RURAL BANK LTD-KUMASI',
                    'OFFSHORE BANKING',
                    'OFFSHORE BANKING UNIT - GH',
                    'OKAISHIE',
                    'OKAISHIE BRANCH',
                    'OKOFO BRANCH',
                    'OKOMFO ANOKYE RURAL BANK LTD-WIAMOASE',
                    'OKPONGLO',
                    'OKPONGLO BRANCH',
                    'OLA',
                    'OLD NINGO',
                    'OLD SUAME MAGAZINE',
                    'OLD TAFO',
                    'OMAA AREA RURAL BANK LTD-BOMAA',
                    'OMOA AJUMAKO RURAL BANK LTD-AFRANSI',
                    'OMOA RURAL BANK LTD-APAM',
                    'OMORO RURAL BANK LTD-TIKOBO NO. 1',
                    'OMOSADU RURAL BANK LTD',
                    'ONGO RURAL BANK LTD-BOLGATANGA',
                    'ONZALI RURAL BANK LTD-KUMBUNGU',
                    'ONZELE RURAL BANK LTD-JIRAPA',
                    'OPEIBEA HOUSE',
                    'OPERA SQUARE',
                    'OPERA SQUARE BRANCH',
                    'OPPORTUNITY INT. SAVINGS AND LOAN',
                    'OPPORTUNITY INT. SAVINGS AND LOANS',
                    'OPPORTUNITY INTERNATIONAL',
                    'OREIGN',
                    'ORIMANGA RURAL BANK LTD-SAVELEGU',
                    'ORTH TONGU RURAL BANK LTD-ADIDOME',
                    'ORTH VOLTA RURAL BANK LTD-GUAMAN',
                    'OSEI KWADWO KROM',
                    'OSOME FREHO RURAL BANK LTD-KUMASI',
                    'OSOMTWI RURAL BANK LTD-KUNTANASE',
                    'OSU',
                    'OSU  OXFORD BRANCH',
                    'OSU - OXFORD STREET',
                    'OSU - WATSON STREET',
                    'OSU OXFORD ST.',
                    'OSU OXFORD STREET',
                    'OSU WATSON BRANCH',
                    'OTUASEKAN RURAL BANK LTD-KOFIASE',
                    'OUTH AKIM RURAL BANK LTD-NANKESE',
                    'OUTH BIRIM RURAL BANK LTD-ACHIASE',
                    'OWER PRA RURAL BANK LTD-SHAMA',
                    'OXFORD STREET BRANCH',
                    'OYIBI AREA RURAL BANK LIMITED',
                    'PACIFIC SAVINGS AND LOANS LTD',
                    'PAGA',
                    'PALM WINE JUNCTION',
                    'PALMWINE JUNCTION',
                    'PARLIAMENT HOUSE',
                    'PASSA RURAL BANK LTD-HOHOE',
                    'PATASE',
                    'PATRICE LUMUMBA ROAD',
                    'PEKI',
                    'PENTAGON LEGON BRANCH',
                    'PEX BANK - BOLGA CENTRE-BOLGATANGA',
                    'PEX BANK - CAPE COASTCAPE COAST',
                    'PEX BANK - TAMALE CENTRE-TAMALE',
                    'PEX BANK -HEAD OFFICE-SOUTH RIDGE ACCRA',
                    'PEX BANK -HOHOE CENTRE-HOHOE',
                    'PEX BANK KOFORIDUA CENTRE-KOFORIDUA',
                    'PEX BANK -SUNYANI CENTRE-SUNYANI',
                    'PEX BANK -WA CENTRE-WA',
                    'PEX BANKACCRA CENTRE-ACCRA',
                    'PEX BANK-HO CENTRE-HO',
                    'PEX BANK-KUMASIKUMASI',
                    'PEX BANK-TAKORADI CENTRE-TAKORADI',
                    'PEX LINK CENTRE-CEDI HOUSE',
                    'PIG FARM BRANCH',
                    'POKUASE',
                    'POKUASE BRANCH',
                    'PONUA RURAL BANK LTD-AMUANA PRASO',
                    'POST OFFICE SQUARE',
                    'POTSIN',
                    'PPER AMENFI RURAL BANK LTD-ANKWAWSO',
                    'PPER MANYA KRO RURAL BANK LTD-ASESEWA',
                    'PRAMPRAM',
                    'PRANG',
                    'PREMIER TOWERS',
                    'PREMPEH II STREET',
                    'PRESTEA',
                    'PRIVATE BANKING',
                    'PROCREDIT SAVINGS & LOANS',
                    'PUSIGA',
                    'RAKWA BRAMAN RURAL BANK LTD-BRAKWA',
                    'REINSURANCE HOUSE',
                    'REINSURANCE HOUSE BRANCH',
                    'REPUBLIC HOUSE',
                    'RIDGE',
                    'RIDGE BRANCH',
                    'RIDGE TOWERS',
                    'RING ROAD',
                    'RING ROAD BRANCH',
                    'RING ROAD CENTRAL',
                    'RING ROAD CENTRAL BRANCH',
                    'RING ROAD WEST',
                    'ROBO COMMUNITY RURAL BANK LTD-DROBO',
                    'ROMAN HILL',
                    'ROMAN HILL BRANCH',
                    'ROMAN HILL MARKET BRANCH',
                    'RRC',
                    'RRC REMITTANCE CENTRE',
                    'RUSSIA ROAD BRANCH',
                    'SABOBA',
                    'SAFE BOND',
                    'SAFE BOND JUBILEE',
                    'SAFE BOUND JUBILEE',
                    'SAFEBOND',
                    'SAKAMAN',
                    'SAKUMONO',
                    'SAKUMONO BRANCH',
                    'SALAGA',
                    'SALTPOND',
                    'SAMPA',
                    'SAMREBOI',
                    'SANDEMA',
                    'SANKORE',
                    'SANTA MARIA',
                    'SANTASI',
                    'SANTE AKYEM RURAL BANK LTD-JUANSA',
                    'SAVELUGU',
                    'SAWINSO RURAL BANK LTD-SEFWI-ASAWINSO',
                    'SAWLA',
                    'SEFWI BEKWAI',
                    'SEFWI BRANCH',
                    'SEFWI ESSAM',
                    'SEFWI WIAWO',
                    'SEFWI WIAWSO',
                    'SEFWIMAN RURAL BANK LTD-SEFWI-ASAW',
                    'SEFWI-WIASO',
                    'SEGE',
                    'SEKONDI',
                    'SEKYEDUMASI RURAL BANK LTD-SEKYEDUMASI',
                    'SEKYERE RURAL BANK LTD-JAMASI',
                    'SERVICE BRANCH',
                    'SHAI RURAL BANK LTD-DODOWA',
                    'SHIPPERS HOUSE BRANCH',
                    'SILVER STAR',
                    'SINAPI ABA SAVINGS & LOAN',
                    'SINAPI ABA SAVINGS & LOANS',
                    'SISSALA RURAL BANK LTD-TUMA',
                    'SME',
                    'SME CENTRE',
                    'SOATREMAN RURAL BANK LTD-NSOATRE',
                    'SOGAKOPE',
                    'SOKOBAN',
                    'SOKOBAN BRANCH',
                    'SOKORE RURAL BANK LTD-ASOKRE',
                    'SOMANYA',
                    'SONZELE RURAL BANK LTD-JIRAPA',
                    'SOUTH AKIM RURAL BANK LTD-NANKESE',
                    'SOUTH BIRIM RURAL BANK LTD-ACHIASE',
                    'SOUTH INDUSTRIAL AREA',
                    'SOUTH INDUSTRIAL AREA(SIA)',
                    'SOUTH INDUSTRIAL BRANCH',
                    'SOUTH LEGON BRANCH',
                    'SOWUTUOM',
                    'SPINTEX',
                    'SPINTEX BRANCH',
                    'SPINTEX MAIN',
                    'SPINTEX PRESTIGE',
                    'SPINTEX ROAD',
                    'SPINTEX ROAD BRANCH',
                    'SSINMAN RURAL BANK LTD-ASSIN MANSO',
                    'SSNIT HOUSE BRANCH',
                    'ST PETERS CO-OPERATIVE CREDIT UNION',
                    'STADIUM',
                    'STADIUM AMAKOM',
                    'STADIUM POST',
                    'STEWART AVENUE KUMASI',
                    'SUAME',
                    'SUAME BRANCH',
                    'SUAME MAAKRO',
                    'SUBONTEN RURAL BANK LTD-WORAWORA',
                    'SUHUM',
                    'SUHUM RURAL BANK',
                    'SUMA RURAL BANK LTD-SUMA AHENKRO',
                    'SUNYANI',
                    'SUNYANI CENTRAL',
                    'SUNYANI MAIN BRANCH',
                    'SUNYANI NIMPONG',
                    'SUNYANI ODUMASE',
                    'SUNYANI POST OFFICE BRANCH',
                    'SUNYANI POSTBANK',
                    'SUOGYAMAN RURAL BANK LTD-ACCRA',
                    'SUOPRA RURAL BANK LTD-AFOSU',
                    'SUTAMAN RURAL BANK LTD-NSUTA',
                    'SUTIFI RURAL BANK LTD-ACERENSUA',
                    'SWANMILL',
                    'SWEDRU',
                    'SWEDRU BRANCH',
                    'TAFO',
                    'TAFO BRANCH',
                    'TAIFA',
                    'TAKORADI',
                    'TAKORADI - KOKOMPE',
                    'TAKORADI BRANCH',
                    'TAKORADI EFFIEKUMA',
                    'TAKORADI HABOUR BRANCH',
                    'TAKORADI HARBOUR',
                    'TAKORADI HARBOUR ROAD',
                    'TAKORADI KOKOMPE',
                    'TAKORADI MAIN',
                    'TAKORADI MARKET CIRCLE',
                    'TAKORADI MKT CIRCLE',
                    'TAKORADI POST',
                    'TAKORADI POSTBANK',
                    'TAMALE',
                    'TAMALE ABOABO',
                    'TAMALE ABOABO BRANCH',
                    'TAMALE BRANCH',
                    'TAMALE CENTRAL',
                    'TAMALE HOSP ROAD',
                    'TAMALE HOSPITAL ROAD',
                    'TAMALE HOSPITAL ROAD BRANCH',
                    'TAMALE LAMESHEGU',
                    'TAMALE MAIN BRANCH',
                    'TAMALE MARKET',
                    'TANOKROM',
                    'TANOSO',
                    'TANTRA',
                    'TARKORADI MARKET',
                    'TARKWA',
                    'TARKWA BRANCH',
                    'TARKWA MINES',
                    'TEASE',
                    'TECH JUNCTION',
                    'TECHIMAN',
                    'TECHIMAN BRANCH',
                    'TECHIMAN MARKET',
                    'TECHIMAN MARKET BRANCH',
                    'TECHIMAN NORTH BRANCH',
                    'TECHIMAN TIA BRANCH',
                    'TECHIMAN VALLEY VIEW BRANCH',
                    'TECHIMAN WENCHI ROAD BRANCH',
                    'TEMA',
                    'TEMA BRANCH',
                    'TEMA COMM 1',
                    'TEMA COMM 2',
                    'TEMA COMM 4',
                    'TEMA COMM. 25',
                    'TEMA COMM. 6',
                    'TEMA COMMUNITY 1',
                    'TEMA COMMUNITY 1 BRANCH',
                    'TEMA COMMUNITY 2 BRANCH',
                    'TEMA COMMUNITY 25',
                    'TEMA COMMUNITY 25 BRANCH',
                    'TEMA COMMUNITY 6',
                    'TEMA COMMUNITY 9',
                    'TEMA COMMUNITY ONE',
                    'TEMA COMMUNITY TWO',
                    'TEMA DRYDOCK',
                    'TEMA EAST',
                    'TEMA FISHING HABOUR',
                    'TEMA FISHING HARBOUR',
                    'TEMA HABOUR',
                    'TEMA HARBOUR',
                    'TEMA INDUSTRIAL AREA',
                    'TEMA LONG ROOM',
                    'TEMA MAIN',
                    'TEMA MAIN BRANCH',
                    'TEMA MARKET',
                    'TEMA METRO BRANCH',
                    'TEMA OIL REFINERY',
                    'TEMA SAFEBOND',
                    'TEMA SHOPPING MAIL',
                    'TEMA SHOPPING MALL',
                    'TEMPANE BRANCH',
                    'TEPA',
                    'TEPAMAN RURAL BANK LTD - TEPAMAN',
                    'TESANO',
                    'TESANO BRANCH',
                    'TESHIE',
                    'TESHIE BRANCH',
                    'TESHIE NUNGUA',
                    'TETTEH QUARSHIE',
                    'THE SEED SAVINGS AND LOANS',
                    'TIA BRANCH',
                    'TIWA RURAL BANK LTD-KWABENG',
                    'TIZAA RURAL BANK LTD-TAMALE',
                    'TOENDE RURAL BANK LTD-ZIBILLA',
                    'TOLON',
                    'TONGU',
                    'TOR',
                    'TRADE FAIR',
                    'TRADE FAIR BRANCH',
                    'TREASURY',
                    'TREDE',
                    'TRUST TOWERS',
                    'TRUST TOWERS BRANCH',
                    'TSITO',
                    'TSOPOLI',
                    'TSUI BLEOO',
                    'TUASEKAN RURAL BANK LTD-KOFIASE',
                    'TUDU',
                    'TUDU BRANCH',
                    'TUMJU',
                    'TUMU',
                    'TUOBODOM',
                    'TUTU BRANCH',
                    'TWEABAN RURAL BANK LTD-SUNYANI',
                    'TWIFO PRASO',
                    'TWIFO RURAL BANK LTD-TWIFO AGONA',
                    'TWIMA KWANWOMA RURAL BANK LTD-PAKYI NO. 2',
                    'TWIMA MPONUA RURAL BANK LTD-TOASE',
                    'TWIMA RURAL BANK LTD-FOASE',
                    'UABEN RURAL BANK LTD-JUABEN',
                    'UCC',
                    'UEW BRANCH',
                    'UHUM RURAL BANK',
                    'UILSA COMMUNITY BANK LTD-SANDEMA',
                    'UMA RURAL BANK LTD-SUMA AHENKRO',
                    'UMAWUMAN RURAL BANK LTD-KUMAWU',
                    'UMPONG RURAL BANK LTD-OFRAMASE',
                    'UMUADA RURAL BANK LTD-OSINO',
                    'UNDP',
                    'UNION RURAL BANK LTD-SENYA BREKU',
                    'UNION SAVINGS & LOANS',
                    'UNION SAVINGS AND LOANS',
                    'UNION SAVINGS AND LOANS LIMITED',
                    'UNITY RURAL BANK LTD-ZIOPE',
                    'UNIV. OF CAPE COAST BRANCH',
                    'UNIVERSITY OF CAPE COAST',
                    'UNIVERSITY OF CAPE COAST BRANCH',
                    'UNIVERSITY OF GHANA BRANCH',
                    'UPPER AMENFI RURAL BANK LTD-ANKWAWSO',
                    'UPPER MANYA KRO RURAL BANK LTD-ASESEWA',
                    'UTAWU RURAL BANK LTD-HO',
                    'UTRAK SAVINGS AND LOANS',
                    'UWUULONSO RURAL BANK LTD-TAMALE',
                    'VAKPO',
                    'VALLEY VIEW',
                    'VALLEY VIEW UNIVERSITY- TECHIMAN',
                    'VENOR RURAL BANK LTD-AKATSI',
                    'WA',
                    'WA BRANCH',
                    'WABIAGYA RURAL BANK LTD-BAREKESE',
                    'WAEBIBIREM RURAL BANK LTD-ASUOM',
                    'WAHU PRASO RURAL BANK LTD-KWAHU PRASO',
                    'WAHU RURAL BANK LTD-KWAHU PEPEASE',
                    'WALEWALE',
                    'WAMANMAN RURAL BANK LTD-KWAMANG',
                    'WAMFIE',
                    'WAMFIE RURAL BANK LTD-WAMFIE',
                    'WASSA MANSO',
                    'WECHIAU',
                    'WEIJA',
                    'WEIJA BRANCH',
                    'WENCHI',
                    'WENCHI RURAL BANK LTD',
                    'WEST HILLS MALL',
                    'WEST LANDS',
                    'WEST MANPRUSI COMMUNITY BANK LTD-WALEWALE',
                    'WESTERN HILL',
                    'WESTERN RURAL BANK LTD-WASSA AKROPONG',
                    'WESTLANDS',
                    'WETO RURAL BANK LTD-KPEVE',
                    'WIDANA',
                    'WIFO RURAL BANK LTD-TWIFO AGONA',
                    'WINNEBA',
                    'WINNEBA BRANCH',
                    'WINNEBA RD',
                    'WINNEBA ROAD',
                    'WISCONSIN',
                    'WISCONSIN BRANCH',
                    'WOMANS WORLD BANKING',
                    'WOMENS WORLD BANK GHANA SAVINGS AND LOANS',
                    'WOMENS WORLD BANKING',
                    'WULENSI',
                    'WURIYENGA',
                    'WUTU EMASA RURAL BANK LTD-AWUMASA',
                    'YAA ASANTEWAA RURAL BANK LTD',
                    'YAKROM RURAL BANK LTD-NYAKROM',
                    'YANKUMASE AHENKRO RURAL BANK LTD-FANTI NYANKUMASE',
                    'YAPRA RURAL BANK LTD-PRANG',
                    'YEJI',
                    'YENDI',
                    'YESU NKWA',
                    'YIBI AREA RURAL BANK LIMITED',
                    'YUNYOO',
                    'ZABZUGU RURAL BANK LTD',
                    'ZEBILLA',
                    'ZEMA MANLE RURAL BANK LTD-AWIEBO',
                    'ZENITH BANK (GH) LTD-SUNYANI',
                    'ZENU',
                    'ZENU BRANCH',
                    'ZONGO JUNCTION',
                  ],
                  publishChange: true,
                  readonly: false,
                  tooltip: 'Account Branch Name',
                  type: 'field',
                  uid: '503a71583f1182e2',
                },
                {
                  id: 'account-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Account Number',
                  uid: '495c881bcf57de23',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: true,
          label: 'System List',
          uid: 'd1ac4ed6d65c6c5b',
          content: [],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '0e4aa5c3bc499efa',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '1881c4e375aa40d9',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8be3408b492a252e',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '261486a530f75645',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '8588b418b3adff34',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Payment Details',
    uri: 'below-100-savings/quote',
    class: 'below-100-savings-payment-details',
  },
  'stop-order-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: 'd29fde8fc9478ed6',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'stop-order-details',
          type: 'list',
          readonly: false,
          label: 'Stop Order Details',
          uid: '9507e4585fe783c4',
          content: [
            {
              id: 'employer-organisation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Employer Organisation',
              uid: '0822aa9611b07e14',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Abokobi Area Rural Bankstaff',
                'Accra Metro Assembly',
                'Adansi Rural Bank',
                'Advans Ghana',
                'Advent Press',
                'African Regent Hotel',
                'Agave Rural Bank',
                'Agric Development Bank',
                'Ahafo Ano Premier Rural Bank',
                'Ahantaman Rural Bankâ ',
                'Akaa Security Services',
                'Akatakyiman Rural Bank',
                'Akim Bosome Rural Bank',
                'Akwapem Rural Bank',
                'Aluworks',
                'Ama Dansowaa Clinic',
                'Amanano Rural Bank',
                'Amansie West Rural Bank',
                'Amatin & Kasei Comm Bank',
                'Antrak Ghana Ltd',
                'Anum Rural Bank Ltd',
                'Apex Bank',
                'Ashante Akyem Rural Bank',
                'Asokore Rural Bank Ltd - Kumasi',
                'Asutifi Rural Bank Limited',
                'Atlantic Int. Holding Co',
                'Atwima Kwanwoma Rural Bank',
                'Atwima Rural Bank',
                'Auto Parts Limited',
                'Awutu Emasa Rural Bank',
                'Ayrton Drug Manufacturing Limited',
                'Bank of Ghana',
                'Baptist Medical Centre',
                'Barge Operators and Allied Services Ltd',
                'Benso Oil Palm Plantationâ ',
                'Bessfa Rural Bank',
                'Bible Society of Ghana',
                'Bonzali Rural Bank',
                'Bosomtwe Rb',
                'Bureau of National Communication',
                'Bureau of National Investigations',
                'Busua Beach Resortâ ',
                'Capital Rural Bank',
                'Carmeuse Lime Productâ ',
                'Cejose Wisdom Int. School',
                'Chemico Limited',
                'Chirano Gold Mines',
                'Christ Ebenezer',
                'Church Hill of God',
                'Cocoa Marketiing Company Ltd',
                'Cocoa Processing Comp',
                'Controller',
                'Cssvd Control Unit',
                'Dangme Rural Bank Ltd - Staff',
                'De Youngsters International School Ltd',
                'Deoke Foundation Schools',
                'Derma Area Rural Bank',
                'Dizengoff Ghana Ltd',
                'Dynamic Insurance Brokers',
                'Ebennezer Osei Brenya Enterprise',
                'Ejuraman Rural Bank',
                'Electricity Company of Ghana',
                'Elmina Beach Resort',
                'Englebert School',
                'Enterprise Funeral Services',
                'Enterprise Group Ltd',
                'Enterprise Insurance',
                'Enterprise Life Reps',
                'Enterprise Life Staff',
                'Enterprise Properties Ltd',
                'Enterprise Trustees Limited',
                'Epa',
                'Eskay Therapeutics Ltd',
                'Fiagya Rural Bank Ltd',
                'Fiaseman Rural Bankâ ',
                'Fiesta Royale Hotel',
                'First National Savings and Loansâ ',
                'Forces Pay Office',
                'Ga East Municipal Assembly',
                'Ga Rural Bank',
                'Ga Rural Customers',
                'Gafco',
                'Garden City - Multi Credit',
                'Gcb Bank Ltd Staff',
                'Ghana Airport Company Ltd.',
                'Ghana Bauxite Company Limted',
                'Ghana Cement',
                'Ghana Chamber of Mines',
                'Ghana Civil Aviation Authority',
                'Ghana Cocoa Board',
                'Ghana Grid Company',
                'Ghana Heavy Equipment Limited',
                'Ghana Link Network Services Ltd',
                'Ghana Maritime Authority',
                'Ghana Medical School',
                'Ghana Oil Co. Ltd',
                'Ghana Police Service',
                'Ghana Post',
                'Ghana Railway Co. Ltd',
                'Ghana Re Company Limited',
                'Ghana Revenue Authority',
                'Ghana Standard Board',
                'Ghana Union Conference of s.d.a.',
                'Gihoc Distilleries Company Limited',
                'Gimpa',
                'Globeserve Ministry Intâ ',
                'Golden Exotics Ltd',
                'Goldengate Services Ltd',
                'Gpha - Takoradi',
                'Gpha - Tema',
                'Grace Baptist School - Kumasi',
                'Guiness Ghana Ltd',
                'Gwcl - Accra East',
                'Gwcl - Accra West',
                'Gwcl - Ashanti North',
                'Gwcl - Ashanti Production',
                'Gwcl - Ashanti South',
                'Gwcl - Cape Coast',
                'Gwcl - Greater Accra Region Production',
                'Gwcl - Head Office',
                'Gwcl - Koforidua',
                'Gwcl - Sunyani',
                'Gwcl - Takoradi',
                'Gwcl - Tema',
                'Hobats Clinic',
                'Holiday Inn Accra',
                'Home Finance Company Bank Ltd',
                'House of Compassion Ministries',
                'Japan Motors Trading Company',
                'Juaben Rural Bank',
                'Jubilee Shipping Services Gh Ltd',
                'k.n.u.s.t',
                'Kaaseman Rural Bank Ltd',
                'Kessben',
                'Kingsgroup of Companies',
                'Kintampo Health Research Centre',
                'Kintampo Rural Bank Limited',
                'Koforidua Stop Order Walk In',
                'Korle Bu Teaching Hospital',
                'Krane Contsruction',
                'Krobo Danish Association',
                'Kuapa Kokoo',
                'Kumawuman Rural Bnk',
                'Kwamaman Rural Bank',
                'La Nkwantanang Madina Municipal Assembly',
                'Labadi Beach Hotel',
                'Life Healthcare Medical Dental and Optical Clinic',
                'Living Star School',
                'Lordship Insurance Brokers',
                'Lower Pra Rural Bank',
                'Maxam Ghana Ltd',
                'Mechanical Lloyd',
                'Metromass',
                'Mojo Investment Ltd',
                'Mondial Veneer Gh. Ltd',
                'Mumuada Rural Bank',
                'Nafana Rural Bank Ltd',
                'National Security',
                'Nexans',
                'Nick Tc Scan Ltd',
                'Nsoatreman Rural Bank',
                'Nthc Properties Ltd',
                'Nwabiagya Rural Bank',
                'Okomfo Anokye Rural Bank Staff',
                'Opportunity Int',
                'Parliament',
                'Pbc Ltd',
                'Pentecost Preparatory Schoolâ ',
                'Pentecost University College',
                'Phyto Riker',
                'Poly Products',
                'Presbyterian Womens Center - Abokobi',
                'Prince of Peace',
                'Quality Control Division (Cocobod)',
                'Regional Maritime Academy',
                'Ridge Church School',
                'Safebound Company Limited - Tema',
                'Sakworg Company Ltd',
                'Samartex Timber and Plywood Company',
                'Sandvik Mining and Construction Limited',
                'Scanbech Ghana Ltd',
                'Sdv Ghana',
                'Securities Discount Company Limited',
                'Sekyedumase Rural Bank',
                'Sekyere Rural Bank',
                'Services Intergrity Savings and Loans',
                'Sgs Laboratory Services',
                'Sg Ssb',
                'Silver Star Auto Ltd',
                'Sinapi Aba Trust',
                'Sofaamy Company Limited',
                'Sos Childrens Village Ghana',
                'South Birim Rural Bank',
                'South Central Conference of Sda',
                'Special Ice Company Ltd',
                'Ssnit',
                'St Ambrose College',
                'St Michaels Hospital Pramso',
                'Stellar Logistic',
                'Sunyani Cluster Icps',
                'Takoradi Flour Mill Ltd',
                'Takoradi Int. Co.',
                'Tct',
                'Tema Development Corp.',
                'Tema Parents Association School',
                'Tesano Baptist Church',
                'The Church of Pentecost',
                'Toende Rural Bank',
                'Trasacco',
                'Twifo Oil Palm Plantation',
                'Type Company Ltd',
                'Unilever',
                'University College of Education',
                'University for Development Studies',
                'University of Energy and Natural Resources',
                'University of Ghana - Legon',
                'Upper Amenfi Rural Bank',
                'Upper Manya Kro Rural Bank',
                'Utrak Savings and Loans',
                'Valley View',
                'Valley View University Basic School',
                'Vat Service',
                'Volta Hotel',
                'Volta River Authority',
                'Waec - Headquarters',
                'Waec - Main',
                'West Africa Mills Co. Ltd',
                'Weto Rural Bank',
                'Winners Chapel Ghana',
                'Yaakson Herbal Centre',
                'Yarsako',
              ],
            },
            {
              id: 'employee-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Employee Number',
              uid: 'de5d87a0cd14bafb',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
          ],
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: true,
          label: 'System List',
          uid: 'd1ac4ed6d65c6c5b',
          content: [],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '0e4aa5c3bc499efa',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '1881c4e375aa40d9',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8be3408b492a252e',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '261486a530f75645',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '8588b418b3adff34',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Payment Details',
    uri: 'below-100-savings/quote',
    class: 'below-100-savings-payment-details',
  },
  'mobile-wallet-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: '0c2ef12afcfe9b9a',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: false,
          label: 'System List',
          uid: '51ec63fbc00239ee',
          content: [
            {
              id: 'mobile-wallet-details',
              type: 'list',
              readonly: false,
              label: 'Mobile Wallet Details',
              uid: '2d34ee1c0b75f4a2',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '7577327f9a279ee2',
                  content: '<p>Please provide your mobile wallet details</p>\n',
                },
                {
                  id: 'mobile-wallet-network-provider',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Mobile Wallet Network Provider',
                  uid: '3a6d7817942bbe73',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Mobile Wallet Network Provider',
                  options: ['', 'Mtn', 'Vodafone'],
                },
                {
                  id: 'mobile-wallet-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Mobile Wallet Number',
                  uid: '165034a17f3ef9dd',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: 'Mobile Wallet Number',
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '0e4aa5c3bc499efa',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '1881c4e375aa40d9',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8be3408b492a252e',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '261486a530f75645',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '8588b418b3adff34',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Payment Details',
    uri: 'below-100-savings/quote',
    class: 'below-100-savings-payment-details',
  },
  'payment-details-verification': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details-signed',
      type: 'list',
      readonly: false,
      label: 'Payment Details Signed',
      uid: '0c1e10d724a6fec1',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '3d456e0848aa07fd',
          content: '<h2>Payment Details Verification</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'be1e2b1ea1a243b0',
          content: '<p>Please confirm payment details by signing below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'ee768accd6bcc82e',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'payment-details-summary',
          type: 'list',
          readonly: true,
          label: 'Payment Details Summary',
          uid: '3dfc3c4dd8295cd5',
          content: [
            {
              id: 'contract-id',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Application Reference Number',
              uid: 'c56972c7be439b96',
              content: '',
              inputType: 'text',
            },
            {
              id: 'payer-employment-details',
              type: 'list',
              readonly: true,
              label: 'Payer Employment Details',
              uid: 'f3ca71aa9ffb82ac',
              content: [],
            },
          ],
        },
        {
          id: 'signature-section',
          type: 'list',
          readonly: false,
          label: 'Signature Section',
          uid: '6bbbcf51ffc6fbe6',
          content: [
            {
              id: 'interactive-canvas-html',
              type: 'component',
              component: 'InteractiveCanvas',
              readonly: false,
              label: 'Interactive Canvas',
              uid: '2b6864784d4c6f97',
              content:
                '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '35866f0b4c9104d3',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'dafe4079c9604906',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '6d7e7b56c0d9debf',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '1418229d47efa9f4',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '9262e0eaafc96904',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Payment Details',
    uri: 'below-100-savings/quote',
    class: 'below-100-savings-payment-details',
  },
  'upload-documents': {
    text: [],
    buttons: [
      {
        label: 'Upload ID document',
        id: 'upload-id-document',
        uid: '69a53b6758f4ff80',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'upload-documents',
      type: 'list',
      readonly: true,
      label: 'Upload Documents',
      uid: '1511bc9f84f134b2',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '2dd71fd55a00cef9',
          content: '<h2>Upload Documents</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f4179312612d1ce4',
          content:
            '<p>Please upload the necessary documentation or proceed to the next page if you would like to provide it later.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'e567849ef903ec0e',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='done clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'documents-list',
          type: 'list',
          readonly: true,
          label: 'Documents List',
          uid: '8a30a43b57bb332b',
          content: [
            {
              id: 'system-field-outcome',
              type: 'html',
              readonly: true,
              label: '',
              uid: '7721dd56ec3e43de',
              content:
                "<p><a href='#upload-id-document' class=''>ID Document <span>Re Upload</span></a></p>\n",
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '435d067985765c84',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'e651dd46bc389514',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'e8b87a30a8dc49ba',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '2a87c254915eccfd',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '4e4fe20b1a8844de',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Upload Documents',
    uri: 'below-100-savings/upload-documents',
    class: 'below-100-savings-upload-documents',
  },
  'upload-document-pages': {
    modal: true,
    buttons: [
      {
        control: false,
        id: 'add-page',
        label: 'Add Page',
        uid: '1a5450d98db152d6',
      },
      {
        control: true,
        id: 'abort',
        label: 'Abort',
        uid: 'e11279c7f15a77ea',
      },
      {
        control: true,
        id: 'continue',
        label: 'Continue',
        uid: '0d54090d869303f6',
      },
    ],
    item: {
      content: [
        {
          content:
            '<p>Please upload your document below. If it consists of multiple pages, please upload all of them together.</p>\n',
          id: 'system-field-outcome',
          label: '',
          readonly: true,
          type: 'html',
          uid: '8872705861783d2d',
        },
        {
          content: [],
          id: 'uploads',
          label: 'Uploads',
          readonly: false,
          type: 'table',
          uid: '2d999fbaaefb947c',
        },
        {
          content:
            '<p><a class="button add-page-button" href="#add-page">Add Page</a></p>\n',
          id: 'system-constant',
          label: '',
          readonly: true,
          type: 'html',
          uid: 'a47ce0e3f28e09c5',
        },
      ],
      id: 'upload-section',
      label: 'Upload Section',
      readonly: false,
      type: 'list',
      uid: '74b77d523e9f9b6d',
    },
    class: 'upload-layout',
    context: 'Upload Layout',
    template: '',
    text: [],
    title: 'Public Access \n Welcome to Elac',
    uri: 'upload-document/file/new',
  },
  'acceptance-screen': {
    text: [],
    buttons: [
      {
        label: 'Submit',
        id: 'submit',
        uid: 'ef8243ce592928b2',
        control: false,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'acceptance',
      type: 'list',
      readonly: false,
      label: 'Acceptance',
      uid: '8995a7af69a348ea',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '981d430846482fe8',
          content: '<h2>Summary</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f07080f78f7cac67',
          content: '<p>Please verify the details below</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '65dbe261db324f63',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='done clickable'><a href='#uploads'>Uploads</a></li>\n<li class='done clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'summary',
          type: 'list',
          readonly: true,
          label: 'Summary',
          uid: '12c0066914d7e16e',
          content: [
            {
              id: 'contract-id',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Application Reference Number',
              uid: '4d1e978720fb5e43',
              content: '',
              inputType: 'text',
            },
            {
              id: 'life-assured-identification',
              type: 'list',
              readonly: true,
              label: 'Life Assured Identification',
              uid: 'b937abc47a3c2cf2',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: '0b3f98557f8e9284',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: '597b3d4a6dbb41a6',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'idpassport-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'ID Number',
                  uid: 'fecc9856412d6b21',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '4fbcb5f5033fa4a4',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: 'ed95cacac04323fd',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'marital-status',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Marital Status',
                  uid: '0e8075522a13b595',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'nationality',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Nationality',
                  uid: 'fd96e1398cc06178',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'gender',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Gender',
                  uid: '25970a93188b88d4',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'id-type',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'ID Type',
                  uid: 'fc98cb44ad30f614',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'life-assured-contact-details',
              type: 'list',
              readonly: true,
              label: 'Life Assured Contact Details',
              uid: '36788fbefebe8638',
              content: [
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '4f9e98cae52cd1fd',
                  content: '',
                  inputType: 'tel',
                },
                {
                  id: 'email',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Email',
                  uid: '335f6e4f2371391c',
                  content: '',
                  inputType: 'email',
                },
              ],
            },
            {
              id: 'life-assured-postal-address',
              type: 'list',
              readonly: true,
              label: 'Life Assured Postal Address',
              uid: 'b4ac7437cca0b535',
              content: [
                {
                  id: 'po-box-or-private-bag',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'PO Box Or Private Bag',
                  uid: '49445ab27c5f04bb',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'area-code',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Area Code',
                  uid: '84dffb1b09bbb530',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'street',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Street',
                  uid: '54d1167f7e19114c',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'suburb',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Suburb',
                  uid: '2c14f2392cbb1a1e',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'town',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Town',
                  uid: '45eb7b455e071a11',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'region',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Region',
                  uid: '4aefee52942b615b',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'spouse-identification',
              type: 'list',
              readonly: true,
              label: 'Spouse Identification',
              uid: '2e842375c9b06680',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: 'ccd538c2088052bd',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: 'b677673e4cacec33',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: '5a0f975ff6b5e243',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'marital-status',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Marital Status',
                  uid: 'ce7b960d4a5e2fa6',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: 'f3076f00334f2cb3',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'gender',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Gender',
                  uid: 'cfc476ce3a327d90',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'spouse-contact-details',
              type: 'list',
              readonly: true,
              label: 'Spouse Contact Details',
              uid: 'efba3a7af7a5ac8a',
              content: [
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '73d625bddc443835',
                  content: '',
                  inputType: 'tel',
                },
              ],
            },
            {
              id: 'trustee-identification',
              type: 'list',
              readonly: true,
              label: 'Trustee Identification',
              uid: 'f9f29cc313250373',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: '48ee1e7cae322da6',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: 'd1a1895c15a6723a',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: '44443c7a2e61cf83',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '890da04c904445a0',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '6360b42285a0f5e2',
                  content: '',
                  inputType: 'tel',
                },
                {
                  id: 'relationship',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Relationship',
                  uid: 'bed853c124158693',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'trustee-contact-details',
              type: 'list',
              readonly: true,
              label: 'Trustee Contact Details',
              uid: 'f9f29cc313250373',
              content: [
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '6360b42285a0f5e2',
                  content: '',
                  inputType: 'tel',
                },
              ],
            },
            {
              id: 'beneficiary-details',
              type: 'table',
              readonly: true,
              label: 'Beneficiary Details',
              uid: 'b03d6fadec7e04a3',
              content: [
                [
                  {
                    id: 'beneficiary-role',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'Beneficiary Role',
                    uid: '90f74f80c6fb538d',
                    content: 'Beneficiary-1',
                    inputType: 'text',
                  },
                  {
                    id: 'first-names',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'First Names',
                    uid: 'f57633ad62c9188b',
                    content: 'Laop',
                    inputType: 'text',
                  },
                  {
                    id: 'surname',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'Surname',
                    uid: 'c799c54d5f89b18b',
                    content: 'Asd',
                    inputType: 'text',
                  },
                  {
                    id: 'benefit-split',
                    type: 'real',
                    elementType: 'input',
                    readonly: true,
                    label: 'Benefit Split',
                    uid: 'da32277ba38b1094',
                    content: '100.00',
                    inputType: 'number',
                  },
                  {
                    id: 'role',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'Role',
                    uid: '35a6afe04925eace',
                    content: 'Cessionary',
                    inputType: 'text',
                  },
                  {
                    id: 'gender',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'Gender',
                    uid: 'e1aedd1cc1dd7b7d',
                    content: 'Male',
                    inputType: 'text',
                  },
                  {
                    id: 'date-of-birth',
                    type: 'integer',
                    elementType: 'input',
                    readonly: true,
                    label: 'Date Of Birth',
                    uid: '61c5408a99676d66',
                    content: '19900405',
                    inputType: 'number',
                  },
                ],
              ],
            },
            {
              id: 'payer-person-identification',
              type: 'list',
              readonly: true,
              label: 'Payer Person Identification',
              uid: 'fe8d97f7b55d0cdf',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: '9a1acf70193ff100',
                  content: 'Laop',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: '56235cf10e4eccb3',
                  content: 'Asd',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '9f20d39f7c44486d',
                  content: '19900405',
                  inputType: 'number',
                },
              ],
            },
            {
              id: 'payment-details',
              type: 'list',
              readonly: true,
              label: 'Payer Details',
              uid: '887ff5379d20fc16',
              content: [],
            },
          ],
        },
        {
          id: 'interactive-canvas-html',
          type: 'component',
          component: 'InteractiveCanvas',
          readonly: false,
          label: 'Interactive Canvas',
          uid: '1fcad0e41194c3d1',
          content:
            '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '2265e3664824a5e7',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'c56757f9fc276612',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '1545b3fe6f7b46a9',
                  content: '<p>Below 100 <strong>Savings</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '28ce4f2f87f3adaa',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'bbd7cd4827d9d0c8',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Below 100 Savings Acceptance Screen',
    uri: 'below-100-savings/acceptance',
    class: 'below-100-savings-acceptance-screen',
  },
}

export const acceptancePaymentDetails = (form) => {
  if (form['payment-method-selection']['debit-order'] === 'Y') {
    return {
      content: [
        {
          content: form['payment-bank-details']['account-entity-name'] || '',
          elementType: 'input',
          id: 'account-entity-name',
          inputType: 'text',
          label: 'Account Entity Name',
          readonly: true,
          type: 'field',
          uid: 'e47cf0c452614349',
        },
        {
          content: form['payment-bank-details']['account-holder-name'] || '',
          elementType: 'input',
          id: 'account-holder-name',
          inputType: 'text',
          label: 'Account Holder Name',
          readonly: true,
          type: 'field',
          uid: '3d49b733e15abc7c',
        },
        {
          content: form['payment-bank-details']['account-number'] || '',
          elementType: 'input',
          id: 'account-number',
          inputType: 'text',
          label: 'Account Number',
          readonly: true,
          type: 'field',
          uid: 'cf966f446acc41ae',
        },
        {
          content: form['payment-bank-details']['account-type'] || '',
          elementType: 'input',
          id: 'account-type',
          inputType: 'text',
          label: 'Account Type',
          readonly: true,
          type: 'field',
          uid: 'e8cfb99fa1289443',
        },
        {
          content: form['payment-bank-details']['account-branch-name'] || '',
          elementType: 'input',
          id: 'account-branch-name',
          inputType: 'text',
          label: 'Account Branch Name',
          readonly: true,
          type: 'field',
          uid: '30738e87a98b959c',
        },
      ],
      id: 'payment-bank-details',
      label: 'Payment Bank Details',
      readonly: true,
      type: 'list',
      uid: '80eb57ac744be154',
    }
  } else if (form['payment-method-selection']['stop-order'] === 'Y') {
    return {
      id: 'payer-employment-details',
      type: 'list',
      readonly: true,
      label: 'Payer Employment Details',
      uid: '9f4ebc3347190f87',
      content: [
        {
          id: 'employer-organisation',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employer Organisation',
          uid: '8d379c9e95d45b1c',
          content: form['stop-order-details']['employer-organisation'] || '',
          inputType: 'text',
        },
        {
          id: 'employee-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employee Number',
          uid: '2aeba28f1d28c239',
          content: form['stop-order-details']['employee-number'] || '',
          inputType: 'text',
        },
      ],
    }
  } else if (form['payment-method-selection']['mobile-wallet'] === 'Y') {
    return {
      id: 'mobile-wallet-details',
      type: 'list',
      readonly: true,
      label: 'Mobile Wallet Details',
      uid: '9f4ebc3347190f87',
      content: [
        {
          id: 'mobile-wallet-network-provider',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Mobile Network Provider',
          uid: '8d379c9e95d45b1c',
          content:
            form['mobile-wallet-details']['mobile-wallet-network-provider'] ||
            '',
          inputType: 'text',
        },
        {
          id: 'mobile-wallet-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Mobile Wallet Number',
          uid: '2aeba28f1d28c239',
          content: form['mobile-wallet-details']['mobile-wallet-number'] || '',
          inputType: 'text',
        },
      ],
    }
  } else {
    return null
  }
}

export const beneficiaryItem = (b, counter) => [
  {
    id: 'beneficiary-role',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Beneficiary Role',
    uid: `f35595dfe84ccc32-${counter}`,
    content: `Beneficiary-${counter + 1}`,
    inputType: 'text',
  },
  {
    id: 'first-names',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'First Names',
    uid: `45b46971a875150a-${counter}`,
    content: b['first-names'] || '',
    inputType: 'text',
  },
  {
    id: 'surname',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Surname',
    uid: `3b48bea6904714d1-${counter}`,
    content: b.surname || '',
    inputType: 'text',
  },
  {
    id: 'benefit-split',
    type: 'real',
    elementType: 'input',
    readonly: true,
    label: 'Benefit Split',
    uid: `945421f454cb1b58-${counter}`,
    content: b['benefit-split'] || '',
    inputType: 'number',
  },
  {
    id: 'role',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Role',
    uid: `62705a905026834d-${counter}`,
    content: b.role || '',
    inputType: 'text',
  },
  {
    id: 'gender',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Gender',
    uid: `58cb28acbf7c5468-${counter}`,
    content: b.gender || '',
    inputType: 'text',
  },
  {
    id: 'date-of-birth',
    type: 'integer',
    elementType: 'input',
    readonly: true,
    label: 'Date Of Birth',
    uid: `7dd6d8c600476662-${counter}`,
    content: b['date-of-birth'] || '',
    inputType: 'number',
  },
]

export const benefitSplitItem = (b, counter) => ({
  id: `beneficiary-${counter + 1}`,
  type: 'list',
  readonly: false,
  label: `Beneficiary-${counter + 1}`,
  uid: `8dca6eb49ac65bcc-${counter}`,
  content: [
    {
      id: 'system-constant',
      type: 'html',
      readonly: true,
      label: '',
      uid: `7259b360e6ec1490-${counter}`,
      content: `<p>${b['first-names']}</p>\n`,
    },
    {
      id: `beneficiary-split-${counter + 1}`,
      type: 'field',
      elementType: 'input',
      readonly: false,
      label: 'Beneficiary Split',
      uid: `8644e07220d3617d-${counter}`,
      content: b['benefit-split'] || '',
      publishChange: true,
      inputType: 'text',
    },
  ],
})
