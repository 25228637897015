/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'
import InstallPrompt from '../containers/InstallPrompt'

const environment = () =>
  process.env.APPLICATION_ENV !== 'Production'
    ? ` - ${process.env.APPLICATION_ENV.toUpperCase()} ENVIRONMENT`
    : ''

const includeHelp = process.env.APPLICATION_ENV !== 'Production'

const Header = ({
  enterprise,
  product,
  title,
  agent,
  postponeProcess,
  agentLogout,
}) => (
  <header data-title={`${enterprise}${environment()}`}>
    <nav>
      <img src={process.env.APPLICATION_LOGO} alt={enterprise} />
      <ul className='navigation'>
        {includeHelp && (
          <li className='nav-item'>
            <h1
              onClick={() =>
                window.Sentry && window.Sentry.captureMessage('Help')
              }
            >
              {process.env.APPLICATION_ENV}
            </h1>
          </li>
        )}
        {title && <li className='nav-item'>{title}</li>}
        {agent && (
          <li
            className={classNames({ 'nav-item': true, 'nav-dropdown': true })}
          >
            <label htmlFor='headerNav'>
              <b>Agent</b>: {agent}
            </label>
            <input type='checkbox' id='headerNav' />
            <div className='nav-dropdown-content'>
              <a href='#' onClick={agentLogout}>
                Logout
              </a>
            </div>
          </li>
        )}
        {product ? (
          <a href='#' className='button postpone' onClick={postponeProcess}>
            Save
          </a>
        ) : (
          <InstallPrompt />
        )}
      </ul>
    </nav>
    <div className='clearfix' />
  </header>
)

export default Header
