/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import { ulid } from 'ulid'
import { parseRoute } from './router'
import { postponeProcess, changeRoute } from './actions'

// Load application business logic
const logic = require(process.env.APPLICATION_CONTENT)

const productInfo = ({ id, label, description }) => ({ id, label, description })

export const products = Object.values(logic).map(productInfo)

export const newProcess = (state, { product }) => {
  if (product in logic) {
    logic[product].createState(state)
    state.currentProcess.product = logic[product].id
    state.currentProcess.label = logic[product].label
    state.currentProcess.agent = state.agentContractId
    state.currentProcess['process-id'] = ulid()
  } else {
    state.currentProcess = null
    state.error = `Invalid product ${product}`
  }
}

// Select a link in the current process
export const select = (state, target, asyncDispatch) => {
  if (!state.currentProcess) {
    if (process.env.APPLICATION_ENV !== 'Production') {
      // Development tools
      if ('route' in target) {
        parseRoute(target.route).forEach(r => {
          switch (r.route) {
            case 'setStatus':
              if ('ref' in r.params && 'status' in r.params) {
                state.completed.forEach(item => {
                  if (item.reference === r.params.ref) {
                    item.status = r.params.status
                  }
                })
              }
              break
          }
        })
      }
    }
    return
  }
  let btn = null
  if ('button' in target) {
    btn = state.currentProcess.page.buttons.find(
      b => b.uid === target.button.uid
    )
  } else if ('route' in target) {
    const route = target.route.split(/#|\//)
    if (route.length > 0) {
      if (route[0].length < 1) {
        // hash route will have first element empty
        route.shift()
      }
      const name = route.shift()
      btn = state.currentProcess.page.buttons.find(b => b.id === name)
      if (route.length > 0) {
        // process any further routes as hash routes
        asyncDispatch(changeRoute(`#${route.join('#')}`))
      }
    }
  } else if ('item' in target) {
    logic[state.currentProcess.product].processItemClick(
      state.currentProcess,
      target,
      asyncDispatch
    )
  }
  if (btn) {
    if (state.currentProcess && btn.id === 'postpone') {
      asyncDispatch(postponeProcess(state.currentProcess['process-id']))
    } else {
      logic[state.currentProcess.product].processButton(
        state.currentProcess,
        btn,
        asyncDispatch
      )
    }
  }
}

// Update a value in the current process
export const update = (state, target, asyncDispatch) =>
  logic[state.currentProcess.product].processChange(
    state.currentProcess,
    target
  )

export default logic
