/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import { connect } from 'react-redux'
import { createContent } from '../core/content'

const ProcessContent = ({ page, dispatch }) => createContent(page, dispatch)

export default connect(({ environment }) => environment.currentProcess)(ProcessContent)
