/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'

const isCurrency = (inputType, itemType) =>
  process.env.APPLICATION_CURRENCY &&
  inputType === 'number' &&
  itemType === 'amount'

export const inputSymbolContainerClasses = (
  id,
  inputType,
  itemType,
  prefix,
  suffix
) => ({
  'has-input-group-prepend': prefix || isCurrency(inputType, itemType),
  'has-input-group-append': suffix || id.endsWith('-percentage'),
})

const InputSymbol = ({ id, inputType, itemType, prefix, suffix }) => {
  if (prefix && suffix) {
    return (
      <>
        <div className='input-group-prepend'>
          <span id='input-name-prefix'>{prefix}</span>
        </div>
        <div className='input-group-append'>
          <span id='input-name-suffix'>{suffix}</span>
        </div>
      </>
    )
  } else if (prefix) {
    return (
      <div className='input-group-prepend'>
        <span id='input-name-prefix'>{prefix}</span>
      </div>
    )
  } else if (suffix) {
    return (
      <div className='input-group-append'>
        <span id='input-name-suffix'>{suffix}</span>
      </div>
    )
  } else {
    if (isCurrency(inputType, itemType)) {
      return (
        <div className='input-group-prepend'>
          <span id='input-name-currency'>
            {process.env.APPLICATION_CURRENCY}
          </span>
        </div>
      )
    } else if (id.endsWith('-percentage')) {
      return (
        <div className='input-group-append'>
          <span id='input-name-percent'>%</span>
        </div>
      )
    }
  }
  return null
}

export default InputSymbol
