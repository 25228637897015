/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import {
  createItem,
  createButton,
  slugify,
} from '../../../../src/core/dataitems'
import options from '../../options.json'
import dayjs from 'dayjs'

const loadOptions = (uid) => (uid in options ? options[uid].values : [])
const thisYear = dayjs().year()

export const pages = {
  'confirm-cancel': {
    modal: true,
    text: [],
    buttons: [
      { label: 'Yes', id: 'yes', uid: '0baa62eba1b1a7d7', control: true },
      { label: 'No', id: 'no', uid: '6c0e0e2956ec3933', control: true },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '9bc15702a2e49228',
      content: ['Are you sure you want to cancel this application?'],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'quotation-details': {
    text: [],
    buttons: [
      {
        label: 'More Info',
        id: 'more-info',
        uid: '37610f3c75b1ae60',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'quote-screen',
      type: 'list',
      readonly: false,
      label: 'Quote Screen',
      uid: '5268ecf541dc98be',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '552d19e7a2f676a3',
          content: '<h2 id="choose-your-cover">Choose your cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '94e9794e11a5bd34',
          content:
            '<p>In order to receive a quotation, we require the following information from you</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output Html',
          uid: 'dca307e831b918e8',
          content:
            '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="clickable"><a href="#additional">Additional</a></li>\n<li class="clickable"><a href="#details">Details</a></li>\n<li class="clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
        },
        {
          id: 'cover-details',
          type: 'list',
          readonly: false,
          label: 'Cover Details',
          uid: '43ac1fd1492177da',
          content: [
            {
              id: 'will-you-also-be-covered-under-this-policy',
              type: 'field',
              elementType: 'radio',
              readonly: false,
              label: 'Will you also be covered under this policy?',
              uid: '5db6a42a2a210661',
              content: 'Yes',
              publishChange: true,
              options: ['Yes', 'No'],
            },
            {
              id: 'date-of-birth',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Date Of Birth',
              uid: 'f17d3e9cdf443c93',
              content: '',
              publishChange: true,
              errors: [],
              inputType: 'date',
              component: 'DateSelector',
              maxYear: thisYear,
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'e29789e59b5ab7e0',
              content: '<p>Choose a policy term</p>\n',
            },
            {
              id: 'policy-term-radio-group',
              type: 'list',
              readonly: false,
              label: 'Policy Term Radio Group',
              uid: '6e43ab6550fcfd9f',
              content: [
                {
                  id: '10-years',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: '10 years',
                  uid: 'dbfab6f309a374ed',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  tooltip: '10 years',
                  options: ['Y'],
                },
                {
                  id: '15-years',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: '15 years',
                  uid: '988b04cc1989e450',
                  content: '',
                  publishChange: true,
                  tooltip: '15 years',
                  options: ['Y'],
                },
              ],
            },
            {
              id: 'system-list',
              type: 'list',
              readonly: false,
              label: 'System List',
              uid: '4bfba8a734e6c514',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'c795da1c0b9a2e9f',
                  content:
                    '<p>How much would you like to be covered for?</p>\n',
                },
                {
                  id: 'main-cover',
                  type: 'amount',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Main Cover',
                  uid: '0a3d406a19391722',
                  content: '',
                  publishChange: true,
                  options: [
                    '2000',
                    '5000',
                    '7500',
                    '10000',
                    '15000',
                    '20000',
                    '25000',
                    '30000',
                    '40000',
                    '50000',
                  ],
                },
              ],
            },
            {
              id: 'optional-benefits',
              type: 'list',
              readonly: false,
              label: 'Optional Benefits',
              uid: '69cd412db0bc000b',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'a718e1d06f5aaa23',
                  content:
                    '<p>The following benefits are optional for all covered lives. The options chosen will be added to your Funeral plan.</p>\n',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ac743cfd4d36da48',
                  content: '<p><a href="#more-info">More Info</a></p>\n',
                },
                {
                  id: 'memorial-cover',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Memorial Cover',
                  uid: 'fc146b97c4fd7bd4',
                  content: '',
                  publishChange: true,
                  tooltip: 'Memorial Cover',
                  options: ['Y', 'N'],
                },
                {
                  id: 'accident-benefit-double',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Accident Benefit Double',
                  uid: '5da11d6a0f032ba5',
                  content: '',
                  publishChange: true,
                  tooltip: 'Double Accidental Benefit',
                  options: ['Y'],
                },
                {
                  id: 'pre-burial-benefits',
                  type: 'list',
                  readonly: false,
                  label: 'Pre Burial Benefits',
                  uid: '0266955a9495ee7a',
                  content: [
                    {
                      id: '7-day-celebration-cover',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: '7 day Celebration Cover',
                      uid: 'b1759992c51afc04',
                      content: '',
                      publishChange: true,
                      tooltip: '7 day Celebration Cover',
                      options: ['Y', 'N'],
                    },
                    {
                      id: '40-day-celebration-cover',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: '40 day Celebration Cover',
                      uid: 'd2d27bed42d56f35',
                      content: '',
                      publishChange: true,
                      tooltip: '40 day Celebration Cover',
                      options: ['Y', 'N'],
                    },
                  ],
                },
                {
                  id: 'hospital-premium',
                  type: 'amount',
                  elementType: 'select',
                  readonly: false,
                  label: 'Hospital Premium',
                  uid: '5f075dfaf939f50a',
                  content: '',
                  publishChange: true,
                  tooltip: 'Hospital Cover per day',
                  options: ['', '50', '80', '100', '120', '150'],
                },
                {
                  id: 'personal-accident',
                  type: 'amount',
                  elementType: 'select',
                  readonly: false,
                  label: 'Personal Accident',
                  uid: 'b6d6aaca7a8fe11d',
                  content: '',
                  publishChange: true,
                  tooltip: 'Personal Accident',
                  options: ['', '10000', '20000', '30000', '40000', '50000'],
                },
              ],
            },
            {
              id: 'quotation-panel',
              type: 'list',
              readonly: true,
              label: 'Quotation Panel',
              uid: '3c0bb4597a88c783',
              content: [
                {
                  id: 'quotation-information',
                  type: 'list',
                  readonly: true,
                  label: 'Quotation Information',
                  uid: 'cb9cfafe153600a8',
                  content: [
                    {
                      id: 'product',
                      type: 'field',
                      elementType: 'input',
                      readonly: true,
                      label: 'Product',
                      uid: '6d24802265375cbd',
                      content: 'Family Funeral Plan Unlimited',
                      inputType: 'text',
                    },
                    {
                      id: 'benefit-rate',
                      type: 'amount',
                      elementType: 'input',
                      readonly: true,
                      label: 'Benefit Rate',
                      uid: '88773b4864cdcf12',
                      content: '',
                      inputType: 'number',
                    },
                    {
                      id: 'premium',
                      type: 'amount',
                      elementType: 'input',
                      readonly: true,
                      label: 'Premium',
                      uid: '333e206ce5cf85a5',
                      content: '',
                      inputType: 'number',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Quotation Details',
    uri: 'ffp-unlimited/quotation-details',
    template: 'ffp-unlimited-quote-screen',
    class: 'ffp-unlimited-quotation-details',
  },
  'additional-info': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Done',
        id: 'done',
        uid: '4e5f7031a7643ff6',
        control: true,
      },
    ],
    item: {
      id: 'terms-explained',
      type: 'list',
      readonly: true,
      label: 'Terms Explained',
      uid: '219d163f356b19a4',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '796a81f6f0ce33a4',
          content:
            '<h2 id="7-day-celebration-cover">7-Day Celebration Cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f93d34f5f852c156',
          content:
            '<p>This option pays 30% of the total sum assured upon confirmation of death.</p>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '30c7390d96ca4895',
          content:
            '<h2 id="40-day-celebration-cover">40-Day Celebration Cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f93d34f5f852c156',
          content:
            '<p>This option pays 50% of the total sum assured upon confirmation of death.</p>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f88e7c19a897bf5a',
          content: '<h2 id="memorial-cover">Memorial Cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'd6df126f33785061',
          content:
            '<p>This benefit provides 50% of the sum assured for a covered life 12 months after the death of the covered life.</p>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'abeab311c9c95ac1',
          content:
            '<h2 id="hospitalization-cover">Hospitalization Cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'c878baa7b2535d81',
          content:
            '<p>The policy will pay out sums to you if you are hospitalized for more than 2 nights and are calculated from the first day of hospitalization.</p>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '9f5def3a9429e92a',
          content:
            '<h2 id="double-accidental-benefit">Double Accidental Benefit</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '526326924640685b',
          content:
            '<p>This benefit pays an amount twice the value of the sum assured if the policyholder is above 54 and dies through and accident within the 6 months waiting period.</p>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'b7b78df2d1961f22',
          content: '<h2 id="cash-bonus">Cash Bonus</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a66adff30810a4fd',
          content:
            '<p>This allows the policyholder to maintain an investment account with a good return and withdraw a maximum of 50% of the toal premium after 12 months. Full withdrawal may be made after three years of contribution.</p>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '4ecebb109aa16b43',
          content: '<h2 id="inflation-protector">Inflation Protector</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'd39d9939c74cacfb',
          content:
            '<p>The premium will automatically increaseon the anniversary date by the percentage selected by the policyholder. It can be decreased during the lifetime of the policy but not increased.</p>\n',
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'quotation-family': {
    text: [],
    buttons: [
      {
        label: 'More Info',
        id: 'more-info',
        uid: '37610f3c75b1ae60',
        control: false,
      },
      {
        label: 'Add family',
        id: 'add-family',
        uid: '4944cc6ab7d9e98c',
        control: false,
      },
      {
        label: 'Remove family',
        id: 'remove-family',
        uid: 'a5ca5df087270493',
        control: false,
      },
      {
        label: 'Edit family',
        id: 'edit-family',
        uid: '0d437aba38a8517f',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'family-cover',
      type: 'list',
      readonly: false,
      label: 'Family Cover',
      uid: '3dc47e0ad3d81993',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '761960c148272fb4',
          content: '<h2 id="additional-cover">Additional Cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '4bcd970ba2471072',
          content:
            '<p>You have the option to add additional cover to your policy. Please note that this will increase your premium accordingly.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output Html',
          uid: 'c31a91feab2846c8',
          content:
            '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#additional">Additional</a></li>\n<li class="clickable"><a href="#details">Details</a></li>\n<li class="clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
        },
        {
          id: 'your-family',
          type: 'list',
          readonly: true,
          label: 'Your Family',
          uid: 'eaa290da3068b910',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '4f73c363d5f77471',
              content:
                '<p>Do you want to cover any of your family members under this benefit?</p>\n',
            },
            {
              id: 'lives-added-basic',
              type: 'table',
              readonly: true,
              label: 'Lives Added Basic',
              uid: 'a0f6644a584eb6c3',
              content: [],
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '44612adcb73f4f97',
              content: '<p><a href="#add-family">Add Member</a></p>\n',
            },
          ],
        },
        {
          id: 'cash-bonus-list',
          type: 'list',
          readonly: false,
          label: 'Cash Bonus',
          uid: '06d94c5d5f4ea9e2',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'a235c34cefff34a6',
              content:
                '<p>Would you like to add a deposit to your cover?</p>\n',
            },
            {
              id: 'cash-bonus',
              type: 'amount',
              elementType: 'input',
              readonly: false,
              label: 'Cash Bonus',
              uid: '8e3f60a816d0375e',
              content: '',
              publishChange: true,
              inputType: 'number',
            },
          ],
        },
        {
          id: 'inflation-protector',
          type: 'list',
          readonly: false,
          label: 'Inflation Protector',
          uid: 'fea13f42810cffb6',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'd59ec5df260665b6',
              content:
                '<p>You have the opportunity to add inflation protection.</p>\n',
            },
            {
              id: 'inflation-protector',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Inflation Protector',
              uid: 'd6b5f1d2a287986d',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Inflation Protector',
              options: ['', '0', '5', '10', '15', '20', '25', '30'],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'e9f1f8d1572ca789',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'af54b03dc6502386',
              content: [
                {
                  id: 'product',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Product',
                  uid: '1fe13135b15500cb',
                  content: 'Family Funeral Plan Unlimited',
                  inputType: 'text',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'ac8eab068b415704',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'a0c2ac103202215d',
                  content: '',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Additional Benefits',
    uri: 'ffp-unlimited/quotation-family',
    template: 'ffp-unlimited-family-quote-screen',
    class: 'ffp-unlimited-additional-benefits',
  },
  'add-family-members': {
    modal: true,
    text: [],
    buttons: [],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: 'ecd683030d7458a9',
      content: [
        'Please specify your relationship to the life you want to add.',
      ],
    },
    class: 'ffp-unlimited-additional-benefits',
    title: 'Public Access \n Welcome to Elac',
  },
  'family-members-age-and-gender': {
    modal: true,
    text: ['Please provide the age and gender of your spouse'],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'additional-family-details',
      type: 'list',
      readonly: false,
      label: 'Additional Family Details',
      uid: '4c87084fb0a64199',
      content: [
        {
          id: 'system-field-outcome',
          type: 'html',
          readonly: true,
          label: '',
          uid: '9a07e60b5bba99b8',
          content: '<p></p>\n',
        },
        {
          id: 'age-of-member',
          type: 'integer',
          elementType: 'select',
          readonly: false,
          label: 'Age Of Member',
          tooltip: 'Age Of Member',
          uid: '31635e1db55cb34c',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: [],
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Gender',
          tooltip: 'Gender',
          uid: 'defa6021f5cbe751',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: ['', 'Female', 'Male'],
        },
      ],
    },
    class: 'ffp-unlimited-additional-benefits',
    title: 'Public Access \n Welcome to Elac',
  },
  'family-members-quotation-details': {
    modal: true,
    text: ['Select the cover for your family'],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'cover-for-live-assured',
      type: 'list',
      readonly: false,
      label: 'Cover For Life Assured',
      uid: '13be8bacd481c6f5',
      content: [
        {
          id: 'cover-benefit',
          type: 'amount',
          elementType: 'select',
          readonly: false,
          label: 'Cover Benefit',
          tooltip: 'Cover Benefit',
          uid: '18e1120a1fcea60c',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: [
            '',
            '2000',
            '5000',
            '7500',
            '10000',
            '15000',
            '20000',
            '25000',
            '30000',
            '40000',
            '50000',
          ],
        },
        {
          id: 'memorial-cover-options',
          type: 'list',
          readonly: false,
          label: 'Memorial Cover Options',
          uid: '461487a2f421c8ec',
          content: [
            {
              id: '7-day-celebration-cover',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: '7 day Celebration Cover',
              uid: '61abf74446f21c88',
              content: '',
              publishChange: true,
              tooltip: '7 day Celebration Cover',
              options: ['Y', 'N'],
            },
            {
              id: '40-day-celebration-cover',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: '40 day Celebration Cover',
              uid: 'e0a825c092890530',
              content: '',
              publishChange: true,
              tooltip: '40 day Celebration Cover',
              options: ['Y', 'N'],
            },
            {
              id: 'memorial-cover',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Memorial One Year',
              uid: '236b635e77358f24',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
          ],
        },
      ],
    },
    class: 'ffp-unlimited-additional-benefits',
    title: 'Public Access \n Welcome to Elac',
  },
  'remove-family-members': {
    modal: true,
    text: [],
    buttons: [
      {
        label: '',
        id: '',
        uid: 'bfb0a1ef332e5a00',
        control: false,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '4981e8376bfbfbd8',
      content: [
        'Please select the person you would like to remove from the policy',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'edit-family-members': {
    modal: true,
    text: [],
    buttons: [],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '4981e8376bfbfbd8',
      content: [
        'Please select the person whose details you would like to edit',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'personal-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'personal-details',
      type: 'list',
      readonly: false,
      label: 'Personal Details',
      uid: '2d879a4c2a5336a9',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'c9d124707f9d1b14',
          content: '<h2 id="personal-details">Personal Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a8902e1cd0cee07e',
          content:
            '<p>For us to complete the purchase, we require the following information</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output Html',
          uid: 'da5a292adf7f9381',
          content:
            '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#additional">Additional</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
        },
        {
          id: 'your-details',
          type: 'list',
          readonly: false,
          label: 'Your Details',
          uid: 'aba375584debd622',
          content: [
            {
              id: 'first-names',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'First Names',
              uid: 'b0cb5e2013562d51',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'surname',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Surname',
              uid: '464b64f4d28fd6b9',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'idpassport-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'ID/Passport Number',
              uid: '98e2671fcd68feeb',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'date-of-birth',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Date Of Birth',
              uid: 'cf5112787e541dd8',
              content: '',
              inputType: 'date',
              maxYear: thisYear,
            },
            {
              id: 'title',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Title',
              uid: 'df961919bc248ac4',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Title',
              options: [
                '',
                'Adv',
                'Dr',
                'Miss',
                'Mr',
                'Mrs',
                'Ms',
                'Prof',
                'Rev',
              ],
            },
            {
              id: 'marital-status',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Marital Status',
              uid: 'b7787bd9a19e2138',
              content: '',
              publishChange: true,
              tooltip: 'Marital Status',
              options: [
                '',
                'Divorced',
                'Married',
                'Single',
                'Widow',
                'Widower',
              ],
            },
            {
              id: 'ghanaian',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Ghanaian',
              uid: 'baf950dbec76f0ef',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Ghanaian',
              options: ['Y', 'N'],
            },
            {
              id: 'other-nationality',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Other Nationality',
              uid: '3e4c1392a502afef',
              content: '',
              publishChange: true,
              tooltip: 'Other Nationality',
              options: [
                '',
                'Afghan',
                'Albanian',
                'Algerian',
                'American',
                'Andorran',
                'Angolan',
                'Argentine',
                'Armenian',
                'Aromanian',
                'Aruban',
                'Australian',
                'Austrian',
                'Azerbaijani',
                'Bahamian',
                'Bahraini',
                'Baltic German',
                'Baltic Russian',
                'Bangladeshi',
                'Barbadian',
                'Belarusian',
                'Belgian',
                'Belizean',
                'Bermudian',
                'Boer',
                'Bosniak',
                'Brazilian',
                'Breton',
                'British',
                'British Virgin Islander',
                'Bruneian',
                'Bulgarian',
                'Burkinabè',
                'Burundian',
                'Cambodian',
                'Cameroonian',
                'Canadian',
                'Cape Verdean',
                'Catalan',
                'Chadian',
                'Chilean',
                'Chinese',
                'Colombian',
                'Comorian',
                'Congolese',
                'Croatian',
                'Cuban',
                'Cypriot',
                'Czech',
                'Dane',
                'Dominican (Commonwealth)',
                'Dominican (Republic)',
                'Dutch',
                'East Timorese',
                'Ecuadorian',
                'Egyptian',
                'Emirati',
                'English',
                'Eritrean',
                'Estonian',
                'Ethiopian',
                'Falkland Islander',
                'Faroese',
                'Fijian',
                'Filipino',
                'Finn',
                'Finnish Swedish',
                'French Citizen',
                'Georgian',
                'German',
                'Ghanaian',
                'Gibraltar',
                'Greek',
                'Greek Macedonian',
                'Grenadian',
                'Guatemalan',
                'Guianese (French)',
                'Guinea Bissau National',
                'Guinean',
                'Guyanese',
                'Haitian',
                'Honduran',
                'Hong Kong',
                'Hungarian',
                'I Kiribati',
                'Icelander',
                'Indian',
                'Indonesian',
                'Iranian',
                'Iraqi',
                'Irish',
                'Israeli',
                'Italian',
                'Ivoirian',
                'Jamaican',
                'Japanese',
                'Jordanian',
                'Kazakh',
                'Kenyan',
                'Korean',
                'Kosovar',
                'Kurd',
                'Kuwaiti',
                'Kyrgyz',
                'Lao',
                'Latvian',
                'Lebanese',
                'Liberian',
                'Libyan',
                'Liechtensteiner',
                'Lithuanian',
                'Luxembourger',
                'Macedonian',
                'Macedonian Bulgarian',
                'Malagasy',
                'Malawian',
                'Malaysian',
                'Maldivian',
                'Malian',
                'Maltese',
                'Manx',
                'Mauritian',
                'Mexican',
                'Moldovan',
                'Mongolian',
                'Montenegrin',
                'Moroccan',
                'Namibian',
                'Nepalese',
                'New Zealander',
                'Nicaraguan',
                'Nigerian',
                'Nigerien',
                'Norwegian',
                'Other',
                'Pakistani',
                'Palauan',
                'Palestinian',
                'Panamanian',
                'Papua New Guinean',
                'Paraguayan',
                'Peruvian',
                'Pole',
                'Portuguese',
                'Puerto Rican',
                'Qatari',
                'Romanian',
                'Russian',
                'Rwandan',
                'Salvadoran',
                'Saudi',
                'Scot',
                'Senegalese',
                'Serb',
                'Sierra Leonean',
                'Sindhian',
                'Singaporean',
                'Slovak',
                'Slovene',
                'Somali',
                'Somalilander',
                'South African',
                'Spaniard',
                'Sri Lankan',
                'St Lucian',
                'Sudanese',
                'Surinamese',
                'Swede',
                'Swiss',
                'Syriac',
                'Syrian',
                'São Tomé And Príncipe',
                'Taiwanese',
                'Tajik',
                'Tanzanian',
                'Thai',
                'Tibetan',
                'Tobagonian',
                'Trinidadian',
                'Tunisian',
                'Turk',
                'Turkish Cypriot',
                'Tuvaluan',
                'Ugandan',
                'Ukrainian',
                'United States',
                'Uruguayan',
                'Uzbek',
                'Vanuatuan',
                'Venezuelan',
                'Vietnamese',
                'Welsh',
                'Yemeni',
                'Zambian',
                'Zimbabwean',
              ],
            },
            {
              id: 'gender',
              type: 'field',
              elementType: 'radio',
              readonly: false,
              label: 'Gender',
              uid: '72e95c05ec4cf91a',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Gender',
              options: ['Female', 'Male'],
            },
            {
              id: 'occupation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Occupation',
              uid: '757974e1f13f21c0',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Occupation',
              options: [
                '',
                'Abattoir Worker',
                'Accountant',
                'Accounting Officer',
                'Actor',
                'Actress',
                'Actuary',
                'Admin Assistant',
                'Administrator',
                'Advertising Agent',
                'Advocate',
                'Affal Washer',
                'Agriculture Assistant',
                'Agriculture Officer',
                'Air Force',
                'Air Force Ground Crew',
                'Aircraft Engineer',
                'Airline Employee',
                'Airline Executive',
                'Airline Pilot',
                'Ambulance Attendant',
                'Ambulance Driver',
                'Announcer',
                'Artisan',
                'Artist',
                'Assessor',
                'Auctioneer',
                'Auditor',
                'Author',
                'Auto Electrician',
                'Auxillary Worker',
                'Aviation',
                'Baker',
                'Bank Teller',
                'Bank-Manager',
                'Banker',
                'Bar Lady',
                'Bar Steward',
                'Barber',
                'Barmaid',
                'Barman',
                'Beautician',
                'Blacksmith',
                'Blockman',
                'Boilermaker',
                'Bookbinder',
                'Bookkeeper',
                'Bookmaker',
                'Boring Contractor',
                'Bottle Store Assistant',
                'Bottle Store Manager',
                'Boxer',
                'Bricklayer',
                'Broker',
                'Builder',
                'Building Contractor',
                'Building Foreman',
                'Building Inspector',
                'Bus Driver',
                'Butcher',
                'Buyer',
                'Cabinetmaker',
                'Cafe Owner',
                'Cameraman',
                'Cardiologist',
                'Caretaker',
                'Carpenter',
                'Carpet Layer',
                'Cashier',
                'Caterer',
                'Checker',
                'Chef',
                'Chief Officer',
                'Chiropodist',
                'Civil Defense Officer',
                'Cleaner',
                'Clergyman',
                'Clerk',
                'Cold Storage Worker',
                'Commercial Photographer',
                'Compositor-Electronic',
                'Compositor-Mechanical',
                'Computer Engineer',
                'Computer Operator',
                'Computer Programmer',
                'Computer Systems Analyst',
                'Computer Technician',
                'Conductor',
                'Constable',
                'Construction Worker',
                'Consultant',
                'Cook',
                'Copywriter',
                'Councillor',
                'Courier',
                'Cropduster',
                'Croupier',
                'Customs Official',
                'Dairy Worker',
                'Dancing Teacher',
                'Dean',
                'Demolition Worker',
                'Dental Assistant',
                'Dental Technician',
                'Dentist',
                'Detective',
                'Diamond Cutter',
                'Dietician',
                'Director',
                'Display Artist',
                'Diver',
                'Dockworker',
                'Doctor',
                'Domestic',
                'Draughtsman',
                'Dressmaker',
                'Driller',
                'Driver',
                'Dry Cleaner',
                'Economist',
                'Editor',
                'Education Inspector',
                'Educator',
                'Electrical Contractor',
                'Electrician',
                'Electronics Engineer',
                'Electroplater',
                'Employee',
                'Engineer',
                'Engineer-Qualified',
                'Engraver',
                'Entomologist',
                'Estate Agent',
                'Executive',
                'Explosives Worker',
                'Exterminator',
                'Farm Worker',
                'Farmer Foreman',
                'Farmer Manager',
                'Farmer Owner',
                'Fashion Designer',
                'Film Maker',
                'Fireman',
                'Fisherman',
                'Fitter And Turner',
                'Floor Surfacer',
                'Florist',
                'Foreman',
                'Forester',
                'Fortune Teller',
                'Foundry Manager',
                'Foundry Worker',
                'Freight Forwarder',
                'Fumigator',
                'Furnace Assistant',
                'Furnace Manager',
                'Furnace Supervisor',
                'Game Ranger',
                'Game Warden',
                'Garage Attendant',
                'Garage Owner',
                'Gardener',
                'General Assistant',
                'General Dealer',
                'General Worker',
                'Geologist',
                'Glassblower',
                'Glazier',
                'Goldsmith',
                'Grain Silo Operator',
                'Graphic Designer',
                'Greengrocer',
                'Groundsman',
                'Guard (Security)',
                'Gunsmith',
                'Hairdresser',
                'Hawker',
                'Head Of Department',
                'Headmaster',
                'Health Inspector',
                'Homeopath',
                'Horticulturist',
                'Hotel Manager',
                'Housewife',
                'Hr Assistant',
                'Inspector',
                'Insurance Agent',
                'Insurance Assessor',
                'Interior Decorator',
                'Interpreter',
                'Investigator',
                'Jeweller',
                'Jockey',
                'Joiner',
                'Journalist',
                'Labourer',
                'Lawyer',
                'Lecturer',
                'Librarian',
                'Lift Inspector',
                'Lift Operator',
                'Lift Technician',
                'Lighthouse Keeper',
                'Locksmith',
                'Machine Operator',
                'Machinist',
                'Magistrate',
                'Major',
                'Manager',
                'Manicurist',
                'Manufacturer',
                'Market Researcher',
                'Marketing Officer',
                'Martial Arts Instructor',
                'Mason',
                'Meat Inspector',
                'Mechanic',
                'Medical Representative',
                'Medical Technologist',
                'Medical-Practitioner',
                'Merchandiser',
                'Merchant Seaman',
                'Messenger',
                'Metallurgist',
                'Meter Reader',
                'Midwife',
                'Millwright',
                'Miner',
                'Minister Of Religion',
                'Model',
                'Motor Salesman',
                'Motor Spares Salesman',
                'Moulder',
                'Musician',
                'Naval Personnel',
                'Night Watchman',
                'Nurse',
                'Nurseryman',
                'Nursing Assistant',
                'Nursing Sister',
                'Occupational Therapist',
                'Officer',
                'Operator',
                'Ophthalmologist',
                'Optical Mechanic',
                'Orthopedic Orthopedist',
                'Packer',
                'Painter',
                'Painting Contractor',
                'Panelbeater',
                'Paramedic',
                'Pastor',
                'Pawnbroker',
                'Pensioner',
                'Permanent Way',
                'Personnel Officer',
                'Pest Exterminator',
                'Petrol Attendant',
                'Petroleum Refinery',
                'Pharmacist',
                'Photographer',
                'Physiotherapist',
                'Piano Tuner',
                'Pilot',
                'Plasterer',
                'Plumber',
                'Police Constable',
                'Police Woman',
                'Policeman',
                'Porter',
                'Postman',
                'Postmaster',
                'Principal',
                'Printer',
                'Prisons Personnel',
                'Private Investigator',
                'Producer',
                'Professor',
                'Programmer',
                'Property Appraiser',
                'Proprietor',
                'Public Prosecutor',
                'Public Relations Officer',
                'Quality Controller',
                'Quantity Surveyor',
                'Quarryman',
                'Racehorse Trainer',
                'Radiographer',
                'Radiologist',
                'Receptionist',
                'Religious Leader',
                'Repairman',
                'Representative',
                'Research Assistant',
                'Restaurant Owner',
                'Retired',
                'Rigger (Docks)',
                'Roadworker',
                'Sailor',
                'Sales Consultant',
                'Sales Lady',
                'Sales Support',
                'Sales Woman',
                'Salesman',
                'Seaman',
                'Seaman (Merchant)',
                'Seamstress',
                'Secretary',
                'Security Guard',
                'Self-Employed',
                'Sergeant',
                'Shedman',
                'Sheet Metal Worker',
                'Shipping Agent',
                'Shop Assistant',
                'Shop Manager',
                'Shopfitter',
                'Shunter-Foreman',
                'Signwriter',
                'Silversmith',
                'Singer',
                'Slaughterer',
                'Smelter',
                'Social Worker',
                'Soldier',
                'Sound Engineer',
                'Sportsman',
                'Sportswoman',
                'Spray Painter',
                'Sprayer',
                'Stationmaster',
                'Statistician',
                'Stevedore',
                'Steward',
                'Stock Broker',
                'Store Lady',
                'Storeman',
                'Student',
                'Supervisor',
                'Surgeon',
                'Sweeper',
                'Swimming Pool Attendant',
                'Switchboard Operator',
                'Systems Analyst',
                'Tailor',
                'Taxi Driver',
                'Teacher',
                'Technician',
                'Telegraphist',
                'Telephone Technician',
                'Telephonist',
                'Tiler',
                'Toolmaker',
                'Town Clerk',
                'Town Planner',
                'Trader',
                'Tradesman',
                'Traffic Officer',
                'Trainer',
                'Translator',
                'Travel Agent',
                'Typesetter',
                'Typist',
                'Undertaker',
                'Underwriter',
                'Unemployed',
                'Upholsterer',
                'Urologist',
                'Vetrinarian',
                'Vetrinary Surgeon',
                'Vulcaniser',
                'Waiter',
                'Waitress',
                'Warden',
                'Watch Maker',
                'Watchman',
                'Welder',
                'Well Sinker',
                'Window Cleaner',
                'Window Dresser',
                'Wood Cutter',
                'Worker',
                'Wrestler',
              ],
            },
            {
              id: 'id-type-radio-group',
              type: 'list',
              readonly: false,
              label: 'ID Type Radio Group',
              uid: '55144de6e5b38f62',
              content: [
                {
                  id: 'dvla',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Dvla',
                  uid: 'bc235e8781bbe982',
                  content: '',
                  publishChange: true,
                  tooltip: 'Dvla',
                  options: ['Y'],
                },
                {
                  id: 'passport',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Passport',
                  uid: 'e59e68b8ac36a00f',
                  content: '',
                  publishChange: true,
                  tooltip: 'Passport',
                  options: ['Y'],
                },
                {
                  id: 'voter-id',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Voter ID',
                  uid: '9215dbff34ced1f3',
                  content: '',
                  publishChange: true,
                  tooltip: 'Voter ID',
                  options: ['Y'],
                },
                {
                  id: 'nhis',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Nhis',
                  uid: 'c0bebf727748350e',
                  content: '',
                  publishChange: true,
                  tooltip: 'Nhis',
                  options: ['Y'],
                },
                {
                  id: 'ssnit',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Ssnit',
                  uid: 'f79d0cafc5c371cd',
                  content: '',
                  publishChange: true,
                  tooltip: 'Ssnit',
                  options: ['Y'],
                },
                {
                  id: 'nia-ghana-card',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Nia Ghana Card',
                  uid: '44966d1518c74a13',
                  content: '',
                  publishChange: true,
                  tooltip: 'Nia Ghana Card',
                  options: ['Y'],
                },
              ],
              markers: [],
              errors: ['*'],
            },
          ],
        },
        {
          id: 'your-contact-details',
          type: 'list',
          readonly: false,
          label: 'Your Contact Details',
          uid: 'd7d27f40d83251fd',
          content: [
            {
              id: 'cell-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Cell Phone',
              uid: '43bb2ffb6f6594b6',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'email',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Email',
              uid: 'ee92a0ea9dee039a',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'email',
            },
          ],
        },
        {
          id: 'your-postal-address',
          type: 'list',
          readonly: false,
          label: 'Your Postal Address',
          uid: 'c29a36de9d382eca',
          content: [
            {
              id: 'addressee',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Addressee',
              uid: 'e9a4ee96372e6a26',
              content: '',
              publishChange: true,
              inputType: 'text',
            },
            {
              id: 'po-box-or-private-bag',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'PO Box Or Private Bag',
              uid: '31bbec7a6c188206',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'region',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Region',
              uid: 'f9534e4ceabbdd80',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Region',
              options: [
                '',
                'Ahafo',
                'Ashanti',
                'Bono East',
                'Brong Ahafo',
                'Central',
                'Eastern',
                'Greater Accra',
                'North East',
                'Northern',
                'Oti',
                'Savannah',
                'Upper East',
                'Upper West',
                'Volta',
                'Western',
                'Western North',
              ],
            },
            {
              id: 'town',
              type: 'field',
              elementType: 'datalist',
              readonly: false,
              label: 'Town',
              uid: 'f65c4a97d4bee5a0',
              content: '',
              publishChange: true,
              options: loadOptions('f65c4a97d4bee5a0'),
              partialOptions: true,
              autoComplete: 'options',
              errors: ['*'],
            },
          ],
        },
        {
          id: 'your-residential-address',
          type: 'list',
          readonly: false,
          label: 'Your Residential Address',
          uid: '779d604deb520f6d',
          content: [
            {
              id: 'contracting-party-residential-address',
              type: 'list',
              readonly: false,
              label: 'Contracting Party Residential Address',
              uid: 'cd1e17079bd9f591',
              content: [
                {
                  id: 'street',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Street',
                  uid: '826fe83fd1ca4522',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'suburb',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Suburb',
                  uid: 'e9a716ed65d1a7cb',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'your-digital-address',
          type: 'list',
          readonly: false,
          label: 'Your Digital Address',
          uid: '9a4ea7606aee94f6',
          content: [
            {
              id: 'contracting-party-digital-address',
              type: 'list',
              readonly: false,
              label: 'Contracting Party Digital Address',
              uid: 'ad1b8aa131344b34',
              content: [
                {
                  id: 'digital-address',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Digital Address',
                  uid: '861c37edd222d849',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '65d887e32c964c27',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '8521031a16270ee4',
              content: [
                {
                  id: 'product',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Product',
                  uid: 'ed3fbff5d2534aae',
                  content: 'Family Funeral Plan Unlimited',
                  inputType: 'text',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '58719255037788a9',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'd302a75bb5ba94db',
                  content: '',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Personal Details',
    uri: 'ffp-unlimited/personal-details',
    class: 'ffp-unlimited-personal-details',
  },
  'trustee-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'trustee-details',
      type: 'list',
      readonly: false,
      label: 'Trustee Details',
      uid: 'e31072f30342f8ee',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '716305c544aa14a7',
          content: '<h2 id="trustee-details">Trustee Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53952a550bf734cc',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output Html',
          uid: '5b2ce4d53ab64bc2',
          content:
            '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#additional">Additional</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'd37b809ecb78a979',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: 'c89c29373f3e3c1b',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '5723ca9ce0da97c1',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'date',
          component: 'DateSelector',
          maxYear: thisYear,
        },
        {
          id: 'title',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Title',
          uid: '5e62b5dd60e864f7',
          content: '',
          publishChange: true,
          tooltip: 'Title',
          options: ['', 'Adv', 'Dr', 'Miss', 'Mr', 'Mrs', 'Ms', 'Prof', 'Rev'],
        },
        {
          id: 'cell-phone',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Cell Phone',
          uid: '7d6ed2ce591e1ce6',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'tel',
          component: 'PhoneNumber',
        },
        {
          id: 'relationship',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Relationship',
          uid: '87473b2d56cc829e',
          content: '',
          publishChange: true,
          errors: ['*'],
          tooltip: 'Relationship',
          options: [
            '',
            'Brother',
            'Daughter',
            'Estate',
            'Father',
            'Father In Law',
            'Grandfather',
            'Grandmother',
            'Mother',
            'Mother In Law',
            'Sister',
            'Son',
            'Spouse',
            'Testament',
            'Trust',
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '7253c3f4fbf2c2d9',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '368b2dac9c4f97bf',
              content: [
                {
                  id: 'product',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Product',
                  uid: '3667c0d6902b8565',
                  content: 'Family Funeral Plan Unlimited',
                  inputType: 'text',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '19759474a887a51d',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'c915d127733ac59f',
                  content: '',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Trustee Details',
    uri: 'ffp-unlimited/trustee-details',
    template: 'ffp-unlimited-family-quote-screen',
    class: 'ffp-unlimited-trustee-details',
  },
  'additional-lives': {
    text: [],
    buttons: [
      { label: 'Next', id: 'next', uid: '26ca340069ec84e5', control: true },
      { label: 'Back', id: 'back', uid: '1303e2b2da021d46', control: true },
      { label: 'Cancel', id: 'cancel', uid: 'e25c3188a733209e', control: true },
    ],
    item: {
      id: 'additional-lives',
      type: 'list',
      readonly: false,
      label: 'Additional Lives',
      uid: '8ecfd29866544493',
      content: [],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Lives Assured',
    uri: 'ffp-unlimited/lives-assured',
    class: 'ffp-unlimited-lives-assured',
  },
  'payment-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      content: [
        {
          content: '<h2 id="payment-details">Payment Details</h2>\n',
          id: 'system-constant',
          label: '',
          readonly: true,
          type: 'html',
          uid: '53606e9c53fc5262',
        },
        {
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
          id: 'system-constant',
          label: '',
          readonly: true,
          type: 'html',
          uid: '493a034ea85c537a',
        },
        {
          content:
            '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#additional">Additional</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="done clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
          id: 'output-html',
          label: 'Output Html',
          readonly: true,
          type: 'html',
          uid: '3ca8c37eef2ad68a',
        },
        {
          content: [
            {
              content: '<p>Choose a payment method</p>\n',
              id: 'system-constant',
              label: '',
              readonly: true,
              type: 'html',
              uid: 'b3820d390c57590c',
            },
            {
              content: [
                {
                  content: '',
                  elementType: 'checkbox',
                  id: 'debit-order',
                  label: 'Debit Order',
                  publishChange: true,
                  readonly: false,
                  tooltip: 'Debit Order',
                  type: 'field',
                  uid: '66026bce0175bd85',
                },
                {
                  content: '',
                  elementType: 'checkbox',
                  id: 'stop-order',
                  label: 'Stop Order',
                  publishChange: true,
                  readonly: false,
                  tooltip: 'Stop Order',
                  type: 'field',
                  uid: 'addc4bc1f4c832eb',
                },
              ],
              id: 'payment-method-radio-group',
              label: 'Payment Method Radio Group',
              readonly: false,
              type: 'list',
              uid: '5f29ef713684dcdd',
            },
          ],
          id: 'payment-method',
          label: 'Payment Method',
          readonly: false,
          type: 'list',
          uid: '935b4319fdcbab12',
        },
        {
          content: [
            {
              content:
                '<p>Choose the payment start date and your payment frequency</p>\n',
              id: 'system-constant',
              label: '',
              readonly: true,
              type: 'html',
              uid: '1b71c7807dbb0c27',
            },
            {
              content: '',
              elementType: 'select',
              id: 'payment-start-date',
              label: 'Payment Start Date',
              options: [],
              publishChange: true,
              readonly: false,
              tooltip: 'Payment Start Date',
              type: 'integer',
              uid: 'cd4537bfa3fb5f7d',
            },
            {
              content: [
                {
                  content: '',
                  elementType: 'checkbox',
                  errors: ['*'],
                  id: 'monthly',
                  label: 'Monthly',
                  options: ['Y'],
                  publishChange: true,
                  readonly: false,
                  tooltip: 'Monthly',
                  type: 'field',
                  uid: '3f6c19ccd48cbeeb',
                },
                {
                  content: '',
                  elementType: 'checkbox',
                  id: 'quarterly',
                  label: 'Quarterly',
                  options: ['Y'],
                  publishChange: true,
                  readonly: false,
                  tooltip: 'Quarterly',
                  type: 'field',
                  uid: '3085ba131761cdaa',
                },
                {
                  content: '',
                  elementType: 'checkbox',
                  id: 'annually',
                  label: 'Annually',
                  options: ['Y'],
                  publishChange: true,
                  readonly: false,
                  tooltip: 'Annually',
                  type: 'field',
                  uid: '9d98a9533a9281e4',
                },
                {
                  content: '',
                  elementType: 'checkbox',
                  id: 'bi-annually',
                  label: 'Bi Annually',
                  options: ['Y'],
                  publishChange: true,
                  readonly: false,
                  tooltip: 'Bi Annually',
                  type: 'field',
                  uid: '58f1bdb69bf88bd3',
                },
              ],
              id: 'payment-frequency-radio-group',
              label: 'Payment Frequency Radio Group',
              readonly: false,
              type: 'list',
              uid: 'da49d3d1b25a1db9',
            },
          ],
          id: 'payment-commencement',
          label: 'Payment Commencement',
          readonly: false,
          type: 'list',
          uid: 'a15f2bb4ec8ed8b1',
        },
        {
          id: 'payment-type-details',
          label: '',
          readonly: false,
          type: 'list',
          uid: '8dcbe97c1d6c8492',
          content: [],
        },
        {
          content: [
            {
              component: 'InteractiveCanvas',
              content:
                '<p><h2>Sign in the area below</h2></p>\n<p><p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p></p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
              id: 'interactive-canvas-html',
              label: 'Interactive Canvas Html',
              markers: [],
              readonly: false,
              type: 'component',
              uid: 'b25a984636d42a56',
            },
          ],
          id: 'signature',
          label: 'Signature',
          readonly: false,
          type: 'list',
          uid: '1848a6126f8f1403',
        },
        {
          content: [
            {
              content: [
                {
                  content: 'Family Funeral Plan Unlimited',
                  elementType: 'input',
                  id: 'product',
                  inputType: 'text',
                  label: 'Product',
                  readonly: true,
                  type: 'field',
                  uid: '2bff807f9041d7a4',
                },
                {
                  content: '',
                  elementType: 'input',
                  id: 'benefit-rate',
                  inputType: 'number',
                  label: 'Benefit Rate',
                  readonly: true,
                  type: 'amount',
                  uid: 'b25aa5e49e79acdf',
                },
                {
                  content: '',
                  elementType: 'input',
                  id: 'premium',
                  inputType: 'number',
                  label: 'Premium',
                  readonly: true,
                  type: 'amount',
                  uid: '8588b418b3adff34',
                },
              ],
              id: 'quotation-information',
              label: 'Quotation Information',
              readonly: true,
              type: 'list',
              uid: '91650795b4ec8cfb',
            },
          ],
          id: 'quotation-panel',
          label: 'Quotation Panel',
          readonly: true,
          type: 'list',
          uid: '8568ca55c1d21339',
        },
      ],
      id: 'payment-details',
      label: 'Payment Details',
      readonly: false,
      type: 'list',
      uid: '428c3bf307d7d944',
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Payment Details',
    uri: 'ffp-unlimited/payment-details',
    class: 'ffp-unlimited-payment-details',
  },
  'upload-documents': {
    text: [],
    buttons: [
      {
        label: 'Upload ID document',
        id: 'upload-id-document',
        uid: '69a53b6758f4ff80',
        control: false,
      },
      {
        label: 'Upload Passport Document',
        id: 'upload-passport-document',
        uid: '52f9c5a583133af8',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'upload-documents',
      type: 'list',
      readonly: true,
      label: 'Upload Documents',
      uid: '4243c40a669db124',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '2dd71fd55a00cef9',
          content: '<h2 id="upload-documents">Upload Documents</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'ec04f637625f8246',
          content: '<p>Please upload the necessary documentation</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output Html',
          uid: '6c8aa633d46ba070',
          content:
            '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#additional">Additional</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="done clickable"><a href="#payment">Payment</a></li>\n<li class="done clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
        },
        {
          content: [
            {
              content:
                '<p><a href="#upload-id-document" class="">ID Document <span>Re Upload</span></a></p>\n',
              id: 'system-field-outcome',
              label: '',
              readonly: true,
              type: 'html',
              uid: '7721dd56ec3e43de',
            },
            {
              content:
                '<p><a href="#upload-passport-document" class="">Passport Document <span>Re Upload</span></a></p>\n',
              id: 'system-field-outcome',
              label: '',
              readonly: true,
              type: 'html',
              uid: '276a211edc9e33a4',
            },
          ],
          id: 'documents-list',
          label: 'Documents List',
          readonly: true,
          type: 'list',
          uid: '8e9b6f7db40b876c',
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'f4bbe2222a1cd316',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '47a4aff7f98fabb2',
              content: [
                {
                  id: 'product',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Product',
                  uid: '22c0eb197048ad30',
                  content: 'Family Funeral Plan Unlimited',
                  inputType: 'text',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '1fdec6607ee9f967',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'e8d4b11a9bdd5075',
                  content: '',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Upload Documents',
    uri: 'ffp-unlimited/upload-documents',
    template: 'ffp-unlimited-upload-documents-screen',
    class: 'ffp-unlimited-upload-documents',
  },
  'upload-document-pages': {
    modal: true,
    buttons: [
      {
        control: false,
        id: 'add-page',
        label: 'Add Page',
        uid: '1a5450d98db152d6',
      },
      {
        control: true,
        id: 'abort',
        label: 'Abort',
        uid: 'e11279c7f15a77ea',
      },
      {
        control: true,
        id: 'continue',
        label: 'Continue',
        uid: '0d54090d869303f6',
      },
    ],
    item: {
      content: [
        {
          content:
            '<p>Please upload your document below. If it consists of multiple pages, please upload all of them together.</p>\n',
          id: 'system-field-outcome',
          label: '',
          readonly: true,
          type: 'html',
          uid: '8872705861783d2d',
        },
        {
          content: [],
          id: 'uploads',
          label: 'Uploads',
          readonly: false,
          type: 'table',
          uid: '2d999fbaaefb947c',
        },
        {
          content:
            '<p><a class="button add-page-button" href="#add-page">Add Page</a></p>\n',
          id: 'system-constant',
          label: '',
          readonly: true,
          type: 'html',
          uid: 'a47ce0e3f28e09c5',
        },
      ],
      id: 'upload-section',
      label: 'Upload Section',
      readonly: false,
      type: 'list',
      uid: '74b77d523e9f9b6d',
    },
    class: 'upload-layout',
    context: 'Upload Layout',
    template: '',
    text: [],
    title: 'Public Access \n Welcome to Elac',
    uri: 'upload-document/file/new',
  },
  'acceptance-screen': {
    text: [],
    buttons: [
      {
        label: 'Submit',
        id: 'submit',
        uid: 'ef8243ce592928b2',
        control: false,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'acceptance',
      type: 'list',
      readonly: false,
      label: 'Acceptance',
      uid: '4b633acc802037cf',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '981d430846482fe8',
          content: '<h2 id="summary">Summary</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '8b470d282f194b34',
          content: '<p>Please review the information below</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output Html',
          uid: '41c1a03e9a972134',
          content:
            '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#additional">Additional</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="done clickable"><a href="#payment">Payment</a></li>\n<li class="done clickable"><a href="#uploads">Uploads</a></li>\n<li class="done clickable"><a href="#summary">Summary</a></li>\n</ol>',
        },
        {
          id: 'summary',
          label: 'Summary',
          readonly: true,
          type: 'list',
          uid: 'be97473d97db5cf9',
          content: [
            {
              id: 'contract-id',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Application Reference Number',
              uid: '2ef58e6238bc72dd',
              content: '',
              inputType: 'text',
            },
            {
              id: 'life-assured-identification',
              type: 'list',
              readonly: true,
              label: 'Contracting Party Identification',
              uid: 'eb22654d010de316',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: '287f570f36b10e2d',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: '3b05fa8939081671',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: 'd2d8de1b1c90253f',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: '8728a9af5b4d3b15',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'marital-status',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Marital Status',
                  uid: '6e893aff3d29a1ba',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'gender',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Gender',
                  uid: 'b943e0950ff73c20',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'ghanaian',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Ghanaian',
                  uid: 'c9c5ceeb3a7d8306',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'other-nationality',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Other Nationality',
                  uid: '2f424b4b0d898ac1',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'idpassport-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'ID Number',
                  uid: '5adb7298c37ea412',
                  content: '',
                  inputType: 'number',
                },
              ],
            },
            {
              id: 'life-assured-contact-details',
              type: 'list',
              readonly: true,
              label: 'Contracting Party Contact Details',
              uid: '34d474293817b6c9',
              content: [
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '116c326a9eed17c8',
                  content: '',
                  inputType: 'tel',
                },
                {
                  id: 'email',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Email',
                  uid: '7cf1f4f57a879033',
                  content: '',
                  inputType: 'email',
                },
              ],
            },
            {
              id: 'life-assured-postal-address',
              type: 'list',
              readonly: true,
              label: 'Contracting Party Postal Address',
              uid: 'e293e53b0d305c28',
              content: [
                {
                  id: 'addressee',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Addressee',
                  uid: '81b72ce3a3f365f2',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'po-box-or-private-bag',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'PO Box Or Private Bag',
                  uid: '0a52a0160f86178a',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'region',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Region',
                  uid: 'd3f8f04b4fca8954',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'town',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Town',
                  uid: '9f2c10d2de5de530',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'trustee-identification',
              type: 'list',
              readonly: true,
              label: 'Trustee Identification',
              uid: '2e8a83e9fbc4f34d',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: 'b38bffa85e13a881',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: '08fe6d272fa609e7',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '4cfa6610a6b7954f',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: '6c0be6ce90175ae1',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '5c95268c2f6e39e7',
                  content: '',
                  inputType: 'tel',
                },
                {
                  id: 'relationship',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Relationship',
                  uid: '33a5306be231c7b1',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'payment-details',
              type: 'list',
              readonly: true,
              label: 'Payment Details',
              uid: 'd2994131363640e0',
              content: [],
            },
          ],
        },
        {
          id: 'interactive-canvas-html',
          type: 'component',
          component: 'InteractiveCanvas',
          readonly: false,
          label: 'Interactive Canvas Html',
          uid: '1fcad0e41194c3d1',
          content:
            '<p><h2>Sign in the area below</h2></p>\n<p><p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p></p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '7ecc18acf4d597c2',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'dd079d192b9eb85a',
              content: [
                {
                  id: 'product',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Product',
                  uid: '024c4057371b29e3',
                  content: 'Family Funeral Plan Unlimited',
                  inputType: 'text',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '28a6cd68ea42c9c9',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '48f061e288e60d8b',
                  content: '',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Acceptance Screen',
    uri: 'ffp-unlimited/acceptance',
    template: 'ffp-unlimited-acceptance-screen',
    class: 'ffp-unlimited-acceptance-screen',
  },
  completed: {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Continue',
        id: 'continue',
        uid: 'fb11ce7f64c71481',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '95c15702a2e492f8',
      content: ['Application completed successfully.'],
    },
    context: '',
    title: 'Public Access \n Welcome to Elac',
  },
}

export const familyMemberTablePrefix = [
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: '0bc795d3e0cb7f34',
    content:
      '<h2 id="additional-lives-details">Additional Lives Details</h2>\n',
  },
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: 'cdcd7a4b761e07b0',
    content: '',
  },
  {
    id: 'output-html',
    type: 'html',
    readonly: true,
    label: 'Output Html',
    uid: '61f48521f562ddce',
    content:
      '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#additional">Additional</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
  },
]

export const familyMemberItem = (f, counter) => ({
  id: `life-${counter}`,
  type: 'list',
  readonly: false,
  label: f.relationship,
  uid: '8ecfd29866544493',
  content: [
    {
      id: 'system-field-outcome',
      type: 'html',
      readonly: true,
      label: '',
      uid: '1a3c568e4f5afeda',
      content: `<p>Please provide the following information for your <strong>${f.relationship}</strong> aged <strong>${f['age-of-member']}</strong>.</p>\n`,
    },
    {
      id: `first-names-${counter}`,
      type: 'field',
      elementType: 'input',
      readonly: false,
      label: 'First Names',
      uid: 'c25583134b856e84',
      content: f['first-names'] || '',
      errors: f['first-names'] ? [] : ['*'],
      publishChange: true,
      inputType: 'text',
    },
    {
      id: `surname-${counter}`,
      type: 'field',
      elementType: 'input',
      readonly: false,
      label: 'Surname',
      uid: '92b7de7d6e94e8aa',
      content: f.surname || '',
      errors: f.surname ? [] : ['*'],
      publishChange: true,
      inputType: 'text',
    },
    {
      id: `date-of-birth-${counter}`,
      type: 'component',
      elementType: 'input',
      readonly: false,
      label: 'Date Of Birth',
      uid: '5ca6a755437569bb',
      content: f['date-of-birth'] || '',
      errors: f['date-of-birth'] ? [] : ['*'],
      publishChange: true,
      inputType: 'date',
      component: 'DateSelector',
      maxYear: dayjs().year(),
    },
    {
      id: `gender-${counter}`,
      type: 'field',
      elementType: 'input',
      readonly: true,
      label: 'Gender',
      uid: '7d759fa831bbbccb',
      content: f.gender || '',
      inputType: 'text',
      options: ['', 'Female', 'Male'],
    },
    {
      id: 'quotation-panel',
      type: 'list',
      readonly: true,
      label: 'Quotation Panel',
      uid: '1f874a324af01a0a',
      content: [
        {
          id: 'quotation-information',
          type: 'list',
          readonly: true,
          label: 'Quotation Information',
          uid: '4f5a478187ad4b9d',
          content: [
            {
              id: 'product',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Product',
              uid: 'f12ba8a10a52a4dd',
              content: 'Family Funeral Plan Unlimited',
              inputType: 'text',
            },
            {
              id: 'benefit-rate',
              type: 'amount',
              elementType: 'input',
              readonly: true,
              label: 'Benefit Rate',
              uid: 'deeffb58b9038029',
              content: '',
              inputType: 'number',
            },
            {
              id: 'premium',
              type: 'amount',
              elementType: 'input',
              readonly: true,
              label: 'Premium',
              uid: 'b9f996d5dc386fec',
              content: '',
              inputType: 'number',
            },
          ],
        },
      ],
    },
  ],
})

let ageRange = () => {
  const cached = {}
  return (start, end) => {
    if (start > end) {
      start = end
    }
    const k = `[${start}-${end}]`
    if (k in cached) {
      return cached[k]
    }
    const range = ['', `${start}`]
    for (let val = start + 1; val <= end; val++) {
      range.push(`${val}`)
    }
    cached[k] = range
    return range
  }
}
ageRange = ageRange()

// Family member menu
export const familyMemberOptions = (age) => ({
  Spouse: {
    options: ageRange(18, 100),
  },
  Child: {
    options: ageRange(0, 21),
  },
  //AGE DIFFERENCE BETWEEN LIFE ASSURED AND PARENTS = MINIMUM OF 13 YEARS
  'Parent or In-Laws': {
    Mother: {
      options: ageRange(age + 13, 100),
    },
    'Mother in law': {
      options: ageRange(age + 13, 100),
    },
    Father: {
      options: ageRange(age + 13, 100),
    },
    'Father in law': {
      options: ageRange(age + 13, 100),
    },
  },
  //AGE DIFFERENCE BETWEEN LIFE ASSURED AND GRAND PARENTS = 26 YEARS
  Grandparents: {
    Grandmother: {
      options: ageRange(age + 26, 100),
    },
    Grandfather: {
      options: ageRange(age + 26, 100),
    },
  },
  'Step Parents': {
    'Step mother': {
      options: ageRange(0, 100),
    },
    'Step father': {
      options: ageRange(0, 100),
    },
  },
  Siblings: {
    Sister: {
      options: ageRange(0, 100),
    },
    Brother: {
      options: ageRange(0, 100),
    },
  },
  'Additional Spouse': {
    options: ageRange(18, 100),
  },
  //AGE DIFFERENCE BETWEEN LIFE ASSURED AND GAURDIAN = 16 YEARS
  Guardian: {
    options: ageRange(age + 16, 100),
  },
  Other: {
    options: ageRange(0, 100),
    rename: 'Wider',
  },
})
export const familyMemberLabels = Object.keys(familyMemberOptions(0))

export const createFamilyMember = (
  relationship,
  ageofmember,
  gender,
  coverbenefit,
  memorialsevenday,
  memorialfortyday,
  memorialoneyear
) => ({
  relationship,
  'age-of-member': ageofmember,
  gender,
  'cover-benefit': coverbenefit,
  '7-day-celebration-cover': memorialsevenday,
  '40-day-celebration-cover': memorialfortyday,
  'memorial-cover': memorialoneyear,
  'first-names': null,
  surname: null,
  'date-of-birth': null,
})

export const isMultipleOccuranceRelationship = (relationship) =>
  ['Spouse'].indexOf(relationship) < 0

export const multipleOccurancesExist = (life, lives) =>
  isMultipleOccuranceRelationship(life.relationship) &&
  lives.reduce(
    (c, l) => (l.relationship === life.relationship ? c + 1 : c),
    0
  ) > 1

const findOccurance = (life, position, lives) => {
  let occuranceString = ''
  if (multipleOccurancesExist(life, lives)) {
    let occurance = 1
    for (let i = position - 1; i >= 0; i--) {
      if (lives[i].relationship === life.relationship) {
        occurance += 1
      }
    }
    occuranceString = ` ${occurance}`
  }
  return occuranceString
}

export const additionalLife = (life, position, lives) => {
  const lifeRelationship = `${life.relationship}${findOccurance(
    life,
    position,
    lives
  )}`
  return [
    createItem('Relationship', {
      content: lifeRelationship,
      elementType: 'input',
      inputType: 'text',
      readonly: true,
      type: 'field',
    }),
    createItem('Age', {
      content: life['age-of-member'],
      elementType: 'input',
      inputType: 'number',
      readonly: true,
      type: 'integer',
    }),
    createItem('system-field-outcome', {
      content: `<p><a class="remove" href="#remove-family#${slugify(
        lifeRelationship
      )}">Remove</a><a class="edit" href="#edit-family#${slugify(
        lifeRelationship
      )}">Edit</a></p>\n`,
      label: '',
      readonly: true,
      type: 'html',
    }),
  ]
}

export const buttonFromLifeAssured = (life, position, lives) =>
  createButton(`${life.relationship}${findOccurance(life, position, lives)}`)

export const idFromLifeAssured = (life, position, lives) =>
  slugify(`${life.relationship}${findOccurance(life, position, lives)}`)

export const paymentDetailsSummary = (form) => {
  if (form['mobile-wallet'] === 'Y') {
    return {
      id: 'mobile-wallet-details',
      type: 'list',
      readonly: true,
      label: 'Mobile Wallet Details',
      content: [
        {
          id: 'mobile-wallet-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Mobile Wallet Number',
          uid: '96d79859b70f1d61',
          content: '',
          inputType: 'text',
        },
        {
          id: 'mobile-wallet-network-provider',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Mobile Wallet Network Provider',
          uid: 'e15ab36d3f397c2e',
          content: '',
          inputType: 'text',
        },
      ],
    }
  } else if (form['debit-order'] === 'Y') {
    return {
      id: 'payment-bank-details',
      type: 'list',
      readonly: true,
      label: 'Payment Bank Details',
      content: [
        {
          id: 'account-holder-name',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Holder Name',
          uid: 'c48fe6bcd83a0b4a',
          content: '',
          inputType: 'text',
        },
        {
          id: 'account-entity-name',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Entity Name',
          uid: '887f2c3da9067907',
          content: '',
          inputType: 'text',
        },
        {
          id: 'account-type',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Type',
          uid: '8de41e5b5f22dfaa',
          content: '',
          inputType: 'text',
        },
        {
          id: 'account-branch-name',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Branch Name',
          uid: 'faebf7bb7f978ec3',
          content: '',
          inputType: 'text',
        },
        {
          id: 'account-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Account Number',
          uid: '27269012310a5430',
          content: '',
          inputType: 'text',
        },
      ],
    }
  } else if (form['stop-order'] === 'Y') {
    return {
      id: 'payer-employment-details',
      type: 'list',
      readonly: true,
      label: 'Payer Employment Details',
      content: [
        {
          id: 'employer-organisation',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employer Organisation',
          uid: '781441cc7b190193',
          content: '',
          inputType: 'text',
        },
        {
          id: 'employee-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employee Number',
          uid: '27581bcc2706c489',
          content: '',
          inputType: 'text',
        },
      ],
    }
  } else {
    return {}
  }
}

export function paymentDetailsSection(form) {
  if (form['debit-order'] === 'Y') {
    return {
      id: 'debit-order-details',
      label: 'Debit Order Details',
      readonly: false,
      type: 'list',
      content: [
        {
          content: '<p>Complete the following details</p>\n',
          id: 'system-constant',
          label: '',
          readonly: true,
          type: 'html',
          uid: '82bc4d510e05f52c',
        },
        {
          content: [
            {
              content: '',
              elementType: 'input',
              errors: ['*'],
              id: 'account-holder-name',
              inputType: 'text',
              label: 'Account Holder Name',
              publishChange: true,
              readonly: false,
              type: 'field',
              uid: 'ab65844cd0d1e094',
            },
            {
              content: '',
              elementType: 'select',
              errors: ['*'],
              id: 'account-type',
              label: 'Account Type',
              options: ['', 'Current', 'Savings'],
              publishChange: true,
              readonly: false,
              tooltip: 'Account Type',
              type: 'field',
              uid: 'fbdc797b84a0f751',
            },
            {
              content: '',
              elementType: 'select',
              errors: ['*'],
              id: 'account-entity-name',
              label: 'Account Entity Name',
              options: [
                '',
                'Absa (gh) Ltd',
                'Access Bank Ghana Ltd',
                'Agricultural Development Bank',
                'Bank Of Africa',
                'Cal Bank Ltd',
                'Consolidated Bank Ghana',
                'Ecobank Ghana Ltd',
                'Fidelity Bank Ghana',
                'First Atlantic Bank Ltd',
                'First Bank Nigeria',
                'Gcb Bank Ltd',
                'Guaranty Trust (gh) Ltd',
                'National Investment Bank',
                'Omni Bank Ghana Ltd',
                'Prudential Bank Ltd',
                'Republic Bank (gh) Ltd',
                'Societe General Ghana Ltd',
                'Stanbic Bank Ghana Ltd',
                'Standard Chartered Bank',
                'United Bank For Africa (gh) Ltd',
                'Universal Merchant Bank (gh) Ltd',
                'Zenith Bank',
              ],
              publishChange: true,
              readonly: false,
              tooltip: 'Account Entity Name',
              type: 'field',
              uid: '41ac2dfe1beb4c8d',
            },
            {
              content: '',
              elementType: 'select',
              errors: ['*'],
              id: 'account-branch-name',
              label: 'Account Branch Name',
              options: [
                '',
                '1ST LIGHT',
                '31ST DECEMBER MARKET',
                'A & C MALL',
                'A & C SHOPPING MAIL',
                'A AND C',
                'A AND C MALL',
                'A COMMUNITY BANK LTD-LA',
                'A RURAL BANK LTD-AMASAMAN',
                'A&C MALL',
                'AA ASANTEWAA RURAL BANK LTD',
                'AARA RURAL BANK LTD-PAGA',
                'AASEMAN RURAL BANK LTD-KAASE',
                'ABBOSEY OKAI',
                'ABBOSEY OKAI BRANCH',
                'ABEKA',
                'ABEKA BRANCH',
                'ABEKA LAPAZ',
                'ABEKA LAPAZ BRANCH',
                'ABEKALAPAZ',
                'ABELENKPE',
                'ABETIFI',
                'ABETIFI BRANCH',
                'ABII NATIONAL SAVINGS AND LOANS LTD',
                'ABN-50129-TEMA COMMUNITY 9',
                'ABOABO',
                'ABOABO MARKET TAMALE',
                'ABOKOBI RURAL BANK LTD-ABOKOBI',
                'ABOR',
                'ABORA',
                'ABOSEY OKAI',
                'ABOSSEY OKAI',
                'ABOSSEY OKINE',
                'ABREPO JUNCTION',
                'ABREPO JUNCTION BRANCH',
                'ABREPO MAIN',
                'ABREPO MAIN BRANCH',
                'ABUAKWA',
                'ABUAKWA BRANCH',
                'ABURA DUNKWA',
                'ABURI',
                'ABZUGU RURAL BANK LTD',
                'ACCCRA NEWTOWN',
                'ACCION',
                'ACCRA',
                'ACCRA & MAKOLA',
                'ACCRA BRANCH',
                'ACCRA CENTRAL',
                'ACCRA CENTRAL POST',
                'ACCRA CENTRAL POSTBANK',
                'ACCRA CORPORATE SERVICE CENTRE',
                'ACCRA MAIN',
                'ACCRA MALL',
                'ACCRA NEW TOWN',
                'ACCRA SHOPPING MAIL',
                'ACCRA SHOPPING MALL',
                'ACHIASE',
                'ACHIMOTA',
                'ACHIMOTA BRANCH',
                'ACHIMOTA MILE 7',
                'ACTION CHAPEL',
                'ADA FOAH',
                'ADA RURAL BANK LTD-KASSEH',
                'ADABOKROM',
                'ADABRAKA',
                'ADABRAKA BRANCH',
                'ADANSI RURAL BANK LTD-FOMENA',
                'ADAWSO',
                'ADB HOUSE',
                'ADB HOUSE BRANCH',
                'ADEHYEMAN SAVINGS & LOAN',
                'ADEHYEMAN SAVINGS AND LOANS',
                'ADENTA',
                'ADENTA BRANCH',
                'ADENTA MARKET',
                'ADENTAN',
                'ADI HARBOUR',
                'ADIAKIO WAYA',
                'ADIDOME',
                'ADJABENG',
                'ADJIRINGANOR',
                'ADONTEN COMMUNITY BANK LTD-NEW TAFO',
                'ADUM',
                'ADUM BRANCH',
                'ADUM POST',
                'ADUMAN RURAL BANK LTD-BADU',
                'ADUM-KUMASI',
                'AFANA RURAL BANK LTD-SAMPA',
                'AFFUL NKWANTA',
                'AFFUL NKWANTA BRANCH',
                'AFLAO',
                'AFRAM RURAL BANK LTD-TEASE',
                'AFRANSIE',
                'AGAVE RURAL BANK LTD-DABALE',
                'AGBOBLOSHIE',
                'AGBOGBLOSHIE',
                'AGBOGBOLSHIE',
                'AGBOZUME',
                'AGOGO',
                'AGONA ABREM',
                'AGONA AHANTA',
                'AGONA ASHANTI',
                'AGONA NKWANTA',
                'AGONA RURAL BANK LTD-KWANYAKU',
                'AGONA SWEDRU',
                'AHAFO ANO PREMIER RURAL BANK LTD-WIOSO',
                'AHAFO COMMUNITY RURAL BANK LTD-KUKUOM',
                'AHANTAMAN RURAL BANK LTD-AGONA NKWANTA',
                'AHINSAN KUMASI',
                'AHODWO',
                'AHODWO BRANCH',
                'AIRPORT',
                'AIRPORT BRANCH',
                'AIRPORT CITY',
                'AIRPORT CITY BRANCH',
                'AIRPORT EAST',
                'AIYINASE',
                'AJUMAKO',
                'AKASTI',
                'AKATAKYIMAN RURAL BANK LTD-KOMENDA',
                'AKATSI',
                'AKIM ABENASE',
                'AKIM ADUASA',
                'AKIM BOSOME RURAL BANK LTD-AKIM SWEDRU',
                'AKIM ODA',
                'AKIM SWEDRU',
                'AKOFO HOUSE',
                'AKONTOMBRA',
                'AKOSOMBO',
                'AKOSOMBO BRANCH',
                'AKOTI RURAL BANK LTD-ASSIN AKROPONG',
                'AKROFUOM AREA RURAL BANK LTD-AKROFUOM',
                'AKROPONG',
                'AKROPONG AKWAPIM',
                'AKROSO',
                'AKUAPEM RURAL BANK LTD-MAMFE',
                'AKUM RURAL BANK LTD-ELMINA',
                'AKUMADAN',
                'AKUSE',
                'AKWADUM',
                'AKWATIA',
                'AKWETEYMAN',
                'AKYEM MANSA RURAL BANK LTD-AYIREBI',
                'AKYEPIM RURAL BANK LTD-GOMOA-DAW',
                'ALABAR',
                'ALABAR BRANCH',
                'ALPHA CAPITAL SAVING&L LTD',
                'AMAKOM',
                'AMAKOM BRANCH',
                'AMANANO RURAL BANK LTD-NYINAHIM',
                'AMANTEN',
                'AMASAMAN',
                'AMASIE WEST RURAL BANK LTD-ANTOAKROM',
                'AMATIN AND KASEI COMMUNITY BANK LTD-KASEI',
                'AMENFIMAN RURAL BANK LTD-WASSA AKROPONG',
                'AMERICAN HOUSE BRANCH',
                'AMFIE RURAL BANK LTD-WAMFIE',
                'ANDOM RURAL BANK LTD-NANDOM',
                'ANGBE RURAL BANK LTD-PRAMPRAM',
                'ANKOBRA WEST (ESIAMA) RURAL BANK LTD-ESIAMA',
                'ANLO RURAL BANK LTD-ANLOGA',
                'ANLOGA',
                'ANSOMAN RURAL BANK LTD-MANSO-AMENFI',
                'ANTEAKWA RURAL BANK LTD-KOFORIDUA',
                'ANUM APAPAM',
                'ANUM RURAL BANK LTD-ANUM',
                'ANYA KROBO RURAL BANK LTD-ODUMASE-KROBO',
                'ANYAA',
                'ANYAA MARKET BRANCH',
                'ANYINAM',
                'APAM',
                'APENKWA',
                'APENKWA BRANCH',
                'APEX BANK - BOLGA CENTRE-BOLGATANGA',
                'APEX BANK - CAPE COASTCAPE COAST',
                'APEX BANK - TAMALE CENTRE-TAMALE',
                'APEX BANK -HEAD OFFICE-SOUTH RIDGE ACCRA',
                'APEX BANK -HOHOE CENTRE-HOHOE',
                'APEX BANK KOFORIDUA CENTRE-KOFORIDUA',
                'APEX BANK -SUNYANI CENTRE-SUNYANI',
                'APEX BANK -WA CENTRE-WA',
                'APEX BANKACCRA CENTRE-ACCRA',
                'APEX BANK-HO CENTRE-HO',
                'APEX BANK-KUMASIKUMASI',
                'APEX BANK-TAKORADI CENTRE-TAKORADI',
                'APEX LINK CENTRE-CEDI HOUSE',
                'APITAL RURAL BANK LTD-ABESIM',
                'APLAKU',
                'APRA RURAL BANK LTD-PRANG',
                'ARENA BRANCH',
                'ASAFO',
                'ASAFO BRANCH',
                'ASAFO MARKET',
                'ASAMANKESE',
                'ASANKRAGUA',
                'ASANKRAGWA',
                'ASANTE AKYEM RURAL BANK LTD-JUANSA',
                'ASAWINSO BRANCH',
                'ASAWINSO RURAL BANK LTD-SEFWI-ASAWINSO',
                'ASEBU',
                'ASEMKROM',
                'ASEMPANEYE',
                'ASH TOWN',
                'ASHAIMAN',
                'ASHAIMAN BRANCH',
                'ASHAIMAN LEBABNON',
                'ASHAIMAN MAIN BRANCH',
                'ASHAIMAN ROUND ABOUT BRANCH',
                'ASHALLEY BOTWE BRANCH',
                'ASHANTI BEKWAI',
                'ASHANTI MAMPONG',
                'ASHIAMAN',
                'ASHIYEA BRANCH',
                'ASHTOWN',
                'ASHTOWN BRANCH',
                'ASHTOWN EAST',
                'ASHTOWN EAST BRANCH',
                'ASIAKWA',
                'ASIKUMA',
                'ASOKORE RURAL BANK LTD-ASOKRE',
                'ASOKWA',
                'ASOKWA BRANCH',
                'ASSIN FOSO',
                'ASSIN FOSU',
                'ASSIN FOSU POST',
                'ASSINMAN RURAL BANK LTD-ASSIN MANSO',
                'AST MANPRUSI COMMUNITY BABK LTD-GAMBAGA',
                'ASTERN GOMOA ASSIN RURAL BANK LTD-GOMOA-DOMINASE',
                'ASUBONTEN RURAL BANK LTD-WORAWORA',
                'ASUOGYAMAN RURAL BANK LTD-ACCRA',
                'ASUOPRA RURAL BANK LTD-AFOSU',
                'ASUTIFI RURAL BANK LTD-ACERENSUA',
                'ASUTUARE',
                'ASYLUM DOWN',
                'ATAABADZE',
                'ATEBUBU',
                'ATIWA RURAL BANK',
                'ATIWA RURAL BANK LTD-KWABENG',
                'ATOMIC BRANCH',
                'ATOMIC JUNCTION BRANCH',
                'ATONSU',
                'ATONSU BRANCH',
                'ATWEABAN RURAL BANK LTD-SUNYANI',
                'ATWIMA KWANWOMA RURAL BANK LTD-PAKYI NO. 2',
                'ATWIMA MPONUA RURAL BANK LTD-TOASE',
                'ATWIMA RURAL BANK LTD-FOASE',
                'AVENOR RURAL BANK LTD-AKATSI',
                'AVENUE CENTRAL',
                'AWASO',
                'AWJIASE AREA RURAL BANK LTD-AWUTU BAWJIASE',
                'AWRA RURAL BANK LTD-WA',
                'AWUTU',
                'AWUTU EMASA RURAL BANK LTD-AWUMASA',
                'AXIM',
                'AXIM ROAD BRANCH',
                'AYANFURI',
                'AYETEPA',
                'AYIREBI',
                'AYOMSO',
                'BAATSONA',
                'BAATSONA BRANCH',
                'BADUKROM',
                'BADUMAN RURAL BANK LTD-BADU',
                'BAMAHU',
                'BANDA NKWANTA',
                'BANTAMA',
                'BANTAMA 1',
                'BANTAMA BRANCH',
                'BANTAMA GNTC BRANCH',
                'BASYONDE',
                'BATSONA BRANCH',
                'BAWALESHIE',
                'BAWDJIASE',
                'BAWJIASE AREA RURAL BANK LTD-AWUTU BAWJIASE',
                'BAWKU',
                'BAWKU BRANCH',
                'BCM',
                'BECHEM',
                'BEGORO',
                'BEIGE CAPITAL',
                'BEKWAI',
                'BEPOSO',
                'BEREKUM',
                'BESEASE',
                'BESSFA RURAL BANK LTD-GARU',
                'BESTPOINT SAVINGS AND LOANS',
                'BEYIN',
                'BI RURAL BANK LTD-HOHOE',
                'BIA DEBISO',
                'BIA-TORYA RURAL BANK LTD-BONSU-NKWANTA',
                'BIBIANI',
                'BIMBILLA',
                'BINABA',
                'BIRIWA',
                'BOGOSO',
                'BOGOSU',
                'BOINSO',
                'BOKOBI RURAL BANK LTD-ABOKOBI',
                'BOLE',
                'BOLE BRANCH',
                'BOLGA COMMERCIAL ROAD BRANCH',
                'BOLGA POLICE STATION ROAD BRANCH',
                'BOLGA POST',
                'BOLGA POSTBANK',
                'BOLGATANGA',
                'BOMAA AREA RURAL BANK LTD-BOMAA',
                'BOMOSADU RURAL BANK LTD',
                'BOND SAVINGS AND LOANS',
                'BONGO',
                'BONGO RURAL BANK LTD-BOLGATANGA',
                'BONSO NKWANTA',
                'BONSU NKWANTA',
                'BONTRASE',
                'BONZALI RURAL BANK LTD-KUMBUNGU',
                'BORIMANGA RURAL BANK LTD-SAVELEGU',
                'BOSOME FREHO RURAL BANK LTD-KUMASI',
                'BOSOMTWI RURAL BANK LTD-KUNTANASE',
                'BOUNDARY ROAD',
                'BRAKWA BRAMAN RURAL BANK LTD-BRAKWA',
                'BREEZE EAST LEGON',
                'BREMAN ASIKUMA',
                'BREMAN UGC',
                'BUI',
                'BUILSA COMMUNITY BANK LTD-SANDEMA',
                'BUIPE',
                'BUNKPRUGU',
                'BURMA CAMP',
                'BURMA CAMP BRANCH',
                'BUSIE',
                'BUSUNYA',
                'BUTAWU RURAL BANK LTD-HO',
                'BUWUULONSO RURAL BANK LTD-TAMALE',
                'CAPE COAST',
                'CAPE COAST BRANCH',
                'CAPE COAST- MELCOM',
                'CAPE COAST- MFANTSIPIM',
                'CAPECOAST BRANCH',
                'CAPITAL RURAL BANK LTD-ABESIM',
                'CASTLE RD',
                'CASTLE ROAD',
                'CASTLE ROAD (HEAD OFFICE)',
                'CASTLE ROAD BRANCH',
                'CEDI HOUSE',
                'CHAMBA',
                'CHAPEL SQUARE',
                'CHARIA',
                'CHEREPONI',
                'CHIRAA',
                'CHORKOR',
                'CIRCLE',
                'CIRCLE BRANCH',
                'CITIZEN RURAL BANK LTD-ACCRA',
                'CLEARING UNIT',
                'COLLINS AVENUE',
                'COLLINS STREET',
                'COMMUNITY 25',
                'COMMUNITY 6',
                'COMMUNITY1 BRANCH',
                'CONSUMER FINANCE COMPANY (CFC) LTD',
                'CORPORATE',
                'CORPORATE SERVICE CENTRE',
                'CUC MIOTSO',
                'DA RURAL BANK LTD-KASSEH',
                'DABALA',
                'DABAN',
                'DABOASE',
                'DADIESO',
                'DAMANG',
                'DAMANGO',
                'DAMBAI',
                'DAMONGO',
                'DANGBE RURAL BANK LTD-PRAMPRAM',
                'DANICOM HOUSE(KOKOMLEMLE)',
                'DANQUAH CIRCLE',
                'DANSI RURAL BANK LTD-FOMENA',
                'DANSOMAN',
                'DANSOMAN EXHIBITION BRANCH',
                'DANSOMAN ROUND-ABOUT BRANCH',
                'DARKUMAN',
                'DARKUMAN BRANCH',
                'DAWA',
                'DENKYIRAMAN RURAL BANK LTD-AYAMFURI',
                'DENU',
                'DERBY AVENUE',
                'DERMA',
                'DERMA RURAL BANK LTD-DERMA',
                'DIRECT SALES',
                'DODOWA',
                'DOME',
                'DOME BRANCH',
                'DOME ST. JOHNS',
                'DONKORKROM',
                'DONTEN COMMUNITY BANK LTD-NEW TAFO',
                'DORMAA AHENKRO',
                'DOTOBRI RURAL BANK LTD-JACOBU',
                'DROBO',
                'DROBO COMMUNITY RURAL BANK LTD-DROBO',
                'DRORWULU',
                'DUADASO',
                'DUAYAW NKANTA',
                'DUAYAW NKWANTA',
                'DUMPONG RURAL BANK LTD-OFRAMASE',
                'DUNKWA',
                'DUNKWA OFFIN',
                'DUNKWA ON-OFFIN',
                'DUNKWA-ON-OFFIN',
                'DUNKWAW-ON-OFFIN BRANCH',
                'DUPONGKPEHE RURAL BANK LTD-KASOA',
                'DWEN-ANOMA RURAL BANK LTD-KWEEHWEE',
                'DWORWULU WEST AIRPORT',
                'DZEMENI',
                'DZODZE',
                'DZORWULU',
                'DZORWULU BRANCH',
                'DZORWULU MOTORWAY',
                'DZWORWULU',
                'EAST AIRPORT BRANCH',
                'EAST CANTONMENT',
                'EAST CANTONMENTS BRANCH',
                'EAST LEGON',
                'EAST LEGON 1',
                'EAST LEGON 2',
                'EAST LEGON BRANCH',
                'EAST MANPRUSI COMMUNITY BABK LTD-GAMBAGA',
                'EASTERN GOMOA ASSIN RURAL BANK LTD-GOMOA-DOMINASE',
                'EBANKESE',
                'ECOBANK BANTAMA GNTC',
                'ECOBANK COMMUNITY 1',
                'ECOBANK E PROCESS',
                'ECOBANK EAST AIRPORT',
                'ECOBANK EVANDY HOSTEL',
                'ECOBANK GH TAMALE',
                'ECOBANK GHANA',
                'ECOBANK HIGH STREET',
                'ECOBANK HOSPITAL ROAD',
                'ECOBANK KANTAMANTO',
                'ECOBANK MADINA CENTRAL',
                'ECOBANK OKPONGLO',
                'ECOBANK PENTAGON LEGON',
                'ECOBANK SSNIT HOUSE',
                'ECOBANK SUAME',
                'EFFIDUASE',
                'EFWIMAN RURAL BANK LTD-SEFWI-ASAW',
                'EJISU',
                'EJISU BRANCH',
                'EJURA',
                'EJURAMAN RURAL BANK LTD-KUMASI',
                'EKUMFIMAN RURAL BANK LTD-ESSUEHYIA',
                'EKYEDUMASI RURAL BANK LTD-SEKYEDUMASI',
                'EKYERE RURAL BANK LTD-JAMASI',
                'ELMINA',
                'ELMINA - CHAPEL SQUARE',
                'ELMINA -ESSUAKYIR',
                'ELUBO',
                'ELUBU',
                'ENCHI',
                'ENCHI BRANCH',
                'ENCHI RURAL BANK LTD',
                'ENKYIRAMAN RURAL BANK LTD-AYAMFURI',
                'ENYAN DENKYIRA RURAL BANK LTD-ENYAN DENKYIRA',
                'EPAMAN RURAL BANK LTD - TEPAMAN',
                'EPE AREA RURAL BANK LTD-MEPE',
                'ERMA RURAL BANK LTD-DERMA',
                'ESIAMA',
                'ESSAM',
                'ESSFA RURAL BANK LTD-GARU',
                'EST MANPRUSI COMMUNITY BANK LTD-WALEWALE',
                'ESTERN RURAL BANK LTD-WASSA AKROPONG',
                'ETO RURAL BANK LTD-KPEVE',
                'EVANDY HOSTEL BRANCH',
                'EZI SAVINGS AND LOANS LTD',
                'FAANOFA',
                'FAMAL',
                'FANTEAKWA RURAL BANK LTD-KOFORIDUA',
                'FANTSIMAN COMMUNITY RURAL BANK LTD-BIRIWA',
                'FARRAR',
                'FFINSO RURAL BANK LTD-KUMASI',
                'FIAGYA RURAL BANK LTD-BUSUNYA',
                'FIAPRE',
                'FIASEMAN (BOGOSO AREA) RURAL BANK LTD-BOGOSO',
                'FIRST AFRICAN SAVINGS AND LOANS',
                'FIRST ALLIED SAVINGS & LOANS',
                'FIRST ALLIED SAVINGS AND LOANS',
                'FIRST GHANA BUILDING SOCIETY- ACCRA',
                'FIRST GHANA BUILDING SOCIETY- KOFORIDUA',
                'FIRST GHANA BUILDING SOCIETY- KUMASI AMAKOM',
                'FIRST GHANA BUILDING SOCIETY- KUMASI SUAME',
                'FIRST GHANA BUILDING SOCIETY- SUNYANI',
                'FIRST GHANA BUILDING SOCIETY- TAKORADI',
                'FIRST GHANA BUILDING SOCIETY-HEAD OFFICE',
                'FIRST GHANA BUILDING SOCIETY-HOHOE',
                'FIRST GHANA BUILDING SOCIETY-TAKORADI',
                'FIRST GHANA BUILDING SOCIETY-TEMA',
                'FIRST NATATIONAL S&L',
                'FIRST NATIONAL SAVINGS & LOANS',
                'FOASE',
                'FOREIGN',
                'FOREIGN OPERATIONS',
                'FOREIGN OPERATIONS BRANCH',
                'FRAM RURAL BANK LTD-TEASE',
                'FULFOSO',
                'FUMBISI',
                'GA RURAL BANK LTD-AMASAMAN',
                'GAMASHIE',
                'GARU',
                'GAVE RURAL BANK LTD-DABALE',
                'GBI RURAL BANK LTD-HOHOE',
                'GICEL',
                'GIMPA',
                'GIMPA BRANCH',
                'GLOBAL ACCESS SAVINGS & LOANS CO. LTD',
                'GNPC',
                'GNPC TEMA',
                'GOASO',
                'GOLDEN TULIP KUMASI BRANCH',
                'GOLOKWATI',
                'GOMOA AJUMAKO RURAL BANK LTD-AFRANSI',
                'GOMOA RURAL BANK LTD-APAM',
                'GONA RURAL BANK LTD-KWANYAKU',
                'GRAPHIC ROAD',
                'GREL',
                'GULF HOUSE',
                'GUMANI',
                'GUSHIEGU',
                'GWB LAPAZ',
                'HAATSO',
                'HAATSOO',
                'HAFO ANO PREMIER RURAL BANK LTD-WIOSO',
                'HAFO COMMUNITY RURAL BANK LTD-KUKUOM',
                'HAI RURAL BANK LTD-DODOWA',
                'HALF ASSINI',
                'HAMILE',
                'HANTAMAN RURAL BANK LTD-AGONA NKWANTA',
                'HAPER ROAD',
                'HARBOUR AREA',
                'HARPER ROAD',
                'HARPER ROAD BRANCH',
                'HE SEED SAVINGS AND LOANS',
                'HEAD OFFICE',
                'HEAD-OFFICE',
                'HIGH STREET',
                'HIGH STREET BRANCH',
                'HIGH STREET TAKORADI',
                'HIGH STREET-TAKORADI',
                'HO',
                'HO AGENCY',
                'HO BRANCH',
                'HO MARKET',
                'HO POLYTECHNIC',
                'HO POST',
                'HO POSTBANK',
                'HOEHOE',
                'HOHOE',
                'HOHOE POST',
                'HOHOE POSTBANK',
                'HOSPITAL ROAD BRANCH',
                'HWEDIEM',
                'HWIDIEM',
                'HWIDIEM[ B/A]',
                'IAGYA RURAL BANK LTD-BUSUNYA',
                'IASEMAN (BOGOSO AREA) RURAL BANK LTD-BOGOSO',
                'IA-TORYA RURAL BANK LTD-BONSU-NKWANTA',
                'ICROFIN RURAL BANK LIMITED',
                'INDEPENDENCE AVENUE',
                'INDUSTRIAL AREA',
                'INTAMPO RURAL BANK LTD-KINTAMPO',
                'INTERNATIONAL BANKING',
                'IPS',
                'IPS BRANCH',
                'IPS LEGON',
                'IRST GHANA BUILDING SOCIETY- ACCRA',
                'IRST GHANA BUILDING SOCIETY- KOFORIDUA',
                'IRST GHANA BUILDING SOCIETY- KUMASI AMAKOM',
                'IRST GHANA BUILDING SOCIETY- KUMASI SUAME',
                'IRST GHANA BUILDING SOCIETY- SUNYANI',
                'IRST GHANA BUILDING SOCIETY- TAKORADI',
                'IRST GHANA BUILDING SOCIETY-HEAD OFFICE',
                'IRST GHANA BUILDING SOCIETY-HOHOE',
                'IRST GHANA BUILDING SOCIETY-TAKORADI',
                'IRST GHANA BUILDING SOCIETY-TEMA',
                'ISSALA RURAL BANK LTD-TUMA',
                'ITIZEN RURAL BANK LTD-ACCRA',
                'IZAA RURAL BANK LTD-TAMALE',
                'JACOBU',
                'JANYAGU',
                'JAPEKROM',
                'JAPEKROM/DROBO',
                'JASIKAN',
                'JEMA',
                'JINIJIN',
                'JIRAPA',
                'JISONAYILI',
                'JOMORO RURAL BANK LTD-TIKOBO NO. 1',
                'JUABEN RURAL BANK LTD-JUABEN',
                'JUABESO',
                'JUAPONG',
                'JUASO',
                'JUBILEE HOUSE',
                'JUBILEE HOUSE COCOBOD BUILDING',
                'JUKWA',
                'JUNCTION MALL',
                'JUNCTION MALL BRANCH',
                'JURAMAN RURAL BANK LTD-KUMASI',
                'KAASE',
                'KAASEMAN RURAL BANK LTD-KAASE',
                'KADE',
                'KADJEBI',
                'KAJEBI',
                'KAKUM RURAL BANK LTD-ELMINA',
                'KALADAN',
                'KANASHIE',
                'KANESHIE',
                'KANESHIE BRANCH',
                'KANESHIE INDUSTRIAL AREA',
                'KANESHIE MAIN',
                'KANESHIE MARKET',
                'KANESHIE POST OFFICE',
                'KANESHIE POST OFFICE BRANCH',
                'KANESSHIE',
                'KANTAMANTO',
                'KANTAMANTO AGENCY',
                'KANTAMANTO BRANCH',
                'KARAGA',
                'KASAPIN',
                'KASOA',
                'KASOA BRANCH',
                'KASOA MAIN',
                'KASOA MAIN BRANCH',
                'KASOA MARKET',
                'KASOA MILLENIUM CITY',
                'KASOA NEW MARKET',
                'KATAKYIMAN RURAL BANK LTD-KOMENDA',
                'KEJETIA',
                'KEJETIA BRANCH',
                'KEJETIA- KUMASI',
                'KENYASE',
                'KENYASI AGENCY',
                'KETA',
                'KETE KRACHI',
                'KIBI',
                'KIM BOSOME RURAL BANK LTD-AKIM SWEDRU',
                'KINTAMPO',
                'KINTAMPO RURAL BANK LTD-KINTAMPO',
                'KISSEIMAN BRANCH',
                'KISSI',
                'KISSIEMAN',
                'KNUST',
                'KNUST BRANCH',
                'KNUST CAMPUS',
                'KNUST KUMASI',
                'KNUTSFORD',
                'KNUTSFORD AVENUE',
                'KOFORIDUA',
                'KOFORIDUA BRANCH',
                'KOFORIDUA KAMA CENTRE',
                'KOJO THOMPSON ROAD',
                'KOKOMLEMLE BRANCH',
                'KOMENDA',
                'KOMFO ANOKYE RURAL BANK LTD-WIAMOASE',
                'KOMLEMLE',
                'KONGO',
                'KONONGO',
                'KORANMAN RURAL BANK LTD-SEIKWA',
                'KORANZA KWABRE RURAL BANK LTD-AKUMA',
                'KORKORDZOR',
                'KORLE BU',
                'KORLE DUDOR',
                'KORLE DUDOR BRANCH',
                'KORLE-BU',
                'KORLE-BU BRANCH',
                'KOTI RURAL BANK LTD-ASSIN AKROPONG',
                'KOTOBABI',
                'KPADIE',
                'KPANDAI',
                'KPANDO',
                'KPANDU',
                'KPASSA RURAL BANK LTD-HOHOE',
                'KPEDZE',
                'KPETOE',
                'KPEVE',
                'KPONE',
                'KPONG',
                'KRACHE NKWANTA',
                'KRANKWANTA RURAL BANK LTD-SUNYANI',
                'KROFOM',
                'KROFROM',
                'KROFUOM AREA RURAL BANK LTD-AKROFUOM',
                'KRONUM BRANCH',
                'KUAPEM RURAL BANK LTD-MAMFE',
                'KUMASI',
                'KUMASI (ALABAR)',
                'KUMASI ADUM',
                'KUMASI -ADUM',
                'KUMASI AMAKOM',
                'KUMASI ASAFO',
                'KUMASI ASAFO BRANCH',
                'KUMASI ASOKWA',
                'KUMASI BRANCH',
                'KUMASI CENT.',
                'KUMASI CENTRAL',
                'KUMASI CENTRAL MARKET',
                'KUMASI HARPER ROAD',
                'KUMASI MAIN',
                'KUMASI PREMPEH 11 STREET',
                'KUMASI PREMPEH STREET',
                'KUMASI SUAME',
                'KUMASI TANNOSO',
                'KUMASI-LAKE  ROAD',
                'KUMAWUMAN RURAL BANK LTD-KUMAWU',
                'KUMFIMAN RURAL BANK LTD-ESSUEHYIA',
                'KWABENYA',
                'KWABENYA BRANCH',
                'KWADASO',
                'KWAEBIBIREM RURAL BANK LTD-ASUOM',
                'KWAHU PRASO RURAL BANK LTD-KWAHU PRASO',
                'KWAHU RURAL BANK LTD-KWAHU PEPEASE',
                'KWAHUTAFO',
                'KWAMANMAN RURAL BANK LTD-KWAMANG',
                'KWAME DANSO BRANCH',
                'KWAME NKRUMAH AVE',
                'KWAME NKRUMAH CIRCLE',
                'KWAPONG',
                'KWASHIEMAN',
                'KWASHIEMAN BRANCH',
                'KYEKYEWERE',
                'KYEM MANSA RURAL BANK LTD-AYIREBI',
                'KYEPIM RURAL BANK LTD-GOMOA-DAW',
                'LA',
                'LA COMMUNITY BANK LTD-LA',
                'LABONE',
                'LABONE BRANCH',
                'LAPAZ',
                'LAPAZ BRANCH',
                'LASHIBI',
                'LASHIBI BRANCH',
                'LAW COURT COMPLEX',
                'LAW-COURT',
                'LAWRA',
                'LAWRA RURAL BANK LTD-WA',
                'LEGON',
                'LEGON MAIN',
                'LIBERATION ROAD TAKORADI',
                'LIBERATION ROAD TOKORADI',
                'LIBERATION ROAD-TAKORADI',
                'LIBERIA ROAD',
                'LIBERTY HSE',
                'LOTTERIES',
                'LOWER PRA RURAL BANK LTD-SHAMA',
                'MAAKRO BRANCH',
                'MAAME KROBO',
                'MAAMOBI',
                'MACCARTHY HILL',
                'MADINA',
                'MADINA BRANCH',
                'MADINA CENTRAL BRANCH',
                'MADINA MAIN BRANCH',
                'MADINA NEW ROAD BRANCH',
                'MAKANGO',
                'MAKOLA',
                'MAKOLA BRANCH',
                'MAKOLA SQUARE',
                'MALLAM',
                'MALLAM INTERCHANGE',
                'MALLAM JUNCTION BRANCH',
                'MAMFE AKUAPIM',
                'MAMFE BRANCH',
                'MAMOBI',
                'MAMOBI BRANCH',
                'MAMPONG',
                'MAMPONG AKWAPIM',
                'MAMPONG ASHANTI',
                'MAMPROBI',
                'MAMPROBI POST',
                'MAMPROBI POSTBANK',
                'MANANO RURAL BANK LTD-NYINAHIM',
                'MANET TOWER 3 BRANCH',
                'MANKESSIM',
                'MANKESSIM OFFICE COMPLEX',
                'MANKRASO',
                'MANSO NKWANTA',
                'MANSOMAN RURAL BANK LTD-MANSO-AMENFI',
                'MANYA KROBO RURAL BANK LTD-ODUMASE-KROBO',
                'MARKET CIRCLE BRANCH',
                'MARKET CIRCLE TAKORADI',
                'MASIE WEST RURAL BANK LTD-ANTOAKROM',
                'MATAHEKO',
                'MATIN AND KASEI COMMUNITY BANK LTD-KASEI',
                'MAX MART',
                'MCCARTHY HILL',
                'MELCOM',
                'MENFIMAN RURAL BANK LTD-WASSA AKROPONG',
                'MEPE AREA RURAL BANK LTD-MEPE',
                'MERIDIAN BRANCH',
                'MFANTSIMAN COMMUNITY RURAL BANK LTD-BIRIWA',
                'MFANTSIPIM',
                'MICHEL CAMP',
                'MICROFIN RURAL BANK LIMITED',
                'MIDLAND SAVING AND LOANS',
                'MIDLAND SAVINGS & LOANS LTD',
                'MILE 7/TANTRA',
                'MIM',
                'MINISTRIES',
                'MIOTSO',
                'MOREE',
                'MOTOR WAY ROUNDABOUT',
                'MOTORWAY EXT',
                'MOTORWAY EXTENSION',
                'MOTORWAY ROUNDABOUT',
                'MPATASIE',
                'MPOHOR',
                'MPONUA RURAL BANK LTD-AMUANA PRASO',
                'MPRAESO',
                'MULTI CREDIT SAVINGS AND LOANS - KUMASI',
                'MULTICREDIT SAVINGS AND LOANS CO.',
                'MUMFORD',
                'MUMUADA RURAL BANK LTD-OSINO',
                'NAARA RURAL BANK LTD-PAGA',
                'NAFANA RURAL BANK LTD-SAMPA',
                'NAGODE',
                'NANDOM',
                'NANDOM RURAL BANK LTD-NANDOM',
                'NAVORONGO',
                'NAVRONGO',
                'NBFI-ADVANS GH',
                'NCHIRAA',
                'NCR',
                'NEW ABEKA',
                'NEW ABIREM',
                'NEW ABIREM BRANCH',
                'NEW ABRIEM',
                'NEW ADUBIASE',
                'NEW AMAKOM',
                'NEW DORMAA KOTOKROM',
                'NEW EDUBIASE',
                'NEW NINGO',
                'NEW OFFINSO',
                'NEW SUAME MAGAZINE',
                'NEW TAFO',
                'NEW TOWN',
                'NEWTOWN',
                'NEWTOWN ACCRA',
                'NHYIAESO BRANCH',
                'NHYIEASO',
                'NHYIESO',
                'NIA',
                'NIA BRANCH',
                'NIMA',
                'NIMA BRANCH',
                'NIMA MAIN BRANCH',
                'NINGO',
                'NION RURAL BANK LTD-SENYA BREKU',
                'NITY RURAL BANK LTD-ZIOPE',
                'NKAWIE',
                'NKAWKAW',
                'NKOBRA WEST (ESIAMA) RURAL BANK LTD-ESIAMA',
                'NKORANMAN RURAL BANK LTD-SEIKWA',
                'NKORANSAH',
                'NKORANZA',
                'NKORANZA KWABRE RURAL BANK LTD-AKUMA',
                'NKRANKWANTA',
                'NKRANKWANTA RURAL BANK LTD-SUNYANI',
                'NKWAKAW POST',
                'NKWANTA',
                'NLO RURAL BANK LTD-ANLOGA',
                'NOBLE DREAM MICROFINANCE LTD',
                'NORTH INDUSTRIAL',
                'NORTH INDUSTRIAL AREA',
                'NORTH INDUSTRIAL AREA BRANCH',
                'NORTH KANESHIE',
                'NORTH RIDGE',
                'NORTH TONGU RURAL BANK LTD-ADIDOME',
                'NORTH VOLTA RURAL BANK LTD-GUAMAN',
                'NSAWAM',
                'NSOATREMAN RURAL BANK LTD-NSOATRE',
                'NSUATRE',
                'NSUFA ASHANTI',
                'NSUTAMAN RURAL BANK LTD-NSUTA',
                'NTOROSO',
                'NUM RURAL BANK LTD-ANUM',
                'NUNGUA',
                'NWABIAGYA RURAL BANK LTD-BAREKESE',
                'NYAKROM RURAL BANK LTD-NYAKROM',
                'NYAN DENKYIRA RURAL BANK LTD-ENYAN DENKYIRA',
                'NYANKPALA',
                'NYANKUMASE AHENKRO RURAL BANK LTD-FANTI NYANKUMASE',
                'NYIAESO',
                'NYIAESO AHODWO',
                'NZEMA MANLE RURAL BANK LTD-AWIEBO',
                'OBUASI',
                'ODA',
                'ODA NKWANTA',
                'ODORKOR',
                'ODOTOBRI RURAL BANK LTD-JACOBU',
                'ODUPONGKPEHE RURAL BANK LTD-KASOA',
                'ODWEN-ANOMA RURAL BANK LTD-KWEEHWEE',
                'OENDE RURAL BANK LTD-ZIBILLA',
                'OFFINSO',
                'OFFINSO RURAL BANK LTD-KUMASI',
                'OFFSHORE BANKING',
                'OFFSHORE BANKING UNIT - GH',
                'OKAISHIE',
                'OKAISHIE BRANCH',
                'OKOFO BRANCH',
                'OKOMFO ANOKYE RURAL BANK LTD-WIAMOASE',
                'OKPONGLO',
                'OKPONGLO BRANCH',
                'OLA',
                'OLD NINGO',
                'OLD SUAME MAGAZINE',
                'OLD TAFO',
                'OMAA AREA RURAL BANK LTD-BOMAA',
                'OMOA AJUMAKO RURAL BANK LTD-AFRANSI',
                'OMOA RURAL BANK LTD-APAM',
                'OMORO RURAL BANK LTD-TIKOBO NO. 1',
                'OMOSADU RURAL BANK LTD',
                'ONGO RURAL BANK LTD-BOLGATANGA',
                'ONZALI RURAL BANK LTD-KUMBUNGU',
                'ONZELE RURAL BANK LTD-JIRAPA',
                'OPEIBEA HOUSE',
                'OPERA SQUARE',
                'OPERA SQUARE BRANCH',
                'OPPORTUNITY INT. SAVINGS AND LOAN',
                'OPPORTUNITY INT. SAVINGS AND LOANS',
                'OPPORTUNITY INTERNATIONAL',
                'OREIGN',
                'ORIMANGA RURAL BANK LTD-SAVELEGU',
                'ORTH TONGU RURAL BANK LTD-ADIDOME',
                'ORTH VOLTA RURAL BANK LTD-GUAMAN',
                'OSEI KWADWO KROM',
                'OSOME FREHO RURAL BANK LTD-KUMASI',
                'OSOMTWI RURAL BANK LTD-KUNTANASE',
                'OSU',
                'OSU  OXFORD BRANCH',
                'OSU - OXFORD STREET',
                'OSU - WATSON STREET',
                'OSU OXFORD ST.',
                'OSU OXFORD STREET',
                'OSU WATSON BRANCH',
                'OTUASEKAN RURAL BANK LTD-KOFIASE',
                'OUTH AKIM RURAL BANK LTD-NANKESE',
                'OUTH BIRIM RURAL BANK LTD-ACHIASE',
                'OWER PRA RURAL BANK LTD-SHAMA',
                'OXFORD STREET BRANCH',
                'OYIBI AREA RURAL BANK LIMITED',
                'PACIFIC SAVINGS AND LOANS LTD',
                'PAGA',
                'PALM WINE JUNCTION',
                'PALMWINE JUNCTION',
                'PARLIAMENT HOUSE',
                'PASSA RURAL BANK LTD-HOHOE',
                'PATASE',
                'PATRICE LUMUMBA ROAD',
                'PEKI',
                'PENTAGON LEGON BRANCH',
                'PEX BANK - BOLGA CENTRE-BOLGATANGA',
                'PEX BANK - CAPE COASTCAPE COAST',
                'PEX BANK - TAMALE CENTRE-TAMALE',
                'PEX BANK -HEAD OFFICE-SOUTH RIDGE ACCRA',
                'PEX BANK -HOHOE CENTRE-HOHOE',
                'PEX BANK KOFORIDUA CENTRE-KOFORIDUA',
                'PEX BANK -SUNYANI CENTRE-SUNYANI',
                'PEX BANK -WA CENTRE-WA',
                'PEX BANKACCRA CENTRE-ACCRA',
                'PEX BANK-HO CENTRE-HO',
                'PEX BANK-KUMASIKUMASI',
                'PEX BANK-TAKORADI CENTRE-TAKORADI',
                'PEX LINK CENTRE-CEDI HOUSE',
                'PIG FARM BRANCH',
                'POKUASE',
                'POKUASE BRANCH',
                'PONUA RURAL BANK LTD-AMUANA PRASO',
                'POST OFFICE SQUARE',
                'POTSIN',
                'PPER AMENFI RURAL BANK LTD-ANKWAWSO',
                'PPER MANYA KRO RURAL BANK LTD-ASESEWA',
                'PRAMPRAM',
                'PRANG',
                'PREMIER TOWERS',
                'PREMPEH II STREET',
                'PRESTEA',
                'PRIVATE BANKING',
                'PROCREDIT SAVINGS & LOANS',
                'PUSIGA',
                'RAKWA BRAMAN RURAL BANK LTD-BRAKWA',
                'REINSURANCE HOUSE',
                'REINSURANCE HOUSE BRANCH',
                'REPUBLIC HOUSE',
                'RIDGE',
                'RIDGE BRANCH',
                'RIDGE TOWERS',
                'RING ROAD',
                'RING ROAD BRANCH',
                'RING ROAD CENTRAL',
                'RING ROAD CENTRAL BRANCH',
                'RING ROAD WEST',
                'ROBO COMMUNITY RURAL BANK LTD-DROBO',
                'ROMAN HILL',
                'ROMAN HILL BRANCH',
                'ROMAN HILL MARKET BRANCH',
                'RRC',
                'RRC REMITTANCE CENTRE',
                'RUSSIA ROAD BRANCH',
                'SABOBA',
                'SAFE BOND',
                'SAFE BOND JUBILEE',
                'SAFE BOUND JUBILEE',
                'SAFEBOND',
                'SAKAMAN',
                'SAKUMONO',
                'SAKUMONO BRANCH',
                'SALAGA',
                'SALTPOND',
                'SAMPA',
                'SAMREBOI',
                'SANDEMA',
                'SANKORE',
                'SANTA MARIA',
                'SANTASI',
                'SANTE AKYEM RURAL BANK LTD-JUANSA',
                'SAVELUGU',
                'SAWINSO RURAL BANK LTD-SEFWI-ASAWINSO',
                'SAWLA',
                'SEFWI BEKWAI',
                'SEFWI BRANCH',
                'SEFWI ESSAM',
                'SEFWI WIAWO',
                'SEFWI WIAWSO',
                'SEFWIMAN RURAL BANK LTD-SEFWI-ASAW',
                'SEFWI-WIASO',
                'SEGE',
                'SEKONDI',
                'SEKYEDUMASI RURAL BANK LTD-SEKYEDUMASI',
                'SEKYERE RURAL BANK LTD-JAMASI',
                'SERVICE BRANCH',
                'SHAI RURAL BANK LTD-DODOWA',
                'SHIPPERS HOUSE BRANCH',
                'SILVER STAR',
                'SINAPI ABA SAVINGS & LOAN',
                'SINAPI ABA SAVINGS & LOANS',
                'SISSALA RURAL BANK LTD-TUMA',
                'SME',
                'SME CENTRE',
                'SOATREMAN RURAL BANK LTD-NSOATRE',
                'SOGAKOPE',
                'SOKOBAN',
                'SOKOBAN BRANCH',
                'SOKORE RURAL BANK LTD-ASOKRE',
                'SOMANYA',
                'SONZELE RURAL BANK LTD-JIRAPA',
                'SOUTH AKIM RURAL BANK LTD-NANKESE',
                'SOUTH BIRIM RURAL BANK LTD-ACHIASE',
                'SOUTH INDUSTRIAL AREA',
                'SOUTH INDUSTRIAL AREA(SIA)',
                'SOUTH INDUSTRIAL BRANCH',
                'SOUTH LEGON BRANCH',
                'SOWUTUOM',
                'SPINTEX',
                'SPINTEX BRANCH',
                'SPINTEX MAIN',
                'SPINTEX PRESTIGE',
                'SPINTEX ROAD',
                'SPINTEX ROAD BRANCH',
                'SSINMAN RURAL BANK LTD-ASSIN MANSO',
                'SSNIT HOUSE BRANCH',
                'ST PETERS CO-OPERATIVE CREDIT UNION',
                'STADIUM',
                'STADIUM AMAKOM',
                'STADIUM POST',
                'STEWART AVENUE KUMASI',
                'SUAME',
                'SUAME BRANCH',
                'SUAME MAAKRO',
                'SUBONTEN RURAL BANK LTD-WORAWORA',
                'SUHUM',
                'SUHUM RURAL BANK',
                'SUMA RURAL BANK LTD-SUMA AHENKRO',
                'SUNYANI',
                'SUNYANI CENTRAL',
                'SUNYANI MAIN BRANCH',
                'SUNYANI NIMPONG',
                'SUNYANI ODUMASE',
                'SUNYANI POST OFFICE BRANCH',
                'SUNYANI POSTBANK',
                'SUOGYAMAN RURAL BANK LTD-ACCRA',
                'SUOPRA RURAL BANK LTD-AFOSU',
                'SUTAMAN RURAL BANK LTD-NSUTA',
                'SUTIFI RURAL BANK LTD-ACERENSUA',
                'SWANMILL',
                'SWEDRU',
                'SWEDRU BRANCH',
                'TAFO',
                'TAFO BRANCH',
                'TAIFA',
                'TAKORADI',
                'TAKORADI - KOKOMPE',
                'TAKORADI BRANCH',
                'TAKORADI EFFIEKUMA',
                'TAKORADI HABOUR BRANCH',
                'TAKORADI HARBOUR',
                'TAKORADI HARBOUR ROAD',
                'TAKORADI KOKOMPE',
                'TAKORADI MAIN',
                'TAKORADI MARKET CIRCLE',
                'TAKORADI MKT CIRCLE',
                'TAKORADI POST',
                'TAKORADI POSTBANK',
                'TAMALE',
                'TAMALE ABOABO',
                'TAMALE ABOABO BRANCH',
                'TAMALE BRANCH',
                'TAMALE CENTRAL',
                'TAMALE HOSP ROAD',
                'TAMALE HOSPITAL ROAD',
                'TAMALE HOSPITAL ROAD BRANCH',
                'TAMALE LAMESHEGU',
                'TAMALE MAIN BRANCH',
                'TAMALE MARKET',
                'TANOKROM',
                'TANOSO',
                'TANTRA',
                'TARKORADI MARKET',
                'TARKWA',
                'TARKWA BRANCH',
                'TARKWA MINES',
                'TEASE',
                'TECH JUNCTION',
                'TECHIMAN',
                'TECHIMAN BRANCH',
                'TECHIMAN MARKET',
                'TECHIMAN MARKET BRANCH',
                'TECHIMAN NORTH BRANCH',
                'TECHIMAN TIA BRANCH',
                'TECHIMAN VALLEY VIEW BRANCH',
                'TECHIMAN WENCHI ROAD BRANCH',
                'TEMA',
                'TEMA BRANCH',
                'TEMA COMM 1',
                'TEMA COMM 2',
                'TEMA COMM 4',
                'TEMA COMM. 25',
                'TEMA COMM. 6',
                'TEMA COMMUNITY 1',
                'TEMA COMMUNITY 1 BRANCH',
                'TEMA COMMUNITY 2 BRANCH',
                'TEMA COMMUNITY 25',
                'TEMA COMMUNITY 25 BRANCH',
                'TEMA COMMUNITY 6',
                'TEMA COMMUNITY 9',
                'TEMA COMMUNITY ONE',
                'TEMA COMMUNITY TWO',
                'TEMA DRYDOCK',
                'TEMA EAST',
                'TEMA FISHING HABOUR',
                'TEMA FISHING HARBOUR',
                'TEMA HABOUR',
                'TEMA HARBOUR',
                'TEMA INDUSTRIAL AREA',
                'TEMA LONG ROOM',
                'TEMA MAIN',
                'TEMA MAIN BRANCH',
                'TEMA MARKET',
                'TEMA METRO BRANCH',
                'TEMA OIL REFINERY',
                'TEMA SAFEBOND',
                'TEMA SHOPPING MAIL',
                'TEMA SHOPPING MALL',
                'TEMPANE BRANCH',
                'TEPA',
                'TEPAMAN RURAL BANK LTD - TEPAMAN',
                'TESANO',
                'TESANO BRANCH',
                'TESHIE',
                'TESHIE BRANCH',
                'TESHIE NUNGUA',
                'TETTEH QUARSHIE',
                'THE SEED SAVINGS AND LOANS',
                'TIA BRANCH',
                'TIWA RURAL BANK LTD-KWABENG',
                'TIZAA RURAL BANK LTD-TAMALE',
                'TOENDE RURAL BANK LTD-ZIBILLA',
                'TOLON',
                'TONGU',
                'TOR',
                'TRADE FAIR',
                'TRADE FAIR BRANCH',
                'TREASURY',
                'TREDE',
                'TRUST TOWERS',
                'TRUST TOWERS BRANCH',
                'TSITO',
                'TSOPOLI',
                'TSUI BLEOO',
                'TUASEKAN RURAL BANK LTD-KOFIASE',
                'TUDU',
                'TUDU BRANCH',
                'TUMJU',
                'TUMU',
                'TUOBODOM',
                'TUTU BRANCH',
                'TWEABAN RURAL BANK LTD-SUNYANI',
                'TWIFO PRASO',
                'TWIFO RURAL BANK LTD-TWIFO AGONA',
                'TWIMA KWANWOMA RURAL BANK LTD-PAKYI NO. 2',
                'TWIMA MPONUA RURAL BANK LTD-TOASE',
                'TWIMA RURAL BANK LTD-FOASE',
                'UABEN RURAL BANK LTD-JUABEN',
                'UCC',
                'UEW BRANCH',
                'UHUM RURAL BANK',
                'UILSA COMMUNITY BANK LTD-SANDEMA',
                'UMA RURAL BANK LTD-SUMA AHENKRO',
                'UMAWUMAN RURAL BANK LTD-KUMAWU',
                'UMPONG RURAL BANK LTD-OFRAMASE',
                'UMUADA RURAL BANK LTD-OSINO',
                'UNDP',
                'UNION RURAL BANK LTD-SENYA BREKU',
                'UNION SAVINGS & LOANS',
                'UNION SAVINGS AND LOANS',
                'UNION SAVINGS AND LOANS LIMITED',
                'UNITY RURAL BANK LTD-ZIOPE',
                'UNIV. OF CAPE COAST BRANCH',
                'UNIVERSITY OF CAPE COAST',
                'UNIVERSITY OF CAPE COAST BRANCH',
                'UNIVERSITY OF GHANA BRANCH',
                'UPPER AMENFI RURAL BANK LTD-ANKWAWSO',
                'UPPER MANYA KRO RURAL BANK LTD-ASESEWA',
                'UTAWU RURAL BANK LTD-HO',
                'UTRAK SAVINGS AND LOANS',
                'UWUULONSO RURAL BANK LTD-TAMALE',
                'VAKPO',
                'VALLEY VIEW',
                'VALLEY VIEW UNIVERSITY- TECHIMAN',
                'VENOR RURAL BANK LTD-AKATSI',
                'WA',
                'WA BRANCH',
                'WABIAGYA RURAL BANK LTD-BAREKESE',
                'WAEBIBIREM RURAL BANK LTD-ASUOM',
                'WAHU PRASO RURAL BANK LTD-KWAHU PRASO',
                'WAHU RURAL BANK LTD-KWAHU PEPEASE',
                'WALEWALE',
                'WAMANMAN RURAL BANK LTD-KWAMANG',
                'WAMFIE',
                'WAMFIE RURAL BANK LTD-WAMFIE',
                'WASSA MANSO',
                'WECHIAU',
                'WEIJA',
                'WEIJA BRANCH',
                'WENCHI',
                'WENCHI RURAL BANK LTD',
                'WEST HILLS MALL',
                'WEST LANDS',
                'WEST MANPRUSI COMMUNITY BANK LTD-WALEWALE',
                'WESTERN HILL',
                'WESTERN RURAL BANK LTD-WASSA AKROPONG',
                'WESTLANDS',
                'WETO RURAL BANK LTD-KPEVE',
                'WIDANA',
                'WIFO RURAL BANK LTD-TWIFO AGONA',
                'WINNEBA',
                'WINNEBA BRANCH',
                'WINNEBA RD',
                'WINNEBA ROAD',
                'WISCONSIN',
                'WISCONSIN BRANCH',
                'WOMANS WORLD BANKING',
                'WOMENS WORLD BANK GHANA SAVINGS AND LOANS',
                'WOMENS WORLD BANKING',
                'WULENSI',
                'WURIYENGA',
                'WUTU EMASA RURAL BANK LTD-AWUMASA',
                'YAA ASANTEWAA RURAL BANK LTD',
                'YAKROM RURAL BANK LTD-NYAKROM',
                'YANKUMASE AHENKRO RURAL BANK LTD-FANTI NYANKUMASE',
                'YAPRA RURAL BANK LTD-PRANG',
                'YEJI',
                'YENDI',
                'YESU NKWA',
                'YIBI AREA RURAL BANK LIMITED',
                'YUNYOO',
                'ZABZUGU RURAL BANK LTD',
                'ZEBILLA',
                'ZEMA MANLE RURAL BANK LTD-AWIEBO',
                'ZENITH BANK (GH) LTD-SUNYANI',
                'ZENU',
                'ZENU BRANCH',
                'ZONGO JUNCTION',
              ],
              publishChange: true,
              readonly: false,
              tooltip: 'Account Branch Name',
              type: 'field',
              uid: '503a71583f1182e2',
            },
            {
              content: '',
              elementType: 'input',
              errors: ['*'],
              id: 'account-number',
              inputType: 'text',
              label: 'Account Number',
              publishChange: true,
              readonly: false,
              type: 'field',
              uid: '495c881bcf57de23',
            },
          ],
          id: 'new-account-details',
          label: 'Bank Account Details',
          readonly: false,
          type: 'list',
          uid: '57e29091b22f7c17',
        },
      ],
    }
  } else if (form['stop-order'] === 'Y') {
    return {
      content: [
        {
          content: '',
          elementType: 'select',
          errors: ['*'],
          id: 'employer-organisation',
          label: 'Employer Organisation',
          options: [
            '',
            'Abokobi Area Rural Bank Staff',
            'Accra Metro Assembly',
            'Advent Press',
            'African Regent Hotel',
            'Agave Rural Bank',
            'Agric Development Bank',
            'Ahafo Ano Premier Rural Bank',
            'Akaa Security Services',
            'Akwapem Rural Bank',
            'Aluworks',
            'Ama Dansowaa Clinic',
            'Amanano Rural Bank',
            'Amansie West Rural Bank',
            'Antrak Ghana Ltd',
            'Anum Rural Bank Ltd',
            'Apex Bank',
            'Ashante Akyem Rural Bank',
            'Asokore Rural Bank Ltd - Kumasi',
            'Asutifi Rural Bank Limited',
            'Atlantic Int. Holding Co',
            'Atwima Kwanwoma Rural Bank',
            'Atwima Rural Bank',
            'Auto Parts Limited',
            'Awutu Emasa Rural Bank',
            'Ayrton Drug Manufacturing Limited',
            'Bank Of Ghana',
            'Baptist Medical Centre',
            'Bible Society Of Ghana',
            'Bonzali Rural Bank',
            'Bosomtwe Rb',
            'Bureau Of National Investigations',
            'Capital Rural Bank',
            'Cejose Wisdom Int. School',
            'Ceps',
            'Chemico Limited',
            'Chirano Gold Mines',
            'Christ Ebenezer',
            'Christian Service University College - K',
            'Church Hill Of God',
            'Cocoa Marketiing Company Ltd',
            'Cocoa Processing Comp',
            'Controller',
            'Cssvd Control Unit',
            'Dangme Rural Bank Ltd - Staff',
            'De Youngsters International School Ltd',
            'Deoke Foundation Schools',
            'Derma Area Rural Bank',
            'Dizengoff Ghana Ltd',
            'Dynamic Insurance Brokers',
            'Ebennezer Osei Brenya Enterprise',
            'Ejuraman Rural Bank',
            'Englebert School',
            'Enterprise Funeral Services',
            'Enterprise Group Ltd',
            'Enterprise Insurance',
            'Enterprise Life Reps',
            'Enterprise Life Staff',
            'Enterprise Properties Ltd',
            'Enterprise Trustees Limited',
            'Epa',
            'Eskay Therapeutics Ltd',
            'Expandable Polystyrene Products Ltd',
            'Fiagya Rural Bank Ltd',
            'Fiesta Royale Hotel',
            'Forces Pay Office',
            'Ga East Municipal Assembly',
            'Ga Rural Bank',
            'Gafco',
            'Garden City Savings & Loans Limited',
            'Gcb Bank Ltd Staff',
            'Ghacem',
            'Ghana Airport Company Ltd.',
            'Ghana Cement',
            'Ghana Chamber Of Mines',
            'Ghana Civil Aviation Authority',
            'Ghana Cocoa Board',
            'Ghana Grid Company',
            'Ghana Heavy Equipment Limited',
            'Ghana Link Network Services Ltd',
            'Ghana Maritime Authority',
            'Ghana Medical School',
            'Ghana Oil Co. Ltd',
            'Ghana Police Service',
            'Ghana Post',
            'Ghana Railway Co. Ltd',
            'Ghana Re Company Limited',
            'Ghana Standard Board',
            'Ghana Union Conference Of S.D.A.',
            'Ghana Water Company Ltd',
            'Gihoc Distilleries Company Limited',
            'Gimpa',
            'Golden Exotics Ltd',
            'Goldengate Services Ltd',
            'Grace Baptist School - Kumasi',
            'Guiness Ghana Ltd',
            'Gwcl - Accra East',
            'Gwcl - Accra West',
            'Gwcl - Ashanti North',
            'Gwcl - Ashanti Production',
            'Gwcl - Ashanti South',
            'Gwcl - Head Office',
            'Gwcl - Sunyani',
            'Gwcl - Takoradi',
            'Gwcl - Tema',
            'Gwcl Greater Accra Region Production',
            'Hobats Clinic',
            'Holiday Inn Accra',
            'Home Finance Company Bank Ltd',
            'House Of Compassion Ministries',
            'Internal Revenue Service',
            'Japan Motors Trading Company',
            'Juaben Rural Bank',
            'Jubilee Shipping Services Gh Ltd',
            'K.N.U.S.T',
            'Kaaseman Rural Bank Ltd',
            'Kessben',
            'Kingsgroup Of Companies',
            'Kintampo Health Research Centre',
            'Korle Bu Teaching Hospital',
            'Krane Contsruction Co. Ltd',
            'Krobo Danish Association',
            'Kuapa Kokoo',
            'Kumawuman Rural Bank',
            'Kumawuman Rural Bnk',
            'Kwamaman Rural Bank',
            'Labadi Beach Hotel',
            'La Nkwantanang Madina Municipal Assembly',
            'Living Star School',
            'Lordship Insurance Brokers',
            'Lower Pra',
            'Maxam Ghana Ltd',
            'Mechanical Lloyd',
            'Metromass',
            'Mobitel',
            'Mondial Veneer Gh. Ltd',
            'Mumuada Rural Bank',
            'Nafana Rural Bank Ltd',
            'National Security',
            'Nexans',
            'Nsoatreman Rural Bank',
            'Nthc Properties Ltd',
            'Nwabiagya Rural Bank',
            'Okomfo Anokye Rural Bank Staff',
            'Opportunity International S.A Savings An',
            'Parliament',
            'Pbc Ltd',
            'Pentecost University College',
            'Phyto Riker',
            'Poly Products',
            "Presbyterian Women's Center - Abokobi",
            'Prince Of Peace',
            'Quality Control Division',
            'Quality Control Division (Cocobod)',
            'Regional Maritime Academy',
            'Ridge Church School',
            'Safebound Company Limited - Tema',
            'Sakworg Company Ltd',
            'Samartex Timber And Plywood Company',
            'Sandvik Mining And Construction Limited',
            'Scanbech Ghana Ltd',
            'Sdv Ghana',
            'Securities Discount Company Limited',
            'Sekyedumase Rural Bank',
            'Sekyere Rural Bank',
            'Sgs Laboratory Services',
            'Sg Ssb',
            'Silver Star Auto Ltd',
            'Sinapi Aba Trust',
            'Sofaamy Company Limited',
            "Sos Children's Village, Ghana",
            'South Birim Rural Bank',
            'Special Ice Company Ltd',
            'Ssnit',
            'St Ambrose College',
            "St Michael's Hospital, Pramso",
            'Stellar Logistic',
            'Sunyani Cluster Icps',
            'Takoradi Flour Mill Ltd',
            'Takoradi Int. Co.',
            'Tct',
            'Tema Development Corp.',
            'Tema Parents Association School',
            'Tesano Baptist Church',
            'The Church Of Pentecost',
            'Toende Rural Bank',
            'Trasacco',
            'Type Company Ltd',
            'Unicredit Ghana Limited',
            'Unilever',
            'University College Of Education - Winneba,Kumasi',
            'University For Development Studies',
            'University Of Energy And Natural Resources (Uenr)',
            'University Of Ghana - Legon',
            'Upper Amenfi Rural Bank',
            'Upper Manya Kro Rural Bank',
            'Utrak Savings And Loans',
            'Valley View',
            'Valley View University Basic School',
            'Vat Service',
            'Volta Hotel',
            'Volta River Authority',
            'Waec - Headquarters',
            'Waec - Main',
            'Wenchi Rural Bank Ltd',
            'West Africa Mills Co. Ltd',
            'Weto Rural Bank',
            'Winners Chapel, Ghana',
            'Womens World Banking',
            'Yaakson Herbal Centre',
            'Yarsako',
          ],
          publishChange: true,
          readonly: false,
          tooltip: 'Employer Organisation',
          type: 'field',
          uid: '0932f536b3ff3a7f',
        },
        {
          content: '',
          elementType: 'input',
          errors: ['*'],
          id: 'employee-number',
          inputType: 'text',
          label: 'Employee Number',
          publishChange: true,
          readonly: false,
          type: 'field',
          uid: 'de5d87a0cd14bafb',
        },
      ],
      id: 'stop-order-details',
      label: 'Stop Order Details',
      readonly: false,
      type: 'list',
    }
  } else if (form['mobile-wallet'] === 'Y') {
    return {
      id: 'mobile-wallet-details',
      type: 'list',
      readonly: false,
      label: 'Mobile Wallet Details',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '41a5a0dbe74cdac1',
          content: '<p>Please provide your mobile wallet details</p>\n',
        },
        {
          id: 'mobile-wallet-network-provider',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Mobile Wallet Network Provider',
          uid: 'df42717555d9c5a8',
          content: '',
          publishChange: true,
          errors: ['*'],
          tooltip: 'Mobile Wallet Network Provider',
          options: [
            '',
            'Vodafone',
            'Mtn',
            'Tigo',
            'Airtel',
            'Expresso',
            'Glo Mobile',
          ],
        },
        {
          id: 'mobile-wallet-number',
          type: 'field',
          elementType: 'input',
          readonly: false,
          errors: ['*'],
          label: 'Mobile Wallet Number',
          uid: 'd99073989526da52',
          content: '',
          publishChange: true,
          tooltip: 'Mobile Wallet Number',
          inputType: 'text',
        },
      ],
    }
  } else {
    return {}
  }
}

export function formSectionFromPaymentType(form) {
  if (form['debit-order'] === 'Y') {
    return 'payment-bank-details'
  } else if (form['stop-order'] === 'Y') {
    return 'payer-employment-details'
  } else if (form['mobile-wallet'] === 'Y') {
    return 'mobile-wallet-details'
  } else {
    return null
  }
}
