/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const form = {
  'term-in-years': null,
  'inflation-protector': null,
  'investment-premium': null,
  'life-assured-identification': {
    'first-names': null,
    surname: null,
    'idpassport-number': null,
    'id-type': null,
    'date-of-birth': null,
    title: null,
    'marital-status': null,
    nationality: null,
    gender: null,
  },
  'life-assured-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
    fax: null,
    email: null,
  },
  'life-assured-postal-address': {
    addressee: null,
    'po-box-or-private-bag': null,
    'area-code': null,
    street: null,
    suburb: null,
    town: null,
    region: null,
  },
  'employment-details': {
    'employer-organisation': null,
    'employee-number': null,
    'basic-earnings': null,
    occupation: null,
    'debit-order-day': null,
    'salary-payment-frequency': null,
  },
  'spouse-identification': {
    'first-names': null,
    surname: null,
    title: null,
    'marital-status': null,
    'id-type': null,
    'idpassport-number': null,
    'date-of-birth': null,
    gender: null,
  },
  'spouse-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
  },
  'spouse-is-trustee': null,
  'trustee-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'id-type': null,
    'idpassport-number': null,
    title: null,
    relationship: null,
  },
  'trustee-contact-details': {
    'cell-phone': null,
  },
  'beneficiary-details': [],
  'payment-method-selection': {
    'debit-order': null,
    'stop-order': null,
  },
  'payment-start-date': null,
  'payment-frequency': {
    monthly: null,
    quarterly: null,
    'bi-annually': null,
    annually: null,
  },
  'payer-person': {
    myself: null,
    'my-spouse': null,
  },
  'payment-bank-details': {
    'account-holder-name': null,
    'account-type': null,
    'account-entity-name': null,
    'account-number': null,
    'account-branch-name': null,
  },
  'stop-order-details': {
    'employer-organisation': null,
    'employee-number': null,
  },
  'mobile-wallet-details': {
    'mobile-wallet-network-provider': null,
    'mobile-wallet-number': null,
  },
  'payer-person-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'id-type': null,
    'idpassport-number': null,
  },
  'payer-signature': null,
  'life-assured-payer': null,
  documents: {
    'id-document': [],
    signature: [],
  },
}
