/* eslint  react/jsx-indent: 0 */

/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'
import { setValue, clickItem } from './actions'
import AttachedText from '../components/AttachedText'
import ButtonGroup from '../containers/ButtonGroup'
import Field from '../components/Field'
import Form from '../components/Form'
import Input from '../components/Input'
import Datalist from '../components/Datalist'
import Image from '../components/Image'
import Select from '../components/Select'
import Radio from '../components/Radio'
import Checkbox from '../components/Checkbox'
import List from '../components/List'
import ListItem from '../components/ListItem'
import Table from '../components/Table'
import MarkupItem from '../components/MarkupItem'
import Text from '../components/Text'
import RadioGroup from '../components/RadioGroup'
import Accordion from '../components/Accordion'
import PhoneNumber from '../components/PhoneNumber'
import DateSelector from '../components/DateSelector'
import ImageDrop from '../components/ImageDrop'
import TemplateItem from '../components/Template'
import InteractiveCanvas from '../components/InteractiveCanvas'
import Page from '../components/Page'

const itemHasTrigger = (item) =>
  item.triggers && item.triggers.indexOf('DoubleClick') > -1

const isRadioGroup = (item) =>
  item.id.endsWith('-radio-group') &&
  item.content.every((i) => i.elementType === 'checkbox')

const checkedVal = (i, t) =>
  i.type === 'boolean' ? (t.checked ? 'TRUE' : 'FALSE') : t.checked ? 'Y' : 'N'

const inputGroupHasEditableTable = (item) => {
  switch (item.type) {
    case 'list':
      return !!item.content.find(inputGroupHasEditableTable)
    case 'table':
      return !item.content.every((row) => row.every((cell) => cell.readonly))
    default:
      return false
  }
}

const itemDescription = (item) =>
  item.type === 'component'
    ? item.component.toLowerCase().replace(/item$/, '')
    : item.type

const fieldType = React.createElement(Field).type
const dataListType = React.createElement(Datalist).type
const selectType = React.createElement(Select).type
const radioType = React.createElement(Radio).type
const checkboxType = React.createElement(Checkbox).type
const imageType = React.createElement(Image).type
const listType = React.createElement(List).type
const tableType = React.createElement(Table).type
const textItemType = React.createElement(Text).type
const markupItemType = React.createElement(MarkupItem).type
const inputGroupType = React.createElement(Form).type
const radioGroupType = React.createElement(RadioGroup).type

export function componentTypeName(c) {
  if (!c || !c.type) {
    return null
  } else if (c.type === fieldType) {
    return 'atomic'
  } else if (c.type === dataListType) {
    return 'atomic'
  } else if (c.type === selectType) {
    return 'atomic'
  } else if (c.type === radioType) {
    return 'atomic'
  } else if (c.type === checkboxType) {
    return 'atomic'
  } else if (c.type === imageType) {
    return 'image'
  } else if (c.type === listType) {
    return 'list'
  } else if (c.type === tableType) {
    return 'table'
  } else if (c.type === textItemType) {
    return 'text'
  } else if (c.type === markupItemType) {
    return 'markup'
  } else if (c.type === inputGroupType) {
    return 'input-group'
  } else if (c.type === radioGroupType) {
    return 'radio-group'
  } else {
    return null
  }
}

const omit = (item, key) => {
  const r = Object.assign(new item.constructor(), item)
  delete r[key]
  return r
}

const inputUids = (item) => {
  switch (item.type) {
    case 'component':
      return item.inputUids ? item.inputUids : []
    case 'list':
      return isRadioGroup(item)
        ? [item.uid]
        : item.content.reduce((a, i) => a.concat(inputUids(i)), [])
    case 'table':
      return item.content
        .reduce((acc, val) => acc.concat(val), [])
        .reduce((a, i) => a.concat(inputUids(i)), [])
    case 'text':
    default:
      return item.readonly ? [] : [item.uid]
  }
}

const wrapped = (item, itemIndex, hasTable, dispatch, component) =>
  item.readonly || itemIndex ? (
    component
  ) : (
    <Form
      items={inputUids(item)}
      hasTable={hasTable}
      incomplete={[]}
      onSubmit={() => {}}
    >
      {component}
    </Form>
  )

// Generate a component dynamically from an object describing the data item
export const componentFromDataItem = (
  dispatch,
  parent,
  item,
  itemIndex,
  hideLabel,
  inheritedTrigger
) => {
  if (item.invisible) {
    return null
  }
  const itemKey =
    typeof itemIndex !== 'undefined' ? `${item.id}-${itemIndex}` : `${item.id}`
  const hasTrigger = itemHasTrigger(item) || inheritedTrigger
  switch (item.type) {
    case 'markdown':
    case 'html':
    case 'script':
      return (
        <MarkupItem
          markup={item.type}
          key={itemKey}
          id={item.id}
          uid={item.uid}
          label={item.label}
          content={item.content}
        />
      )
    case 'text':
      return (
        <Text
          id={item.id}
          uid={item.uid}
          label={item.label}
          text={item.content}
          itemIndex={itemIndex}
          key={itemKey}
        />
      )
    case 'image':
      return (
        <Image
          label={item.label}
          itemIndex={itemIndex}
          key={itemKey}
          id={item.id}
          uid={item.uid}
          readonly={item.readonly}
          content={item.content}
        />
      )
    case 'list': {
      if (isRadioGroup(item)) {
        const radioGroupComponent = (
          <RadioGroup
            label={item.label
              .substring(0, item.label.length - ' Radio Group'.length)
              .trim()}
            readonly={item.content.every((i) => i.readonly)}
            itemIndex={itemIndex}
            key={itemKey}
            id={item.id}
            uid={item.uid}
            markers={item.markers}
            errors={item.errors}
            elements={item.content}
            onChange={(uid, id, value) => dispatch(setValue(uid, id, value))}
          />
        )
        return wrapped(item, itemIndex, false, dispatch, radioGroupComponent)
      }

      const listItems = item.content.map((subItem, index) => (
        <ListItem
          key={index + 1}
          readonly={subItem.readonly}
          itemIndex={index + 1}
          contains={itemDescription(subItem)}
          extraClass={subItem.extraClass}
        >
          {componentFromDataItem(
            dispatch,
            parent,
            omit(subItem, 'extraClass'),
            index + 1,
            false,
            hasTrigger
          )}
        </ListItem>
      ))

      let listComponent
      if (item.id.endsWith('-accordion')) {
        const trimmedLabel = item.label.substring(0, item.label.length - 10)
        const listId = item.id.substring(0, item.id.length - 10)
        listComponent = (
          <Accordion
            id={item.id}
            label={trimmedLabel}
            listId={listId}
            key={itemKey}
            errors={item.errors || []}
          >
            <List
              label={trimmedLabel}
              readonly={item.content.every((i) => i.readonly)}
              itemIndex={itemIndex}
              id={listId}
              uid={item.uid}
              markers={item.markers}
              errors={item.errors}
              extraClass={
                (item.extraClass ? item.extraClass + ' ' : '') +
                'accordion-content'
              }
            >
              {listItems}
            </List>
          </Accordion>
        )
      } else {
        listComponent = (
          <List
            label={item.label}
            readonly={item.content.every((i) => i.readonly)}
            itemIndex={itemIndex}
            key={itemKey}
            id={item.id}
            uid={item.uid}
            markers={item.markers}
            errors={item.errors}
            extraClass={item.extraClass}
            transposed={item.transposed}
          >
            {listItems}
          </List>
        )
      }
      return wrapped(
        item,
        itemIndex,
        inputGroupHasEditableTable(item),
        dispatch,
        listComponent
      )
    }
    case 'table': {
      return wrapped(
        item,
        itemIndex,
        inputGroupHasEditableTable(item),
        dispatch,
        <Table
          label={item.label}
          readonly={item.content.every((r) => r.every((c) => c.readonly))}
          itemIndex={itemIndex}
          key={itemKey}
          columns={
            item.content.length > 0 ? item.content[0].map((c) => c.label) : []
          }
          id={item.id}
          uid={item.uid}
          markers={item.markers}
          errors={item.errors}
        >
          {item.content.map((row, rowIdx) => (
            <tr key={rowIdx}>
              {row.map((cell, cellIdx) => (
                <td
                  key={cellIdx}
                  data-th={cell.label}
                  data-row={rowIdx + 1}
                  data-col={cellIdx + 1}
                  className={`has-${itemDescription(cell)}-item`}
                >
                  {componentFromDataItem(
                    dispatch,
                    parent,
                    cell,
                    rowIdx + 1,
                    true,
                    hasTrigger
                  )}
                </td>
              ))}
            </tr>
          ))}
        </Table>
      )
    }
    case 'component':
      switch (item.component) {
        case 'PhoneNumber':
          return wrapped(
            item,
            itemIndex,
            inputGroupHasEditableTable(item),
            dispatch,
            <PhoneNumber
              label={item.label}
              value={item.content}
              id={item.id}
              uid={item.uid}
              hideLabel={hideLabel}
              readonly={item.readonly}
              itemIndex={itemIndex}
              errors={item.errors}
              markers={item.markers}
              tooltip={item.tooltip}
              onChange={(v) => dispatch(setValue(item.uid, item.id, v))}
            />
          )
        case 'DateSelector':
          return wrapped(
            item,
            itemIndex,
            inputGroupHasEditableTable(item),
            dispatch,
            <DateSelector
              label={item.label}
              value={item.content}
              id={item.id}
              uid={item.uid}
              hideLabel={hideLabel}
              readonly={item.readonly}
              itemIndex={itemIndex}
              maxYear={item.maxYear}
              minYear={item.minYear}
              errors={item.errors}
              markers={item.markers}
              tooltip={item.tooltip}
              onChange={(v) => dispatch(setValue(item.uid, item.id, v))}
            />
          )
        case 'InteractiveCanvas':
          return wrapped(
            item,
            itemIndex,
            inputGroupHasEditableTable(item),
            dispatch,
            <InteractiveCanvas
              label={item.label}
              value={item.content}
              id={item.id}
              uid={item.uid}
              hideLabel={hideLabel}
              itemIndex={itemIndex}
              errors={item.errors}
              markers={item.markers}
              tooltip={item.tooltip}
              onChange={(v) => dispatch(setValue(item.uid, item.id, v))}
            />
          )
        case 'ImageDrop':
          return wrapped(
            item,
            itemIndex,
            inputGroupHasEditableTable(item),
            dispatch,
            <ImageDrop
              label={item.label}
              value={item.content}
              id={item.id}
              uid={item.uid}
              hideLabel={hideLabel}
              itemIndex={itemIndex}
              errors={item.errors}
              markers={item.markers}
              tooltip={item.tooltip}
              onChange={(v) => dispatch(setValue(item.uid, item.id, v))}
            />
          )
        case 'TemplateItem':
          return wrapped(
            item,
            itemIndex,
            inputGroupHasEditableTable(item),
            dispatch,
            <TemplateItem
              dispatch={dispatch}
              label={item.label}
              value={item.content}
              id={item.id}
              uid={item.uid}
              hideLabel={hideLabel}
              itemIndex={itemIndex}
              errors={item.errors}
              markers={item.markers}
              tooltip={item.tooltip}
              onChange={(v) => dispatch(setValue(item.uid, item.id, v))}
            />
          )
        default:
          console.warn(
            `${item.component} component not implemented for: ${item.label}`
          )
          return (
            <MarkupItem
              markup='html'
              itemIndex={itemIndex}
              key={itemKey}
              id={item.id}
              uid={item.uid}
              label={item.label}
              content={`${item.component} - not implemented`}
            />
          )
      }
    case 'field':
    case 'amount':
    case 'integer':
    case 'real':
      if (hideLabel && item.readonly) {
        return (
          <Field
            value={item.content}
            id={item.id}
            inputType={item.inputType}
            itemType={item.type}
            elementType={item.elementType}
            itemIndex={itemIndex}
            key={itemKey}
            tooltip={item.tooltip}
            onClick={
              hasTrigger
                ? (e) => dispatch(clickItem(item.uid, item.id, itemIndex))
                : undefined
            }
          />
        )
      }
      switch (item.elementType) {
        case 'input':
          return wrapped(
            item,
            itemIndex,
            false,
            dispatch,
            <Input
              label={item.label}
              value={item.content}
              key={item.key}
              id={item.id}
              uid={item.uid}
              readonly={item.readonly}
              inputType={item.inputType}
              itemType={item.type}
              setFocus={false}
              required={false}
              errors={item.errors || []}
              hideLabel={hideLabel}
              itemIndex={itemIndex}
              tooltip={item.tooltip}
              prefix={item.prefix}
              suffix={item.suffix}
              onChange={(e) =>
                !item.readonly &&
                dispatch(setValue(item.uid, item.id, e.target.value))
              }
            />
          )
        case 'datalist':
          return wrapped(
            item,
            itemIndex,
            false,
            dispatch,
            <Datalist
              label={item.label}
              value={item.content}
              key={item.key}
              id={item.id}
              uid={item.uid}
              readonly={item.readonly}
              inputType='text'
              itemType={item.type}
              setFocus={false}
              required={false}
              errors={item.errors || []}
              hideLabel={hideLabel}
              itemIndex={itemIndex}
              tooltip={item.tooltip}
              options={item.options}
              onChange={(e) =>
                !item.readonly &&
                dispatch(setValue(item.uid, item.id, e.target.value))
              }
            />
          )
        case 'select':
          return wrapped(
            item,
            itemIndex,
            false,
            dispatch,
            <Select
              label={item.label}
              value={item.content}
              key={item.key}
              id={item.id}
              uid={item.uid}
              readonly={item.readonly}
              inputType={item.inputType}
              itemType={item.type}
              setFocus={false}
              required={false}
              errors={item.errors || []}
              hideLabel={hideLabel}
              itemIndex={itemIndex}
              tooltip={item.tooltip}
              options={item.options}
              onChange={(e) =>
                !item.readonly &&
                dispatch(setValue(item.uid, item.id, e.target.value))
              }
            />
          )
        case 'radio':
          return wrapped(
            item,
            itemIndex,
            false,
            dispatch,
            <Radio
              label={item.label}
              value={item.content}
              key={item.key}
              id={item.id}
              uid={item.uid}
              readonly={item.readonly}
              inputType={item.inputType}
              itemType={item.type}
              setFocus={false}
              required={false}
              errors={item.errors || []}
              hideLabel={hideLabel}
              itemIndex={itemIndex}
              tooltip={item.tooltip}
              options={item.options}
              onChange={(e) =>
                !item.readonly &&
                dispatch(setValue(item.uid, item.id, e.target.value))
              }
            />
          )
        case 'checkbox':
          return wrapped(
            item,
            itemIndex,
            false,
            dispatch,
            <Checkbox
              label={item.label}
              value={item.content}
              key={item.key}
              id={item.id}
              uid={item.uid}
              readonly={item.readonly}
              inputType={item.inputType}
              itemType={item.type}
              hideLabel={hideLabel}
              required={false}
              errors={item.errors || []}
              itemIndex={itemIndex}
              tooltip={item.tooltip}
              onChange={(e) =>
                !item.readonly &&
                dispatch(
                  setValue(item.uid, item.id, checkedVal(item, e.target))
                )
              }
            />
          )
        default: {
          console.warn(`No case to handle ${item.elementType}: ${item.id}`)
          return <div id={item.id} />
        }
      }
    default: {
      console.warn(`No case to handle ${item.type}: ${item.id}`)
      return <div id={item.id} />
    }
  }
}

// Generate the page component
export const createContent = (page, dispatch) => {
  const pageItem = page.item && componentFromDataItem(dispatch, null, page.item)
  const pageItemType = componentTypeName(pageItem)
  const containerClasses = {
    modal: page.modal,
    container: !page.modal,
    'has-user-button-group':
      page.buttons.filter((b) => !b.control && !b.invisible).length > 0,
    'has-attached-text': page.text && page.text.length > 0,
  }
  if (pageItemType) {
    containerClasses[`has-${pageItemType}-item`] = true
  }
  return (
    page && (
      <Page pageClass={classNames(containerClasses)}>
        {page.text && page.text.length > 0 && <AttachedText text={page.text} />}
        {pageItem}
        {page.buttons && (
          <ButtonGroup
            buttons={page.buttons.filter((b) => !b.control && !b.invisible)}
          />
        )}
        {page.buttons && (
          <ButtonGroup
            control
            buttons={page.buttons.filter((b) => b.control && !b.invisible)}
          />
        )}
      </Page>
    )
  )
}

export default createContent
