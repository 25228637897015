/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const form = {
  'contract-details': {
    'sum-assured': null,
    'term-in-years': null,
    'update-option': null,
    'cash-bonus-premium': null,
  },
  'product-options': {
    'death-and-disability': null,
    'death-and-disability-and-illness': null,
    'death-and-disability-and-hospitalisation': null,
    'death-and-disability-and-illness-and-hospitalisation': null,
  },
  'life-assured-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
    title: null,
    'marital-status': null,
    nationality: null,
    gender: null,
  },
  'life-assured-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
    fax: null,
    email: null,
  },
  'life-assured-postal-address': {
    addressee: null,
    'po-box-or-private-bag': null,
    'area-code': null,
    street: null,
    suburb: null,
    town: null,
    region: null,
  },
  'life-assured-employment-details': {
    occupation: null,
  },
  'trustee-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
    'marital-status': null,
    relationship: null,
    title: null,
    gender: null,
  },
  'trustee-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
  },
  'parents-details': [],
  'beneficiary-details': [],
  'life-assured-general-habits': {
    'planning-other-insurance': null,
    'planning-other-insurance-details': null,
    'other-assurance-past12-months': null,
    'other-assurance-past12-months-details': null,
    'cover-exceeds-one-hundred-thousand': null,
    'average-monthly-gross-income': null,
    'total-existing-assurance-cover-in-force-on-your-life': null,
    'full-reasons-for-effecting-this-additional-cover': null,
    'risky-occupation-hobbies': null,
    'risky-occupation-hobbies-details': null,
    'refused-application-or-increased-premium': null,
    'refused-application-or-increased-premium-details': null,
    'insolvency-issues': null,
    'insolvency-issues-details': null,
    'occupation-not-clerical': null,
    'occupation-not-clerical-details': null,
    smoker: null,
    'non-smoker': null,
    'never-smoked': null,
    'years-smoking': null,
    'years-not-smoking': null,
    'cigarettes-per-day': null,
    'cigars-per-day': null,
    pipe: null,
    'medical-advice-stop-smoking': null,
    'medical-advice-reduce-drinking': null,
    'excessive-drinking': null,
    'excessive-drinking-past': null,
  },
  'life-assured-health-details': {
    'surgery-last-five-years': null,
    'medical-tests-last-five-years': null,
    'chest-pain': null,
    'high-blood-pressure': null,
    'respiratory-condition': null,
    'digestive-condition': null,
    'urinary-condition': null,
    'anxiety-epilepsy': null,
    diabetes: null,
    'cancer-or-tumors': null,
    'arthritis-osteoporosis': null,
    aids: null,
    'sexually-transmitted-disease': null,
    'taking-medication': null,
    disability: null,
    'assurance-risk': null,
    pregnant: null,
    'family-history-of-illness': null,
    'family-history-of-illness-details': null,
    'close-family-early-death': null,
    'height-in-cm': null,
    'weight-in-kg': null,
  },
  'health-concerns': [],
  'payment-method-selection': {
    'debit-order': null,
    'stop-order': null,
  },
  'payment-start-date': null,
  'payment-frequency': null,
  'life-assured-is-payer': null,
  'payer-person': {
    myself: null,
    'my-spouse': null,
  },
  'payer-person-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
    occupation: null,
    role: null,
  },
  'payment-bank-details': {
    'account-holder-name': null,
    'account-type': null,
    'account-entity-name': null,
    'account-branch-name': null,
    'account-number': null,
  },
  'payment-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
  },
  'payer-signature': null,
  documents: {
    'id-document': [],
    'passport-document': [],
  },
  'acceptance-signature': null,
  'application-acceptance-date': null,
  'application-accepted': null,
}
