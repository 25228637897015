/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import { hasValue, verify, validEmail } from '../../../../src/core/validations'
import dayjs from 'dayjs'

import {
  validNHIS,
  validVotersID,
  validSSNIT,
  validPassport,
  validDrivers,
  validID,
  validName,
  validCellnumber,
  onlyDigits,
} from '../../validations'

export const validate = (state, requiredOnly, validateOnly) => {
  if (process.env.DISABLE_VALIDATIONS === 'true') {
    return true
  }

  let validates = true
  switch (state.step) {
    case 'quotation-screen':
      validates =
        verify(
          state,
          state.form['life-assured-identification']['date-of-birth'],
          hasValue,
          !validateOnly && { id: 'date-of-birth' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 18 &&
                dayjs().diff(v, 'year') <= 100)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['gender'],
          hasValue,
          !validateOnly && { id: 'gender' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['cover-options']['ghs-1000000'] ||
            state.form['cover-options']['ghs-1500000'] ||
            state.form['cover-options']['ghs-2000000'] ||
            state.form['cover-options']['ghs-2500000'] ||
            state.form['cover-options']['ghs-3000000'] ||
            state.form['cover-options']['ghs-5000000'] ||
            state.form['cover-options']['ghs-7000000'] ||
            state.form['cover-options']['ghs-10000000'],
          hasValue,
          !validateOnly && { id: 'main-plan-type-radio-group' },
          ['*']
        ) && validates
      break

    case 'family-members-age-and-gender':
      validates =
        verify(
          state,
          state.local && state.local['age-of-member'],
          hasValue,
          !validateOnly && { id: 'age-of-member' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.local && state.local.gender,
          hasValue,
          !validateOnly && { id: 'gender' },
          ['*']
        ) && validates

      //Validate the gender for main life and spouse
      if (['Spouse'].indexOf(state.local.relationship) >= 0) {
        if (state.form['life-assured-identification']['gender'] === 'Female') {
          validates =
            verify(
              state,
              state.local.gender,
              (v) =>
                hasValue(v) &&
                (requiredOnly || ['Male'].indexOf(state.local.gender) >= 0),
              !validateOnly && { id: 'gender' },
              requiredOnly
                ? ['*']
                : [
                    'The main life gender must be opposite to the spouse gender selected.',
                  ]
            ) && validates
        }
      }

      //Validate the gender for main life and spouse
      if (['Spouse'].indexOf(state.local.relationship) >= 0) {
        if (state.form['life-assured-identification']['gender'] === 'Male') {
          validates =
            verify(
              state,
              state.local.gender,
              (v) =>
                hasValue(v) &&
                (requiredOnly || ['Female'].indexOf(state.local.gender) >= 0),
              !validateOnly && { id: 'gender' },
              requiredOnly
                ? ['*']
                : [
                    'The main life gender must be opposite to the spouse gender selected.',
                  ]
            ) && validates
        }
      }

      //Validate the gender & relationship
      if (
        [
          'Mother',
          'Mother in law',
          'Grandmother',
          'Step mother',
          'Sister',
        ].indexOf(state.local.relationship) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly || ['Female'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (
        [
          'Father',
          'Father in law',
          'Grandfather',
          'Step father',
          'Brother',
        ].indexOf(state.local.relationship) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly || ['Male'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (['Child', 'Guardian'].indexOf(state.local.relationship) >= 0) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Male', 'Female'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }
      break

    case 'personal-details':
      //Validate Field Names to only be letters
      validates =
        verify(
          state,
          state.form['life-assured-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['idpassport-number'],
          hasValue,
          !validateOnly && { id: 'idpassport-number' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification'].title,
          hasValue,
          !validateOnly && { id: 'title' },
          ['*']
        ) && validates

      //Validate the gender & title
      if (state.form['life-assured-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['life-assured-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (
        state.form['life-assured-identification']['other-nationality'] === null
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification'].ghanaian,
            hasValue,
            !validateOnly && { id: 'ghanaian' },
            ['*']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['life-assured-identification']['other-nationality'],
          (v) =>
            hasValue(v) ||
            (validates &&
              state.form['life-assured-identification'].ghanaian === 'Y'),
          !validateOnly && { id: 'other-nationality' },
          requiredOnly
            ? ['*']
            : ['If you are not a ghanaian please select your nationality']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification-type'].dvla ||
            state.form['life-assured-identification-type'].passport ||
            state.form['life-assured-identification-type']['voter-id'] ||
            state.form['life-assured-identification-type'].nhis ||
            state.form['life-assured-identification-type'].ssnit ||
            state.form['life-assured-identification-type']['nia-ghana-card'],
          hasValue,
          !validateOnly && { id: 'id-type-radio-group' },
          ['*']
        ) && validates

      //Validation for Voters ID
      if (state.form['life-assured-identification-type']['voter-id'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Voters ID Number']
          ) && validates
      }

      //Validation for NHIS
      if (state.form['life-assured-identification-type']['nhis'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid NHIS Number']
          ) && validates
      }

      //Validation for SSNIT
      if (state.form['life-assured-identification-type']['ssnit'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid SSNIT Number']
          ) && validates
      }

      //Passport
      if (state.form['life-assured-identification-type']['passport'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Passport Number']
          ) && validates
      }

      //Drivers
      if (state.form['life-assured-identification-type']['dvla'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Drivers Number']
          ) && validates
      }

      //ID
      if (
        state.form['life-assured-identification-type']['nia-ghana-card'] === 'Y'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid ID Number']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['life-assured-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-contact-details'].email,
          (v) => (requiredOnly ? hasValue(v) : validEmail(v)),
          !validateOnly && { id: 'email' },
          requiredOnly ? ['*'] : ['Please enter a valid Email address']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-employment-details'].occupation,
          hasValue,
          !validateOnly && { id: 'occupation' },
          ['*']
        ) && validates

      validates = Object.keys(state.form['life-assured-postal-address'])
        .filter((k) => ['addressee', 'street'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-postal-address'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      break

    case 'spouse-details':
      //Validate Name Fields To Not Accept Numbers
      validates =
        verify(
          state,
          state.form['spouse-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['spouse-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age - spouse has no maximum age
      validates =
        verify(
          state,
          state.form['spouse-identification']['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().diff(v, 'year') >= 18),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      //Validate the gender & title
      if (state.form['spouse-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['spouse-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['spouse-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['spouse-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      // Cell phone number
      validates =
        verify(
          state,
          state.form['spouse-identification']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      //ID
      if (state.form['spouse-identification-type']['nia-ghana-card'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid National ID Number']
          ) && validates
      }

      //Voters ID
      if (state.form['spouse-identification-type']['voter-id'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Voters ID Number']
          ) && validates
      }

      //Passport
      if (state.form['spouse-identification-type']['passport'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Passport Number']
          ) && validates
      }

      //Ssnit
      if (state.form['spouse-identification-type']['ssnit'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Ssnit Number']
          ) && validates
      }

      //Drivers
      if (state.form['spouse-identification-type']['dvla'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Drivers Number']
          ) && validates
      }

      //NHIS
      if (state.form['spouse-identification-type']['nhis'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Nhis Number']
          ) && validates
      }

      //validate the date of birth of the spouse of spouse was added on the quotation stage
      if (
        state.form['lives-assured'].find((l) => l.relationship === 'Spouse')
      ) {
        function findspouse(age) {
          return age.relationship === 'Spouse'
        }
        let s = state.form['lives-assured'].find((age) => findspouse(age))
        let ageSpouse = s['age-of-member']

        validates =
          verify(
            state,
            state.form['spouse-identification']['date-of-birth'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                dayjs().diff(v, 'year').toString() === ageSpouse),
            !validateOnly && { id: 'date-of-birth' },
            requiredOnly
              ? ['*']
              : [
                  'The date of birth does not match previously selected age for spouse',
                ]
          ) && validates
      }
      break

    case 'trustee-details':
      validates =
        verify(
          state,
          state.form['trustee-identification']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      validates = [
        'first-names',
        'surname',
        'date-of-birth',
        'relationship',
        'title',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.form['trustee-identification'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      //Validate Field Names to only be letters
      validates =
        verify(
          state,
          state.form['trustee-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['trustee-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age
      validates =
        verify(
          state,
          state.form['trustee-identification']['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().diff(v, 'year') >= 18),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The minimum allowable age for this product is 18.']
        ) && validates

      //Validate the gender & relationship
      if (
        ['Adv', 'Dr', 'Prof', 'Rev'].indexOf(
          state.form['trustee-identification']['title']
        ) >= 0
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Daughter',
                  'Estate',
                  'Grandmother',
                  'Mother',
                  'Mother-In-Law',
                  'Sister',
                  'Spouse',
                  'Testament',
                  'Trust',
                  'Brother',
                  'Father',
                  'Son',
                  'Father-In-Law',
                  'Grandfather',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      if (
        ['Miss', 'Mrs', 'Ms'].indexOf(
          state.form['trustee-identification']['title']
        ) >= 0
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Daughter',
                  'Estate',
                  'Grandmother',
                  'Mother',
                  'Mother-In-Law',
                  'Sister',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      if (state.form['trustee-identification']['title'] === 'Mr') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Estate',
                  'Father',
                  'Son',
                  'Father-In-Law',
                  'Grandfather',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      break

    case 'additional-lives':
      validates = state.form['lives-assured'].reduce((valid, life, counter) => {
        valid = ['first-names', 'surname', 'date-of-birth'].reduce(
          (valid, field) => valid && hasValue(life[field]),
          valid
        )
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['date-of-birth'],
              (v) =>
                dayjs().diff(v, 'year').toString() === life['age-of-member'],
              !validateOnly && { id: `date-of-birth-${counter}` },
              [
                `Date of birth does not match indicated age of ${life['age-of-member']} for ${life['relationship']}`,
              ]
            ) && valid
        }
        if (!requiredOnly && valid && life['relationship'] === 'Spouse') {
          let spousedob = state.form['spouse-identification']['date-of-birth']
          valid =
            verify(
              state,
              life['date-of-birth'],
              (v) => v === spousedob,
              !validateOnly && { id: `date-of-birth-${counter}` },
              [
                `Date of birth does not match indicated age of ${life['relationship']}`,
              ]
            ) && valid
        }
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['first-names'],
              (v) => (requiredOnly ? hasValue(v) : validName(v)),
              !validateOnly && { id: `first-names-${counter}` },
              requiredOnly ? ['*'] : ['Please enter a valid first-name']
            ) && valid
        }
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['surname'],
              (v) => (requiredOnly ? hasValue(v) : validName(v)),
              !validateOnly && { id: `surname-${counter}` },
              requiredOnly ? ['*'] : ['Please enter a valid surname']
            ) && valid
        }
        return valid
      }, validates)
      break

    case 'payment-details':
      validates =
        verify(
          state,
          state.form['debit-order'] ||
            state.form['stop-order'] ||
            state.form['mobile-wallet'],
          hasValue,
          !validateOnly && { id: 'payment-method-radio-group' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-start-date'],
          hasValue,
          !validateOnly && { id: 'payment-start-date' },
          ['*']
        ) && validates
      break

    case 'debit-order-details':
      validates = Object.keys(state.form['payment-bank-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-bank-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates

      //Validate field name to have no numbers
      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-holder-name'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'account-holder-name' },
          requiredOnly ? ['*'] : ['Please remove all numbers in the field']
        ) && validates

      //VALIDATE ACCOUNT NUMBER LENGTH BASED ON THE ACCOUNT ENTITY NAME CHOSEN
      if (!requiredOnly) {
        //10 DIGITS
        if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Absa (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Zenith Bank') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 10 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //9 / 10 / 11 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Bank Of Africa' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) =>
                (v.length === 9 || v.length === 10 || v.length === 11) &&
                onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Access Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Consolidated Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Ecobank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Fidelity Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Atlantic Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Bank Nigeria' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Gcb Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'National Investment Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Omni Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Prudential Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Republic Bank (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Stanbic Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Standard Chartered Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Universal Merchant Bank (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 13 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //14 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'United Bank For Africa (gh) Ltd' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 14 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //12 / 13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Cal Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Societe General Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Guaranty Trust (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => (v.length === 12 || v.length === 13) && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //16 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Agricultural Development Bank' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 16 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
      }
      break

    case 'stop-order-details':
      validates = Object.keys(state.form['payment-employment-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-employment-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break

    case 'mobile-wallet-details':
      validates = Object.keys(state.form['mobile-wallet-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['mobile-wallet-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'acceptance-screen':
      validates =
        verify(
          state,
          state.form['acceptance-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break
  }
  if (!requiredOnly && !validates) {
    state.local = state.local || {}
    state.local.failed_validation = true
  }
  return validates
}
