/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'
import ValidationErrors from './ValidationErrors'

const useTooltips = process.env.APPLICATION_USE_TOOLTIPS === 'true'

const Select = ({
  value,
  id,
  label,
  readonly,
  setFocus,
  required,
  errors,
  hideLabel,
  itemIndex,
  tooltip,
  options,
  onChange
}) => {
  const elementId = hideLabel && itemIndex ? `${id}-${itemIndex}` : id
  const tooltipOption = !useTooltips && tooltip && (
    <option value='' key='-1' disabled hidden>
      {tooltip}
    </option>
  )
  return (
    <div
      className={classNames({
        editable: !readonly,
        field: true,
        'has-tooltip': useTooltips && tooltip,
        'has-value': value.length > 0,
        select: true
      })}
    >
      <label
        htmlFor={elementId}
        className={classNames({ hidden: hideLabel, label: true })}
      >
        {label}
      </label>
      <select
        autoFocus={setFocus}
        required={required}
        className={classNames({
          disabled: readonly,
          'has-error': errors && errors.length > 0,
          'is-required': required,
          placeholder: value.length === 0 && !useTooltips && tooltip
        })}
        name={label}
        id={elementId}
        placeholder={useTooltips ? null : tooltip}
        value={value}
        onChange={onChange}
      >
        {tooltipOption}
        {options.map((option, i) => (
          <option value={option} key={i}>
            {option}
          </option>
        ))}
      </select>
      {errors && errors.length > 0 && <ValidationErrors errors={errors} />}
    </div>
  )
}

export default Select
