/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */

import {
  pages,
  parentDetailsItem,
  beneficiaryItem,
  benefitSplitItem,
  healthDetailsItem,
  healthDetailsPrefix,
  quotationPanel,
  acceptancePaymentDetails,
  ageRange,
} from './family-income-protection/pages'
import { form } from './family-income-protection/form'
import { rates } from './family-income-protection/rates'
import { validate } from './family-income-protection/validations'
import { emptyPhoneNumber } from '../../../src/core/validations'
import {
  createItem,
  updateItemIn,
  createButton,
  findItemsIn,
} from '../../../src/core/dataitems'
import { cancelProcess, completeProcess } from '../../../src/core/actions'
import { original } from 'immer'
import { createSelector } from 'reselect'
import shortid from 'shortid'
import dayjs from 'dayjs'

const dateFormat = 'D MMMM YYYY'

const amountString = (f) =>
  f
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/, '')

const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

let productOptions = {
  'death-and-disability': 'option-1',
  'death-and-disability-and-illness': 'option-2',
  'death-and-disability-and-hospitalisation': 'option-3',
  'death-and-disability-and-illness-and-hospitalisation': 'option-4',
}

const parentPremium = (age) => {
  if (age < 31) {
    return 0
  } else if (age < 36) {
    return 10.07
  } else if (age < 41) {
    return 10.88
  } else if (age < 46) {
    return 11.15
  } else if (age < 51) {
    return 11.6
  } else if (age < 56) {
    return 12.3
  } else if (age < 61) {
    return 14.14
  } else if (age < 66) {
    return 18.5
  } else if (age < 71) {
    return 25.13
  } else if (age < 75) {
    return 26.35
  }
  return 0
}

const paymentFrequencyLookup = {
  'Bi-Annual': 6,
  Monthly: 1,
  Quarterly: 3,
  Annual: 12,
}

const premiumAmount = createSelector(
  (form) => form['life-assured-identification']['date-of-birth'],
  (form) => form['life-assured-identification']['gender'],
  (form) => form['contract-details']['sum-assured'],
  (form) => form['product-options'],
  (form) => form['contract-details']['cash-bonus-premium'],
  (form) => form['parents-details'],
  (form) => form['payment-frequency'],
  (dob, gender, benefit, product, cashBonus, parents, paymentFrequency) => {
    const age = dayjs().diff(dob, 'year')
    const benefitAmt = parseFloat(benefit)
    const cashAmt = parseFloat(cashBonus)
    const productName = Object.keys(product).find((p) => product[p] === 'Y')
    const pfmultiplier =
      paymentFrequency in paymentFrequencyLookup
        ? paymentFrequencyLookup[paymentFrequency]
        : 1
    if (!isNaN(benefitAmt) && productName && !isNaN(age) && gender) {
      const benefitMult = benefitAmt > 100000 ? 50000 : 5000
      const rateKey = `${productOptions[productName]}:${
        Math.floor(benefitAmt / benefitMult) * benefitMult
      }:${age}`
      if (rateKey in rates[gender]) {
        const parentsAmt = parents.reduce(
          (s, p) => s + parentPremium(dayjs().diff(p['date-of-birth'], 'year')),
          0
        )
        return amountString(
          (1 +
            rates[gender][rateKey] +
            (isNaN(cashAmt) || cashAmt < 10.0 || cashAmt > 50 ? 0 : cashAmt) +
            parentsAmt) *
            pfmultiplier
        )
      }
    }
    return '0.00'
  }
)

const benefitRate = createSelector(
  (form) => form['contract-details']['sum-assured'],
  (sumAssured) => {
    const s = parseFloat(sumAssured)
    return isNaN(s) || s < 5000 || s > 500000 ? '0.00' : amountString(s)
  }
)

const isTrusteeABeneficiary = createSelector(
  (form) => form['trustee-identification']['first-names'],
  (form) => form['trustee-identification'].surname,
  (form) => form['trustee-identification']['date-of-birth'],
  (form) => form['beneficiary-details'],
  (fn, sn, dob, bs) =>
    fn &&
    sn &&
    dob &&
    bs.reduce(
      (result, b) =>
        result ||
        (b['first-names'] === fn &&
          b.surname === sn &&
          b['date-of-birth'] === dob),
      false
    )
)

const updateBeneficiarySplit = createSelector(
  (form) => form['beneficiary-details'],
  (beneficiaries) => {
    if (beneficiaries.length > 0) {
      const split = Math.floor(100 / beneficiaries.length)
      let difference = 100 - split * beneficiaries.length
      for (let i = 0; i < beneficiaries.length; i++) {
        if (difference > 0) {
          beneficiaries[i]['benefit-split'] = amountString(split + 1)
          difference -= 1
        } else {
          beneficiaries[i]['benefit-split'] = amountString(split)
        }
      }
    }
    return beneficiaries
  }
)

const healthConcernCount = (form) =>
  Object.keys(form['life-assured-health-details']).reduce(
    (t, f) => t + (form['life-assured-health-details'][f] === 'Yes' ? 1 : 0),
    0
  )

const createHealthDetailsPage = (state) => {
  const maxConcerns = Math.min(4, healthConcernCount(original(state.form)))
  updateItemIn(
    state.page.item,
    { id: 'health-descriptions', type: 'list' },
    {
      content: healthDetailsPrefix
        .concat(healthDetailsItem(maxConcerns))
        .concat([quotationPanel]),
    }
  )
  if (state.form['health-concerns'].length !== maxConcerns) {
    while (state.form['health-concerns'].length > maxConcerns) {
      state.form['health-concerns'].pop()
    }
    for (let i = state.form['health-concerns'].length; i < maxConcerns; i++) {
      state.form['health-concerns'].push({
        complaints: null,
        'complaint-description': null,
        'medical-condition-date': null,
      })
    }
  }
}

const generateAcceptancePage = (state) => {
  // Update the reference number
  updateItemIn(
    state.page.item,
    { id: 'contract-id' },
    { content: state.form['reference-number'] }
  )
  // Update all sections
  Object.keys(state.form).forEach((section) => {
    findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
      Object.keys(state.form[section]).forEach((field) => {
        if (state.form[section][field]) {
          updateItemIn(
            sItem,
            { id: field },
            { content: state.form[section][field] }
          )
        }
      })
    })
  })
  // Add parents details
  updateItemIn(
    state.page.item,
    { id: 'parents-details', type: 'table' },
    { content: (state.form['parents-details'] || []).map(parentDetailsItem) }
  )
  // Add beneficiary details
  updateItemIn(
    state.page.item,
    { id: 'beneficiary-details', type: 'table' },
    { content: (state.form['beneficiary-details'] || []).map(beneficiaryItem) }
  )
  // Update the payment details
  let paymentDetails = acceptancePaymentDetails(state.form)
  if (paymentDetails) {
    updateItemIn(
      state.page.item,
      { id: 'payment-details', type: 'list' },
      paymentDetails
    )
  }
}

const setPageItemFromState = (state) => {
  validate(state, state.local && state.local.failed_validation ? false : true)

  switch (state.step) {
    case 'quotation-screen':
      // Date of birth
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
          maxYear: dayjs().year() - 18,
          minYear: dayjs().year() - 60,
        }
      )
      // Gender
      updateItemIn(
        state.page.item,
        { id: 'gender' },
        { content: state.form['life-assured-identification']['gender'] || '' }
      )
      // Contract Details
      Object.keys(state.form['contract-details'])
        .filter((field) => field !== 'term-in-years')
        .forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form['contract-details'][field] || '' }
          )
        )
      // Term in years
      if (state.form['life-assured-identification']['date-of-birth']) {
        const maxTerm =
          65 -
          dayjs().diff(
            state.form['life-assured-identification']['date-of-birth'],
            'year'
          )
        const minTerm = Math.min(7, maxTerm)
        updateItemIn(
          state.page.item,
          { id: 'term-in-years' },
          {
            content: state.form['contract-details']['term-in-years'] || '',
            options: ageRange(minTerm, maxTerm),
          }
        )
      }

      // Product Options
      Object.keys(state.form['product-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['product-options'][field] || '' }
        )
      )
      // Gender
      updateItemIn(
        state.page.item,
        { id: 'term-in-years' },
        { content: state.form['contract-details']['term-in-years'] || '' }
      )
      break
    case 'personal-details':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-employment-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      if (state.local && state.local.clearFieldErrors) {
        updateItemIn(
          state.page.item,
          { id: state.local.clearFieldErrors },
          { errors: [] }
        )
        delete state.local.clearFieldErrors
      }
      break
    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(state.page.item, { id: field }, (item) => {
              item.content = state.form[section][field] || ''
              item.readonly = state.form['spouse-is-trustee'] === 'Y'
              if (item.readonly) {
                if (item.elementType === 'select') {
                  item.elementType = 'input'
                  item.inputType = 'text'
                } else if (item.type === 'component') {
                  item.type = 'field'
                }
              }
              return item
            })
          )
        }
      )
      break
    case 'parents-details':
      const numParents = (state.form['parents-details'] || []).length

      updateItemIn(
        state.page.item,
        { id: 'parents-details', type: 'table' },
        {
          invisible: numParents < 1,
          content: (state.form['parents-details'] || []).map(parentDetailsItem),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-constant', uid: '247ee06fbedee297' }, // Add parent
        { invisible: numParents >= 2 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'add-parent':
            button.invisible = numParents >= 6
            break
        }
      })
      break

    case 'add-parent':
    case 'edit-parent':
      Object.keys(state.local || {})
        .filter((field) => field !== 'date-of-birth')
        .forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            {
              content: state.local[field] || '',
            }
          )
        )
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content: state.local['date-of-birth'] || '',
          maxYear: dayjs().year() - 31,
          minYear: dayjs().year() - 74,
        }
      )
      break

    case 'beneficiary-details':
      const numBeneficiaries = (state.form['beneficiary-details'] || []).length
      updateItemIn(
        state.page.item,
        { id: 'beneficiary-details', type: 'table' },
        {
          invisible: numBeneficiaries < 1,
          content: (state.form['beneficiary-details'] || []).map(
            beneficiaryItem
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '6c60b1c422ae8373' }, // Add Trustee as beneficiary
        {
          invisible: isTrusteeABeneficiary(state.form) || numBeneficiaries >= 9,
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '145b199f80e0c8c3' }, // Add a different beneficiary
        { invisible: numBeneficiaries >= 9 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'update-benefit-split-percentage':
            button.invisible = numBeneficiaries < 2
            break
          case 'add-another-beneficiary':
            button.invisible = numBeneficiaries >= 9
            break
          case 'add-trustee':
            button.invisible =
              isTrusteeABeneficiary(state.form) || numBeneficiaries >= 9
            break
        }
      })
      break
    case 'add-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break
    case 'edit-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break
    case 'update-beneficiary-split-percentage':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break
    case 'medical-underwriting':
      Object.keys(state.form['life-assured-general-habits']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-general-habits'][field] || '' }
        )
      )
      ;[
        'planning-other-insurance',
        'other-assurance-past12-months',
        'risky-occupation-hobbies',
        'refused-application-or-increased-premium',
        'insolvency-issues',
        'occupation-not-clerical',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { id: `${field}-details` },
          {
            invisible:
              state.form['life-assured-general-habits'][field] !== 'Yes',
          }
        )
      })
      ;[
        '5f8b25ed4415c897', //  'average-monthly-gross-income-list',
        'beaacad6ef6645ee', // 'total-existing-assurance-cover-in-force-on-your-life-list',
        '91930796f1d9da82', // 'full-reasons-for-effecting-this-additional-cover-list',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { uid: field, type: 'list' },
          {
            invisible:
              state.form['life-assured-general-habits'][
                'cover-exceeds-one-hundred-thousand'
              ] !== 'Yes',
          }
        )
      })

      updateItemIn(
        state.page.item,
        { id: 'years-smoking' },
        {
          invisible: state.form['life-assured-general-habits'].smoker !== 'Y',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'smoking-details', type: 'list' },
        {
          invisible: state.form['life-assured-general-habits'].smoker !== 'Y',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'years-not-smoking' },
        {
          invisible:
            state.form['life-assured-general-habits']['non-smoker'] !== 'Y',
        }
      )

      break
    case 'medical-declaration':
      Object.keys(state.form['life-assured-health-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-health-details'][field] || '' }
        )
      )
      updateItemIn(
        state.page.item,
        { id: 'family-history-of-illness-details' },
        {
          invisible:
            state.form['life-assured-health-details'][
              'family-history-of-illness'
            ] !== 'Yes',
        }
      )

      // TODO
      break
    case 'health-descriptions':
      state.form['health-concerns'].forEach((c, i) =>
        Object.keys(c).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: `${field}-${i + 1}` },
            { content: c[field] || '' }
          )
        )
      )
      break
    case 'payment-details':
      // Cover level
      Object.keys(state.form['payment-method-selection']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-method-selection'][field] || '' }
        )
      )
      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'payment-frequency' },
        { content: state.form['payment-frequency'] || '' }
      )

      updateItemIn(
        state.page.item,
        { uid: 'ce35234c9c489acc' },
        {
          invisible:
            state.form['life-assured-identification']['marital-status'] !==
            'Married',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'myself' },
        { content: state.form['payer-person']['myself'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'my-spouse' },
        { content: state.form['payer-person']['my-spouse'] || '' }
      )
      break
    case 'spouse-details-for-payment':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.local[field] || '' }
        )
      )
      break
    case 'debit-order-details':
      Object.keys(state.form['payment-bank-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['payment-bank-details'][field] || '',
          }
        )
      )
      break
    case 'stop-order-details':
      Object.keys(state.form['payment-employment-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['payment-employment-details'][field] || '',
          }
        )
      )
      break
    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break
  }

  // Persistent elements
  updateItemIn(
    state.page.item,
    { id: 'benefit-rate' },
    { content: benefitRate(state.form) }
  )

  // Update the quotation
  updateItemIn(
    state.page.item,
    { id: 'premium' },
    { content: premiumAmount(state.form) }
  )
}

// Change the page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}

// Create the state for a new process
const createState = (state) => {
  state.currentProcess = {} // Create a new process
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  ) // Overall Process state
  state.currentProcess.step = 'quotation-screen' // Current process step
  state.currentProcess.page = pages['quotation-screen'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

// processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break
      case 'quotation-screen':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              if (premiumAmount(state.form) < 50) {
                changePage(state, 'premium-amount')
              } else {
                changePage(state, 'parents-details')
              }
            }
            break
        }
        break
      case 'premium-amount':
        switch (button.id) {
          case 'done':
            changePage(state, 'quotation-screen')
            break
        }
        break
      case 'personal-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'parents-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'trustee-details')
            }
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              state.form['payer-person']['myself'] = 'Y'
            }
            break
        }
        break
      case 'parents-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-screen')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'personal-details')
            }
            break
          case 'add-parent':
            if (state.form['parents-details'].length < 2) {
              changePage(state, 'add-parent')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break
        }
        break
      case 'add-parent':
        switch (button.id) {
          case 'back':
            changePage(state, 'parents-details')
            break
          case 'add':
            if (validate(state)) {
              if (state.form['parents-details'].length < 6) {
                state.form['parents-details'].push({
                  'first-names': state.local['first-names'],
                  surname: state.local.surname,
                  'date-of-birth': state.local['date-of-birth'],
                  'parent-role': state.local['parent-role'] || null,
                  gender: state.local.gender,
                })
              }
              changePage(state, 'parents-details')
            }
            break
        }
      case 'edit-parent':
        switch (button.id) {
          case 'update':
            if (validate(state)) {
              if (
                state.local.index >= 0 &&
                state.local.index <= state.form['parents-details'].length
              ) {
                Object.keys(
                  state.form['parents-details'][state.local.index - 1]
                ).forEach(
                  (k) =>
                    (state.form['parents-details'][state.local.index - 1][k] =
                      state.local[k])
                )
              }
              changePage(state, 'parents-details')
            }
            break
          case 'remove-parent':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['parents-details'].length
            ) {
              const old = original(state.form['parents-details'])
              state.form['parents-details'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
            }
            changePage(state, 'parents-details')
            break
        }
        break
      case 'trustee-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'personal-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'beneficiary-details')
            }
            break
        }
        break

      case 'beneficiary-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'trustee-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'medical-underwriting')
            }
            break
          case 'add-trustee':
            if (
              state.form['beneficiary-details'].length < 9 &&
              !isTrusteeABeneficiary(original(state.form))
            ) {
              state.form['beneficiary-details'].push({
                'first-names':
                  state.form['trustee-identification']['first-names'],
                surname: state.form['trustee-identification'].surname,
                'benefit-split': null,
                role: 'Cessionary',
                gender: state.form['trustee-identification'].gender,
                'date-of-birth':
                  state.form['trustee-identification']['date-of-birth'],
              })
              updateBeneficiarySplit(state.form)
            }
            break

          case 'add-another-beneficiary':
            if (state.form['beneficiary-details'].length < 9) {
              changePage(state, 'add-beneficiary')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
                'role',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break

          case 'update-benefit-split-percentage':
            changePage(state, 'update-beneficiary-split-percentage')
            state.local = state.form['beneficiary-details'].reduce(
              (l, b, c) => {
                l[`beneficiary-split-${c + 1}`] = b['benefit-split'] || ''
                return l
              },
              {}
            )
            updateItemIn(
              state.page.item,
              { id: 'update-benefit-split-percentage', type: 'list' },
              {
                content:
                  state.form['beneficiary-details'].map(benefitSplitItem),
              }
            )
            break
        }
        break
      case 'add-beneficiary':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'add':
            if (validate(state)) {
              if (state.form['beneficiary-details'].length < 9) {
                state.form['beneficiary-details'].push({
                  'first-names': state.local['first-names'],
                  surname: state.local.surname,
                  'benefit-split': null,
                  role: state.local.role,
                  gender: state.local.gender,
                  'date-of-birth': state.local['date-of-birth'],
                })
                updateBeneficiarySplit(state.form)
              }
              changePage(state, 'beneficiary-details')
            }
            break
        }
        break
      case 'edit-beneficiary':
        switch (button.id) {
          case 'update':
            if (validate(state)) {
              if (
                state.local.index >= 0 &&
                state.local.index <= state.form['beneficiary-details'].length
              ) {
                Object.keys(
                  original(
                    state.form['beneficiary-details'][state.local.index - 1]
                  )
                ).forEach(
                  (k) =>
                    (state.form['beneficiary-details'][state.local.index - 1][
                      k
                    ] = state.local[k])
                )
              }
              changePage(state, 'beneficiary-details')
            }
            break
          case 'delete-beneficiary':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['beneficiary-details'].length
            ) {
              const old = original(state.form['beneficiary-details'])
              state.form['beneficiary-details'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
              updateBeneficiarySplit(state.form)
            }
            changePage(state, 'beneficiary-details')
            break
        }
        break
      case 'update-beneficiary-split-percentage':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'update':
            if (validate(state)) {
              state.form['beneficiary-details'] = original(
                state.form['beneficiary-details']
              ).map((b, c) => {
                b['benefit-split'] = amountString(
                  parseFloat(state.local[`beneficiary-split-${c + 1}`])
                )
                return b
              })
              changePage(state, 'beneficiary-details')
            }
        }
        break
      case 'medical-underwriting':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'medical-declaration')
            }
            break
        }
        break
      case 'medical-declaration':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-underwriting')
            break
          case 'next':
            if (validate(state)) {
              if (healthConcernCount(original(state.form)) > 0) {
                changePage(state, 'health-descriptions')
                createHealthDetailsPage(state)
              } else {
                state.form['health-concerns'].length = 0
                changePage(state, 'payment-details')
              }
            }
            break
        }
        break
      case 'health-descriptions':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-underwriting')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details')
            }
        }
        break
      case 'payment-details':
        switch (button.id) {
          case 'back':
            if (healthConcernCount(original(state.form)) > 0) {
              changePage(state, 'health-descriptions')
              createHealthDetailsPage(state)
            } else {
              changePage(state, 'medical-underwriting')
            }
            break
          case 'next':
            if (validate(state)) {
              if (
                state.form['payer-person']['my-spouse'] === 'Y' &&
                state.form['payer-person-identification']['first-names'] ===
                  null
              ) {
                changePage(state, 'spouse-details-for-payment')
                state.local = Object.keys(
                  state.form['payer-person-identification']
                ).reduce((l, k) => {
                  l[k] = null
                  return l
                }, {})
              } else if (
                state.form['payer-person-identification']['first-names'] ||
                state.form['payer-person']['myself'] === 'Y'
              ) {
                if (
                  state.form['payment-method-selection']['debit-order'] === 'Y'
                ) {
                  changePage(state, 'debit-order-details')
                } else if (
                  state.form['payment-method-selection']['stop-order'] === 'Y'
                ) {
                  changePage(state, 'stop-order-details')
                }
              }
            }
            if (state.form['payer-person']['myself'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['life-assured-identification'][field])
              )
            }
            state.form['life-assured-is-payer'] =
              state.form['payer-person']['myself']
            break
        }
        break
      case 'spouse-details-for-payment':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              state.form['life-assured-is-payer'] = 'N'
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.local[field])
              )
              changePage(state, 'payment-details')
            }
        }
        break
      case 'debit-order-details':
      case 'stop-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
        }
        break
      case 'upload-documents':
        switch (button.id) {
          case 'upload-id-document':
          case 'upload-passport-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'acceptance-screen')
              generateAcceptancePage(state)
            }
            break
          case 'back':
            if (state.form['payment-method-selection']['debit-order'] === 'Y') {
              changePage(state, 'debit-order-details')
            } else if (
              state.form['payment-method-selection']['stop-order'] === 'Y'
            ) {
              changePage(state, 'stop-order-details')
            }
            state.form['payer-signature'] = null
            break
        }
        break

      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            if (state.form.documents[state.local.document].length > 0) {
              changePage(state, 'acceptance-screen')
              generateAcceptancePage(state)
            } else {
              changePage(state, 'upload-documents')
            }
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'submit':
            if (validate(state)) {
              state.form['application-accepted'] = 'Y'
              state.form['application-acceptance-date'] =
                dayjs().format('YYYYMMDD')
              state.form.premium = premiumAmount(state.form)
              changePage(state, 'completed')
            }
            break
          case 'back':
            changePage(state, 'upload-documents')
        }
        break
      case 'completed':
        asyncDispatch(completeProcess(state.form['reference-number']))
        break
    }
    setPageItemFromState(state)
  }
}

// processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-screen':
      if (data.id in state.form['life-assured-identification']) {
        state.form['life-assured-identification'][data.id] = data.value
        dirty = true
      } else if (data.id in state.form['contract-details']) {
        state.form['contract-details'][data.id] = data.value
        dirty = true
      } else if (data.id in state.form['product-options']) {
        state.form['product-options'][data.id] = data.value
        dirty = true
      } else {
        console.warn(`No form element found for ${data.id} [${data.value}]`)
      }
      break
    case 'personal-details':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-employment-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      if (['work-phone', 'home-phone', 'fax'].indexOf(data.id) >= 0) {
        if (emptyPhoneNumber(data.value)) {
          state.form['life-assured-contact-details'][data.id] = null
          state.local = state.local || {}
          state.local.clearFieldErrors = data.id
          dirty = true
        }
      }
      break
    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      break
    case 'add-parent':
    case 'edit-parent':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break
    case 'add-beneficiary':
    case 'edit-beneficiary':
    case 'update-beneficiary-split-percentage':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break
    case 'medical-underwriting':
      if (data.id in state.form['life-assured-general-habits']) {
        state.form['life-assured-general-habits'][data.id] = data.value
        dirty = true
      }
      break
    case 'medical-declaration':
      if (data.id in state.form['life-assured-health-details']) {
        state.form['life-assured-health-details'][data.id] = data.value
        dirty = true
      }
      break
    case 'health-descriptions':
      let id = data.id.split('-')
      const index = parseInt(id.pop())
      if (!isNaN(index) && index <= state.form['health-concerns'].length) {
        id = id.join('-')
        if (id in state.form['health-concerns'][index - 1]) {
          state.form['health-concerns'][index - 1][id] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break
    case 'payment-details':
      switch (data.id) {
        case 'debit-order':
        case 'stop-order':
          state.form['payment-method-selection'][data.id] = data.value
          dirty = true
          break
        case 'payment-start-date':
          const date = dayjs(data.value)
          state.form['payment-start-date'] = date.isValid()
            ? date.format('YYYYMMDD')
            : null
          dirty = true
          break
        case 'payment-frequency':
          state.form['payment-frequency'] = data.value
          dirty = true
          break
        case 'myself':
        case 'my-spouse':
          state.form['payer-person'][data.id] = data.value
          dirty = true
          break
      }
      break
    case 'spouse-details-for-payment':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break
    case 'debit-order-details':
      if (data.id in state.form['payment-bank-details']) {
        state.form['payment-bank-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break
    case 'stop-order-details':
      if (data.id in state.form['payment-employment-details']) {
        state.form['payment-employment-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break
    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

const processItemClick = (state, data, asyncDispatch) => {
  let dirty = false
  switch (state.step) {
    case 'parents-details':
      if (
        data.index > 0 &&
        data.index <= state.form['parents-details'].length
      ) {
        changePage(state, 'edit-parent')
        state.local = Object.assign(
          {},
          original(state.form['parents-details'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
    case 'beneficiary-details':
      if (
        data.index > 0 &&
        data.index <= state.form['beneficiary-details'].length
      ) {
        changePage(state, 'edit-beneficiary')
        state.local = Object.assign(
          {},
          original(state.form['beneficiary-details'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

export default {
  createState,
  processButton,
  processChange,
  processItemClick,
}
