/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'

const Accordion = ({ id, label, listId, errors, children }) => (
  <div
    id={id}
    className={classNames({
      accordion: true,
      'has-errors': (errors || []).length > 0,
    })}
  >
    <div className='accordion-header'>
      <span className='accordion-name'>{label}</span>
      {(errors || []).length > 0 && (
        <span data-error-count={errors.length} className='accordion-errors' />
      )}
    </div>
    <input type='checkbox' htmlFor={listId} />
    {children}
  </div>
)

export default Accordion
