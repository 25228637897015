/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import { createActions } from 'redux-actions'

/* Action creators */
export const {
  setOnlineStatus,
  clickButton,
  clickItem,
  changeRoute,
  setValue,
  startProcess,
  postponeProcess,
  cancelProcess,
  resumeProcess,
  completeProcess,
  setAgentDetails,
  checkCompleted,
  uploadCompletedProcess,
  getUploadedStatus,
  setUploadedStatus,
  recordEvent,
  installPrompt,
  exportData
} = createActions({
  SET_ONLINE_STATUS: (online = true) => ({ online }),
  CLICK_BUTTON: button => ({ button }),
  CLICK_ITEM: (uid, id, index) => ({ item: true, uid, id, index }),
  CHANGE_ROUTE: route => ({ route }),
  SET_VALUE: (uid, id, value) => ({ uid, id, value }),
  START_PROCESS: product => ({ product }),
  POSTPONE_PROCESS: id => ({ id }),
  CANCEL_PROCESS: id => ({ id }),
  RESUME_PROCESS: id => ({ id }),
  COMPLETE_PROCESS: reference => ({ reference }),
  SET_AGENT_DETAILS: contractId => ({ contractId }),
  CHECK_COMPLETED: () => ({}),
  UPLOAD_COMPLETED_PROCESS: (ref, status, info) => ({ ref, status, info }),
  GET_UPLOADED_STATUS: (ref, requestId) => ({ ref, requestId }),
  SET_UPLOADED_STATUS: (ref, processId) => ({ ref, processId }),
  RECORD_EVENT: (event, value) => ({ event, value }),
  INSTALL_PROMPT: prompt => ({ prompt }),
  EXPORT_DATA: () => ({})
})
