/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */

import {
  pages,
  healthDetailsPrefix,
  healthDetailsItem,
  quotationPanel,
  beneficiaryItem,
  benefitSplitItem,
  familyMemberLabels,
  familyMemberOptions,
  createFamilyMember,
  additionalLife,
  familyMemberItem,
  familyMemberTablePrefix,
  buttonFromLifeAssured,
  idFromLifeAssured,
  paymentDetailsSummary,
} from './family-income-security-plan/pages'
import { cancelProcess, completeProcess } from '../../../src/core/actions'
import shortid from 'shortid'
import { form } from './family-income-security-plan/form'
import {
  updateItemIn,
  createItem,
  findItemsIn,
  createButton,
  slugify,
} from '../../../src/core/dataitems'
import dayjs from 'dayjs'
import { original } from 'immer'
import { createSelector } from 'reselect'
import { validate } from './family-income-security-plan/validations'
import { rates } from './family-income-security-plan/rates'

// Create the state for a new process
const createState = (state) => {
  state.currentProcess = {} // Create a new process
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  ) // Overall Process state
  state.currentProcess.step = 'quotation-page' // Current process step
  state.currentProcess.page = pages['quotation-page'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

const dateFormat = 'D MMMM YYYY'

const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

const amountString = (f) =>
  f
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/, '')

const healthConcernCount = (form) =>
  [
    'chest-pain',
    'high-blood-pressure',
    'respiratory-condition',
    'digestive-condition',
    'urinary-condition',
    'anxiety-epilepsy',
    'diabetes',
    'cancer-or-tumors',
    'arthritis-osteoporosis',
    'aids',
    'sexually-transmitted-disease',
    'taking-medication',
    'disability',
  ].reduce(
    (t, f) =>
      t +
      (form['life-assured-health-details'][f] === 'Yes' ||
      form['life-assured-health-details'][f] === 'Y'
        ? 1
        : 0),
    0
  )

const createHealthDetailsPage = (state) => {
  const maxConcerns = Math.min(4, healthConcernCount(original(state.form)))
  updateItemIn(
    state.page.item,
    { id: 'health-descriptions', type: 'list' },
    {
      content: healthDetailsPrefix
        .concat(healthDetailsItem(maxConcerns))
        .concat([quotationPanel]),
    }
  )
  if (state.form['health-concerns'].length !== maxConcerns) {
    while (state.form['health-concerns'].length > maxConcerns) {
      state.form['health-concerns'].pop()
    }
    for (let i = state.form['health-concerns'].length; i < maxConcerns; i++) {
      state.form['health-concerns'].push({
        complaints: null,
        'complaint-description': null,
        'medical-condition-date': null,
        'health-results': null,
      })
    }
  }
}

//Spouse should be opposite to main life gender
const spouseGender = (gender) => {
  switch (gender) {
    case 'Male':
      return 'Female'
    case 'Female':
      return 'Male'
    default:
      return null
  }
}

const updateBeneficiarySplit = createSelector(
  (form) => form['beneficiary-details'],
  (beneficiaries) => {
    if (beneficiaries.length > 0) {
      const split = Math.floor(100 / beneficiaries.length)
      let difference = 100 - split * beneficiaries.length
      for (let i = 0; i < beneficiaries.length; i++) {
        if (difference > 0) {
          beneficiaries[i]['benefit-split'] = amountString(split + 1)
          difference -= 1
        } else {
          beneficiaries[i]['benefit-split'] = amountString(split)
        }
      }
    }
    return beneficiaries
  }
)

const isTrusteeABeneficiary = createSelector(
  (form) => form['trustee-identification']['first-names'],
  (form) => form['trustee-identification'].surname,
  (form) => form['trustee-identification']['date-of-birth'],
  (form) => form['beneficiary-details'],
  (fn, sn, dob, bs) =>
    fn &&
    sn &&
    dob &&
    bs.reduce(
      (result, b) =>
        result ||
        (b['first-names'] === fn &&
          b.surname === sn &&
          b['date-of-birth'] === dob),
      false
    )
)

const optionsForRelationship = (opts, relationship) => {
  if (relationship in opts) {
    return opts[relationship].options || []
  } else {
    const alt = Object.values(opts).find((v) => v.rename === relationship)
    return alt ? alt.options || [] : null
  }
}

const policyTermYears = createSelector(
  (form) => form['policy-term']['term-ten'],
  (form) => form['policy-term']['term-fifteen'],
  (ten, fifteen) => (ten === 'Y' ? 10 : fifteen === 'Y' ? 15 : 0)
)

//Change actual mapping from the "displayed online value"
const hospitalUnits = {
  50: '1',
  80: '2',
  100: '3',
  120: '4',
  150: '5',
  200: '6',
}

/*----
Map the data captured in the [lives-assured] table to their separate relationship tables,
to match the online form mappings for webservices
----*/
//Map Parent data to Parent-Details table
const maptoParent = createSelector(
  (form) => form['lives-assured'],
  (parents) => {
    const items = parents.filter(
      (item) => ['Mother', 'Father'].indexOf(item.relationship) >= 0
    )
    return items
  }
)

//Map Step Parents when Joint Cover is chosen
const maptoStepParent = createSelector(
  (form) => form['lives-assured'],
  (parents) => {
    const items = parents.filter(
      (item) =>
        ['Mother', 'Father', 'Mother in law', 'Father in law'].indexOf(
          item.relationship
        ) >= 0
    )
    return items
  }
)

//Map Children data to Children-Details table
const maptoChild = createSelector(
  (form) => form['lives-assured'],
  (child) => {
    const items = child.filter(
      (item) => item.relationship.indexOf('Child') !== -1
    )
    return items
  }
)

const isLifeABeneficiary = (life, bs) =>
  life['first-names'] &&
  life.surname &&
  life['date-of-birth'] &&
  bs.reduce(
    (result, b) =>
      result ||
      (b['first-names'] === life['first-names'] &&
        b.surname === life.surname &&
        b['date-of-birth'] === life['date-of-birth']),
    false
  )
const mainLifeAge = createSelector(
  (form) => form['life-assured-identification']['date-of-birth'],
  (dob) => dayjs().diff(dob, 'year')
)
const female_age = createSelector(
  (form) => form['life-assured-identification']['gender'],
  (form) => form['life-assured-identification']['date-of-birth'],
  (form) => form['spouse-identification']['date-of-birth'],
  (maingender, mdob, sdob) => {
    const maindob = dayjs().diff(mdob, 'year')
    const spousedob = dayjs().diff(sdob, 'year')
    if (maingender === 'Female') {
      return maindob
    } else if (maingender === 'Male') {
      return spousedob
    }
  }
)
const male_age = createSelector(
  (form) => form['life-assured-identification']['gender'],
  (form) => form['life-assured-identification']['date-of-birth'],
  (form) => form['spouse-identification']['date-of-birth'],
  (maingender, mdob, sdob) => {
    const maindob = dayjs().diff(mdob, 'year')
    const spousedob = dayjs().diff(sdob, 'year')
    if (maingender === 'Male') {
      return maindob
    } else if (maingender === 'Female') {
      return spousedob
    }
  }
)
//PREMIUM CALCULATIONS
//AGE INDEX
var female_age_index = function (age) {
  if (66 <= age && age <= 70) {
    return 10
  } else if (61 <= age && age <= 65) {
    return 9
  } else if (56 <= age && age <= 60) {
    return 8
  } else if (51 <= age && age <= 55) {
    return 7
  } else if (46 <= age && age <= 50) {
    return 6
  } else if (41 <= age && age <= 45) {
    return 5
  } else if (36 <= age && age <= 40) {
    return 4
  } else if (31 <= age && age <= 35) {
    return 3
  } else if (26 <= age && age <= 30) {
    return 2
  } else if (18 <= age && age <= 25) {
    return 1
  } else {
    return -1
  }
}
var male_age_index = function (age) {
  if (66 <= age && age <= 70) {
    return 144
  } else if (61 <= age && age <= 65) {
    return 128
  } else if (56 <= age && age <= 60) {
    return 112
  } else if (51 <= age && age <= 55) {
    return 96
  } else if (46 <= age && age <= 50) {
    return 80
  } else if (41 <= age && age <= 45) {
    return 64
  } else if (36 <= age && age <= 40) {
    return 48
  } else if (31 <= age && age <= 35) {
    return 32
  } else if (26 <= age && age <= 30) {
    return 16
  } else if (18 <= age && age <= 25) {
    return 0
  } else {
    return -1
  }
}

//Total Benefit Rate Calculation
const benefitRatelives = createSelector(
  (form) => form['main-life-funeral-benefit'],
  (form) => form['lives-assured'],
  (mainCover, lives) =>
    lives.reduce(
      (m, l) => m + parseFloat(l['funeral-cover-benefit'] || '0.0'),
      parseFloat(mainCover || '0.0')
    )
)
//TERM IN YEARS
const term_index = createSelector(
  (form) => form['term-in-years'],
  (term) => {
    const index = parseFloat(term)
    if (index === 5) {
      return 1
    } else if (index === 6) {
      return 2
    } else if (index === 7) {
      return 3
    } else if (index === 8) {
      return 4
    } else if (index === 9) {
      return 5
    } else if (index === 10) {
      return 6
    } else if (index === 11) {
      return 7
    } else if (index === 12) {
      return 8
    } else if (index === 13) {
      return 9
    } else if (index === 14) {
      return 10
    } else if (index === 15) {
      return 11
    } else if (index === 16) {
      return 12
    } else if (index === 17) {
      return 13
    } else if (index === 18) {
      return 14
    } else if (index === 19) {
      return 15
    } else if (index === 20) {
      return 16
    } else {
      return 0
    }
  }
)
const getbenefit_option = createSelector(
  (form) => form['benefit-option'],
  (form) => form['standalone-options']['death-sum-assured'],
  (form) => form['standalone-options']['total-personal-disability-sum-assured'],
  (form) => form['standalone-options']['illness-sum-assured'],
  (form) => form['accelerated-options']['death-and-total-personal-disability'],
  (form) => form['accelerated-options']['death-and-critical-illness'],
  (form) =>
    form['accelerated-options'][
      'death-and-total-disability-and-critical-illness'
    ],
  (
    benefit,
    s_death,
    s_disability,
    s_illness,
    a_disability,
    a_illness,
    a_death
  ) => {
    let death = !isNaN(parseInt(s_death)) ? parseInt(s_death) : 0
    let disability = !isNaN(parseInt(s_disability)) ? parseInt(s_disability) : 0
    let illness = !isNaN(parseInt(s_illness)) ? parseInt(s_illness) : 0
    let totalstandalone = death + disability + illness
    if (benefit === 'Standalone') {
      if (death > 0 && disability === 0 && illness === 0) {
        return 'option 1'
      } else if (disability > 0 && death === 0 && illness === 0) {
        return 'option 2'
      } else if (illness > 0 && death == 0 && disability === 0) {
        return 'option 3'
      } else if (
        death !== 0 &&
        disability !== 0 &&
        illness === 0 &&
        totalstandalone > 0
      ) {
        return 'option 7'
      } else if (
        death !== 0 &&
        disability === 0 &&
        illness !== 0 &&
        totalstandalone > 0
      ) {
        return 'option 8'
      } else if (
        death === 0 &&
        disability !== 0 &&
        illness !== 0 &&
        totalstandalone > 0
      ) {
        return 'option 9'
      } else if (
        death !== 0 &&
        disability !== 0 &&
        illness !== 0 &&
        totalstandalone > 0
      ) {
        return 'option 10'
      }
      return 0
    }
    if (benefit === 'Accelerated') {
      if (a_disability === 'Y') {
        return 'option 4'
      } else if (a_illness === 'Y') {
        return 'option 5'
      } else if (a_death === 'Y') {
        return 'option 6'
      }
      return 0
    }
  }
)
const get_sumassured = createSelector(
  getbenefit_option,
  (form) => form['standalone-options']['death-sum-assured'],
  (form) => form['standalone-options']['total-personal-disability-sum-assured'],
  (form) => form['standalone-options']['illness-sum-assured'],
  (form) => form['accelerated-cover-sum-assured'],
  (form) => form['educational-support-benefit']['educational-support-benefit'],
  (option, option1, option2, option3, option_accelerated, eduprem) => {
    const stand1 = !isNaN(parseInt(option1)) ? parseInt(option1) : 0
    const stand2 = !isNaN(parseInt(option2)) ? parseInt(option2) : 0
    const stand3 = !isNaN(parseInt(option3)) ? parseInt(option3) : 0
    const educational = !isNaN(parseInt(eduprem)) ? parseInt(eduprem) : 0
    let standcombined = educational + stand1 + stand2 + stand3
    const acc1 = !isNaN(parseInt(option_accelerated))
      ? parseInt(option_accelerated)
      : 0
    if (option === 'option 1') {
      return stand1
    } else if (option === 'option 2') {
      return stand2
    } else if (option === 'option 3') {
      return stand3
    } else if (
      option === 'option 7' ||
      option === 'option 8' ||
      option === 'option 9' ||
      option === 'option 10'
    ) {
      return standcombined + educational
    } else if (
      option === 'option 4' ||
      option === 'option 5' ||
      option === 'option 6'
    ) {
      return acc1 + educational
    }
    return '0.00'
  }
)
const vlookupFactorMain = createSelector(
  term_index,
  getbenefit_option,
  (term, benefit) => {
    if (benefit === 'option 1') {
      return term + 0
    } else if (benefit === 'option 2') {
      return term + 16
    } else if (benefit === 'option 3') {
      return term + 32
    } else if (benefit === 'option 6') {
      return term + 48
    } else if (benefit === 'option 4') {
      return term + 64
    } else if (benefit === 'option 5') {
      return term + 80
    } else if (benefit === 'option 7') {
      //option 1 + 2
      return term + 0
    } else if (benefit === 'option 8') {
      //option 1 + 3
      return term + 0
    } else if (benefit === 'option 9') {
      //option 2 + 3
      return term + 0
    } else if (benefit === 'option 10') {
      //option 1 + 2 + 3
      return term + 0
    }
    return 0
  }
)
const vlookupFactorJoint = createSelector(
  term_index,
  male_age,
  getbenefit_option,
  (term, age, benefit) => {
    const ageindex = male_age_index(age)
    if (benefit === 'option 1') {
      return term + ageindex
    } else if (benefit === 'option 2') {
      return term + ageindex
    } else if (benefit === 'option 3') {
      return term + ageindex
    } else if (benefit === 'option 4') {
      return term + ageindex
    } else if (benefit === 'option 5') {
      return term + ageindex
    } else if (benefit === 'option 6') {
      return term + ageindex
    } else if (benefit === 'option 7') {
      return term + ageindex //option 1 + 2
    } else if (benefit === 'option 8') {
      return term + ageindex //option 1 + 3
    } else if (benefit === 'option 9') {
      return term + ageindex //option 2 + 3
    } else if (benefit === 'option 10') {
      return term + ageindex //option 1 + 2 + 3
    }
  }
)
const sumassuredIdx = createSelector(get_sumassured, (sum) => {
  if (sum) {
    const div = 1000.0
    let mult = sum / div
    return mult
  } else {
    return 0
  }
})
//TWO MAIN BASE RATES FOR MAIN LIFE ONLY
var main_life_incap_payment_rate = function (
  benefit_option,
  term,
  main_life_age,
  multfactor,
  multfactor1,
  multfactor2,
  multfactor3
) {
  switch (benefit_option) {
    //function is called female age but it uses the main life age regardless of the gender
    case 'option 1':
      return (
        main_incap_rates[term - 1][female_age_index(main_life_age) - 1] *
        multfactor
      )
    case 'option 2':
      return (
        main_incap_rates[term - 1][female_age_index(main_life_age) - 1] *
        multfactor
      )
    case 'option 3':
      return (
        main_incap_rates[term - 1][female_age_index(main_life_age) - 1] *
        multfactor
      )
    case 'option 4':
      return (
        main_incap_rates[term - 1][female_age_index(main_life_age) - 1] *
        multfactor
      )
    case 'option 5':
      return (
        main_incap_rates[term - 1][female_age_index(main_life_age) - 1] *
        multfactor
      )
    case 'option 6':
      return (
        main_incap_rates[term - 1][female_age_index(main_life_age) - 1] *
        multfactor
      )
    case 'option 7':
      return (
        main_incap_rates[term - 1][female_age_index(main_life_age) - 1] *
          (multfactor1 / 1000.0) +
        main_incap_rates[term + 15][female_age_index(main_life_age) - 1] *
          (multfactor2 / 1000.0)
      )
    case 'option 8':
      return (
        main_incap_rates[term - 1][female_age_index(main_life_age) - 1] *
          (multfactor1 / 1000.0) +
        main_incap_rates[term + 31][female_age_index(main_life_age) - 1] *
          (multfactor3 / 1000.0)
      )
    case 'option 9':
      return (
        main_incap_rates[term + 15][female_age_index(main_life_age) - 1] *
          (multfactor2 / 1000.0) +
        main_incap_rates[term + 31][female_age_index(main_life_age) - 1] *
          (multfactor3 / 1000.0)
      )
    case 'option 10':
      return (
        main_incap_rates[term - 1][female_age_index(main_life_age) - 1] *
          (multfactor1 / 1000.0) +
        main_incap_rates[term + 15][female_age_index(main_life_age) - 1] *
          (multfactor2 / 1000.0) +
        main_incap_rates[term + 31][female_age_index(main_life_age) - 1] *
          (multfactor3 / 1000.0)
      )
    default:
      return 0
  }
}

var main_life_hcb_payment_rate = function (term, age) {
  return main_hbc_rates[term - 1][female_age_index(age) - 1]
}
const mainLifePremium = createSelector(
  getbenefit_option,
  vlookupFactorMain,
  mainLifeAge,
  sumassuredIdx,
  (form) => form['main-life-funeral-benefit'], //main cover
  (form) => form['rider-options'], //optional benefits
  (form) => form['hospitalisation-units'], //hospital premium
  (form) => form['funeral-cover-for-main-life'], //cover details
  (form) => form['double-accident-benefit'],
  policyTermYears,
  (form) => form['standalone-options']['death-sum-assured'],
  (form) => form['standalone-options']['total-personal-disability-sum-assured'],
  (form) => form['standalone-options']['illness-sum-assured'],
  (
    benefit,
    term,
    age,
    multfactor,
    cover,
    ben,
    hcb,
    isCovered,
    addben,
    pterm,
    multfactor1,
    multfactor2,
    multfactor3
  ) => {
    let rate = 0
    let products = []
    let addedrate = 0
    if (benefit && age && term && multfactor) {
      rate =
        1 +
        main_life_incap_payment_rate(
          benefit,
          term,
          age,
          multfactor,
          multfactor1,
          multfactor2,
          multfactor3
        )
      if (pterm && age && cover && isCovered !== 'N') {
        products = [{ p: 'ffun', a: cover }]
        if (ben['memorial-one-year'] === 'Y') {
          products.push({ p: 'mem', a: cover })
        }
        if (ben['memorial-seven-day'] === 'Y') {
          products.push({ p: 'mem_7', a: cover })
        }
        if (ben['memorial-forty-day'] === 'Y') {
          products.push({ p: 'mem_40', a: cover })
        }
        if (addben === 'Y') {
          products.push({ p: 'adb', a: cover })
        }
        if (hcb && hcb.length > 0) {
          products.push({ p: 'hcb', a: hcb })
        }
        addedrate = products.reduce((s, p) => {
          const k = `${p.p}:${pterm}:${p.a}:${age}`
          return s + (k in rates ? rates[k] : 0)
        }, 0)
      }
      if (products && products.length > 0 && isCovered === 'Y') {
        return rate + addedrate
      } else {
        return rate
      }
    } else {
      return '0.00'
    }
  }
)
const mainhcbPrem = createSelector(
  (form) => form['hospitilization-cover-main-life-only'],
  term_index,
  mainLifeAge,
  (form) => form['hospitalisation-units'], //hospital premium
  (hcb, term, age, hospitalunit) => {
    if (hcb === 'Y' && hospitalunit) {
      return (main_life_hcb_payment_rate(term, age) * hospitalunit) / 1000
    } else {
      return 0
    }
  }
)

//TWO JOINT LIFE BASE RATES FOR ADDING A SPOUSE
var joint_life_incap_payment_rate = function (
  benefit_option,
  term,
  female_age,
  multfactor,
  multfactor1,
  multfactor2,
  multfactor3
) {
  switch (benefit_option) {
    case 'option 1':
      return (
        joint_life_1_rates[term - 1][female_age_index(female_age) - 1] *
        multfactor
      )
    case 'option 2':
      return (
        joint_life_2_rates[term - 1][female_age_index(female_age) - 1] *
        multfactor
      )
    case 'option 3':
      return (
        joint_life_3_rates[term - 1][female_age_index(female_age) - 1] *
        multfactor
      )
    case 'option 4':
      return (
        joint_life_4_rates[term - 1][female_age_index(female_age) - 1] *
        multfactor
      )
    case 'option 5':
      return (
        joint_life_5_rates[term - 1][female_age_index(female_age) - 1] *
        multfactor
      )
    case 'option 6':
      return (
        joint_life_6_rates[term - 1][female_age_index(female_age) - 1] *
        multfactor
      )
    case 'option 7':
      return (
        joint_life_1_rates[term - 1][female_age_index(female_age) - 1] *
          (multfactor1 / 1000.0) +
        joint_life_2_rates[term - 1][female_age_index(female_age) - 1] *
          (multfactor2 / 1000.0)
      )
    case 'option 8':
      return (
        joint_life_1_rates[term - 1][female_age_index(female_age) - 1] *
          (multfactor1 / 1000.0) +
        joint_life_3_rates[term - 1][female_age_index(female_age) - 1] *
          (multfactor3 / 1000.0)
      )
    case 'option 9':
      return (
        joint_life_2_rates[term - 1][female_age_index(female_age) - 1] *
          (multfactor2 / 1000.0) +
        joint_life_3_rates[term - 1][female_age_index(female_age) - 1] *
          (multfactor3 / 1000.0)
      )
    case 'option 10':
      return (
        joint_life_1_rates[term - 1][female_age_index(female_age) - 1] *
          (multfactor1 / 1000.0) +
        joint_life_2_rates[term - 1][female_age_index(female_age) - 1] *
          (multfactor2 / 1000.0) +
        joint_life_3_rates[term - 1][female_age_index(female_age) - 1] *
          (multfactor3 / 1000.0)
      )
    default:
      return 0
  }
}
var joint_life_hcb_payment_rate = function (hcbLookup, female_age) {
  return joint_hbc_rates[hcbLookup - 1][female_age_index(female_age) - 1]
}
const jointLifePremium = createSelector(
  getbenefit_option,
  vlookupFactorJoint,
  female_age,
  sumassuredIdx,
  (form) => form['covered-details']['joint-life'],
  mainLifeAge,
  (form) => form['main-life-funeral-benefit'], //main cover
  (form) => form['rider-options'], //optional benefits
  (form) => form['hospitalisation-units'], //hospital premium
  (form) => form['funeral-cover-for-main-life'], //cover details
  (form) => form['double-accident-benefit'],
  policyTermYears,
  (form) => form['standalone-options']['death-sum-assured'],
  (form) => form['standalone-options']['total-personal-disability-sum-assured'],
  (form) => form['standalone-options']['illness-sum-assured'],
  (
    benefit,
    term,
    femaleage,
    multfactor,
    jointlife,
    mainage,
    cover,
    ben,
    hcb,
    isCovered,
    addben,
    pterm,
    multfactor1,
    multfactor2,
    multfactor3
  ) => {
    let rate = 0
    let products = []
    let addedrate = 0
    if (jointlife === 'Y' && benefit && femaleage < 71 && term && multfactor) {
      rate =
        1 +
        joint_life_incap_payment_rate(
          benefit,
          term,
          femaleage,
          multfactor,
          multfactor1,
          multfactor2,
          multfactor3
        )
      if (pterm && mainage && cover && isCovered !== 'N') {
        products = [{ p: 'ffun', a: cover }]
        if (ben['memorial-one-year'] === 'Y') {
          products.push({ p: 'mem', a: cover })
        }
        if (ben['memorial-seven-day'] === 'Y') {
          products.push({ p: 'mem_7', a: cover })
        }
        if (ben['memorial-forty-day'] === 'Y') {
          products.push({ p: 'mem_40', a: cover })
        }
        if (addben === 'Y') {
          products.push({ p: 'adb', a: cover })
        }
        if (hcb && hcb.length > 0) {
          products.push({ p: 'hcb', a: hcb })
        }
        addedrate = products.reduce((s, p) => {
          const k = `${p.p}:${pterm}:${p.a}:${mainage}`
          return s + (k in rates ? rates[k] : 0)
        }, 0)
      }
      if (products && products.length > 0 && isCovered === 'Y') {
        return rate + addedrate
      } else {
        return rate
      }
    } else {
      return '0.00'
    }
  }
)
const jointhcbPrem = createSelector(
  (form) => form['hospitilization-cover-joint-life'],
  vlookupFactorJoint,
  male_age,
  (form) => form['hospitalisation-units'], //hospital premium
  (hcb, hcbterm, age, hospitalunit) => {
    if (hcb === 'Y' && hospitalunit) {
      const jointhcb_rate =
        (joint_life_hcb_payment_rate(hcbterm, age) * hospitalunit) / 1000
      return jointhcb_rate
    } else {
      return 0
    }
  }
)
//ADDITIONAL RATES TO BE ADDED TO THE TOTAL PREMIUM IF THESE ARE ADDED
const educational_premium = createSelector(
  (form) => form['educational-support-benefit']['educational-support-benefit'],
  (form) => form['educational-support-benefit']['educational-support-term'],
  (premium, term) => {
    if (!isNaN(premium) && !isNaN(term)) {
      var index = 12
      let premben = parseInt(premium)
      let tdx = parseInt(term)
      let div = index * tdx
      var end_rate = premben / div
      return end_rate
    } else {
      0
    }
  }
)

//TOTAL PREMIUM
export const totalPremium = createSelector(
  (form) => form['life-assured-identification']['marital-status'],
  (form) => form['main-life-funeral-benefit'], //main cover
  mainLifePremium,
  mainhcbPrem,
  jointLifePremium,
  jointhcbPrem,
  educational_premium,
  (form) => form['cash-bonus'], //amount
  (form) => form['retirement-premium'], //amount
  (form) => form['covered-details']['main-life-only'],
  (form) => form['lives-assured'],
  policyTermYears,
  (form) => form['payment-frequency'],
  (
    status,
    mainfuneral,
    mainprem,
    mainhcb,
    jointprem,
    jointhcb,
    educational,
    cbpremium,
    retirementpremium,
    cover,
    lives,
    pterm,
    paymentFrequency
  ) => {
    const rprem = parseInt(retirementpremium)
    const cbp = parseInt(cbpremium)
    const maintotal = []
    const jointtotal = []
    let sum = 0
    let jsum = 0
    const pfkey = Object.keys(paymentFrequency).find(
      (key) => paymentFrequency[key] === 'Y'
    )
    const pfmultiplier =
      pfkey in paymentFrequencyLookup ? paymentFrequencyLookup[pfkey] : 1
    const mil = mainfuneral
      ? 'Main'
      : (mostImportantLife(lives) || {}).relationship
    if ((status !== 'Married' || cover === 'Y') && mainprem > 0) {
      if (!isNaN(mainprem) && mainprem > 0) {
        maintotal.push(mainprem)
      }
      if (mainhcb > 0) {
        maintotal.push(mainhcb)
      }
      if (educational > 0) {
        maintotal.push(educational)
      }
      if (!isNaN(cbp) && cbp > 0) {
        maintotal.push(cbp)
      }
      if (!isNaN(rprem) && rprem > 0) {
        maintotal.push(rprem)
      }
      if (maintotal.length > 0) {
        for (let m = 0; m < maintotal.length; m++) {
          sum += maintotal[m]
        }
      }
      const premiums = lives.map((l) => {
        const products = [l.relationship === mil ? 'ffun' : 'ffun_add']
        if (l['memorial-one-year'] === 'Y') {
          products.push('mem')
        }
        if (l['memorial-seven-day'] === 'Y') {
          products.push('mem_7')
        }
        if (l['memorial-forty-day'] === 'Y') {
          products.push('mem_40')
        }
        return products.reduce((s, p) => {
          const k = `${p}:${pterm}:${l['funeral-cover-benefit']}:${l['age-of-member']}`
          return s + (k in rates ? rates[k] : 0)
        }, 0)
      })
      const premium = premiums.reduce((s, p) => s + p, isNaN(sum) ? 0 : sum)
      return premium * pfmultiplier
    } else if (status === 'Married' && jointprem > 0) {
      if (!isNaN(jointprem) && jointprem > 0) {
        jointtotal.push(jointprem)
      }
      if (jointhcb > 0) {
        jointtotal.push(jointhcb)
      }
      if (mainhcb > 0) {
        jointtotal.push(mainhcb)
      }
      if (educational > 0) {
        jointtotal.push(educational)
      }
      if (!isNaN(cbp) && cbp > 0) {
        jointtotal.push(cbp)
      }
      if (!isNaN(rprem) && rprem > 0) {
        jointtotal.push(rprem)
      }
      if (jointtotal.length > 0) {
        for (let m = 0; m < jointtotal.length; m++) {
          jsum += jointtotal[m]
        }
      }
      const jpremiums = lives.map((l) => {
        const products = [l.relationship === mil ? 'ffun' : 'ffun_add'] //check the relationships
        if (l['memorial-one-year'] === 'Y') {
          products.push('mem')
        }
        if (l['memorial-seven-day'] === 'Y') {
          products.push('mem_7')
        }
        if (l['memorial-forty-day'] === 'Y') {
          products.push('mem_40')
        }
        return products.reduce((s, p) => {
          const k = `${p}:${pterm}:${l['funeral-cover-benefit']}:${l['age-of-member']}`
          return s + (k in rates ? rates[k] : 0)
        }, 0)
      })
      const jpremium = jpremiums.reduce((s, p) => s + p, isNaN(jsum) ? 0 : jsum)
      return jpremium * pfmultiplier
    } else {
      return '0.00'
    }
  }
)

const mostImportantLife = (lives) =>
  ['Child', 'Sister', 'Brother', 'Mother', 'Father'].reduce(
    (f, r) => f || lives.find((l) => l.relationship === r),
    null
  )

//FUNERAL BENEFIT CALCULATION
const paymentFrequencyLookup = {
  'bi-annual': 6,
  annual: 12,
  quarterly: 3,
  monthly: 1,
}

const generateAcceptancePage = (state) => {
  // Update the reference number
  updateItemIn(
    state.page.item,
    { id: 'contract-id' },
    { content: state.form['reference-number'] }
  )
  // Update all sections
  Object.keys(state.form).forEach((section) => {
    findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
      Object.keys(state.form[section]).forEach((field) => {
        if (state.form[section][field]) {
          updateItemIn(
            sItem,
            { id: field },
            { content: state.form[section][field] }
          )
        }
      })
    })
  })
  // Add beneficiary details
  updateItemIn(
    state.page.item,
    { id: 'beneficiary-details', type: 'table' },
    { content: (state.form['beneficiary-details'] || []).map(beneficiaryItem) }
  )
  // Add additional lives
  updateItemIn(
    state.page.item,
    { id: 'other-lives-assured-funeral-cover', type: 'table' },
    {
      invisible: state.form['lives-assured'].length === 0,
      content: (state.form['lives-assured'] || []).map(additionalLife),
    }
  )

  // Update the payment details
  let paymentDetails = paymentDetailsSummary(state.form)
  if (paymentDetails) {
    updateItemIn(
      state.page.item,
      { id: 'payment-details', type: 'list' },
      paymentDetails
    )
  }
}

const setPageItemFromState = (state) => {
  validate(state, state.local && state.local.failed_validation ? false : true) //comment this validate line out if validations have not been added yet
  switch (state.step) {
    case 'quotation-page':
      //Recursively update a deeply nested object using a copy on write approach
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'date-of-birth-spouse' },
        {
          content: state.form['spouse-identification']['date-of-birth'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'gender' },
        {
          content: state.form['life-assured-identification']['gender'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'marital-status' },
        {
          content:
            state.form['life-assured-identification']['marital-status'] || '',
        }
      )

      //Hide Joint Life
      updateItemIn(
        state.page.item,
        { uid: 'cfb7d7b9fce9448b', type: 'list' },
        {
          invisible:
            state.form['life-assured-identification']['marital-status'] !==
            'Married',
        }
      )

      //Hide Spouse Details if main life is only covered
      updateItemIn(
        state.page.item,
        { uid: '3e032cfcc085d3ed', type: 'list' },
        {
          invisible: !(
            state.form['covered-details']['joint-life'] === 'Y' &&
            state.form['life-assured-identification']['marital-status'] ===
              'Married'
          ),
        }
      )

      //Select the Benefit Option
      updateItemIn(
        state.page.item,
        { id: 'benefit-option' },
        { content: state.form['benefit-option'] || '' }
      )

      // Hide elements when a benefit option has not been chosen yet.
      updateItemIn(
        state.page.item,
        { uid: '17f5a69ffc982802', type: 'list' },
        { invisible: state.form['benefit-option'] !== 'Standalone' }
      )
      updateItemIn(
        state.page.item,
        { uid: '409fdfaa45405865', type: 'list' },
        { invisible: state.form['benefit-option'] !== 'Accelerated' }
      )

      Object.keys(state.form['covered-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['covered-details'][field] || '' }
        )
      )

      //Standalone
      Object.keys(state.form['standalone-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['standalone-options'][field] || '' }
        )
      )

      //Accelerated
      Object.keys(state.form['accelerated-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['accelerated-options'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { id: 'accelerated-cover-sum-assured' },
        {
          content: state.form['accelerated-cover-sum-assured'] || '',
        }
      )

      //Select the term in years
      updateItemIn(
        state.page.item,
        { id: 'term-in-years' },
        { content: state.form['term-in-years'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'update-option' },
        { content: state.form['update-option'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'funeral-cover-for-main-life' },
        { content: state.form['funeral-cover-for-main-life'] || '' }
      )

      break

    case 'additional-benefits':
      updateItemIn(
        state.page.item,
        { id: 'hospitilization-cover-main-life-only' },
        { content: state.form['hospitilization-cover-main-life-only'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'hospitilization-cover-main-life-only-1' },
        { content: state.form['hospitilization-cover-main-life-only'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'hospitilization-cover-joint-life' },
        { content: state.form['hospitilization-cover-joint-life'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'no-option' },
        { content: state.form['no-option'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'hospitalisation-units' },
        { content: state.form['hospitalisation-units'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'educational-support-benefit', type: 'amount' },
        {
          content:
            state.form['educational-support-benefit'][
              'educational-support-benefit'
            ] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'educational-support-term' },
        {
          content:
            state.form['educational-support-benefit'][
              'educational-support-term'
            ] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'retirement-premium', type: 'amount' },
        { content: state.form['retirement-premium'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'main-life-funeral-benefit', type: 'amount' },
        { content: state.form['main-life-funeral-benefit'] || '' }
      )

      Object.keys(state.form['policy-term']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['policy-term'][field] || '' }
        )
      )

      Object.keys(state.form['rider-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['rider-options'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { id: 'double-accident-benefit' },
        { content: state.form['double-accident-benefit'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'cash-bonus', type: 'amount' },
        { content: state.form['cash-bonus'] || '' }
      )

      updateItemIn(
        state.page.item,
        { uid: 'e54476f4baccb9e7', type: 'list' },
        { invisible: !(state.form['funeral-cover-for-main-life'] === 'Y') }
      )

      updateItemIn(
        state.page.item,
        { uid: '5e2b367a83f688f9', type: 'list' },
        {
          invisible:
            state.form['life-assured-identification']['marital-status'] !==
            'Married',
        }
      )

      updateItemIn(
        state.page.item,
        { uid: '1f3dcdbd8870ab17', type: 'list' },
        {
          invisible:
            state.form['life-assured-identification']['marital-status'] ===
            'Married',
        }
      )
      break

    case 'quotation-family':
      // Lives insured
      updateItemIn(
        state.page.item,
        { id: 'fipp-all-lives-added' },
        {
          invisible: state.form['lives-assured'].length === 0,
          content: state.form['lives-assured'].map(additionalLife),
        }
      )

      Object.keys(state.form['policy-term']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['policy-term'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { uid: '037922f0f85648c6', type: 'list' },
        {
          invisible: state.form['lives-assured'].length === 0,
        }
      )

      //Allow onyl for 16 members to be added to the policy, hide the button if 16 has been added
      updateItemIn(
        state.page.item,
        { uid: '6eda4a43294678c8' },
        {
          invisible: state.form['lives-assured'].length >= 16,
        }
      )
      break

    // Add additional lives
    case 'add-family-members':
      let familyButtonLabels = familyMemberLabels
      if (
        state.local &&
        state.local.relationship &&
        familyMemberLabels.indexOf(state.local.relationship) > -1
      ) {
        familyButtonLabels = Object.keys(
          familyMemberOptions(0)[state.local.relationship]
        )
      }
      state.page.buttons = familyButtonLabels.map(createButton)
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'family-members-age':
      state.page.text = [
        `Please select an age for your ${
          state.local.relationship || 'family member'
        }`,
      ]

      updateItemIn(
        state.page.item,
        { id: 'age-of-member' },
        {
          content: state.local['age-of-member'] || '',
          options: state.local.options || [],
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'gender' },
        {
          content:
            (state.local.relationship === 'Mother' ||
            state.local.relationship === 'Mother in law' ||
            state.local.relationship === 'Step mother' ||
            state.local.relationship === 'Sister' ||
            state.local.relationship === 'Grandmother'
              ? (state.local.gender = 'Female')
              : state.local.gender || '') ||
            (state.local.relationship === 'Father' ||
            state.local.relationship === 'Father in law' ||
            state.local.relationship === 'Grandfather' ||
            state.local.relationship === 'Step father' ||
            state.local.relationship === 'Brother' ||
            state.local.relationship === 'Grandfather'
              ? (state.local.gender = 'Male')
              : state.local.gender || ''),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'funeral-cover' },
        { content: state.local['funeral-cover'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'free-child-cover' },
        { content: state.local['free-child-cover'] || '' }
      )

      //Only allowed to add 4 CHILDREN to
      let amountchildren = 0
      for (let i = 0; i < state.form['lives-assured'].length; i++) {
        if (state.form['lives-assured'][i]['free-child-cover'] === 'Y')
          amountchildren++
      }

      //hide free child cover if relationship !=Child
      updateItemIn(
        state.page.item,
        { uid: '67567a213fc88138', type: 'list' },
        {
          invisible:
            state.local.relationship !== 'Child' || amountchildren === 4,
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'old-age-cover' },
        { content: state.local['old-age-cover'] || '' }
      )

      //hide old age cover if relationship != (Father || Mother)
      updateItemIn(
        state.page.item,
        { uid: '39bcc649474ed99b', type: 'list' },
        {
          invisible: !(
            state.local.relationship === 'Father' ||
            state.local.relationship === 'Mother'
          ),
        }
      )
      break

    case 'family-members-quotation-details':
      updateItemIn(
        state.page.item,
        { id: 'funeral-cover-benefit' },
        { content: state.local['funeral-cover-benefit'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'memorial-seven-day' },
        { content: state.local['memorial-seven-day'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'memorial-forty-day' },
        { content: state.local['memorial-forty-day'] || '' }
      )
      updateItemIn(
        state.page.item,
        { id: 'memorial-one-year' },
        { content: state.local['memorial-one-year'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'old-age-cover-benefit' },
        { content: state.local['old-age-cover-benefit'] || '' }
      )

      //hide old age cover if relationship !=Father || Mother
      updateItemIn(
        state.page.item,
        { uid: '7f8682b5a25cf9df', type: 'list' },
        {
          invisible:
            !(
              state.local.relationship === 'Father' ||
              state.local.relationship === 'Mother'
            ) || !(state.local['old-age-cover'] === 'Y'),
        }
      )

      //hide the funeral benefit options
      updateItemIn(
        state.page.item,
        { id: 'funeral-benefit', type: 'list' },
        {
          invisible:
            (!(state.local['funeral-cover'] === 'Y') &&
              state.local['old-age-cover'] === 'Y') ||
            (!(state.local['funeral-cover'] === 'Y') &&
              state.local['free-child-cover'] === 'Y'),
        }
      )

      //hide free child benefit message if "free child care" has never been selected or not even given as an option
      updateItemIn(
        state.page.item,
        { id: 'free-child-benefit', type: 'list' },
        { invisible: !(state.local['free-child-cover'] === 'Y') }
      )

      break

    case 'remove-family-members':
      state.page.buttons = state.form['lives-assured'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'edit-family-members':
      state.page.buttons = state.form['lives-assured'].map(
        buttonFromLifeAssured
      )
      state.page.buttons.push(createButton('Back', { control: true }))
      break

    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-identification-type',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-residential-address',
        'life-assured-employment-details',
        'life-assured-digital-address',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      break

    case 'spouse-details':
      ;[
        'spouse-identification',
        'spouse-contact-details',
        'spouse-identification-type',
        'spouse-employment-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })

      updateItemIn(
        state.page.item,
        { id: 'spouse-is-trustee' },
        { content: state.form['spouse-is-trustee'] || '' }
      )
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(state.page.item, { id: field }, (item) => {
              item.content = state.form[section][field] || ''
              item.readonly = state.form['spouse-is-trustee'] === 'Y'
              if (item.readonly) {
                if (item.elementType === 'select') {
                  item.elementType = 'input'
                  item.inputType = 'text'
                } else if (item.type === 'component') {
                  item.type = 'field'
                }
              }
              return item
            })
          )
        }
      )
      break

    case 'additional-lives':
      updateItemIn(
        state.page.item,
        { id: 'additional-lives' },
        {
          content: familyMemberTablePrefix.concat(
            state.form['lives-assured'].map(familyMemberItem)
          ),
        }
      )
      validate(
        state,
        state.local && state.local.failed_validation ? false : true
      )
      break

    case 'beneficiary-details':
      const numBeneficiaries = (state.form['beneficiary-details'] || []).length
      updateItemIn(
        state.page.item,
        { id: 'beneficiary-details', type: 'table' },
        {
          invisible: numBeneficiaries < 1,
          content: (state.form['beneficiary-details'] || []).map(
            beneficiaryItem
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '6c60b1c422ae8373' }, // Add Trustee as beneficiary
        {
          invisible: isTrusteeABeneficiary(state.form) || numBeneficiaries >= 5,
        }
      )

      let numValidLives = state.form['lives-assured'].reduce(
        (b, c) =>
          b +
          (isLifeABeneficiary(c, state.form['beneficiary-details']) ? 0 : 1),
        0
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '57d2100ecd8854cc' }, // Add a Life beneficiary
        { invisible: numValidLives == 0 || numBeneficiaries >= 5 }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '145b199f80e0c8c3' }, // Add a different beneficiary
        { invisible: numBeneficiaries >= 5 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'update-benefit-split-percentage':
            button.invisible = numBeneficiaries < 2
            break
          case 'add-another-beneficiary':
            button.invisible = numBeneficiaries >= 9
            break
          case 'add-trustee':
            button.invisible =
              isTrusteeABeneficiary(state.form) || numBeneficiaries >= 9
            break
        }
      })
      break

    case 'add-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'edit-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break
    case 'update-beneficiary-split-percentage':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break
    case 'insurance-arrangements':
      Object.keys(state.form['existing-policies']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['existing-policies'][field] || '' }
        )
      )

      Object.keys(state.form['existing-policy-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['existing-policy-details'][field] || '' }
        )
      )

      //Hide Elements
      updateItemIn(
        state.page.item,
        { uid: '427309bfc272eea5', type: 'list' },
        {
          invisible:
            !(
              state.form['existing-policies'][
                'application-replaces-existing'
              ] === 'Y'
            ) ||
            state.form['existing-policies']['application-replaces-existing'] ===
              'Y' ||
            state.form['existing-policies']['application-replaces-existing'] ===
              null,
        }
      )

      updateItemIn(
        state.page.item,
        { uid: '427309bfc272eea5', type: 'list' },
        {
          invisible:
            !(state.form['existing-policies']['existing-coverage'] === 'Y') ||
            state.form['existing-policies']['existing-coverage'] === 'Y' ||
            state.form['existing-policies']['existing-coverage'] === null,
        }
      )
      break

    case 'medical-underwriting':
      Object.keys(state.form['life-assured-general-habits']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-general-habits'][field] || '' }
        )
      )
      ;[
        'planning-other-insurance',
        'other-assurance-past12-months',
        'risky-occupation-hobbies',
        'refused-application-or-increased-premium',
        'insolvency-issue',
        'occupation-not-clerical',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { id: `${field}-details` },
          {
            invisible:
              state.form['life-assured-general-habits'][field] !== 'Yes',
          }
        )
      })
      ;[
        '5f8b25ed4415c897', //  'average-monthly-gross-income-list',
        'beaacad6ef6645ee', // 'total-existing-assurance-cover-in-force-on-your-life-list',
        '91930796f1d9da82', // 'full-reasons-for-effecting-this-additional-cover-list',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { uid: field, type: 'list' },
          {
            invisible:
              state.form['life-assured-general-habits'][
                'cover-exceeds-one-hundred-thousand'
              ] !== 'Yes',
          }
        )
      })

      updateItemIn(
        state.page.item,
        { id: 'years-smoking' },
        { invisible: state.form['life-assured-general-habits'].smoker !== 'Y' }
      )
      updateItemIn(
        state.page.item,
        { id: 'smoking-details', type: 'list' },
        { invisible: state.form['life-assured-general-habits'].smoker !== 'Y' }
      )

      updateItemIn(
        state.page.item,
        { id: 'years-not-smoking' },
        {
          invisible:
            state.form['life-assured-general-habits']['non-smoker'] !== 'Y',
        }
      )
      break

    case 'medical-declaration':
      Object.keys(state.form['life-assured-health-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-health-details'][field] || '' }
        )
      )
      updateItemIn(
        state.page.item,
        { id: 'family-history-of-illness-details' },
        {
          invisible:
            state.form['life-assured-health-details'][
              'family-history-of-illness'
            ] !== 'Yes',
        }
      )

      Object.keys(state.form['life-assured-health-tests']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-health-tests'][field] || '' }
        )
      )
      break

    case 'health-descriptions':
      state.form['health-concerns'].forEach((c, i) =>
        Object.keys(c).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: `${field}-${i + 1}` },
            { content: c[field] || '' }
          )
        )
      )
      break

    case 'payment-details':
      Object.keys(state.form['payment-method']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-method'][field] || '' }
        )
      )
      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )
      //Payment Frequency
      Object.keys(state.form['payment-frequency']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-frequency'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { id: 'payer-person', type: 'list' },
        {
          invisible: !(
            state.form['life-assured-identification']['marital-status'] ===
            'Married'
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'myself' },
        { content: state.form['payer-person']['myself'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'my-spouse' },
        { content: state.form['payer-person']['my-spouse'] || '' }
      )
      break

    case 'debit-order-details':
      Object.keys(state.form['payment-bank-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['payment-bank-details'][field] || '' }
        )
      )
      break

    case 'mobile-wallet-details':
      Object.keys(state.form['mobile-wallet-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['mobile-wallet-details'][field] || '' }
        )
      )
      break

    case 'stop-order-details':
      Object.keys(state.form['payment-employment-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['payment-employment-details'][field] || '' }
        )
      )
      break

    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break
  }
  updateItemIn(
    state.page.item,
    { id: 'premium' },
    { content: totalPremium(state.form) }
  )
  updateItemIn(
    state.page.item,
    { id: 'benefit-rate' },
    { content: get_sumassured(state.form) + benefitRatelives(state.form) }
  )
}

// Change the page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}

// processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break
      case 'quotation-page':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              changePage(state, 'additional-benefits')
              if (
                state.form['life-assured-identification']['marital-status'] !==
                  'Married' ||
                state.form['covered-details']['main-life-only'] === ''
              ) {
                state.form['covered-details']['main-life-only'] = 'Y'
              }
            }
            break
        }
        break
      case 'additional-benefits':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-page')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'quotation-family')
            }
        }
        break
      case 'premium-validate':
        switch (button.id) {
          case 'done':
            changePage(state, 'quotation-family')
            break
        }
        break
      case 'quotation-family':
        switch (button.id) {
          case 'back':
            changePage(state, 'additional-benefits')
            break
          case 'next':
            if (validate(state)) {
              if (totalPremium(state.form) < 100) {
                changePage(state, 'premium-validate')
              } else {
                changePage(state, 'life-assured-identification')
              }
            }
            break
          case 'add-family':
            changePage(state, 'add-family-members')
            break
          case 'edit-family':
            const locals = state.local
            changePage(state, 'edit-family-members')
            state.local = locals
            break
          case 'remove-family':
            changePage(state, 'remove-family-members')
            break
        }
        break

      case 'add-family-members':
        if (button.id === 'back') {
          changePage(state, 'quotation-family')
        } else {
          let optionLevel = familyMemberOptions(mainLifeAge(state.form) || 0)
          if (
            state.local &&
            state.local.relationship &&
            familyMemberLabels.indexOf(state.local.relationship) > -1
          ) {
            optionLevel = optionLevel[state.local.relationship]
          }
          const elem = Object.keys(optionLevel).find(
            (l) => button.id === slugify(l)
          )
          if (elem) {
            const options = optionLevel[elem]
            if ('options' in options) {
              changePage(state, 'family-members-age')
              state.local = state.local || {}
              state.local.options = options.options
              state.local.relationship = options.rename || elem
            } else {
              state.local = state.local || {}
              state.local.relationship = elem
            }
          }
        }
        break

      case 'family-members-age':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-family')
            break
          case 'next':
            if (validate(state)) {
              const local = state.local
              changePage(state, 'family-members-quotation-details')
              state.local = local
            }
            break
        }
        break

      case 'family-members-quotation-details':
        switch (button.id) {
          case 'back':
            const local = state.local
            changePage(state, 'family-members-age')
            state.local = local
            break
          case 'next':
            if (validate(state)) {
              if (
                state.local &&
                [
                  'relationship',
                  'age-of-member',
                  'gender',
                  //'funeral-cover',
                  //  'funeral-cover-benefit',
                ].every((f) => f in state.local)
              ) {
                const life = createFamilyMember(
                  state.local.relationship,
                  state.local['age-of-member'],
                  state.local.gender,
                  state.local['funeral-cover'] || '',
                  state.local['free-child-cover'] || '',
                  state.local['old-age-cover'] || '',
                  state.local['funeral-cover-benefit'],
                  state.local['memorial-seven-day'] || '',
                  state.local['memorial-forty-day'] || '',
                  state.local['memorial-one-year'] || '',
                  state.local['old-age-cover-benefit']
                )
                if (
                  (state.local.replace || state.local.replace === 0) &&
                  state.local.replace >= 0 &&
                  state.local.replace < state.form['lives-assured'].length
                ) {
                  state.form['lives-assured'][state.local.replace] = life
                } else {
                  state.form['lives-assured'].push(life)
                }
                changePage(state, 'quotation-family')
              }
            }
            break
        }
        break

      case 'edit-family-members':
        if (button.id === 'back') {
          changePage(state, 'quotation-family')
        } else {
          const lifePosition = state.form['lives-assured'].findIndex(
            (life, position, lives) =>
              idFromLifeAssured(life, position, lives) === button.id
          )
          if (lifePosition >= 0) {
            changePage(state, 'family-members-age')
            const life = original(state.form['lives-assured'][lifePosition])
            state.local = {
              replace: lifePosition,
              relationship: life.relationship,
              'age-of-member': life['age-of-member'],
              gender: life.gender,
              'funeral-cover': life['funeral-cover'] === 'Y' ? 'Y' : undefined,
              'free-child-cover':
                life['free-child-cover'] === 'Y' ? 'Y' : undefined,
              'old-age-cover': life['old-age-cover'] === 'Y' ? 'Y' : undefined,
              'funeral-cover-benefit': life['funeral-cover-benefit'],
              'memorial-seven-day':
                life['memorial-seven-day'] === 'Y' ? 'Y' : undefined,
              'memorial-forty-day':
                life['memorial-forty-day'] === 'Y' ? 'Y' : undefined,
              'memorial-one-year':
                life['memorial-one-year'] === 'Y' ? 'Y' : undefined,
              'old-age-cover-benefit': life['old-age-cover-benefit'],
            }
            const optionLevel =
              familyMemberOptions(mainLifeAge(state.form) || 0) || []
            state.local.options = optionsForRelationship(
              optionLevel,
              life.relationship
            )
            if (state.local.options === null) {
              state.local.options = Object.keys(optionLevel).reduce(
                (options, key) =>
                  optionsForRelationship(optionLevel[key], life.relationship) ||
                  options,
                []
              )
            }
          }
        }
        break

      case 'remove-family-members':
        state.form['lives-assured'] = state.form['lives-assured'].filter(
          (life, position, lives) =>
            idFromLifeAssured(life, position, lives) !== button.id
        )
        changePage(state, 'quotation-family')
        break

      case 'life-assured-identification':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-family')
            break
          case 'next':
            if (validate(state)) {
              changePage(
                state,
                state.form['life-assured-identification']['marital-status'] ===
                  'Married'
                  ? 'spouse-details'
                  : 'trustee-details'
              )
              if (
                ['life-assured-identification']['marital-status'] !== 'Married'
              ) {
                state.form['payer-person']['myself'] = 'Y'
              }
            }
            break
        }
        break
      case 'spouse-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'life-assured-identification')
            break
          case 'next':
            if (validate(state)) {
              if (state.form['spouse-is-trustee'] === 'Y') {
                ;['identification', 'contact-details'].forEach((section) =>
                  Object.keys(state.form[`spouse-${section}`]).forEach(
                    (field) =>
                      (state.form[`trustee-${section}`][field] =
                        state.form[`spouse-${section}`][field])
                  )
                )
                state.form['trustee-identification']['relationship'] = 'Spouse'
              }
              changePage(state, 'trustee-details')
            }
            break
        }
        break

      case 'update-beneficiary-split-percentage':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'update':
            if (validate(state)) {
              state.form['beneficiary-details'] = original(
                state.form['beneficiary-details']
              ).map((b, c) => {
                b['benefit-split'] = amountString(
                  parseFloat(state.local[`beneficiary-split-${c + 1}`])
                )
                return b
              })
              changePage(state, 'beneficiary-details')
            }
        }
        break

      case 'add-additional-life-as-beneficiary':
        if (
          button.index >= 0 &&
          button.index < state.form['lives-assured'].length
        ) {
          state.form['beneficiary-details'].push({
            'first-names':
              state.form['lives-assured'][button.index]['first-names'],
            surname: state.form['lives-assured'][button.index].surname,
            'benefit-split': null,
            role: state.form['lives-assured'][button.index].relationship,
            gender: state.form['lives-assured'][button.index].gender,
            'date-of-birth':
              state.form['lives-assured'][button.index]['date-of-birth'],
          })
          updateBeneficiarySplit(state.form)
        }
        changePage(state, 'beneficiary-details')
        break
      case 'trustee-details':
        switch (button.id) {
          case 'back':
            changePage(
              state,
              state.form['life-assured-identification']['marital-status'] ===
                'Married'
                ? 'spouse-details'
                : 'life-assured-identification'
            )
            break
          case 'next':
            if (validate(state)) {
              if (state.form['lives-assured'].length > 0) {
                changePage(state, 'additional-lives')
              } else {
                changePage(state, 'beneficiary-details')
              }
              if (state.form['trustee-identification'].relationship === 'Son') {
                state.form['trustee-identification'].relationship = 'Son-1'
              }
              if (
                state.form['trustee-identification'].relationship === 'Daughter'
              ) {
                state.form['trustee-identification'].relationship = 'Daughter-1'
              }
            }
            break
        }
        break
      case 'additional-lives':
        switch (button.id) {
          case 'back':
            changePage(state, 'trustee-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'beneficiary-details')
            }
            break
        }
        break
      case 'beneficiary-details':
        switch (button.id) {
          case 'back':
            if (state.form['lives-assured'].length > 0) {
              changePage(state, 'additional-lives')
            } else {
              changePage(state, 'trustee-details')
            }
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'insurance-arrangements')
            }
            break
          case 'add-another-beneficiary':
            if (state.form['beneficiary-details'].length < 6) {
              changePage(state, 'add-beneficiary')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
                'role',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break
          case 'add-trustee':
            if (
              state.form['beneficiary-details'].length < 6 &&
              !isTrusteeABeneficiary(original(state.form))
            ) {
              state.form['beneficiary-details'].push({
                'first-names':
                  state.form['trustee-identification']['first-names'],
                surname: state.form['trustee-identification'].surname,
                'benefit-split': null,
                role: 'Cessionary',
                'date-of-birth':
                  state.form['trustee-identification']['date-of-birth'],
              })
              updateBeneficiarySplit(state.form)
            }
            break
          case 'add-additional-life-as-beneficiary':
            let btns = state.form['lives-assured'].reduce((b, c, i) => {
              if (!isLifeABeneficiary(c, state.form['beneficiary-details'])) {
                b.push(createButton(c['first-names'], { index: i }))
              }
              return b
            }, [])
            if (btns.length > 0) {
              changePage(state, 'add-additional-life-as-beneficiary')
              state.page.buttons = btns
            }
            break
          case 'update-benefit-split-percentage':
            changePage(state, 'update-beneficiary-split-percentage')
            state.local = state.form['beneficiary-details'].reduce(
              (l, b, c) => {
                l[`beneficiary-split-${c + 1}`] = b['benefit-split'] || ''
                return l
              },
              {}
            )
            updateItemIn(
              state.page.item,
              { id: 'update-benefit-split-percentage', type: 'list' },
              {
                content:
                  state.form['beneficiary-details'].map(benefitSplitItem),
              }
            )
            break
        }
        break
      case 'add-beneficiary':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'add':
            if (state.form['beneficiary-details'].length < 6) {
              state.form['beneficiary-details'].push({
                'first-names': state.local['first-names'],
                surname: state.local.surname,
                'benefit-split': null,
                role: state.local.role,
                gender: state.local.gender,
                'date-of-birth': state.local['date-of-birth'],
              })
              updateBeneficiarySplit(state.form)
            }
            changePage(state, 'beneficiary-details')
            break
        }
        break

      case 'edit-beneficiary':
        switch (button.id) {
          case 'update':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['beneficiary-details'].length
            ) {
              Object.keys(
                original(
                  state.form['beneficiary-details'][state.local.index - 1]
                )
              ).forEach(
                (k) =>
                  (state.form['beneficiary-details'][state.local.index - 1][k] =
                    state.local[k])
              )
            }
            changePage(state, 'beneficiary-details')
            break
          case 'delete-beneficiary':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['beneficiary-details'].length
            ) {
              const old = original(state.form['beneficiary-details'])
              state.form['beneficiary-details'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
              updateBeneficiarySplit(state.form)
            }
            changePage(state, 'beneficiary-details')
            break
        }
        break
      case 'insurance-arrangements':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'next':
            changePage(state, 'medical-underwriting')
            break
        }
        break
      case 'medical-underwriting':
        switch (button.id) {
          case 'back':
            changePage(state, 'insurance-arrangements')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'medical-declaration')
            }
            break
        }
        break
      case 'medical-declaration':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-underwriting')
            break
          case 'next':
            if (validate(state)) {
              if (healthConcernCount(original(state.form)) > 0) {
                changePage(state, 'health-descriptions')
                createHealthDetailsPage(state)
              } else {
                state.form['health-concerns'].length = 0
                changePage(state, 'payment-details')
              }
            }
            break
        }
        break

      case 'health-descriptions':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-declaration')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details')
            }
        }
        break

      case 'payment-details':
        switch (button.id) {
          case 'back':
            if (healthConcernCount(original(state.form)) > 0) {
              changePage(state, 'health-descriptions')
              createHealthDetailsPage(state)
            } else {
              changePage(state, 'medical-declaration')
            }
            break
          case 'next':
            if (validate(state)) {
              if (state.form['payment-method']['debit-order'] === 'Y') {
                changePage(state, 'debit-order-details')
              } else if (state.form['payment-method']['stop-order'] === 'Y') {
                changePage(state, 'stop-order-details')
              } else if (
                state.form['payment-method']['mobile-wallet'] === 'Y'
              ) {
                changePage(state, 'mobile-wallet-details')
              }
            }
            if (state.form['payer-person']['my-spouse'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['spouse-identification'][field])
              )
              state.form['payer-person-identification']['relationship'] ===
                'Spouse'
            } else if (state.form['payer-person']['myself'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['life-assured-identification'][field])
              )
            }
            state.form['life-assured-is-payer'] =
              state.form['payer-person']['myself']
            state.form['payer-person-identification']['relationship'] = 'Main'
            break
        }
        break

      case 'mobile-wallet-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
        }
        break

      case 'debit-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
        }
        break

      case 'stop-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
        }
        break

      case 'upload-documents':
        switch (button.id) {
          case 'back':
            if (state.form['payment-method']['debit-order'] === 'Y') {
              changePage(state, 'debit-order-details')
            } else if (state.form['payment-method']['stop-order'] === 'Y') {
              changePage(state, 'stop-order-details')
            } else if (state.form['payment-method']['mobile-wallet'] === 'Y') {
              changePage(state, 'mobile-wallet-details')
            }
            state.form['payer-signature'] = null
            break
          case 'upload-id-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
          case 'next':
            changePage(state, 'acceptance-screen')
            generateAcceptancePage(state)
            break
        }
        break

      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            if (state.form.documents[state.local.document].length > 0) {
              changePage(state, 'acceptance-screen')
              generateAcceptancePage(state)
            } else {
              changePage(state, 'upload-documents')
            }
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'submit':
            if (validate(state)) {
              state.form['application-accepted'] = 'Y'
              state.form['application-acceptance-date'] =
                dayjs().format('YYYYMMDD')
              asyncDispatch(completeProcess(state.form['reference-number']))
              state.form.premium = totalPremium(state.form)
              if (state.form['covered-details']['main-life-only'] === 'Y') {
                state.form['parents-details'] = maptoParent(state.form)
              } else {
                state.form['parents-details'] = maptoStepParent(state.form)
              }
              state.form['parents-details'] = state.form[
                'parents-details'
              ].filter((item) => !(item['old-age-cover'] === ''))
              state.form['children-details'] = maptoChild(state.form)
              state.form['children-details'] = state.form[
                'children-details'
              ].filter((item) => !(item['free-child-cover'] === ''))
              state.form['lives-assured'] = state.form['lives-assured'].filter(
                (item) => !(item['funeral-cover'] === '')
              )
              state.form['children-details'].forEach((user) => {
                delete user['old-age-cover']
              })
              state.form['parents-details'].forEach((user) => {
                delete user['free-child-cover']
              })
              if (state.form['hospitalisation-units']) {
                const unit = state.form['hospitalisation-units']
                const mapUnit =
                  unit in hospitalUnits
                    ? hospitalUnits[unit]
                    : hospitalUnits[unit]
                state.form['hospitalisation-units'] = mapUnit
              }
            }
            break
          case 'back':
            changePage(state, 'upload-documents')
            break
        }
        break
    }
    setPageItemFromState(state)
  }
}

//processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-page':
      switch (data.id) {
        case 'date-of-birth':
          state.form['life-assured-identification']['date-of-birth'] =
            data.value
          dirty = true
          break
        case 'marital-status':
          state.form['life-assured-identification']['marital-status'] =
            data.value
          dirty = true
          break
        case 'gender':
          state.form['life-assured-identification']['gender'] = data.value
          dirty = true
          break
        case 'date-of-birth-spouse':
          state.form['spouse-identification']['date-of-birth'] = data.value
          dirty = true
          break
        case 'benefit-option':
          state.form['benefit-option'] = data.value
          dirty = true
          break
        case 'update-option':
          state.form['update-option'] = data.value
          dirty = true
          break
        case 'term-in-years':
          state.form['term-in-years'] = data.value
          dirty = true
          break
        case 'joint-life':
        case 'main-life-only':
          state.form['covered-details'][data.id] = data.value
          dirty = true
          break
        case 'death-sum-assured':
        case 'total-personal-disability-sum-assured':
        case 'illness-sum-assured':
          state.form['standalone-options'][data.id] = data.value
          dirty = true
          break
        case 'death-and-total-personal-disability':
        case 'death-and-critical-illness':
        case 'death-and-total-disability-and-critical-illness':
          state.form['accelerated-options'][data.id] = data.value
          dirty = true
          break
        case 'accelerated-cover-sum-assured':
          state.form['accelerated-cover-sum-assured'] = data.value
          dirty = true
          break
        case 'funeral-cover-for-main-life':
          state.form['funeral-cover-for-main-life'] = data.value
          dirty = true
          break
      }
      if (data.id === 'marital-status') {
        state.form['spouse-identification'].gender =
          data.value === 'Married'
            ? spouseGender(state.form['life-assured-identification'].gender)
            : null
        state.form['spouse-identification']['marital-status'] =
          data.value === 'Married' ? 'Married' : null
        dirty = true
      } else if (data.id === 'gender') {
        if (
          state.form['life-assured-identification']['marital-status'] ===
          'Married'
        ) {
          state.form['spouse-identification'].gender = spouseGender(data.value)
          state.form['spouse-identification']['marital-status'] = 'Married'
          dirty = true
        }
      }
      break
    case 'additional-benefits':
      switch (data.id) {
        case 'hospitilization-cover-main-life-only':
        case 'hospitilization-cover-main-life-only-1':
          state.form['hospitilization-cover-main-life-only'] = data.value
          dirty = true
          break
        case 'hospitilization-cover-joint-life':
          state.form['hospitilization-cover-joint-life'] = data.value
          dirty = true
          break
        case 'no-option':
          state.form['no-option'] = data.value
          dirty = true
          break
        case 'hospitalisation-units':
          state.form['hospitalisation-units'] = data.value
          dirty = true
          break
        case 'educational-support-benefit':
          state.form['educational-support-benefit'][
            'educational-support-benefit'
          ] = data.value
          dirty = true
          break
        case 'educational-support-term':
          state.form['educational-support-benefit'][
            'educational-support-term'
          ] = data.value
          dirty = true
          break
        case 'retirement-premium':
          state.form['retirement-premium'] = data.value
          dirty = true
          break
        case 'main-life-funeral-benefit':
          state.form['main-life-funeral-benefit'] = data.value
          dirty = true
          break
        case 'term-ten':
        case 'term-fifteen':
          state.form['policy-term'][data.id] = data.value
          dirty = true
          break
        case 'memorial-seven-day':
        case 'memorial-forty-day':
        case 'memorial-one-year':
          state.form['rider-options'][data.id] = data.value
          dirty = true
          break
        case 'double-accident-benefit':
          state.form['double-accident-benefit'] = data.value
          dirty = true
          break
        case 'cash-bonus':
          state.form['cash-bonus'] = data.value
          dirty = true
          break
      }
      break

    case 'family-members-age':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'family-members-quotation-details':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'quotation-family':
      switch (data.id) {
        case 'term-ten':
        case 'term-fifteen':
          state.form['policy-term'][data.id] = data.value
          dirty = true
          break
      }
      break

    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-identification-type',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-residential-address',
        'life-assured-employment-details',
        'life-assured-digital-address',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      break

    case 'spouse-details':
      ;[
        'spouse-identification',
        'spouse-contact-details',
        'spouse-identification-type',
        'spouse-employment-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      if (data.id === 'spouse-is-trustee') {
        state.form[data.id] = data.value
        dirty = true
      }
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      break

    case 'insurance-arrangements':
      if (data.id in state.form['existing-policies']) {
        state.form['existing-policies'][data.id] = data.value
        dirty = true
      }
      if (data.id in state.form['existing-policy-details']) {
        state.form['existing-policy-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'additional-lives':
      let id1 = data.id.split('-')
      const index1 = parseInt(id1.pop())
      if (!isNaN(index1) && index1 < state.form['lives-assured'].length) {
        id1 = id1.join('-')
        if (id1 in state.form['lives-assured'][index1]) {
          state.form['lives-assured'][index1][id1] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break

    case 'add-beneficiary':
    case 'edit-beneficiary':
    case 'update-beneficiary-split-percentage':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'medical-underwriting':
      if (data.id in state.form['life-assured-general-habits']) {
        state.form['life-assured-general-habits'][data.id] = data.value
        dirty = true
      }
      break

    case 'medical-declaration':
      if (data.id in state.form['life-assured-health-details']) {
        state.form['life-assured-health-details'][data.id] = data.value
        dirty = true
      }
      if (data.id in state.form['life-assured-health-tests']) {
        state.form['life-assured-health-tests'][data.id] = data.value
        dirty = true
      }
      break

    case 'health-descriptions':
      let id = data.id.split('-')
      const index = parseInt(id.pop())
      if (!isNaN(index) && index <= state.form['health-concerns'].length) {
        id = id.join('-')
        if (id in state.form['health-concerns'][index - 1]) {
          state.form['health-concerns'][index - 1][id] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break

    case 'payment-details':
      switch (data.id) {
        case 'debit-order':
        case 'stop-order':
        case 'mobile-wallet':
          state.form['payment-method'][data.id] = data.value
          dirty = true
          break
        case 'monthly':
        case 'annual':
        case 'bi-annual':
        case 'quarterly':
          state.form['payment-frequency'][data.id] = data.value
          dirty = true
          break
        case 'payment-start-date':
          const date = dayjs(data.value)
          state.form['payment-start-date'] = date.isValid()
            ? date.format('YYYYMMDD')
            : null
          dirty = true
          break
        case 'myself':
        case 'my-spouse':
          state.form['payer-person'][data.id] = data.value
          dirty = true
          break
      }
      break

    case 'debit-order-details':
      if (data.id in state.form['payment-bank-details']) {
        state.form['payment-bank-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'mobile-wallet-details':
      if (data.id in state.form['mobile-wallet-details']) {
        state.form['mobile-wallet-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'stop-order-details':
      if (data.id in state.form['payment-employment-details']) {
        state.form['payment-employment-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

const processItemClick = (state, data, asyncDispatch) => {
  let dirty = false
  switch (state.step) {
    case 'beneficiary-details':
      if (
        data.index > 0 &&
        data.index <= state.form['beneficiary-details'].length
      ) {
        changePage(state, 'edit-beneficiary')
        state.local = Object.assign(
          {},
          original(state.form['beneficiary-details'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

export default {
  createState,
  processButton,
  processChange,
  processItemClick,
}

//RATE TABLES
var main_incap_rates = [
  [
    1.0343, 1.0217, 1.1904, 1.4303, 1.9304, 2.6778, 4.506, 7.5726, 10.9364,
    16.3745,
  ],
  [
    0.8022, 0.7941, 0.9283, 1.1197, 1.5165, 2.1084, 3.5545, 6.0018, 8.6973,
    13.028,
  ],
  [
    0.6775, 0.6722, 0.7878, 0.9525, 1.2961, 1.8043, 3.0438, 5.1571, 7.4895,
    11.2215,
  ],
  [
    0.6013, 0.5978, 0.7019, 0.8504, 1.1601, 1.6169, 2.7306, 4.6372, 6.7446,
    10.1067,
  ],
  [
    0.5514, 0.5493, 0.646, 0.7842, 1.0718, 1.4949, 2.5264, 4.2978, 6.2577,
    9.3777,
  ],
  [
    0.5172, 0.5167, 0.6086, 0.7391, 1.0116, 1.4116, 2.387, 4.0657, 5.9244,
    8.8782,
  ],
  [0.4939, 0.4941, 0.5829, 0.7087, 0.9709, 1.3888, 0.0, 0.0, 0.0, 0.0],
  [0.4766, 0.4774, 0.5638, 0.686, 0.9403, 1.3716, 0.0, 0.0, 0.0, 0.0],
  [0.4638, 0.4649, 0.5493, 0.6686, 0.9166, 1.3582, 0.0, 0.0, 0.0, 0.0],
  [0.4541, 0.4554, 0.5383, 0.6552, 0.8982, 1.3478, 0.0, 0.0, 0.0, 0.0],
  [0.4469, 0.4483, 0.53, 0.6451, 0.8844, 1.34, 0.0, 0.0, 0.0, 0.0],
  [0.4416, 0.4431, 0.5239, 0.6376, 0.8802, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4377, 0.4392, 0.5193, 0.6327, 0.8777, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4348, 0.4364, 0.5159, 0.6285, 0.8753, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4325, 0.4342, 0.5134, 0.6253, 0.8735, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4309, 0.4326, 0.5115, 0.623, 0.8722, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.618, 0.6315, 0.7019, 0.7658, 0.9453, 1.1868, 1.8747, 0.0, 0.0, 0.0],
  [0.4787, 0.4912, 0.5474, 0.6002, 0.7474, 0.9489, 1.7203, 0.0, 0.0, 0.0],
  [0.4047, 0.4164, 0.465, 0.5117, 0.6412, 0.8205, 1.6366, 0.0, 0.0, 0.0],
  [0.3603, 0.3708, 0.4148, 0.4579, 0.5764, 0.7415, 1.5849, 0.0, 0.0, 0.0],
  [0.3313, 0.3412, 0.3823, 0.423, 0.5343, 0.6901, 1.5513, 0.0, 0.0, 0.0],
  [0.3114, 0.321, 0.3601, 0.3993, 0.5056, 0.6552, 1.5284, 0.0, 0.0, 0.0],
  [0.298, 0.3073, 0.3451, 0.3833, 0.4864, 0.6449, 0.0, 0.0, 0.0, 0.0],
  [0.2881, 0.2972, 0.3341, 0.3714, 0.472, 0.6372, 0.0, 0.0, 0.0, 0.0],
  [0.2808, 0.2897, 0.3257, 0.3627, 0.4613, 0.6312, 0.0, 0.0, 0.0, 0.0],
  [0.2753, 0.284, 0.3194, 0.3558, 0.4527, 0.6266, 0.0, 0.0, 0.0, 0.0],
  [0.2712, 0.2798, 0.3148, 0.3507, 0.4464, 0.6232, 0.0, 0.0, 0.0, 0.0],
  [0.2682, 0.2767, 0.3113, 0.3469, 0.4443, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.266, 0.2744, 0.3087, 0.3441, 0.4428, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.2643, 0.2726, 0.3068, 0.342, 0.4417, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.263, 0.2714, 0.3054, 0.3405, 0.4408, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.2621, 0.2704, 0.3043, 0.3393, 0.4402, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    0.7192, 0.7676, 0.919, 1.0909, 1.5329, 2.3064, 4.2427, 7.8934, 12.098,
    17.8306,
  ],
  [
    0.5585, 0.6012, 0.7251, 0.8683, 1.2355, 1.8742, 3.4646, 6.501, 10.0713,
    15.0635,
  ],
  [
    0.4739, 0.513, 0.621, 0.7486, 1.0743, 1.6363, 3.0354, 5.7281, 8.9337,
    13.4971,
  ],
  [
    0.4221, 0.4589, 0.5573, 0.6753, 0.9747, 1.4889, 2.7692, 5.2463, 8.2221,
    12.5189,
  ],
  [
    0.3886, 0.4237, 0.5167, 0.6287, 0.9106, 1.3934, 2.5965, 4.9328, 7.7601,
    11.8907,
  ],
  [
    0.3658, 0.3999, 0.4887, 0.597, 0.8674, 1.3286, 2.4796, 4.721, 7.4501,
    11.4766,
  ],
  [0.3505, 0.3837, 0.4699, 0.5759, 0.8395, 1.3129, 0.0, 0.0, 0.0, 0.0],
  [0.3391, 0.3717, 0.4558, 0.5599, 0.8174, 1.3001, 0.0, 0.0, 0.0, 0.0],
  [0.3307, 0.3626, 0.445, 0.5474, 0.8, 1.2901, 0.0, 0.0, 0.0, 0.0],
  [0.3243, 0.3556, 0.4367, 0.5377, 0.7863, 1.2822, 0.0, 0.0, 0.0, 0.0],
  [0.3195, 0.3505, 0.4305, 0.5305, 0.7761, 1.2763, 0.0, 0.0, 0.0, 0.0],
  [0.316, 0.3466, 0.426, 0.5252, 0.7731, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3134, 0.3438, 0.4227, 0.5213, 0.771, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3114, 0.3417, 0.4202, 0.5184, 0.7693, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.31, 0.3401, 0.4183, 0.5163, 0.7682, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3089, 0.3389, 0.417, 0.5147, 0.7673, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.5573, 1.6179, 2.0201, 2.5832, 3.6243, 5.1273, 8.6235, 13.9629, 0.0, 0.0],
  [1.2141, 1.2669, 1.5868, 2.0381, 2.8808, 4.1109, 6.995, 11.4286, 0.0, 0.0],
  [1.0292, 1.078, 1.3549, 1.7452, 2.4794, 3.5605, 6.0757, 9.9942, 0.0, 0.0],
  [0.9157, 0.962, 1.211, 1.5643, 2.2334, 3.2212, 5.5069, 9.1017, 0.0, 0.0],
  [0.8411, 0.8861, 1.1178, 1.4473, 2.0744, 3.001, 5.1363, 8.5196, 0.0, 0.0],
  [0.7899, 0.8342, 1.0545, 1.3682, 1.9667, 2.8515, 4.8845, 8.125, 0.0, 0.0],
  [0.7548, 0.7989, 1.0119, 1.3152, 1.8943, 2.8106, 0.0, 0.0, 0.0, 0.0],
  [0.7288, 0.7726, 0.98, 1.2753, 1.8396, 2.7796, 0.0, 0.0, 0.0, 0.0],
  [0.7091, 0.7526, 0.9556, 1.2446, 1.7972, 2.7555, 0.0, 0.0, 0.0, 0.0],
  [0.6943, 0.7373, 0.9369, 1.2209, 1.7642, 2.7367, 0.0, 0.0, 0.0, 0.0],
  [0.6832, 0.7259, 0.9229, 1.2032, 1.7396, 2.7228, 0.0, 0.0, 0.0, 0.0],
  [0.675, 0.7175, 0.9125, 1.1902, 1.7323, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.669, 0.7112, 0.9049, 1.1805, 1.7268, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6645, 0.7066, 0.8992, 1.1733, 1.7227, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6611, 0.7031, 0.895, 1.168, 1.7197, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6586, 0.7006, 0.8918, 1.1641, 1.7175, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.1505, 1.1718, 1.3991, 1.7141, 2.3361, 3.2237, 5.3044, 8.0762, 0.0, 0.0],
  [0.8965, 0.918, 1.0989, 1.3479, 1.8513, 2.5696, 4.2597, 6.4882, 0.0, 0.0],
  [0.7598, 0.7817, 0.9362, 1.151, 1.5885, 2.2147, 3.6593, 5.5835, 0.0, 0.0],
  [0.6759, 0.6972, 0.8368, 1.0306, 1.4276, 1.9963, 3.2891, 5.0248, 0.0, 0.0],
  [0.621, 0.642, 0.7722, 0.9539, 1.3244, 1.8542, 3.0475, 4.66, 0.0, 0.0],
  [0.5835, 0.6042, 0.7282, 0.9008, 1.2533, 1.7574, 2.8826, 4.4112, 0.0, 0.0],
  [0.5584, 0.5786, 0.6984, 0.8651, 1.2053, 1.7305, 0.0, 0.0, 0.0, 0.0],
  [0.5393, 0.5595, 0.6762, 0.8384, 1.1691, 1.7101, 0.0, 0.0, 0.0, 0.0],
  [0.525, 0.545, 0.6593, 0.8179, 1.1411, 1.6944, 0.0, 0.0, 0.0, 0.0],
  [0.5141, 0.534, 0.6462, 0.8021, 1.1193, 1.6821, 0.0, 0.0, 0.0, 0.0],
  [0.506, 0.5258, 0.6365, 0.7902, 1.103, 1.6729, 0.0, 0.0, 0.0, 0.0],
  [0.5, 0.5197, 0.6293, 0.7814, 1.0981, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4956, 0.5151, 0.6239, 0.7748, 1.0944, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4923, 0.5118, 0.6199, 0.7699, 1.0916, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4898, 0.5092, 0.6169, 0.7663, 1.0895, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4879, 0.5073, 0.6146, 0.7636, 1.088, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    1.1313, 1.1426, 1.3704, 1.6793, 2.2988, 3.2571, 5.6465, 10.1021, 15.0505,
    21.7665,
  ],
  [
    0.8724, 0.8859, 1.0648, 1.3085, 1.8068, 2.5783, 4.5004, 8.1209, 12.2264,
    17.9421,
  ],
  [
    0.7354, 0.7493, 0.9017, 1.112, 1.5478, 2.2176, 3.8827, 7.0441, 10.6769,
    15.8249,
  ],
  [
    0.6515, 0.6658, 0.8034, 0.9936, 1.3892, 1.9966, 3.5069, 6.3868, 9.7293,
    14.5328,
  ],
  [
    0.597, 0.6116, 0.7397, 0.9171, 1.2863, 1.8529, 3.2618, 5.9582, 9.1135,
    13.7012,
  ],
  [
    0.5598, 0.5748, 0.6965, 0.8664, 1.2175, 1.7549, 3.095, 5.6672, 8.6984,
    13.15,
  ],
  [0.5345, 0.5499, 0.6674, 0.8317, 1.1707, 1.7285, 0.0, 0.0, 0.0, 0.0],
  [0.5164, 0.5319, 0.6464, 0.8067, 1.1371, 1.7095, 0.0, 0.0, 0.0, 0.0],
  [0.5032, 0.5189, 0.6313, 0.7886, 1.1126, 1.6957, 0.0, 0.0, 0.0, 0.0],
  [0.4935, 0.5093, 0.6202, 0.7754, 1.0947, 1.6856, 0.0, 0.0, 0.0, 0.0],
  [0.4863, 0.5022, 0.612, 0.7657, 1.0815, 1.6781, 0.0, 0.0, 0.0, 0.0],
  [0.4811, 0.497, 0.606, 0.7586, 1.0776, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4771, 0.4932, 0.6016, 0.7533, 1.0747, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4741, 0.4903, 0.5984, 0.7495, 1.0725, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.472, 0.4882, 0.596, 0.7466, 1.0709, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4707, 0.4866, 0.5942, 0.7445, 1.0698, 0.0, 0.0, 0.0, 0.0, 0.0],
]
var joint_life_1_rates = [
  [
    1.3858, 1.5054, 1.8385, 2.4589, 3.4186, 4.9271, 6.969, 9.7097, 14.0627,
    19.9165,
  ],
  [
    1.0829, 1.1746, 1.4353, 1.9255, 2.6789, 3.8577, 5.4606, 7.6164, 11.0375,
    15.6491,
  ],
  [
    0.918, 0.996, 1.2177, 1.6352, 2.2769, 3.2799, 4.6451, 6.484, 9.4003,
    13.3376,
  ],
  [
    0.8166, 0.886, 1.0837, 1.4563, 2.029, 2.9233, 4.1413, 5.7838, 8.3876,
    11.9063,
  ],
  [0.75, 0.814, 0.997, 1.34, 1.8659, 2.6884, 3.8093, 5.3223, 7.7197, 10.9624],
  [
    0.7042, 0.7645, 0.9366, 1.259, 1.7533, 2.5262, 3.58, 5.0034, 7.2582,
    10.3101,
  ],
  [0.6729, 0.7308, 0.8952, 1.2033, 1.676, 2.4806, 0.0, 0.0, 0.0, 0.0],
  [0.6494, 0.7056, 0.8643, 1.1616, 1.6177, 2.4463, 0.0, 0.0, 0.0, 0.0],
  [0.6316, 0.6864, 0.8409, 1.1299, 1.5733, 2.42, 0.0, 0.0, 0.0, 0.0],
  [0.6181, 0.6719, 0.8231, 1.1058, 1.5393, 2.3999, 0.0, 0.0, 0.0, 0.0],
  [0.6081, 0.6611, 0.8099, 1.0879, 1.5141, 2.3849, 0.0, 0.0, 0.0, 0.0],
  [0.6006, 0.6531, 0.8, 1.0745, 1.5064, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5951, 0.6471, 0.7927, 1.0645, 1.5006, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5909, 0.6426, 0.7873, 1.0571, 1.4964, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5879, 0.6393, 0.7833, 1.0516, 1.4932, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5855, 0.6369, 0.7802, 1.0475, 1.4908, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    1.4277, 1.5265, 1.8595, 2.4799, 3.4397, 4.9482, 6.9901, 9.7308, 14.0838,
    19.938,
  ],
  [
    1.1162, 1.1935, 1.4534, 1.9437, 2.6971, 3.8759, 5.4787, 7.6346, 11.0557,
    15.6674,
  ],
  [
    0.9484, 1.0141, 1.2343, 1.6518, 2.2935, 3.2965, 4.6617, 6.5007, 9.4172,
    13.3543,
  ],
  [0.8452, 0.904, 1.0996, 1.472, 2.0447, 2.939, 4.157, 5.7996, 8.4032, 11.922],
  [
    0.7775, 0.8319, 1.0125, 1.3551, 1.881, 2.7035, 3.8244, 5.3374, 7.7348,
    10.9775,
  ],
  [0.731, 0.7826, 0.952, 1.2737, 1.7681, 2.541, 3.5947, 5.0182, 7.273, 10.3248],
  [0.6992, 0.7489, 0.9109, 1.218, 1.6905, 2.4954, 0.0, 0.0, 0.0, 0.0],
  [0.6754, 0.7237, 0.8802, 1.1765, 1.6323, 2.461, 0.0, 0.0, 0.0, 0.0],
  [0.6573, 0.7045, 0.8568, 1.1449, 1.5879, 2.4347, 0.0, 0.0, 0.0, 0.0],
  [0.6435, 0.6898, 0.8389, 1.1207, 1.554, 2.4147, 0.0, 0.0, 0.0, 0.0],
  [0.6332, 0.6789, 0.8256, 1.1027, 1.5287, 2.3997, 0.0, 0.0, 0.0, 0.0],
  [0.6255, 0.6708, 0.8157, 1.0894, 1.521, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6198, 0.6647, 0.8083, 1.0794, 1.5153, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6155, 0.6602, 0.8028, 1.0719, 1.511, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6123, 0.6568, 0.7987, 1.0663, 1.5078, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6099, 0.6543, 0.7957, 1.0622, 1.5054, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    1.6462, 1.6749, 1.913, 2.5332, 3.4931, 5.0016, 7.0436, 9.7843, 14.1373,
    19.9915,
  ],
  [
    1.2879, 1.3125, 1.4994, 1.9891, 2.7425, 3.9212, 5.5241, 7.6802, 11.1015,
    15.7129,
  ],
  [
    1.0947, 1.1178, 1.2767, 1.6927, 2.3345, 3.3375, 4.7027, 6.5417, 9.4582,
    13.3956,
  ],
  [
    0.9763, 0.9984, 1.1401, 1.5101, 2.0828, 2.9771, 4.1951, 5.8377, 8.4414,
    11.9602,
  ],
  [
    0.8989, 0.9205, 1.0513, 1.3916, 1.9174, 2.7399, 3.8608, 5.3738, 7.7712,
    11.0139,
  ],
  [0.846, 0.8672, 0.992, 1.3099, 1.8033, 2.5762, 3.63, 5.0534, 7.3082, 10.3603],
  [0.8099, 0.831, 0.9508, 1.2544, 1.7256, 2.5304, 0.0, 0.0, 0.0, 0.0],
  [0.7828, 0.8037, 0.9199, 1.2129, 1.6675, 2.496, 0.0, 0.0, 0.0, 0.0],
  [0.7621, 0.7829, 0.8962, 1.1811, 1.623, 2.4698, 0.0, 0.0, 0.0, 0.0],
  [0.7463, 0.7668, 0.878, 1.1567, 1.5888, 2.4497, 0.0, 0.0, 0.0, 0.0],
  [0.7345, 0.7549, 0.8644, 1.1385, 1.5633, 2.4347, 0.0, 0.0, 0.0, 0.0],
  [0.7256, 0.7459, 0.8543, 1.1249, 1.5556, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.719, 0.7393, 0.8467, 1.1147, 1.5499, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.7141, 0.7343, 0.841, 1.1072, 1.5455, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.7104, 0.7305, 0.8368, 1.1015, 1.5423, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.7076, 0.7277, 0.8336, 1.0972, 1.5399, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    2.1331, 2.1605, 2.2113, 2.6487, 3.6083, 5.117, 7.1589, 9.8998, 14.2531,
    20.1069,
  ],
  [
    1.6709, 1.6937, 1.7367, 2.0858, 2.8393, 4.0181, 5.621, 7.777, 11.1984,
    15.8103,
  ],
  [
    1.4213, 1.4417, 1.4825, 1.7801, 2.4211, 3.4241, 4.7894, 6.6284, 9.5448,
    13.482,
  ],
  [
    1.2695, 1.2889, 1.3292, 1.5932, 2.1629, 3.0572, 4.2753, 5.9179, 8.5214,
    12.0403,
  ],
  [
    1.169, 1.1881, 1.2281, 1.4719, 1.994, 2.8158, 3.9367, 5.4497, 7.8471,
    11.0898,
  ],
  [
    1.1004, 1.1194, 1.1593, 1.3906, 1.8791, 2.6494, 3.7031, 5.1265, 7.3813,
    10.4348,
  ],
  [1.0536, 1.0726, 1.1125, 1.3345, 1.8011, 2.6034, 0.0, 0.0, 0.0, 0.0],
  [1.0186, 1.0375, 1.0772, 1.2921, 1.7423, 2.569, 0.0, 0.0, 0.0, 0.0],
  [0.9917, 1.0105, 1.0499, 1.2594, 1.697, 2.5427, 0.0, 0.0, 0.0, 0.0],
  [0.9711, 0.9898, 1.0288, 1.234, 1.6621, 2.5224, 0.0, 0.0, 0.0, 0.0],
  [0.9557, 0.9743, 1.0131, 1.2151, 1.6359, 2.5073, 0.0, 0.0, 0.0, 0.0],
  [0.9442, 0.9627, 1.0013, 1.2009, 1.6281, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9355, 0.9541, 0.9925, 1.1903, 1.6222, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9291, 0.9476, 0.9859, 1.1824, 1.6178, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9242, 0.9427, 0.9809, 1.1764, 1.6145, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9206, 0.9391, 0.9772, 1.1719, 1.612, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    2.9287, 2.9562, 3.007, 3.1176, 3.8282, 5.3367, 7.3786, 10.1194, 14.4727,
    20.3273,
  ],
  [
    2.3031, 2.3259, 2.3689, 2.4635, 3.0217, 4.2005, 5.8035, 7.9595, 11.3809,
    15.9925,
  ],
  [
    1.9612, 1.9816, 2.0204, 2.1099, 2.5861, 3.5859, 4.9511, 6.7901, 9.7065,
    13.6435,
  ],
  [
    1.752, 1.7708, 1.8084, 1.8954, 2.3196, 3.2055, 4.4236, 6.0662, 8.6698,
    12.1883,
  ],
  [
    1.6131, 1.6317, 1.6693, 1.7546, 2.1464, 2.9576, 4.0766, 5.5892, 7.9865,
    11.229,
  ],
  [
    1.5183, 1.5368, 1.5745, 1.6586, 2.0283, 2.7886, 3.839, 5.2621, 7.5147,
    10.5704,
  ],
  [1.4537, 1.4722, 1.51, 1.5934, 1.9479, 2.7423, 0.0, 0.0, 0.0, 0.0],
  [1.4052, 1.4237, 1.4614, 1.5437, 1.8867, 2.7073, 0.0, 0.0, 0.0, 0.0],
  [1.368, 1.3864, 1.4238, 1.5051, 1.8391, 2.6803, 0.0, 0.0, 0.0, 0.0],
  [1.3393, 1.3576, 1.3948, 1.475, 1.8022, 2.6595, 0.0, 0.0, 0.0, 0.0],
  [1.3179, 1.3361, 1.3731, 1.4526, 1.7745, 2.6439, 0.0, 0.0, 0.0, 0.0],
  [1.3112, 1.3295, 1.3664, 1.4457, 1.7662, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.3063, 1.3245, 1.3614, 1.4406, 1.76, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.3025, 1.3207, 1.3577, 1.4368, 1.7553, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.2997, 1.3179, 1.3549, 1.4339, 1.7518, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.2976, 1.3158, 1.3527, 1.4317, 1.7492, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    4.1989, 4.2195, 4.2703, 4.3808, 4.5976, 5.7008, 7.7429, 10.4836, 14.8368,
    20.6908,
  ],
  [
    3.2968, 3.3197, 3.3627, 3.4558, 3.6414, 4.5018, 6.1037, 8.2597, 11.6811,
    16.2926,
  ],
  [
    2.8088, 2.8292, 2.868, 2.9515, 3.1254, 3.8589, 5.2154, 7.0544, 9.9707,
    13.9075,
  ],
  [
    2.5071, 2.5259, 2.5621, 2.6425, 2.8086, 3.4645, 4.6685, 6.3088, 8.911,
    12.4294,
  ],
  [
    2.3084, 2.3262, 2.3622, 2.4416, 2.6023, 3.2076, 4.3124, 5.8214, 8.2124,
    11.4587,
  ],
  [
    2.1719, 2.1897, 2.226, 2.3047, 2.4615, 3.0321, 4.0694, 5.489, 7.7359,
    10.7929,
  ],
  [2.1343, 2.152, 2.1883, 2.2671, 2.4231, 2.9841, 0.0, 0.0, 0.0, 0.0],
  [2.106, 2.1236, 2.16, 2.2386, 2.3938, 2.9474, 0.0, 0.0, 0.0, 0.0],
  [2.0842, 2.1019, 2.1382, 2.2167, 2.3709, 2.9189, 0.0, 0.0, 0.0, 0.0],
  [2.0675, 2.0851, 2.1214, 2.1996, 2.3532, 2.8967, 0.0, 0.0, 0.0, 0.0],
  [2.055, 2.0725, 2.1088, 2.1869, 2.3398, 2.8801, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    6.0506, 6.0781, 6.129, 6.2395, 6.4561, 6.8206, 8.2996, 11.0365, 15.3899,
    21.2433,
  ],
  [
    4.7525, 4.7753, 4.8184, 4.9115, 5.0916, 5.4056, 6.5735, 8.7139, 12.1352,
    16.7462,
  ],
  [
    4.0498, 4.0702, 4.1091, 4.1926, 4.3537, 4.6452, 5.646, 7.4614, 10.3694,
    14.306,
  ],
  [
    3.6151, 3.6339, 3.6702, 3.7476, 3.9016, 4.1776, 5.0756, 6.6927, 9.2778,
    12.7977,
  ],
  [
    3.3287, 3.3466, 3.3812, 3.4575, 3.6074, 3.8725, 4.7034, 6.1917, 8.5671,
    11.8101,
  ],
  [
    3.1311, 3.1489, 3.1839, 3.2597, 3.4066, 3.6639, 4.4488, 5.8494, 8.082,
    11.136,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    8.4592, 8.4867, 8.5375, 8.648, 8.8647, 9.2254, 9.8264, 11.8935, 16.2023,
    22.0557,
  ],
  [
    6.652, 6.6749, 6.718, 6.8111, 6.9912, 7.2969, 7.8198, 9.4613, 12.8046,
    17.4151,
  ],
  [
    5.6731, 5.6935, 5.7323, 5.8159, 5.9779, 6.2579, 6.7373, 8.1492, 10.9798,
    14.9082,
  ],
  [
    5.0669, 5.0857, 5.1219, 5.2009, 5.3539, 5.6197, 6.0696, 7.3399, 9.8585,
    13.3638,
  ],
  [
    4.6676, 4.6859, 4.7218, 4.7987, 4.9476, 5.2036, 5.6333, 6.8111, 9.1271,
    12.357,
  ],
  [
    4.3937, 4.4119, 4.448, 4.5244, 4.6703, 4.9193, 5.3346, 6.449, 8.627,
    11.6689,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    11.9923, 12.0197, 12.0706, 12.181, 12.3976, 12.7581, 13.3146, 14.2899,
    17.5334, 23.3084,
  ],
  [
    9.4412, 9.4641, 9.5071, 9.6003, 9.7803, 10.0777, 10.5648, 11.4116, 13.9787,
    18.4925,
  ],
  [
    8.0581, 8.0785, 8.1173, 8.2008, 8.3603, 8.6338, 9.0843, 9.8537, 12.057,
    15.8985,
  ],
  [
    7.2008, 7.2196, 7.2558, 7.3346, 7.4871, 7.7475, 8.1726, 8.8904, 10.8699,
    14.3006,
  ],
  [
    6.6371, 6.6557, 6.6921, 6.77, 6.9185, 7.1697, 7.5771, 8.2602, 10.0934,
    13.2566,
  ],
  [
    6.2523, 6.2708, 6.3074, 6.3848, 6.5304, 6.7748, 7.1697, 7.8283, 9.5615,
    12.5419,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    17.7173, 17.7448, 17.7955, 17.906, 18.1224, 18.4828, 19.0315, 19.8689,
    21.3702, 25.6175,
  ],
  [
    13.953, 13.9759, 14.0189, 14.112, 14.292, 14.5892, 15.0407, 15.7828, 17.082,
    20.484,
  ],
  [
    11.9112, 11.9316, 11.9704, 12.0538, 12.2132, 12.4749, 12.895, 13.5799,
    14.7579, 17.7001,
  ],
  [
    10.6453, 10.664, 10.7002, 10.7775, 10.9275, 11.1777, 11.5767, 12.2222,
    13.3198, 15.9775,
  ],
  [
    9.8126, 9.8312, 9.8675, 9.9442, 10.0904, 10.3325, 10.7166, 11.3347, 12.3782,
    14.8488,
  ],
  [
    9.2441, 9.2626, 9.2992, 9.3753, 9.5188, 9.7549, 10.1282, 10.7271, 11.7327,
    14.0748,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
]
var joint_life_2_rates = [
  [0.6979, 0.761, 0.8954, 1.1394, 1.4629, 1.8574, 2.2353, 0.0, 0.0, 0.0],
  [0.5459, 0.5962, 0.7019, 0.8932, 1.1515, 1.4709, 2.0244, 0.0, 0.0, 0.0],
  [0.4652, 0.5077, 0.5972, 0.7603, 0.983, 1.2617, 1.91, 0.0, 0.0, 0.0],
  [0.4154, 0.4535, 0.5338, 0.6787, 0.8791, 1.133, 1.8399, 0.0, 0.0, 0.0],
  [0.3828, 0.4186, 0.492, 0.626, 0.8113, 1.0504, 1.7958, 0.0, 0.0, 0.0],
  [0.3606, 0.3943, 0.4635, 0.5897, 0.7651, 0.9934, 1.7649, 0.0, 0.0, 0.0],
  [0.3454, 0.3778, 0.4442, 0.5652, 0.7339, 0.9759, 0.0, 0.0, 0.0, 0.0],
  [0.3341, 0.3655, 0.4297, 0.5469, 0.7106, 0.9629, 0.0, 0.0, 0.0, 0.0],
  [0.3256, 0.3562, 0.4188, 0.533, 0.693, 0.953, 0.0, 0.0, 0.0, 0.0],
  [0.3192, 0.3491, 0.4106, 0.5225, 0.6795, 0.9454, 0.0, 0.0, 0.0, 0.0],
  [0.3143, 0.3439, 0.4044, 0.5147, 0.6695, 0.9398, 0.0, 0.0, 0.0, 0.0],
  [0.3108, 0.34, 0.3999, 0.509, 0.6663, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3081, 0.337, 0.3965, 0.5047, 0.664, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3061, 0.3349, 0.394, 0.5015, 0.6622, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3046, 0.3332, 0.3921, 0.4992, 0.6609, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3035, 0.332, 0.3907, 0.4974, 0.66, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.742, 0.8019, 0.9272, 1.1614, 1.4844, 1.8782, 2.2659, 0.0, 0.0, 0.0],
  [0.5831, 0.6298, 0.7273, 0.9115, 1.1696, 1.4709, 2.0244, 0.0, 0.0, 0.0],
  [0.4969, 0.5379, 0.6197, 0.7765, 0.9989, 1.2617, 1.91, 0.0, 0.0, 0.0],
  [0.4441, 0.4809, 0.5547, 0.6944, 0.8938, 1.133, 1.8399, 0.0, 0.0, 0.0],
  [0.4095, 0.4437, 0.5117, 0.6404, 0.8254, 1.0504, 1.7958, 0.0, 0.0, 0.0],
  [0.3859, 0.4182, 0.4824, 0.6038, 0.7789, 0.9934, 1.7649, 0.0, 0.0, 0.0],
  [0.3698, 0.4008, 0.4624, 0.5789, 0.7474, 0.9759, 0.0, 0.0, 0.0, 0.0],
  [0.3578, 0.3878, 0.4476, 0.5604, 0.7239, 0.9629, 0.0, 0.0, 0.0, 0.0],
  [0.349, 0.378, 0.4363, 0.5463, 0.706, 0.953, 0.0, 0.0, 0.0, 0.0],
  [0.3421, 0.3705, 0.4277, 0.5356, 0.6924, 0.9454, 0.0, 0.0, 0.0, 0.0],
  [0.3369, 0.3649, 0.4214, 0.5277, 0.6823, 0.9398, 0.0, 0.0, 0.0, 0.0],
  [0.3331, 0.3608, 0.4166, 0.5218, 0.6791, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3302, 0.3577, 0.4131, 0.5175, 0.6766, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3281, 0.3554, 0.4105, 0.5143, 0.6749, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3265, 0.3537, 0.4086, 0.5119, 0.6735, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3253, 0.3524, 0.4071, 0.5101, 0.6726, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.8248, 0.8684, 0.9654, 1.1869, 1.5091, 1.9017, 2.3012, 0.0, 0.0, 0.0],
  [0.6477, 0.6848, 0.7601, 0.9332, 1.1906, 1.4884, 2.0535, 0.0, 0.0, 0.0],
  [0.5533, 0.5851, 0.6496, 0.7967, 1.0181, 1.2777, 1.9386, 0.0, 0.0, 0.0],
  [0.4951, 0.5239, 0.5828, 0.7142, 0.9124, 1.148, 1.8681, 0.0, 0.0, 0.0],
  [0.457, 0.484, 0.5386, 0.6599, 0.8438, 1.0649, 1.8237, 0.0, 0.0, 0.0],
  [0.4311, 0.4566, 0.5085, 0.623, 0.7972, 1.0075, 1.7925, 0.0, 0.0, 0.0],
  [0.4134, 0.438, 0.488, 0.5981, 0.7656, 0.9898, 0.0, 0.0, 0.0, 0.0],
  [0.4002, 0.4241, 0.4727, 0.5794, 0.742, 0.9767, 0.0, 0.0, 0.0, 0.0],
  [0.3902, 0.4136, 0.4611, 0.5651, 0.724, 0.9666, 0.0, 0.0, 0.0, 0.0],
  [0.3826, 0.4055, 0.4521, 0.5543, 0.7103, 0.959, 0.0, 0.0, 0.0, 0.0],
  [0.3769, 0.3995, 0.4455, 0.5462, 0.7001, 0.9533, 0.0, 0.0, 0.0, 0.0],
  [0.3727, 0.3951, 0.4406, 0.5403, 0.6968, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3696, 0.3917, 0.437, 0.5359, 0.6944, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3672, 0.3893, 0.4343, 0.5326, 0.6926, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3655, 0.3874, 0.4323, 0.5302, 0.6913, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3642, 0.3861, 0.4308, 0.5284, 0.6903, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.0052, 1.032, 1.0717, 1.2294, 1.5472, 1.9406, 2.3622, 0.0, 0.0, 0.0],
  [0.7912, 0.8149, 0.8494, 0.9734, 1.2255, 1.4884, 2.0535, 0.0, 0.0, 0.0],
  [0.6754, 0.697, 0.7285, 0.8357, 1.0523, 1.2777, 1.9386, 0.0, 0.0, 0.0],
  [0.6046, 0.6248, 0.6544, 0.7521, 0.9462, 1.148, 1.8681, 0.0, 0.0, 0.0],
  [0.5586, 0.5778, 0.6061, 0.6972, 0.8774, 1.0649, 1.8237, 0.0, 0.0, 0.0],
  [0.5272, 0.5457, 0.5731, 0.6598, 0.8306, 1.0075, 1.7925, 0.0, 0.0, 0.0],
  [0.506, 0.5239, 0.5508, 0.6345, 0.7989, 0.9898, 0.0, 0.0, 0.0, 0.0],
  [0.4901, 0.5076, 0.534, 0.6154, 0.7751, 0.9767, 0.0, 0.0, 0.0, 0.0],
  [0.478, 0.4951, 0.5212, 0.6008, 0.7569, 0.9666, 0.0, 0.0, 0.0, 0.0],
  [0.4687, 0.4856, 0.5113, 0.5896, 0.7428, 0.959, 0.0, 0.0, 0.0, 0.0],
  [0.4619, 0.4785, 0.504, 0.5813, 0.7325, 0.9533, 0.0, 0.0, 0.0, 0.0],
  [0.4568, 0.4733, 0.4986, 0.5752, 0.7292, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.453, 0.4694, 0.4946, 0.5706, 0.7267, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4502, 0.4665, 0.4916, 0.5673, 0.7249, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4481, 0.4644, 0.4894, 0.5648, 0.7236, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4466, 0.4628, 0.4877, 0.563, 0.7226, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.2951, 1.3185, 1.3462, 1.3988, 1.6295, 2.0232, 2.4825, 0.0, 0.0, 0.0],
  [1.0225, 1.0425, 1.0683, 1.117, 1.3031, 1.5102, 2.0872, 0.0, 0.0, 0.0],
  [0.8743, 0.8931, 0.9175, 0.9636, 1.1266, 1.2982, 1.9718, 0.0, 0.0, 0.0],
  [0.7839, 0.8016, 0.825, 0.8693, 1.0179, 1.1677, 1.901, 0.0, 0.0, 0.0],
  [0.7251, 0.742, 0.7649, 0.808, 0.9473, 1.0843, 1.8563, 0.0, 0.0, 0.0],
  [0.6851, 0.7015, 0.724, 0.7662, 0.8993, 1.0267, 1.8249, 0.0, 0.0, 0.0],
  [0.658, 0.674, 0.6962, 0.738, 0.8668, 1.009, 0.0, 0.0, 0.0, 0.0],
  [0.6378, 0.6534, 0.6754, 0.7166, 0.8422, 0.9957, 0.0, 0.0, 0.0, 0.0],
  [0.6223, 0.6377, 0.6594, 0.7001, 0.8231, 0.9856, 0.0, 0.0, 0.0, 0.0],
  [0.6104, 0.6256, 0.6471, 0.6873, 0.8084, 0.9779, 0.0, 0.0, 0.0, 0.0],
  [0.6016, 0.6166, 0.638, 0.678, 0.7984, 0.9722, 0.0, 0.0, 0.0, 0.0],
  [0.5988, 0.6138, 0.6351, 0.6749, 0.7949, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5967, 0.6116, 0.6329, 0.6727, 0.7924, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5952, 0.61, 0.6313, 0.671, 0.7905, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.594, 0.6089, 0.6301, 0.6698, 0.7891, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5932, 0.608, 0.6292, 0.6689, 0.7881, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.6897, 1.7124, 1.7402, 1.7878, 1.8981, 2.2162, 2.6994, 0.0, 0.0, 0.0],
  [1.3405, 1.3604, 1.3858, 1.4309, 1.5302, 1.5102, 2.0872, 0.0, 0.0, 0.0],
  [1.1518, 1.1704, 1.1945, 1.2377, 1.33, 1.2982, 1.9718, 0.0, 0.0, 0.0],
  [1.0362, 1.0538, 1.0769, 1.1187, 1.2063, 1.1677, 1.901, 0.0, 0.0, 0.0],
  [0.9611, 0.9779, 1.0005, 1.0413, 1.1257, 1.0843, 1.8563, 0.0, 0.0, 0.0],
  [0.91, 0.9263, 0.9485, 0.9898, 1.0723, 1.0267, 1.8249, 0.0, 0.0, 0.0],
  [0.8949, 0.9109, 0.933, 0.9742, 1.0564, 1.009, 0.0, 0.0, 0.0, 0.0],
  [0.8836, 0.8995, 0.9214, 0.9625, 1.0443, 0.9957, 0.0, 0.0, 0.0, 0.0],
  [0.8749, 0.8907, 0.9125, 0.9535, 1.035, 0.9856, 0.0, 0.0, 0.0, 0.0],
  [0.8683, 0.8839, 0.9057, 0.9466, 1.0277, 0.9779, 0.0, 0.0, 0.0, 0.0],
  [0.8634, 0.8789, 0.9007, 0.9403, 1.0224, 0.9722, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [2.1378, 2.1685, 2.2068, 2.2716, 2.4011, 2.6453, 3.0945, 0.0, 0.0, 0.0],
  [1.9422, 1.9723, 2.0095, 2.0736, 2.2012, 1.547, 2.1482, 0.0, 0.0, 0.0],
  [1.8371, 1.8664, 1.9028, 1.9664, 2.0925, 1.3336, 2.0327, 0.0, 0.0, 0.0],
  [1.7726, 1.8013, 1.8373, 1.9004, 2.0254, 1.2025, 1.9616, 0.0, 0.0, 0.0],
  [1.7306, 1.759, 1.7945, 1.8575, 1.9817, 1.1188, 1.9168, 0.0, 0.0, 0.0],
  [1.7021, 1.7301, 1.7655, 1.8283, 1.9532, 1.061, 1.8854, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
]
var joint_life_3_rates = [
  [
    0.8705, 0.9778, 1.1852, 1.5512, 2.1293, 3.0968, 4.5505, 6.7949, 10.2547,
    15.0127,
  ],
  [
    0.6829, 0.7704, 0.9365, 1.2292, 1.703, 2.5036, 3.7121, 5.5999, 8.5446,
    12.693,
  ],
  [
    0.5828, 0.6593, 0.8028, 1.0563, 1.4729, 2.1781, 3.2494, 4.9362, 7.5838,
    11.3791,
  ],
  [
    0.5217, 0.5912, 0.721, 0.9506, 1.3332, 1.9786, 2.9625, 4.5222, 6.9827,
    10.5586,
  ],
  [
    0.482, 0.547, 0.6678, 0.8824, 1.242, 1.8482, 2.7763, 4.2528, 6.5924,
    10.0318,
  ],
  [
    0.4548, 0.5169, 0.6316, 0.8364, 1.1803, 1.7597, 2.6503, 4.0707, 6.3305,
    9.6847,
  ],
  [0.4365, 0.4969, 0.6072, 0.8055, 1.1388, 1.7355, 0.0, 0.0, 0.0, 0.0],
  [0.4228, 0.4815, 0.5888, 0.7821, 1.1071, 1.717, 0.0, 0.0, 0.0, 0.0],
  [0.4123, 0.4697, 0.5746, 0.764, 1.0824, 1.7026, 0.0, 0.0, 0.0, 0.0],
  [0.4043, 0.4607, 0.5638, 0.75, 1.0632, 1.6914, 0.0, 0.0, 0.0, 0.0],
  [0.3984, 0.454, 0.5558, 0.7397, 1.0491, 1.6832, 0.0, 0.0, 0.0, 0.0],
  [0.394, 0.449, 0.5499, 0.7321, 1.0448, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3907, 0.4453, 0.5455, 0.7265, 1.0417, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3883, 0.4426, 0.5422, 0.7223, 1.0394, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3865, 0.4405, 0.5398, 0.7193, 1.0377, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.3851, 0.439, 0.5381, 0.7171, 1.0365, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9563, 1.0597, 1.26, 1.6117, 2.19, 3.162, 4.6233, 6.8804, 10.3389, 15.0851],
  [
    0.7547, 0.8392, 1.0003, 1.2826, 1.7565, 2.5608, 3.7747, 5.6723, 8.6165,
    12.7566,
  ],
  [
    0.6465, 0.7217, 0.8601, 1.1052, 1.5219, 2.23, 3.3059, 5.001, 7.6486,
    11.4375,
  ],
  [
    0.5802, 0.6488, 0.774, 0.9964, 1.3793, 2.0272, 3.015, 4.5821, 7.043,
    10.6137,
  ],
  [
    0.5371, 0.6013, 0.718, 0.9262, 1.286, 1.8946, 2.8262, 4.3095, 6.6499,
    10.0851,
  ],
  [0.5077, 0.569, 0.68, 0.8789, 1.2231, 1.8046, 2.6985, 4.1255, 6.3862, 9.7369],
  [0.4877, 0.547, 0.6547, 0.8471, 1.1805, 1.78, 0.0, 0.0, 0.0, 0.0],
  [0.4727, 0.5304, 0.6352, 0.8228, 1.148, 1.7612, 0.0, 0.0, 0.0, 0.0],
  [0.4616, 0.5176, 0.6201, 0.8039, 1.1226, 1.7466, 0.0, 0.0, 0.0, 0.0],
  [0.4528, 0.5078, 0.6086, 0.7894, 1.1029, 1.7352, 0.0, 0.0, 0.0, 0.0],
  [0.4462, 0.5005, 0.6, 0.7786, 1.0883, 1.7268, 0.0, 0.0, 0.0, 0.0],
  [0.4414, 0.495, 0.5937, 0.7707, 1.084, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4377, 0.491, 0.589, 0.7649, 1.0808, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.435, 0.488, 0.5856, 0.7606, 1.0784, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.433, 0.4858, 0.583, 0.7574, 1.0766, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.4315, 0.4842, 0.5812, 0.7551, 1.0754, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    1.1259, 1.2248, 1.4014, 1.7253, 2.292, 3.269, 4.7422, 7.0123, 10.465,
    15.192,
  ],
  [
    0.8946, 0.9744, 1.1177, 1.3799, 1.8453, 2.6537, 3.8767, 5.7843, 8.7249,
    12.8515,
  ],
  [
    0.7688, 0.839, 0.9651, 1.1929, 1.6026, 2.3142, 3.3977, 5.1013, 7.7468,
    11.5254,
  ],
  [
    0.6916, 0.7558, 0.8704, 1.0779, 1.455, 2.1059, 3.1003, 4.6752, 7.1348,
    10.6973,
  ],
  [
    0.6414, 0.7017, 0.8089, 1.0037, 1.3583, 1.9696, 2.9072, 4.3979, 6.7376,
    10.1661,
  ],
  [
    0.6072, 0.6649, 0.7672, 0.9537, 1.293, 1.8771, 2.7767, 4.2108, 6.4714,
    9.8165,
  ],
  [0.5841, 0.6399, 0.7389, 0.92, 1.249, 1.8519, 0.0, 0.0, 0.0, 0.0],
  [0.5666, 0.6209, 0.7175, 0.8943, 1.2153, 1.8325, 0.0, 0.0, 0.0, 0.0],
  [0.5532, 0.6067, 0.7008, 0.8742, 1.1888, 1.8175, 0.0, 0.0, 0.0, 0.0],
  [0.5429, 0.5955, 0.6881, 0.8587, 1.1683, 1.8057, 0.0, 0.0, 0.0, 0.0],
  [0.5353, 0.5872, 0.6787, 0.8474, 1.1532, 1.7971, 0.0, 0.0, 0.0, 0.0],
  [0.5296, 0.5811, 0.6718, 0.839, 1.1487, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5254, 0.5765, 0.6667, 0.8328, 1.1454, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5223, 0.5732, 0.6629, 0.8283, 1.1429, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.52, 0.5707, 0.6601, 0.825, 1.1411, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.5184, 0.5689, 0.6581, 0.8226, 1.1398, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    1.4252, 1.5175, 1.6763, 1.9548, 2.4871, 3.4553, 4.9528, 7.215, 10.6567,
    15.3523,
  ],
  [
    1.1366, 1.2149, 1.3438, 1.5724, 2.0127, 2.8152, 4.0567, 5.9588, 8.8922,
    12.9962,
  ],
  [0.9822, 1.0503, 1.1636, 1.3649, 1.7571, 2.4611, 3.56, 5.2596, 7.9, 11.6612],
  [
    0.8867, 0.949, 1.0526, 1.2372, 1.5971, 2.2437, 3.2516, 4.8233, 7.2794,
    10.8279,
  ],
  [
    0.825, 0.8836, 0.9809, 1.155, 1.4942, 2.1017, 3.0518, 4.5399, 6.8771,
    10.2939,
  ],
  [
    0.7834, 0.8395, 0.9326, 1.0999, 1.4251, 2.0058, 2.9169, 4.3489, 6.6079,
    9.9429,
  ],
  [0.7554, 0.8098, 0.9001, 1.0642, 1.3786, 1.9797, 0.0, 0.0, 0.0, 0.0],
  [0.7341, 0.7871, 0.8751, 1.0358, 1.3428, 1.9598, 0.0, 0.0, 0.0, 0.0],
  [0.7175, 0.7693, 0.8557, 1.0135, 1.3146, 1.9441, 0.0, 0.0, 0.0, 0.0],
  [0.7046, 0.7556, 0.8406, 0.9961, 1.2926, 1.9319, 0.0, 0.0, 0.0, 0.0],
  [0.6951, 0.7455, 0.8295, 0.9834, 1.2765, 1.923, 0.0, 0.0, 0.0, 0.0],
  [0.6882, 0.738, 0.8214, 0.9741, 1.2717, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.683, 0.7325, 0.8155, 0.9672, 1.2682, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6792, 0.7284, 0.8111, 0.9622, 1.2656, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6764, 0.7255, 0.8079, 0.9586, 1.2638, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6743, 0.7233, 0.8055, 0.956, 1.2624, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    1.9603, 2.0445, 2.1945, 2.4531, 2.9414, 3.8876, 5.3618, 7.6135, 11.0024,
    15.6402,
  ],
  [
    1.5789, 1.6496, 1.7737, 1.9877, 2.4003, 3.1779, 4.4085, 6.3035, 9.199,
    13.2609,
  ],
  [
    1.372, 1.4349, 1.5443, 1.7365, 2.1012, 2.7905, 3.8784, 5.573, 8.1839,
    11.9122,
  ],
  [
    1.2442, 1.3021, 1.4023, 1.5793, 1.9154, 2.5476, 3.5485, 5.1169, 7.5488,
    11.0708,
  ],
  [
    1.1617, 1.2165, 1.3126, 1.4782, 1.7959, 2.3908, 3.3349, 4.8209, 7.1377,
    10.5325,
  ],
  [
    1.1074, 1.1601, 1.2507, 1.4103, 1.7156, 2.2848, 3.1909, 4.6219, 6.8633,
    10.1796,
  ],
  [1.0698, 1.121, 1.209, 1.3646, 1.6627, 2.2573, 0.0, 0.0, 0.0, 0.0],
  [1.041, 1.091, 1.1769, 1.3294, 1.621, 2.2361, 0.0, 0.0, 0.0, 0.0],
  [1.0184, 1.0673, 1.1517, 1.3016, 1.588, 2.2193, 0.0, 0.0, 0.0, 0.0],
  [1.0007, 1.0489, 1.132, 1.2799, 1.5622, 2.2062, 0.0, 0.0, 0.0, 0.0],
  [0.9877, 1.0353, 1.1176, 1.264, 1.5433, 2.1966, 0.0, 0.0, 0.0, 0.0],
  [0.9838, 1.0313, 1.1133, 1.2593, 1.5379, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.981, 1.0283, 1.1101, 1.2559, 1.5339, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9788, 1.0261, 1.1078, 1.2534, 1.5311, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9773, 1.0245, 1.1061, 1.2515, 1.529, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9762, 1.0233, 1.1048, 1.2502, 1.5274, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    2.9001, 2.9979, 3.1519, 3.3987, 3.8749, 4.7678, 6.2049, 8.3844, 11.681,
    16.2158,
  ],
  [
    2.3577, 2.4389, 2.5669, 2.7735, 3.1685, 3.9167, 5.1129, 6.9626, 9.7936,
    13.7824,
  ],
  [
    2.0589, 2.1305, 2.2432, 2.427, 2.7827, 3.4442, 4.5078, 6.1678, 8.7292,
    12.402,
  ],
  [
    1.8736, 1.9391, 2.0424, 2.212, 2.5409, 3.1497, 4.1308, 5.6712, 8.0633,
    11.5413,
  ],
  [
    1.7535, 1.8151, 1.9122, 2.0748, 2.3847, 2.9591, 3.8865, 5.3492, 7.6331,
    10.9919,
  ],
  [
    1.6719, 1.7322, 1.8253, 1.9808, 2.2792, 2.8301, 3.7217, 5.1328, 7.3464,
    10.633,
  ],
  [1.6514, 1.7099, 1.802, 1.9559, 2.252, 2.7969, 0.0, 0.0, 0.0, 0.0],
  [1.6347, 1.6927, 1.784, 1.9368, 2.231, 2.7713, 0.0, 0.0, 0.0, 0.0],
  [1.6216, 1.6792, 1.7699, 1.9218, 2.2144, 2.7512, 0.0, 0.0, 0.0, 0.0],
  [1.6114, 1.6687, 1.7589, 1.9101, 2.2014, 2.7355, 0.0, 0.0, 0.0, 0.0],
  [1.6039, 1.6609, 1.7508, 1.9015, 2.1919, 2.7241, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    4.418, 4.5159, 4.6728, 4.9151, 5.3542, 6.2045, 7.5607, 9.6342, 12.7931,
    17.1719,
  ],
  [
    3.6071, 3.6927, 3.823, 4.0267, 4.4014, 5.1112, 6.2549, 8.0304, 10.7652,
    14.6444,
  ],
  [
    3.1656, 3.2377, 3.3527, 3.5344, 3.8715, 4.5054, 5.5288, 7.1316, 9.6199,
    13.2095,
  ],
  [
    2.8891, 2.9552, 3.0606, 3.2288, 3.542, 4.1282, 5.0763, 6.5703, 8.904,
    12.3165,
  ],
  [
    2.7096, 2.7718, 2.871, 3.0307, 3.3286, 3.8838, 4.7833, 6.207, 8.4428,
    11.7488,
  ],
  [
    2.5881, 2.6477, 2.7428, 2.8971, 3.1848, 3.7189, 4.5863, 5.9639, 8.1371,
    11.3799,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    6.7292, 6.8237, 6.9677, 7.1884, 7.5876, 8.3596, 9.6107, 11.5359, 14.5003,
    18.6565,
  ],
  [
    5.5477, 5.6268, 5.7478, 5.9357, 6.2809, 6.9407, 8.0108, 9.6811, 12.2785,
    15.9996,
  ],
  [
    4.8912, 4.9616, 5.0694, 5.2387, 5.5525, 6.1478, 7.1143, 8.636, 11.0196,
    14.4896,
  ],
  [
    4.4817, 4.5466, 4.6461, 4.8039, 5.0978, 5.6526, 6.5545, 7.983, 10.2335,
    13.5523,
  ],
  [
    4.2153, 4.2767, 4.3709, 4.5215, 4.8029, 5.3315, 6.1922, 7.5615, 9.7294,
    12.96,
  ],
  [
    4.0354, 4.0944, 4.1851, 4.3314, 4.6046, 5.1158, 5.9497, 7.2812, 9.3978,
    12.5789,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    10.2034, 10.2871, 10.414, 10.6066, 10.9532, 11.6332, 12.7472, 14.4771,
    17.1812, 21.0335,
  ],
  [
    8.5028, 8.5745, 8.6835, 8.8512, 9.1588, 9.7543, 10.7282, 12.2606, 14.6753,
    18.1821,
  ],
  [
    7.5475, 7.6121, 7.7108, 7.8644, 8.1488, 8.6949, 9.5881, 11.0047, 13.2506,
    16.5608,
  ],
  [
    6.9498, 7.0101, 7.1022, 7.2471, 7.517, 8.0321, 8.8754, 10.2203, 12.3634,
    15.5592,
  ],
  [
    6.5619, 6.6193, 6.7073, 6.8471, 7.1081, 7.6039, 8.4162, 9.7173, 11.7992,
    14.9332,
  ],
  [
    6.3016, 6.3573, 6.4427, 6.5794, 6.835, 7.3186, 8.1118, 9.3864, 11.4329,
    14.5363,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    14.9661, 15.0375, 15.1453, 15.3067, 15.5955, 16.1726, 17.1303, 18.6356,
    21.0339, 24.521,
  ],
  [
    12.6544, 12.7175, 12.8131, 12.9586, 13.2239, 13.7464, 14.6104, 15.983,
    18.1825, 21.4328,
  ],
  [
    11.3453, 11.4034, 11.4918, 11.6281, 11.8797, 12.3701, 13.1799, 14.4755,
    16.561, 19.682,
  ],
  [
    10.5277, 10.5828, 10.6668, 10.7977, 11.041, 11.5121, 12.2896, 13.5397,
    15.5594, 18.6122,
  ],
  [
    10.0029, 10.0562, 10.1375, 10.2655, 10.5046, 10.9644, 11.7235, 12.9484,
    14.9333, 17.9564,
  ],
  [
    9.6572, 9.7094, 9.7892, 9.9159, 10.1529, 10.6066, 11.3558, 12.5678, 14.5364,
    17.5516,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
]
var joint_life_4_rates = [
  [2.235, 2.5207, 3.2712, 4.5971, 6.5203, 9.3766, 12.8453, 0.0, 0.0, 0.0],
  [1.7526, 1.9793, 2.5709, 3.6192, 5.1527, 7.4475, 10.2818, 0.0, 0.0, 0.0],
  [1.4912, 1.6884, 2.1937, 3.0882, 4.4081, 6.3925, 8.8623, 0.0, 0.0, 0.0],
  [1.3315, 1.5083, 1.9586, 2.7601, 3.9472, 5.738, 7.9777, 0.0, 0.0, 0.0],
  [1.2249, 1.3887, 1.804, 2.5445, 3.6442, 5.3075, 7.3949, 0.0, 0.0, 0.0],
  [1.1527, 1.3067, 1.6975, 2.3962, 3.436, 5.012, 6.995, 0.0, 0.0, 0.0],
  [1.1026, 1.2506, 1.6247, 2.2946, 3.2933, 4.9278, 0.0, 0.0, 0.0, 0.0],
  [1.065, 1.2086, 1.5704, 2.2188, 3.1871, 4.8653, 0.0, 0.0, 0.0, 0.0],
  [1.0364, 1.1766, 1.5292, 2.1612, 3.1067, 4.818, 0.0, 0.0, 0.0, 0.0],
  [1.0147, 1.1523, 1.4979, 2.1175, 3.0456, 4.7821, 0.0, 0.0, 0.0, 0.0],
  [0.9986, 1.1342, 1.4747, 2.0852, 3.0005, 4.7557, 0.0, 0.0, 0.0, 0.0],
  [0.9866, 1.1208, 1.4576, 2.0614, 2.9868, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9776, 1.1109, 1.4448, 2.0438, 2.9767, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9711, 1.1035, 1.4354, 2.0308, 2.9692, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9662, 1.0981, 1.4285, 2.0212, 2.9637, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.9625, 1.094, 1.4234, 2.0142, 2.9597, 0.0, 0.0, 0.0, 0.0, 0.0],
  [2.3576, 2.6042, 3.3528, 4.6751, 6.5931, 9.4419, 12.9024, 0.0, 0.0, 0.0],
  [1.8533, 2.0497, 2.6401, 3.686, 5.2161, 7.4475, 10.2818, 0.0, 0.0, 0.0],
  [1.58, 1.7522, 2.2557, 3.1486, 4.4659, 6.3925, 8.8623, 0.0, 0.0, 0.0],
  [1.4134, 1.5685, 2.0161, 2.8162, 4.0012, 5.738, 7.9777, 0.0, 0.0, 0.0],
  [1.3041, 1.447, 1.8587, 2.5978, 3.6958, 5.3075, 7.3949, 0.0, 0.0, 0.0],
  [1.2282, 1.3639, 1.751, 2.4478, 3.486, 5.012, 6.995, 0.0, 0.0, 0.0],
  [1.1765, 1.3072, 1.6779, 2.3457, 3.343, 4.9278, 0.0, 0.0, 0.0, 0.0],
  [1.1376, 1.2648, 1.6233, 2.2697, 3.2368, 4.8653, 0.0, 0.0, 0.0, 0.0],
  [1.108, 1.2323, 1.5817, 2.2119, 3.1562, 4.818, 0.0, 0.0, 0.0, 0.0],
  [1.0853, 1.2074, 1.5499, 2.1678, 3.0949, 4.7821, 0.0, 0.0, 0.0, 0.0],
  [1.0685, 1.189, 1.5264, 2.1353, 3.0497, 4.7557, 0.0, 0.0, 0.0, 0.0],
  [1.056, 1.1753, 1.5091, 2.1113, 3.0359, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.0467, 1.1651, 1.4962, 2.0936, 3.0258, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.0398, 1.1575, 1.4867, 2.0805, 3.0183, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.0347, 1.152, 1.4796, 2.0709, 3.0127, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.0309, 1.1479, 1.4744, 2.0638, 3.0087, 0.0, 0.0, 0.0, 0.0, 0.0],
  [2.8542, 2.9443, 3.4891, 4.8059, 6.7152, 9.5517, 12.9978, 0.0, 0.0, 0.0],
  [2.2528, 2.3284, 2.7564, 3.7986, 5.3228, 7.506, 10.3344, 0.0, 0.0, 0.0],
  [1.923, 1.9913, 2.3613, 3.2505, 4.5634, 6.4465, 8.9119, 0.0, 0.0, 0.0],
  [1.7208, 1.7863, 2.1158, 2.9113, 4.0926, 5.7888, 8.0253, 0.0, 0.0, 0.0],
  [1.5877, 1.6515, 1.9566, 2.6893, 3.7834, 5.3565, 7.4412, 0.0, 0.0, 0.0],
  [1.497, 1.5597, 1.8484, 2.5385, 3.5728, 5.0602, 7.0404, 0.0, 0.0, 0.0],
  [1.4353, 1.4973, 1.775, 2.4365, 3.4303, 4.9758, 0.0, 0.0, 0.0, 0.0],
  [1.3891, 1.4503, 1.7199, 2.3603, 3.3241, 4.9133, 0.0, 0.0, 0.0, 0.0],
  [1.3538, 1.4143, 1.6776, 2.302, 3.2433, 4.8659, 0.0, 0.0, 0.0, 0.0],
  [1.3267, 1.3866, 1.6452, 2.2575, 3.1817, 4.83, 0.0, 0.0, 0.0, 0.0],
  [1.3066, 1.3661, 1.6212, 2.2247, 3.1364, 4.8035, 0.0, 0.0, 0.0, 0.0],
  [1.2917, 1.3509, 1.6035, 2.2005, 3.1225, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.2807, 1.3396, 1.5904, 2.1826, 3.1123, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.2725, 1.3312, 1.5807, 2.1694, 3.1048, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.2664, 1.325, 1.5735, 2.1597, 3.0993, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.2619, 1.3204, 1.5682, 2.1526, 3.0952, 0.0, 0.0, 0.0, 0.0, 0.0],
  [3.8944, 3.982, 4.1147, 5.0361, 6.93, 9.7445, 13.1656, 0.0, 0.0, 0.0],
  [3.0795, 3.1532, 3.2667, 3.9982, 5.5118, 7.506, 10.3344, 0.0, 0.0, 0.0],
  [2.6332, 2.6991, 2.8045, 3.435, 4.7371, 6.4465, 8.9119, 0.0, 0.0, 0.0],
  [2.357, 2.4182, 2.5214, 3.0888, 4.258, 5.7888, 8.0253, 0.0, 0.0, 0.0],
  [2.1762, 2.2363, 2.3381, 2.8652, 3.9473, 5.3565, 7.4412, 0.0, 0.0, 0.0],
  [2.0537, 2.113, 2.214, 2.7141, 3.7375, 5.0602, 7.0404, 0.0, 0.0, 0.0],
  [1.9707, 2.0294, 2.13, 2.6121, 3.5957, 4.9758, 0.0, 0.0, 0.0, 0.0],
  [1.9085, 1.9666, 2.0665, 2.5351, 3.4893, 4.9133, 0.0, 0.0, 0.0, 0.0],
  [1.8608, 1.9184, 2.0175, 2.4757, 3.4079, 4.8659, 0.0, 0.0, 0.0, 0.0],
  [1.8244, 1.8814, 1.9798, 2.43, 3.3456, 4.83, 0.0, 0.0, 0.0, 0.0],
  [1.7974, 1.8541, 1.952, 2.3964, 3.2999, 4.8035, 0.0, 0.0, 0.0, 0.0],
  [1.7774, 1.8339, 1.9314, 2.3717, 3.2859, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.7625, 1.8189, 1.9163, 2.3535, 3.2756, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.7516, 1.8078, 1.9051, 2.3401, 3.2681, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.7435, 1.7996, 1.8969, 2.3303, 3.2625, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.7375, 1.7936, 1.8908, 2.3231, 3.2585, 0.0, 0.0, 0.0, 0.0, 0.0],
  [5.5411, 5.6234, 5.7485, 5.9675, 7.3276, 10.1016, 13.4764, 0.0, 0.0, 0.0],
  [4.3919, 4.4623, 4.5706, 4.7708, 5.8718, 7.6042, 10.423, 0.0, 0.0, 0.0],
  [3.7655, 3.8289, 3.9291, 4.1229, 5.0816, 6.5375, 8.9957, 0.0, 0.0, 0.0],
  [3.377, 3.4368, 3.5342, 3.7246, 4.5954, 5.875, 8.1056, 0.0, 0.0, 0.0],
  [3.1235, 3.1822, 3.2788, 3.4669, 4.2809, 5.4398, 7.5195, 0.0, 0.0, 0.0],
  [2.9516, 3.0096, 3.1058, 3.2927, 4.0683, 5.1434, 7.1195, 0.0, 0.0, 0.0],
  [2.835, 2.8926, 2.9887, 3.1749, 3.9246, 5.0591, 0.0, 0.0, 0.0, 0.0],
  [2.7477, 2.8048, 2.9004, 3.0855, 3.8158, 4.9966, 0.0, 0.0, 0.0, 0.0],
  [2.6809, 2.7375, 2.8325, 3.0163, 3.7317, 4.9492, 0.0, 0.0, 0.0, 0.0],
  [2.6297, 2.6859, 2.7804, 2.9628, 3.6671, 4.9131, 0.0, 0.0, 0.0, 0.0],
  [2.592, 2.6479, 2.7421, 2.9236, 3.6198, 4.8866, 0.0, 0.0, 0.0, 0.0],
  [2.5804, 2.6362, 2.7303, 2.9116, 3.6055, 0.0, 0.0, 0.0, 0.0, 0.0],
  [2.5718, 2.6275, 2.7215, 2.9028, 3.595, 0.0, 0.0, 0.0, 0.0, 0.0],
  [2.5654, 2.6211, 2.7151, 2.8963, 3.5873, 0.0, 0.0, 0.0, 0.0, 0.0],
  [2.5607, 2.6164, 2.7104, 2.8916, 3.5817, 0.0, 0.0, 0.0, 0.0, 0.0],
  [2.5573, 2.6129, 2.7069, 2.8881, 3.5776, 0.0, 0.0, 0.0, 0.0, 0.0],
  [8.0179, 8.093, 8.207, 8.406, 8.8018, 10.7564, 14.0578, 0.0, 0.0, 0.0],
  [6.3874, 6.4531, 6.5538, 6.7342, 7.1173, 7.6042, 10.423, 0.0, 0.0, 0.0],
  [5.4945, 5.5544, 5.6477, 5.825, 6.1981, 6.5375, 8.9957, 0.0, 0.0, 0.0],
  [4.9397, 4.9973, 5.0894, 5.2655, 5.6293, 5.875, 8.1056, 0.0, 0.0, 0.0],
  [4.5789, 4.6359, 4.7277, 4.9031, 5.2605, 5.4398, 7.5195, 0.0, 0.0, 0.0],
  [4.3343, 4.3908, 4.4827, 4.6579, 5.0108, 5.1434, 7.1195, 0.0, 0.0, 0.0],
  [4.2663, 4.3225, 4.4142, 4.5894, 4.9419, 5.0591, 0.0, 0.0, 0.0, 0.0],
  [4.2153, 4.2713, 4.3627, 4.5379, 4.8895, 4.9966, 0.0, 0.0, 0.0, 0.0],
  [4.1763, 4.2321, 4.3234, 4.4982, 4.8488, 4.9492, 0.0, 0.0, 0.0, 0.0],
  [4.1465, 4.2021, 4.2932, 4.4677, 4.8174, 4.9131, 0.0, 0.0, 0.0, 0.0],
  [4.1246, 4.18, 4.271, 4.4453, 4.7944, 4.8866, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [11.3683, 11.4343, 11.5344, 11.7083, 12.06, 12.8147, 15.2282, 0.0, 0.0, 0.0],
  [9.1215, 9.1811, 9.2722, 9.4411, 9.786, 7.7782, 10.5792, 0.0, 0.0, 0.0],
  [7.8742, 7.9295, 8.015, 8.1742, 8.5175, 6.6997, 9.1444, 0.0, 0.0, 0.0],
  [7.0959, 7.1486, 7.2324, 7.3932, 7.7324, 6.0294, 8.2491, 0.0, 0.0, 0.0],
  [6.5869, 6.6395, 6.7241, 6.8861, 7.2224, 5.5932, 7.662, 0.0, 0.0, 0.0],
  [6.2419, 6.2945, 6.3798, 6.5429, 6.8773, 5.2981, 7.265, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
]
var joint_life_5_rates = [
  [1.588, 1.754, 2.1935, 2.9918, 4.1623, 5.8723, 7.9083, 0.0, 0.0, 0.0],
  [1.2445, 1.3751, 1.7206, 2.3502, 3.2783, 4.6401, 6.2811, 0.0, 0.0, 0.0],
  [1.0589, 1.1703, 1.467, 2.0051, 2.7982, 3.9689, 5.3762, 0.0, 0.0, 0.0],
  [0.9445, 1.044, 1.3087, 1.7902, 2.5014, 3.5531, 4.8143, 0.0, 0.0, 0.0],
  [0.8694, 0.9613, 1.2049, 1.6489, 2.3061, 3.2794, 4.4435, 0.0, 0.0, 0.0],
  [0.8178, 0.9045, 1.1336, 1.5516, 2.1715, 3.0909, 4.1878, 0.0, 0.0, 0.0],
  [0.7824, 0.8657, 1.085, 1.485, 2.0792, 3.0369, 0.0, 0.0, 0.0, 0.0],
  [0.7558, 0.8367, 1.0487, 1.4353, 2.0104, 2.9965, 0.0, 0.0, 0.0, 0.0],
  [0.7356, 0.8145, 1.0211, 1.3976, 1.9581, 2.9659, 0.0, 0.0, 0.0, 0.0],
  [0.7202, 0.7977, 1.0, 1.3689, 1.9183, 2.9425, 0.0, 0.0, 0.0, 0.0],
  [0.7087, 0.7851, 0.9844, 1.3476, 1.8887, 2.9252, 0.0, 0.0, 0.0, 0.0],
  [0.7001, 0.7758, 0.9728, 1.3318, 1.8797, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6938, 0.7688, 0.9642, 1.32, 1.8729, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.689, 0.7636, 0.9578, 1.3113, 1.8679, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6855, 0.7598, 0.953, 1.3049, 1.8643, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6828, 0.7569, 0.9495, 1.3001, 1.8615, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.6579, 1.7979, 2.2347, 3.0317, 4.2008, 5.9085, 7.9418, 0.0, 0.0, 0.0],
  [1.3039, 1.4147, 1.7555, 2.3843, 3.3114, 4.6401, 6.2811, 0.0, 0.0, 0.0],
  [1.1123, 1.2075, 1.4989, 2.0359, 2.8282, 3.9689, 5.3762, 0.0, 0.0, 0.0],
  [0.9942, 1.0798, 1.3389, 1.8188, 2.5293, 3.5531, 4.8143, 0.0, 0.0, 0.0],
  [0.9167, 0.9961, 1.2339, 1.6761, 2.3327, 3.2794, 4.4435, 0.0, 0.0, 0.0],
  [0.8634, 0.9387, 1.1624, 1.5782, 2.1974, 3.0909, 4.1878, 0.0, 0.0, 0.0],
  [0.8269, 0.8995, 1.1137, 1.5116, 2.1051, 3.0369, 0.0, 0.0, 0.0, 0.0],
  [0.7996, 0.8701, 1.0773, 1.462, 2.0363, 2.9965, 0.0, 0.0, 0.0, 0.0],
  [0.7786, 0.8476, 1.0495, 1.4242, 1.984, 2.9659, 0.0, 0.0, 0.0, 0.0],
  [0.7626, 0.8303, 1.0282, 1.3953, 1.944, 2.9425, 0.0, 0.0, 0.0, 0.0],
  [0.7507, 0.8175, 1.0124, 1.3738, 1.9144, 2.9252, 0.0, 0.0, 0.0, 0.0],
  [0.7418, 0.808, 1.0007, 1.358, 1.9054, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.7351, 0.8008, 0.992, 1.3462, 1.8986, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.7301, 0.7955, 0.9855, 1.3374, 1.8936, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.7264, 0.7915, 0.9807, 1.3309, 1.8899, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.7237, 0.7886, 0.9771, 1.3261, 1.8872, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.9441, 1.9988, 2.3121, 3.105, 4.2711, 5.9747, 8.003, 0.0, 0.0, 0.0],
  [1.5289, 1.577, 1.8233, 2.4471, 3.3721, 4.6717, 6.3108, 0.0, 0.0, 0.0],
  [1.3049, 1.3493, 1.5615, 2.0928, 2.8835, 3.9977, 5.4037, 0.0, 0.0, 0.0],
  [1.1673, 1.2091, 1.3992, 1.8719, 2.5811, 3.5802, 4.8402, 0.0, 0.0, 0.0],
  [1.0774, 1.1175, 1.2935, 1.7279, 2.3823, 3.3053, 4.4684, 0.0, 0.0, 0.0],
  [1.0158, 1.0548, 1.2215, 1.63, 2.2468, 3.1161, 4.2122, 0.0, 0.0, 0.0],
  [0.9749, 1.0132, 1.1725, 1.5636, 2.1548, 3.0618, 0.0, 0.0, 0.0, 0.0],
  [0.9434, 0.9811, 1.1357, 1.5139, 2.0861, 3.0215, 0.0, 0.0, 0.0, 0.0],
  [0.9192, 0.9564, 1.1074, 1.4759, 2.0336, 2.9908, 0.0, 0.0, 0.0, 0.0],
  [0.9006, 0.9374, 1.0857, 1.4467, 1.9935, 2.9674, 0.0, 0.0, 0.0, 0.0],
  [0.8868, 0.9233, 1.0695, 1.425, 1.9637, 2.9501, 0.0, 0.0, 0.0, 0.0],
  [0.8765, 0.9128, 1.0575, 1.4089, 1.9546, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.8688, 0.9049, 1.0485, 1.397, 1.9478, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.8631, 0.899, 1.0418, 1.3881, 1.9428, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.8588, 0.8947, 1.0369, 1.3816, 1.9391, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.8556, 0.8914, 1.0332, 1.3767, 1.9363, 0.0, 0.0, 0.0, 0.0, 0.0],
  [2.5668, 2.6151, 2.6888, 3.2475, 4.4077, 6.103, 8.1218, 0.0, 0.0, 0.0],
  [2.0254, 2.0658, 2.1313, 2.5696, 3.4904, 4.6717, 6.3108, 0.0, 0.0, 0.0],
  [1.729, 1.7656, 1.8288, 2.2069, 2.9912, 3.9977, 5.4037, 0.0, 0.0, 0.0],
  [1.5465, 1.5817, 1.6432, 1.9831, 2.6832, 3.5802, 4.8402, 0.0, 0.0, 0.0],
  [1.4277, 1.4619, 1.5225, 1.8377, 2.4834, 3.3053, 4.4684, 0.0, 0.0, 0.0],
  [1.3467, 1.3803, 1.4404, 1.739, 2.3477, 3.1161, 4.2122, 0.0, 0.0, 0.0],
  [1.2926, 1.3259, 1.3857, 1.6719, 2.2555, 3.0618, 0.0, 0.0, 0.0, 0.0],
  [1.2512, 1.2841, 1.3435, 1.6213, 2.1863, 3.0215, 0.0, 0.0, 0.0, 0.0],
  [1.2194, 1.252, 1.3109, 1.5822, 2.1331, 2.9908, 0.0, 0.0, 0.0, 0.0],
  [1.1951, 1.2273, 1.2857, 1.552, 2.0922, 2.9674, 0.0, 0.0, 0.0, 0.0],
  [1.1769, 1.209, 1.267, 1.5296, 2.062, 2.9501, 0.0, 0.0, 0.0, 0.0],
  [1.1634, 1.1953, 1.2531, 1.513, 2.0527, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.1534, 1.1852, 1.2428, 1.5006, 2.0458, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.1459, 1.1776, 1.2351, 1.4915, 2.0407, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.1403, 1.172, 1.2294, 1.4847, 2.037, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.1361, 1.1678, 1.2252, 1.4797, 2.0342, 0.0, 0.0, 0.0, 0.0, 0.0],
  [3.566, 3.6128, 3.6837, 3.8221, 4.6644, 6.3448, 8.3458, 0.0, 0.0, 0.0],
  [2.8199, 2.8593, 2.9201, 3.047, 3.7165, 4.7295, 6.3652, 0.0, 0.0, 0.0],
  [2.4117, 2.4468, 2.5036, 2.6267, 3.2053, 4.0508, 5.4542, 0.0, 0.0, 0.0],
  [2.1589, 2.1923, 2.2484, 2.3682, 2.891, 3.6301, 4.8882, 0.0, 0.0, 0.0],
  [1.9941, 2.0268, 2.0825, 2.2002, 2.6866, 3.3533, 4.5149, 0.0, 0.0, 0.0],
  [1.882, 1.9142, 1.9697, 2.0859, 2.5477, 3.1638, 4.2577, 0.0, 0.0, 0.0],
  [1.8056, 1.8375, 1.8931, 2.0083, 2.4533, 3.1097, 0.0, 0.0, 0.0, 0.0],
  [1.7483, 1.7799, 1.8352, 1.9492, 2.3816, 3.0694, 0.0, 0.0, 0.0, 0.0],
  [1.7044, 1.7357, 1.7907, 1.9034, 2.3261, 3.0387, 0.0, 0.0, 0.0, 0.0],
  [1.6706, 1.7018, 1.7563, 1.868, 2.2831, 3.0153, 0.0, 0.0, 0.0, 0.0],
  [1.6456, 1.6765, 1.7309, 1.8417, 2.2514, 2.9979, 0.0, 0.0, 0.0, 0.0],
  [1.6378, 1.6687, 1.723, 1.8337, 2.2418, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.632, 1.6628, 1.7171, 1.8277, 2.2346, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.6277, 1.6585, 1.7127, 1.8232, 2.2293, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.6245, 1.6553, 1.7095, 1.8199, 2.2254, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.6221, 1.6529, 1.7071, 1.8175, 2.2225, 0.0, 0.0, 0.0, 0.0, 0.0],
  [5.0626, 5.1071, 5.1743, 5.3035, 5.5655, 6.7611, 8.7363, 0.0, 0.0, 0.0],
  [4.0108, 4.0487, 4.1069, 4.2208, 4.4659, 4.7295, 6.3652, 0.0, 0.0, 0.0],
  [3.4372, 3.4712, 3.5244, 3.6353, 3.8691, 4.0508, 5.4542, 0.0, 0.0, 0.0],
  [3.0813, 3.1132, 3.166, 3.2755, 3.5005, 3.6301, 4.8882, 0.0, 0.0, 0.0],
  [2.849, 2.8803, 2.9331, 3.0415, 3.2603, 3.3533, 4.5149, 0.0, 0.0, 0.0],
  [2.6909, 2.7218, 2.7747, 2.8824, 3.0969, 3.1638, 4.2577, 0.0, 0.0, 0.0],
  [2.6465, 2.6773, 2.7301, 2.8378, 3.0515, 3.1097, 0.0, 0.0, 0.0, 0.0],
  [2.6133, 2.6438, 2.6966, 2.8041, 3.0169, 3.0694, 0.0, 0.0, 0.0, 0.0],
  [2.5878, 2.6182, 2.6709, 2.7782, 2.99, 3.0387, 0.0, 0.0, 0.0, 0.0],
  [2.5682, 2.5985, 2.6511, 2.7582, 2.9692, 3.0153, 0.0, 0.0, 0.0, 0.0],
  [2.5537, 2.5839, 2.6364, 2.7433, 2.9538, 2.9979, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [7.0655, 7.1069, 7.1692, 7.2889, 7.5296, 8.0083, 9.4791, 0.0, 0.0, 0.0],
  [5.6227, 5.6585, 5.7134, 5.8214, 6.046, 4.8422, 6.4713, 0.0, 0.0, 0.0],
  [4.818, 4.8505, 4.9012, 5.0012, 5.2106, 4.1542, 5.5527, 0.0, 0.0, 0.0],
  [4.3178, 4.3482, 4.3966, 4.4932, 4.6948, 3.7273, 4.9816, 0.0, 0.0, 0.0],
  [3.9887, 4.018, 4.0655, 4.1601, 4.3585, 3.4486, 4.6051, 0.0, 0.0, 0.0],
  [3.763, 3.7916, 3.8385, 3.9333, 4.1295, 3.2592, 4.3467, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
]
var joint_life_6_rates = [
  [
    1.5259, 1.6867, 2.1271, 2.9232, 4.0986, 5.9064, 8.2623, 11.2149, 15.5612,
    20.8161,
  ],
  [
    1.1816, 1.3078, 1.6494, 2.2708, 3.1928, 4.6184, 6.4955, 8.9011, 12.5141,
    17.054,
  ],
  [
    0.9978, 1.1054, 1.396, 1.9224, 2.7048, 3.9219, 5.5379, 7.6461, 10.8534,
    14.9809,
  ],
  [
    0.8863, 0.9826, 1.2401, 1.7081, 2.4066, 3.4962, 4.9559, 6.8856, 9.8447,
    13.7219,
  ],
  [
    0.8136, 0.9027, 1.1387, 1.5685, 2.2126, 3.2201, 4.5791, 6.3932, 9.1938,
    12.9162,
  ],
  [
    0.764, 0.8482, 1.0699, 1.474, 2.0816, 3.0335, 4.325, 6.0621, 8.7589,
    12.3858,
  ],
  [0.7303, 0.8113, 1.0234, 1.4104, 1.9935, 2.9816, 0.0, 0.0, 0.0, 0.0],
  [0.706, 0.7846, 0.9901, 1.3649, 1.9304, 2.9445, 0.0, 0.0, 0.0, 0.0],
  [0.6882, 0.7652, 0.9658, 1.3319, 1.8849, 2.9177, 0.0, 0.0, 0.0, 0.0],
  [0.6751, 0.7509, 0.9481, 1.3079, 1.8517, 2.8983, 0.0, 0.0, 0.0, 0.0],
  [0.6655, 0.7404, 0.9352, 1.2904, 1.8276, 2.8841, 0.0, 0.0, 0.0, 0.0],
  [0.6583, 0.7327, 0.9257, 1.2775, 1.8203, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.653, 0.7269, 0.9187, 1.2681, 1.8149, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6491, 0.7227, 0.9135, 1.2611, 1.8109, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6461, 0.7195, 0.9096, 1.2559, 1.808, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6439, 0.7172, 0.9068, 1.2521, 1.8058, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    1.5955, 1.7341, 2.161, 2.9551, 4.1281, 5.9325, 8.2842, 11.232, 15.5767,
    20.8315,
  ],
  [
    1.24, 1.3495, 1.6784, 2.297, 3.2175, 4.6408, 6.5151, 8.9203, 12.5335,
    17.0731,
  ],
  [
    1.0503, 1.1443, 1.4225, 1.9456, 2.7268, 3.9422, 5.5581, 7.667, 10.8746,
    15.0017,
  ],
  [
    0.9352, 1.0198, 1.2663, 1.7304, 2.4281, 3.5166, 4.9768, 6.9074, 9.8667,
    13.7437,
  ],
  [
    0.8601, 0.9386, 1.1649, 1.5911, 2.2346, 3.2412, 4.6006, 6.4155, 9.2164,
    12.9387,
  ],
  [
    0.8089, 0.8834, 1.0962, 1.497, 2.1041, 3.0551, 4.3471, 6.0848, 8.782,
    12.4088,
  ],
  [0.7742, 0.8459, 1.0497, 1.4337, 2.0162, 3.0033, 0.0, 0.0, 0.0, 0.0],
  [0.749, 0.8188, 1.0163, 1.3883, 1.9533, 2.9663, 0.0, 0.0, 0.0, 0.0],
  [0.7307, 0.7991, 0.9921, 1.3554, 1.908, 2.9396, 0.0, 0.0, 0.0, 0.0],
  [0.7171, 0.7846, 0.9744, 1.3315, 1.8749, 2.9202, 0.0, 0.0, 0.0, 0.0],
  [0.7072, 0.7739, 0.9614, 1.314, 1.8509, 2.9061, 0.0, 0.0, 0.0, 0.0],
  [0.6998, 0.766, 0.9519, 1.3013, 1.8436, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6943, 0.7602, 0.9449, 1.2918, 1.8382, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6903, 0.7559, 0.9398, 1.2849, 1.8343, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6873, 0.7527, 0.936, 1.2799, 1.8313, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.6851, 0.7504, 0.9332, 1.2761, 1.8292, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    1.8753, 1.9325, 2.238, 3.0147, 4.1833, 5.9813, 8.3254, 11.2676, 15.6052,
    20.8622,
  ],
  [
    1.4595, 1.5094, 1.7474, 2.3462, 3.2635, 4.6826, 6.5518, 8.9584, 12.5694,
    17.1101,
  ],
  [
    1.2379, 1.2835, 1.4857, 1.9919, 2.7697, 3.9813, 5.5955, 7.7077, 10.9134,
    15.0415,
  ],
  [
    1.1036, 1.1465, 1.3293, 1.7769, 2.4714, 3.557, 5.016, 6.9494, 9.9072,
    13.785,
  ],
  [
    1.0162, 1.0572, 1.2263, 1.6378, 2.2785, 3.2823, 4.6408, 6.4582, 9.2578,
    12.9812,
  ],
  [
    0.9568, 0.9965, 1.1565, 1.5438, 2.1483, 3.0969, 4.388, 6.1281, 8.8243,
    12.4523,
  ],
  [0.9166, 0.9554, 1.1094, 1.4807, 2.0608, 3.0453, 0.0, 0.0, 0.0, 0.0],
  [0.8876, 0.9258, 1.0755, 1.4354, 1.9982, 3.0084, 0.0, 0.0, 0.0, 0.0],
  [0.8664, 0.9042, 1.0509, 1.4026, 1.953, 2.9818, 0.0, 0.0, 0.0, 0.0],
  [0.8518, 0.8893, 1.0329, 1.3788, 1.9202, 2.9625, 0.0, 0.0, 0.0, 0.0],
  [0.8404, 0.8777, 1.0198, 1.3614, 1.8962, 2.9484, 0.0, 0.0, 0.0, 0.0],
  [0.832, 0.8692, 1.0101, 1.3487, 1.889, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.8258, 0.8629, 1.003, 1.3394, 1.8836, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.8213, 0.8582, 0.9978, 1.3325, 1.8797, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.8179, 0.8548, 0.994, 1.3275, 1.8769, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.8154, 0.8523, 0.9912, 1.3239, 1.8748, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    2.4816, 2.5208, 2.6062, 3.1313, 4.2834, 6.0699, 8.4, 11.3424, 15.6611,
    20.9349,
  ],
  [
    1.9307, 1.9676, 2.0444, 2.4617, 3.3556, 4.7623, 6.6287, 9.0357, 12.6376,
    17.1904,
  ],
  [
    1.641, 1.6761, 1.7475, 2.1015, 2.8617, 4.0623, 5.6737, 7.7884, 10.9868,
    15.1249,
  ],
  [
    1.4639, 1.4978, 1.5659, 1.8859, 2.5635, 3.6396, 5.0963, 7.0317, 9.9833,
    13.8705,
  ],
  [
    1.3492, 1.3821, 1.4481, 1.7448, 2.3705, 3.366, 4.7223, 6.5415, 9.3357,
    13.0681,
  ],
  [
    1.2715, 1.3039, 1.3682, 1.6495, 2.2402, 3.1812, 4.4703, 6.2122, 8.9037,
    12.5407,
  ],
  [1.2191, 1.2512, 1.3145, 1.5856, 2.1528, 3.1298, 0.0, 0.0, 0.0, 0.0],
  [1.1815, 1.2132, 1.276, 1.5398, 2.0902, 3.093, 0.0, 0.0, 0.0, 0.0],
  [1.1541, 1.1856, 1.2479, 1.5065, 2.0449, 3.0666, 0.0, 0.0, 0.0, 0.0],
  [1.135, 1.1664, 1.2283, 1.4823, 2.0121, 3.0474, 0.0, 0.0, 0.0, 0.0],
  [1.1203, 1.1516, 1.2134, 1.4646, 1.9882, 3.0334, 0.0, 0.0, 0.0, 0.0],
  [1.1095, 1.1408, 1.2024, 1.4516, 1.9809, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.1016, 1.1328, 1.1944, 1.4422, 1.9756, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.0957, 1.1269, 1.1884, 1.4352, 1.9718, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.0914, 1.1226, 1.1841, 1.4302, 1.9689, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.0883, 1.1195, 1.181, 1.4265, 1.9669, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    3.456, 3.4935, 3.5613, 3.729, 4.5366, 6.2579, 8.564, 11.4993, 15.7865,
    21.0816,
  ],
  [
    2.7019, 2.7334, 2.7975, 2.9474, 3.5982, 4.9497, 6.7945, 9.2032, 12.7814,
    17.3498,
  ],
  [
    2.298, 2.3316, 2.3928, 2.5322, 3.092, 4.2482, 5.8422, 7.9583, 11.1373,
    15.2894,
  ],
  [
    2.0539, 2.0865, 2.1459, 2.2785, 2.7849, 3.8231, 5.2649, 7.2017, 10.1372,
    14.0379,
  ],
  [
    1.8958, 1.9276, 1.9857, 2.1138, 2.5856, 3.5473, 4.8906, 6.7114, 9.4915,
    13.2377,
  ],
  [
    1.7887, 1.82, 1.8772, 2.0022, 2.4504, 3.3606, 4.638, 6.3819, 9.0614,
    12.7127,
  ],
  [1.7166, 1.7476, 1.8043, 1.9273, 2.3599, 3.3091, 0.0, 0.0, 0.0, 0.0],
  [1.6648, 1.6956, 1.752, 1.8736, 2.295, 3.2724, 0.0, 0.0, 0.0, 0.0],
  [1.6272, 1.6579, 1.714, 1.8347, 2.248, 3.2457, 0.0, 0.0, 0.0, 0.0],
  [1.5999, 1.6304, 1.6864, 1.8063, 2.2138, 3.2265, 0.0, 0.0, 0.0, 0.0],
  [1.5798, 1.6103, 1.6662, 1.7856, 2.1889, 3.2125, 0.0, 0.0, 0.0, 0.0],
  [1.5736, 1.604, 1.6599, 1.7793, 2.1815, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.569, 1.5995, 1.6554, 1.7747, 2.176, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.5657, 1.5961, 1.652, 1.7713, 2.1721, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.5632, 1.5937, 1.6496, 1.7688, 2.1692, 0.0, 0.0, 0.0, 0.0, 0.0],
  [1.5614, 1.5918, 1.6478, 1.767, 2.1671, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    4.9661, 5.0001, 5.0634, 5.2014, 5.5688, 6.8126, 8.9903, 11.8774, 16.0757,
    21.3719,
  ],
  [
    3.8952, 3.9285, 3.9895, 4.118, 4.4394, 5.4446, 7.1965, 9.5707, 13.0835,
    17.6541,
  ],
  [
    3.3236, 3.3559, 3.4147, 3.5368, 3.831, 4.7055, 6.225, 8.3138, 11.4406,
    15.5971,
  ],
  [
    2.9777, 3.0092, 3.0665, 3.1842, 3.4606, 4.2553, 5.6336, 7.548, 10.4395,
    14.3474,
  ],
  [
    2.7532, 2.7841, 2.8404, 2.9552, 3.2197, 3.9622, 5.2491, 7.0511, 9.793,
    13.5486,
  ],
  [
    2.601, 2.6315, 2.687, 2.7997, 3.0558, 3.7629, 4.9889, 6.7165, 9.3627,
    13.026,
  ],
  [2.5591, 2.5895, 2.6447, 2.7571, 3.0118, 3.7089, 0.0, 0.0, 0.0, 0.0],
  [2.529, 2.5593, 2.6144, 2.7266, 2.9803, 3.6704, 0.0, 0.0, 0.0, 0.0],
  [2.5072, 2.5374, 2.5925, 2.7045, 2.9574, 3.6424, 0.0, 0.0, 0.0, 0.0],
  [2.4913, 2.5215, 2.5765, 2.6884, 2.9408, 3.6222, 0.0, 0.0, 0.0, 0.0],
  [2.4797, 2.5098, 2.5648, 2.6767, 2.9286, 3.6074, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    7.1116, 7.144, 7.2016, 7.3288, 7.6293, 8.4303, 10.1538, 12.8017, 16.7558,
    21.9942,
  ],
  [
    5.6147, 5.6469, 5.7038, 5.8244, 6.0973, 6.7763, 8.1946, 10.394, 13.7268,
    18.2602,
  ],
  [
    4.8114, 4.8428, 4.8983, 5.0141, 5.2692, 5.8781, 7.1265, 9.0748, 12.0562,
    16.189,
  ],
  [
    4.3246, 4.3554, 4.4099, 4.5222, 4.7651, 5.33, 6.4744, 8.269, 11.037,
    14.9308,
  ],
  [
    4.0085, 4.0388, 4.0925, 4.2025, 4.4373, 4.9731, 6.0502, 7.7461, 10.3793,
    14.1284,
  ],
  [
    3.7947, 3.8247, 3.8778, 3.9861, 4.215, 4.7309, 5.7632, 7.3941, 9.9414,
    13.6051,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    9.8015, 9.8393, 9.9135, 10.0517, 10.3722, 11.1276, 12.5815, 14.9293, 18.407,
    23.42,
  ],
  [
    7.8299, 7.8661, 7.9353, 8.0641, 8.3525, 9.002, 10.226, 12.2101, 15.1912,
    19.5705,
  ],
  [
    6.7618, 6.7966, 6.8622, 6.9847, 7.2531, 7.8408, 8.9345, 10.7126, 13.4107,
    17.4308,
  ],
  [
    6.1122, 6.1458, 6.209, 6.3273, 6.5826, 7.1316, 8.1453, 9.7978, 12.3248,
    16.1337,
  ],
  [
    5.6899, 5.7229, 5.7845, 5.9001, 6.1468, 6.6705, 7.6328, 9.2057, 11.6265,
    15.3108,
  ],
  [
    5.4047, 5.4372, 5.4978, 5.6114, 5.8523, 6.359, 7.2878, 8.8093, 11.1642,
    14.778,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    13.5555, 13.5957, 13.6667, 13.8355, 14.1568, 14.9096, 16.2609, 18.3849,
    21.5667, 26.0585,
  ],
  [
    10.9858, 11.024, 11.0907, 11.243, 11.533, 12.1865, 13.3439, 15.1742,
    17.9528, 21.958,
  ],
  [
    9.5777, 9.6142, 9.6779, 9.8202, 10.0913, 10.6868, 11.7335, 13.3966, 15.9451,
    19.6757,
  ],
  [
    8.7182, 8.7536, 8.8153, 8.9513, 9.2104, 9.7702, 10.7496, 12.3123, 14.7243,
    18.2989,
  ],
  [
    8.1606, 8.1952, 8.2558, 8.388, 8.6395, 9.1767, 10.1141, 11.615, 13.9454,
    17.4339,
  ],
  [
    7.7861, 7.8202, 7.88, 8.0098, 8.2564, 8.7792, 9.6903, 11.1534, 13.4365,
    16.8817,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    18.9812, 19.0265, 19.1014, 19.2392, 19.5306, 20.1545, 21.3641, 23.2264,
    26.0569, 30.1258,
  ],
  [
    15.6336, 15.6759, 15.7461, 15.8754, 16.1445, 16.7055, 17.7688, 19.4186,
    21.9565, 25.6729,
  ],
  [
    13.7812, 13.8215, 13.8888, 14.0128, 14.2683, 14.7924, 15.772, 17.3016,
    19.6743, 23.1976,
  ],
  [
    12.6521, 12.6912, 12.7568, 12.8778, 13.1253, 13.6273, 14.5576, 16.0178,
    18.2975, 21.7197,
  ],
  [
    11.9263, 11.9648, 12.0293, 12.149, 12.3921, 12.8811, 13.7825, 15.2031,
    17.4325, 20.8082,
  ],
  [
    11.4469, 11.485, 11.5491, 11.6679, 11.909, 12.3906, 13.2757, 14.6753,
    16.8804, 20.2402,
  ],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
]
var main_hbc_rates = [
  [
    28.3008, 28.2651, 28.2265, 28.1448, 27.9951, 27.7602, 27.4148, 0.0, 0.0,
    0.0,
  ],
  [22.212, 22.1912, 22.1652, 22.1112, 22.0145, 21.8639, 24.4921, 0.0, 0.0, 0.0],
  [18.8823, 18.8634, 18.8435, 18.803, 18.7321, 18.6226, 22.8931, 0.0, 0.0, 0.0],
  [
    16.7881, 16.7836, 16.7675, 16.7351, 16.6797, 16.5948, 21.8935, 0.0, 0.0,
    0.0,
  ],
  [
    15.4252, 15.4011, 15.3874, 15.3605, 15.3156, 15.2472, 21.2275, 0.0, 0.0,
    0.0,
  ],
  [
    14.4523, 14.4371, 14.4252, 14.4024, 14.3652, 14.3089, 20.7615, 0.0, 0.0,
    0.0,
  ],
  [13.7813, 13.7676, 13.7571, 13.7374, 13.7058, 13.9852, 0.0, 0.0, 0.0, 0.0],
  [13.2756, 13.2634, 13.2541, 13.2372, 13.2103, 13.7477, 0.0, 0.0, 0.0, 0.0],
  [12.8882, 12.8771, 12.8689, 12.8543, 12.8315, 13.5531, 0.0, 0.0, 0.0, 0.0],
  [12.5899, 12.5792, 12.572, 12.5596, 12.5403, 13.4111, 0.0, 0.0, 0.0, 0.0],
  [12.3652, 12.3553, 12.3491, 12.3386, 12.3224, 13.3038, 0.0, 0.0, 0.0, 0.0],
  [12.1956, 12.1858, 12.1804, 12.1716, 12.2416, 0.0, 0.0, 0.0, 0.0, 0.0],
  [12.0711, 12.0626, 12.0581, 12.0509, 12.1832, 0.0, 0.0, 0.0, 0.0, 0.0],
  [11.9877, 11.9708, 11.9669, 11.9609, 12.1392, 0.0, 0.0, 0.0, 0.0, 0.0],
  [11.9174, 11.9021, 11.8989, 11.8939, 12.1065, 0.0, 0.0, 0.0, 0.0, 0.0],
  [11.8647, 11.8504, 11.8477, 11.8436, 12.0819, 0.0, 0.0, 0.0, 0.0, 0.0],
]
var joint_hbc_rates = [
  [56.3087, 56.2851, 56.2145, 56.0605, 55.7701, 55.3046, 54.62, 0.0, 0.0, 0.0],
  [44.285, 44.2427, 44.1953, 44.0932, 43.905, 43.6069, 48.8259, 0.0, 0.0, 0.0],
  [37.6706, 37.6341, 37.5979, 37.5221, 37.3859, 37.1718, 45.637, 0.0, 0.0, 0.0],
  [
    33.5056, 33.4836, 33.4538, 33.3921, 33.2833, 33.1151, 43.6081, 0.0, 0.0,
    0.0,
  ],
  [30.7646, 30.7709, 30.7455, 30.6945, 30.6065, 30.471, 42.3119, 0.0, 0.0, 0.0],
  [28.8471, 28.8413, 28.8192, 28.7756, 28.702, 28.5905, 41.3721, 0.0, 0.0, 0.0],
  [27.521, 27.5096, 27.4899, 27.4521, 27.3895, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.5169, 26.5065, 26.4891, 26.4564, 26.4032, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.7477, 25.7385, 25.7231, 25.695, 25.6498, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.156, 25.1479, 25.1343, 25.1102, 25.0722, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.7107, 24.7035, 24.6917, 24.6712, 24.6393, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.3749, 24.3685, 24.3583, 24.3411, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.1288, 24.1233, 24.1148, 24.1006, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.9462, 23.9604, 23.9532, 23.9414, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.8099, 23.8223, 23.8163, 23.8066, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.7074, 23.7189, 23.7138, 23.7059, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    56.2779, 56.2408, 56.1703, 56.0168, 55.7273, 55.2631, 54.5805, 0.0, 0.0,
    0.0,
  ],
  [44.2262, 44.202, 44.1546, 44.0525, 43.8643, 43.5663, 48.7791, 0.0, 0.0, 0.0],
  [37.6166, 37.5985, 37.5622, 37.4854, 37.3469, 37.1303, 45.584, 0.0, 0.0, 0.0],
  [33.4843, 33.4698, 33.4401, 33.3784, 33.2699, 33.102, 43.5836, 0.0, 0.0, 0.0],
  [30.7369, 30.7248, 30.6995, 30.6483, 30.56, 30.4249, 42.2541, 0.0, 0.0, 0.0],
  [
    28.8217, 28.8112, 28.7892, 28.7458, 28.6724, 28.5611, 41.3289, 0.0, 0.0,
    0.0,
  ],
  [27.4917, 27.4824, 27.4629, 27.4252, 27.3629, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.4907, 26.4824, 26.465, 26.4325, 26.3794, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.7241, 25.7167, 25.7013, 25.6732, 25.6283, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.1335, 25.1268, 25.1133, 25.0893, 25.0514, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.6899, 24.684, 24.6722, 24.6518, 24.6201, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.3543, 24.3491, 24.339, 24.3219, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.1111, 24.1066, 24.0982, 24.0841, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.9296, 23.9257, 23.9185, 23.9067, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.7943, 23.7909, 23.7849, 23.7752, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.6924, 23.6895, 23.6845, 23.6766, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    56.2025, 56.1656, 56.0954, 55.9425, 55.6541, 55.1917, 54.5115, 0.0, 0.0,
    0.0,
  ],
  [44.1755, 44.1514, 44.1041, 44.0025, 43.815, 43.5181, 48.7169, 0.0, 0.0, 0.0],
  [
    37.5779, 37.5599, 37.5238, 37.4472, 37.3093, 37.0935, 45.5253, 0.0, 0.0,
    0.0,
  ],
  [
    33.4528, 33.4383, 33.4088, 33.3474, 33.2394, 33.0722, 43.5269, 0.0, 0.0,
    0.0,
  ],
  [
    30.7103, 30.6982, 30.6731, 30.6221, 30.5342, 30.3997, 42.1989, 0.0, 0.0,
    0.0,
  ],
  [
    28.7986, 28.7882, 28.7663, 28.7232, 28.6502, 28.5395, 41.2748, 0.0, 0.0,
    0.0,
  ],
  [27.4714, 27.4621, 27.4427, 27.4053, 27.3433, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.4727, 26.4644, 26.4472, 26.4149, 26.3622, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.7082, 25.7009, 25.6856, 25.6578, 25.6133, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.1196, 25.113, 25.0996, 25.0758, 25.0383, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.6778, 24.672, 24.6604, 24.6402, 24.6088, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.344, 24.3388, 24.3289, 24.312, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.1024, 24.098, 24.0897, 24.0759, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.9222, 23.9184, 23.9112, 23.8997, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.7881, 23.7848, 23.7788, 23.7694, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.6872, 23.6844, 23.6795, 23.6718, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    56.0435, 56.0069, 55.9373, 55.7856, 55.4995, 55.0407, 54.3658, 0.0, 0.0,
    0.0,
  ],
  [
    44.0703, 44.0463, 43.9995, 43.8986, 43.7126, 43.4181, 48.5874, 0.0, 0.0,
    0.0,
  ],
  [37.499, 37.4811, 37.4453, 37.3694, 37.2325, 37.0185, 45.4037, 0.0, 0.0, 0.0],
  [33.3896, 33.3753, 33.346, 33.2852, 33.1781, 33.0123, 43.4101, 0.0, 0.0, 0.0],
  [30.6579, 30.646, 30.6211, 30.5706, 30.4835, 30.3502, 42.0853, 0.0, 0.0, 0.0],
  [28.7543, 28.744, 28.7224, 28.6796, 28.6074, 28.4978, 41.1636, 0.0, 0.0, 0.0],
  [27.433, 27.4239, 27.4047, 27.3677, 27.3064, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.4395, 26.4314, 26.4144, 26.3825, 26.3305, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.6797, 25.6724, 25.6574, 25.63, 25.5861, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.0951, 25.0886, 25.0755, 25.0521, 25.0152, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.6571, 24.6513, 24.6399, 24.6201, 24.5893, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.3265, 24.3215, 24.3118, 24.2953, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.0881, 24.0838, 24.0757, 24.0622, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.9102, 23.9065, 23.8996, 23.8884, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.7783, 23.7751, 23.7693, 23.7601, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [23.6792, 23.6765, 23.6717, 23.6643, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [55.75, 55.7139, 55.6453, 55.496, 55.2141, 54.7617, 54.0962, 0.0, 0.0, 0.0],
  [43.8796, 43.856, 43.8099, 43.7104, 43.527, 43.2366, 48.3521, 0.0, 0.0, 0.0],
  [37.3587, 37.3411, 37.3058, 37.231, 37.0961, 36.8851, 45.1852, 0.0, 0.0, 0.0],
  [33.2797, 33.2657, 33.2368, 33.177, 33.0714, 32.908, 43.2019, 0.0, 0.0, 0.0],
  [30.5686, 30.5568, 30.5323, 30.4826, 30.397, 30.2657, 41.8842, 0.0, 0.0, 0.0],
  [28.6801, 28.67, 28.6487, 28.6067, 28.5357, 28.428, 40.9678, 0.0, 0.0, 0.0],
  [27.3699, 27.3609, 27.3421, 27.3058, 27.2457, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.3859, 26.3779, 26.3613, 26.3301, 26.2792, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.6342, 25.6271, 25.6125, 25.5858, 25.5429, 0.0, 0.0, 0.0, 0.0, 0.0],
  [25.0568, 25.0505, 25.0377, 25.0149, 24.979, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.6249, 24.6194, 24.6083, 24.5891, 24.5592, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.465, 24.4596, 24.4491, 24.431, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.3494, 24.3443, 24.3343, 24.3173, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.2626, 24.2576, 24.248, 24.2318, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.1981, 24.1933, 24.184, 24.1686, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [24.1496, 24.1449, 24.136, 24.1212, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    55.2814, 55.2461, 55.1792, 55.0333, 54.7579, 54.3157, 53.6649, 0.0, 0.0,
    0.0,
  ],
  [43.5789, 43.5558, 43.5107, 43.4136, 43.2343, 42.95, 47.9831, 0.0, 0.0, 0.0],
  [
    37.1402, 37.1229, 37.0885, 37.0154, 36.8834, 36.6769, 44.8469, 0.0, 0.0,
    0.0,
  ],
  [33.1104, 33.0967, 33.0685, 33.01, 32.9068, 32.7471, 42.8821, 0.0, 0.0, 0.0],
  [
    30.4323, 30.4208, 30.3969, 30.3484, 30.2648, 30.1367, 41.5771, 0.0, 0.0,
    0.0,
  ],
  [28.5678, 28.558, 28.5373, 28.4964, 28.4273, 28.3223, 40.6699, 0.0, 0.0, 0.0],
  [27.9247, 27.9153, 27.8953, 27.8562, 27.791, 0.0, 0.0, 0.0, 0.0, 0.0],
  [27.4533, 27.4444, 27.4251, 27.3878, 27.3265, 0.0, 0.0, 0.0, 0.0, 0.0],
  [27.0669, 27.0583, 27.0395, 27.0035, 26.945, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.7856, 26.7773, 26.7593, 26.7251, 26.6702, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.5864, 26.5803, 26.5376, 26.5042, 26.4507, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.5919, 26.5858, 26.5432, 26.5097, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.5946, 26.5885, 26.5459, 26.5124, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.596, 26.5899, 26.5473, 26.5138, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.5968, 26.5907, 26.5481, 26.5146, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [26.5973, 26.5912, 26.5486, 26.5151, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [
    54.5943, 54.5602, 54.4956, 54.3548, 54.0887, 53.6612, 53.0315, 0.0, 0.0,
    0.0,
  ],
  [48.7913, 48.7622, 48.7046, 48.5785, 48.346, 47.9801, 47.445, 0.0, 0.0, 0.0],
  [
    45.6159, 45.5893, 45.5349, 45.4159, 45.1997, 44.8637, 44.3557, 0.0, 0.0,
    0.0,
  ],
  [
    43.6287, 43.6037, 43.5511, 43.4364, 43.2302, 42.9124, 42.4192, 0.0, 0.0,
    0.0,
  ],
  [
    42.3048, 42.2808, 42.2295, 42.1179, 41.9188, 41.6137, 41.1332, 0.0, 0.0,
    0.0,
  ],
  [41.3788, 41.3556, 41.3052, 41.196, 41.0023, 40.7066, 40.2399, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
]
