/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import {
  createItem,
  createButton,
  slugify,
} from '../../../../src/core/dataitems'
import dayjs from 'dayjs'

export const pages = {
  'confirm-cancel': {
    modal: true,
    text: [],
    buttons: [
      { label: 'Yes', id: 'yes', uid: '0baa62eba1b1a7d7', control: true },
      { label: 'No', id: 'no', uid: '6c0e0e2956ec3933', control: true },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '9bc15702a2e49228',
      content: ['Are you sure you want to cancel this application?'],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'quotation-screen': {
    text: [],
    buttons: [
      {
        label: 'More Info',
        id: 'more-info',
        uid: '37610f3c75b1ae60',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'quote-screen',
      type: 'list',
      readonly: false,
      label: 'Quote Screen',
      uid: '906f1e4e54380f78',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '552d19e7a2f676a3',
          content: '<h2>Choose your cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '94e9794e11a5bd34',
          content:
            '<p>In order to receive a quotation, we require the following information from you</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'dca307e831b918e8',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='clickable'><a href='#additional'>Additional</a></li>\n<li class='clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'your-details',
          type: 'list',
          readonly: false,
          label: 'Your Details',
          uid: 'ff905a13b58fae40',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'd05b9947580c9acd',
              content:
                '<p>Please provide the following information in order to receive a quote.</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'b85bf420db7a7753',
              content: '<p>When were you born?</p>\n',
            },
            {
              id: 'date-of-birth',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Date Of Birth',
              uid: 'f0e0a8a867c4bd0c',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'date',
              component: 'DateSelector',
              maxYear: 2023,
              minYear: 1923,
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '91ffa524b2b4c765',
              content: '<p>What is your marital status?</p>\n',
            },
            {
              id: 'marital-status',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Marital Status',
              uid: 'fcd68df2ea5d3b92',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Divorced',
                'Married',
                'Single',
                'Widow',
                'Widower',
              ],
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '36a48639064fc078',
              content: '<p>What is your gender?</p>\n',
            },
            {
              id: 'gender',
              type: 'field',
              elementType: 'radio',
              readonly: false,
              label: 'Gender',
              uid: 'a8a4db22b3901177',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: ['Female', 'Male'],
            },
          ],
        },
        {
          id: 'cover-details',
          type: 'list',
          readonly: false,
          label: 'Cover Details',
          uid: '42f4b7abda23ffc8',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '6664920d6c4e475b',
              content: '<p>How much would you like to be covered for?</p>\n',
            },
            {
              id: 'main-cover-level-radio-group',
              type: 'list',
              readonly: false,
              label: 'Main Cover Level Radio Group',
              uid: '86d9fe89e7b66719',
              content: [
                {
                  id: 'ghc-250000',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'GHc 2500.00',
                  uid: 'efe6ae05521f38b5',
                  content: 'N',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'ghc-500000',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'GHc 5000.00',
                  uid: '7830571e86dc3bbb',
                  content: 'N',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'ghc-750000',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'GHc 7500.00',
                  uid: '7c963f5349b2fb53',
                  content: 'N',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'ghc-1000000',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'GHc 10000.00',
                  uid: '9837ff719db41699',
                  content: 'N',
                  publishChange: true,
                  options: ['Y'],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '565ccb4f9165b9fe',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '0ef550bcaf49074f',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'afaaab84c8a19188',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'f20aba0e17ed86fa',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '2e71b32f3296dcea',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Pure Risk Quotation Details',
    uri: 'pure-risk/quotation-details',
    template: 'pure-risk-quote-screen',
    class: 'pure-risk-quotation-details',
  },
  'more-info': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Done',
        id: 'done',
        uid: '4e5f7031a7643ff6',
        control: true,
      },
    ],
    item: {
      id: 'terms-explained',
      type: 'list',
      readonly: true,
      label: 'Terms Explained',
      uid: '8b62a96d18b02862',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'b7b78df2d1961f22',
          content: '<h2>Cash Bonus</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a66adff30810a4fd',
          content:
            '<p>This allows the policyholder to maintain an investment account with a good return and withdraw a maximum of 50% of the toal premium after 12 months. Full withdrawal may be made after three years of contribution.</p>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '4ecebb109aa16b43',
          content: '<h2>Inflation Protector</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'd39d9939c74cacfb',
          content:
            '<p>The premium will automatically increaseon the anniversary date by the percentage selected by the policyholder. It can be decreased during the lifetime of the policy but not increased.</p>\n',
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'additional-cover': {
    text: [],
    buttons: [
      {
        label: 'Add family',
        id: 'add-family',
        uid: '4944cc6ab7d9e98c',
        control: false,
      },
      {
        label: 'Remove family',
        id: 'remove-family',
        uid: 'a5ca5df087270493',
        control: false,
      },
      {
        label: 'Edit family',
        id: 'edit-family',
        uid: '0d437aba38a8517f',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'family-cover',
      type: 'list',
      readonly: false,
      label: 'Family Cover',
      uid: '3a61312ad3f9b2cf',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '761960c148272fb4',
          content: '<h2>Additional Cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '4bcd970ba2471072',
          content:
            '<p>You have the option to add additional cover to your policy. Please note that this will increase your premium accordingly.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'c31a91feab2846c8',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'your-family',
          type: 'list',
          readonly: true,
          label: 'Your Family',
          uid: '8b0f17f1bf94f994',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '4f73c363d5f77471',
              content:
                '<p>Do you want to cover any of your family members under this benefit?</p>\n',
            },
            {
              id: 'lives-added-basic',
              type: 'table',
              readonly: true,
              label: 'Lives Added Basic',
              uid: 'db93c8e11679b795',
              content: [],
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '44612adcb73f4f97',
              content: "<p><a href='#add-family'>Add Member</a></p>\n",
            },
          ],
        },
        {
          id: 'cash-bonus',
          type: 'list',
          readonly: false,
          label: 'Cash Bonus',
          uid: '98748f0868e79994',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'a235c34cefff34a6',
              content:
                '<p>Would you like to add a deposit to your cover?</p>\n',
            },
            {
              id: 'cash-bonus-premium',
              type: 'amount',
              elementType: 'input',
              readonly: false,
              label: 'Cash Bonus Premium',
              uid: '3d28ea23b7969e7b',
              content: '16.00',
              publishChange: true,
              inputType: 'number',
            },
          ],
        },
        {
          id: 'inflation-protector-option',
          type: 'list',
          readonly: false,
          label: 'Inflation Protector Option',
          uid: '62e58d83711fad00',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'ef4144d8ad0fa263',
              content:
                '<p>Would you like to add inflation protection to your cover?</p>\n',
            },
            {
              id: 'inflation-protector',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Inflation Protector',
              uid: 'd470dd067910031a',
              content: '10',
              publishChange: true,
              options: ['', '0', '5', '10', '15', '20', '25', '30'],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'e869bd5290f2b6af',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'e76d622e4049dc58',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'a6bce11b69a9734d',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'ac8eab068b415704',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'a0c2ac103202215d',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
      errors: ['*'],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Pure Risk Additional Benefits',
    uri: 'pure-risk/quotation-family',
    template: 'pure-risk-family-quote-screen',
    class: 'pure-risk-additional-benefits',
  },
  'add-family-members': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Child',
        id: 'child',
        uid: 'c5f621c4de771a35',
        control: false,
      },
      {
        label: 'Parent or In-Laws',
        id: 'parent-or-inlaws',
        uid: 'f7065b1abaf50262',
        control: false,
      },
      {
        label: 'Grandparents',
        id: 'grandparents',
        uid: '98c6225de3221391',
        control: false,
      },
      {
        label: 'Step Parents',
        id: 'step-parents',
        uid: '480f0d0f68bfd114',
        control: false,
      },
      {
        label: 'Guardian',
        id: 'guardian',
        uid: 'fc1a2c4093156337',
        control: false,
      },
      {
        label: 'Siblings',
        id: 'siblings',
        uid: 'fd82c2ca754f6530',
        control: false,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '178b7838c3fade6d',
      content: [
        ' Please specify your relationship to the life you want to add.',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'family-members-age-and-gender': {
    modal: true,
    text: ['Please provide the age and gender of your child'],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'additional-family-details',
      type: 'list',
      readonly: false,
      label: 'Additional Family Details',
      uid: '67be24d42f14d4d3',
      content: [
        {
          id: 'system-field-outcome',
          type: 'html',
          readonly: true,
          label: '',
          uid: '12c7379a842a1a9e',
          content: '<p></p>\n',
        },
        {
          id: 'age-of-member',
          type: 'integer',
          elementType: 'select',
          readonly: false,
          label: 'Age Of Member',
          uid: '31635e1db55cb34c',
          content: '',
          publishChange: true,
          errors: ['*'],
          tooltip: 'Age Of Member',
          options: [],
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'radio',
          readonly: false,
          label: 'Gender',
          uid: 'defa6021f5cbe751',
          content: '',
          publishChange: true,
          errors: ['*'],
          tooltip: 'Gender',
          options: ['Female', 'Male'],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'family-members-quotation-details': {
    modal: true,
    text: ['Select the cover for your child'],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'cover-level',
      type: 'list',
      readonly: false,
      label: 'Cover Level',
      uid: '2e7798924908f1da',
      content: [
        {
          id: 'cover-level-options-radio-group',
          type: 'list',
          readonly: false,
          label: 'Cover Level Options Radio Group',
          uid: '92c78ba475fd3241',
          content: [
            {
              id: 'option-e',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Option E Two Thousand Five Hundred',
              uid: 'ed133e9d3b61e8fa',
              content: '',
              publishChange: true,
              options: ['Y'],
            },
            {
              id: 'option-f',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Option F Five Thousand',
              uid: 'aa5cf7d105b2330c',
              content: '',
              publishChange: true,
              options: ['Y'],
            },
            {
              id: 'option-g',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Option G Seven Thousand Five Hundred',
              uid: 'a43c934a1b1ac869',
              content: '',
              publishChange: true,
              options: ['Y'],
            },
            {
              id: 'option-h',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Option H Ten Thousand',
              uid: '8f2fab9f8fa1941b',
              content: '',
              publishChange: true,
              options: ['Y'],
            },
          ],
        },
        {
          id: 'quoted-premium',
          type: 'list',
          readonly: true,
          label: 'Quoted Premium',
          uid: 'cedd22accd338cf9',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '797634d7d4510c65',
              content:
                '<p>Based on the information above your monthly premium will be:</p>\n',
            },
            {
              id: 'system-field-outcome',
              type: 'html',
              readonly: true,
              label: '',
              uid: '89247d9fc3a19518',
              content: '<p>GHc0.00</p>\n',
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'edit-family-members': {
    modal: true,
    text: [],
    buttons: [],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '4981e8376bfbfbd8',
      content: [
        'Please select the person whose details you would like to edit',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'remove-family-members': {
    modal: true,
    text: [],
    buttons: [
      {
        label: '',
        id: '',
        uid: 'bfb0a1ef332e5a00',
        control: false,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '4981e8376bfbfbd8',
      content: [
        'Please select the person you would like to remove from the policy',
      ],
    },
    title: 'Public Access \n Welcome to Elac',
  },
  'life-assured-identification': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'personal-details',
      type: 'list',
      readonly: false,
      label: 'Personal Details',
      uid: '7e4ee7bc0f8a01ad',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'c9d124707f9d1b14',
          content: '<h2>Personal Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a8902e1cd0cee07e',
          content:
            '<p>For us to complete the purchase, we require the following information</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'da5a292adf7f9381',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'your-details',
          type: 'list',
          readonly: false,
          label: 'Your Details',
          uid: '15954ab47f4ef0f0',
          content: [
            {
              id: 'first-names',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'First Names',
              uid: 'b0cb5e2013562d51',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'surname',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Surname',
              uid: '464b64f4d28fd6b9',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'date-of-birth',
              type: 'integer',
              elementType: 'input',
              readonly: true,
              label: 'Date Of Birth',
              uid: 'af59285b1b5638ec',
              content: '19950714',
              inputType: 'number',
            },
            {
              id: 'gender',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Gender',
              uid: 'fb0f9fc57b0b4539',
              content: 'Female',
              inputType: 'text',
            },
            {
              id: 'title',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Title',
              uid: 'df961919bc248ac4',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Adv',
                'Dr',
                'Miss',
                'Mr',
                'Mrs',
                'Ms',
                'Prof',
                'Rev',
              ],
            },
            {
              id: 'marital-status',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Marital Status',
              uid: 'd4f2748d1256fded',
              content: 'Single',
              inputType: 'text',
            },
            {
              id: 'idpassport-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'ID Number',
              uid: 'd78e2a31654d72d7',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'id-type-radio-group',
              type: 'list',
              readonly: false,
              label: 'ID Type Radio Group',
              uid: '55144de6e5b38f62',
              content: [
                {
                  id: 'dvla',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Dvla',
                  uid: 'bc235e8781bbe982',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'passport',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Passport',
                  uid: 'e59e68b8ac36a00f',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'voter-id',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Voter ID',
                  uid: '9215dbff34ced1f3',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'nhis',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Nhis',
                  uid: 'c0bebf727748350e',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'ssnit',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Ssnit',
                  uid: 'f79d0cafc5c371cd',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'nia-ghana-card',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Nia Ghana Card',
                  uid: '44966d1518c74a13',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
              ],
              errors: ['*'],
            },
          ],
        },
        {
          id: 'nationality',
          type: 'list',
          readonly: false,
          label: 'Nationality',
          uid: '71e8e17583d9906c',
          content: [
            {
              id: 'ghanaian',
              type: 'field',
              elementType: 'checkbox',
              readonly: false,
              label: 'Ghanaian',
              uid: 'dacdb9a1fa09156f',
              content: '',
              publishChange: true,
              options: ['Y', 'N'],
            },
            {
              id: 'other-nationality',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Other Nationality',
              uid: '8efff3d3ce1d471b',
              content: '',
              publishChange: true,
              options: [
                '',
                'Afghan',
                'Albanian',
                'Algerian',
                'American',
                'Andorran',
                'Angolan',
                'Argentine',
                'Armenian',
                'Aromanian',
                'Aruban',
                'Australian',
                'Austrian',
                'Azerbaijani',
                'Bahamian',
                'Bahraini',
                'Baltic German',
                'Baltic Russian',
                'Bangladeshi',
                'Barbadian',
                'Belarusian',
                'Belgian',
                'Belizean',
                'Bermudian',
                'Boer',
                'Bosniak',
                'Brazilian',
                'Breton',
                'British',
                'British Virgin Islander',
                'Bruneian',
                'Bulgarian',
                'Burkinabè',
                'Burundian',
                'Cambodian',
                'Cameroonian',
                'Canadian',
                'Cape Verdean',
                'Catalan',
                'Chadian',
                'Chilean',
                'Chinese',
                'Colombian',
                'Comorian',
                'Congolese',
                'Croatian',
                'Cuban',
                'Cypriot',
                'Czech',
                'Dane',
                'Dominican (Commonwealth)',
                'Dominican (Republic)',
                'Dutch',
                'East Timorese',
                'Ecuadorian',
                'Egyptian',
                'Emirati',
                'English',
                'Eritrean',
                'Estonian',
                'Ethiopian',
                'Falkland Islander',
                'Faroese',
                'Fijian',
                'Filipino',
                'Finn',
                'Finnish Swedish',
                'French Citizen',
                'Georgian',
                'German',
                'Ghanaian',
                'Gibraltar',
                'Greek',
                'Greek Macedonian',
                'Grenadian',
                'Guatemalan',
                'Guianese (French)',
                'Guinea Bissau National',
                'Guinean',
                'Guyanese',
                'Haitian',
                'Honduran',
                'Hong Kong',
                'Hungarian',
                'I Kiribati',
                'Icelander',
                'Indian',
                'Indonesian',
                'Iranian',
                'Iraqi',
                'Irish',
                'Israeli',
                'Italian',
                'Ivoirian',
                'Jamaican',
                'Japanese',
                'Jordanian',
                'Kazakh',
                'Kenyan',
                'Korean',
                'Kosovar',
                'Kurd',
                'Kuwaiti',
                'Kyrgyz',
                'Lao',
                'Latvian',
                'Lebanese',
                'Liberian',
                'Libyan',
                'Liechtensteiner',
                'Lithuanian',
                'Luxembourger',
                'Macedonian',
                'Macedonian Bulgarian',
                'Malagasy',
                'Malawian',
                'Malaysian',
                'Maldivian',
                'Malian',
                'Maltese',
                'Manx',
                'Mauritian',
                'Mexican',
                'Moldovan',
                'Mongolian',
                'Montenegrin',
                'Moroccan',
                'Namibian',
                'Nepalese',
                'New Zealander',
                'Nicaraguan',
                'Nigerian',
                'Nigerien',
                'Norwegian',
                'Other',
                'Pakistani',
                'Palauan',
                'Palestinian',
                'Panamanian',
                'Papua New Guinean',
                'Paraguayan',
                'Peruvian',
                'Pole',
                'Portuguese',
                'Puerto Rican',
                'Qatari',
                'Romanian',
                'Russian',
                'Rwandan',
                'Salvadoran',
                'Saudi',
                'Scot',
                'Senegalese',
                'Serb',
                'Sierra Leonean',
                'Sindhian',
                'Singaporean',
                'Slovak',
                'Slovene',
                'Somali',
                'Somalilander',
                'South African',
                'Spaniard',
                'Sri Lankan',
                'St Lucian',
                'Sudanese',
                'Surinamese',
                'Swede',
                'Swiss',
                'Syriac',
                'Syrian',
                'São Tomé and Príncipe',
                'Taiwanese',
                'Tajik',
                'Tanzanian',
                'Thai',
                'Tibetan',
                'Tobagonian',
                'Trinidadian',
                'Tunisian',
                'Turk',
                'Turkish Cypriot',
                'Tuvaluan',
                'Ugandan',
                'Ukrainian',
                'United States',
                'Uruguayan',
                'Uzbek',
                'Vanuatuan',
                'Venezuelan',
                'Vietnamese',
                'Welsh',
                'Yemeni',
                'Zambian',
                'Zimbabwean',
              ],
            },
          ],
        },
        {
          id: 'contact-details',
          type: 'list',
          readonly: false,
          label: 'Contact Details',
          uid: 'a52cb532d69115be',
          content: [
            {
              id: 'cell-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Cell Phone',
              uid: '274625debda227a4',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'email',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Email',
              uid: '22971e773226ed7b',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'email',
            },
          ],
        },
        {
          id: 'address-details',
          type: 'list',
          readonly: false,
          label: 'Address Details',
          uid: 'dafe780445dde155',
          content: [
            {
              id: 'postal-address',
              type: 'list',
              readonly: false,
              label: 'Postal Address',
              uid: 'd2316e1f9edb8c52',
              content: [
                {
                  id: 'addressee',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Addressee',
                  uid: 'caae9ee6f17bb452',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'po-box-or-private-bag',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'PO Box Or Private Bag',
                  uid: 'ba4b48af9a1bb9a8',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'region',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Region',
                  uid: '86e33aad7177122c',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Ahafo',
                    'Ashanti',
                    'Bono East',
                    'Brong Ahafo',
                    'Central',
                    'Eastern',
                    'Greater Accra',
                    'North East',
                    'Northern',
                    'Oti',
                    'Savannah',
                    'Upper East',
                    'Upper West',
                    'Volta',
                    'Western',
                    'Western North',
                  ],
                },
                {
                  id: 'town',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Town',
                  uid: '1cc461a249672d53',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Abamasarefan',
                    'Abandze',
                    'Abaoba',
                    'Abasa',
                    'Abasa Ajumaku',
                    'Abasi',
                    'Abdulaikrom',
                    'Abease',
                    'Abenasi',
                    'Abene',
                    'Abesem',
                    'Ablornu',
                    'Aboabo No 2',
                    'Abodom',
                    'Abofoo',
                    'Aboma',
                    'Abomoso',
                    'Abompe',
                    'Aboo',
                    'Abooso',
                    'Abora',
                    'Abora Oboasi',
                    'Abore',
                    'Abra',
                    'Abrekum',
                    'Abuakwa',
                    'Abuom',
                    'Abura Dunkwa',
                    'Aburi',
                    'Abutia - Kloe',
                    'Accra',
                    'Achawa Pt.',
                    'Achiase',
                    'Achimota',
                    'Ada',
                    'Adabokurom',
                    'Adaiso',
                    'Adansi',
                    'Adawsu',
                    'Adidakpavui',
                    'Adidome',
                    'Adidwan',
                    'Adina',
                    'Adjena',
                    'Adobo',
                    'Adonso',
                    'Aduaben',
                    'Aduamoa',
                    'Adubea',
                    'Adubiliyili',
                    'Adukrom',
                    'Adumadum',
                    'Adwamadiem',
                    'Adwufia',
                    'Adwum',
                    'Adzakoe',
                    'Afegame',
                    'Afiaso',
                    'Afienya',
                    'Aflao',
                    'Afosu',
                    'Aframso',
                    'Afratwo',
                    'Agogo',
                    'Agona',
                    'Agona Nkwanta',
                    'Aguna',
                    'Agyana',
                    'Ahamasu',
                    'Ahebensu',
                    'Ahemakurom',
                    'Ahenkro',
                    'Ahobre',
                    'Ahunda',
                    'Ahwerewa',
                    'Ahwiren',
                    'Aiyiribi',
                    'Ajemra',
                    'Ajumako',
                    'Ajumaku',
                    'Akantansu',
                    'Akanu',
                    'Akatsi',
                    'Akim Oda',
                    'Akim Swedru',
                    'Akim Tafo',
                    'Akoasi',
                    'Akontaanim',
                    'Akontanim',
                    'Akoroso',
                    'Akosombo',
                    'Akosombo Juapon',
                    'Akpabanya',
                    'Akra',
                    'Akrofuom',
                    'Akrokerri',
                    'Akropon',
                    'Akropong',
                    'Akumadan',
                    'Akuntuase',
                    'Akuse',
                    'Akwantonbra',
                    'Akwidaa',
                    'Akyekyere',
                    'Akyemfo Gyomuro',
                    'Alenda',
                    'Amamsi',
                    'Amankwakrom',
                    'Amasaman',
                    'Amasu',
                    'Ambo',
                    'Amedzofe',
                    'Amenfi',
                    'Amlakpo',
                    'Amoama',
                    'Amoma',
                    'Amoya',
                    'Ampeda',
                    'Ampeyoo',
                    'Anfoaga',
                    'Anibil',
                    'Ankaasi',
                    'Ankaful',
                    'Ankan',
                    'Anloga',
                    'Anomabo',
                    'Antabia',
                    'Anum',
                    'Anyaboni',
                    'Anyako',
                    'Anyanui',
                    'Anyinam',
                    'Anyinasu',
                    'Anyirawase',
                    'Aobo Aduamoa',
                    'Apam',
                    'Apapasu',
                    'Apataim',
                    'Aperade',
                    'Apesokubi',
                    'Apinso',
                    'Apowa',
                    'Asaafa',
                    'Asaam',
                    'Asaaman',
                    'Asafo',
                    'Asakraka',
                    'Asamankese',
                    'Asankrangua',
                    'Asantekrom',
                    'Asasitre',
                    'Asatowe',
                    'Asawinso',
                    'Asebu',
                    'Asekore',
                    'Asesewa',
                    'Ashanti',
                    'Asiakwa',
                    'Asiekpe',
                    'Asikuma',
                    'Assin Breku',
                    'Assin Manso',
                    'Assin Nyankumase',
                    'Assin Praso',
                    'Asuboa',
                    'Asuderi',
                    'Asukawkaw',
                    'Asumenya',
                    'Asunafo',
                    'Asuom',
                    'Asuotiano',
                    'Asutsuare',
                    'Ataase',
                    'Atebubu',
                    'Atesa',
                    'Atiavi',
                    'Atieku',
                    'Atimpoku',
                    'Atobase',
                    'Atuabo',
                    'Atuna',
                    'Ave-afiadenyiga',
                    'Ave-dakpa',
                    'Avenorpeme',
                    'Aveyime',
                    'Awatia',
                    'Awisa',
                    'Awisan',
                    'Awonakrom',
                    'Awurasa',
                    'Awutu',
                    'Axim',
                    'Ayanfure',
                    'Ayerede',
                    'Ayiase',
                    'Ayiem',
                    'Ayikuma',
                    'Ayinwafe',
                    'Ayomfo',
                    'Baabiaraneha',
                    'Baaglo',
                    'Baantama',
                    'Babile',
                    'Babopi',
                    'Bagape',
                    'Bagbiapi',
                    'Bagmare',
                    'Bagurugu',
                    'Bagwiwu',
                    'Baika',
                    'Bakanta',
                    'Bakau',
                    'Bakoniaba',
                    'Bale',
                    'Bamboi',
                    'Banaso',
                    'Banda',
                    'Banda - Nkwanta',
                    'Bandae',
                    'Bandiyili',
                    'Bangwan',
                    'Banko',
                    'Bansang',
                    'Banso',
                    'Banu',
                    'Barae No 2',
                    'Barra',
                    'Basa',
                    'Basie',
                    'Basse',
                    'Battor',
                    'Batume Junction',
                    'Bau',
                    'Bawdie',
                    'Bawiesibal',
                    'Bawjiase',
                    'Bawka',
                    'Bawku',
                    'Bechem',
                    'Bediakokurom',
                    'Begoro',
                    'Beku',
                    'Bekwai',
                    'Bende',
                    'Benso',
                    'Beposo',
                    'Berekum',
                    'Berekuso',
                    'Besease',
                    'Beyin',
                    'Biadam',
                    'Bibiani',
                    'Bielikpang',
                    'Billaw',
                    'Bimbago',
                    'Bimbagokpasenkpe',
                    'Bimbagu',
                    'Bimbila',
                    'Binaba',
                    'Binduri',
                    'Bisa',
                    'Biung',
                    'Boanim',
                    'Bodi',
                    'Bodwesango',
                    'Bofaso',
                    'Bogdoo',
                    'Bogorono',
                    'Bogorop',
                    'Bogoso',
                    'Bole',
                    'Bolgatanga',
                    'Bombori',
                    'Bomburi Fimbo',
                    'Bomfa',
                    'Bonakye',
                    'Bongo',
                    'Bonkrom',
                    'Bonohoma',
                    'Bonyere',
                    'Booyem',
                    'Bopa',
                    'Boro',
                    'Bortianor',
                    'Bososo',
                    'Bowiri',
                    'Brakwa',
                    'Branam',
                    'Brenase',
                    'Bresuanu',
                    'Brewaniase',
                    'Brikama',
                    'Brikamaba',
                    'Brofuyedru',
                    'Brufut',
                    'Brugbani',
                    'Buachipe',
                    'Buaka',
                    'Bugri',
                    'Bugu',
                    'Buipe',
                    'Bukanor',
                    'Bulangsa',
                    'Bulangsa Torsa',
                    'Bulenga',
                    'Bungeli',
                    'Bunkpurugu',
                    'Burai',
                    'Busa',
                    'Busie',
                    'Busunu',
                    'Butie',
                    'Buya',
                    'Cape Coast',
                    'Cape St. Paul',
                    'Cape Three Pts.',
                    'Carnpaga Sakogu',
                    'Chache',
                    'Chaisa',
                    'Chama',
                    'Chamba',
                    'Changbuni',
                    'Chanyili',
                    'Chase',
                    'Chatali',
                    'Chemfe',
                    'Chepuri',
                    'Cherepon',
                    'Cheropon',
                    'Chia',
                    'Chiana-asuma',
                    'Chichari',
                    'Christiansborg',
                    'Chuchiliga',
                    'Dabala',
                    'Daboase',
                    'Dabori',
                    'Dabova',
                    'Daboya',
                    'Dadeeso',
                    'Dado',
                    'Dadwen',
                    'Dafiama',
                    'Dafo',
                    'Dafor',
                    'Dagonkadi',
                    'Dakpam',
                    'Damanko',
                    'Dambai',
                    'Damongo',
                    'Danko',
                    'Dapa',
                    'Dasanyi',
                    'Datako-kurbor',
                    'Datano',
                    'Dawa',
                    'Dawadawa',
                    'Dawhenya',
                    'Debiribi',
                    'Debiso',
                    'Dedeso Wireko',
                    'Degbila',
                    'Dego',
                    'Demoh',
                    'Dena',
                    'Denkyira',
                    'Densubon',
                    'Denu',
                    'Diabakurom',
                    'Diari',
                    'Diasempa',
                    'Diaso',
                    'Digma',
                    'Digmamandari',
                    'Disiga',
                    'Dixcove',
                    'Dodowa',
                    'Dogokrom',
                    'Domeabra',
                    'Domenase',
                    'Dominase',
                    'Dompem',
                    'Dompoase',
                    'Doninga',
                    'Donkponkwanta',
                    'Doriman',
                    'Dormaa-ahenk',
                    'Doryum',
                    'Drakofe',
                    'Drobo',
                    'Dromakese',
                    'Duaburori',
                    'Duadaso',
                    'Duie',
                    'Dukusen',
                    'Dumienu',
                    'Dunkwa',
                    'Dunkwa On Offin',
                    'Duu',
                    'Dwija',
                    'Dwokwa',
                    'Dyayaw-nkwanta',
                    'Dzana',
                    'Dzita',
                    'Dzodze',
                    'Effiduase',
                    'Eikwe',
                    'Ejian',
                    'Ejisu',
                    'Ejura',
                    'Ekumdipe',
                    'Ekuropon',
                    'Ekyiamenfurom',
                    'Elmina',
                    'Elubo',
                    'Enchi',
                    'Enyan',
                    'Enyanmansu',
                    'Enyinabrim',
                    'Esaaman',
                    'Esalikawu',
                    'Essem',
                    'Essiama',
                    'Esuakrom',
                    'Fajara',
                    'Fanti',
                    'Farafenni',
                    'Farifari',
                    'Fawamam',
                    'Fenyi Yokoe',
                    'Feo-nabisi',
                    'Fete',
                    'Fetenta',
                    'Fian',
                    'Fianko',
                    'Fimbo',
                    'Fomena',
                    'Foso',
                    'Frankadua',
                    'Fuchmbluma',
                    'Fufulsu',
                    'Fufuo',
                    'Fumbisi',
                    'Fumsi',
                    'Fumso',
                    'Fuu',
                    'Ga',
                    'Gaa',
                    'Gambia No 1',
                    'Gambia No 2',
                    'Gbal',
                    'Gbalga',
                    'Gbangdaa',
                    'Gbantala',
                    'Gbari',
                    'Gbasinkpa',
                    'Gbateto',
                    'Gbedema',
                    'Gbeini',
                    'Gbemfo',
                    'Gbeni',
                    'Gbimsi',
                    'Gbogu',
                    'Gbulumpe',
                    'Gbung',
                    'Gida',
                    'Gitase',
                    'Gnani',
                    'Goantala',
                    'Goaso',
                    'Goka',
                    'Gokoron',
                    'Golokuati',
                    'Gonnokurom',
                    'Gorgu',
                    'Gpapga',
                    'Grube',
                    'Grupe',
                    'Gulibikomonpa',
                    'Guri',
                    'Guru-natinga',
                    'Gushie',
                    'Gwallo',
                    'Gwellukupulima',
                    'Gwo',
                    'Gwosi',
                    'Gyapa',
                    'Gyedua',
                    'Gyekiti',
                    'Gyema',
                    'Gyenegyene',
                    'Half Assini',
                    'Hamale',
                    'Harglakarpe',
                    'Have Etoe',
                    'Helu',
                    'Heluivi',
                    'Heman',
                    'Hevi',
                    'Hian',
                    'Hiawoanwu',
                    'Ho',
                    'Hohoe',
                    'Honuta',
                    'Huhunya',
                    'Hunt Valley',
                    'Huntodo',
                    'Hwidiem',
                    'Imbraim',
                    'Issa',
                    'Jaba',
                    'Jadima',
                    'Jafor',
                    'Jakai',
                    'Jakobo',
                    'Jamasi',
                    'Jamra',
                    'Jandra',
                    'Jang',
                    'Janjangbureh',
                    'Jasikan',
                    'Jato',
                    'Jawani',
                    'Jeffisi',
                    'Jegbuni',
                    'Jeinsusa',
                    'Jema',
                    'Jemile',
                    'Jerigu',
                    'Jeshwang',
                    'Jewi Wharf',
                    'Jeyiri',
                    'Jimdabuo',
                    'Jinfranukasuridabarshe',
                    'Jinpasi',
                    'Jirapa',
                    'Jogboi',
                    'Jombo',
                    'Juaben',
                    'Juabeso',
                    'Juana',
                    'Juapon',
                    'Juaso',
                    'Jumo',
                    'Junle',
                    'Juo',
                    'Kaase',
                    'Kabaka',
                    'Kablima',
                    'Kabonwele',
                    'Kade',
                    'Kadema',
                    'Kadjebi',
                    'Kafaba',
                    'Kafodzidzi',
                    'Kafuta',
                    'Kakawere',
                    'Kakorasua',
                    'Kakum',
                    'Kalagie',
                    'Kalba',
                    'Kaleo',
                    'Kalini',
                    'Kamsorio',
                    'Kananta',
                    'Kandige',
                    'Kanga',
                    'Kanifing',
                    'Kanilie',
                    'Kanjaga',
                    'Kanseri',
                    'Kantani',
                    'Kanyaga',
                    'Kapro',
                    'Kapua',
                    'Karaga',
                    'Karamenga',
                    'Karni',
                    'Kasa',
                    'Kasapen',
                    'Kasoa',
                    'Kasuridabarshe',
                    'Kataijeli',
                    'Katanga',
                    'Katanka',
                    'Katiu',
                    'Kawga',
                    'Kechebi',
                    'Kedzi',
                    'Kensere',
                    'Kenyasi No 1',
                    'Keta',
                    'Kete Krache',
                    'Kibi',
                    'Kikam',
                    'Kimabui',
                    'Kintampo',
                    'Kissi',
                    'Klavw',
                    'Klikor - Agbozume',
                    'Kobresu',
                    'Kodee',
                    'Kofi Djan',
                    'Kofiase',
                    'Koforidua',
                    'Kogota',
                    'Kogyei',
                    'Kokoben',
                    'Kokofu',
                    'Kokoso',
                    'Kokuma',
                    'Kologo',
                    'Kololi',
                    'Komampo',
                    'Komenda',
                    'Komonpa',
                    'Konfogsi',
                    'Kong',
                    'Kongo',
                    'Konkori',
                    'Kononga',
                    'Kopota',
                    'Kotoko',
                    'Kotu',
                    'Kotua',
                    'Koue',
                    'Kpadui',
                    'Kpagto',
                    'Kpalbe',
                    'Kpalbus',
                    'Kpalguni',
                    'Kpalisogu',
                    'Kpandae',
                    'Kpandu',
                    'Kpanianba',
                    'Kparekpare',
                    'Kparigu',
                    'Kpasa',
                    'Kpasenk',
                    'Kpatinga',
                    'Kpedze',
                    'Kpedzeglo',
                    'Kpembe',
                    'Kpetchu',
                    'Kpetoe',
                    'Kpeve',
                    'Kpoglo',
                    'Kpone',
                    'Kpong',
                    'Kpotsu',
                    'Kptokuomtoas',
                    'Kraboa Coaltar',
                    'Krakurom',
                    'Kroba',
                    'Krobo',
                    'Krokosue',
                    'Krokrobite',
                    'Ksauridabarshe',
                    'Kubari',
                    'Kudani',
                    'Kugnani',
                    'Kugnanidaboya',
                    'Kugri Natinga',
                    'Kuikpanga',
                    'Kujopere',
                    'Kukabila',
                    'Kukuo',
                    'Kukuom',
                    'Kukurantumi',
                    'Kulkpanga',
                    'Kulmae',
                    'Kulmasa',
                    'Kulun',
                    'Kulungugu',
                    'Kulupene',
                    'Kumasi',
                    'Kumawu',
                    'Kumbungu',
                    'Kumfa',
                    'Kunchoge Basirisan',
                    'Kundang',
                    'Kunkwa',
                    'Kunso',
                    'Kuntanse',
                    'Kuntunso',
                    'Kupullma',
                    'Kusawgu',
                    'Kusebi',
                    'Kusinaba',
                    'Kute',
                    'Kutukrom',
                    'Kwaben',
                    'Kwabenadapaa',
                    'Kwadwokurom',
                    'Kwadwonkromkurom',
                    'Kwahu Tafo',
                    'Kwaihunu',
                    'Kwakwanya',
                    'Kwaman',
                    'Kwame Danso',
                    'Kwame Kwasi',
                    'Kwamedwamenakurom',
                    'Kwameseikurom',
                    'Kwanyaku',
                    'Kwawu',
                    'Kwesiadai',
                    'Kyekyewere',
                    'Kyeraa',
                    'Kyeremasu',
                    'Kyinderi',
                    'Labadi',
                    'Labo',
                    'Lambusie',
                    'Lamin',
                    'Lampel',
                    'Lampurgo',
                    'Langantre',
                    'Langasi',
                    'Langbinsi',
                    'Lanja',
                    'Larabanga',
                    'Larekaga',
                    'Larteh',
                    'Laura',
                    'Lawra',
                    'Lepusi',
                    'Lilikse Nankpawie',
                    'Lobi',
                    'Loggo',
                    'Lolonya',
                    'Longoro',
                    'Lovi',
                    'Luari',
                    'Lungbunga',
                    'Lungni',
                    'Lziri',
                    'Maasu',
                    'Maknkuma',
                    'Makongo',
                    'Malume',
                    'Mamfe',
                    'Mampaten',
                    'Mampon',
                    'Mampong',
                    'Mampoten',
                    'Mandari',
                    'Mankarigy',
                    'Mankessim',
                    'Mankpan',
                    'Mankranso',
                    'Mankuma',
                    'Mankwadze',
                    'Mansing',
                    'Manso',
                    'Manso Nkwanta',
                    'Mantukwa',
                    'Masaka',
                    'Matse',
                    'Mawuabammu',
                    'Mengwe',
                    'Mengyi',
                    'Menusu',
                    'Meriche',
                    'Mim',
                    'Mirigu-nabaanga',
                    'Mishue',
                    'Moglo',
                    'Mogono',
                    'Mojina',
                    'Moree',
                    'Moseaso',
                    'Motigy',
                    'Mpana',
                    'Mpasaso',
                    'Mpasem',
                    'Mpataba',
                    'Mpatoam',
                    'Mpeam',
                    'Mpohor',
                    'Mpraeso',
                    'Mrigu-nabara',
                    'Mumford',
                    'Muna',
                    'Mungu',
                    'Murugu',
                    'Nabari',
                    'Nabisi',
                    'Nabogo',
                    'Nabolo',
                    'Nabori',
                    'Nadiyili',
                    'Nafkoliga',
                    'Naga',
                    'Naga Pwalugu',
                    'Nagbo',
                    'Nakong-atinia',
                    'Nakpali',
                    'Nakpayili',
                    'Nakundugu',
                    'Nakwa',
                    'Nakwabi',
                    'Nalerigu',
                    'Namanso',
                    'Namasua',
                    'Nambiri',
                    'Namvili',
                    'Nandawli',
                    'Nandom',
                    'Nangodi',
                    'Nangruma',
                    'Nankanduri',
                    'Nanton',
                    'Naro',
                    'Nasia',
                    'Natinga',
                    'Natog',
                    'Nauli',
                    'Navrongo',
                    'Nawuni',
                    'Nawuniyipala',
                    'Nereso',
                    'New Abirem',
                    'New Debiso',
                    'New Edubiase',
                    'New Longoro',
                    'New Ningo',
                    'New Town',
                    'New Worabon',
                    'Nframa',
                    'Nkasiem',
                    'Nkawie',
                    'Nkawkaw',
                    'Nkenkasu',
                    'Nketepa',
                    'Nkoranza',
                    'Nkroful',
                    'Nkuntunso',
                    'Nkwaben',
                    'Nkwanta',
                    'Nobekaw',
                    'Nogokpo',
                    'Npuasu',
                    'Nsaba',
                    'Nsawam',
                    'Nsawkaw',
                    'Nsinsin',
                    'Nsuaem',
                    'Nsuatre',
                    'Nsuta',
                    'Nteresa',
                    'Ntereso',
                    'Ntronan',
                    'Nungua',
                    'Nwereme',
                    'Nyakrom',
                    'Nyame Bekyere',
                    'Nyamease',
                    'Nyameyeadom',
                    'Nyang',
                    'Nyankpala',
                    'Nyankumase',
                    'Nyanyaano',
                    'Nyensungi',
                    'Nyinahin',
                    'Nyive',
                    'Nzema',
                    'Obawale',
                    'Obo',
                    'Obogu',
                    'Obomeng',
                    'Obuase',
                    'Obuasi',
                    'Odahu',
                    'Odami',
                    'Odonkawkrom',
                    'Odumase',
                    'Odumasi',
                    'Odumasua',
                    'Oduponkpebe',
                    'Ofaose',
                    'Ofe',
                    'Offinso',
                    'Ofoase',
                    'Oforikurom',
                    'Ohiamankyene Kpetchu',
                    'Ohyiayeanisa',
                    'Ojobi',
                    'Okomfookurom',
                    'Old Edabo',
                    'Old Ningo',
                    'Oseekodwokurom',
                    'Osenase',
                    'Osino',
                    'Osudoku',
                    'Otekpolu',
                    'Otrokpe',
                    'Owusukuro',
                    'Pabire',
                    'Paga',
                    'Palangasi',
                    'Palari',
                    'Pampawie',
                    'Pankron',
                    'Panyane',
                    'Papase',
                    'Patriensa',
                    'Pawa',
                    'Peblastre',
                    'Pechi',
                    'Peki',
                    'Pelungo',
                    'Pemenasi',
                    'Perisi',
                    'Pigu',
                    'Pina',
                    'Piong',
                    'Pisie',
                    'Pisigu',
                    'Poano',
                    'Poasi',
                    'Pokoasi',
                    'Polmakom',
                    'Pomadze',
                    'Pong-tamale',
                    'Pramkese',
                    'Prampram',
                    'Prang',
                    'Prestea',
                    'Primase',
                    "Prince's Town",
                    'Pulo',
                    'Pumpuni',
                    'Pusiga',
                    'Pusuga',
                    'Pute',
                    'Pwalugu',
                    'Saani',
                    'Sabari',
                    'Saboba',
                    'Sabonjida',
                    'Sabuli',
                    'Sabusa',
                    'Sabzundi',
                    'Sagadugu',
                    'Sakai',
                    'Sakala',
                    'Sakode - Etae',
                    'Sakomono',
                    'Sakpa',
                    'Salaga',
                    'Salkpang',
                    'Saltpond',
                    'Samambow',
                    'Samanhyia',
                    'Sambo',
                    'Sambreboe',
                    'Sambrun',
                    'Sambu',
                    'Samila',
                    'Samoa',
                    'Sampa',
                    'Sampemo',
                    'Sampraso',
                    'Sandema',
                    'Sang',
                    'Sangbaa',
                    'Sankalaa',
                    'Sankore',
                    'Sankpala',
                    'Sanujan',
                    'Sanyang',
                    'Sapiegu',
                    'Saranouse',
                    'Saripere',
                    'Sarpere Ambo',
                    'Sarri',
                    'Saru',
                    'Savelugu',
                    'Sawla',
                    'Seekwa',
                    'Sefwi Anhwiaso',
                    'Sefwi Bekwai',
                    'Sefwi Wiawso',
                    'Segbasi',
                    'Sekasua',
                    'Seketia',
                    'Sekondi Takoradi',
                    'Sekpe',
                    'Sekyedumase',
                    'Sekyerekurom',
                    'Sema',
                    'Senase',
                    'Senchi',
                    'Seneso',
                    'Senfi',
                    'Senkyiem',
                    'Senya Bereku',
                    'Seripe',
                    'Serrekunda',
                    'Sese',
                    'Seseamam',
                    'Shama',
                    'Shi',
                    'Shia',
                    'Shiega',
                    'Shieni',
                    'Silibele',
                    'Sinebaga',
                    'Singa',
                    'Siriminchu',
                    'Sogakope',
                    'Soma(mansakonko)',
                    'Somanya',
                    'Sombisi',
                    'Soo',
                    'Sovie',
                    'Srogbae',
                    'Subri',
                    'Suggo',
                    'Suhum',
                    'Sukuta',
                    'Sumiso',
                    'Sumsei',
                    'Sung',
                    'Sunsan',
                    'Sunyani',
                    'Surukrom',
                    'Swedru',
                    'Taali',
                    'Tachiman',
                    'Tachimantia',
                    'Tadieso',
                    'Tadzevu',
                    'Taenso',
                    'Tahima',
                    'Takoradi',
                    'Takpo',
                    'Tamale',
                    'Tamale Port',
                    'Tamaligu',
                    'Tampion',
                    'Tamso',
                    'Tanaso',
                    'Tangasia',
                    'Tanina',
                    'Tanji',
                    'Tanoso',
                    'Tantali',
                    'Tarkwa',
                    'Tarongo',
                    'Tatale',
                    'Teainano',
                    'Techigu',
                    'Teekyere',
                    'Tefle',
                    'Tema',
                    'Tenfian',
                    'Teselima',
                    'Teshi',
                    'Tetema',
                    'Tetrem',
                    'Tijo',
                    'Tikobo No 1',
                    'Tikobo No 2',
                    'Tikpang',
                    'Tilli',
                    'Tinga',
                    'Tolon',
                    'Tongo',
                    'Torsa',
                    'Toyanyili',
                    'Trede',
                    'Trom',
                    'Tromeso',
                    'Tsito',
                    'Tsrefe',
                    'Tugu',
                    'Tuluwe',
                    'Tumfakura',
                    'Tumu',
                    'Tuna',
                    'Tuobodom',
                    'Turumi',
                    'Tutukpene',
                    'Tutumi',
                    'Tuwuo',
                    'Twenene',
                    'Twifo Praso',
                    'Twifo-mampon',
                    'Ugando',
                    'Vaggo',
                    'Vakpo',
                    'Vamboi',
                    'Wa',
                    'Waiasi',
                    'Wakawaka',
                    'Walembele',
                    'Walewale',
                    'Wam',
                    'Wamanafo',
                    'Wamfie',
                    'Wangasi - Turu',
                    'Waniada',
                    'Wapuli',
                    'Warinvaga',
                    'Wasipe',
                    'Waso',
                    'Watoro',
                    'Waya',
                    'Wechiau',
                    'Wegbe',
                    'Weija',
                    'Wenchi',
                    'Wenchiki',
                    'Wiaga-yisobso',
                    'Wiamoase',
                    'Wiasi',
                    'Winisa',
                    'Winkogo',
                    'Winneba',
                    'Wiosa',
                    'Woe',
                    'Worakese',
                    'Worawora',
                    'Wuinta',
                    'Wulensi',
                    'Wulensimpana',
                    'Wumenu',
                    'Wungu',
                    'Wurnpigo',
                    'Wuru',
                    'Wute',
                    'Yabraso',
                    'Yabum',
                    'Yagaba',
                    'Yahaiyape',
                    'Yakase',
                    'Yala',
                    'Yamatwa',
                    'Yankoman',
                    'Yankzia',
                    'Yapei',
                    'Yapessa',
                    'Yawga',
                    'Yazori',
                    'Yendi',
                    'Yepalsi',
                    'Yezes',
                    'Yikpabango',
                    'Yipala',
                    'Yundum',
                    'Zabzugu',
                    'Zanduli',
                    'Zankum',
                    'Zariboku',
                    'Zebilla',
                    'Zegbeli',
                    'Zin',
                    'Zion',
                    'Zlope',
                    'Zoggo',
                    'Zoko',
                    'Zongo-markyeri',
                    'Zuarugu',
                    'Zuro',
                  ],
                },
              ],
            },
            {
              id: 'residential-address',
              type: 'list',
              readonly: false,
              label: 'Residential Address',
              uid: 'ca9800178ee79ee6',
              content: [
                {
                  id: 'suburb',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Suburb',
                  uid: '806b6bfca74a2527',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'street',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Street',
                  uid: '6affe5c349829f20',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'your-digital-address',
              type: 'list',
              readonly: false,
              label: 'Your Digital Address',
              uid: '39dc30f452b69ccb',
              content: [
                {
                  id: 'contracting-party-digital-address',
                  type: 'list',
                  readonly: false,
                  label: 'Contracting Party Digital Address',
                  uid: '0b95b82789ac7abd',
                  content: [
                    {
                      id: 'digital-address',
                      type: 'field',
                      elementType: 'input',
                      readonly: false,
                      label: 'Digital Address',
                      uid: '6ab5c06da4d0229c',
                      content: '',
                      publishChange: true,
                      inputType: 'text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'b4963be9e1f26d1d',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '0549df4935a4241f',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'd400baac16a5b3f1',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'c5ff56a5ca01b467',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'abed9ed6fd97427b',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Pure Risk Personal Details',
    uri: 'pure-risk/personal-details',
    template: 'pure-risk-family-quote-screen',
    class: 'pure-risk-personal-details',
  },
  'trustee-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'trustee-details',
      type: 'list',
      readonly: false,
      label: 'Trustee Details',
      uid: '2ac467c899187a6f',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '716305c544aa14a7',
          content: '<h2>Trustee Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '7c28e967f24c755b',
          content:
            '<p>Please provide the details of your nominated trustee below</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '5b2ce4d53ab64bc2',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'd37b809ecb78a979',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: 'c89c29373f3e3c1b',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '5723ca9ce0da97c1',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'date',
          component: 'DateSelector',
          maxYear: 2023,
          minYear: 1923,
        },
        {
          id: 'title',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Title',
          uid: '5e62b5dd60e864f7',
          content: '',
          publishChange: true,
          errors: ['*'],
          tooltip: 'Title',
          options: ['', 'Adv', 'Dr', 'Miss', 'Mr', 'Mrs', 'Ms', 'Prof', 'Rev'],
        },
        {
          id: 'relationship',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Relationship',
          uid: '87473b2d56cc829e',
          content: '',
          publishChange: true,
          errors: ['*'],
          tooltip: 'Relationship',
          options: [
            '',
            'Brother',
            'Daughter',
            'Estate',
            'Father',
            'Father-In-Law',
            'Grandfather',
            'Grandmother',
            'Mother',
            'Mother-In-Law',
            'Sister',
            'Son',
            'Spouse',
            'Testament',
            'Trust',
          ],
        },
        {
          id: 'cell-phone',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Cell Phone',
          uid: '7d6ed2ce591e1ce6',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'tel',
          component: 'PhoneNumber',
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '0dc67ce6e7542cdf',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '6d9c9b8cca642194',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '30111d613a3010b5',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '0c8496dd91693bb7',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'c915d127733ac59f',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Pure Risk Trustee Details',
    uri: 'pure-risk/trustee-details',
    class: 'pure-risk-trustee-details',
  },
  'additional-lives': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'additional-lives',
      type: 'list',
      readonly: false,
      label: 'Additional Lives',
      uid: 'cc6e20b8621ebea2',
      content: [],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Pure Risk Lives Assured',
    uri: 'pure-risk/lives-assured',
    template: 'pure-risk-lives-assured-screen',
    class: 'pure-risk-lives-assured',
  },
  'payment-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: '3877b872fb50a0a7',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'payment-method',
          type: 'list',
          readonly: false,
          label: 'Payment Method',
          uid: '935b4319fdcbab12',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'b3820d390c57590c',
              content: '<p>Choose a payment method</p>\n',
            },
            {
              id: 'payment-method-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payment Method Radio Group',
              uid: '5f29ef713684dcdd',
              content: [
                {
                  id: 'debit-order',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Debit Order',
                  uid: '66026bce0175bd85',
                  content: 'N',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'stop-order',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Stop Order',
                  uid: 'addc4bc1f4c832eb',
                  content: 'N',
                  publishChange: true,
                  options: ['Y'],
                },
              ],
              errors: ['*'],
            },
          ],
        },
        {
          id: 'payment-commencement',
          type: 'list',
          readonly: false,
          label: 'Payment Commencement',
          uid: '9e87ce8c7a83a2f5',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'a50540585ca12c07',
              content:
                '<p>Choose the payment start date and your payment frequency</p>\n',
            },
            {
              id: 'payment-start-date',
              type: 'integer',
              elementType: 'select',
              readonly: false,
              label: 'Payment Start Date',
              uid: 'cd4537bfa3fb5f7d',
              content: '',
              publishChange: true,
              options: [],
            },
            {
              id: 'payment-frequency-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payment Frequency Radio Group',
              uid: '109a457cd91bf639',
              content: [
                {
                  id: 'monthly',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Monthly',
                  uid: '3f6c19ccd48cbeeb',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'quarterly',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Quarterly',
                  uid: '3085ba131761cdaa',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'bi-annually',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Bi Annually',
                  uid: '58f1bdb69bf88bd3',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
                {
                  id: 'annually',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Annually',
                  uid: '9d98a9533a9281e4',
                  content: '',
                  publishChange: true,
                  options: ['Y'],
                },
              ],
            },
          ],
        },
        {
          id: 'payer-person',
          type: 'list',
          readonly: false,
          label: 'Payer Person',
          uid: 'ce35234c9c489acc',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'ee7c49bb35030b57',
              content: '<p>Who will be paying for this policy?</p>\n',
            },
            {
              id: 'payer-person-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payer Person Radio Group',
              uid: '2c1019491493ebab',
              content: [
                {
                  id: 'myself',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Myself',
                  uid: '4d7d4200a4fd7585',
                  content: 'Y',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
                {
                  id: 'my-spouse',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'My Spouse',
                  uid: '6198ae13e0925e88',
                  content: 'N',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '92758bfed52da6b5',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '5035ae517e7b1524',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8b31df748a9b25f8',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '261486a530f75645',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '8588b418b3adff34',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Payment Details',
    uri: 'ffp-unlimited/payment-details',
    template: 'ffp-unlimited-payment-details-screen',
    class: 'ffp-unlimited-payment-details',
  },
  'debit-order-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: 'c0ae9ce5146abcb5',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'debit-order-details',
          type: 'list',
          readonly: false,
          label: 'Debit Order Details',
          uid: '72dff4d800131d77',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '82bc4d510e05f52c',
              content: '<p>Complete the following details</p>\n',
            },
            {
              id: 'new-account-details',
              type: 'list',
              readonly: false,
              label: 'New Account Details',
              uid: '78b8ef88dc5a56fd',
              content: [
                {
                  id: 'account-holder-name',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Account Holder Name',
                  uid: 'ab65844cd0d1e094',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'account-type',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Account Type',
                  uid: 'fbdc797b84a0f751',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['', 'Current', 'Savings'],
                },
                {
                  id: 'account-entity-name',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Account Entity Name',
                  uid: '41ac2dfe1beb4c8d',
                  tooltip: 'Account Entity Name',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Absa (gh) Ltd',
                    'Access Bank Ghana Ltd',
                    'Agricultural Development Bank',
                    'Bank Of Africa',
                    'Cal Bank Ltd',
                    'Consolidated Bank Ghana',
                    'Ecobank Ghana Ltd',
                    'Fidelity Bank Ghana',
                    'First Atlantic Bank Ltd',
                    'First Bank Nigeria',
                    'Gcb Bank Ltd',
                    'Guaranty Trust (gh) Ltd',
                    'National Investment Bank',
                    'Omni Bank Ghana Ltd',
                    'Prudential Bank Ltd',
                    'Republic Bank (gh) Ltd',
                    'Societe General Ghana Ltd',
                    'Stanbic Bank Ghana Ltd',
                    'Standard Chartered Bank',
                    'United Bank For Africa (gh) Ltd',
                    'Universal Merchant Bank (gh) Ltd',
                    'Zenith Bank',
                  ],
                },
                {
                  content: '',
                  elementType: 'select',
                  errors: ['*'],
                  id: 'account-branch-name',
                  label: 'Account Branch Name',
                  options: [
                    '',
                    '1ST LIGHT',
                    '31ST DECEMBER MARKET',
                    'A & C MALL',
                    'A & C SHOPPING MAIL',
                    'A AND C',
                    'A AND C MALL',
                    'A COMMUNITY BANK LTD-LA',
                    'A RURAL BANK LTD-AMASAMAN',
                    'A&C MALL',
                    'AA ASANTEWAA RURAL BANK LTD',
                    'AARA RURAL BANK LTD-PAGA',
                    'AASEMAN RURAL BANK LTD-KAASE',
                    'ABBOSEY OKAI',
                    'ABBOSEY OKAI BRANCH',
                    'ABEKA',
                    'ABEKA BRANCH',
                    'ABEKA LAPAZ',
                    'ABEKA LAPAZ BRANCH',
                    'ABEKALAPAZ',
                    'ABELENKPE',
                    'ABETIFI',
                    'ABETIFI BRANCH',
                    'ABII NATIONAL SAVINGS AND LOANS LTD',
                    'ABN-50129-TEMA COMMUNITY 9',
                    'ABOABO',
                    'ABOABO MARKET TAMALE',
                    'ABOKOBI RURAL BANK LTD-ABOKOBI',
                    'ABOR',
                    'ABORA',
                    'ABOSEY OKAI',
                    'ABOSSEY OKAI',
                    'ABOSSEY OKINE',
                    'ABREPO JUNCTION',
                    'ABREPO JUNCTION BRANCH',
                    'ABREPO MAIN',
                    'ABREPO MAIN BRANCH',
                    'ABUAKWA',
                    'ABUAKWA BRANCH',
                    'ABURA DUNKWA',
                    'ABURI',
                    'ABZUGU RURAL BANK LTD',
                    'ACCCRA NEWTOWN',
                    'ACCION',
                    'ACCRA',
                    'ACCRA & MAKOLA',
                    'ACCRA BRANCH',
                    'ACCRA CENTRAL',
                    'ACCRA CENTRAL POST',
                    'ACCRA CENTRAL POSTBANK',
                    'ACCRA CORPORATE SERVICE CENTRE',
                    'ACCRA MAIN',
                    'ACCRA MALL',
                    'ACCRA NEW TOWN',
                    'ACCRA SHOPPING MAIL',
                    'ACCRA SHOPPING MALL',
                    'ACHIASE',
                    'ACHIMOTA',
                    'ACHIMOTA BRANCH',
                    'ACHIMOTA MILE 7',
                    'ACTION CHAPEL',
                    'ADA FOAH',
                    'ADA RURAL BANK LTD-KASSEH',
                    'ADABOKROM',
                    'ADABRAKA',
                    'ADABRAKA BRANCH',
                    'ADANSI RURAL BANK LTD-FOMENA',
                    'ADAWSO',
                    'ADB HOUSE',
                    'ADB HOUSE BRANCH',
                    'ADEHYEMAN SAVINGS & LOAN',
                    'ADEHYEMAN SAVINGS AND LOANS',
                    'ADENTA',
                    'ADENTA BRANCH',
                    'ADENTA MARKET',
                    'ADENTAN',
                    'ADI HARBOUR',
                    'ADIAKIO WAYA',
                    'ADIDOME',
                    'ADJABENG',
                    'ADJIRINGANOR',
                    'ADONTEN COMMUNITY BANK LTD-NEW TAFO',
                    'ADUM',
                    'ADUM BRANCH',
                    'ADUM POST',
                    'ADUMAN RURAL BANK LTD-BADU',
                    'ADUM-KUMASI',
                    'AFANA RURAL BANK LTD-SAMPA',
                    'AFFUL NKWANTA',
                    'AFFUL NKWANTA BRANCH',
                    'AFLAO',
                    'AFRAM RURAL BANK LTD-TEASE',
                    'AFRANSIE',
                    'AGAVE RURAL BANK LTD-DABALE',
                    'AGBOBLOSHIE',
                    'AGBOGBLOSHIE',
                    'AGBOGBOLSHIE',
                    'AGBOZUME',
                    'AGOGO',
                    'AGONA ABREM',
                    'AGONA AHANTA',
                    'AGONA ASHANTI',
                    'AGONA NKWANTA',
                    'AGONA RURAL BANK LTD-KWANYAKU',
                    'AGONA SWEDRU',
                    'AHAFO ANO PREMIER RURAL BANK LTD-WIOSO',
                    'AHAFO COMMUNITY RURAL BANK LTD-KUKUOM',
                    'AHANTAMAN RURAL BANK LTD-AGONA NKWANTA',
                    'AHINSAN KUMASI',
                    'AHODWO',
                    'AHODWO BRANCH',
                    'AIRPORT',
                    'AIRPORT BRANCH',
                    'AIRPORT CITY',
                    'AIRPORT CITY BRANCH',
                    'AIRPORT EAST',
                    'AIYINASE',
                    'AJUMAKO',
                    'AKASTI',
                    'AKATAKYIMAN RURAL BANK LTD-KOMENDA',
                    'AKATSI',
                    'AKIM ABENASE',
                    'AKIM ADUASA',
                    'AKIM BOSOME RURAL BANK LTD-AKIM SWEDRU',
                    'AKIM ODA',
                    'AKIM SWEDRU',
                    'AKOFO HOUSE',
                    'AKONTOMBRA',
                    'AKOSOMBO',
                    'AKOSOMBO BRANCH',
                    'AKOTI RURAL BANK LTD-ASSIN AKROPONG',
                    'AKROFUOM AREA RURAL BANK LTD-AKROFUOM',
                    'AKROPONG',
                    'AKROPONG AKWAPIM',
                    'AKROSO',
                    'AKUAPEM RURAL BANK LTD-MAMFE',
                    'AKUM RURAL BANK LTD-ELMINA',
                    'AKUMADAN',
                    'AKUSE',
                    'AKWADUM',
                    'AKWATIA',
                    'AKWETEYMAN',
                    'AKYEM MANSA RURAL BANK LTD-AYIREBI',
                    'AKYEPIM RURAL BANK LTD-GOMOA-DAW',
                    'ALABAR',
                    'ALABAR BRANCH',
                    'ALPHA CAPITAL SAVING&L LTD',
                    'AMAKOM',
                    'AMAKOM BRANCH',
                    'AMANANO RURAL BANK LTD-NYINAHIM',
                    'AMANTEN',
                    'AMASAMAN',
                    'AMASIE WEST RURAL BANK LTD-ANTOAKROM',
                    'AMATIN AND KASEI COMMUNITY BANK LTD-KASEI',
                    'AMENFIMAN RURAL BANK LTD-WASSA AKROPONG',
                    'AMERICAN HOUSE BRANCH',
                    'AMFIE RURAL BANK LTD-WAMFIE',
                    'ANDOM RURAL BANK LTD-NANDOM',
                    'ANGBE RURAL BANK LTD-PRAMPRAM',
                    'ANKOBRA WEST (ESIAMA) RURAL BANK LTD-ESIAMA',
                    'ANLO RURAL BANK LTD-ANLOGA',
                    'ANLOGA',
                    'ANSOMAN RURAL BANK LTD-MANSO-AMENFI',
                    'ANTEAKWA RURAL BANK LTD-KOFORIDUA',
                    'ANUM APAPAM',
                    'ANUM RURAL BANK LTD-ANUM',
                    'ANYA KROBO RURAL BANK LTD-ODUMASE-KROBO',
                    'ANYAA',
                    'ANYAA MARKET BRANCH',
                    'ANYINAM',
                    'APAM',
                    'APENKWA',
                    'APENKWA BRANCH',
                    'APEX BANK - BOLGA CENTRE-BOLGATANGA',
                    'APEX BANK - CAPE COASTCAPE COAST',
                    'APEX BANK - TAMALE CENTRE-TAMALE',
                    'APEX BANK -HEAD OFFICE-SOUTH RIDGE ACCRA',
                    'APEX BANK -HOHOE CENTRE-HOHOE',
                    'APEX BANK KOFORIDUA CENTRE-KOFORIDUA',
                    'APEX BANK -SUNYANI CENTRE-SUNYANI',
                    'APEX BANK -WA CENTRE-WA',
                    'APEX BANKACCRA CENTRE-ACCRA',
                    'APEX BANK-HO CENTRE-HO',
                    'APEX BANK-KUMASIKUMASI',
                    'APEX BANK-TAKORADI CENTRE-TAKORADI',
                    'APEX LINK CENTRE-CEDI HOUSE',
                    'APITAL RURAL BANK LTD-ABESIM',
                    'APLAKU',
                    'APRA RURAL BANK LTD-PRANG',
                    'ARENA BRANCH',
                    'ASAFO',
                    'ASAFO BRANCH',
                    'ASAFO MARKET',
                    'ASAMANKESE',
                    'ASANKRAGUA',
                    'ASANKRAGWA',
                    'ASANTE AKYEM RURAL BANK LTD-JUANSA',
                    'ASAWINSO BRANCH',
                    'ASAWINSO RURAL BANK LTD-SEFWI-ASAWINSO',
                    'ASEBU',
                    'ASEMKROM',
                    'ASEMPANEYE',
                    'ASH TOWN',
                    'ASHAIMAN',
                    'ASHAIMAN BRANCH',
                    'ASHAIMAN LEBABNON',
                    'ASHAIMAN MAIN BRANCH',
                    'ASHAIMAN ROUND ABOUT BRANCH',
                    'ASHALLEY BOTWE BRANCH',
                    'ASHANTI BEKWAI',
                    'ASHANTI MAMPONG',
                    'ASHIAMAN',
                    'ASHIYEA BRANCH',
                    'ASHTOWN',
                    'ASHTOWN BRANCH',
                    'ASHTOWN EAST',
                    'ASHTOWN EAST BRANCH',
                    'ASIAKWA',
                    'ASIKUMA',
                    'ASOKORE RURAL BANK LTD-ASOKRE',
                    'ASOKWA',
                    'ASOKWA BRANCH',
                    'ASSIN FOSO',
                    'ASSIN FOSU',
                    'ASSIN FOSU POST',
                    'ASSINMAN RURAL BANK LTD-ASSIN MANSO',
                    'AST MANPRUSI COMMUNITY BABK LTD-GAMBAGA',
                    'ASTERN GOMOA ASSIN RURAL BANK LTD-GOMOA-DOMINASE',
                    'ASUBONTEN RURAL BANK LTD-WORAWORA',
                    'ASUOGYAMAN RURAL BANK LTD-ACCRA',
                    'ASUOPRA RURAL BANK LTD-AFOSU',
                    'ASUTIFI RURAL BANK LTD-ACERENSUA',
                    'ASUTUARE',
                    'ASYLUM DOWN',
                    'ATAABADZE',
                    'ATEBUBU',
                    'ATIWA RURAL BANK',
                    'ATIWA RURAL BANK LTD-KWABENG',
                    'ATOMIC BRANCH',
                    'ATOMIC JUNCTION BRANCH',
                    'ATONSU',
                    'ATONSU BRANCH',
                    'ATWEABAN RURAL BANK LTD-SUNYANI',
                    'ATWIMA KWANWOMA RURAL BANK LTD-PAKYI NO. 2',
                    'ATWIMA MPONUA RURAL BANK LTD-TOASE',
                    'ATWIMA RURAL BANK LTD-FOASE',
                    'AVENOR RURAL BANK LTD-AKATSI',
                    'AVENUE CENTRAL',
                    'AWASO',
                    'AWJIASE AREA RURAL BANK LTD-AWUTU BAWJIASE',
                    'AWRA RURAL BANK LTD-WA',
                    'AWUTU',
                    'AWUTU EMASA RURAL BANK LTD-AWUMASA',
                    'AXIM',
                    'AXIM ROAD BRANCH',
                    'AYANFURI',
                    'AYETEPA',
                    'AYIREBI',
                    'AYOMSO',
                    'BAATSONA',
                    'BAATSONA BRANCH',
                    'BADUKROM',
                    'BADUMAN RURAL BANK LTD-BADU',
                    'BAMAHU',
                    'BANDA NKWANTA',
                    'BANTAMA',
                    'BANTAMA 1',
                    'BANTAMA BRANCH',
                    'BANTAMA GNTC BRANCH',
                    'BASYONDE',
                    'BATSONA BRANCH',
                    'BAWALESHIE',
                    'BAWDJIASE',
                    'BAWJIASE AREA RURAL BANK LTD-AWUTU BAWJIASE',
                    'BAWKU',
                    'BAWKU BRANCH',
                    'BCM',
                    'BECHEM',
                    'BEGORO',
                    'BEIGE CAPITAL',
                    'BEKWAI',
                    'BEPOSO',
                    'BEREKUM',
                    'BESEASE',
                    'BESSFA RURAL BANK LTD-GARU',
                    'BESTPOINT SAVINGS AND LOANS',
                    'BEYIN',
                    'BI RURAL BANK LTD-HOHOE',
                    'BIA DEBISO',
                    'BIA-TORYA RURAL BANK LTD-BONSU-NKWANTA',
                    'BIBIANI',
                    'BIMBILLA',
                    'BINABA',
                    'BIRIWA',
                    'BOGOSO',
                    'BOGOSU',
                    'BOINSO',
                    'BOKOBI RURAL BANK LTD-ABOKOBI',
                    'BOLE',
                    'BOLE BRANCH',
                    'BOLGA COMMERCIAL ROAD BRANCH',
                    'BOLGA POLICE STATION ROAD BRANCH',
                    'BOLGA POST',
                    'BOLGA POSTBANK',
                    'BOLGATANGA',
                    'BOMAA AREA RURAL BANK LTD-BOMAA',
                    'BOMOSADU RURAL BANK LTD',
                    'BOND SAVINGS AND LOANS',
                    'BONGO',
                    'BONGO RURAL BANK LTD-BOLGATANGA',
                    'BONSO NKWANTA',
                    'BONSU NKWANTA',
                    'BONTRASE',
                    'BONZALI RURAL BANK LTD-KUMBUNGU',
                    'BORIMANGA RURAL BANK LTD-SAVELEGU',
                    'BOSOME FREHO RURAL BANK LTD-KUMASI',
                    'BOSOMTWI RURAL BANK LTD-KUNTANASE',
                    'BOUNDARY ROAD',
                    'BRAKWA BRAMAN RURAL BANK LTD-BRAKWA',
                    'BREEZE EAST LEGON',
                    'BREMAN ASIKUMA',
                    'BREMAN UGC',
                    'BUI',
                    'BUILSA COMMUNITY BANK LTD-SANDEMA',
                    'BUIPE',
                    'BUNKPRUGU',
                    'BURMA CAMP',
                    'BURMA CAMP BRANCH',
                    'BUSIE',
                    'BUSUNYA',
                    'BUTAWU RURAL BANK LTD-HO',
                    'BUWUULONSO RURAL BANK LTD-TAMALE',
                    'CAPE COAST',
                    'CAPE COAST BRANCH',
                    'CAPE COAST- MELCOM',
                    'CAPE COAST- MFANTSIPIM',
                    'CAPECOAST BRANCH',
                    'CAPITAL RURAL BANK LTD-ABESIM',
                    'CASTLE RD',
                    'CASTLE ROAD',
                    'CASTLE ROAD (HEAD OFFICE)',
                    'CASTLE ROAD BRANCH',
                    'CEDI HOUSE',
                    'CHAMBA',
                    'CHAPEL SQUARE',
                    'CHARIA',
                    'CHEREPONI',
                    'CHIRAA',
                    'CHORKOR',
                    'CIRCLE',
                    'CIRCLE BRANCH',
                    'CITIZEN RURAL BANK LTD-ACCRA',
                    'CLEARING UNIT',
                    'COLLINS AVENUE',
                    'COLLINS STREET',
                    'COMMUNITY 25',
                    'COMMUNITY 6',
                    'COMMUNITY1 BRANCH',
                    'CONSUMER FINANCE COMPANY (CFC) LTD',
                    'CORPORATE',
                    'CORPORATE SERVICE CENTRE',
                    'CUC MIOTSO',
                    'DA RURAL BANK LTD-KASSEH',
                    'DABALA',
                    'DABAN',
                    'DABOASE',
                    'DADIESO',
                    'DAMANG',
                    'DAMANGO',
                    'DAMBAI',
                    'DAMONGO',
                    'DANGBE RURAL BANK LTD-PRAMPRAM',
                    'DANICOM HOUSE(KOKOMLEMLE)',
                    'DANQUAH CIRCLE',
                    'DANSI RURAL BANK LTD-FOMENA',
                    'DANSOMAN',
                    'DANSOMAN EXHIBITION BRANCH',
                    'DANSOMAN ROUND-ABOUT BRANCH',
                    'DARKUMAN',
                    'DARKUMAN BRANCH',
                    'DAWA',
                    'DENKYIRAMAN RURAL BANK LTD-AYAMFURI',
                    'DENU',
                    'DERBY AVENUE',
                    'DERMA',
                    'DERMA RURAL BANK LTD-DERMA',
                    'DIRECT SALES',
                    'DODOWA',
                    'DOME',
                    'DOME BRANCH',
                    'DOME ST. JOHNS',
                    'DONKORKROM',
                    'DONTEN COMMUNITY BANK LTD-NEW TAFO',
                    'DORMAA AHENKRO',
                    'DOTOBRI RURAL BANK LTD-JACOBU',
                    'DROBO',
                    'DROBO COMMUNITY RURAL BANK LTD-DROBO',
                    'DRORWULU',
                    'DUADASO',
                    'DUAYAW NKANTA',
                    'DUAYAW NKWANTA',
                    'DUMPONG RURAL BANK LTD-OFRAMASE',
                    'DUNKWA',
                    'DUNKWA OFFIN',
                    'DUNKWA ON-OFFIN',
                    'DUNKWA-ON-OFFIN',
                    'DUNKWAW-ON-OFFIN BRANCH',
                    'DUPONGKPEHE RURAL BANK LTD-KASOA',
                    'DWEN-ANOMA RURAL BANK LTD-KWEEHWEE',
                    'DWORWULU WEST AIRPORT',
                    'DZEMENI',
                    'DZODZE',
                    'DZORWULU',
                    'DZORWULU BRANCH',
                    'DZORWULU MOTORWAY',
                    'DZWORWULU',
                    'EAST AIRPORT BRANCH',
                    'EAST CANTONMENT',
                    'EAST CANTONMENTS BRANCH',
                    'EAST LEGON',
                    'EAST LEGON 1',
                    'EAST LEGON 2',
                    'EAST LEGON BRANCH',
                    'EAST MANPRUSI COMMUNITY BABK LTD-GAMBAGA',
                    'EASTERN GOMOA ASSIN RURAL BANK LTD-GOMOA-DOMINASE',
                    'EBANKESE',
                    'ECOBANK BANTAMA GNTC',
                    'ECOBANK COMMUNITY 1',
                    'ECOBANK E PROCESS',
                    'ECOBANK EAST AIRPORT',
                    'ECOBANK EVANDY HOSTEL',
                    'ECOBANK GH TAMALE',
                    'ECOBANK GHANA',
                    'ECOBANK HIGH STREET',
                    'ECOBANK HOSPITAL ROAD',
                    'ECOBANK KANTAMANTO',
                    'ECOBANK MADINA CENTRAL',
                    'ECOBANK OKPONGLO',
                    'ECOBANK PENTAGON LEGON',
                    'ECOBANK SSNIT HOUSE',
                    'ECOBANK SUAME',
                    'EFFIDUASE',
                    'EFWIMAN RURAL BANK LTD-SEFWI-ASAW',
                    'EJISU',
                    'EJISU BRANCH',
                    'EJURA',
                    'EJURAMAN RURAL BANK LTD-KUMASI',
                    'EKUMFIMAN RURAL BANK LTD-ESSUEHYIA',
                    'EKYEDUMASI RURAL BANK LTD-SEKYEDUMASI',
                    'EKYERE RURAL BANK LTD-JAMASI',
                    'ELMINA',
                    'ELMINA - CHAPEL SQUARE',
                    'ELMINA -ESSUAKYIR',
                    'ELUBO',
                    'ELUBU',
                    'ENCHI',
                    'ENCHI BRANCH',
                    'ENCHI RURAL BANK LTD',
                    'ENKYIRAMAN RURAL BANK LTD-AYAMFURI',
                    'ENYAN DENKYIRA RURAL BANK LTD-ENYAN DENKYIRA',
                    'EPAMAN RURAL BANK LTD - TEPAMAN',
                    'EPE AREA RURAL BANK LTD-MEPE',
                    'ERMA RURAL BANK LTD-DERMA',
                    'ESIAMA',
                    'ESSAM',
                    'ESSFA RURAL BANK LTD-GARU',
                    'EST MANPRUSI COMMUNITY BANK LTD-WALEWALE',
                    'ESTERN RURAL BANK LTD-WASSA AKROPONG',
                    'ETO RURAL BANK LTD-KPEVE',
                    'EVANDY HOSTEL BRANCH',
                    'EZI SAVINGS AND LOANS LTD',
                    'FAANOFA',
                    'FAMAL',
                    'FANTEAKWA RURAL BANK LTD-KOFORIDUA',
                    'FANTSIMAN COMMUNITY RURAL BANK LTD-BIRIWA',
                    'FARRAR',
                    'FFINSO RURAL BANK LTD-KUMASI',
                    'FIAGYA RURAL BANK LTD-BUSUNYA',
                    'FIAPRE',
                    'FIASEMAN (BOGOSO AREA) RURAL BANK LTD-BOGOSO',
                    'FIRST AFRICAN SAVINGS AND LOANS',
                    'FIRST ALLIED SAVINGS & LOANS',
                    'FIRST ALLIED SAVINGS AND LOANS',
                    'FIRST GHANA BUILDING SOCIETY- ACCRA',
                    'FIRST GHANA BUILDING SOCIETY- KOFORIDUA',
                    'FIRST GHANA BUILDING SOCIETY- KUMASI AMAKOM',
                    'FIRST GHANA BUILDING SOCIETY- KUMASI SUAME',
                    'FIRST GHANA BUILDING SOCIETY- SUNYANI',
                    'FIRST GHANA BUILDING SOCIETY- TAKORADI',
                    'FIRST GHANA BUILDING SOCIETY-HEAD OFFICE',
                    'FIRST GHANA BUILDING SOCIETY-HOHOE',
                    'FIRST GHANA BUILDING SOCIETY-TAKORADI',
                    'FIRST GHANA BUILDING SOCIETY-TEMA',
                    'FIRST NATATIONAL S&L',
                    'FIRST NATIONAL SAVINGS & LOANS',
                    'FOASE',
                    'FOREIGN',
                    'FOREIGN OPERATIONS',
                    'FOREIGN OPERATIONS BRANCH',
                    'FRAM RURAL BANK LTD-TEASE',
                    'FULFOSO',
                    'FUMBISI',
                    'GA RURAL BANK LTD-AMASAMAN',
                    'GAMASHIE',
                    'GARU',
                    'GAVE RURAL BANK LTD-DABALE',
                    'GBI RURAL BANK LTD-HOHOE',
                    'GICEL',
                    'GIMPA',
                    'GIMPA BRANCH',
                    'GLOBAL ACCESS SAVINGS & LOANS CO. LTD',
                    'GNPC',
                    'GNPC TEMA',
                    'GOASO',
                    'GOLDEN TULIP KUMASI BRANCH',
                    'GOLOKWATI',
                    'GOMOA AJUMAKO RURAL BANK LTD-AFRANSI',
                    'GOMOA RURAL BANK LTD-APAM',
                    'GONA RURAL BANK LTD-KWANYAKU',
                    'GRAPHIC ROAD',
                    'GREL',
                    'GULF HOUSE',
                    'GUMANI',
                    'GUSHIEGU',
                    'GWB LAPAZ',
                    'HAATSO',
                    'HAATSOO',
                    'HAFO ANO PREMIER RURAL BANK LTD-WIOSO',
                    'HAFO COMMUNITY RURAL BANK LTD-KUKUOM',
                    'HAI RURAL BANK LTD-DODOWA',
                    'HALF ASSINI',
                    'HAMILE',
                    'HANTAMAN RURAL BANK LTD-AGONA NKWANTA',
                    'HAPER ROAD',
                    'HARBOUR AREA',
                    'HARPER ROAD',
                    'HARPER ROAD BRANCH',
                    'HE SEED SAVINGS AND LOANS',
                    'HEAD OFFICE',
                    'HEAD-OFFICE',
                    'HIGH STREET',
                    'HIGH STREET BRANCH',
                    'HIGH STREET TAKORADI',
                    'HIGH STREET-TAKORADI',
                    'HO',
                    'HO AGENCY',
                    'HO BRANCH',
                    'HO MARKET',
                    'HO POLYTECHNIC',
                    'HO POST',
                    'HO POSTBANK',
                    'HOEHOE',
                    'HOHOE',
                    'HOHOE POST',
                    'HOHOE POSTBANK',
                    'HOSPITAL ROAD BRANCH',
                    'HWEDIEM',
                    'HWIDIEM',
                    'HWIDIEM[ B/A]',
                    'IAGYA RURAL BANK LTD-BUSUNYA',
                    'IASEMAN (BOGOSO AREA) RURAL BANK LTD-BOGOSO',
                    'IA-TORYA RURAL BANK LTD-BONSU-NKWANTA',
                    'ICROFIN RURAL BANK LIMITED',
                    'INDEPENDENCE AVENUE',
                    'INDUSTRIAL AREA',
                    'INTAMPO RURAL BANK LTD-KINTAMPO',
                    'INTERNATIONAL BANKING',
                    'IPS',
                    'IPS BRANCH',
                    'IPS LEGON',
                    'IRST GHANA BUILDING SOCIETY- ACCRA',
                    'IRST GHANA BUILDING SOCIETY- KOFORIDUA',
                    'IRST GHANA BUILDING SOCIETY- KUMASI AMAKOM',
                    'IRST GHANA BUILDING SOCIETY- KUMASI SUAME',
                    'IRST GHANA BUILDING SOCIETY- SUNYANI',
                    'IRST GHANA BUILDING SOCIETY- TAKORADI',
                    'IRST GHANA BUILDING SOCIETY-HEAD OFFICE',
                    'IRST GHANA BUILDING SOCIETY-HOHOE',
                    'IRST GHANA BUILDING SOCIETY-TAKORADI',
                    'IRST GHANA BUILDING SOCIETY-TEMA',
                    'ISSALA RURAL BANK LTD-TUMA',
                    'ITIZEN RURAL BANK LTD-ACCRA',
                    'IZAA RURAL BANK LTD-TAMALE',
                    'JACOBU',
                    'JANYAGU',
                    'JAPEKROM',
                    'JAPEKROM/DROBO',
                    'JASIKAN',
                    'JEMA',
                    'JINIJIN',
                    'JIRAPA',
                    'JISONAYILI',
                    'JOMORO RURAL BANK LTD-TIKOBO NO. 1',
                    'JUABEN RURAL BANK LTD-JUABEN',
                    'JUABESO',
                    'JUAPONG',
                    'JUASO',
                    'JUBILEE HOUSE',
                    'JUBILEE HOUSE COCOBOD BUILDING',
                    'JUKWA',
                    'JUNCTION MALL',
                    'JUNCTION MALL BRANCH',
                    'JURAMAN RURAL BANK LTD-KUMASI',
                    'KAASE',
                    'KAASEMAN RURAL BANK LTD-KAASE',
                    'KADE',
                    'KADJEBI',
                    'KAJEBI',
                    'KAKUM RURAL BANK LTD-ELMINA',
                    'KALADAN',
                    'KANASHIE',
                    'KANESHIE',
                    'KANESHIE BRANCH',
                    'KANESHIE INDUSTRIAL AREA',
                    'KANESHIE MAIN',
                    'KANESHIE MARKET',
                    'KANESHIE POST OFFICE',
                    'KANESHIE POST OFFICE BRANCH',
                    'KANESSHIE',
                    'KANTAMANTO',
                    'KANTAMANTO AGENCY',
                    'KANTAMANTO BRANCH',
                    'KARAGA',
                    'KASAPIN',
                    'KASOA',
                    'KASOA BRANCH',
                    'KASOA MAIN',
                    'KASOA MAIN BRANCH',
                    'KASOA MARKET',
                    'KASOA MILLENIUM CITY',
                    'KASOA NEW MARKET',
                    'KATAKYIMAN RURAL BANK LTD-KOMENDA',
                    'KEJETIA',
                    'KEJETIA BRANCH',
                    'KEJETIA- KUMASI',
                    'KENYASE',
                    'KENYASI AGENCY',
                    'KETA',
                    'KETE KRACHI',
                    'KIBI',
                    'KIM BOSOME RURAL BANK LTD-AKIM SWEDRU',
                    'KINTAMPO',
                    'KINTAMPO RURAL BANK LTD-KINTAMPO',
                    'KISSEIMAN BRANCH',
                    'KISSI',
                    'KISSIEMAN',
                    'KNUST',
                    'KNUST BRANCH',
                    'KNUST CAMPUS',
                    'KNUST KUMASI',
                    'KNUTSFORD',
                    'KNUTSFORD AVENUE',
                    'KOFORIDUA',
                    'KOFORIDUA BRANCH',
                    'KOFORIDUA KAMA CENTRE',
                    'KOJO THOMPSON ROAD',
                    'KOKOMLEMLE BRANCH',
                    'KOMENDA',
                    'KOMFO ANOKYE RURAL BANK LTD-WIAMOASE',
                    'KOMLEMLE',
                    'KONGO',
                    'KONONGO',
                    'KORANMAN RURAL BANK LTD-SEIKWA',
                    'KORANZA KWABRE RURAL BANK LTD-AKUMA',
                    'KORKORDZOR',
                    'KORLE BU',
                    'KORLE DUDOR',
                    'KORLE DUDOR BRANCH',
                    'KORLE-BU',
                    'KORLE-BU BRANCH',
                    'KOTI RURAL BANK LTD-ASSIN AKROPONG',
                    'KOTOBABI',
                    'KPADIE',
                    'KPANDAI',
                    'KPANDO',
                    'KPANDU',
                    'KPASSA RURAL BANK LTD-HOHOE',
                    'KPEDZE',
                    'KPETOE',
                    'KPEVE',
                    'KPONE',
                    'KPONG',
                    'KRACHE NKWANTA',
                    'KRANKWANTA RURAL BANK LTD-SUNYANI',
                    'KROFOM',
                    'KROFROM',
                    'KROFUOM AREA RURAL BANK LTD-AKROFUOM',
                    'KRONUM BRANCH',
                    'KUAPEM RURAL BANK LTD-MAMFE',
                    'KUMASI',
                    'KUMASI (ALABAR)',
                    'KUMASI ADUM',
                    'KUMASI -ADUM',
                    'KUMASI AMAKOM',
                    'KUMASI ASAFO',
                    'KUMASI ASAFO BRANCH',
                    'KUMASI ASOKWA',
                    'KUMASI BRANCH',
                    'KUMASI CENT.',
                    'KUMASI CENTRAL',
                    'KUMASI CENTRAL MARKET',
                    'KUMASI HARPER ROAD',
                    'KUMASI MAIN',
                    'KUMASI PREMPEH 11 STREET',
                    'KUMASI PREMPEH STREET',
                    'KUMASI SUAME',
                    'KUMASI TANNOSO',
                    'KUMASI-LAKE  ROAD',
                    'KUMAWUMAN RURAL BANK LTD-KUMAWU',
                    'KUMFIMAN RURAL BANK LTD-ESSUEHYIA',
                    'KWABENYA',
                    'KWABENYA BRANCH',
                    'KWADASO',
                    'KWAEBIBIREM RURAL BANK LTD-ASUOM',
                    'KWAHU PRASO RURAL BANK LTD-KWAHU PRASO',
                    'KWAHU RURAL BANK LTD-KWAHU PEPEASE',
                    'KWAHUTAFO',
                    'KWAMANMAN RURAL BANK LTD-KWAMANG',
                    'KWAME DANSO BRANCH',
                    'KWAME NKRUMAH AVE',
                    'KWAME NKRUMAH CIRCLE',
                    'KWAPONG',
                    'KWASHIEMAN',
                    'KWASHIEMAN BRANCH',
                    'KYEKYEWERE',
                    'KYEM MANSA RURAL BANK LTD-AYIREBI',
                    'KYEPIM RURAL BANK LTD-GOMOA-DAW',
                    'LA',
                    'LA COMMUNITY BANK LTD-LA',
                    'LABONE',
                    'LABONE BRANCH',
                    'LAPAZ',
                    'LAPAZ BRANCH',
                    'LASHIBI',
                    'LASHIBI BRANCH',
                    'LAW COURT COMPLEX',
                    'LAW-COURT',
                    'LAWRA',
                    'LAWRA RURAL BANK LTD-WA',
                    'LEGON',
                    'LEGON MAIN',
                    'LIBERATION ROAD TAKORADI',
                    'LIBERATION ROAD TOKORADI',
                    'LIBERATION ROAD-TAKORADI',
                    'LIBERIA ROAD',
                    'LIBERTY HSE',
                    'LOTTERIES',
                    'LOWER PRA RURAL BANK LTD-SHAMA',
                    'MAAKRO BRANCH',
                    'MAAME KROBO',
                    'MAAMOBI',
                    'MACCARTHY HILL',
                    'MADINA',
                    'MADINA BRANCH',
                    'MADINA CENTRAL BRANCH',
                    'MADINA MAIN BRANCH',
                    'MADINA NEW ROAD BRANCH',
                    'MAKANGO',
                    'MAKOLA',
                    'MAKOLA BRANCH',
                    'MAKOLA SQUARE',
                    'MALLAM',
                    'MALLAM INTERCHANGE',
                    'MALLAM JUNCTION BRANCH',
                    'MAMFE AKUAPIM',
                    'MAMFE BRANCH',
                    'MAMOBI',
                    'MAMOBI BRANCH',
                    'MAMPONG',
                    'MAMPONG AKWAPIM',
                    'MAMPONG ASHANTI',
                    'MAMPROBI',
                    'MAMPROBI POST',
                    'MAMPROBI POSTBANK',
                    'MANANO RURAL BANK LTD-NYINAHIM',
                    'MANET TOWER 3 BRANCH',
                    'MANKESSIM',
                    'MANKESSIM OFFICE COMPLEX',
                    'MANKRASO',
                    'MANSO NKWANTA',
                    'MANSOMAN RURAL BANK LTD-MANSO-AMENFI',
                    'MANYA KROBO RURAL BANK LTD-ODUMASE-KROBO',
                    'MARKET CIRCLE BRANCH',
                    'MARKET CIRCLE TAKORADI',
                    'MASIE WEST RURAL BANK LTD-ANTOAKROM',
                    'MATAHEKO',
                    'MATIN AND KASEI COMMUNITY BANK LTD-KASEI',
                    'MAX MART',
                    'MCCARTHY HILL',
                    'MELCOM',
                    'MENFIMAN RURAL BANK LTD-WASSA AKROPONG',
                    'MEPE AREA RURAL BANK LTD-MEPE',
                    'MERIDIAN BRANCH',
                    'MFANTSIMAN COMMUNITY RURAL BANK LTD-BIRIWA',
                    'MFANTSIPIM',
                    'MICHEL CAMP',
                    'MICROFIN RURAL BANK LIMITED',
                    'MIDLAND SAVING AND LOANS',
                    'MIDLAND SAVINGS & LOANS LTD',
                    'MILE 7/TANTRA',
                    'MIM',
                    'MINISTRIES',
                    'MIOTSO',
                    'MOREE',
                    'MOTOR WAY ROUNDABOUT',
                    'MOTORWAY EXT',
                    'MOTORWAY EXTENSION',
                    'MOTORWAY ROUNDABOUT',
                    'MPATASIE',
                    'MPOHOR',
                    'MPONUA RURAL BANK LTD-AMUANA PRASO',
                    'MPRAESO',
                    'MULTI CREDIT SAVINGS AND LOANS - KUMASI',
                    'MULTICREDIT SAVINGS AND LOANS CO.',
                    'MUMFORD',
                    'MUMUADA RURAL BANK LTD-OSINO',
                    'NAARA RURAL BANK LTD-PAGA',
                    'NAFANA RURAL BANK LTD-SAMPA',
                    'NAGODE',
                    'NANDOM',
                    'NANDOM RURAL BANK LTD-NANDOM',
                    'NAVORONGO',
                    'NAVRONGO',
                    'NBFI-ADVANS GH',
                    'NCHIRAA',
                    'NCR',
                    'NEW ABEKA',
                    'NEW ABIREM',
                    'NEW ABIREM BRANCH',
                    'NEW ABRIEM',
                    'NEW ADUBIASE',
                    'NEW AMAKOM',
                    'NEW DORMAA KOTOKROM',
                    'NEW EDUBIASE',
                    'NEW NINGO',
                    'NEW OFFINSO',
                    'NEW SUAME MAGAZINE',
                    'NEW TAFO',
                    'NEW TOWN',
                    'NEWTOWN',
                    'NEWTOWN ACCRA',
                    'NHYIAESO BRANCH',
                    'NHYIEASO',
                    'NHYIESO',
                    'NIA',
                    'NIA BRANCH',
                    'NIMA',
                    'NIMA BRANCH',
                    'NIMA MAIN BRANCH',
                    'NINGO',
                    'NION RURAL BANK LTD-SENYA BREKU',
                    'NITY RURAL BANK LTD-ZIOPE',
                    'NKAWIE',
                    'NKAWKAW',
                    'NKOBRA WEST (ESIAMA) RURAL BANK LTD-ESIAMA',
                    'NKORANMAN RURAL BANK LTD-SEIKWA',
                    'NKORANSAH',
                    'NKORANZA',
                    'NKORANZA KWABRE RURAL BANK LTD-AKUMA',
                    'NKRANKWANTA',
                    'NKRANKWANTA RURAL BANK LTD-SUNYANI',
                    'NKWAKAW POST',
                    'NKWANTA',
                    'NLO RURAL BANK LTD-ANLOGA',
                    'NOBLE DREAM MICROFINANCE LTD',
                    'NORTH INDUSTRIAL',
                    'NORTH INDUSTRIAL AREA',
                    'NORTH INDUSTRIAL AREA BRANCH',
                    'NORTH KANESHIE',
                    'NORTH RIDGE',
                    'NORTH TONGU RURAL BANK LTD-ADIDOME',
                    'NORTH VOLTA RURAL BANK LTD-GUAMAN',
                    'NSAWAM',
                    'NSOATREMAN RURAL BANK LTD-NSOATRE',
                    'NSUATRE',
                    'NSUFA ASHANTI',
                    'NSUTAMAN RURAL BANK LTD-NSUTA',
                    'NTOROSO',
                    'NUM RURAL BANK LTD-ANUM',
                    'NUNGUA',
                    'NWABIAGYA RURAL BANK LTD-BAREKESE',
                    'NYAKROM RURAL BANK LTD-NYAKROM',
                    'NYAN DENKYIRA RURAL BANK LTD-ENYAN DENKYIRA',
                    'NYANKPALA',
                    'NYANKUMASE AHENKRO RURAL BANK LTD-FANTI NYANKUMASE',
                    'NYIAESO',
                    'NYIAESO AHODWO',
                    'NZEMA MANLE RURAL BANK LTD-AWIEBO',
                    'OBUASI',
                    'ODA',
                    'ODA NKWANTA',
                    'ODORKOR',
                    'ODOTOBRI RURAL BANK LTD-JACOBU',
                    'ODUPONGKPEHE RURAL BANK LTD-KASOA',
                    'ODWEN-ANOMA RURAL BANK LTD-KWEEHWEE',
                    'OENDE RURAL BANK LTD-ZIBILLA',
                    'OFFINSO',
                    'OFFINSO RURAL BANK LTD-KUMASI',
                    'OFFSHORE BANKING',
                    'OFFSHORE BANKING UNIT - GH',
                    'OKAISHIE',
                    'OKAISHIE BRANCH',
                    'OKOFO BRANCH',
                    'OKOMFO ANOKYE RURAL BANK LTD-WIAMOASE',
                    'OKPONGLO',
                    'OKPONGLO BRANCH',
                    'OLA',
                    'OLD NINGO',
                    'OLD SUAME MAGAZINE',
                    'OLD TAFO',
                    'OMAA AREA RURAL BANK LTD-BOMAA',
                    'OMOA AJUMAKO RURAL BANK LTD-AFRANSI',
                    'OMOA RURAL BANK LTD-APAM',
                    'OMORO RURAL BANK LTD-TIKOBO NO. 1',
                    'OMOSADU RURAL BANK LTD',
                    'ONGO RURAL BANK LTD-BOLGATANGA',
                    'ONZALI RURAL BANK LTD-KUMBUNGU',
                    'ONZELE RURAL BANK LTD-JIRAPA',
                    'OPEIBEA HOUSE',
                    'OPERA SQUARE',
                    'OPERA SQUARE BRANCH',
                    'OPPORTUNITY INT. SAVINGS AND LOAN',
                    'OPPORTUNITY INT. SAVINGS AND LOANS',
                    'OPPORTUNITY INTERNATIONAL',
                    'OREIGN',
                    'ORIMANGA RURAL BANK LTD-SAVELEGU',
                    'ORTH TONGU RURAL BANK LTD-ADIDOME',
                    'ORTH VOLTA RURAL BANK LTD-GUAMAN',
                    'OSEI KWADWO KROM',
                    'OSOME FREHO RURAL BANK LTD-KUMASI',
                    'OSOMTWI RURAL BANK LTD-KUNTANASE',
                    'OSU',
                    'OSU  OXFORD BRANCH',
                    'OSU - OXFORD STREET',
                    'OSU - WATSON STREET',
                    'OSU OXFORD ST.',
                    'OSU OXFORD STREET',
                    'OSU WATSON BRANCH',
                    'OTUASEKAN RURAL BANK LTD-KOFIASE',
                    'OUTH AKIM RURAL BANK LTD-NANKESE',
                    'OUTH BIRIM RURAL BANK LTD-ACHIASE',
                    'OWER PRA RURAL BANK LTD-SHAMA',
                    'OXFORD STREET BRANCH',
                    'OYIBI AREA RURAL BANK LIMITED',
                    'PACIFIC SAVINGS AND LOANS LTD',
                    'PAGA',
                    'PALM WINE JUNCTION',
                    'PALMWINE JUNCTION',
                    'PARLIAMENT HOUSE',
                    'PASSA RURAL BANK LTD-HOHOE',
                    'PATASE',
                    'PATRICE LUMUMBA ROAD',
                    'PEKI',
                    'PENTAGON LEGON BRANCH',
                    'PEX BANK - BOLGA CENTRE-BOLGATANGA',
                    'PEX BANK - CAPE COASTCAPE COAST',
                    'PEX BANK - TAMALE CENTRE-TAMALE',
                    'PEX BANK -HEAD OFFICE-SOUTH RIDGE ACCRA',
                    'PEX BANK -HOHOE CENTRE-HOHOE',
                    'PEX BANK KOFORIDUA CENTRE-KOFORIDUA',
                    'PEX BANK -SUNYANI CENTRE-SUNYANI',
                    'PEX BANK -WA CENTRE-WA',
                    'PEX BANKACCRA CENTRE-ACCRA',
                    'PEX BANK-HO CENTRE-HO',
                    'PEX BANK-KUMASIKUMASI',
                    'PEX BANK-TAKORADI CENTRE-TAKORADI',
                    'PEX LINK CENTRE-CEDI HOUSE',
                    'PIG FARM BRANCH',
                    'POKUASE',
                    'POKUASE BRANCH',
                    'PONUA RURAL BANK LTD-AMUANA PRASO',
                    'POST OFFICE SQUARE',
                    'POTSIN',
                    'PPER AMENFI RURAL BANK LTD-ANKWAWSO',
                    'PPER MANYA KRO RURAL BANK LTD-ASESEWA',
                    'PRAMPRAM',
                    'PRANG',
                    'PREMIER TOWERS',
                    'PREMPEH II STREET',
                    'PRESTEA',
                    'PRIVATE BANKING',
                    'PROCREDIT SAVINGS & LOANS',
                    'PUSIGA',
                    'RAKWA BRAMAN RURAL BANK LTD-BRAKWA',
                    'REINSURANCE HOUSE',
                    'REINSURANCE HOUSE BRANCH',
                    'REPUBLIC HOUSE',
                    'RIDGE',
                    'RIDGE BRANCH',
                    'RIDGE TOWERS',
                    'RING ROAD',
                    'RING ROAD BRANCH',
                    'RING ROAD CENTRAL',
                    'RING ROAD CENTRAL BRANCH',
                    'RING ROAD WEST',
                    'ROBO COMMUNITY RURAL BANK LTD-DROBO',
                    'ROMAN HILL',
                    'ROMAN HILL BRANCH',
                    'ROMAN HILL MARKET BRANCH',
                    'RRC',
                    'RRC REMITTANCE CENTRE',
                    'RUSSIA ROAD BRANCH',
                    'SABOBA',
                    'SAFE BOND',
                    'SAFE BOND JUBILEE',
                    'SAFE BOUND JUBILEE',
                    'SAFEBOND',
                    'SAKAMAN',
                    'SAKUMONO',
                    'SAKUMONO BRANCH',
                    'SALAGA',
                    'SALTPOND',
                    'SAMPA',
                    'SAMREBOI',
                    'SANDEMA',
                    'SANKORE',
                    'SANTA MARIA',
                    'SANTASI',
                    'SANTE AKYEM RURAL BANK LTD-JUANSA',
                    'SAVELUGU',
                    'SAWINSO RURAL BANK LTD-SEFWI-ASAWINSO',
                    'SAWLA',
                    'SEFWI BEKWAI',
                    'SEFWI BRANCH',
                    'SEFWI ESSAM',
                    'SEFWI WIAWO',
                    'SEFWI WIAWSO',
                    'SEFWIMAN RURAL BANK LTD-SEFWI-ASAW',
                    'SEFWI-WIASO',
                    'SEGE',
                    'SEKONDI',
                    'SEKYEDUMASI RURAL BANK LTD-SEKYEDUMASI',
                    'SEKYERE RURAL BANK LTD-JAMASI',
                    'SERVICE BRANCH',
                    'SHAI RURAL BANK LTD-DODOWA',
                    'SHIPPERS HOUSE BRANCH',
                    'SILVER STAR',
                    'SINAPI ABA SAVINGS & LOAN',
                    'SINAPI ABA SAVINGS & LOANS',
                    'SISSALA RURAL BANK LTD-TUMA',
                    'SME',
                    'SME CENTRE',
                    'SOATREMAN RURAL BANK LTD-NSOATRE',
                    'SOGAKOPE',
                    'SOKOBAN',
                    'SOKOBAN BRANCH',
                    'SOKORE RURAL BANK LTD-ASOKRE',
                    'SOMANYA',
                    'SONZELE RURAL BANK LTD-JIRAPA',
                    'SOUTH AKIM RURAL BANK LTD-NANKESE',
                    'SOUTH BIRIM RURAL BANK LTD-ACHIASE',
                    'SOUTH INDUSTRIAL AREA',
                    'SOUTH INDUSTRIAL AREA(SIA)',
                    'SOUTH INDUSTRIAL BRANCH',
                    'SOUTH LEGON BRANCH',
                    'SOWUTUOM',
                    'SPINTEX',
                    'SPINTEX BRANCH',
                    'SPINTEX MAIN',
                    'SPINTEX PRESTIGE',
                    'SPINTEX ROAD',
                    'SPINTEX ROAD BRANCH',
                    'SSINMAN RURAL BANK LTD-ASSIN MANSO',
                    'SSNIT HOUSE BRANCH',
                    'ST PETERS CO-OPERATIVE CREDIT UNION',
                    'STADIUM',
                    'STADIUM AMAKOM',
                    'STADIUM POST',
                    'STEWART AVENUE KUMASI',
                    'SUAME',
                    'SUAME BRANCH',
                    'SUAME MAAKRO',
                    'SUBONTEN RURAL BANK LTD-WORAWORA',
                    'SUHUM',
                    'SUHUM RURAL BANK',
                    'SUMA RURAL BANK LTD-SUMA AHENKRO',
                    'SUNYANI',
                    'SUNYANI CENTRAL',
                    'SUNYANI MAIN BRANCH',
                    'SUNYANI NIMPONG',
                    'SUNYANI ODUMASE',
                    'SUNYANI POST OFFICE BRANCH',
                    'SUNYANI POSTBANK',
                    'SUOGYAMAN RURAL BANK LTD-ACCRA',
                    'SUOPRA RURAL BANK LTD-AFOSU',
                    'SUTAMAN RURAL BANK LTD-NSUTA',
                    'SUTIFI RURAL BANK LTD-ACERENSUA',
                    'SWANMILL',
                    'SWEDRU',
                    'SWEDRU BRANCH',
                    'TAFO',
                    'TAFO BRANCH',
                    'TAIFA',
                    'TAKORADI',
                    'TAKORADI - KOKOMPE',
                    'TAKORADI BRANCH',
                    'TAKORADI EFFIEKUMA',
                    'TAKORADI HABOUR BRANCH',
                    'TAKORADI HARBOUR',
                    'TAKORADI HARBOUR ROAD',
                    'TAKORADI KOKOMPE',
                    'TAKORADI MAIN',
                    'TAKORADI MARKET CIRCLE',
                    'TAKORADI MKT CIRCLE',
                    'TAKORADI POST',
                    'TAKORADI POSTBANK',
                    'TAMALE',
                    'TAMALE ABOABO',
                    'TAMALE ABOABO BRANCH',
                    'TAMALE BRANCH',
                    'TAMALE CENTRAL',
                    'TAMALE HOSP ROAD',
                    'TAMALE HOSPITAL ROAD',
                    'TAMALE HOSPITAL ROAD BRANCH',
                    'TAMALE LAMESHEGU',
                    'TAMALE MAIN BRANCH',
                    'TAMALE MARKET',
                    'TANOKROM',
                    'TANOSO',
                    'TANTRA',
                    'TARKORADI MARKET',
                    'TARKWA',
                    'TARKWA BRANCH',
                    'TARKWA MINES',
                    'TEASE',
                    'TECH JUNCTION',
                    'TECHIMAN',
                    'TECHIMAN BRANCH',
                    'TECHIMAN MARKET',
                    'TECHIMAN MARKET BRANCH',
                    'TECHIMAN NORTH BRANCH',
                    'TECHIMAN TIA BRANCH',
                    'TECHIMAN VALLEY VIEW BRANCH',
                    'TECHIMAN WENCHI ROAD BRANCH',
                    'TEMA',
                    'TEMA BRANCH',
                    'TEMA COMM 1',
                    'TEMA COMM 2',
                    'TEMA COMM 4',
                    'TEMA COMM. 25',
                    'TEMA COMM. 6',
                    'TEMA COMMUNITY 1',
                    'TEMA COMMUNITY 1 BRANCH',
                    'TEMA COMMUNITY 2 BRANCH',
                    'TEMA COMMUNITY 25',
                    'TEMA COMMUNITY 25 BRANCH',
                    'TEMA COMMUNITY 6',
                    'TEMA COMMUNITY 9',
                    'TEMA COMMUNITY ONE',
                    'TEMA COMMUNITY TWO',
                    'TEMA DRYDOCK',
                    'TEMA EAST',
                    'TEMA FISHING HABOUR',
                    'TEMA FISHING HARBOUR',
                    'TEMA HABOUR',
                    'TEMA HARBOUR',
                    'TEMA INDUSTRIAL AREA',
                    'TEMA LONG ROOM',
                    'TEMA MAIN',
                    'TEMA MAIN BRANCH',
                    'TEMA MARKET',
                    'TEMA METRO BRANCH',
                    'TEMA OIL REFINERY',
                    'TEMA SAFEBOND',
                    'TEMA SHOPPING MAIL',
                    'TEMA SHOPPING MALL',
                    'TEMPANE BRANCH',
                    'TEPA',
                    'TEPAMAN RURAL BANK LTD - TEPAMAN',
                    'TESANO',
                    'TESANO BRANCH',
                    'TESHIE',
                    'TESHIE BRANCH',
                    'TESHIE NUNGUA',
                    'TETTEH QUARSHIE',
                    'THE SEED SAVINGS AND LOANS',
                    'TIA BRANCH',
                    'TIWA RURAL BANK LTD-KWABENG',
                    'TIZAA RURAL BANK LTD-TAMALE',
                    'TOENDE RURAL BANK LTD-ZIBILLA',
                    'TOLON',
                    'TONGU',
                    'TOR',
                    'TRADE FAIR',
                    'TRADE FAIR BRANCH',
                    'TREASURY',
                    'TREDE',
                    'TRUST TOWERS',
                    'TRUST TOWERS BRANCH',
                    'TSITO',
                    'TSOPOLI',
                    'TSUI BLEOO',
                    'TUASEKAN RURAL BANK LTD-KOFIASE',
                    'TUDU',
                    'TUDU BRANCH',
                    'TUMJU',
                    'TUMU',
                    'TUOBODOM',
                    'TUTU BRANCH',
                    'TWEABAN RURAL BANK LTD-SUNYANI',
                    'TWIFO PRASO',
                    'TWIFO RURAL BANK LTD-TWIFO AGONA',
                    'TWIMA KWANWOMA RURAL BANK LTD-PAKYI NO. 2',
                    'TWIMA MPONUA RURAL BANK LTD-TOASE',
                    'TWIMA RURAL BANK LTD-FOASE',
                    'UABEN RURAL BANK LTD-JUABEN',
                    'UCC',
                    'UEW BRANCH',
                    'UHUM RURAL BANK',
                    'UILSA COMMUNITY BANK LTD-SANDEMA',
                    'UMA RURAL BANK LTD-SUMA AHENKRO',
                    'UMAWUMAN RURAL BANK LTD-KUMAWU',
                    'UMPONG RURAL BANK LTD-OFRAMASE',
                    'UMUADA RURAL BANK LTD-OSINO',
                    'UNDP',
                    'UNION RURAL BANK LTD-SENYA BREKU',
                    'UNION SAVINGS & LOANS',
                    'UNION SAVINGS AND LOANS',
                    'UNION SAVINGS AND LOANS LIMITED',
                    'UNITY RURAL BANK LTD-ZIOPE',
                    'UNIV. OF CAPE COAST BRANCH',
                    'UNIVERSITY OF CAPE COAST',
                    'UNIVERSITY OF CAPE COAST BRANCH',
                    'UNIVERSITY OF GHANA BRANCH',
                    'UPPER AMENFI RURAL BANK LTD-ANKWAWSO',
                    'UPPER MANYA KRO RURAL BANK LTD-ASESEWA',
                    'UTAWU RURAL BANK LTD-HO',
                    'UTRAK SAVINGS AND LOANS',
                    'UWUULONSO RURAL BANK LTD-TAMALE',
                    'VAKPO',
                    'VALLEY VIEW',
                    'VALLEY VIEW UNIVERSITY- TECHIMAN',
                    'VENOR RURAL BANK LTD-AKATSI',
                    'WA',
                    'WA BRANCH',
                    'WABIAGYA RURAL BANK LTD-BAREKESE',
                    'WAEBIBIREM RURAL BANK LTD-ASUOM',
                    'WAHU PRASO RURAL BANK LTD-KWAHU PRASO',
                    'WAHU RURAL BANK LTD-KWAHU PEPEASE',
                    'WALEWALE',
                    'WAMANMAN RURAL BANK LTD-KWAMANG',
                    'WAMFIE',
                    'WAMFIE RURAL BANK LTD-WAMFIE',
                    'WASSA MANSO',
                    'WECHIAU',
                    'WEIJA',
                    'WEIJA BRANCH',
                    'WENCHI',
                    'WENCHI RURAL BANK LTD',
                    'WEST HILLS MALL',
                    'WEST LANDS',
                    'WEST MANPRUSI COMMUNITY BANK LTD-WALEWALE',
                    'WESTERN HILL',
                    'WESTERN RURAL BANK LTD-WASSA AKROPONG',
                    'WESTLANDS',
                    'WETO RURAL BANK LTD-KPEVE',
                    'WIDANA',
                    'WIFO RURAL BANK LTD-TWIFO AGONA',
                    'WINNEBA',
                    'WINNEBA BRANCH',
                    'WINNEBA RD',
                    'WINNEBA ROAD',
                    'WISCONSIN',
                    'WISCONSIN BRANCH',
                    'WOMANS WORLD BANKING',
                    'WOMENS WORLD BANK GHANA SAVINGS AND LOANS',
                    'WOMENS WORLD BANKING',
                    'WULENSI',
                    'WURIYENGA',
                    'WUTU EMASA RURAL BANK LTD-AWUMASA',
                    'YAA ASANTEWAA RURAL BANK LTD',
                    'YAKROM RURAL BANK LTD-NYAKROM',
                    'YANKUMASE AHENKRO RURAL BANK LTD-FANTI NYANKUMASE',
                    'YAPRA RURAL BANK LTD-PRANG',
                    'YEJI',
                    'YENDI',
                    'YESU NKWA',
                    'YIBI AREA RURAL BANK LIMITED',
                    'YUNYOO',
                    'ZABZUGU RURAL BANK LTD',
                    'ZEBILLA',
                    'ZEMA MANLE RURAL BANK LTD-AWIEBO',
                    'ZENITH BANK (GH) LTD-SUNYANI',
                    'ZENU',
                    'ZENU BRANCH',
                    'ZONGO JUNCTION',
                  ],
                  publishChange: true,
                  readonly: false,
                  tooltip: 'Account Branch Name',
                  type: 'field',
                  uid: '503a71583f1182e2',
                },
                {
                  id: 'account-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Account Number',
                  uid: '495c881bcf57de23',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: true,
          label: 'System List',
          uid: 'd1ac4ed6d65c6c5b',
          content: [],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '92758bfed52da6b5',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '5035ae517e7b1524',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8b31df748a9b25f8',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '261486a530f75645',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '8588b418b3adff34',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Payment Details',
    uri: 'ffp-unlimited/payment-details',
    template: 'ffp-unlimited-payment-details-screen',
    class: 'ffp-unlimited-payment-details',
  },
  'stop-order-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: '33fe6203a26b19c6',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'stop-order-details',
          type: 'list',
          readonly: false,
          label: 'Stop Order Details',
          uid: '6bd977fde75cac31',
          content: [
            {
              id: 'employer-organisation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Employer Organisation',
              uid: '583c1096a7303045',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Abokobi Area Rural Bankstaff',
                'Accra Metro Assembly',
                'Adansi Rural Bank',
                'Advans Ghana',
                'Advent Press',
                'African Regent Hotel',
                'Agave Rural Bank',
                'Agric Development Bank',
                'Ahafo Ano Premier Rural Bank',
                'Ahantaman Rural Bankâ ',
                'Akaa Security Services',
                'Akatakyiman Rural Bank',
                'Akim Bosome Rural Bank',
                'Akwapem Rural Bank',
                'Aluworks',
                'Ama Dansowaa Clinic',
                'Amanano Rural Bank',
                'Amansie West Rural Bank',
                'Amatin & Kasei Comm Bank',
                'Antrak Ghana Ltd',
                'Anum Rural Bank Ltd',
                'Apex Bank',
                'Ashante Akyem Rural Bank',
                'Asokore Rural Bank Ltd - Kumasi',
                'Asutifi Rural Bank Limited',
                'Atlantic Int. Holding Co',
                'Atwima Kwanwoma Rural Bank',
                'Atwima Rural Bank',
                'Auto Parts Limited',
                'Awutu Emasa Rural Bank',
                'Ayrton Drug Manufacturing Limited',
                'Bank of Ghana',
                'Baptist Medical Centre',
                'Barge Operators and Allied Services Ltd',
                'Benso Oil Palm Plantationâ ',
                'Bessfa Rural Bank',
                'Bible Society of Ghana',
                'Bonzali Rural Bank',
                'Bosomtwe Rb',
                'Bureau of National Communication',
                'Bureau of National Investigations',
                'Busua Beach Resortâ ',
                'Capital Rural Bank',
                'Carmeuse Lime Productâ ',
                'Cejose Wisdom Int. School',
                'Chemico Limited',
                'Chirano Gold Mines',
                'Christ Ebenezer',
                'Church Hill of God',
                'Cocoa Marketiing Company Ltd',
                'Cocoa Processing Comp',
                'Controller',
                'Cssvd Control Unit',
                'Dangme Rural Bank Ltd - Staff',
                'De Youngsters International School Ltd',
                'Deoke Foundation Schools',
                'Derma Area Rural Bank',
                'Dizengoff Ghana Ltd',
                'Dynamic Insurance Brokers',
                'Ebennezer Osei Brenya Enterprise',
                'Ejuraman Rural Bank',
                'Electricity Company of Ghana',
                'Elmina Beach Resort',
                'Englebert School',
                'Enterprise Funeral Services',
                'Enterprise Group Ltd',
                'Enterprise Insurance',
                'Enterprise Life Reps',
                'Enterprise Life Staff',
                'Enterprise Properties Ltd',
                'Enterprise Trustees Limited',
                'Epa',
                'Eskay Therapeutics Ltd',
                'Fiagya Rural Bank Ltd',
                'Fiaseman Rural Bankâ ',
                'Fiesta Royale Hotel',
                'First National Savings and Loansâ ',
                'Forces Pay Office',
                'Ga East Municipal Assembly',
                'Ga Rural Bank',
                'Ga Rural Customers',
                'Gafco',
                'Garden City - Multi Credit',
                'Gcb Bank Ltd Staff',
                'Ghana Airport Company Ltd.',
                'Ghana Bauxite Company Limted',
                'Ghana Cement',
                'Ghana Chamber of Mines',
                'Ghana Civil Aviation Authority',
                'Ghana Cocoa Board',
                'Ghana Grid Company',
                'Ghana Heavy Equipment Limited',
                'Ghana Link Network Services Ltd',
                'Ghana Maritime Authority',
                'Ghana Medical School',
                'Ghana Oil Co. Ltd',
                'Ghana Police Service',
                'Ghana Post',
                'Ghana Railway Co. Ltd',
                'Ghana Re Company Limited',
                'Ghana Revenue Authority',
                'Ghana Standard Board',
                'Ghana Union Conference of s.d.a.',
                'Gihoc Distilleries Company Limited',
                'Gimpa',
                'Globeserve Ministry Intâ ',
                'Golden Exotics Ltd',
                'Goldengate Services Ltd',
                'Gpha - Takoradi',
                'Gpha - Tema',
                'Grace Baptist School - Kumasi',
                'Guiness Ghana Ltd',
                'Gwcl - Accra East',
                'Gwcl - Accra West',
                'Gwcl - Ashanti North',
                'Gwcl - Ashanti Production',
                'Gwcl - Ashanti South',
                'Gwcl - Cape Coast',
                'Gwcl - Greater Accra Region Production',
                'Gwcl - Head Office',
                'Gwcl - Koforidua',
                'Gwcl - Sunyani',
                'Gwcl - Takoradi',
                'Gwcl - Tema',
                'Hobats Clinic',
                'Holiday Inn Accra',
                'Home Finance Company Bank Ltd',
                'House of Compassion Ministries',
                'Japan Motors Trading Company',
                'Juaben Rural Bank',
                'Jubilee Shipping Services Gh Ltd',
                'k.n.u.s.t',
                'Kaaseman Rural Bank Ltd',
                'Kessben',
                'Kingsgroup of Companies',
                'Kintampo Health Research Centre',
                'Kintampo Rural Bank Limited',
                'Koforidua Stop Order Walk In',
                'Korle Bu Teaching Hospital',
                'Krane Contsruction',
                'Krobo Danish Association',
                'Kuapa Kokoo',
                'Kumawuman Rural Bnk',
                'Kwamaman Rural Bank',
                'La Nkwantanang Madina Municipal Assembly',
                'Labadi Beach Hotel',
                'Life Healthcare Medical Dental and Optical Clinic',
                'Living Star School',
                'Lordship Insurance Brokers',
                'Lower Pra Rural Bank',
                'Maxam Ghana Ltd',
                'Mechanical Lloyd',
                'Metromass',
                'Mojo Investment Ltd',
                'Mondial Veneer Gh. Ltd',
                'Mumuada Rural Bank',
                'Nafana Rural Bank Ltd',
                'National Security',
                'Nexans',
                'Nick Tc Scan Ltd',
                'Nsoatreman Rural Bank',
                'Nthc Properties Ltd',
                'Nwabiagya Rural Bank',
                'Okomfo Anokye Rural Bank Staff',
                'Opportunity Int',
                'Parliament',
                'Pbc Ltd',
                'Pentecost Preparatory Schoolâ ',
                'Pentecost University College',
                'Phyto Riker',
                'Poly Products',
                'Presbyterian Womens Center - Abokobi',
                'Prince of Peace',
                'Quality Control Division (Cocobod)',
                'Regional Maritime Academy',
                'Ridge Church School',
                'Safebound Company Limited - Tema',
                'Sakworg Company Ltd',
                'Samartex Timber and Plywood Company',
                'Sandvik Mining and Construction Limited',
                'Scanbech Ghana Ltd',
                'Sdv Ghana',
                'Securities Discount Company Limited',
                'Sekyedumase Rural Bank',
                'Sekyere Rural Bank',
                'Services Intergrity Savings and Loans',
                'Sgs Laboratory Services',
                'Sg Ssb',
                'Silver Star Auto Ltd',
                'Sinapi Aba Trust',
                'Sofaamy Company Limited',
                'Sos Childrens Village Ghana',
                'South Birim Rural Bank',
                'South Central Conference of Sda',
                'Special Ice Company Ltd',
                'Ssnit',
                'St Ambrose College',
                'St Michaels Hospital Pramso',
                'Stellar Logistic',
                'Sunyani Cluster Icps',
                'Takoradi Flour Mill Ltd',
                'Takoradi Int. Co.',
                'Tct',
                'Tema Development Corp.',
                'Tema Parents Association School',
                'Tesano Baptist Church',
                'The Church of Pentecost',
                'Toende Rural Bank',
                'Trasacco',
                'Twifo Oil Palm Plantation',
                'Type Company Ltd',
                'Unilever',
                'University College of Education',
                'University for Development Studies',
                'University of Energy and Natural Resources',
                'University of Ghana - Legon',
                'Upper Amenfi Rural Bank',
                'Upper Manya Kro Rural Bank',
                'Utrak Savings and Loans',
                'Valley View',
                'Valley View University Basic School',
                'Vat Service',
                'Volta Hotel',
                'Volta River Authority',
                'Waec - Headquarters',
                'Waec - Main',
                'West Africa Mills Co. Ltd',
                'Weto Rural Bank',
                'Winners Chapel Ghana',
                'Yaakson Herbal Centre',
                'Yarsako',
              ],
            },
            {
              id: 'employee-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Employee Number',
              uid: '17ff314b6165c883',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
          ],
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: true,
          label: 'System List',
          uid: 'd1ac4ed6d65c6c5b',
          content: [],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '94121cb9c8426e7b',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'bf217a70f0f2bfe4',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8b31df748a9b25f8',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'b25aa5e49e79acdf',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '8588b418b3adff34',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Payment Details',
    uri: 'ffp-unlimited/payment-details',
    template: 'ffp-unlimited-payment-details-screen',
    class: 'ffp-unlimited-payment-details',
  },
  'mobile-wallet-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: 'a07c524fbb7ab98b',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'system-list',
          type: 'list',
          readonly: false,
          label: 'System List',
          uid: '51ec63fbc00239ee',
          content: [
            {
              id: 'mobile-wallet-details',
              type: 'list',
              readonly: false,
              label: 'Mobile Wallet Details',
              uid: '2d34ee1c0b75f4a2',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '7577327f9a279ee2',
                  content: '<p>Please provide your mobile wallet details</p>\n',
                },
                {
                  id: 'mobile-wallet-network-provider',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Mobile Wallet Network Provider',
                  uid: '3a6d7817942bbe73',
                  content: 'Vodafone',
                  publishChange: true,
                  tooltip: 'Mobile Wallet Network Provider',
                  options: ['', 'Mtn', 'Vodafone'],
                },
                {
                  id: 'mobile-wallet-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Mobile Wallet Number',
                  uid: '165034a17f3ef9dd',
                  content: '081824732',
                  publishChange: true,
                  errors: [
                    'Number needs to be 12 digits long and start with 233.',
                    'Invalid Mobile Money number',
                  ],
                  tooltip: 'Mobile Wallet Number',
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '94121cb9c8426e7b',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'bf217a70f0f2bfe4',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8b31df748a9b25f8',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'b25aa5e49e79acdf',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '8588b418b3adff34',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Payment Details',
    uri: 'ffp-unlimited/payment-details',
    template: 'ffp-unlimited-payment-details-screen',
    class: 'ffp-unlimited-payment-details',
  },
  'payer-details': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'payer-details',
      type: 'list',
      readonly: false,
      label: 'Payer Details',
      uid: 'df332eaf9a8c9473',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'c1b8e5265e6b9227',
          content: '<p>Please provide the details of your spouse below</p>\n',
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'cb3a424839e23b16',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: '7422101eadba0c9e',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '6dc0473a40fcda83',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'date',
          component: 'DateSelector',
          maxYear: 2023,
          minYear: 1923,
        },
        {
          id: 'idpassport-number',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'ID Number',
          uid: 'a4a76d5e5d9cfe41',
          content: '',
          publishChange: true,
          inputType: 'text',
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Payment Details',
    uri: 'ffp-unlimited/payment-details',
    template: 'ffp-unlimited-payment-details-screen',
    class: 'ffp-unlimited-payment-details',
  },
  'payment-details-acceptance': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details-signed',
      type: 'list',
      readonly: false,
      label: 'Payment Details Signed',
      uid: '316bc081effb179a',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '3d456e0848aa07fd',
          content: '<h2>Payment Details Verification</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'be1e2b1ea1a243b0',
          content: '<p>Please confirm payment details by signing below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'ee768accd6bcc82e',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'payment-details-summary',
          type: 'list',
          readonly: true,
          label: 'Payment Details Summary',
          uid: '54be37ef7247f399',
          content: [
            {
              id: 'contract-id',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Application Reference',
              uid: 'c56972c7be439b96',
              content: '',
              inputType: 'text',
            },
            {
              id: 'payer-employment-details',
              type: 'list',
              readonly: true,
              label: 'Payer Details',
              uid: '02527c74d33f95fc',
              content: [],
            },
          ],
        },
        {
          id: 'signature-section',
          type: 'list',
          readonly: false,
          label: 'Signature Section',
          uid: '6bbbcf51ffc6fbe6',
          content: [
            {
              id: 'interactive-canvas-html',
              type: 'component',
              component: 'InteractiveCanvas',
              readonly: false,
              label: 'Interactive Canvas',
              uid: '2b6864784d4c6f97',
              content:
                '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '6a8d0db26f923086',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'fd41df84207ac1ff',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '1d51b45b2fccc9ad',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'b429d050c8923173',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '9262e0eaafc96904',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Payment Details',
    uri: 'ffp-unlimited/payment-details',
    template: 'ffp-unlimited-payment-details-screen',
    class: 'ffp-unlimited-payment-details',
  },
  'upload-documents': {
    text: [],
    buttons: [
      {
        label: 'Upload ID document',
        id: 'upload-id-document',
        uid: '69a53b6758f4ff80',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'upload-documents',
      type: 'list',
      readonly: true,
      label: 'Upload Documents',
      uid: 'f49101649c555c2f',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '2dd71fd55a00cef9',
          content: '<h2>Upload Documents</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f4179312612d1ce4',
          content:
            '<p>Please upload the necessary documentation or proceed to the next page if you would like to provide it later.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'e567849ef903ec0e',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='done clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'documents-list',
          type: 'list',
          readonly: true,
          label: 'Documents List',
          uid: '8a30a43b57bb332b',
          content: [
            {
              id: 'system-field-outcome',
              type: 'html',
              readonly: true,
              label: '',
              uid: '7721dd56ec3e43de',
              content:
                "<p><a href='#upload-id-document' class=''>ID Document <span>Re Upload</span></a></p>\n",
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '53b8fb3939571654',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '02b107c27aa54bae',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '467ced30dcca8a0b',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '568f821040a6fa07',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '4e4fe20b1a8844de',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Ffp Unlimited Upload Documents',
    uri: 'ffp-unlimited/upload-documents',
    template: 'ffp-unlimited-payment-details-screen',
    class: 'ffp-unlimited-upload-documents',
  },
  'upload-document-pages': {
    modal: true,
    buttons: [
      {
        control: false,
        id: 'add-page',
        label: 'Add Page',
        uid: '1a5450d98db152d6',
      },
      {
        control: true,
        id: 'abort',
        label: 'Abort',
        uid: 'e11279c7f15a77ea',
      },
      {
        control: true,
        id: 'continue',
        label: 'Continue',
        uid: '0d54090d869303f6',
      },
    ],
    item: {
      content: [
        {
          content:
            '<p>Please upload your document below. If it consists of multiple pages, please upload all of them together.</p>\n',
          id: 'system-field-outcome',
          label: '',
          readonly: true,
          type: 'html',
          uid: '8872705861783d2d',
        },
        {
          content: [],
          id: 'uploads',
          label: 'Uploads',
          readonly: false,
          type: 'table',
          uid: '2d999fbaaefb947c',
        },
        {
          content:
            '<p><a class="button add-page-button" href="#add-page">Add Page</a></p>\n',
          id: 'system-constant',
          label: '',
          readonly: true,
          type: 'html',
          uid: 'a47ce0e3f28e09c5',
        },
      ],
      id: 'upload-section',
      label: 'Upload Section',
      readonly: false,
      type: 'list',
      uid: '74b77d523e9f9b6d',
    },
    class: 'upload-layout',
    context: 'Upload Layout',
    template: '',
    text: [],
    title: 'Public Access \n Welcome to Elac',
    uri: 'upload-document/file/new',
  },
  'acceptance-screen': {
    text: [],
    buttons: [
      {
        label: 'Submit',
        id: 'submit',
        uid: 'ef8243ce592928b2',
        control: false,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'acceptance',
      type: 'list',
      readonly: false,
      label: 'Acceptance',
      uid: '934169883429c058',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '981d430846482fe8',
          content: '<h2>Summary</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '8b470d282f194b34',
          content: '<p>Please review the information below</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '41c1a03e9a972134',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='done clickable'><a href='#uploads'>Uploads</a></li>\n<li class='done clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'summary',
          type: 'list',
          readonly: true,
          label: 'Summary',
          uid: '6a24efd1fc822eb9',
          content: [
            {
              id: 'contract-id',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Application Reference',
              uid: '4d1e978720fb5e43',
              content: 'Gh2av000281',
              inputType: 'text',
            },
            {
              id: 'life-assured-identification',
              type: 'list',
              readonly: true,
              label: 'Life Assured Identification',
              uid: 'dbb0486da013fbb7',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: '4bc984547af74803',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: '8e3b79aeb4f939c9',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '4fbcb5f5033fa4a4',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: 'ea0dba2cd4864025',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'marital-status',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Marital Status',
                  uid: '3ec2074800e0e60c',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'gender',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Gender',
                  uid: '25970a93188b88d4',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'nationality',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Nationality',
                  uid: '6909867734297b2e',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'ghanaian',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Ghanaian',
                  uid: '6909867734297b2e',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'idpassport-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'ID Number',
                  uid: 'fecc9856412d6b21',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'life-assured-contact-details',
              type: 'list',
              readonly: true,
              label: 'Life Assured Contact Details',
              uid: 'aad1ad22d4b98139',
              content: [
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '4f9e98cae52cd1fd',
                  content: '',
                  inputType: 'tel',
                },
                {
                  id: 'email',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Email',
                  uid: '362ade8fc8a227fa',
                  content: '',
                  inputType: 'email',
                },
              ],
            },
            {
              id: 'life-assured-postal-details',
              type: 'list',
              readonly: true,
              label: 'Contracting Party Postal Address',
              uid: '31b6c0e59dacb6d4',
              content: [
                {
                  id: 'po-box-or-private-bag',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'PO Box Or Private Bag',
                  uid: 'c92afda7ead33da0',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'region',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Region',
                  uid: '3c53bc0cee2fd370',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'town',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Town',
                  uid: '511fb5304f86327c',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'trustee-identification',
              type: 'list',
              readonly: true,
              label: 'Trustee Identification',
              uid: 'c470381d6bc764ee',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: '33561cb43b80e632',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: '5ccdf849ff975910',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '890da04c904445a0',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: '44443c7a2e61cf83',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '6360b42285a0f5e2',
                  content: '',
                  inputType: 'tel',
                },
                {
                  id: 'relationship',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Relationship',
                  uid: '2dbd3a6d1c1ade2b',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'payer-employment-details',
              type: 'list',
              readonly: true,
              label: 'Payer Employment Details',
              uid: '10e0808786ba4167',
              content: [],
            },
          ],
        },
        {
          id: 'declaration',
          type: 'list',
          readonly: true,
          label: 'Declaration',
          uid: '51b21c0c902da621',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'bbb0a899a5b9827c',
              content:
                '<p>I, the undersigned, hereby declare that I have not suffered from any illness nor consulted a medical practitioner and that I am currently in good health.</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '73f3c6324d7e7c1a',
              content:
                '<p>I also declare that the information provided by me and required of me on this application is both correct and accurate, that the option as selected herein is clear and that I understand the conditions of this policy.</p>\n',
            },
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '3c70023c2f3425f4',
              content:
                '<p>I agree that this declaration shall be taken together with the proposal as the basis of the contract between Enterprise Life Assurance Company Limited and me.</p>\n',
            },
          ],
        },
        {
          id: 'interactive-canvas-html',
          type: 'component',
          component: 'InteractiveCanvas',
          readonly: false,
          label: 'Interactive Canvas',
          uid: '1fcad0e41194c3d1',
          content:
            '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'a960f1ffe4614f2f',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'b995b3bd0d39e92d',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '9e6af49ed94df69f',
                  content: '<p>Below 100 Pure Risk</p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: 'd39549556da24b71',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'bbd7cd4827d9d0c8',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac',
    context: 'Pure Risk Acceptance Screen',
    uri: 'pure-risk/acceptance',
    template: 'pure-risk-acceptance-screen',
    class: 'pure-risk-acceptance-screen',
  },
}

export const acceptancePaymentDetails = (form) => {
  if (form['payment-method-selection']['debit-order'] === 'Y') {
    return {
      content: [
        {
          content: form['payment-bank-details']['account-entity-name'] || '',
          elementType: 'input',
          id: 'account-entity-name',
          inputType: 'text',
          label: 'Account Entity Name',
          readonly: true,
          type: 'field',
          uid: 'e47cf0c452614349',
        },
        {
          content: form['payment-bank-details']['account-holder-name'] || '',
          elementType: 'input',
          id: 'account-holder-name',
          inputType: 'text',
          label: 'Account Holder Name',
          readonly: true,
          type: 'field',
          uid: '3d49b733e15abc7c',
        },
        {
          content: form['payment-bank-details']['account-number'] || '',
          elementType: 'input',
          id: 'account-number',
          inputType: 'text',
          label: 'Account Number',
          readonly: true,
          type: 'field',
          uid: 'cf966f446acc41ae',
        },
        {
          content: form['payment-bank-details']['account-type'] || '',
          elementType: 'input',
          id: 'account-type',
          inputType: 'text',
          label: 'Account Type',
          readonly: true,
          type: 'field',
          uid: 'e8cfb99fa1289443',
        },
        {
          content: form['payment-bank-details']['account-branch-name'] || '',
          elementType: 'input',
          id: 'account-branch-name',
          inputType: 'text',
          label: 'Account Branch Name',
          readonly: true,
          type: 'field',
          uid: '30738e87a98b959c',
        },
      ],
      id: 'payment-bank-details',
      label: 'Payment Bank Details',
      readonly: true,
      type: 'list',
      uid: '80eb57ac744be154',
    }
  } else if (form['payment-method-selection']['stop-order'] === 'Y') {
    return {
      id: 'payer-employment-details',
      type: 'list',
      readonly: true,
      label: 'Payer Employment Details',
      uid: '9f4ebc3347190f87',
      content: [
        {
          id: 'employer-organisation',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employer Organisation',
          uid: '8d379c9e95d45b1c',
          content: form['stop-order-details']['employer-organisation'] || '',
          inputType: 'text',
        },
        {
          id: 'employee-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employee Number',
          uid: '2aeba28f1d28c239',
          content: form['stop-order-details']['employee-number'] || '',
          inputType: 'text',
        },
      ],
    }
  } else if (form['payment-method-selection']['mobile-wallet'] === 'Y') {
    return {
      id: 'mobile-wallet-details',
      type: 'list',
      readonly: true,
      label: 'Mobile Wallet Details',
      uid: '9f4ebc3347190f87',
      content: [
        {
          id: 'mobile-wallet-network-provider',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Mobile Network Provider',
          uid: '8d379c9e95d45b1c',
          content:
            form['mobile-wallet-details']['mobile-wallet-network-provider'] ||
            '',
          inputType: 'text',
        },
        {
          id: 'mobile-wallet-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Mobile Wallet Number',
          uid: '2aeba28f1d28c239',
          content: form['mobile-wallet-details']['mobile-wallet-number'] || '',
          inputType: 'text',
        },
      ],
    }
  } else {
    return null
  }
}

let ageRange = () => {
  const cached = {}
  return (start, end) => {
    if (start > end) {
      start = end
    }
    const k = `[${start}-${end}]`
    if (k in cached) {
      return cached[k]
    }
    const range = ['', `${start}`]
    for (let val = start + 1; val <= end; val++) {
      range.push(`${val}`)
    }
    cached[k] = range
    return range
  }
}
ageRange = ageRange()

// Family member menu
export const familyMemberOptions = (age) => ({
  Spouse: {
    options: ageRange(18, 59),
  },
  Child: {
    options: ageRange(0, 21),
  },
  //AGE DIFFERENCE BETWEEN LIFE ASSURED AND PARENTS = MINIMUM OF 13 YEARS
  'Parent or In-Laws': {
    Mother: {
      options: ageRange(age + 13, 74),
    },
    'Mother in law': {
      options: ageRange(age + 13, 74),
    },
    Father: {
      options: ageRange(age + 13, 74),
    },
    'Father in law': {
      options: ageRange(age + 13, 74),
    },
  },
  //AGE DIFFERENCE BETWEEN LIFE ASSURED AND GRAND PARENTS = 26 YEARS
  Grandparents: {
    Grandmother: {
      options: ageRange(age + 26, 74),
    },
    Grandfather: {
      options: ageRange(age + 26, 74),
    },
  },
  'Step Parents': {
    'Step mother': {
      options: ageRange(18, 74),
    },
    'Step father': {
      options: ageRange(18, 74),
    },
  },
  'Additional-Spouse': {
    options: ageRange(18, 59),
  },
  //AGE DIFFERENCE BETWEEN LIFE ASSURED AND GAURDIAN = 16 YEARS
  Guardian: {
    options: ageRange(age + 16, 74),
  },
  Siblings: {
    Sister: {
      options: ageRange(0, 74),
    },
    Brother: {
      options: ageRange(0, 74),
    },
  },
})
export const familyMemberLabels = Object.keys(familyMemberOptions(0))

export const additionalLife = (life, position, lives) => {
  const lifeRelationship = `${life.relationship}${findOccurance(
    life,
    position,
    lives
  )}`
  return [
    createItem('Relationship', {
      content: lifeRelationship,
      elementType: 'input',
      inputType: 'text',
      readonly: true,
      type: 'field',
    }),
    createItem('Age', {
      content: life['age-of-member'],
      elementType: 'input',
      inputType: 'number',
      readonly: true,
      type: 'integer',
    }),
    createItem('system-field-outcome', {
      content: `<p><a class="remove" href="#remove-family#${slugify(
        lifeRelationship
      )}">Remove</a><a class="edit" href="#edit-family#${slugify(
        lifeRelationship
      )}">Edit</a></p>\n`,
      label: '',
      readonly: true,
      type: 'html',
    }),
  ]
}

export const buttonFromLifeAssured = (life, position, lives) =>
  createButton(`${life.relationship}${findOccurance(life, position, lives)}`)

export const idFromLifeAssured = (life, position, lives) =>
  slugify(`${life.relationship}${findOccurance(life, position, lives)}`)

export const createFamilyMember = (
  relationship,
  ageofmember,
  gender,
  optione,
  optionf,
  optiong,
  optionh
) => ({
  relationship,
  'age-of-member': ageofmember,
  gender,
  'option-e': optione,
  'option-f': optionf,
  'option-g': optiong,
  'option-h': optionh,
  'first-names': null,
  surname: null,
  'date-of-birth': null,
})

export const isMultipleOccuranceRelationship = (relationship) =>
  ['Spouse'].indexOf(relationship) < 0

export const multipleOccurancesExist = (life, lives) =>
  isMultipleOccuranceRelationship(life.relationship) &&
  lives.reduce(
    (c, l) => (l.relationship === life.relationship ? c + 1 : c),
    0
  ) > 1

const findOccurance = (life, position, lives) => {
  let occuranceString = ''
  if (multipleOccurancesExist(life, lives)) {
    let occurance = 1
    for (let i = position - 1; i >= 0; i--) {
      if (lives[i].relationship === life.relationship) {
        occurance += 1
      }
    }
    occuranceString = ` ${occurance}`
  }
  return occuranceString
}

export const familyMemberTablePrefix = [
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: '0bc795d3e0cb7f34',
    content:
      '<h2 id="additional-lives-details">Additional Lives Details</h2>\n',
  },
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: 'cdcd7a4b761e07b0',
    content: '',
  },
  {
    id: 'output-html',
    type: 'html',
    readonly: true,
    label: 'Output Html',
    uid: '61f48521f562ddce',
    content:
      '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#additional">Additional</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
  },
]

export const familyMemberItem = (f, counter) => ({
  id: `life-${counter}`,
  type: 'list',
  readonly: false,
  label: f.relationship,
  uid: '8ecfd29866544493',
  content: [
    {
      id: 'system-field-outcome',
      type: 'html',
      readonly: true,
      label: '',
      uid: '1a3c568e4f5afeda',
      content: `<p>Please provide the following information for your <strong>${f.relationship}</strong> aged <strong>${f['age-of-member']}</strong>.</p>\n`,
    },
    {
      id: `first-names-${counter}`,
      type: 'field',
      elementType: 'input',
      readonly: false,
      label: 'First Names',
      uid: 'c25583134b856e84',
      content: f['first-names'] || '',
      errors: f['first-names'] ? [] : ['*'],
      publishChange: true,
      inputType: 'text',
    },
    {
      id: `surname-${counter}`,
      type: 'field',
      elementType: 'input',
      readonly: false,
      label: 'Surname',
      uid: '92b7de7d6e94e8aa',
      content: f.surname || '',
      errors: f.surname ? [] : ['*'],
      publishChange: true,
      inputType: 'text',
    },
    {
      id: `date-of-birth-${counter}`,
      type: 'component',
      elementType: 'input',
      readonly: false,
      label: 'Date Of Birth',
      uid: '5ca6a755437569bb',
      content: f['date-of-birth'] || '',
      errors: f['date-of-birth'] ? [] : ['*'],
      publishChange: true,
      inputType: 'date',
      component: 'DateSelector',
      maxYear: dayjs().year(),
    },
    {
      id: `gender-${counter}`,
      type: 'field',
      elementType: 'input',
      readonly: true,
      label: 'Gender',
      uid: '7d759fa831bbbccb',
      content: f.gender || '',
      inputType: 'text',
      options: ['', 'Female', 'Male'],
    },
    {
      id: 'quotation-panel',
      type: 'list',
      readonly: true,
      label: 'Quotation Panel',
      uid: '63f0b1c676b3494e',
      content: [
        {
          id: 'quotation-information',
          type: 'list',
          readonly: true,
          label: 'Quotation Information',
          uid: 'f5571aa6be26c0ae',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'd1c9946821f6b274',
              content: '<p>Below 100 Pure Risk</p>\n',
            },
            {
              id: 'benefit-rate',
              type: 'amount',
              elementType: 'input',
              readonly: true,
              label: 'Benefit Rate',
              uid: 'c0744d8f375c610b',
              content: '0.00',
              inputType: 'number',
            },
            {
              id: 'premium',
              type: 'amount',
              elementType: 'input',
              readonly: true,
              label: 'Premium',
              uid: '858a3fe5e214d158',
              content: '0.00',
              inputType: 'number',
            },
          ],
        },
      ],
    },
  ],
})
