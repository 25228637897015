/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const form = {
  'selected-premium': {
    'term-in-years': null,
    'update-option': null,
    'hospitalisation-cover-option': null,
    'cash-bonus-premium': null,
    'investment-premium': null,
  },
  'life-assured-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
    title: null,
    'marital-status': null,
    nationality: null,
    gender: null,
  },
  'life-assured-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
    fax: null,
    email: null,
  },
  'life-assured-postal-address': {
    addressee: null,
    'po-box-or-private-bag': null,
    'area-code': null,
    street: null,
    suburb: null,
    town: null,
    region: null,
  },
  'life-assured-employment-details': {
    occupation: null,
  },
  'spouse-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
    'marital-status': null,
    title: null,
    gender: null,
  },
  'spouse-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
  },
  'spouse-is-trustee': null,
  'trustee-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
    'marital-status': null,
    relationship: null,
    title: null,
    gender: null,
  },
  'trustee-contact-details': {
    'cell-phone': null,
    'work-phone': null,
    'home-phone': null,
  },
  'children-details': [],
  'beneficiary-details': [],
  'life-assured-health-details': {
    'presently-in-good-health': null,
    'risky-occupation-hobbies': null,
    'taking-prescriptions': null,
    'sexually-transmitted-diseases': null,
    'refused-application-or-increased-premium': null,
    'height-in-cm': null,
    'weight-in-kg': null,
    'weight-change': null,
    'medical-advice-stop-smoking': null,
    'medical-advice-reduce-drinking': null,
    'excessive-drinking': null,
    'excessive-drinking-past': null,
    smoker: null,
    'non-smoker': null,
    'never-smoked': null,
    'years-smoking': null,
    'years-not-smoking': null,
    'cigarettes-per-day': null,
    'cigars-per-day': null,
    pipe: null,
  },
  'health-condition-details': {
    'presently-in-good-health-details': null,
    'risky-occupation-hobbies-details': null,
    'taking-prescriptions-details': null,
    'sexually-transmitted-diseases-details': null,
    'refused-application-or-increased-premium-details': null,
    'medical-advice-stop-smoking-details': null,
    'medical-advice-reduce-drinking-details': null,
    'excessive-drinking-details': null,
    'excessive-drinking-past-details': null,
  },
  'health-concerns': [],
  'payment-method-selection': {
    'debit-order': null,
    'stop-order': null,
  },
  'payment-start-date': null,
  'payment-frequency': null,
  'payer-person': {
    myself: null,
    'my-spouse': null,
  },
  'life-assured-is-payer': null,
  'payer-person-identification': {
    'first-names': null,
    surname: null,
    'date-of-birth': null,
    'idpassport-number': null,
    'id-type': null,
  },
  'payment-bank-details': {
    'account-holder-name': null,
    'account-type': null,
    'account-entity-name': null,
    'account-branch-name': null,
    'account-number': null,
  },
  'payment-employment-details': {
    'employer-organisation': null,
    'employee-number': null,
  },
  'payer-signature': null,
  documents: {
    'id-document': [],
    'passport-document': [],
  },
  'acceptance-signature': null,
  'application-acceptance-date': null,
  'application-accepted': null,
}
