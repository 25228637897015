/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'
import ValidationErrors from './ValidationErrors'

const useTooltips = process.env.APPLICATION_USE_TOOLTIPS === 'true'

const Radio = ({
  value,
  id,
  label,
  readonly,
  inputType,
  required,
  errors,
  hideLabel,
  itemIndex,
  options,
  tooltip,
  onChange
}) => {
  const elementId = hideLabel && itemIndex ? `${id}-${itemIndex}` : id
  return (
    <div
      className={classNames({
        editable: !readonly,
        field: true,
        'has-tooltip': useTooltips && tooltip,
        'has-value': value.length > 0,
        radio: true
      })}
    >
      {!hideLabel && (
        <label
          htmlFor={elementId}
          className={classNames({
            hidden: hideLabel,
            label: true,
            'label-inline': true
          })}
        >
          {label}
        </label>
      )}
      <div className='input-group'>
        <span
          id={elementId}
          className={classNames({
            disabled: readonly,
            'has-error': errors && errors.length > 0,
            'is-required': required,
            placeholder: value.length === 0 && !useTooltips && tooltip
          })}
          data-tooltip={tooltip}
        >
          {options.map((option, i) => {
            const itemId = `${elementId}-${i}`
            return (
              <div key={i}>
                <input
                  type='radio'
                  key={i}
                  value={option}
                  name={label}
                  id={itemId}
                  required={required}
                  checked={value === option}
                  onChange={onChange}
                />
                <label
                  htmlFor={itemId}
                  className={classNames({
                    hidden: hideLabel,
                    label: true,
                    'label-inline': true
                  })}
                >
                  {option}
                </label>
              </div>
            )
          })}
        </span>
      </div>
      {errors && errors.length > 0 && <ValidationErrors errors={errors} />}
    </div>
  )
}

export default Radio
