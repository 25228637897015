/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.handleErrorReport = this.handleErrorReport.bind(this)
    this.state = { hasError: false, eventId: null }
  }

  static getDerivedStateFromError(error) {
    console.log('ONE')
    console.log(error)
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('Well, caught an error !')
    console.log(error)
    console.log(errorInfo)
    window.Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = window.Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  handleErrorReport() {
    window.Sentry.showReportDialog({ eventId: this.state.eventId })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='container'>
          <div className='error-content'>
            <img src={process.env.APPLICATION_LOGO} />
            <h1>Something went wrong</h1>
            <span>The problem has been reported and we are working on it!</span>
            <br />
            <span>
              If you would like to provide any further information, click this
              button below
            </span>
            <br />
            <div className='button-group'>
              <a className='button' href='#' onClick={this.handleErrorReport}>
                Report feedback
              </a>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
