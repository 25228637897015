/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'

const MarkupItem = ({ id, content }) => (
  <div id={id} dangerouslySetInnerHTML={{ __html: content }} />
)

export default MarkupItem
