/* eslint camelcase: 0 */
/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import classNames from 'classnames'
import dayjs from 'dayjs'
import React from 'react'
import ValidationErrors from './ValidationErrors'

const useTooltips = process.env.APPLICATION_USE_TOOLTIPS === 'true'

class DateSelector extends React.Component {
  constructor(props) {
    super(props)

    this.changeDate = this.changeDate.bind(this)
    this.getDate = this.getDate.bind(this)
    this.checkDate = this.checkDate.bind(this)
    this.isCompleted = this.isCompleted.bind(this)
    this.formatDate = this.formatDate.bind(this)
    this.handleDayChange = this.handleDayChange.bind(this)
    this.handleMonthChange = this.handleMonthChange.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)

    /* Set initial state */
    this.state = {
      day: null,
      month: null,
      year: null,
      selectedDay: 'Day',
      selectedMonth: 'Month',
      selectedYear: 'Year',
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    return (
      this.state.selectedDay !== nextState.selectedDay ||
      this.state.selectedMonth !== nextState.selectedMonth ||
      this.state.selectedYear !== nextState.selectedYear
    )
  }

  UNSAFE_componentWillMount() {
    const day = []
    const month = []
    const year = []
    for (let i = 1; i <= 31; i++) {
      day.push(i)
    }

    let monthIndex = 1
    for (const monthName of dayjs.en.months) {
      month.push({
        text: monthName,
        value: monthIndex,
      })
      monthIndex++
    }

    const maxYear = this.props.maxYear || dayjs().year()
    const minYear = this.props.minYear || 1916

    for (let i = maxYear; i >= minYear; i--) {
      year.push(i)
    }

    this.setState(
      Object.assign(this.formatDate(this.props.value), { day, month, year })
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState(this.formatDate(nextProps.value))
    }

    if (
      (this.props.errors && this.props.errors.length) !==
      (nextProps.errors && nextProps.errors.length)
    ) {
      this.forceUpdate()
    }
  }

  changeDate(e, type) {
    this.setState({
      [type]: e.target.value,
    })
    this.checkDate(e.target.value, type)
  }

  getDate(date) {
    const d = dayjs(
      new Date(parseInt(date.year), date.month, parseInt(date.day))
    )
    if (d.isValid()) {
      return d.format('YYYYMMDD')
    } else {
      return ''
    }
  }

  formatDate(text) {
    const d = dayjs(text)
    if (d.isValid()) {
      return {
        selectedDay: d.date(),
        selectedMonth: d.month() + 1,
        selectedYear: d.year(),
      }
    } else {
      return {
        selectedDay: 'Day',
        selectedMonth: 'Month',
        selectedYear: 'Year',
      }
    }
  }

  checkDate(value, type) {
    let { selectedDay, selectedMonth, selectedYear } = this.state

    if (type === 'selectedDay') {
      selectedDay = value
    } else if (type === 'selectedMonth') {
      selectedMonth = value
    } else if (type === 'selectedYear') {
      selectedYear = value
    }

    if (this.isCompleted(selectedDay, selectedMonth, selectedYear)) {
      this.props.onChange(
        this.getDate({
          year: selectedYear,
          month: selectedMonth - 1,
          day: selectedDay,
        })
      )
    } else {
      this.props.onChange('')
    }
  }

  isCompleted(d, m, y) {
    return (
      d !== '' &&
      d !== 'Day' &&
      m !== '' &&
      m !== 'Month' &&
      y !== '' &&
      y !== 'Year'
    )
  }

  handleDayChange(e) {
    this.changeDate(e, 'selectedDay')
  }

  handleMonthChange(e) {
    this.changeDate(e, 'selectedMonth')
  }

  handleYearChange(e) {
    this.changeDate(e, 'selectedYear')
  }

  render() {
    const { selectedDay, selectedMonth, selectedYear } = this.state
    const hasValue = this.isCompleted(selectedDay, selectedMonth, selectedYear)
    let hasError = this.props.errors && this.props.errors.length > 0
    if (
      hasError &&
      hasValue &&
      this.props.errors.reduce(
        (every, err) =>
          every &&
          ((err || '').toLowerCase().indexOf('required') >= 0 || err === '*'),
        true
      )
    ) {
      hasError = false
    }

    const hideLabel = this.props.hideLabel
    const labelClass = classNames({
      hidden: hideLabel,
      label: true,
    })

    const containerClass = classNames({
      editable: !this.props.readonly,
      field: true,
      'has-icon': this.props.markers && this.props.markers.length > 0,
      'has-tooltip': useTooltips && this.props.tooltip,
      'has-value': hasValue,
      input: true,
    })

    const inputClass = {
      disabled: this.props.readonly,
      'has-error': hasError,
    }

    const elementId =
      this.props.hideLabel && this.props.itemIndex
        ? `${this.props.id}-${this.props.itemIndex}`
        : this.props.id

    const markers =
      this.props.markers &&
      this.props.markers.map((marker) => {
        const markerSrc = `data:${marker.type};base64,${marker.data}`
        return (
          <img
            className='input-icon'
            id={marker.id}
            src={markerSrc}
            key={marker.id}
            alt={marker.id}
          />
        )
      })

    return (
      <div className={containerClass}>
        <label htmlFor={elementId} className={labelClass}>
          {this.props.label}
        </label>
        <div
          className='date-selector'
          id={elementId}
          data-tooltip={this.props.tooltip}
        >
          <select
            className={classNames(Object.assign({ day: true }, inputClass))}
            value={this.state.selectedDay}
            disabled={this.props.readonly}
            onChange={this.handleDayChange}
          >
            <option value='' className='date-selector-label'>
              Day
            </option>
            {this.state.day.map((day, id) => (
              <option value={day} key={id}>
                {day}
              </option>
            ))}
          </select>
          <select
            className={classNames(Object.assign({ month: true }, inputClass))}
            value={this.state.selectedMonth}
            disabled={this.props.readonly}
            onChange={this.handleMonthChange}
          >
            <option value='' className='date-selector-label'>
              Month
            </option>
            {this.state.month.map((month, id) => (
              <option value={month.value} key={id}>
                {month.text}
              </option>
            ))}
          </select>
          <select
            className={classNames(Object.assign({ year: true }, inputClass))}
            value={this.state.selectedYear}
            disabled={this.props.readonly}
            onChange={this.handleYearChange}
          >
            <option value='' className='date-selector-label'>
              Year
            </option>
            {this.state.year.map((year, id) => (
              <option value={year} key={id}>
                {year}
              </option>
            ))}
          </select>
        </div>
        {this.props.errors && this.props.errors.length > 0 && (
          <ValidationErrors errors={this.props.errors} />
        )}
        {markers}
      </div>
    )
  }
}

export default DateSelector
