/* eslint react/jsx-handler-names: 0 */
/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import ValidationErrors from './ValidationErrors'

/*
 * Polyfills for browser support
 */

// Detect passive event listener support
const passiveEventListenerSupport = () => {
  let supportsPassiveOption = false
  try {
    const opts = Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassiveOption = true
      }
    })
    window.addEventListener('test', null, opts)
  } catch (e) {}
  return supportsPassiveOption
}

/* Workaround for webkit bug on iOS 11.3+
 * https://bugs.webkit.org/show_bug.cgi?id=182521
 */

window.preventTouchMove = function () {}
window.releaseTouchMove = function () {}

if (passiveEventListenerSupport()) {
  var blockTouchMove = false
  window.addEventListener(
    'touchmove',
    function (event) {
      if (!blockTouchMove) {
        return
      }
      if (event.defaultPrevented) {
        return
      }
      event.preventDefault()
    },
    { passive: false, capture: false }
  )

  window.preventTouchMove = function () {
    blockTouchMove = true
  }
  window.releaseTouchMove = function () {
    blockTouchMove = false
  }
}

const getEventLocationObject = nativeEvent => {
  if ('TouchEvent' in window && nativeEvent instanceof window.TouchEvent) {
    return nativeEvent.targetTouches[0]
  } else if (nativeEvent instanceof window.MouseEvent) {
    return nativeEvent
  } else {
    return {}
  }
}

class InteractiveCanvas extends React.Component {
  constructor (props) {
    super(props)
    this.updateCanvas = this.updateCanvas.bind(this)
    this.startDrawing = this.startDrawing.bind(this)
    this.stopDrawing = this.stopDrawing.bind(this)
    this.updateDrawing = this.updateDrawing.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.clearCanvas = this.clearCanvas.bind(this)
    const initialWidth = props.maxWidth || Math.min(window.innerWidth - 10, 500)
    this.state = {
      hasDrawn: false,
      drawing: false,
      width: props.maxWidth || Math.min(window.innerWidth - 10, 500),
      height: props.maxHeight || initialWidth / 2.5,
      updates: []
    }
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    const ctx = this.refs.canvas.getContext('2d')
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, this.refs.canvas.width, this.refs.canvas.height)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  clearCanvas (ignore) {
    const ctx = this.refs.canvas.getContext('2d')
    ctx.clearRect(
      0,
      0,
      this.refs.canvas.clientWidth,
      this.refs.canvas.clientHeight
    )
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, this.refs.canvas.width, this.refs.canvas.height)

    if (!ignore) {
      this.setState({ hasDrawn: false, drawing: false, updates: [] })
    }
  }

  updateWindowDimensions () {
    const newState = {
      width: this.refs.canvas.clientWidth,
      height: this.refs.canvas.clientWidth / 2.5
    }
    let cleared = false
    if (
      this.state.width !== this.refs.canvas.clientWidth ||
      this.state.height !== this.refs.canvas.clientHeight
    ) {
      newState.hasDrawn = false
      newState.drawing = false
      newState.updates = []
      cleared = true
    }
    this.setState(newState, () => {
      if (cleared) {
        this.clearCanvas(true)
      }
    })
  }

  updateCanvas () {
    let pnt, _i, _len
    const ctx = this.refs.canvas.getContext('2d')
    ctx.lineJoin = 'round'
    ctx.lineCap = 'round'
    ctx.beginPath()
    ctx.moveTo(this.state.updates[0].x, this.state.updates[0].y)
    const _ref = this.state.updates
    for (_i = 0, _len = _ref.length; _i < _len; _i += 1) {
      pnt = _ref[_i]
      ctx.lineTo(pnt.x, pnt.y)
    }
    ctx.strokeStyle = '#000000'
    ctx.lineWidth = 2
    ctx.stroke()
  }

  startDrawing (evt) {
    evt.preventDefault()
    /* Workaround for webkit browser bug */
    window.preventTouchMove()
    const loc = getEventLocationObject(evt.nativeEvent)
    const updates = [
      {
        x: loc.pageX - this.refs.canvas.getBoundingClientRect().left,
        y:
          loc.pageY -
          this.refs.canvas.getBoundingClientRect().top -
          window.pageYOffset,
        event: evt.type
      }
    ]
    this.setState({ drawing: true, updates })
  }

  stopDrawing (evt) {
    evt.preventDefault()
    const wasDrawing = this.state.drawing
    const state = { drawing: false }
    const isIncomplete = !this.state.hasDrawn
    if (this.state.drawing && isIncomplete) {
      state.hasDrawn = true
    }
    this.setState(state, () => {
      wasDrawing && this.props.onChange(this.refs.canvas.toDataURL('image/png'))
    })
    /* Workaround for webkit browser bug */
    window.releaseTouchMove()
  }

  updateDrawing (evt) {
    evt.preventDefault()
    if (this.state.drawing) {
      const loc = getEventLocationObject(evt.nativeEvent)
      const update = {
        x: loc.pageX - this.refs.canvas.getBoundingClientRect().left,
        y:
          loc.pageY -
          this.refs.canvas.getBoundingClientRect().top -
          window.pageYOffset,
        event: evt.type
      }
      this.setState(prevState => {
        prevState.updates.push(update)
        return { updates: prevState.updates }
      })
    }
  }

  render () {
    if (this.refs.canvas && this.state.updates.length) {
      this.updateCanvas()
    }
    return (
      <div className='interactive-canvas-container'>
        {this.props.value ? (
          <div
            className='interactive-canvas-content'
            dangerouslySetInnerHTML={{ __html: this.props.value }}
          />
        ) : null}
        <canvas
          ref='canvas'
          className='interactive-canvas'
          width={this.state.width}
          height={this.state.height}
          onMouseDown={this.startDrawing}
          onMouseUp={this.stopDrawing}
          onMouseOut={this.stopDrawing}
          onMouseLeave={this.stopDrawing}
          onTouchMove={this.updateDrawing}
          onTouchStart={this.startDrawing}
          onTouchEnd={this.stopDrawing}
          onTouchCancel={this.stopDrawing}
          onMouseMove={this.updateDrawing}
        />
        {this.props.errors && this.props.errors.length > 0 && (
          <ValidationErrors errors={this.props.errors} />
        )}
        {this.state.hasDrawn && (
          <div
            className='clear-interactive-canvas-btn'
            onClick={evt => {
              evt.preventDefault()
              this.clearCanvas()
              this.props.onChange(null)
            }}
          />
        )}
      </div>
    )
  }
}

export default InteractiveCanvas
