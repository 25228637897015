/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */

import {
  pages,
  healthDetailsItem,
  healthDetailsPrefix,
  beneficiaryItem,
  benefitSplitItem,
  quotationPanel,
  otherLivesItem,
  acceptancePaymentDetails,
} from './living-plus/pages'

import {
  updateItemIn,
  createItem,
  findItemsIn,
  createButton,
} from '../../../src/core/dataitems'
import { form } from './living-plus/form'
import { cancelProcess, completeProcess } from '../../../src/core/actions'
import shortid from 'shortid'
import { original } from 'immer'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import { validate } from './living-plus/validations'

const dateFormat = 'D MMMM YYYY'

// Change Page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}

const amountString = (f) =>
  f
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/, '')

const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

const healthConcernCount = (form) =>
  Object.keys(form['life-assured-health-details']).reduce(
    (t, f) =>
      t +
      (form['life-assured-health-details'][f] === 'Y' ||
      form['life-assured-health-details'][f] === 'Yes'
        ? 1
        : 0),
    0
  )

//Is Trustee The Beneficiary
const isTrusteeABeneficiary = createSelector(
  (form) => form['trustee-identification']['first-names'],
  (form) => form['trustee-identification'].surname,
  (form) => form['trustee-identification']['date-of-birth'],
  (form) => form['beneficiary-details'],
  (fn, sn, dob, bs) =>
    fn &&
    sn &&
    dob &&
    bs.reduce(
      (result, b) =>
        result ||
        (b['first-names'] === fn &&
          b.surname === sn &&
          b['date-of-birth'] === dob),
      false
    )
)

const isAddedLivesBeneficiary = (life, bs) =>
  life['first-names'] &&
  life.surname &&
  life['date-of-birth'] &&
  bs.reduce(
    (result, b) =>
      result ||
      (b['first-names'] === life['first-names'] &&
        b.surname === life.surname &&
        b['date-of-birth'] === life['date-of-birth']),
    false
  )

const updateBeneficiarySplit = createSelector(
  (form) => form['beneficiary-details'],
  (beneficiaries) => {
    if (beneficiaries.length > 0) {
      const split = Math.floor(100 / beneficiaries.length)
      let difference = 100 - split * beneficiaries.length
      for (let i = 0; i < beneficiaries.length; i++) {
        if (difference > 0) {
          beneficiaries[i]['benefit-split'] = amountString(split + 1)
          difference -= 1
        } else {
          beneficiaries[i]['benefit-split'] = amountString(split)
        }
      }
    }
    return beneficiaries
  }
)

const createHealthDetailsPage = (state) => {
  const maxConcerns = Math.min(4, healthConcernCount(original(state.form)))
  updateItemIn(
    state.page.item,
    { id: 'health-descriptions', type: 'list' },
    {
      content: healthDetailsPrefix
        .concat(healthDetailsItem(maxConcerns))
        .concat([quotationPanel]),
    }
  )
  if (state.form['health-concerns'].length !== maxConcerns) {
    while (state.form['health-concerns'].length > maxConcerns) {
      state.form['health-concerns'].pop()
    }
    for (let i = state.form['health-concerns'].length; i < maxConcerns; i++) {
      state.form['health-concerns'].push({
        complaints: null,
        'complaint-description': null,
        'medical-condition-date': null,
        'health-results': null,
      })
    }
  }
}

const generateAcceptancePage = (state) => {
  //Update the reference number
  updateItemIn(
    state.page.item,
    { id: 'contract-id' },
    { content: state.form['reference-number'] }
  )
  // Update all sections
  Object.keys(state.form).forEach((section) => {
    findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
      Object.keys(state.form[section]).forEach((field) => {
        if (state.form[section][field]) {
          updateItemIn(
            sItem,
            { id: field },
            { content: state.form[section][field] }
          )
        }
      })
    })
  })
  // Add beneficiary details
  updateItemIn(
    state.page.item,
    { id: 'beneficiary-details', type: 'table' },
    { content: (state.form['beneficiary-details'] || []).map(beneficiaryItem) }
  )

  // Update the payment details
  let paymentDetails = acceptancePaymentDetails(state.form)
  if (paymentDetails) {
    updateItemIn(
      state.page.item,
      { id: 'payment-details', type: 'list' },
      paymentDetails
    )
  }
}

const setPageItemFromState = (state) => {
  validate(state, state.local && state.local.failed_validation ? false : true)
  switch (state.step) {
    case 'quotation-details':
      // Date of birth
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'marital-status' },
        {
          content:
            state.form['life-assured-identification']['marital-status'] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'gender', type: 'field' },
        { content: state.form['life-assured-identification']['gender'] || '' }
      )

      //Quotation Details
      updateItemIn(
        state.page.item,
        { id: 'benefit-option' },
        { content: state.form['benefit-option'] || '' }
      )

      //Standalone
      Object.keys(state.form['standalone-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['standalone-options'][field] || '' }
        )
      )

      //Accelerated
      Object.keys(state.form['accelerated-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['accelerated-options'][field] || '' }
        )
      )

      //Update Premium Option
      updateItemIn(
        state.page.item,
        { id: 'update-option', type: 'field' },
        { content: state.form['premium-updates'] || '' }
      )

      // Hide elements when a benefit option has not been chosen yet.

      updateItemIn(
        state.page.item,
        { uid: 'b0e2b2201f4c3697', type: 'list' },
        { invisible: state.form['benefit-option'] !== 'Standalone' }
      )
      updateItemIn(
        state.page.item,
        { uid: '06b5f9b12c545697', type: 'list' },
        { invisible: state.form['benefit-option'] !== 'Accelerated' }
      )
      break

    case 'additional-benefits':
      updateItemIn(
        state.page.item,
        { id: 'hospitalisation-units', type: 'amount' },
        { content: state.form['hospitalisation-units'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'accidental-death-units', type: 'amount' },
        {
          content:
            state.form['personal-accident-death-cover'][
              'accidental-death-units'
            ] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'incapacity-units', type: 'amount' },
        {
          content:
            state.form['personal-accident-death-cover']['incapacity-units'] ||
            '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'educational-support-premium', type: 'amount' },
        {
          content:
            state.form['educational-support-benefit'][
              'educational-support-premium'
            ] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'educational-support-term', type: 'integer' },
        {
          content:
            state.form['educational-support-benefit'][
              'educational-support-term'
            ] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'retirement-premium', type: 'amount' },
        {
          content: state.form['retirement-benefit']['retirement-premium'] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'retirement-annuity-term' },
        {
          content:
            state.form['retirement-benefit']['retirement-annuity-term'] || '',
        }
      )

      Object.keys(state.form['funeral-cover-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['funeral-cover-options'][field] || '' }
        )
      )

      Object.keys(state.form['rider-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['rider-options'][field] || '' }
        )
      )

      Object.keys(state.form['asset-prevention-benefit']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['asset-prevention-benefit'][field] || '' }
        )
      )

      Object.keys(state.form['benefit-payout-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['benefit-payout-options'][field] || '' }
        )
      )

      //HIDE RIDER OPTIONS IF FUNERAL COVER OPTIONS === "NO OPTION"
      updateItemIn(
        state.page.item,
        { uid: '9b6e574c044bd988', type: 'list' },
        {
          invisible: state.form['funeral-cover-options']['no-option'] === 'Y',
        }
      )
      break

    case 'personal-details':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-employment-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(
              state.page.item,
              { id: field },
              {
                content: state.form[section][field] || '',
              }
            )
          )
        }
      )
      break

    case 'spouse-details':
      ;['spouse-identification', 'spouse-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(
              state.page.item,
              { id: field },
              { content: state.form[section][field] || '' }
            )
          )
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'spouse-is-trustee' },
        { content: state.form['spouse-is-trustee'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'spouse-funeral' },
        { content: state.form['spouse-funeral'] || '' }
      )

      //HIDE ELEMENT IF NO BENEFIT OPTION HAS BEEN SELECTED AND MAIN LIFE === MARRIED
      updateItemIn(
        state.page.item,
        { uid: 'ca82e73a33b2c72b', type: 'list' },
        {
          invisible: state.form['funeral-cover-options']['no-option'] === 'Y',
        }
      )
      updateItemIn(
        state.page.item,
        { uid: '178bc7db0a5b64ba', type: 'html' },
        {
          invisible: state.form['funeral-cover-options']['no-option'] === 'Y',
        }
      )
      updateItemIn(
        state.page.item,
        { uid: '93ad48174469aa41', type: 'list' },
        {
          invisible: state.form['funeral-cover-options']['no-option'] === 'Y',
        }
      )

      //Add Lives to Spouse Details page
      const otherLives = (state.form['additional-lives'] || []).length

      updateItemIn(
        state.page.item,
        { uid: 'c69240fbf3b4768d', type: 'table' },
        {
          invisibile: otherLives < 1,
          content: (state.form['additional-lives'] || []).map(otherLivesItem),
        }
      )

      //Hide life assured button if no lives has been added
      updateItemIn(
        state.page.item,
        { uid: '949a59451e9c6919', type: 'list' },
        {
          invisible: otherLives < 1,
        }
      )
      break

    case 'other-lives-assured':
      const numOtherLives = (state.form['additional-lives'] || []).length

      updateItemIn(
        state.page.item,
        { id: 'other-lives-assured', type: 'table' },
        {
          invisibile: numOtherLives < 1,
          content: (state.form['additional-lives'] || []).map(otherLivesItem),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-constant', uid: '32bfaf422b20744e' },
        { invisible: numOtherLives >= 2 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'add-other-life-assured':
            button.invisible = numOtherLives >= 6
            break
        }
      })
      break

    case 'add-other-lives':
    case 'edit-other-lives':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'beneficiary-details':
      const numBeneficiaries = (state.form['beneficiary-details'] || []).length
      updateItemIn(
        state.page.item,
        { id: 'beneficiary-details', type: 'table' },
        {
          invisible: numBeneficiaries < 1,
          content: (state.form['beneficiary-details'] || []).map(
            beneficiaryItem
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '6c60b1c422ae8373' }, // Add Trustee as beneficiary
        {
          invisible: isTrusteeABeneficiary(state.form) || numBeneficiaries >= 9,
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: 'fdd3e9a355e983ef' }, // Add additional life as beneficiary
        { invisible: numBeneficiaries >= 9 }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '145b199f80e0c8c3' }, // Add a different beneficiary
        { invisible: numBeneficiaries >= 9 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'update-benefit-split-percentage':
            button.invisible = numBeneficiaries < 2
            break
          case 'add-another-beneficiary':
            button.invisible = numBeneficiaries >= 9
            break
          case 'add-trustee':
            button.invisible =
              isTrusteeABeneficiary(state.form) || numBeneficiaries >= 9
            break
        }
      })
      break

    case 'add-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'edit-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'update-beneficiary-split-percentage':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'insurance-arrangements':
      Object.keys(state.form['existing-policies']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['existing-policies'][field] || '' }
        )
      )

      Object.keys(state.form['existing-policy-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['existing-policy-details'][field] || '' }
        )
      )

      //Hide Elements
      updateItemIn(
        state.page.item,
        { uid: '427309bfc272eea5', type: 'list' },
        {
          invisible: !(
            state.form['existing-policies']['existing-coverage'] === 'Y' ||
            state.form['existing-policies']['application-replaces-existing'] ===
              'Y'
          ),
        }
      )
      break

    case 'medical-underwriting':
      Object.keys(state.form['life-assured-general-habits']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-general-habits'][field] || '' }
        )
      )
      ;[
        'planning-other-insurance',
        'other-assurance-past12-months',
        'risky-occupation-hobbies',
        'refused-application-or-increased-premium',
        'insolvency-issue',
        'occupation-not-clerical',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { id: `${field}-details` },
          {
            invisible:
              state.form['life-assured-general-habits'][field] !== 'Yes',
          }
        )
      })
      ;[
        '5f8b25ed4415c897', //  'average-monthly-gross-income-list',
        'beaacad6ef6645ee', // 'total-existing-assurance-cover-in-force-on-your-life-list',
        '91930796f1d9da82', // 'full-reasons-for-effecting-this-additional-cover-list',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { uid: field, type: 'list' },
          {
            invisible:
              state.form['life-assured-general-habits'][
                'cover-exceeds-one-hundred-thousand'
              ] !== 'Yes',
          }
        )
      })

      updateItemIn(
        state.page.item,
        { id: 'years-smoking' },
        { invisible: state.form['life-assured-general-habits'].smoker !== 'Y' }
      )
      updateItemIn(
        state.page.item,
        { id: 'smoking-details', type: 'list' },
        { invisible: state.form['life-assured-general-habits'].smoker !== 'Y' }
      )
      //non smoker
      updateItemIn(
        state.page.item,
        { id: 'years-not-smoking' },
        {
          invisible:
            state.form['life-assured-general-habits']['non-smoker'] !== 'Y',
        }
      )
      break

    case 'medical-declaration':
      Object.keys(state.form['life-assured-health-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-health-details'][field] || '' }
        )
      )
      Object.keys(state.form['medical-attendant-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['medical-attendant-details'][field] || '' }
        )
      )
      Object.keys(state.form['life-assured-medical-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-medical-details'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { uid: '9a5a65cc9d9c54d5' },
        {
          invisible:
            state.form['life-assured-health-details'][
              'family-history-of-illness'
            ] !== 'Yes',
        }
      )
      break

    case 'health-descriptions':
      state.form['health-concerns'].forEach((c, i) =>
        Object.keys(c).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: `${field}-${i + 1}` },
            { content: c[field] || '' }
          )
        )
      )
      break

    case 'payment-details':
      Object.keys(state.form['payment-method-selection']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-method-selection'][field] || '' }
        )
      )
      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )
      //Payment Frequency
      Object.keys(state.form['payment-frequency']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['payment-frequency'][field] || '' }
        )
      )

      //Payer person
      Object.keys(state.form['payer-person']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['payer-person'][field] || '' }
        )
      )

      //Hide radio options when not married
      updateItemIn(
        state.page.item,
        { uid: 'ce35234c9c489acc', type: 'list' },
        {
          invisible:
            state.form['life-assured-identification']['marital-status'] !==
            'Married',
        }
      )
      break

    case 'debit-order-details':
      Object.keys(state.form['payment-bank-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['payment-bank-details'][field] || '' }
        )
      )
      break
    case 'stop-order-details':
      Object.keys(state.form['payment-employment-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['payment-employment-details'][field] || '' }
        )
      )
      break
    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break
  }
}

// Create the state for a new process
const createState = (state) => {
  //create a new process
  state.currentProcess = {}
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  )
  state.currentProcess.step = 'quotation-details' // Current process step
  state.currentProcess.page = pages['quotation-details'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

//processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break
      case 'quotation-details':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              if (state.form['funeral-cover-options']['no-option'] === 'N') {
                state.form['funeral-cover-options']['no-option'] = 'N'
              } else {
                state.form['funeral-cover-options']['no-option'] = 'Y'
              }
              changePage(state, 'additional-benefits')
            }
            break
        }
        break
      case 'additional-benefits':
        switch (button.id) {
          case 'back':
            if (state.form['funeral-cover-options']['no-option'] === 'N') {
              state.form['funeral-cover-options']['no-option'] = 'N'
            } else {
              state.form['funeral-cover-options']['no-option'] = null
            }
            changePage(state, 'quotation-details')
            break
          case 'next':
            if (validate(state)) {
              if (
                state.form['funeral-cover-options']['no-option'] === 'N' &&
                state.form['life-assured-identification']['marital-status'] !==
                  'Married'
              ) {
                changePage(state, 'other-lives-assured')
              } else if (
                state.form['life-assured-identification']['marital-status'] ===
                  'Married' &&
                state.form['funeral-cover-options']['no-option'] === 'Y'
              ) {
                changePage(state, 'spouse-details')
              } else if (
                state.form['life-assured-identification']['marital-status'] !==
                  'Married' &&
                state.form['funeral-cover-options']['no-option'] === 'Y'
              ) {
                changePage(state, 'personal-details')
              } else if (
                state.form['funeral-cover-options']['no-option'] === 'N' &&
                state.form['life-assured-identification']['marital-status'] ===
                  'Married'
              )
                changePage(state, 'spouse-details')
            }
            break
        }
        break

      case 'spouse-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'additional-benefits')
            break
          case 'next':
            if (validate(state)) {
              if (state.form['spouse-is-trustee'] === 'Y') {
                ;['identification', 'contact-details'].forEach((section) =>
                  Object.keys(state.form[`spouse-${section}`]).forEach(
                    (field) =>
                      (state.form[`trustee-${section}`][field] =
                        state.form[`spouse-${section}`][field])
                  )
                )
              }
              changePage(state, 'personal-details')
            }
            break
          case 'add-other-life-assured':
            if (state.form['additional-lives'].length < 6) {
              changePage(state, 'add-other-lives')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
                'role',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break
        }
        break
      case 'other-lives-assured':
        switch (button.id) {
          case 'back':
            changePage(state, 'additional-benefits')
            break
          case 'next':
            changePage(state, 'personal-details')
            break
          case 'add-other-life-assured':
            if (state.form['additional-lives'].length < 6) {
              changePage(state, 'add-other-lives')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
                'role',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break
        }
        break

      case 'add-other-lives':
        switch (button.id) {
          case 'back':
            changePage(
              state,
              state.form['life-assured-identification']['marital-status'] ===
                'Married'
                ? 'spouse-details'
                : 'other-lives-assured'
            )
            break
          case 'next':
            if (validate(state)) {
              if (state.form['additional-lives'].length < 6) {
                state.form['additional-lives'].push({
                  'first-names': state.local['first-names'],
                  surname: state.local.surname,
                  'date-of-birth': state.local['date-of-birth'],
                  role: state.local.role || null,
                  gender: state.local.gender,
                })
              }
              changePage(
                state,
                state.form['life-assured-identification']['marital-status'] ===
                  'Married'
                  ? 'spouse-details'
                  : 'other-lives-assured'
              )
            }
            break
        }
        break

      case 'edit-other-lives':
        switch (button.id) {
          case 'update':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['additional-lives'].length
            ) {
              Object.keys(
                state.form['additional-lives'][state.local.index - 1]
              ).forEach(
                (k) =>
                  (state.form['additional-lives'][state.local.index - 1][k] =
                    state.local[k])
              )
            }
            if (
              state.form['life-assured-identification']['marital-status'] ===
              'Married'
            ) {
              changePage(state, 'spouse-details')
            } else {
              changePage(state, 'other-lives-assured')
            }
            break
          case 'delete-member':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['additional-lives'].length
            ) {
              const old = original(state.form['additional-lives'])
              state.form['additional-lives'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
            }
            if (
              state.form['life-assured-identification']['marital-status'] ===
              'Married'
            ) {
              changePage(state, 'spouse-details')
            } else {
              changePage(state, 'other-lives-assured')
            }
            break
          case 'back':
            if (
              state.form['life-assured-identification']['marital-status'] ===
              'Married'
            ) {
              changePage(state, 'spouse-details')
            } else {
              changePage(state, 'other-lives-assured')
            }
            break
        }
        break

      case 'personal-details':
        switch (button.id) {
          case 'back':
            if (
              state.form['life-assured-identification']['marital-status'] ===
              'Married'
            ) {
              changePage(state, 'spouse-details')
            } else if (
              state.form['life-assured-identification']['marital-status'] !==
                'Married' &&
              state.form['funeral-cover-options']['no-option'] === 'Y'
            ) {
              changePage(state, 'additional-benefits')
            } else if (
              state.form['life-assured-identification']['marital-status'] !==
                'Married' &&
              state.form['funeral-cover-options']['no-option'] === 'N'
            ) {
              changePage(state, 'other-lives-assured')
            }
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'trustee-details')
            }
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              state.form['payer-person']['myself'] = 'Y'
            }
            break
        }
        break

      case 'trustee-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'personal-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'beneficiary-details')
            }
            break
        }
        break

      case 'beneficiary-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'trustee-details')
            break
          case 'next':
            if (state.form['beneficiary-details'].length < 1) {
              changePage(state, 'beneficiary-required')
            } else {
              changePage(state, 'insurance-arrangements')
            }
            break
          case 'add-trustee':
            if (
              state.form['beneficiary-details'].length < 9 &&
              !isTrusteeABeneficiary(original(state.form))
            ) {
              state.form['beneficiary-details'].push({
                'first-names':
                  state.form['trustee-identification']['first-names'],
                surname: state.form['trustee-identification'].surname,
                'benefit-split': null,
                role: 'Cessionary',
                gender: state.form['trustee-identification'].gender,
                'date-of-birth':
                  state.form['trustee-identification']['date-of-birth'],
              })
              updateBeneficiarySplit(state.form)
            }
            break

          case 'add-another-beneficiary':
            if (state.form['beneficiary-details'].length < 9) {
              changePage(state, 'add-beneficiary')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'role',
                'gender',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break

          case 'add-life-assured-as-beneficiary':
            if (state.form['additional-lives'].length < 1) {
              changePage(state, 'additional-life-required')
            } else {
              let btns = state.form['additional-lives'].reduce((b, c, i) => {
                if (
                  !isAddedLivesBeneficiary(c, state.form['beneficiary-details'])
                ) {
                  b.push(createButton(c['first-names'], { index: i }))
                }
                return b
              }, [])
              if (btns.length > 0) {
                changePage(state, 'add-other-lives-as-beneficiary')
                state.page.buttons = btns
              }
            }
            break

          case 'update-benefit-split-percentage':
            changePage(state, 'update-beneficiary-split-percentage')
            state.local = state.form['beneficiary-details'].reduce(
              (l, b, c) => {
                l[`beneficiary-split-${c + 1}`] = b['benefit-split'] || ''
                return l
              },
              {}
            )
            updateItemIn(
              state.page.item,
              { id: 'update-benefit-split-percentage', type: 'list' },
              {
                content:
                  state.form['beneficiary-details'].map(benefitSplitItem),
              }
            )
            break
        }
        break

      case 'add-beneficiary':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'add':
            if (validate(state)) {
              if (state.form['beneficiary-details'].length < 9) {
                state.form['beneficiary-details'].push({
                  'first-names': state.local['first-names'],
                  surname: state.local.surname,
                  'benefit-split': null,
                  role: state.local.role,
                  gender: state.local.gender,
                  'date-of-birth': state.local['date-of-birth'],
                })
                updateBeneficiarySplit(state.form)
              }
              changePage(state, 'beneficiary-details')
            }
            break
        }
        break

      case 'add-other-lives-as-beneficiary':
        if (
          button.index >= 0 &&
          button.index < state.form['additional-lives'].length
        ) {
          state.form['beneficiary-details'].push({
            'first-names':
              state.form['additional-lives'][button.index]['first-names'],
            surname: state.form['additional-lives'][button.index].surname,
            'benefit-split': null,
            role: state.form['additional-lives'][button.index].role,
            gender: state.form['additional-lives'][button.index].gender,
            'date-of-birth':
              state.form['additional-lives'][button.index]['date-of-birth'],
          })
          updateBeneficiarySplit(state.form)
        }
        changePage(state, 'beneficiary-details')
        break

      case 'edit-beneficiary':
        switch (button.id) {
          case 'update':
            if (validate(state)) {
              if (
                state.local.index >= 0 &&
                state.local.index <= state.form['beneficiary-details'].length
              ) {
                Object.keys(
                  state.form['beneficiary-details'][state.local.index - 1]
                ).forEach(
                  (k) =>
                    (state.form['beneficiary-details'][state.local.index - 1][
                      k
                    ] = state.local[k])
                )
              }
              changePage(state, 'beneficiary-details')
            }
            break
          case 'delete-beneficiary':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['beneficiary-details'].length
            ) {
              const old = original(state.form['beneficiary-details'])
              state.form['beneficiary-details'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
              updateBeneficiarySplit(state.form)
            }
            changePage(state, 'beneficiary-details')
            break
        }
        break

      case 'update-beneficiary-split-percentage':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'update':
            if (validate(state)) {
              state.form['beneficiary-details'] = original(
                state.form['beneficiary-details']
              ).map((b, c) => {
                b['benefit-split'] = amountString(
                  parseFloat(state.local[`beneficiary-split-${c + 1}`])
                )
                return b
              })
              changePage(state, 'beneficiary-details')
            }
        }
        break

      case 'beneficiary-required':
        switch (button.id) {
          case 'done':
            changePage(state, 'beneficiary-details')
            break
        }
        break

      case 'additional-life-required':
        switch (button.id) {
          case 'done':
            changePage(state, 'beneficiary-details')
            break
        }
        break

      case 'insurance-arrangements':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'next':
            changePage(state, 'medical-underwriting')
            break
        }
        break
      case 'medical-underwriting':
        switch (button.id) {
          case 'back':
            changePage(state, 'insurance-arrangements')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'medical-declaration')
            }
            break
        }
        break

      case 'medical-declaration':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-underwriting')
            break
          case 'next':
            if (validate(state)) {
              if (healthConcernCount(original(state.form)) > 0) {
                changePage(state, 'health-descriptions')
                createHealthDetailsPage(state)
              } else {
                state.form['health-concerns'].length = 0
                changePage(state, 'payment-details')
              }
            }
            break
        }
        break

      case 'health-descriptions':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-declaration')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details')
            }
            break
        }
        break

      case 'payment-details':
        switch (button.id) {
          case 'back':
            if (healthConcernCount(original(state.form)) > 0) {
              changePage(state, 'health-descriptions')
              createHealthDetailsPage(state)
            } else {
              changePage(state, 'medical-declaration')
            }
            break
          case 'next':
            if (state.form['payer-person']['my-spouse'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['spouse-identification'][field])
              )
              if (
                state.form['payment-method-selection']['debit-order'] === 'Y'
              ) {
                changePage(state, 'debit-order-details')
              } else if (
                state.form['payment-method-selection']['stop-order'] === 'Y'
              ) {
                changePage(state, 'stop-order-details')
              }
            } else if (state.form['payer-person']['myself'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['life-assured-identification'][field])
              )
              if (
                state.form['payment-method-selection']['debit-order'] === 'Y'
              ) {
                changePage(state, 'debit-order-details')
              } else if (
                state.form['payment-method-selection']['stop-order'] === 'Y'
              ) {
                changePage(state, 'stop-order-details')
              }
            }
            break
        }
        break

      case 'debit-order-details':
      case 'stop-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
            break
        }
        break

      case 'upload-documents':
        switch (button.id) {
          case 'upload-id-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
          case 'next':
            changePage(state, 'acceptance-screen')
            generateAcceptancePage(state)
            break
          case 'back':
            if (state.form['payment-method-selection']['debit-order'] === 'Y') {
              changePage(state, 'debit-order-details')
            } else if (
              state.form['payment-method-selection']['stop-order'] === 'Y'
            ) {
              changePage(state, 'stop-order-details')
            }
            state.form['payer-signature'] = null
            break
        }
        break
      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            if (state.form.documents[state.local.document].length > 0) {
              changePage(state, 'acceptance-screen')
              generateAcceptancePage(state)
            } else {
              changePage(state, 'upload-documents')
            }
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'submit':
            if (validate(state)) {
              state.form['application-accepted'] = 'Y'
              state.form['application-acceptance-date'] =
                dayjs().format('YYYYMMDD')
              changePage(state, 'completed')
            }
            break
          case 'back':
            changePage(state, 'upload-documents')
        }
        break
      case 'completed':
        asyncDispatch(completeProcess(state.form['reference-number']))
        break
    }
    setPageItemFromState(state)
  }
}

// Prodct rules say spouse gender is opposite to main life gender :(
const spouseGender = (gender) => {
  switch (gender) {
    case 'Male':
      return 'Female'
    case 'Female':
      return 'Male'
    default:
      return null
  }
}

// processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-details':
      switch (data.id) {
        case 'date-of-birth':
          state.form['life-assured-identification']['date-of-birth'] =
            data.value
          dirty = true
          break
        case 'marital-status':
          state.form['life-assured-identification']['marital-status'] =
            data.value
          dirty = true
          break
        case 'gender':
          state.form['life-assured-identification']['gender'] = data.value
          dirty = true
          break
        case 'benefit-option':
          state.form['benefit-option'] = data.value
          dirty = true
          break
        case 'update-option':
          state.form['premium-updates'] = data.value
          dirty = true
          break
        case 'death-sum-assured':
        case 'total-personal-disability-sum-assured':
        case 'illness-sum-assured':
          state.form['standalone-options'][data.id] = data.value
          dirty = true
          break
        case 'death-and-total-personal-disability-sum-assured':
        case 'death-total-personal-disability-and-illness-sum-assured':
          state.form['accelerated-options'][data.id] = data.value
          dirty = true
          break
      }
      if (data.id === 'marital-status') {
        state.form['spouse-identification'].gender =
          data.value === 'Married'
            ? spouseGender(state.form['life-assured-identification'].gender)
            : null
        state.form['spouse-identification']['marital-status'] =
          data.value === 'Married' ? 'Married' : null
        dirty = true
      } else if (data.id === 'gender') {
        if (
          state.form['life-assured-identification']['marital-status'] ===
          'Married'
        ) {
          state.form['spouse-identification'].gender = spouseGender(data.value)
          state.form['spouse-identification']['marital-status'] = 'Married'
          dirty = true
        }
      }
      break
    case 'additional-benefits':
      switch (data.id) {
        case 'hospitalisation-units':
          state.form['hospitalisation-units'] = data.value
          dirty = true
          break

        case 'accidental-death-units':
        case 'incapacity-units':
          state.form['personal-accident-death-cover'][data.id] = data.value
          dirty = true
          break

        case 'educational-support-premium':
        case 'educational-support-term':
          state.form['educational-support-benefit'][data.id] = data.value
          dirty = true
          break

        case 'retirement-premium':
        case 'retirement-annuity-term':
          state.form['retirement-benefit'][data.id] = data.value
          dirty = true
          break
        case 'executive':
        case 'premier':
        case 'exclusive':
        case 'no-option':
          state.form['funeral-cover-options'][data.id] = data.value
          dirty = true
          break
        case 'memorial':
        case 'pre-burial':
        case 'celebration':
          state.form['rider-options'][data.id] = data.value
          dirty = true
          break
        case 'type-of-asset':
        case 'value-of-asset':
        case 'address-of-asset':
          state.form['asset-prevention-benefit'][data.id] = data.value
          dirty = true
          break
        case 'full-upfront':
        case 'full-investment':
        case 'upfront-and-investment':
          state.form['benefit-payout-options'][data.id] = data.value
          dirty = true
      }
      break
    case 'personal-details':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-employment-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      break

    case 'spouse-details':
      ;['spouse-identification', 'spouse-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      if (data.id === 'spouse-is-trustee') {
        state.form[data.id] = data.value
        dirty = true
      }
      if (data.id === 'spouse-funeral') {
        state.form[data.id] = data.value
        dirty = true
      }
      break
    case 'add-other-lives':
    case 'edit-other-lives':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      break

    case 'add-beneficiary':
    case 'edit-beneficiary':
    case 'update-beneficiary-split-percentage':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'insurance-arrangements':
      if (data.id in state.form['existing-policies']) {
        state.form['existing-policies'][data.id] = data.value
        dirty = true
      }
      if (data.id in state.form['existing-policy-details']) {
        state.form['existing-policy-details'][data.id] = data.value
        dirty = true
      }
      break
    case 'medical-underwriting':
      if (data.id in state.form['life-assured-general-habits']) {
        state.form['life-assured-general-habits'][data.id] = data.value
        dirty = true
      }
      break
    case 'medical-declaration':
      if (data.id in state.form['life-assured-health-details']) {
        state.form['life-assured-health-details'][data.id] = data.value
        dirty = true
      }
      if (data.id in state.form['medical-attendant-details']) {
        state.form['medical-attendant-details'][data.id] = data.value
        dirty = true
      }
      if (data.id in state.form['life-assured-medical-details']) {
        state.form['life-assured-medical-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'health-descriptions':
      let id = data.id.split('-')
      const index = parseInt(id.pop())
      if (!isNaN(index) && index <= state.form['health-concerns'].length) {
        id = id.join('-')
        if (id in state.form['health-concerns'][index - 1]) {
          state.form['health-concerns'][index - 1][id] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break

    case 'payment-details':
      switch (data.id) {
        case 'debit-order':
        case 'stop-order':
          state.form['payment-method-selection'][data.id] = data.value
          dirty = true
          break
        case 'monthly':
        case 'quarterly':
        case 'annual':
        case 'bi-annual':
          state.form['payment-frequency'][data.id] = data.value
          dirty = true
          break
        case 'myself':
        case 'my-spouse':
          state.form['payer-person'][data.id] = data.value
          dirty = true
          break
        case 'payment-start-date':
          const date = dayjs(data.value)
          state.form['payment-start-date'] = date.isValid()
            ? date.format('YYYYMMDD')
            : null
          dirty = true
          break
      }
      break

    case 'debit-order-details':
      if (data.id in state.form['payment-bank-details']) {
        state.form['payment-bank-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break
    case 'stop-order-details':
      if (data.id in state.form['payment-employment-details']) {
        state.form['payment-employment-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break
    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

const processItemClick = (state, data, asyncDispatch) => {
  let dirty = false
  switch (state.step) {
    case 'beneficiary-details':
      if (
        data.index > 0 &&
        data.index <= state.form['beneficiary-details'].length
      ) {
        changePage(state, 'edit-beneficiary')
        state.local = Object.assign(
          {},
          original(state.form['beneficiary-details'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
    case 'other-lives-assured':
      if (
        data.index > 0 &&
        data.index <= state.form['additional-lives'].length
      ) {
        changePage(state, 'edit-other-lives')
        state.local = Object.assign(
          {},
          original(state.form['additional-lives'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
    case 'spouse-details':
      if (
        data.index > 0 &&
        data.index <= state.form['additional-lives'].length
      ) {
        changePage(state, 'edit-other-lives')
        state.local = Object.assign(
          {},
          original(state.form['additional-lives'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

export default {
  createState,
  processButton,
  processChange,
  processItemClick,
}
