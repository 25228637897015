/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import classNames from 'classnames'

const Table = ({ id, label, columns, children, errors }) => (
  <ul
    id={id}
    className={classNames({
      table: true,
      'has-error': errors && errors.length > 0
    })}
  >
    <li className='table-label'>{label}</li>
    <li className='has-table'>
      <table>
        <thead>
          <tr>
            {columns.map((col, i) => (
              <th key={i}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </li>
    {errors && errors.length > 0 && (
      <li className='section-errors'>
        <ul className='error-description'>
          {errors.map((error, i) => (
            <li key={i}>{error}</li>
          ))}
        </ul>
      </li>
    )}
  </ul>
)

export default Table
