/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import React from 'react'
import parse from 'html-react-parser'
import Button from './Button'
import { componentFromDataItem } from '../core/content'

const TemplateItem = ({ dispatch, value }) => (
  <div>
    {parse(value, {
      replace: ({ name, attribs, children }) => {
        if (!attribs) {
          return
        }
        if (attribs.uid) {
          try {
            switch (name) {
              case 'button':
                return (
                  <Button
                    id={attribs.id}
                    uid={attribs.uid}
                    label={attribs.label}
                  />
                )
              case 'dataitem':
                if (children.length > 0) {
                  /* eslint-disable no-eval */
                  const obj = (0, eval)(
                    `(${children.map((c) => c.data).join('')})`
                  )
                  return componentFromDataItem(dispatch, null, obj, attribs.key)
                }
            }
          } catch (err) {
            console.error(`Error parsing template item ${name}`)
            console.error(err)
            console.log(attribs)
            console.log(children)
          }
        }
        return null
      },
    })}
  </div>
)

export default TemplateItem
