/*
 * Enterprise Life offline processes
 */

const PRODUCTS = [
  {
    id: 'educare-plan-enhanced',
    label: 'Educare Plus',
    description:
      "The policy is designed to provide benefit payments, which are intended to cover the education expenses of the policyholder's child. The product is a term policy with both life cover and investment component.",
  },
  {
    id: 'lifetime-needs-plus',
    label: 'Lifetime Needs Plus Plan',
    description:
      "This product is designed to provide the member with the means of contributing to an investment fund which can meet the member's lifetime needs. These needs include child education, retirement (old age expenses), vacation, engagement and wedding as well as housing project.",
  },
  {
    id: 'family-funeral-plan-unlimited',
    label: 'Funeral Finance Plan Unlimited',
    description:
      'The policy provides immediate cash payout to cover funeral expenses of loved ones. The FFP Unlimited is a whole life funeral policy without all the limitations that a traditional Funeral policy has. The policy permits you to cover the people you care about without any maximum limit on age and has other unique features like the Life Swap, no lapse and premium discounts.',
  },
  {
    id: 'transition-plan-enhanced',
    label: 'Transition Plan',
    description:
      'This product is a whole Life assurance policy designed to provide funeral services as policy benefits. This product seeks to encourage early burying of our dearly departed ones and to organize a complete burial package at a very affordable cost.',
  },
  {
    id: 'bloom',
    label: 'Bloom',
    description:
      'This is a female related term assurance product which covers Death, Total Permanent Disability (TPD), Critical Illness (CI) and other Female Related Illness for policyholder.',
  },
  {
    id: 'family-income-security-plan',
    label: 'Family Income Security Plan',
    description:
      'This Policy is designed as an all-inclusive product to meet the death and survival needs of the Policyholder. It provides a basic cover for death, total permanent disability, and critical illness. There are also optional covers for hospitalization, retirement, educational support benefit, asset preservation benefit, funeral cover, and, a personal accident death and disability cover.',
  },
  {
    id: 'funeral-smart-plan',
    label: 'My Smart Plan, Funeral',
    description:
      'This is a whole life policy with no payment term designed to pay out death benefits only in the event of death of the lives assured as well as provide cover for lives covered under the policy in the event that the premium payer dies.',
  },
  {
    id: 'savings-smart-plan',
    label: 'My Smart Plan, Savings',
    description:
      'This solution is designed to support the policyholder to save towards their lifetime needs while securing these needs with cover against death.',
  },
  /*{
    id: 'living-plus-enhanced-plan',
    label: 'Living Plus Enhanced Plan',
    description:
      'This Policy is designed as an all-inclusive product to meer the death and survival needs of the Policyholder. It provides a basic cover for death, total permanent disability, and critical illness. There are also optional covers for hospitalization, retirement, educational support benefit, asset preservation benefit, funeral cover, and, a personal accident death and disability cover.',
  },*/
]

PRODUCTS.forEach((product) => {
  module.exports[product.id] = Object.assign(
    {},
    product,
    require(`./products/${product.id}`).default
  )
})
