/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */

import {
  pages,
  beneficiaryItem,
  benefitSplitItem,
  otherLivesItem,
  acceptancePaymentDetails,
  healthDetailsPrefix,
  healthDetailsItem,
  quotationPanel,
} from './living-plus-enhanced-plan/pages'

import { form } from './living-plus-enhanced-plan/form'
import { cancelProcess } from '../../../src/core/actions'
import shortid from 'shortid'
import {
  updateItemIn,
  findItemsIn,
  createItem,
  createButton,
} from '../../../src/core/dataitems'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import { original } from 'immer'

// Change Page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}

const amountString = (f) =>
  f
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/, '')

// Create the state for a new process
const createState = (state) => {
  //create a new process
  state.currentProcess = {}
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  )
  state.currentProcess.step = 'quotation-details' // Current process step
  state.currentProcess.page = pages['quotation-details'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

const dateFormat = 'D MMMM YYYY'

const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

const isTrusteeABeneficiary = createSelector(
  (form) => form['trustee-identification']['first-names'],
  (form) => form['trustee-identification'].surname,
  (form) => form['trustee-identification']['date-of-birth'],
  (form) => form['beneficiary-details'],
  (fn, sn, dob, bs) =>
    fn &&
    sn &&
    dob &&
    bs.reduce(
      (result, b) =>
        result ||
        (b['first-names'] === fn &&
          b.surname === sn &&
          b['date-of-birth'] === dob),
      false
    )
)

const isLifeABeneficiary = (life, bs) =>
  life['first-names'] &&
  life.surname &&
  life['date-of-birth'] &&
  bs.reduce(
    (result, b) =>
      result ||
      (b['first-names'] === life['first-names'] &&
        b.surname === life.surname &&
        b['date-of-birth'] === life['date-of-birth']),
    false
  )

const updateBeneficiarySplit = createSelector(
  (form) => form['beneficiary-details'],
  (beneficiaries) => {
    if (beneficiaries.length > 0) {
      const split = Math.floor(100 / beneficiaries.length)
      let difference = 100 - split * beneficiaries.length
      for (let i = 0; i < beneficiaries.length; i++) {
        if (difference > 0) {
          beneficiaries[i]['benefit-split'] = amountString(split + 1)
          difference -= 1
        } else {
          beneficiaries[i]['benefit-split'] = amountString(split)
        }
      }
    }
    return beneficiaries
  }
)

const hospitalUnits = {
  150: '1',
  250: '2',
  350: '3',
  500: '4',
  750: '5',
}

const healthConcernCount = (form) =>
  Object.keys(form['life-assured-health-details']).reduce(
    (t, f) =>
      t +
      (form['life-assured-health-details'][f] === 'Y' ||
      form['life-assured-health-details'][f] === 'Yes'
        ? 1
        : 0),
    0
  )

const createHealthDetailsPage = (state) => {
  const maxConcerns = Math.min(4, healthConcernCount(original(state.form)))
  updateItemIn(
    state.page.item,
    { id: 'health-descriptions', type: 'list' },
    {
      content: healthDetailsPrefix
        .concat(healthDetailsItem(maxConcerns))
        .concat([quotationPanel]),
    }
  )
  if (state.form['health-concerns'].length !== maxConcerns) {
    while (state.form['health-concerns'].length > maxConcerns) {
      state.form['health-concerns'].pop()
    }
    for (let i = state.form['health-concerns'].length; i < maxConcerns; i++) {
      state.form['health-concerns'].push({
        complaints: null,
        'complaint-description': null,
        'medical-condition-date': null,
        'health-results': null,
      })
    }
  }
}

//Spouse should be opposite to main life gender
const spouseGender = (gender) => {
  switch (gender) {
    case 'Male':
      return 'Female'
    case 'Female':
      return 'Male'
    default:
      return null
  }
}

const generateAcceptancePage = (state) => {
  //Update the reference number
  updateItemIn(
    state.page.item,
    { id: 'contract-id' },
    { content: state.form['reference-number'] }
  )
  // Update all sections
  Object.keys(state.form).forEach((section) => {
    findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
      Object.keys(state.form[section]).forEach((field) => {
        if (state.form[section][field]) {
          updateItemIn(
            sItem,
            { id: field },
            { content: state.form[section][field] }
          )
        }
      })
    })
  })
  // Add beneficiary details
  updateItemIn(
    state.page.item,
    { id: 'beneficiary-details', type: 'table' },
    { content: (state.form['beneficiary-details'] || []).map(beneficiaryItem) }
  )

  //Add other lives assured
  updateItemIn(
    state.page.item,
    { id: 'other-lives-assured', type: 'table' },
    { content: (state.form['additional-lives'] || []).map(otherLivesItem) }
  )

  // Update the payment details
  let paymentDetails = acceptancePaymentDetails(state.form)
  if (paymentDetails) {
    updateItemIn(
      state.page.item,
      { id: 'payment-details', type: 'list' },
      paymentDetails
    )
  }
}

const setPageItemFromState = (state) => {
  //validate(state, state.local && state.local.failed_validation ? false : true)
  switch (state.step) {
    case 'quotation-details':
      // Date of birth
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
          maxYear: dayjs().year() - 18,
          minYear: dayjs().year() - 100,
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'date-of-birth-spouse' },
        {
          content: state.form['spouse-identification']['date-of-birth'] || '',
        }
      )

      //hide date of birth from spouse when joint life is not selected
      updateItemIn(
        state.page.item,
        { id: 'spouse-details', type: 'list' },
        {
          invisible: !(state.form['cover-details']['joint-life'] === 'Y'),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'marital-status' },
        {
          content:
            state.form['life-assured-identification']['marital-status'] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'gender', type: 'field' },
        { content: state.form['life-assured-identification']['gender'] || '' }
      )

      //Quotation Details
      updateItemIn(
        state.page.item,
        { id: 'benefit-option' },
        { content: state.form['benefit-option'] || '' }
      )

      //Hide Joint Life
      updateItemIn(
        state.page.item,
        { uid: 'ccdd6239258d5381', type: 'list' },
        {
          invisible:
            state.form['life-assured-identification']['marital-status'] !==
            'Married',
        }
      )
      //joint life
      Object.keys(state.form['cover-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['cover-details'][field] || '' }
        )
      )

      // Hide elements when a benefit option has not been chosen yet.
      updateItemIn(
        state.page.item,
        { uid: '17f5a69ffc982802', type: 'list' },
        { invisible: state.form['benefit-option'] !== 'Standalone' }
      )
      updateItemIn(
        state.page.item,
        { uid: '409fdfaa45405865', type: 'list' },
        { invisible: state.form['benefit-option'] !== 'Accelerated' }
      )

      //Standalone
      Object.keys(state.form['standalone-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['standalone-options'][field] || '' }
        )
      )

      //Accelerated
      Object.keys(state.form['accelerated-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['accelerated-options'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { id: 'accelerated-cover-sum-assured' },
        {
          content: state.form['accelerated-cover-sum-assured'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'update-option' },
        { content: state.form['update-option'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'funeral-cover-for-main-life' },
        { content: state.form['funeral-cover-main-life'] || '' }
      )
      break

    case 'additional-benefits':
      updateItemIn(
        state.page.item,
        { id: 'hospitalisation-units', type: 'amount' },
        { content: state.form['hospitalisation-units'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'personal-accident-death', type: 'amount' },
        {
          content:
            state.form['personal-accident-cover']['personal-accident-death'] ||
            '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'personal-accident-disability', type: 'amount' },
        {
          content:
            state.form['personal-accident-cover'][
              'personal-accident-disability'
            ] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'educational-support-premium', type: 'amount' },
        {
          content:
            state.form['educational-support-benefit'][
              'educational-support-premium'
            ] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'educational-support-term', type: 'integer' },
        {
          content:
            state.form['educational-support-benefit'][
              'educational-support-term'
            ] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'educational-support-escalation', type: 'field' },
        {
          content:
            state.form['educational-support-benefit'][
              'educational-support-escalation'
            ] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'retirement-premium', type: 'amount' },
        {
          content: state.form['retirement-benefit']['retirement-premium'] || '',
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'retirement-annuity-term' },
        {
          content:
            state.form['retirement-benefit']['retirement-annuity-term'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'retirement-escalation' },
        {
          content:
            state.form['retirement-benefit']['retirement-escalation'] || '',
        }
      )

      Object.keys(state.form['funeral-cover-options']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['funeral-cover-options'][field] || '' }
        )
      )

      Object.keys(state.form['policy-term']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['policy-term'][field] || '' }
        )
      )

      Object.keys(state.form['optional-benefits']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['optional-benefits'][field] || '' }
        )
      )

      Object.keys(state.form['asset-prevention-benefit']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['asset-prevention-benefit'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { uid: '05036d9fe12ebcbb', type: 'list' },
        { invisible: state.form['funeral-cover-main-life'] !== 'Y' }
      )
      break

    case 'other-lives-assured':
      ;[
        'spouse-identification',
        'spouse-contact-details',
        'spouse-identification-type',
        'spouse-employment-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })

      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content: state.form['spouse-identification']['date-of-birth']
            ? state.form['spouse-identification']['date-of-birth']
            : '',
          readonly:
            state.form['cover-details']['joint-life'] === 'Y' ? true : false,
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'spouse-is-trustee' },
        { content: state.form['spouse-is-trustee'] || '' }
      )

      Object.keys(state.form['policy-term']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['policy-term'][field] || '' }
        )
      )

      //populate other lives tables
      const numOtherLives = (state.form['additional-lives'] || []).length

      updateItemIn(
        state.page.item,
        { id: 'other-lives-assured', type: 'table' },
        {
          invisibile: numOtherLives < 1,
          content: (state.form['additional-lives'] || []).map(otherLivesItem),
        }
      )

      updateItemIn(
        state.page.item,
        { uid: '4278a5faf22ac007' },
        { invisible: numOtherLives < 1 }
      )

      updateItemIn(
        state.page.item,
        { id: 'other-lives-assured', type: 'table' },
        { invisible: numOtherLives < 1 }
      )

      //Hide spouse details if the life assured is unmarried
      updateItemIn(
        state.page.item,
        { uid: '55f3e786b1fea632', type: 'list' },
        {
          invisible: !(
            state.form['life-assured-identification']['marital-status'] ===
            'Married'
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { uid: 'e488880cf163d397', type: 'list' },
        { invisible: numOtherLives < 1 }
      )

      break

    case 'add-other-lives':
    case 'edit-other-lives':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-identification-type',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-residential-address',
        'life-assured-employment-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      break

    case 'beneficiary-details':
      const numBeneficiaries = (state.form['beneficiary-details'] || []).length
      updateItemIn(
        state.page.item,
        { id: 'beneficiary-details', type: 'table' },
        {
          invisible: numBeneficiaries < 1,
          content: (state.form['beneficiary-details'] || []).map(
            beneficiaryItem
          ),
        }
      )

      //ADD TRUSTEE AS BENEFICIARY
      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '6c60b1c422ae8373' },
        {
          invisible: isTrusteeABeneficiary(state.form) || numBeneficiaries >= 5,
        }
      )

      //if an additional life is a beneficiary
      let numValidLives = state.form['additional-lives'].reduce(
        (b, c) =>
          b +
          (isLifeABeneficiary(c, state.form['beneficiary-details']) ? 0 : 1),
        0
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: 'fdd3e9a355e983ef' }, // Add a Life beneficiary
        { invisible: numValidLives == 0 || numBeneficiaries >= 5 }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '145b199f80e0c8c3' }, // Add a different beneficiary
        { invisible: numBeneficiaries >= 5 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'update-benefit-split-percentage':
            button.invisible = numBeneficiaries < 2
            break
          case 'add-another-beneficiary':
            button.invisible = numBeneficiaries >= 5
            break
          case 'add-trustee':
            button.invisible =
              isTrusteeABeneficiary(state.form) || numBeneficiaries >= 5
            break
        }
      })
      break

    case 'add-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'edit-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'update-beneficiary-split-percentage':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(
              state.page.item,
              { id: field },
              {
                content: state.form[section][field] || '',
              }
            )
          )
        }
      )
      break

    case 'medical-underwriting':
      Object.keys(state.form['life-assured-general-habits']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-general-habits'][field] || '' }
        )
      )
      ;[
        'planning-other-insurance',
        'other-assurance-past12-months',
        'risky-occupation-hobbies',
        'refused-application-or-increased-premium',
        'insolvency-issues',
        'occupation-not-clerical',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { id: `${field}-details` },
          {
            invisible:
              state.form['life-assured-general-habits'][field] !== 'Yes',
          }
        )
      })
      ;[
        '5f8b25ed4415c897', //  'average-monthly-gross-income-list',
        'beaacad6ef6645ee', // 'total-existing-assurance-cover-in-force-on-your-life-list',
        '91930796f1d9da82', // 'full-reasons-for-effecting-this-additional-cover-list',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { uid: field, type: 'list' },
          {
            invisible:
              state.form['life-assured-general-habits'][
                'cover-exceeds-one-hundred-thousand'
              ] !== 'Yes',
          }
        )
      })

      updateItemIn(
        state.page.item,
        { id: 'years-smoking' },
        { invisible: state.form['life-assured-general-habits'].smoker !== 'Y' }
      )
      updateItemIn(
        state.page.item,
        { id: 'smoking-details', type: 'list' },
        { invisible: state.form['life-assured-general-habits'].smoker !== 'Y' }
      )
      //non smoker
      updateItemIn(
        state.page.item,
        { id: 'years-not-smoking' },
        {
          invisible:
            state.form['life-assured-general-habits']['non-smoker'] !== 'Y',
        }
      )
      break

    case 'medical-declaration':
      Object.keys(state.form['life-assured-health-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['life-assured-health-details'][field] || '' }
        )
      )
      Object.keys(state.form['medical-attendant-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['medical-attendant-details'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { uid: '9a5a65cc9d9c54d5' },
        {
          invisible:
            state.form['life-assured-health-details'][
              'family-history-of-illness'
            ] !== 'Yes',
        }
      )
      break

    case 'health-descriptions':
      state.form['health-concerns'].forEach((c, i) =>
        Object.keys(c).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: `${field}-${i + 1}` },
            { content: c[field] || '' }
          )
        )
      )
      break

    case 'payment-details':
      Object.keys(state.form['payment-method']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-method'][field] || '' }
        )
      )
      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )
      //Payment Frequency
      Object.keys(state.form['payment-frequency']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-frequency'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { id: 'payer-person', type: 'list' },
        {
          invisible: !(
            state.form['life-assured-identification']['marital-status'] ===
            'Married'
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'myself' },
        { content: state.form['payer-person']['myself'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'my-spouse' },
        { content: state.form['payer-person']['my-spouse'] || '' }
      )
      break

    case 'debit-order-details':
      Object.keys(state.form['payment-bank-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['payment-bank-details'][field] || '' }
        )
      )
      break

    case 'mobile-wallet-details':
      Object.keys(state.form['mobile-wallet-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['mobile-wallet-details'][field] || '' }
        )
      )
      break

    case 'stop-order-details':
      Object.keys(state.form['payment-employment-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['payment-employment-details'][field] || '' }
        )
      )
      break

    case 'upload-signature-page':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break

    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break
  }
}

//processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break
      case 'quotation-details':
        switch (button.id) {
          case 'next':
            changePage(state, 'additional-benefits')
            break
        }
        break
      case 'additional-benefits':
        switch (button.id) {
          case 'next':
            changePage(state, 'other-lives-assured')
            break
          case 'back':
            changePage(state, 'quotation-details')
            break
        }
        break
      case 'other-lives-assured':
        switch (button.id) {
          case 'next':
            if (state.form['spouse-is-trustee'] === 'Y') {
              ;['identification', 'contact-details'].forEach((section) =>
                Object.keys(state.form[`spouse-${section}`]).forEach(
                  (field) =>
                    (state.form[`trustee-${section}`][field] =
                      state.form[`spouse-${section}`][field])
                )
              )
              state.form['trustee-identification']['relationship'] = 'Spouse'
            }
            changePage(state, 'life-assured-identification')
            break
          case 'back':
            changePage(state, 'additional-benefits')
            break
          case 'add-other-life-assured':
            if (state.form['additional-lives'].length < 6) {
              changePage(state, 'add-other-lives')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
                'role',
                'memorial-seven-day',
                'memorial-forty-day',
                'memorial-one-year',
                'cover-benefit',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break
        }
        break

      case 'add-other-lives':
        switch (button.id) {
          case 'next':
            if (state.form['additional-lives'].length < 6) {
              state.form['additional-lives'].push({
                'first-names': state.local['first-names'],
                surname: state.local.surname,
                'date-of-birth': state.local['date-of-birth'],
                role: state.local.role || null,
                gender: state.local.gender,
                'memorial-seven-day': state.local['memorial-seven-day'] || 'N',
                'memorial-forty-day': state.local['memorial-forty-day'] || 'N',
                'memorial-one-year': state.local['memorial-one-year'] || 'N',
                'cover-benefit': state.local['cover-benefit'] || null,
              })
            }
            changePage(state, 'other-lives-assured')
            break
          case 'back':
            changePage(state, 'other-lives-assured')
            break
        }
        break

      case 'edit-other-lives':
        switch (button.id) {
          case 'update':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['additional-lives'].length
            ) {
              Object.keys(
                state.form['additional-lives'][state.local.index - 1]
              ).forEach(
                (k) =>
                  (state.form['additional-lives'][state.local.index - 1][k] =
                    state.local[k])
              )
            }
            changePage(state, 'other-lives-assured')
            break
          case 'delete-member':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['additional-lives'].length
            ) {
              const old = original(state.form['additional-lives'])
              state.form['additional-lives'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
            }
            changePage(state, 'other-lives-assured')
            break
          case 'back':
            changePage(state, 'other-lives-assured')
            break
        }
        break

      case 'life-assured-identification':
        switch (button.id) {
          case 'next':
            changePage(state, 'trustee-details')
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              state.form['payer-person']['myself'] = 'Y'
            }
            break
          case 'back':
            changePage(state, 'other-lives-assured')
            break
        }
        break
      case 'trustee-details':
        switch (button.id) {
          case 'next':
            changePage(state, 'beneficiary-details')
            break
          case 'back':
            changePage(state, 'life-assured-identification')
            break
        }
        break
      case 'beneficiary-details':
        switch (button.id) {
          case 'next':
            changePage(state, 'existing-insurance')
            break
          case 'back':
            changePage(state, 'trustee-details')
            break
          case 'add-another-beneficiary':
            if (state.form['beneficiary-details'].length < 5) {
              changePage(state, 'add-beneficiary')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
                'role',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break
          case 'add-trustee':
            if (
              state.form['beneficiary-details'].length < 6 &&
              !isTrusteeABeneficiary(original(state.form))
            ) {
              state.form['beneficiary-details'].push({
                'first-names':
                  state.form['trustee-identification']['first-names'],
                surname: state.form['trustee-identification'].surname,
                'benefit-split': null,
                role: 'Cessionary',
                'date-of-birth':
                  state.form['trustee-identification']['date-of-birth'],
              })
              updateBeneficiarySplit(state.form)
            }
            break
          case 'add-life-assured-as-beneficiary':
            let btns = state.form['additional-lives'].reduce((b, c, i) => {
              if (!isLifeABeneficiary(c, state.form['beneficiary-details'])) {
                b.push(createButton(c['first-names'], { index: i }))
              }
              return b
            }, [])
            if (btns.length > 0) {
              changePage(state, 'add-life-assured-as-beneficiary')
              state.page.buttons = btns
            }
            break

          case 'update-benefit-split-percentage':
            changePage(state, 'update-beneficiary-split-percentage')
            state.local = state.form['beneficiary-details'].reduce(
              (l, b, c) => {
                l[`beneficiary-split-${c + 1}`] = b['benefit-split'] || ''
                return l
              },
              {}
            )
            updateItemIn(
              state.page.item,
              { id: 'update-benefit-split-percentage', type: 'list' },
              {
                content:
                  state.form['beneficiary-details'].map(benefitSplitItem),
              }
            )
            break
        }
        break

      case 'update-beneficiary-split-percentage':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'update':
            state.form['beneficiary-details'] = original(
              state.form['beneficiary-details']
            ).map((b, c) => {
              b['benefit-split'] = amountString(
                parseFloat(state.local[`beneficiary-split-${c + 1}`])
              )
              return b
            })
            changePage(state, 'beneficiary-details')
        }
        break

      case 'add-beneficiary':
        switch (button.id) {
          case 'add':
            if (state.form['beneficiary-details'].length < 5) {
              state.form['beneficiary-details'].push({
                'first-names': state.local['first-names'],
                surname: state.local.surname,
                'benefit-split': null,
                role: state.local.role,
                gender: state.local.gender,
                'date-of-birth': state.local['date-of-birth'],
              })
              updateBeneficiarySplit(state.form)
            }
            changePage(state, 'beneficiary-details')
            break
          case 'back':
            changePage(state, 'beneficiary-details')
            break
        }
        break

      case 'edit-beneficiary':
        switch (button.id) {
          case 'update':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['beneficiary-details'].length
            ) {
              Object.keys(
                original(
                  state.form['beneficiary-details'][state.local.index - 1]
                )
              ).forEach(
                (k) =>
                  (state.form['beneficiary-details'][state.local.index - 1][k] =
                    state.local[k])
              )
            }
            changePage(state, 'beneficiary-details')
            break
          case 'delete-beneficiary':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['beneficiary-details'].length
            ) {
              const old = original(state.form['beneficiary-details'])
              state.form['beneficiary-details'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
            }
            changePage(state, 'beneficiary-details')
            break
        }
        break

      case 'add-life-assured-as-beneficiary':
        if (
          button.index >= 0 &&
          button.index < state.form['additional-lives'].length
        ) {
          state.form['beneficiary-details'].push({
            'first-names':
              state.form['additional-lives'][button.index]['first-names'],
            surname: state.form['additional-lives'][button.index].surname,
            'benefit-split': null,
            role: state.form['additional-lives'][button.index].relationship,
            gender: state.form['additional-lives'][button.index].gender,
            'date-of-birth':
              state.form['additional-lives'][button.index]['date-of-birth'],
          })
          updateBeneficiarySplit(state.form)
        }
        changePage(state, 'beneficiary-details')
        break

      case 'existing-insurance':
        switch (button.id) {
          case 'next':
            changePage(state, 'medical-underwriting')
            break
          case 'back':
            changePage(state, 'beneficiary-details')
            break
        }
        break
      case 'medical-underwriting':
        switch (button.id) {
          case 'next':
            changePage(state, 'medical-declaration')
            break
          case 'back':
            changePage(state, 'existing-insurance')
            break
        }
        break
      case 'medical-declaration':
        switch (button.id) {
          case 'next':
            if (healthConcernCount(original(state.form)) > 0) {
              changePage(state, 'health-descriptions')
              createHealthDetailsPage(state)
            } else {
              state.form['health-concerns'].length = 0
              changePage(state, 'payment-details')
            }
            break
          case 'back':
            changePage(state, 'medical-underwriting')
            break
        }
        break

      case 'health-descriptions':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-declaration')
            break
          case 'next':
            changePage(state, 'payment-details')
            break
        }
        break

      case 'payment-details':
        switch (button.id) {
          case 'next':
            if (state.form['payer-person']['my-spouse'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['spouse-identification'][field])
              )
              if (state.form['payment-method']['debit-order'] === 'Y') {
                changePage(state, 'debit-order-details')
              } else if (state.form['payment-method']['stop-order'] === 'Y') {
                changePage(state, 'stop-order-details')
              } else if (
                state.form['payment-method']['mobile-wallet'] === 'Y'
              ) {
                changePage(state, 'mobile-wallet-details')
              }
            } else if (state.form['payer-person']['myself'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['life-assured-identification'][field])
              )
              if (state.form['payment-method']['debit-order'] === 'Y') {
                changePage(state, 'debit-order-details')
              } else if (state.form['payment-method']['stop-order'] === 'Y') {
                changePage(state, 'stop-order-details')
              } else if (
                state.form['payment-method']['mobile-wallet'] === 'Y'
              ) {
                changePage(state, 'mobile-wallet-details')
              }
            }
            break
          case 'back':
            changePage(state, 'medical-declaration')
            break
        }
        break

      case 'debit-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            changePage(state, 'payment-details-verification')
            generateAcceptancePage(state)
        }
        break

      case 'stop-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            changePage(state, 'payment-details-verification')
            generateAcceptancePage(state)
        }
        break

      case 'mobile-wallet-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            changePage(state, 'payment-details-verification')
            generateAcceptancePage(state)
        }
        break

      case 'payment-details-verification':
        switch (button.id) {
          case 'back':
            if (state.form['payment-method']['debit-order'] === 'Y') {
              changePage(state, 'debit-order-details')
            } else if (state.form['payment-method']['stop-order'] === 'Y') {
              changePage(state, 'stop-order-details')
            } else if (state.form['payment-method']['mobile-wallet'] === 'Y') {
              changePage(state, 'mobile-wallet-details')
            }
            state.form['payer-signature'] = null
            break
          case 'next':
            changePage(state, 'upload-documents')
            break
          case 'upload-signature':
            changePage(state, 'disclaimer-warning')
            break
        }
        break

      case 'disclaimer-warning':
        switch (button.id) {
          case 'okay':
            changePage(state, 'upload-signature-page')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.signaturedocument[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
        }
        break

      case 'upload-signature-page':
        switch (button.id) {
          case 'continue':
            state.form.signaturedocument[state.local.document] =
              state.local.pages || []
            if (state.form.signaturedocument[state.local.document].length > 0) {
              changePage(state, 'upload-documents')
            } else {
              changePage(state, 'payment-details-verification')
            }
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'upload-documents':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details-verification')
            break
          case 'next':
            changePage(state, 'acceptance-screen')
            generateAcceptancePage(state)
            break
          case 'upload-id-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
        }
        break

      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            if (state.form.documents[state.local.document].length > 0) {
              changePage(state, 'acceptance-screen')
              generateAcceptancePage(state)
            } else {
              changePage(state, 'upload-documents')
            }
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'back':
            changePage(state, 'upload-documents')
            break
          case 'submit':
            state.form['application-accepted'] = 'Y'
            state.form['application-acceptance-date'] =
              dayjs().format('YYYYMMDD')
            changePage(state, 'completed')
            break
        }
        break
    }
    setPageItemFromState(state)
  }
}

// processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-details':
      switch (data.id) {
        case 'date-of-birth':
          state.form['life-assured-identification']['date-of-birth'] =
            data.value
          dirty = true
          break
        case 'marital-status':
          state.form['life-assured-identification']['marital-status'] =
            data.value
          dirty = true
          break
        case 'gender':
          state.form['life-assured-identification']['gender'] = data.value
          dirty = true
          break
        case 'joint-life':
        case 'main-life-only':
          state.form['cover-details'][data.id] = data.value
          dirty = true
          break
        case 'date-of-birth-spouse':
          state.form['spouse-identification']['date-of-birth'] = data.value
          dirty = true
          break
        case 'benefit-option':
          state.form['benefit-option'] = data.value
          dirty = true
          break
        case 'death-sum-assured':
        case 'total-personal-disability-sum-assured':
        case 'illness-sum-assured':
          state.form['standalone-options'][data.id] = data.value
          dirty = true
          break
        case 'death-and-total-personal-disability':
        case 'death-and-critical-illness':
        case 'death-and-total-disability-and-critical-illness':
          state.form['accelerated-options'][data.id] = data.value
          dirty = true
          break
        case 'accelerated-cover-sum-assured':
          state.form['accelerated-cover-sum-assured'] = data.value
          dirty = true
          break
        case 'funeral-cover-for-main-life':
          state.form['funeral-cover-main-life'] = data.value
          dirty = true
          break
        case 'update-option':
          state.form['update-option'] = data.value
          dirty = true
          break
      }
      if (data.id === 'marital-status') {
        state.form['spouse-identification'].gender =
          data.value === 'Married'
            ? spouseGender(state.form['life-assured-identification'].gender)
            : null
        state.form['spouse-identification']['marital-status'] =
          data.value === 'Married' ? 'Married' : null
        dirty = true
      } else if (data.id === 'gender') {
        if (
          state.form['life-assured-identification']['marital-status'] ===
          'Married'
        ) {
          state.form['spouse-identification'].gender = spouseGender(data.value)
          state.form['spouse-identification']['marital-status'] = 'Married'
          dirty = true
        }
      }
      break
    case 'additional-benefits':
      switch (data.id) {
        case 'hospitalisation-units':
          state.form['hospitalisation-units'] = data.value
          dirty = true
          break
        case 'personal-accident-death':
        case 'personal-accident-disability':
          state.form['personal-accident-cover'][data.id] = data.value
          dirty = true
          break

        case 'educational-support-premium':
        case 'educational-support-term':
        case 'educational-support-escalation':
          state.form['educational-support-benefit'][data.id] = data.value
          dirty = true
          break

        case 'retirement-premium':
        case 'retirement-annuity-term':
        case 'retirement-escalation':
          state.form['retirement-benefit'][data.id] = data.value
          dirty = true
          break
        case 'basic-ghs10000':
        case 'executive-ghs20000':
        case 'premier-ghs30000':
        case 'exclusive-ghs40000':
        case 'platinum-ghs50000':
          state.form['funeral-cover-options'][data.id] = data.value
          dirty = true
          break
        case 'term-ten':
        case 'term-fifteen':
          state.form['policy-term'][data.id] = data.value
          dirty = true
          break

        case 'memorial-seven-day':
        case 'memorial-forty-day':
        case 'memorial-one-year':
        case 'double-accident-benefit':
          state.form['optional-benefits'][data.id] = data.value
          dirty = true
          break

        case 'type-of-asset':
        case 'value-of-asset':
        case 'address-of-asset':
          state.form['asset-prevention-benefit'][data.id] = data.value
          dirty = true
          break
      }
      break

    case 'other-lives-assured':
      ;[
        'spouse-identification',
        'spouse-contact-details',
        'spouse-identification-type',
        'spouse-employment-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      if (data.id === 'spouse-is-trustee') {
        state.form[data.id] = data.value
        dirty = true
      }
      break

    case 'add-other-lives':
    case 'edit-other-lives':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-identification-type',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-residential-address',
        'life-assured-employment-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      break

    case 'add-beneficiary':
    case 'edit-beneficiary':
    case 'update-beneficiary-split-percentage':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'medical-underwriting':
      if (data.id in state.form['life-assured-general-habits']) {
        state.form['life-assured-general-habits'][data.id] = data.value
        dirty = true
      }
      break
    case 'medical-declaration':
      if (data.id in state.form['life-assured-health-details']) {
        state.form['life-assured-health-details'][data.id] = data.value
        dirty = true
      }
      if (data.id in state.form['medical-attendant-details']) {
        state.form['medical-attendant-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'health-descriptions':
      let id = data.id.split('-')
      const index = parseInt(id.pop())
      if (!isNaN(index) && index <= state.form['health-concerns'].length) {
        id = id.join('-')
        if (id in state.form['health-concerns'][index - 1]) {
          state.form['health-concerns'][index - 1][id] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break

    case 'payment-details':
      switch (data.id) {
        case 'debit-order':
        case 'stop-order':
        case 'mobile-wallet':
          state.form['payment-method'][data.id] = data.value
          dirty = true
          break
        case 'monthly':
        case 'annual':
        case 'bi-annual':
        case 'quarterly':
          state.form['payment-frequency'][data.id] = data.value
          dirty = true
          break
        case 'payment-start-date':
          const date = dayjs(data.value)
          state.form['payment-start-date'] = date.isValid()
            ? date.format('YYYYMMDD')
            : null
          dirty = true
          break
        case 'myself':
        case 'my-spouse':
          state.form['payer-person'][data.id] = data.value
          dirty = true
          break
      }
      break

    case 'debit-order-details':
      if (data.id in state.form['payment-bank-details']) {
        state.form['payment-bank-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'mobile-wallet-details':
      if (data.id in state.form['mobile-wallet-details']) {
        state.form['mobile-wallet-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'stop-order-details':
      if (data.id in state.form['payment-employment-details']) {
        state.form['payment-employment-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break

    case 'upload-signature-page':
      const signdoc = data.id.match(/^upload-(\d+)$/)
      if (signdoc) {
        if (signdoc <= state.local.pages.length) {
          state.local.pages[signdoc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

const processItemClick = (state, data, asyncDispatch) => {
  let dirty = false
  switch (state.step) {
    case 'beneficiary-details':
      if (
        data.index > 0 &&
        data.index <= state.form['beneficiary-details'].length
      ) {
        changePage(state, 'edit-beneficiary')
        state.local = Object.assign(
          {},
          original(state.form['beneficiary-details'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
    case 'other-lives-assured':
      if (
        data.index > 0 &&
        data.index <= state.form['additional-lives'].length
      ) {
        changePage(state, 'edit-other-lives')
        state.local = Object.assign(
          {},
          original(state.form['additional-lives'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

export default {
  createState,
  processButton,
  processChange,
  processItemClick,
}
