/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import {
  hasValue,
  verify,
  validEmail,
  validAmountRange,
  validMinAmount,
} from '../../../../src/core/validations'
import { totalPremium } from '../family-income-security-plan'

import {
  validNHIS,
  validVotersID,
  validSSNIT,
  validPassport,
  validDrivers,
  validID,
  validName,
  validCellnumber,
  onlyDigits,
} from '../../validations'
import { updateItemIn } from '../../../../src/core/dataitems'

import dayjs from 'dayjs'
const now = dayjs()

export const validate = (state, requiredOnly, validateOnly) => {
  if (process.env.DISABLE_VALIDATIONS === 'true') {
    return true
  }

  let validates = true
  switch (state.step) {
    case 'quotation-page':
      validates =
        verify(
          state,
          state.form['life-assured-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 18 && dayjs().diff(v, 'year') <= 71)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      //Accelerated age boundaries
      let term = parseInt(state.form['term-in-years'])
      if (
        (state.form['accelerated-options'][
          'death-and-total-personal-disability'
        ] === 'Y' &&
          term < 11) ||
        (state.form['accelerated-options'][
          'death-and-total-disability-and-critical-illness'
        ] === 'Y' &&
          term < 11)
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['date-of-birth'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                (dayjs().diff(v, 'year') >= 18 &&
                  dayjs().diff(v, 'year') <= 54)),
            !validateOnly && { id: 'date-of-birth' },
            requiredOnly
              ? ['*']
              : ['The age is outside the allowable bounds for this product.']
          ) && validates
      }

      //Standalone
      if (
        state.form['standalone-options'][
          'total-personal-disability-sum-assured'
        ] &&
        term < 11
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['date-of-birth'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                (dayjs().diff(v, 'year') >= 18 &&
                  dayjs().diff(v, 'year') <= 54)),
            !validateOnly && { id: 'date-of-birth' },
            requiredOnly
              ? ['*']
              : ['The age is outside the allowable bounds for this product.']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['life-assured-identification']['gender'],
          hasValue,
          !validateOnly && { id: 'gender' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['marital-status'],
          hasValue,
          !validateOnly && { id: 'marital-status' },
          ['*']
        ) && validates

      if (state.form['covered-details']['joint-life'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['date-of-birth'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                (dayjs().diff(v, 'year') >= 18 &&
                  dayjs().diff(v, 'year') <= 70)),
            !validateOnly && { id: 'date-of-birth-spouse' },
            requiredOnly
              ? ['*']
              : ['The age is outside the allowable bounds for this product.']
          ) && validates
      }

      //TERM IN YEARS
      let personaldisability =
        state.form['accelerated-options']['death-and-total-personal-disability']
      let disabilityillness =
        state.form['accelerated-options'][
          'death-and-total-disability-and-critical-illness'
        ]
      //let criticalillness = state.form['accelerated-options']['death-and-critical-illness']
      let standalonedD = state.form['standalone-options']['death-sum-assured']
      let standalonePF =
        state.form['standalone-options'][
          'total-personal-disability-sum-assured'
        ]
      let standaloneI = state.form['standalone-options']['illness-sum-assured']
      let ceaseage =
        personaldisability === 'Y' ||
        disabilityillness === 'Y' ||
        standalonedD > 0 ||
        standalonePF > 0 ||
        standaloneI > 0
          ? 60
          : 60 //{Task 3469 update}
      let maxTerm =
        ceaseage -
        dayjs().diff(
          state.form['life-assured-identification']['date-of-birth'],
          'year'
        )
      if (maxTerm < 10) {
        maxTerm = 10
      }
      validates =
        verify(
          state,
          state.form['term-in-years'],
          hasValue,
          !validateOnly && { id: 'term-in-years' },
          ['*']
        ) && validates

      if (validates && !requiredOnly) {
        validates =
          verify(
            state,
            state.form['term-in-years'],
            (v) => !hasValue(v) || term <= maxTerm,
            !validateOnly && { id: 'term-in-years' },
            [
              `The policy cannot be active after your ${ceaseage}-th birth-date. Please select a term that is less than ${maxTerm}`,
            ]
          ) && validates
      }

      //benefit-option
      validates =
        verify(
          state,
          state.form['benefit-option'],
          hasValue,
          !validateOnly && { id: 'benefit-option' },
          ['*']
        ) && validates

      if (state.form['benefit-option'] === 'Standalone') {
        if (state.form['standalone-options']['death-sum-assured']) {
          validates =
            verify(
              state,
              state.form['standalone-options']['death-sum-assured'],
              (v) =>
                requiredOnly
                  ? hasValue(v)
                  : validAmountRange(v, 100000, 500000),
              !validateOnly && { id: 'death-sum-assured' },
              requiredOnly
                ? ['*']
                : [
                    'Sum Assured for each option must be at least GHc 100,000.00',
                  ]
            ) && validates
        }
        if (
          state.form['standalone-options'][
            'total-personal-disability-sum-assured'
          ]
        ) {
          validates =
            verify(
              state,
              state.form['standalone-options'][
                'total-personal-disability-sum-assured'
              ],
              (v) =>
                requiredOnly
                  ? hasValue(v)
                  : validAmountRange(v, 100000, 500000),
              !validateOnly && { id: 'total-personal-disability-sum-assured' },
              requiredOnly
                ? ['*']
                : [
                    'Sum Assured for each option must be at least GHc 100,000.00',
                  ]
            ) && validates
        }
        if (state.form['standalone-options']['illness-sum-assured']) {
          validates =
            verify(
              state,
              state.form['standalone-options']['illness-sum-assured'],
              (v) =>
                requiredOnly
                  ? hasValue(v)
                  : validAmountRange(v, 100000, 500000),
              !validateOnly && { id: 'illness-sum-assured' },
              requiredOnly
                ? ['*']
                : [
                    'Sum Assured for each option must be at least GHc 100,000.00',
                  ]
            ) && validates
        }

        //Validate the fields of standalone, at least one field should have a value
        if (
          state.form['standalone-options']['illness-sum-assured'] === null ||
          state.form['standalone-options'][
            'total-personal-disability-sum-assured'
          ] === null ||
          state.form['standalone-options']['death-sum-assured'] === null ||
          state.form['standalone-options']['illness-sum-assured'] === '' ||
          state.form['standalone-options'][
            'total-personal-disability-sum-assured'
          ] === '' ||
          state.form['standalone-options']['death-sum-assured'] === ''
        ) {
          validates =
            verify(
              state,
              state.form['standalone-options']['illness-sum-assured'] ||
                state.form['standalone-options'][
                  'total-personal-disability-sum-assured'
                ] ||
                state.form['standalone-options']['death-sum-assured'],
              (v) => (requiredOnly ? hasValue(v) : hasValue(v)),
              !validateOnly && { id: 'illness-sum-assured' },
              requiredOnly
                ? ['*']
                : [
                    'Please choose at least one standalone benefit option and indicate your required benefit rate for that option',
                  ]
            ) && validates
        }

        //if standalone is chosen and more than one sum assured is added to the option then validate the total sum assured
        if (
          state.form['standalone-options']['illness-sum-assured'] !== 0 ||
          state.form['standalone-options'][
            'total-personal-disability-sum-assured'
          ] !== 0 ||
          state.form['standalone-options']['death-sum-assured'] !== 0
        ) {
          let death = !isNaN(
            parseInt(state.form['standalone-options']['death-sum-assured'])
          )
            ? parseInt(state.form['standalone-options']['death-sum-assured'])
            : 0
          let disability = !isNaN(
            parseInt(
              state.form['standalone-options'][
                'total-personal-disability-sum-assured'
              ]
            )
          )
            ? parseInt(
                state.form['standalone-options'][
                  'total-personal-disability-sum-assured'
                ]
              )
            : 0
          let illness = !isNaN(
            parseInt(state.form['standalone-options']['illness-sum-assured'])
          )
            ? parseInt(state.form['standalone-options']['illness-sum-assured'])
            : 0
          let totalsumassured = death + disability + illness
          validates =
            verify(
              state,
              state.form['standalone-options']['illness-sum-assured'] ||
                state.form['standalone-options'][
                  'total-personal-disability-sum-assured'
                ] ||
                state.form['standalone-options']['death-sum-assured'],
              (v) =>
                !hasValue(v) ||
                validAmountRange(totalsumassured, 100000, 500000),
              !validateOnly && { id: 'death-sum-assured' },
              [
                'Your maximum total sum assured (the sum of all your standalone options) must not be more than GHc 500,000.',
              ]
            ) && validates
        }
      }

      if (state.form['benefit-option'] === 'Accelerated') {
        validates =
          verify(
            state,
            state.form['accelerated-options'][
              'death-and-total-personal-disability'
            ] ||
              state.form['accelerated-options']['death-and-critical-illness'] ||
              state.form['accelerated-options'][
                'death-and-total-disability-and-critical-illness'
              ],
            hasValue,
            !validateOnly && { id: 'accelerated-options-radio-group' },
            ['Please choose an accelerated benefit option']
          ) && validates

        validates =
          verify(
            state,
            state.form['accelerated-cover-sum-assured'],
            (v) =>
              requiredOnly ? hasValue(v) : validAmountRange(v, 100000, 500000),
            !validateOnly && { id: 'accelerated-cover-sum-assured' },
            requiredOnly
              ? ['*']
              : [
                  'Sum Assured must be at least GHc 100,000.00 and Maximum Sum Assured is GHc 500000',
                ]
          ) && validates
      }
      break

    case 'additional-benefits':
      if (
        state.form['hospitilization-cover-main-life-only'] === 'Y' ||
        state.form['hospitilization-cover-joint-life'] === 'Y'
      ) {
        validates =
          verify(
            state,
            state.form['hospitalisation-units'],
            hasValue,
            !validateOnly && { id: 'hospitalisation-units' },
            ['*']
          ) && validates
      }

      if (
        state.form['educational-support-benefit']['educational-support-benefit']
      ) {
        validates =
          verify(
            state,
            state.form['educational-support-benefit'][
              'educational-support-benefit'
            ],
            (v) =>
              requiredOnly ? hasValue(v) : validAmountRange(v, 0, 100000),
            !validateOnly && { id: 'educational-support-benefit' },
            requiredOnly ? ['*'] : ['The maximum benefit is GHc 100000.00']
          ) && validates

        validates =
          verify(
            state,
            state.form['educational-support-benefit'][
              'educational-support-term'
            ],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v)),
            !validateOnly && { id: 'educational-support-term' },
            requiredOnly
              ? ['*']
              : ['You have to specify the educational support term.']
          ) && validates
      }

      if (
        state.form['educational-support-benefit']['educational-support-term']
      ) {
        validates =
          verify(
            state,
            state.form['educational-support-benefit'][
              'educational-support-term'
            ],
            (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 5)),
            !validateOnly && { id: 'educational-support-term' },
            requiredOnly ? ['*'] : ['The minimum term is 5 years']
          ) && validates
      }

      let mainterm = parseInt(state.form['term-in-years'])
      let benefitterm = parseInt(
        state.form['educational-support-benefit']['educational-support-term']
      )
      let policyterm =
        state.form['policy-term']['term-ten'] === 'Y'
          ? parseInt('10')
          : parseInt('15')

      if (validates && !requiredOnly) {
        validates =
          verify(
            state,
            state.form['educational-support-benefit'][
              'educational-support-term'
            ],
            (v) => !hasValue(v) || benefitterm <= mainterm,
            !validateOnly && { id: 'educational-support-term' },
            ['The Edusaver term cannot be longer than the policy term.']
          ) && validates
      }

      //Retirement premium
      if (state.form['retirement-premium']) {
        validates =
          verify(
            state,
            state.form['retirement-premium'],
            (v) =>
              requiredOnly ? hasValue(v) : validAmountRange(v, 200, 20000),
            !validateOnly && { id: 'retirement-premium' },
            requiredOnly
              ? ['*']
              : [
                  'Please make sure that your premium amount is in the range of GHc 200.00 - Ghc 20000.00',
                ]
          ) && validates
      }

      if (validates && !requiredOnly) {
        if (state.form['funeral-cover-for-main-life'] === 'Y') {
          validates =
            verify(
              state,
              state.form['policy-term']['term-fifteen'] ||
                state.form['policy-term']['term-ten'],
              (v) => !hasValue(v) || policyterm <= mainterm,
              !validateOnly && { id: 'funeral-options' },
              [
                `The Funeral term selected as ${policyterm} cannot be longer than the policy term in years ${mainterm}`,
              ]
            ) && validates
        }
      }

      const prem = totalPremium(state.form)
      let cashb = parseFloat(state.form['cash-bonus'])
      let quotedPrem = prem - cashb
      if (cashb < 20) {
        validates =
          verify(
            state,
            state.form['cash-bonus'],
            (v) => (requiredOnly ? hasValue(v) : validMinAmount(v, 20)),
            !validateOnly && { id: 'cash-bonus', type: 'amount' },
            requiredOnly ? ['*'] : ['The minimum amount is GHc 20.00']
          ) && validates
      } else if (cashb > quotedPrem) {
        validates =
          verify(
            state,
            state.form['cash-bonus'],
            (v) => !hasValue(v) || validAmountRange(cashb, 20, quotedPrem),
            !validateOnly && { id: 'cash-bonus', type: 'amount' },
            [`The maximum amount is GH¢ ${parseFloat(quotedPrem)}`]
          ) && validates
      }

      //FUNERAL OPTIONS VALIDATIONS
      if (state.form['funeral-cover-for-main-life'] === 'Y') {
        validates =
          verify(
            state,
            state.form['policy-term']['term-ten'] ||
              state.form['policy-term']['term-fifteen'],
            hasValue,
            !validateOnly && { id: 'policy-term-radio-group' },
            ['*']
          ) && validates
      }
      break

    case 'quotation-family':
      if (state.form['lives-assured'].length > 0) {
        validates =
          verify(
            state,
            state.form['policy-term']['term-ten'] ||
              state.form['policy-term']['term-fifteen'],
            hasValue,
            !validateOnly && { id: 'policy-term-radio-group' },
            ['*']
          ) && validates
      }

      let mainT = parseInt(state.form['term-in-years'])
      let policyT =
        state.form['policy-term']['term-ten'] === 'Y'
          ? parseInt('10')
          : parseInt('15')

      if (validates && !requiredOnly) {
        validates =
          verify(
            state,
            state.form['policy-term']['term-fifteen'] ||
              state.form['policy-term']['term-ten'],
            (v) => !hasValue(v) || policyT <= mainT,
            !validateOnly && { id: 'policy-term-radio-group' },
            [
              `The Funeral term selected as ${policyT} cannot be longer than the policy term in years ${mainT}`,
            ]
          ) && validates
      }
      break

    case 'family-members-age':
      //Validate the gender & relationship
      if (
        [
          'Mother',
          'Mother in law',
          'Grandmother',
          'Step mother',
          'Sister',
        ].indexOf(state.local.relationship) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly || ['Female'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (
        [
          'Father',
          'Father in law',
          'Grandfather',
          'Step father',
          'Brother',
        ].indexOf(state.local.relationship) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly || ['Male'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (
        [
          'Father',
          'Father in law',
          'Grandfather',
          'Step father',
          'Brother',
        ].indexOf(state.local.relationship) >= 0
      ) {
        validates =
          verify(
            state,
            state.local.gender,
            (v) =>
              hasValue(v) &&
              (requiredOnly || ['Male'].indexOf(state.local.gender) >= 0),
            !validateOnly && { id: 'gender' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      validates =
        verify(
          state,
          state.local && state.local['age-of-member'],
          hasValue,
          !validateOnly && { id: 'age-of-member' },
          ['*']
        ) && validates

      //validate this field (either funeral cover or free baby benefit field must be selected) can you select both? -- TO DO
      if (
        state.local['funeral-cover'] !== 'Y' ||
        state.local['free-child-cover'] !== 'Y' ||
        state.local['old-age-cover'] !== 'Y'
      ) {
        validates =
          verify(
            state,
            state.local &&
              (state.local['funeral-cover'] ||
                state.local['free-child-cover'] ||
                state.local['old-age-cover']),
            hasValue,
            !validateOnly && { id: 'funeral-cover' },
            ['At least one benefit option must be chosen']
          ) && validates
      }
      break

    case 'family-members-quotation-details':
      //validate this field only when "free child cover" has not been selected
      if (
        state.local['funeral-cover'] === 'Y' ||
        state.local['free-child-cover'] === 'N'
      ) {
        validates =
          verify(
            state,
            state.local && state.local['funeral-cover-benefit'],
            hasValue,
            !validateOnly && { id: 'funeral-cover-benefit' },
            ['*']
          ) && validates
      }

      if (state.local['old-age-cover'] === 'Y') {
        validates =
          verify(
            state,
            state.local && state.local['old-age-cover-benefit'],
            (v) => (requiredOnly ? hasValue(v) : validAmountRange(v, 1, 50000)),
            !validateOnly && { id: 'old-age-cover-benefit' },
            requiredOnly
              ? ['*']
              : [
                  'Please enter an Old Age Cover amount of GHc 50,000.00 or less',
                ]
          ) && validates
      }
      break

    case 'life-assured-identification':
      validates = Object.keys(state.form['life-assured-identification'])
        .filter((k) => ['ghanaian', 'other-nationality'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      //Validate Field Names to only be letters
      validates =
        verify(
          state,
          state.form['life-assured-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      //Validate the gender & title
      if (state.form['life-assured-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (
        state.form['life-assured-identification']['other-nationality'] === null
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification'].ghanaian,
            hasValue,
            !validateOnly && { id: 'ghanaian' },
            ['*']
          ) && validates
      }
      validates =
        verify(
          state,
          state.form['life-assured-identification']['other-nationality'],
          (v) =>
            hasValue(v) ||
            (validates &&
              state.form['life-assured-identification'].ghanaian === 'Y'),
          !validateOnly && { id: 'other-nationality' },
          requiredOnly
            ? ['*']
            : ['If you are not a ghanaian please select your nationality']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification-type'].dvla ||
            state.form['life-assured-identification-type'].passport ||
            state.form['life-assured-identification-type']['voter-id'] ||
            state.form['life-assured-identification-type'].nhis ||
            state.form['life-assured-identification-type'].ssnit ||
            state.form['life-assured-identification-type']['nia-ghana-card'],
          hasValue,
          !validateOnly && { id: 'id-type-radio-group' },
          ['*']
        ) && validates

      //Validation for Voters ID
      if (state.form['life-assured-identification-type']['voter-id'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Voters ID Number']
          ) && validates
      }

      //Validation for NHIS
      if (state.form['life-assured-identification-type']['nhis'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid NHIS Number']
          ) && validates
      }

      //Validation for SSNIT
      if (state.form['life-assured-identification-type']['ssnit'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid SSNIT Number']
          ) && validates
      }

      //Passport
      if (state.form['life-assured-identification-type']['passport'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Passport Number']
          ) && validates
      }

      //Drivers
      if (state.form['life-assured-identification-type']['dvla'] === 'Y') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Drivers Number']
          ) && validates
      }

      //ID
      if (
        state.form['life-assured-identification-type']['nia-ghana-card'] === 'Y'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid ID Number']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['life-assured-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-contact-details'].email,
          (v) => (requiredOnly ? hasValue(v) : validEmail(v)),
          !validateOnly && { id: 'email' },
          requiredOnly ? ['*'] : ['Please enter a valid Email address']
        ) && validates

      validates = Object.keys(state.form['life-assured-postal-address'])
        .filter((k) => ['addressee'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-postal-address'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      validates =
        verify(
          state,
          state.form['life-assured-residential-address'].suburb,
          hasValue,
          !validateOnly && { id: 'suburb' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-employment-details'].occupation,
          hasValue,
          !validateOnly && { id: 'occupation' },
          ['*']
        ) && validates
      break

    case 'spouse-details':
      //Validate Name Fields To Not Accept Numbers
      validates =
        verify(
          state,
          state.form['spouse-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['spouse-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Cell phone number
      validates =
        verify(
          state,
          state.form['spouse-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      validates =
        verify(
          state,
          state.form['spouse-identification']['title'],
          hasValue,
          !validateOnly && { id: 'title' },
          ['*']
        ) && validates

      //Validation for Voters ID
      if (state.form['spouse-identification-type']['voter-id'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validVotersID(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Voters ID Number']
          ) && validates
      }

      //Validation for NHIS
      if (state.form['spouse-identification-type']['nhis'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validNHIS(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid NHIS Number']
          ) && validates
      }

      //Validation for SSNIT
      if (state.form['spouse-identification-type']['ssnit'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validSSNIT(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid SSNIT Number']
          ) && validates
      }

      //Passport
      if (state.form['spouse-identification-type']['passport'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validPassport(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Passport Number']
          ) && validates
      }

      //Drivers
      if (state.form['spouse-identification-type']['dvla'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) =>
              requiredOnly ? hasValue(v) : hasValue(v) && validDrivers(v),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid Drivers Number']
          ) && validates
      }

      //ID
      if (state.form['spouse-identification-type']['nia-ghana-card'] === 'Y') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['idpassport-number'],
            (v) => (requiredOnly ? hasValue(v) : hasValue(v) && validID(v)),
            !validateOnly && { id: 'idpassport-number' },
            requiredOnly ? ['*'] : ['Please enter a valid ID Number']
          ) && validates
      }

      //Validate the gender & title
      if (state.form['spouse-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['spouse-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['spouse-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['spouse-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }
      break

    case 'trustee-details':
      //remove idpassport number validation whenever spouse has been added as the trustee.
      validates = Object.keys(state.form['trustee-identification'])
        .filter((k) => ['idpassport-number'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['trustee-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      validates =
        verify(
          state,
          state.form['trustee-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      //Validate Field Names to only be letters
      validates =
        verify(
          state,
          state.form['trustee-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['trustee-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age
      validates =
        verify(
          state,
          state.form['trustee-identification']['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().diff(v, 'year') >= 18),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The minimum allowable age for this product is 18.']
        ) && validates

      //Validate the gender & relationship
      if (
        ['Adv', 'Dr', 'Prof', 'Rev'].indexOf(
          state.form['trustee-identification']['title']
        ) >= 0
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Daughter',
                  'Estate',
                  'Grandmother',
                  'Mother',
                  'Mother-In-Law',
                  'Sister',
                  'Spouse',
                  'Testament',
                  'Trust',
                  'Brother',
                  'Father',
                  'Son',
                  'Father-In-Law',
                  'Grandfather',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      if (
        ['Miss', 'Mrs', 'Ms'].indexOf(
          state.form['trustee-identification']['title']
        ) >= 0
      ) {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Daughter',
                  'Estate',
                  'Grandmother',
                  'Mother',
                  'Mother-In-Law',
                  'Sister',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      if (state.form['trustee-identification']['title'] === 'Mr') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Estate',
                  'Father',
                  'Son',
                  'Father-In-Law',
                  'Grandfather',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and title does not correspond.']
          ) && validates
      }
      break

    case 'additional-lives':
      validates = state.form['lives-assured'].reduce((valid, life, counter) => {
        valid = ['first-names', 'surname', 'date-of-birth'].reduce(
          (valid, field) => valid && hasValue(life[field]),
          valid
        )
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['date-of-birth'],
              (v) =>
                dayjs().diff(v, 'year').toString() === life['age-of-member'],
              !validateOnly && { id: `date-of-birth-${counter}` },
              [
                `Date of birth does not match indicated age of ${life['age-of-member']} for ${life['relationship']}`,
              ]
            ) && valid
        }
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['first-names'],
              (v) => hasValue(v) && validName(v),
              !validateOnly && { id: `first-names-${counter}` },
              ['Please enter a valid first name']
            ) && valid
        }
        if (!requiredOnly && valid) {
          valid =
            verify(
              state,
              life['surname'],
              (v) => hasValue(v) && validName(v),
              !validateOnly && { id: `surname-${counter}` },
              ['Please enter a valid surname']
            ) && valid
        }
        return valid
      }, validates)
      break

    case 'beneficiary-details':
      if (!requiredOnly) {
        validates =
          verify(
            state,
            state.form['beneficiary-details'],
            (v) => v.length > 0,
            !validateOnly && { id: 'specify-beneficiaries', type: 'list' },
            ['Please add a beneficiary']
          ) && validates
      }
      break

    case 'add-beneficiary':
    case 'edit-beneficiary':
      validates = [
        'first-names',
        'surname',
        'date-of-birth',
        'role',
        'gender',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.local[field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      //Validate Name Fields To Not Accept Numbers
      validates =
        verify(
          state,
          state.local['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.local['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      //Validate the DOB to not be selected in the future
      validates =
        verify(
          state,
          state.local['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().isAfter(v, now)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['Please ensure the date of birth is not selected in the future.']
        ) && validates

      //Validate the gender & role
      if (state.local['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Child',
                  'Cousin',
                  'Daughter',
                  'Family',
                  'Grandchild',
                  'Grandmother',
                  'Main',
                  'Mother',
                  'Mother-in-law',
                  'Niece',
                  'Sister',
                  'Spouse',
                  'Step-Mother',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ['The selected role and gender does not correspond.']
          ) && validates
      }

      if (state.local['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Child',
                  'Cousin',
                  'Family',
                  'Father',
                  'Father-in-law',
                  'Grandchild',
                  'Grandfather',
                  'Main',
                  'Nephew',
                  'Son',
                  'Spouse',
                  'Step-Father',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ['The selected role and gender does not correspond.']
          ) && validates
      }
      break

    case 'update-beneficiary-split-percentage':
      if (!requiredOnly) {
        validates =
          Math.ceil(
            Object.keys(state.local || {})
              .filter((k) => k.startsWith('beneficiary-split'))
              .reduce((t, b) => {
                const v = parseFloat(state.local[b])
                return isNaN(v) ? -1.0 : v + t
              }, 0.0)
          ) === 100 && validates
        if (!validateOnly) {
          updateItemIn(
            state.page.item,
            { id: 'update-benefit-split-percentage' },
            {
              errors: validates ? [] : ['The allocation must add up to 100.00'],
            }
          )
        }
      }
      break

    case 'medical-underwriting':
      validates = [
        'planning-other-insurance',
        'other-assurance-past12-months',
        'risky-occupation-hobbies',
        'refused-application-or-increased-premium',
        'insolvency-issue',
        'occupation-not-clerical',
      ].reduce((valid, field) => {
        valid =
          verify(
            state,
            state.form['life-assured-general-habits'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid
        if (state.form['life-assured-general-habits'][field] === 'Yes') {
          let details = `${field}-details`
          valid =
            verify(
              state,
              state.form['life-assured-general-habits'][details],
              hasValue,
              !validateOnly && { id: details },
              ['*']
            ) && valid
        }
        return valid
      }, validates)

      validates =
        verify(
          state,
          state.form['life-assured-general-habits'][
            'cover-exceeds-one-hundred-thousand'
          ],
          hasValue,
          !validateOnly && { id: 'cover-exceeds-one-hundred-thousand' },
          ['*']
        ) && validates

      if (
        state.form['life-assured-general-habits'][
          'cover-exceeds-one-hundred-thousand'
        ] === 'Yes'
      ) {
        validates =
          verify(
            state,
            state.form['life-assured-general-habits'][
              'average-monthly-gross-income'
            ],
            hasValue,
            !validateOnly && { id: 'average-monthly-gross-income' },
            ['*']
          ) && validates
      }

      validates =
        verify(
          state,
          state.form['life-assured-general-habits']['smoker'] === 'Y' ||
            state.form['life-assured-general-habits']['non-smoker'] === 'Y' ||
            state.form['life-assured-general-habits']['never-smoked'] === 'Y',
          hasValue,
          !validateOnly && { id: 'smoker-options-radio-group' },
          ['*']
        ) && validates

      let age = dayjs().diff(
        state.form['life-assured-identification']['date-of-birth'],
        'year'
      )

      if (state.form['life-assured-general-habits'].smoker === 'Y') {
        let yrs = parseInt(
          state.form['life-assured-general-habits']['years-smoking']
        )

        validates =
          verify(
            state,
            state.form['life-assured-general-habits']['years-smoking'],
            (v) =>
              requiredOnly
                ? hasValue(v)
                : hasValue(v) && !isNaN(yrs) && yrs > 0 && yrs <= age,
            !validateOnly && { id: 'years-smoking' },
            requiredOnly ? ['*'] : ['Please enter a valid amount of years']
          ) && validates

        validates = [
          'cigarettes-per-day',
          'cigars-per-day',
          'pipe',
          'medical-advice-stop-smoking',
        ].reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-general-habits'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      }

      if (state.form['life-assured-general-habits']['non-smoker'] === 'Y') {
        let yrs = parseInt(
          state.form['life-assured-general-habits']['years-not-smoking']
        )
        validates =
          verify(
            state,
            state.form['life-assured-general-habits']['years-not-smoking'],
            (v) =>
              requiredOnly
                ? hasValue(v)
                : hasValue(v) && !isNaN(yrs) && yrs > 0 && yrs <= age,
            !validateOnly && { id: 'years-not-smoking' },
            requiredOnly ? ['*'] : ['Please enter a valid amount of years']
          ) && validates
      }

      validates = [
        'medical-advice-reduce-drinking',
        'excessive-drinking',
        'excessive-drinking-past',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.form['life-assured-general-habits'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      break

    case 'medical-declaration':
      validates = Object.keys(state.form['life-assured-health-tests']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['life-assured-health-tests'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      validates = Object.keys(state.form['life-assured-health-details'])
        .filter(
          (k) =>
            [
              'chest-pain',
              'high-blood-pressure',
              'respiratory-condition',
              'digestive-condition',
              'urinary-condition',
              'anxiety-epilepsy',
              'diabetes',
              'cancer-or-tumors',
              'arthritis-osteoporosis',
              'aids',
              'sexually-transmitted-disease',
              'family-history-of-illness',
              'family-history-of-illness-details',
            ].indexOf(k) < 0
        )
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-health-details'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      validates = ['family-history-of-illness'].reduce((valid, field) => {
        valid =
          verify(
            state,
            state.form['life-assured-health-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid
        if (state.form['life-assured-health-details'][field] === 'Yes') {
          let details = `${field}-details`
          valid =
            verify(
              state,
              state.form['life-assured-health-details'][details],
              hasValue,
              !validateOnly && { id: details },
              ['*']
            ) && valid
        }
        return valid
      }, validates)
      break

    case 'health-descriptions':
      validates = state.form['health-concerns'].reduce(
        (itemValid, item, index) =>
          Object.keys(item).reduce(
            (fieldValid, field) =>
              verify(
                state,
                item[field],
                hasValue,
                !validateOnly && { id: `${field}-${index + 1}` },
                ['*']
              ) && fieldValid,
            itemValid
          )
      )
      validates = state.form['health-concerns'].reduce(
        (valid, date, counter) => {
          valid = ['medical-condition-date'].reduce(
            (valid, field) => valid && hasValue(date[field]),
            valid
          )
          if (!requiredOnly && valid) {
            valid =
              verify(
                state,
                date['medical-condition-date'],
                (v) => hasValue(v) && (requiredOnly || dayjs().isAfter(v, now)),
                !validateOnly && {
                  id: `medical-condition-date-${counter + 1}`,
                },
                requiredOnly
                  ? ['*']
                  : [
                      'Please ensure the medical condition date is not selected in the future.',
                    ]
              ) && valid
          }
          return valid
        },
        validates
      )
      break

    case 'payment-details':
      validates =
        verify(
          state,
          state.form['payment-method']['debit-order'] ||
            state.form['payment-method']['stop-order'] ||
            state.form['payment-method']['mobile-wallet'],
          hasValue,
          !validateOnly && { id: 'payment-method-radio-group' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-start-date'],
          hasValue,
          !validateOnly && { id: 'payment-start-date' },
          ['*']
        ) && validates
      break

    case 'debit-order-details':
      validates = Object.keys(state.form['payment-bank-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-bank-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates

      //Validate field name to have no numbers
      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-holder-name'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'account-holder-name' },
          requiredOnly ? ['*'] : ['Please remove all numbers in the field']
        ) && validates

      //VALIDATE ACCOUNT NUMBER LENGTH BASED ON THE ACCOUNT ENTITY NAME CHOSEN
      if (!requiredOnly) {
        //10 DIGITS
        if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Absa (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Zenith Bank') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 10 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //9 / 10 / 11 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Bank Of Africa' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) =>
                (v.length === 9 || v.length === 10 || v.length === 11) &&
                onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Access Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Consolidated Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Ecobank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Fidelity Bank Ghana' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Atlantic Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'First Bank Nigeria' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Gcb Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'National Investment Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Omni Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Prudential Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Republic Bank (gh) Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Stanbic Bank Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Standard Chartered Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Universal Merchant Bank (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 13 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //14 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'United Bank For Africa (gh) Ltd' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 14 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //12 / 13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Cal Bank Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Societe General Ghana Ltd' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Guaranty Trust (gh) Ltd') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => (v.length === 12 || v.length === 13) && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //16 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Agricultural Development Bank' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 16 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
      }
      break

    case 'stop-order-details':
      validates = Object.keys(state.form['payment-employment-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-employment-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break

    case 'mobile-wallet-details':
      validates = Object.keys(state.form['mobile-wallet-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['mobile-wallet-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break

    case 'acceptance-screen':
      validates =
        verify(
          state,
          state.form['acceptance-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break
  }

  if (!requiredOnly && !validates) {
    state.local = state.local || {}
    state.local.failed_validation = true
  }
  return validates
}
