/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import classNames from 'classnames'
import React, { useState } from 'react'

const unselectedValue = e =>
  e.options && e.options.indexOf('N') > -1 ? 'N' : ''

const RadioGroup = ({ id, label, elements, errors, onChange }) => {
  const [value, setValue] = useState(
    elements.reduce(
      (a, i) =>
        i.content === 'Y'
          ? { id: i.id, uid: i.uid, un: unselectedValue(i) }
          : a,
      null
    )
  )
  return (
    <ul
      id={id}
      className={classNames({
        list: true,
        'has-error': errors && errors.length > 0
      })}
    >
      <li className='section-header'>{label}</li>
      {elements.map((elem, idx) => (
        <li key={idx}>
          <input
            type='radio'
            id={elem.id}
            name={id}
            value={elem.uid}
            checked={value ? elem.uid === value.uid : false}
            onChange={t => {
              if (value) {
                onChange(value.uid, value.id, value.un)
              }
              onChange(elem.uid, elem.id, 'Y')
              setValue({
                id: elem.id,
                uid: elem.uid,
                un: unselectedValue(elem)
              })
            }}
          />
          <label
            htmlFor={elem.id}
            className={classNames({ label: true, 'label-inline': true })}
          >
            {elem.label}
          </label>
        </li>
      ))}
      {errors && errors.length > 0 && (
        <li className='section-errors'>
          <ul className='error-description'>
            {errors.map((error, idx) => (
              <li key={idx}>{error}</li>
            ))}
          </ul>
        </li>
      )}
    </ul>
  )
}

export default RadioGroup
