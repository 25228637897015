/* global Blob */

/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import browser from 'browser-detect'
import dayjs from 'dayjs'
import fetch from 'cross-fetch'
import CryptoJS from 'crypto-js'
import { saveAs } from 'file-saver'
import {
  uploadCompletedProcess,
  setUploadedStatus,
  getUploadedStatus,
} from './actions'

let checking = false

export const check = (completed, dispatch) => {
  if (!checking) {
    checking = true
    completed.forEach((app) => {
      if (app.status === 'uploaded') {
        dispatch(getUploadedStatus(app.reference, app.upload_result.id))
      } else if (!app.status || app.status === 'pending') {
        dispatch(uploadCompletedProcess(app.reference, 'start', {}))
      } else if (
        app.status === 'uploading' &&
        dayjs(app.at).isBefore(dayjs().subtract(2, 'hour'))
      ) {
        dispatch(uploadCompletedProcess(app.reference, 'failure', {}))
      }
    })
    checking = false
  }
}

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText)
  }
  return response.json()
}

export const upload = (completedApp, dispatch) => {
  fetch(process.env.SYNC_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.SYNC_TOKEN}`,
    },
    body: JSON.stringify({
      enterprise: process.env.SYNC_ENTERPRISE,
      environment: process.env.APPLICATION_ENV,
      source: 'offline-app',
      data: completedApp,
    }),
  })
    .then(handleErrors)
    .then((data) => {
      if (data.id) {
        dispatch(
          uploadCompletedProcess(completedApp.reference, 'success', {
            at: dayjs().format(),
            ...data,
          })
        )
      } else {
        dispatch(
          uploadCompletedProcess(
            completedApp.reference,
            'failure',
            data.message || 'Internal error'
          )
        )
        console.log(data)
      }
    })
    .catch((err) => {
      console.log(err)
      const status =
        err && err.message === 'Unprocessable Entity' ? 'rejected' : 'failure'
      dispatch(uploadCompletedProcess(completedApp.reference, status, err))
    })
}

export const getStatus = (ref, requestId, dispatch) => {
  fetch(
    `${process.env.SYNC_URL}/${requestId}?enterprise=${process.env.SYNC_ENTERPRISE}&environment=${process.env.APPLICATION_ENV}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.SYNC_TOKEN}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      if (data['process-id']) {
        dispatch(setUploadedStatus(ref, data['process-id']))
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const exportData = (completed, agentId) => {
  const date = dayjs().format()
  const content = JSON.stringify({
    exported_at: date,
    enterprise: process.env.SYNC_ENTERPRISE,
    environment: process.env.APPLICATION_ENV,
    client: browser(),
    agent: agentId,
    revision: process.env.REVISION,
    export_data: completed,
  })
  const fileName = `export-${process.env.ENTERPRISE}-${dayjs().format(
    'YYYY-MM-DD-HH-mm'
  )}.data`
  saveAs(
    new Blob(
      [
        `-----BEGIN EXPORTED DATA-----\nExport: ${date}\nEnterprise: ${process.env.SYNC_ENTERPRISE}\nEnvironment: ${process.env.APPLICATION_ENV}\nRevision: ${process.env.REVISION}\n\n`,
        CryptoJS.AES.encrypt(content, process.env.EXPORT_KEY),
        '\n-----END EXPORTED DATA-----',
      ],
      { type: 'application/octet-stream' }
    ),
    fileName
  )
}
