/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import { changeRoute } from './actions'

const setRoute = route => {
  window.history.replaceState(null, null, '#')
}

export const createRouter = dispatch => {
  window.onhashchange = () => {
    dispatch(changeRoute(window.location.hash))
  }
  return setRoute
}

export const parseRoute = routeStr => {
  const route = routeStr.split(/#|\//)
  if (route.length > 0) {
    if (route[0].length < 1) {
      route.shift()
    }
    return route.map(r => {
      const [base, rest] = r.split('?')
      const ps = (rest || '').split('&').reduce((ps, p) => {
        if (p.length > 0) {
          const [key, value] = p.split('=')
          ps[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
        }
        return ps
      }, {})
      return { route: base, params: ps }
    })
  }
  return []
}

export default createRouter
