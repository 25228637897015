/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */

import {
  pages,
  acceptancePaymentDetails,
  beneficiaryItem,
  benefitSplitItem,
} from './savings-smart-plan/pages'

import {
  createItem,
  updateItemIn,
  findItemsIn,
} from '../../../src/core/dataitems'
import { form } from './savings-smart-plan/form'
import { cancelProcess, completeProcess } from '../../../src/core/actions'
import shortid from 'shortid'
import dayjs from 'dayjs'
import { original } from 'immer'
import { createSelector } from 'reselect'
import { validate } from './savings-smart-plan/validations'

// Change the page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}

const amountString = (f) =>
  f
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/, '')

const dateFormat = 'D MMMM YYYY'
const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

// Prodct rules say spouse gender is opposite to main life gender :(
const spouseGender = (gender) => {
  switch (gender) {
    case 'Male':
      return 'Female'
    case 'Female':
      return 'Male'
    default:
      return null
  }
}

const isTrusteeABeneficiary = createSelector(
  (form) => form['trustee-identification']['first-names'],
  (form) => form['trustee-identification'].surname,
  (form) => form['trustee-identification']['date-of-birth'],
  (form) => form['beneficiary-details'],
  (fn, sn, dob, bs) =>
    fn &&
    sn &&
    dob &&
    bs.reduce(
      (result, b) =>
        result ||
        (b['first-names'] === fn &&
          b.surname === sn &&
          b['date-of-birth'] === dob),
      false
    )
)

const updateBeneficiarySplit = createSelector(
  (form) => form['beneficiary-details'],
  (beneficiaries) => {
    if (beneficiaries.length > 0) {
      const split = Math.floor(100 / beneficiaries.length)
      let difference = 100 - split * beneficiaries.length
      for (let i = 0; i < beneficiaries.length; i++) {
        if (difference > 0) {
          beneficiaries[i]['benefit-split'] = amountString(split + 1)
          difference -= 1
        } else {
          beneficiaries[i]['benefit-split'] = amountString(split)
        }
      }
    }
    return beneficiaries
  }
)

const generateAcceptancePage = (state) => {
  // Update the reference number
  updateItemIn(
    state.page.item,
    { id: 'contract-id' },
    { content: state.form['reference-number'] }
  )
  // Update all sections
  Object.keys(state.form).forEach((section) => {
    findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
      Object.keys(state.form[section]).forEach((field) => {
        if (state.form[section][field]) {
          updateItemIn(
            sItem,
            { id: field },
            { content: state.form[section][field] }
          )
        }
      })
    })
  })
  // Add beneficiary details
  updateItemIn(
    state.page.item,
    { id: 'beneficiary-details', type: 'table' },
    { content: (state.form['beneficiary-details'] || []).map(beneficiaryItem) }
  )
  // Update the payment details
  let paymentDetails = acceptancePaymentDetails(state.form)
  if (paymentDetails) {
    updateItemIn(
      state.page.item,
      { id: 'payment-details', type: 'list' },
      paymentDetails
    )
  }
}

//Premium Calculations
var main_life_payment_rate_index = function (premium) {
  if (premium >= 50 && premium < 60) {
    return 2000
  }
  if (premium >= 60 && premium < 70) {
    return 4000
  }
  if (premium >= 70 && premium < 80) {
    return 6000
  }
  if (premium >= 80 && premium < 90) {
    return 8000
  }
  if (premium >= 90 && premium < 100) {
    return 10000
  } else {
    return '0.00'
  }
}

const paymentFrequencyLookup = {
  'bi-annually': 6,
  monthly: 1,
  quarterly: 3,
  annually: 12,
}

const totalPremium = createSelector(
  (form) => form['investment-premium'],
  (form) => form['payment-frequency'],
  (mainrate, paymentFrequency) => {
    const rate = parseFloat(mainrate)
    const pfkey = Object.keys(paymentFrequency).find(
      (key) => paymentFrequency[key] === 'Y'
    )
    const pfmultiplier =
      pfkey in paymentFrequencyLookup ? paymentFrequencyLookup[pfkey] : 1
    if (!isNaN(rate)) {
      const premiumrate = rate
      if (premiumrate > 0 && pfmultiplier) {
        return (1 + premiumrate) * pfmultiplier
      } else '0.00'
    } else return '0.00'
  }
)

const totalBenefitRate = createSelector(
  (form) => form['investment-premium'],
  (benefitrate) => {
    if (!isNaN(benefitrate)) {
      const rate = main_life_payment_rate_index(benefitrate)
      return rate
    } else return '0.00'
  }
)

// Create the state for a new process
const createState = (state) => {
  state.currentProcess = {} // Create a new process
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  ) // Overall Process state
  state.currentProcess.step = 'quotation-screen' // Current process step
  state.currentProcess.page = pages['quotation-screen'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

const setPageItemFromState = (state) => {
  validate(state, state.local && state.local.failed_validation ? false : true)
  switch (state.step) {
    case 'quotation-screen':
      // Date of birth
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
          maxYear: dayjs().year() - 18,
          minYear: dayjs().year() - 56,
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'inflation' },
        {
          content: state.form['inflation-protector'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'investment-amount' },
        {
          content: state.form['investment-premium'] || '',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'term' },
        {
          content: state.form['term-in-years'] || '',
        }
      )
      break

    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'employment-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })

      break

    case 'spouse-details':
      ;['spouse-identification', 'spouse-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(
              state.page.item,
              { id: field },
              { content: state.form[section][field] || '' }
            )
          )
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'spouse-is-trustee' },
        { content: state.form['spouse-is-trustee'] || '' }
      )
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(
              state.page.item,
              { id: field },
              { content: state.form[section][field] || '' }
            )
          )
        }
      )
      break

    case 'beneficiary-details':
      const numBeneficiaries = (state.form['beneficiary-details'] || []).length
      updateItemIn(
        state.page.item,
        { id: 'beneficiary-details', type: 'table' },
        {
          invisible: numBeneficiaries < 1,
          content: (state.form['beneficiary-details'] || []).map(
            beneficiaryItem
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '6c60b1c422ae8373' }, // Add Trustee as beneficiary
        {
          invisible: isTrusteeABeneficiary(state.form) || numBeneficiaries >= 9,
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '145b199f80e0c8c3' }, // Add a different beneficiary
        { invisible: numBeneficiaries >= 9 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'update-benefit-split-percentage':
            button.invisible = numBeneficiaries < 2
            break
          case 'add-another-beneficiary':
            button.invisible = numBeneficiaries >= 9
            break
          case 'add-trustee':
            button.invisible =
              isTrusteeABeneficiary(state.form) || numBeneficiaries >= 9
            break
        }
      })
      break

    case 'add-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'edit-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'update-beneficiary-split-percentage':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'payment-details':
      Object.keys(state.form['payment-method-selection']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-method-selection'][field] || '' }
        )
      )

      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )

      Object.keys(state.form['payment-frequency']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-frequency'][field] || '' }
        )
      )

      updateItemIn(
        state.page.item,
        { id: 'payer-person', type: 'list' },
        {
          invisible: !(
            state.form['life-assured-identification']['marital-status'] ===
            'Married'
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'myself' },
        { content: state.form['payer-person']['myself'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'my-spouse' },
        { content: state.form['payer-person']['my-spouse'] || '' }
      )
      break

    case 'debit-order-details':
      Object.keys(state.form['payment-bank-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['payment-bank-details'][field] || '',
          }
        )
      )
      break

    case 'stop-order-details':
      Object.keys(state.form['stop-order-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['stop-order-details'][field] || '',
          }
        )
      )
      break

    case 'mobile-wallet-details':
      Object.keys(state.form['mobile-wallet-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['mobile-wallet-details'][field] || '',
          }
        )
      )
      break

    case 'payment-details-verification':
      updateItemIn(
        state.page.item,
        { id: 'contract-id' },
        { content: state.form['reference-number'] }
      )

      // Update the payment details
      let paymentDetails = acceptancePaymentDetails(state.form)
      if (paymentDetails) {
        updateItemIn(
          state.page.item,
          { id: 'payer-employment-details', type: 'list' },
          paymentDetails
        )
      }
      break

    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break

    case 'acceptance-screen':
      updateItemIn(
        state.page.item,
        { id: 'spouse-identification', type: 'list' },
        {
          invisible: !(
            state.form['life-assured-identification']['marital-status'] ===
            'Married'
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'spouse-contact-details', type: 'list' },
        {
          invisible: !(
            state.form['life-assured-identification']['marital-status'] ===
            'Married'
          ),
        }
      )
      break
  }
  // Update the premium
  updateItemIn(
    state.page.item,
    { id: 'premium' },
    { content: totalPremium(state.form) }
  )
  // Update the benefit rate
  updateItemIn(
    state.page.item,
    { id: 'benefit-rate' },
    { content: totalBenefitRate(state.form) }
  )
}

// processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break
      case 'quotation-screen':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              if (state.form['investment-premium'] > 99) {
                changePage(state, 'maximum-premium')
              } else if (state.form['investment-premium'] < 50) {
                changePage(state, 'minimum-premium')
              } else {
                changePage(state, 'life-assured-identification')
              }
            }
            break
        }
        break
      case 'maximum-premium':
        switch (button.id) {
          case 'done':
            changePage(state, 'quotation-screen')
            break
        }
        break
      case 'minimum-premium':
        switch (button.id) {
          case 'done':
            changePage(state, 'quotation-screen')
            break
        }
        break
      case 'life-assured-identification':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              if (
                state.form['life-assured-identification']['marital-status'] ===
                'Married'
              ) {
                changePage(state, 'spouse-details')
              } else {
                changePage(state, 'trustee-details')
              }
              if (
                state.form['life-assured-identification']['marital-status'] !==
                'Married'
              ) {
                state.form['payer-person']['myself'] = 'Y'
              }
            }
            break
          case 'back':
            changePage(state, 'quotation-screen')
            break
        }
        break
      case 'spouse-details':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              if (state.form['spouse-is-trustee'] === 'Y') {
                ;['identification', 'contact-details'].forEach((section) =>
                  Object.keys(state.form[`spouse-${section}`]).forEach(
                    (field) =>
                      (state.form[`trustee-${section}`][field] =
                        state.form[`spouse-${section}`][field])
                  )
                )
                state.form['trustee-identification']['relationship'] = 'Spouse'
              }
              changePage(state, 'trustee-details')
            }
            break
          case 'back':
            changePage(state, 'life-assured-identification')
            break
        }
        break

      case 'trustee-details':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              changePage(state, 'beneficiary-details')
              if (state.form['trustee-identification'].relationship === 'Son') {
                state.form['trustee-identification'].relationship = 'Son-1'
              }
              if (
                state.form['trustee-identification'].relationship === 'Daughter'
              ) {
                state.form['trustee-identification'].relationship = 'Daughter-1'
              }
            }
            break
          case 'back':
            if (
              state.form['life-assured-identification']['marital-status'] ===
              'Married'
            ) {
              changePage(state, 'spouse-details')
            } else {
              changePage(state, 'life-assured-identification')
            }
            break
        }
        break

      case 'beneficiary-details':
        switch (button.id) {
          case 'next':
            if (state.form['beneficiary-details'].length === 0) {
              changePage(state, 'beneficiary-validation')
            } else {
              changePage(state, 'payment-details')
            }
            break
          case 'back':
            changePage(state, 'trustee-details')
            break
          case 'add-trustee':
            if (
              state.form['beneficiary-details'].length < 9 &&
              !isTrusteeABeneficiary(original(state.form))
            ) {
              state.form['beneficiary-details'].push({
                'first-names':
                  state.form['trustee-identification']['first-names'],
                surname: state.form['trustee-identification'].surname,
                'benefit-split': null,
                role: 'Cessionary',
                gender: state.form['trustee-identification'].gender,
                'date-of-birth':
                  state.form['trustee-identification']['date-of-birth'],
              })
              updateBeneficiarySplit(state.form)
            }
            break

          case 'add-another-beneficiary':
            if (state.form['beneficiary-details'].length < 9) {
              changePage(state, 'add-beneficiary')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
                'role',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break

          case 'update-benefit-split-percentage':
            changePage(state, 'update-beneficiary-split-percentage')
            state.local = state.form['beneficiary-details'].reduce(
              (l, b, c) => {
                l[`beneficiary-split-${c + 1}`] = b['benefit-split'] || ''
                return l
              },
              {}
            )
            updateItemIn(
              state.page.item,
              { id: 'update-benefit-split-percentage', type: 'list' },
              {
                content:
                  state.form['beneficiary-details'].map(benefitSplitItem),
              }
            )
            break
        }
        break

      case 'beneficiary-validation':
        switch (button.id) {
          case 'done':
            changePage(state, 'beneficiary-details')
            break
        }
        break

      case 'add-beneficiary':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'add':
            if (validate(state)) {
              if (state.form['beneficiary-details'].length < 9) {
                state.form['beneficiary-details'].push({
                  'first-names': state.local['first-names'],
                  surname: state.local.surname,
                  'benefit-split': null,
                  role: state.local.role,
                  gender: state.local.gender,
                  'date-of-birth': state.local['date-of-birth'],
                })
                updateBeneficiarySplit(state.form)
                changePage(state, 'beneficiary-details')
              }
            }
            break
        }
        break

      case 'edit-beneficiary':
        switch (button.id) {
          case 'update':
            if (validate) {
              if (
                state.local.index >= 0 &&
                state.local.index <= state.form['beneficiary-details'].length
              ) {
                Object.keys(
                  original(
                    state.form['beneficiary-details'][state.local.index - 1]
                  )
                ).forEach(
                  (k) =>
                    (state.form['beneficiary-details'][state.local.index - 1][
                      k
                    ] = state.local[k])
                )
              }
              changePage(state, 'beneficiary-details')
            }
            break
          case 'delete-beneficiary':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['beneficiary-details'].length
            ) {
              const old = original(state.form['beneficiary-details'])
              state.form['beneficiary-details'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
              updateBeneficiarySplit(state.form)
            }
            changePage(state, 'beneficiary-details')
            break
        }
        break

      case 'update-beneficiary-split-percentage':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'update':
            if (validate(state)) {
              state.form['beneficiary-details'] = original(
                state.form['beneficiary-details']
              ).map((b, c) => {
                b['benefit-split'] = amountString(
                  parseFloat(state.local[`beneficiary-split-${c + 1}`])
                )
                return b
              })
              changePage(state, 'beneficiary-details')
            }
        }
        break

      case 'payment-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'next':
            if (validate(state)) {
              if (
                state.form['payment-method-selection']['debit-order'] === 'Y'
              ) {
                changePage(state, 'debit-order-details')
              } else if (
                state.form['payment-method-selection']['stop-order'] === 'Y'
              ) {
                changePage(state, 'stop-order-details')
              } else if (
                state.form['payment-method-selection']['mobile-wallet'] === 'Y'
              ) {
                changePage(state, 'mobile-wallet-details')
              }
            }
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              ;(state.form['payer-person']['myself'] = 'Y') &&
                (state.form['payer-person']['my-spouse'] = 'N')
            }
            if (state.form['payer-person']['my-spouse'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['spouse-identification'][field])
              )
            } else if (state.form['payer-person']['myself'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['life-assured-identification'][field])
              )
            }
            if (state.form['payment-frequency']['monthly'] === 'Y') {
              state.form['payment-frequency-option'] = 'Monthly'
            } else if (state.form['payment-frequency']['quarterly'] === 'Y') {
              state.form['payment-frequency-option'] = 'Quarterly'
            } else if (state.form['payment-frequency']['bi-annually'] === 'Y') {
              state.form['payment-frequency-option'] = 'Half-Yearly'
            } else if (state.form['payment-frequency']['annually'] === 'Y') {
              state.form['payment-frequency-option'] = 'Yearly'
            }
            break
        }
        break

      case 'debit-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details-verification')
            }
            break
        }
        break

      case 'stop-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details-verification')
            }
            break
        }
        break

      case 'mobile-wallet-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details-verification')
            }
            break
        }
        break

      case 'payment-details-verification':
        switch (button.id) {
          case 'back':
            if (state.form['payment-method-selection']['debit-order'] === 'Y') {
              changePage(state, 'debit-order-details')
            } else if (
              state.form['payment-method-selection']['stop-order'] === 'Y'
            ) {
              changePage(state, 'stop-order-details')
            } else if (
              state.form['payment-method-selection']['mobile-wallet'] === 'Y'
            ) {
              changePage(state, 'mobile-wallet-details')
            }
            break

          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              state.form['payer-person']['myself'] = 'Y'
            }
            break
        }
        break

      case 'upload-documents':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details-verification')
            break
          case 'upload-id-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
          case 'next':
            changePage(state, 'acceptance-screen')
            generateAcceptancePage(state)
            break
        }
        break

      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            if (state.form.documents[state.local.document].length > 0) {
              changePage(state, 'upload-documents')
            } else {
              changePage(state, 'upload-documents')
            }
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'back':
            changePage(state, 'upload-documents')
            break
          case 'submit':
            if (validate(state)) {
              state.form['life-assured-payer'] =
                state.form['payer-person']['myself']
              state.form['application-accepted'] = 'Y'
              state.form['application-acceptance-date'] =
                dayjs().format('YYYYMMDD')
              state.form['total-premium'] = totalPremium(state.form)
              asyncDispatch(completeProcess(state.form['reference-number']))
            }
            break
        }
        break
    }
    setPageItemFromState(state)
  }
}

// processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-screen':
      switch (data.id) {
        case 'date-of-birth':
          state.form['life-assured-identification']['date-of-birth'] =
            data.value
          dirty = true
          break
        case 'inflation':
          state.form['inflation-protector'] = data.value
          dirty = true
          break
        case 'investment-amount':
          state.form['investment-premium'] = data.value
          dirty = true
          break
        case 'term':
          state.form['term-in-years'] = data.value
          dirty = true
          break
      }
      break
    case 'life-assured-identification':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'employment-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })

      if (data.id === 'marital-status') {
        state.form['spouse-identification'].gender =
          data.value === 'Married'
            ? spouseGender(state.form['life-assured-identification'].gender)
            : null
        state.form['spouse-identification']['marital-status'] =
          data.value === 'Married' ? 'Married' : null
        dirty = true
      } else if (data.id === 'gender') {
        if (
          state.form['life-assured-identification']['marital-status'] ===
          'Married'
        ) {
          state.form['spouse-identification'].gender = spouseGender(data.value)
          state.form['spouse-identification']['marital-status'] = 'Married'
          dirty = true
        }
      }
      break

    case 'spouse-details':
      ;['spouse-identification', 'spouse-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      if (data.id === 'spouse-is-trustee') {
        state.form[data.id] = data.value
        dirty = true
      }
      break

    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      break

    case 'add-beneficiary':
    case 'edit-beneficiary':
    case 'update-beneficiary-split-percentage':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break

    case 'payment-details':
      switch (data.id) {
        case 'debit-order':
        case 'stop-order':
        case 'mobile-wallet':
          state.form['payment-method-selection'][data.id] = data.value
          dirty = true
          break
        case 'payment-start-date':
          const date = dayjs(data.value)
          state.form['payment-start-date'] = date.isValid()
            ? date.format('YYYYMMDD')
            : null
          dirty = true
          break
        case 'monthly':
        case 'quarterly':
        case 'bi-annually':
        case 'annually':
          state.form['payment-frequency'][data.id] = data.value
          dirty = true
          break
        case 'myself':
        case 'my-spouse':
          state.form['payer-person'][data.id] = data.value
          dirty = true
          break
      }
      break

    case 'debit-order-details':
      if (data.id in state.form['payment-bank-details']) {
        state.form['payment-bank-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'stop-order-details':
      if (data.id in state.form['stop-order-details']) {
        state.form['stop-order-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'mobile-wallet-details':
      if (data.id in state.form['mobile-wallet-details']) {
        state.form['mobile-wallet-details'][data.id] = data.value
        dirty = true
      }
      break

    case 'payment-details-verification':
      if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break

    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

const processItemClick = (state, data, asyncDispatch) => {
  let dirty = false
  switch (state.step) {
    case 'beneficiary-details':
      if (
        data.index > 0 &&
        data.index <= state.form['beneficiary-details'].length
      ) {
        changePage(state, 'edit-beneficiary')
        state.local = Object.assign(
          {},
          original(state.form['beneficiary-details'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

export default {
  createState,
  processButton,
  processChange,
  processItemClick,
}
