/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

/* Four functions are expected to implement a process
 *
 * 1. createState : state -> ()
 *    - create the currentProcess property of the state
 *
 * 2. processButton : (processState, { id }) -> ()
 *    - called when a button is clicked to modify the process state
 *
 * 3. processChange : (processState, { id, uid, value }) => ()
 *    - called when an input value is changes to modify the process state
 *
 * 4. processItemClick : (processState, { id, uid }) => ()
 *    - called when an item is clicked to modify the process state
 */

import {
  pages,
  childDetailsItem,
  beneficiaryItem,
  benefitSplitItem,
  healthDetailsItem,
  healthDetailsPrefix,
  quotationPanel,
  acceptancePaymentDetails,
} from './educare-plan-enhanced/pages'
import { form } from './educare-plan-enhanced/form'
import { rates } from './educare-plan-enhanced/rates'
import { validate } from './educare-plan-enhanced/validations'
import { emptyPhoneNumber } from '../../../src/core/validations'
import {
  createItem,
  updateItemIn,
  createButton,
  findItemsIn,
} from '../../../src/core/dataitems'
import { cancelProcess, completeProcess } from '../../../src/core/actions'
import { original } from 'immer'
import { createSelector } from 'reselect'
import shortid from 'shortid'
import dayjs from 'dayjs'

const dateFormat = 'D MMMM YYYY'

const amountString = (f) =>
  f
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/, '')

const startOfNextMonths = (monthCount) => {
  const result = []
  const now = dayjs()
  for (let m = 1; m <= monthCount; m++) {
    result.push(now.add(m, 'month').startOf('month').format(dateFormat))
  }
  return result
}

const paymentFrequencyLookup = {
  'Half-Yearly': 6,
  Monthly: 1,
  Quarterly: 3,
  Yearly: 12,
}

const premiumAmount = createSelector(
  (form) => form['selected-premium']['investment-premium'],
  (form) => form['selected-premium']['cash-bonus-premium'],
  (form) => form['selected-premium']['hospitalisation-cover-option'],
  (form) => form['life-assured-identification']['date-of-birth'],
  (form) => form['payment-frequency'],
  (premium, cashBonus, hospitalisation, dob, paymentFrequency) => {
    const p = parseFloat(premium)
    const cb = parseFloat(cashBonus)
    const age = dayjs().diff(dob, 'year')
    const rIdx = `${hospitalisation}:main:${age}`
    const pfmultiple =
      paymentFrequency in paymentFrequencyLookup
        ? paymentFrequencyLookup[paymentFrequency]
        : 1
    return isNaN(p) || p < 20.0 || p > 1000.0
      ? '0.00'
      : amountString(
          (1 +
            p +
            (isNaN(cb) || cb < 10.0 || cb > 50 ? 0 : cb) +
            (rIdx in rates ? rates[rIdx] : 0)) *
            pfmultiple
        )
  }
)

const benefitRate = createSelector(
  (form) => form['selected-premium']['investment-premium'],
  (premium) => {
    const p = parseFloat(premium)
    if (isNaN(p) || p > 1000.0) {
      return '0.00'
    }
    if (p > 750.0) {
      return amountString(12000.0)
    } else if (p > 500.0) {
      return amountString(9000.0)
    } else if (p > 250.0) {
      return amountString(6000.0)
    } else if (p > 100.0) {
      return amountString(3000.0)
    } else if (p >= 20.0) {
      return amountString(1200.0)
    } else {
      return '0.00'
    }
  }
)

const isTrusteeABeneficiary = createSelector(
  (form) => form['trustee-identification']['first-names'],
  (form) => form['trustee-identification'].surname,
  (form) => form['trustee-identification']['date-of-birth'],
  (form) => form['beneficiary-details'],
  (fn, sn, dob, bs) =>
    fn &&
    sn &&
    dob &&
    bs.reduce(
      (result, b) =>
        result ||
        (b['first-names'] === fn &&
          b.surname === sn &&
          b['date-of-birth'] === dob),
      false
    )
)

const isChildABeneficiary = (child, bs) =>
  child['first-names'] &&
  child.surname &&
  child['date-of-birth'] &&
  bs.reduce(
    (result, b) =>
      result ||
      (b['first-names'] === child['first-names'] &&
        b.surname === child.surname &&
        b['date-of-birth'] === child['date-of-birth']),
    false
  )

const updateBeneficiarySplit = createSelector(
  (form) => form['beneficiary-details'],
  (beneficiaries) => {
    if (beneficiaries.length > 0) {
      const split = Math.floor(100 / beneficiaries.length)
      let difference = 100 - split * beneficiaries.length
      for (let i = 0; i < beneficiaries.length; i++) {
        if (difference > 0) {
          beneficiaries[i]['benefit-split'] = amountString(split + 1)
          difference -= 1
        } else {
          beneficiaries[i]['benefit-split'] = amountString(split)
        }
      }
    }
    return beneficiaries
  }
)

const healthConcernCount = (form) =>
  [
    'risky-occupation-hobbies',
    'taking-prescriptions',
    'sexually-transmitted-diseases',
    'refused-application-or-increased-premium',
  ].reduce(
    (t, f) => t + (form['life-assured-health-details'][f] === 'Yes' ? 1 : 0),
    0
  )

const createHealthDetailsPage = (state) => {
  const maxConcerns = Math.min(4, healthConcernCount(original(state.form)))
  updateItemIn(
    state.page.item,
    { id: 'health-descriptions', type: 'list' },
    {
      content: healthDetailsPrefix
        .concat(healthDetailsItem(maxConcerns))
        .concat([quotationPanel]),
    }
  )
  if (state.form['health-concerns'].length !== maxConcerns) {
    while (state.form['health-concerns'].length > maxConcerns) {
      state.form['health-concerns'].pop()
    }
    for (let i = state.form['health-concerns'].length; i < maxConcerns; i++) {
      state.form['health-concerns'].push({
        complaints: null,
        'complaint-description': null,
        'medical-condition-date': null,
      })
    }
  }
}

const generateAcceptancePage = (state) => {
  // Update the reference number
  updateItemIn(
    state.page.item,
    { id: 'contract-id' },
    { content: state.form['reference-number'] }
  )
  // Update all sections
  Object.keys(state.form).forEach((section) => {
    findItemsIn(state.page.item, { id: section }).forEach((sItem) => {
      Object.keys(state.form[section]).forEach((field) => {
        if (state.form[section][field]) {
          updateItemIn(
            sItem,
            { id: field },
            { content: state.form[section][field] }
          )
        }
      })
    })
  })
  // Add children details
  updateItemIn(
    state.page.item,
    { id: 'children-details', type: 'table' },
    { content: (state.form['children-details'] || []).map(childDetailsItem) }
  )
  // Add beneficiary details
  updateItemIn(
    state.page.item,
    { id: 'beneficiary-details', type: 'table' },
    { content: (state.form['beneficiary-details'] || []).map(beneficiaryItem) }
  )
  // Update the payment details
  let paymentDetails = acceptancePaymentDetails(state.form)
  if (paymentDetails) {
    updateItemIn(
      state.page.item,
      { id: 'payment-details', type: 'list' },
      paymentDetails
    )
  }
}

const setPageItemFromState = (state) => {
  validate(state, state.local && state.local.failed_validation ? false : true)
  switch (state.step) {
    case 'quotation-screen':
      // Date of birth
      updateItemIn(
        state.page.item,
        { id: 'date-of-birth' },
        {
          content:
            state.form['life-assured-identification']['date-of-birth'] || '',
          maxYear: dayjs().year() - 18,
          minYear: dayjs().year() - 59,
        }
      )
      // Premium selection
      Object.keys(state.form['selected-premium']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          { content: state.form['selected-premium'][field] || '' }
        )
      )
      break
    case 'personal-details':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-employment-details',
      ].forEach((section) => {
        Object.keys(state.form[section]).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: field },
            { content: state.form[section][field] || '' }
          )
        )
      })
      if (state.local && state.local.clearFieldErrors) {
        updateItemIn(
          state.page.item,
          { id: state.local.clearFieldErrors },
          { errors: [] }
        )
        delete state.local.clearFieldErrors
      }
      break
    case 'spouse-details':
      ;['spouse-identification', 'spouse-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(
              state.page.item,
              { id: field },
              { content: state.form[section][field] || '' }
            )
          )
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'spouse-is-trustee' },
        { content: state.form['spouse-is-trustee'] || '' }
      )
      break
    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(
              state.page.item,
              { id: field },
              {
                content: state.form[section][field] || '',
              }
            )
          )
        }
      )
      break
    case 'children-details':
      const numChildren = (state.form['children-details'] || []).length

      updateItemIn(
        state.page.item,
        { id: 'children-details', type: 'table' },
        {
          invisible: numChildren < 1,
          content: (state.form['children-details'] || []).map(childDetailsItem),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-constant', uid: '109a133405122cef' }, // Add child
        { invisible: numChildren >= 6 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'add-child':
            button.invisible = numChildren >= 6
            break
        }
      })
      break

    case 'add-child':
    case 'edit-child':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break

    case 'beneficiary-details':
      const numBeneficiaries = (state.form['beneficiary-details'] || []).length
      updateItemIn(
        state.page.item,
        { id: 'beneficiary-details', type: 'table' },
        {
          invisible: numBeneficiaries < 1,
          content: (state.form['beneficiary-details'] || []).map(
            beneficiaryItem
          ),
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '6c60b1c422ae8373' }, // Add Trustee as beneficiary
        {
          invisible: isTrusteeABeneficiary(state.form) || numBeneficiaries >= 9,
        }
      )

      let numValidChildren = state.form['children-details'].reduce(
        (b, c) =>
          b +
          (isChildABeneficiary(c, state.form['beneficiary-details']) ? 0 : 1),
        0
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: 'bf6a79c7a7af53dc' }, // Add a Child beneficiary
        { invisible: numValidChildren == 0 || numBeneficiaries >= 9 }
      )

      updateItemIn(
        state.page.item,
        { id: 'system-field-outcome', uid: '145b199f80e0c8c3' }, // Add a different beneficiary
        { invisible: numBeneficiaries >= 9 }
      )

      state.page.buttons.forEach((button) => {
        switch (button.id) {
          case 'update-benefit-split-percentage':
            button.invisible = numBeneficiaries < 2
            break
          case 'add-another-beneficiary':
          case 'add-children':
            button.invisible = numBeneficiaries >= 9
            break
          case 'add-trustee':
            button.invisible =
              isTrusteeABeneficiary(state.form) || numBeneficiaries >= 9
            break
        }
      })
      break
    case 'add-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break
    case 'edit-beneficiary':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break
    case 'update-beneficiary-split-percentage':
      Object.keys(state.local || {}).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.local[field] || '',
          }
        )
      )
      break
    case 'medical-underwriting':
      ;['life-assured-health-details', 'health-condition-details'].forEach(
        (section) => {
          Object.keys(state.form[section]).forEach((field) =>
            updateItemIn(
              state.page.item,
              { id: field },
              { content: state.form[section][field] || '' }
            )
          )
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'presently-in-good-health-details' },
        {
          invisible:
            state.form['life-assured-health-details'][
              'presently-in-good-health'
            ] !== 'No',
        }
      )
      ;[
        'risky-occupation-hobbies',
        'taking-prescriptions',
        'sexually-transmitted-diseases',
        'refused-application-or-increased-premium',
        'medical-advice-stop-smoking',
        'medical-advice-reduce-drinking',
        'excessive-drinking',
        'excessive-drinking-past',
      ].forEach((field) => {
        updateItemIn(
          state.page.item,
          { id: `${field}-details` },
          {
            invisible:
              state.form['life-assured-health-details'][field] !== 'Yes',
          }
        )
      })

      updateItemIn(
        state.page.item,
        { id: 'years-smoking' },
        {
          invisible: state.form['life-assured-health-details'].smoker !== 'Y',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'smoking-details', type: 'list' },
        {
          invisible: state.form['life-assured-health-details'].smoker !== 'Y',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'years-not-smoking' },
        {
          invisible:
            state.form['life-assured-health-details']['non-smoker'] !== 'Y',
        }
      )

      break
    case 'health-descriptions':
      state.form['health-concerns'].forEach((c, i) =>
        Object.keys(c).forEach((field) =>
          updateItemIn(
            state.page.item,
            { id: `${field}-${i + 1}` },
            { content: c[field] || '' }
          )
        )
      )
      break
    case 'payment-details':
      // Cover level
      Object.keys(state.form['payment-method-selection']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field, type: 'field' },
          { content: state.form['payment-method-selection'][field] || '' }
        )
      )
      const months = startOfNextMonths(3)
      if (!state.form['payment-start-date']) {
        state.form['payment-start-date'] = dayjs(months[0]).format('YYYYMMDD')
      }
      updateItemIn(
        state.page.item,
        { id: 'payment-start-date' },
        {
          content: dayjs(state.form['payment-start-date']).format(dateFormat),
          options: months,
        }
      )
      updateItemIn(
        state.page.item,
        { id: 'payment-frequency' },
        { content: state.form['payment-frequency'] || '' }
      )

      updateItemIn(
        state.page.item,
        { uid: 'ce35234c9c489acc' },
        {
          invisible:
            state.form['life-assured-identification']['marital-status'] !==
            'Married',
        }
      )

      updateItemIn(
        state.page.item,
        { id: 'myself' },
        { content: state.form['payer-person']['myself'] || '' }
      )

      updateItemIn(
        state.page.item,
        { id: 'my-spouse' },
        { content: state.form['payer-person']['my-spouse'] || '' }
      )
      break
    case 'debit-order-details':
      Object.keys(state.form['payment-bank-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['payment-bank-details'][field] || '',
          }
        )
      )
      break
    case 'stop-order-details':
      Object.keys(state.form['payment-employment-details']).forEach((field) =>
        updateItemIn(
          state.page.item,
          { id: field },
          {
            content: state.form['payment-employment-details'][field] || '',
          }
        )
      )
      break
    case 'upload-document-pages':
      state.local.pages.forEach((doc, idx) =>
        updateItemIn(
          state.page.item,
          { id: `upload-${idx + 1}` },
          { content: doc }
        )
      )
      break
  }

  // Persistent elements
  updateItemIn(
    state.page.item,
    { id: 'benefit-rate' },
    { content: benefitRate(state.form) }
  )

  // Update the quotation
  updateItemIn(
    state.page.item,
    { id: 'premium' },
    { content: premiumAmount(state.form) }
  )
}

// Change the page
const changePage = (state, page) => {
  if (page in pages) {
    state.page = pages[page]
    state.step = page
    state.local = null
  }
}

// Create the state for a new process
const createState = (state) => {
  state.currentProcess = {} // Create a new process
  state.currentProcess.form = Object.assign(
    { 'reference-number': shortid.generate() },
    form
  ) // Overall Process state
  state.currentProcess.step = 'quotation-screen' // Current process step
  state.currentProcess.page = pages['quotation-screen'] // Current process page data item
  state.currentProcess.local = null // Current process page local state
  setPageItemFromState(state.currentProcess) // Update initial page
}

// processButton
const processButton = (state, button, asyncDispatch) => {
  if (button.id === 'cancel') {
    const step = state.step
    changePage(state, 'confirm-cancel')
    state.local = state.local || {}
    state.local.previous = step
  } else {
    switch (state.step) {
      case 'confirm-cancel':
        switch (button.id) {
          case 'yes':
            asyncDispatch(cancelProcess(state['process-id']))
            break
          case 'no':
            changePage(state, state.local.previous)
            break
        }
        break
      case 'quotation-screen':
        switch (button.id) {
          case 'next':
            if (validate(state)) {
              if (premiumAmount(state.form) < 100) {
                changePage(state, 'premium-amount')
              } else {
                changePage(state, 'personal-details')
              }
            }
            break
        }
        break
      case 'premium-amount':
        switch (button.id) {
          case 'done':
            changePage(state, 'quotation-screen')
            break
        }
        break
      case 'personal-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'quotation-screen')
            break
          case 'next':
            if (validate(state)) {
              changePage(
                state,
                state.form['life-assured-identification']['marital-status'] ===
                  'Married'
                  ? 'spouse-details'
                  : 'trustee-details'
              )
            }
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              state.form['payer-person']['myself'] = 'Y'
            }
            break
        }
        break
      case 'spouse-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'personal-details')
            break
          case 'next':
            if (validate(state)) {
              if (state.form['spouse-is-trustee'] === 'Y') {
                ;['identification', 'contact-details'].forEach((section) =>
                  Object.keys(state.form[`spouse-${section}`]).forEach(
                    (field) =>
                      (state.form[`trustee-${section}`][field] =
                        state.form[`spouse-${section}`][field])
                  )
                )
                state.form['trustee-identification']['relationship'] = 'Spouse'
              }
              changePage(state, 'trustee-details')
            }
            break
        }
        break
      case 'trustee-details':
        switch (button.id) {
          case 'back':
            changePage(
              state,
              state.form['life-assured-identification']['marital-status'] ===
                'Married'
                ? 'spouse-details'
                : 'personal-details'
            )
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'children-details')
            }
            break
        }
        break
      case 'children-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'trustee-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'beneficiary-details')
            }
            break
          case 'add-child':
            if (state.form['children-details'].length < 6) {
              changePage(state, 'add-child')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'gender',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break
        }
        break
      case 'add-child':
        switch (button.id) {
          case 'back':
            changePage(state, 'children-details')
            break
          case 'add':
            if (validate(state)) {
              if (state.form['children-details'].length < 6) {
                state.form['children-details'].push({
                  'first-names': state.local['first-names'],
                  surname: state.local.surname,
                  'date-of-birth': state.local['date-of-birth'],
                  'idpassport-number': state.local['idpassport-number'] || null,
                  'id-type': state.local['id-type'] || null,
                  gender: state.local.gender,
                })
              }
              changePage(state, 'children-details')
            }
            break
        }
      case 'edit-child':
        switch (button.id) {
          case 'update':
            if (validate(state)) {
              if (
                state.local.index >= 0 &&
                state.local.index <= state.form['children-details'].length
              ) {
                Object.keys(
                  state.form['children-details'][state.local.index - 1]
                ).forEach(
                  (k) =>
                    (state.form['children-details'][state.local.index - 1][k] =
                      state.local[k])
                )
              }
              changePage(state, 'children-details')
            }
            break
          case 'remove-child':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['children-details'].length
            ) {
              const old = original(state.form['children-details'])
              state.form['children-details'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
            }
            changePage(state, 'children-details')
            break
        }
        break
      case 'beneficiary-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'children-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'medical-underwriting')
            }
            break
          case 'add-trustee':
            if (
              state.form['beneficiary-details'].length < 9 &&
              !isTrusteeABeneficiary(original(state.form))
            ) {
              state.form['beneficiary-details'].push({
                'first-names':
                  state.form['trustee-identification']['first-names'],
                surname: state.form['trustee-identification'].surname,
                'benefit-split': null,
                role: 'Cessionary',
                gender: state.form['trustee-identification'].gender,
                'date-of-birth':
                  state.form['trustee-identification']['date-of-birth'],
              })
              updateBeneficiarySplit(state.form)
            }
            break
          case 'add-children':
            let btns = state.form['children-details'].reduce((b, c, i) => {
              if (!isChildABeneficiary(c, state.form['beneficiary-details'])) {
                b.push(createButton(c['first-names'], { index: i }))
              }
              return b
            }, [])
            if (btns.length > 0) {
              changePage(state, 'add-children-as-beneficiary')
              state.page.buttons = btns
            }
            break

          case 'add-another-beneficiary':
            if (state.form['beneficiary-details'].length < 9) {
              changePage(state, 'add-beneficiary')
              state.local = [
                'first-names',
                'surname',
                'date-of-birth',
                'role',
                'gender',
              ].reduce((l, f) => {
                l[f] = null
                return l
              }, {})
            }
            break

          case 'update-benefit-split-percentage':
            changePage(state, 'update-beneficiary-split-percentage')
            state.local = state.form['beneficiary-details'].reduce(
              (l, b, c) => {
                l[`beneficiary-split-${c + 1}`] = b['benefit-split'] || ''
                return l
              },
              {}
            )
            updateItemIn(
              state.page.item,
              { id: 'update-benefit-split-percentage', type: 'list' },
              {
                content:
                  state.form['beneficiary-details'].map(benefitSplitItem),
              }
            )
            break
        }
        break
      case 'add-beneficiary':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'add':
            if (validate(state)) {
              if (state.form['beneficiary-details'].length < 9) {
                state.form['beneficiary-details'].push({
                  'first-names': state.local['first-names'],
                  surname: state.local.surname,
                  'benefit-split': null,
                  role: state.local.role,
                  gender: state.local.gender,
                  'date-of-birth': state.local['date-of-birth'],
                })
                updateBeneficiarySplit(state.form)
              }
              changePage(state, 'beneficiary-details')
            }
            break
        }
        break
      case 'edit-beneficiary':
        switch (button.id) {
          case 'update':
            if (validate(state)) {
              if (
                state.local.index >= 0 &&
                state.local.index <= state.form['beneficiary-details'].length
              ) {
                Object.keys(
                  original(
                    state.form['beneficiary-details'][state.local.index - 1]
                  )
                ).forEach(
                  (k) =>
                    (state.form['beneficiary-details'][state.local.index - 1][
                      k
                    ] = state.local[k])
                )
              }
              changePage(state, 'beneficiary-details')
            }
            break
          case 'delete-beneficiary':
            if (
              state.local.index >= 0 &&
              state.local.index <= state.form['beneficiary-details'].length
            ) {
              const old = original(state.form['beneficiary-details'])
              state.form['beneficiary-details'] = old
                .slice(0, state.local.index - 1)
                .concat(old.slice(state.local.index, old.length))
              updateBeneficiarySplit(state.form)
            }
            changePage(state, 'beneficiary-details')
            break
        }
        break
      case 'update-beneficiary-split-percentage':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'update':
            if (validate(state)) {
              state.form['beneficiary-details'] = original(
                state.form['beneficiary-details']
              ).map((b, c) => {
                b['benefit-split'] = amountString(
                  parseFloat(state.local[`beneficiary-split-${c + 1}`])
                )
                return b
              })
              changePage(state, 'beneficiary-details')
            }
        }
        break
      case 'add-children-as-beneficiary':
        if (
          button.index >= 0 &&
          button.index < state.form['children-details'].length
        ) {
          state.form['beneficiary-details'].push({
            'first-names':
              state.form['children-details'][button.index]['first-names'],
            surname: state.form['children-details'][button.index].surname,
            'benefit-split': null,
            role: `Child-${button.index + 1}`,
            gender: state.form['children-details'][button.index].gender,
            'date-of-birth':
              state.form['children-details'][button.index]['date-of-birth'],
          })
          updateBeneficiarySplit(state.form)
        }
        changePage(state, 'beneficiary-details')
        break
      case 'medical-underwriting':
        switch (button.id) {
          case 'back':
            changePage(state, 'beneficiary-details')
            break
          case 'next':
            if (validate(state)) {
              if (healthConcernCount(original(state.form)) > 0) {
                changePage(state, 'health-descriptions')
                createHealthDetailsPage(state)
              } else {
                state.form['health-concerns'].length = 0
                changePage(state, 'payment-details')
              }
            }
            break
        }
        break
      case 'health-descriptions':
        switch (button.id) {
          case 'back':
            changePage(state, 'medical-underwriting')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'payment-details')
            }
        }
        break
      case 'payment-details':
        switch (button.id) {
          case 'back':
            if (healthConcernCount(original(state.form)) > 0) {
              changePage(state, 'health-descriptions')
              createHealthDetailsPage(state)
            } else {
              changePage(state, 'medical-underwriting')
            }
            break
          case 'next':
            if (validate(state)) {
              if (
                state.form['payment-method-selection']['debit-order'] === 'Y'
              ) {
                changePage(state, 'debit-order-details')
              } else if (
                state.form['payment-method-selection']['stop-order'] === 'Y'
              ) {
                changePage(state, 'stop-order-details')
              }
            }
            if (
              ['life-assured-identification']['marital-status'] !== 'Married'
            ) {
              ;(state.form['payer-person']['myself'] = 'Y') &&
                (state.form['payer-person']['my-spouse'] = 'N')
            }
            if (state.form['payer-person']['my-spouse'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['spouse-identification'][field])
              )
            } else if (state.form['payer-person']['myself'] === 'Y') {
              Object.keys(state.form['payer-person-identification']).forEach(
                (field) =>
                  (state.form['payer-person-identification'][field] =
                    state.form['life-assured-identification'][field])
              )
            }
            state.form['life-assured-is-payer'] =
              state.form['payer-person']['myself']
            break
        }
        break
      case 'debit-order-details':
      case 'stop-order-details':
        switch (button.id) {
          case 'back':
            changePage(state, 'payment-details')
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'upload-documents')
            }
        }
        break
      case 'upload-documents':
        switch (button.id) {
          case 'upload-id-document':
          case 'upload-passport-document':
            changePage(state, 'upload-document-pages')
            state.local = state.local || {}
            state.local.document = button.id.substring(7)
            state.local.pages =
              original(state.form.documents[state.local.document]) || []
            const docs =
              state.local.pages.length === 0 ? [null] : state.local.pages
            updateItemIn(
              state.page.item,
              { id: 'uploads' },
              {
                content: docs.map((doc, idx) => {
                  return [
                    createItem('Upload', {
                      component: 'ImageDrop',
                      id: `upload-${idx + 1}`,
                      content: doc,
                      readonly: false,
                      type: 'component',
                    }),
                  ]
                }),
              }
            )
            break
          case 'next':
            if (validate(state)) {
              changePage(state, 'acceptance-screen')
              generateAcceptancePage(state)
            }
            break
          case 'back':
            if (state.form['payment-method-selection']['debit-order'] === 'Y') {
              changePage(state, 'debit-order-details')
            } else if (
              state.form['payment-method-selection']['stop-order'] === 'Y'
            ) {
              changePage(state, 'stop-order-details')
            }
            state.form['payer-signature'] = null
            break
        }
        break

      case 'upload-document-pages':
        switch (button.id) {
          case 'continue':
            state.form.documents[state.local.document] = state.local.pages || []
            if (state.form.documents[state.local.document].length > 0) {
              changePage(state, 'acceptance-screen')
              generateAcceptancePage(state)
            } else {
              changePage(state, 'upload-documents')
            }
            break
          case 'abort':
            changePage(state, 'upload-documents')
            break
          case 'add-page':
            const uploadsItem = findItemsIn(state.page.item, { id: 'uploads' })
            if (uploadsItem.length > 0) {
              const currentContent = original(uploadsItem[0].content)
              updateItemIn(
                state.page.item,
                { id: 'uploads' },
                {
                  content: currentContent.concat([
                    [
                      createItem('Upload', {
                        component: 'ImageDrop',
                        id: `upload-${currentContent.length + 1}`,
                        content: null,
                        readonly: false,
                        type: 'component',
                      }),
                    ],
                  ]),
                }
              )
            }
            break
        }
        break

      case 'acceptance-screen':
        switch (button.id) {
          case 'submit':
            if (validate(state)) {
              state.form['application-accepted'] = 'Y'
              state.form['application-acceptance-date'] =
                dayjs().format('YYYYMMDD')
              state.form.premium = premiumAmount(state.form)
              changePage(state, 'completed')
            }
            break
          case 'back':
            changePage(state, 'upload-documents')
        }
        break
      case 'completed':
        asyncDispatch(completeProcess(state.form['reference-number']))
        break
    }
    setPageItemFromState(state)
  }
}

// Prodct rules say spouse gender is opposite to main life gender :(
const spouseGender = (gender) => {
  switch (gender) {
    case 'Male':
      return 'Female'
    case 'Female':
      return 'Male'
    default:
      return null
  }
}

// processChange
const processChange = (state, data) => {
  let dirty = false
  switch (state.step) {
    case 'quotation-screen':
      if (data.id === 'date-of-birth') {
        state.form['life-assured-identification']['date-of-birth'] = data.value
        dirty = true
      } else if (data.id in state.form['selected-premium']) {
        state.form['selected-premium'][data.id] = data.value
        dirty = true
      }
      break
    case 'personal-details':
      ;[
        'life-assured-identification',
        'life-assured-contact-details',
        'life-assured-postal-address',
        'life-assured-employment-details',
      ].forEach((section) => {
        if (data.id in state.form[section]) {
          state.form[section][data.id] = data.value
          dirty = true
        }
      })
      if (['work-phone', 'home-phone', 'fax'].indexOf(data.id) >= 0) {
        if (emptyPhoneNumber(data.value)) {
          state.form['life-assured-contact-details'][data.id] = null
          state.local = state.local || {}
          state.local.clearFieldErrors = data.id
          dirty = true
        }
      }
      if (data.id === 'marital-status') {
        state.form['spouse-identification'].gender =
          data.value === 'Married'
            ? spouseGender(state.form['life-assured-identification'].gender)
            : null
        state.form['spouse-identification']['marital-status'] =
          data.value === 'Married' ? 'Married' : null
        dirty = true
      } else if (data.id === 'gender') {
        if (
          state.form['life-assured-identification']['marital-status'] ===
          'Married'
        ) {
          state.form['spouse-identification'].gender = spouseGender(data.value)
          state.form['spouse-identification']['marital-status'] = 'Married'
          dirty = true
        }
      }
      break
    case 'spouse-details':
      ;['spouse-identification', 'spouse-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      if (data.id === 'spouse-is-trustee') {
        state.form[data.id] = data.value
        dirty = true
      }
      break
    case 'trustee-details':
      ;['trustee-identification', 'trustee-contact-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      break
    case 'add-child':
    case 'edit-child':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break
    case 'add-beneficiary':
    case 'edit-beneficiary':
    case 'update-beneficiary-split-percentage':
      state.local = state.local || {}
      state.local[data.id] = data.value
      dirty = true
      break
    case 'medical-underwriting':
      ;['life-assured-health-details', 'health-condition-details'].forEach(
        (section) => {
          if (data.id in state.form[section]) {
            state.form[section][data.id] = data.value
            dirty = true
          }
        }
      )
      break
    case 'health-descriptions':
      let id = data.id.split('-')
      const index = parseInt(id.pop())
      if (!isNaN(index) && index <= state.form['health-concerns'].length) {
        id = id.join('-')
        if (id in state.form['health-concerns'][index - 1]) {
          state.form['health-concerns'][index - 1][id] = data.value
          dirty = true
        } else {
          console.warn(`No form element found for ${data.id} [${data.value}]`)
        }
      }
      break
    case 'payment-details':
      switch (data.id) {
        case 'debit-order':
        case 'stop-order':
          state.form['payment-method-selection'][data.id] = data.value
          dirty = true
          break
        case 'payment-start-date':
          const date = dayjs(data.value)
          state.form['payment-start-date'] = date.isValid()
            ? date.format('YYYYMMDD')
            : null
          dirty = true
          break
        case 'payment-frequency':
          state.form['payment-frequency'] = data.value
          dirty = true
          break
        case 'myself':
        case 'my-spouse':
          state.form['payer-person'][data.id] = data.value
          dirty = true
          break
      }
      break
    case 'debit-order-details':
      if (data.id in state.form['payment-bank-details']) {
        state.form['payment-bank-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break
    case 'stop-order-details':
      if (data.id in state.form['payment-employment-details']) {
        state.form['payment-employment-details'][data.id] = data.value
        dirty = true
      } else if (data.id === 'interactive-canvas-html') {
        state.form['payer-signature'] = data.value
        dirty = true
      }
      break
    case 'upload-document-pages':
      const doc = data.id.match(/^upload-(\d+)$/)
      if (doc) {
        if (doc <= state.local.pages.length) {
          state.local.pages[doc - 1] = data.value
        } else {
          state.local.pages.push(data.value)
        }
        dirty = true
      } else {
        console.warn(
          `No form element found for ${data.id} [${data.value}] in step ${state.step}`
        )
      }
      break

    case 'acceptance-screen':
      if (data.id === 'interactive-canvas-html') {
        state.form['acceptance-signature'] = data.value
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

const processItemClick = (state, data, asyncDispatch) => {
  let dirty = false
  switch (state.step) {
    case 'children-details':
      if (
        data.index > 0 &&
        data.index <= state.form['children-details'].length
      ) {
        changePage(state, 'edit-child')
        state.local = Object.assign(
          {},
          original(state.form['children-details'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
    case 'beneficiary-details':
      if (
        data.index > 0 &&
        data.index <= state.form['beneficiary-details'].length
      ) {
        changePage(state, 'edit-beneficiary')
        state.local = Object.assign(
          {},
          original(state.form['beneficiary-details'][data.index - 1])
        )
        state.local.index = data.index
        dirty = true
      }
      break
  }
  if (dirty) {
    setPageItemFromState(state)
  }
}

export default {
  createState,
  processButton,
  processChange,
  processItemClick,
}
